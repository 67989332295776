// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-TreeDnD-___TreeRow-module__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
}

  .app-components-TreeDnD-___TreeRow-module__container:hover > .app-components-TreeDnD-___TreeRow-module__unhideMenuHover {
    opacity: 1;
  }

.app-components-TreeDnD-___TreeRow-module__grabCursor {
  cursor: grab;
}

.app-components-TreeDnD-___TreeRow-module__grabCursor:active {
    cursor: grabbing;
  }

.app-components-TreeDnD-___TreeRow-module__nodeDirectoryLabel {
  font-weight: bolder;
  line-height: 32px;
}

.app-components-TreeDnD-___TreeRow-module__unhideMenuHover {
  opacity: 0.1;
}

.app-components-TreeDnD-___TreeRow-module__hideTargetDuringDragging {
  opacity: 0;
  max-height: 1px;
  margin-top: -1px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./app/components/TreeDnD/TreeRow.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AAKrB;;EAHE;IACE,UAAU;EACZ;;AAGF;EACE,YAAY;AAKd;;AAHE;IACE,gBAAgB;EAClB;;AAGF;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  font-size: 16px;\n  padding-top: 3px;\n  padding-bottom: 3px;\n\n  &:hover > .unhideMenuHover {\n    opacity: 1;\n  }\n}\n\n.grabCursor {\n  cursor: grab;\n\n  &:active {\n    cursor: grabbing;\n  }\n}\n\n.nodeDirectoryLabel {\n  font-weight: bolder;\n  line-height: 32px;\n}\n\n.unhideMenuHover {\n  opacity: 0.1;\n}\n\n.hideTargetDuringDragging {\n  opacity: 0;\n  max-height: 1px;\n  margin-top: -1px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-TreeDnD-___TreeRow-module__container`,
	"unhideMenuHover": `app-components-TreeDnD-___TreeRow-module__unhideMenuHover`,
	"grabCursor": `app-components-TreeDnD-___TreeRow-module__grabCursor`,
	"nodeDirectoryLabel": `app-components-TreeDnD-___TreeRow-module__nodeDirectoryLabel`,
	"hideTargetDuringDragging": `app-components-TreeDnD-___TreeRow-module__hideTargetDuringDragging`
};
export default ___CSS_LOADER_EXPORT___;
