import React from 'react'
import resources from 'app/store/resources'
import { getImpersonificationUser } from './impersonificationUser'

export const idMessageNotif = 'impersonification'

export default class ImpersonificationStart extends React.Component {
  componentDidMount() {
    const user = getImpersonificationUser()

    if (user) {
      this.startImpersonification(user.id, user.email)
    }
  }

  startImpersonification = (id, email) => {
    resources.messages.create(
      {
        id: idMessageNotif,
        text: `<a href="/admin/impersonification">Vous êtes connecté en tant que <b>${email}</b></a>`,
        messageType: 1,
        disablePermanentHide: true,
        disableHide: true,
        data: { id, email },
      },
      { localOnly: true },
    )
  }

  render() {
    return null
  }
}
