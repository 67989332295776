/** @flow */
import moment from 'moment'
import innerText from 'react-innertext'
import forEach from 'lodash/forEach'
import { stringClean } from 'app/libs/helpers'
import type { MenuAction } from 'app/core/types'
import { getOptionsValue } from 'app/core/utils/optionsPriority'

import type {
  Row,
  TableColumn,
  ColumnHeader,
  TableInstance,
  Cell,
  TableColumnHeader,
  ColumnWidths,
  Column,
} from './types'
import history from '../../main/routerHistory'
import { viewableValue } from '../Form/FormData/getInput.tsx'
import { getResources } from '../../store/selectors'
import { getLastTakeFromTask } from '../../pages/Project/ProductionManagement/FollowUp/utils'
import { getAuthorizationDescription } from '../NavigatorAuthorizations/NavigatorAuthorizations.jsx'

export const cellIdSeparator = '__cell__'

export const getColumnId = (column: TableColumn): string => {
  if (column.id) return column.id
  if (typeof column.accessor === 'string') return column.accessor
  return ''
}

export const getCellId = (row: Row, column: TableColumn): string => {
  return `${getColumnId(column)}${cellIdSeparator}${row.original?.id || 'grouped'}`
}

export const cellIdSlug = (cellId: string): string => stringClean(cellId)

export const getCellIdFromRowIdAndColumnId = (columnId: string, rowId: string): string => {
  return `${columnId}${cellIdSeparator}${rowId}`
}

export const isColumnEditable = (cell: Cell, instance: TableInstance): void | MenuAction => {
  const { actions, readOnly } = cell.column

  if (!actions || readOnly) return undefined

  let resolvedActions
  if (typeof actions === 'function') {
    resolvedActions = actions(instance, cell)
  } else {
    resolvedActions = actions
  }

  if (!resolvedActions) return undefined

  if (resolvedActions.includes('edit')) return 'edit'

  const editAction = resolvedActions.find(
    (action) => typeof action === 'object' && action?.label && innerText(action?.label) === 'Edit',
  )
  return editAction
}

export const getInfosFromCellId = (cellId: string): { cellId: string, columnId: string, rowId: string } => {
  const [columnId, rowId] = cellId.split(cellIdSeparator)
  return { cellId, columnId, rowId }
}
export const getFlattenColumns = (columns: Array<ColumnHeader>): Array<Column> => {
  return columns.map((column) => column.columns || [column]).reduce((a, b) => [...a, ...b], [])
}

export const getInternalStatus = (original: Object): null | number => {
  if (!original) return null
  if (original?.internalStatus) return original.internalStatus
  if (original?.taskInst) return getInternalStatus(original.taskInst)
  if (original?.assetInst) return getInternalStatus(original.assetInst)
  return null
}

export const keyMapper = (event: SyntheticKeyboardEvent<any>): string => {
  let keyName = ''

  function add(key: ?string, _keyName: string) {
    if (!key) return _keyName
    if (_keyName) return `${_keyName}+${key}`
    return key
  }

  if (event.ctrlKey) {
    keyName = add('ctrl', keyName)
  }
  if (event.metaKey) {
    keyName = add('command', keyName)
  }
  if (event.shiftKey) {
    keyName = add('shift', keyName)
  }
  if (event.altKey) {
    keyName = add('alt', keyName)
    event.preventDefault()
  }

  let key: string = event.key.toLowerCase()
  if (['control', 'alt', 'meta', 'shift'].includes(key)) key = ''

  if (key.startsWith('arrow')) {
    return add(key.replace('arrow', ''), keyName)
  }

  return add(key, keyName)
}

export const getUserPrefKey = (tableId: string): string => `tables.${tableId}`

export const groupByFn = (
  rows: Array<Row>,
  columnId: string,
  instance: TableInstance,
  groupingFnKey: string,
): { [rowName: string]: Array<Row> } => {
  const { visibleColumns } = instance

  let groupingFn = (rows, columnId, instance) => {
    return rows.reduce((prev, row, i) => {
      const resKey = String(row.values[columnId])
      prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
      prev[resKey].push(row)
      return prev
    }, {})
  }

  const column = visibleColumns.find((column) => column.id === columnId)

  if (column?.groupingFns && groupingFnKey) {
    groupingFn = column.groupingFns[groupingFnKey]?.fn
  }

  const groupedRows = groupingFn(rows, columnId, instance)

  return groupedRows
}

export function getAge(date: string): string {
  const momentDate = moment(new Date(date))
  const less2Hours = moment().subtract(2, 'hours')
  const less6Hours = moment().subtract(6, 'hours')
  const less1day = moment().subtract(1, 'days')
  const less2days = moment().subtract(2, 'days')
  const less1week = moment().subtract(1, 'weeks')
  const less1month = moment().subtract(1, 'months')

  if (momentDate.isSameOrAfter(less2Hours)) return 'Less than 2 hours'
  if (momentDate.isSameOrAfter(less6Hours)) return 'Less than 6 hours'
  if (momentDate.isSameOrAfter(less1day)) return 'Less than 1 day'
  if (momentDate.isSameOrAfter(less2days)) return 'Less than 2 days'
  if (momentDate.isSameOrAfter(less1week)) return 'Less than 1 week'
  if (momentDate.isSameOrAfter(less1month)) return 'Less than 1 month'
  return 'More than 1 month'
}
export const getDate = (date: string): string => {
  let func: string = 'subtract'
  let comp = 'isAfter'

  const momentDate = moment(new Date(date))

  if (momentDate.isAfter(moment())) {
    func = 'add'
    comp = 'isSameOrBefore'
  }

  // $FlowFixMe
  const less1day = moment()[func](1, 'days')
  // $FlowFixMe
  const less2days = moment()[func](2, 'days')
  // $FlowFixMe
  const less3days = moment()[func](3, 'days')
  // $FlowFixMe
  const less4days = moment()[func](4, 'days')
  // $FlowFixMe
  const less5days = moment()[func](5, 'days')
  // $FlowFixMe
  const less6days = moment()[func](6, 'days')
  // $FlowFixMe
  const less1week = moment()[func](1, 'weeks')
  // $FlowFixMe
  const less2week = moment()[func](2, 'weeks')
  // $FlowFixMe
  const less3week = moment()[func](3, 'weeks')
  // $FlowFixMe
  const less1month = moment()[func](1, 'months')

  // $FlowFixMe
  if (momentDate[comp](less1day)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}1 day${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  // $FlowFixMe
  if (momentDate[comp](less2days)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}2 days${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  // $FlowFixMe
  if (momentDate[comp](less3days)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}3 days${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  // $FlowFixMe
  if (momentDate[comp](less4days)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}4 days${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  // $FlowFixMe
  if (momentDate[comp](less5days)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}5 days${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  // $FlowFixMe
  if (momentDate[comp](less6days)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}6 days${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  // $FlowFixMe
  if (momentDate[comp](less1week)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}1 week${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  // $FlowFixMe
  if (momentDate[comp](less2week)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}2 week${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  // $FlowFixMe
  if (momentDate[comp](less3week)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}3 week${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  // $FlowFixMe
  if (momentDate[comp](less1month)) {
    return `${comp === 'isSameOrBefore' ? 'In ' : ''}1 month${comp === 'isSameOrBefore' ? '' : ' ago'}`
  }
  return `${comp === 'isSameOrBefore' ? 'In more than ' : 'More than '}1 month${
    comp === 'isSameOrBefore' ? '' : ' ago'
  }`
}

export function getDurationRange(duration: number): string {
  const min15 = 15 * 60
  const min30 = 30 * 60
  const min45 = 45 * 60
  const hour1 = 60 * 60
  const hour130 = 1.5 * hour1
  const hour2 = 2 * hour1
  const hour3 = 3 * hour1
  const hour4 = 4 * hour1
  const hour5 = 5 * hour1
  const hour6 = 6 * hour1
  const hour7 = 7 * hour1
  const day1 = 8 * hour1
  const day2 = 2 * day1
  const day3 = 3 * day1
  const day4 = 4 * day1
  const day5 = 5 * day1

  if (duration <= min15) return '< 15 minutes'
  if (duration >= min15 && duration < min30) return '15 ~ 30 minutes'
  if (duration >= min30 && duration < min45) return '30 ~ 45 minutes'
  if (duration >= min45 && duration < hour1) return '45 ~ 60 minutes'
  if (duration >= hour1 && duration < hour130) return '1 ~ 1.5 hours'
  if (duration >= hour130 && duration < hour2) return '1.5 ~ 2 hours'
  if (duration >= hour2 && duration < hour3) return '2 ~ 3 hours'
  if (duration >= hour3 && duration < hour4) return '3 ~ 4 hours'
  if (duration >= hour4 && duration < hour5) return '4 ~ 5 hours'
  if (duration >= hour5 && duration < hour6) return '5 ~ 6 hours'
  if (duration >= hour6 && duration < hour7) return '6 ~ 7 hours'
  if (duration >= hour7 && duration < day1) return '7 ~ 8 hours'
  if (duration >= day1 && duration < day2) return '1 ~ 2 days'
  if (duration >= day2 && duration < day3) return '2 ~ 3 days'
  if (duration >= day3 && duration < day4) return '3 ~ 4 days'
  if (duration >= day4 && duration < day5) return '4 ~ 5 days'
  return '> 5 days'
}

export function getCustomRange(value: number, range: number): string {
  // prettier-ignore
  const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]

  for (let index = 0; index < values.length; index += 1) {
    const min = values[index] * range
    const max = values[index + 1] * range

    if (value >= min && value < max) return max - min > 1 ? `${min} ~ ${max}` : String(min)
    if (index === 0 && value < min) return `< ${min}`
    if (index === values.length - 1 && value > min) return `> ${min}`
  }
  return `> ${1000 * range}`
}

export const URLParamsParser = {
  get: (key: string): any => {
    const URLParams = new URLSearchParams(window.location.search).get(key)

    let parsedURLParams
    if (URLParams) {
      try {
        parsedURLParams = JSON.parse(atob(URLParams))
      } catch (error) {
        console.error('[URLParamsParser] JSON.parse error:', error)
      }
    }

    if (parsedURLParams === 'undefined') return undefined

    return parsedURLParams
  },

  set: (key: string, data: any) => {
    if (!data || (Array.isArray(data) && data.length === 0)) {
      history.updateQuery({ [key]: undefined })
      return
    }

    history.updateQuery({ [key]: btoa(JSON.stringify(data)) })
  },
}

export const isFromExpanderGroup = (column: TableColumn | TableColumnHeader): boolean => {
  if (
    column.isExpander ||
    column.isPivot ||
    column.isCustomPivot ||
    column.isRowSelector ||
    column.parent?.id === 'expanderGroup' ||
    column.parent?.originalId === 'expanderGroup'
  ) {
    return true
  }
  return false
}

export function getURLParamsKey(key: string, tableId?: ?string): string {
  return `${tableId ? `${tableId}-` : ''}${key}`
}

export function getMaxColumnWidth(
  columnWidths: ColumnWidths,
  remainingColumnWidths: ColumnWidths,
  column: TableColumn,
): number {
  const columnWidth = Number.isNaN(columnWidths[column.id]) ? 0 : columnWidths[column.id]
  const remainingColumnWidth = Number.isNaN(remainingColumnWidths[column.id]) ? 0 : remainingColumnWidths[column.id]
  const originalWidth = Number.isNaN(column.originalWidth) ? 0 : column.originalWidth
  const width = Number.isNaN(column.width) ? 0 : column.width

  const min = column.minWidth || 30
  return Number(Math.max(columnWidth || remainingColumnWidth || originalWidth || width, min))
}

export function copyCellsValuesToClipboard(instance: TableInstance) {
  const { selectedCells } = instance.getLastestSelection()

  const rawText = []
  const progressionStatus = getResources(undefined, 'progressionStatus')

  forEach(selectedCells, (cell) => {
    if (cell.value === undefined || cell.value === null) {
      rawText.push('')
      return
    }
    switch (cell.column.cellType) {
      case 'CellFlags_take':
      case 'CellFlags_asset': {
        const flagsText = cell.value?.map((flag) => flag.flagInst.name).filter((_) => _)
        rawText.push(flagsText.join(' '))
        break
      }
      case 'CellAssetsList':
      case 'CellAssetsSelect': {
        if (Array.isArray(cell.value)) {
          const assetsText = cell.value?.map((item) => item?.to_assetInst?.name).filter((_) => _)
          rawText.push(assetsText.join(' '))
        } else if (typeof cell.value === 'object') {
          rawText.push(cell.value.name)
        }
        break
      }
      case 'CellDynamicApproval':
        rawText.push(cell.value.comment || '')
        break
      case 'CellTask': {
        const lastTake = getLastTakeFromTask(cell.value)
        rawText.push(lastTake?.comment || '')
        break
      }
      case 'CellStatus': {
        rawText.push(progressionStatus[cell.value]?.name || '')
        break
      }
      case 'CellRating':
        rawText.push(Array(cell.value).fill('*').join('')) // prettier-ignore
        break
      case 'CellDuration':
        rawText.push(viewableValue('duration', cell.value))
        break
      case 'CellText_date':
        rawText.push(viewableValue('date', cell.value))
        break
      case 'CellText_time':
        rawText.push(viewableValue('time', cell.value))
        break
      case 'CellText_datetime':
        rawText.push(viewableValue('datetime', cell.value))
        break
      case 'CellPriority':
        rawText.push(getOptionsValue(cell.value)?.label)
        break
      case 'CellMedias':
        rawText.push(cell.value?.map((media) => media.url).join(' \n'))
        break
      case 'decimalHours':
      case 'CellText_number':
        rawText.push(Number(cell.value))
        break
      case 'CellProgression': {
        if (typeof cell.value?.max !== 'number' || typeof cell.value?.value !== 'number') {
          break
        }

        const value = cell.value.max - cell.value.value
        if (!value) rawText.push('Done')
        else {
          rawText.push(
            value > 0
              ? `Ends in ${String(viewableValue('duration', value))}`
              : `Exceeded +${String(viewableValue('duration', Math.abs(value)))}`,
          )
        }
        break
      }
      case 'CellAggregatedAttribute': {
        let value = null
        if (Array.isArray(cell.value)) {
          value = cell.value?.map((val) => JSON.stringify(val).replace(/["}{]/g, '')).join(' \n')
        } else value = JSON.stringify(cell.value).replace(/["}{]/g, '')
        if (value) rawText.push(value || ' ')
        break
      }
      case 'CellTimecode':
        rawText.push(viewableValue('timecode', cell.value))
        break

      // Unvaluable celltypes
      case 'CellAutocomplete':
      case 'CellLink':
      case 'CellThumbnail':
      case 'CellNotes':
      case 'CellTimeline':
      case 'CellGanttTree':
      case 'CellSelectRow':
      case 'expander':
      case 'pivot':
      case 'rowSelector':
      case 'custom':
        break

      case 'CellCheckbox':
      case 'CellRichText':
      case 'CellPhone':
      case 'CellSelect':
      case 'CellText_text':
      case 'CellText_url':
      case 'CellText_ip':
      case 'CellText_email':
      default:
        rawText.push(String(cell.value))
        break
    }
  })

  const { ClipboardItem } = window
  const item = new ClipboardItem({
    'text/plain': new Blob([rawText.join('\n\r').replace(/(<([^>]+)>)/gi, '')], { type: 'text/plain' }),
    'text/html': new Blob([rawText.join('\n\r')], { type: 'text/html' }),
  })
  // $FlowFixMe
  navigator.clipboard.write([item])
}

export async function getClipboardValues(): Promise<{ [mimeType: 'text/plain' | 'text/html']: string }> {
  return navigator.permissions.query({ name: 'clipboard-read' }).then((result) => {
    if (result.state === 'granted' || result.state === 'prompt') {
      if (result.state === 'prompt') {
        getAuthorizationDescription('clipboard')
      }

      // $FlowFixMe
      return navigator.clipboard.read().then((values: Array<Object>) => {
        const ClipboardItem = values[0]
        const copiedValues = {}
        const { types } = ClipboardItem
        types.forEach((type) => {
          copiedValues[type] = ClipboardItem.getType(type).then((res) => res.text())
        })

        return Promise.all(Object.values(copiedValues)).then((res) => {
          return res.reduce((acc, value, index) => ({ ...acc, [types[index]]: value }), {})
        })
      })
    }
    return Promise.reject()
  })
}
