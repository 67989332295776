// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellTask-___groupingFns-module__status {
  border-radius: 8px;
  padding: 3px 10px;
  font-weight: bold;
}

.app-components-Table-Cells-CellTask-___groupingFns-module__assignedUser {
  position: relative;
  margin: 2px;
  background-color: #555;
  color: #fff;
  padding: 3px 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellTask/groupingFns.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".status {\n  border-radius: 8px;\n  padding: 3px 10px;\n  font-weight: bold;\n}\n\n.assignedUser {\n  position: relative;\n  margin: 2px;\n  background-color: #555;\n  color: #fff;\n  padding: 3px 7px;\n  border-radius: 3px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `app-components-Table-Cells-CellTask-___groupingFns-module__status`,
	"assignedUser": `app-components-Table-Cells-CellTask-___groupingFns-module__assignedUser`
};
export default ___CSS_LOADER_EXPORT___;
