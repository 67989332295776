// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-CheckboxGroup-___CheckboxGroup-module__pointer {
  cursor: pointer;
}

.app-components-Form-CheckboxGroup-___CheckboxGroup-module__optionContainer {
  display: flex;
  margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/CheckboxGroup/CheckboxGroup.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;AACb","sourcesContent":[".pointer {\n  cursor: pointer;\n}\n\n.optionContainer {\n  display: flex;\n  margin: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pointer": `app-components-Form-CheckboxGroup-___CheckboxGroup-module__pointer`,
	"optionContainer": `app-components-Form-CheckboxGroup-___CheckboxGroup-module__optionContainer`
};
export default ___CSS_LOADER_EXPORT___;
