/** @flow */
import React from 'react'
import { SnackbarProvider } from 'notistack'
import makeStyles from '@material-ui/styles/makeStyles'
import { NotificationsPublisher } from './NotificationsPublisher.jsx'

type Props = {|
  children: *,
|}

const useStyles = makeStyles((theme) => ({
  success: { backgroundColor: '#48C27E' },
  error: { backgroundColor: '#E56D7A' },
  warning: { backgroundColor: '#E3E05B' },
  info: { backgroundColor: '#00639A' },
}))

export function NotificationsHOC(props: Props): React$Node {
  const { children } = props
  const classes = useStyles()

  return (
    <SnackbarProvider
      maxSnack={5}
      style={{ zIndex: 10000 }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      <NotificationsPublisher>{children}</NotificationsPublisher>
    </SnackbarProvider>
  )
}
