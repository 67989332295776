/** @flow */
import React from 'react'
import { DurationRead, Duration } from 'app/components/Duration/Duration.tsx'
import type { DefaultCellProps, Column } from '../../types'

import { groupingFns } from './groupingFns.jsx'
import classes from './CellDuration.module.scss'

type CellDurationParams = {|
  ...$Exact<DefaultCellProps>,
  hoursInDay?: number,
  durationProps?: Object,
  disableSelection?: boolean,
|}

export const CellDuration = ({ durationProps, disableSelection, hoursInDay, ...data }: CellDurationParams): Column => ({
  cellType: 'CellDuration',
  Cell: (instance) => {
    const { value, cell } = instance
    const { getCellProps } = cell
    const { edition } = getCellProps()
    const { isBeingEdit, endEdit, save } = edition

    if (isBeingEdit) {
      return (
        <Duration
          value={value}
          center={true}
          hoursInDay={hoursInDay}
          inputsRef={(refs) => {
            if (refs) {
              ;(refs.h || refs.d || refs.m)?.select?.()
            }
          }}
          onCancel={() => {
            endEdit()
            setTimeout(() => cell.focusCell(), 0)
          }}
          onEnter={(newValue) => {
            endEdit()
            cell.focusCell()
          }}
          onBlur={(newValue) => {
            if (!newValue && !value) endEdit()
            else if (value !== newValue) save(newValue)
            else endEdit()
          }}
          className="flex center alignCenter fullWidth fullHeight"
          {...durationProps}
        />
      )
    }

    return (
      <div className={classes.container}>
        <DurationRead value={value} hoursInDay={hoursInDay} {...durationProps} />
      </div>
    )
  },
  width: 100,
  groupingFns,
  ...data,
})
