/** @flow */
import map from 'lodash/map'
import invert from 'lodash/invert'
import type { Option } from 'app/core/types'

export const getContractTypeKey = (key: string, contracts): string => {
  const status = contracts
  if (!status) return key
  if (status[key]) return status[key]
  return invert(status)[key]
}

export const getContractTypesOptions = (contracts): Array<Option> => {
  const options = map(contracts, (label, value) => ({ label, value }))
  return options
}
