/** @flow */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { map } from 'lodash'
import type { Asset } from 'app/core/types'
import resources from 'app/store/resources'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import { ObjectValues } from 'app/libs/flowPolyfills'

import classes from './Usage.module.scss'

type Props = {|
  asset: Asset,
  episode: Asset,
  getShotsText: Function,
  isLoaded: Function,
|}

export function UsageShots(props: Props): React$Node {
  const { asset, episode, getShotsText, isLoaded } = props
  const [shots, setShots] = useState<Array<{ label: string, shot: Asset }>>([])
  const [loader, setLoader] = useState(true)

  let isMounted = true

  useEffect(() => {
    resources.assets
      .fetchShotUsage(
        { assetId: asset.id, episodeId: episode.id },
        {
          params: { queries: { page_size: 1000 }, headers: { [window.OVM_PROJECT_HEADER]: asset.project || '' } },
        },
      )
      .then((res) => {
        if (!isMounted) return

        const { assets } = res.includedResources
        const assetLinks = res.resources

        let shotsUsage = {}
        assetLinks.forEach((assetLink) => {
          const shot = shotsUsage[assetLink.from_asset]
          if (shot) {
            shotsUsage[assetLink.from_asset].count += 1
          } else {
            shotsUsage[assetLink.from_asset] = {
              ...assets[assetLink.from_asset],
              count: 1,
            }
          }
        })

        shotsUsage = ObjectValues(shotsUsage)
        shotsUsage.sort((a, b) => `${a.name}`.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))

        const shotsText = map(shotsUsage, (usage) => ({
          label: `${usage.name}${usage.count === 1 ? '' : ` (x${usage.count})`}`,
          shot: usage,
        }))
        setShots(shotsText)
        getShotsText(shotsText)
        isLoaded(true)
        setLoader(false)
      })
    return () => {
      isMounted = false
    }
  }, [])

  return loader ? (
    <div className="flex center alignCenter padding30 fullWidth">
      <LoaderSmall style={{ width: 30, height: 30 }} />
    </div>
  ) : (
    <div className="grid col9">
      {map(shots, (shot) => {
        return (
          <Link
            key={shot.shot.id}
            className={classes.shotLink}
            to={`/projects/${shot.shot.project || ''}/assets?assetDetails=/${
              shot.shot.id
            }/breakdownShot&assetDetails-rightPanel=false`}
          >
            {shot.label}
          </Link>
        )
      })}
    </div>
  )
}
