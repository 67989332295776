// @flow

import type { StoreResourceDef } from 'app/core/types'

export const projectDepartments: StoreResourceDef<> = {
  resourceType: 'projectDepartments',
  relations: {
    projectInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'project',
    },
    departmentInst: {
      type: 'hasOne',
      resourceType: 'departments',
      foreignKey: 'department',
    },
  },
}
