/** @flow */
import React, { useMemo } from 'react'
import { sortBy } from 'lodash'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import { MUIButton } from 'app/components/Form'
import flagClasses from 'app/components/Table/Cells/CellFlags/CellFlags.module.scss'

import { ModalPie } from './ModalPie.jsx'
import type { CellInstance } from '../../types'
import { mountModal } from '../../TableView/Elements/ModalEdition.jsx'

type Props = {|
  instance: CellInstance,
  flags: Array<Object>,
  columnName?: string,
  onFilter?: Function,
|}

export function DisplayFlags(props: Props): React$Node {
  const { flags, columnName, onFilter, instance } = props

  const { tableId, filtersTools } = instance
  const { setUrlFilters } = filtersTools || {}

  const filterByFlags = (flagId) => {
    if (!columnName || !setUrlFilters) return
    const filter = btoa(JSON.stringify([[columnName, 'inList', flagId]]))
    setUrlFilters(filter, true, false)
  }

  const sortedFlags = useMemo(
    () =>
      sortBy(flags, ['count', 'flags__name'])
        .reverse()
        .filter((flag) => flag.flags__name),
    [flags],
  )

  const pieData = useMemo(
    () =>
      sortedFlags.reduce(
        (acc, { flags__name, flags__color, flags__uuid, count }) =>
          flags__uuid
            ? [
                ...acc,
                {
                  value: count,
                  id: flags__uuid,
                  label: flags__name,
                  color: flags__color,
                },
              ]
            : acc,
        [],
      ),
    [sortedFlags],
  )

  return (
    <div
      className="relative overflowXAuto flex column"
      style={{ border: '1px solid rgba(0,0,0,0.1)', borderRadius: 3, minHeight: 42 }}
    >
      <div className="flex row noWrap alignCenter marginLeft5 spaceBetween">
        <div className="bold">count: {flags?.length || 0}</div>
        <div style={{ margin: 3 }}>
          <MUIButton
            onClick={() => {
              mountModal(ModalPie, tableId, {
                data: pieData,
                colors: (data) => data.color,
              })
            }}
            bgColor="#ffffff"
            style={{ color: '#000000' }}
            icon="fas-chart-pie"
            dataCy="flags-open-pie"
          />
        </div>
      </div>
      <div className="flex flex1 row noWrap">
        <div className="relative padding5">
          <div className="grid col2 noGridGap">
            {sortedFlags.map(({ flags__name, flags__color = '#4e4e4e', flags__uuid, count }) => {
              return [
                <div className="flex" key={flags__name}>
                  <div
                    onClick={(e) => (onFilter ? onFilter(flags__uuid, true) : filterByFlags(flags__uuid))}
                    className={flagClasses.flagReadOnly}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: flags__color,
                      color: getColorFromBackground(flags__color),
                    }}
                  >
                    {flags__name}
                  </div>
                </div>,
                <span key={flags__uuid} className="bold">
                  {count}
                </span>,
              ]
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
