/** @flow */

export const targetIsChild = (event?: SyntheticEvent<>, _target?: ?HTMLElement, _self?: ?HTMLElement): boolean => {
  const self = _self || event?.currentTarget
  // $FlowFixMe
  let target = _target || event?.relatedTarget

  if (!target || !self) return false

  while (target) {
    // $FlowFixMe
    if (self.isEqualNode(target)) return true
    target = target.parentNode
  }

  return false
}

export function isEqualNode(event: SyntheticEvent<any, any>, _target?: HTMLElement): boolean {
  const target = _target || event.nativeEvent.target
  const source = event.nativeEvent.relatedTarget
  if (!source || !target) return false
  return target.isEqualNode(source)
}
