// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellAggregated-___CellAggregatedAttribute-module__container {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
  overflow-wrap: anywhere;
  position: relative;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellAggregated/CellAggregatedAttribute.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".container {\n  overflow-x: hidden;\n  overflow-y: auto;\n  padding: 5px;\n  overflow-wrap: anywhere;\n  position: relative;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellAggregated-___CellAggregatedAttribute-module__container`
};
export default ___CSS_LOADER_EXPORT___;
