/** @flow */
import React, { type Element, useEffect, useRef, useState, useMemo, RefObject, ReactElement, ReactNode } from 'react'
import type { ResolvedMenuAction } from 'app/core/types/MenuAction.js'
import innerText from 'react-innertext'
import { filter } from 'lodash'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { hideContextMenu } from './ContextMenuComponent.jsx'
import classes from './ContextMenu.module.scss'
import keyCode from '../../libs/keyCode.js'

export interface TSContextMenuItemProps extends ResolvedMenuAction {
  cy?: string;
  disabled?: boolean | (() => boolean);
  separator?: boolean;
  label?: ReactNode;
  rightLabel?: string | ReactElement<any>;
  leftLabel?: string | ReactElement<any>;
  data?: any;
  onClick?: () => void;
  onClose?: () => void;
  controlled?: boolean;
  items?: TSContextMenuItemProps[];
  color?: string;
  dontHideOnClick?: boolean;
  searching?: boolean;
  index?: string;
  itemRef?: RefObject<any>;
  parentRef?: RefObject<any>;
  parentSearchRef?: RefObject<any>;
}

export type ContextMenuItemProps = {|
  ...$Shape<$Exact<ResolvedMenuAction>>,
  cy?: string,
  disabled?: boolean | (() => boolean),
  separator?: boolean,
  label?: React$Node,
  rightLabel?: string | Element<any>,
  leftLabel?: string | Element<any>,
  data?: any,
  onClick?: Function,
  onClose?: Function,
  controlled?: boolean,
  items?: Array<ContextMenuItemProps>,
  color?: string,
  dontHideOnClick?: boolean,
  searching?: boolean,
  index?: string,
  itemRef?: React$ElementRef<any>,
  parentRef?: React$ElementRef<any>,
  parentSearchRef?: React$ElementRef<any>,
|}

export function MenuItem(props: ContextMenuItemProps): React$Node {
  const {
    label,
    rightLabel,
    leftLabel,
    disabled,
    separator,
    items,
    color,
    controlled,
    cy,
    dontHideOnClick,
    searching = (items || []).length > 10,
    index,
    itemRef: _itemRef,
    parentRef,
    parentSearchRef,
    onClose,
  } = props

  const itemRef = useRef()
  const childsRefs = useRef()
  const inputRef = useRef()
  const [search, setSearch] = useState('')

  function onClick(event) {
    event.stopPropagation()
    const { data, onClick } = props
    if (onClick) {
      onClick(event, data)
      if (!dontHideOnClick) hideContextMenu()
    }
  }

  function recalculatePosition() {
    if (items && itemRef.current) {
      const subItems = itemRef.current.getElementsByClassName('ovm-contextmenu-subItem')
      setTimeout(() => {
        Array.from(subItems).forEach((subItems) => {
          const { innerHeight, innerWidth } = window
          const { clientHeight, clientWidth } = subItems
          const { top: fromWindowTop, left: fromWindowLeft } = subItems.getBoundingClientRect()

          if (clientWidth + fromWindowLeft > innerWidth) subItems.style.left = `-${clientWidth}px`
          if (clientHeight + fromWindowTop > innerHeight - 50) {
            subItems.style.top = `-${clientHeight - (innerHeight - fromWindowTop) + 50}px`
          }
        })
      }, 0)
    }
  }

  const arrow = items ? (
    <span className={classes.arrow}>
      <FontIcon icon="fas-caret-right" />
    </span>
  ) : null

  useEffect(() => {
    recalculatePosition()
  }, [])

  const reducedItems = useMemo(() => {
    if (!items || !search) return items
    return items.filter((item) =>
      innerText(item.label)
        .toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .includes(search),
    )
  }, [search, items])

  if (separator) {
    return (
      <div className="relative" data-cy={cy}>
        <div
          style={{
            width: '100%',
            backgroundColor: 'rgba(0,0,0,0.08)',
            height: 1,
            margin: label ? '8px 0 6px 0' : undefined,
          }}
        />
        {label ? (
          <div
            className="fontSize10 absolute"
            style={{
              lineHeight: '11px',
              top: -5,
              left: 5,
              backgroundColor: '#ffffff',
              color: 'rgba(0,0,0,0.2)',
              padding: '0 3px',
            }}
          >
            {label}
          </div>
        ) : null}
      </div>
    )
  }

  function onKeyDown(e: SyntheticKeyboardEvent<>) {
    switch (e.keyCode) {
      case keyCode.ENTER: {
        e.preventDefault()
        e.stopPropagation()
        onClick(e)
        break
      }

      case keyCode.DOWN: {
        e.preventDefault()
        e.stopPropagation()
        // $FlowFixMe
        const selectableItems = filter(itemRef.current?.parentNode?.childNodes, (node) =>
          node.getAttribute('item-selector'),
        )
        if (!selectableItems) return
        let current = selectableItems.findIndex((node) => node.isSameNode(itemRef.current))
        if (current === selectableItems.length - 1 && parentSearchRef?.current) {
          parentSearchRef.current.focus()
          break
        } else if (selectableItems[current + 1]) current += 1
        else current = 0
        selectableItems[current].focus()
        break
      }

      case keyCode.UP: {
        e.preventDefault()
        e.stopPropagation()
        // $FlowFixMe
        const selectableItems = filter(itemRef.current?.parentNode?.childNodes, (node) =>
          node.getAttribute('item-selector'),
        )
        if (!selectableItems) return
        let current = selectableItems.findIndex((node) => node.isSameNode(itemRef.current))
        if (current === 0 && parentSearchRef?.current) {
          parentSearchRef.current.focus()
          break
        } else if (selectableItems[current - 1]) current -= 1
        else current = selectableItems.length - 1
        selectableItems[current].focus()
        break
      }

      case keyCode.LEFT: {
        e.preventDefault()
        e.stopPropagation()
        parentRef?.current?.focus?.()
        break
      }

      case keyCode.RIGHT: {
        e.preventDefault()
        e.stopPropagation()
        if (searching) inputRef.current?.select()
        // $FlowFixMe
        else filter(childsRefs.current?.childNodes, (node) => node.getAttribute('item-selector'))[0]?.focus?.()
        break
      }

      case keyCode.ESCAPE: {
        e.preventDefault()
        e.stopPropagation()
        onClose?.()
        break
      }

      case keyCode.F: {
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault()
          e.stopPropagation()
          parentSearchRef?.current?.focus()
        }
        break
      }

      default:
        break
    }
  }

  function searchingInput() {
    return (
      <div tabIndex="0" item-selector="true" className="padding5 fullWidth" onFocus={() => inputRef.current?.select()}>
        <input
          ref={(ref) => {
            inputRef.current = ref
          }}
          onKeyDown={(e) => {
            switch (e.keyCode) {
              case keyCode.DOWN: {
                e.preventDefault()
                e.stopPropagation()
                // $FlowFixMe
                const selectableItems = filter(childsRefs.current?.childNodes, (node) =>
                  node.getAttribute('item-selector'),
                )
                selectableItems[0]?.focus?.()
                break
              }
              case keyCode.UP: {
                e.preventDefault()
                e.stopPropagation()
                // $FlowFixMe
                const selectableItems = filter(childsRefs.current?.childNodes, (node) =>
                  node.getAttribute('item-selector'),
                )
                selectableItems[selectableItems.length - 1]?.focus?.()

                break
              }

              default:
                break
            }
          }}
          className={classes.searchInput}
          placeholder="Search..."
          value={search}
          style={{ height: 25, fontSize: '16px', padding: 5 }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    )
  }

  const isDisabled = typeof disabled === 'function' ? disabled() : disabled

  if (controlled) return label || null

  return (
    <div
      className={classes.item}
      onClick={onClick}
      onMouseEnter={(e) => itemRef.current?.focus()}
      onKeyDown={onKeyDown}
      ref={(ref) => {
        itemRef.current = ref
        if (_itemRef) _itemRef.current = ref
      }}
      data-cy={cy}
      item-selector="true"
      tabIndex="0"
      style={
        isDisabled || (items && items.length < 1)
          ? {
              color: 'lightgrey',
              pointerEvents: 'none',
            }
          : color
          ? {
              color: getColorFromBackground(color),
              backgroundColor: color,
            }
          : {}
      }
    >
      <div className={classes.text}>
        <span className={classes.label}>
          {leftLabel ? (
            <span className={classes.leftLabel} style={{ color: isDisabled ? 'lightgrey' : undefined }}>
              {leftLabel}
            </span>
          ) : null}
          {label}
        </span>
        {rightLabel ? (
          <span
            className={classes.rightLabel}
            style={{
              marginRight: items ? 15 : undefined,
              color: isDisabled ? 'lightgrey' : !color ? '#999' : 'inherit',
            }}
          >
            {rightLabel}
          </span>
        ) : null}
      </div>
      {arrow}
      {items && (
        <div className={`${classes.box} ${classes.subItems} ovm-contextmenu-subItem`}>
          {searching ? searchingInput() : null}
          <div style={{ overflowY: items && items.length > 10 ? 'auto' : undefined, maxHeight: '70vh' }}>
            <div
              style={{ width: 'inherit', maxHeight: '70vh' }}
              ref={(ref) => {
                childsRefs.current = ref
              }}
            >
              {(reducedItems || []).map((action: ContextMenuItemProps, id: number) => {
                let key = id
                const innerLabelText = `${innerText(action.label)}-${String(id)}`
                if (innerLabelText) key = action.separator ? `separator-${innerLabelText}` : innerLabelText
                return (
                  <MenuItem
                    key={key}
                    parentRef={itemRef}
                    parentSearchRef={inputRef}
                    cy={String(key)}
                    onClose={onClose}
                    index={`${index ? `${index}-` : ''}${id}`}
                    {...action}
                  />
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
