export const toolbox = {
  kind: 'categoryToolbox',
  contents: [
    {
      kind: 'category',
      name: 'Operations',
      contents: [
        { kind: 'block', type: 'number_block' },
        { kind: 'block', type: 'list_block' },
        { kind: 'block', type: 'aggregate_block' },
      ],
    },
    {
      kind: 'category',
      name: 'Lists',
      contents: [
        { kind: 'block', type: 'tasks_list' },
        { kind: 'block', type: 'step_block' },
        { kind: 'block', type: 'dynamicApproval_list' },
        { kind: 'block', type: 'dynamicApproval_block' },
      ],
    },
    { kind: 'category', name: 'Parameters', contents: [{ kind: 'block', type: 'ignoreBlank_parameter' }] },
    {
      kind: 'category',
      name: 'Asset attributes',
      contents: [
        { kind: 'block', type: 'asset_name_accessor' },
        { kind: 'block', type: 'asset_assetType_accessor' },
        { kind: 'block', type: 'asset_attributes_accessor' },
        { kind: 'block', type: 'asset_childs_accessor' },
        { kind: 'block', type: 'asset_links_accessor' },
        { kind: 'block', type: 'asset_childsLinks_accessor' },
      ],
    },
    {
      kind: 'category',
      name: 'Task attributes',
      contents: [
        { kind: 'block', type: 'task_name_accessor' },
        { kind: 'block', type: 'task_priority_accessor' },
        { kind: 'block', type: 'task_startDate_accessor' },
        { kind: 'block', type: 'task_earliestStartDate_accessor' },
        { kind: 'block', type: 'task_endDate_accessor' },
        { kind: 'block', type: 'task_latestEndDate_accessor' },
        { kind: 'block', type: 'task_quoteEstimLength_accessor' },
        { kind: 'block', type: 'task_realEstimLength_accessor' },
        { kind: 'block', type: 'task_validLength_accessor' },
      ],
    },
    {
      kind: 'category',
      name: 'Task status attributes',
      contents: [
        { kind: 'block', type: 'task_status_name_accessor' },
        { kind: 'block', type: 'task_status_order_accessor' },
        { kind: 'block', type: 'task_status_color_accessor' },
        { kind: 'block', type: 'task_status_statusType_accessor' },
      ],
    },
    {
      kind: 'category',
      name: 'Task assigned user attributes',
      contents: [{ kind: 'block', type: 'task_assignedUser_name_accessor' }],
    },
    {
      kind: 'category',
      name: 'Task last take attributes',
      contents: [
        { kind: 'block', type: 'task_lastTake_name_accessor' },
        { kind: 'block', type: 'task_lastTake_comment_accessor' },
        { kind: 'block', type: 'task_lastTake_number_accessor' },
        { kind: 'block', type: 'task_lastTake_estimLength_accessor' },
        { kind: 'block', type: 'task_lastTake_statusUpdatedAt_accessor' },
      ],
    },
    {
      kind: 'category',
      name: 'Task takes attributes',
      contents: [
        { kind: 'block', type: 'task_takes_name_accessor' },
        { kind: 'block', type: 'task_takes_comment_accessor' },
        { kind: 'block', type: 'task_takes_number_accessor' },
        { kind: 'block', type: 'task_takes_estimLength_accessor' },
        { kind: 'block', type: 'task_takes_statusUpdatedAt_accessor' },
      ],
    },
    {
      kind: 'category',
      name: 'Task activities attributes',
      contents: [
        { kind: 'block', type: 'task_activities_duration_accessor' },
        { kind: 'block', type: 'task_activities_activityType_accessor' },
        { kind: 'block', type: 'task_activities_comment_accessor' },
        { kind: 'block', type: 'task_activities_date_accessor' },
      ],
    },
    {
      kind: 'category',
      name: 'Dynamic approval value attributes',
      contents: [
        { kind: 'block', type: 'dynamicApprovalValue_status_accessor' },
        { kind: 'block', type: 'dynamicApprovalValue_statusUpdatedAt_accessor' },
        { kind: 'block', type: 'dynamicApprovalValue_comment_accessor' },
      ],
    },
  ],
}
