/** @flow */
import React from 'react'
import resources from 'app/store/resources'
import pipe from 'app/core/utils/pipe'
import DefaultGroups, { type Props } from 'app/containers/Groups/Groups.jsx'

export const Groups: React$AbstractComponent<any, any> = pipe()
  .request((props) => {
    if (!props.projectId) {
      return resources.assets.fetchAll({
        params: {
          queries: {
            assetType: 'gp',
            project__uuid__isnull: true,
            page_size: 1000,
          },
        },
      })
    }
    return Promise.resolve()
  })
  .render((props: Props): React$Node => <DefaultGroups route="/admin/groups" routing="/admin/groups" />)
