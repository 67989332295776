// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-ColorPicker-___ColorPickerPopper-module__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-components-Form-ColorPicker-___ColorPickerPopper-module__colorPicker {
  position: fixed;
  z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/ColorPicker/ColorPickerPopper.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":[".container {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.colorPicker {\n  position: fixed;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Form-ColorPicker-___ColorPickerPopper-module__container`,
	"colorPicker": `app-components-Form-ColorPicker-___ColorPickerPopper-module__colorPicker`
};
export default ___CSS_LOADER_EXPORT___;
