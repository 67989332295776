// @flow
import { enumProxy } from 'app/libs/helpers'
import vars from './vars'

export const { colors } = vars

export type ColorType = $Keys<typeof vars.colors>

/**
 * Example of use:
 *
 *  colors.strokeHover.mainColor
 *  colors.strokeHover.blue
 *  colors.borderColor.greyLight
 *
 */
const getColorsClassName = () => {
  const list = [
    'color',
    'backgroundColor',
    'fill',
    'stroke',
    'borderColor',
    'borderTopColor',
    'borderBottomColor',
    'borderLeftColor',
    'borderRightColor',
  ]

  const colorsMethods = {}

  for (const key of list) {
    const listDefault = {}
    const listHover = {}

    Object.keys(colors).forEach((color) => {
      listDefault[color] = `${key}_${color}`
      listHover[color] = `${key}Hover_${color}`
    })

    colorsMethods[key] = enumProxy(listDefault)
    colorsMethods[`${key}Hover`] = enumProxy(listHover)
  }

  return colorsMethods
}

export const colorsClassName: {
  backgroundColor: { [key: string]: string },
  borderBottomColor: { [key: string]: string },
  borderColor: { [key: string]: string },
  borderLeftColor: { [key: string]: string },
  borderRightColor: { [key: string]: string },
  borderTopColor: { [key: string]: string },
  color: { [key: string]: string },
  fill: { [key: string]: string },
  stroke: { [key: string]: string },
} = getColorsClassName()
