/** @flow */
import * as React from 'react'
import PubSub from 'pubsub-js'
import { HowToUserHours } from 'app/pages/MyHours/HowTo.tsx'
import * as utils from './utils'

const components = {
  userHours: <HowToUserHours />,
}

type Props = {|
  history: Object,
|}

function findRoute(pathname) {
  const routes = pathname.split('/')

  if (routes[1] === 'my-hours') return 'userHours'
  return null
}

export const HowTo = (props: Props): React$Node => {
  const { history } = props
  const { pathname } = history.location

  const [howTo, setHowTo] = React.useState(null)

  const sub = React.useRef()

  React.useEffect(() => {
    sub.current = PubSub.subscribe('HOW-TO', (msg, key) => {
      if (key) setHowTo(components[key])
    })
    return () => PubSub.unsubscribe(sub.current)
  })

  React.useEffect(() => {
    const route = findRoute(pathname)

    if (route) {
      const options = utils.getHowToVisitedPages()
      if (options && !options[route]) setHowTo(components[route])
    }
  }, [pathname])

  return howTo
}
