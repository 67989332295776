/** @flow */
import React from 'react'
import type { ResourcesList, ProgressionStatus } from 'app/core/types'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import { getAge } from 'app/components/Table/utils'

import type { Column } from '../../types'
import classes from './groupingFns.module.scss'

export const groupingFns: (
  progressionStatus: ResourcesList<ProgressionStatus>,
) => $ElementType<Column, 'groupingFns'> = (progressionStatus) => ({
  status: {
    fn: (rows, columnId, instance) => {
      const ordered = {}
      const unordered = rows.reduce((prev, row, i) => {
        const { lastTake, takesInst } = row.values?.[columnId] || {}

        const resKey = String(
          (lastTake && takesInst && takesInst.find((take) => take.id === lastTake)?.status) || 'undefined',
        )
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})

      Object.keys(unordered)
        .sort((prev, current) => {
          const currentStatus = progressionStatus[current]?.order || -1
          const prevStatus = progressionStatus[prev]?.order || -1
          return prevStatus - currentStatus
        })
        .forEach((key) => {
          ordered[key] = unordered[key]
        })
      return ordered
    },
    label: 'Status',
    headerRow: (cell) => {
      if (!cell.value) return null
      const { statusInst } = cell.value
      if (!statusInst) return null

      const { name, color } = statusInst
      return (
        <div className={classes.status} style={{ backgroundColor: color, color: getColorFromBackground(color) }}>
          {name}
        </div>
      )
    },
  },
  statusTake: {
    fn: (rows, columnId, instance) => {
      const ordered = {}
      const unordered = rows.reduce((prev, row, i) => {
        const value = row.values[columnId]
        const lastTake = value?.takesInst?.find((t) => t.id === value?.lastTake)
        const takeNumber = lastTake?.number
        const statusOrder = lastTake?.statusInst.order

        const resKey = lastTake ? `${takeNumber * 100}@${statusOrder}` : 'undefined'
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})

      Object.keys(unordered)
        .sort((prev, current) => {
          if (current === 'undefined') return -2
          const currentScore = current.split('@').reduce((a, b) => a + Number(b), 0)
          const prevScore = prev.split('@').reduce((a, b) => a + Number(b), 0)
          if (currentScore > prevScore) return -1
          return 1
        })
        .forEach((key) => {
          ordered[key] = unordered[key]
        })
      return ordered
    },
    label: 'Status/Take',
    headerRow: (cell) => {
      if (!cell.value) return null
      const { statusInst } = cell.value
      const lastTake = cell.value?.takesInst?.find((t) => t.id === cell.value?.lastTake)
      const number = lastTake?.number

      if (!statusInst && !number) return null

      return (
        <div className="flex row alignCenter fullHeight">
          {number ? <div className="marginRight10 bold">Take {number}</div> : null}
          {statusInst ? (
            <div
              className={classes.status}
              style={{ backgroundColor: statusInst.color, color: getColorFromBackground(statusInst.color) }}
            >
              {statusInst.name}
            </div>
          ) : null}
        </div>
      )
    },
  },
  statusTake1andOther: {
    fn: (rows, columnId, instance) => {
      const ordered = {}
      const unordered = rows.reduce((prev, row, i) => {
        const value = row.values[columnId]
        const lastTake = value?.takesInst?.find((t) => t.id === value?.lastTake)
        const takeNumber = lastTake?.number
        const statusOrder = lastTake?.statusInst.order

        const resKey = lastTake ? `${takeNumber === 1 ? '100' : '200'}@${statusOrder}` : 'undefined'
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})

      Object.keys(unordered)
        .sort((prev, current) => {
          if (current === 'undefined') return -2
          const currentScore = current.split('@').reduce((a, b) => a + Number(b), 0)
          const prevScore = prev.split('@').reduce((a, b) => a + Number(b), 0)
          if (currentScore > prevScore) return -1
          return 1
        })
        .forEach((key) => {
          ordered[key] = unordered[key]
        })
      return ordered
    },
    label: 'Status/Take (1 and others)',
    headerRow: (cell) => {
      if (!cell.value) return null
      const { statusInst } = cell.value
      const lastTake = cell.value?.takesInst?.find((t) => t.id === cell.value?.lastTake)
      const number = lastTake?.number

      if (!statusInst && !number) return null

      return (
        <div className="flex row alignCenter fullHeight">
          {number ? <div className="marginRight10 bold">Take {number === 1 ? '1' : '2+'}</div> : null}
          {statusInst ? (
            <div
              className={classes.status}
              style={{ backgroundColor: statusInst.color, color: getColorFromBackground(statusInst.color) }}
            >
              {statusInst.name}
            </div>
          ) : null}
        </div>
      )
    },
  },
  takeNumber: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const value = row.values[columnId]
        const lastTake = value?.takesInst?.find((t) => t.id === value?.lastTake)
        const resKey = String(lastTake?.number)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Take number',
    headerRow: (cell) => {
      const lastTake = cell.value?.takesInst?.find((t) => t.id === cell.value?.lastTake)
      return (
        <div className="flex alignCenter fullHeight">
          {lastTake?.number ? <div className="bold">Take {lastTake.number}</div> : null}
        </div>
      )
    },
  },
  takeNumber1andOther: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const value = row.values[columnId]
        const lastTake = value?.takesInst?.find((t) => t.id === value?.lastTake)
        const resKey = String(lastTake?.number ? (lastTake?.number === 1 ? '1' : '2+') : undefined)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Take number (1 and others)',
    headerRow: (cell) => {
      const lastTake = cell.value?.takesInst?.find((t) => t.id === cell.value?.lastTake)
      return (
        <div className="flex alignCenter fullHeight">
          {lastTake?.number ? <div className="bold">Take {lastTake.number === 1 ? '1' : '2+'}</div> : null}
        </div>
      )
    },
  },
  assignUser: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const resKey = String(row.values[columnId]?.assignedUserInst?.name)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Assigned user',
    headerRow: (cell) =>
      cell.value?.assignedUserInst?.name ? (
        <div className={classes.assignedUser}>{cell.value?.assignedUserInst?.name}</div>
      ) : null,
  },
  updatedAt: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const value = row.values[columnId]
        const lastTake = value?.takesInst?.find((t) => t.id === value?.lastTake)
        const resKey = String(lastTake?.updatedAt ? getAge(lastTake.updatedAt) : undefined)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Update',
    headerRow: (cell) => {
      const lastTake = cell.value?.takesInst?.find((t) => t.id === cell.value?.lastTake)
      return lastTake?.updatedAt ? <div className="bold">{getAge(lastTake.updatedAt)}</div> : null
    },
  },
  statusUpdatedAt: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const value = row.values[columnId]
        const lastTake = value?.takesInst?.find((t) => t.id === value?.lastTake)
        const resKey = String(lastTake?.statusUpdatedAt ? getAge(lastTake.statusUpdatedAt) : undefined)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Status update',
    headerRow: (cell) => {
      const lastTake = cell.value?.takesInst?.find((t) => t.id === cell.value?.lastTake)
      return lastTake?.statusUpdatedAt ? <div className="bold">{getAge(lastTake.statusUpdatedAt)}</div> : null
    },
  },
  hasComment: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const value = row.values[columnId]
        const lastTake = value?.takesInst?.find((t) => t.id === value?.lastTake)
        const resKey = String(row.values[columnId] ? (lastTake?.comment ? 'comment' : 'nocomment') : undefined)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Have a comment',
    headerRow: (cell) => {
      if (!cell.value) return null
      const lastTake = cell.value?.takesInst?.find((t) => t.id === cell.value?.lastTake)
      return lastTake?.comment ? (
        <div className="bold">Have a comment</div>
      ) : (
        <div className="bold">Do not have a comment</div>
      )
    },
  },
})
