/** @flow */
import React, { useEffect, useRef, useState, ReactNode } from 'react'
import cx from 'classnames'
import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'

import { MUIButton } from '../Form'
import LoaderSmall from '../LoaderSmall/LoaderSmall.jsx'
import { getColorFromBackground } from '../../libs/helpers/getColorFromBackground'
import classes from './MUIModal.module.scss'
import keyCode from '../../libs/keyCode'

export type TSModalProps = {
  onRequestClose?: () => void,
  onExited?: () => void,
  onExiting?: () => void,
  title?: string | any,
  titleColor?: string,
  exponentTitle?: string,
  paginatedList?: string,
  width?: number,
  height?: number,
  minWidth?: number,
  maxWidth?: number,
  minHeight?: number,
  maxHeight?: number,
  draggable?: boolean,
  resizable?: boolean,
  children: ReactNode | string | (() => void),
  disableBackdrop?: boolean,
  disableBackdropClick?: boolean,
  keepMounted?: boolean,
  onEscapeKeyDown?: () => void,
  disablePortal?: boolean,
  disableEnforceFocus?: boolean,
  open?: boolean,
  loading?: boolean,

  extendsButtons?: ((onRequestClose: () => void) => ReactNode[]) | ReactNode[] | ReactNode,
  childreContainerStyle?: {},

  // ModalConfirm
  validateLabel?: string,
  validateLabelColor?: string,
  hideValidate?: boolean,
  cancelLabel?: string,
  cancelLabelColor?: string,
  hideCancel?: boolean,
  disableCancel?: boolean,
  disableValidate?: boolean,
  keepOpenWhenValidate?: boolean,
  showValidateAndContinue?: boolean,

  onValidate?: (handleClose: () => void) => Promise<any>,
  onCancel?: (handleClose: () => void) => Promise<any>,
}

export type ModalProps = {|
  onRequestClose?: () => void,
  onExited?: Function,
  onExiting?: Function,
  title?: string | any,
  titleColor?: string,
  exponentTitle?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  paginatedList?: ?string,
  width?: number,
  height?: number,
  minWidth?: number,
  maxWidth?: number,
  minHeight?: number,
  maxHeight?: number,
  draggable?: boolean,
  resizable?: boolean,
  children: React$Node | string | Function,
  disableBackdrop?: boolean,
  disableBackdropClick?: boolean,
  keepMounted?: boolean,
  onEscapeKeyDown?: Function,
  disablePortal?: boolean,
  disableEnforceFocus?: boolean,
  open?: boolean,
  loading?: boolean,
  onPaste?: () => void,

  extendsButtons?: (({ onRequestClose: Function }) => Array<React$Node>) | Array<React$Node> | React$Node,
  childreContainerStyle?: Object,

  // ModalConfirm
  validateLabel?: string,
  validateLabelColor?: string,
  hideValidate?: boolean,
  cancelLabel?: string,
  cancelLabelColor?: string,
  hideCancel?: boolean,
  disableCancel?: boolean,
  disableValidate?: boolean,
  keepOpenWhenValidate?: boolean,
  showValidateAndContinue?: boolean,

  onValidate?: ({ handleClose: Function }) => Promise<any>,
  onCancel?: ({ handleClose: Function }) => Promise<any>,
|}

function DraggableComponent({
  className,
  nodeRef,
  style,
  children,
  disableBackdrop,
  defaultPosition,
  setDefaultPosition,
  ...props
}: *) {
  if (!defaultPosition) {
    defaultPosition = {
      x: window.innerWidth / 2,
      y: 0,
    }
  }

  return (
    <Draggable
      cancel={'[class*="MuiDialogContent-root"]'}
      position={disableBackdrop ? defaultPosition : undefined}
      onStop={disableBackdrop ? (e, data) => setDefaultPosition({ x: data.x, y: data.y }) : undefined}
    >
      <Paper
        className={cx(className)}
        {...props}
        style={{
          ...style,
          ...(disableBackdrop
            ? {
                minWidth: 'min-content',
                minHeight: 'min-content',
              }
            : {}),
        }}
      >
        <div>{children}</div>
      </Paper>
    </Draggable>
  )
}

export function MUIModal(props: ModalProps): React$Node {
  const { innerHeight, innerWidth } = window

  const {
    disableBackdrop,
    disableBackdropClick = true,
    minWidth = 400,
    maxWidth = innerWidth - 100,
    minHeight = 200,
    maxHeight = innerHeight - 200,
    width = props.minWidth || 600,
    height = props.minHeight || 400,
    open = true,
    title,
    titleColor,
    childreContainerStyle,
    exponentTitle,
    resizable = false,
    draggable = false,
    keepMounted = false,
    disablePortal = false,
    disableEnforceFocus = true,
    extendsButtons,
    onRequestClose = () => {},
    onEscapeKeyDown,
    onExited,
    onExiting,
    disableValidate = false,
    keepOpenWhenValidate = false,
    validateLabel,
    validateLabelColor,
    hideValidate,
    showValidateAndContinue = false,
    cancelLabel,
    cancelLabelColor,
    hideCancel,
    disableCancel,
    onValidate = (Function) => Promise.resolve(),
    children,
    onCancel = (Function) => Promise.resolve(),
    loading,
    onPaste,
  } = props

  const [loader, setLoader] = useState(false)
  const [defaultPosition, setDefaultPosition] = useState()
  const [error, setError] = useState(null)

  const isMounted = useRef()
  const modalRef = useRef<HTMLElement | null | void>()

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  })

  async function handleClose(event: Event, reason?: string) {
    if (reason === 'backdropClick' && disableBackdropClick) return undefined
    if (reason === 'escapeKeyDown' && onEscapeKeyDown) {
      if (onEscapeKeyDown && onEscapeKeyDown.then) return onEscapeKeyDown.then(onRequestClose)
      onEscapeKeyDown && onEscapeKeyDown()
      return onRequestClose()
    }
    return onRequestClose()
  }

  async function handleCancel(event: Event, reason?: string) {
    if (!onCancel) return Promise.resolve()

    const cancelPromise = onCancel({ handleClose })

    // $FlowFixMe
    if (cancelPromise && cancelPromise.then)
      cancelPromise.then((res) => {
        handleClose(event, reason)
      })

    return cancelPromise
  }

  async function handleValidate(event: Event, keepModalOpen?: boolean, reason?: string) {
    setError(null)

    if (!onValidate) return handleClose(event, reason)

    setLoader(true)

    const validatePromise = onValidate({ handleClose })

    // $FlowFixMe
    if (validatePromise && (validatePromise.then || validatePromise.catch)) {
      validatePromise
        .then((res) => {
          if (!keepOpenWhenValidate && !(res instanceof Error) && !keepModalOpen) handleClose(event, reason)
          return res
        })
        .catch((err) => {
          if (err?.serverError) setError(err?.serverError.infos)
          return err
        })
        .finally(() => {
          if (isMounted.current) setLoader(false)
        })
    }

    return validatePromise
  }

  function wrapResizable(content) {
    if (!resizable) return content

    return (
      <div
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
      >
        <ResizableBox
          width={Math.min(width, maxWidth)}
          height={Math.min(height, maxHeight)}
          minConstraints={[minWidth, minHeight]}
          maxConstraints={[maxWidth, maxHeight]}
        >
          {content}
        </ResizableBox>
      </div>
    )
  }

  function onKeyDown(e: KeyboardEvent) {
    if (e.ctrlKey || e.metaKey) {
      if (e.keyCode === keyCode.ENTER) {
        handleValidate(e)
      }
    }
  }

  function catchKeyboardValidation(e: KeyboardEvent) {
    if (e.metaKey || e.ctrlKey) {
      if (e.keyCode === 13) {
        handleValidate(e)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  }, [])

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.addEventListener('keydown', catchKeyboardValidation)
      return () => modalRef.current?.removeEventListener('keydown', catchKeyboardValidation)
    }
    return undefined
  }, [modalRef.current])

  function viewableError(error) {
    if (typeof error === 'string') return error
    if (Array.isArray(error))
      return error.map((err, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index}>
          {String(err)}
          <br />
        </span>
      ))
    if (typeof error === 'object')
      return Object.values(error).map((err, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index}>
          {String(err)}
          <br />
        </span>
      ))
    return null
  }

  let computedChildren = children
  if (typeof children === 'function') computedChildren = children({ handleClose })

  let content = (
    <div
      className={classes.contentContainer}
      style={{ width: !resizable ? width : undefined }}
      onMouseDown={(e) => {
        e.stopPropagation()
      }}
    >
      <div className={classes.childrenContainer} style={childreContainerStyle}>
        {computedChildren}
      </div>
      {!!error && (
        <div className={classes.error}>
          <span className="fontSize18 marginRight10">⚠</span>
          {viewableError(error)}
        </div>
      )}
      {(onValidate || extendsButtons) && (
        <div className={`${classes.buttonsContainer} flex row noWrap marginRight10 marginTop10`}>
          {!hideCancel ? (
            <MUIButton
              data-cy="modal-cancel"
              onClick={handleCancel}
              bgColor={cancelLabelColor}
              color="default"
              disabled={disableCancel}
            >
              {cancelLabel || 'Cancel'}
            </MUIButton>
          ) : null}
          {extendsButtons && (
            <div className="marginLeft10 flex row noWrap">
              {typeof extendsButtons === 'function' ? extendsButtons({ onRequestClose }) : extendsButtons}
            </div>
          )}
          {!hideValidate ? (
            <MUIButton
              style={{ marginLeft: 8 }}
              onClick={handleValidate}
              bgColor={validateLabelColor}
              color={validateLabelColor ? 'default' : 'secondary'}
              disabled={loading || loader || disableValidate}
              data-cy="modal-confirm"
            >
              {loading || (loader && <LoaderSmall style={{ marginRight: 10 }} />)}{' '}
              {validateLabel && showValidateAndContinue ? `${validateLabel} and close` : validateLabel || 'Ok'}
            </MUIButton>
          ) : null}
          {showValidateAndContinue ? (
            <MUIButton
              style={{ marginLeft: 8 }}
              onClick={(e) => handleValidate(e, true)}
              bgColor={validateLabelColor}
              color={validateLabelColor ? 'default' : 'secondary'}
              disabled={loading || loader || disableValidate}
            >
              {loading || (loader && <LoaderSmall style={{ marginRight: 10 }} />)}{' '}
              {`${validateLabel} and continue` || 'Ok'}
            </MUIButton>
          ) : null}
        </div>
      )}
    </div>
  )

  if (resizable) content = wrapResizable(content)

  return (
    <Dialog
      open={open}
      onPaste={onPaste}
      onClose={handleClose}
      onExited={onExited}
      onExiting={onExiting}
      PaperComponent={draggable ? DraggableComponent : Paper}
      PaperProps={disableBackdrop ? { disableBackdrop, defaultPosition, setDefaultPosition } : undefined}
      maxWidth={false}
      keepMounted={keepMounted}
      disablePortal={disablePortal}
      disableEnforceFocus={disableEnforceFocus}
      style={disableBackdrop ? { width: 1, height: 1 } : undefined}
      classes={{
        root: classes.dialogRoot,
        scrollPaper: disableBackdrop ? classes.dialogScrollPaper : undefined,
      }}
      ref={modalRef}
    >
      {title && (
        <DialogTitle
          style={{
            backgroundColor: titleColor || '#3DC3D2',
            cursor: draggable ? 'move' : 'normal',
            padding: '8px 25px 8px 25px',
            textAlign: 'center',
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 2,
          }}
        >
          {exponentTitle ? (
            <div
              className={classes.exponentTitle}
              style={{ color: titleColor ? getColorFromBackground(titleColor) : '#ffffff' }}
            >
              {exponentTitle}
            </div>
          ) : null}
          <span style={{ color: titleColor ? getColorFromBackground(titleColor) : '#ffffff' }}>{title}</span>
        </DialogTitle>
      )}
      {content}
    </Dialog>
  )
}
