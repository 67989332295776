/** @flow */
import React, { useState } from 'react'
import moment from 'moment'
import cx from 'classnames'
import type { ID } from 'app/core/types'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'

import type { CellInstance, TimelineEditionItems } from '../../types'
import classes from './ColumnTimeline.module.scss'

export type TimelineCellComponent = React$AbstractComponent<
  {|
    item: Object,
    timelineEditionItems: ?TimelineEditionItems,
    instance: CellInstance,
    onSelect: (id: ?ID) => void,
    selected: ?ID,
  |},
  any,
>

export type TimelineDrawerComponent = React$AbstractComponent<
  {|
    instance: CellInstance,
    values: Array<Object>,
    height: number,
    selected: ?ID,
  |},
  any,
>

type Props = {|
  instance: CellInstance,
  CellComponent: TimelineCellComponent,
  Drawer: ?TimelineDrawerComponent,
|}

export function CellTimeline(props: Props): React$Node {
  const { instance, CellComponent, Drawer } = props
  const { cell, state, dimensions, prefs, row } = instance
  const { ganttCellsState, expandedRows, savingCells } = state

  const [selected, setSelected] = useState<ID | void | null>()

  function getRowHeight() {
    if (row.isGrouped) return 50
    const { minLineHeight, maxLineHeight } = prefs
    const estimatedHeight = expandedRows[row.id] ? maxLineHeight : minLineHeight
    return estimatedHeight
  }

  const height = getRowHeight()

  if (!dimensions) return <div className={classes.cell} style={{ height }} />
  if (cell.row.isGrouped) return <div className={classes.cell} style={{ height }} />

  return (
    <>
      <div className={classes.loaderContainer}>
        <div className={classes.loader}>
          {savingCells[cell.id] ? (
            <div style={{ padding: '0 10px' }}>
              <LoaderSmall size={16} thick={3} />
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={cx(classes.cell, { [classes.disabled]: savingCells[cell.id] })}
        onContextMenu={(e) => e.preventDefault()}
      >
        {CellComponent
          ? cell.value
              .sort((a, b) => moment(b.endDate).diff(moment(b.startDate)) - moment(a.endDate).diff(moment(a.startDate)))
              .map((item: Object, index: number) => {
                const timelineEditionItems = cell.column.timelineEdition?.(item, instance, cell.column)

                if (!timelineEditionItems) return null

                return (
                  <CellComponent
                    instance={instance}
                    item={item}
                    timelineEditionItems={timelineEditionItems}
                    key={`${item?.id || ''}-${ganttCellsState[cell.id]?.resetKey}`}
                    onSelect={(id) => setSelected(id)}
                    selected={selected}
                  />
                )
              })
          : null}
        {Drawer ? <Drawer values={cell.value} instance={instance} height={height} selected={selected} /> : null}
      </div>
    </>
  )
}
