var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useMemo } from 'react';
import { map } from 'lodash';
import moment from 'moment';
import { getServerConfig } from 'app/core/utils/getServerConfig';
import { FormData } from 'app/components/Form';
import { ModalConfirmForm } from 'app/components/Modal';
import resources from 'app/store/resources';
import { getResources } from 'app/store/selectors';
import store from 'app/store';
export function ModalAddCustomActivity(props) {
    const { paginatedList, userId, preSelectedDate = new Date(), onChange } = props, rest = __rest(props, ["paginatedList", "userId", "preSelectedDate", "onChange"]);
    const userProjects = useMemo(() => getResources(undefined, 'assets', store.getState().user.projects), []);
    function onAddCustomActivity(data) {
        return resources.activities
            .create(Object.assign(Object.assign({}, data), { date: moment(data.date).format('YYYY-MM-DD'), user: userId, activityType: data.activityType, project: data.project ? data.project.value : undefined }))
            .then((res) => {
            if (onChange)
                onChange(res.resources);
        });
    }
    return (_jsx(ModalConfirmForm, Object.assign({ title: "Add custom activity", minWidth: 500 }, rest, { children: _jsx(FormData, { onSave: onAddCustomActivity, defaultData: {
                duration: 0,
                date: preSelectedDate,
                comment: '',
            }, properties: [
                {
                    key: 'project',
                    label: 'Project',
                    type: 'autocomplete',
                    elementProps: (data, setData) => ({
                        options: () => [{ label: '-', value: null }].concat(userProjects.map((project) => ({ label: project.name, value: project.id }))),
                        placeholder: 'Select a project',
                    }),
                },
                {
                    key: 'duration',
                    label: 'Duration',
                    type: 'duration',
                    elementProps: {
                        isRequired: true,
                        hide: { days: true },
                    },
                },
                {
                    key: 'activityType',
                    label: 'Type',
                    type: 'select',
                    elementProps: {
                        isRequired: true,
                        fullWidth: true,
                        options: map(getServerConfig().ACTIVITY_TYPES, (label, value) => ({
                            label,
                            value,
                        })).filter((o) => o.value !== 'lv'),
                        placeholder: 'Select an activity type',
                    },
                },
                {
                    key: 'comment',
                    label: 'Comment',
                    type: 'string',
                    elementProps: {
                        placeholder: 'Short description.',
                        isRequired: true,
                    },
                },
                {
                    key: 'date',
                    label: 'Date',
                    type: 'string',
                    elementProps: (data, setData) => ({
                        type: 'date',
                        isRequired: true,
                        value: data.date ? moment(data.date).format('YYYY-MM-DD') : undefined,
                        max: moment().format('YYYY-MM-DD'),
                        min: '2000-01-01',
                        onChange: (e) => setData({ date: e.target.value }),
                    }),
                },
            ] }) })));
}
