/** @flow */
import React from 'react'
import moment from 'moment'
import { map, filter, sortBy, uniqBy } from 'lodash'
import { confirm } from 'app/components/Modal/confirm.jsx'
import { durationStr } from 'app/components/Duration/Duration.tsx'
import { error } from 'app/components/Notifications/notify.js'
import type { Asset, MenuAction, ResourcesList } from 'app/core/types'
import type { Cell, TableInstance, State } from 'app/components/Table'
import resources from 'app/store/resources/index.js'

import type { CellValue } from './TableTimeRecap.jsx'

function fillBusinessDay(
  instance: TableInstance,
  cell: Cell,
  project?: Asset,
  currentSelectedCells: $Exact<{ current: $ElementType<State, 'selectedCells'> | void }>,
  reloadActivities: Function,
  updateTable: () => void,
) {
  return (e) => {
    const { getLastestSelection, updateTable, updateCells } = instance
    const { selectedCells } = getLastestSelection()

    currentSelectedCells.current = selectedCells

    const dates = []

    uniqBy(map(selectedCells), 'column.id').forEach((cell) => {
      const { userInst, user, allActivities }: CellValue = cell.value

      const date = moment(cell.column.id, 'YYYY-MM-DD')
      const dateString = cell.column.id
      const requiredTime = [6, 0].includes(date.day()) ? 0 : date.day() === 5 ? 7 * 3600 : 8 * 3600

      const activitiesSum: number = filter(
        map(allActivities).flat(),
        (activity) => activity.date === dateString && activity.user === user,
      ).reduce((acc, activity) => acc + activity.duration, 0)

      const rest = requiredTime - activitiesSum

      if (rest > 0) dates.push({ date, duration: rest, user: userInst })
    })

    if (dates.length === 0) {
      error('Activities already been declared on these days or these days are in a weekend.')
      return Promise.resolve()
    }

    return confirm({
      title: `Pre-filled custom activities ${project?.name ? `on project ${project.name}` : ''}`,
      render: (
        <div style={{ color: 'rgba(0,0,0,0.8)', padding: 15 }}>
          <span className="bold">You will create pre-filled custom activities for the following days:</span>
          <ul>
            {dates.map(({ date, duration }) => {
              const dateStr = date.format('ddd DD/MM/YYYY')
              return (
                <li key={dateStr}>
                  {durationStr(duration, undefined, { days: true })} - {dateStr}
                </li>
              )
            })}
          </ul>
          <span className="bold">Are you sure?</span>
        </div>
      ),
      onValidate: () =>
        resources.activities
          .create(
            dates.map(({ date, duration, user }) => ({
              date: date.format('YYYY-MM-DD'),
              comment: `autofill - ${user.name} - ${moment().format('DD/MM/YYYY')}`,
              user: user.id,
              activityType: 'ot',
              project: project?.id || null,
              duration,
            })),
          )
          .then((res) => {
            reloadActivities()
            updateTable()
            updateCells(selectedCells)
          }),
    })
  }
}

export function fillBusinessDayOption(
  instance: TableInstance,
  cell: Cell,
  projects: ResourcesList<Asset>,
  currentSelectedCells: $Exact<{ current: $ElementType<State, 'selectedCells'> | void }>,
  reloadActivities: () => void,
  updateTable: () => void,
): MenuAction {
  return {
    label: 'Autofill business day',
    editAction: true,
    items: [{ label: '-', value: undefined }]
      .concat(
        sortBy(
          map(projects, (project) => ({ label: project.name, value: project })),
          ['label'],
        ),
      )
      .map((option) => ({
        label: option.label,
        onClick: fillBusinessDay(instance, cell, option.value, currentSelectedCells, reloadActivities, updateTable),
        editAction: true,
      })),
  }
}
