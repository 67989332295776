// @flow

import React from 'react'
import clip from 'text-clipper'
import TextRead from 'app/components/TextEditor/TextRead.jsx'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type { Props as AssetHistoryProps } from 'app/containers/AssetHistory'
import { AssetHistoryModal } from 'app/containers/AssetHistory/AssetHistoryModal.tsx'
import { openModal, type ModalProps } from 'app/components/Modal'

import { CellRichTextModal } from './CellRichTextModal.jsx'
import type { Cell, DefaultCellProps, Column, TableInstance } from '../../types'
import classes from './CellRichText.module.scss'

type CellRichTextParams = {|
  ...$Shape<DefaultCellProps>,
  modalTitle?: string,
  checkValidContent?: (cell: Cell) => ?React$Element<any>,
  actionsIfNotValidContent?: $ElementType<DefaultCellProps, 'actions'>,
  showHistoryParams?: (
    instance: TableInstance,
    cell: Cell,
  ) => {|
    assetId: $ElementType<AssetHistoryProps, 'assetId'>,
    projectId?: $ElementType<AssetHistoryProps, 'projectId'>,
    resourceType: $ElementType<AssetHistoryProps, 'resourceType'>,
    requestName: $ElementType<AssetHistoryProps, 'requestName'>,
    title: $ElementType<ModalProps, 'title'>,
    accessor: Function | string,
  |} | null,
|}

export const CellRichText = ({
  modalTitle,
  actions,
  checkValidContent,
  actionsIfNotValidContent,
  showHistoryParams,
  ...data
}: CellRichTextParams): Column => ({
  cellType: 'CellRichText',
  Cell: (instance) => {
    const { cell, prefs } = instance
    const { value, getCellProps } = cell
    const { isRowExpanded } = getCellProps()
    const { minLineHeight, maxLineHeight } = prefs

    if (checkValidContent) {
      const validationContent = checkValidContent(cell)
      if (validationContent) return validationContent
    }

    if (!value) return null

    const text = isRowExpanded
      ? value
      : clip(value, Math.round((cell.column.width || 80) * 0.1 * 3), {
          html: true,
        })

    return (
      <div className="relative fullWidth fullHeight">
        {!isRowExpanded && value && text.length < value.length ? (
          <Tooltip title={<TextRead text={value} />}>
            <div className={classes.isCollapsedTextIcon}>
              <FontIcon icon="fas-pen" />
            </div>
          </Tooltip>
        ) : null}
        <TextRead
          text={text}
          preventImageReflow={isRowExpanded}
          qlProps={{ style: { padding: 0 } }}
          className={classes.read}
          style={{ maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2 }}
        />
      </div>
    )
  },
  EditModal: (instance) => {
    const { cell, cellPositionLabel } = instance
    const { column, value, getCellProps } = cell
    const { edition } = getCellProps()
    const { save, endEdit } = edition

    return (
      <CellRichTextModal
        value={value}
        allowEmpty={true}
        modalTitle={modalTitle || String(column.Header)}
        onChange={save}
        onRequestClose={endEdit}
        open={true}
        exponentTitle={cellPositionLabel}
      />
    )
  },
  actions: (instance, cell) => {
    const notValidContent = checkValidContent && checkValidContent(cell)

    if (notValidContent) {
      if (!actionsIfNotValidContent) return []
      if (typeof actionsIfNotValidContent === 'function') return actionsIfNotValidContent(instance, cell)
      return actionsIfNotValidContent
    }

    let resolvedActions

    if (typeof actions === 'function') resolvedActions = actions(instance, cell) || []
    else resolvedActions = actions || []

    if (showHistoryParams) {
      const params = showHistoryParams(instance, cell)

      if (params) {
        const { assetId, projectId, resourceType, requestName, title = 'History', accessor } = params

        resolvedActions.push(
          { separator: true },
          {
            label: 'Show history',
            editAction: false,
            onClick: () => {
              openModal(
                <AssetHistoryModal
                  tableId="dynAppHistory"
                  projectId={projectId}
                  columns={[
                    {
                      Header: title,
                      id: title,
                      columns: [
                        CellRichText({
                          id: 'richComment',
                          Header: title,
                          accessor: typeof accessor === 'function' ? accessor : `actionObject.${accessor}`,
                          noFilterOption: true,
                          readOnly: true,
                          actions: () => [],
                          width: undefined,
                          minWidth: 150,
                        }),
                      ],
                    },
                  ]}
                  resourceType={resourceType}
                  requestName={requestName}
                  assetId={assetId}
                  title={title}
                />,
              )
            },
          },
        )
      }
    }

    return resolvedActions
  },
  groupingFns: {
    hasComment: {
      fn: (rows, columnId, instance) => {
        return rows.reduce((prev, row, i) => {
          const resKey = String(Boolean(row.values[columnId]))
          prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
          prev[resKey].push(row)
          return prev
        }, {})
      },
      label: 'Contain text',
      headerRow: (cell) =>
        cell.value ? <div className="bold">Contain text</div> : <div className="bold">Do not contain text</div>,
    },
  },
  width: 200,
  ...data,
})
