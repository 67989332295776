// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Richtext-___Richtext-module__draggablePart {
  min-height: 6px;
  width: 100%;
  cursor: row-resize;
  transition: all 0.1s;
  font-size: 14px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  margin-top: -3px;
  z-index: 10;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.app-components-Form-Richtext-___Richtext-module__draggablePart:hover {
  background-color: #c4c4c4;
}

.app-components-Form-Richtext-___Richtext-module__draggableIcon {
  font-size: 16px;
  height: 8px;
  color: lightgrey;
  margin-top: -8px;
}

.app-components-Form-Richtext-___Richtext-module__draggablePart:hover .app-components-Form-Richtext-___Richtext-module__draggableIcon {
  margin-top: -6px;
  color: #c4c4c4;
}

.app-components-Form-Richtext-___Richtext-module__editor {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Richtext/Richtext.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,8BAA8B;EAC9B,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".draggablePart {\n  min-height: 6px;\n  width: 100%;\n  cursor: row-resize;\n  transition: all 0.1s;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n  position: relative;\n  margin-top: -3px;\n  z-index: 10;\n  border-bottom-left-radius: 3px;\n  border-bottom-right-radius: 3px;\n}\n\n.draggablePart:hover {\n  background-color: #c4c4c4;\n}\n\n.draggableIcon {\n  font-size: 16px;\n  height: 8px;\n  color: lightgrey;\n  margin-top: -8px;\n}\n\n.draggablePart:hover .draggableIcon {\n  margin-top: -6px;\n  color: #c4c4c4;\n}\n\n.editor {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggablePart": `app-components-Form-Richtext-___Richtext-module__draggablePart`,
	"draggableIcon": `app-components-Form-Richtext-___Richtext-module__draggableIcon`,
	"editor": `app-components-Form-Richtext-___Richtext-module__editor`
};
export default ___CSS_LOADER_EXPORT___;
