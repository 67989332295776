// @flow
import React from 'react'
import { confirm, type Props } from './confirm'

export const confirmDelete = ({ title, ...modalProps }: Props): Promise<any> =>
  confirm({
    title: <div style={{ color: '#ffffff' }}>{title || 'Delete'}</div>,
    titleColor: '#E56D7A',
    validateLabel: 'Delete',
    validateLabelColor: '#E56D7A',
    ...modalProps,
  })
