/** @flow */
import React, { useEffect, useState } from 'react'
import uniqid from 'app/libs/uniqid.js'
import Widget from 'app/components/Widget/Widget.jsx'
import { ModulableTree } from 'app/components/ModulableTree/ModulableTree.jsx'
import { getActionObjectTypeIdentifier, functionsByContentType } from 'app/core/utils/contentTypesUtils'
import PaginatedComponent from 'app/components/PaginatedComponent/PaginatedComponent.jsx'
import resources from 'app/store/resources'
import { getStatusRequest } from 'app/store/resources/utils/getStatusRequest'
import { getResources } from 'app/store/selectors/getResources'
import { confirm } from 'app/components/Modal'
import { useSelector } from 'react-redux'
import type { User, Subscription, ID } from 'app/core/types'
import classes from './ManageSubscriptions.module.scss'

type PaginatedList = {
  count: number,
  ids: ID[],
  next: string | null,
  previous: string | null,
}

const subscriptionsPaginatedList = uniqid('subscriptions-')

export default function ManageSubscriptions(): React$Node {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { paginatedLists } = useSelector((state) => state.subscriptions)
  const paginatedList: PaginatedList = paginatedLists[subscriptionsPaginatedList]
  const [subscriptions, setSubscriptions] = useState<Array<Object>>([])
  const [count, setCount] = useState<number>(0)
  const user: User = useSelector((state) => state.user)

  useEffect(() => {
    const load = getStatusRequest('subscriptions', 'fetchAllUserSubscriptions')
    setIsLoading(load.pending)
    const getSubscriptions = Object.values(
      getResources(undefined, `subscriptions.paginatedLists.${subscriptionsPaginatedList}`),
    )
    setSubscriptions(getSubscriptions)
    if (paginatedList) setCount(paginatedList.count)
  }, [paginatedList])

  function onRequestNeeded(pageSize: number, currentPage: number) {
    resources.subscriptions.fetchAllUserSubscriptions(user.asset, {
      params: {
        queries: {
          page_size: pageSize,
          page: currentPage,
        },
      },
      paginatedList: subscriptionsPaginatedList,
    })
  }

  function onClickDeleteSubscription(subscription: Subscription) {
    const label: string = functionsByContentType[
      getActionObjectTypeIdentifier(subscription.targetType, subscription.verb)
    ].getFeedName(subscription.target)
    confirm({
      title: 'Unsubscribe',
      render: (
        <div>
          Do you want to unsubscribe to <b>{label}</b> ?
        </div>
      ),
      validateLabelColor: '#E56D7A',
      validateLabel: 'Unsubscribe',
      onValidate: () => {
        return resources.subscriptions.delete(subscription.id, { paginatedList: subscriptionsPaginatedList })
      },
    })
  }

  function renderSubscriptions(pageSize: number, currentPage: number) {
    const items = subscriptions.map((subscription: Subscription) => ({
      key: subscription.id,
      value: subscription,
      label: functionsByContentType[
        getActionObjectTypeIdentifier(subscription.targetType, subscription.verb)
      ].labelFeed(subscription.target),
      actions: [
        {
          key: 'delete',
          onClick: () => onClickDeleteSubscription(subscription),
        },
      ],
    }))

    return (
      <div style={{ overflowY: 'auto' }}>
        <ModulableTree items={items} noItemsLabel="No notifications" treeProps={{ classes }} />
      </div>
    )
  }

  return (
    <Widget style={{ margin: '0 auto', height: '100%', width: '100%' }}>
      <PaginatedComponent
        count={count}
        renderChildren={renderSubscriptions}
        isLoading={isLoading}
        onRequestNeeded={onRequestNeeded}
      />
    </Widget>
  )
}
