import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo, useState } from 'react';
import Widget from 'app/components/Widget/Widget.jsx';
import { Table } from 'app/components/Table';
import { CellText, CellLink } from 'app/components/Table/Cells';
import { MUIButton, FormData } from 'app/components/Form';
import { ModalTrigger, ModalConfirmForm, confirmDelete } from 'app/components/Modal';
import vars from 'app/styles/vars.js';
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx';
import { MUIModal } from 'app/components/Modal/MUIModal.jsx';
import { Filters } from './Filters';
import resources from 'app/store/resources';
const tableId = 'table-ovmTokens';
const TokensManagement = () => {
    const [openModalKeyInfos, setOpenModalKeyInfos] = useState(false);
    const [newToken, setNewToken] = useState();
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const [currentKey, setCurrentKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        Promise.resolve(resources.ovmTokens.fetchAll()).then(() => {
            setIsLoading(false);
        });
    }, []);
    function onDelete(row, instance) {
        const { reloadData } = instance.getLastestInstance();
        confirmDelete({
            title: 'Delete',
            render: (_jsxs("p", { children: ["Do you want to delete ", _jsx("b", { children: row.original.user.assetInst.name }), " ?"] })),
            onValidate: () => {
                return resources.ovmTokens.delete(row.original.id).then(() => reloadData());
            },
        });
    }
    const columns = useMemo(() => {
        return [
            {
                Header: 'All tokens',
                id: 'rows',
                columns: [
                    CellText({
                        id: 'username',
                        readOnly: true,
                        Header: 'User name',
                        accessor: 'user.username',
                        sortingKey: 'user__username',
                        searchFilterLabel: 'User name',
                    }),
                    CellText({
                        id: 'keyHint',
                        readOnly: true,
                        Header: 'Key Hint',
                        accessor: 'keyHint',
                    }),
                    CellText({
                        id: 'created',
                        readOnly: true,
                        Header: 'Created date',
                        inputProps: {
                            type: 'datetime',
                        },
                        accessor: 'created',
                        width: 400,
                    }),
                    CellText({
                        id: 'expiration',
                        Header: 'Expiration date',
                        inputProps: {
                            type: 'datetime',
                        },
                        sortingKey: 'expiration',
                        searchFilterLabel: 'Expiration date',
                        accessor: 'expiration',
                        actions: () => ['edit'],
                        save: {
                            resource: 'ovmTokens',
                            saveData: (item, value) => {
                                return resources.ovmTokens.update({ id: item.id, expiration: value });
                            },
                        },
                        width: 400,
                        description: 'expirationDate',
                    }),
                    CellLink({
                        id: 'delete',
                        readOnly: false,
                        Header: 'Delete',
                        onClick: (row, event, instance) => onDelete(row, instance),
                        width: 80,
                        accessor: () => _jsx(MUIButton, { icon: "trash", bgColor: vars.colors.red }),
                    }),
                ],
            },
        ];
    }, []);
    const modal = (instance) => (_jsx(ModalTrigger, { modal: _jsx(ModalConfirmForm, { title: "Add token", children: _jsx(FormData, { onSave: (data) => {
                    const { reloadData } = instance.getLastestInstance();
                    resources.ovmTokens
                        .create({ asset: data.asset.value, expiration: data.expiration })
                        .then((res) => setCurrentKey(res.resources[0].token))
                        .then(() => reloadData());
                    setNewToken(data);
                    setOpenModalKeyInfos(true);
                    return Promise.resolve();
                }, defaultData: {
                    asset: {},
                    expiration: new Date(),
                }, properties: [
                    {
                        key: 'asset',
                        label: 'User name',
                        type: 'autocomplete',
                        element: AssetsSelect,
                        elementProps: (data, setData) => ({
                            assetTypes: ['us'],
                            placeholder: 'Select user',
                            showAssetType: true,
                            value: data.asset || {},
                            onChange: (value) => setData({ asset: { value: value.value, label: value.label } }),
                        }),
                    },
                    {
                        key: 'expiration',
                        label: 'Expiration Date',
                        type: 'dateTime',
                        elementProps: {
                            isRequired: true,
                        },
                    },
                ] }) }), children: _jsx(MUIButton, { icon: "fas-plus", children: "Add Token" }) }));
    const resourcesParams = useMemo(() => {
        if (isLoading)
            return null;
        return {
            resourceType: 'ovmTokens',
            requestName: 'fetchAll',
        };
    }, [isLoading]);
    const filters = useMemo(() => {
        if (!isLoading)
            setFiltersLoaded(true);
        return new Filters({ columns }).getFilters();
    }, [columns, isLoading]);
    return (_jsxs(Widget, { style: { height: '100%', width: '100%' }, children: [_jsx("div", { style: { flex: 1, display: 'flex', width: '100%', height: '100%' }, children: _jsx(Table, { resourcesParams: resourcesParams, tableId: tableId, extendToolbar: modal, columns: columns, isLoading: isLoading && !filtersLoaded, filters: filters }) }), openModalKeyInfos && (_jsxs(MUIModal, { hideCancel: true, open: openModalKeyInfos, title: `${newToken === null || newToken === void 0 ? void 0 : newToken.asset.label.toUpperCase()} new token`, onValidate: () => Promise.resolve(), onRequestClose: () => {
                    setNewToken(undefined);
                    setCurrentKey(null);
                    setOpenModalKeyInfos(false);
                }, children: [_jsxs("p", { children: ["The new token for ", _jsx("strong", { children: newToken === null || newToken === void 0 ? void 0 : newToken.asset.label }), " has been created."] }), _jsx("p", { children: "Please, make sure you save it. After closing this window, you won't be able to access it again." }), _jsx("br", {}), _jsxs("p", { children: ["Token : ", _jsx("strong", { children: currentKey })] })] }))] }));
};
export default TokensManagement;
