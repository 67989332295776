var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useState } from 'react';
import { MUIModal } from 'app/components/Modal';
import { UploadFilesPreviews } from 'app/components/Medias/UploadFilesModal.jsx';
export function ThumbnailModal(props) {
    const { onSave, title, defaultMedia, setMedia } = props, rest = __rest(props, ["onSave", "title", "defaultMedia", "setMedia"]);
    const [files, setFiles] = useState(defaultMedia ? [defaultMedia] : []);
    function _onValidate() {
        return onSave(files);
    }
    const handlePaste = (evt) => __awaiter(this, void 0, void 0, function* () {
        const clipboardItems = evt.clipboardData.items;
        const items = [].slice.call(clipboardItems).filter(function (item) {
            return /^image\//.test(item.type);
        });
        if (items.length === 0) {
            return;
        }
        const item = items[0];
        const blob = item.getAsFile();
        if (blob) {
            const url = URL.createObjectURL(blob);
            let image = new File([blob], blob.name, {
                type: 'image/jpeg',
                lastModified: new Date().getTime(),
            });
            image.url = url;
            image.path = blob === null || blob === void 0 ? void 0 : blob.name;
            let container = new DataTransfer();
            container.items.add(image);
            setFiles([container.files[0]]);
        }
    });
    return (_jsx(MUIModal, Object.assign({ onPaste: handlePaste, title: title, onValidate: _onValidate, validateLabel: "Save", resizable: true, draggable: true }, rest, { children: _jsx(UploadFilesPreviews, { multiple: false, setFiles: setFiles, files: files, accept: "image/*", allowPinMedia: false, allowValidateMedia: false, setMedia: setMedia }) })));
}
