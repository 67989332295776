/** @flow */
import React, { useState } from 'react'
import type { ResourcesList, ProgressionStatus, ID, Step } from 'app/core/types'
import { ToggleButtonGroup, ToggleButton } from 'app/components/Form'
import type { CellInstance } from '../../types'
import { Container } from './Container.jsx'
import { DisplayDefault } from './DisplayDefault.jsx'
import { DisplayStatus } from './DisplayStatus.jsx'
import { DisplayAssignedUsers } from './DisplayAssignedUsers.jsx'
import { DisplayEstimations } from './DisplayEstimations.jsx'

type Props = {|
  instance: CellInstance,
  progressionStatus: ResourcesList<ProgressionStatus>,
  step: ID,
  steps: ResourcesList<Step>,
  columnName: string,
|}

export function CellStatsStep(props: Props): React$Node {
  const { instance, progressionStatus, step, steps, columnName } = props
  const [tab, setTab] = useState(0)

  if (!instance || !instance.stats) return <div />

  const {
    stats,
    filtersTools,
    pagination: { totalRowsCount },
    tableId,
  } = instance
  const stepStats = stats[`step_${step}`]
  const { users__counts, status_counts = [], takes, activities, tasks } = stepStats || {}

  const emptyStatusNumber = status_counts.reduce((a, n) => a - n.count, totalRowsCount)

  return (
    <Container className="flex column fullHeight relative">
      <div style={{ maxWidth: '100%', overflowX: 'auto', marginBottom: 10 }}>
        {/* prettier-ignore */}
        <ToggleButtonGroup style={{ paddingBottom: 10}}>
          <ToggleButton onClick={() => setTab(0)} selected={tab === 0}>Status</ToggleButton>
          <ToggleButton onClick={() => setTab(1)} selected={tab === 1}>Activities</ToggleButton>
          <ToggleButton onClick={() => setTab(2)} selected={tab === 2}>Tasks</ToggleButton>
          <ToggleButton onClick={() => setTab(3)} selected={tab === 3}>Takes</ToggleButton>
          <ToggleButton onClick={() => setTab(4)} selected={tab === 4}>Users</ToggleButton>
          <ToggleButton onClick={() => setTab(5)} selected={tab === 5}>Estim</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div style={{ height: 'calc(100% - 50px)' }} className="YscrollAuto XscrollAuto">
        {tab === 0 ? (
          <DisplayStatus
            tableId={tableId}
            statusList={[...status_counts, { status: null, count: emptyStatusNumber }]}
            progressionStatus={progressionStatus}
            setFilters={filtersTools?.setUrlFilters}
            columnName={columnName}
          />
        ) : null}
        {tab === 1 ? (
          <>
            <DisplayDefault tableId={tableId} stats={activities} />
            <DisplayAssignedUsers
              tableId={tableId}
              users={users__counts}
              setFilters={filtersTools?.setUrlFilters}
              columnName={columnName ? `${columnName} - assigned user` : undefined}
              hiddenColumns={[1, 2, 3, 4, 5, 6]}
            />
          </>
        ) : null}
        {tab === 2 ? (
          <DisplayDefault
            tableId={tableId}
            stats={tasks}
            updateFields={(entry) => {
              if (typeof entry === 'object') {
                if (entry.step_id) {
                  return {
                    Step: steps[entry.step_id]?.name || '?',
                    count: entry.count,
                  }
                }
                if (entry.asset__parent__name) {
                  return {
                    'Parent name': entry.asset__parent__name,
                    'Asset type': entry.asset__parent__assetType,
                    count: entry.count,
                  }
                }
              }
              return entry
            }}
          />
        ) : null}
        {tab === 3 ? (
          <DisplayDefault
            tableId={tableId}
            stats={takes}
            updateFields={(entry) => {
              if (typeof entry === 'object' && entry.fTakes__number) {
                return {
                  'Take number': entry.fTakes__number,
                  count: entry.count,
                }
              }
              return entry
            }}
          />
        ) : null}
        {tab === 4 ? (
          <div style={{ height: 'calc(100% - 5px)' }}>
            <DisplayAssignedUsers
              tableId={tableId}
              users={users__counts}
              setFilters={filtersTools?.setUrlFilters}
              columnName={columnName ? `${columnName} - assigned user` : undefined}
              hiddenColumns={[7]}
            />
          </div>
        ) : null}

        {tab === 5 ? (
          <DisplayEstimations
            users={users__counts}
            progressionStatus={progressionStatus}
            columnName={columnName}
            setFilters={filtersTools?.setUrlFilters}
            tableId={tableId}
          />
        ) : null}
      </div>
    </Container>
  )
}
