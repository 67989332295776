// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

  .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__editionContainer {
    position: absolute;
    height: 40%;
    width: 12px;
    display: inline-block;
    z-index: 2;
  }

  .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__editionContainer[position='left'] {
      cursor: w-resize;
      left: -8px;
    }

  .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__editionContainer[position='right'] {
      cursor: e-resize;
      right: -8px;
    }

  .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__editionContainer[position='earliest'],
    .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__editionContainer[position='latest'] {
      height: calc(100% + 10px);
      width: 3px;
      background-color: black;
      cursor: ew-resize;
    }

  .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__editionContainer[position='earliest'] {
      left: -1.5px;
    }

  .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__editionContainer[position='latest'] {
      right: -1.5px;
    }

  .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__editionContainer .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__button {
      height: 100%;
      width: 100%;
    }

  .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container .app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__disabled {
    cursor: default !important;
    pointer-events: none;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/ColumnTimeline/TimelineResizer.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AA0CpB;;EAxCE;IACE,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,qBAAqB;IACrB,UAAU;EA8BZ;;EA5BE;MACE,gBAAgB;MAChB,UAAU;IACZ;;EACA;MACE,gBAAgB;MAChB,WAAW;IACb;;EAEA;;MAEE,yBAAyB;MACzB,UAAU;MACV,uBAAuB;MACvB,iBAAiB;IACnB;;EAEA;MACE,YAAY;IACd;;EACA;MACE,aAAa;IACf;;EAEA;MACE,YAAY;MACZ,WAAW;IACb;;EAEF;IACE,0BAA0B;IAC1B,oBAAoB;EACtB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  position: relative;\n\n  .editionContainer {\n    position: absolute;\n    height: 40%;\n    width: 12px;\n    display: inline-block;\n    z-index: 2;\n\n    &[position='left'] {\n      cursor: w-resize;\n      left: -8px;\n    }\n    &[position='right'] {\n      cursor: e-resize;\n      right: -8px;\n    }\n\n    &[position='earliest'],\n    &[position='latest'] {\n      height: calc(100% + 10px);\n      width: 3px;\n      background-color: black;\n      cursor: ew-resize;\n    }\n\n    &[position='earliest'] {\n      left: -1.5px;\n    }\n    &[position='latest'] {\n      right: -1.5px;\n    }\n\n    .button {\n      height: 100%;\n      width: 100%;\n    }\n  }\n  .disabled {\n    cursor: default !important;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__container`,
	"editionContainer": `app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__editionContainer`,
	"button": `app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__button`,
	"disabled": `app-components-Table-Cells-ColumnTimeline-___TimelineResizer-module__disabled`
};
export default ___CSS_LOADER_EXPORT___;
