import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { isNumber } from 'lodash';
import { getCustomRange, getDate } from '../../utils.js';
export const groupingFns = (type) => {
    const value = {
        fn: (rows, columnId, instance) => {
            return rows.reduce((prev, row, i) => {
                const resKey = String(row.values[columnId]);
                prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : [];
                prev[resKey].push(row);
                return prev;
            }, {});
        },
        label: 'Value',
        headerRow: (cell) => {
            if (!cell.value)
                return null;
            return _jsx("div", { className: "flex alignCenter fullHeight", children: cell.value });
        },
    };
    const range = (divisor) => {
        return {
            fn: (rows, columnId, instance) => {
                return rows
                    .sort((prev, row) => {
                    if (row.values[columnId] === undefined)
                        return -2;
                    if (prev.values[columnId] < row.values[columnId])
                        return -1;
                    if (prev.values[columnId] > row.values[columnId])
                        return 1;
                    return 0;
                })
                    .reduce((prev, row, i) => {
                    const resKey = String(isNumber(row.values[columnId]) ? getCustomRange(row.values[columnId], divisor) : undefined);
                    prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : [];
                    prev[resKey].push(row);
                    return prev;
                }, {});
            },
            label: `Range by ${String(divisor)}`,
            headerRow: (cell) => {
                return isNumber(cell.value) ? _jsx("div", { className: "bold", children: getCustomRange(cell.value, divisor) }) : null;
            },
        };
    };
    const date = {
        fn: (rows, columnId, instance) => {
            return rows
                .sort((prev, row) => {
                if (prev.values[columnId] < row.values[columnId])
                    return -1;
                if (prev.values[columnId] > row.values[columnId])
                    return 1;
                return 0;
            })
                .reduce((prev, row, i) => {
                const resKey = String(row.values[columnId] ? getDate(row.values[columnId]) : undefined);
                prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : [];
                prev[resKey].push(row);
                return prev;
            }, {});
        },
        label: `Age`,
        headerRow: (cell) => {
            return cell.value ? _jsx("div", { className: "bold", children: getDate(cell.value) }) : null;
        },
    };
    switch (type) {
        case 'number':
            return {
                value,
                range1: range(1),
                range5: range(5),
                range10: range(10),
                range50: range(50),
                range100: range(100),
                range1000: range(1000),
                range10000: range(10000),
            };
        case 'date':
            return {
                value,
                date,
            };
        case 'time':
            return {
                value,
            };
        case 'url':
        case 'ip':
        case 'email':
        case 'text':
        default:
            return {
                value,
            };
    }
};
