// @flow
import * as React from 'react'
import cx from 'classnames'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { type IconType } from 'app/core/types'
import { Permission } from 'app/containers/Permissions'
import classes from './NavItem.module.scss'

type Props = {
  label?: string,
  icon?: IconType | React.Element<any>,
  permissions?: Array<string>,
}

export class NavSubTitle extends React.PureComponent<Props> {
  renderIcon(): React$Node {
    const { icon } = this.props

    if (typeof icon === 'string')
      return (
        <div className={classes.iconContainer}>
          <FontIcon icon={icon} />
        </div>
      )
    if (icon) return icon
    return null
  }

  render(): React$Node {
    const { permissions, label, ...props } = this.props

    return (
      <Permission actions={permissions} operator="or">
        {/* $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating
         * flow */}
        <div className={cx(classes.container, classes.subTitleContainer)} {...props}>
          {this.renderIcon()}
          <div className={classes.titleDiv}>
            <div className={classes.inner}>{label}</div>
          </div>
        </div>
      </Permission>
    )
  }
}
