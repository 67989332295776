import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo, useState } from 'react';
import { map, filter, find, forEach } from 'lodash';
import history from 'app/main/routerHistory';
import resources from 'app/store/resources';
import { CellThumbnail, CellFlags, CellRichText, CellText, CellLink, CellMedias, CellSelect, } from 'app/components/Table/Cells';
import { Table } from 'app/components/Table/Table.jsx';
import { getAssetsAttributValues } from 'app/store/selectors/getAssetsAttributValues';
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import { accessorAssetMediaGroups, saveAssetMediasGroups } from 'app/components/Table/Cells/CellMedias/utils.js';
import { getPostBoardLinksModels } from 'app/store/selectors/getPostBoardLinksModels';
import { getResources } from 'app/store/selectors/index.js';
import { createUpdatingFlagsPromises } from 'app/components/Form/Flags/utils';
import { postBoardLinkTypes, postBoardTypes } from 'app/core/constants';
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL.js';
import { permission } from 'app/containers/Permissions/index.js';
import { router } from 'app/containers/Assets/AssetDetail/router';
import { confirmDelete, openModal } from 'app/components/Modal';
import { AssetHistoryModal } from 'app/containers/AssetHistory/AssetHistoryModal';
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx';
import { assetIcons } from 'app/components/Icons/assetsIcons.js';
import { ModalAddAssetToPostboard } from './ModalAddAssetToPostboard';
import { Filters } from './Filters';
import { createRecursiveRequestIfNoPostboardLink } from '../PostBoardShot/utils.js';
import { postboardLinksTypesOptions } from '../../../core/constants/postBoardLinkTypes';
export const tableId = 'PostBoardModels';
export const linkTypes = [
    { value: postBoardLinkTypes.create, label: 'New asset' },
    { value: postBoardLinkTypes.reut, label: 'Reused asset' },
    { value: postBoardLinkTypes.update, label: 'Updated asset' },
    { value: postBoardLinkTypes.deriv, label: 'Derivated from existing asset' },
];
const useResources = (episodeId, projectId, paginatedList) => {
    const [episode, setEpisode] = useState();
    const [categories, setCategories] = useState();
    const [project, setProject] = useState();
    const [links, setLinks] = useState();
    const [stepProjects, setStepProjects] = useState();
    const [notes, setNotes] = useState();
    useEffect(() => {
        const id = projectId || (episode === null || episode === void 0 ? void 0 : episode.project);
        if (id && id !== (project === null || project === void 0 ? void 0 : project.id)) {
            resources.assets.fetchOne(projectId || (episode === null || episode === void 0 ? void 0 : episode.project)).then((res) => {
                setProject(res.resources[0]);
            });
        }
    }, [projectId, episode]);
    useEffect(() => {
        function _getResources(episodeProject) {
            const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: episodeProject || '' } } };
            return Promise.all([
                resources.assetsCustomAttributValues.fetch('category', requestsConfig),
                episodeProject ? resources.stepProjects.fetchByProject(episodeProject, requestsConfig) : undefined,
            ]).then(() => {
                var _a;
                if (episodeId)
                    setEpisode(getResources(undefined, 'assets', episodeId));
                setCategories(((_a = getAssetsAttributValues(undefined, 'category')) === null || _a === void 0 ? void 0 : _a.values) || []);
                const resourcesStepProjects = getResources(undefined, 'stepProjects', { project: projectId }, ['stepInst']);
                const filteredStepsProjects = filter(resourcesStepProjects, (stepProject) => stepProject.stepInst.assetType === 'mo' &&
                    stepProject.display &&
                    stepProject.display.indexOf(tableId) !== -1);
                const _stepProjects = [{ stepInst: { id: undefined, name: 'General' } }, ...filteredStepsProjects];
                setStepProjects(_stepProjects);
                setNotes([
                    ..._stepProjects.map(({ stepInst }) => {
                        if (stepInst.name === 'general')
                            return { value: 'general', label: 'general' };
                        return { value: stepInst.id, label: stepInst.name };
                    }),
                ]);
            });
        }
        if (episodeId) {
            resources.assets.fetchOne(episodeId).then((res) => {
                var _a, _b;
                _getResources(((_b = (_a = res === null || res === void 0 ? void 0 : res.resources) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.project) || projectId);
            });
        }
        else
            _getResources(projectId);
    }, [episodeId]);
    useEffect(() => {
        if (episodeId && paginatedList) {
            const postboardLinks = getPostBoardLinksModels(undefined, paginatedList);
            if (postboardLinks)
                setLinks(map(postboardLinks));
        }
    }, [paginatedList, episodeId]);
    return { episode, stepProjects, categories, links, notes, project };
};
export function TablePostboardModels(props) {
    const { match } = props;
    const projectId = getProjectIdFromURL();
    const episodeId = props.episodeId || (match === null || match === void 0 ? void 0 : match.params.episodeId) || null;
    const [paginatedList, setPaginatedList] = useState();
    const { episode, stepProjects, categories, links, notes, project } = useResources(episodeId, projectId, paginatedList);
    useEffect(() => {
        if (episode)
            documentTitle(episode.name);
    }, [episode]);
    function getDisabledOptions(linkType) {
        switch (linkType) {
            case postBoardLinkTypes.create:
            case postBoardLinkTypes.deriv:
                return !permission(['projet_assets__create']); // && project?.attributes?.ovm_enableAssetCreationInMPN)
            case postBoardLinkTypes.reut:
                return false;
            case postBoardLinkTypes.update:
                return false;
            default:
                return true;
        }
    }
    const toggleButtons = (instance) => {
        const buttons = [];
        if (episode && permission(['wiki___read'])) {
            buttons.push({
                icon: 'fas-external-link-alt',
                onClick: () => router.goTo('wiki', { assetId: episode.id }, { rightPanel: true }),
                key: 'wiki',
                label: 'Wiki',
            });
        }
        if (permission(['projet_production notes_notes_create'])) {
            buttons.push({
                key: 'addAsset',
                disabled: !episode,
                icon: 'fas-plus',
                label: 'Add Asset',
                options: postboardLinksTypesOptions.map(({ label, value: linkType }) => {
                    return {
                        key: linkType,
                        disabled: !episode || getDisabledOptions(linkType),
                        onClick: () => {
                            if (!episode)
                                return;
                            const { reloadData } = instance.getLastestInstance();
                            if (!project)
                                return;
                            openModal(_jsx(ModalAddAssetToPostboard, { episodeId: episode.id, paginatedList: paginatedList, project: project, onSave: () => reloadData(), linkType: linkType }));
                        },
                        label,
                    };
                }),
            });
        }
        return buttons;
    };
    const showHistory = (note) => () => {
        openModal(_jsx(AssetHistoryModal, { tableId: "assetsHistory", requestName: "history", resourceType: "postBoardNotes", projectId: projectId, columns: [
                {
                    Header: 'Notes',
                    id: 'notes',
                    columns: [
                        CellRichText({
                            id: 'note',
                            Header: 'Note',
                            accessor: 'actionObject.text',
                            readOnly: true,
                            width: undefined,
                        }),
                    ],
                },
            ], assetId: note.id, title: "Model note history" }));
    };
    const defaultActions = (item, stepInst) => {
        const note = find(item.notesInst, ({ step }) => step === stepInst.id || (step === null && !stepInst.id));
        if (!note)
            return [];
        return ['separator', { label: 'Show history', onClick: showHistory(note), editAction: false }];
    };
    const onDeletePostBoard = (linkId, instance) => {
        const link = links === null || links === void 0 ? void 0 : links.find(({ id }) => id === linkId);
        const { reloadData } = instance.getLastestInstance();
        if (link) {
            confirmDelete({
                title: 'Delete model in fabrication notes',
                render: (_jsxs("div", { children: ["Do you want to delete ", _jsx("b", { children: link.assetInst.name }), " ?"] })),
                validateLabel: 'Delete',
                validateLabelColor: '#E56D7A',
                onValidate: () => resources.postBoardLinks.delete(link.id, { paginatedList }).then(() => reloadData()),
            });
        }
    };
    const readOnly = !permission(['projet_production notes_notes_update']);
    const columns = useMemo(() => {
        if (!stepProjects)
            return [];
        return [
            {
                Header: 'Assets',
                id: 'assets',
                columns: [
                    CellLink({
                        Header: 'Asset',
                        id: 'asset',
                        fixed: 'left',
                        fixable: false,
                        showRemoved: true,
                        accessor: 'assetInst.name',
                        readOnly,
                        sortingKey: 'asset__name',
                        onClick: (row) => router.goTo('index', { assetId: row.original.assetInst.id }, { rightPanel: true }),
                        width: 300,
                    }),
                    CellLink({
                        id: 'delete',
                        Header: 'Delete',
                        hidden: !permission(['projet_production notes_notes_delete']),
                        accessor: () => _jsx(FontIcon, { icon: "trash" }),
                        onClick: (row, event, instance) => onDeletePostBoard(row.original.id, instance),
                        fixable: true,
                        readOnly,
                        hiddenable: true,
                        width: 50,
                    }),
                    CellThumbnail({
                        id: 'thumbnail',
                        Header: 'Thumbnail',
                        accessor: (item) => item.assetInst.thumbnailInst,
                        fixable: true,
                        readOnly,
                        hiddenable: true,
                        width: 150,
                        actions: () => ['edit', 'copy', 'past', 'delete'],
                        save: {
                            resource: 'assets',
                            attribute: 'thumbnail',
                            formatData: (item, value, cell, instance, type) => {
                                return {
                                    id: item.assetInst.id,
                                    thumbnail: (value === null || value === void 0 ? void 0 : value.id) || null,
                                };
                            },
                        },
                    }),
                    CellText({
                        Header: 'Category',
                        id: 'category',
                        actions: () => [],
                        fixable: true,
                        readOnly,
                        hiddenable: true,
                        accessor: 'assetInst.attributes.category',
                        width: 120,
                    }),
                    CellSelect({
                        Header: 'Type',
                        id: 'linkType',
                        fixable: true,
                        readOnly,
                        hiddenable: true,
                        actions: () => ['edit'],
                        accessor: (item) => {
                            if (!item)
                                return '';
                            const { linkType } = item;
                            if (!linkType)
                                return '';
                            const linkTypeValue = linkTypes.find(({ value }) => value === linkType);
                            return linkTypeValue ? linkTypeValue.value : '';
                        },
                        options: () => linkTypes,
                        save: {
                            resource: 'postBoardLinks',
                            formatData: (item, value, cell, instance, type) => ({
                                id: item.id,
                                linkType: value,
                            }),
                        },
                    }),
                    CellFlags({
                        id: 'flags',
                        Header: 'Flags',
                        width: 200,
                        accessor: 'assetInst.assetFlagsInst',
                        fixable: true,
                        readOnly,
                        hiddenable: true,
                        actions: () => ['edit', 'copy', 'past', 'delete'],
                        foreignKey: 'asset',
                        getResourceID: (cell) => cell.row.original.assetInst.id,
                        category: (cell) => { var _a; return (_a = cell.row.original.assetInst) === null || _a === void 0 ? void 0 : _a.assetType; },
                        save: {
                            resource: 'assets',
                            attribute: 'assetFlagsInst',
                            formatData: (item, value, cell, instance, type) => {
                                if (type === 'delete')
                                    value = [];
                                const newFlags = map(value, ({ flagInst }) => ({ asset: item.assetInst.id, flag: flagInst.id }));
                                return createUpdatingFlagsPromises(newFlags, map(item.assetInst.assetFlagsInst), 'assetFlags', type);
                            },
                        },
                    }),
                    CellMedias({
                        Header: 'Refs',
                        id: 'medias',
                        fixable: true,
                        readOnly,
                        hiddenable: true,
                        accessor: (item) => {
                            if (!item)
                                return null;
                            const { assetInst } = item;
                            const { assetMediaGroupsInst, id } = assetInst;
                            return accessorAssetMediaGroups(assetMediaGroupsInst, [
                                postBoardTypes.postBoardModels,
                                `${postBoardTypes.postBoardModels}_${id}`,
                            ]);
                        },
                        actions: () => ['edit', 'delete'],
                        allowPinMedia: true,
                        allowValidateMedia: true,
                        save: {
                            resource: 'medias',
                            formatData: (item, value, cell, instance, type) => {
                                if (!item)
                                    return null;
                                const { assetInst } = item;
                                const { assetMediaGroupsInst, id } = assetInst;
                                const groupMediaName = `${postBoardTypes.postBoardModels}_${id}`;
                                let mediasGroup;
                                forEach(assetMediaGroupsInst, (assetMediaGroup) => {
                                    if (assetMediaGroup.mediaGroupInst && assetMediaGroup.mediaGroupInst.name === groupMediaName) {
                                        mediasGroup = assetMediaGroup.mediaGroupInst;
                                    }
                                });
                                return saveAssetMediasGroups(value, groupMediaName, item.assetInst.id, mediasGroup, type, cell.value);
                            },
                        },
                    }),
                ],
            },
            {
                Header: 'Production Notes',
                id: 'notes',
                headerColor: '#EEB413',
                columns: [
                    ...stepProjects.map(({ stepInst }) => {
                        return CellRichText({
                            Header: stepInst.name,
                            fixable: true,
                            readOnly,
                            hiddenable: true,
                            actions: (instance, cell) => [
                                'edit',
                                'copy',
                                'past',
                                'delete',
                                ...defaultActions(cell.row.original, stepInst),
                            ],
                            accessor: (item) => {
                                if (!item)
                                    return '';
                                const note = find(item.notesInst, ({ step }) => step === stepInst.id || (step === null && !stepInst.id));
                                return note && note.text ? note.text : '';
                            },
                            id: stepInst.id || 'general',
                            width: 300,
                            save: {
                                resource: 'postBoardNotes',
                                formatData: (item, value, cell, instance, type) => {
                                    const { assetInst, id, notesInst } = item;
                                    const postboardLink = find(assetInst.postBoardLinksInst, (p) => p.id === id);
                                    if (!postboardLink) {
                                        return createRecursiveRequestIfNoPostboardLink(item, undefined, value, !stepInst.id ? null : stepInst);
                                    }
                                    const note = find(notesInst, ({ step }) => step === stepInst.id || (step === null && !stepInst.id));
                                    if (!note) {
                                        return {
                                            link: postboardLink && postboardLink.id,
                                            text: value,
                                            step: stepInst.id,
                                        };
                                    }
                                    return {
                                        id: note.id,
                                        text: value,
                                    };
                                },
                            },
                        });
                    }),
                ],
            },
        ];
    }, [stepProjects, links, paginatedList, categories]);
    const resourcesParams = useMemo(() => {
        if (!episode)
            return undefined;
        return {
            resourceType: 'postBoardLinks',
            requestName: 'fetchAllAndStoreDependentInsts',
            headers: episode.project ? { [window.OVM_PROJECT_HEADER]: episode.project || '' } : {},
            requestData: episode.id,
            queries: {
                asset__assetType: 'mo',
            },
            includedResources: [
                'assetInst',
                'assetInst.thumbnailInst',
                'assetInst.assetFlagsInst.flagInst',
                'assetInst.postBoardLinksInst',
                'assetInst.assetMediaGroupsInst.mediaGroupInst.mediasInst',
                'notesInst',
            ],
        };
    }, [episode]);
    const filters = useMemo(() => new Filters({ linkTypes, categories: categories || [], notes: notes || [] }).getFilters(), [linkTypes, categories, notes]);
    if (!stepProjects || !project)
        return null;
    return (_jsx("div", { className: "fullHeight", children: _jsx(Table, { columns: columns, tableId: tableId, exportExcelFormat: episode ? { assetId: episode.id, data: {}, type: 'postBoardModels' } : undefined, filters: filters, getPaginatedList: (requestKey) => setPaginatedList(requestKey), toggleButtons: toggleButtons, resourcesParams: episode ? resourcesParams : undefined, rowExpander: true, extendToolbar: !props.episodeId ? (_jsx(AssetsSelect, { assetTypes: ['ep'], onChange: (episode) => {
                    if (projectId)
                        history.push(`/projects/${projectId}/postboard-models/${episode.value}`);
                }, placeholder: "Search asset", style: { width: '100%', minWidth: 200, background: '#ffffff' }, showAssetType: true, showAssetParent: true, value: episode ? { label: episode.name, value: episode.id, icon: assetIcons(episode.assetType) } : null })) : undefined, defaultPrefs: {
                minLineHeight: 70,
            } }) }));
}
