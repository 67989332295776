// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___getContractAttributeNode-module__listItemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___getContractAttributeNode-module__attributeLabel {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./app/pages/HR/HRSettings/PeopleTableEditor/ContractAttributesEditor/getContractAttributeNode.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".listItemContainer {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.attributeLabel {\n  width: 200px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItemContainer": `app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___getContractAttributeNode-module__listItemContainer`,
	"attributeLabel": `app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___getContractAttributeNode-module__attributeLabel`
};
export default ___CSS_LOADER_EXPORT___;
