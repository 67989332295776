// @flow
import * as React from 'react'
import { useMemo } from 'react'
import type { AssetTypes } from 'app/core/types'
import { NODE_DIRECTORY, NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import type { SchemaColumnSettings } from 'app/core/types/Schema'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'
import classes from './SettingsFollowUp.module.scss'

type Props = {|
  assetType: ?AssetTypes,
|}

type AssetDragItem = {|
  name: string,
  type: string,
  attr?: string,
  columnType: string,
  category?: ?AssetTypes,
  aggregateType?: string,
  aggregateDatas?: Object,
|}

export function Asset(props: Props): React$Node {
  const { assetType } = props

  const assets = useMemo(() => {
    const assets: AssetDragItem[] = [
      { name: 'Thumbnail', type: 'assets', attr: 'thumbnailInst', columnType: 'asset thumbnail' },
      { name: 'Asset type', type: 'assets', attr: 'assetType', columnType: 'asset type' },
      { name: 'Flags', type: 'assets', attr: 'assetFlagsInst', columnType: 'asset flags' },
      { name: 'Asset parent', type: 'assets', attr: 'parentInst', columnType: 'asset parent' },
    ]

    if (['fo', 'ep'].includes(assetType)) {
      assets.push({
        name: 'aggregated flags',
        columnType: 'aggregated flags',
        parentType: 'assets',
        category: assetType,
        type: 'aggregation',
        aggregateType: 'flag',
        aggregateDatas: {},
      })
    }
    return assets
  }, [assetType])

  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: assets.map((assetAttr) => {
        return new Node<$Shape<SchemaColumnSettings<AssetDragItem>>>({
          key: assetAttr.name,
          name: assetAttr.name,
          parent: rootNode,
          data: { ...assetAttr, type: assetAttr.type ?? 'assets' },
          icon: 'fas-grip-vertical',
          type: NODE_ITEM,
        })
      }),
    })
    return rootNode
  }, [assets.length])

  return (
    <div className={classes.AttributesBlockContainer} style={{ padding: '0 10px 10px 10px' }}>
      <TreeDnD rootNode={rootNode} />
    </div>
  )
}
