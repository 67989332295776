/**  */
import cookies from 'js-cookie'
import { cloneDeep, isPlainObject, reduce } from 'lodash'
import { stringify } from 'query-string'
import settings from 'app/core/settings'
import { error as postError } from 'app/components/Notifications/notify'
import { easingTimeout } from 'app/components/Loader/utils'

import Authorization from './Authorization'
import { methods } from './methods'
import { idToUuid, uuidToId } from './replaceKey.js'

export class ServerError extends Error {
  constructor(error) {
    const message = `Server Error: ${error.status}`
    super(message)
    this.serverError = error
    this.name = `ServerError`
  }
}

export const sameFilterMultipleSeparator = '[#$%]' // urlEscaped = %5B%23%24%25%5D
const parseQueriesStrings = (url) => url.replace(/[0-9]*%5B%23%24%25%5D/g, '')

export const http = {
  /**
   * @param string method
   * @param string url
   * @param object data
   * @param object headers
   *
   * @return Promise
   */
  request(args) {
    let { method, url, headers = {}, data = null } = args
    const { queries = null, getHttpProgress, requestController } = args

    const removeOVMProjectFromHeader = headers?.[window.OVM_PROJECT_HEADER] === null

    headers = {
      ...Authorization.getAuthHeaders(),
      ...headers,
    }

    if (removeOVMProjectFromHeader) delete headers[window.OVM_PROJECT_HEADER]

    url = this._parseURL(url, data)

    if (queries) {
      const stringifiedQueries = stringify(queries)
      url = `${url}?${parseQueriesStrings(stringifiedQueries)}`
    }

    if (isPlainObject(data) || Array.isArray(data)) {
      data = idToUuid(data)
    }

    let body = null

    if ([methods.POST, methods.PUT, methods.PATCH].indexOf(method) !== -1) {
      headers['Content-Type'] = 'application/json'
      body = data ? JSON.stringify(data) : null
    }

    if ([methods.FILE].indexOf(method) !== -1) {
      body = data
      method = methods.POST
    }

    let percentageLoaded = 0
    let timeoutLoaded = 0

    const simulateDataCharging = (callback, times) => {
      return easingTimeout(() => {
        if (timeoutLoaded < 50) {
          timeoutLoaded += 1
          callback(timeoutLoaded + percentageLoaded)
        }
      }, 20)
    }

    const timeoutToClear = getHttpProgress ? simulateDataCharging(getHttpProgress, 50) : undefined

    const interceptRequestProgress = (response) => {
      if (String(response.status)[0] !== '2') throw response

      const reader = response.body.getReader()
      const contentLength = +response.headers.get('Content-Length')
      const unit = contentLength / 100
      let loadedLength = 0

      return new Response(
        new ReadableStream({
          start(controller) {
            function pump() {
              return reader
                .read()
                .then(({ done, value }) => {
                  if (getHttpProgress && !done) {
                    loadedLength += value.length
                    percentageLoaded = Math.round(loadedLength / unit)
                    getHttpProgress(percentageLoaded > 100 ? 100 : percentageLoaded)
                  }

                  if (done) {
                    if (timeoutToClear) timeoutToClear()
                    controller.close()
                    return undefined
                  }

                  controller.enqueue(value)
                  return pump()
                })
                .catch((error) => {
                  if (timeoutToClear) timeoutToClear()
                  return this._handleError(error)
                })
            }
            return pump()
          },
        }),
      )
    }

    return fetch(url, {
      ...requestController,
      method,
      mode: 'cors',
      headers: new Headers(headers),
      body,
    })
      .then(getHttpProgress ? interceptRequestProgress : (_) => _)
      .then((response) => this._handleResponse(response))
      .then((data) => uuidToId(data))
      .catch((error) => {
        if (typeof error.json === 'function') {
          return error
            .json()
            .catch(() => {
              throw this._handleError(error)
            })
            .then((message) => {
              Object.assign(error, {
                infos: message,
                json: null,
              })
              return this._handleError(error, message)
            })
        }

        return this._handleError(error, error.infos)
      })
  },

  _postErrorMessage(error, message) {
    let errorMessage

    if (error.status === 200) errorMessage = 'Failed to authentificate.'
    if (error.status === 403) errorMessage = 'You are not authorized.'
    if (error.status === 404) errorMessage = 'Resource not found.'
    if (error.status === 409) errorMessage = 'Conflict.'
    if (error.status >= 400 && error.status < 500) errorMessage = `Error${message ? ': ' : ''}`
    if (error.status >= 500) errorMessage = 'A server error has occurred.'

    if (message) {
      const joinErrorMessages = (msg) => {
        if (typeof msg === 'string') errorMessage = `${errorMessage}\n\n${msg}`
        if (typeof msg === 'object') {
          errorMessage = `${errorMessage}\n\n${reduce(
            msg,
            (acc, err, errName) => `${acc}\n${String(errName)}: ${String(err)}`,
            '',
          )}`
        }
      }

      if (Array.isArray(message)) message.forEach(joinErrorMessages)
      else joinErrorMessages(message)
    }

    postError(errorMessage)
  },

  _handleError(error, message) {
    this._postErrorMessage(error, message)

    if (error.name === 'AbortError') return null

    if (error.status) {
      if (error.statusText) console.error(error.statusText)

      // Authentication error (error.status === 200)
      if (error.status === 200) return null

      if (error.status === 401) {
        const { pathname, origin } = window.location
        if (pathname === '/authentication/login') return null
        cookies.remove(settings.cookieToken)
        const path = `/authentication/login?redirect=${pathname}`
        window.location.href = `${origin}${path}`
        return null
      }

      throw new ServerError(error)
    }

    return null
  },

  _handleResponse(response) {
    /**
     * Success or error with message
     */
    if ([200, 201].indexOf(response.status) !== -1) {
      return response.json().then((json) => {
        return response.ok ? json : null
      })
    }
    if ([204].indexOf(response.status) !== -1) {
      /**
       * No Content
       */
      return null
    }
    if (typeof response.status === 'number' && response.status >= 400) {
      return Promise.reject(response)
    }

    return null
  },

  _parseURL(str, data = {}) {
    const matched = str.match(/({[^}]+})/g)

    if (!matched) return str

    data = cloneDeep(data)

    let output = str

    for (const match of matched) {
      const key = match.replace('{', '').replace('}', '').trim()

      if (data[key]) {
        output = output.replace(match, data[key])
        delete data[key]
      } else {
        throw new Error(`http._parseURL - key '${key}' is required. ${str}`)
      }
    }

    return output
  },
}

export default {
  methods,
  http,
}
