/* eslint-disable no-use-before-define */
/** @flow */
import { forEach, endsWith, flatten, isNaN, findLast, map, find } from 'lodash'
import moment from 'moment'
import { binaryOperators, gtOperators, ltOperators } from 'app/components/Table/TableView/Elements/Filters/utils.ts'
import { getOptionsValue } from 'app/core/utils/optionsPriority'
import resources from 'app/store/resources'
import type { ColumnFilter, ItemFilter, EditorType, OperatorFilter } from 'app/core/types'
import type { InputType } from 'app/core/types/Filter.js'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import { promiseAllProgress } from 'app/components/Loader/utils'
import { getResources } from 'app/store/selectors'
import { sameFilterMultipleSeparator } from 'app/core/api/http.js'

import { getURLParamsKey } from '../utils'
import { logicOperators } from '../TableView/Elements/Filters/logicOperators'
import type { ResourcesParams } from '../types'

const PROJECT_HEADER = window.OVM_PROJECT_HEADER

const getFromRequest = (id, type) => {
  if (!type) return Promise.resolve()
  return resources[type]
    .fetchOne(id)
    .then((res) => {
      if (res) return res.resources[0]
      return null
    })
    .catch((err) => {
      console.error(err)
      return null
    })
}

export function isAnAttributeOperator(operator: OperatorFilter): boolean {
  return operator.attr.includes('attributes__') || operator.attr.includes('personAttribute_')
}

export const splitFilters = (filters: ItemFilter[] | ItemFilter[][]): ItemFilter[][] => {
  const separatedFilters = []
  let currentFilter = []

  filters.forEach((filter, index) => {
    if (Array.isArray(filter)) {
      if (filter.length > 0) separatedFilters.push(filter)
      return
    }
    if (filter.type === 'logicOperator') {
      if (currentFilter.length > 0) separatedFilters.push(currentFilter)

      currentFilter = []
      separatedFilters.push([filter])
      return
    }
    if (filter.type === 'column' && index !== 0) {
      if (currentFilter.length > 0) separatedFilters.push(currentFilter)

      currentFilter = []
    }
    if (index === filters.length - 1) {
      currentFilter.push(filter)
      if (currentFilter.length > 0) separatedFilters.push(currentFilter)

      return
    }
    currentFilter.push(filter)
  })

  return separatedFilters
}

export function convertOldFiltersFormat(filters: ItemFilter[][]): ItemFilter[][] {
  if (filters.find((filter) => filter[0].type === 'bracket')) {
    const _filters = []

    for (let i = 0; i < filters.length; i += 1) {
      const filter = filters[i]

      if (filter[0].type === 'bracket') _filters.push(...filter[0].filters)
      else if (filter[0].type === 'logicOperator' && filter[0].value === '&') continue
      else _filters.push(filter)
    }

    return _filters
  }
  return filters
}

export function convertOldStringFiltersFormat(filters: ItemFilter[][]): ItemFilter[][] {
  if (filters.find((filter) => typeof filter[0] === 'object' && !Array.isArray(filter[0]))) {
    const _filters = []

    for (let i = 0; i < filters.length; i += 1) {
      const filter = filters[i]
      const isBracket = typeof filter[0] === 'object' && !Array.isArray(filter[0])

      // $FlowFixMe - [PATCH] faor old config. Suppress "{v: obj, f: function}" of bracket operator
      if (isBracket) _filters.push(...filter[0].f)
      else if (filter[0] === '&') continue
      else _filters.push(filter)
    }

    return _filters
  }
  return filters
}

export const urlToFilters = async (
  params: string | Array<string | null>,
  Filters: Array<ColumnFilter>,
  setProgress?: Function,
  allFilters?: Array<ColumnFilter>,
  lockFilters?: boolean,
): Promise<ItemFilter[][]> => {
  let urlFilters = []

  if (Array.isArray(params)) {
    params.forEach((encodedFilter) => {
      if (!encodedFilter) return
      urlFilters = urlFilters.concat(JSON.parse(atob(encodedFilter)))
    })
  } else {
    urlFilters = JSON.parse(atob(params))
  }

  urlFilters = convertOldStringFiltersFormat(urlFilters)

  const promises = urlFilters.map((filter) => {
    let operators: ?Array<OperatorFilter>
    let operator: ?OperatorFilter

    return promiseAllProgress(
      filter.map((type: Object | string, index) => {
        // Or
        if (logicOperators[type]) {
          return Promise.resolve(logicOperators[type])
        }

        // Column
        if (index === 0) {
          let column = Filters.find((f) => f.label === type)
          if (!column && lockFilters) column = allFilters.find((f) => f.label === type)
          if (!column) operator === undefined
          else ({ operators } = column)
          return Promise.resolve(column)
        }

        // Operator
        if (index === 1) {
          const splitedOperators = String(type).split(',')

          operator = operators?.find((op) => splitedOperators.includes(op.value))

          if (!operator) return Promise.reject(new Error('This column has no filter in filters file.'))

          if (operator && operator?.options && operator?.resourceValue) {
            return resources[operator.resourceValue].fetchAll().then((res) => {
              if (!operator) return Promise.resolve()
              let data
              if (operator.formatResourceValues) data = res.resources.map(operator.formatResourceValues)
              else data = res.resources.map(({ name, id }) => ({ label: name, value: id }))
              if (operator?.resourceValue === 'steps') data.push({ label: 'General', value: 'general' })
              return { ...operator, options: { ...operator?.options, data } }
            })
          }

          return Promise.resolve(operator)
        }

        // Values
        if (!operator) return Promise.reject(new Error('This column has no filter in filters file.'))

        let value: Object = { value: type, label: type, type: 'value', inputType: operator.inputType }

        if (index > 1) {
          if (index === 2 && operator.serverKey === 'range') value.operatorValue = 'and'
          if (
            ['in', 'in!', 'relationempty!', 'relationempty'].includes(operator.serverKey) ||
            Array.isArray(operator?.assetTypes) ||
            operator?.resourceValue
          ) {
            if (operator?.resourceValue && operator?.resourceValue !== 'steps') {
              let item = getResources(undefined, operator.resourceValue, type)

              if (operator?.resourceValue === 'steps' && type === 'general') {
                item = value
                item.value = 'general'
                item.label = 'General'
                return Promise.resolve(item)
              }

              return (item ? Promise.resolve(item) : getFromRequest(type, operator?.resourceValue)).then((_item) => {
                if (_item) {
                  value.label = _item.name
                  value.backgroundColor = _item.color

                  if (operator?.getSubLabel) {
                    value.subLabel = operator.getSubLabel(_item)
                  }
                  if (operator?.getProjectHeader) {
                    value.projectHeader = operator.getProjectHeader(_item)
                  }
                  if (operator?.formatResourceValues) {
                    value = {
                      ...value,
                      ...operator?.formatResourceValues(_item),
                    }
                  }
                }
                return value
              })
            }
            if (operator?.inputType === 'asset' || Array.isArray(operator?.assetTypes)) {
              const asset = getResources(undefined, 'assets', type)
              return (asset ? Promise.resolve(asset) : getFromRequest(type, 'assets')).then((asset) => {
                if (asset) {
                  value.label = asset.name
                  value.icon = assetIcons(asset.assetType)
                  if (operator?.getSubLabel) {
                    value.subLabel = operator.getSubLabel(asset)
                  }
                  if (operator?.getProjectHeader) {
                    value.projectHeader = operator.getProjectHeader(asset)
                  }
                }
                return value
              })
            }
            if (operator?.inputType === 'step') {
              const steps = getResources(undefined, 'steps')
              const step = find(steps, (el) => el.name === value.label)
              return Promise.resolve(step).then((res) => {
                value.label = res.name
                value.value = res.id
                return value
              })
            }
            if (operator?.inputType === 'flags') {
              const flag = getResources(undefined, 'flags', type)
              return (flag ? Promise.resolve(flag) : getFromRequest(type, 'flags')).then((flag) => {
                if (flag) {
                  value.label = flag.name
                  value.color = flag.color
                }
                value.icon = 'fas-flag'
                return value
              })
            }
          }
          if (operator?.inputType === 'select') {
            const option = operator?.options?.data.find((opt) => opt.value === value.value)

            if (endsWith(operator?.attr, 'priority') && getOptionsValue(value.value)) {
              const priorityValue = getOptionsValue(value.value)

              if (!priorityValue) return undefined

              value.label = priorityValue.label
              value.backgroundColor = priorityValue.backgroundColor
            } else if (operator?.options && operator.options.data && option) {
              value.label = option.label
              value.backgroundColor = option.backgroundColor
            } else {
              value.label = String(value.value).split('"').join('')
            }
          }
          if (['datetime', 'date', 'time'].indexOf(operator?.inputType) !== -1) {
            if (operator?.inputType === 'datetime') {
              value.value = new Date(value.value)
              value.label = moment(value.value).format('DD/MM/YYYY HH:mm')
            }
            if (operator?.inputType === 'date') {
              value.value = new Date(value.value)
              value.label = moment(value.value).format('DD/MM/YYYY')
            }
          }
        }
        return Promise.resolve(value)
      }),
      setProgress,
    )
  })
  return Promise.all(promises)
}

function encodeFilters(str: string): string {
  return encodeURIComponent(str)
}

function concatComplexQueriesToString(queries: $ElementType<ResourcesParams, 'queries'>): string {
  return encodeFilters(map(queries, (value, key: string) => `${key}=${String(value)}`).join('&'))
}

type ComplexFiltersToQueriesOutput = (
  filters: ItemFilter[][],
  depth?: number,
) => { queries: string, headers: { [header: string]: string } }

export const complexFiltersToQueries: ComplexFiltersToQueriesOutput = (filters, depth = 0) => {
  let queries = ``
  const headers = {}

  let queriesFilters = []

  const getStringQueries = (_queries, _headers) => {
    forEach(_headers, (header, project) => {
      if (headers[project]) headers[project].push(header)
      else headers[project] = [header]
    })
    return concatComplexQueriesToString(_queries)
  }

  for (let index = 0; index < filters.length; index += 1) {
    const filter = filters[index]

    const isLogicOperator = filter[0].type === 'logicOperator'

    if (isLogicOperator) {
      const { queries: currentQueries, headers: currentHeaders } = filtersToQueries(queriesFilters)
      let filterValue = filter[0].value || '&'

      if (filterValue !== '~') filterValue = `${filterValue} `
      else filterValue = `${filterValue}`

      const str = getStringQueries(currentQueries, currentHeaders)
      queries = `${queries}(${str}) ${filterValue}`

      queriesFilters = []
    } else {
      queriesFilters.push(filter)
    }
  }

  const { queries: currentQueries, headers: currentHeaders } = filtersToQueries(queriesFilters)
  const str = getStringQueries(currentQueries, currentHeaders)
  queries = `${queries}(${str})`

  return { queries, headers }
}

type FiltersToQueriesOutput = (
  filters: ItemFilter[][],
  depth?: number,
) => { queries: $ElementType<ResourcesParams, 'queries'>, headers: { [header: string]: string } }

export const filtersToQueries: FiltersToQueriesOutput = (filters) => {
  const queries = {}
  const headers = {}

  const isComplexFilter = Boolean(
    filters.find((filter) => {
      if (filter?.[0]?.type === 'logicOperator') return true
      return false
    }),
  )

  if (isComplexFilter) {
    const { queries: complexeQueries, headers: complexeHeaders } = complexFiltersToQueries(
      convertOldFiltersFormat(filters),
    )

    if (complexeHeaders[PROJECT_HEADER] && Array.isArray(complexeHeaders[PROJECT_HEADER])) {
      complexeHeaders[PROJECT_HEADER] = complexeHeaders[PROJECT_HEADER].join(',')
    }

    return { queries: { filters: complexeQueries }, headers: complexeHeaders }
  }

  forEach(filters, (filter) => {
    let operator: OperatorFilter | void
    let filterKey: string | void
    let multipleValuesQuery: Array<string> = []

    const previousOperators: OperatorFilter[] = []

    forEach(filter, (filterPart, index: number) => {
      if (!filterPart) return
      if (filterPart.type === 'operator') {
        if (multipleValuesQuery.length > 0 && filterKey) {
          queries[filterKey] = JSON.stringify(multipleValuesQuery)
          multipleValuesQuery = []
        }
        if (operator) previousOperators.push(operator)
        operator = filterPart

        filterKey = `${operator.attr}__${operator.serverKey}`.replace('__exact', '')

        if (queries[filterKey]) {
          // $FlowFixMe
          const numberOfSameFilter = Object.keys(queries).filter((key) => key.endsWith(filterKey)).length
          filterKey = `${String(numberOfSameFilter)}${sameFilterMultipleSeparator}${filterKey}`
        }

        if (binaryOperators(operator.value)) {
          queries[filterKey] = operator.formatValue
            ? operator.formatValue(operator.filterValue, operator)
            : operator.filterValue
        }
      } else if (filterPart.type === 'value' && operator) {
        const value = operator?.formatValue?.(filterPart.value, operator) || filterPart.value
        let filterKey = `${operator.attr}__${operator.serverKey}`.replace('__exact', '')

        if (queries[filterKey]) {
          const numberOfSameFilter = Object.keys(queries).filter((key) => key.endsWith(filterKey)).length
          filterKey = `${String(numberOfSameFilter)}${sameFilterMultipleSeparator}${filterKey}`
        }

        const filterStart = queries[filterKey] || ''

        if (['in', 'notIn', 'inList', 'notInList', 'range'].includes(operator.value)) {
          multipleValuesQuery.push(value)
        } else if (
          gtOperators(operator.value) &&
          !isNaN(Number(queries[filterKey])) &&
          operator.inputType !== 'datetime'
        ) {
          queries[filterKey] = Math.max(Number(queries[filterKey]), Number(value))
        } else if (
          ltOperators(operator.value) &&
          !isNaN(Number(queries[filterKey])) &&
          operator.inputType !== 'datetime'
        ) {
          queries[filterKey] = Math.min(Number(queries[filterKey]), Number(value))
        } else {
          queries[filterKey] = `${filterStart ? `${filterStart},` : ''}${String(value)}`
        }

        if (filterPart.projectHeader) {
          if (!headers[PROJECT_HEADER]) headers[PROJECT_HEADER] = []
          if (headers[PROJECT_HEADER].indexOf(filterPart.projectHeader) === -1) {
            headers[PROJECT_HEADER].push(filterPart.projectHeader)
          }
        }

        if (operator.formatMultipleValues && (!filter[index + 1] || filter[index + 1]?.type !== 'value')) {
          queries[filterKey] = operator.formatMultipleValues?.(queries[filterKey], undefined, operator)
        }
      }
    })

    if (multipleValuesQuery.length > 0 && filterKey) {
      // is an attributes__
      if (operator && isAnAttributeOperator(operator)) {
        // is an attributes__ and need to concat
        if (queries[filterKey]) {
          try {
            const parsedQueries = JSON.parse(queries[filterKey])
            if (Array.isArray(parsedQueries)) {
              parsedQueries.push(...multipleValuesQuery)
            }
            queries[filterKey] = JSON.stringify(parsedQueries)
          } catch (error) {
            console.error(error)
          }
          // is an attributes__ and dont need to concat
        } else queries[filterKey] = JSON.stringify(multipleValuesQuery)
        // is not an attributes__ and need to concat
      } else if (queries[filterKey]) queries[filterKey] = `${queries[filterKey]},${multipleValuesQuery.toString()}`
      // is not an attributes__ and dont need to concat
      else queries[filterKey] = multipleValuesQuery.toString()
      multipleValuesQuery = []
    }
  })

  if (headers[PROJECT_HEADER] && Array.isArray(headers[PROJECT_HEADER])) {
    headers[PROJECT_HEADER] = headers[PROJECT_HEADER].join(',')
  }

  return { queries, headers }
}

export function filterObjToStr(filter: ItemFilter): string {
  if (filter.type === 'column') {
    return filter.label
  }
  if (filter.type === 'operator') {
    return filter.value
  }
  if (filter.type === 'logicOperator') {
    return filter.value
  }
  return filter.value
}

export function getReducedFilters(_filters: ItemFilter[][]): Array<Array<string>> {
  const filters = Array.isArray(_filters[0]) ? _filters : splitFilters(_filters)

  const reducedFilters = []

  filters.forEach((_filter) => {
    if (_filter) reducedFilters.push(_filter.map(filterObjToStr))
  })

  return reducedFilters
}

export const filtersToURL = (filters: ItemFilter[][]): ?string => {
  const urlFilter = getReducedFilters(filters)
  if (urlFilter.length === 0) return null
  return btoa(JSON.stringify(urlFilter))
}

export const urlToQueries = (
  columns: ?Array<ColumnFilter>,
  setProgress?: Function,
  tableId: ?string,
): Promise<{ queries: $ElementType<ResourcesParams, 'queries'>, headers: { [header: string]: string } }> => {
  const url = new URL(window.location.href)
  const urlFilters = url.searchParams.get(getURLParamsKey('filters', tableId))

  if (urlFilters && columns) {
    return urlToFilters(urlFilters, columns, setProgress).then((filters) => {
      const { queries, headers } = filtersToQueries(filters)
      return { queries, headers }
    })
  }

  return Promise.resolve({ queries: {}, headers: {} })
}

export const validateFilter = (filters: ItemFilter[]): ItemFilter[] => {
  const remapedFilters = []
  let currentFilter = []

  let lastLogicOperatorItem

  filters.forEach((filter) => {
    switch (filter.type) {
      case 'column': {
        if (currentFilter.length > 0) remapedFilters.push(currentFilter)
        currentFilter = []
        lastLogicOperatorItem = undefined
        currentFilter.push(filter)
        break
      }
      case 'operator': {
        currentFilter.push(filter)
        break
      }
      case 'logicOperator': {
        if (lastLogicOperatorItem && lastLogicOperatorItem.value !== filter.value) {
          remapedFilters.push(currentFilter, [filter])
          currentFilter = []
          lastLogicOperatorItem = undefined
          break
        }
        currentFilter.push(filter)
        break
      }
      case 'value': {
        if (currentFilter.length === 0) {
          const lastFilter = findLast(remapedFilters, (remapedFilter) => remapedFilter[0]?.type === 'column')

          if (lastFilter && lastFilter[0] && lastFilter[1]) {
            const [column, operator] = lastFilter
            currentFilter = [column, operator, filter]
            break
          }
        }

        currentFilter.push(filter)
        break
      }
      default: {
        currentFilter.push(filter)
      }
    }
  })

  if (currentFilter.length > 0) remapedFilters.push(currentFilter)

  return flatten(remapedFilters)
}

export function compactFilters(filters: ItemFilter[][]): ItemFilter[][] {
  const newFilters = []
  filters.forEach((filter, currentIndex) => {
    let columnIndex
    let columnLabel
    let operatorValue

    let skipRest = false

    filter.forEach((filterPart) => {
      if (skipRest) return

      if (filterPart.type === 'logicOperator') {
        newFilters.push(filter)
        return
      }

      if (filterPart.type === 'column') {
        if (!newFilters.find((filter) => filter[0].label === filterPart.label)) {
          newFilters.push(filter)
          skipRest = true
        } else {
          columnLabel = filterPart.label
        }
      }
      if (filterPart.type === 'operator') {
        for (let index = 0; index < newFilters.length; index += 1) {
          const currentFilter = newFilters[index]
          // $FlowFixMe
          if (currentFilter[0].label === columnLabel && currentFilter[1].value === filterPart.value) columnIndex = index
        }

        if (
          columnIndex >= 0 &&
          ['inList', 'notInList'].includes(filterPart.value) &&
          newFilters[columnIndex][1].value === filterPart.value
        ) {
          operatorValue = filterPart.value
        } else {
          newFilters.push(filter)
          skipRest = true
        }
      }

      if (operatorValue && filterPart.type === 'value') {
        newFilters[columnIndex].push(filterPart)
      }
    })
  })

  return newFilters
}

export const editorTypeToInputType = (type: ?EditorType): InputType => {
  // prettier-ignore
  switch (type) {
    case "int": return 'number'
    case "slider": return 'number'
    case "float": return 'number'
    case "str": return 'text'
    case "txt": return 'text'
    case "datetime": return 'datetime'
    case "time": return 'time'
    case "date": return 'date'
    case "duration": return 'duration'
    case "choice": return 'select'
    case "bool": return 'text'
    case "email": return 'text'
    case "url": return 'text'
    case "log": return 'text'
    case "rating": return 'number'
    case "priority": return 'number'
    case "iban": return 'text'
    case "phone": return 'text'
    case "timecode": return 'number'
    default:return 'text'
  }
}
