// @flow
import * as React from 'react'
import cx from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'
import MUIFab from '@material-ui/core/Fab'
import { Link } from 'react-router-dom'
import type { ElementProps, IconType } from 'app/core/types'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'

type Props = {|
  ...ElementProps,
  action?: Function,
  buttonRef?: Function | Object,
  centerRipple?: boolean,
  children?: React.Element<any> | string | number | mixed,
  classes?: Object,
  component?: string | React.ComponentType<any>,
  disabled?: boolean,
  disableRipple?: boolean,
  bgColor?: string,
  labelColor?: string,
  disableTouchRipple?: boolean,
  focusRipple?: boolean,
  focusVisibleClassName?: string,
  onFocusVisible?: Function,
  TouchRippleProps?: Object,
  type?: string,
  style?: Object,
  tabIndex?: string | number,
  onClick?: Function,
  className?: string,
  tooltip?: string,
  tooltipProps?: Object,
  tooltipStyle?: Object,
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'validate' | 'error',
  disableFocusRipple?: boolean,
  fullWidth?: boolean,
  href?: string,
  mini?: boolean,
  size?: 'small' | 'medium' | 'large' | 'medium',
  variant?: 'round' | 'extended',
  to?: string,
  icon?: IconType,
  loader?: boolean,
|}

const useStyles = makeStyles((theme) => ({
  root: ({ color, bgColor }) => ({
    '&:hover': {
      backgroundColor: bgColor,
      color: color || (bgColor && '#ffffff') || 'initial',
      boxShadow: '0 0 1000px rgba(0,0,0,0.2) inset',
    },
    '&:active': {
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);',
    },
    backgroundColor: bgColor,
    color: color || (bgColor && '#ffffff') || 'initial',
  }),
}))

export function Fab({
  children,
  tooltip,
  variant,
  size,
  color,
  style,
  to,
  icon,
  loader,
  bgColor,
  labelColor,
  classes,
  tooltipProps,
  tooltipStyle,
  ...props
}: Props): React$Node {
  const colorsClasses = useStyles({ color: labelColor, bgColor })

  let ButtonComp = (
    <MUIFab
      size={size || 'small'}
      color={color || 'default'}
      disabled={loader}
      style={{ minWidth: 30, minHeight: 30, ...style }}
      classes={{
        ...classes,
        root: cx([(bgColor || labelColor) && colorsClasses.root, classes && classes.root]),
      }}
      {...props}
      component={to ? Link : 'button'}
      to={to}
    >
      {loader ? (
        <LoaderSmall style={{ marginRight: 5 }} />
      ) : icon ? (
        <FontIcon icon={icon} className={`${children ? 'marginRight5' : ''}`} />
      ) : null}
      {children}
    </MUIFab>
  )

  if (tooltip && !props.disabled)
    ButtonComp = (
      <Tooltip style={tooltipStyle} title={tooltip} {...tooltipProps}>
        <span>{ButtonComp}</span>
      </Tooltip>
    )

  return ButtonComp
}
