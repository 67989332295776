/** @flow */
import React, { useMemo } from 'react'
import { NODE_DIRECTORY, NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import type { SchemaColumnSettings } from 'app/core/types/Schema'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'

type ContractInformationType = {|
  name: string,
  type:
    | 'contractType'
    | 'contractStatus'
    | 'startDate'
    | 'endDate'
    | 'missionEstimatedStartDate'
    | 'missionEstimatedEndDate'
    | 'salary'
    | 'office'
    | 'projectPlan'
    | 'contractsSummary',
|}

export type ContractInformationNodeData = {|
  contractInformation: ContractInformationType,
  contentType: 'contractInformation',
|}

const contractInformationItems: ContractInformationType[] = [
  { name: 'Contract type', type: 'contractType' },
  { name: 'Contract status', type: 'contractStatus' },
  { name: 'Start date', type: 'startDate' },
  { name: 'End date', type: 'endDate' },
  { name: 'Mission estimated start date', type: 'missionEstimatedStartDate' },
  { name: 'Mission estimated end date', type: 'missionEstimatedEndDate' },
  { name: 'Salary', type: 'salary' },
  { name: 'Office', type: 'office' },
  { name: 'Project', type: 'projectPlan' },
  { name: 'Contracts summary', type: 'contractsSummary' },
]

export function ContractInformations(): React$Node {
  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: contractInformationItems.map((contractInformation) => {
        return new Node<$Shape<SchemaColumnSettings<ContractInformationNodeData>>>({
          key: contractInformation.name,
          name: contractInformation.name,
          parent: rootNode,
          data: { contractInformation, contentType: 'contractInformation' },
          icon: 'fas-grip-vertical',
          type: NODE_ITEM,
        })
      }),
    })

    return rootNode
  }, [])

  return (
    <div style={{ padding: '0 10px 10px 10px', maxWidth: '100%', width: '100%' }}>
      <TreeDnD rootNode={rootNode} />
    </div>
  )
}
