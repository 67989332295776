/** @flow */
import React from 'react'
import { PhoneInput, type PhoneInputProps } from 'app/components/Form/PhoneInput/PhoneInput.jsx'
import { viewableValue } from 'app/components/Form/FormData/getInput.tsx'
import type { Column, DefaultCellProps } from '../../types'

export type CellPhoneParams = {|
  ...DefaultCellProps,
  inputProps?: $Shape<PhoneInputProps>,
|}

export const CellPhone = ({ inputProps, ...data }: CellPhoneParams): Column => ({
  cellType: 'CellPhone',
  Cell: (instance) => {
    const { value, cell } = instance
    const { getCellProps } = cell
    const { edition } = getCellProps()
    const { isBeingEdit, endEdit, save } = edition

    return (
      <div className="fullWidth fullHeight flex center alignCenter">
        {isBeingEdit ? (
          <PhoneInput
            style={{ position: 'relative', zIndex: 1 }}
            value={value}
            autoFocus={true}
            onValidate={(e, newValue) => {
              if (newValue !== value) save(newValue)
            }}
            onBlur={(e) => {
              endEdit()
            }}
            onExit={(e) => {
              endEdit()
            }}
            {...inputProps}
          />
        ) : (
          viewableValue('phone', value)
        )}
      </div>
    )
  },
  width: 120,
  ...data,
})
