var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import cx from 'classnames';
import { ModalExternalJob } from 'app/components/Form';
import classes from './CellExternalJob.module.scss';
function Read({ value }) {
    if (!value)
        return null;
    const { label } = value;
    return (_jsx("div", { className: cx(classes.read), children: _jsx("div", { style: { padding: '0 5px', borderRadius: 4 }, children: label }) }));
}
export const CellExternalJob = (_a) => {
    var { options, actions, componentProps = {}, width } = _a, data = __rest(_a, ["options", "actions", "componentProps", "width"]);
    return (Object.assign(Object.assign({}, data), { cellType: 'CellExternalJob', Cell: (instance) => {
            const { cell } = instance;
            const optionsValue = options(cell);
            const currentValue = optionsValue.find(({ value }) => value === cell.value);
            return (_jsx("div", { className: classes.container, children: _jsx(Read, { value: currentValue }) }));
        }, EditModal: (instance) => {
            const { cell } = instance;
            const { edition } = cell.getCellProps();
            const { endEdit, save } = edition || {};
            const optionsValue = options(cell);
            const currentValue = optionsValue.find(({ value }) => value === cell.value);
            return (_jsx(ModalExternalJob, { externalJob: currentValue, onSave: (data) => save(data), onRequestClose: endEdit, autoFocus: true }));
        }, actions: (instance, cell) => {
            const { disabledOptions } = componentProps || {};
            const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || [];
            let optionsValue = options(cell);
            if (disabledOptions) {
                optionsValue = optionsValue.filter((opt) => !disabledOptions.includes(opt.value));
            }
            return resolvedActions;
        }, width: 250 }));
};
