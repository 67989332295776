/** @flow */
import reduce from 'lodash/reduce'
import type { Contract, Person, DateDay, ResourcesList, PersonAttributeValue, ID } from 'app/core/types'
import { getContractTypeKey } from 'app/containers/Modals/Contracts/ModalEditContract/utils'

// eslint-disable-next-line no-use-before-define
type SubRow = Array<RowData<any, string>> | { [key: string]: RowData<any, string> }

export type RowData<T, R> = {|
  __resource: R,
  __subRowDepth: number,
  __rootId?: ID,
  subRows?: SubRow,
  includedResources?: Array<string>,
  name: string,
  startDate: DateDay,
  endDate: DateDay,
  uneditableDates?: boolean,
  ...T,
|}

export function formatRowPerson(
  person?: $Shape<RowData<Person, 'people'>> = {},
  subRowsContainer: SubRow = [],
): $Shape<RowData<Person, 'people'>> {
  const { firstName, lastName } = person
  const name = `${lastName} ${firstName}`

  const attributesInst: ResourcesList<PersonAttributeValue> = reduce(
    person.attributesInst,
    (acc, attribute) => {
      if (attribute?.personAttribute) acc[attribute.personAttribute] = attribute
      return acc
    },
    {},
  )

  return {
    ...person,
    __resource: 'people',
    __subRowDepth: 0,
    includedResources: ['attributesInst', 'contractsInst'],
    name,
    attributesInst,
    uneditableDates: true,
    subRows: person.subRows || subRowsContainer,
  }
}

export function formatRowContract(
  contract?: $Shape<RowData<Contract, 'contracts'>> = {},
  rootId: ID,
): $Shape<RowData<Contract, 'contracts'>> {
  return {
    ...contract,
    __resource: 'contracts',
    __rootId: rootId,
    __subRowDepth: 1,
    includedResources: [],
    name: contract
      ? `${getContractTypeKey(contract.contractType)} - ${contract.collectiveAgreementTitleData?.jobTitle || ''}`
      : ' ',
  }
}
