/** @flow */
import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import cx from 'classnames'
import type { Media } from 'app/core/types'
import { MUIModal } from 'app/components/Modal'
import { validExtensions } from 'app/components/Medias/MediaItem/fileExtensions.js'
import { permission } from 'app/containers/Permissions'
import { MediasList } from 'app/components/Table/Cells/CellMedias/MediasList.jsx'
import { MUIButton } from 'app/components/Form'
import classes from './ModalMedias.module.scss'

export type ModalMediasProps = {|
  title?: string,
  medias: Array<Media>,
  readOnly?: boolean,
  onCancel: Function,
  onValidate: (relations: Object) => Promise<any>,
  maxSize?: number,
  accept?: string,
  multipleSelection?: boolean,
  onRequestClose: Function,
  exponentTitle?: string,
  allowPinMedia: boolean,
  allowValidateMedia: boolean,
|}

export function ModalMedias(props: ModalMediasProps): React$Node {
  const {
    onCancel,
    title,
    medias,
    accept = validExtensions.join(', '),
    readOnly = !permission(['uploads___upload']),
    maxSize = 50 * 1000000, // 50Mo
    onValidate: _onValidate,
    multipleSelection,
    onRequestClose,
    exponentTitle,
    allowPinMedia,
    allowValidateMedia,
    ...rest
  } = props
  const [errors, setErrors] = useState([])

  const [files, setFiles] = useState([])
  const [toDelete, setToDelete] = useState([])

  function onDeleteMedia(media: Media) {
    if (media.id) {
      setToDelete([...toDelete, media.id])
    } else {
      setFiles(files.filter((file) => file.url !== media.url))
    }
  }

  const { getRootProps, getInputProps, rejectedFiles, isDragReject } = useDropzone({
    accept,
    maxSize,
    noDrag: readOnly,
    onDrop: (acceptedFiles = []) => {
      acceptedFiles.forEach((file) => {
        file.url = URL.createObjectURL(file)
      })
      const newFiles = files.concat(acceptedFiles)

      setFiles(newFiles)
    },
    ...rest,
  })

  useEffect(() => {
    setErrors(
      (rejectedFiles || []).map((file) => {
        const message = `${file.path} - ${file.size > maxSize ? 'The file size is too large.' : 'Unsuported format'}`
        return message
      }),
    )
    setTimeout(() => {
      if (setErrors && (rejectedFiles || [])?.length > 0) setErrors([])
    }, 7000)
  }, [rejectedFiles])

  const { onClick, ...rootProps } = getRootProps()
  let allMedias = files

  if (medias && !multipleSelection) {
    allMedias = allMedias.concat(medias.filter((media) => !toDelete.includes(media.id)))
  }

  async function onValidate() {
    return _onValidate({ toCreate: files, toDelete })
  }

  return (
    <MUIModal
      title={title || 'Medias'}
      onCancel={onCancel}
      width={800}
      height={800}
      resizable={true}
      draggable={true}
      onValidate={onValidate}
      onRequestClose={onRequestClose}
      exponentTitle={exponentTitle}
      extendsButtons={
        !readOnly ? (
          <MUIButton icon="fas-plus" onClick={onClick}>
            Add a media
          </MUIButton>
        ) : undefined
      }
      {...rest}
    >
      <div {...rootProps} className={cx(classes.dropzone, isDragReject && classes.dropzoneError)}>
        <input {...getInputProps()} data-cy="uploadMedias" />
        {allMedias.length === 0 ? (
          <div className="fullWidth fullHeight flex center alignCenter lightgrey">
            Add a media or drag and drop a file here.
          </div>
        ) : null}
        <MediasList
          medias={allMedias}
          onDelete={!readOnly ? onDeleteMedia : undefined}
          allowPinMedia={allowPinMedia}
          allowValidateMedia={allowValidateMedia}
        />
        {errors.length > 0 ? (
          <div className={classes.errors}>
            {errors.map((err, index) => (
              <div key={err} className="flex">
                <div className={classes.error}>{err}</div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </MUIModal>
  )
}
