// @flow
import map from 'lodash/map'
import type { ResourcesList, Step, Options } from 'app/core/types'

export const optionsSteps = (steps: ResourcesList<Step>): Options => {
  return map(steps, (step) => ({
    value: step.id,
    label: step.name,
  }))
}
