/** @flow */

import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import moment from 'moment'
import { padStart, filter, find } from 'lodash'
import type { Activity, ID, ResourcesList } from 'app/core/types'
import vars from 'app/styles/vars.js'
import { getResources } from 'app/store/selectors/index.js'
import Calendar from 'app/components/Calendar/Calendar.jsx'
import resources from 'app/store/resources'
import classes from './MyHoursCalendar.module.scss'

const { colors } = vars

type Props = {|
  userId?: ID,
  display?: 'week' | 'month',
  onSelectWeek?: (startOfWeek: moment, endOfWeek: moment) => void,
  onSelectMonth?: (month: moment) => void,
  onChangeSelectMonth?: (startOfMonth: moment, endOfMonth: moment) => void,
  selectedWeek: moment,
|}

export function MyHoursCalendar(props: Props): React$Node {
  const {
    selectedWeek,
    onSelectWeek: _onSelectWeek,
    onSelectMonth: _onSelectMonth,
    display = 'week',
    userId,
    onChangeSelectMonth,
  } = props

  const [dates, setDates] = useState([
    moment(selectedWeek).subtract(1, 'week').startOf('month').format('YYYY-MM-DD'),
    moment(selectedWeek).add(1, 'week').endOf('month').format('YYYY-MM-DD'),
  ])
  const [isLoading, setIsLoading] = useState(false)
  const [activities, setActivities] = useState<ResourcesList<Activity>>({})

  const calendarRef: React$ElementRef<any> = React.createRef()

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const [startDate, endDate] = dates

    if (display === 'week') {
      setIsLoading(true)
      resources.activities.fetchAllActivities({ startDate, endDate }).then(() => {
        if (!isMounted.current) return
        setActivities(getResources<ResourcesList<Activity>>(undefined, 'activities', { user: userId }))
        setIsLoading(false)
      })
    }
  }, [dates, display])

  function onSelectWeek(startOfWeek: moment, endOfWeek: moment) {
    if (_onSelectWeek) _onSelectWeek(startOfWeek, endOfWeek)
  }

  function onSelectMonth(month: moment) {
    if (_onSelectMonth) _onSelectMonth(month)
  }

  function onChangeMonth(selectedDay: moment) {
    const startDate = moment(selectedDay).subtract(1, 'week').startOf('month')
    const endDate = moment(selectedDay).add(1, 'week').endOf('month')

    if (onChangeSelectMonth) onChangeSelectMonth(startDate, endDate)

    if (!userId) return

    setDates([startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')])
  }

  function activitiesInDay(day: string) {
    return Boolean(find(activities, (activity) => day === activity.date))
  }

  function getDayActivities(day: string): Array<Activity> {
    return filter(activities, (activity) => day === activity.date)
  }

  function renderDay(params: {
    day: moment,
    isInCurrentMonth: boolean,
    isCurrentDay: boolean,
    defaultClassName: string,
  }) {
    const { day, defaultClassName, isInCurrentMonth, isCurrentDay } = params

    const dayString = day.format('YYYY-MM-DD')
    const currentDate = moment()
    const haveActivitiesInDay = activitiesInDay(dayString)
    const isWeekEnd = day.day() === 0 || day.day() === 6
    const dayIsPast = day.isBefore(currentDate)
    const futureDay = day.isAfter(currentDate)

    const className = cx(`${defaultClassName} ${classes.dayInCalendar}`, {
      [classes.dayInPast]: dayIsPast,
      [classes.isWeekEnd]: isWeekEnd,
      [classes.isCurrentDay]: isCurrentDay,
      [classes.isInCurrentMonth]: isInCurrentMonth,
    })

    if (!userId) {
      return (
        <div className={className}>
          <div className={classes.dayString}>{day.format('D')}</div>
        </div>
      )
    }

    const dayActivities = getDayActivities(dayString)

    const totalDayDuration = dayActivities.map(({ duration }: Activity) => duration).reduce((a, b) => a + b, 0)
    const hoursInDay = day.day() === 5 ? 7 : 8
    const isExceeded = totalDayDuration > 60 * 60 * hoursInDay
    let time

    if (isExceeded) time = `${hoursInDay}h`
    else {
      const hours = totalDayDuration / 60 / 60
      const minutes = padStart(
        moment
          .duration(totalDayDuration * 1000)
          .minutes()
          .toString(),
        2,
        '0',
      )
      time = `${Math.floor(hours)}h${minutes !== '00' ? minutes : ''}`
    }

    let color = '#8b8b8b'
    let opacity = 1

    if (isLoading || futureDay) {
      color = colors.greyLight
    } else if (!haveActivitiesInDay && dayIsPast && !isWeekEnd) {
      color = colors.grey
    } else if (isExceeded && !isWeekEnd) {
      color = colors.orange
    } else if (isWeekEnd && totalDayDuration === 0) {
      color = '#b9b9b9'
    } else if (dayIsPast || isCurrentDay) {
      color = colors.green
    }

    if (isWeekEnd) opacity = 0.5
    if (!isInCurrentMonth) opacity = 0.5

    return (
      <div className={className} style={{ color, opacity }}>
        <div className={classes.warning}>
          <span className={classes.warningHours}>{isLoading ? '0h' : time}</span>
        </div>
        <div className={classes.dayString}>{day.format('D')}</div>
      </div>
    )
  }

  return (
    <Calendar
      display={display}
      isLoading={isLoading}
      ref={calendarRef}
      onSelectWeek={onSelectWeek}
      onSelectMonth={onSelectMonth}
      onChangeMonth={onChangeMonth}
      RenderDay={renderDay}
      selectedDay={selectedWeek}
    />
  )
}
