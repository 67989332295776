// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-ModulableTree-styles-___expandableDraggable-module__item {
  min-height: 25px;
  margin: 2px 10px 2px 0;
  padding: 2px 5px;
  border-radius: 5px;
}

.app-components-ModulableTree-styles-___expandableDraggable-module__menuIconContainer {
  min-height: 20px;
  height: 20px;
}

.app-components-ModulableTree-styles-___expandableDraggable-module__actionsContent {
  min-height: 20px;
  height: 20px;
}

.app-components-ModulableTree-styles-___expandableDraggable-module__labelContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 20px;
  height: 100%;
}

.app-components-ModulableTree-styles-___expandableDraggable-module__itemContentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 10px;
  min-height: 20px;
  height: 100%;
}

.app-components-ModulableTree-styles-___expandableDraggable-module__icon {
  min-height: 20px;
  height: 20px;
}

.app-components-ModulableTree-styles-___expandableDraggable-module__item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.app-components-ModulableTree-styles-___expandableDraggable-module__itemsContainer {
  border: none;
}

.app-components-ModulableTree-styles-___expandableDraggable-module__firstItem {
  border-top: none;
}

.app-components-ModulableTree-styles-___expandableDraggable-module__draggableIcon {
  color: grey;
  padding: 4px 5px 2px 15px;
  min-width: 15px;
}
`, "",{"version":3,"sources":["webpack://./app/components/ModulableTree/styles/expandableDraggable.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".item {\n  min-height: 25px;\n  margin: 2px 10px 2px 0;\n  padding: 2px 5px;\n  border-radius: 5px;\n}\n\n.menuIconContainer {\n  min-height: 20px;\n  height: 20px;\n}\n\n.actionsContent {\n  min-height: 20px;\n  height: 20px;\n}\n\n.labelContent {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  align-items: center;\n  min-height: 20px;\n  height: 100%;\n}\n\n.itemContentWrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  align-items: center;\n  padding-left: 10px;\n  min-height: 20px;\n  height: 100%;\n}\n\n.icon {\n  min-height: 20px;\n  height: 20px;\n}\n\n.item:hover {\n  background-color: rgba(0, 0, 0, 0.02);\n}\n\n.itemsContainer {\n  border: none;\n}\n\n.firstItem {\n  border-top: none;\n}\n\n.draggableIcon {\n  color: grey;\n  padding: 4px 5px 2px 15px;\n  min-width: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `app-components-ModulableTree-styles-___expandableDraggable-module__item`,
	"menuIconContainer": `app-components-ModulableTree-styles-___expandableDraggable-module__menuIconContainer`,
	"actionsContent": `app-components-ModulableTree-styles-___expandableDraggable-module__actionsContent`,
	"labelContent": `app-components-ModulableTree-styles-___expandableDraggable-module__labelContent`,
	"itemContentWrapper": `app-components-ModulableTree-styles-___expandableDraggable-module__itemContentWrapper`,
	"icon": `app-components-ModulableTree-styles-___expandableDraggable-module__icon`,
	"itemsContainer": `app-components-ModulableTree-styles-___expandableDraggable-module__itemsContainer`,
	"firstItem": `app-components-ModulableTree-styles-___expandableDraggable-module__firstItem`,
	"draggableIcon": `app-components-ModulableTree-styles-___expandableDraggable-module__draggableIcon`
};
export default ___CSS_LOADER_EXPORT___;
