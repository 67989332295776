var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import resources from 'app/store/resources';
import { clearPersonData, fetchOrganizationChartLayout, fetchOrganizationChartPersonData, updateTrombinoscopePeopleThumbnail, } from 'app/store/reducers/organizationChart';
import { useDispatch, useSelector } from 'react-redux';
import classes from './OrganizationChartDetail.module.scss';
import noImage from 'app/styles/images/defaultImage.png';
import { map, find } from 'lodash';
import { MUIButton } from 'app/components/Form';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import history from 'app/main/routerHistory';
import LeftSection from 'app/components/OrganizationChart/DetailPage/LeftSection';
import MainSection from 'app/components/OrganizationChart/DetailPage/MainSection';
import LeftSectionSkeleton from 'app/components/OrganizationChart/DetailPage/LeftSectionSkeleton';
import MainSectionSkeleton from 'app/components/OrganizationChart/DetailPage/MainSectionSkeleton';
import HeaderSkeleton from 'app/components/OrganizationChart/DetailPage/HeaderSkeleton';
const OrganizationChartDetail = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { personId } = params;
    const { layout, personDatas, thumbnail, loading: isLoading, updateLoading, } = useSelector((state) => state.organizationChart);
    const [jobs, setJobs] = useState([]);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
    const [displayLeftSection, setDisplayLeftSection] = useState([]);
    const [displayMainSection, setDisplayMainSection] = useState([]);
    const [studio, setStudio] = useState('');
    const [media, setMedia] = useState();
    function fetchJobs() {
        return __awaiter(this, void 0, void 0, function* () {
            const responseJobs = yield Promise.resolve(resources.jobs.fetchAll());
            setJobs(responseJobs.resources);
        });
    }
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            dispatch(fetchOrganizationChartLayout());
            dispatch(fetchOrganizationChartPersonData(personId));
            yield fetchJobs();
        }))();
        return () => {
            dispatch(clearPersonData());
        };
    }, []);
    useEffect(() => {
        var _a;
        if (!isLoading && personDatas) {
            if (personDatas.thumbnailInst) {
                dispatch(updateTrombinoscopePeopleThumbnail(personDatas.thumbnailInst));
            }
            else if ((_a = personDatas.userInst) === null || _a === void 0 ? void 0 : _a.thumbnailInst) {
                dispatch(updateTrombinoscopePeopleThumbnail(personDatas.userInst.thumbnailInst));
            }
            else {
                dispatch(updateTrombinoscopePeopleThumbnail(null));
            }
        }
        if (personDatas && jobs.length > 0) {
            const findTitle = find(jobs, (job) => personDatas[layout === null || layout === void 0 ? void 0 : layout.title.key] === job.id);
            if (findTitle)
                setTitle(findTitle.name);
        }
        if (personDatas && (layout === null || layout === void 0 ? void 0 : layout.subTitle)) {
            const findSubTitle = find(personDatas.attributesInst, (attr) => attr.personAttribute === layout.subTitle.id);
            setSubTitle((findSubTitle === null || findSubTitle === void 0 ? void 0 : findSubTitle.value) || '');
            setDisplayLeftSection(map(layout.leftSection, (l) => {
                const section = find(personDatas.attributesInst, (attr) => attr.personAttribute === l.id);
                if (section) {
                    return {
                        title: l.title,
                        value: section.value,
                        id: l.id,
                        isEditable: (l === null || l === void 0 ? void 0 : l.editable) || true,
                        attributeId: section.id,
                    };
                }
                return {
                    title: l.title,
                    value: '',
                    id: l.id,
                    isEditable: (l === null || l === void 0 ? void 0 : l.editable) || true,
                };
            }));
            const studioObject = find(personDatas.attributesInst, (attr) => attr.personAttribute === layout.mainSection[0].id);
            if (studioObject)
                setStudio(studioObject.value);
            setDisplayMainSection(map(layout.mainSection.slice(1), (l) => {
                const section = find(personDatas.attributesInst, (attr) => attr.personAttribute === l.id);
                if (section) {
                    return {
                        title: l.title,
                        value: section.value,
                        id: l.id,
                        isEditable: (l === null || l === void 0 ? void 0 : l.editable) || true,
                        attributeId: section.id,
                    };
                }
                return {
                    title: l.title,
                    value: '',
                    id: l.id,
                    isEditable: (l === null || l === void 0 ? void 0 : l.editable) || true,
                };
            }));
        }
    }, [personDatas, jobs, isLoading, layout]);
    useEffect(() => {
        if (thumbnail) {
            setMedia(thumbnail);
            setBackgroundImageUrl((thumbnail === null || thumbnail === void 0 ? void 0 : thumbnail.thumbnail) || (thumbnail === null || thumbnail === void 0 ? void 0 : thumbnail.url));
        }
        else {
            setMedia(undefined);
            setBackgroundImageUrl(noImage);
        }
    }, [thumbnail]);
    function onSavePicture(file) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!file || !file[0]) {
                dispatch(updateTrombinoscopePeopleThumbnail(null));
                return;
            }
            if (media && file && media.url === file[0].url)
                return Promise.resolve();
            const res = yield resources.medias.upload({ files: file });
            yield resources.people.update({ id: personDatas === null || personDatas === void 0 ? void 0 : personDatas.id, thumbnail: res.resources[0].id });
            dispatch(updateTrombinoscopePeopleThumbnail(res.resources[0]));
        });
    }
    return (_jsxs("div", { className: classes.container, children: [_jsx("div", { className: classes.buttonContainer, children: _jsx("div", { className: classes.overflowContainer, children: _jsxs(MUIButton, { onClick: () => history.push('/organization-chart'), children: [_jsx(FontIcon, { icon: "fas-arrow-left", className: "marginRight5" }), "Back to chart"] }) }) }), _jsxs("div", { className: classes.title, children: [isLoading && (_jsx("div", { className: classes.titleOverflowContainer, children: _jsx(HeaderSkeleton, {}) })), !isLoading && personDatas && (_jsxs("div", { className: classes.titleOverflowContainer, children: [_jsx("h1", { children: title ? title : 'Job non renseigné' }), _jsx("h2", { children: subTitle ? subTitle : '' })] }))] }), _jsx("div", { className: classes.mainContainer, children: isLoading ? (_jsxs("div", { className: classes.overflowContainer, children: [_jsx(LeftSectionSkeleton, {}), _jsx(MainSectionSkeleton, {})] })) : (!isLoading &&
                    personDatas && (_jsxs("div", { className: classes.overflowContainer, children: [_jsx(LeftSection, { backgroundImageUrl: backgroundImageUrl, personDatas: personDatas, displayLeftSection: displayLeftSection, media: media, setMedia: setMedia, onSavePicture: onSavePicture, updateLoading: updateLoading }), _jsx(MainSection, { studio: studio, displayMainSection: displayMainSection, personDatas: personDatas, updateLoading: updateLoading })] }))) })] }));
};
export default OrganizationChartDetail;
