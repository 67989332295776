/* eslint-disable global-require */
/** @flow */
import moment from 'moment'
import type { Task } from 'app/core/types'
import { error } from 'app/components/Notifications/notify'
import type { HookFunction } from './types'

async function verifyStartEndEarlyLatestDates(data: Array<Task>, getAPI, getResources) {
  const startEnd = []
  const earliestLatest = []

  data.forEach((taskData) => {
    const originalTask = taskData.id ? getResources(undefined, 'tasks', taskData.id) : taskData
    const task = { ...originalTask, ...taskData }

    if (
      taskData.startDate &&
      taskData.endDate &&
      (taskData.startDate !== originalTask.startDate || taskData.endDate !== originalTask.endDate) &&
      moment(task.startDate).isAfter(moment(task.endDate))
    ) {
      startEnd.push(task)
    } else if (
      taskData.earliestStartDate &&
      taskData.latestEndDate &&
      (taskData.earliestStartDate !== originalTask.earliestStartDate ||
        taskData.latestEndDate !== originalTask.latestEndDate) &&
      moment(task.earliestStartDate).isAfter(moment(task.latestEndDate))
    ) {
      earliestLatest.push(task)
    }
  })

  if (startEnd.length) {
    error('The start date cannot be after the end date.')
    throw new Error('The start date cannot be after the end date.')
  }
  if (earliestLatest.length) {
    error('The earliest start date cannot be after the latest end date.')
    throw new Error('The earliest start date cannot be after the latest end date.')
  }

  return Promise.resolve(data)
}

export const beforeCreate: HookFunction<Array<Task>> = (getResourcesAPI, getResources) => (data) => {
  return verifyStartEndEarlyLatestDates(data, getResourcesAPI, getResources)
}

export const beforeUpdate: HookFunction<Array<Task>> = (getResourcesAPI, getResources) => (data) => {
  return verifyStartEndEarlyLatestDates(data, getResourcesAPI, getResources)
}
