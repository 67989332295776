// @flow
import React, { RefObject } from 'react'
import { map, forEach } from 'lodash'
import { MUIAutocomplete, ColorPickerPopper, type AutocompleteProps } from 'app/components/Form'
import resources from 'app/store/resources'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type { ID, AssetTypes, Option, Flag, ResourcesList, FlagRelation } from 'app/core/types'
import type { AssetTypes as TSAssetTypes, FlagRelation as TSFlagRelation } from 'app/core/types/types'
import { toHighlightJSX } from 'app/libs/helpers/toHighlightJSX.jsx'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { permission, Permission } from 'app/containers/Permissions/Permission.jsx'
import vars from 'app/styles/vars.js'
import Tooltip from '@material-ui/core/Tooltip'

import { cleanFlag, getRelationDiff } from './utils'
import classes from './FlagsRelations.module.scss'

const { colors } = vars

export interface TSProps extends AutocompleteProps {
  foreignKey: 'take' | 'asset';
  resourceID: ID;
  allowCreate?: boolean;
  category: 'take' | 'article' | 'dynamicApprovalValue' | 'post' | TSAssetTypes;
  itemCategory: TSAssetTypes | 'take' | 'article' | 'dynamicApprovalValue' | 'post';
  value: TSResourcesList<TSFlagRelation>;
  onChange?: () => void;
  onRelationChange?: (toCreate: TSFlagRelation[], toDelete: ID[]) => void;
  inputRef?: RefObject<any>;
}

export type Props = {|
  foreignKey: 'take' | 'asset',
  resourceID: ID,
  allowCreate?: boolean,
  category: 'take' | 'article' | 'dynamicApprovalValue' | 'post' | AssetTypes,
  itemCategory: AssetTypes | 'take' | 'article' | 'dynamicApprovalValue' | 'post',
  value: ResourcesList<FlagRelation>,
  onChange?: Function,
  onRelationChange?: ({ toCreate: Array<FlagRelation>, toDelete: Array<ID> }) => void,
  inputRef?: React$ElementRef<any>,
  ...$Exact<AutocompleteProps>,
|}

type FlagOption = $Shape<{ ...Option, data: { ...FlagRelation, toCreate: boolean } }>

export class FlagsRelations extends React.PureComponent<Props> {
  static defaultProps: $Shape<Props> = {
    allowCreate: true,
  }

  onSearch: (string) => Promise<
    Array<{
      label: string,
      labelCustom?: React$Node,
      value: ID,
      backgroundColor?: string,
      data: Object,
    }>,
  > = (searchValue: string) => {
    const { itemCategory, category, value, allowCreate, foreignKey, resourceID } = this.props
    return resources.flags
      .search(
        {
          query: searchValue,
          category,
        },
        null,
        { formatMetaOnSearchResult: true },
      )
      .then(({ plainResponse }) => {
        const output = plainResponse.results
          .filter((res) => !map(value, (item) => item.flag).includes(res.id))
          .map((flagInst) => {
            return {
              label: flagInst.name,
              labelCustom: toHighlightJSX(flagInst.__highlight),
              value: flagInst.id,
              backgroundColor: flagInst.color,
              description: flagInst.description,
              data: {
                [String(foreignKey)]: resourceID,
                flag: flagInst.id,
                flagInst,
              },
            }
          })

        if (allowCreate && permission(['projet_global_flags_create'])) {
          const sameItem = output.find(({ label }) => label === searchValue)

          if (!sameItem) {
            output.push({
              value,
              labelCustom: `Create Item "${searchValue}"`,
              label: searchValue,
              data: {
                toCreate: true,
                [String(foreignKey)]: resourceID,
                flagInst: {
                  name: searchValue,
                  color: '#555',
                  category: itemCategory,
                },
              },
            })
          }
        }
        return output
      })
  }

  onChangeColor: (item: FlagOption, color: string) => Promise<void> = async (item: FlagOption, color: Object) => {
    resources.flags
      .update({
        id: item.data.flagInst.id,
        color,
      })
      .then(() => {
        const { value, onChange } = this.props

        if (!onChange) return Promise.resolve()

        const newItems = { ...value }

        forEach(value, ({ flag }) => {
          if (item?.data?.flagInst && flag === item.value) item.data.flagInst.color = color
        })

        onChange(newItems)
        return Promise.resolve()
      })
  }

  renderTags: (items: Array<Option>, getTagProps: Function) => Array<React$Element<any>> = (items, getTagProps) => {
    const itemsComponents = items.map((option: Option, index: number) => {
      const tprops = getTagProps({ index })
      const { onDelete, className, ...rest } = tprops
      const { label, backgroundColor } = option

      return (
        <div
          {...rest}
          className={classes.flag}
          style={
            backgroundColor
              ? { backgroundColor, color: getColorFromBackground(backgroundColor) || colors.black }
              : undefined
          }
          key={label}
        >
          <Tooltip title={`Description: ${option?.data?.flagInst.description || 'No description'}`}>
            <span>{label}</span>
          </Tooltip>
          <Permission actions={['projet_global_flags_edit color']}>
            <ColorPickerPopper color={option.backgroundColor} onChange={(color) => this.onChangeColor(option, color)} />
          </Permission>
          <FontIcon icon="close" style={{ padding: '0 0 0 4px', cursor: 'pointer' }} onClick={onDelete} />
        </div>
      )
    })

    return itemsComponents
  }

  onChange: (result: Array<FlagOption>) => void = (result) => {
    const { foreignKey, resourceID, value, onRelationChange, onChange } = this.props

    const flagsToCreate: Array<Flag> = []

    result.forEach(({ data }) => {
      const { toCreate, flagInst } = data
      if (toCreate) flagsToCreate.push(flagInst)
    })

    const promise = flagsToCreate.length > 0 ? resources.flags.create(flagsToCreate) : Promise.resolve()

    promise.then((res) => {
      const newFlags = res?.resources || []

      result.forEach(({ data }) => {
        newFlags.push(cleanFlag(data.flagInst))
      })

      const relations = newFlags
        .filter((flag) => flag.id)
        .map((flag) => ({
          [String(foreignKey)]: resourceID,
          flag: flag.id,
          flagInst: flag,
          id: result.find(({ data }) => data.flag === flag.id && data[String(foreignKey)] === resourceID)?.data?.id,
        }))

      if (onChange) onChange?.(relations)
      else {
        const { toCreate, toDelete } = getRelationDiff(map(value), relations)
        if (onRelationChange) onRelationChange({ toCreate, toDelete })
        else {
          const resource = foreignKey === 'take' ? 'takes' : foreignKey === 'article' ? 'articleFlags' : 'assets'
          Promise.all([
            toCreate.length > 0 ? resources[resource].create(toCreate) : undefined,
            toDelete.length > 0 ? resources[resource].delete(toDelete) : undefined,
          ])
        }
      }
    })
  }

  render(): React$Node {
    const {
      foreignKey,
      resourceID,
      allowCreate,
      category,
      itemCategory,
      value,
      onChange,
      inputRef,
      onRelationChange,
      ...autocompleteProps
    } = this.props

    const finalValue: Array<Option> = map(value, (flagRelation) => {
      const {
        flagInst: { id, name, color },
      } = flagRelation

      return {
        value: id,
        label: name,
        backgroundColor: color,
        icon: 'fas-flag',
        data: flagRelation,
      }
    })

    return (
      <MUIAutocomplete
        inputRef={inputRef}
        clearOnEscape={false}
        value={finalValue}
        onChange={this.onChange}
        multiple={true}
        onSearch={this.onSearch}
        renderTags={this.renderTags}
        fullWidth={true}
        clearOnValidate={true}
        {...autocompleteProps}
      />
    )
  }
}
