/** @flow */
import React from 'react'
import cx from 'classnames'
import type { DefaultCellProps, Column } from '../../types'

import classes from './CellProgression.module.scss'

type CellProgressionParams = {|
  ...$Exact<DefaultCellProps>,
  readMask?: (value: number) => ?React$Node,
|}

export const CellProgression = ({ readMask = (val) => val, ...data }: CellProgressionParams): Column => ({
  cellType: 'CellProgression',
  extends: {
    readMask,
  },
  Cell: (instance) => {
    const { value: cellValue, prefs } = instance
    const { minLineHeight } = prefs

    const isMinFormat = minLineHeight < 50

    if (!cellValue) return null

    const { value, max } = cellValue
    const difference = max - value

    const progressMin = Math.min(value, max)
    const progressMax = Math.max(value, max)

    const unit = progressMax / 100
    const status = difference > 0 ? 'Ends in' : difference < 0 ? 'Exceeded' : 'Done'

    let colorScheme
    if (progressMin === progressMax) colorScheme = 'equal'
    else if (progressMin === value) colorScheme = 'lower'
    else colorScheme = 'higher'

    return (
      <div className={classes.container} style={{ padding: isMinFormat ? '0 5px' : '5px' }}>
        <div className={cx(classes.status, { [classes.minFormat]: isMinFormat }, status === 'Exceeded' && 'bold')}>
          {status}
        </div>
        {status !== 'Done' ? (
          <div className={cx(classes.difference, { [classes.minFormat]: isMinFormat })}>
            {status === 'Exceeded' ? '+' : ''} {readMask(Math.abs(difference))}
          </div>
        ) : null}
        <div className={classes[colorScheme]} style={{ height: isMinFormat ? 5 : 10 }}>
          <div
            className={cx(classes.progressBar, classes.left)}
            style={{
              width: `${progressMin / unit}%`,
            }}
          />
          <div
            className={cx(classes.progressBar, classes.right)}
            style={{
              width: `${100 - progressMin / unit}%`,
            }}
          />
        </div>
      </div>
    )
  },
  width: 100,
  ...data,
})
