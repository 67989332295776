// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Label-___Label-module__label {
  align-items: center;
  word-break: break-word;
  font-weight: bold;
  color: #424242;
  padding-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Label/Label.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".label {\n  align-items: center;\n  word-break: break-word;\n  font-weight: bold;\n  color: #424242;\n  padding-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `app-components-Form-Label-___Label-module__label`
};
export default ___CSS_LOADER_EXPORT___;
