/** @flow */
import { createRouter } from 'app/components/LocalRouter'

export const taskRoutes = {
  task: '/:taskId',
  index: `/:taskId/takes`,
  information: '/:taskId/information',
  assetInformation: '/:taskId/assetInformation',
  history: '/:taskId/history',
  takes: '/:taskId/takes',
}

export const router: * = createRouter('task', taskRoutes)
export const { Link, Route } = router
