// @flow

import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { ActivitiesActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const activities: StoreResourceDef<ActivitiesActions<>> = {
  resourceType: 'activities',
  actions: (getResources: Object) => ({
    myActivities: (data, config) => {
      const { requestKey, params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey,
        request: () => api.users.myActivities(null, params.queries).then(transformResults(restConfig)),
      }
    },
    userActivities: ({ userId }, config) => {
      const { requestKey, params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey,
        request: () => api.users.activities({ id: userId }, params.queries).then(transformResults(restConfig)),
      }
    },
    fetchAllActivities: (data, config = {}) => {
      const { requestKey, params = {}, ...restConfig } = config || {}
      const { startDate, endDate, userId } = data

      const fetchActivities = (page, activities = { count: 0, results: [] }) => {
        return api.users[userId ? 'activities' : 'myActivities'](
          userId ? { id: userId } : null,
          {
            ...params.queries,
            page_size: 1000,
            page,
            date__range: `${startDate},${endDate}`,
          },
          params.headers,
          params.getHttpProgress,
          params.requestController,
        ).then((res) => {
          const results = {
            ...res,
            results: activities?.results.concat(res?.results || []) || [],
          }
          if (res && res.next) return fetchActivities(page + 1, results)
          return results
        })
      }

      return {
        type: 'read',
        requestKey,
        request: () => fetchActivities(1).then(transformResults(restConfig)),
      }
    },
  }),
  relations: {
    taskInst: {
      type: 'hasOne',
      resourceType: 'tasks',
      foreignKey: 'task',
    },
  },
}
