// @flow
import React from 'react'
import { IconButton } from 'app/components/Form/Button/IconButton.jsx'
import { MUIButton } from 'app/components/Form/Button/MUIButton.jsx'
import type { ElementProps, IconType } from 'app/core/types'
import FontIcon from '../FontIcon/FontIcon.jsx'

type Props = {|
  ...ElementProps,
  icon?: IconType,
  label?: string,
  to?: string,
  tooltip?: string,
|}

export function ExpansionPanelButton(props: Props): React$Node {
  const { icon, label, ...rest } = props
  let button

  if (icon && !label) {
    button = (
      <IconButton style={{ height: '100%' }} {...rest}>
        <FontIcon style={{ fontSize: 17 }} icon={icon} />
      </IconButton>
    )
  } else {
    button = (
      <MUIButton {...rest}>
        {icon && <FontIcon style={{ marginRight: 5, fontSize: 17 }} icon={icon} />}
        {label}
      </MUIButton>
    )
  }

  return (
    <div
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      {button}
    </div>
  )
}
