/** @flow */
import React from 'react'
import type { ResourcesList, ProgressionStatus } from 'app/core/types'
import type { CellInstance } from '../../types'
import { Container, DisplayStatus } from './index'

type Props = {|
  instance: CellInstance,
  progressionStatus: ResourcesList<ProgressionStatus>,
  columnName: string,
|}

export function CellStatsStatus(props: Props): React$Node {
  const { instance, progressionStatus, columnName } = props
  const {
    stats,
    filtersTools,
    pagination: { totalRowsCount },
    tableId,
  } = instance
  if (!stats || !stats.default) return null
  const { status_counts } = stats.default

  function onFilter(statusId) {
    const filters = btoa(JSON.stringify([[columnName || 'Status', 'inList', statusId]]))
    filtersTools?.setUrlFilters(filters, true, true)
  }

  const emptyStatusNumber = status_counts.reduce((a, n) => a - n.count, totalRowsCount)

  return (
    <Container>
      <DisplayStatus
        progressionStatus={progressionStatus}
        statusList={[...status_counts, { status: null, count: emptyStatusNumber }]}
        onFilter={onFilter}
        columnName={columnName}
        tableId={tableId}
      />
    </Container>
  )
}
