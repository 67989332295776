// @flow

import { isPlainObject, mapValues } from 'lodash'
import { normalize, schema } from 'normalizr'
import type { ResourceConfig, ResourcesList } from 'app/core/types'

// type Item = {
//   id: ID,
//   ...
// }

// type ServerResults =
//   | {|
//       count: number,
//       previous: string,
//       next: string,
//       unread?: number,
//       results: Array<Item>,
//     |}
//   | Array<Item>
//   | Item

type ServerResultsParsed = {
  resources: Array<Object>,
  meta?: Object,
  paginatedLists?: {|
    ids: Array<string>,
    count: number,
  |},
  includedResources?: ResourcesList<Array<Object>>,
  requestProperties?: {|
    count: number,
    previous?: string,
    next?: string,
    unread?: number,
  |},
}
type TransformResults = (?$Shape<ResourceConfig<>>) => (?(Object | Array<Object>)) => ServerResultsParsed

export const transformResults: TransformResults =
  (config = {}) =>
  (res) => {
    const { requestKey, requestProperties, formatDispatch, ...restConfig } = config || {}
    if (!res) {
      return {
        ...restConfig,
        requestProperties,
        resources: [],
        plainResponse: [],
      }
    }
    if (Array.isArray(res)) {
      return {
        ...restConfig,
        requestProperties,
        resources: res,
        plainResponse: res,
      }
    }
    if (res.results) {
      // default format
      const { results, ...restOfRes } = res

      if (Array.isArray(results)) {
        return {
          ...restConfig,
          resources: formatDispatch ? formatDispatch(results) : results,
          plainResponse: res,
          requestProperties: {
            ...restOfRes,
            ...requestProperties,
          },
        }
      }
      if (isPlainObject(results)) {
        // relation format
        const keys = Object.keys(results)

        // example: rel_groupUsersInst
        const serverRelationKey = keys.find((key) => key.startsWith('rel_'))

        // example: assetsInst
        const serverResourceKey = keys.find((key) => !key.startsWith('rel_'))

        if (!serverRelationKey) {
          throw new Error('transformResults(): serverRelationKey is undefined')
        }

        if (!serverResourceKey) {
          throw new Error('transformResults(): serverResourceKey is undefined')
        }

        // remove '_rel' and 'Inst'
        const relationResourceType = serverRelationKey.replace('rel_', '').replace('Inst', '')
        const plainResourceResourceType = serverResourceKey.replace('Inst', '')

        const entities = {
          [serverRelationKey]: relationResourceType,
          [serverResourceKey]: plainResourceResourceType,
        }

        const dataSchema = mapValues(entities, (entity) => [new schema.Entity(entity, {}, { idAttribute: 'id' })])

        const includedResources = normalize(results, dataSchema).entities

        const finalResources = results[serverRelationKey]

        // sort finalResources base on resource relations order
        // example with rel_groupUsersInst and assetsInst:
        // the server sort assetsInst, but not rel_groupUsersInst
        // so we have to sort rel_groupUsersInst base on assetsInst order
        //
        // this code can be remove after a closed issue:
        // https://overmind.teamto.fr/overmind/assetmanager/issues/323
        {
          const firstRelResource = results[serverRelationKey][0]
          if (firstRelResource) {
            const serverResources = results[serverResourceKey]
            const keys = Object.entries(firstRelResource).filter(([key]) => key !== 'id')
            const value = keys.find(([key, id]) => {
              return serverResources.map(({ id }) => id).includes(id)
            })

            if (!value) {
              throw new Error('transformResults: rel key not found')
            }

            const key = value[0]

            finalResources.sort((a, b) => {
              const indexA = serverResources.findIndex(({ id }) => id === a[key])
              const indexB = serverResources.findIndex(({ id }) => id === b[key])
              return indexA - indexB
            })
          }
        }

        return {
          ...restConfig,
          resources: finalResources,
          plainResponse: res,
          includedResources,
          requestProperties: {
            ...restOfRes,
            ...requestProperties,
          },
        }
      }

      throw new Error('transformResults: Error with results type. Must be an Object or an array')
    }

    return {
      ...restConfig,
      requestProperties,
      resources: [res],
    }
  }
