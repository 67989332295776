// @flow
import React from 'react'
import resources from 'app/store/resources'
import { openPictures, isPicture } from 'app/components/PictureVisualization/PictureVisualization.tsx'
import type { DefaultCellProps, Column, PrefsProps } from 'app/components/Table/types.js'
import type { Media } from 'app/core/types'
import { ThumbnailModal } from './ThumbnailModal.tsx'
import classes from './CellThumbnail.module.scss'

type Props = {|
  ...DefaultCellProps,
  disableSelection?: boolean,
|}

type ReadProps = {|
  media: Media,
  prefs: PrefsProps,
  isRowExpanded: boolean,
|}

export function ReadThumbnail(props: ReadProps): React$Node {
  const { media, prefs, isRowExpanded } = props
  const { minLineHeight, maxLineHeight } = prefs
  return media ? (
    <div
      className="padding5 flex center alignCenter inheritWidth"
      style={{
        minHeight: minLineHeight,
        maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2,
        height: isRowExpanded ? 'auto' : minLineHeight,
        overflow: 'hidden',
      }}
    >
      <img
        className={`${classes.picture} activeSelection`}
        src={media.thumbnail || media.url}
        onClick={isPicture(media.url) ? () => openPictures([media], false, false, 0) : undefined}
        alt="broken url"
      />
    </div>
  ) : null
}

export const CellThumbnail = ({
  placeholder = 'Choose a thumbnail',
  disableSelection,
  actions,
  ...data
}: Props): Column => ({
  cellType: 'CellThumbnail',
  Cell: (tableState) => {
    const { prefs, cell } = tableState
    const { getCellProps } = cell
    const { isRowExpanded } = getCellProps()
    const { minLineHeight, maxLineHeight } = prefs

    const media = tableState.value

    return (
      <div
        className="fullHeight center alignCenter flex"
        style={{
          maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2,
        }}
      >
        <ReadThumbnail media={media} prefs={prefs} isRowExpanded={isRowExpanded} />
      </div>
    )
  },
  EditModal: (tableState) => {
    const { cell, cellPositionLabel } = tableState
    const { getCellProps } = cell
    const { edition } = getCellProps()
    const { endEdit, save } = edition

    const media = tableState.value

    return (
      <ThumbnailModal
        title={placeholder}
        defaultMedia={media}
        onRequestClose={endEdit}
        onSave={(file) => {
          if (!file || !file[0]) return save([])
          if (media && file && media.url === file[0].url) return Promise.resolve()
          return resources.medias.upload({ files: file }).then((res) => save(res?.resources?.[0] || null))
        }}
        exponentTitle={cellPositionLabel}
      />
    )
  },
  actions: (instance, cell) => {
    const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []

    return [
      ...resolvedActions,
      {
        label: 'Open in viewer',
        editAction: false,
        disabled: !cell.value,
        rightLabel: 'Ctrl + Enter',
        hotKeys: ['ctrl+enter', 'command+enter'],
        onClick: () => openPictures([cell.value], false, false, 0),
      },
    ]
  },
  width: 150,
  ...data,
})
