// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellPriority-___CellPriority-module__container {
  position: relative;
  min-height: 30px;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.app-components-Table-Cells-CellPriority-___CellPriority-module__read {
  /* height: 20px; */
  white-space: pre-wrap;
  line-height: 20px;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellPriority/CellPriority.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".container {\n  position: relative;\n  min-height: 30px;\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n}\n\n.read {\n  /* height: 20px; */\n  white-space: pre-wrap;\n  line-height: 20px;\n  border-radius: 4px;\n  padding: 0 10px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellPriority-___CellPriority-module__container`,
	"read": `app-components-Table-Cells-CellPriority-___CellPriority-module__read`
};
export default ___CSS_LOADER_EXPORT___;
