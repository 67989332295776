/** @flow */
import { createBrowserHistory } from 'history'
import queryString from 'query-string'
import forEach from 'lodash/forEach'
import type { History } from 'app/core/types'

const history: History = createBrowserHistory()

history.pushWithParams = (match, newParams, suffixPath = '') => {
  const { params, path } = match
  const finalParams = {
    ...params,
    ...newParams,
  }

  const finalPath = path
    .split('/')
    .map((chunk) => chunk.replace('?', ''))
    .map((chunk) => {
      return chunk.startsWith(':') ? finalParams[chunk.replace(':', '')] : chunk
    })
    .join('/')

  history.push(finalPath + suffixPath)
}

history.pushWithQuery = (data: { [key: string]: string }, url = history.location.pathname) => {
  // $FlowFixMe
  const currentState: ?{ [key: string]: string } = queryString.parse(history.location.search) || {}
  const allData = {
    ...currentState,
    ...data,
  }

  history.push(`${url}?${decodeURIComponent(queryString.stringify(allData))}`)
}

history.pushQuery = (data: { [key: string]: string }) => {
  const url = new URL(window.location)
  forEach(data, (value, key) => {
    if (value === undefined) return url.searchParams.delete(key)
    if (value === null) return url.searchParams.delete(key)
    return url.searchParams.set(key, value)
  })

  url.search = decodeURIComponent(url.search)
  window.history.pushState(data, null, url)
}

history.updateQuery = (data: { [key: string]: string }) => {
  const url = new URL(window.location)
  forEach(data, (value, key) => {
    if (value === undefined) return url.searchParams.delete(key)
    if (value === null) return url.searchParams.delete(key)
    return url.searchParams.set(key, value)
  })

  url.search = decodeURIComponent(url.search)
  window.history.replaceState(data, null, url)
}

export default history
