// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellTask-___FastScheduler-module__chip {
  background-color: #555555;
  color: #ffffff;
  border-radius: 3px;
  padding: 2px 5px;
}

.app-components-Table-Cells-CellTask-___FastScheduler-module__chip:focus-within {
  background-color: #55555588;
}

.app-components-Table-Cells-CellTask-___FastScheduler-module__workersInput {
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: bolder;
  width: 60px;
  text-align: right;
  outline: none;
  color: #000000de;
}

.app-components-Table-Cells-CellTask-___FastScheduler-module__workersInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.app-components-Table-Cells-CellTask-___FastScheduler-module__dichotomyContainer {
  border: 1px solid #b3b9c7;
  padding: 8px;
  border-radius: 3px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellTask/FastScheduler.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".chip {\n  background-color: #555555;\n  color: #ffffff;\n  border-radius: 3px;\n  padding: 2px 5px;\n}\n\n.chip:focus-within {\n  background-color: #55555588;\n}\n\n.workersInput {\n  border: none;\n  background-color: transparent;\n  font-size: 18px;\n  font-weight: bolder;\n  width: 60px;\n  text-align: right;\n  outline: none;\n  color: #000000de;\n}\n\n.workersInput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n}\n\n.dichotomyContainer {\n  border: 1px solid #b3b9c7;\n  padding: 8px;\n  border-radius: 3px;\n  margin-top: 15px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chip": `app-components-Table-Cells-CellTask-___FastScheduler-module__chip`,
	"workersInput": `app-components-Table-Cells-CellTask-___FastScheduler-module__workersInput`,
	"dichotomyContainer": `app-components-Table-Cells-CellTask-___FastScheduler-module__dichotomyContainer`
};
export default ___CSS_LOADER_EXPORT___;
