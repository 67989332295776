/** @flow */
import React, { useEffect, useMemo, useState } from 'react'
import truncateMiddle from 'truncate-middle'
import { MUIAutocomplete, type AutocompleteProps } from 'app/components/Form'
import resources from 'app/store/resources'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import type { AssetTypes, Option, Asset, ID, TaskTypes } from 'app/core/types'
import { toHighlightJSX } from 'app/libs/helpers/toHighlightJSX.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { getResources } from 'app/store/selectors'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'

import classes from 'app/components/Table/Cells/CellAssetsSelect/CellAssetsSelect.module.scss'

export type Props = {|
  ...$Shape<AutocompleteProps>,
  assetTypes?: Array<AssetTypes>,
  filterByAttribut?: string,
  filterByAttributValue?: string | Array<string>,
  renderSelectLabel?: Function,
  allowCreate?: boolean,

  showAssetCateg?: boolean,
  showAssetType?: boolean,
  showAssetParent?: boolean,

  groupId?: ID,
  noProjectHeader?: boolean,

  formatMetaOnSearchResult?: boolean,
  headers?: Object,
  assetParent?: ?ID,
  parentSearch?: ?ID,
  model?: 'assets' | 'tasks' | 'people',
  taskType?: TaskTypes,
  projects?: Array<ID>,
  filterResults?: (data: Array<Object>) => Array<Object>,
  inputProps?: Object,
|}

type AssetOption = { ...Asset, __highlight: string, parent__name: string }

export function renderTags(items: Array<Option>, getTagProps: Function): Array<React$Element<any>> {
  return items.map((option: Option, index: number) => {
    const tagProps = getTagProps({ index })
    const { onDelete, className, ...rest } = tagProps
    const { label, data, icon, value } = option

    return (
      <div {...rest} key={value} className={`${classes.item} ${className}`}>
        {icon || data?.assetType ? (
          <FontIcon icon={icon || assetIcons(data?.assetType)} className="marginRight5" />
        ) : null}{' '}
        {label}
        <FontIcon icon="close" style={{ padding: '0 0 0 4px', cursor: 'pointer' }} onClick={onDelete} />
      </div>
    )
  })
}

export function renderSelectLabel({
  asset,
  showAssetType,
  showAssetCateg,
  showAssetParent,
}: {
  asset: AssetOption,
  showAssetType?: boolean,
  showAssetCateg?: boolean,
  showAssetParent?: boolean,
}): React$Element<any> {
  const highlightedName = toHighlightJSX(asset.__highlight || asset.name)

  function showParent(parentName) {
    if (!parentName) return undefined
    return <span className="grey">{parentName}</span>
  }

  if (showAssetType) {
    return (
      <span className="flex row noWrap alignCenter">
        <FontIcon icon={assetIcons(asset.assetType)} className="marginRight5" />
        {highlightedName}&nbsp;
        {showAssetParent && showParent(asset.parent__name)}
      </span>
    )
  }

  if (showAssetCateg) {
    return (
      <span>
        {highlightedName}&nbsp;
        {asset.attributes && asset.attributes.category && (
          <span>
            ({asset.attributes.category})&nbsp;{showAssetParent && showParent(asset.parent__name)}
          </span>
        )}
      </span>
    )
  }

  return (
    <span>
      {highlightedName}&nbsp;{showAssetParent && showParent(asset.parent__name)}
    </span>
  )
}

export default function (props: Props): React$Node {
  const {
    assetTypes,
    filterByAttribut,
    filterByAttributValue,
    formatMetaOnSearchResult,
    renderSelectLabel: _renderSelectLabel,
    groupId,
    noProjectHeader,
    showAssetCateg,
    showAssetType = true,
    showAssetParent = true,
    headers,
    assetParent,
    filterResults,
    model = 'assets',
    projects,
    taskType,
    allowCreate,
    inputProps,
    parentSearch,
    multiple,
    ...rest
  } = props

  const [parentSearchAsset, setParentSearchAsset] = useState<Asset | void>()

  useEffect(() => {
    if (parentSearch) {
      const asset = getResources(undefined, 'assets', parentSearch)
      if (['fo', 'ep'].includes(asset.assetType)) {
        setParentSearchAsset(asset)
      } else {
        const parentAsset = getResources(undefined, 'assets', asset.parent)
        setParentSearchAsset(parentAsset)
      }
    } else {
      setParentSearchAsset()
    }
  }, [parentSearch])

  const currentDirectorySearch = useMemo(() => {
    if (!parentSearchAsset) return undefined
    return (
      <Tooltip
        title={
          <div>
            Search in the folder: <span className="fontSize12 bold">{parentSearchAsset.name}</span>
            <br />
            <br />
            Click to change the search directory on the root.
          </div>
        }
      >
        <div
          className="flex row noWrap center alignCenter marginRight10 pointer"
          style={{ borderRadius: '3px', backgroundColor: 'grey', padding: '2px 4px', marginLeft: '-5px' }}
          onClick={() => setParentSearchAsset()}
        >
          <div className="marginRight8 white center alignEnd relative" style={{ height: 20 }}>
            <FontIcon icon={assetIcons(parentSearchAsset.assetType)} />
            <FontIcon icon="fas-search" style={{ position: 'absolute', color: 'grey', fontSize: 9, left: 4, top: 7 }} />
          </div>
          <div className="white fontSize13" style={{ lineHeight: '2px' }}>
            {truncateMiddle(parentSearchAsset.name, 4, 7, '...')}
          </div>
        </div>
      </Tooltip>
    )
  }, [parentSearchAsset])

  function onSearch(value: string) {
    const queries = {
      query: value,
      projects: projects ? JSON.stringify(projects) : undefined,
      groupUuid: groupId,
      assetType: assetTypes ? assetTypes.join('|') : undefined,
      assetParent,
      taskType,
      parentUuid: parentSearchAsset?.id,
    }

    const searchHeaders = headers ? { ...headers } : {}
    if (noProjectHeader) searchHeaders[window.OVM_PROJECT_HEADER] = ''

    return resources[model].search(queries, searchHeaders, { formatMetaOnSearchResult }).then(({ plainResponse }) => {
      if (model === 'people') {
        return plainResponse.results.map((person) => {
          const { fullName, id } = person
          return {
            label: fullName,
            value: id,
            data: person,
          }
        })
      }

      let output = plainResponse.results
      if (filterByAttribut) {
        output = output?.filter((asset) => {
          if (!asset.attributes) return false
          if (typeof filterByAttributValue === 'string') {
            return asset.attributes[filterByAttribut] === filterByAttributValue
          }
          if (Array.isArray(filterByAttributValue)) {
            return filterByAttributValue.includes(asset.attributes[filterByAttribut])
          }
          return false
        })
      }

      if (filterResults) output = filterResults(output)
      if (!output) return []

      output = output.map((res) => ({
        label: res.name,
        labelCustom: _renderSelectLabel
          ? _renderSelectLabel(res, res.__highlight)
          : renderSelectLabel({
              asset: res,
              showAssetCateg,
              showAssetType,
              showAssetParent,
            }),
        value: res.id,
        data: res,
      }))

      return output
    })
  }

  return (
    <MUIAutocomplete
      clearOnValidate={true}
      onSearch={onSearch}
      renderTags={multiple ? renderTags : undefined}
      multiple={multiple}
      startAdornment={currentDirectorySearch}
      {...inputProps}
      {...rest}
    />
  )
}
