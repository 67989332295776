// @flow
import type { StoreResourceDef } from 'app/core/types'
import type { DynamicApprovalValuesActions } from 'app/core/types/StoreResourcesCustomActions'
import api from 'app/core/api/api.js'
import { transformResults } from './utils/transformResults'

export const dynamicApprovalValues: StoreResourceDef<DynamicApprovalValuesActions<>> = {
  resourceType: 'dynamicApprovalValues',
  actions: (actionsCreators: Object) => ({
    pushTo: (queries: Object, config: Object = {}) => {
      const { params = {} } = config
      return {
        type: 'update',
        requestKey: config?.requestKey,
        request: () => api.pushTo({ ...queries, ...params.queries }).then(transformResults(config)),
      }
    },
    history: ({ id }, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => api.dynamicApprovalValues.history({ id, ...params.queries }).then(transformResults(config)),
      }
    },
  }),
  relations: {
    authorInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'author',
    },
  },
}
