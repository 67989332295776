// @flow

import { Model } from './Model'
import { http } from './http'
import settings from '../settings/index'
import resources from './resources.js'
import type { APIResource, API } from './api.type'

/**
 * Generate API resources
 */

const api: API = {}

export const generateFromResources = () => {
  for (const resourceKey of Object.keys(resources)) {
    const resource: APIResource = resources[resourceKey]

    let uri = `${settings.env.API_URL}/${resource.uri}`

    if (resource.crud) {
      api[resourceKey] = new Model(uri, resource.collection, resource.subResources)
    } else if (resource.method) {
      uri += '/'

      api[resourceKey] = (data, queries, headers = {}, getHttpProgress, requestController) => {
        return http.request({
          method: resource.method,
          url: uri,
          data,
          queries,
          headers,
          getHttpProgress,
          requestController,
        })
      }
    }
  }
}

generateFromResources()

export default api
