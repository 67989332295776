/** @flow */
import React from 'react'
import { optionsProgressionStatus, progressionStatusFilter } from 'app/core/utils/optionsProgressionStatus'
import type { ResourcesList, ProgressionStatus } from 'app/core/types'

type Props = {|
  setData: Function,
  data: any,
  progressionStatus: ResourcesList<ProgressionStatus>,
  getInput: Function,
  usedStatus?: ProgressionStatus,
|}

function RetakeToNewStatusView(props: Props): React$Node {
  const { setData, data = {}, progressionStatus, getInput, usedStatus } = props
  const filteredProgressionStatus = progressionStatusFilter(progressionStatus, 'with', ['normal', 'new', 'pause'])

  const select = getInput(
    {
      type: 'select',
      key: 'status',
      props: {
        options: optionsProgressionStatus(filteredProgressionStatus),
        selected: data.status,
        fullWidth: true,
        placeholder: 'Select a status',
      },
    },
    data,
    (data) => setData(data),
  )

  return (
    <div className="flex column fullWidth">
      <p>
        Be careful, edit a take to a status {usedStatus ? usedStatus.name : 'of type retake'} closes the current take
        and creates a new one. To do this, you must choose the status of the newly created take.
      </p>
      <div className="flex flex1 end margin10">{select}</div>
    </div>
  )
}

type DataSetterProps = { setData: Function, data: Object }

export const RetakeToNewStatus = (
  progressionStatus: ResourcesList<ProgressionStatus>,
  getInput: Function,
  usedStatus?: ProgressionStatus,
): ((DataSetterProps) => React$Node) =>
  function ({ setData, data }: DataSetterProps) {
    return (
      <RetakeToNewStatusView
        setData={setData}
        data={data}
        progressionStatus={progressionStatus}
        getInput={getInput}
        usedStatus={usedStatus}
      />
    )
  }
