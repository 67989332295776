import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon.jsx'

/**
 * Icon took on thenounproject : https://thenounproject.com/search/?q=users%20group&i=791122
 */

export default class UsersGroup extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  }

  static defaultProps = {
    width: 20,
    height: 20,
    fill: 'mainColor',
    fillHover: 'mainColorMedium',
  }

  static buttonColors = {
    iconFillClassName: 'white',
    iconFillHoverClassName: 'mainColor',
  }

  render() {
    const { title, ...props } = this.props

    return (
      <Icon {...props} viewBox="0 0 100 100">
        <g>
          {title ? <title>{title}</title> : null}
          <circle cx="50" cy="33.5" r="11.4" />
          <circle cx="75.6" cy="36.1" r="8.5" />
          <path d="M92.5,64.7C90.8,54,85.2,47.6,75.6,47.6c-3.7,0-6.7,0.9-9.2,2.7c0.3,0.3,0.6,0.6,1,0.9c4.1,4.3,6.9,10.5,8.2,18.4   c0,0.3,0.1,0.6,0.1,0.8C85,70.5,93.2,68.9,92.5,64.7z" />
          <circle cx="24.4" cy="36.1" r="8.5" />
          <path d="M32.7,51.2c0.3-0.3,0.6-0.6,0.9-0.9c-2.5-1.8-5.6-2.7-9.2-2.7c-9.5,0-15.1,6.3-16.9,17.1c-0.7,4.2,7.6,5.8,16.9,5.8   c0-0.3,0-0.6,0.1-0.8C25.7,61.8,28.5,55.6,32.7,51.2z" />
          <path d="M50,77.9c-12.5,0-23.5-2.2-22.6-7.7c2.4-14.4,9.9-22.8,22.6-22.8c12.7,0,20.2,8.5,22.6,22.8C73.5,75.7,62.5,77.9,50,77.9z" />
        </g>
      </Icon>
    )
  }
}
