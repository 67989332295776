/** @flow */
import React, { useState } from 'react'
import { map, sortBy, reverse } from 'lodash'
import type { Thread, Pipe, ID } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import { getResources } from 'app/store/selectors'
import Widget from 'app/components/Widget/Widget.jsx'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import resources from 'app/store/resources/index.js'
import ThreadItem from './Thread.jsx'
import { ModalCreateDiscussions } from './ModalCreateDiscussions.tsx'

import classes from './Discussions.module.scss'

type Props = {|
  router: Object,
  threads: Array<Thread>,
  topicId: ID,
  getWidth: (width: number) => void,
  loading?: boolean,
  selectedThread: ID | null,
  projectId: ID,
|}

function Threads(props: Props) {
  const { router, threads, topicId, getWidth, loading, selectedThread, projectId } = props
  const { Link } = router
  const [width, _setWidth] = useState(400)
  const [isOpen, setIsOpen] = useState(true)

  const setWidth = (width: number) => {
    _setWidth(width)
    getWidth(width)
  }

  return (
    <div className="fullHeight" style={{ width, transition: 'width 300ms' }}>
      <Widget
        styleInner={{ border: 'none', borderRight: '1px solid rgba(0,0,0,0.1)' }}
        styleContent={{ padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
        style={{ height: 'inherit', width: '100%' }}
        enableHideColumn={true}
        noOverlay={true}
        minWidth={62}
        maxWidth={400}
        optionName="discussionTreads"
        onOpenChange={(_isOpen) => {
          if (_isOpen) setWidth(400)
          else setWidth(62)
          setIsOpen(_isOpen)
        }}
      >
        <div className="flex column fullHeight">
          <ModalCreateDiscussions type="threads" relationId={topicId} projectId={projectId} isOpen={isOpen} />
          <div style={{ height: 'calc(100% - 40px)', overflowY: 'auto', overflowX: 'hidden' }}>
            {loading ? (
              <div className="flex flex1 center alignCenter fullHeight">
                <LoaderSmall style={{ width: 30, height: 30 }} />
              </div>
            ) : null}
            <div className={`flex column ${classes.itemContainer}`}>
              {threads.map((thread, index) => {
                return (
                  <Link
                    key={thread.id}
                    name="thread"
                    params={{ threadId: thread.id }}
                    activeClassName={classes.linkActive}
                  >
                    <ThreadItem
                      key={thread.id}
                      thread={thread}
                      style={{ height: isOpen ? 'initial' : 62 }}
                      showSubscribe={isOpen}
                      selectedThread={selectedThread}
                    />
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </Widget>
    </div>
  )
}

const pipeInst: Pipe<{ params: Object, localRouter: Object, project: ID }, typeof Threads> = pipe()

const ThreadComponent: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { localRouter, params, project } = props
    const { topicId } = params

    return {
      threads: reverse(
        sortBy(
          map(getResources(state, 'threads', { topic: topicId }, ['lastPostInst', 'lastPostInst.authorInst'])),
          ({ lastPostInst }) => lastPostInst && lastPostInst.updatedOn,
        ),
      ),
      router: localRouter,
      topicId,
      projectId: project,
    }
  })
  .request((props) => {
    const { project } = props
    const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: project || '' } } }

    return resources.threads.fetchThreadsByTopic(props.params.topicId, requestsConfig)
  })
  // $FlowFixMe[prop-missing] $FlowFixMe Error when updating flow
  .renderLoader(({ localRouter, params, ...rest }) => <Threads loading={true} {...rest} />)
  // $FlowFixMe[prop-missing] $FlowFixMe Error when updating flow
  .render(({ localRouter, params, ...rest }) => <Threads {...rest} />)

export default ThreadComponent
