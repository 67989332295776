// @flow
import React, { useEffect, useMemo, useState } from 'react'
import { Container, RightContent } from 'app/components/LeftNav'
import { getResources } from 'app/store/selectors'
import { useParams } from 'react-router-dom'
import resources from 'app/store/resources'
import { setProject } from 'app/store/actions/project'
import Loader from 'app/components/Loader/Loader.jsx'
import { NotFound } from 'app/components/NotFound/NotFound.jsx'
import pipe from 'app/core/utils/pipe'
import type { Asset } from 'app/core/types/Asset'
import { Nav } from './Nav.jsx'

type Props = {|
  children: any,
  setProject: (project: Asset | null) => void,
|}

function Project(props: Props): React$Node {
  const { children, setProject } = props

  const { projectId } = useParams()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const project = useMemo(() => {
    return getResources<Asset | null>(undefined, 'assets', projectId, ['thumbnailInst'])
  }, [isLoading, projectId])

  useEffect(() => {
    setIsLoading(true)

    resources.assets
      .fetchOne(projectId)
      .then((res) => setProject(res.resources[0]))
      .catch((err) => {
        throw new Error(err)
      })
      .finally(() => setIsLoading(false))
  }, [projectId])

  if (isLoading) return <Loader />
  if (!project) return <NotFound />

  return (
    <Container>
      <Nav project={project} />
      <RightContent>{children}</RightContent>
    </Container>
  )
}

const Component: React$ComponentType<any> = pipe().withRouter().connect(undefined, { setProject }).render(Project)

export default Component
