/** @flow */
import React, { useState, KeyboardEvent } from 'react'
import PhoneNumberInput, { isValidPhoneNumber } from 'react-phone-number-input'
import cx from 'classnames'
import keyCode from 'app/libs/keyCode.js'

import Validators from '../Validators/Validators.jsx'
import classes from './PhoneInput.module.scss'

export type TSPhoneInputProps = {
  country?: string,
  defaultCountry?: string,
  placeholder?: string,
  onChange?: (phoneNumber: string) => void,
  value: string,
  validatorRef?: {} | (() => void),
  errors?: any[],
  isRequired?: boolean,
  validators?: {},
  onExit?: (e: KeyboardEvent<any>, value?: string) => void,
  onValidate?: (e: KeyboardEvent<any>, value?: string) => void,
  onBlur?: (e: KeyboardEvent<any>, value?: string) => void,
  autoFocus?: boolean,
  style?: {},
}

export type PhoneInputProps = {|
  country?: string,
  defaultCountry?: string,
  placeholder?: string,
  onChange?: (phoneNumber: string) => void,
  value: string,
  validatorRef?: Object | Function,
  errors?: Array<any>,
  isRequired?: boolean,
  validators?: Object,
  onExit?: (e: SyntheticKeyboardEvent<any>, value: ?string) => void,
  onValidate?: (e: SyntheticKeyboardEvent<any>, value: ?string) => void,
  onBlur?: (e: SyntheticKeyboardEvent<any>, value: ?string) => void,
  autoFocus?: boolean,
  style?: Object,
|}

export function PhoneInput(props: PhoneInputProps): React$Node {
  const {
    value: defaultValue,
    validatorRef,
    errors,
    isRequired,
    validators,
    onExit,
    onValidate,
    onBlur: defaultOnBlur,
    style,
    onChange,
    autoFocus,
    ...rest
  } = props
  const [error, setError] = useState(false)
  const [value, setValue] = useState(defaultValue || '')

  function onKeyDown(e) {
    switch (e.keyCode) {
      case keyCode.ESCAPE:
        onExit?.(e, value)
        break
      case keyCode.ENTER:
        onValidate?.(e, value)
        break
      default:
        break
    }
  }

  function onBlur(e) {
    if (onValidate && isValidPhoneNumber(value)) onValidate(e, value)
    defaultOnBlur?.(e, value)
  }

  function _onChange(val) {
    setValue(val)
    onChange?.(val)
  }

  return (
    <div className={classes.container} style={style}>
      <PhoneNumberInput
        defaultCountry="FR"
        value={value}
        className={cx({ [classes.error]: error }, 'input')}
        onKeyDown={onKeyDown}
        onChange={_onChange}
        onBlur={onBlur}
        ref={(ref) => {
          if (autoFocus) ref?.select()
        }}
        {...rest}
      />
      <Validators
        ref={(ref) => {
          if (validatorRef) validatorRef(ref)
        }}
        className={classes.fieldError}
        value={value}
        errors={errors}
        onSuccess={() => setError(false)}
        onError={() => setError(true)}
        validators={{
          required: isRequired,
          ...validators,
        }}
      />
    </div>
  )
}
