/** @flow */
import React from 'react'

import type { FormulaItem as FormulaItemProps } from './calculatedFieldsTypes'
import classes from './FormulaItem.module.scss'

type Props = {|
  formulaItem: FormulaItemProps,
  index: number,
  functionOptionPicker?: (params?: Array<string>, index?: number) => React$Element<any>,
|}

export function FormulaItem(props: Props): React$Node {
  const { formulaItem, index, functionOptionPicker } = props

  if (formulaItem.type === 'operator') {
    return (
      <span key={String(index)} className={classes.operator}>
        {formulaItem.label}
      </span>
    )
  }
  if (formulaItem.type === 'value') {
    return (
      <span key={String(index)} className={classes.value}>
        {formulaItem.label || 'unknown item'}
        {formulaItem.attribute?.label ? <span className="marginLeft5">{formulaItem.attribute.label}</span> : null}
      </span>
    )
  }
  if (formulaItem.type === 'function') {
    return (
      <span key={String(index)} className={classes.function}>
        <span className="bold textNoWrap">{formulaItem.label} (</span>
        {formulaItem.items.map((item, i) => (
          <FormulaItem key={`${String(index)}-${String(i)}`} formulaItem={item} index={i} />
        ))}
        {functionOptionPicker?.(['values'], index)}
        <span className="bold">)</span>
      </span>
    )
  }
  return 'null'
}
