/** @flow */
import { mapValues } from 'lodash'
import type { Store } from 'app/store'
import type { StoreResourceName, ResourceActions, GetResourcesActions } from 'app/core/types'
import actionTypes from './actionTypes'

export type CreateResource = (
  store: Store,
  getResources: GetResourcesActions,
  resourceType: StoreResourceName,
  resource: (getResources: GetResourcesActions) => Object,
) => ResourceActions

export const createResource: CreateResource = (store, getResources, resourceType, resource) => {
  const handledActions = mapValues(resource(getResources), (action, actionName) => {
    const handleAction = (actionConfig) => {
      if (!actionConfig) return null
      const { request, type, requestKey = actionName, data, ...rest } = actionConfig

      const actionType = actionTypes[type]

      if (!actionType) {
        throw new Error(
          `redux-resource-request: request type ${type} for ${resourceType}.${actionName}() is invalid. Use read, create, update or delete`,
        )
      }

      // store all dispatch properties into one object
      const actionDefaults = {
        ...rest,
        requestKey,
        resourceType,
        requestName: actionName,
      }

      // request can be synchronous, without server request
      const requestIsAsync = typeof request === 'function'

      if (!requestIsAsync) {
        return store.dispatch({
          ...request,
          type: actionType.local,
          ...actionDefaults,
        })
      }

      // Comment these lines cause dispatch take a lot of time and resources
      // store.dispatch({
      //   type: actionType.pending,
      //   ...actionDefaults,
      // })

      if (data) {
        store.dispatch({
          type: actionType.local,
          resources: {
            [String(resourceType)]: data,
          },
        })
      }

      return request()
        .then((response) => {
          return store.dispatch({
            // response must include resources, and can include requestProperties, list, meta
            ...response,
            type: actionType.succeeded,
            ...actionDefaults,
          })
        })
        .catch((error) => {
          store.dispatch({
            type: actionType.failed,
            ...actionDefaults,
          })

          throw error
        })
    }

    return (...args) => {
      const actionConfig = action(...args)
      // make possible to have multiple requests in one action
      return Array.isArray(actionConfig)
        ? actionConfig.map((config) => handleAction(config))
        : handleAction(actionConfig)
    }
  })

  return handledActions
}
