/** @flow */
import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { sortBy } from 'lodash'
import type { Activity, ResourcesList, Asset } from 'app/core/types'
import { durationStr } from 'app/components/Duration/Duration.tsx'
import { getServerConfig } from '../../../core/utils/getServerConfig'

type Props = {|
  activities: Array<Activity>,
  projects: ResourcesList<Asset>,
|}

function Table({ columns, data }: *) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export function TableQuickLook(props: Props): React$Node {
  const { activities, projects } = props
  const activityTypes = useMemo(() => getServerConfig().ACTIVITY_TYPES, [])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Project',
        accessor: (item) => {
          if (!item.project) return '-'
          return projects[item.project]?.name || 'Other project'
        },
        Cell: (instance) => {
          return <div className="ellipsis">{instance.cell.value}</div>
        },
      },
      {
        Header: 'Duration',
        accessor: (item) => durationStr(item.duration, 24, { days: true }),
        Cell: (instance) => {
          return <div className="ellipsis">{instance.cell.value}</div>
        },
      },
      {
        Header: 'Type',
        accessor: (item) => {
          if (!item.activityType && item.task) {
            if (item.project) return 'Task'
            return '-'
          }
          return activityTypes?.[item.activityType] || 'unknown'
        },
        Cell: (instance) => {
          return <div className="ellipsis">{instance.cell.value}</div>
        },
      },
    ],
    [],
  )

  return <Table columns={columns} data={sortBy(activities, ['project'])} />
}
