/** @flow */
import React from 'react'
import moment from 'moment'
import { map } from 'lodash'
import cx from 'classnames'
import type { Thread, Pipe, Asset, ID, User } from 'app/core/types'
import SubscribeButton from 'app/containers/Subscription/SubscribeButton.jsx'
import { CustomLogo } from 'app/libs/helpers/CustomLogo/CustomLogo.jsx'
import pipe from 'app/core/utils/pipe'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import { getResources } from 'app/store/selectors'

import classes from './Thread.module.scss'

type Props = {|
  thread: Thread,
  createdBy: Asset,
  style?: Object,
  showSubscribe?: boolean,
  currentThread: ID | null,
  user: User,
|}

function ThreadItem(props: Props) {
  const { thread, createdBy, showSubscribe, style, currentThread, user } = props
  const { name, id, postCount, unreadPostCount, lastPostInst: lastPost } = thread

  function metadata(color) {
    const output = []
    const count = postCount || 0

    output.push(<span style={{ color }} key="by">By <span className={classes.by}>{createdBy.name}</span></span>) // prettier-ignore
    output.push(<span style={{ color }} key="replies"> • {count} repl{count > 1 ? 'ies' : 'y'}</span>) // prettier-ignore

    if (count === 0) return <div className={classes.metadata}>{output}</div>

    if (lastPost) {
      const span = document.createElement('span')
      span.innerHTML = lastPost.text

      const lastReply = (
        <div key="last-reply" className="flex column marginTop5">
          <div className={classes.text} style={{ color }}>
            <span className="bold">{lastPost.authorInst.name}: </span>
            {span.textContent || span.innerText}
          </div>
          <div style={{ color }} className={`${classes.text} flex end`}>
            {moment(lastPost.postedOn).calendar()}
          </div>
        </div>
      )
      output.push(lastReply)
    }
    return (
      <div className={classes.metadata} style={{ color }}>
        {output}
      </div>
    )
  }
  const { lastPostInst } = thread
  const lastPostUser = lastPostInst ? lastPostInst.authorInst.name !== user.username : true

  return (
    <Tooltip
      placement="right"
      notActive={style && style.height === 'initial'}
      title={
        <div className={`${classes.item} flex row noWrap ${classes.tooltip}`}>
          {lastPostUser && unreadPostCount > 0 && thread.id !== currentThread ? (
            <div className={classes.notifUnread} />
          ) : null}
          {CustomLogo(['lightgrey'], name)}
          <div className="flex column" style={{ width: 'calc(100% - 67px)' }}>
            <div className={cx(classes.text, classes.title)}>{name}</div>
            {metadata('#f3f3f3')}
          </div>
        </div>
      }
    >
      <div className={`${classes.item} flex row noWrap`} style={style}>
        {lastPostUser && unreadPostCount > 0 && thread.id !== currentThread ? (
          <div className={classes.notifUnread} />
        ) : null}
        {CustomLogo(['lightgrey'], name)}
        <div className="flex column" style={{ width: 'calc(100% - 67px)' }}>
          <div className={cx(classes.text, classes.title)}>{name}</div>
          {metadata()}
        </div>
        <div
          className={`${classes.subscribeButton} flex center alignCenter`}
          style={{ display: showSubscribe ? undefined : 'none' }}
        >
          <SubscribeButton target={{ name, id }} targetType="thread" hideRegister={true} />
        </div>
      </div>
    </Tooltip>
  )
}

const pipeInst: Pipe<{ thread: Thread, selectedThread: ID | null }, typeof ThreadItem> = pipe()

const ThreadComponent: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { thread, selectedThread } = props
    return {
      createdBy: map(getResources(state, 'assets', { id: thread.createdBy }))[0],
      currentThread: selectedThread,
      user: state.user,
    }
  })
  .renderLoader(({ selectedThread, ...rest }) => <ThreadItem {...rest} />)
  .render(({ selectedThread, ...rest }) => <ThreadItem {...rest} />)

export default ThreadComponent
