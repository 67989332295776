// @flow
import * as React from 'react'
import cx from 'classnames'
import { isBoolean } from 'lodash'
import { Link } from 'react-router-dom'
import type { Column, Row, DefaultCellProps, CellInstance, TableInstance } from 'app/components/Table/types.js'

import classes from './CellLink.module.scss'

type Props = {|
  ...DefaultCellProps,
  onClick?: (row: Row, e: SyntheticMouseEvent<>, instance: CellInstance | TableInstance) => void,
  to?: string,
  showRemoved?: boolean,
  disableClick?: boolean,
  disabled?: (instance: CellInstance) => boolean,
|}

export const CellLink = ({ onClick, to, showRemoved, disableClick, actions, disabled, ...data }: Props): Column => {
  return {
    cellType: 'CellLink',
    Cell: (instance) => {
      const { cell } = instance
      const { row, getCellProps, value } = cell
      const { isRowExpanded } = getCellProps()
      const { isDeleted } = row

      const isDisabled = disabled?.(instance)

      const displayRemovedLabel = showRemoved && isDeleted
      const _disableClick = isBoolean(disableClick) ? disableClick : !showRemoved && isDeleted

      if (onClick) {
        return (
          <div
            onClick={(e) => !_disableClick && !isDisabled && onClick(row, e, instance)}
            className={cx(classes.container, { [classes.isDisabled]: isDisabled || _disableClick })}
          >
            {displayRemovedLabel ? <div className={classes.removedTag}>removed</div> : null}
            <span
              style={displayRemovedLabel ? { textDecoration: 'line-through' } : undefined}
              className={cx(classes.label, { [classes.reduce]: !isRowExpanded })}
            >
              {value}
            </span>
          </div>
        )
      }
      if (to) {
        return (
          <Link to={!_disableClick ? to : ''} className={classes.container}>
            {displayRemovedLabel ? <div className={classes.removedTag}>removed</div> : null}
            <span style={displayRemovedLabel ? { textDecoration: 'line-through' } : undefined}>{instance.value}</span>
          </Link>
        )
      }
      return <div className={classes.container}>{value}</div>
    },
    actions: (instance, cell) => {
      if (disableClick) return []

      const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []

      return [
        {
          editAction: false,
          label: 'Open',
          rightLabel: 'Enter',
          onClick: onClick ? (e) => onClick(cell.row, e, instance) : null,
          hotKeys: ['enter'],
        },
        { separator: true },
        ...resolvedActions,
      ]
    },
    unselectable: true,
    width: 150,
    ...data,
  }
}
