/** @flow */
import React from 'react'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NODE_DIRECTORY, Node } from './Node'
import { NodeActions } from './NodeActions.jsx'
import classes from './TreeRow.module.scss'

type TreeRowProps<Data> = {|
  node: Node<Data>,
  depth: number,
  indentation: number,
  selected: boolean,
  ancestorSelected: boolean,
  select: () => void,
  deselect: () => void,
  collapsed: boolean,
  collapse: () => void,
|}

export function TreeRow<Data>(props: TreeRowProps<Data>): React$Node {
  const { node, depth, indentation, selected, ancestorSelected, select, deselect, collapsed, collapse } = props

  function onCollapseButtonClick(e: SyntheticMouseEvent<HTMLElement>) {
    e.stopPropagation()
    collapse()
  }

  function onClick(event: SyntheticMouseEvent<HTMLElement>) {
    if (!(event.metaKey || event.shiftKey)) deselect()
    select()
  }

  const isDirectory = node.type === NODE_DIRECTORY || node.children.length

  return (
    <div
      onClick={onClick}
      style={{
        background: selected ? 'rgba(0,0,0,0.08)' : ancestorSelected ? 'rgba(0,0,0,0.03)' : undefined,
        paddingLeft: depth * indentation,
      }}
      className={`${classes.container} ${!node.disableDrag ? classes.grabCursor : ''}`}
    >
      <div style={{ minWidth: 6, height: 30, backgroundColor: node.color, marginRight: 10 }} />
      {isDirectory ? (
        <span onClick={onCollapseButtonClick} className="pointer">
          {node.children.length === 0 || collapsed ? (
            <FontIcon
              icon="fas-caret-right"
              className="marginRight10"
              style={{ opacity: node.children.length === 0 ? 0.2 : 1 }}
            />
          ) : (
            <FontIcon icon="fas-caret-down" className="marginRight10" />
          )}
        </span>
      ) : null}
      {node.icon ? (
        <FontIcon icon={node.icon} className="marginRight10" style={{ color: 'rgb(178, 185, 199)' }} />
      ) : node.faIcon ? (
        <FontAwesomeIcon icon={node.faIcon} className="marginRight10" style={{ color: 'rgb(178, 185, 199)' }} />
      ) : null}
      <div className={isDirectory ? classes.nodeDirectoryLabel : undefined}>{node.name}</div>
      <div className={classes.unhideMenuHover}>{node.actions ? <NodeActions actions={node.actions} /> : null}</div>
    </div>
  )
}
