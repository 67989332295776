// @flow

import React from 'react'
import PubSub from 'pubsub-js'
import { success } from 'app/components/Notifications/notify'
import { openModalEventName } from './openModal'
import { MUIModal, type ModalProps } from './MUIModal.jsx'

export type Props = {|
  render: (() => React$Node) | React$Node,
  validateMessage?: String,
  ...$Rest<
    ModalProps,
    {
      children: $ElementType<ModalProps, 'children'>,
    },
  >,
|}

export const confirm = (modalProps: Props): Promise<any> => {
  return new Promise((resolve, reject) => {
    const { render, onCancel, onValidate, validateMessage, ...restModalProps } = modalProps

    const children = typeof render === 'function' ? render() : render

    const element = (
      <MUIModal
        onCancel={({ handleClose }) => {
          if (onCancel) {
            return onCancel({ handleClose })
          }

          resolve(handleClose())
          return Promise.resolve()
        }}
        onValidate={async ({ handleClose }) => {
          if (onValidate) {
            const res = await onValidate({ handleClose })
            success(validateMessage)
            return res
          }

          resolve(handleClose())
          return Promise.resolve()
        }}
        {...restModalProps}
      >
        <div
          style={{
            paddingBottom: 15,
          }}
        >
          {children}
        </div>
      </MUIModal>
    )

    PubSub.publish(openModalEventName, element)
  })
}
