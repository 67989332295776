import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect } from 'react';
import { Input } from 'app/components/Form';
import { useDispatch, useSelector } from 'react-redux';
import { searchAssets, setSearchInput, setSize, toggleShowLegend } from 'app/store/reducers/library';
import { assetIcons } from 'app/components/Icons/assetsIcons';
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL';
import { router } from 'app/containers/Assets/AssetDetail/router';
import { Checkbox, FormControlLabel, Slider, Typography, makeStyles } from '@material-ui/core';
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import classes from './Library.module.scss';
import Loader from 'app/components/Loader/Loader.jsx';
import LibraryCard from 'app/components/Card/LibraryCard';
const useStyles = makeStyles({
    slider: {
        width: 300,
    },
});
const Library = () => {
    const muiClasses = useStyles();
    const dispatch = useDispatch();
    const projectId = getProjectIdFromURL();
    const { data, showLegend, searchInput, size, loading, apiLoading, message } = useSelector((state) => state.library);
    useEffect(() => {
        // TO DO : create debounce util
        const getData = setTimeout(() => {
            if (searchInput) {
                dispatch(searchAssets({ query: searchInput, projectId }));
            }
        }, 500);
        return () => clearTimeout(getData);
    }, [searchInput]);
    const handleSetSearchInput = (val) => dispatch(setSearchInput(val));
    const handleToggleShowLegend = () => dispatch(toggleShowLegend());
    const handleSetImageSize = (val) => dispatch(setSize(Number(val)));
    const marks = [
        {
            value: 0,
            label: 'S',
        },
        {
            value: 1,
            label: 'M',
        },
        {
            value: 2,
            label: 'L',
        },
        {
            value: 3,
            label: 'XL',
        },
    ];
    const cardContent = (item) => (_jsxs("div", { className: "row noWrap alignCenter", children: [item.assetType ? (_jsx(FontIcon, { icon: assetIcons(item.assetType), className: `fontSize${size === 6 ? 18 : 14} paddingRight${size === 6 ? 7 : 5} grey alignCenter` })) : null, _jsx("span", { style: { marginBottom: '5px', fontSize: size === 6 ? 18 : 14 }, className: classes.label, children: item.name }), item.highlight.doc.map((str, index) => {
                const splitedStr = str.split('\n');
                const result = splitedStr.filter((s) => s.includes('class="highlight"'));
                return (_jsx("div", { className: classes.highlightString, children: result.map((res) => {
                        return (_jsx("div", { style: { whiteSpace: 'pre-wrap' }, className: "fullWidth marginTop5", dangerouslySetInnerHTML: { __html: res || '' } }));
                    }) }, String(index)));
            })] }));
    return (_jsxs(_Fragment, { children: [apiLoading ? (_jsx("div", { className: classes.overlay_3, children: _jsx(Loader, { className: classes.spinner }) })) : null, _jsxs("div", { className: "fullHeight overflowYAuto padding10 relative marginBottom20", children: [_jsxs("div", { className: "flex spaceBetween marginBottom30", children: [_jsx(Input, { dataCy: "input-search", placeholder: "Search asset", value: searchInput, onChange: (e) => handleSetSearchInput(e.target.value), styleContainer: { maxWidth: 700, height: 'auto', margin: '15px', marginLeft: 0 }, adornment: _jsx("div", { className: "fullHeight flex center alignCenter", style: { width: 20 }, children: loading ? _jsx(LoaderSmall, {}) : _jsx(FontIcon, { icon: "fas-search" }) }) }), _jsxs("div", { className: "flex", children: [_jsx(FormControlLabel, { className: "marginRight50", label: _jsx(Typography, { className: "fontSize14", children: "Show legend" }), control: _jsx(Checkbox, { size: "small", checked: showLegend, onChange: handleToggleShowLegend, name: "legend", color: "primary", disabled: !(data === null || data === void 0 ? void 0 : data.length) }) }), _jsxs("div", { className: "flex column marginRight50", children: [_jsx("p", { className: "fontSize14", children: "Size" }), _jsx(Slider, { className: muiClasses.slider, disabled: !(data === null || data === void 0 ? void 0 : data.length), value: size === 6 ? 3 : size - 2, onChange: (e, val) => handleSetImageSize(val), min: 0, max: 3, "aria-labelledby": "discrete-slider-restrict", step: null, marks: marks })] })] })] }), _jsx("div", { style: {
                            display: 'grid',
                            gridTemplateColumns: `repeat(${9 - size}, minmax(0, 1fr))`,
                            columnGap: '20px',
                            rowGap: '20px',
                        }, children: !data.length ? (_jsxs("div", { className: classes.noResults, children: [_jsx("span", { children: message }), _jsx(FontIcon, { icon: "fas-search" })] })) : (data.map((item) => {
                            var _a, _b, _c;
                            return (_jsx(LibraryCard, { title: item.name, cardContent: cardContent(item), showLegend: showLegend, height: size * 100, image: (_b = (_a = item.thumbnailInst) === null || _a === void 0 ? void 0 : _a.thumbnail) !== null && _b !== void 0 ? _b : (_c = item.thumbnailInst) === null || _c === void 0 ? void 0 : _c.url, onClick: () => router.goTo('index', { assetId: item.id }, { rightPanel: true }), size: size }));
                        })) })] })] }));
};
export default Library;
