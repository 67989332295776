/** @flow */
import React, { useState } from 'react'
import moment from 'moment'
import type { Contract, Imputation, ProjectDepartmentJob, ProjectPlan } from 'app/core/types'
import { getContractTypeKey } from 'app/containers/Modals/Contracts/ModalEditContract/utils'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import resources from 'app/store/resources'
import { getResources } from 'app/store/selectors'

type ToltipContractProps = {|
  children: React$Node,
  contract: ?Contract,
|}
export function TooltipContract(props: ToltipContractProps): React$Node {
  const { contract, children } = props

  if (!contract) return children

  const { startDate, endDate, contractType, collectiveAgreementTitleData, secureDataInst } = contract || {}
  const duration = moment(endDate).diff(moment(startDate), 'days') + 1
  const contractTypeKey = getContractTypeKey(contractType)
  return (
    <Tooltip
      disabled={!contract}
      title={
        <div className="grid col2 gridGap5">
          <div>Contract type:</div>
          <div>{contractTypeKey}</div>
          <div>Duration:</div>
          <div>{duration} days</div>
          <div>Start of contract:</div>
          <div>{moment(startDate).format('ddd DD MMM YYYY')}</div>
          <div>End of contract:</div>
          <div>{moment(endDate).format('ddd DD MMM YYYY')}</div>
          <div>Collective agreement title:</div>
          <div>{String(collectiveAgreementTitleData?.jobTitle)}</div>
          {secureDataInst ? (
            <>
              <div>Salary:</div>
              <div>{secureDataInst.salary}</div>
            </>
          ) : null}
        </div>
      }
    >
      {children}
    </Tooltip>
  )
}

type ToltipImputationProps = {|
  children: React$Node,
  imputation: ?Imputation,
|}
export function TooltipImputation(props: ToltipImputationProps): React$Node {
  const { children, imputation } = props
  const [loader, setLoader] = useState<boolean>(false)
  const [projectDepartmentJob, setProjectDepartmentJob] = useState<ProjectDepartmentJob | void>()
  const [projectPlan, setProjectPlan] = useState<ProjectPlan | void>()

  if (!imputation) return children

  const { projectDepartmentJob: projectDepartmentJobId } = imputation
  const { jobInst, projectDepartmentInst, name: projectDepartmentJobName } = projectDepartmentJob || {}
  const { name: jobName } = jobInst || {}
  const { departmentInst } = projectDepartmentInst || {}
  const { name: departmentName } = departmentInst || {}

  function getProjectPlan(projectPlanId) {
    if (!projectPlanId) {
      setLoader(false)
      return
    }
    const _projectPlan = getResources<ProjectPlan>(undefined, 'projectPlans', projectPlanId, ['projectInst'])
    if (_projectPlan) {
      setProjectPlan(_projectPlan)
      setLoader(false)
      return
    }
    resources.projectPlans.fetchOne(projectPlan).then((res) => {
      setProjectPlan(res.resources[0])
      setLoader(false)
    })
  }

  function getProjectDepartmentJob() {
    if (projectDepartmentJob) return

    const storeProjectDepartmentJob = getResources<ProjectDepartmentJob>(
      undefined,
      'projectDepartmentJobs',
      projectDepartmentJobId,
      ['projectDepartmentInst', 'projectDepartmentInst.departmentInst', 'jobInst'],
    )

    if (storeProjectDepartmentJob?.jobInst) {
      setProjectDepartmentJob(storeProjectDepartmentJob)
      getProjectPlan(storeProjectDepartmentJob?.projectDepartmentInst?.projectPlan)
    } else {
      setLoader(true)
      resources.projectDepartmentJobs.fetchOne(projectDepartmentJobId).then((res) => {
        const { projectPlan } = res.resources[0]?.projectDepartmentInst || {}
        setProjectDepartmentJob(res.resources[0])
        getProjectPlan(projectPlan)
      })
    }
  }

  return (
    <Tooltip
      disabled={!imputation}
      onOpen={getProjectDepartmentJob}
      title={
        loader ? (
          <LoaderSmall />
        ) : (
          <div className="grid col2 gridGap5">
            {projectPlan ? (
              <>
                <div>Project:</div>
                <div>{projectPlan?.projectInst?.name}</div>
              </>
            ) : null}
            <div>Department:</div>
            <div>{departmentName}</div>
            <div>Department job:</div>
            <div>{projectDepartmentJobName}</div>
            <div>Job title:</div>
            <div>{jobName}</div>
          </div>
        )
      }
    >
      {children}
    </Tooltip>
  )
}
