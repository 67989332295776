// @flow
import * as React from 'react'
import { forEach, map, reduce, invert, filter, uniq, maxBy, reverse, sortBy, flatten } from 'lodash'
import { openModal } from 'app/components/Modal'
import { getServerConfig } from 'app/core/utils/getServerConfig'
import type { TableInstance, Cell, TableColumn } from 'app/components/Table/types.js'
import type { ID, DynamicApprovalValue, MenuAction } from 'app/core/types'
import resources from 'app/store/resources'
import { progressionStatusFilter } from 'app/core/utils/optionsProgressionStatus.js'
import { permission } from 'app/containers/Permissions'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'

import type { CellDynamicApprovalParams } from '../CellDynamicApproval/CellDynamicApproval'
import { PushToColumnModal, type Status } from './PushToColumnModal.jsx'

const pushTo = (
  cell: Cell,
  subColumnSrc: TableColumn,
  subColumnDest: TableColumn,
  instance: TableInstance,
  merge: boolean,
  mergeOnly: boolean,
) => {
  if (!subColumnSrc.extends) return undefined
  if (!subColumnDest.extends) return undefined

  const {
    updateCells,
    state: { selectedCells },
  } = instance
  const { progressionStatus } = subColumnSrc.extends

  const { pushType: srcType, id: src } = subColumnSrc.extends && subColumnSrc.extends
  const { pushType: destType, id: dest } = subColumnDest.extends && subColumnDest.extends

  const contentTypes = invert(getServerConfig().CONTENT_TYPES)

  let selectedAssets: Array<Cell> = []

  forEach(selectedCells, (cell) => {
    if (cell.value && cell.value.status) {
      selectedAssets.push(cell)
    }
  })

  if (selectedAssets.length < 2) selectedAssets = [cell]

  let sourceStatus: Array<Status> = []
  selectedAssets.forEach(({ value }) => {
    if (value.takesInst) return sourceStatus.push(maxBy(map(value.takesInst), 'number').status)
    return sourceStatus.push(value.status)
  })

  sourceStatus = uniq(sourceStatus)

  function onSave(relations: Array<{ src: Status | null, dest: Status | null }>) {
    return Promise.all(
      relations.map(({ src: statusSrc, dest: statusDest }) => {
        const assetsIds: Array<ID> = map(
          filter(selectedAssets, (cell) => {
            if (mergeOnly) return true
            const lastTake = cell.value ? reverse(sortBy(cell.value.takesInst, ['number']))[0] : undefined
            return (lastTake ? lastTake.status : cell.value.status) === statusSrc
          }),
          (cell) => cell.row.original?.id,
        )

        let func = null

        if (destType === 'steps') func = resources.takes.pushTo
        if (destType === 'dynamicApprovals') func = resources.dynamicApprovalValues.pushTo
        if (destType === 'steps' && srcType === 'dynamicApprovals' && statusSrc === 'rt') {
          func = resources.takes.pushToFromRetake
        }

        if (!func) return () => Promise.resolve()

        return func({
          assets: assetsIds,
          status: statusDest || undefined,
          srcContentType: srcType === 'steps' ? contentTypes.step : contentTypes.dynamicapproval,
          src,
          destContentType: destType === 'steps' ? contentTypes.step : contentTypes.dynamicapproval,
          dest,
          merge,
          mergeOnly,
        }).then(() => updateCells(selectedCells))
      }),
    )
  }

  if (mergeOnly) return onSave([{ src: null, dest: null }])

  return openModal(
    <PushToColumnModal
      merge={merge}
      sourceStatus={sourceStatus}
      srcType={srcType}
      destType={destType}
      columnSrc={subColumnSrc}
      columnDest={subColumnDest}
      progressionStatus={progressionStatus}
      onSave={onSave}
    />,
  )
}

const autoPush = (instance: TableInstance, cell: Cell, stepStatus: string) => {
  const {
    updateCells,
    state: { selectedCells, lastSelectedCell },
    setSavingCells,
    unsetSavingCells,
    updateSelection,
  } = instance
  const { id: src, targetStep } = cell.column.extends || {}
  const dynamicApprovalValue: DynamicApprovalValue = cell.value

  const contentTypes = invert(getServerConfig().CONTENT_TYPES)

  let func: Function = resources.takes.pushTo
  if (dynamicApprovalValue.status === 'rt') func = resources.takes.pushToFromRetake

  const assetsIds = reduce(
    selectedCells,
    (acc, cell) => (cell.value.status ? [...acc, cell.row.original?.id] : acc),
    [],
  ).filter((_) => _)

  setSavingCells(selectedCells)
  updateSelection(lastSelectedCell, selectedCells)

  return func({
    assets: assetsIds,
    status: stepStatus,
    srcContentType: contentTypes.dynamicapproval,
    src,
    destContentType: contentTypes.step,
    dest: targetStep,
    merge: true,
    mergeOnly: false,
  }).then(() => {
    unsetSavingCells()
    updateCells(selectedCells)
    updateSelection(lastSelectedCell, selectedCells)
  })
}

const autoPushMenu = (instance: TableInstance, cell: Cell) => {
  const ext: $ElementType<CellDynamicApprovalParams, 'extends'> = cell.column.extends || {}
  const { progressionStatus, targetStep } = ext

  if (!targetStep) return []

  const items: Array<MenuAction> = progressionStatusFilter(progressionStatus, 'with', ['normal', 'close']).map(
    (ps) => ({
      label: ps.name,
      color: ps.color,
      onClick: (event: SyntheticMouseEvent<>) => {
        event.preventDefault()
        event.nativeEvent?.stopImmediatePropagation?.()
        autoPush(instance, cell, ps.id)
      },
      editAction: false,
      hotKeys: ps.order ? [`ctrl+${ps.order}`, `command+${ps.order}`] : undefined,
      rightLabel: ps.order ? `Ctrl + ${ps.order}` : undefined,
    }),
  )

  if (items.length === 1) {
    // $FlowFixMe
    const { color, label, onClick } = items[0]
    return [
      {
        label: (
          <span className="flex row noWrap alignCenter">
            Auto push
            <div
              style={{
                backgroundColor: color,
                color: getColorFromBackground(color),
                borderRadius: 4,
                border: '1px solid rgba(0,0,0,0.1)',
                padding: '0 4px',
                marginLeft: 5,
              }}
            >
              {label}
            </div>
          </span>
        ),
        onClick,
        editAction: false,
      },
    ]
  }

  return [
    {
      label: 'Auto push',
      editAction: false,
      items,
    },
  ]
}

const renderPushActions = (columnId: ID, instance: TableInstance, cell: Cell, merge: boolean, mergeOnly: boolean) => {
  const { visibleColumns } = instance

  const items = {}
  let columnSrc: TableColumn

  forEach(visibleColumns, (column: TableColumn) => {
    const { pushType } = column.extends || {}

    if (column.id === columnId) columnSrc = column

    if (column.id !== columnId && pushType && !column.readOnly) {
      if (!items[pushType]) items[pushType] = []

      items[pushType].push({
        label: column.Header,
        onClick: (event: Object) => pushTo(cell, columnSrc, column, instance, merge, mergeOnly),
        editAction: false,
      })
    }
  })

  let label = 'Push status and replace to'
  if (merge && !mergeOnly) label = 'Push status and merge to'
  if (mergeOnly && !merge) label = 'Replace content'
  if (mergeOnly && merge) label = 'Merge content'

  return [
    {
      label,
      editAction: false,
      items: (flatten(
        (map(items, (item, name: string) => [{ separator: true, label: name }, ...item]): Array<Object>),
      ): Array<Object>),
    },
  ]
}

export const pushToActions = (columnId: string, instance: TableInstance, cell: Cell): Array<MenuAction> => {
  if (!permission(['projet_follow-up__push/merge to'])) return []

  const autoPushActions = autoPushMenu(instance, cell)

  if (autoPushActions.length > 0) {
    return [
      ...autoPushActions,
      {
        label: 'Push',
        editAction: false,
        leftLabel: <FontIcon icon="fas-sign-out-alt" />,
        items: [
          ...renderPushActions(columnId, instance, cell, false, true),
          ...renderPushActions(columnId, instance, cell, true, true),
          ...renderPushActions(columnId, instance, cell, false, false),
          ...renderPushActions(columnId, instance, cell, true, false),
        ],
      },
    ]
  }

  return [
    {
      label: 'Push',
      editAction: false,
      leftLabel: <FontIcon icon="fas-sign-out-alt" />,
      items: [
        ...renderPushActions(columnId, instance, cell, false, true),
        ...renderPushActions(columnId, instance, cell, true, true),
        ...renderPushActions(columnId, instance, cell, false, false),
        ...renderPushActions(columnId, instance, cell, true, false),
      ],
    },
  ]
}
