/** @flow */
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ExpansionPanel from 'app/components/ExpansionPanel/ExpansionPanel.jsx'
import Widget from 'app/components/Widget/Widget.jsx'
import { MUIButton } from 'app/components/Form/Button/MUIButton.jsx'
import type { Option, History, Schema } from 'app/core/types'
import resources from 'app/store/resources'
import map from 'lodash/map'
import { openModal } from 'app/components/Modal/openModal'
import { Autocomplete } from 'app/components/Form'
import find from 'lodash/find'
import Tooltip from '@material-ui/core/Tooltip'
import TableConstructor from 'app/components/TableConstructor/TableConstructor.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { confirmDelete } from 'app/components/Modal/confirmDelete.jsx'
import { uuid } from 'app/libs/uuid'
import type { NodeConstructor } from 'app/components/TreeDnD/Node'
import { ColorPickerPopper } from 'app/components/Form/ColorPicker/ColorPickerPopper.jsx'
import type { SchemaColumn } from 'app/core/types/Schema'
import { peopleSchemasResourcesSelector } from 'app/store/selectors/peopleSchemas.ts'
import { PersonAttributesEditor } from './PersonAttributesEditor/PersonAttributesEditor.jsx'
import { ContractAttributesEditor } from './ContractAttributesEditor/ContractAttributesEditor.jsx'
import classes from './PeopleTableEditor.module.scss'
import { ModalEditPeopleTableSchema } from './ModalEditPeopleTableSchema.jsx'
import type { ContractAttributeNodeData } from './ContractAttributesEditor/getContractAttributeNode.jsx'
import type { PersonAttributeNodeData } from './PersonAttributesEditor/PersonAttributesEditor.jsx'
import { ModalEditSchemaGroup } from './ModalEditSchemaGroup.jsx'
import { ModalEditSchemaColumn } from './ModalEditSchemaColumn.jsx'
import { PersonInformations, type PersonInformationNodeData } from './PersonInformations.jsx'
import { type ContractInformationNodeData, ContractInformations } from './ContractInformations.jsx'
import { Metadatas, type MetadataNodeData } from './Metadatas.jsx'
import { CollectiveAgreements } from './CollectiveAgreementsInformations.jsx'
import type { CollectiveAgreementNodeData } from './CollectiveAgreementsInformations.jsx'

type PeopleTableEditorProps = {||}

type Item =
  | ContractAttributeNodeData
  | PersonAttributeNodeData
  | PersonInformationNodeData
  | ContractInformationNodeData
  | CollectiveAgreementNodeData
  | MetadataNodeData

type SchemaType = Schema<Item>

export function PeopleTableEditor(props: PeopleTableEditorProps): React$Node {
  const { peopleSchemaId } = useParams()
  const history: History = useHistory()

  const peopleSchemas = useSelector(peopleSchemasResourcesSelector)

  const peopleSchema = useMemo<SchemaType | void>(
    () => find(peopleSchemas, (peopleSchema) => peopleSchema.id === peopleSchemaId),
    [peopleSchemaId, peopleSchemas],
  )

  useEffect(() => {
    resources.peopleSchemas.fetchAll()
  }, [])

  function onSelectTable(option: Option) {
    history.push(`/hr/settings/people-table-editor/${option.value || ''}`)
  }

  function onSchemaChange(schema: SchemaType) {
    return resources.peopleSchemas.update(schema)
  }

  function settingsUpdater(peopleSchema: SchemaType, path: string[], value: Object) {
    if (!path.length) return { ...peopleSchema, ...value }

    const newSchema = { ...peopleSchema }

    if (path.length === 1) {
      const groupIndex = newSchema.schema.findIndex((group) => group.key === path[0])
      Object.assign(newSchema.schema[groupIndex].settings, value)
    }

    if (path.length === 2) {
      const groupIndex = newSchema.schema.findIndex((group) => group.key === path[0])
      const columnIndex = newSchema.schema[groupIndex].items.findIndex((group) => group.key === path[1])
      Object.assign(newSchema.schema[groupIndex].items[columnIndex].settings, value)
    }
    return newSchema
  }

  function changeColor(path: string[], color: string) {
    if (!peopleSchema) return Promise.resolve()
    return onSchemaChange(settingsUpdater(peopleSchema, path, { color }))
  }

  function editColumn(path: string[]) {
    if (!peopleSchema) return

    const groupIndex = peopleSchema.schema.findIndex((group) => group.key === path[0])
    const columnIndex = peopleSchema.schema[groupIndex].items.findIndex((group) => group.key === path[1])
    const column = peopleSchema.schema[groupIndex].items[columnIndex]

    if (!column) return

    openModal(
      <ModalEditSchemaColumn
        column={column}
        onSave={(newColumn) => {
          const schema = [...peopleSchema.schema]
          schema[groupIndex].items.splice(columnIndex, 1, newColumn)
          return onSchemaChange({ ...peopleSchema, schema })
        }}
      />,
    )
  }

  function deleteColumn(path: string[]) {
    if (!peopleSchema) return

    confirmDelete({
      render: 'Are you sure you want to delete this column?',
      onValidate: () => {
        const schema = [...peopleSchema.schema]
        const groupIndex = schema.findIndex((group) => group.key === path[0])
        const columnIndex = schema[groupIndex].items.findIndex((group) => group.key === path[1])
        schema[groupIndex].items.splice(columnIndex, 1)
        return onSchemaChange({ ...peopleSchema, schema })
      },
    })
  }

  function addGroup() {
    if (!peopleSchema) return

    openModal(
      <ModalEditSchemaGroup
        onSave={(newGroup) => {
          if (newGroup.settings.readOnly) {
            newGroup.items.forEach((item) => {
              item.settings.readOnly = newGroup.settings.readOnly
            })
          }

          const newSchemaType = { ...peopleSchema }
          newSchemaType.schema.unshift(newGroup)

          return onSchemaChange(newSchemaType)
        }}
      />,
    )
  }

  function editGroup(path: string[]) {
    if (!peopleSchema) return

    const groupIndex = peopleSchema.schema.findIndex((group) => group.key === path[0])
    const group = peopleSchema.schema[groupIndex]

    if (!group) return

    openModal(
      <ModalEditSchemaGroup
        group={group}
        onSave={(newGroup) => {
          if (newGroup.settings.readOnly) {
            newGroup.items.forEach((item) => {
              item.settings.readOnly = newGroup.settings.readOnly
            })
          }

          const newSchemaType = { ...peopleSchema }
          newSchemaType.schema[groupIndex] = newGroup

          return onSchemaChange(newSchemaType)
        }}
      />,
    )
  }

  function deleteGroup(path: string[]) {
    if (!peopleSchema) return

    confirmDelete({
      render: 'Are you sure you want to delete this group?',
      onValidate: () => {
        const schema = [...peopleSchema.schema]
        const groupIndex = schema.findIndex((group) => group.key === path[0])
        schema.splice(groupIndex, 1)
        return onSchemaChange({ ...peopleSchema, schema })
      },
    })
  }

  function addTable() {
    openModal(
      <ModalEditPeopleTableSchema
        onSaved={(newPeopleSchema) => {
          history.push(`/hr/settings/people-table-editor/${newPeopleSchema.id}`)
        }}
      />,
    )
  }

  function editTable() {
    openModal(<ModalEditPeopleTableSchema peopleSchema={peopleSchema} />)
  }

  function deleteTable() {
    if (!peopleSchema) return
    confirmDelete({
      render: `Are you sure you want to remove ${peopleSchema.name}?`,
      onValidate: async () => {
        const res = await resources.peopleSchemas.delete(peopleSchema.id)
        return res
      },
      validateMessage: 'Table deleted',
    })
  }

  function setNode<Data>(item, path): $Shape<NodeConstructor<Data>> {
    if (!item.items) {
      const { settings } = item
      const { readOnly, color } = settings

      return {
        name: <span className={classes.label}>{settings.columnName || settings.name || item.name}</span>,
        actions: [
          readOnly
            ? {
                key: 'readOnly',
                'data-cy': 'lock',
                label: <FontIcon icon="fas-lock" />,
                tooltip: 'This column is in "Read Only" mode.',
                onClick: () => {},
              }
            : null,
          {
            key: 'color',
            'data-cy': 'color',
            label: (
              <ColorPickerPopper color={color} onChange={(color) => changeColor(path, color)} tooltip="Change color" />
            ),
            onClick: () => {},
          },
          {
            key: 'menu',
            'data-cy': 'menu',
            onClick: () => editColumn(path),
            label: <FontIcon icon="fas-cog" />,
            tooltip: 'Column settings',
          },
          {
            key: 'delete',
            'data-cy': 'delete',
            label: <FontIcon icon="fas-trash" />,
            onClick: () => deleteColumn(path),
            tooltip: 'Remove column',
          },
        ].filter(Boolean),
      }
    }

    const { settings } = item
    const { color } = settings

    return {
      actions: [
        {
          key: 'color',
          'data-cy': 'color',
          onClick: () => {},
          label: (
            <ColorPickerPopper color={color} onChange={(color) => changeColor(path, color)} tooltip="Change color" />
          ),
        },
        {
          key: 'edit',
          'data-cy': 'edit',
          onClick: () => editGroup(path),
          label: <FontIcon icon="fas-cog" />,
          tooltip: 'Group settings',
        },
        {
          key: 'delete',
          'data-cy': 'delete',
          label: <FontIcon icon="fas-trash" />,
          onClick: () => deleteGroup(path),
          tooltip: 'Remove group',
        },
      ],
    }
  }

  function onAddExternalNode(data: Item): SchemaColumn<Item> | null {
    let item
    switch (data.contentType) {
      case 'contractAttribute': {
        item = data.contractAttribute
        break
      }
      case 'personAttribute': {
        item = data.personAttribute
        break
      }
      case 'contractInformation': {
        item = data.contractInformation
        break
      }
      case 'personInformation': {
        item = data.personInformation
        break
      }
      case 'collectiveAgreement': {
        item = data.collectiveAgreement
        break
      }
      case 'metadata': {
        item = data.metadata
        break
      }
      default:
        break
    }

    if (!item) return null

    const schemaColumn: SchemaColumn<Item> = {
      key: uuid(),
      name: item.displayName || item.name,
      settings: {
        name: item.displayName || item.name,
        columnName: item.displayName || item.name,
        description: '',
        hiddenable: true,
        contentType: data.contentType,
        color: '',
        fixable: true,
        isVisible: true,
        hidden: false,
        readOnly: false,
      },
      data,
    }

    return schemaColumn
  }

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <ExpansionPanel title="Person information" data-cy="PeopleTableEditor-personInformations">
          <PersonInformations />
        </ExpansionPanel>
        <ExpansionPanel title="Person attributes" data-cy="PeopleTableEditor-personAttributes">
          <PersonAttributesEditor />
        </ExpansionPanel>
        <ExpansionPanel title="Contract information" data-cy="PeopleTableEditor-contractInformations">
          <ContractInformations />
        </ExpansionPanel>
        <ExpansionPanel title="Contract attributes" data-cy="PeopleTableEditor-contractAttributes">
          <ContractAttributesEditor />
        </ExpansionPanel>
        <ExpansionPanel title="Collective agreements" data-cy="PeopleTableEditor-collectiveAgreements">
          <CollectiveAgreements />
        </ExpansionPanel>
        <ExpansionPanel title="Metadatas" data-cy="PeopleTableEditor-metadatas">
          <Metadatas />
        </ExpansionPanel>
      </div>
      <div className={classes.rightContainer}>
        <Widget style={{ padding: 5, height: '100%', width: '100%' }}>
          <div className="flex column fullWidth fullHeight">
            <div className="flex row end fullWidth">
              <div style={{ width: 250 }}>
                <Autocomplete
                  options={map(peopleSchemas, (peopleSchema) => ({
                    label: peopleSchema.name,
                    value: peopleSchema.id,
                  }))}
                  placeholder="Search a table"
                  value={peopleSchema ? { label: peopleSchema.name, value: peopleSchema.id } : null}
                  onChange={onSelectTable}
                />
              </div>
              <MUIButton icon="fas-plus" tooltip="Create new table" style={{ marginLeft: 10 }} onClick={addTable} />
            </div>

            <div style={{ height: 'calc(100% - 30px)' }}>
              {peopleSchema ? (
                <div style={{ height: '100%', overflowY: 'auto', position: 'relative' }}>
                  <div className={classes.tableTitle} data-cy="people-table-name">
                    <FontIcon
                      icon="fas-table"
                      style={{ color: 'rgb(178, 185, 199)', fontSize: '1.2em', marginRight: 10 }}
                    />
                    {peopleSchema.name}
                    <Tooltip title="Edit table settings">
                      <div style={{ marginLeft: 5 }} className={classes.viewTableButton} onClick={editTable}>
                        <FontIcon icon="fas-cog" />
                      </div>
                    </Tooltip>
                    <Tooltip title="Add a folder">
                      <div className={classes.viewTableButton} onClick={addGroup}>
                        <FontIcon icon="fas-folder-plus" />
                      </div>
                    </Tooltip>
                    <Tooltip title={`Remove table ${peopleSchema.name}`}>
                      <div className={classes.viewTableButton} onClick={deleteTable}>
                        <FontIcon icon="fas-trash" />
                      </div>
                    </Tooltip>
                    <Tooltip title="Go to people table">
                      <div
                        className={classes.viewTableButton}
                        onClick={() => {
                          history.push(`/hr/contracts/${peopleSchema.id}`)
                        }}
                      >
                        <FontIcon icon="fas-external-link-alt" />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="paddingLeft30" style={{ height: '100%' }}>
                    <TableConstructor
                      schema={peopleSchema}
                      onSchemaChange={onSchemaChange}
                      setNode={setNode}
                      onAddExternalNode={onAddExternalNode}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Widget>
      </div>
    </div>
  )
}
