import PropTypes from 'prop-types'

export default (propType, explanation) => {
  return (props, propName, componentName) => {
    const check = PropTypes.checkPropTypes({ [propName]: propType }, props, 'prop', componentName)

    if (props[propName] !== undefined) {
      const message = `"${propName}" has been deprecated on "${componentName}". ${explanation}`

      // console.warn(message);

      throw new Error(message)
    }

    return check
  }
}
