// @flow
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { startsWith } from 'lodash'
import cx from 'classnames'
import history from 'app/main/routerHistory'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { Permission } from 'app/containers/Permissions'
import { type IconType } from 'app/core/types'
import classes from './NavItem.module.scss'

type Props = {|
  label: string,
  icon?: IconType | React.Element<any>,
  onClick?: Function,
  to: string,
  permissions?: Array<string>,
  collapse?: boolean,
|}

export default class NavItem extends React.Component<Props> {
  renderIcon(): React$Node {
    const { icon } = this.props
    if (typeof icon === 'string')
      return (
        <div className={classes.iconContainer}>
          <FontIcon icon={icon} />
        </div>
      )
    if (icon) return icon
    return null
  }

  render(): React$Node {
    const { to, label, permissions, collapse, icon, ...rest } = this.props
    const active = startsWith(history.location.pathname, to)

    return (
      <Permission actions={permissions} operator="or">
        <NavLink
          to={to}
          style={active ? { backgroundColor: '#666F78', fontWeight: 'bold' } : {}}
          className={cx(classes.container, classes.itemContainer, 'relative')}
          title={this.props.label}
          {...rest}
        >
          {active && (
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                height: 'calc(100% - 1px)',
                width: '8px',
                backgroundColor: '#3dc3d2b3',
                color: '#ffffff',
              }}
            />
          )}
          <div
            className={cx(classes.label, {
              [classes.collpased]: collapse,
              [classes.haveIcon]: typeof icon === 'string',
            })}
          >
            {this.renderIcon()}
            <div className={classes.inner}>{label}</div>
          </div>
        </NavLink>
      </Permission>
    )
  }
}
