// @flow

import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { TopicsActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const topics: StoreResourceDef<TopicsActions<>> = {
  resourceType: 'topics',
  actions: (actionsCreators) => ({
    fetchAllByAsset: (assetId, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.assets
            .topics({ id: assetId, ...params.queries }, params.queries, params.headers)
            .then(transformResults(config)),
      }
    },
  }),
  relations: {
    threadsInst: {
      type: 'hasMany',
      resourceType: 'threads',
      foreignKey: 'threads',
    },
  },
}
