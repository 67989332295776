/*eslint-disable */

import React from 'react'
import Icon from '../Icon.jsx'

export default class Planet_3 extends React.Component {
  static defaultProps = {
    width: 18,
    height: 18,
    fill: 'grey',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 18 18">
        <path
          d="M13.5,4.5c-1.1-1.1-3-1.1-4.1,0c-1.1,1.1-1.1,3,0,4.1c1.1,1.1,3,1.1,4.1,0C14.6,7.5,14.6,5.7,13.5,4.5z
                     M10.1,7.9c-0.7-0.7-0.7-1.9,0-2.7s1.9-0.7,2.7,0c0.7,0.7,0.7,1.9,0,2.7S10.8,8.6,10.1,7.9z"
        />
        <path
          d="M14.7,12.3c0.3-0.5,0.3-0.8,0.2-1.1c-0.1-0.3-0.3-0.5-0.5-0.7c0.7-0.6,1.4-1.4,2-2.2c2.6-3.9,1.3-7.5,1.2-7.7
                    c-0.1-0.1-0.2-0.2-0.3-0.3C17.2,0.3,13.5-1,9.7,1.6c-0.8,0.5-1.5,1.3-2.2,2C7.3,3.3,7,3.2,6.7,3.1C6.5,3,6.1,3,5.7,3.3
                    C4.5,4.1,1.5,6.8,1.4,6.9C1.2,7,1.2,7.3,1.2,7.5c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.2l3.1,0.1C4.7,8,4.7,8,4.7,8
                    c-0.1,0.1-0.1,0.3,0,0.4c0,0,0.1,0.1,0.1,0.3L4.2,9.4C4.1,9.5,4.1,9.5,4,9.6c0,0.1,0,0.4,0.1,0.6L0.9,14c-0.1,0.1-0.2,0.3-0.1,0.5
                    c0.1,0.2,0.2,0.3,0.4,0.3l0.2,0l-1.2,2.4C0,17.4,0,17.7,0.2,17.8c0,0,0,0,0,0C0.3,18,0.6,18,0.8,17.9l4.1-2.3l0.1,0.1
                    C5,15.9,5.1,16,5.3,16c0.2,0,0.3,0,0.4-0.1l2-2C8,14,8.2,14,8.4,14c0.1,0,0.2-0.1,0.2-0.1l0.7-0.7c0.2,0.1,0.3,0.1,0.3,0.1
                    c0.1,0.1,0.3,0.1,0.4,0c0,0,0.1,0,0.1-0.1l0.1,3.1c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0,0.6-0.1
                    C11.2,16.5,13.9,13.5,14.7,12.3z M3.1,6.8c1-0.9,2.5-2.1,3.2-2.6c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.3,0.4
                    C6.2,5.3,5.6,6.2,5.3,6.9L3.1,6.8z M5.5,14.6c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.3,0-0.4,0l-3,1.7l0.7-1.4c0.1-0.1,0.1-0.3,0-0.5
                    c-0.1-0.1-0.2-0.2-0.3-0.3l2.5-2.8c0.2,0.3,0.6,0.7,1,1.1c0.4,0.4,0.8,0.8,1.1,1L5.5,14.6z M8,12.9c-0.2-0.1-0.7-0.4-1.7-1.3
                    c-0.9-0.9-1.2-1.4-1.3-1.7l0.4-0.4c0.3,0.4,0.8,1,1.4,1.6s1.1,1.1,1.6,1.4L8,12.9z M9.8,12.2c-0.4-0.2-1.2-0.7-2.2-1.8
                    C6.5,9.4,6,8.6,5.8,8.2c0.4-0.9,2.1-4.1,4.5-5.7c2.9-2,5.7-1.4,6.5-1.2c0.2,0.8,0.8,3.6-1.2,6.5C13.9,10.2,10.7,11.8,9.8,12.2z
                     M11.1,12.7c0.7-0.4,1.5-0.9,2.4-1.5c0.2,0.1,0.4,0.3,0.4,0.3c0,0.1,0,0.2-0.1,0.2c-0.5,0.7-1.8,2.2-2.6,3.2L11.1,12.7z"
        />
      </Icon>
    )
  }
}

/* eslint-enable */
