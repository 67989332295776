// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-LoaderSmall-___LoaderSmall-module__loader,
.app-components-LoaderSmall-___LoaderSmall-module__loader:after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.app-components-LoaderSmall-___LoaderSmall-module__loader {
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: app-components-LoaderSmall-___LoaderSmall-module__load8 1.1s infinite linear;
}
@keyframes app-components-LoaderSmall-___LoaderSmall-module__load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./app/components/LoaderSmall/LoaderSmall.module.scss"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EAGpB,wBAAwB;EAExB,uFAAqC;AACvC;AAWA;EACE;IAEE,uBAAuB;EACzB;EACA;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader,\n.loader:after {\n  border-radius: 50%;\n  width: 12px;\n  height: 12px;\n}\n\n.loader {\n  position: relative;\n  text-indent: -9999em;\n  -webkit-transform: translateZ(0);\n  -ms-transform: translateZ(0);\n  transform: translateZ(0);\n  -webkit-animation: load8 1.1s infinite linear;\n  animation: load8 1.1s infinite linear;\n}\n@-webkit-keyframes load8 {\n  0% {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n@keyframes load8 {\n  0% {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `app-components-LoaderSmall-___LoaderSmall-module__loader`,
	"load8": `app-components-LoaderSmall-___LoaderSmall-module__load8`
};
export default ___CSS_LOADER_EXPORT___;
