// @flow
import { mapValues } from 'lodash'
import { actionTypes } from 'redux-resource'

const actionCreateKeys = {
  read: 'read',
  create: 'create',
  update: 'update',
  delete: 'delete',
}

const statuses = {
  idle: 'idle',
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
}

const types: {
  [key: 'read' | 'update' | 'delete' | 'create']: {
    idle: string,
    pending: string,
    succeeded: string,
    failed: string,
    local: string,
  },
} = mapValues(actionCreateKeys, (type) => {
  return {
    ...mapValues(statuses, (status) => actionTypes[`${type.toUpperCase()}_RESOURCES_${status.toUpperCase()}`]),
    local: type === 'delete' ? actionTypes.DELETE_RESOURCES : actionTypes.UPDATE_RESOURCES,
  }
})

export default types
