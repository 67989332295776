/** @flow */
import React, { useEffect, useState } from 'react'
import type { EditorProps } from 'app/components/TextEditor/Editor.jsx'
import { Editor } from 'app/components/TextEditor/Editor.jsx'
import Fade from '@material-ui/core/Fade'
import { ToggleButtonGroup, ToggleButton } from 'app/components/Form'
import { permission } from 'app/containers/Permissions'
import TextRead from 'app/components/TextEditor/TextRead.jsx'

import './EditorFullScreen.module.scss'

type Props = {|
  ...$Exact<EditorProps>,
  handleClose?: Function,
  mode: 'READ_ONLY' | 'PREVIEW' | 'EDITION',
  onEdit: Function,
  onLeaveEdit: Function,
  onSave: Function,
  onClose: Function,
  onDelete: Function,
  isSaving: boolean,
  dataHasChanged: boolean,
|}

export function EditorFullScreen(props: Props): React$Node {
  const {
    handleClose,
    mode,
    onEdit,
    onLeaveEdit,
    onSave,
    onClose,
    onDelete,
    onInit,
    isSaving,
    dataHasChanged,
    value,
    diffValue,
    onChange,
    editor,
    ...editorProps
  } = props
  const [toolbar, setToolbar] = useState()

  useEffect(() => {
    const save = (
      <ToggleButton
        key="btn1Save"
        bgColor={dataHasChanged ? '#48C27E' : undefined}
        loader={isSaving}
        onClick={() => onSave(undefined, editor)}
        icon="fas-save"
        tooltip="Ctrl + S"
      >
        Save
      </ToggleButton>
    )

    const deleteArticle = permission(['wiki__articles_delete']) ? (
      <ToggleButton icon="fas-trash" key="delete1Btn" disabled={isSaving} bgColor="#E56D7A" onClick={onDelete}>
        Delete article
      </ToggleButton>
    ) : null

    const close = (
      <ToggleButton key="btn1Close" bgColor="#B3B9C7" onClick={onClose} icon="fas-times-circle" tooltip="Ctrl + Enter">
        Close
      </ToggleButton>
    )

    const edit = permission(['wiki__articles_update', 'wiki__articles_create', 'wiki__articles_delete'], 'or') ? (
      <ToggleButton key="btn1Edit" color="primary" onClick={onEdit} icon="fas-edit" tooltip="Ctrl + E">
        Edit
      </ToggleButton>
    ) : null

    const leaveEdit = (
      <ToggleButton key="btn2Edit" color="primary" onClick={onLeaveEdit} icon="fas-sign-out-alt" tooltip="Ctrl + E">
        Quit editing
      </ToggleButton>
    )

    if (mode === 'READ_ONLY') {
      setToolbar([edit, close])
      if (!editor.current?.isReadOnly) editor.current?.enableReadOnlyMode('my-feature-id')
    } else if (mode === 'EDITION') {
      setToolbar([leaveEdit, save, deleteArticle, close])
      if (editor.current?.isReadOnly) editor.current?.enableReadOnlyMode('my-feature-id')
    }
  }, [mode, dataHasChanged, isSaving])

  useEffect(() => () => handleClose?.(), [])

  return (
    <Fade in={true}>
      <div
        className="flex column fullWidth overflowYAuto"
        style={{
          height: 'calc(100vh - 40px)',
          width: '100vw',
          position: 'fixed',
          backgroundColor: 'whitesmoke',
          left: 0,
          top: 40,
          zIndex: 200,
        }}
      >
        <div className="relative">
          <div style={{ position: 'sticky', top: 0, zIndex: 100 }}>
            <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
              {mode === 'EDITION' ? <div id="ovm-editor-toolbar" /> : undefined}
            </div>
            <div
              style={{ position: 'absolute', zIndex: 10, top: mode === 'EDITION' ? 45 : 0, right: 15 }}
              className="flex"
            >
              <ToggleButtonGroup style={{ margin: 5, backgroundColor: '#f5f5f5' }}>{toolbar}</ToggleButtonGroup>
            </div>
          </div>
          <div className="flex center fullWidth">
            <div
              className={`ovm-fullscreen-editor ${diffValue ? 'ck-diff-value' : ''}`}
              style={{ backgroundColor: '#ffffff', margin: '90px 50px', maxWidth: 1500, width: '100%' }}
            >
              {mode !== 'EDITION' ? (
                <div className="ck-content">
                  <TextRead text={value || ''} padding={15} />
                </div>
              ) : (
                <Editor
                  {...editorProps}
                  diffValue={diffValue}
                  onInit={(editorEl) => {
                    editor.current = editorEl
                    onInit?.(editorEl)
                  }}
                  onChange={(value) => {
                    onChange(value, editor)
                  }}
                  value={value}
                  getToolbar="ovm-editor-toolbar"
                  decoupled={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}
