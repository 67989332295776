/** @flow */
import React from 'react'
import moment from 'moment'
import { map, startsWith, isNumber } from 'lodash'
import { durationStr } from 'app/components/Duration/Duration.tsx'

import { MakeTableFromArray } from './MakeTableFromArray.jsx'

type Props = {|
  stats: { [key: string]: number | string | Array<Object> },
  fields?: Array<string>,
  cellHeight?: number,
  dontRound?: boolean,
  tableId: ?string,
  updateFields?: (item: any) => any,
|}

export function DisplayDefault(props: Props): React$Node {
  const { stats, fields, updateFields, cellHeight, dontRound, tableId } = props

  if (!stats) return <div />

  let sectionSpace

  function displayValue(value, key) {
    if (Array.isArray(value)) {
      if (fields && fields.indexOf(key) === -1) return null
      if (value.length === 0) return null

      const arr = []

      value.forEach((item) => {
        arr.push(updateFields ? updateFields(item) : item)
      })

      return (
        <div style={{ height: cellHeight }} key={key}>
          <MakeTableFromArray
            tableId={tableId}
            values={arr}
            referenceName={Object.keys(arr[0])[0]}
            style={{ height: '100%' }}
          />
        </div>
      )
    }

    if (fields && !fields.reduce((a, b) => a || startsWith(key, b), false)) return null
    if (!dontRound && isNumber(value)) value = Math.round(value * 100) / 100

    let type
    let formatValue
    let needBr = false
    const splittedKey = key.split('__')

    if (splittedKey.length === 2) type = splittedKey[0]
    else if (splittedKey.length === 3) type = splittedKey[1]

    if (sectionSpace !== splittedKey[0]) {
      needBr = true
      sectionSpace = splittedKey[0]
    }

    if (['estimLength', 'duration', 'quoteEstimLength', 'realEstimLength'].indexOf(type) !== -1)
      formatValue = (val: number) => durationStr(val || 0, 7.8)
    if (['startDate', 'endDate'].indexOf(type) !== -1) formatValue = (val) => moment(val).calendar()

    return (
      <div key={key} className={`flex row wrap alignCenter ${needBr ? 'marginTop10' : ''}`}>
        <span className="marginRight5 bold">{splittedKey.join(' ')}: </span>
        {
          // $FlowFixMe[incompatible-call]
          formatValue ? formatValue(value) : value
        }
      </div>
    )
  }
  // $FlowFixMe[incompatible-call]
  return <>{map(stats, displayValue)}</>
}
