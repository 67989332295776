// @flow

export default {
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  CMD: 91,
  ALT: 18,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  V: 86,
  ESCAPE: 27,
  TOP: 38, // deprecated. Use UP instead
  BOTTOM: 40, // deprecated. Use UP instead
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  BACKSPACE: 8,
  DELETE: 46,
  SPACE: 32,
}
