/** @flow */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@material-ui/core'
import sortBy from 'lodash/sortBy'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import { setUserSettings } from 'app/libs/helpers/userSettings'
import type {
  SearchResults,
  SearchItemResult,
  SearchTypeResults,
  IconType,
  ResourcesList,
  ProgressionStatus,
  Option,
  Asset,
  ID,
} from 'app/core/types'
import { getResources } from 'app/store/selectors/getResources'
import { MUISelect } from 'app/components/Form/Select/MUISelect.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'

import { SearchingGridItem } from './SearchingGridItem.jsx'

type Props = {|
  results: ?SearchResults,
  closePopper: () => void,
  progressionStatus: ResourcesList<ProgressionStatus>,
  searchIn: Array<string>,
  setSearchIn: (Array<string>) => void,
  selectedProjects: Array<ID>,
  setSelectedProjects: (Array<ID>) => void,
  projects: ResourcesList<Asset>,
  setIsSelectOpened: (boolean) => void,
|}

export type ResolvedItem = {|
  ...SearchItemResult,
  icon?: IconType,
  label?: string,
  type: SearchTypeResults,
|}

export function SearchingResults(props: Props): React$Node {
  const {
    results,
    closePopper,
    progressionStatus,
    searchIn,
    setSearchIn,
    selectedProjects: _selectedProjects,
    setSelectedProjects,
    projects,
    setIsSelectOpened,
  } = props

  const selectedProjects: Array<Option> = useSelector((state) => {
    if (!Array.isArray(_selectedProjects) || _selectedProjects.length === 0) {
      return [{ label: 'All projects', value: 'all' }]
    }

    return map(getResources(state, 'assets', _selectedProjects), (project) => {
      return {
        label: project.name,
        value: project.id,
      }
    })
  })

  function getTypeContent(type: string) {
    switch (type) {
      case 'assets':
        return { icon: 'fas-cube', label: 'Assets' }
      case 'postboardnotes':
        return { icon: 'fas-sticky-note', label: 'Postboard Notes' }
      case 'articles':
        return { icon: 'fas-book-reader', label: 'Articles' }
      case 'dynamicapprovalvalues':
        return { icon: 'fas-clipboard-check', label: 'Dynamic Approvals' }
      case 'tasks':
        return { icon: 'fas-tasks', label: 'Tasks' }

      default:
        return {}
    }
  }

  const sortedResults: Array<ResolvedItem> = useMemo(() => {
    // $FlowFixMe
    const cleanedResults = reduce(
      results,
      (acc, r: SearchItemResult, type: SearchTypeResults) => {
        if (r.hits.length) acc.push({ ...r, type, ...getTypeContent(type), hits: sortBy(r.hits, ['_score']).reverse() })
        return acc
      },
      [],
    )

    return (
      sortBy(cleanedResults, ['type'])
        // unable to get link arounf follow-up
        .filter((res) => res.type !== 'dynamicapprovalvalues')
    )
  }, [results])

  function _setSearchIn(types: Array<Option>) {
    const values = types.map((option) => option.value).filter((option) => option !== 'all')
    setSearchIn(values)
    setUserSettings(`searchingBar-searchIn`, values)
  }

  function _setSelectedProjects(projects: Array<Option>) {
    const projectIds = projects.map((option) => option.value).filter((option) => option !== 'all')
    setSelectedProjects(projectIds)
    setUserSettings(`searchingBar-project`, projectIds)
  }

  function getTypeIcon(type) {
    switch (type) {
      case 'assets':
        return 'fas-cube'
      case 'articles':
        return 'fas-book-reader'
      case 'tasks':
        return 'fas-tasks'
      case 'postboardnotes':
        return 'fas-sticky-note'
      case 'dynamicapprovalvalues':
        return 'fas-clipboard-check'
      default:
        return 'fas-asterisk'
    }
  }

  function getOptions(list: ResourcesList<Object> | Array<Object>, label: string) {
    return [{ label: `All ${label}`, value: 'all' }].concat(
      sortBy(
        map(list, (item) => ({ label: item.name, value: item.id, icon: item.icon })),
        ['label'],
      ),
    )
  }

  function getOnChange(onChange: (Array<Option>) => void, label: string) {
    return (values: Array<Option>, previousValues: Array<Option>) => {
      const allOpt = values.find((opt) => opt.value === 'all')
      const prevAllOpts = previousValues.find((opt) => opt.value === 'all')
      if (values.length === 0 || (allOpt && !prevAllOpts)) return onChange([])
      return onChange(values)
    }
  }

  const typesOptions = [
    { label: 'All types', value: 'all', icon: getTypeIcon() },
    { label: 'Assets', value: 'assets', icon: getTypeIcon('assets') },
    { label: 'Wiki', value: 'articles', icon: getTypeIcon('articles') },
    { label: 'Tasks', value: 'tasks', icon: getTypeIcon('tasks') },
    {
      label: 'Postboard Notes',
      value: 'postboardnotes',
      icon: getTypeIcon('postboardnotes'),
    },
    // {
    //   label: 'Dynamic Approvals',
    //   value: 'dynamicapprovalvalues',
    //   icon: getTypeIcon('dynamicapprovalvalues'),
    // },
  ]

  const typesValues = useMemo(() => {
    if (!Array.isArray(searchIn) || searchIn.length === 0) {
      return [typesOptions[0]]
    }
    return searchIn.map((type) => typesOptions.find((option) => option.value === type))
  }, [searchIn])

  return (
    <div
      style={{
        padding: '10px 20px 20px 20px',
      }}
    >
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          boxShadow: 'rgb(0 0 0 / 30%) 0px 2px 11px 5px',
          maxWidth: '50vw',
          minWidth: 550,
          maxHeight: '80vh',
          borderRadius: '4px',
          overflow: 'hidden',
          backgroundColor: '#ffffff',
          overflowY: 'auto',
        }}
      >
        <div
          className="flex end alignCenter"
          style={{ position: 'sticky', top: 0, backgroundColor: '#f3f3f3', borderBottom: '1px solid lightgrey' }}
        >
          <div className="flex row noWrap">
            <MUISelect
              fullWidth={false}
              options={getOptions(projects, 'projects')}
              value={selectedProjects}
              style={{ marginRight: 10, border: 'none' }}
              onChange={getOnChange(_setSelectedProjects, 'projects')}
              multiple={true}
              onOpen={(e) => setIsSelectOpened(true)}
              onClose={(e) => setIsSelectOpened(false)}
              renderValue={(selected, options) => {
                const { length } = selected
                if (length > 1) return `In ${length} projects`
                return options[selected[0]]?.label
              }}
            />

            <MUISelect
              fullWidth={false}
              options={typesOptions}
              value={typesValues}
              style={{ marginRight: 10, border: 'none' }}
              onChange={getOnChange(_setSearchIn, 'types')}
              multiple={true}
              onOpen={(e) => setIsSelectOpened(true)}
              onClose={(e) => setIsSelectOpened(false)}
              renderValue={(selected, options) => {
                if (selected.length === 1 && options[selected[0]]) {
                  return (
                    <span className="fullWidth flex end">
                      {options[selected[0]].icon ? (
                        <FontIcon icon={options[selected[0]].icon} className="fontSize16 marginRight5" />
                      ) : null}
                      {options[selected[0]].label}
                    </span>
                  )
                }

                return (
                  <div className="fullWidth flex row noWrap end">
                    {selected.map((index) => {
                      if (!options[index]) return ''

                      return options[index].icon ? (
                        <FontIcon icon={options[index].icon} className="fontSize16 marginLeft5" />
                      ) : (
                        options[index].label
                      )
                    })}
                  </div>
                )
              }}
            />
          </div>
        </div>

        {sortedResults.length === 0 ? (
          <div style={{ width: '100%', height: 80 }} className="lightgrey bold flex center alignCenter">
            No results
          </div>
        ) : (
          <Grid container={true} spacing={0} style={{ margin: 0 }}>
            {sortedResults.map((item, index) => {
              const len = sortedResults.length
              const isPair = len % 2 === 0
              const isLastItem = len === index + 1
              const only2 = len === 2
              const xs = only2 ? 12 : isPair ? 6 : isLastItem ? 12 : 6
              const numberOfRows = len === 1 ? 1 : Math.round(len / 2)

              return (
                <SearchingGridItem
                  key={item.type}
                  item={item}
                  closePopper={closePopper}
                  xs={xs}
                  index={index}
                  progressionStatus={progressionStatus}
                  height={`calc(calc(80vh - 30px) / ${numberOfRows})`}
                />
              )
            })}
          </Grid>
        )}
      </Box>
    </div>
  )
}
