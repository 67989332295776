import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import { useEffect, useMemo } from 'react';
import { BreakdownTable } from 'app/pages/Project/Breakdown/BreakdownTable.jsx';
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL';
import { permission } from 'app/containers/Permissions';
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
export default function (props) {
    const { asset } = props;
    const projectId = (asset === null || asset === void 0 ? void 0 : asset.project) || getProjectIdFromURL();
    useEffect(() => {
        if (asset)
            documentTitle(asset.name);
    }, [asset]);
    const resourceParams = useMemo(() => asset
        ? {
            resourceType: 'assets',
            requestName: 'childrenWithLinks',
            headers: asset ? { [window.OVM_PROJECT_HEADER]: asset.project || '' } : undefined,
            includedResources: [
                'assetLinksInst.to_assetInst',
                'assetFlagsInst',
                'assetFlagsInst.flagInst',
                'thumbnailInst',
            ],
            requestData: {
                id: asset.id,
            },
        }
        : undefined, [asset]);
    if (!projectId) {
        return (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter lightgrey", style: { padding: 10, fontSize: 14 }, children: `You have to be in the ${(asset === null || asset === void 0 ? void 0 : asset.name) || ''} project to be able to see the breakdown` }));
    }
    if (projectId && !permission(['projet_breakdown__read'], undefined, undefined, projectId)) {
        return (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter lightgrey", style: { padding: 10, fontSize: 14 }, children: `You don't have permission to see the breakdown table.` }));
    }
    return (_jsx("div", { style: { width: `100%` }, children: _jsx(BreakdownTable, { tableId: "assetBreakdownEpisode", parentAssetId: asset === null || asset === void 0 ? void 0 : asset.id, enableEdit: permission(['projet_breakdown_links_create']), projectId: projectId, resourcesParams: resourceParams }) }));
}
