/** @flow */
import React from 'react'
import { ResponsivePie } from '@nivo/pie'

export type PieProps = {|
  data: Array<{
    id: string,
    label: string,
    value: number,
    color?: string,
  }>,
  radialLabel?: Function,
  sliceLabel?: Function,
  formatLabel?: Function,
  formatValue?: Function,
  colors?: Function,
|}

export function Pie({ data, sliceLabel, radialLabel, formatLabel, formatValue, ...rest }: PieProps): React$Node {
  function formatTooltip(data) {
    const { color, label, value } = data
    return (
      <div className="flex row noWrap">
        <div className="marginRight10" style={{ height: 20, width: 22, backgroundColor: color, borderRadius: 2 }} />
        <span className="flex row noWrap center alignCenter">
          {formatLabel ? formatLabel(label) : label}: {formatValue ? formatValue(value) : value}
        </span>
      </div>
    )
  }

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 50, right: 350, bottom: 50, left: 150 }}
      pixelRatio={2}
      innerRadius={0.2}
      padAngle={0.7}
      cornerRadius={3}
      colors={{ scheme: 'paired' }}
      borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
      radialLabelsSkipAngle={5}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: 'color' }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      sliceLabel={sliceLabel}
      radialLabel={radialLabel}
      tooltip={formatTooltip}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          translateX: 140,
          itemWidth: 60,
          itemHeight: 11,
          itemsSpacing: 1,
          symbolSize: 10,
          symbolShape: 'circle',
        },
      ]}
      {...rest}
    />
  )
}
