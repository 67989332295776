// @flow
import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { getUserSettings, setUserSettings } from 'app/libs/helpers/userSettings'
import { useResizer } from 'app/hooks/useResizer'
import classes from './Widget.module.scss'

export type WidgetProps = {|
  className?: string,
  style?: Object,
  'data-cy'?: string,
  styleInner?: Object,
  styleContent?: Object,
  styleOpen?: Object,
  children: any,
  classNameInner?: string,
  enableHideColumn?: boolean | Function,
  noOverlay?: boolean,
  title?: string,
  collapseTitle?: string,
  maxWidth?: number,
  minWidth?: number,
  defaultWidth?: number | string,
  optionName?: string,
  isOpen?: boolean,
  resizable?: boolean,
  onSizeChange?: (size: number) => void,
  onOpenChange?: (open: boolean) => void,
|}

export default function (props: WidgetProps): React$Node {
  const {
    children,
    className,
    style,
    styleInner,
    styleContent,
    styleOpen,
    classNameInner,
    collapseTitle,
    optionName,
    enableHideColumn = false,
    onOpenChange,
    noOverlay,
    title,
    maxWidth = 800,
    minWidth = 20,
    defaultWidth: _defaultWidth,
    resizable,
    onSizeChange,
    ...rest
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(optionName ? getUserSettings(optionName) !== 'closed' : true)

  const resizerRef = useRef()
  const resizableRef = useRef()

  useEffect(() => {
    if (optionName) setUserSettings(optionName, isOpen ? 'open' : 'closed')
    if (typeof enableHideColumn === 'function') enableHideColumn(isOpen)
  }, [isOpen, optionName])

  function toggle() {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    onOpenChange?.(isOpen)
  }, [isOpen])

  function onResized(width: number) {
    if (optionName) setUserSettings(`${optionName}-width`, width)
    if (onSizeChange) onSizeChange(width)
  }

  const defaultWidth = useMemo(() => {
    if (optionName) {
      const userWidth = getUserSettings(`${optionName}-width`)
      if (userWidth) return userWidth
    }
    return style?.width || _defaultWidth || 'fit-content'
  }, [optionName, _defaultWidth, style])

  useEffect(() => {
    if (defaultWidth !== 'fit-content' && onSizeChange) {
      onSizeChange(Number(defaultWidth))
    }
  }, [defaultWidth, onSizeChange])

  useResizer({
    direction: 'horizontal',
    resizer: resizerRef,
    resizable: resizableRef,
    onResized,
    max: maxWidth,
    min: minWidth,
    disabled: !resizable,
  })

  const containerStyle = useMemo(
    () => ({
      ...style,
      ...(isOpen
        ? {
            width: defaultWidth,
          }
        : { maxWidth: minWidth }),
    }),
    [style, isOpen, defaultWidth, styleOpen, minWidth],
  )

  return (
    <div className={cx(classes.container, className)} style={containerStyle} {...rest} ref={resizableRef}>
      <div
        className={cx(classes.inner, classNameInner)}
        style={{
          ...styleInner,
          ...(enableHideColumn
            ? {
                overflow: 'hidden',
                height: '100%',
                width: '100%',
              }
            : {}),
        }}
      >
        {enableHideColumn && !isOpen && !noOverlay ? (
          <div className={classes.collapseOverlay} onClick={toggle}>
            <span className={classes.collapseTitle}>{collapseTitle}</span>
          </div>
        ) : null}

        {title ? <div className={classes.title}>{title}</div> : null}

        <div
          className={classes.contentContainer}
          style={{ height: enableHideColumn ? `calc(100% - 40px)` : '100%', ...styleContent }}
        >
          {typeof children === 'function' ? children({ isOpen }) : children}
        </div>

        {enableHideColumn && (
          <div className={classes.resizeContainer} onClick={toggle}>
            <div className={classes.resizeInner}>
              <div style={{ minWidth: 18 }} className={classes.resizeInner}>
                <FontIcon icon={isOpen ? 'fas-angle-left' : 'fas-angle-right'} />
              </div>
              <div
                style={{
                  opacity: isOpen ? 1 : 0,
                  position: isOpen ? 'initial' : 'absolute',
                  userSelect: 'none',
                }}
              >
                Collapse
              </div>
            </div>
          </div>
        )}
      </div>
      {resizable && isOpen ? <div className={classes.resize} ref={resizerRef} /> : null}
    </div>
  )
}
