// @flow
import * as React from 'react'
import { map } from 'lodash'
import AssetsSelect, { type Props as AssetsSelectProps } from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import type { AssetTypes, ID } from 'app/core/types'

type Props = {|
  ...AssetsSelectProps,
  value: Object,
  onChange: Function,
  placeholder: string,
  assetTypes?: Array<AssetTypes>,
  groupId?: ID,
  noProjectHeader?: boolean,
  ressourceID?: ID,
  foreignKey?: string,
  inputRef?: Function,
  inputProps?: Object,
|}

export class AssetsRelations extends React.PureComponent<Props> {
  static defaultProps: $Shape<Props> = {
    allowCreate: true,
  }

  onChange: (value: Array<Object>) => void = (value) => {
    const { onChange, ressourceID, foreignKey } = this.props

    const output = map(value, ({ data, assetRelation }) => {
      return {
        ...assetRelation,
        ...(foreignKey ? { [foreignKey]: ressourceID } : {}),
        asset: data.id,
        assetInst: data,
      }
    })

    return onChange(output)
  }

  render(): React$Node {
    const {
      value,
      onChange,
      placeholder,
      assetTypes,
      groupId,
      noProjectHeader,
      foreignKey,
      ressourceID,
      inputProps,
      ...props
    } = this.props

    const finalValue = map(value, (assetRelation) => {
      const {
        assetInst: { id, name },
      } = assetRelation
      return { label: name, value: id, data: assetRelation.assetInst, assetRelation }
    })

    return (
      /* $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow */
      <AssetsSelect
        assetTypes={assetTypes}
        placeholder={placeholder}
        value={finalValue}
        multiple={true}
        onChange={this.onChange}
        groupId={groupId}
        noProjectHeader={noProjectHeader}
        inputProps={inputProps}
        {...props}
      />
    )
  }
}
