/** @flow */

import map from 'lodash/map'
import type { Option } from 'app/core/types'
import { getServerConfig } from './getServerConfig'

export const getContractStatus = (): Array<string> => {
  const config = getServerConfig()
  return config.CONTRACT_STATUS
}

export const getContractStatusColor = (contractStatus: string): string => {
  const colors = {
    creation: '#22ac00',
    planned: '#0095ff',
    signed: '#ff4949',
    signing: '#ffb584',
  }
  return colors[contractStatus]
}

export const getContractStatusOptions = (): Array<Option> => {
  const contractStatus: Object = getContractStatus()

  const options: Array<$Shape<Option>> = map(contractStatus, (label: string, value: string) => ({
    label,
    value,
    backgroundColor: getContractStatusColor(value),
  }))
  return options
}
