/** @flow */
import React, { useEffect, useRef } from 'react'
import PubSub from 'pubsub-js'
import { compact } from 'lodash'
import type { Asset, Pipe, Match, Location } from 'app/core/types'
import pipe from 'app/core/utils/pipe'

type Props = {|
  project: Asset | null,
  location: Location,
  match: Match,
|}

export const documentTitle = (title: ?string, type?: 'page' | 'trigram'): * =>
  PubSub.publish('DOCUMENT_TITLE', { title, type })

function DocumentTitleView(props: Props): React$Node {
  const { project, match, location } = props

  function getPage() {
    const splitedUrl = location.pathname.substring(1, location.pathname.length).split('/')
    if (splitedUrl[0] === 'projects') {
      if (splitedUrl[2] === 'settings') {
        if (splitedUrl[3] === 'follow-up') return 'Follow-up settings'
        if (splitedUrl[3] === 'groups') return 'Groups settings'
        if (splitedUrl[3] === 'steps') return 'Steps settings'
      }
      if (splitedUrl[2] === 'postboard-models') return 'MPN'
      if (splitedUrl[2] === 'postboard-shots') return 'SPN'
      if (splitedUrl[2] === 'breakdown') return 'Breakdown'
      if (splitedUrl[2] === 'follow-up') return 'Follow-up'
      if (splitedUrl[2] === 'tasks') return 'Tasks'
      if (splitedUrl[2] === 'assets') return 'Assets list'
    }
    if (splitedUrl[0] === 'assets-wiki') return 'Wiki'
    if (splitedUrl[0] === 'my-tasks') return 'My tasks'
    if (splitedUrl[0] === 'my-hours') return 'My hours'
    if (splitedUrl[0] === 'hr') {
      if (splitedUrl[1] === 'time-recap') return 'Time recap'
    }
    if (splitedUrl[0] === 'admin') return 'Admin'
    return ''
  }

  const sub = useRef()
  const trigram = useRef()
  trigram.current = project?.attributes?.trigram?.toUpperCase() || project?.name?.toUpperCase()
  const page = useRef()
  page.current = getPage()

  function makeTitle(title?: string) {
    const joinedTitle = compact([trigram.current, page.current, title]).join(' - ') || 'Overmind - Asset manager'
    document.title = joinedTitle
  }

  useEffect(() => {
    sub.current = PubSub.subscribe('DOCUMENT_TITLE', (name: string, { title, type }) => {
      if (type === 'page') {
        page.current = title
        makeTitle()
      } else if (type === 'trigram') {
        trigram.current = title
        makeTitle()
      } else makeTitle(title)
    })
    return () => PubSub.unsubscribe(sub.current)
  })

  useEffect(() => {
    makeTitle()
  }, [match, trigram.current])
  return null
}

const pipeInst: Pipe<{}, typeof DocumentTitleView> = pipe()

export const DocumentTitle: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => ({ project: state.project }))
  .render(({ history, ...props }) => <DocumentTitleView {...props} />)
