/** @flow */
import React, { Suspense } from 'react'
import type { EditorProps } from './EditorComponent.jsx'

const EditorComponent = React.lazy(() => import('./EditorComponent.jsx'))

function Editor(props: EditorProps): React$Node {
  return (
    <Suspense fallback="loading...">
      <EditorComponent {...props} />
    </Suspense>
  )
}

export type { EditorProps }
export { Editor }
