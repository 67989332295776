/** @flow */
import React, { useEffect, useMemo, useState } from 'react'
import map from 'lodash/map'
import resources from 'app/store/resources'
import type { TrackingSchema, TrackingSchemaAccess, Option, ID } from 'app/core/types'
import FormData from 'app/components/Form/FormData/FormData.jsx'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import uniqBy from 'lodash/uniqBy'
import { assetsTypes } from 'app/core/constants/assetsTypes'
import Loader from 'app/components/Loader/Loader.jsx'
import { updateTrackingSchemaAccess } from './utils'

type ModalEditTrackingSchemaTableProps = {|
  ...$Shape<$Diff<ModalProps, { children: any }>>,
  trackingSchema?: TrackingSchema,
  projectId: ID,
  handleNewTrackingSchema?: (trackingSchema: TrackingSchema | void) => void,
|}

export function ModalEditTrackingSchemaTable(props: ModalEditTrackingSchemaTableProps): React$Node {
  const { trackingSchema, projectId, handleNewTrackingSchema, ...rest } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [trackingSchemaAccesses, setTrackingSchemaAccesses] = useState<TrackingSchemaAccess[]>([])

  useEffect(() => {
    if (trackingSchema) {
      setIsLoading(true)
      const params = { queries: { page_size: 1000, trackingSchema__uuid: trackingSchema.id } }
      resources.trackingSchemaAccesses
        .fetchAll({ params })
        .then((res) => {
          setTrackingSchemaAccesses(res.resources)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  const assetTypeOptions: Array<Option> = map(assetsTypes, (label, value) => ({
    value,
    label,
    icon: assetIcons(value),
  })).filter(({ value }) => ['mo', 'sh', 'ep', 'fo', 'mi'].includes(value))

  async function onSave(data) {
    const {
      linkedAssets,
      assetType,
      isExternal,
      isVisible,
      name,
      hiddenAssetsSheet,
      hiddenTaskSheet,
      hiddenStats,
      hiddenExports,
      lockColumnOrder,
    } = data

    const newRestrictedActions = []
    if (hiddenAssetsSheet) newRestrictedActions.push('assetSheet')
    if (hiddenTaskSheet) newRestrictedActions.push('taskSheet')
    if (hiddenStats) newRestrictedActions.push('stats')
    if (hiddenExports) newRestrictedActions.push('exports')

    const updatedTrackingSchema: $Shape<TrackingSchema> = {
      project: projectId,
      assetType: typeof assetType === 'string' ? assetType : assetType.value,
      settings: {
        restrictedActions: newRestrictedActions,
        lockColumnOrder,
      },
      isExternal: Boolean(isExternal),
      isVisible,
      name,
      id: trackingSchema?.id,
    }

    if (!trackingSchema) updatedTrackingSchema.schema = []

    const trackingSchemasRes = await resources.trackingSchemas[trackingSchema?.id ? 'update' : 'create'](
      updatedTrackingSchema,
    )
    const newTrackingSchema = trackingSchemasRes.resources[0]
    if (trackingSchema && newTrackingSchema) {
      const res = await updateTrackingSchemaAccess(
        trackingSchemaAccesses,
        linkedAssets,
        trackingSchema,
        newTrackingSchema,
      )
      handleNewTrackingSchema?.(newTrackingSchema)
      return res
    }

    if (linkedAssets?.length && newTrackingSchema) {
      const res = await resources.trackingSchemaAccesses.create(
        linkedAssets.map((opt) => ({ asset: opt.value, trackingSchema: newTrackingSchema.id })),
      )
      handleNewTrackingSchema?.(newTrackingSchema)
      return res
    }

    handleNewTrackingSchema?.(newTrackingSchema)
    return trackingSchemasRes
  }

  const defaultData = useMemo(() => {
    if (trackingSchema) {
      const { restrictedActions = [], lockColumnOrder = false } = trackingSchema.settings || {}

      const linkedAssets = uniqBy<Object>(
        map(trackingSchemaAccesses, (tsa) => {
          return {
            label: tsa.assetInst?.name,
            value: tsa.assetInst?.id,
            icon: assetIcons(tsa.assetInst.assetType),
          }
        }),
        'label',
      )

      return {
        ...trackingSchema,
        assetType: {
          value: trackingSchema.assetType,
          label: trackingSchema.assetType,
        },
        hiddenAssetsSheet: restrictedActions.includes('assetSheet'),
        hiddenTaskSheet: restrictedActions.includes('taskSheet'),
        hiddenStats: restrictedActions.includes('stats'),
        hiddenExports: restrictedActions.includes('exports'),
        lockColumnOrder,
        linkedAssets,
      }
    }
    return {
      isVisible: true,
    }
  }, [trackingSchemaAccesses])

  return (
    <ModalConfirmForm
      title={trackingSchema ? `Update ${trackingSchema.name}` : 'Create a new table'}
      draggable={false}
      {...rest}
    >
      {isLoading ? (
        <div className="flex fullWidth center alignCenter" style={{ height: 300 }}>
          <Loader />
        </div>
      ) : (
        <FormData
          defaultData={defaultData}
          properties={[
            {
              key: 'name',
              label: 'Name',
              type: 'string',
              elementProps: {
                isRequired: true,
                placeholder: 'Table name',
              },
            },
            {
              key: 'assetType',
              label: 'Type',
              type: 'select',
              elementProps: {
                isRequired: true,
                fullWidth: true,
                placeholder: 'Asset type',
                options: assetTypeOptions,
              },
            },
            {
              key: 'hiddenAssetsSheet',
              label: 'Hide asset sheets',
              type: 'checkbox',
            },
            {
              key: 'hiddenTaskSheet',
              label: 'Hide task sheets',
              type: 'checkbox',
            },
            {
              key: 'hiddenStats',
              label: 'Hide stats',
              type: 'checkbox',
            },
            {
              key: 'hiddenExports',
              label: 'Hide exports',
              type: 'checkbox',
            },
            {
              key: 'isVisible',
              label: 'Visible by users',
              type: 'checkbox',
            },
            {
              key: 'lockColumnOrder',
              label: 'Lock column order',
              type: 'checkbox',
            },
            {
              key: 'isExternal',
              label: 'External Follow-up',
              type: 'checkbox',
            },
            {
              key: 'linkedAssets',
              label: ((
                <div className="textNoWrap textCenter">
                  Users / Groups <br />
                  authorized
                </div>
              ): React$Node),
              type: 'autocomplete',
              isShow: ({ isExternal }: *): boolean => isExternal === true,
              element: AssetsSelect,
              elementProps: (data) => ({
                placeholder: 'Search user/group...',
                multiple: true,
                value: data.linkedAssets || [],
                model: 'assets',
                showAssetType: true,
                assetTypes: ['gp', 'us'],
              }),
            },
          ]}
          onSave={onSave}
          flashNotifSuccessLabel={`Table ${trackingSchema ? 'updated' : 'created'}`}
        />
      )}
    </ModalConfirmForm>
  )
}
