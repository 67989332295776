// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetDetail-Infos-___Infos-module__infos {
  display: flex;
  padding: 10px;
  width: 100%;
  position: relative;
}

.app-containers-Assets-AssetDetail-Infos-___Infos-module__leftContent {
  flex: 1;
}

.app-containers-Assets-AssetDetail-Infos-___Infos-module__rightContent {
  position: absolute;
  right: 20px;
  top: 20px;
}

.app-containers-Assets-AssetDetail-Infos-___Infos-module__input {
}

.app-containers-Assets-AssetDetail-Infos-___Infos-module__attributesContainer {
  padding: 5px;
  margin-top: 20px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.app-containers-Assets-AssetDetail-Infos-___Infos-module__attributesLabel {
  position: absolute;
  padding: 0 5px;
  top: -10px;
  height: 20px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: bolder;
  background-color: #ffffff;
}

.app-containers-Assets-AssetDetail-Infos-___Infos-module__field {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.app-containers-Assets-AssetDetail-Infos-___Infos-module__fieldValue {
  flex: 1;
}

.app-containers-Assets-AssetDetail-Infos-___Infos-module__infoLabel {
  margin-right: 5px;
  font-weight: bold;
}

.app-containers-Assets-AssetDetail-Infos-___Infos-module__flagItem {
  display: inline-block;
  color: #fff;
  background-color: var(--colors-grey);
  margin: 0 3px;
  padding: 0 10px;
  border-radius: 30px;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetDetail/Infos/Infos.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;AACA;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,qCAAqC;AACvC;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;EACf,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,oCAAoC;EACpC,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".infos {\n  display: flex;\n  padding: 10px;\n  width: 100%;\n  position: relative;\n}\n\n.leftContent {\n  flex: 1;\n}\n\n.rightContent {\n  position: absolute;\n  right: 20px;\n  top: 20px;\n}\n\n.input {\n}\n\n.attributesContainer {\n  padding: 5px;\n  margin-top: 20px;\n  position: relative;\n  border: 1px solid rgba(0, 0, 0, 0.05);\n}\n.attributesLabel {\n  position: absolute;\n  padding: 0 5px;\n  top: -10px;\n  height: 20px;\n  line-height: 20px;\n  color: rgba(0, 0, 0, 0.5);\n  font-size: 12px;\n  font-weight: bolder;\n  background-color: #ffffff;\n}\n\n.field {\n  display: flex;\n  align-items: center;\n  margin: 10px 0;\n}\n\n.fieldValue {\n  flex: 1;\n}\n\n.infoLabel {\n  margin-right: 5px;\n  font-weight: bold;\n}\n\n.flagItem {\n  display: inline-block;\n  color: #fff;\n  background-color: var(--colors-grey);\n  margin: 0 3px;\n  padding: 0 10px;\n  border-radius: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infos": `app-containers-Assets-AssetDetail-Infos-___Infos-module__infos`,
	"leftContent": `app-containers-Assets-AssetDetail-Infos-___Infos-module__leftContent`,
	"rightContent": `app-containers-Assets-AssetDetail-Infos-___Infos-module__rightContent`,
	"input": `app-containers-Assets-AssetDetail-Infos-___Infos-module__input`,
	"attributesContainer": `app-containers-Assets-AssetDetail-Infos-___Infos-module__attributesContainer`,
	"attributesLabel": `app-containers-Assets-AssetDetail-Infos-___Infos-module__attributesLabel`,
	"field": `app-containers-Assets-AssetDetail-Infos-___Infos-module__field`,
	"fieldValue": `app-containers-Assets-AssetDetail-Infos-___Infos-module__fieldValue`,
	"infoLabel": `app-containers-Assets-AssetDetail-Infos-___Infos-module__infoLabel`,
	"flagItem": `app-containers-Assets-AssetDetail-Infos-___Infos-module__flagItem`
};
export default ___CSS_LOADER_EXPORT___;
