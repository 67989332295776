// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-OrganizationChart-___OrganizationChart-module__container {
  height: 100%;
}

.app-pages-OrganizationChart-___OrganizationChart-module__embla {
  --slide-spacing: 1rem;
  --slide-size: 80%;
  --slide-height: 19rem;
  padding: 1.2rem;
  background-color: #41474d;
  position: relative;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: 25rem;
}

.app-pages-OrganizationChart-___OrganizationChart-module__textNewArrivals {
  color: white;
  position: absolute;
  top: 0.5rem;
  left: 3.3rem;
  font-size: 20px;
  font-family: 'Courier New', Courier, monospace;
}

.app-pages-OrganizationChart-___OrganizationChart-module__textNoNewArrivals {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
}

.app-pages-OrganizationChart-___OrganizationChart-module__skeletonContainer {
  background-color: white;
  margin: 1.25rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.app-pages-OrganizationChart-___OrganizationChart-module__cardContainer {
  height: calc(100vh - 35rem);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  padding: 10px;
  flex-wrap: wrap;
  width: 90%;
  margin: 20px auto;
}
`, "",{"version":3,"sources":["webpack://./app/pages/OrganizationChart/OrganizationChart.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,qBAAqB;EACrB,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,4BAAuB;EAAvB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,8CAA8C;AAChD;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,eAAe;EACf,8CAA8C;AAChD;;AAEA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,UAAU;EACV,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".container {\n  height: 100%;\n}\n\n.embla {\n  --slide-spacing: 1rem;\n  --slide-size: 80%;\n  --slide-height: 19rem;\n  padding: 1.2rem;\n  background-color: #41474d;\n  position: relative;\n  min-height: fit-content;\n  height: 25rem;\n}\n\n.textNewArrivals {\n  color: white;\n  position: absolute;\n  top: 0.5rem;\n  left: 3.3rem;\n  font-size: 20px;\n  font-family: 'Courier New', Courier, monospace;\n}\n\n.textNoNewArrivals {\n  color: white;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-size: 16px;\n  font-family: 'Courier New', Courier, monospace;\n}\n\n.skeletonContainer {\n  background-color: white;\n  margin: 1.25rem auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 18rem;\n  width: 50%;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.cardContainer {\n  height: calc(100vh - 35rem);\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  overflow-y: auto;\n  padding: 10px;\n  flex-wrap: wrap;\n  width: 90%;\n  margin: 20px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-OrganizationChart-___OrganizationChart-module__container`,
	"embla": `app-pages-OrganizationChart-___OrganizationChart-module__embla`,
	"textNewArrivals": `app-pages-OrganizationChart-___OrganizationChart-module__textNewArrivals`,
	"textNoNewArrivals": `app-pages-OrganizationChart-___OrganizationChart-module__textNoNewArrivals`,
	"skeletonContainer": `app-pages-OrganizationChart-___OrganizationChart-module__skeletonContainer`,
	"cardContainer": `app-pages-OrganizationChart-___OrganizationChart-module__cardContainer`
};
export default ___CSS_LOADER_EXPORT___;
