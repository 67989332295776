// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Notifications-Notification-___Notification-module__container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-top: 1px solid var(--colors-greyLight);
  cursor: pointer;
}

  .app-containers-Notifications-Notification-___Notification-module__container.app-containers-Notifications-Notification-___Notification-module__isLastNotif {
    border-bottom: 1px solid var(--colors-greyLight);
  }

  .app-containers-Notifications-Notification-___Notification-module__container:not(.app-containers-Notifications-Notification-___Notification-module__isRead) {
    background-color: var(--colors-mainColorLight);
  }

  .app-containers-Notifications-Notification-___Notification-module__container:hover {
    background-color: var(--colors-mainColorMedium);
  }

  .app-containers-Notifications-Notification-___Notification-module__container:hover .app-containers-Notifications-Notification-___Notification-module__notifDate {
      color: var(--colors-blackLight);
    }

.app-containers-Notifications-Notification-___Notification-module__notifDate {
  font-style: italic;
  color: var(--colors-grey);
}
`, "",{"version":3,"sources":["webpack://./app/containers/Notifications/Notification/Notification.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,6CAA6C;EAC7C,eAAe;AAiBjB;;EAfE;IACE,gDAAgD;EAClD;;EAEA;IACE,8CAA8C;EAChD;;EAEA;IACE,+CAA+C;EAKjD;;EAHE;MACE,+BAA+B;IACjC;;AAIJ;EACE,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 5px;\n  border-top: 1px solid var(--colors-greyLight);\n  cursor: pointer;\n\n  &.isLastNotif {\n    border-bottom: 1px solid var(--colors-greyLight);\n  }\n\n  &:not(.isRead) {\n    background-color: var(--colors-mainColorLight);\n  }\n\n  &:hover {\n    background-color: var(--colors-mainColorMedium);\n\n    .notifDate {\n      color: var(--colors-blackLight);\n    }\n  }\n}\n\n.notifDate {\n  font-style: italic;\n  color: var(--colors-grey);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-containers-Notifications-Notification-___Notification-module__container`,
	"isLastNotif": `app-containers-Notifications-Notification-___Notification-module__isLastNotif`,
	"isRead": `app-containers-Notifications-Notification-___Notification-module__isRead`,
	"notifDate": `app-containers-Notifications-Notification-___Notification-module__notifDate`
};
export default ___CSS_LOADER_EXPORT___;
