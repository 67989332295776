// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import Bowser from 'bowser'
import cx from 'classnames'
import pipe from 'app/core/utils/pipe'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import PubSub from 'pubsub-js'
import resources from 'app/store/resources'
import settings from 'app/core/settings'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { getResources } from 'app/store/selectors/getResources'
import type { ID } from 'app/core/types/ID'
import classes from './Notif.module.scss'

const browser = Bowser.getParser(window.navigator.userAgent)

function Message({ message, userId }: { message: Object, userId: ID }) {
  const messageTypeClassKey = `messageType_${message.messageType}`

  const messageTypeClassName = classes[messageTypeClassKey]

  const className = cx([classes.message, messageTypeClassName])

  const closeBtn = message.disableHide ? null : (
    <FontIcon
      icon="close"
      className={classes.messageClose}
      onClick={(e) => {
        resources.messages.hideMessage(message, userId)
      }}
    />
  )

  return (
    <div className={className}>
      <div className={classes.messageText}>
        {typeof message.text === 'string' ? <span dangerouslySetInnerHTML={{ __html: message.text }} /> : message.text}
      </div>
      {closeBtn}
    </div>
  )
}

Message.propTypes = {
  message: PropTypes.object.isRequired,
  userId: PropTypes.string,
}

type Props = {
  messages: {
    [messageId: string]: {
      name: string,
      messageType: string,
    },
  },
  userId: string,
}

type State = {
  show: false,
}

class Notif extends React.PureComponent<Props, State> {
  componentDidMount() {
    PubSub.subscribe('ON_RECEIVE_CONFIG', this.onReceiveConfig)
  }

  isNotValidBrowser = () => !browser.satisfies({ chrome: '>0', opera: '>0', chromium: '>0' })

  onReceiveConfig = (msg: string, config: Object) => {
    const { userId } = this.props

    const messages = []

    if (settings.env.NODE_ENV === 'preproduction') {
      messages.push({
        id: 'preprod',
        title: '',
        text: 'Environment Preproduction',
        messageType: '1',
        disableHide: true,
      })
    }

    if (this.isNotValidBrowser()) {
      messages.push({
        id: 'validBrowser',
        title: 'Warning',
        text: (
          <span aria-hidden="true">
            The browser you are currently using is not supported by Overmind. If you encounter any problems, please do
            not contact Overmind support. The browsers currently available are{' '}
            <a href="https://www.google.com/intl/en_en/chrome/" aria-hidden="true">
              Chrome
            </a>
            ,{' '}
            <a href="https://www.chromium.org/" aria-hidden="true">
              Chromium
            </a>{' '}
            and{' '}
            <a href="https://www.opera.com/en/download" aria-hidden="true">
              Opera
            </a>
            .
          </span>
        ),
        messageType: '0',
        disableHide: true,
      })
    }

    resources.messages.addAuthenticatedMessages([...config.MESSAGES, ...messages], userId)
  }

  render(): React$Node {
    const { messages, userId } = this.props

    if (!Object.values(messages).length === 0) return false

    return (
      <div className={classes.container}>
        {map(messages, (message) => (
          // $FlowFixMe[prop-missing] $FlowFixMe Error when updating flow
          <Message key={message.id} message={message} userId={userId} />
        ))}
      </div>
    )
  }
}

const pipeInst: Pipe<{}, typeof Notif> = pipe()

const Component: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    return {
      messages: {
        ...getResources(undefined, 'messages.lists.notif'),
        ...getResources(undefined, 'messages', { disablePermanentHide: true }),
      },
      userId: state.user.asset,
    }
  })
  .render(Notif)

export default Component
