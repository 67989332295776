import { createLocalResource } from 'app/libs/createLocalResource'
import store from 'app/store/index.ts'

const getLocalStorageKey = () => {
  const { user } = store.getState()
  const userId = user && user.asset
  // if (!userId) throw new Error('getLocalStorageKey(userId) userId is undefind')
  return `ovmUserPrefs__${userId}`
}

export const userPrefLocalResource = createLocalResource(getLocalStorageKey)
