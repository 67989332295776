/** @flow */
import { keyBy, mapValues, map, pickBy } from 'lodash'
import type { StoreRequest } from 'app/core/types'
import type { Store } from 'app/store'

import uniqid from 'app/libs/uniqid.js'
import getPreDeletedResources from './getPreDeletedResources'
import getPreCreatedResources from './getPreCreatedResources'
import getPreUpdatedResources from './getPreUpdatedResources'

type Params = {
  preCreate: (data: Object | Array<Object>, config: Object) => Array<StoreRequest> | StoreRequest | null,
  revertPreCreate: () => Array<StoreRequest> | StoreRequest | null,
  preDelete: (data: string | Array<string>) => Array<StoreRequest> | StoreRequest | null,
  revertPreDelete: () => Array<StoreRequest> | StoreRequest | null,
  preUpdate: (data: Object | Array<Object>, config: Object) => Array<StoreRequest> | StoreRequest | null,
  revertPreUpdate: () => Array<StoreRequest> | StoreRequest | null,
}

export default (store: Store, resourceType: string): Params => ({
  preCreate: (data: Object | Array<Object>, config: Object = {}) => {
    const dataAsArray = Array.isArray(data) ? data : [data]
    const newResources = keyBy(
      dataAsArray.map((resource) => ({ ...resource, id: `LOCAL__${uniqid()}` })),
      'id',
    )
    const newResourcesMeta = mapValues(newResources, () => ({ preCreated: true }))

    return {
      type: 'update',
      requestKey: null,
      request: {
        resources: {
          [resourceType]: newResources,
        },
        meta: {
          [resourceType]: newResourcesMeta,
        },
        ...config,
      },
    }
  },
  revertPreCreate: () => {
    const preCreatedResources = getPreCreatedResources(store.getState()[resourceType])
    if (!Object.keys(preCreatedResources).length) return null
    return {
      type: 'delete',
      requestKey: null,
      request: {
        resources: {
          [resourceType]: map(preCreatedResources, (resource, key: string) => key),
        },
      },
    }
  },
  preDelete: (data: string | Array<string>) => {
    const dataAsArray = Array.isArray(data) ? data : [data]

    const resourcesPreCreated = getPreCreatedResources(store.getState()[resourceType])

    // keep only item not pre created
    const idsToPreDelete = dataAsArray.filter((id) => !resourcesPreCreated[id])
    const resourcesMeta = mapValues(
      keyBy(
        idsToPreDelete.map((id) => ({ id })),
        'id',
      ),
      (item) => ({ preDeleted: true }),
    )

    // item precreated will be delete
    const idsToDelete = dataAsArray.filter((id) => resourcesPreCreated[id])

    return [
      {
        type: 'update',
        requestKey: null,
        request: {
          meta: {
            [resourceType]: resourcesMeta,
          },
        },
      },
      {
        type: 'delete',
        requestKey: null,
        request: {
          resources: {
            [resourceType]: idsToDelete,
          },
        },
      },
    ]
  },
  revertPreDelete: () => {
    const preDeletedResources = getPreDeletedResources(store.getState()[resourceType])
    if (!Object.keys(preDeletedResources).length) return null
    return {
      type: 'update',
      requestKey: null,
      request: {
        meta: {
          [resourceType]: mapValues(preDeletedResources, () => ({ preDeleted: false })),
        },
      },
    }
  },
  preUpdate: (data: Object | Array<Object>, config: Object = {}) => {
    const dataAsArray = Array.isArray(data) ? data : [data]
    const idsToUpdate = dataAsArray.map(({ id }) => id)

    // get the resources before update
    const resourcesToPreUpdate = pickBy(store.getState()[resourceType].resources, (resource, id: string) => {
      return idsToUpdate.includes(id)
    })

    const newResources = keyBy(dataAsArray, 'id')
    // store in preUpdated the previous value of the resource to update
    const newResourcesMeta = mapValues(newResources, ({ id }) => ({ preUpdated: resourcesToPreUpdate[id] }))

    return {
      type: 'update',
      requestKey: null,
      request: {
        resources: {
          [resourceType]: newResources,
        },
        meta: {
          [resourceType]: newResourcesMeta,
        },
        ...config,
      },
    }
  },
  revertPreUpdate: () => {
    const slicedState = store.getState()[resourceType]
    const preUpdatedResources = getPreUpdatedResources(slicedState)
    if (!Object.keys(preUpdatedResources).length) return null

    const metaToRevert = pickBy(slicedState.meta, (resourceMeta, id: string) => resourceMeta.preUpdated)

    return {
      type: 'update',
      requestKey: null,
      request: {
        resources: {
          [resourceType]: map(preUpdatedResources, (resource, key: string) => metaToRevert[key].preUpdated),
        },
        meta: {
          [resourceType]: mapValues(preUpdatedResources, () => ({ preUpdated: null })),
        },
      },
    }
  },
})
