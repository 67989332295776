// @flow
import type { StoreResourceDef } from 'app/core/types'
import type { ProjectDepartmentJobsActions } from 'app/core/types/StoreResourcesCustomActions'
import api from 'app/core/api/api'
import { transformResults } from './utils/transformResults'

export const projectDepartmentJobs: StoreResourceDef<ProjectDepartmentJobsActions<>> = {
  resourceType: 'projectDepartmentJobs',
  actions: (actionsCreators) => ({
    fetchJobsImputations: (projectId, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.projectDepartmentJobs
            .imputations(null, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(transformResults(config)),
      }
    },
    fetchAllImputations: (projectId, config) => {
      const { params = {} } = config || {}

      const fetchAllImputations = (page, imputations = { count: 0, results: [] }) => {
        return api.projectDepartmentJobs
          .fetchAll(
            {
              ...params.queries,
              page_size: 1000,
              page,
            },
            params.headers,
            params.getHttpProgress,
            params.requestController,
          )
          .then((res) => {
            const results = {
              ...res,
              results: imputations?.results.concat(res?.results || []) || [],
            }
            if (res && res.next) return fetchAllImputations(page + 1, results)
            return results
          })
      }

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => fetchAllImputations(1).then(transformResults(config)),
      }
    },
  }),
  relations: {
    projectDepartmentInst: {
      type: 'hasOne',
      resourceType: 'projectDepartments',
    },
    jobInst: {
      type: 'hasOne',
      resourceType: 'jobs',
    },
    imputationsInst: {
      type: 'hasMany',
      resourceType: 'imputations',
      foreignKey: 'projectDepartmentJob',
    },
  },
}
