/** @flow */
import React from 'react'
import { MUICheckbox } from 'app/components/Form/Checkbox/MUICheckbox.jsx'
import type { Column, DefaultCellProps } from 'app/components/Table/types.js'
import { ControlledCheckbox } from './ControlledCheckbox.jsx'

type CellCheckboxParam = {|
  ...DefaultCellProps,
  alwaysBeingEdit?: boolean,
|}

export const CellCheckbox = ({ readOnly, alwaysBeingEdit, ...data }: CellCheckboxParam): Column => {
  return {
    cellType: 'CellCheckbox',
    Cell: (instance) => {
      const { cell } = instance
      const { value, getCellProps } = cell
      const { edition } = getCellProps()
      const { endEdit, save, isBeingEdit } = edition

      if (isBeingEdit || alwaysBeingEdit) {
        return (
          <div className="fullHeight flex center alignCenter">
            <ControlledCheckbox
              readOnly={Boolean(readOnly)}
              checked={Boolean(value)}
              onChange={(value) => {
                cell.focusCell()
                save(value)
              }}
              onBlur={(e: SyntheticFocusEvent<EventTarget>) => {
                if (alwaysBeingEdit) return
                // $FlowFixMe
                if (e.target.checked !== value) save(e.target.checked)
                else endEdit()
              }}
              ignoreFocus={alwaysBeingEdit}
            />
          </div>
        )
      }

      if (value === undefined) return null

      return (
        <div className="fullHeight flex center alignCenter">
          <MUICheckbox readOnly={true} checked={Boolean(value)} />
        </div>
      )
    },
    readOnly,
    groupingFns: {
      rating: {
        fn: (rows, columnId, instance) =>
          rows.reduce((prev, row, i) => {
            const resKey = String(row.values[columnId])
            prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
            prev[resKey].push(row)
            return prev
          }, {}),
        label: 'Value',
        headerRow: (cell) => {
          if (cell.value === undefined) return null
          return <MUICheckbox readOnly={true} checked={Boolean(cell.value)} />
        },
      },
    },
    width: 50,
    ...data,
  }
}
