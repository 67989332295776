/** @flow */
import React from 'react'
import { Editor, type EditorProps } from 'app/components/TextEditor/Editor.jsx'

export function TextEditor(): React$Element<(props: EditorProps) => React$Node> {
  function onInit(editor: Object) {
    window.CKEditor = editor
  }

  return <Editor onInit={onInit} className="fullWidth fullHeight" />
}
