import { jsx as _jsx } from "react/jsx-runtime";
import { TablePostBoardShots } from 'app/pages/Project/PostBoardShot/TablePostBoardShots.jsx';
import { permission } from 'app/containers/Permissions';
export default function (props) {
    const { asset } = props;
    if (!permission(['projet_production notes__read'], undefined, undefined, asset.project)) {
        return (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter lightgrey", style: { padding: 10, fontSize: 14 }, children: `You don't have permission to see the postboard shots table.` }));
    }
    return (_jsx("div", { style: { width: '100%' }, children: _jsx(TablePostBoardShots, { episodeId: asset.id }, asset.id) }));
}
