/** @flow */
import api from 'app/core/api/api.js'
import store from 'app/store/index.ts'
import type { StoreResourcesActions } from 'app/core/types'

import { createResources } from './utils/createResources'
import * as resources from './resources'

const createdResources: StoreResourcesActions = createResources(resources, api, store)

export default createdResources
