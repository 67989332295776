// @flow
import * as React from 'react'
import { find } from 'lodash'
import { Autocomplete } from 'app/components/Form'
import resources from 'app/store/resources'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { toHighlightJSX } from 'app/libs/helpers/toHighlightJSX.jsx'
import type { AutocompleteOption } from 'app/components/Form/Autocomplete/AutocompleteSingle/Option.jsx'
import type { Step } from 'app/core/types/Step'

export type Props = {|
  value: Object,
  onChange: Function,
  stepProjects: Array<Object>,
  steps: Array<Object>,
|}

type State = {|
  steps: Array<AutocompleteOption<Step>>,
|}

export class StepsRelations extends React.PureComponent<Props, State> {
  state: State = {
    steps: [],
  }

  onSearch: (value: string) => Promise<Array<AutocompleteOption<Step>>> = (value: string) => {
    const { steps } = this.props
    const mergedSteps = steps

    this.state.steps.forEach((option) => option.data && mergedSteps.push(option.data))

    if (!value) return Promise.resolve([])

    return resources.steps.search({ query: value }).then(({ plainResponse }) => {
      return plainResponse.results
        .map((step) => {
          if (find(mergedSteps, (mergedStep) => mergedStep.id === step.id)) {
            return undefined
          }
          return { value: step.id, label: step.name, labelCustom: toHighlightJSX(step.__highlight), data: step }
        })
        .filter((_) => _)
    })
  }

  renderLabel: ({|
    item: AutocompleteOption<Step>,
    onDelete: (e: SyntheticMouseEvent<any>) => void,
  |}) => React$Node = ({ item, onDelete }) => {
    return (
      <div style={{ color: '#ffffff' }}>
        {item.label}
        <FontIcon icon="close" style={{ padding: '0 0 0 4px', cursor: 'pointer' }} onClick={onDelete} />
      </div>
    )
  }

  onChange: (Array<Object>) => void = (steps) => {
    const { onChange } = this.props

    if (onChange) onChange(steps)

    this.setState({ steps })
  }

  render(): React$Node {
    const { onChange, ...props } = this.props

    return (
      <div>
        <Autocomplete
          {...props}
          onChange={this.onChange}
          searchable={true}
          multiple={true}
          onSearch={this.onSearch}
          renderLabel={this.renderLabel}
        />
      </div>
    )
  }
}
