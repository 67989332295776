// @flow
import * as React from 'react'
import cx from 'classnames'
import type { ID } from 'app/core/types'
import type { LinkProps } from 'app/components/LocalRouter/Link.jsx'
import classes from './TabsRouter.module.scss'

export type TabsType = {|
  permission?: Array<string>,
  operator?: 'and' | 'or',
  projectId?: ID,
  activeName?: string,
  key: string,
  name: string,
  parent?: string,
  params: Object,
  label: string,
  subList?: Array<TabsType>,
|}
export type TabsRouterProps = {|
  itemStyle?: Object,
  contentStyle?: Object,
  tabs: Array<TabsType>,
  localRouter: $Exact<{
    Link: React$ComponentType<LinkProps>,
    Route: *,
    router: Object,
  }>,
  noBorder?: boolean,
  className?: string,
  children: Array<null | React$Element<any> | Array<React$Element<any>>>,
|}

export default class Tabs extends React.Component<TabsRouterProps> {
  static defaultProps: $Shape<TabsRouterProps> = {
    noBorder: false,
  }

  renderNavSubItems(tabs: Array<TabsType>): React$Node {
    const { itemStyle, localRouter } = this.props
    const { Link, router } = localRouter

    const navElements = []

    tabs.forEach((tab: TabsType) => {
      const { label, subList, key, ...tabRest } = tab
      navElements.push(
        <Link
          key={key}
          localRouter={router}
          isSubItem={true}
          style={itemStyle}
          className={classes.navSubItem}
          activeClassName={classes.active}
          {...tabRest}
        >
          {label}
        </Link>,
      )
    })
    return (
      <div className={classes.navSubItemWrapper}>
        <div className={classes.navSubItemContainer}>{navElements}</div>
      </div>
    )
  }

  renderNavItems(tabs: Array<TabsType>): Array<React$Element<any>> {
    const { itemStyle, localRouter } = this.props
    const { Link, router } = localRouter

    const navElements = []

    tabs.forEach((tab: TabsType) => {
      const { label, subList, key, ...tabRest } = tab
      navElements.push(
        <Link
          key={key}
          localRouter={router}
          style={itemStyle}
          className={classes.navItem}
          activeClassName={classes.active}
          subItems={subList ? this.renderNavSubItems(subList) : undefined}
          {...tabRest}
        >
          {label}
        </Link>,
      )
    })

    return navElements
  }

  render(): React$Node {
    const { children, className, itemStyle, contentStyle, tabs, noBorder, localRouter, ...props } = this.props

    const _className = cx([classes.container, className])

    return (
      <div {...props} className={_className}>
        <div className={classes.nav}>{this.renderNavItems(tabs)}</div>
        <div className={classes.childrenContainer} style={contentStyle}>
          {children}
        </div>
      </div>
    )
  }
}
