/** @flow */
import React from 'react'
import type { CellInstance } from '../../types'
import { DisplayFlags } from './DisplayFlags.jsx'
import { Container } from './Container.jsx'

type Props = {|
  instance: CellInstance,
  columnName: string,
|}

export function CellStatsFlags(props: Props): React$Node {
  const { instance, columnName } = props
  const { stats } = instance
  if (!stats || !stats.default) return null

  const { flags } = stats.default

  return (
    <Container>
      <DisplayFlags flags={flags} instance={instance} columnName={columnName} />
    </Container>
  )
}
