/** @flow */
const originalAtob = window.atob
const originalBtoa = window.btoa

function unicdeAtob(b64) {
  let str = originalAtob(b64)
  str = escape(str)
  str = decodeURIComponent(str)
  return str
}

function unicodeBtoa(data) {
  return originalBtoa(unescape(encodeURIComponent(data)))
}

window.atob = unicdeAtob
window.btoa = unicodeBtoa
