/* eslint-disable no-use-before-define */
/** @flow */
import type { IconType } from 'app/core/types'
import type { NodeAction } from './NodeActions.jsx'

export const NODE_DIRECTORY = 'NODE_DIRECTORY'
export const NODE_ITEM = 'NODE_ITEM'

const selectedNodes = {}
const collapsedNodes = {}

export type NodeConstructor<Data> = {|
  key: string,
  type: 'NODE_DIRECTORY' | 'NODE_ITEM',
  name: React$Node,
  collapsed?: boolean,
  parent: Node<> | void,
  children?: Node<>[],
  data: Data,
  icon?: IconType,
  color?: string,
  actions?: NodeAction[],
  disableDrag?: boolean,
|}

export class Node<Data = any> {
  key: string

  type: 'NODE_DIRECTORY' | 'NODE_ITEM' = NODE_ITEM

  name: React$Node = ''

  icon: IconType | void

  faIcon: FAIcon | null

  color: string | void

  collapsed: boolean

  data: Data

  actions: NodeAction[] | void

  disableDrag: boolean = false

  parent: Node<> | void = undefined

  nextSibling: Node<> | void = undefined

  previousSibling: Node<> | void = undefined

  firstChild: Node<> | void = undefined

  lastChild: Node<> | void = undefined

  constructor({
    key,
    type,
    name,
    collapsed,
    parent,
    children,
    data,
    icon,
    faIcon,
    actions,
    color,
    disableDrag,
  }: NodeConstructor<Data>) {
    this.key = key
    this.type = type
    this.name = name
    this.disableDrag = Boolean(disableDrag)
    this.parent = parent
    this.data = data
    this.icon = icon
    this.faIcon = faIcon
    this.color = color
    this.actions = actions
    this.collapsed = collapsedNodes[key] ?? Boolean(collapsed)

    if (children) this.append(...children)
  }

  get children(): Node<>[] {
    const children: Node<>[] = []
    let node = this.firstChild
    while (node) {
      children.push(node)
      node = node.nextSibling
    }
    return children
  }

  getData<D>(): D | Data {
    return this.data
  }

  remove(): void {
    const { parent } = this
    if (!parent) {
      return
    }

    const prev = this.previousSibling
    const next = this.nextSibling

    if (prev) {
      prev.nextSibling = next
    } else {
      parent.firstChild = next
    }
    if (next) {
      next.previousSibling = prev
    } else {
      parent.lastChild = prev
    }
    this.parent = undefined
    this.previousSibling = undefined
    this.nextSibling = undefined
  }

  insertBefore(child: Node<>, next: Node<> | void): void {
    if (child === next) {
      return
    }
    if (child.includes(this)) {
      throw new Error('Cannot insert node to its descendant')
    }
    if (next && next.parent !== this) {
      throw new Error('The ref node is not a child of this node')
    }
    child.remove()

    const prev = next ? next.previousSibling : this.lastChild
    if (prev) {
      prev.nextSibling = child
    } else {
      this.firstChild = child
    }
    if (next) {
      next.previousSibling = child
    } else {
      this.lastChild = child
    }
    child.previousSibling = prev
    child.nextSibling = next
    child.parent = this
  }

  append: (children: Node<>) => void = (...children) => {
    for (const child of children) {
      this.insertBefore(child, undefined)
    }
  }

  includes(other: Node<>): boolean {
    if (this === other.parent) {
      return true
    }
    if (!other.parent) {
      return false
    }
    return this.includes(other.parent)
  }

  get root(): Node<> {
    return this.parent?.root ?? this
  }

  get selected(): boolean {
    return Boolean(selectedNodes[this.key])
  }

  toggleCollapse(): void {
    this.collapsed = !collapsedNodes[this.key]
    collapsedNodes[this.key] = !collapsedNodes[this.key]
  }

  select() {
    selectedNodes[this.key] = true
    for (const child of this.children) {
      child.deselect()
    }
  }

  deselect() {
    delete selectedNodes[this.key]
    for (const child of this.children) {
      child.deselect()
    }
  }

  get ancestorSelected(): boolean {
    return this.selected || (this.parent?.ancestorSelected ?? false)
  }

  get selectedDescendants(): Node<>[] {
    if (this.selected) {
      return [this]
    }
    return this.children.flatMap((child) => child.selectedDescendants)
  }
}
