// @flow
import React from 'react'
import type { Attribute, PersonAttribute, ContractAttribute } from 'app/core/types'
import { NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import type { AttrType, EditorType } from 'app/core/types/Attribute'
import classes from './AttributesEditor.module.scss'

type Props = {|
  attribute: Attribute | PersonAttribute | ContractAttribute,
  onSelect: Function,
  aggregated?: boolean,
  parentNode: Node<>,
|}

type Attr = {|
  ...$Shape<Attribute>,
  ...$Shape<PersonAttribute>,
  ...$Shape<ContractAttribute>,
|}

type Data = {|
  ...Attr,
  type: 'attributes',
  aggregated?: boolean,
  aggregateType?: 'attribute',
  aggregateDatas?: {
    name: string,
    attrType: AttrType,
    editorType: EditorType | null,
  },
|}

export function getAttributeNode(props: Props): Node<Data> {
  const { attribute, onSelect, aggregated, parentNode, type } = props

  let content = { ...attribute, type: type ?? 'attributes' }

  if (aggregated) {
    content = {
      aggregated,
      ...attribute,
      parentType: 'attributes',
      type: 'aggregation',
      aggregateType: type ?? 'attribute',
      aggregateDatas: {
        name: attribute.name,
        attrType: attribute.attrType,
        editorType: attribute.editorType,
      },
    }
  }

  return new Node({
    key: attribute.id,
    icon: 'fas-grip-vertical',
    name: (
      <div className={classes.listItemContainer} onClick={() => onSelect(attribute)}>
        <span className={classes.attributeLabel}>
          {aggregated ? <span>aggregated </span> : null}
          {attribute.displayName || attribute.name}
        </span>
      </div>
    ),
    data: content,
    parent: parentNode,
    type: NODE_ITEM,
  })
}
