// @flow
import { fromPairs, forEach, chain } from 'lodash'
import api from 'app/core/api/api'
import type { StoreResourceDef } from 'app/core/types'
import type { MediasActions } from 'app/core/types/StoreResourcesCustomActions'
import upload from 'app/core/utils/upload'
import { transformResults } from './utils/transformResults'

export const medias: StoreResourceDef<MediasActions<>> = {
  resourceType: 'medias',
  hooks: (getResourcesAPI, getResources) => ({
    beforeDelete: async (mediaIds) => {
      const resourceAPI = getResourcesAPI()

      const relations = ['takeRefMedias', 'takeValidationMedias']
      const includesResources = relations.map((key) => `${key}Inst`)

      const medias = getResources(undefined, 'medias', (media) => mediaIds.includes(media.id), includesResources)

      const relationIds = fromPairs(relations.map((rel) => [rel, []]))

      forEach(medias, (media) => {
        relations.forEach((key) => {
          const ids = Object.keys(media[`${key}Inst`])
          if (ids.length) {
            relationIds[key] = [...relationIds[key], ...ids]
          }
        })
      })

      await Promise.all(
        chain(relationIds)
          .pickBy((ids, key) => ids.length)
          .map((ids, key) => resourceAPI[key].delete(ids, { localOnly: true })),
      )

      return mediaIds
    },
  }),
  actions: (actionsCreators) => ({
    upload: (data, config = {}) => {
      return {
        type: 'create',
        requestKey: config?.requestKey,
        request: () => upload(data).then(transformResults(config)),
      }
    },
    fetchAllTakesValidationMedias: (data, config) => {
      const { params = {}, ...restConfig } = config || {}
      const { id } = data

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.assets
            .allTakesValidationMedias({ id }, { ...params.queries }, params.headers, params.getHttpProgress)
            .then(transformResults(restConfig)),
      }
    },
    fetchAllTakesPinnedMedias: (data, config) => {
      const { params = {}, ...restConfig } = config || {}
      const { id } = data

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.assets
            .allTakesPinnedMedias({ id }, { ...params.queries }, params.headers, params.getHttpProgress)
            .then(transformResults(restConfig)),
      }
    },
  }),
  relations: {
    takeRefMediasInst: {
      type: 'hasMany',
      resourceType: 'takeRefMedias',
      foreignKey: 'media',
    },
    takeValidationMediasInst: {
      type: 'hasMany',
      resourceType: 'takeValidationMedias',
      foreignKey: 'media',
    },
  },
}
