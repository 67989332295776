// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellMedias-___MediasList-module__groupBackground {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 25px;
  padding: 10px;
  text-transform: capitalize;
  opacity: 0.1;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellMedias/MediasList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,eAAe;EACf,aAAa;EACb,0BAA0B;EAC1B,YAAY;AACd","sourcesContent":[".groupBackground {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  font-size: 25px;\n  padding: 10px;\n  text-transform: capitalize;\n  opacity: 0.1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupBackground": `app-components-Table-Cells-CellMedias-___MediasList-module__groupBackground`
};
export default ___CSS_LOADER_EXPORT___;
