/** @flow */
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import { Title } from 'app/components/Texts/Title/Title.jsx'
import { Grid, Paper } from '@material-ui/core'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type {
  SearchSourceDynAppValue,
  SearchSourceTask,
  SearchSourceAsset,
  SearchSourceArticle,
  SearchSourcePostboardNote,
  ResourcesList,
  ProgressionStatus,
} from 'app/core/types'
import { getResources } from 'app/store/selectors/getResources.js'

import type { ResolvedItem } from './SearchingResults.jsx'
import classes from './SearchingResults.module.scss'

type Props = {|
  item: ResolvedItem,
  xs: ?number,
  index: number,
  closePopper: () => void,
  progressionStatus: ResourcesList<ProgressionStatus>,
  height: string,
|}

export function SearchingGridItem(props: Props): React$Node {
  const { item, xs, index, closePopper, progressionStatus, height } = props

  const resolvedHits = useMemo(() => {
    return item.hits.map((h, i) => ({
      ...h,
      _source: {
        ...h._source,
        projectInst: h._source.project ? getResources(undefined, 'assets', h._source.project) : null,
      },
    }))
  }, [item, progressionStatus])

  function getLink(res) {
    switch (res._index) {
      case 'assets': {
        const { _id } = res
        const source: SearchSourceAsset = res._source
        const { project } = source
        return `/projects/${project}/assets?assetDetails=/${_id}/informations`
      }
      case 'postboardnotes': {
        const source: SearchSourcePostboardNote = res._source
        const { project, episode, asset, assetType } = source
        return `/projects/${project}/postboard-${assetType === 'sh' ? 'shots' : 'models'}/${episode}?PostBoard${
          assetType === 'sh' ? 'Shots' : 'Models'
        }-filters=${btoa(`[["Asset","inList","${asset}"]]`)}`
      }
      case 'articles': {
        const { _id } = res
        const source: SearchSourceArticle = res._source
        const { asset } = source
        return `/assets-wiki/${asset}/${_id}`
      }
      case 'dynamicapprovalvalues': {
        const source: SearchSourceDynAppValue = res._source
        const { project } = source
        return `/projects/${project}`
      }
      case 'tasks': {
        const source: SearchSourceTask = res._source
        const { project, asset, step } = source
        return `/projects/${project}/assets?assetDetails=/${asset}/tasks${`&AssetDetailsTableTask-filters=${btoa(
          `[["Step","inList","${step}"]]`,
        )}`}`
      }

      default:
        return ''
    }
  }

  function getTitle(hit) {
    switch (item.type) {
      case 'tasks': {
        const [parent, title, step] = hit._source.title.split(' - ')
        return (
          <>
            <span className={classes.label}>{title}</span>
            <span className={classes.stepLabel}>{step}</span>
            <span className={classes.parentLabel} style={{ marginLeft: 'auto' }}>
              <FontIcon icon="fas-folder" className="paddingRight5 fontSize10" />
              {parent}
            </span>
          </>
        )
      }
      case 'dynamicapprovalvalues': {
        const [parent, asset, title] = hit._source.title.split(' - ')
        return (
          <>
            <span className={classes.label}>{title}</span>
            <span className={classes.parentLabel} style={{ marginLeft: 'auto' }}>
              {asset}
            </span>
            <span className={classes.parentLabel}>
              <FontIcon icon="fas-folder" className="paddingRight5 fontSize10" />
              {parent}
            </span>
          </>
        )
      }
      case 'postboardnotes': {
        const [episode, asset, step] = hit._source.title.split(' - ')
        return (
          <>
            <span className={classes.label}>{asset}</span>
            <span className={classes.stepLabel}>{step}</span>
            <span className={classes.parentLabel}>
              <FontIcon icon="fas-film" className="paddingRight5 fontSize10" />
              {episode}
            </span>
          </>
        )
      }
      case 'articles':
      case 'assets':
      default:
        return <span className={classes.label}>{hit._source.title}</span>
    }
  }

  function createResults() {
    return resolvedHits.map((hit, index) => {
      const { projectInst } = hit._source

      return (
        <Link onClick={closePopper} key={hit._id} className={classes.link} to={getLink(hit)}>
          {index > 0 ? <div style={{ height: 1, backgroundColor: 'rgba(0,0,0,0.07)', width: '100%' }} /> : null}
          <div className={`${classes.link} flex column noWrap padding8 fullWidth`}>
            <div className="fullWidth" style={{ marginBottom: 6 }}>
              {projectInst ? (
                <div className="floatRight">
                  <div className="flex row noWrap alignCenter">
                    <div className={classes.projectLabel}>
                      <FontIcon icon="fas-project-diagram" className="paddingRight5 fontSize10" />
                      {projectInst.attributes?.trigram || projectInst.name}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="flex row noWrap alignCenter">
                {hit._source.assetType ? (
                  <FontIcon icon={assetIcons(hit._source.assetType)} className="paddingRight5 grey fontSize12" />
                ) : null}
                {getTitle(hit)}
              </div>
            </div>
            {hit.highlight.doc.map((str, index) => {
              const splitedStr = str.split('\n')
              const result = splitedStr.filter((s) => s.includes('class="highlight"'))
              return (
                <div key={String(index)} className={classes.highlightString}>
                  <span style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: result[0] || '' }} />
                </div>
              )
            })}
          </div>
        </Link>
      )
    })
  }

  return (
    <Grid item={true} xs={xs}>
      <Paper
        elevation={0}
        square={true}
        style={{
          padding: 0,
          borderRight: index % 2 === 0 ? '1px solid rgba(0,0,0,0.1)' : undefined,
          width: '100%',
          minHeight: '100%',
          maxHeight: height,
          borderRadius: '0px',
        }}
      >
        <div
          className="flex row noWrap alignCenter"
          style={{
            width: '100%',
            padding: `8px 15px 8px 15px`,
            backgroundColor: 'rgba(0,0,0,0.06)',
          }}
        >
          {item?.icon ? <FontIcon icon={item.icon} className="paddingRight8" /> : null}
          <Title size="4">{item.label || item.type || ''}</Title>
          <div className="marginLeftAuto grey">{item.total.value}</div>
        </div>
        <div style={{ witdh: '100%', overflowY: 'auto', maxHeight: `calc(${height} - 36px)` }}>{createResults()}</div>
      </Paper>
    </Grid>
  )
}
