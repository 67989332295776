/** @flow */
import React from 'react'
import type { Topic } from 'app/core/types'
import * as Icons from 'app/components/Icons'
import classes from './Topic.module.scss'

type Props = {|
  index: number,
  topic: Topic,
  hasUnreadThread?: boolean,
|}

export function TopicItem(props: Props): React$Element<any> {
  const { hasUnreadThread, index, topic } = props
  const { name, threads } = topic

  let icon = null
  const planetsCount = 9
  const planetName = `Planet_${String((index % planetsCount) + 1)}`
  const PlanetApp = Icons.planets[planetName]

  icon = <PlanetApp />

  return (
    <div className={`${classes.item} flex row noWrap`} title={name}>
      {hasUnreadThread ? <div className={classes.notifUnread} /> : null}
      <div style={{ minWidth: 42 }} className="flex center alignCenter">
        {icon}
      </div>
      <div className="flex column" style={{ width: 'calc(100% - 50px)' }}>
        <div className={classes.text}>{name}</div>
        <div className={`${classes.subtext}`}>{`${threads ? threads.length : 0} threads`}</div>
      </div>
    </div>
  )
}
