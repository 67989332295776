/** @flow */
import React from 'react'
import { DisplayAssignedUsers } from './DisplayAssignedUsers.jsx'
import type { CellInstance } from '../../types'

type Props = {|
  instance: CellInstance,
  columnName: string,
|}

export function CellStatsAssignedUsers(props: Props): React$Node {
  const { instance, columnName } = props
  const { stats, tableId, filtersTools } = instance
  if (!stats || !stats.default) return null

  const { users__counts = [] } = stats.default

  return (
    <div style={{ height: 'calc(100% - 5px)' }}>
      <DisplayAssignedUsers
        users={users__counts}
        setFilters={filtersTools?.setUrlFilters}
        columnName={columnName}
        tableId={tableId}
      />
    </div>
  )
}
