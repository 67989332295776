// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Texts-Title-___Title-module__container {
  font-family: 'gothambold';
}

.app-components-Texts-Title-___Title-module__size-1 {
  font-size: 36px;
}

.app-components-Texts-Title-___Title-module__size-2 {
  font-size: 30px;
}

.app-components-Texts-Title-___Title-module__size-3 {
  font-size: 24px;
}

.app-components-Texts-Title-___Title-module__size-3 {
  font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Texts/Title/Title.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  font-family: 'gothambold';\n}\n\n.size-1 {\n  font-size: 36px;\n}\n\n.size-2 {\n  font-size: 30px;\n}\n\n.size-3 {\n  font-size: 24px;\n}\n\n.size-3 {\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Texts-Title-___Title-module__container`,
	"size-1": `app-components-Texts-Title-___Title-module__size-1`,
	"size-2": `app-components-Texts-Title-___Title-module__size-2`,
	"size-3": `app-components-Texts-Title-___Title-module__size-3`
};
export default ___CSS_LOADER_EXPORT___;
