// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-libs-helpers-CustomLogo-___CustomLogo-module__container {
  height: 40px;
  min-width: 42px;
  max-width: 42px;
  margin: 10px;
  border-radius: 3px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./app/libs/helpers/CustomLogo/CustomLogo.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".container {\n  height: 40px;\n  min-width: 42px;\n  max-width: 42px;\n  margin: 10px;\n  border-radius: 3px;\n  color: #ffffff;\n  font-weight: bold;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-libs-helpers-CustomLogo-___CustomLogo-module__container`
};
export default ___CSS_LOADER_EXPORT___;
