/** @flow */
import React from 'react'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import type { User } from 'app/core/types'
import { FormData } from 'app/components/Form'
import { reportingActions } from './reporting.config.js'

import { postMessage } from './reporting'

type Props = {|
  ...$Shape<$Rest<ModalProps, { children: React$Node }>>,
  user: User,
|}

export function ReportingModal(props: Props): React$Node {
  const { user, ...modalProps } = props

  return (
    <ModalConfirmForm
      title="Report an issue"
      validateLabel="Report an issue"
      width={800}
      draggable={true}
      {...modalProps}
    >
      <FormData
        defaultData={{
          url: window.location.href,
        }}
        properties={[
          {
            key: 'type',
            label: 'Type of issue',
            type: 'select',
            elementProps: {
              options: reportingActions.map((opt) => ({ label: opt.label, value: opt.label, action: opt.action })),
              fullWidth: true,
              isRequired: true,
              placeholder: 'Select a type of issue',
            },
          },
          {
            key: 'message',
            label: 'Message',
            isShow: (data) => !!data.type,
            type: 'textarea',
            elementProps: {
              style: { width: '100%' },
              rows: 10,
              placeholder: 'A description as complete as possible.',
              isRequired: true,
            },
          },
          {
            key: 'url',
            label: 'Url',
            isShow: (data) => !!data.type,
            type: 'string',
            elementProps: {
              isRequired: true,
            },
          },
          {
            key: 'attachments',
            label: 'Attachments',
            isShow: (data) => !!data.type,
            type: 'file',
            elementProps: {
              isRequired: false,
              accept: 'image/*',
              multiple: true,
            },
          },
        ]}
        onSave={(data) => {
          return postMessage({ user, ...data })
        }}
      />
    </ModalConfirmForm>
  )
}
