// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-SmartDate-___SmartDate-module__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.app-components-Form-SmartDate-___SmartDate-module__inputContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 10;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}

.app-components-Form-SmartDate-___SmartDate-module__inputContainer label {
    font-size: 12px;
    font-weight: bolder;
    margin-top: 5px;
  }

.app-components-Form-SmartDate-___SmartDate-module__subComponent {
  color: lightgrey;
  font-size: 11px;
  line-height: 12px;
}

.app-components-Form-SmartDate-___SmartDate-module__diff {
  line-height: 14px;
  font-size: 12px;
  position: absolute;
  right: 3px;
  top: 0px;
}

.app-components-Form-SmartDate-___SmartDate-module__input {
  height: 35px;
}

.app-components-Form-SmartDate-___SmartDate-module__error {
  color: var(--colors-red);
  font-size: 10px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/SmartDate/SmartDate.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,oCAAoC;EACpC,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,WAAW;AAOb;;AALE;IACE,eAAe;IACf,mBAAmB;IACnB,eAAe;EACjB;;AAGF;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,QAAQ;AACV;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.inputContainer {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  position: relative;\n  z-index: 10;\n  background-color: #ffffff;\n  padding: 10px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  border-radius: 3px;\n  height: fit-content;\n  width: 100%;\n\n  label {\n    font-size: 12px;\n    font-weight: bolder;\n    margin-top: 5px;\n  }\n}\n\n.subComponent {\n  color: lightgrey;\n  font-size: 11px;\n  line-height: 12px;\n}\n\n.diff {\n  line-height: 14px;\n  font-size: 12px;\n  position: absolute;\n  right: 3px;\n  top: 0px;\n}\n\n.input {\n  height: 35px;\n}\n\n.error {\n  color: var(--colors-red);\n  font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Form-SmartDate-___SmartDate-module__container`,
	"inputContainer": `app-components-Form-SmartDate-___SmartDate-module__inputContainer`,
	"subComponent": `app-components-Form-SmartDate-___SmartDate-module__subComponent`,
	"diff": `app-components-Form-SmartDate-___SmartDate-module__diff`,
	"input": `app-components-Form-SmartDate-___SmartDate-module__input`,
	"error": `app-components-Form-SmartDate-___SmartDate-module__error`
};
export default ___CSS_LOADER_EXPORT___;
