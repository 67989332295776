// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Modals-Contracts-ModalEditContract-___ModalEditContract-module__contractNumberContrainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
}

.app-containers-Modals-Contracts-ModalEditContract-___ModalEditContract-module__icon {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Modals/Contracts/ModalEditContract/ModalEditContract.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".contractNumberContrainer {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  font-size: 16px;\n}\n\n.icon {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contractNumberContrainer": `app-containers-Modals-Contracts-ModalEditContract-___ModalEditContract-module__contractNumberContrainer`,
	"icon": `app-containers-Modals-Contracts-ModalEditContract-___ModalEditContract-module__icon`
};
export default ___CSS_LOADER_EXPORT___;
