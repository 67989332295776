import React from 'react'
import PropTypes from 'prop-types'
import classes from './ButtonList.module.scss'

function ButtonList({ children, className, ...props }) {
  return (
    <div className={`${classes.buttonList} ${className}`} {...props}>
      {children}
    </div>
  )
}

ButtonList.propTypes = {
  className: PropTypes.string,
}

ButtonList.defaultProps = {
  className: null,
}

export default ButtonList
