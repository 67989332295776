/** @flow */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import type { Activity, ID, Task } from 'app/core/types'
import { publishMouseTooltip } from 'app/components/MouseTooltip/publishMouseTooltip'

import type { CellInstance } from '../../../../types'

type Props = {|
  instance: CellInstance,
  values: Array<Task>,
  height: number,
  selected: ?ID,
|}

export function TimelineDrawerComponentActivities(props: Props): React$Node {
  const { instance, height: rowHeight, values, selected } = props
  const { dimensions, zoom, ganttProperties, timelineUnit, listTools } = instance
  const { dayWidth = 0, totalWidth } = dimensions || {}
  const { ganttStart } = ganttProperties || {}

  const canvasWidth = Math.min(63010, totalWidth)

  const [activitiesPositions, setActivitiesPositions] = useState({})
  const token = useRef(null)

  const height = (rowHeight / 100) * 80 - 3
  const top = (rowHeight / 100) * 10 + 1

  const canvasRef = useRef<HTMLCanvasElement | void | null>()

  const activities: Array<Activity> = useMemo(() => {
    const filteredActivities = sortBy(
      values.flatMap((task: Task, index: number) =>
        map(task.activitiesInst, (activity) => ({ ...activity, task: task.id, taskInst: task })),
      ),
      ['duration'],
    ).reverse()

    return filteredActivities
  }, [values])

  const scrollContainer = useMemo(
    () => listTools.ganttContainerEl?.querySelector('.ReactVirtualized__Grid__innerScrollContainer'),
    [listTools.ganttContainerEl],
  )

  function createActivitiesPositions() {
    if (canvasRef.current) {
      const _activitiesPositions = {}
      const { top, left, height } = canvasRef.current.getBoundingClientRect()

      activities.forEach((activity) => {
        const { date, duration, taskInst } = activity
        const totalDurationPerc = (8 * 60 * 60) / 100
        const durationPerc = duration / totalDurationPerc
        const heightPerc = height / 100
        const activityHeight = durationPerc * heightPerc

        if (!_activitiesPositions[date]) _activitiesPositions[date] = []
        _activitiesPositions[date].push({
          activity,
          taskInst,
          durationPerc,
          left,
          minTop: top + height - activityHeight,
          maxTop: top + height,
        })
      })

      setActivitiesPositions?.(_activitiesPositions)
    }
  }

  function displayMouseTooltip(e: MouseEvent) {
    const { left = 0 } =
      listTools.ganttContainerEl
        ?.querySelector('.ReactVirtualized__Grid__innerScrollContainer')
        ?.getBoundingClientRect() || {}

    const nbOfDays = (e.pageX - left - dayWidth / 2) / dayWidth
    const date = moment(ganttStart).add(nbOfDays, 'day').format('YYYY-MM-DD')

    if (activitiesPositions[date] && (!token.current || token.current !== date)) {
      const str = []
      activitiesPositions[date].forEach((val) => {
        const { activity, taskInst, durationPerc, minTop, maxTop } = val
        const { stepInst = {} } = taskInst || {}
        if ((minTop <= e.pageY && maxTop >= e.pageY) || str.length > 0) {
          str.push(
            `step: ${stepInst?.name}\ndate: ${moment(activity.date).format('dd DD MMM YYYY')}\nduration: ${
              Math.round((activity.duration / 60 / 60) * 10) / 10
            }h (${durationPerc}%)`,
          )
        }
      })

      if (str.length > 0) {
        token.current = date
        publishMouseTooltip({ tooltip: str.join('\n\n'), clientX: e.pageX, clientY: e.pageY + 50 })
      }
    } else if (token.current && !activitiesPositions[date]) {
      publishMouseTooltip()
      token.current = null
    }
  }

  useEffect(() => {
    if (ganttProperties && dimensions) {
      const canvas = canvasRef.current
      // $FlowFixMe
      if (canvas?.getContext) {
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvasWidth, height)

        activities.forEach((activity) => {
          const { date, duration, task, taskInst } = activity
          const { color } = instance.column.timelineEdition?.(taskInst, instance, instance.cell.column) || {}

          const diff = moment(date).diff(ganttStart, 'day')
          const totalDurationPerc = (8 * 60 * 60) / 100
          const durationPerc = duration / totalDurationPerc
          const heightPerc = height / 100

          const left = diff * dayWidth + 1
          const activityHeight = durationPerc * heightPerc

          const opacified = selected ? task !== selected : false

          if (opacified) ctx.globalAlpha = 0.1
          else ctx.globalAlpha = 0.8

          if (left > canvasWidth) return

          if (color) ctx.fillStyle = color
          ctx.fillRect(left, height - activityHeight, dayWidth - 1, durationPerc * heightPerc)
        })
      }
    }
  }, [zoom, ganttProperties, dimensions, timelineUnit, height, selected])

  useEffect(() => {
    if (listTools.ganttContainerEl) {
      listTools.ganttContainerEl.addEventListener('scroll', createActivitiesPositions)
      createActivitiesPositions()
      return () => listTools.ganttContainerEl?.removeEventListener('scroll', createActivitiesPositions)
    }
    return undefined
  }, [listTools.ganttContainerEl, canvasRef.current, activities])

  useEffect(() => {
    if (scrollContainer) {
      scrollContainer.addEventListener('mousemove', displayMouseTooltip)
      return () => scrollContainer.removeEventListener('mousemove', displayMouseTooltip)
    }
    return undefined
  }, [scrollContainer, activitiesPositions])

  if (!ganttProperties || !dimensions || !timelineUnit || values.length === 0) return null

  return (
    <canvas
      width={`${canvasWidth || 0}px`}
      height={`${height}px`}
      style={{
        position: 'absolute',
        left: -1,
        top,
        zIndex: 11,
        pointerEvents: 'none',
      }}
      ref={(ref) => {
        canvasRef.current = ref
      }}
      onContextMenu={(e) => e.preventDefault()}
    />
  )
}
