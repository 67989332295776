// @flow
import type { Options, Option } from 'app/core/types'

export const optionsPiority: Options = [
  { value: 25, label: 'low', backgroundColor: '#B8E986' },
  { value: 50, label: 'medium', backgroundColor: '#3dc3d2' },
  { value: 75, label: 'high', backgroundColor: '#eeb413' },
  { value: 100, label: 'urgent', backgroundColor: '#e50f26' },
]

export const getOptionsValue: (number | void) => Option | void = (value) => {
  if (!value || value === 0) return undefined
  if (value > 0 && value <= 37) return optionsPiority[0]
  if (value > 37 && value <= 62) return optionsPiority[1]
  if (value > 62 && value <= 87) return optionsPiority[2]
  if (value > 87) return optionsPiority[3]
  return undefined
}
