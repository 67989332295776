var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useMemo } from 'react';
import { FormData } from 'app/components/Form';
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx';
import resources from 'app/store/resources';
import { filter, find, map, sortBy } from 'lodash';
import { getResources } from 'app/store/selectors';
import { getConfigEntryOption } from 'app/pages/Project/Settings/FollowUp/AttributesEditor/attributeAssets.jsx';
import { makeStyles } from '@material-ui/core';
import { ModalConfirmForm } from 'app/components/Modal';
import { useSelector } from 'react-redux';
import { externalJobsSelector } from 'app/store/selectors/externalJobs';
const useStyles = makeStyles((theme) => ({
    textarea: {
        minHeight: '30px',
    },
}));
export const ModalEditPerson = (props) => {
    const { person, onSave, onChange, person: defaultPerson, categories, onRequestClose } = props, modalProps = __rest(props, ["person", "onSave", "onChange", "person", "categories", "onRequestClose"]);
    const { userInst } = person || {};
    const classes = useStyles();
    const externalJobs = useSelector(externalJobsSelector);
    const personAttributeValues = useMemo(() => getResources(undefined, 'personAttributeValues', { person: person === null || person === void 0 ? void 0 : person.id }), []);
    const externalJobsOptions = externalJobs
        ? sortBy(map(externalJobs, (job) => ({ label: job.name, value: job.id })), ['label'])
        : null;
    const personAttributesInst = map(categories, (category) => {
        const attrInst = category.personAttributesInst;
        const attrInCreateForm = filter(attrInst, (attr) => attr.isInCreateForm === true);
        return {
            personAttributesInst: attrInCreateForm,
            name: category.name,
        };
    }).filter((category) => category.personAttributesInst.length > 0);
    const editAttr = personAttributesInst.flatMap((attribute) => {
        return map(attribute.personAttributesInst, (personAttributes) => {
            const { id, name, editorType, editorParams, attrType, fillingHelp, createFormRank } = personAttributes;
            if (editorType === 'choice' || attrType === 'skill') {
                const { choice = [] } = editorParams;
                let customChoiceList;
                if (attrType === 'skill')
                    customChoiceList = getConfigEntryOption('SKILL_LEVELS');
                return {
                    key: id,
                    label: `${attribute.name} / ${name}`,
                    type: 'select',
                    tooltip: fillingHelp || null,
                    rank: createFormRank,
                    elementProps: {
                        fullWidth: true,
                        options: customChoiceList ||
                            sortBy(choice.map((c) => ({ value: c, label: c })), ['label']),
                        placeholder: `Select ${name.toLowerCase()}`,
                    },
                };
            }
            switch (attrType) {
                case 'integer':
                case 'float':
                case 'char':
                    return {
                        key: id,
                        inputType: 'text',
                        label: `${attribute.name} / ${name}`,
                        type: 'string',
                        tooltip: fillingHelp || null,
                        rank: createFormRank,
                    };
                case 'datetime':
                case 'time':
                case 'date':
                    return {
                        key: id,
                        inputType: 'text',
                        label: `${attribute.name} / ${name}`,
                        type: 'string',
                        tooltip: fillingHelp || null,
                        rank: createFormRank,
                    };
                case 'email':
                    return {
                        key: id,
                        inputType: 'email',
                        label: `${attribute.name} / ${name}`,
                        type: 'string',
                        tooltip: fillingHelp || null,
                        rank: createFormRank,
                    };
                case 'url':
                    if (editorType === 'int' ||
                        editorType === 'float' ||
                        editorType === 'str' ||
                        editorType === 'email' ||
                        editorType === 'url' ||
                        editorType === 'iban' ||
                        editorType === 'slider' ||
                        editorType === 'priority') {
                        return {
                            key: id,
                            inputType: 'url',
                            label: `${attribute.name} / ${name}`,
                            type: 'string',
                            tooltip: fillingHelp || null,
                            rank: createFormRank,
                        };
                    }
                    if (editorType === 'datetime')
                        return {
                            key: id,
                            label: `${attribute.name} / ${name}`,
                            type: 'dateTime',
                            tooltip: fillingHelp || null,
                            rank: createFormRank,
                        };
                    if (editorType === 'time')
                        return {
                            key: id,
                            label: `${attribute.name} / ${name}`,
                            type: 'time',
                            tooltip: fillingHelp || null,
                            rank: createFormRank,
                        };
                    if (editorType === 'date')
                        return {
                            key: id,
                            label: `${attribute.name} / ${name}`,
                            type: 'date',
                            tooltip: fillingHelp || null,
                            rank: createFormRank,
                        };
                    if (editorType === 'rating')
                        return {
                            key: id,
                            label: `${attribute.name} / ${name}`,
                            type: 'rating',
                            tooltip: fillingHelp || null,
                            rank: createFormRank,
                        };
                    if (editorType === 'phone')
                        return {
                            key: id,
                            label: `${attribute.name} / ${name}`,
                            type: 'phone',
                            tooltip: fillingHelp || null,
                            rank: createFormRank,
                        };
                    return;
                case 'phone':
                    return {
                        key: id,
                        label: `${attribute.name} / ${name}`,
                        type: 'phone',
                        tooltip: fillingHelp || null,
                        rank: createFormRank,
                    };
                case 'text':
                    return {
                        key: id,
                        label: `${attribute.name} / ${name}`,
                        type: 'textarea',
                        tooltip: fillingHelp || null,
                        rank: createFormRank,
                        elementProps: {
                            style: { width: '100%' },
                            className: classes.textarea,
                        },
                    };
                case 'duration':
                    return {
                        key: id,
                        label: `${attribute.name} / ${name}`,
                        type: 'duration',
                        tooltip: fillingHelp || null,
                        rank: createFormRank,
                    };
                case 'bool':
                    return {
                        key: id,
                        label: `${attribute.name} / ${name}`,
                        type: 'checkbox',
                        tooltip: fillingHelp || null,
                        rank: createFormRank,
                    };
                default:
                    return {
                        key: id,
                        label: `${attribute.name} / ${name}`,
                        type: 'string',
                        tooltip: fillingHelp || null,
                        rank: createFormRank,
                    };
            }
        });
    });
    const sortedEditAttr = sortBy(editAttr, ['rank']);
    function personToFormdata(person) {
        const { id, firstName, lastName, user, externalJob1, externalJob2, attributesInst } = person || {};
        const _editAttr = map(attributesInst, (attribute) => {
            sortedEditAttr.find((attr) => attr.key === attribute.personAttribute);
            return {
                label: attribute.personAttribute,
                value: attribute.value,
            };
        });
        const _editAttrObject = _editAttr.reduce((obj, item) => Object.assign(obj, { [item.label]: item.value }), {});
        return Object.assign({ id,
            firstName,
            lastName,
            externalJob1,
            externalJob2, user: userInst ? { label: userInst.name, value: userInst.id, assetType: 'us' } : user }, _editAttrObject);
    }
    function save(data) {
        const { firstName, lastName, user, externalJob1, externalJob2 } = data, _editAttrObject = __rest(data, ["firstName", "lastName", "user", "externalJob1", "externalJob2"]);
        const newPerson = {
            id: person === null || person === void 0 ? void 0 : person.id,
            firstName,
            lastName,
            externalJob1,
            externalJob2,
            user: user === null || user === void 0 ? void 0 : user.value,
        };
        if (onSave)
            return onSave(newPerson);
        return resources.people[(person === null || person === void 0 ? void 0 : person.id) ? 'update' : 'create'](newPerson)
            .then((res) => {
            const person = res.resources[0];
            const mapPersonAttributesValues = map(_editAttrObject, (newValue, key) => {
                const attributeId = find(personAttributeValues, (attr) => attr.personAttribute === key);
                return {
                    person: person.id,
                    personAttribute: key,
                    value: newValue,
                    id: (attributeId === null || attributeId === void 0 ? void 0 : attributeId.id) || null,
                };
            }).filter((attribute) => attribute.value !== undefined);
            const newPersonAttributesValues = mapPersonAttributesValues.filter((attr) => attr.id === null);
            const updatePersonAttributesValues = mapPersonAttributesValues.filter((attr) => attr.id !== null);
            const promises = Promise.all([
                resources.personAttributeValues.create(newPersonAttributesValues),
                resources.personAttributeValues.update(updatePersonAttributesValues),
                resources.people.update(person),
            ]);
            promises.catch(() => {
                if (onRequestClose)
                    onRequestClose();
                onChange === null || onChange === void 0 ? void 0 : onChange(person);
            });
            return promises;
        })
            .then((res) => {
            onChange === null || onChange === void 0 ? void 0 : onChange(res[2].resources[0]);
        });
    }
    return (_jsx(ModalConfirmForm, Object.assign({ title: person ? `Edit ${person.firstName} ${person.lastName}` : 'Create new person', draggable: true, onRequestClose: onRequestClose }, modalProps, { children: _jsx(FormData, { onSave: save, defaultData: person ? personToFormdata(person) : undefined, properties: [
                {
                    label: 'First name',
                    key: 'firstName',
                    type: 'string',
                    elementProps: {
                        isRequired: true,
                    },
                },
                {
                    label: 'Last name',
                    key: 'lastName',
                    type: 'string',
                    elementProps: {
                        isRequired: true,
                    },
                },
                {
                    label: 'Link Overmind user',
                    key: 'user',
                    type: 'autocomplete',
                    element: AssetsSelect,
                    elementProps: {
                        assetTypes: ['us'],
                        showAssetType: true,
                        placeholder: 'link overmind user',
                    },
                },
                {
                    label: 'External job 1',
                    key: 'externalJob1',
                    type: 'select',
                    elementProps: {
                        options: externalJobsOptions,
                        fullWidth: true,
                        placeholder: 'Select an external job',
                    },
                },
                {
                    label: 'External job 2',
                    key: 'externalJob2',
                    type: 'select',
                    elementProps: {
                        options: externalJobsOptions,
                        fullWidth: true,
                        placeholder: 'Select an external job',
                    },
                },
                ...sortedEditAttr,
            ] }) })));
};
