/** @flow */
import React from 'react'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData } from 'app/components/Form'

import type { CellInstance } from '../../../types'

type Props = {|
  ...$Shape<$Rest<ModalProps, { children: React$Node }>>,
  instance: CellInstance,
|}

export function EditModal(props: Props): React$Node {
  const { instance, ...rest } = props
  const { cell } = instance
  const { value, getCellProps } = cell
  const { id, startDate, endDate, name } = value
  const { edition } = getCellProps()

  return (
    <ModalConfirmForm title={`Edit ${name}`} draggable={true} {...rest}>
      <FormData
        onSave={(form) => edition.save({ id, ...form })}
        defaultData={{
          startDate,
          endDate,
        }}
        properties={[
          {
            label: 'Start date',
            key: 'startDate',
            type: 'string',
            elementProps: {
              type: 'date',
              isRequired: true,
            },
          },
          {
            label: 'End date',
            key: 'endDate',
            type: 'string',
            elementProps: {
              type: 'date',
              isRequired: true,
            },
          },
        ]}
      />
    </ModalConfirmForm>
  )
}
