/** @flow */
import React from 'react'

import { TreeView } from './TreeView'
import { NODE_DIRECTORY, Node } from './Node'
import type { TreeViewItem } from './TreeViewItem'
import type { DropIndication } from './DropIndication'
import { TreeRow } from './TreeRow'
import type { TreeViewProps } from './TreeViewProps'
import type { NodeConstructor } from './Node'

function DropIndicator({
  indication,
  indentation,
  dropIndicatorOffset,
}: {
  indication: DropIndication,
  indentation: number,
  dropIndicatorOffset: number,
}) {
  if (indication.type === 'between') {
    const left = indication.depth * indentation + dropIndicatorOffset
    return (
      <div
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          left: `${left}px`,
          right: '0',
          top: `${indication.top}px`,
          height: '2px',
          background: 'rgb(0, 138, 230)',
        }}
      />
    )
  }
  return (
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        top: `${indication.top}px`,
        left: 0,
        right: 0,
        height: `${indication.height}px`,
        boxSizing: 'border-box',
        border: '1px solid rgb(0, 138, 230)',
      }}
    />
  )
}

type TreeDnDProps<Data> = {|
  footer?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'footer'>,
  header?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'header'>,
  indentation?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'indentation'>,
  dropIndicatorOffset?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'dropIndicatorOffset'>,
  nonReorderable?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'nonReorderable'>,
  renderDropIndicator?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'renderDropIndicator'>,
  className?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'className'>,
  hidden?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'hidden'>,
  style?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'style'>,
  onBackgroundClick?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'onBackgroundClick'>,
  renderRow?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'renderRow'>,
  handleDragStart?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'handleDragStart'>,
  handleDragEnd?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'handleDragEnd'>,
  canDropData?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'canDropData'>,
  handleDrop?: $ElementType<TreeViewProps<TreeViewItem<Data>>, 'handleDrop'>,
  rootNode: Node<Data>,
  onChange?: (Node<Data>) => Promise<any>,
  onAddExternalNode?: (data: TreeViewItem<any>, parent: ?TreeViewItem<any>) => NodeConstructor<any> | null,
|}

export function TreeDnD<Data>(props: TreeDnDProps<Data>): React$Node {
  const { rootNode, onChange, nonReorderable, onAddExternalNode, ...rest } = props

  return (
    <TreeView
      rootNode={rootNode}
      noHiddingOnDrag={!onChange}
      onBackgroundClick={(e, state) => {
        const { setSelectedNodes } = state
        setSelectedNodes({})
      }}
      renderDropIndicator={(props) => <DropIndicator {...props} />}
      handleDragStart={(item) => {
        if (!item.node.selected) {
          item.node.root.deselect()
          item.node.select()
        }
        return true
      }}
      canDropData={(item, { draggedItem, event }) => {
        if (nonReorderable) return false
        if (!item.node.parent && draggedItem?.node.type === NODE_DIRECTORY) return true
        if (item.node.parent && item.node.type === NODE_DIRECTORY && draggedItem?.node.type !== NODE_DIRECTORY)
          return true
        return false
      }}
      handleDrop={(parent, { draggedItem, before, event }, callback) => {
        if (nonReorderable) return
        if (!draggedItem) return

        if (draggedItem.isExternal) {
          const externalNodeConstructor = onAddExternalNode?.(draggedItem)
          if (!externalNodeConstructor) return
          const node = new Node(externalNodeConstructor)
          parent.node.insertBefore(node, before?.node)
        } else {
          for (const node of parent.node.root.selectedDescendants) {
            parent.node.insertBefore(node, before?.node)
          }
        }

        onChange?.(rootNode).finally(() => {
          callback?.()
        })
      }}
      renderRow={(item, { depth, indentation, selected, ancestorSelected, select, deselect, collapsed, collapse }) => {
        return (
          <TreeRow
            node={item.node}
            depth={depth}
            indentation={indentation}
            selected={selected}
            ancestorSelected={ancestorSelected}
            select={select}
            deselect={deselect}
            collapsed={collapsed}
            collapse={collapse}
          />
        )
      }}
      nonReorderable={nonReorderable}
      {...rest}
    />
  )
}
