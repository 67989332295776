/** @flow */
import React from 'react'
import { reduce, sortBy } from 'lodash'

import type { Column } from '../../types'
import { getCellAssetSelectValue, Read } from './CellAssetsSelect'

export const groupingFns: $ElementType<Column, 'groupingFns'> = {
  priority: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const value = sortBy(getCellAssetSelectValue(row.values[columnId]), ['name'])
        const resKey = reduce(
          value,
          (acc, asset) => {
            return `${acc}-${asset.id}`
          },
          '',
        )
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Assets',
    headerRow: (cell) => {
      if (!cell.value) return null
      return <Read value={getCellAssetSelectValue(cell.value)} />
    },
  },
}
