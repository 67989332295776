/** @flow */
import { flatten } from 'lodash'
import api from 'app/core/api/api.js'
import type { ID } from 'app/core/types'
import { stringCleanWithDot } from 'app/libs/helpers'

type FilesUpload = { files: File | Array<File>, groupId?: ID }
type Params = FilesUpload | Array<FilesUpload>

export default (params: Params): * => {
  function uploadFiles(filesParams: FilesUpload) {
    const { files, groupId } = filesParams
    const formData = new FormData()

    if (!files) return Promise.resolve([])
    if (groupId) formData.append('group_id', groupId)

    if (Array.isArray(files)) {
      files.forEach((file) => {
        const filename = stringCleanWithDot(file.name)
        formData.append(file.name, file, filename)
      })
    } else {
      const filename = stringCleanWithDot(files.name)

      formData.append(files.name, files, filename)
    }
    return api.upload(formData)
  }

  if (Array.isArray(params)) {
    return Promise.all(params.map(uploadFiles)).then(flatten)
  }
  return uploadFiles(params)
}
