/* eslint-disable max-classes-per-file */
import io from 'socket.io-client'
import { uuidToId } from 'app/core/api/replaceKey'
import settings from '../settings/index'

const eventsNames = {
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe',
}

class SocketRoom {
  constructor(publisher, roomName) {
    this.publisher = publisher
    this.roomName = roomName
    this.subs = []
  }

  on(event, fn) {
    const sub = this.publisher.on(event, (data) => fn(uuidToId(data)))
    this.subs.push(sub)
    return this
  }

  remove() {
    for (const sub of this.subs) {
      sub.remove()
    }

    this.publisher.unsubscribe(this.roomName)
  }
}

class SocketSub {
  constructor(publisher, event, fn) {
    this.publisher = publisher
    this.event = event
    this.fn = fn
  }

  remove() {
    this.publisher.removeListener(this.event, this.fn)
  }
}

class Socket {
  constructor(namespace = '/') {
    this._io = io(settings.env.SOCKET_URL + namespace, { transports: ['websocket', 'polling'] })

    this.rooms = {}
  }

  subscribe(roomName) {
    if (this.rooms[roomName]) return this.rooms[roomName]

    this._io.emit(eventsNames.SUBSCRIBE, roomName)
    const socketRoom = new SocketRoom(this, roomName)

    this.rooms[roomName] = socketRoom

    return socketRoom
  }

  subscribeAndListen(roomName, event, fn) {
    const room = this.subscribe(roomName)

    room.on(event, fn)

    return room
  }

  unsubscribe(roomName) {
    this.rooms[roomName] = null

    this._io.emit(eventsNames.UNSUBSCRIBE, roomName)
  }

  on(event, fn) {
    this._io.on(event, fn)

    return new SocketSub(this, event, fn)
  }

  emit(event, data) {
    this._io.emit(event, data)
  }

  removeListener(event, fn) {
    this._io.removeListener(event, fn)
  }
}

export default new Socket('/')
