// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__iconContainer {
  border-radius: 4px;
  margin-right: 3px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #4a4a4a;
}

.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__itemSubLabel {
  color: #bbbbbb;
  margin-left: 6px;
}

.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__itemEditorLabel {
  color: #3dc3d2;
  margin-left: 6px;
}

.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__tableNameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 15px;
  height: 55px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 10;
}

.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__tableTitle {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}

.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__tableTitle:hover > .app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__viewTableButton {
    opacity: 0.5;
  }

.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__viewTableButton {
  cursor: pointer;
  opacity: 0.3;
  padding-left: 6px;
  font-size: 18px;
}

.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__viewTableButton:hover {
    opacity: 1 !important;
  }

.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__tableType {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__tableTypeLabel {
  line-height: 18px;
  font-size: 18px;
  margin-left: 4px;
}
`, "",{"version":3,"sources":["webpack://./app/pages/Project/Settings/FollowUp/TrackingSchemas.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,MAAM;EACN,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AAKnB;;AAHE;IACE,YAAY;EACd;;AAGF;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,eAAe;AAKjB;;AAHE;IACE,qBAAqB;EACvB;;AAGF;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".iconContainer {\n  border-radius: 4px;\n  margin-right: 3px;\n  padding: 5px;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  color: #4a4a4a;\n}\n\n.itemSubLabel {\n  color: #bbbbbb;\n  margin-left: 6px;\n}\n\n.itemEditorLabel {\n  color: #3dc3d2;\n  margin-left: 6px;\n}\n\n.tableNameContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  padding: 15px;\n  height: 55px;\n  position: sticky;\n  top: 0;\n  background-color: #ffffff;\n  z-index: 10;\n}\n\n.tableTitle {\n  display: flex;\n  flex-direction: row;\n  align-content: center;\n  align-items: center;\n  flex-wrap: nowrap;\n  font-weight: bold;\n  font-size: 20px;\n  line-height: 20px;\n\n  &:hover > .viewTableButton {\n    opacity: 0.5;\n  }\n}\n\n.viewTableButton {\n  cursor: pointer;\n  opacity: 0.3;\n  padding-left: 6px;\n  font-size: 18px;\n\n  &:hover {\n    opacity: 1 !important;\n  }\n}\n\n.tableType {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n}\n\n.tableTypeLabel {\n  line-height: 18px;\n  font-size: 18px;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": `app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__iconContainer`,
	"itemSubLabel": `app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__itemSubLabel`,
	"itemEditorLabel": `app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__itemEditorLabel`,
	"tableNameContainer": `app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__tableNameContainer`,
	"tableTitle": `app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__tableTitle`,
	"viewTableButton": `app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__viewTableButton`,
	"tableType": `app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__tableType`,
	"tableTypeLabel": `app-pages-Project-Settings-FollowUp-___TrackingSchemas-module__tableTypeLabel`
};
export default ___CSS_LOADER_EXPORT___;
