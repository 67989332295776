/** @flow */
import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import { forEach } from 'lodash'
import type { CellInstance, TableInstance, TimeUnit } from '../../types'

import classes from './BackgroundSVG.module.scss'

type Props = {|
  instance: CellInstance | TableInstance,
  height: number,
  unit: TimeUnit,
  unitFormat: string,
  formatLabel?: (day: moment) => string | number | Array<string | number>,
  style?: Object,
  fontSize?: number,
  dontDrawNow?: boolean,
|}

export function HeaderSVG(props: Props): React$Node {
  const { instance, height, unit, unitFormat, formatLabel, style, dontDrawNow } = props
  const { fontSize = 14 } = props
  const { dimensions, ganttProperties, zoom } = instance
  const { dayWidth = 0, totalWidth } = dimensions || {}

  const canvasRef = useRef()

  const canvasWidth = Math.min(63010, totalWidth)

  useEffect(() => {
    if (ganttProperties && dimensions) {
      const { timeline, ganttStart } = ganttProperties
      let previousLeft = 0

      const canvas = canvasRef.current

      // $FlowFixMe
      if (canvas?.getContext) {
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvasWidth, height)

        forEach(timeline[unit], (day, index: number) => {
          const isWeekend = unit === 'day' ? [6, 0].includes(day.day()) : false
          const dayLabel = formatLabel?.(day) || day.format(unitFormat)

          const endOf = moment(day).endOf(unit)
          const diff = endOf.diff(day, 'days') + 1
          const width = diff * dayWidth

          const left = previousLeft
          previousLeft += width

          if (left > canvasWidth) return

          ctx.fillStyle = 'rgba(0,0,0,0.05)'
          ctx.fillRect(left, 0, isWeekend ? width + 1 : 1, height)

          ctx.fillStyle = 'black'
          ctx.font = `${fontSize}px "Helvetica"`

          ctx.textAlign = 'center'

          if (Array.isArray(dayLabel)) {
            const heightUnit = height / dayLabel.length
            ctx.textBaseline = 'top'
            dayLabel.forEach((label, index) => {
              ctx.fillText(String(label), left + width / 2, heightUnit * index)
            })
          } else {
            ctx.textBaseline = 'middle'
            ctx.fillText(String(dayLabel), left + width / 2, height / 2)
          }
        })

        if (dontDrawNow) return

        /** Draw now */
        const diff = moment().diff(ganttStart, 'day')
        ctx.fillStyle = 'rgba(0,70,255,0.15)'
        ctx.fillRect(diff * dayWidth, 0, dayWidth, height)
      }
    }
  }, [zoom, ganttProperties, dimensions])

  if (!ganttProperties || !dimensions) return null

  return (
    <canvas
      width={`${canvasWidth || 0}px`}
      height={`${height}px`}
      ref={(ref) => {
        canvasRef.current = ref
      }}
      className={classes.container}
      style={style}
      onContextMenu={(e) => e.preventDefault()}
    />
  )
}
