// @flow

import type { StoreResourceDef } from 'app/core/types'

export const takeFlags: StoreResourceDef<> = {
  resourceType: 'takeFlags',
  relations: {
    flagInst: {
      type: 'hasOne',
      resourceType: 'flags',
      foreignKey: 'flag',
    },
  },
}
