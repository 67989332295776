var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    loading: false,
    data: {},
};
// for later
export const fetchConfig = createAsyncThunk('config/fetchConfig', () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield api.config();
    return response;
}));
const { actions, reducer } = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setProjectConfig: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchConfig.pending, (state, action) => {
            state.loading = true;
        }),
            builder.addCase(fetchConfig.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            });
    },
});
export const { setProjectConfig } = actions;
export default reducer;
