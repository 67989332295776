// @flow
import * as React from 'react'
import { openModal } from './openModal'

type Props = {
  children: React.Element<any>,
  modal: React.Element<any> | Function,
  localOwner?: boolean,
}

type State = {
  modalIsOpen: boolean,
}

export default class Button extends React.Component<Props, State> {
  static defaultProps: $Shape<Props> = {
    localOwner: false,
  }

  state: State = {
    modalIsOpen: false,
  }

  getModal: Function = () => {
    const { modal } = this.props
    return typeof modal === 'function' ? modal() : modal
  }

  onClick: Function = () => {
    const { localOwner } = this.props

    if (localOwner) {
      this.setState({ modalIsOpen: true })
    } else {
      openModal(this.getModal())
    }
  }

  render(): React$Node {
    const { children, localOwner } = this.props
    const { modalIsOpen } = this.state

    return (
      <>
        {React.cloneElement(children, {
          onClick: this.onClick,
        })}
        {localOwner &&
          modalIsOpen &&
          React.cloneElement(this.getModal(), {
            onRequestClose: () => this.setState({ modalIsOpen: false }),
          })}
      </>
    )
  }
}
