import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    projectsLockedFilters: {},
};
const { actions, reducer } = createSlice({
    name: 'lockedFilters',
    initialState,
    reducers: {
        setLockedFilters: (state, { payload }) => {
            const { projectId, isLocked } = payload;
            return Object.assign(Object.assign({}, state), { projectsLockedFilters: Object.assign(Object.assign({}, state.projectsLockedFilters), { [projectId]: isLocked }) });
        },
    },
});
export const { setLockedFilters } = actions;
export default reducer;
