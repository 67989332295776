// @flow
import React, { useMemo, useState } from 'react'
import { map, find } from 'lodash'
import moment from 'moment'
import pipe from 'app/core/utils/pipe'
import { getServerConfig } from 'app/core/utils/getServerConfig'
import type { ID, ResourcesList, ProgressionStatus, Task, Pipe, Asset, Activity } from 'app/core/types'
import { getResources } from 'app/store/selectors'
import { Table, type TableInstance } from 'app/components/Table'
import { CellText, CellSelect, CellDuration, CellLink, CellAssetsSelect, CellStatus } from 'app/components/Table/Cells'
import { router } from 'app/containers/Assets/AssetDetail/router'
import { router as routerTask } from 'app/containers/Task/router'
import resources from 'app/store/resources/index.js'
import { openModal } from 'app/components/Modal'
import { ModalAddCustomActivity } from 'app/pages/MyHours/ModalAddCustomActivity.tsx'

import history from 'app/main/routerHistory.js'
import { ModalAddActivitiesForUser } from './ModalAddActivitiesForUser.jsx'
import { ModalAddAbsenceActivity } from '../../MyHours/ModalAddAbsenceActivity.tsx'

const tableId = 'table-time-recap-activities'

type Props = {|
  progressionStatus: ResourcesList<ProgressionStatus>,
  projects: ResourcesList<Asset>,
  allotment?: boolean,
  userId: ID,
  date: moment,
  type: ?string,
  reloadKey: string,
  tableHoursTools: ?{
    updateTable: () => void,
    getMetadataFromActivity: (
      activity: Activity,
    ) => $Exact<{ labelRow: string, labelRowConcat: string, user: ID, date: string }>,
  },
|}

function TableTimeRecapActivitiesView(props: Props): React$Node {
  const { progressionStatus, date, userId, projects, type, tableHoursTools, reloadKey, allotment } = props

  const activityTypes = useMemo(() => getServerConfig().ACTIVITY_TYPES, [])

  const startDate = useMemo(() => moment(date).startOf('day'), [date])
  const endDate = useMemo(() => moment(date).endOf('day'), [date])
  const [updatingKey, setUpdatingKey] = useState()

  function defaultActions(instance, cell) {
    if (!cell?.value) return []
    const { taskInst } = cell.value
    const { getLastestSelection, updateCells } = instance.getLastestInstance?.() || instance
    const { selectedCells } = getLastestSelection()
    const toDeleteActivities = map(selectedCells, (cell) => cell.row.original.id)
    const nb = toDeleteActivities.length

    return [
      {
        label: `Delete ${nb > 1 ? `${nb} ` : ''}activit${nb > 1 ? 'ies' : 'y'}`,
        onClick: (e) => {
          resources.activities.delete(toDeleteActivities).then(() => {
            updateCells(selectedCells)
            tableHoursTools?.updateTable?.()
          })
        },
        editAction: true,
      },
      { separator: true },
      {
        label: 'Show task details',
        onClick: (e) => routerTask.goTo('index', { taskId: taskInst.id }, { rightPanel: true }),
        editAction: false,
      },
    ]
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Activities',
        id: 'activities',
        columns: [
          CellDuration({
            Header: 'Duration',
            id: 'duration',
            noFilterOption: true,
            readOnly: false,
            accessor: (task) => task && task.duration,
            width: 110,
            actions: (instance, cell) => ['edit', 'separator', ...defaultActions(instance, cell)],
            durationProps: { hide: { days: true } },
            save: {
              resource: 'activities',
              formatData: (item, value, cell, instance, type) => {
                const { date, user, task } = item

                const activity = map(getResources(undefined, 'activities', { date, user, task }))[0]

                if (type === 'delete' && activity) return activity.id

                let activityValue

                if (activity) {
                  activityValue = {
                    id: activity.id,
                    duration: value,
                  }
                } else {
                  activityValue = {
                    date: startDate.toISOString(),
                    user,
                    task,
                    duration: value,
                  }
                }

                return activityValue
              },
            },
          }),
        ],
      },
      {
        Header: 'Custom activities',
        id: 'task',
        columns: [
          CellSelect({
            id: 'reason',
            Header: 'Reason',
            hiddenable: true,
            noFilterOption: true,
            actions: (instance, cell) => {
              if (!cell.row?.original?.activityType) return defaultActions(instance, cell)
              return ['edit', ...defaultActions(instance, cell)]
            },
            width: 110,
            options: () => map(activityTypes, (label: string, value: string) => ({ label, value })),
            accessor: 'activityType',
            readOnly: false,
            save: {
              resource: 'activities',
              formatData: (item, value) => (item.activityType ? { id: item.id, activityType: value } : null),
            },
          }),
          CellText({
            id: 'comment',
            Header: 'Comment',
            hiddenable: true,
            noFilterOption: true,
            readOnly: false,
            actions: (instance, cell) => {
              if (!cell.row?.original?.activityType) return defaultActions(instance, cell)
              return ['edit', ...defaultActions(instance, cell)]
            },
            accessor: 'comment',
            save: {
              resource: 'activities',
              formatData: (item, value) => (item.activityType ? { id: item.id, comment: value } : null),
            },
            width: 250,
          }),
        ],
      },
      {
        Header: 'Asset',
        id: 'asset',
        columns: [
          CellLink({
            Header: 'Name',
            id: 'name',
            showRemoved: true,
            actions: defaultActions,
            readOnly: false,
            width: 150,
            onClick: ({ original }: { original: { taskInst: Task } }) => {
              const { taskInst = { assetInst: {} } } = original
              return router.goTo('index', { assetId: taskInst.assetInst.id }, { rightPanel: true })
            },
            accessor: (item) => {
              const { taskInst = { assetInst: {} } } = item
              return taskInst && taskInst.assetInst.name
            },
          }),
          CellAssetsSelect({
            Header: 'Project',
            id: 'project',
            placeholder: '',
            hiddenable: true,
            showIcon: false,
            readOnly: false,
            width: 170,
            actions: defaultActions,
            accessor: (item) => {
              if (!item || !item.project) return undefined
              return projects[item.project]
            },
          }),
          CellAssetsSelect({
            Header: 'Asset parent',
            placeholder: '',
            hiddenable: true,
            showIcon: true,
            id: 'parent',
            width: 170,
            noFilterOption: true,
            actions: defaultActions,
            readOnly: false,
            accessor: (item) => {
              if (!item || !item.taskInst || !item.taskInst.assetParentInst) return undefined
              const { assetParentInst } = item.taskInst
              return assetParentInst
            },
          }),
          CellText({
            Header: 'Step',
            id: 'step',
            hiddenable: true,
            width: 100,
            noFilterOption: true,
            actions: defaultActions,
            readOnly: false,
            accessor: (item: { taskInst: Task }) => {
              const { taskInst = {} } = item
              return taskInst && taskInst.stepInst && taskInst.stepInst.name
            },
          }),
          CellStatus({
            id: 'status',
            Header: 'Status',
            hiddenable: true,
            readOnly: false,
            actions: defaultActions,
            noFilterOption: true,
            accessor: 'taskInst.status',
            progressionStatus,
          }),
        ],
      },
    ],
    [projects],
  )

  function onAddActivities(instance: TableInstance, activities: Array<Activity>) {
    if (!tableHoursTools) return
    const { updateTable, getMetadataFromActivity } = tableHoursTools || {}
    if (updateTable) updateTable()

    const { date: activityDate, user, labelRowConcat } = getMetadataFromActivity(activities[0])

    if (type === labelRowConcat && date.format('YYYY-MM-DD') === activityDate && userId === user) {
      setUpdatingKey(Date.now())
    } else {
      history.pushWithQuery({
        selectedUser: user,
        selectedDay: moment(activityDate).format('D'),
        selectedRow: labelRowConcat,
      })
    }
  }

  const ToggleButtons = (instance) =>
    !allotment
      ? [
          {
            key: 'custom',
            onClick: () =>
              openModal(
                <ModalAddCustomActivity
                  userId={userId}
                  onChange={(activities) => onAddActivities(instance, activities)}
                  preSelectedDate={date.toDate()}
                />,
              ),
            icon: 'fas-calendar-plus',
            label: 'Add a custom activity',
            options: [
              {
                key: 'add',
                onClick: () =>
                  openModal(
                    <ModalAddActivitiesForUser
                      date={date.toDate()}
                      userId={userId}
                      onAddActivities={(activities) => onAddActivities(instance, activities)}
                    />,
                  ),
                icon: 'fas-plus',
                label: 'Add an activity',
              },
              {
                key: 'leave',
                icon: 'fas-clock',
                onClick: () =>
                  openModal(
                    <ModalAddAbsenceActivity
                      userId={userId}
                      preSelectedDate={date.toDate()}
                      onChange={(activities) => onAddActivities(instance, activities)}
                      showDuration={true}
                    />,
                  ),
                label: 'Add a leave',
              },
            ],
          },
        ]
      : [
          {
            key: 'custom',
            onClick: () =>
              openModal(
                <ModalAddAbsenceActivity
                  userId={userId}
                  preSelectedDate={date.toDate()}
                  onChange={(activities) => onAddActivities(instance, activities)}
                  showDuration={true}
                />,
              ),
            icon: 'fas-calendar-plus',
            label: 'Add a leave',
          },
        ]

  function formatRowData(activity: Activity) {
    if (allotment) {
      if (activity?.activityType === 'lv') return activity
      if (activity?.taskInst?.taskTypeInst?.name === 'alt') return activity
      return []
    }
    if (activity?.taskInst?.taskTypeInst?.name === 'alt') return []

    if (!type || type === 'total') return activity
    if (type === 'otherActivities' && !activity.project) return []
    if (type === 'otherProjects') return []

    const project = find(projects, (project) => project.name === type)
    if (project && activity.project !== project.id) return []

    return activity
  }

  const resourcesParams = useMemo(() => {
    return {
      resourceType: 'activities',
      requestName: 'userActivities',
      queries: {
        date__range: `${startDate.format('YYYY-MM-DD')},${endDate.format('YYYY-MM-DD')}`,
      },
      key: `${type || ''}${updatingKey || ''}${reloadKey}`,
      includedResources: ['taskInst', 'taskInst.stepInst', 'taskInst.assetInst'],
      requestData: { userId },
    }
  }, [startDate, endDate, type, userId, updatingKey, reloadKey])

  return (
    <Table
      tableId={tableId}
      resourcesParams={resourcesParams}
      afterUpdate={tableHoursTools?.updateTable}
      formatRowData={formatRowData}
      toggleButtons={ToggleButtons}
      columns={columns}
    />
  )
}

const pipeInst: Pipe<{}, typeof TableTimeRecapActivitiesView> = pipe()

export const TableTimeRecapActivities: React$AbstractComponent<any, any> = pipeInst
  .connect((state, props) => ({
    progressionStatus: getResources(state, 'progressionStatus'),
    projects: getResources(state, 'assets', { assetType: 'pj' }),
  }))
  .render(TableTimeRecapActivitiesView)
