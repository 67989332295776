/** @flow */
import api from 'app/core/api/api'
import { notify } from 'app/components/Notifications/notify'
import type { TableInstance } from 'app/components/Table/types'
import type { SentryEvent } from 'app/core/types'

export function SendEvent(instance: TableInstance, action: string): Promise<any> {
  notify('Follow-up assets list sent to sentry.')
  const { queries: filterQueries, headers: filterHeaders }: Object = instance.filtersTools || {}
  const headers = {
    ...filterHeaders,
  }
  const { getSortingString } = instance.getLastestInstance?.() || instance
  const queries: SentryEvent = {
    filters: {
      ...filterQueries,
      ordering: getSortingString() || undefined,
    },
    rootAsset: instance.extendDatas?.asset.id,
    action,
  }
  if (instance.extendDatas?.trackingSchemaId) {
    queries.externalTrackingSchema = instance.extendDatas?.trackingSchemaId
  }

  return api.sendEventToSentry(queries, headers)
}
