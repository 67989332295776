/** @flow */
import React from 'react'
import { reduce, sortBy, map, uniqBy } from 'lodash'
import type { Column } from '../../types'
import { AssetListRead } from './CellAssetsList'

export const groupingFns: (aggregated?: boolean) => $ElementType<Column, 'groupingFns'> = (aggregated) =>
  aggregated
    ? undefined
    : {
        assets: {
          fn: (rows, columnId, instance) => {
            const orderedAssets = {}
            const unorderedAssets = rows.reduce((prev, row, i) => {
              const value = sortBy(
                uniqBy(
                  map(row.values[columnId], (link) => link.to_assetInst),
                  'id',
                ),
                ['id'],
              )

              const resKey = reduce(
                value,
                (acc, asset) => {
                  return `${acc}@${asset.id}`
                },
                '',
              )
              prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
              prev[resKey].push(row)
              return prev
            }, {})
            Object.keys(unorderedAssets)
              .sort((prev, current) => current.split('@').length - prev.split('@').length)
              .forEach((key) => {
                orderedAssets[key] = unorderedAssets[key]
              })

            return orderedAssets
          },
          label: 'Assets',
          headerRow: (cell, instance) => {
            if (!cell.value) return null
            return (
              <AssetListRead
                assets={uniqBy(
                  map(cell.value, (link) => link.to_assetInst),
                  'id',
                )}
                isRowExpanded={false}
                disableClickableAssets={true}
                cellWidth={300}
                prefs={instance.prefs}
              />
            )
          },
        },
      }
