// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-ModulableTree-styles-___labelFullWidth-module__label {
  min-width: 100%;
  height: 100%;
  min-height: 35px;
}

.app-components-ModulableTree-styles-___labelFullWidth-module__itemContentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 10px;
  height: 100%;
  width: 100%;
  min-height: 35px;
  padding: 0;
}

.app-components-ModulableTree-styles-___labelFullWidth-module__firstItem {
  height: 100%;
  min-height: 35px;
}

.app-components-ModulableTree-styles-___labelFullWidth-module__labelContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 35px;
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./app/components/ModulableTree/styles/labelFullWidth.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".label {\n  min-width: 100%;\n  height: 100%;\n  min-height: 35px;\n}\n\n.itemContentWrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  align-items: center;\n  padding-left: 10px;\n  height: 100%;\n  width: 100%;\n  min-height: 35px;\n  padding: 0;\n}\n\n.firstItem {\n  height: 100%;\n  min-height: 35px;\n}\n\n.labelContent {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  align-items: center;\n  min-height: 35px;\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `app-components-ModulableTree-styles-___labelFullWidth-module__label`,
	"itemContentWrapper": `app-components-ModulableTree-styles-___labelFullWidth-module__itemContentWrapper`,
	"firstItem": `app-components-ModulableTree-styles-___labelFullWidth-module__firstItem`,
	"labelContent": `app-components-ModulableTree-styles-___labelFullWidth-module__labelContent`
};
export default ___CSS_LOADER_EXPORT___;
