// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Project-Settings-Flags-___Flags-module__flagPanel {
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  height: calc(100% - 60px);
  overflow-y: auto;
  margin: 0 15px;
}

  .app-pages-Project-Settings-Flags-___Flags-module__flagPanel .app-pages-Project-Settings-Flags-___Flags-module__flagsContainer {
    background-color: white;
    width: 100%;
  }

  .app-pages-Project-Settings-Flags-___Flags-module__flagPanel .app-pages-Project-Settings-Flags-___Flags-module__flagsContainer .app-pages-Project-Settings-Flags-___Flags-module__newFlag {
      display: block;
      margin-top: 15px;
      margin-bottom: 20px;
    }

  .app-pages-Project-Settings-Flags-___Flags-module__flagPanel .app-pages-Project-Settings-Flags-___Flags-module__flagsContainer .app-pages-Project-Settings-Flags-___Flags-module__noFlag {
      margin: 15px 5px;
    }

  .app-pages-Project-Settings-Flags-___Flags-module__flagPanel .app-pages-Project-Settings-Flags-___Flags-module__flagsContainer .app-pages-Project-Settings-Flags-___Flags-module__flags {
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

  .app-pages-Project-Settings-Flags-___Flags-module__flagPanel .app-pages-Project-Settings-Flags-___Flags-module__flagsContainer .app-pages-Project-Settings-Flags-___Flags-module__flagsItems {
      display: flex;
      justify-content: space-between;
      margin: 5px;
      border-radius: 15px;
      width: auto;
      padding: 5px;
      margin: 10px 4px;
    }

  .app-pages-Project-Settings-Flags-___Flags-module__flagPanel .app-pages-Project-Settings-Flags-___Flags-module__flagsContainer .app-pages-Project-Settings-Flags-___Flags-module__flagsItems .app-pages-Project-Settings-Flags-___Flags-module__flag {
        padding: 2px 5px;
      }

  .app-pages-Project-Settings-Flags-___Flags-module__flagPanel .app-pages-Project-Settings-Flags-___Flags-module__flagsContainer .app-pages-Project-Settings-Flags-___Flags-module__flagsItems .app-pages-Project-Settings-Flags-___Flags-module__icon {
        font-weight: bolder;
        padding: 0;
      }

  .app-pages-Project-Settings-Flags-___Flags-module__flagPanel .app-pages-Project-Settings-Flags-___Flags-module__flagsContainer h3 {
      margin: 5px;
    }
`, "",{"version":3,"sources":["webpack://./app/pages/Project/Settings/Flags/Flags.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;AA8ChB;;EA5CE;IACE,uBAAuB;IACvB,WAAW;EAyCb;;EAvCE;MACE,cAAc;MACd,gBAAgB;MAChB,mBAAmB;IACrB;;EAEA;MACE,gBAAgB;IAClB;;EAEA;MACE,UAAU;MACV,cAAc;MACd,aAAa;MACb,mBAAmB;MACnB,eAAe;MACf,uBAAuB;IACzB;;EACA;MACE,aAAa;MACb,8BAA8B;MAC9B,WAAW;MACX,mBAAmB;MACnB,WAAW;MACX,YAAY;MACZ,gBAAgB;IAUlB;;EARE;QACE,gBAAgB;MAClB;;EAEA;QACE,mBAAmB;QACnB,UAAU;MACZ;;EAEF;MACE,WAAW;IACb","sourcesContent":[".flagPanel {\n  display: flex;\n  flex-direction: column;\n  width: calc(100% - 30px);\n  height: calc(100% - 60px);\n  overflow-y: auto;\n  margin: 0 15px;\n\n  .flagsContainer {\n    background-color: white;\n    width: 100%;\n\n    .newFlag {\n      display: block;\n      margin-top: 15px;\n      margin-bottom: 20px;\n    }\n\n    .noFlag {\n      margin: 15px 5px;\n    }\n\n    .flags {\n      width: 90%;\n      margin: 0 auto;\n      display: flex;\n      align-items: center;\n      flex-wrap: wrap;\n      justify-content: center;\n    }\n    .flagsItems {\n      display: flex;\n      justify-content: space-between;\n      margin: 5px;\n      border-radius: 15px;\n      width: auto;\n      padding: 5px;\n      margin: 10px 4px;\n\n      .flag {\n        padding: 2px 5px;\n      }\n\n      .icon {\n        font-weight: bolder;\n        padding: 0;\n      }\n    }\n    h3 {\n      margin: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flagPanel": `app-pages-Project-Settings-Flags-___Flags-module__flagPanel`,
	"flagsContainer": `app-pages-Project-Settings-Flags-___Flags-module__flagsContainer`,
	"newFlag": `app-pages-Project-Settings-Flags-___Flags-module__newFlag`,
	"noFlag": `app-pages-Project-Settings-Flags-___Flags-module__noFlag`,
	"flags": `app-pages-Project-Settings-Flags-___Flags-module__flags`,
	"flagsItems": `app-pages-Project-Settings-Flags-___Flags-module__flagsItems`,
	"flag": `app-pages-Project-Settings-Flags-___Flags-module__flag`,
	"icon": `app-pages-Project-Settings-Flags-___Flags-module__icon`
};
export default ___CSS_LOADER_EXPORT___;
