// @flow
import * as React from 'react'
import uniqid from 'app/libs/uniqid.js'
import cx from 'classnames'
import type { DragProps, DragState } from './DragNDrop.type'
import classes from './DragNDrop.module.scss'

export class Drag extends React.PureComponent<DragProps, DragState> {
  static originElData: Object = {}

  dragRef: Object | null = React.createRef()

  handleDragStart: * = (event: Object) => {
    const { type, dragStart, elementData, hideOrigin } = this.props
    let { content } = this.props

    if (content) {
      const id = uniqid('drag-')

      if (typeof content === typeof {}) {
        content = { ...content, timestamp: Date.now() }
      }

      event.dataTransfer.setData('text/x-asset', JSON.stringify({ type, content }))
      Drag.originElData[id] = {
        ref: this.dragRef,
        data: elementData,
        clientHeight: this.dragRef && this.dragRef.clientHeight,
        clientWidth: this.dragRef && this.dragRef.clientWidth,
        hideOrigin,
      }
      event.dataTransfer.setData(id, '')
    } else {
      event.preventDefault()
    }
    typeof dragStart === 'function' && dragStart(event)
  }

  handleDragEnd: * = (event: Object) => {
    const { dragEnd } = this.props

    if (this.dragRef && (this.dragRef.hidden || event.target.hidden)) {
      this.dragRef.hidden = false
      event.target.hidden = false
    }

    typeof dragEnd === 'function' && dragEnd(event)
  }

  render(): React$Node {
    const { children, content, type, className, dragStart, dragEnd, elementData, hideOrigin, ...props } = this.props
    return (
      // $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow
      <div
        ref={(ref: Object | null) => {
          this.dragRef = ref
        }}
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
        draggable={true}
        {...props}
        className={cx(className, classes.dragWrapper)}
      >
        {children}
      </div>
    )
  }
}
