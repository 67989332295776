/** @flow */
import React, { useRef } from 'react'
import { ClickAwayListener, InputBase } from '@material-ui/core'
import type { ElementProps } from 'app/core/types'
import { MUIButton } from '../Form'
import classes from './ClipboardText.module.scss'

type Props = {|
  ...ElementProps,
  text: string,
  value?: string,
|}

export function ClipboardText(props: Props): React$Node {
  const { text, value, className, ...rest } = props

  const textRef = useRef()
  const selected = useRef(false)

  function selectAll(force?: boolean) {
    if (textRef.current && (!selected.current || force)) {
      textRef.current.select()
      selected.current = true
    }
  }

  function copy() {
    selectAll(true)
    const { ClipboardItem } = window
    const item = new ClipboardItem({
      'text/plain': new Blob([value || text], { type: 'text/plain' }),
      'text/html': new Blob([value || text], { type: 'text/html' }),
    })
    // $FlowFixMe
    navigator.clipboard.write([item])
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        selected.current = false
      }}
    >
      <div className={`${classes.container} ${className || ''}`} {...rest} onClick={selectAll}>
        <InputBase
          inputRef={textRef}
          className={classes.text}
          value={text}
          onClick={copy}
          inputProps={{ style: { fontFamily: 'monospace' } }}
        />
        <MUIButton tooltip="Copy to clipboard" style={{ borderRadius: 0 }} icon="fas-copy" onClick={copy} />
      </div>
    </ClickAwayListener>
  )
}
