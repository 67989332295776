// @flow
import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { ArticleContentsActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const articleContents: StoreResourceDef<ArticleContentsActions<>> = {
  resourceType: 'articleContents',
  actions: (actionsCreators: Object) => ({
    fetchContent: (articleId, articleContentId, config = {}) => {
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          if (articleContentId) {
            return api.articleContents.fetchOne(articleContentId).then(transformResults(config))
          }

          return api.articles.lastVersion({ id: articleId }).then((res) => {
            return !res.article ? { resources: [] } : transformResults(config)(res)
          })
        },
      }
    },
  }),
}
