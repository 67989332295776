// @flow

import React from 'react'
import moment from 'moment'
import { filter } from 'lodash'
import pipe from 'app/core/utils/pipe'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import type { ResourcesList, Step, ProgressionStatus, Task, ID, Asset } from 'app/core/types'
import resources from 'app/store/resources'
import { FormData, MUIButton } from 'app/components/Form'
import { optionsPiority } from 'app/core/utils/optionsPriority'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import { ModalConfirmForm, ModalLoader, MUIModal, confirmDelete } from 'app/components/Modal'
import { getResources } from 'app/store/selectors/getResources'
import { optionsTasksType } from 'app/core/utils/optionsTaskTypes'
import { getSubResources } from 'app/store/resources/utils/getSubResources'
import { optionsProgressionStatus } from 'app/core/utils/optionsProgressionStatus'

import { userIsAdmin } from 'app/core/permissions'
import api from 'app/core/api/api'
import { Permission } from '../Permissions'

type Props = {|
  onSuccess?: Function,
  progressionStatus: ResourcesList<ProgressionStatus>,
  steps: ResourcesList<Step>,
  paginatedListName?: string,
  task: ?Task,
  title?: string,
  projectId?: ID,
  taskId?: ID,
  canDelete?: boolean,
  onRequestClose?: Function,
  fromAsset?: Asset,
  taskTypes?: Array<Object>,
|}

type TaskToSave = $Shape<{ ...Task, id: ?ID }>

class ModalEditTaskView extends React.PureComponent<Props> {
  defaultState = () => {
    const { fromAsset, task } = this.props
    if (task) {
      const taskAsset = task.asset ? getResources(undefined, 'assets', task.asset) : null

      return {
        ...task,
        assignedUser: task.assignedUserInst
          ? { value: task.assignedUserInst.id, label: task.assignedUserInst.name, assetType: 'us' }
          : null,
        asset: taskAsset ? { value: taskAsset.id, label: taskAsset.name, assetType: taskAsset.assetType } : null,
      }
    }

    return {
      name: fromAsset ? fromAsset.name : '',
      asset: fromAsset ? { value: fromAsset.id, label: fromAsset.name, assetType: fromAsset.assetType } : '',
      stepType: fromAsset ? fromAsset.assetType : undefined,
    }
  }

  onSave = async (data) => {
    const { task, onSuccess } = this.props

    const dataToSave: TaskToSave = {
      id: task && task.id,
      name: data.name,
      comment: data.comment,
      priority: Number(data.priority),
      startDate: data.startDate ? moment(data.startDate, 'YYYY-MM-DD').toISOString() : undefined,
      endDate: data.endDate ? moment(data.endDate, 'YYYY-MM-DD').toISOString() : undefined,
      earliestStartDate: data.startDate ? moment(data.startDate, 'YYYY-MM-DD').toISOString() : undefined,
      latestEndDate: data.endDate ? moment(data.endDate, 'YYYY-MM-DD').toISOString() : undefined,
      realEstimLength: data.realEstimLength,
      status: data.status,
      assignedUser: data.assignedUser?.value,
      asset: data.asset.value,
      step: data.step,
      taskType: data.taskType,
    }

    const res = await resources.tasks[task?.id ? 'update' : 'create'](dataToSave)
    onSuccess?.()
    return res
  }

  getCreateTasksProperties() {
    const { progressionStatus, steps, taskTypes = [] } = this.props

    return [
      {
        key: 'comment',
        label: 'Brief',
        type: 'richtext',
      },
      {
        key: 'priority',
        label: 'Priority',
        type: 'select',
        elementProps: {
          fullWidth: true,
          options: optionsPiority,
          placeholder: 'Select a priority',
        },
      },
      {
        key: 'startDate',
        label: 'Start Date',
        type: 'string',
        elementProps: {
          type: 'date',
        },
      },
      {
        key: 'endDate',
        label: 'End Date',
        type: 'string',
        elementProps: {
          type: 'date',
          isRequired: true,
        },
      },
      {
        key: 'realEstimLength',
        label: 'Time (estim)',
        type: 'duration',
      },
      {
        key: 'status',
        label: 'Status',
        type: 'select',
        elementProps: {
          isRequired: true,
          fullWidth: true,
          options: optionsProgressionStatus(progressionStatus),
          placeholder: 'Select a status',
        },
      },
      {
        key: 'assignedUser',
        label: 'Worker',
        type: 'autocomplete',
        element: AssetsSelect,
        elementProps: {
          assetTypes: ['us'],
          placeholder: 'Search worker',
        },
      },
      {
        key: 'asset',
        label: 'Asset',
        type: 'autocomplete',
        element: AssetsSelect,
        elementProps: (state, setData) => {
          const assetTypes = ['mo', 'sh', 'ep', 'mi']

          return {
            showAssetType: true,
            placeholder: 'Search asset',
            isRequired: true,
            assetTypes,
            onChange: (value) => {
              if (value.data.assetType !== state.stepType) setData({ step: null })
              setData({ asset: value, stepType: value.data.assetType })
              setData({
                name: `${getResources(undefined, 'assets', value.value).name} ${
                  state.step ? steps[state.step].name : ''
                }`,
              })
            },
          }
        },
      },
      {
        key: 'step',
        label: 'Steps',
        type: 'select',
        elementProps: (state, setData) => {
          const asset = state.asset ? getResources(undefined, 'assets', state.asset.value) : null

          return {
            isRequired: true,
            fullWidth: true,
            placeholder: 'Choose a step',
            options: filter(
              steps,
              (step) => (state.stepType ? [state.stepType] : ['mo', 'sh', 'ep', 'mi']).indexOf(step.assetType) !== -1,
            ).map((step) => ({
              value: step.id,
              label: step.name,
              assetType: step.assetType,
            })),
            onChange: (value) => {
              if (state.stepType !== value.assetType) setData({ asset: null })
              setData({ step: value.value })
              setData({
                name: `${asset ? asset.name : ''} ${value.label}`,
              })
            },
          }
        },
      },
      {
        key: 'name',
        label: 'Task name',
        type: 'string',
        elementProps: (state: Object) => ({
          isRequired: true,
          disabled: !state.step || !state.asset,
        }),
      },
      {
        key: 'taskType',
        label: 'Type',
        type: 'select',
        elementProps: {
          fullWidth: true,
          options: optionsTasksType(taskTypes),
          disabled: !userIsAdmin(),
          placeholder: 'Select a task',
        },
      },
    ]
  }

  extendsButtons = () => {
    const { canDelete, task, onRequestClose } = this.props

    if (!task || !canDelete) return undefined

    return (
      <Permission actions={['projet_tasks__delete']}>
        <MUIButton
          bgColor="#E56D7A"
          onClick={() =>
            confirmDelete({
              render: 'Are you sure you want to delete this task ?',
              onValidate: () => resources.tasks.delete(task.id).then(onRequestClose),
            })
          }
        >
          Delete
        </MUIButton>
      </Permission>
    )
  }

  render(): React$Node {
    const {
      onSuccess,
      paginatedListName,
      progressionStatus,
      steps,
      task,
      title,
      projectId,
      taskId,
      canDelete,
      fromAsset,
      taskTypes,
      ...rest
    } = this.props

    if (!projectId) {
      return (
        <MUIModal title={title} {...rest}>
          <div className="flex fullWidth center grey">You need to be in a project to add a task.</div>
        </MUIModal>
      )
    }

    const newTaskProps = task
      ? {}
      : {
          cancelLabel: 'Close',
          cancelLabelColor: '#5d5d5d',
          keepOpenWhenValidate: true,
        }

    return (
      // $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow
      <ModalConfirmForm
        draggable={false}
        title={title}
        minWidth={600}
        extendsButtons={this.extendsButtons()}
        {...newTaskProps}
        {...rest}
      >
        <FormData
          defaultData={this.defaultState()}
          properties={this.getCreateTasksProperties()}
          onSave={this.onSave}
          flashNotifSuccessLabel={`Task ${task ? 'updated' : 'created'}`}
        />
      </ModalConfirmForm>
    )
  }
}

const pipeInst: Pipe<{ taskId?: string }, typeof ModalEditTaskView> = pipe()

export const ModalEditTask: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { taskId } = props
    const task = taskId ? getResources(state, 'tasks', taskId, ['assetInst', 'assignedUserInst']) : null

    let projectId

    if (state.project) {
      projectId = state.project.id
    } else if (task && task.assetInst) {
      projectId = task.assetInst.project
    }

    const stepProjects = getResources(state, 'stepProjects', { project: projectId }, ['stepInst'])
    const steps = getSubResources(stepProjects, 'stepInst')

    return {
      task,
      progressionStatus: state.progressionStatus.resources,
      steps,
      projectId,
    }
  })
  .request((props) => {
    if (!props.projectId) return Promise.resolve()
    return Promise.all([resources.stepProjects.fetchByProject(props.projectId), api.taskTypes.fetchAll()])
  })
  .mapRequestToProps(([, res]) => ({ taskTypes: res.results }))
  .renderLoader(() => <ModalLoader title="Charging elements..." />)
  .render(ModalEditTaskView)
