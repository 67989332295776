/** @flow */
import React, { useMemo } from 'react'
import { NODE_DIRECTORY, NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import type { SchemaColumnSettings } from 'app/core/types/Schema'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'

type PersonInformationType = {|
  name: string,
  type:
    | 'firstname'
    | 'lastname'
    | 'linkedUSer'
    | 'jobs'
    | 'underContract'
    | 'thumbnail'
    | 'externalJob1'
    | 'externalJob2',
|}

export type PersonInformationNodeData = {|
  personInformation: PersonInformationType,
  contentType: 'personInformation',
|}

const personInformationItems: PersonInformationType[] = [
  { name: 'Firstname', type: 'firstname' },
  { name: 'Lastname', type: 'lastname' },
  { name: 'Linked user', type: 'linkedUSer' },
  { name: 'Jobs (read-only)', type: 'jobs' },
  { name: 'External Job 1', type: 'externalJob1' },
  { name: 'External Job 2', type: 'externalJob2' },
  { name: 'Under contract', type: 'underContract' },
  { name: 'Thumbnail', type: 'thumbnail' },
]

export function PersonInformations(): React$Node {
  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: personInformationItems.map((personInformation) => {
        return new Node<$Shape<SchemaColumnSettings<PersonInformationNodeData>>>({
          key: personInformation.name,
          name: personInformation.name,
          parent: rootNode,
          data: { personInformation, contentType: 'personInformation' },
          icon: 'fas-grip-vertical',
          type: NODE_ITEM,
        })
      }),
    })

    return rootNode
  }, [])

  return (
    <div style={{ padding: '0 10px 10px 10px', maxWidth: '100%', width: '100%' }}>
      <TreeDnD rootNode={rootNode} />
    </div>
  )
}
