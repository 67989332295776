// @flow
import * as React from 'react'
import cx from 'classnames'
import classes from './Title.module.scss'

type Props = {
  size: '1' | '2' | '3' | '4' | 1 | 2 | 3 | 4,
  children: React.Node,
  className?: string,
}

export function Title(props: Props): React$Node {
  const { size, className, children, ...rest } = props
  return (
    <div {...rest} className={cx(classes.container, classes[`size-${size}`], className)}>
      {children}
    </div>
  )
}
