var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { useState, useEffect } from 'react';
import { map, filter, sortBy, find } from 'lodash';
import resources from 'app/store/resources';
import { getResources } from 'app/store/selectors';
import moment from 'moment';
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx';
import classes from './OrganizationChart.module.scss';
import OrganizationChartCarousel from 'app/components/OrganizationChart/GlobalPage/OrganizationChartCarousel';
import { Input } from 'app/components/Form';
import { MUIAutocompleteSelect } from 'app/components/Form/Autocomplete/MUIAutocompleteSelect.jsx';
import OrganizationChartBottom from 'app/components/OrganizationChart/GlobalPage/OrganizationChartBottom';
import { IconButton } from '@mui/material';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters, setFilter, fetchOrganizationChartPeople } from 'app/store/reducers/organizationChart';
import OrganizationChartBottomSkeleton from 'app/components/OrganizationChart/GlobalPage/OrganizationChartBottomSkeleton';
import { CardSkeleton } from 'app/components/OrganizationChart/GlobalPage/CardSkeleton';
const OrganizationChart = () => {
    const dispatch = useDispatch();
    const [newArrived, setNewArrived] = useState([]);
    const [peopleUnderContractThisMonth, setPeopleUnderContractThisMonth] = useState([]);
    const { people, loading: isLoading, filters: { searchInput, filterStudio, filterProject }, } = useSelector((state) => state.organizationChart);
    const projectPlans = useSelector((state) => {
        return getResources(state, 'projectPlans');
    });
    const offices = useSelector((state) => {
        return getResources(state, 'assets', { assetType: 'of' });
    });
    const minDateDays = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    const maxDateDays = moment().add(30, 'days').format('YYYY-MM-DD');
    const minDateYear = moment().subtract(12, 'months').format('YYYY-MM-DD');
    useEffect(() => {
        if (Object.keys(people).length === 0) {
            ;
            (() => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    yield Promise.all([resources.projectPlans.fetchAll({ params: { queries: { page_size: 1000 } } })]);
                    dispatch(fetchOrganizationChartPeople());
                }
                catch (error) {
                    throw new Error(error);
                }
            }))();
        }
    }, []);
    useEffect(() => {
        if (!isLoading && people.length > 0) {
            setPeopleUnderContractThisMonth(map(people, (p) => {
                const currentContract = find(p.contractsInst, (contract) => contract.startDate <= moment().endOf('month').format('YYYY-MM-DD') &&
                    contract.endDate >= moment().startOf('month').format('YYYY-MM-DD'));
                return Object.assign(Object.assign({}, p), { currentContract });
            }));
        }
    }, [isLoading, people]);
    useEffect(() => {
        if (!isLoading && peopleUnderContractThisMonth.length > 0) {
            setNewArrived(filter(peopleUnderContractThisMonth, (p) => {
                var _a, _b, _c, _d;
                if (p.contractsInst && p.contracts.length === 1) {
                    return ((_a = p.contractsInst[0]) === null || _a === void 0 ? void 0 : _a.startDate) >= minDateDays && ((_b = p.contractsInst[0]) === null || _b === void 0 ? void 0 : _b.startDate) <= today;
                }
                if (p.contractsInst && p.contracts.length > 1) {
                    const filteredContract = filter(p.contractsInst, (contract) => contract.startDate <= maxDateDays);
                    const sortedContract = sortBy(filteredContract, ['startDate']).reverse();
                    return ((_c = sortedContract[0]) === null || _c === void 0 ? void 0 : _c.startDate) >= minDateDays && ((_d = sortedContract[1]) === null || _d === void 0 ? void 0 : _d.endDate) <= minDateYear;
                }
                return null;
            }));
        }
    }, [peopleUnderContractThisMonth, isLoading]);
    const getOnChangeDispatchFilter = (filterName, filterValue) => {
        dispatch(setFilter(filterName, filterValue));
    };
    const onResetFilters = () => {
        dispatch(resetFilters());
    };
    const getOnChange = (name, onChange, label) => {
        return (values) => {
            var _a;
            const allOpt = values.find((opt) => opt.value === 'all');
            if (values.length === 0)
                return onChange(name, [{ label: `All ${label}`, value: 'all' }]);
            if (((_a = values[values.length - 1]) === null || _a === void 0 ? void 0 : _a.value) === 'all')
                return onChange(name, [values[values.length - 1]]);
            if (allOpt && values.length > 1)
                return onChange(name, values.filter((opt) => opt.value !== 'all'));
            return onChange(name, values);
        };
    };
    if (!isLoading && !peopleUnderContractThisMonth)
        return (_jsx("div", { style: { height: '100%', width: '100%' }, children: _jsx("p", { style: { textAlign: 'center' }, children: "No people currently under contract saved in the database." }) }));
    return (_jsxs("div", { className: classes.container, children: [_jsxs("div", { className: classes.embla, children: [_jsx("div", { className: classes.textNewArrivals, children: "New arrivals :" }), isLoading ? (_jsx("div", { className: classes.skeletonContainer, children: _jsx(CardSkeleton, { cards: 3 }) })) : newArrived.length === 0 ? (_jsx("div", { className: classes.textNoNewArrivals, children: "Nobody arrived recently." })) : (_jsx(OrganizationChartCarousel, { people: newArrived, options: {
                            align: 'start',
                            dragFree: false,
                            containScroll: 'trimSnaps',
                            loop: false,
                        }, offices: offices, projectPlans: projectPlans }))] }), _jsx("div", { style: { height: '100%', width: '100%' }, children: isLoading ? (_jsx(OrganizationChartBottomSkeleton, {})) : (peopleUnderContractThisMonth.length && (_jsxs(_Fragment, { children: [_jsxs("div", { style: {
                                display: 'flex',
                                width: '95%',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                marginTop: 15,
                            }, children: [_jsx("div", { children: _jsx(MUIAutocompleteSelect, { options: map(offices, (office) => ({ label: office.name, value: office.id })), label: "All studios", value: filterStudio, style: {
                                            width: 300,
                                            height: 43,
                                            backgroundColor: 'white',
                                            border: '1px solid #b3b9c7',
                                            color: '#b3b9c7',
                                        }, onChange: getOnChange('filterStudio', getOnChangeDispatchFilter, 'studios'), multiple: true, titled: true, endAdornment: _jsx(_Fragment, {}) }) }), _jsx("div", { children: _jsx(MUIAutocompleteSelect, { options: map(projectPlans, (project) => ({ label: project.name, value: project.id })), label: "All projects", value: filterProject, style: {
                                            width: 300,
                                            height: 43,
                                            backgroundColor: 'white',
                                            border: '1px solid #b3b9c7',
                                            color: '#b3b9c7',
                                        }, onChange: getOnChange('filterProject', getOnChangeDispatchFilter, 'projects'), multiple: true, titled: true, endAdornment: _jsx(_Fragment, {}) }) }), _jsx(Input, { dataCy: "input-search", placeholder: "Search people by first name or last name", value: searchInput, onChange: (e) => dispatch(setFilter('searchInput', e.target.value)), style: { borderRadius: 0 }, styleContainer: { maxWidth: 350, height: 43 } }), _jsx(Tooltip, { title: "Reset filters", children: _jsx(IconButton, { style: {
                                            width: 20,
                                            height: 20,
                                            marginLeft: 10,
                                        }, onClick: onResetFilters, children: _jsx(FontIcon, { icon: "fas-times-circle" }) }) })] }), _jsx("div", { className: classes.cardContainer, children: _jsx(OrganizationChartBottom, { searchInput: searchInput.toLowerCase(), people: peopleUnderContractThisMonth, offices: offices, projectPlans: projectPlans, filterStudio: filterStudio, filterProject: filterProject }) })] }))) })] }));
};
export default OrganizationChart;
