/** @flow */
import React from 'react'
import type { Column } from '../../types'

export const groupingFns: $ElementType<Column, 'groupingFns'> = {
  hasComment: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const resKey = String(Boolean(row.values[columnId]?.length > 0))
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Contain media',
    headerRow: (cell) =>
      cell.value?.length > 0 ? (
        <div className="bold">Contain media</div>
      ) : (
        <div className="bold">Do not contain media</div>
      ),
  },
}
