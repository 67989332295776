/** @flow */
import React from 'react'
import type { ResourcesList, ProgressionStatus } from 'app/core/types'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'

import type { Column } from '../../types'
import classes from './groupingFns.module.scss'

export const groupingFns: (
  progressionStatus: ResourcesList<ProgressionStatus>,
) => $ElementType<Column, 'groupingFns'> = (progressionStatus) => ({
  status: {
    fn: (rows, columnId, instance) => {
      const ordered = {}
      const unordered = rows.reduce((prev, row, i) => {
        const resKey = String(row.values[columnId])
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})

      Object.keys(unordered)
        .sort((prev, current) => {
          if (current === 'undefined') return -2
          const currentScore = progressionStatus[current]?.order || 0
          const prevScore = progressionStatus[prev]?.order || 0
          if (currentScore > prevScore) return -1
          return 1
        })
        .forEach((key) => {
          ordered[key] = unordered[key]
        })
      return ordered
    },
    label: 'Status',
    headerRow: (cell) => {
      if (!cell.value) return null

      const { color, name } = progressionStatus[cell.value]

      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: color,
            color: getColorFromBackground(color),
          }}
        >
          {name}
        </div>
      )
    },
  },
})
