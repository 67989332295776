/** @flow */
import React, { useRef } from 'react'
import { Editor, type EditorProps, type TSEditorProps } from 'app/components/TextEditor/Editor.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import classes from './Richtext.module.scss'

export interface TSProps extends TSEditorProps {
  maxHeight?: number;
  minHeight?: number;
  defaultHeight?: number;
}

export type Props = {|
  ...EditorProps,
  maxHeight?: number,
  minHeight?: number,
  defaultHeight?: number,
|}

export function Richtext(props: Props): React$Node {
  const { value, onChange, defaultHeight = 200, maxHeight = 1000, minHeight = 200, ...rest } = props

  const containerRef = useRef()

  const maxSize = maxHeight - 15
  let origSize = 0
  let origY = 0
  let origMouseY = 0

  function resize(e) {
    const height = origSize + (e.pageY - origMouseY)

    if (height > minHeight && height < maxSize && containerRef.current) {
      containerRef.current.style.height = `${height}px`
      containerRef.current.style.left = `${origY + (e.pageY - origMouseY)}px`
    }
  }

  function stopResize(e) {
    window.removeEventListener('mousemove', resize)
    window.removeEventListener('mouseup', stopResize)
  }

  return (
    <div
      style={{ height: defaultHeight, maxHeight, width: '100%', maxWidth: '100%' }}
      ref={(ref) => {
        containerRef.current = ref
      }}
    >
      <Editor
        value={value}
        onChange={onChange}
        className={classes.editor}
        customToolbar={[
          'heading',
          '|',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'alignment',
          'blockQuote',
          'code',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo',
        ]}
        {...rest}
      />
      <div
        className={classes.draggablePart}
        onMouseDown={(e) => {
          e.preventDefault()

          if (!containerRef.current) return

          origSize = parseFloat(getComputedStyle(containerRef.current, '').getPropertyValue('height').replace('px', ''))
          // $FlowFixMe
          origY = containerRef.current.getBoundingClientRect().left
          origMouseY = e.pageY

          window.addEventListener('mousemove', resize)
          window.addEventListener('mouseup', stopResize)
        }}
      >
        <FontIcon icon="fas-sort-down" className={classes.draggableIcon} />
      </div>
    </div>
  )
}
