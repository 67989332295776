/** @flow */
import React from 'react'
import { dateToLocaleStringFull } from 'app/libs/helpers'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import { router as assetRouter } from 'app/containers/Assets/AssetDetail/router.js'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type { Task, Asset, Take } from 'app/core/types'
import classes from './Task.module.scss'

type Props = {|
  task: Task,
  timeEstim: string,
  timeReal: string,
  project: ?Asset,
  asset: ?Asset,
  lastTake: ?Take,
|}

export function TaskInformations(props: Props): React$Node {
  const { task, timeEstim, timeReal, project, asset, lastTake } = props
  return (
    <div className="grid col2 rowAuto padding10">
      {project
        ? [
            <span key="1" className="bold flex alignCenter end">
              Project:
            </span>,
            <div key="2" className="flex row alignCenter">
              {project.thumbnailInst && project.thumbnailInst.url ? (
                <div
                  className={classes.thumbnail}
                  style={{
                    background: `url(${project.thumbnailInst.url}) center center / cover no-repeat`,
                  }}
                />
              ) : null}
              <div className="fontSize20 bold">{project.name}</div>
            </div>,
          ]
        : null}
      <span className="bold flex alignCenter end">Asset:</span>
      <div className="flex row alignCenter">
        {asset && asset.thumbnailInst && asset.thumbnailInst.url ? (
          <div
            className={classes.thumbnail}
            style={{
              background: `url(${asset.thumbnailInst.url}) center center / cover no-repeat`,
            }}
          />
        ) : null}
        <div
          onClick={() => {
            asset && assetRouter.goTo('index', { assetId: asset.id }, { rightPanel: true })
          }}
          className={`${classes.assetLink} flex row noWrap alignCenter`}
        >
          {task.assetInst.name} <FontIcon icon="fas-external-link-square-alt" className="marginLeft10" />
        </div>
      </div>
      <span className="bold flex alignCenter end">Worker:</span>
      <div className="flex">
        <div className={classes.user}>{task.assignedUserInst && task.assignedUserInst.name}</div>
      </div>
      {lastTake && lastTake.statusInst && lastTake.statusInst.color
        ? [
            <span key="1" className="bold flex alignCenter end">
              Status:
            </span>,
            <div key="2" className="flex">
              <div
                className={classes.status}
                style={{
                  backgroundColor: lastTake.statusInst.color,
                  color: getColorFromBackground(lastTake.statusInst.color),
                }}
              >
                {lastTake.statusInst.name}
              </div>
            </div>,
          ]
        : null}
      <span className="bold flex alignCenter end">Start Date: </span>{' '}
      {task.startDate ? dateToLocaleStringFull(new Date(task.startDate)) : ''}
      <span className="bold flex alignCenter end">End Date: </span>{' '}
      {task.endDate ? dateToLocaleStringFull(new Date(task.endDate)) : ''}
      <span className="bold flex alignCenter end">Earliest start date: </span>{' '}
      {task.earliestStartDate ? dateToLocaleStringFull(new Date(task.earliestStartDate)) : ''}
      <span className="bold flex alignCenter end">Latest end date: </span>{' '}
      {task.latestEndDate ? dateToLocaleStringFull(new Date(task.latestEndDate)) : ''}
      <span className="bold flex alignCenter end">Time (estim):</span> {timeEstim}
      <span className="bold flex alignCenter end">Time (real):</span> {timeReal}
    </div>
  )
}
