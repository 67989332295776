/** @flow */
import React, { useState } from 'react'
import { Duration } from 'app/components/Duration/Duration.tsx'
// TODO : when converting this component to TS, remove DurationProps below and import from app/components/Duration/Duration.tsx
import type { Activity, DurationProps } from 'app/core/types'

import type { PrefsProps, CellEdition, Cell } from '../../types'

type Props = {|
  ...$Exact<DurationProps>,
  activity: ?Activity,
  cell: Cell,
  prefs: PrefsProps,
  isRowExpanded: boolean,
  edition: CellEdition,
  textAlignment?: 'left' | 'center' | 'right',
|}

export function TextDuration(props: Props): React$Node {
  const { activity, prefs, isRowExpanded, edition, cell, textAlignment, ...rest } = props
  const { minLineHeight, maxLineHeight } = prefs
  const { endEdit, save } = edition

  const [additionnalValue, setAdditionnalValue] = useState(activity?.comment || '')

  function additionnalInputComponent(componentProps) {
    const { onFocus, onBlur, ref, onKeyDown } = componentProps
    return (
      <div
        style={{
          position: 'absolute',
          top: isRowExpanded ? maxLineHeight : minLineHeight,
          left: textAlignment === 'left' ? 0 : undefined,
          right: textAlignment === 'right' ? 0 : undefined,
          zIndex: 10,
          backgroundColor: '#ffffff',
          padding: '4px 4px 0 4px',
          borderRadius: '4px',
          border: '1px solid rgba(0,0,0,0.05)',
          boxShadow: '0px 5px 10px -7px rgb(0 0 0 / 50%)',
        }}
      >
        <textarea
          placeholder="Comment..."
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          ref={ref}
          rows={4}
          value={additionnalValue}
          onChange={(e) => setAdditionnalValue(e.target.value)}
          style={{
            outline: 'none',
            border: '1px solid rgba(0,0,0,0.2)',
            borderRadius: '2px',
            padding: '2px 4px',
          }}
        />
      </div>
    )
  }

  return (
    <Duration
      value={activity?.duration || 0}
      center={true}
      inputsRef={(refs) => {
        if (refs) {
          ;(refs.h || refs.d || refs.m)?.select?.()
        }
      }}
      onCancel={() => {
        endEdit()
        setTimeout(() => cell.focusCell(), 0)
      }}
      onEnter={(newValue) => {
        endEdit()
        cell.focusCell()
      }}
      onBlur={(newValue) => {
        if (!newValue && !activity?.duration && !additionnalValue && !activity?.comment) endEdit()
        else if (activity?.duration !== newValue || additionnalValue !== activity?.comment) {
          save({ duration: newValue, text: additionnalValue })
        } else endEdit()
      }}
      className="flex center alignCenter fullWidth fullHeight"
      additionnalInput={additionnalInputComponent}
      {...rest}
    />
  )
}
