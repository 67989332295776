// @flow
import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { AssetsCustomAttributValuesActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const assetsCustomAttributValues: StoreResourceDef<AssetsCustomAttributValuesActions<>> = {
  resourceType: null,
  actions: (getResourcesActions) => ({
    fetch: (attribut, config = {}) => {
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.assets
            .customAttributValues(null, { attribut, page_size: 1000, ...config?.params?.queries })
            .then((res) => [{ id: attribut, values: res?.results || [] }])
            .then(transformResults(config)),
      }
    },
  }),
}
