// @flow
import * as React from 'react'
import type { Match } from 'react-router-dom'
import pipe from 'app/core/utils/pipe'
import type { Pipe, ID } from 'app/core/types'
import resources from 'app/store/resources'
import history from 'app/main/routerHistory'
import { AssetsTreeSearch } from 'app/containers/Assets/AssetsTree'
import type { Node } from 'app/components/Tree/TreeRow.jsx'
import { getResources } from 'app/store/selectors/index.js'
import Widget from 'app/components/Widget/Widget.jsx'
import { permission } from 'app/containers/Permissions/Permission.jsx'

import { RightContentContainer } from '../FollowUp/RightContent.jsx'

type Props = {|
  match: Match,
|}

class ExternalFollowUpView extends React.PureComponent<Props> {
  onClickTreeItem = (item: $ElementType<Node, 'data'>) => {
    const { metadata } = item
    if (!metadata) return
    history.pushWithParams(this.props.match, { assetId: metadata.id }, window.location.search)
  }

  onSearchAsset = (assetId: ID) => {
    history.pushWithParams(this.props.match, { assetId })
  }

  render(): React$Node {
    const { match } = this.props
    const { projectId, assetId } = match.params

    return (
      <div style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
        <Widget
          enableHideColumn={true}
          collapseTitle="Asset list"
          resizable={true}
          optionName="widget-externalFu"
          defaultWidth={300}
        >
          <AssetsTreeSearch
            assetTypes={['ep', 'fo', 'mo', 'sh', 'mi']}
            onClickItem={this.onClickTreeItem}
            currentAssetId={assetId}
            rootAssetId={projectId}
            onSearchAsset={this.onSearchAsset}
            disbaleContextMenu={true}
            resizable={true}
          />
        </Widget>
        <div className="fullHeight flex1">
          {assetId && (
            <RightContentContainer
              isExternal={true}
              permissions={{
                tasks: permission(
                  ['projet_external follow-up__update', 'projet_external follow-up_update type_tasks'],
                  'or',
                ),
                takes: permission(
                  ['projet_external follow-up__update', 'projet_external follow-up_update type_takes'],
                  'or',
                ),
                assets: permission(
                  ['projet_external follow-up__update', 'projet_external follow-up_update type_assets'],
                  'or',
                ),
                attributes: permission(
                  ['projet_external follow-up__update', 'projet_external follow-up_update type_assets attributes'],
                  'or',
                ),
                steps: permission(
                  ['projet_external follow-up__update', 'projet_external follow-up_update type_steps'],
                  'or',
                ),
                dynApp: permission(
                  ['projet_external follow-up__update', 'projet_external follow-up_update type_dynamic approvals'],
                  'or',
                ),
                notes: permission(
                  ['projet_external follow-up__update', 'projet_external follow-up_update type_notes'],
                  'or',
                ),
                assetLinks: permission(
                  ['projet_external follow-up__update', 'projet_external follow-up_update type_breakdown'],
                  'or',
                ),
                timeline: {
                  read: permission(
                    [
                      'projet_external follow-up_timeline_read',
                      'projet_external follow-up_timeline_update start/end dates',
                      'projet_external follow-up_timeline_update earliest/latest dates',
                    ],
                    'or',
                  ),
                  startEndUpdate: permission(['projet_external follow-up_timeline_update start/end dates']),
                  earliestLatestUpdate: permission(['projet_external follow-up_timeline_update earliest/latest dates']),
                },
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

const pipeInst: Pipe<{}, typeof ExternalFollowUpView> = pipe()

export const ExternalFollowUp: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => {
    const { match } = props
    const { assetId } = match.params

    return {
      asset: getResources(state, 'assets', assetId),
      projectId: state.project ? state.project.id : '',
    }
  })
  .request(({ projectId }) => {
    return Promise.all([
      resources.trackingSchemas.fetchExternalsByProject(projectId, { params: { queries: { isVisible: true } } }),
      resources.assets.fetchEpisodes(projectId),
      resources.stepProjects.fetchByProject(projectId),
      resources.attributes.fetchByProject(projectId),
      resources.progressionStatus.fetchAll(),
      resources.dynamicApprovals.fetchByProject(projectId),
    ])
  })
  .render(({ projectId, ...props }) => {
    // $FlowFixMe[prop-missing] $FlowFixMe Error when updating flow
    return <ExternalFollowUpView {...props} />
  })
