/** @flow */
import React from 'react'
import type { TrackingSchemaGroup } from 'app/core/types'
import ModalConfirmForm from 'app/components/Modal/ModalConfirmForm'
import FormData from 'app/components/Form/FormData/FormData'
import type { ModalProps } from 'app/components/Modal/MUIModal.jsx'

type ModalEditTrackingSchemaGroupProps = {|
  ...$Shape<$Diff<ModalProps, { children: any }>>,
  group?: TrackingSchemaGroup,
  onSave: (group: { ...$Shape<TrackingSchemaGroup>, readOnly: boolean | 'ind' }) => Promise<any>,
  readOnly?: boolean | 'ind',
|}

export function ModalEditTrackingSchemaGroup(props: ModalEditTrackingSchemaGroupProps): React$Node {
  const { group, onSave, readOnly, ...rest } = props

  return (
    <ModalConfirmForm title={group ? `Edit ${group.groupName}` : 'Add a new group'} draggable={false} {...rest}>
      <FormData
        defaultData={{
          groupName: group?.groupName || '',
          readOnly: readOnly || false,
        }}
        properties={[
          {
            key: 'groupName',
            label: 'Group name',
            type: 'string',
            elementProps: {
              isRequired: true,
            },
          },
          {
            key: 'readOnly',
            label: 'Read only',
            type: 'checkbox',
            elementProps: (data, setData) => {
              return {
                indeterminate: data.readOnly === 'ind',
                onChange: (value) => setData({ readOnly: value }),
                checked: Boolean(data.readOnly),
              }
            },
          },
        ]}
        onSave={onSave}
        flashNotifSuccessLabel={`Group ${group ? 'updated' : 'created'}`}
      />
    </ModalConfirmForm>
  )
}
