/** @flow */
import React, { useMemo } from 'react'
import classes from './LoaderSmall.module.scss'

type Props = {|
  className?: string,
  style?: Object,
  size?: number | string,
  thick?: number,
  color?: string,
  bgColor?: string,
  ref?: React$ElementRef<any>,
|}

function LoaderSmall(props: Props): React$Node {
  // eslint-disable-next-line react/prop-types
  const { className, ref, color, bgColor, size = 12, thick = 3, style, ...rest } = props

  const _style = useMemo(
    () => ({
      borderTop: `${thick}px solid ${bgColor || 'rgba(230, 230, 230, 1)'}`,
      borderRight: `${thick}px solid ${bgColor || 'rgba(230, 230, 230, 1)'}`,
      borderBottom: `${thick}px solid ${bgColor || 'rgba(230, 230, 230, 1)'}`,
      borderLeft: `${thick}px solid ${color || 'rgba(100, 100, 100, 1)'}`,
      width: size,
      height: size,
      ...style,
    }),
    [size, color, style, thick],
  )

  return (
    <div
      className={`${classes.loader} ${className || ''}`}
      ref={(elRef) => {
        if (ref?.current) ref.current = elRef
      }}
      style={_style}
      {...rest}
    />
  )
}

export default LoaderSmall
