// @flow
import type { StoreResourceDef } from 'app/core/types'
import api from 'app/core/api/api.js'
import type { PresetFiltersActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'
import { autocomplete } from './autocomplete.js'

export const presetFilters: StoreResourceDef<PresetFiltersActions<>> = {
  resourceType: 'presetFilters',
  actions: (getResources) => ({
    fetchAllByProject: (projectId, tableType, config) => {
      const { requestKey } = config || {}
      return {
        type: 'read',
        requestKey,
        request: () =>
          api.presetFilters
            .fetchAll({ project__uuid: projectId || undefined, tableType, public: 'True' })
            .then(transformResults(config)),
      }
    },
    search: autocomplete('filter'),
  }),
}
