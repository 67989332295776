// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetDetail-BreakdownShot-___Breakdown-module__table {
  overflow: hidden;
  flex: 1;
}

.app-containers-Assets-AssetDetail-BreakdownShot-___Breakdown-module__openIconClassName {
  cursor: pointer;
  color: var(--colors-mainColor);
}

.app-containers-Assets-AssetDetail-BreakdownShot-___Breakdown-module__openIconClassName:hover {
  color: var(--colors-mainColorMedium);
}
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetDetail/BreakdownShot/Breakdown.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,OAAO;AACT;;AAEA;EACE,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".table {\n  overflow: hidden;\n  flex: 1;\n}\n\n.openIconClassName {\n  cursor: pointer;\n  color: var(--colors-mainColor);\n}\n\n.openIconClassName:hover {\n  color: var(--colors-mainColorMedium);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `app-containers-Assets-AssetDetail-BreakdownShot-___Breakdown-module__table`,
	"openIconClassName": `app-containers-Assets-AssetDetail-BreakdownShot-___Breakdown-module__openIconClassName`
};
export default ___CSS_LOADER_EXPORT___;
