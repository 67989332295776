// @flow
import { mapValues } from 'lodash'

export default (resourceType: string, options: Object): ((state: Object, action: Object) => Object) => {
  return (state: Object, action: Object) => {
    if (
      action.resourceType !== resourceType ||
      (action.type !== 'DELETE_RESOURCES_SUCCEEDED' && !action.paginatedList)
    ) {
      return state
    }

    switch (action.type) {
      case 'READ_RESOURCES_SUCCEEDED': {
        const { resources, paginatedList, requestProperties } = action

        return {
          ...state,
          paginatedLists: {
            ...state.paginatedLists,
            [paginatedList]: {
              ...requestProperties,
              ids: resources.map((resource) => resource.id),
            },
          },
        }
      }

      case 'CREATE_RESOURCES_SUCCEEDED': {
        const { resources, paginatedList } = action

        // Array to add the create resources ids in all the paginatedList in params
        const paginatedListNames = Array.isArray(paginatedList) ? paginatedList : [paginatedList]
        const paginatedLists = {}
        paginatedListNames.forEach((name) => {
          paginatedLists[name] = state.paginatedLists[name]
        })

        return {
          ...state,
          paginatedLists: {
            ...state.paginatedLists,
            ...mapValues(paginatedLists, (_paginatedList) => {
              return {
                ...(_paginatedList || {}),
                ids: [...resources.map((resource) => resource.id), ...(_paginatedList?.ids || [])],
                count: (_paginatedList?.count || 0) + resources.length,
              }
            }),
          },
        }
      }

      case 'DELETE_RESOURCES_SUCCEEDED': {
        const { resources } = action

        return {
          ...state,
          paginatedLists: mapValues(state.paginatedLists, (list) => ({
            ...list,
            ids: list.ids.filter((id) => !resources.find((_id) => _id === id)),
            count: list.count - resources.length,
          })),
        }
      }

      default:
        return state
    }
  }
}
