// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Textarea-___Textarea-module__container {
  position: relative;
  flex-direction: row;
  height: 100%;
}

.app-components-Form-Textarea-___Textarea-module__fieldError {
  position: absolute;
  right: 0px;
}

.app-components-Form-Textarea-___Textarea-module__input {
  border: solid 1px var(--colors-grey);
  border-radius: var(--mainBorderRadius);
  box-sizing: border-box;
  display: block;
  outline-color: var(--colors-mainColor);
  padding: 6px 10px;
  font-family: var(--mainFont);
  font-size: 14px;
  color: var(--colors-black);
  background-color: #fff;
  width: 100%;
  resize: vertical;
}

.app-components-Form-Textarea-___Textarea-module__input:disabled {
  background: #eeeeee;
  color: #888888;
  cursor: not-allowed;
}

.app-components-Form-Textarea-___Textarea-module__input.app-components-Form-Textarea-___Textarea-module__error {
  border-color: var(--colors-red);
  box-shadow: 0 0 3px var(--colors-red);
}

.app-components-Form-Textarea-___Textarea-module__input:focus {
  border-color: var(--colors-mainColor);
  box-shadow: 0px 0px 1px 1px var(--colors-mainColor);
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Textarea/Textarea.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,oCAAoC;EACpC,sCAAsC;EACtC,sBAAsB;EACtB,cAAc;EACd,sCAAsC;EACtC,iBAAiB;EACjB,4BAA4B;EAC5B,eAAe;EACf,0BAA0B;EAC1B,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;EACrC,mDAAmD;AACrD","sourcesContent":[".container {\n  position: relative;\n  flex-direction: row;\n  height: 100%;\n}\n\n.fieldError {\n  position: absolute;\n  right: 0px;\n}\n\n.input {\n  border: solid 1px var(--colors-grey);\n  border-radius: var(--mainBorderRadius);\n  box-sizing: border-box;\n  display: block;\n  outline-color: var(--colors-mainColor);\n  padding: 6px 10px;\n  font-family: var(--mainFont);\n  font-size: 14px;\n  color: var(--colors-black);\n  background-color: #fff;\n  width: 100%;\n  resize: vertical;\n}\n\n.input:disabled {\n  background: #eeeeee;\n  color: #888888;\n  cursor: not-allowed;\n}\n\n.input.error {\n  border-color: var(--colors-red);\n  box-shadow: 0 0 3px var(--colors-red);\n}\n\n.input:focus {\n  border-color: var(--colors-mainColor);\n  box-shadow: 0px 0px 1px 1px var(--colors-mainColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Form-Textarea-___Textarea-module__container`,
	"fieldError": `app-components-Form-Textarea-___Textarea-module__fieldError`,
	"input": `app-components-Form-Textarea-___Textarea-module__input`,
	"error": `app-components-Form-Textarea-___Textarea-module__error`
};
export default ___CSS_LOADER_EXPORT___;
