// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Autocomplete-AutocompleteMultiple-___AutocompleteMultiple-module__selectContainer {
  margin-bottom: '10px';
}

.app-components-Form-Autocomplete-AutocompleteMultiple-___AutocompleteMultiple-module__item {
  position: relative;
  margin: 2px;
  background-color: #555;
  color: #fff;
  padding: 3px 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.app-components-Form-Autocomplete-AutocompleteMultiple-___AutocompleteMultiple-module__items {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Autocomplete/AutocompleteMultiple/AutocompleteMultiple.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".selectContainer {\n  margin-bottom: '10px';\n}\n\n.item {\n  position: relative;\n  margin: 2px;\n  background-color: #555;\n  color: #fff;\n  padding: 3px 7px;\n  border-radius: 3px;\n  display: flex;\n  align-items: center;\n}\n\n.items {\n  display: flex;\n  flex-wrap: wrap;\n  padding-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectContainer": `app-components-Form-Autocomplete-AutocompleteMultiple-___AutocompleteMultiple-module__selectContainer`,
	"item": `app-components-Form-Autocomplete-AutocompleteMultiple-___AutocompleteMultiple-module__item`,
	"items": `app-components-Form-Autocomplete-AutocompleteMultiple-___AutocompleteMultiple-module__items`
};
export default ___CSS_LOADER_EXPORT___;
