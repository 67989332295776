/** @flow */
import type { StoreResourceName } from 'app/core/types'

const colors = {
  projectPlansMain: '#ef476f',
  projectPlansLight: '#f38da8',
  projectDepartmentsMain: '#c5c2c2',
  projectDepartmentsLight: '#cecece',
  projectDepartmentJobsMain: '#767676',
  projectDepartmentJobsLight: '#919191',
  imputationsMain: '#d3d3d3',
  imputationsLight: '#e3e3e3',
  personsMain: '#118ab2',
  personsLight: '#6bb7cf',
  contractsMain: '#ffd166',
  contractsLight: '#fce1a3',
}

export const resourcesColors = (
  resource: StoreResourceName,
  selected: boolean,
  imputationContract: boolean,
): string => {
  const key = `${resource}${selected ? 'Main' : 'Light'}`
  return colors[key]
}
