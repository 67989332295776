// @flow
import { map } from 'lodash'
import { presetOperators as pop } from 'app/components/Table/TableView/Elements/Filters/operators.ts'
import { optionsProgressionStatus } from 'app/core/utils/optionsProgressionStatus'
import type { ResourcesList, ProgressionStatus, Step, Asset, ColumnFilter } from 'app/core/types'
import { getResources } from 'app/store/selectors'

type Params = {|
  status: ResourcesList<ProgressionStatus>,
  steps: ResourcesList<Step>,
|}

export class Filters {
  constructor({ status, steps }: Params) {
    this.params = {
      ...this.params,
      statusOptions: status,
      stepsOptions: map(steps, (step) => {
        return { value: step.id, label: step.name }
      }),
    }
  }

  params: Object = {}

  getFilters(): Array<ColumnFilter> {
    return [
      {
        label: 'Asset',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => ({
            ...op,
            attr: 'asset__uuid',
            multiple: true,
            inputType: 'asset',
            getSubLabel: (asset: Object) => {
              if (!asset) return undefined
              return asset.parent__name
            },
          })),
          ...map(pop.text, (op) => ({
            ...op,
            attr: 'asset__name',
            inputType: 'text',
          })),
        ],
      },
      {
        label: 'Asset parent',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => ({
            ...op,
            attr: 'asset__parent__uuid',
            multiple: true,
            assetTypes: ['fo', 'ep'],
            inputType: 'asset',
            getSubLabel: (asset: Object) => {
              const project = getResources(undefined, 'assets', asset.project)
              if (!project) return undefined
              return project.name
            },
            getProjectHeader: (asset: Asset) => {
              const project = getResources(undefined, 'assets', asset.project)
              if (!project) return undefined
              return project.id
            },
          })),
          ...map(pop.text, (op) => ({
            ...op,
            attr: 'asset__parent__name',
            inputType: 'text',
          })),
        ],
      },
      {
        label: 'Status',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => ({
            ...op,
            attr: 'status__uuid',
            options: {
              label: 'Status',
              data: optionsProgressionStatus(this.params.statusOptions),
            },
            multiple: true,
            inputType: 'select',
            resourceValue: 'progressionStatus',
          })),
        ],
      },
      {
        label: 'Project',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => ({
            ...op,
            attr: 'asset__project__uuid',
            multiple: true,
            inputType: 'asset',
            assetTypes: ['pj'],
          })),
        ],
      },
      {
        label: 'Start date',
        type: 'column',
        operators: [
          ...map(pop.date, (op) => ({
            ...op,
            attr: 'startDate',
            inputType: 'date',
          })),
        ],
      },
      {
        label: 'End date',
        type: 'column',
        operators: [
          ...map(pop.date, (op) => ({
            ...op,
            attr: 'endDate',
            inputType: 'date',
          })),
        ],
      },
      {
        label: 'Episode',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => {
            return {
              ...op,
              attr: 'episode',
              multiple: true,
              inputType: 'asset',
              assetTypes: ['ep'],
              getSubLabel: (asset: Asset) => {
                const project = getResources(undefined, 'assets', asset.project)
                if (!project) return undefined
                return project.name
              },
              getProjectHeader: (asset: Asset) => {
                const project = getResources(undefined, 'assets', asset.project)
                if (!project) return undefined
                return project.id
              },
            }
          }),
        ],
      },
      {
        label: 'Step',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => ({
            ...op,
            attr: 'step__uuid',
            options: {
              label: 'Step',
              data: this.params.stepsOptions,
            },
            multiple: true,
            inputType: 'select',
            formatValue: (val: string) => this.params.stepsOptions.find((opt) => opt.label === val)?.value,
          })),
        ],
      },
    ]
  }
}
