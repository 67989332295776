// @flow

import * as React from 'react'
import type { ElementProps } from 'app/core/types'
import { getUserSettings, setUserSettings } from 'app/libs/helpers/userSettings'
import classes from './ResizableRow.module.scss'
import FontIcon from '../FontIcon/FontIcon.jsx'

type Props = {|
  ...ElementProps,
  defaultTopSize?: number,
  defaultBottomSize?: number,
  top: React$Element<any> | Array<React$Element<any>>,
  bottom: React$Element<any> | Array<React$Element<any>>,
  styleTop?: Object,
  styleBottom?: Object,
  optionName?: string,
  bottomMin?: number,
  topMin?: number,
|}

export function ResizableRow(props: Props): React$Node {
  const { topResize, bottomResize } = getUserSettings(props.optionName) || {}

  const {
    className,
    defaultTopSize,
    defaultBottomSize,
    top,
    bottom,
    styleTop = {},
    styleBottom = {},
    bottomMin,
    optionName,
    topMin,
    ...rest
  } = props

  let containerSize = 0
  let topHeight = 0
  let origMouseY = 0
  let mouseVector = 0
  const topEl: React$ElementRef<any> = React.useRef()
  const bottomEl: React$ElementRef<any> = React.useRef()
  const containerEl: React$ElementRef<any> = React.useRef()

  function resize(e) {
    const memoizeMouseVector = mouseVector
    mouseVector = e.pageY - origMouseY
    const topPercentage = ((topHeight + mouseVector) / containerSize) * 100

    if (bottomMin || topMin) {
      const $topDOMEl = document.querySelector('.resizableTopContent')
      const $bottomDOMEl = document.querySelector('.resizableBottomContent')

      if ($topDOMEl && $bottomDOMEl) {
        if (origMouseY > e.pageY && topMin && $topDOMEl.clientHeight <= topMin) {
          mouseVector = memoizeMouseVector
          return
        }
        if (origMouseY < e.pageY && bottomMin && $bottomDOMEl.clientHeight <= bottomMin) {
          mouseVector = memoizeMouseVector
          return
        }
      }
    }
    if (topEl.current && bottomEl.current) {
      topEl.current.style.height = `${topPercentage}%`
      bottomEl.current.style.height = `${100 - topPercentage}%`
    }
  }

  function stopResize(e) {
    const topPercentage = ((topHeight + mouseVector) / containerSize) * 100
    const $topDOMEl = document.querySelector('.resizableTopContent')
    const $bottomDOMEl = document.querySelector('.resizableBottomContent')

    if ($topDOMEl && $bottomDOMEl) {
      $topDOMEl.style.height = `${topPercentage}%`
      $bottomDOMEl.style.height = `${100 - topPercentage}%`
    }

    if (optionName) setUserSettings(optionName, { topResize: topPercentage, bottomResize: 100 - topPercentage })

    window.removeEventListener('mousemove', resize)
  }

  function onMouseDown(e: SyntheticMouseEvent<HTMLElement>) {
    e.preventDefault()
    containerSize = containerEl.current.clientHeight
    topHeight = topEl.current.clientHeight
    origMouseY = e.pageY
    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResize)
  }

  return (
    <div {...rest} ref={containerEl} className={`${className || ''} flex column noWrap flex1`}>
      <div
        ref={topEl}
        style={{ height: `${topResize || defaultTopSize || 50}%`, overflowY: 'auto', ...styleTop }}
        className="flex column resizableTopContent"
      >
        <div className="fullWidth fullHeight" style={{ minWidth: 20, paddingTop: 10 }}>
          {top}
        </div>
      </div>
      <div className={classes.draggablePart} onMouseDown={onMouseDown}>
        <div className={classes.verticalResizeBar} />
        <div className={classes.buttonResizeBar}>
          <FontIcon className="padding0" icon="fas-grip-lines" />
        </div>
      </div>
      <div
        ref={bottomEl}
        style={{
          height: `${bottomResize || defaultBottomSize || 50}%`,
          minWidth: 20,
          overflowY: 'auto',
          ...styleBottom,
        }}
        className="paddingTop10 resizableBottomContent"
      >
        {bottom}
      </div>
    </div>
  )
}
