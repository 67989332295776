import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { MenuContext } from 'app/main/Authenticated/Authenticated.tsx'
import classes from './Nav.module.scss'

function RightContent({ children, className, ...props }) {
  const { isOpen } = useContext(MenuContext)
  return (
    <div
      className={cx([classes.rightContent, className])}
      style={{ maxWidth: isOpen ? 'calc(100% - 300px)' : 'calc(100% - 40px)' }}
      {...props}
    >
      {children}
    </div>
  )
}

RightContent.propTypes = {
  className: PropTypes.string,
}

export default RightContent
