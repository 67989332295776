// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ovm-fullscreen-editor > .ck-toolbar {
    box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);
  }
  .ovm-fullscreen-editor .ck-content {
    padding: 2cm 4cm 4cm !important;
    border: 1px hsl(0, 0%, 82.7%) solid;
    background: white !important;
    box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1) !important;
    margin: 0 auto !important;
  }
  .ck-diff-value .ck-content {
    padding: 10px !important;
  }
`, "",{"version":3,"sources":["webpack://./app/containers/Wiki/WikiEditor/EditorFullScreen.module.scss"],"names":[],"mappings":"AACE;IACE,wCAAwC;EAC1C;EACA;IACE,+BAA+B;IAC/B,mCAAmC;IACnC,4BAA4B;IAC5B,mDAAmD;IACnD,yBAAyB;EAC3B;EACA;IACE,wBAAwB;EAC1B","sourcesContent":[":global {\n  .ovm-fullscreen-editor > .ck-toolbar {\n    box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);\n  }\n  .ovm-fullscreen-editor .ck-content {\n    padding: 2cm 4cm 4cm !important;\n    border: 1px hsl(0, 0%, 82.7%) solid;\n    background: white !important;\n    box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1) !important;\n    margin: 0 auto !important;\n  }\n  .ck-diff-value .ck-content {\n    padding: 10px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
