/** @flow */
import React, { useCallback } from 'react'
import type { Pipe, History, Match, Location, Asset } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import resources from 'app/store/resources'
import { Wiki as WikiComponent } from 'app/containers/Wiki/Wiki.jsx'

type Props = {|
  history: History,
  match: Match,
  location: Location,
  asset: Asset,
  localRouter: Object,
|}

export function WikiView(props: Props): React$Node {
  const { history, match, location, asset, localRouter } = props

  const formatRoute = useCallback((projectId, assetId, artcileVersionId, search) => {
    return localRouter.getAbsoluteLink('wiki', { articleId: artcileVersionId })
  }, [])

  return (
    <div className="fullWidth">
      <WikiComponent
        formatRoute={formatRoute}
        rootAssetId={asset.id}
        history={history}
        match={match}
        location={location}
        hideAssetTree={true}
        hideBreadcrumb={true}
      />
    </div>
  )
}

const pipeInst: Pipe<{ asset: Asset, params: Object, localRouter: Object }, typeof WikiView> = pipe()

const Wiki: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => {
    return {
      asset: props.asset,
      history: props.history,
      match: { ...props.match, params: props.params },
      location: props.location,
    }
  })
  .request(({ match }) =>
    match.params.articleId ? resources.articles.fetchOne(match.params.articleId) : Promise.resolve(),
  )
  .render(({ params, ...props }) => <WikiView {...props} />)

export default Wiki
