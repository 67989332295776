// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellMedias-___CellMedias-module__container {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  position: relative;
  z-index: 1;
}

.app-components-Table-Cells-CellMedias-___CellMedias-module__mediaReadOnly {
  margin: 1px;
  padding: 0 10px;
  border-radius: 30px;
  background-color: var(--colors-grey);
  pointer-events: none;
  max-width: 100%;
  width: -moz-fit-content;
  width: fit-content;
}

.app-components-Table-Cells-CellMedias-___CellMedias-module__labelContainer {
  max-width: 100%;
  color: #fff;
  font-weight: bold;
}

.app-components-Table-Cells-CellMedias-___CellMedias-module__mediaLabel {
  text-overflow: ellipsis;
  width: calc(100% - 10px);
  white-space: nowrap;
  overflow: hidden;
}

.app-components-Table-Cells-CellMedias-___CellMedias-module__mediaTooltips .app-components-Table-Cells-CellMedias-___CellMedias-module__mediaReadOnly {
    background-color: #ffffff !important;
  }

.app-components-Table-Cells-CellMedias-___CellMedias-module__mediaTooltips .app-components-Table-Cells-CellMedias-___CellMedias-module__mediaReadOnly .app-components-Table-Cells-CellMedias-___CellMedias-module__labelContainer {
      color: var(--colors-black) !important;
    }
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellMedias/CellMedias.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,oCAAoC;EACpC,oBAAoB;EACpB,eAAe;EACf,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,mBAAmB;EACnB,gBAAgB;AAClB;;AAGE;IACE,oCAAoC;EAItC;;AAHE;MACE,qCAAqC;IACvC","sourcesContent":[".container {\n  display: flex;\n  overflow-x: hidden;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  padding-right: 5px;\n  position: relative;\n  z-index: 1;\n}\n\n.mediaReadOnly {\n  margin: 1px;\n  padding: 0 10px;\n  border-radius: 30px;\n  background-color: var(--colors-grey);\n  pointer-events: none;\n  max-width: 100%;\n  width: fit-content;\n}\n\n.labelContainer {\n  max-width: 100%;\n  color: #fff;\n  font-weight: bold;\n}\n\n.mediaLabel {\n  text-overflow: ellipsis;\n  width: calc(100% - 10px);\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.mediaTooltips {\n  .mediaReadOnly {\n    background-color: #ffffff !important;\n    .labelContainer {\n      color: var(--colors-black) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellMedias-___CellMedias-module__container`,
	"mediaReadOnly": `app-components-Table-Cells-CellMedias-___CellMedias-module__mediaReadOnly`,
	"labelContainer": `app-components-Table-Cells-CellMedias-___CellMedias-module__labelContainer`,
	"mediaLabel": `app-components-Table-Cells-CellMedias-___CellMedias-module__mediaLabel`,
	"mediaTooltips": `app-components-Table-Cells-CellMedias-___CellMedias-module__mediaTooltips`
};
export default ___CSS_LOADER_EXPORT___;
