// @flow
import React, { useEffect, useRef, type Element } from 'react'
import { useImmer } from 'use-immer'
import { map, forEach } from 'lodash'
import PubSub from 'pubsub-js'
import { getUserSettings, setUserSettings } from 'app/libs/helpers/userSettings'
import type { ElementProps } from 'app/core/types'
import { Drawer } from '../Drawer/Drawer.jsx'
import classes from './OverPage.module.scss'
import FontIcon from '../FontIcon/FontIcon.jsx'

type Props = {|
  ...ElementProps,
|}

export const OverPage = (props: Props): React$Node => {
  const sub = useRef()
  const [content, setContent] = useImmer({})
  const [onRequestClose, setOnRequestClose] = useImmer({})
  const [options, setOptions] = useImmer({})
  const [open, setOpen] = useImmer({})
  const [zIndex, setZIndex] = useImmer({})
  const firstPanel = useRef([])

  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true

    sub.current = PubSub.subscribe(
      'OVERPAGE',
      (msg, { id, content: toSetContent, onCloseFunc, options: toSetOptions = {} } = {}) => {
        if (!isMounted.current) return

        if (toSetContent) {
          setOpen((opens) => {
            opens[id] = true
          })
          setContent((oldContent) => {
            oldContent[id] = toSetContent
          })
          setOnRequestClose((oldCloseFunc) => {
            oldCloseFunc[id] = onCloseFunc
          })
          setOptions((oldOptions) => {
            oldOptions[id] = toSetOptions
          })
          setZIndex((zIndexes) => {
            forEach(zIndexes, (a, i) => {
              zIndexes[i] = 5
            })
            zIndexes[id] = 10
          })
          const panel = firstPanel.current || []
          panel.unshift(id)
          firstPanel.current = panel
        } else {
          if (isMounted.current === true) {
            setOpen((opens) => {
              opens[id] = false
            })
            setContent((oldContent) => {
              delete oldContent[id]
            })
            setZIndex((zIndexes) => {
              delete zIndexes[id]
            })
          }
          if (onRequestClose[id]) onRequestClose[id]()
        }
      },
    )

    return () => {
      PubSub.unsubscribe(sub.current)
      isMounted.current = false
    }
  }, [])

  const minSize = 680
  const maxSize = document.body ? document.body.clientWidth - 50 : 1500
  let origSize = 0
  let origX = 0
  let origMouseX = 0
  const elements = useRef({})

  function resize(e) {
    const width = origSize - (e.pageX - origMouseX)

    if (width > minSize && width < maxSize && firstPanel.current[0] && elements.current[firstPanel.current[0]]) {
      elements.current[firstPanel.current[0]].style.width = `${width}px`
      elements.current[firstPanel.current[0]].style.left = `${origX + (e.pageX - origMouseX)}px`
    }
  }

  function stopResize(e) {
    if (firstPanel.current[0] && elements.current[firstPanel.current[0]]) {
      setUserSettings(`rightPanel-${firstPanel.current[0]}`, {
        resizedWidth: elements.current[firstPanel.current[0]].clientWidth,
      })
    }
    window.removeEventListener('mousemove', resize)
    window.removeEventListener('mouseup', stopResize)
  }

  function onMouseDown(index: number) {
    return (e) => {
      e.preventDefault()
      origSize = parseFloat(getComputedStyle(elements.current[index], '').getPropertyValue('width').replace('px', ''))
      origX = elements.current[index].getBoundingClientRect().left
      origMouseX = e.pageX

      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResize)
    }
  }

  const { defaultWidth, assetDetail, ...optRest } = options

  return map<Object, ?React$Element<any>>(content, (panel, index) => {
    if (!panel) return null

    const { resizedWidth } = getUserSettings(`rightPanel-${index}`) || {}

    return (
      <Drawer
        key={index}
        open={open[index]}
        style={{ position: 'aboslute', zIndex: zIndex[index] }}
        onClose={() => {
          if (isMounted.current === true) {
            setOpen((opens) => {
              opens[index] = false
            })
          }

          firstPanel.current = firstPanel.current.filter((panel) => panel !== index)

          setTimeout(() => {
            if (onRequestClose[index]) onRequestClose[index]()
          }, 300)
        }}
        timeout={300}
      >
        <div
          ref={(ref) => {
            elements.current[index] = ref
          }}
          className="flex row noWrap fullHeight"
          style={{ width: resizedWidth || defaultWidth || '70vw', borderTop: '1px solid lightgrey', maxWidth: '98vw' }}
        >
          <div className={classes.draggablePart} onMouseDown={onMouseDown(index)}>
            <div className={classes.buttonResizeBar}>
              <FontIcon className="padding0" icon="fas-grip-lines-vertical" />
            </div>
          </div>
          <div className={classes.contentContainer} {...optRest}>
            {panel}
          </div>
        </div>
      </Drawer>
    )
  })
}

export const showPanel = (id: string, content: Element<any> | null, onRequestClose?: Function, options?: Object) => {
  PubSub.publish('OVERPAGE', { id, content, onCloseFunc: onRequestClose, options })
}
