// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-OrganizationChart-___OrganizationChartDetail-module__container {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

  .app-pages-OrganizationChart-___OrganizationChartDetail-module__container li {
    list-style: none;
  }

.app-pages-OrganizationChart-___OrganizationChartDetail-module__overflowContainer {
  display: flex;
  justify-content: space-between;
  width: 67%;
}

.app-pages-OrganizationChart-___OrganizationChartDetail-module__buttonContainer {
  display: flex;
  justify-content: center;
}

.app-pages-OrganizationChart-___OrganizationChartDetail-module__buttonContainer button {
    margin: 10px 0;
  }

.app-pages-OrganizationChart-___OrganizationChartDetail-module__title {
  display: flex;
  justify-content: center;
  height: 15%;
  background-color: #3dc3d2;
  color: white;
  padding-top: 20px;
}

.app-pages-OrganizationChart-___OrganizationChartDetail-module__titleOverflowContainer {
  display: flex;
  flex-direction: column;
  width: 67%;
}

.app-pages-OrganizationChart-___OrganizationChartDetail-module__mainContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 50px 0;
  flex: 1;
  overflow-y: auto;
  min-height: auto;
}
`, "",{"version":3,"sources":["webpack://./app/pages/OrganizationChart/OrganizationChartDetail.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,sBAAsB;AAKxB;;EAHE;IACE,gBAAgB;EAClB;;AAGF;EACE,aAAa;EACb,8BAA8B;EAC9B,UAAU;AACZ;;AAEA;EAEE,aAAa;EACb,uBAAuB;AAIzB;;AAHE;IACE,cAAc;EAChB;;AAGF;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EAEtB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,OAAO;EACP,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 100%;\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n\n  li {\n    list-style: none;\n  }\n}\n\n.overflowContainer {\n  display: flex;\n  justify-content: space-between;\n  width: 67%;\n}\n\n.buttonContainer {\n  // padding: 0 300px;\n  display: flex;\n  justify-content: center;\n  button {\n    margin: 10px 0;\n  }\n}\n\n.title {\n  display: flex;\n  justify-content: center;\n  height: 15%;\n  background-color: #3dc3d2;\n  color: white;\n  padding-top: 20px;\n}\n\n.titleOverflowContainer {\n  display: flex;\n  flex-direction: column;\n  // justify-content: space-between;\n  width: 67%;\n}\n\n.mainContainer {\n  display: flex;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n  padding: 50px 0;\n  flex: 1;\n  overflow-y: auto;\n  min-height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-OrganizationChart-___OrganizationChartDetail-module__container`,
	"overflowContainer": `app-pages-OrganizationChart-___OrganizationChartDetail-module__overflowContainer`,
	"buttonContainer": `app-pages-OrganizationChart-___OrganizationChartDetail-module__buttonContainer`,
	"title": `app-pages-OrganizationChart-___OrganizationChartDetail-module__title`,
	"titleOverflowContainer": `app-pages-OrganizationChart-___OrganizationChartDetail-module__titleOverflowContainer`,
	"mainContainer": `app-pages-OrganizationChart-___OrganizationChartDetail-module__mainContainer`
};
export default ___CSS_LOADER_EXPORT___;
