// @flow

import React from 'react'
import { TableTasks } from 'app/pages/Project/Tasks/TableTasks.tsx'
import getProjectIdFromURL from '../../../core/utils/getProjectIdFromURL'

type Props = {||}

export function Tasks(props: Props): React$Node {
  return (
    <div className="fullWidth fullHeight">
      <TableTasks
        tableId="PlannerTaskTable"
        enableStats={true}
        projectId={getProjectIdFromURL()}
        resources={{
          resourceType: 'tasks',
          requestName: 'fetchAll',
          includedResources: [
            'assetInst',
            'assetInst.thumbnailInst',
            'stepInst',
            'assignedUserInst',
            'suggestedUserInst',
            'statusInst',
            'activitiesInst',
            'takesInst.statusInst',
            'takesInst.takeFlagsInst.flagInst',
            'takesInst.takeRefMediasInst.mediaInst',
            'takesInst.takeValidationMediasInst.mediaInst',
          ],
        }}
      />
    </div>
  )
}
