import { getServerConfig } from 'app/core/utils/getServerConfig'
import { last } from 'lodash'
import history from 'app/main/routerHistory'
import React from 'react'

export const getActionObjectTypeId = (actionObjectTypeString) => {
  let contentTypeId = null
  Object.entries(getServerConfig().CONTENT_TYPES).forEach(([id, value]) => {
    if (value === actionObjectTypeString) {
      contentTypeId = id
    }
  })
  return contentTypeId ? parseInt(contentTypeId, 10) : contentTypeId
}

export const getActionObjectTypeIdentifier = (actionObjectTypeId, verb) => {
  let contentTypeString = null
  Object.entries(getServerConfig().CONTENT_TYPES).forEach(([id, value]) => {
    if (parseInt(id, 10) === actionObjectTypeId) {
      contentTypeString = value
    }
  })
  return contentTypeString || verb
}

const getRedirectionAsset = (asset) => {
  if (asset.project) {
    return `/projects/${asset.project}/assets?assetDetails=/${asset.id}`
  }
  return `/assets?assetDetails=/${asset.id}`
}

export const functionsByContentType = {
  task: {
    labelNotif: (notification) => {
      const { actionInst } = notification
      return (
        <span>
          <b>{actionInst.actor.name}</b>
          {` `}
          {actionInst.verb}
          {` task `}
          <b>{actionInst.actionObject.name}</b>
        </span>
      )
    },
    redirectNotif: (notification, getPath) => {
      const { actionInst } = notification
      const { asset } = actionInst.actionObject
      if (!getPath) return history.push(`/assets?asset=/${asset}/tasks/`)
      return `/assets?asset=/${asset}/tasks/`
    },
    labelFeed: (target) => {
      return (
        <span>
          task <b>{target.name}</b>
        </span>
      )
    },
    getFeedName: (target) => {
      return target.name
    },
  },
  article: {
    labelNotif: (notification) => {
      const { actionInst } = notification
      switch (actionInst.verb) {
        case 'created':
          return (
            <span>
              <b>{actionInst.actor.name}</b>
              {` `}
              {actionInst.verb}
              {` article `}
              <b>{actionInst.actionObject.title}</b>
              {` in `}
              <b>{actionInst.target.name}</b>
            </span>
          )
        default:
          return (
            <span>
              <b>{actionInst.actor.name}</b>
              {` `}
              {actionInst.verb}
              {` article `}
              <b>{actionInst.actionObject.title}</b>
            </span>
          )
      }
    },
    redirectNotif: (notification, getPath) => {
      const { actionInst } = notification
      const { actionObject } = actionInst
      if (getPath) return `/assets-wiki/${actionObject.asset}/${actionObject.id}/`
      return history.push(`/assets-wiki/${actionObject.asset}/${actionObject.id}/`)
    },
    labelFeed: (target) => {
      return (
        <span>
          article <b>{target.title}</b>
        </span>
      )
    },
    getFeedName: (target) => {
      return target.title
    },
  },
  post: {
    labelNotif: (notification) => {
      const { actionInst, context } = notification
      switch (actionInst.verb) {
        case 'posted_on':
          return (
            <span>
              <b>{actionInst.actor.name}</b>
              {` posted on `}
              <b>{actionInst.target.name}</b>
              {` in `}
              <b>{context.asset.name}</b>
            </span>
          )
        default:
          return (
            <span>
              <b>{actionInst.actor.name}</b>
              {` `}
              {actionInst.verb}
              {` `}
              <b>{actionInst.target.name}</b>
              {` in `}
              <b>{context.asset.name}</b>
            </span>
          )
      }
    },
    redirectNotif: (notification, getPath) => {
      const { context, actionInst } = notification
      const { asset } = context
      if (!getPath)
        return history.push(`${getRedirectionAsset(asset)}/discussions/${context.topic.id}/${actionInst.target.id}/`)
      return `${getRedirectionAsset(asset)}/discussions/${context.topic.id}/${actionInst.target.id}/`
    },
    labelFeed: (target) => {},
    getFeedName: (target) => {},
  },
  postboardlink: {
    labelNotif: (notification) => {
      const { actionInst } = notification
      return (
        <span>
          <b>{actionInst.actor.name}</b>
          {` `}
          {actionInst.verb}
          {` `}
          <b>{actionInst.actionObject.name}</b>
        </span>
      )
    },
    redirectNotif: (notification, getPath) => {},
    labelFeed: (target) => {},
    getFeedName: (target) => {},
  },
  thread: {
    labelNotif: (notification) => {
      const { actionInst } = notification
      return (
        <span>
          <b>{actionInst.actor.name}</b>
          {` `}
          {actionInst.verb}
          {` thread `}
          <b>{actionInst.actionObject.name}</b>
        </span>
      )
    },
    redirectNotif: (notification, getPath) => {},
    labelFeed: (target) => {
      return (
        <span>
          thread <b>{target.name}</b>
        </span>
      )
    },
    getFeedName: (target) => {
      return target.name
    },
  },
  asset: {
    labelNotif: (notification) => {
      const { actionInst } = notification
      switch (actionInst.verb) {
        case 'linked_to':
          return (
            <span>
              <b>{actionInst.actor.name}</b>
              {` linked `}
              <b>{actionInst.target.name}</b>
              {` to `}
              <b>{actionInst.actionObject.name}</b>
            </span>
          )
        default:
          return (
            <span>
              <b>{actionInst.actor.name}</b>
              {` `}
              {actionInst.verb}
              {` asset `}
              <b>{actionInst.actionObject.name}</b>
            </span>
          )
      }
    },
    redirectNotif: (notification, getPath) => {
      const { actionInst } = notification
      switch (actionInst.verb) {
        case 'linked_to':
          if (!getPath)
            return history.push(
              `/projects/${actionInst.actionObject.project}/breakdown/${actionInst.actionObject.parent}`,
            )
          return `/projects/${actionInst.actionObject.project}/breakdown/${actionInst.actionObject.parent}`
        default:
          if (!getPath) return history.push(`${getRedirectionAsset(actionInst.actionObject)}/informations`)
          return `${getRedirectionAsset(actionInst.actionObject)}/informations`
      }
    },
    labelFeed: (target) => {
      return (
        <span>
          asset <b>{target.name}</b>
        </span>
      )
    },
    getFeedName: (target) => {
      return target.name
    },
  },
  articlecontent: {
    labelNotif: (notification) => {
      const { actionInst } = notification
      return (
        <span>
          <b>{actionInst.actor.name}</b>
          {` `}
          {actionInst.verb}
          {` content in article `}
          <b>{actionInst.target.title}</b>
        </span>
      )
    },
    redirectNotif: (notification, getPath) => {
      const { actionInst } = notification
      const { target } = actionInst

      if (!getPath) return history.push(`/assets-wiki/${target.asset}/${target.id}/`)
      return `/assets-wiki/${target.asset}/${target.id}/`
    },
    labelFeed: (target) => {},
    getFeedName: (target) => {},
  },
  topic: {
    labelNotif: (notification) => {},
    redirectNotif: (notification, getPath) => {},
    labelFeed: (target) => {
      return (
        <span>
          topic <b>{target.name}</b>
        </span>
      )
    },
    getFeedName: (target) => {
      return target.name
    },
  },
  flag: {
    labelNotif: (notification) => {
      const { actionInst } = notification
      const { actionObject, verb, target, targetType } = actionInst

      const endLabel = (flag, value) => {
        switch (verb) {
          case 'added_to':
            return (
              <span>
                {` added flag `}
                <b>{flag}</b>
                {` to `}
                <b>{value}</b>
              </span>
            )
          case 'removed_from':
            return (
              <span>
                {` removed flag `}
                <b>{flag}</b>
                {` from `}
                <b>{value}</b>
              </span>
            )
          default:
            return (
              <span>
                {` `}
                {verb}
                {` flag `}
                <b>{flag}</b>
              </span>
            )
        }
      }

      switch (getActionObjectTypeIdentifier(targetType, verb)) {
        case 'asset':
          return (
            <span>
              <b>{actionInst.actor.name}</b>
              {endLabel(actionObject.name, target.name)}
            </span>
          )
        case 'article':
          return (
            <span>
              <b>{actionInst.actor.name}</b>
              {endLabel(actionObject.name, target.title)}
            </span>
          )
        default:
          return (
            <span>
              <b>{actionInst.actor.name}</b>
              {endLabel(actionObject.name)}
            </span>
          )
      }
    },
    redirectNotif: (notification, getPath) => {
      const { actionInst } = notification
      const { target, targetType, verb } = actionInst
      switch (getActionObjectTypeIdentifier(targetType, verb)) {
        case 'asset':
          if (!getPath) return history.push(`${getRedirectionAsset(target)}/informations`)
          return `${getRedirectionAsset(target)}/informations`
        case 'article':
          if (!getPath) return history.push(`/wiki/${target.id}`)
          break
        default:
          return `/wiki/${target.id}`
      }
      return `/wiki/${target.id}`
    },
    labelFeed: (target) => {},
    getFeedName: (target) => {},
  },
  take: {
    labelNotif: (notification) => {
      const { actionInst, context } = notification
      return (
        <span>
          <b>{actionInst.actor.name}</b>
          {` `}
          {actionInst.verb}
          {` take number `}
          <b>{actionInst.actionObject.number}</b>
          {` of task `}
          <b>{context.task.name}</b>
        </span>
      )
    },
    redirectNotif: (notification, getPath) => {
      const { context } = notification
      const { task } = context
      if (!getPath) return history.push(`/assets?asset=/${task.asset}/tasks/`)
      return `/assets?asset=/${task.asset}/tasks/`
    },
    labelFeed: (target) => {},
    getFeedName: (target) => {},
  },
  xlslExport: {
    labelNotif: (notification) => {
      const splitedFilename = notification.actionInst.datas.fileName.split('/')
      const filename = last(splitedFilename).split('.')[0]

      return (
        <span>
          The export of the table <b>{filename}</b> you have requested is ready to be downloaded.
        </span>
      )
    },
    redirectNotif: (notification, getPath) => {
      return ''
    },
    labelFeed: (target) => {},
    getFeedName: (target) => {},
  },
}

export const redirectAction = (notification, { getPath }) => {
  const { actionObjectType, verb } = notification.actionInst

  return functionsByContentType[getActionObjectTypeIdentifier(actionObjectType, verb)].redirectNotif(
    notification,
    getPath,
  )
}
