/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import classes from './Validators.module.scss'
import * as validatorsList from './validatorsList'

export default class Validators extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    errors: PropTypes.array,
    value: PropTypes.any,
    validators: PropTypes.object,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
    hide: PropTypes.bool,
    style: PropTypes.object,
  }

  static defaultProps = {}

  static validAll(validators) {
    const promises = []

    for (const validator of validators) {
      if (!validator.valid) {
        console.error('validator', validator)
        throw new Error(`validator has no valid() method. Maybe validator is not a valid instance ?`)
      }

      const promiseValid = validator.valid()

      if (!promiseValid.then) {
        throw new Error(`Validator.valid() error. valid() method must return a Promise.`)
      }

      promises.push(promiseValid)
    }

    return Promise.all(promises).then((allValids) => {
      for (const valid of allValids) {
        if (typeof valid !== 'boolean') {
          throw new Error(`Validator.valid() method must return a boolean or a Promise with boolean`)
        }

        if (valid === false) return false
      }

      return true
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      error: null,
    }

    this.initValidators(this.props.validators)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initValidators(nextProps.validators)
  }

  componentWillUnmount() {
    this.isUnmount = true
  }

  initValidators(validators) {
    this.validators = {}

    for (const key of Object.keys(validators)) {
      const validator = validators[key]

      if (!validator) continue

      /**
       * Validator already defined
       */
      if (typeof validator === 'boolean') {
        if (validatorsList[key]) {
          this.validators[key] = validatorsList[key]
        } else {
          console.error(`Validator ${key} is not defined`)
        }
      } else if (typeof validator === 'function') {
        /**
         * custom validator
         */
        this.validators[key] = validator
      }
    }
  }

  hasErrors(value) {
    /**
     * If field is not required and value null
     */
    if (!this.validators.required && validatorsList.required(value)) {
      return Promise.resolve(false)
    }

    for (const validatorKey of Object.keys(this.validators)) {
      const fn = this.validators[validatorKey]

      const error = fn(value, validatorsList)

      if (error) {
        return Promise.resolve(error)
      }
    }

    return Promise.resolve(false)
  }

  onError(error) {
    this.setState({ error })

    if (this.props.onError) {
      this.props.onError(error)
    }

    return false
  }

  valid() {
    const errorPromise = this.hasErrors(this.props.value)

    return errorPromise.then((error) => {
      if (error) {
        this.setState({ error })

        if (this.props.onError) {
          this.props.onError(error)
        }

        return false
      }

      if (!this.isUnmount) {
        this.setState({ error: null })

        if (this.props.onSuccess) {
          this.props.onSuccess()
        }
      }

      return true
    })
  }

  render() {
    const { error: stateError } = this.state
    const { errors: propsErrors, className, hide, style } = this.props

    if ((!stateError && !propsErrors) || hide) return null

    let errors = []

    if (stateError) {
      errors.push(stateError)
    } else if (propsErrors) {
      errors = [...errors, ...propsErrors]
    }

    const newClassName = cx(classes.container, { [className]: className })

    /**
     * currently, only first error is show
     */

    return (
      <div className={newClassName} style={style}>
        {errors[0]}
      </div>
    )
  }
}
