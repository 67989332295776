import React from 'react'
import PropTypes from 'prop-types'
import { isPlainObject } from 'lodash'
import cx from 'classnames'
import { Select } from 'app/components/Form'
import classes from './Pagination.module.scss'

export default class Pagination extends React.Component {
  static propTypes = {
    totalNumberOfItems: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    paginationSize: PropTypes.number.isRequired,
    className: PropTypes.string,

    /**
     * Page size options
     */
    allowChangePageSize: PropTypes.bool,
    onChangePageSize: PropTypes.func,
    pageSizeOptions: PropTypes.array,
  }

  static defaultProps = {
    pageSize: 50,
    paginationSize: 5,
    changePageSize: false,
    pageSizeOptions: [50, 100, 200, 500],
  }

  constructor(props) {
    super(props)

    let navStartAt = this.props.currentPage - Math.floor(this.props.paginationSize / 2)

    if (navStartAt < 1) {
      navStartAt = 1
    }

    this.state = {
      navStartAt,
    }
  }

  goToPage = (pageNum) => {
    this.props.goToPage(pageNum)
  }

  prevPagesList = () => {
    this.setState((state) => {
      const newSavStartAt =
        state.navStartAt > this.props.paginationSize ? state.navStartAt - this.props.paginationSize : 1
      return { ...state, navStartAt: newSavStartAt }
    })
  }

  nextPagesList = () => {
    let navEndAt = this.state.navStartAt + this.props.paginationSize * 2

    if (navEndAt > this.pages + 1) {
      navEndAt = this.pages + 1
    }

    const navStartAt = navEndAt - this.props.paginationSize

    this.setState({ navStartAt })
  }

  renderSelectPageSize() {
    if (!this.props.allowChangePageSize) return null

    const options = this.props.pageSizeOptions.map((item) => {
      let value = item
      let label = item

      if (isPlainObject(item)) {
        value = item.size
        ;({ label } = item)
      }

      return (
        <option key={value} value={value}>
          {label}
        </option>
      )
    })

    return (
      <Select
        onChange={(e) => this.props.onChangePageSize(parseInt(e.target.value, 10))}
        value={this.props.pageSize}
        style={{ width: 70, height: 30 }}
      >
        {options}
      </Select>
    )
  }

  render() {
    let pages = this.props.totalNumberOfItems / this.props.pageSize

    if (pages % 1 !== 0) {
      pages = Math.floor(pages) + 1
    }

    this.pages = pages

    let navEndAt = this.state.navStartAt + this.props.paginationSize

    if (navEndAt > pages) {
      navEndAt = pages + 1
    }

    const pagesBtn = []

    for (let i = this.state.navStartAt; i < navEndAt; i += 1) {
      pagesBtn.push(
        <li key={i} onClick={() => this.goToPage(i)} className={this.props.currentPage === i ? classes.active : null}>
          {i}
        </li>,
      )
    }

    if (this.state.navStartAt > 1) {
      pagesBtn.splice(
        0,
        0,
        <li key="prevPagesList" className="pagination" onClick={this.prevPagesList}>
          &lt;
        </li>,
      )
    }

    if (navEndAt <= this.pages) {
      pagesBtn.push(
        <li key="nextPagesList" onClick={this.nextPagesList}>
          &gt;
        </li>,
      )
    }

    const className = cx(classes.pagination, {
      [this.props.className]: !!this.props.className,
    })

    return (
      <div className={className}>
        <ul>{pagesBtn}</ul>

        {this.renderSelectPageSize()}
      </div>
    )
  }
}
