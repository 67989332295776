// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellStatus-___CellStatus-module__container {
  position: relative;
  height: 100%;
  width: 100%;
  position: relative;
}

.app-components-Table-Cells-CellStatus-___CellStatus-module__read {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: pre-wrap;
  text-align: center;
  border-radius: 3px;
  padding: 0px 10px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellStatus/CellStatus.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".container {\n  position: relative;\n  height: 100%;\n  width: 100%;\n  position: relative;\n}\n\n.read {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-sizing: border-box;\n  white-space: pre-wrap;\n  text-align: center;\n  border-radius: 3px;\n  padding: 0px 10px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellStatus-___CellStatus-module__container`,
	"read": `app-components-Table-Cells-CellStatus-___CellStatus-module__read`
};
export default ___CSS_LOADER_EXPORT___;
