/** @flow */
import React from 'react'
import { MUIButton, ButtonList } from 'app/components/Form'
import * as Sentry from '@sentry/browser'
import { ClipboardText } from 'app/components/Clipboard/ClipboardText.jsx'
import iconNoConnectivity from 'app/styles/images/connectivity-error.png'
import warning from 'app/styles/images/no-connection.png'
import settings from 'app/core/settings'

import pipe from 'app/core/utils/pipe'
import store from '../../store/index.ts'

type Props = {|
  children: React$Node,
  location: Location,
|}

type State = {|
  error: Error | null,
  sentryCode: string | null,
  info: Object,
  message: string | null,
  showStackTrace: boolean,
  icon: ?string,
|}

class _Error extends React.PureComponent<Props, State> {
  state = {
    error: null,
    info: null,
    sentryCode: null,
    message: null,
    showStackTrace: false,
    icon: null,
  }

  componentDidCatch(error: Error, info: Object) {
    let sentryCode
    if (settings.sentry.SENTRY_DSN) {
      sentryCode = Sentry.captureException(error, { contexts: { react: { componentStack: info.componentStack } } })
    }

    const userId = store.getState()?.user?.asset

    const messages = [
      {
        msg: 'Oops, the page seems to have a problem.\n If it persists, please report a feedback.',
        icon: iconNoConnectivity,
      },
      {
        msg: 'The connection with the server does not seem to work.\n Check your connection settings. If the problem persists, contact Overmind support.',
        icon: warning,
      },
    ]

    this.setState({
      error,
      message: userId ? messages[0].msg : messages[1].msg,
      icon: userId ? messages[0].icon : messages[1].icon,
      sentryCode: sentryCode
        ? `https://bugs-dev.teamto.fr/organizations/sentry/issues/?project=3&query=${sentryCode}`
        : null,
      info,
      showStackTrace: !!userId,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        error: null,
        message: null,
        icon: null,
        sentryCode: null,
        info: null,
        showStackTrace: false,
      })
    }
  }

  onReload = () => {
    window.location.reload()
  }

  render(): React$Node {
    const { error, sentryCode, info, message, showStackTrace, icon } = this.state
    const { children } = this.props

    if (error) {
      return (
        <div className="flex center alignCenter fullWidth fullHeight">
          <div style={{ margin: '0 30%', whiteSpace: 'pre-line' }} className="textCenter">
            {icon ? <img src={icon} style={{ margin: '20px auto 20px auto', height: '120px' }} /> : null}
            <div style={{ fontSize: 30 }}>{message}</div>
            {sentryCode ? (
              <div className="paddingTop30">
                <div>You can copy this url to Overmind support to identify the problem faster.</div>
                <ClipboardText text={sentryCode} value={sentryCode} style={{ marginTop: 20, width: '100%' }} />
              </div>
            ) : null}

            {showStackTrace ? (
              <>
                <div className="bold fontSize20 marginTop30">Stack trace</div>
                <pre>
                  <textarea style={{ width: '100%', height: 150 }} value={info?.componentStack} readOnly={true} />
                </pre>
                <ButtonList style={{ float: 'right' }}>
                  <MUIButton onClick={this.onReload} variant="text">
                    Reload page
                  </MUIButton>
                </ButtonList>
              </>
            ) : null}
          </div>
        </div>
      )
    }

    return children
  }
}

const Component: React$AbstractComponent<*, *> = pipe().withRouter().render(_Error)

export default Component
