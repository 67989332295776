// @flow

import React from 'react'
import { sortBy } from 'lodash'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import type { DefaultCellProps, Column, Cell, PrefsProps } from 'app/components/Table/types.js'
import type { Option, ProgressionStatus, ResourcesList } from 'app/core/types'
import { optionsProgressionStatus } from 'app/core/utils/optionsProgressionStatus'
import { MUISelect, type SelectProps } from 'app/components/Form/Select/MUISelect.jsx'

import { groupingFns } from './groupingFns.jsx'
import classes from './CellStatus.module.scss'

export type CellSelectParams = {|
  ...DefaultCellProps,
  componentProps?: $Shape<SelectProps>,
  isHashed?: (cell: Cell) => boolean,
  progressionStatus: ResourcesList<ProgressionStatus>,
|}

type ReadProps = {|
  value: $Exact<{ ...Option, backgroundColor: string }>,
  isHashed: boolean,
  isRowExpanded: boolean,
  prefs: PrefsProps,
|}

function Read({ value, isHashed, isRowExpanded, prefs }: ReadProps) {
  if (!value) return null
  const { minLineHeight, maxLineHeight } = prefs
  const { backgroundColor, label } = value

  const rowIsReduced = !isRowExpanded && minLineHeight < 50

  let fontSize

  if (value.label.length < 10) fontSize = 14
  else if (value.label.length < 15) fontSize = 13
  else if (value.label.length < 25) fontSize = 12
  else fontSize = 11

  return (
    <div
      className={classes.container}
      style={{
        padding: rowIsReduced ? 2 : 5,
      }}
    >
      <div
        style={{
          background: isHashed
            ? `repeating-linear-gradient(120deg, ${backgroundColor}, ${backgroundColor} 6px, ${backgroundColor}99 6px, ${backgroundColor}99 12px)`
            : backgroundColor,
          fontWeight: 'bold',
          color: getColorFromBackground(backgroundColor),
          maxHeight: !isRowExpanded ? minLineHeight : maxLineHeight,
          whiteSpace: rowIsReduced ? 'nowrap' : undefined,
          fontSize,
        }}
        className={classes.read}
      >
        <div style={isHashed ? { backgroundColor, padding: '0 5px', borderRadius: 4 } : undefined}>{label}</div>
      </div>
    </div>
  )
}

export const CellStatus = ({
  actions,
  componentProps = {},
  progressionStatus,
  isHashed,
  ...data
}: CellSelectParams): Column => ({
  cellType: 'CellStatus',
  Cell: (instance) => {
    const { cell, prefs } = instance
    const { getCellProps } = cell
    const options = optionsProgressionStatus(progressionStatus)
    const value = options.find(({ value }) => value === cell.value)
    const backgroundIsHashed = isHashed ? isHashed(cell) : false
    const { isRowExpanded, edition } = getCellProps()
    const { isBeingEdit, endEdit, save } = edition

    return (
      <>
        {value ? (
          <Read value={value} isHashed={backgroundIsHashed} isRowExpanded={isRowExpanded} prefs={prefs} />
        ) : null}
        {isBeingEdit ? (
          <div style={{ maxHeight: 0, overflow: 'hidden', position: 'absolute', top: 0, left: 0 }}>
            <MUISelect
              value={cell.value}
              options={options}
              onChange={({ value }) => save(value)}
              open={isBeingEdit}
              onClose={endEdit}
              hidden={true}
              style={{ width: '100%' }}
              {...componentProps}
            />
          </div>
        ) : null}
      </>
    )
  },
  actions: (instance, cell) => {
    const { edition } = cell.getCellProps()
    const { save } = edition
    const { disabledOptions } = componentProps || {}

    const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []

    let options = sortBy(progressionStatus, ['order'])
    if (disabledOptions) {
      options = options.filter((opt) => !disabledOptions.includes(opt.id))
    }

    return [
      ...resolvedActions,
      { separator: true },
      {
        label: 'Status',
        editAction: true,
        items: options.map((opt) => ({
          onClick: () => {
            save(opt.id)
            cell.focusCell()
          },
          editAction: true,
          label: opt.name,
          color: opt.color,
          hotKeys: [String(opt.order)],
          rightLabel: String(opt.order),
        })),
      },
    ]
  },
  groupingFns: groupingFns(progressionStatus),
  width: 150,
  ...data,
})
