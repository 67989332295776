/** @flow */
import React from 'react'
import moment from 'moment'
import type { DateDay, ID } from 'app/core/types'
import type { Dimensions } from '../../../types'

import classes from './SubRowsOverview.module.scss'

type Props = {|
  subRows: ?Array<Object>,
  startDate: moment,
  dimensions: Dimensions,
|}

export function SubRowsOverview(props: Props): React$Node {
  const { subRows, dimensions, startDate } = props
  const { dayWidth } = dimensions

  const overview = subRows?.reduce((acc, row) => {
    if (!acc) return { ...row }
    if (row.startDate < acc.startDate) acc.startDate = row.startDate
    if (row.endDate > acc.endDate) acc.endDate = row.endDate
    return acc
  }, null)

  if (!startDate) return null

  function drawRow(row: { startDate: DateDay, endDate: DateDay, __resource: string, id: ID }) {
    const { startDate: rowStartDate, endDate: rowEndDate, __resource, id } = row

    const startDiff = moment(rowStartDate).diff(startDate, 'day')
    const width = moment(rowEndDate).diff(moment(rowStartDate), 'day') + 1

    return (
      <div
        key={id}
        className={classes.row}
        sub-row-overview={startDiff * dayWidth}
        style={{
          left: startDiff * dayWidth,
          width: width * dayWidth,
        }}
        content-type={__resource}
      />
    )
  }

  if (!overview) return null

  return (
    <div className={classes.container}>
      <div className={classes.rowContainer}>{drawRow(overview)}</div>
    </div>
  )
}
