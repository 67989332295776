// @flow

import * as React from 'react'
import { filter, findIndex } from 'lodash'
import { openPictures, isPicture } from 'app/components/PictureVisualization/PictureVisualization.tsx'
import * as MediaTypes from 'app/containers/Medias/utils'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type { Media } from 'app/core/types'

import { ModalMedias, type ModalMediasProps } from './ModalMedias.jsx'
import type { Column, DefaultCellProps, PrefsProps } from '../../types'
import { groupingFns } from './groupingFns.jsx'
import classes from './CellMedias.module.scss'

export type CellMediasParams = {|
  ...DefaultCellProps,
  ...$Diff<
    ModalMediasProps,
    {
      medias: $ElementType<ModalMediasProps, 'medias'>,
      onCancel: $ElementType<ModalMediasProps, 'onCancel'>,
      onValidate: $ElementType<ModalMediasProps, 'onValidate'>,
      multipleSelection: $ElementType<ModalMediasProps, 'multipleSelection'>,
      onRequestClose: $ElementType<ModalMediasProps, 'onRequestClose'>,
      exponentTitle: $ElementType<ModalMediasProps, 'exponentTitle'>,
    },
  >,
|}

function Read({
  medias,
  isRowExpanded,
  prefs,
  allowPinMedia,
  allowValidateMedia,
}: {
  medias: Array<Media>,
  isRowExpanded: boolean,
  prefs: PrefsProps,
  allowPinMedia: boolean,
  allowValidateMedia: boolean,
}) {
  if (medias.length === 0) return null

  const { minLineHeight, maxLineHeight } = prefs

  const folder = filter(medias, (mediaFromMedias) => {
    const typeFromMedias = MediaTypes.findMediaType(mediaFromMedias)
    return typeFromMedias === MediaTypes.types.IMAGE
  })

  function displayMedia(media: Media) {
    const { id, name, url } = media

    if (isPicture(url)) {
      return (
        <Tooltip
          key={id}
          title={
            <div style={{ maxWidth: 120 }} className="flex column center fontSize10 textCenter white">
              <img src={url} style={{ maxWidth: 120, maxHeight: 120 }} />
              <span>{name}</span>
            </div>
          }
          placement="left"
        >
          <div>
            <div
              className={`${classes.mediaReadOnly} activeSelection`}
              onClick={(e) => {
                if (e.detail === 2) return
                openPictures(
                  folder,
                  allowPinMedia,
                  allowValidateMedia,
                  findIndex(folder, (file) => file.url === url),
                )
              }}
            >
              <div className={`flex row noWrap alignCenter ${classes.labelContainer}`}>
                <FontIcon icon="fas-image" className="marginRight5" />{' '}
                <span className={classes.mediaLabel}>{name}</span>
              </div>
            </div>
          </div>
        </Tooltip>
      )
    }
    return (
      <div className={`${classes.mediaReadOnly} activeSelection`} key={id}>
        <a href={url} rel="noreferrer nofollow" target="_blank" className="inheritWidth inheritHeight">
          <div className={`flex row noWrap alignCenter ${classes.labelContainer}`}>
            <FontIcon icon="fas-file" className="marginRight5" /> <span className={classes.mediaLabel}>{name}</span>
          </div>
        </a>
      </div>
    )
  }

  return (
    <div className={classes.container} style={{ maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2 }}>
      {medias.map((media, index) => {
        if (!isRowExpanded && index > 1) return null
        return displayMedia(media)
      })}
      {!isRowExpanded && medias.length > 2 ? (
        <Tooltip
          title={<div className={classes.mediaTooltips}>{medias.slice(2).map(displayMedia)}</div>}
          placement="left"
        >
          <span className="bold marginLeft5 grey">+{medias.length - 2}</span>
        </Tooltip>
      ) : null}
    </div>
  )
}

export const CellMedias = ({
  title,
  accept,
  maxSize,
  readOnly,
  allowPinMedia,
  allowValidateMedia,
  ...data
}: CellMediasParams): Column => ({
  cellType: 'CellMedias',
  Cell: (instance) => {
    const { cell, prefs } = instance
    const { getCellProps, value } = cell
    const { isRowExpanded } = getCellProps()

    return (
      <Read
        medias={value || []}
        allowPinMedia={allowPinMedia}
        allowValidateMedia={allowValidateMedia}
        isRowExpanded={isRowExpanded}
        prefs={prefs}
      />
    )
  },
  EditModal: (instance) => {
    const {
      cell,
      state: { selectedCells },
      cellPositionLabel,
    } = instance
    const { getCellProps, value } = cell
    const { edition } = getCellProps()
    const { endEdit, save } = edition

    const multipleSelection = selectedCells ? Object.keys(selectedCells).length > 1 : false

    return (
      <ModalMedias
        title={title}
        medias={value || []}
        readOnly={Boolean(readOnly)}
        onCancel={endEdit}
        onValidate={save}
        accept={accept}
        maxSize={maxSize}
        multipleSelection={multipleSelection}
        onRequestClose={endEdit}
        exponentTitle={cellPositionLabel}
        allowPinMedia={allowPinMedia}
        allowValidateMedia={allowValidateMedia}
      />
    )
  },
  readOnly,
  groupingFns,
  width: 100,
  ...data,
})
