/*eslint-disable */

import React from 'react'
import Icon from '../Icon.jsx'

export default class Planet_1 extends React.Component {
  static defaultProps = {
    width: 25,
    height: 18,
    fill: 'grey',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 25 18">
        <path
          d="M25,4.6c0-0.2-0.1-0.4-0.1-0.5l0-0.1l0-0.1l0,0l0,0l0-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.3-0.3-0.3
                    c-0.2-0.2-0.5-0.3-0.7-0.4c-0.5-0.2-0.9-0.3-1.3-0.3c-0.8-0.1-1.5-0.1-2.1-0.1c-0.6,0-1,0.1-1.3,0.1c0,0,0,0,0,0
                    C17.2,1,15,0,12.6,0c-1.1,0-2.2,0.2-3.3,0.6C7,1.5,5.2,3.1,4.2,5.3c-0.5,1-0.7,2.1-0.8,3.1c-0.2,0.2-0.6,0.4-1,0.8
                    C2,9.6,1.5,10,0.9,10.7c-0.3,0.3-0.5,0.7-0.7,1.1C0.1,12,0,12.3,0,12.6c0,0.1,0,0.3,0,0.5l0,0.2l0,0l0,0l0,0c0,0.1,0,0,0,0.1l0,0.1
                    c0.1,0.3,0.3,0.6,0.6,0.8c0.2,0.2,0.5,0.4,0.8,0.5c0.6,0.3,1.1,0.4,1.7,0.5c0.9,0.1,1.9,0.1,2.9,0.1c1.7,1.7,4,2.7,6.5,2.7
                    c1.1,0,2.2-0.2,3.3-0.6c2.3-0.9,4.1-2.5,5.1-4.7c0.5-1,0.7-2.1,0.8-3.2c0.8-0.6,1.5-1.3,2.1-2c0.4-0.5,0.7-0.9,1-1.5
                    c0.1-0.3,0.2-0.6,0.3-0.9l0-0.2L25,4.6z M3.4,13.8c-0.5,0-1-0.1-1.4-0.2c-0.4-0.1-0.7-0.3-0.9-0.6l0,0c0,0,0,0,0,0l0,0l0,0l0,0
                    L1,12.8c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0.1-0.5c0.1-0.3,0.3-0.7,0.5-1c0.4-0.6,0.9-1.1,1.3-1.4c0.2-0.2,0.4-0.4,0.6-0.5
                    c0,1,0.2,2,0.6,3c0.2,0.6,0.5,1.1,0.8,1.6C4.3,13.8,3.8,13.8,3.4,13.8z M19.9,12.2c-0.9,1.9-2.4,3.4-4.4,4.2
                    c-0.9,0.3-1.9,0.5-2.9,0.5c-1.8,0-3.6-0.6-5-1.7c1-0.1,2-0.3,3-0.5c0.6-0.1,1.3-0.3,1.9-0.5c0.6-0.2,1.3-0.4,1.8-0.6
                    c1.2-0.5,2.4-1,3.6-1.6c0.9-0.5,1.7-1,2.6-1.5C20.3,11,20.1,11.6,19.9,12.2z M20,8.4C19,9,18,9.5,16.9,10c-1.1,0.5-2.2,1-3.4,1.4
                    c-0.6,0.2-1.2,0.4-1.7,0.6c-0.6,0.2-1.2,0.4-1.7,0.6c-1.2,0.4-2.3,0.7-3.4,0.9c-0.2,0-0.3,0.1-0.5,0.1c-0.4-0.5-0.7-1.1-1-1.8
                    c-0.8-2-0.7-4.1,0.2-6c0.9-1.9,2.4-3.4,4.4-4.2c0.9-0.3,1.9-0.5,2.9-0.5c3.3,0,6.3,2,7.5,5.1c0.2,0.6,0.4,1.3,0.5,1.9
                    C20.3,8.2,20.2,8.3,20,8.4z M22.6,6.5c-0.3,0.3-0.7,0.6-1.1,0.9c-0.1-0.5-0.3-1.1-0.5-1.6c-0.4-1-0.9-1.9-1.6-2.6
                    c0.2,0,0.5,0,0.8,0c0.5,0,1.2,0,1.9,0.2c0.3,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.4,0.5,0.7C23.8,5,23.3,5.8,22.6,6.5z"
        />
        <path d="M9.8,4.5C9.5,4.4,9.2,4.3,8.9,4.3C7.8,4.3,6.8,5,6.3,6.1C5.7,7.5,6.2,9.1,7.4,9.7C7.7,9.8,8,9.9,8.3,9.9 c1.1,0,2.1-0.7,2.5-1.8c0.3-0.7,0.4-1.4,0.2-2.1C10.9,5.3,10.4,4.8,9.8,4.5z M9.9,7.7C9.6,8.3,9,8.8,8.3,8.8c-0.2,0-0.3,0-0.5-0.1 C7.2,8.4,7,7.4,7.4,6.5c0.3-0.7,0.9-1.1,1.5-1.1c0.2,0,0.3,0,0.5,0.1C9.7,5.7,9.9,5.9,10,6.3C10.1,6.8,10.1,7.2,9.9,7.7z" />
        <ellipse transform="matrix(0.9892 -0.1465 0.1465 0.9892 -0.2939 1.792)" cx="12" cy="2.9" rx="0.8" ry="0.7" />
        <path d="M16.2,4.4c-0.5,0.4-0.5,1.1,0,1.7c0.4,0.6,1.2,0.7,1.7,0.3c0.5-0.4,0.5-1.1,0-1.7C17.4,4.2,16.7,4.1,16.2,4.4z" />
      </Icon>
    )
  }
}

/* eslint-enable */
