// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Admin-Impersonification-___Impersonification-module__container {
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-pages-Admin-Impersonification-___Impersonification-module__top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.app-pages-Admin-Impersonification-___Impersonification-module__label {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./app/pages/Admin/Impersonification/Impersonification.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".container {\n  padding: 20px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.top {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.label {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-Admin-Impersonification-___Impersonification-module__container`,
	"top": `app-pages-Admin-Impersonification-___Impersonification-module__top`,
	"label": `app-pages-Admin-Impersonification-___Impersonification-module__label`
};
export default ___CSS_LOADER_EXPORT___;
