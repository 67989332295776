/** @flow */
import React from 'react'
import { map } from 'lodash'
import { viewableValue } from 'app/components/Form/FormData/getInput.tsx'
import type { DefaultCellProps, Column } from 'app/components/Table/types.js'
import type { AttrType, EditorType } from 'app/core/types'

import classes from './CellAggregatedAttribute.module.scss'

type CellAssetsListParams = {|
  ...DefaultCellProps,
  attrType: AttrType,
  editorType: EditorType,
  attrName: string,
|}

const CellAggregatedAttributeView = (props: {
  values: Object,
  attrName: string,
  attrType: AttrType,
  editorType: EditorType,
}) => {
  const { values, attrType, editorType, attrName } = props

  let formatLabel: ?(value: any) => string | React$Element<any>
  let formatValue = (val) => viewableValue(attrType, val, editorType) || 'null'

  if (['priority', 'text', 'duration', 'datetime'].includes(attrType)) {
    formatLabel = (val) => viewableValue(attrType, val, editorType) || 'null'
  }

  if (['rating'].includes(editorType)) {
    formatValue = undefined
    formatLabel = undefined
  }

  const displayListAttribute = (attribute: Object, attr: string) => {
    if (!attribute) return null
    if ([null, undefined].includes(attribute[`attributes__${attrName}`])) return null

    const attributeValue = String(attribute[`attributes__${attrName}`])

    return (
      <div key={`${attr}_${attributeValue}`} className="flex row wrap alignCenter">
        <span className="marginRight5 bold">
          {formatLabel ? formatLabel(attributeValue) : attributeValue || 'null'}
        </span>
        : {attribute.count}
      </div>
    )
  }

  const displayArrayAttribute = (arr: Object, attr: string) => {
    return (
      <div key={attr} className="grid col2 noGridGap">
        {arr.map((attribute, index: number) => {
          if (!attribute) return null
          if ([null, undefined].includes(attribute[`attributes__${attrName}`])) return null

          const attributeValue = String(attribute[`attributes__${attrName}`])

          if (['rating'].includes(editorType)) {
            formatLabel = (val) => viewableValue(attrType, val, editorType) || 'null'
          }

          return [
            <span className="marginRight5 bold" key={`${attr}_label_${String(index)}`}>
              {formatLabel ? formatLabel(attributeValue) : attributeValue || 'null'}
            </span>,
            <span key={`${attr}_count_${String(index)}`}>{attribute.count}</span>,
          ]
        })}
      </div>
    )
  }

  return map(values, (value: any, attr: string) => {
    if (Array.isArray(value)) return displayArrayAttribute(value, attr)
    if (typeof value === 'object') return displayListAttribute(value, attr)
    if (attr === 'parent_id') return null

    if (!value && value !== 0 && value !== false) {
      return (
        <span key={attr} style={{ color: 'lightgrey' }}>
          null
        </span>
      )
    }

    return (
      <div key={attr} className="flex row wrap alignCenter">
        <span className="marginRight5 bold">{attr}: </span>
        {formatValue ? formatValue(value) : value}
      </div>
    )
  })
}

export const CellAggregatedAttribute = ({ attrType, editorType, attrName, ...data }: CellAssetsListParams): Column => {
  return {
    cellType: 'CellAggregatedAttribute',
    Cell: (instance) => {
      const { cell, prefs } = instance
      const { value, getCellProps } = cell
      const { isRowExpanded } = getCellProps()
      const { minLineHeight, maxLineHeight } = prefs

      return (
        <div
          className={classes.container}
          style={{ maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2, overflowY: 'auto' }}
        >
          <CellAggregatedAttributeView values={value} attrName={attrName} attrType={attrType} editorType={editorType} />
        </div>
      )
    },
    canGroupBy: false,
    width: 180,
    ...data,
  }
}
