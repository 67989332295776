/** @flow */
import React from 'react'
import Pagination from 'app/components/Pagination/Pagination.jsx'
import Loader from 'app/components/Loader/Loader.jsx'
import classes from './PaginatedComponent.module.scss'

type Props = {|
  defaultCurrentPage: number,
  defaultPageSize: number,
  pageSizeOptions: Array<{ size: number, label: string } | number>,
  style?: Object,
  count?: number,
  renderChildren?: Function,
  isLoading: boolean,
  onRequestNeeded?: Function,
|}

type State = {|
  currentPage: number,
  pageSize: number,
|}

export default class PaginatedComponent extends React.Component<Props, State> {
  static defaultProps: $Shape<Props> = {
    defaultCurrentPage: 1,
    defaultPageSize: 25,
    pageSizeOptions: [25, 50, 100, 200, 400],
    isLoading: false,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      currentPage: props.defaultCurrentPage,
      pageSize: props.defaultPageSize,
    }
  }

  componentDidMount() {
    const { pageSize, currentPage } = this.state
    const { onRequestNeeded } = this.props
    if (onRequestNeeded) onRequestNeeded(pageSize, currentPage)
  }

  onChangeCurrentPage: Function = (currentPage: number) => {
    const { onRequestNeeded } = this.props
    const { pageSize } = this.state
    if (onRequestNeeded) onRequestNeeded(pageSize, currentPage)
    this.setState({ currentPage })
  }

  onChangePageSize: Function = (pageSize: number) => {
    const { onRequestNeeded } = this.props
    if (onRequestNeeded) onRequestNeeded(pageSize, 1)
    this.setState({ pageSize, currentPage: 1 })
  }

  render(): React$Node {
    const { isLoading, renderChildren, count, pageSizeOptions } = this.props
    const { pageSize, currentPage } = this.state

    return (
      <div className={classes.container} style={this.props.style}>
        <div className={classes.contentContainer}>
          {isLoading && <Loader overlay={true} />}
          <div style={{ filter: isLoading && 'blur(3px)' }}>
            {renderChildren && renderChildren(pageSize, currentPage)}
          </div>
        </div>
        <div style={{ height: 40 }}>
          <Pagination
            className={classes.pagination}
            currentPage={currentPage}
            pageSize={pageSize}
            totalNumberOfItems={count || 0}
            goToPage={this.onChangeCurrentPage}
            allowChangePageSize={true}
            onChangePageSize={this.onChangePageSize}
            pageSizeOptions={pageSizeOptions}
          />
        </div>
      </div>
    )
  }
}
