// @flow
import { map } from 'lodash'
import type { ColumnFilter, Option } from 'app/core/types'
import { presetOperators as pOp, operators as op } from 'app/components/Table/TableView/Elements/Filters/operators.ts'

type Params = {|
  fileTypes: Array<Option>,
  status: Array<Option>,
|}

export class Filters {
  constructor({ fileTypes, status }: Params) {
    this.params = {
      ...this.params,
      fileTypes,
      status,
    }
  }

  params: Object = {}

  getFilters(): Array<ColumnFilter> {
    return [
      {
        label: 'Asset',
        type: 'column',
        operators: [
          ...map(pOp.text, (op) => ({
            ...op,
            attr: 'asset__name',
            inputType: 'text',
          })),
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'asset__uuid',
            multiple: true,
            inputType: 'asset',
            getSubLabel: (asset: Object) => {
              if (!asset) return undefined
              return asset.parent__name
            },
          })),
        ],
      },
      {
        label: 'Step',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'stepPath__step__uuid',
            multiple: true,
            inputType: 'step',
          })),
        ],
      },
      {
        label: 'Created date',
        type: 'column',
        operators: [
          ...map(pOp.date, (op) => ({
            ...op,
            attr: 'createdAt',
            inputType: 'datetime',
          })),
        ],
      },
      {
        label: 'Uploaded date',
        type: 'column',
        operators: [
          ...map(pOp.date, (op) => ({
            ...op,
            attr: 'fileUpdatedAt',
            inputType: 'datetime',
          })),
        ],
      },
      {
        label: 'Last update date',
        type: 'column',
        operators: [
          ...map(pOp.date, (op) => ({
            ...op,
            attr: 'updatedAt',
            inputType: 'datetime',
          })),
        ],
      },
      {
        label: 'File extension',
        type: 'column',
        operators: [
          ...map(pOp.text.concat([op.exact, op.notExact]), (op) => ({
            ...op,
            attr: 'stepPath__extension',
          })),
        ],
      },
      {
        label: 'File type',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'fileType',
            options: {
              label: 'File type',
              data: this.params.fileTypes,
            },
            multiple: true,
          })),
        ],
      },
      {
        label: 'Status',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'status',
            options: {
              label: 'Status',
              data: this.params.status,
            },
            multiple: true,
          })),
        ],
      },
      {
        label: 'Version',
        type: 'column',
        operators: [
          ...map(pOp.number, (op) => ({
            ...op,
            attr: 'version',
          })),
        ],
      },
      {
        label: 'Author',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'author__uuid',
            multiple: true,
            inputType: 'asset',
            assetTypes: ['us'],
          })),
        ],
      },
    ]
  }
}
