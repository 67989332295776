// @flow
import * as React from 'react'
import { map } from 'lodash'
import resources from 'app/store/resources'
import { optionsProgressionStatus, progressionStatusFilter } from 'app/core/utils/optionsProgressionStatus'
import type { ResourcesList, Take, Task, ProgressionStatus, ID, Pipe } from 'app/core/types'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData, FlagsRelations } from 'app/components/Form'
import pipe from 'app/core/utils/pipe'
import { getResources } from 'app/store/selectors'
import { getLastTakeFromTask } from 'app/pages/Project/ProductionManagement/FollowUp/utils'

type Props = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  take?: Take,
  lastTake: ?Take,
  task: Task,
  progressionStatus: ResourcesList<ProgressionStatus>,
  multipleCellsEdit?: boolean,
  onSave?: (any: any) => Promise<any>,
  onChange?: (res: any) => void,
  paginatedList?: string,
|}

export class ModalEditTakeView extends React.PureComponent<Props> {
  onSave: Function = async (changeData: Object, data: Object, defaultData: Object) => {
    const { task, take, onSave, lastTake, paginatedList, onChange } = this.props
    const { flags, closeStatus, ...dataToSave } = changeData

    if (onSave) return onSave(changeData)

    const takeToSave = {
      id: take && take.id,
      name: 'take',
      task: task.id,
      ...dataToSave,
    }

    return resources.takes[take ? 'update' : 'create'](takeToSave, { paginatedList }).then((res) => {
      const takeId = (take && take.id) || res.resources[0].id

      const flagsToSave = {
        id: takeId,
        takeFlagsInst: flags && map(flags, ({ flagInst }) => ({ take: takeId, flag: flagInst.id })),
      }

      return Promise.all([
        resources.takes.save(flagsToSave),
        lastTake && closeStatus ? resources.takes.update({ id: lastTake.id, status: closeStatus }) : null,
      ]).then((res) => {
        if (onChange) onChange(res)
        return res
      })
    })
  }

  render(): React$Node {
    const { progressionStatus, take, task, onSave, multipleCellsEdit, lastTake, onChange, ...rest } = this.props

    return (
      <ModalConfirmForm
        title={take ? `Edit take of task ${task.name}` : 'Create new take'}
        resizable={true}
        minHeight={550}
        minWidth={420}
        width={700}
        height={600}
        multipleCellsEdit={multipleCellsEdit}
        {...rest}
      >
        <FormData
          onSave={this.onSave}
          defaultData={
            !take
              ? {
                  flags: [],
                }
              : {
                  ...take,
                  flags: take.takeFlagsInst,
                }
          }
          properties={[
            ...(!take && lastTake
              ? [
                  {
                    label: 'Close status of previous take',
                    key: 'closeStatus',
                    useSelected: true,
                    type: 'select',
                    elementProps: {
                      fullWidth: true,
                      options: optionsProgressionStatus(
                        progressionStatusFilter(progressionStatus, 'with', ['close', 'retake']),
                      ),
                      isRequired: true,
                    },
                  },
                ]
              : []),
            {
              label: `Status ${!take && lastTake ? ' of new take' : ''}`,
              key: 'status',
              type: 'select',
              elementProps: {
                options: optionsProgressionStatus(
                  !take && lastTake
                    ? progressionStatusFilter(progressionStatus, 'without', ['close', 'retake'])
                    : progressionStatus,
                ),
                isRequired: true,
                fullWidth: true,
              },
            },
            {
              key: 'estimLength',
              label: 'Duration estim',
              type: 'duration',
              elementProps: (data: Object) => {
                const isACanceledStatus = (progressionStatus[data.status] || { statusType: 0 }).statusType === 3
                return {
                  disabled: isACanceledStatus,
                }
              },
            },
            {
              label: 'Brief',
              key: 'comment',
              type: 'richtext',
              elementProps: {
                onInit: (editor) => editor?.editing.view.focus(),
              },
            },
            {
              key: 'flags',
              label: 'Flags',
              type: 'autocomplete',
              element: FlagsRelations,
              elementProps: {
                foreignKey: 'take',
                resourceID: take && take.id,
                category: 'take',
                itemCategory: 'take',
                placeholder: 'Select a flag',
              },
            },
          ].filter((_) => _)}
        />
      </ModalConfirmForm>
    )
  }
}

const pipeInst: Pipe<{ taskId: ID }, typeof ModalEditTakeView> = pipe()

export const ModalEditTake: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { taskId } = props
    const lastTake = getLastTakeFromTask(getResources(state, 'tasks', taskId, ['takesInst']))
    return {
      task: getResources(state, 'tasks', taskId),
      progressionStatus: getResources(state, 'progressionStatus'),
      lastTake,
    }
  })
  .render(({ taskId, ...rest }) => <ModalEditTakeView {...rest} />)
