// @flow

import type { StoreResourceDef } from 'app/core/types'

export const mediaGroups: StoreResourceDef<> = {
  resourceType: 'mediaGroups',
  relations: {
    mediasInst: {
      type: 'hasMany',
      resourceType: 'medias',
      foreignKey: 'group',
    },
  },
}
