// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { find, fromPairs, map, debounce } from 'lodash'
import type { ColumnHeader } from 'app/components/Table/types'
import api from 'app/core/api/api.js'
import type { ID, GroupPermission } from 'app/core/types'
import { Table } from 'app/components/Table'
import { CellText, CellCheckbox } from 'app/components/Table/Cells'
import permissionsKeys from 'app/core/constants/permissionsKeys'
import { Input } from 'app/components/Form'
import { useSelector } from 'react-redux'
import { getResources } from '../../store/selectors'

const tableId = 'table-groupPermissions'

const defaultPerms: Object = fromPairs(map(permissionsKeys, ({ key }) => [key, false]))

type Props = {|
  groupId: ID,
|}

export function AdminPermissionsTable(props: Props): React$Node {
  const { groupId } = props
  const [endpoints, setEndpoints] = useState([])
  const groupPermissions = useSelector((state) => getResources(state, 'groupPermissions', { group: groupId }))
  const [search, _setSearch] = useState<string>('')

  const setSearch = debounce(_setSearch, 500)

  useEffect(() => {
    api.endPoints().then((endpoints) => {
      setEndpoints(endpoints)
    })
  }, [])

  const resourcesParams = useMemo(
    () => ({
      resourceType: 'groupPermissions',
      requestName: 'fetchPermissionsByGroup',
      requestData: groupId,
    }),
    [groupId],
  )

  function hasPerm(groupPermission: GroupPermission) {
    if (
      groupPermission.createPermission === true ||
      groupPermission.deleteOwnPermission === true ||
      groupPermission.deletePermission === true ||
      groupPermission.readOwnPermission === true ||
      groupPermission.readPermission === true ||
      groupPermission.updateOwnPermission === true ||
      groupPermission.updatePermission === true
    )
      return true
    return false
  }

  const formatData = useCallback(() => {
    return endpoints
      .map((endpoint) => {
        const permission = find(groupPermissions, (groupPermission) => groupPermission.endpoint === endpoint)

        if (permission) {
          return {
            ...permission,
            name: permission.endpoint,
          }
        }

        return { name: endpoint, ...defaultPerms }
      })
      .filter((e) => e.name.includes(search))
      .sort((a, b) => {
        const aHasPerm = hasPerm(a)
        const bHasPerm = hasPerm(b)
        if (aHasPerm && !bHasPerm) return -1
        if (aHasPerm && bHasPerm) return 0
        return 1
      })
  }, [search, endpoints, groupPermissions])

  const columns: Array<ColumnHeader> = useMemo(
    () => [
      {
        Header: 'Permissions',
        id: 'epGroup',
        columns: [
          CellText({
            id: 'endPoint',
            Header: 'End point',
            accessor: 'name',
            readOnly: true,
            containerStyle: {
              style: { alignSelf: 'center', paddingLeft: 10 },
            },
            width: 250,
          }),
          ...permissionsKeys
            .sort((a, b) => {
              const permsValues = {
                createPermission: 0,
                readPermission: 1,
                updatePermission: 2,
                deletePermission: 3,
                readOwnPermission: 4,
                updateOwnPermission: 5,
                deleteOwnPermission: 6,
              }
              return permsValues[a.key] - permsValues[b.key]
            })
            .map((perm) =>
              CellCheckbox({
                id: perm.key,
                Header: perm.label,
                unselectable: true,
                accessor: perm.key,
                width: 75,
                alwaysBeingEdit: true,
                readOnly: false,
                actions: () => ['edit'],
                save: {
                  resource: 'groupPermissions',
                  formatData: (item, value, cell, instance, meta) => {
                    if (!hasPerm({ ...item, [cell.column.id]: value })) return item.id
                    return {
                      group: groupId,
                      endpoint: item.name,
                      id: item.id,
                      [cell.column.id]: value,
                    }
                  },
                },
              }),
            ),
        ],
      },
    ],
    [groupId],
  )

  return (
    <div className="fullWidth fullHeight">
      <div style={{ height: '42px', width: '100%' }}>
        <div style={{ width: 200 }}>
          <Input
            dataCy="AdminPermission-input"
            onChange={(e, value) => setSearch(value ? String(value) : '')}
            adornmentIcon="fas-search"
            placeholder="Search an endpoint"
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 42px)', width: '100%' }}>
        <Table
          tableId={tableId}
          resourcesParams={resourcesParams}
          columns={columns}
          formatData={formatData}
          pageSize={1000}
          pageSizeOptions={[1000]}
        />
      </div>
    </div>
  )
}
