// import { createWarningOnce } from 'app/libs/devWarning';
import { http } from './http'
import { methods } from './methods'
import Authorization from './Authorization'

// const addDeprecated = createWarningOnce(() => console.warn(`api.resource.collection.add() is deprecated. Use create() instead`));

export default class Collection extends Authorization {
  /**
   * @constructor
   *
   * @param string uri
   *
   * @return Collection
   */
  constructor(uri) {
    super()

    this.uri = uri
  }

  /**
   * @param array data
   *
   * @return Promise
   */
  create(data, queries, headers, getHttpProgress, requestController) {
    if (!Array.isArray(data)) {
      throw new Error(`resource.collection.add() must have 1 array parameter - resource ${this.uri}`)
    }

    if (data.length === 0) {
      console.error(`API WARNING: you intend to create on ${this.uri} with an empty array.`)
      return Promise.resolve()
    }

    return http.request({
      method: methods.POST,
      url: `${this.uri}/`,
      data,
      queries,
      headers,
      getHttpProgress,
      requestController,
    })
  }

  /**
   * @param array data
   *
   * @return Promise
   */
  update(data, queries, headers, getHttpProgress, requestController) {
    if (!Array.isArray(data)) {
      throw new Error(`resource.collection.update() must have 1 array parameter - resource ${this.uri}`)
    }

    if (data.length === 0) {
      console.error(`API WARNING: you intend to update on ${this.uri} with an empty array.`)
      return Promise.resolve()
    }

    return http.request({
      method: methods.PATCH,
      url: `${this.uri}/`,
      data,
      queries,
      headers,
      getHttpProgress,
      requestController,
    })
  }

  /**
   * @param array data
   *
   * @return Promise
   */
  delete(data, queries, headers, getHttpProgress, requestController) {
    if (!Array.isArray(data)) {
      throw new Error(
        `resource.collection.delete() must have 1 array parameter - resource ${
          this.uri
        }.\nReceive data:\n${JSON.stringify(data, null, 4)}`,
      )
    }

    if (data.length === 0) {
      console.error(`API WARNING: you intend to delete on ${this.uri} with an empty array.`)
      return Promise.resolve()
    }

    return http.request({
      method: methods.POST,
      url: `${this.uri}/`,
      data,
      queries,
      headers: {
        ...headers,
        'X-Action': 'bulk-delete',
      },
      getHttpProgress,
      requestController,
    })
  }
}
