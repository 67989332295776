// @flow

import React from 'react'
import { map } from 'lodash'
import pipe from 'app/core/utils/pipe'
import type { Pipe, Asset } from 'app/core/types'
import { MUIButton } from 'app/components/Form'
import { getResources } from 'app/store/selectors/getResources'
import resources from 'app/store/resources'
import { ExpansionPanelButton } from 'app/components/ExpansionPanel/ExpansionPanelButton.jsx'
import { copyTextToClipboard } from 'app/libs/copyTextToClipboard'
import ExpansionPanel from 'app/components/ExpansionPanel/ExpansionPanel.jsx'
import { permission } from 'app/containers/Permissions'
import { UsageShots } from './UsageShots.jsx'

import classes from './Usage.module.scss'

type Props = {
  episodes: Array<Asset>,
  asset: Asset,
}

type State = {|
  shotsText: { [link: string]: Array<string> },
  loadedEpisode: Array<boolean>,
|}

class Usage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      shotsText: {},
      loadedEpisode: Array(props.episodes.length).fill(false),
    }
  }

  isMounted: boolean = true

  componentWillUnmount() {
    this.isMounted = false
  }

  getShotStrToCopy(episode) {
    const { shotsText } = this.state
    return shotsText[episode.name].map((usage) => usage).join('\n')
  }

  onCopyAllToClipboard = () => {
    const { episodes } = this.props
    const strToCopy = map(episodes, (episode) => `${episode.name}\n\n${this.getShotStrToCopy(episode)}`).join('\n\n')

    copyTextToClipboard(strToCopy)
  }

  onCopyEpisodeToClipboard(episode) {
    copyTextToClipboard(this.getShotStrToCopy(episode))
  }

  render(): React$Node {
    const { episodes, asset } = this.props
    const { loadedEpisode } = this.state

    return (
      <div className={classes.container}>
        <div className="flex margin5 end">
          <MUIButton
            icon="fas-copy"
            color="primary"
            onClick={this.onCopyAllToClipboard}
            loader={loadedEpisode.indexOf(false) !== -1}
          >
            Copy all episodes
          </MUIButton>
        </div>
        <div className="fullWidth overflowYAuto" style={{ height: 'calc(100% - 30px)' }}>
          <div className="padding5">
            {map(episodes, (episode, index: number) => {
              const iconsList = [
                <ExpansionPanelButton
                  key="copy"
                  icon="fas-copy"
                  onClick={() => this.onCopyEpisodeToClipboard(episode)}
                  tooltip="copy episode's shots name in clipboard"
                />,
              ]

              permission(['projet_breakdown__read']) &&
                episode.project &&
                iconsList.push(
                  <ExpansionPanelButton
                    key={episode.id}
                    icon="fas-external-link-alt"
                    to={`/projects/${episode.project}/breakdown/${episode.id}`}
                    tooltip="Redirect to episode breakdown"
                  />,
                )

              return (
                <ExpansionPanel rightContent={iconsList} key={episode.id} title={episode.name}>
                  <UsageShots
                    episode={episode}
                    asset={asset}
                    getShotsText={(shots) =>
                      this.isMounted &&
                      this.setState((state) => {
                        const { shotsText } = state
                        shotsText[episode.name] = shots
                        return { shotsText }
                      })
                    }
                    isLoaded={(isLoaded) =>
                      this.isMounted &&
                      this.setState((state) => {
                        const { loadedEpisode } = state
                        loadedEpisode[index] = isLoaded
                        return { loadedEpisode }
                      })
                    }
                  />
                </ExpansionPanel>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

const pipeInst: Pipe<{ asset: Asset }, typeof Usage> = pipe()

const UsageComponent: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const episodes = getResources(state, 'assets.requests.fetchEpisodeUsage')
    return {
      episodes: map(episodes).sort((a, b) =>
        `${a.name}`.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }),
      ),
      asset: props.asset,
    }
  })
  .request((props) => {
    const { asset } = props
    return Promise.all([
      resources.assets.fetchEpisodeUsage(asset.id, {
        params: { queries: { page_size: 1000 }, headers: { [window.OVM_PROJECT_HEADER]: asset.project || '' } },
      }),
    ])
  })
  .render(Usage)

export default UsageComponent
