// @flow
import React, { useEffect, useState, type Element } from 'react'
import Slide from '@material-ui/core/Slide'
import type { ElementProps } from 'app/core/types'
import authenticatedClasses from 'app/main/Authenticated/Authenticated.module.scss'

import classes from './Drawer.module.scss'

type Props = {|
  ...ElementProps,
  timeout?: number,
  open: boolean,
  children: Element<any>,
  onClose?: Function,
|}

export function Drawer(props: Props): React$Node {
  const { timeout = 200, open, children, onClose, style, ...rest } = props
  const [mounted, setMounted] = useState(false)
  const [appHeight, setAppHeight] = useState('100vh')

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setMounted(false)
        if (onClose) onClose()
      }, timeout)
    } else {
      setMounted(true)
    }
  }, [open])

  useEffect(() => {
    function onKeyDow(e: *) {
      if (e.key === 'Escape' && onClose) onClose()
    }

    document.body?.addEventListener('keydown', onKeyDow)
    return () => {
      document.body?.removeEventListener('keydown', onKeyDow)
    }
  }, [])

  function onPageResize(entries) {
    const height = entries[0]?.contentRect?.height
    if (height !== appHeight) setAppHeight(height)
  }

  useEffect(() => {
    const pageEl = document.querySelector(`.${authenticatedClasses.pageContainer}`)
    if (pageEl) {
      const observer = new ResizeObserver(onPageResize)
      observer.observe(pageEl)
      return () => {
        observer.disconnect()
      }
    }
    return undefined
  }, [])

  return mounted ? (
    <div
      className={classes.overlay}
      style={{
        ...style,
        opacity: open ? 1 : 0,
        transition: `opacity ${timeout}ms linear`,
        height: appHeight,
      }}
      onMouseDown={(event) => {
        if (onClose) onClose()
      }}
    >
      <Slide in={open} direction="left" timeout={timeout}>
        <div
          style={{
            height: '100%',
          }}
          className={classes.collapse}
          onMouseDown={(event) => {
            event.stopPropagation()
          }}
          {...rest}
        >
          {children}
        </div>
      </Slide>
    </div>
  ) : (
    <div />
  )
}
