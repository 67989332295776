/** @flow */
import React from 'react'
import type { InputProps } from 'app/core/types'
import { MUICheckbox, type MUICheckboxProps } from 'app/components/Form/Checkbox/MUICheckbox.jsx'

type Props = {|
  ...InputProps,
  ...MUICheckboxProps,
  onChange: Function,
  ignoreFocus?: boolean,
|}

export function ControlledCheckbox(props: Props): React$Node {
  const { readOnly, onChange, checked, ignoreFocus, ...rest } = props
  return (
    <MUICheckbox
      inputRef={(ref) => {
        if (!ignoreFocus) ref?.focus?.()
      }}
      readOnly={readOnly}
      checked={checked}
      onChange={onChange}
      {...rest}
    />
  )
}
