/** @flow */
import React from 'react'
import type { ContractAttribute } from 'app/core/types'
import { NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import classes from './getContractAttributeNode.module.scss'

type Props = {|
  contractAttribute: ContractAttribute,
  onSelect: Function,
  parentNode: Node<null>,
|}

export type ContractAttributeNodeData = {|
  contractAttribute: ContractAttribute,
  contentType: 'contractAttribute',
|}

export function getContractAttributeNode(props: Props): Node<ContractAttributeNodeData> {
  const { contractAttribute, onSelect, parentNode } = props

  return new Node({
    key: contractAttribute.id,
    icon: 'fas-grip-vertical',
    name: (
      <div className={classes.listItemContainer} onClick={() => onSelect(contractAttribute)}>
        <span className={classes.attributeLabel}>{contractAttribute.displayName || contractAttribute.name}</span>
      </div>
    ),
    data: { contractAttribute, contentType: 'contractAttribute' },
    parent: parentNode,
    type: NODE_ITEM,
  })
}
