// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* :global(.ReactTable .rt-tr-group > .rt-tr-group:nth-child(2)) {
  display: none;
} */

.app-pages-Project-Tasks-___TableTasks-module__pivotOpen {
  transition: 0.3s;
  transform: rotate(90deg);
}

.app-pages-Project-Tasks-___TableTasks-module__pivotClose {
  transition: 0.3s;
  transform: rotate(0deg);
}

.app-pages-Project-Tasks-___TableTasks-module__placeholderLabel {
  width: calc(100% - 5px);
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: center;
  font-size: 11px;
  color: lightgrey;
  line-height: 12px;
}
`, "",{"version":3,"sources":["webpack://./app/pages/Project/Tasks/TableTasks.module.scss"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,uBAAuB;EACvB,qBAAqB;EACrB,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["/* :global(.ReactTable .rt-tr-group > .rt-tr-group:nth-child(2)) {\n  display: none;\n} */\n\n.pivotOpen {\n  transition: 0.3s;\n  transform: rotate(90deg);\n}\n\n.pivotClose {\n  transition: 0.3s;\n  transform: rotate(0deg);\n}\n\n.placeholderLabel {\n  width: calc(100% - 5px);\n  max-width: 100%;\n  overflow: hidden;\n  display: -webkit-box;\n  text-overflow: ellipsis;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  text-align: center;\n  font-size: 11px;\n  color: lightgrey;\n  line-height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pivotOpen": `app-pages-Project-Tasks-___TableTasks-module__pivotOpen`,
	"pivotClose": `app-pages-Project-Tasks-___TableTasks-module__pivotClose`,
	"placeholderLabel": `app-pages-Project-Tasks-___TableTasks-module__placeholderLabel`
};
export default ___CSS_LOADER_EXPORT___;
