/** @flow */
import React from 'react'
import type { Match, Asset } from 'app/core/types'
import { map } from 'lodash'
import pipe from 'app/core/utils/pipe'
import { ButtonList, MUIButton } from 'app/components/Form'
import { TabsRouter } from 'app/components/Tabs/Tabs.jsx'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import { openPictures } from 'app/components/PictureVisualization/PictureVisualization.tsx'
import { Breadcrumb } from 'app/components/Breadcrumb/Breadcrumb.jsx'
import SubscribeButton from 'app/containers/Subscription/SubscribeButton.jsx'
import { getResources } from 'app/store/selectors/getResources'
import resources from 'app/store/resources'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import { assetsTypes } from 'app/core/constants'
import type { TabsType } from 'app/components/Tabs/TabsRouter.jsx'
import copy from 'copy-to-clipboard'
import { success } from 'app/components/Notifications/notify'

import classes from './AssetDetail.module.scss'
import Discussions from './Discussions/Discussions.jsx'
import Infos from './Infos/Infos.tsx'
import Wiki from './Wiki/Wiki.jsx'
import { Notes } from './Notes/Notes.tsx'
import Tasks from './Tasks/Tasks.tsx'
import BreakdownShot from './BreakdownShot/BreakdownShot.tsx'
import BreakdownEpisode from './BreakdownEpisode/BreakdownEpisode.tsx'
import PostBoardShots from './PostBoardShots/PostBoardShots.tsx'
import PostBoardModels from './PostBoardModels/PostBoardModels.tsx'
import ValidationMedias from './ValidationMedias/ValidationMedias.jsx'
import { Mozaic } from './ValidationMedias/Mozaic.tsx'
import Usage from './Usage/Usage.jsx'
import { router, Route, Link } from './router'

type Props = {|
  breadcrumbProps?: Object,
  params: Object,
  asset: ?Asset,
  localRouter: Object,
  match: Match,
  getAsset?: (asset: Asset) => void,
|}

class AssetDetail extends React.Component<Props> {
  router = (this.props.localRouter && this.props.localRouter.router) || router

  RouterEl = this.props.localRouter
    ? {
        Route: this.props.localRouter.Route,
        Link: this.props.localRouter.Link,
        router: this.props.localRouter.router,
      }
    : {
        Route,
        Link,
        router,
      }

  componentDidUpdate(prevProps) {
    if (prevProps.asset && this.props.asset && this.props.asset.id !== prevProps.asset.id) {
      resources.assets.fetchOne(this.props.params.assetId)
    }
  }

  onCopyTextToClipboard = () => {
    const { asset } = this.props
    const { project: assetProject, id: assetId } = asset

    const { origin } = window.location
    copy(`${origin}/projects/${assetProject}/assets?assetDetails=/${assetId}/informations`)
    success('Copy to clipboard')
  }

  getLinks(): { [key: string]: TabsType } {
    const { asset } = this.props

    if (!asset) return {}

    const assetId = asset.id
    const assetProject = asset.project

    const informations = {
      key: 'informations',
      permission: ['Overmind_Asset sheets_Tabs_Informations'],
      name: 'informations',
      params: { assetId },
      label: 'Information',
    }
    const discussions = {
      permission: ['Overmind_Asset sheets_Tabs_Discussions'],
      projectId: assetProject,
      key: 'discussions',
      name: 'discussions',
      params: { assetId },
      label: 'Discussions',
    }
    const wiki = {
      projectId: assetProject,
      permission: ['Overmind_Asset sheets_Tabs_Wiki'],
      key: 'wiki',
      name: 'wiki',
      params: { assetId },
      label: 'Wiki',
    }
    const tasks = {
      projectId: assetProject,
      permission: ['Overmind_Asset sheets_Tabs_Tasks'],
      key: 'tasks',
      name: 'tasks',
      params: { assetId },
      label: 'Tasks',
    }
    const medias = {
      projectId: assetProject,
      permission: [
        'Overmind_Asset sheets_Tabs_Media - Mozaic',
        'Overmind_Asset sheets_Tabs_Media - Mozaic (All medias)',
        'Overmind_Asset sheets_Tabs_Media - Validation Medias',
      ],
      operator: 'or',
      key: 'medias',
      name: 'medias',
      activeName: 'mozaic',
      params: { assetId },
      subList: [
        {
          label: 'Mozaic',
          key: 'mozaic',
          name: 'mozaic',
          permission: ['Overmind_Asset sheets_Tabs_Media - Mozaic'],
          projectId: assetProject,
          params: { assetId },
          parent: 'medias',
        },
        {
          label: 'Mozaic (all medias)',
          key: 'mozaicAllMedias',
          name: 'mozaicAllMedias',
          permission: ['Overmind_Asset sheets_Tabs_Media - Mozaic (All medias)'],
          projectId: assetProject,
          params: { assetId },
          parent: 'medias',
        },
        {
          label: 'Validation medias',
          key: 'validationMedias',
          name: 'validationMedias',
          permission: ['Overmind_Asset sheets_Tabs_Media - Validation Medias'],
          projectId: assetProject,
          params: { assetId },
          parent: 'medias',
        },
      ],
      label: 'Medias',
    }
    const breakdownShot = {
      projectId: assetProject,
      permission: ['Overmind_Asset sheets_Tabs_Breakdown'],
      key: 'breakdownShot',
      name: 'breakdownShot',
      params: { assetId },
      label: 'Breakdown',
    }
    const breakdownEpisode = {
      projectId: assetProject,
      permission: ['Overmind_Asset sheets_Tabs_Breakdown'],
      key: 'breakdownEpisode',
      name: 'breakdownEpisode',
      params: { assetId },
      label: 'Breakdown',
    }
    const notes = {
      projectId: assetProject,
      permission: ['Overmind_Asset sheets_Tabs_Notes'],
      key: 'notes',
      name: 'notes',
      params: { assetId },
      label: 'Notes',
    }
    const postBoardShots = {
      permission: ['Overmind_Asset sheets_Tabs_Shot Notes'],
      projectId: assetProject,
      key: 'postBoardShots',
      name: 'postBoardShots',
      params: { assetId },
      label: 'Shots Notes',
    }
    const postBoardModels = {
      permission: ['Overmind_Asset sheets_Tabs_Model Notes'],
      projectId: assetProject,
      key: 'postBoardModels',
      name: 'postBoardModels',
      params: { assetId },
      label: 'Models Notes',
    }
    const usage = {
      permission: ['Overmind_Asset sheets_Tabs_Usage'],
      key: 'usage',
      name: 'usage',
      params: { assetId },
      label: 'Usage',
    }

    let customNav = { informations, discussions, wiki, tasks }

    switch (asset.assetType) {
      case 'fo':
        customNav = { ...customNav, medias }
        break
      case 'sh':
        customNav = { ...customNav, breakdownShot, medias, notes }
        break
      case 'ep':
        customNav = { ...customNav, breakdownEpisode, postBoardShots, postBoardModels, medias }
        break
      case 'mo':
        customNav = { ...customNav, usage, medias, notes }
        break
      default:
        break
    }

    return customNav
  }

  onClickBreadcrumbItem = (item) => {
    this.router.goTo('index', { assetId: item.id })
  }

  render(): React$Node {
    const { breadcrumbProps, asset } = this.props
    const { RouterEl } = this

    const tabs = this.getLinks()

    if (!asset) {
      return (
        <div className="fullWidth fullHeight flex center alignCenter grey fontSize16 grey bold">
          Can&#39;t find this asset.
        </div>
      )
    }

    return (
      <div className={classes.container}>
        <div>
          <Breadcrumb asset={asset} onClick={this.onClickBreadcrumbItem} {...breadcrumbProps} />
        </div>
        <ButtonList style={{ justifyContent: 'flex-end', position: 'absolute', top: 10, right: 10 }}>
          <MUIButton
            onClick={this.onCopyTextToClipboard}
            icon="fas-link"
            color="primary"
            dataCy="asset-copy"
            tooltip="Copy url in the clipboard"
          />
          <SubscribeButton target={asset} targetType="asset" />
        </ButtonList>
        <div className={classes.topContent}>
          {asset.thumbnailInst ? (
            <div className={classes.image}>
              <img
                src={asset.thumbnailInst.url}
                onClick={() => {
                  asset.thumbnailInst ? openPictures([asset.thumbnailInst], false, false, 0) : undefined
                }}
              />
            </div>
          ) : null}
          <div className={classes.infos}>
            <div
              className={classes.name}
              style={asset.internalStatus === 2 ? { textDecoration: 'line-through' } : undefined}
            >
              <Tooltip title={assetsTypes[asset.assetType]}>
                <FontIcon icon={assetIcons(asset.assetType)} className="marginRight10" />
              </Tooltip>
              {asset.name}
            </div>
            {asset.internalStatus === 2 ? <div className={classes.removed}>Removed</div> : null}
            <div />
          </div>
        </div>
        <div className={classes.tabsRouter}>
          <TabsRouter
            key={asset.id}
            tabs={map(tabs)}
            localRouter={RouterEl}
            className={classes.tabs}
            contentStyle={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            {tabs.informations ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Informations']}
                name="informations"
                component={Infos}
                componentProps={{ asset }}
              />
            ) : null}
            {tabs.discussions ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Discussions']}
                projectId={asset.project}
                name="discussions"
                component={Discussions}
                componentProps={{ asset, router: this.router }}
              />
            ) : null}

            {tabs.medias
              ? [
                  <RouterEl.Route
                    permission={['Overmind_Asset sheets_Tabs_Media - Mozaic']}
                    operator="or"
                    projectId={asset.project}
                    name="mozaic"
                    key="mozaic"
                    component={Mozaic}
                    componentProps={{ asset, pinned: true }}
                  />,
                  <RouterEl.Route
                    permission={['Overmind_Asset sheets_Tabs_Media - Mozaic (All medias)']}
                    operator="or"
                    projectId={asset.project}
                    name="mozaicAllMedias"
                    key="mozaicAllMedias"
                    component={Mozaic}
                    componentProps={{ asset }}
                  />,
                  <RouterEl.Route
                    permission={['Overmind_Asset sheets_Tabs_Media - Validation Medias']}
                    operator="or"
                    projectId={asset.project}
                    name="validationMedias"
                    key="validationMedias"
                    component={ValidationMedias}
                    componentProps={{ asset }}
                  />,
                ]
              : null}

            {tabs.wiki ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Wiki']}
                name="wiki"
                component={Wiki}
                componentProps={{ asset }}
              />
            ) : null}
            {tabs.tasks ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Tasks']}
                projectId={asset.project}
                name="tasks"
                component={Tasks}
                componentProps={{ asset }}
              />
            ) : null}
            {tabs.notes ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Notes']}
                projectId={asset.project}
                name="notes"
                component={Notes}
                componentProps={{ asset }}
              />
            ) : null}
            {tabs.breakdownShot ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Breakdown']}
                projectId={asset.project}
                name="breakdownShot"
                component={BreakdownShot}
                componentProps={{ asset }}
              />
            ) : null}
            {tabs.breakdownEpisode ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Breakdown']}
                projectId={asset.project}
                name="breakdownEpisode"
                component={BreakdownEpisode}
                componentProps={{ asset }}
              />
            ) : null}
            {tabs.postBoardModels ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Model Notes']}
                projectId={asset.project}
                name="postBoardModels"
                component={PostBoardModels}
                componentProps={{ asset }}
              />
            ) : null}
            {tabs.postBoardShots ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Shot Notes']}
                projectId={asset.project}
                name="postBoardShots"
                component={PostBoardShots}
                componentProps={{ asset }}
              />
            ) : null}
            {tabs.usage ? (
              <RouterEl.Route
                permission={['Overmind_Asset sheets_Tabs_Usage']}
                name="usage"
                component={Usage}
                componentProps={{ asset }}
              />
            ) : null}
          </TabsRouter>
        </div>
      </div>
    )
  }
}

const AssetDetailContainer = pipe()
  .withRouter()
  .connect((state, props) => {
    const { getAsset, params } = props
    const { assetId } = params
    const asset = getResources(state, 'assets', assetId, ['thumbnailInst'])

    if (getAsset) getAsset(asset)

    return { asset }
  })
  .request((props) =>
    resources.assets.fetchOne(props.params.assetId || props.asset.id).catch((err) => console.error(err)),
  )
  .render(AssetDetail)

export default function (props: *): React$Element<any> {
  const { localRouter } = props
  const Component = localRouter ? localRouter.Route : Route

  return <Component name="asset" component={AssetDetailContainer} componentProps={props} />
}
