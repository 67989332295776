// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* reset css for react styleguidedist */

.react-datepicker {
  width: -moz-max-content;
  width: max-content;
}

.react-datepicker__time-list {
  padding-inline-start: 0;
}

.react-datepicker__input-container input {
  padding: 2px 5px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px #ccc;
  text-align: center;
  font-size: 12px;
  min-width: 180px;
  min-height: 30px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/DateTimePicker/DateTimePicker.scss"],"names":[],"mappings":"AAAA,uCAAuC;;AAEvC;EACE,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["/* reset css for react styleguidedist */\n\n:global(.react-datepicker) {\n  width: max-content;\n}\n\n:global(.react-datepicker__time-list) {\n  padding-inline-start: 0;\n}\n\n:global(.react-datepicker__input-container input) {\n  padding: 2px 5px;\n  text-align: center;\n  cursor: pointer;\n  border-radius: 4px;\n  border: solid 1px #ccc;\n  text-align: center;\n  font-size: 12px;\n  min-width: 180px;\n  min-height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
