// @flow

import * as React from 'react'
import Fuse from 'fuse.js'
import innerText from 'react-innertext'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import keyCode from 'app/libs/keyCode'
import BoundingComponent from 'app/components/BoundingComponent/BoundingComponent.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type { IconType } from 'app/core/types'
import { colors } from 'app/styles/colors'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'

import classes from './AutocompleteSingle.module.scss'
import { Input } from '../../Input/Input.jsx'
import Validators from '../../Validators/Validators.jsx'
import { Option, type AutocompleteOption } from './Option.jsx'

const defaultFuseConfig = {
  shouldSort: true,
  threshold: 0.1,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['label'],
}

export type Props = {
  onSearch?: (value: string) => Promise<Object>,
  onChange?: (item: AutocompleteOption<any>, event: SyntheticMouseEvent<>) => ?boolean,
  value: any,
  selected?: any,
  searchable?: boolean,
  filter: (item: Object) => boolean,
  cy?: string,
  options?: Array<AutocompleteOption<any>> | Function | Promise<any>,
  additionnalOptions?: Array<AutocompleteOption<any>>,
  disabledOptions?: Array<mixed>,
  lengthForSearch?: number,
  validators?: Object,
  validateOnChange?: boolean,
  placeholder?: string,
  onSearchDelay?: number,

  /**
   * Useful when we want to select multiple results.
   * See [app/components/List/List](#list)
   *
   * Warning: there is some graphics bugs when component position is moving (because searchResults has a fixed position)
   */
  allowSelectMultiple?: boolean,
  inputProps: Object,
  inputStyle?: Object,
  optionsContainerStyle?: Object,
  enableCache?: boolean,
  isLoading?: boolean,
  isRequired?: boolean,
  style?: Object,
  onBlur?: Function,
  fuseConfig?: Object,
  validatorRef?: Function,
  blurOnSelect?: boolean,
  errors?: Array<string>,
  defaultOpenOptions?: boolean,
  disabled?: boolean,
  animeKeyPressed?: $ElementType<AutocompleteOption<any>, 'animeKeyPressed'>,
}

type State = {
  inputValue: ?string,
  searchResults: ?any,
  openOptions: boolean,
  isLoading: boolean,
  selectedItemIndex: ?number,
  defaultSearchResults: $PropertyType<Props, 'options'>,
  error?: any,
}

/**
 * @visibleName Autocomplete
 */
export default class AutocompleteSingle extends React.PureComponent<Props, State> {
  static defaultProps: $Shape<Props> = {
    options: null,
    searchable: false,
    lengthForSearch: 1,
    validateOnChange: true,
    placeholder: '',
    onSearchDelay: 300, // miliseconds,
    value: { value: '', label: '' },
    allowSelectMultiple: false,
    enableCache: true,
    isLoading: false,
    filter: (item: Object) => !!item,
    onChange: (value: any) => value,
    isRequired: false,
    inputProps: {},
    inputStyle: {},
    fuseConfig: {},
    blurOnSelect: true,
    defaultOpenOptions: false,
    disabled: false,
  }

  static onMouseDownOption(event: Object) {
    event.preventDefault()
    event.stopPropagation()
  }

  isMounted: boolean = false

  optionsIsPromise: boolean = false

  optionsIsFunction: boolean = false

  enableLocalSearch: boolean = false

  searchResultsCurrentMaxHeight: number = 250

  containerRef: Object = React.createRef()

  inputRef: Object = React.createRef()

  cache: Object = {}

  fuseConfig: Object = {
    ...defaultFuseConfig,
    ...this.props.fuseConfig,
  }

  wantFocus: boolean = false

  searchResultsRef: HTMLElement

  requestTimeout: TimeoutID

  validatorRef: *

  backgroundColor: ?string = null

  // inputContainerRef: HTMLElement
  inputContentRef: HTMLElement

  constructor(props: Props) {
    super(props)

    const { options, onSearch } = props

    // $FlowFixMe
    this.optionsIsPromise = !!(options && !Array.isArray(options) && options.then)
    this.optionsIsFunction = typeof props.options === 'function'
    this.enableLocalSearch = !onSearch

    this.state = {
      inputValue: null,
      searchResults: null,
      openOptions: false,
      isLoading: this.optionsIsPromise || this.optionsIsFunction,
      selectedItemIndex: null,
      defaultSearchResults: props.options,
    }
  }

  UNSAFE_componentWillMount() {
    const { options } = this.props

    if (this.optionsIsPromise) {
      // flow doesn't understand optionsIsPromise
      // $FlowFixMe
      options.then((results) => {
        this.setState({
          defaultSearchResults: results,
          searchResults: results,
          openOptions: false,
          isLoading: false,
        })
      })
    } else if (this.optionsIsFunction) {
      // flow doesn't understand optionsIsFunction
      // $FlowFixMe
      const newOptions = options()

      if (newOptions.then) {
        newOptions.then((results) => {
          this.setState({
            defaultSearchResults: results,
            searchResults: results,
            openOptions: false,
            isLoading: false,
          })
        })
      } else {
        this.setState({
          defaultSearchResults: newOptions,
          searchResults: newOptions,
          openOptions: false,
          isLoading: false,
        })
      }
    }
  }

  componentDidMount() {
    this.isMounted = true
    if (this.props.defaultOpenOptions) {
      this.focus()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!this.optionsIsPromise && !this.optionsIsFunction && this.props.options !== nextProps.options) {
      this.setState({
        defaultSearchResults: nextProps.options,
      })
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { value, validateOnChange } = this.props

    if (prevProps.value && value && prevProps.value.value !== value.value) {
      if (validateOnChange) {
        this.valid()
      }
    }

    if (this.wantFocus) {
      this.focus()
    }
  }

  componentWillUnmount() {
    this.isMounted = false
  }

  onAfterRenderOptions: Function = () => {
    const { value } = this.props
    if (value && value.value) {
      const $el = this.searchResultsRef.querySelector(`[data-item-value="${value.value}"]`)

      // if current options is not in the list (can appear with async searchable)
      if (!$el) return

      const optionsContainerHeight = this.searchResultsRef.offsetHeight
      const scrollTop = $el.offsetTop - optionsContainerHeight / 2 + 20 // + 20 for center the item
      this.searchResultsRef.scrollTop = scrollTop
    }
  }

  onChangeInput: Function = (event: Object) => {
    const { searchable, lengthForSearch, onSearch, onSearchDelay, enableCache } = this.props
    const { defaultSearchResults } = this.state

    if (!searchable) return

    clearTimeout(this.requestTimeout)

    const inputValue = event.target.value

    if (inputValue.length < lengthForSearch) {
      const searchResults = this.enableLocalSearch ? defaultSearchResults : null

      this.setState({ inputValue, searchResults, isLoading: false })

      return
    }

    this.setState({ inputValue, isLoading: true })

    if (enableCache && this.cache[inputValue]) {
      this.setState({
        searchResults: this.cache[inputValue],
        openOptions: true,
        isLoading: false,
      })
    } else if (onSearch) {
      this.requestTimeout = setTimeout(() => {
        onSearch(inputValue).then((searchResults) => {
          if (enableCache) {
            this.cache[inputValue] = searchResults
          }

          this.setState({
            searchResults,
            openOptions: true,
            isLoading: false,
          })
        })
      }, onSearchDelay)
    } else if (!inputValue) {
      this.setState({
        searchResults: defaultSearchResults,
        openOptions: true,
        isLoading: false,
      })
    } else if (Array.isArray(defaultSearchResults)) {
      // local Search
      const fuse = new Fuse(defaultSearchResults, this.fuseConfig)
      const options = fuse.search(inputValue).map((el) => el.item)

      this.setState({
        searchResults: options,
        openOptions: true,
        isLoading: false,
      })
    }
  }

  onClickItem(item: AutocompleteOption<any>, e: SyntheticMouseEvent<>) {
    const { onChange = (item, e) => item, allowSelectMultiple } = this.props

    const changed = onChange(item, e)

    if (changed === false) return

    if (allowSelectMultiple) return

    this.hideOptions({ inputValue: null })
  }

  onFocus: Function = (event: Object) => {
    if (this.state.isLoading && document.activeElement) {
      document.activeElement.blur()
      this.wantFocus = true
      return
    }

    this.openOptions()
    event.preventDefault()
  }

  onBlur: Function = () => {
    this.wantFocus = false
    this.hideOptions()
    const { onBlur } = this.props
    if (onBlur) onBlur()
  }

  onKeyDown: Function = (event: Object) => {
    const { selectedItemIndex, openOptions } = this.state

    switch (event.keyCode) {
      case keyCode.UP: {
        event.preventDefault()
        event.stopPropagation()

        this.selectUpItem()

        break
      }

      case keyCode.DOWN: {
        event.preventDefault()
        event.stopPropagation()

        this.selectDownItem()

        break
      }

      case keyCode.ENTER:
        // case keyCode.TAB: {
        if (typeof selectedItemIndex === 'number') {
          const options = this.getOptions()
          const item = options && options[selectedItemIndex]
          if (item) {
            this.onClickItem(item, event)
          }
        }

        break
      // }

      case keyCode.ESCAPE: {
        if (openOptions) {
          event.preventDefault()
          event.stopPropagation()
          this.hideOptions()
        }

        break
      }

      default:
        break
    }
  }

  getSelectedIndexItem(index: number): * {
    if (index === this.state.selectedItemIndex) {
      const searchResults = this.searchResultsRef

      // bug hard to reproduce
      if (!searchResults) {
        console.error('Autocomplete: searchResults undefined')
        return false
      }

      const item = searchResults.querySelector('div')

      if (!item) throw new Error('undefined item')

      const itemHeight = item.offsetHeight
      const marginTopAndBottom = itemHeight * 2

      const itemTop = index * itemHeight
      const itemBottom = (index + 1) * itemHeight

      const { scrollTop } = searchResults

      if (itemBottom > scrollTop + this.searchResultsCurrentMaxHeight - marginTopAndBottom) {
        searchResults.scrollTop = itemBottom - this.searchResultsCurrentMaxHeight + marginTopAndBottom
      } else if (itemTop < scrollTop + marginTopAndBottom) {
        searchResults.scrollTop = itemTop - marginTopAndBottom
      }

      return true
    }

    return false
  }

  getOptions(): ?Array<AutocompleteOption<any>> {
    const { searchResults } = this.state
    const { disabledOptions = [], options, additionnalOptions = [] } = this.props

    let optionsToReturn

    if (searchResults) {
      optionsToReturn = additionnalOptions.concat(searchResults).filter((option) => {
        return disabledOptions.indexOf(option.value) === -1
      })
      return optionsToReturn
    }

    if (Array.isArray(options)) {
      optionsToReturn = additionnalOptions.concat(options).filter((option) => {
        return disabledOptions.indexOf(option.value) === -1
      })
      return optionsToReturn
    }

    return null
  }

  /**
   * @public
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  setInputValue(inputValue: string) {
    this.setState({ inputValue })
  }

  getValueLabel(): { label: string, icon?: IconType } {
    const { value, selected } = this.props
    const { inputValue } = this.state
    const options = this.getOptions()

    if (inputValue !== null && inputValue !== undefined) {
      return { label: inputValue }
    }
    if (value) {
      if (value.label && !options) {
        return { label: value.label, icon: value.icon }
      }
      if (![undefined, null, ''].includes(selected) && Array.isArray(options)) {
        const currentOption = options.find((option) => option.value === selected)
        if (!currentOption) return { label: '' }
        return { label: currentOption.label, icon: currentOption.icon }
      }
      if (![undefined, null, ''].includes(value.value) && Array.isArray(options)) {
        const currentOption = options.find((option) => option.value === value.value)

        if (!currentOption) return { label: '' }

        return { label: currentOption.label, icon: currentOption.icon }
      }
    }

    return { label: '' }
  }

  getValidators(): * {
    return {
      required: this.props.isRequired,
      ...this.props.validators,
    }
  }

  openOptions: Function = () => this.setState({ openOptions: true })

  selectDownItem() {
    const { selectedItemIndex } = this.state
    const options = this.getOptions()

    if (!options) return

    let nextIndex = selectedItemIndex === null ? 0 : (selectedItemIndex || 0) + 1
    if (options[nextIndex] && options[nextIndex].separator) nextIndex += 1
    if (!options[nextIndex]) nextIndex = 0

    this.setState({ selectedItemIndex: nextIndex })
  }

  selectUpItem() {
    const { selectedItemIndex } = this.state
    const options = this.getOptions()

    if (!options) return

    let prevIndex = (selectedItemIndex || 0) - 1
    if (options[prevIndex] && options[prevIndex].separator) prevIndex -= 1
    if (!options[prevIndex]) prevIndex = options.length - 1

    this.setState({ selectedItemIndex: prevIndex })
  }

  hideOptions(state?: { inputValue: ?string }) {
    const { blurOnSelect } = this.props
    this.cache = {}

    this.setState({
      ...state,
      openOptions: false,
      selectedItemIndex: null,
    })

    // keep focus for searchable select with multiple item
    if (blurOnSelect && document.activeElement) {
      document.activeElement.blur()
    }
  }

  valid(): * {
    if (this.validatorRef) {
      return this.validatorRef.valid()
    }

    return null
  }

  /**
   * @public
   */
  focus() {
    if (this.props.searchable && this.inputRef.current) {
      this.inputRef.current.focus()
    } else if (this.containerRef.current) {
      this.containerRef.current.focus()
    }
  }

  renderIcon(): * {
    const iconStyle = { color: this.backgroundColor ? 'white' : colors.greyDark }

    const icon =
      this.state.isLoading || this.props.isLoading ? (
        <LoaderSmall />
      ) : this.props.searchable ? (
        <FontIcon icon="search" style={iconStyle} />
      ) : (
        <FontIcon icon="arrowDown" style={{ ...iconStyle, fontSize: 18 }} />
      )

    return <div className={classes.icon}>{icon}</div>
  }

  renderInput(): * {
    const { value, selected, inputStyle, placeholder, searchable, inputProps, errors, disabled, onSearch } = this.props
    const { error } = this.state

    let style = {}

    const options = this.getOptions()

    let currentOption = null

    if (options && !this.optionsIsPromise && selected) {
      currentOption = options.find((option) => option.value === selected)
    } else if (options && !this.optionsIsPromise && value) {
      currentOption = options.find((option) => option.value === value.value)
    }

    if (currentOption && currentOption.backgroundColor) {
      style.backgroundColor = currentOption.backgroundColor
      style.fontWeight = 'bold'
      if (currentOption.color) {
        style.color = currentOption.color
      }
    }

    this.backgroundColor = style.backgroundColor

    style = {
      ...style,
      ...inputStyle,
      ...inputProps.style,
    }

    const { label, icon } = this.getValueLabel()

    // fix because server send a <span> for highlighted words
    const valueLabel = typeof label === 'string' ? label.replace(/<(?:.|\n)*?>/gm, '') : innerText(label)

    const componentStyle = {
      height: '100%',
      paddingRight: 30,
      cursor: !searchable && !disabled ? 'pointer' : 'default',
      caretColor: !searchable ? 'transparent' : null,
      ...style,
    }

    if (icon) componentStyle.paddingLeft = 30
    if (disabled) componentStyle.color = '#4A4A4A'
    if (style.backgroundColor) componentStyle.color = getColorFromBackground(style.backgroundColor)

    return (
      <>
        {icon ? (
          <FontIcon
            icon={icon}
            style={{ color: 'grey', position: 'absolute', zIndex: 10, left: 10, top: 'calc(50% - 7px)', height: 14 }}
          />
        ) : null}
        <Input
          ref={this.inputRef}
          contentRef={(c) => {
            this.inputContentRef = c
          }}
          value={valueLabel}
          onChange={this.onChangeInput}
          errors={errors || (error && [error])}
          placeholder={placeholder}
          onKeyDown={this.onKeyDown}
          dataCy={cyLabelFormater('autocompleteInput', placeholder)}
          {...inputProps}
          tabIndex={onSearch || searchable ? '0' : '-1'}
          disabled={disabled}
          style={componentStyle}
          data-do-not-focus-autocomplete={true}
        />
      </>
    )
  }

  renderOptions(): React$Node {
    const { value, optionsContainerStyle, filter, animeKeyPressed } = this.props
    const { openOptions, isLoading } = this.state
    if (!openOptions || isLoading) return null

    const options = this.getOptions()

    if (!options) return null

    return (
      <BoundingComponent onAfterRender={this.onAfterRenderOptions}>
        <div
          ref={(c) => {
            if (c) {
              this.searchResultsRef = c
            }
          }}
          className={classes.searchResults}
          style={{
            maxHeight: this.searchResultsCurrentMaxHeight,
            minWidth: this.inputContentRef.offsetWidth - 2,
            ...optionsContainerStyle,
          }}
        >
          {options.length === 0 ? (
            <div className={classes.noResults}>No results</div>
          ) : (
            options.filter(filter).map((option, index) => {
              return (
                <Option
                  key={String(index)}
                  {...option}
                  onClick={(e) => this.onClickItem(option, e)}
                  onMouseDown={AutocompleteSingle.onMouseDownOption}
                  highlighted={this.getSelectedIndexItem(index)}
                  selected={value && option.value === value.value}
                  animeKeyPressed={animeKeyPressed}
                />
              )
            })
          )}
        </div>
      </BoundingComponent>
    )
  }

  render(): React$Node {
    const { style, value, validatorRef, selected, disabled, cy, onSearch, searchable } = this.props

    let valueToValid = ''
    if (selected) {
      valueToValid = selected
    } else if (value) {
      valueToValid = value.value
    }

    return (
      <div
        ref={this.containerRef}
        className={classes.container}
        style={style}
        tabIndex={disabled || onSearch || searchable ? '-1' : '0'}
        onFocus={!disabled ? this.onFocus : undefined}
        onBlur={this.onBlur}
        data-cy={cy}
      >
        {this.renderInput()}

        {!disabled && this.renderIcon()}

        <div id="searchResultsContainer">{this.renderOptions()}</div>

        <Validators
          ref={(c) => {
            if (validatorRef) validatorRef(c)
            this.validatorRef = c
          }}
          className={classes.fieldError}
          value={valueToValid}
          onSuccess={() => (this.isMounted ? this.setState({ error: null }) : undefined)}
          onError={(error) => (this.isMounted ? this.setState({ error }) : undefined)}
          validators={this.getValidators()}
          hide={true}
        />
      </div>
    )
  }
}
