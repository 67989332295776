// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Icons-___Icon-module__btnType {
  cursor: pointer;
}

.app-components-Icons-___Icon-module__logo {
  fill: #273037;
}

.app-components-Icons-___Icon-module__fillMainColor {
  fill: var(--colors-mainColor);
}

/**
 *   Do not remove, leave empty. It's use for hover state
 *   see app/styles/colors.js
 */
.app-components-Icons-___Icon-module__iconContainerHover {
}
`, "",{"version":3,"sources":["webpack://./app/components/Icons/Icon.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;;;EAGE;AACF;AACA","sourcesContent":[".btnType {\n  cursor: pointer;\n}\n\n.logo {\n  fill: #273037;\n}\n\n.fillMainColor {\n  fill: var(--colors-mainColor);\n}\n\n/**\n *   Do not remove, leave empty. It's use for hover state\n *   see app/styles/colors.js\n */\n.iconContainerHover {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnType": `app-components-Icons-___Icon-module__btnType`,
	"logo": `app-components-Icons-___Icon-module__logo`,
	"fillMainColor": `app-components-Icons-___Icon-module__fillMainColor`,
	"iconContainerHover": `app-components-Icons-___Icon-module__iconContainerHover`
};
export default ___CSS_LOADER_EXPORT___;
