// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Select-___Select-module__container {
  cursor: pointer;
  position: relative;
  width: 100%;
  border: solid 1px var(--colors-grey);
  border-radius: 5px;
}

  .app-components-Form-Select-___Select-module__container.app-components-Form-Select-___Select-module__error {
    border-color: var(--colors-red);
    box-shadow: 0 0 3px var(--colors-red);
  }

.app-components-Form-Select-___Select-module__select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 10px center;
  color: var(--colors-blackLight);
  font-family: var(--mainFont);
  font-size: 14px;
  outline: none;
  padding: 7px 35px 7px 8px;
  width: 100%;
  height: 100%;
}

.app-components-Form-Select-___Select-module__icon {
  align-items: center;
  border-left: solid 1px var(--colors-grey);
  display: flex;
  height: 100%;
  justify-content: center;
  line-height: 20px;
  position: absolute;
  pointer-events: none;
  right: 0px;
  top: 0px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  width: 30px;
}

.app-components-Form-Select-___Select-module__icon div {
    display: flex;
    justify-content: center;
    color: var(--colors-grey);
    height: 28px;
    align-items: center;
    font-size: 16px;
  }

.app-components-Form-Select-___Select-module__option {
  padding: 5px 10px;
  font-size: 16px;
}

.app-components-Form-Select-___Select-module__fieldError {
  position: absolute;
  right: 0px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Select/Select.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,oCAAoC;EACpC,kBAAkB;AAMpB;;EAJE;IACE,+BAA+B;IAC/B,qCAAqC;EACvC;;AAGF;EACE,wBAAwB;EACxB,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,4BAA4B;EAC5B,sCAAsC;EACtC,+BAA+B;EAC/B,4BAA4B;EAC5B,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,yCAAyC;EACzC,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,UAAU;EACV,QAAQ;EACR,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,WAAW;AAUb;;AARE;IACE,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,eAAe;EACjB;;AAGF;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".container {\n  cursor: pointer;\n  position: relative;\n  width: 100%;\n  border: solid 1px var(--colors-grey);\n  border-radius: 5px;\n\n  &.error {\n    border-color: var(--colors-red);\n    box-shadow: 0 0 3px var(--colors-red);\n  }\n}\n\n.select {\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  border: none;\n  border-radius: 5px;\n  background-color: #fff;\n  background-repeat: no-repeat;\n  background-position: right 10px center;\n  color: var(--colors-blackLight);\n  font-family: var(--mainFont);\n  font-size: 14px;\n  outline: none;\n  padding: 7px 35px 7px 8px;\n  width: 100%;\n  height: 100%;\n}\n\n.icon {\n  align-items: center;\n  border-left: solid 1px var(--colors-grey);\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  line-height: 20px;\n  position: absolute;\n  pointer-events: none;\n  right: 0px;\n  top: 0px;\n  user-select: none;\n  width: 30px;\n\n  div {\n    display: flex;\n    justify-content: center;\n    color: var(--colors-grey);\n    height: 28px;\n    align-items: center;\n    font-size: 16px;\n  }\n}\n\n.option {\n  padding: 5px 10px;\n  font-size: 16px;\n}\n\n.fieldError {\n  position: absolute;\n  right: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Form-Select-___Select-module__container`,
	"error": `app-components-Form-Select-___Select-module__error`,
	"select": `app-components-Form-Select-___Select-module__select`,
	"icon": `app-components-Form-Select-___Select-module__icon`,
	"option": `app-components-Form-Select-___Select-module__option`,
	"fieldError": `app-components-Form-Select-___Select-module__fieldError`
};
export default ___CSS_LOADER_EXPORT___;
