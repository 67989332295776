// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Project-Assets-AssetList-___Assets-module__container {
  height: 100%;
  display: flex;
}

.app-pages-Project-Assets-AssetList-___Assets-module__right {
  padding-left: 0px;
  flex: 1;
}

.app-pages-Project-Assets-AssetList-___Assets-module__unread {
  color: var(--colors-orange);
  font-weight: bold;
}

.app-pages-Project-Assets-AssetList-___Assets-module__countercontainer {
  display: flex;
  justify-content: 'space-between';
  min-width: 110px;
}

.app-pages-Project-Assets-AssetList-___Assets-module__countercontainer span {
    min-width: 45px;
    text-align: right;
  }

.app-pages-Project-Assets-AssetList-___Assets-module__countercontainer svg {
    margin: auto;
  }

.app-pages-Project-Assets-AssetList-___Assets-module__label {
  min-width: 140px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./app/pages/Project/Assets/AssetList/Assets.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,OAAO;AACT;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,gBAAgB;AAUlB;;AARE;IACE,eAAe;IACf,iBAAiB;EACnB;;AAEA;IACE,YAAY;EACd;;AAGF;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n}\n\n.right {\n  padding-left: 0px;\n  flex: 1;\n}\n\n.unread {\n  color: var(--colors-orange);\n  font-weight: bold;\n}\n\n.countercontainer {\n  display: flex;\n  justify-content: 'space-between';\n  min-width: 110px;\n\n  span {\n    min-width: 45px;\n    text-align: right;\n  }\n\n  svg {\n    margin: auto;\n  }\n}\n\n.label {\n  min-width: 140px;\n  width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-Project-Assets-AssetList-___Assets-module__container`,
	"right": `app-pages-Project-Assets-AssetList-___Assets-module__right`,
	"unread": `app-pages-Project-Assets-AssetList-___Assets-module__unread`,
	"countercontainer": `app-pages-Project-Assets-AssetList-___Assets-module__countercontainer`,
	"label": `app-pages-Project-Assets-AssetList-___Assets-module__label`
};
export default ___CSS_LOADER_EXPORT___;
