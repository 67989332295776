/** @flow */
/*eslint-disable */
import Planet_1 from './Planet_1.jsx'
import Planet_2 from './Planet_2.jsx'
import Planet_3 from './Planet_3.jsx'
import Planet_4 from './Planet_4.jsx'
import Planet_5 from './Planet_5.jsx'
import Planet_6 from './Planet_6.jsx'
import Planet_7 from './Planet_7.jsx'
import Planet_8 from './Planet_8.jsx'
import Planet_9 from './Planet_9.jsx'

export default {
  Planet_1,
  Planet_2,
  Planet_3,
  Planet_4,
  Planet_5,
  Planet_6,
  Planet_7,
  Planet_8,
  Planet_9,
}

/* eslint-enable */
