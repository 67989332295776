import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { Typography, Skeleton } from '@mui/material';
import { CardSkeleton } from './CardSkeleton';
import classes from './SkeletonGlobale.module.scss';
const OrganizationChartBottomSkeleton = () => {
    return (_jsxs(_Fragment, { children: [_jsxs("div", { style: {
                    display: 'flex',
                    width: '95%',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }, children: [_jsx(Typography, { variant: "h2", children: _jsx(Skeleton, { animation: "wave", width: 300 }) }), _jsx(Typography, { variant: "h2", children: _jsx(Skeleton, { animation: "wave", width: 300 }) }), _jsx(Typography, { variant: "h2", children: _jsx(Skeleton, { animation: "wave", width: 350 }) }), _jsx(Skeleton, { animation: "wave", variant: "circular", width: 25, height: 25, style: { marginLeft: 10 } })] }), _jsx("div", { className: classes.cardContainer, children: _jsx(CardSkeleton, { cards: 12 }) })] }));
};
export default OrganizationChartBottomSkeleton;
