// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-HR-TimeRecap-___TimeRecap-module__content {
  margin-top: 10px;
  display: flex;
}

.app-pages-HR-TimeRecap-___TimeRecap-module__topBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-pages-HR-TimeRecap-___TimeRecap-module__toolbar > div {
  margin-right: 8px;
  height: 30px;
}

.app-pages-HR-TimeRecap-___TimeRecap-module__toolItem {
  margin: 10px 0;
}

.app-pages-HR-TimeRecap-___TimeRecap-module__timeRecapUserDate {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./app/pages/HR/TimeRecap/TimeRecap.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".content {\n  margin-top: 10px;\n  display: flex;\n}\n\n.topBar {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.toolbar > div {\n  margin-right: 8px;\n  height: 30px;\n}\n\n.toolItem {\n  margin: 10px 0;\n}\n\n.timeRecapUserDate {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `app-pages-HR-TimeRecap-___TimeRecap-module__content`,
	"topBar": `app-pages-HR-TimeRecap-___TimeRecap-module__topBar`,
	"toolbar": `app-pages-HR-TimeRecap-___TimeRecap-module__toolbar`,
	"toolItem": `app-pages-HR-TimeRecap-___TimeRecap-module__toolItem`,
	"timeRecapUserDate": `app-pages-HR-TimeRecap-___TimeRecap-module__timeRecapUserDate`
};
export default ___CSS_LOADER_EXPORT___;
