// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetDetail-Discussions-___Thread-module__item {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 300ms;
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__item:hover {
  background-color: var(--colors-mainColorLight);
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__item:hover > .app-containers-Assets-AssetDetail-Discussions-___Thread-module__subscribeButton {
  transform: translateX(-40px);
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__item:active {
  background-color: var(--colors-mainColorMedium);
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__title {
  margin-top: 5px;
  font-size: 16px;
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__noReply {
  color: lightgrey;
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__metadata,
.app-containers-Assets-AssetDetail-Discussions-___Thread-module__noReply {
  font-size: 12px;
  font-weight: normal !important;
  color: grey;
  white-space: nowrap;
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__by {
  font-weight: bold;
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__notifUnread {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #f06a54;
  position: absolute;
  top: 5px;
  right: 5px;
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__subscribeButton {
  position: absolute;
  transition: all 0.3s;
  height: 40px;
  width: 40px;
  right: -40px;
  top: 0px;
}

.app-containers-Assets-AssetDetail-Discussions-___Thread-module__tooltip {
  width: 280px;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetDetail/Discussions/Thread.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2CAA2C;EAC3C,qBAAqB;AACvB;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,eAAe;EACf,8BAA8B;EAC9B,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE,YAAY;AACd","sourcesContent":[".item {\n  position: relative;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n  transition: all 300ms;\n}\n\n.item:hover {\n  background-color: var(--colors-mainColorLight);\n}\n\n.item:hover > .subscribeButton {\n  transform: translateX(-40px);\n}\n\n.item:active {\n  background-color: var(--colors-mainColorMedium);\n}\n\n.title {\n  margin-top: 5px;\n  font-size: 16px;\n}\n\n.text {\n  width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.noReply {\n  color: lightgrey;\n}\n\n.metadata,\n.noReply {\n  font-size: 12px;\n  font-weight: normal !important;\n  color: grey;\n  white-space: nowrap;\n}\n\n.by {\n  font-weight: bold;\n}\n\n.notifUnread {\n  width: 6px;\n  height: 6px;\n  border-radius: 3px;\n  background-color: #f06a54;\n  position: absolute;\n  top: 5px;\n  right: 5px;\n}\n\n.subscribeButton {\n  position: absolute;\n  transition: all 0.3s;\n  height: 40px;\n  width: 40px;\n  right: -40px;\n  top: 0px;\n}\n\n.tooltip {\n  width: 280px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `app-containers-Assets-AssetDetail-Discussions-___Thread-module__item`,
	"subscribeButton": `app-containers-Assets-AssetDetail-Discussions-___Thread-module__subscribeButton`,
	"title": `app-containers-Assets-AssetDetail-Discussions-___Thread-module__title`,
	"text": `app-containers-Assets-AssetDetail-Discussions-___Thread-module__text`,
	"noReply": `app-containers-Assets-AssetDetail-Discussions-___Thread-module__noReply`,
	"metadata": `app-containers-Assets-AssetDetail-Discussions-___Thread-module__metadata`,
	"by": `app-containers-Assets-AssetDetail-Discussions-___Thread-module__by`,
	"notifUnread": `app-containers-Assets-AssetDetail-Discussions-___Thread-module__notifUnread`,
	"tooltip": `app-containers-Assets-AssetDetail-Discussions-___Thread-module__tooltip`
};
export default ___CSS_LOADER_EXPORT___;
