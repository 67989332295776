/** @flow */
import React from 'react'

type HighlightedText = {|
  postfix: string,
  prefix: string,
  text: string,
|}
type Props = {
  style: Object,
}

export const toHighlightJSX = (
  textToHighLight: HighlightedText | React$Element<any> | string,
  textProps?: Props,
): React$Node => {
  if (React.isValidElement(textToHighLight)) {
    // $FlowFixMe[incompatible-return]
    return textToHighLight
  }
  if (typeof textToHighLight === 'string') {
    return <span {...textProps} dangerouslySetInnerHTML={{ __html: textToHighLight }} />
  }
  if (typeof textToHighLight === 'object') {
    const { postfix, prefix, text } = textToHighLight

    return (
      <span {...textProps}>
        {prefix}
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{text}</span>
        {postfix}
      </span>
    )
  }

  return null
}
