/** @flow */
import React from 'react'
import type { ElementProps } from 'app/core/types'

type Props = {|
  ...ElementProps,
|}

export function Container(props: Props): React$Node {
  const { children, style, ...rest } = props

  return (
    <div
      className="padding5 overflowXAuto overflowYAuto fullHeight fullWidth"
      style={{ maxWidth: '100%', maxHeight: '100%' }}
    >
      <div className="flex column" style={{ minHeight: '100%', ...style }} {...rest}>
        {children}
      </div>
    </div>
  )
}
