/** @flow */
import cx from 'classnames'
import React from 'react'
import keyCode from 'app/libs/keyCode'

import classesTooltip from './Tooltips.module.scss'
import classes from './TimelineResizer.module.scss'

export type TimelineResizerProps = {|
  setIsUnderEditing?: (boolean) => void,
  onMouseDown?: (e: SyntheticMouseEvent<any>) => void,
  onResize: (diff: number) => void,
  onStopResize: (diff: number) => void,
  className?: string,
  style?: Object,
  position: 'left' | 'right' | 'earliest' | 'latest',
  rangeDimensions: { current: { originX: number, vector: number } },
  resetRangeDimensions: () => void,
  tooltip?: React$Node,
  disabled?: boolean,
  fixFirstRow?: boolean,
  fixLastRow?: boolean,
|}

export function TimelineResizer(props: TimelineResizerProps): React$Node {
  const {
    onMouseDown,
    onResize,
    onStopResize,
    className,
    style,
    position,
    setIsUnderEditing,
    tooltip,
    rangeDimensions,
    resetRangeDimensions,
    disabled,
    fixFirstRow,
    fixLastRow,
  } = props

  function resize(e: MouseEvent) {
    const { originX } = rangeDimensions.current
    const vector = e.clientX - originX

    if (vector === 0) return

    rangeDimensions.current.vector = vector
    onResize(vector)
  }

  function stopResize(e: MouseEvent) {
    window.removeEventListener('mousemove', resize)
    window.removeEventListener('mouseup', stopResize)

    setIsUnderEditing?.(false)

    const { vector } = rangeDimensions.current
    if (vector === 0) return

    onStopResize(vector)
  }

  function cancel(e: Object) {
    if (e.keyCode === keyCode.ESCAPE) {
      setIsUnderEditing?.(false)

      resetRangeDimensions()
      onResize(0)
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('mouseup', stopResize)
      window.removeEventListener('keydown', cancel)
    }
  }

  function _onMouseDown(e: SyntheticMouseEvent<any>) {
    e.stopPropagation()

    if (disabled) return

    if (e.button !== 0) return

    setIsUnderEditing?.(true)

    resetRangeDimensions()
    rangeDimensions.current.originX = e.clientX

    onMouseDown?.(e)

    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResize)
    window.addEventListener('keydown', cancel)
  }

  return (
    <div className={classes.container}>
      <span
        className={cx(classesTooltip.tooltip, {
          [classesTooltip.fixFirstRow]: fixFirstRow && position === 'left',
          [classesTooltip.fixLastRow]: fixLastRow && position === 'right',
        })}
        position={position === 'left' ? 'top' : 'bottom'}
      >
        {tooltip}
      </span>
      <div
        className={cx(classes.editionContainer, className, {
          [classes.disabled]: disabled,
        })}
        style={style}
        onMouseDown={disabled ? undefined : _onMouseDown}
        position={position}
        tabIndex="0"
      >
        <div className={classes.button} />
      </div>
    </div>
  )
}
