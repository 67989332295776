/** @flow */
import type { Schema } from 'app/core/types'
import type { SchemaColumn, SchemaGroup } from 'app/core/types/Schema'
import { Node, NODE_DIRECTORY, NODE_ITEM } from '../TreeDnD/Node'
import type { NodeConstructor } from '../TreeDnD/Node'

export type SchemaToTreeOptions<I, GS, CS> = {|
  setNode?: <Data = SchemaGroup<I, GS, CS> | SchemaColumn<I, CS>>(
    item: SchemaGroup<I, GS, CS> | SchemaColumn<I, CS>,
    path: string[],
  ) => $Shape<NodeConstructor<Data>>,
|}

export function schemaToTree<I, GS, CS>(
  schema: Schema<I, GS, CS>,
  options?: SchemaToTreeOptions<I, GS, CS>,
): Node<Schema<I, GS, CS>> {
  const { setNode } = options || {}

  const schemaNode = new Node<Schema<I, GS, CS>>({
    key: schema.id,
    name: schema.name,
    parent: undefined,
    children: schema.schema.map((schemaGroup) => {
      const customGroupConstructor: $Shape<NodeConstructor<SchemaGroup<I, GS, CS>>> =
        setNode?.(schemaGroup, [schemaGroup.key]) || {}

      const groupNode = new Node<SchemaGroup<I, GS, CS>>({
        key: schemaGroup.key,
        name: schemaGroup.name,
        parent: schemaNode,
        data: { ...schemaGroup, items: [] },
        icon: 'fas-folder',
        color: schemaGroup.settings?.color,
        type: NODE_DIRECTORY,
        ...customGroupConstructor,
        children: schemaGroup.items.map((schemaColumn) => {
          const customColumnConstructor: $Shape<NodeConstructor<SchemaColumn<I, CS>>> =
            setNode?.(schemaColumn, [schemaGroup.key, schemaColumn.key]) || {}

          const columnNode = new Node<SchemaColumn<I, CS>>({
            key: schemaColumn.key,
            name: schemaColumn.name,
            parent: groupNode,
            data: schemaColumn,
            color: schemaColumn.settings?.color,
            type: NODE_ITEM,
            ...customColumnConstructor,
          })
          return columnNode
        }),
      })

      return groupNode
    }),
    type: NODE_DIRECTORY,
    data: { ...schema, schema: [] },
  })

  return schemaNode
}

export function treeToSchema<I, GS, CS>(rootNode: Node<Schema<I, GS, CS>>): Schema<I, GS, CS> {
  const schema = rootNode.getData()
  rootNode.children.forEach((schemaGroupNode) => {
    const schemaGroup = schemaGroupNode.getData<SchemaGroup<I, GS, CS>>()
    schemaGroup.items = schemaGroupNode.children.map((schemaColumnNode) => {
      if (schemaColumnNode?.data?.data?.category?.personAttributes) {
        delete schemaColumnNode.data.data.category.personAttributes
      }
      if (schemaColumnNode?.data?.data?.category?.personAttributesInst) {
        delete schemaColumnNode.data.data.category.personAttributesInst
      }
      return schemaColumnNode.getData<SchemaColumn<I, CS>>()
    })
    schema.schema.push(schemaGroup)
  })

  return schema
}
