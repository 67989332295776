/** @flow */
import React, { useState } from 'react'
import { map, sortBy } from 'lodash'
import type { Topic, Asset, Pipe, ID } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import { getResources } from 'app/store/selectors'
import resources from 'app/store/resources'
import Widget from 'app/components/Widget/Widget.jsx'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import { TopicItem } from './Topic.jsx'
import { ModalCreateDiscussions } from './ModalCreateDiscussions.tsx'

import classes from './Discussions.module.scss'

type Props = {|
  router: Object,
  topics: Array<Topic>,
  asset: Asset,
  getWidth: (width: number) => void,
  loading?: boolean,
  projectId: ID,
|}

function Topics(props: Props) {
  const { router, topics, asset, getWidth, loading, projectId } = props
  const { Link } = router
  const [width, _setWidth] = useState(300)
  const [isOpen, setIsOpen] = useState(true)

  const setWidth = (width: number) => {
    _setWidth(width)
    getWidth(width)
  }

  return (
    <div className="fullHeight" style={{ width, transition: 'width 300ms' }}>
      <Widget
        styleInner={{ border: 'none', borderRight: '1px solid rgba(0,0,0,0.1)' }}
        styleContent={{ padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
        style={{ height: 'inherit', width: '100%' }}
        enableHideColumn={true}
        noOverlay={true}
        minWidth={40}
        maxWidth={300}
        optionName="discussionTopics"
        onOpenChange={(_isOpen) => {
          if (_isOpen) setWidth(300)
          else setWidth(40)
          setIsOpen(_isOpen)
        }}
      >
        <div className="flex column fullHeight ">
          <ModalCreateDiscussions type="topics" relationId={asset.id} projectId={projectId} isOpen={isOpen} />
          <div style={{ height: 'calc(100% - 40px)', overflowY: 'auto', overflowX: 'hidden' }}>
            {loading ? (
              <div className="flex flex1 center alignCenter fullHeight">
                <LoaderSmall style={{ width: 30, height: 30 }} />
              </div>
            ) : null}
            <div className={`flex column ${classes.itemContainer}`}>
              {topics.map((topic, index) => (
                <Link key={topic.id} name="topic" params={{ topicId: topic.id }} activeClassName={classes.linkActive}>
                  <TopicItem index={index} topic={topic} />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Widget>
    </div>
  )
}

const pipeInst: Pipe<{ asset: Asset, projectId: ID }, typeof Topics> = pipe()

const TopicsComponent: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { asset, projectId } = props

    return {
      projectId,
      topics: sortBy(map(getResources(state, 'topics', { asset: asset.id })), (topic) => topic.name.toLowerCase()),
    }
  })
  .request((props) => {
    const { projectId } = props
    const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } } }

    return resources.topics.fetchAllByAsset(props.asset.id, requestsConfig)
  })
  .renderLoader((props) => <Topics loading={true} {...props} />)
  .render(Topics)

export default TopicsComponent
