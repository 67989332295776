// @flow
import * as React from 'react'
import { map } from 'lodash'
import { FormData } from 'app/components/Form'
import api from 'app/core/api/api.js'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import { ModalConfirmForm } from 'app/components/Modal'
import resources from 'app/store/resources'
import { oldNotif } from 'app/components/Notifications/notify'
import { type ID } from 'app/core/types/ID'
import { type Asset } from 'app/core/types/Asset'
import { getResources } from 'app/store/selectors'

type ReplaceFormat = {|
  data: Asset,
  label: string,
  labelCustom: string,
  value: ID,
|}

type Props = {|
  parentAssetId: ID,
  type: 'replace' | 'add' | 'delete',
  onChange?: () => void,
  additionnalQueries?: Object,
|}

type SearchReplaceFormat = {|
  search: ReplaceFormat,
  ['replace' | 'add' | 'delete']: ReplaceFormat,
|}

const variant = {
  replace: {
    route: 'searchAndReplaceBreakdown',
    resultObject: 'replaced',
    word: 'Replace',
  },
  add: {
    route: 'searchAndAddBreakdown',
    resultObject: 'added',
    word: 'Add',
  },
  delete: {
    route: 'searchAndDeleteBreakdown',
    resultObject: 'deleted',
    word: 'Delete',
  },
}

export class ModalSearchAndReplace extends React.PureComponent<Props> {
  searchValidate: (data: SearchReplaceFormat) => any = (data: SearchReplaceFormat) => {
    const { parentAssetId, type, onChange } = this.props

    const params = {
      search: data.search.value,
      [String(type)]: data[type] && data[type].value,
      episode: parentAssetId,
    }

    return api[variant[type].route](params).then((res) => {
      if (res[variant[type].resultObject] > 0) {
        oldNotif({ success: `${res[variant[type].resultObject]} occurence(s) ${variant[type].resultObject}` })
      } else {
        oldNotif({ warning: `Warning no occurence(s) found` })
      }

      if (type === 'delete') {
        const linksToDelete = getResources(undefined, 'assetLinks', { to_asset: data.search.value })
        resources.assetLinks.delete(
          map(linksToDelete, ({ id }) => id),
          { batch: true, localOnly: true },
        )
      }
      onChange?.()
    })
  }

  render(): React$Node {
    const { parentAssetId, type, onChange, additionnalQueries, ...props } = this.props
    return (
      <ModalConfirmForm title={`${variant[type].word} item`} {...props}>
        <FormData
          properties={[
            {
              key: 'search',
              label: `Asset to ${type}`,
              type: 'autocomplete',
              value: { value: '', label: '' },
              element: AssetsSelect,
              elementProps: {
                isRequired: true,
                showAssetCateg: true,
              },
            },
            ...(type !== 'delete'
              ? [
                  {
                    key: type,
                    label: `Asset to ${type} by`,
                    type: 'autocomplete',
                    value: { value: '', label: '' },
                    element: AssetsSelect,
                    elementProps: {
                      isRequired: true,
                      showAssetCateg: true,
                    },
                  },
                ]
              : []),
          ].filter((_) => _)}
          onSave={this.searchValidate}
        />
      </ModalConfirmForm>
    )
  }
}
