/** @flow */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { map, sortBy, reduce, uniqBy, flatMap } from 'lodash'
import { Table } from 'app/components/Table/Table.jsx'
import type {
  ProjectDepartmentJob,
  ResourcesList,
  Imputation,
  ProjectDepartment,
  ProjectPlan,
  ID,
  Department,
  Job,
  Contract,
  Asset,
} from 'app/core/types'
import type { TableColumnHeader } from 'app/components/Table'
import { CellText, ColumnGanttTree, ColumnTimelineImputation } from 'app/components/Table/Cells'
import resources from 'app/store/resources'
import { getResources } from 'app/store/selectors'
import { openModal, confirmDelete } from 'app/components/Modal'
import { ModalEditProjectPlan } from 'app/containers/Modals/ProjectPlans/ModelEditProjectPlan.jsx'
import { error } from 'app/components/Notifications/notify'
import {
  ModalEditProjectDepartment,
  type ModalEditProjectDepartmentProps,
} from 'app/containers/Modals/Departments/ModalEditProjectDepartment.jsx'
import {
  ModalEditProjectDepartmentJob,
  type ModalEditProjectDepartmentJobProps,
} from 'app/containers/Modals/Jobs/ModalEditProjectDepartmentJob.jsx'
import {
  ModalEditImputations,
  type ModalEditImputationsProps,
} from 'app/containers/Modals/Imputations/ModalEditImputations.jsx'
import { permission } from 'app/containers/Permissions'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import { MUIAutocompleteSelect } from 'app/components/Form/index.js'
import { contractTypesSelector } from 'app/store/selectors/serverConfig.ts'
import { HeaderSVG } from 'app/components/Table/Cells/ColumnTimelineImputation/HeaderSVG'
import { useSelector } from 'react-redux'
import {
  type RowData,
  formatRowProject,
  formatRowProjectDepartment,
  formatRowProjectDepartmentJobs,
  formatRowImputation,
} from './tableGanttUtils'
import { useProgress } from '../../../hooks/useProgress'
import type { Data } from '../../../components/Table/hooks/useData'
import { getContractTypesOptions } from '../../../containers/Modals/Contracts/ModalEditContract/utils'

export const tableId = 'table-imputations'

type Props = {||}

type FormatedData = {|
  [projectPlanId: ID]: {
    ...RowData<ProjectPlan, 'projectPlans'>,
    __resource: 'projectPlans',
    subRows: {|
      [departmentId: ID]: {
        ...RowData<ProjectDepartment, 'projectDepartments'>,
        __resource: 'projectDepartments',
        subRows: {|
          [projectDepartmentJobId: ID]: {
            ...RowData<ProjectDepartmentJob, 'projectDepartmentJobs'>,
            __resource: 'projectDepartmentJobs',
            subRows: {|
              [imputationsId: ID]: {
                ...RowData<Imputation, 'imputations'>,
                __resource: 'imputations',
                includedResources: Array<string>,
              },
            |},
          },
        |},
      },
    |},
  },
|}

export function TableTimeline(props: Props): React$Node {
  const [projectPlans, setProjectPlans] = useState<ResourcesList<ProjectPlan>>({})
  const [departments, setDepartments] = useState<ResourcesList<Department>>({})
  const [projectDepartments, setProjectDepartments] = useState<ResourcesList<ProjectDepartment>>({})
  const [offices, setOffices] = useState<ResourcesList<Asset>>({})
  const [jobs, setJobs] = useState<ResourcesList<Job>>({})
  const [dataLoaded, setDataLoaded] = useState(false)
  const [filterUncoveredJobs, setFilterUncoveredJobs] = useState(false)
  const [showRowImputations, setShowRowImputations] = useState(false)

  const [specificProject, setSpecificProject] = useState([{ label: 'All projects', value: 'all' }])
  const [specificDepartment, setSpecificDepartment] = useState([{ label: 'All departments', value: 'all' }])
  const [specificJobs, setSpecificJobs] = useState([{ label: 'All jobs', value: 'all' }])
  const [specificPeoples, setSpecificPeoples] = useState([{ label: 'All people', value: 'all' }])
  const [specificContractTypes, setSpecificContractTypes] = useState([{ label: 'All contract types', value: 'all' }])

  const contractTypes = useSelector(contractTypesSelector)
  const contractTypesOptions = getContractTypesOptions(contractTypes)

  const isMount = useRef(true)

  useEffect(() => {
    return () => {
      isMount.current = false
    }
  }, [])

  const readOnly = useMemo(() => !permission(['human resources_organization_imputations_edit']), [])

  const { initProgresses, updateProgress, progress } = useProgress()

  useEffect(() => {
    initProgresses()

    const config = (params, queries) => ({ params: { ...params, queries: { page_size: 1000, ...queries } } })
    const params = (progressKey: string) => ({
      getHttpProgress: (httpProgress) => updateProgress(progressKey, httpProgress),
    })

    Promise.all([
      resources.projectPlans.fetchAll(config(params('projectPlans'))),
      resources.projectDepartments.fetchAll(config(params('projectDepartments'))),
      resources.departments.fetchAll(config(params('departments'))),
      resources.jobs.fetchAll(config(params('jobs'))),
      resources.people.fetchAll(config(params('people'))),
      resources.assets.fetchAll({ params: { queries: { page_size: 1000, assetType: 'of' } } }),
    ]).then(() => {
      if (isMount.current) {
        setProjectDepartments(
          getResources(undefined, 'projectDepartments', undefined, ['projectInst', 'departmentInst']),
        )
        setDepartments(getResources(undefined, 'departments'))
        setOffices(getResources(undefined, 'assets', { assetType: 'of' }))
        setJobs(getResources(undefined, 'jobs'))
        setProjectPlans(getResources(undefined, 'projectPlans', undefined, ['projectInst']))
        setDataLoaded(true)
        initProgresses()
      }
    })
  }, [])

  useEffect(() => {
    documentTitle('Imputations table', 'page')
  }, [])

  function getFilteredResource() {
    let specificProjectIds = specificProject.map((pp) => pp.value)
    let filteredProjectPlan = projectPlans

    if (specificProjectIds.includes('all')) {
      specificProjectIds = null
    } else {
      filteredProjectPlan = reduce(
        projectPlans,
        (acc: ResourcesList<ProjectPlan>, pp) => (specificProjectIds?.includes(pp.id) ? { ...acc, [pp.id]: pp } : acc),
        {},
      )
    }

    let specificDepartmentIds = specificDepartment.map((d) => d.value)
    let filteredProjectDepartments = projectDepartments

    if (specificDepartmentIds.includes('all')) {
      specificDepartmentIds = null
    } else {
      filteredProjectDepartments = reduce(
        projectDepartments,
        (acc: ResourcesList<ProjectDepartment>, d) =>
          specificDepartmentIds?.includes(d.department) ? { ...acc, [d.id]: d } : acc,
        {},
      )
    }

    const specificJobsIds = specificJobs.map((j) => j.value).includes('all') ? null : specificJobs.map((j) => j.value)
    const specificPeoplesIds = specificPeoples.map((p) => p.value).includes('all')
      ? null
      : specificPeoples.map((p) => p.value)

    const specificContractTypesIds = specificContractTypes.map((c) => c.value).includes('all')
      ? null
      : specificContractTypes.map((c) => c.value)

    const isFiltered = Boolean(
      specificProjectIds || specificDepartmentIds || specificJobsIds || specificPeoplesIds || specificContractTypesIds,
    )

    function removeEmptyRows(rows: Array<RowData<any, string>>) {
      return rows
        .map((row) => {
          if (!row.subRows?.length) return row
          const subRows = removeEmptyRows(row.subRows)
          if (!subRows || !subRows.length) return null
          return { ...row, subRows }
        })
        .filter((_) => _)
    }

    return {
      specificProjectIds,
      filteredProjectPlan,
      specificDepartmentIds,
      filteredProjectDepartments,
      specificJobsIds,
      specificPeoplesIds,
      specificContractTypesIds,
      isFiltered,
      removeEmptyRows,
    }
  }

  function formatData(_projectDepartmentJobs: Array<ProjectDepartmentJob>) {
    const formatedData: FormatedData = {}
    const projectDepartmentJobs = sortBy(_projectDepartmentJobs, ['rank'])

    const {
      specificProjectIds,
      filteredProjectPlan,
      specificDepartmentIds,
      filteredProjectDepartments,
      specificJobsIds,
      specificPeoplesIds,
      specificContractTypesIds,
      isFiltered,
      removeEmptyRows,
    } = getFilteredResource()

    const projectsPlans: Array<ProjectPlan> = sortBy(filteredProjectPlan, ['projectInst.name'])

    // Fill projectPlan
    projectsPlans.forEach((projectPlanInst) => {
      if (!formatedData[projectPlanInst.id]) {
        // $FlowFixMe
        formatedData[projectPlanInst.id] = formatRowProject(projectPlanInst, {}, true)
      }
    })

    /* ************************
     * Fill projectDepartment *
     ************************ */
    const orderedProjectDepartments: Array<ProjectDepartment> = sortBy(filteredProjectDepartments, ['rank'])

    orderedProjectDepartments
      .filter((projectDepartment) => {
        const { projectPlan, department } = projectDepartment
        const projectPlanInst = filteredProjectPlan[projectPlan]
        const departmentInst = departments[department]
        if (!projectPlanInst) return false
        if (specificProjectIds && !specificProjectIds.includes(projectPlanInst.id)) return false
        if (specificDepartmentIds && !specificDepartmentIds.includes(departmentInst.id)) return false
        if (!departmentInst) return false
        return true
      })
      .forEach((projectDepartment) => {
        const { projectPlan, department } = projectDepartment
        const projectPlanInst = filteredProjectPlan[projectPlan]
        const departmentInst = departments[department]

        if (!formatedData[projectPlanInst.id].subRows[projectDepartment.id]) {
          // $FlowFixMe
          formatedData[projectPlanInst.id].subRows[projectDepartment.id] = formatRowProjectDepartment(
            { ...departmentInst, ...projectDepartment },
            {},
            true,
          )
        }
      })

    projectDepartmentJobs
      .filter((projectDepartmentJobInst) => {
        const { projectDepartmentInst, job } = projectDepartmentJobInst
        const { projectPlan, departmentInst } = projectDepartmentInst
        const projectPlanInst = filteredProjectPlan[projectPlan]

        if (!projectPlanInst) return false
        if (specificProjectIds && !specificProjectIds.includes(projectPlanInst.id)) return false
        if (specificDepartmentIds && !specificDepartmentIds.includes(departmentInst.id)) return false
        if (specificJobsIds && !specificJobsIds.includes(job)) return false
        return true
      })
      .forEach((projectDepartmentJobInst) => {
        const { projectDepartmentInst, imputationsInst } = projectDepartmentJobInst
        const { projectPlan } = projectDepartmentInst
        const projectPlanInst = filteredProjectPlan[projectPlan]

        /* **************************************
         * Fill projectDepartment if not setted *
         ************************************** */
        if (!formatedData[projectPlanInst.id].subRows[projectDepartmentInst.id]) {
          const departmentInst = departments[projectDepartmentInst.department]
          // $FlowFixMe
          formatedData[projectPlanInst.id].subRows[projectDepartmentInst.id] = formatRowProjectDepartment(
            { ...departmentInst, ...projectDepartmentInst },
            {},
            true,
          )
        }
        /* ****************************
         * Fill projectDepartmentJobs *
         **************************** */
        if (!formatedData[projectPlanInst.id].subRows[projectDepartmentInst.id].subRows[projectDepartmentJobInst.id]) {
          formatedData[projectPlanInst.id].subRows[projectDepartmentInst.id].subRows[
            projectDepartmentJobInst.id
            // $FlowFixMe
          ] = formatRowProjectDepartmentJobs(projectDepartmentJobInst, {}, readOnly)
        }

        /* ******************
         * Fill imputations *
         ****************** */
        sortBy(imputationsInst, ['startDate', 'endDate'])
          .filter((imputationInst) => {
            const { contractInst } = imputationInst
            const { person, contractType } = contractInst || {}
            if (specificPeoplesIds && !specificPeoplesIds.includes(person)) return false
            if (specificContractTypesIds) {
              if (!contractInst && specificContractTypesIds.includes('candidat')) return true
              if (contractInst && specificContractTypesIds.includes(contractType)) return true
              return false
            }
            return true
          })
          .forEach((imputationInst) => {
            const { id } = imputationInst

            // prettier-ignore
            if (!formatedData[projectPlanInst.id].subRows[projectDepartmentInst.id].subRows[projectDepartmentJobInst.id].subRows[id]) {
          const { firstName, lastName } = imputationInst.personInst || imputationInst.contractInst?.personInst || {}
          // $FlowFixMe
          formatedData[projectPlanInst.id].subRows[projectDepartmentInst.id].subRows[projectDepartmentJobInst.id].subRows[id] = formatRowImputation({
            ...imputationInst,
            name: `${firstName} ${lastName}`,
          }, readOnly)
        }
          })
      })

    // $FlowFixMe
    const _formatedData: Array<RowData<Asset>> = map(formatedData, (depth0) => ({
      ...depth0,
      subRows: map(depth0.subRows, (depth1) => ({
        ...depth1,
        subRows: map(depth1.subRows, (depth2) => ({
          ...depth2,
          subRows: map(depth2.subRows),
        })),
      })),
    }))

    return isFiltered ? removeEmptyRows(_formatedData) : _formatedData
  }

  function onRowsLengthChange(instance) {
    const { updateTable } = instance
    updateTable()
  }

  function editProject(
    e: SyntheticMouseEvent<any>,
    instance: *,
    cell?: *,
    projectPlan?: ProjectPlan,
    type?: 'create' | 'delete',
  ) {
    const { updateOriginals } = instance
    const rowId: string = String(cell ? cell.row.id : 'root')

    openModal(
      <ModalEditProjectPlan
        projectPlan={projectPlan}
        onChange={(data) => {
          const newProjectPlan = formatRowProject({ ...cell?.row.original, ...data }, [], readOnly)
          updateOriginals?.({ [rowId]: newProjectPlan }, type)

          if (['create', 'delete'].includes(type)) onRowsLengthChange(instance)
        }}
      />,
    )
  }

  function editDepartment(
    e: SyntheticMouseEvent<any>,
    instance: *,
    cell?: *,
    rowId: string,
    projectDepartment?: ProjectDepartment,
    type?: 'create' | 'delete',
    modalParams?: $Shape<ModalEditProjectDepartmentProps> = {},
  ) {
    const { updateOriginals } = instance

    openModal(
      <ModalEditProjectDepartment
        projectDepartment={projectDepartment}
        onChange={(data) => {
          const newProjectDepartment = formatRowProjectDepartment(
            { ...(cell?.row?.original || {}), ...projectDepartment, ...data },
            [],
            readOnly,
          )

          updateOriginals?.({ [rowId]: newProjectDepartment }, type)
          if (['create', 'delete'].includes(type)) onRowsLengthChange(instance)
        }}
        {...modalParams}
      />,
    )
  }

  function editProjectDepatmentJob(
    e: SyntheticMouseEvent<any>,
    instance: *,
    cell?: *,
    rowId: string,
    projectDepartmentJob?: ProjectDepartmentJob,
    type?: 'create' | 'delete',
    modalParams?: $Shape<ModalEditProjectDepartmentJobProps> = {},
  ) {
    const { updateOriginals } = instance
    openModal(
      <ModalEditProjectDepartmentJob
        projectDepartmentJob={projectDepartmentJob}
        onChange={(data) => {
          const newProjectDepartmentJob = formatRowProjectDepartmentJobs(
            { ...(cell?.row?.original || {}), ...projectDepartmentJob, ...data },
            [],
            readOnly,
          )
          updateOriginals?.({ [rowId]: newProjectDepartmentJob }, type)
          if (['create', 'delete'].includes(type)) onRowsLengthChange(instance)
        }}
        {...modalParams}
      />,
    )
  }

  function getContractsByOffices(
    data: Array<Data>,
    onlyKeys?: boolean,
  ): {
    [officeName: string]: {
      [timeline: 'day' | 'week' | 'month' | 'quarter' | 'year']: { [dayNumber: string]: Array<Contract> },
    },
  } {
    const contractNumberByOffice = {}

    function getContractsFromData(data: Array<RowData<any, string>>): Array<Contract> {
      const contracts: Array<Contract> = flatMap(data, (row) => {
        if (row.__resource === 'imputations') return row.contractInst
        if (row.subRows?.length) return getContractsFromData(row.subRows)
        // $FlowFixMe
        return null
      }).filter((_) => _)

      return contracts
    }

    const contracts: Array<Contract> = uniqBy(getContractsFromData(data), 'id')

    contracts.forEach((contract) => {
      const { startDate, endDate, office } = contract
      const acc = moment(startDate, 'YYYY-MM-DD')
      const end = moment(endDate, 'YYYY-MM-DD')

      const officeName = office ? offices[office].name : 'no office'

      if (!contractNumberByOffice[officeName]) {
        contractNumberByOffice[officeName] = {
          day: {},
          week: {},
          month: {},
          quarter: {},
          year: {},
        }
      }

      let currentWeek
      let currentMonth
      let currentQuarter

      while (acc.isSameOrBefore(end)) {
        const yearNumber = acc.year()
        const dayNumber = `${acc.dayOfYear()}-${yearNumber}`
        const weekNumber = `${acc.isoWeek()}-${yearNumber}`
        const monthNumber = `${acc.month()}-${yearNumber}`
        const quarterNumber = `${acc.quarter()}-${yearNumber}`

        if (!contractNumberByOffice[officeName].day[dayNumber])
          contractNumberByOffice[officeName].day[dayNumber] = [contract]
        else contractNumberByOffice[officeName].day[dayNumber].push(contract)

        if (!currentWeek || weekNumber !== currentWeek) {
          if (!contractNumberByOffice[officeName].week[weekNumber])
            contractNumberByOffice[officeName].week[weekNumber] = [contract]
          else contractNumberByOffice[officeName].week[weekNumber].push(contract)
          currentWeek = weekNumber
        }
        if (!currentQuarter || quarterNumber !== currentQuarter) {
          if (!contractNumberByOffice[officeName].quarter[quarterNumber])
            contractNumberByOffice[officeName].quarter[quarterNumber] = [contract]
          else contractNumberByOffice[officeName].quarter[quarterNumber].push(contract)
          currentQuarter = quarterNumber
        }
        if (!currentMonth || monthNumber !== currentMonth) {
          if (!contractNumberByOffice[officeName].month[monthNumber])
            contractNumberByOffice[officeName].month[monthNumber] = [contract]
          else contractNumberByOffice[officeName].month[monthNumber].push(contract)
          currentMonth = monthNumber
        }

        acc.add(1, 'day')
      }
    })

    const keys = sortBy(Object.keys(contractNumberByOffice))

    const newObject = {}

    keys.forEach((key) => {
      newObject[key] = contractNumberByOffice[key]
    })

    return newObject
  }

  function editImputation(
    e: SyntheticMouseEvent<any>,
    instance: *,
    cell: *,
    imputation?: Imputation,
    type?: 'create' | 'delete',
    modalParams?: $Shape<ModalEditImputationsProps> = {},
  ) {
    const { updateOriginals } = instance
    openModal(
      <ModalEditImputations
        imputation={imputation}
        onChange={(data) => {
          const { personInst } = data
          const { firstName, lastName } = personInst || {}
          const newImputation = formatRowImputation(
            { ...imputation, ...data, name: `${firstName} ${lastName}` },
            readOnly,
          )
          updateOriginals?.({ [cell.row.id]: newImputation }, type)
          if (['create', 'delete'].includes(type)) onRowsLengthChange(instance)
        }}
        {...modalParams}
      />,
    )
  }

  function contractShouldBeUpdated(cell, value, instance, forceLock) {
    const { row } = cell
    const { original } = row

    if (original.__resource === 'imputations') {
      const cellState = instance.state.ganttCellsState?.[cell.id] || {}
      const { lockedWithContract } = cellState
      const { contractInst } = original

      if (!contractInst) return false

      const { contractStatus, startDate, endDate } = contractInst || {}

      if (
        (startDate !== value.startDate || endDate !== value.endDate) &&
        !['signing', 'signed'].includes(contractStatus) &&
        (forceLock || lockedWithContract)
      ) {
        return true
      }
    }
    return false
  }

  function lockImputationWithContract(cell, instance) {
    const { state, setCellGanttState, getSelectedTimelineCells, resetGanttCell } = instance
    const { contractInst } = cell.row.original

    if (!contractInst) return Promise.resolve()

    const { contractStatus } = contractInst || {}
    const cellState = state.ganttCellsState?.[cell.id] || {}
    const { lockedWithContract } = cellState
    const cells = getSelectedTimelineCells?.()

    if (!lockedWithContract && ['signing', 'signed'].includes(contractStatus)) {
      error("You can't update a signing/signed contract.")
      return Promise.reject()
    }
    if (!cells) {
      error("Isn't a gantt, getSelectedTimelineCells not initialized.")
      return Promise.reject()
    }

    const promises = []

    cells.forEach((_cell) => {
      const { startDate, endDate } = _cell.value
      const { edition } = _cell.getCellProps()
      setCellGanttState?.(_cell.id, { lockedWithContract: !lockedWithContract })

      if (contractShouldBeUpdated(_cell, _cell.value, instance, true)) {
        promises.push(
          resources.contracts.update({ id: _cell.row.original.contract, startDate, endDate }).then(() => {
            return edition.saveTargetOnly(
              { ..._cell.row.original, startDate, endDate },
              {
                catch: () => {
                  resources.contracts
                    .update({
                      id: _cell.row.original.contract,
                      startDate: _cell.row.original.startDate,
                      endDate: _cell.row.original.endDate,
                    })
                    .then(() => resetGanttCell?.(cell))
                },
              },
            )
          }),
        )
      } else {
        promises.push(
          edition.save(
            { ..._cell.row.original, startDate, endDate },
            {
              catch: () => resetGanttCell?.(cell),
            },
          ),
        )
      }
    })

    return Promise.all(promises)
  }

  function ganttActions(instance, cell, depth) {
    const { selectDescendentCells, updateTable, toggleChildrensExpanded } = instance
    const { name, __resource, id, contractInst } = cell.row.original
    const { ganttState } = cell.getCellProps()
    const { lockedWithContract } = ganttState || {}

    if (readOnly) return []

    const deleteAction = {
      label: 'Delete',
      editAction: true,
      rightLabel: 'Suppr',
      hotKeys: ['suppr'],
      onClick: (e) => {
        confirmDelete({
          render: `Are you sure you want to delete ${name} ?`,
          onValidate: () =>
            resources[__resource].delete(id).then(() => {
              const rowId = String(cell ? cell.row.id : 'root')
              instance.updateOriginals?.({ [rowId]: null }, 'delete')
              updateTable()
            }),
        })
      },
    }

    const selectDescendents = {
      label: 'Select descendants',
      hotKeys: ['cmd+a', 'ctrl+a'],
      rightLabel: 'Ctrl + A',
      editAction: false,
      onClick: () => selectDescendentCells?.(cell),
    }

    const expandChildrens = {
      label: 'Expand childrens',
      editAction: false,
      onClick: () => {
        toggleChildrensExpanded?.(cell.row)
      },
      disabled: !cell.row.subRows || cell.row.subRows.length === 0,
    }

    const actions = [
      // project
      [
        {
          label: 'Edit',
          editAction: true,
          rightLabel: 'Enter',
          hotKeys: ['enter'],
          onClick: (e) => {
            const { subRows, ...projectPlan } = cell.row.original
            editProject(e, instance, cell, projectPlan, undefined)
          },
        },
        deleteAction,
        'separator',
        {
          label: 'Add a department',
          editAction: true,
          onClick: (e) => {
            const { subRows, ...projectPlan } = cell.row.original
            editDepartment(e, instance, undefined, cell.row.id, undefined, 'create', {
              projectPlan,
              parentSubRows: subRows,
            })
          },
        },
        'separator',
        selectDescendents,
        'separator',
        expandChildrens,
      ],
      // projectDepartment
      [
        {
          label: 'Edit',
          editAction: true,
          rightLabel: 'Enter',
          hotKeys: ['enter'],
          onClick: (e) => {
            editDepartment(e, instance, cell, cell.row.id, cell.row.original)
          },
        },
        deleteAction,
        'separator',
        {
          label: 'Add a job',
          editAction: true,
          onClick: (e) => {
            const { subRows, ...projectDepartment } = cell.row.original
            editProjectDepatmentJob(e, instance, undefined, cell.row.id, undefined, 'create', {
              projectDepartment,
              parentSubRows: subRows,
            })
          },
        },
        'separator',
        selectDescendents,
        'separator',
        expandChildrens,
      ],
      // projectDepartmentJob
      [
        {
          label: 'Edit',
          editAction: true,
          rightLabel: 'Enter',
          hotKeys: ['enter'],
          onClick: (e) => {
            const { subRows, ...projectDepartmentJob } = cell.row.original
            editProjectDepatmentJob(e, instance, cell, cell.row.id, projectDepartmentJob, undefined, {
              parentSubRows: subRows,
            })
          },
        },
        deleteAction,
        'separator',
        {
          label: 'Add an imputation',
          editAction: true,
          onClick: (e) => {
            const { subRows, ...projectDepartmentJob } = cell.row.original
            editImputation(e, instance, cell, undefined, 'create', { projectDepartmentJob, parentSubRows: subRows })
          },
        },
        'separator',
        selectDescendents,
        'separator',
        expandChildrens,
      ],
      // imputation
      [
        {
          label: 'Edit',
          editAction: true,
          rightLabel: 'Enter',
          hotKeys: ['enter'],
          onClick: (e) => {
            editImputation(e, instance, cell, cell.row.original)
          },
        },
        deleteAction,
        'separator',
        {
          label: `${lockedWithContract ? 'Unlock' : 'Lock'} contract`,
          rightLabel: 'L',
          hotKeys: ['l'],
          editAction: true,
          disabled:
            (!lockedWithContract && ['signing', 'signed'].includes(contractInst?.contractStatus)) ||
            !cell.row.original.contractInst,
          onClick: () => lockImputationWithContract(cell, instance),
        },
      ],
    ]

    return actions[depth]
  }

  const columns: TableColumnHeader = useMemo(
    () => [
      ColumnGanttTree({
        isCustomPivot: true,
        Total: (instance) =>
          useMemo(() => {
            const { data } = instance
            const offices = data.length ? map(getContractsByOffices(data), (data, key) => key) : []
            return (
              <div
                className="flex column"
                style={{
                  width: '100%',
                  height: (offices?.length || 0) * 10,
                  backgroundColor: '#ffffff',
                }}
              >
                {offices.map((officeName: string) => {
                  return (
                    <div
                      key={officeName}
                      className="fullWidth fontSize9 textCenter"
                      style={{ height: 10, lineHeight: '10px' }}
                    >
                      {officeName}
                    </div>
                  )
                })}
              </div>
            )
          }, [instance.data]),
      }),
      {
        Header: 'Dates',
        id: 'dates',
        columns: [
          CellText({
            id: 'startDate',
            Header: 'Start date',
            readOnly,
            hiddenable: true,
            actions: () => ['edit', 'copy', 'past'],
            accessor: 'startDate',
            save: (original) => ({
              resource: original.__resource,
              formatData: (original, value, cell, instance, type) => {
                return {
                  id: original.id,
                  startDate: value,
                }
              },
            }),
            inputProps: {
              type: 'date',
            },
            width: 100,
            Total: (instance) =>
              useMemo(() => {
                const offices = instance.data?.length
                  ? map(getContractsByOffices(instance.data), (data, key) => key)
                  : []
                return <div style={{ height: (offices?.length || 0) * 10 }} />
              }, [instance?.data]),
          }),
          CellText({
            id: 'endDate',
            Header: 'End date',
            readOnly,
            hiddenable: true,
            actions: () => ['edit', 'copy', 'past'],
            accessor: 'endDate',
            save: (original) => ({
              resource: original.__resource,
              formatData: (original, value, cell, instance, type) => {
                return {
                  id: original.id,
                  endDate: value,
                }
              },
            }),
            inputProps: {
              type: 'date',
            },
            width: 100,
            Total: (instance) =>
              useMemo(() => {
                const offices = instance.data?.length
                  ? map(getContractsByOffices(instance.data), (data, key) => key)
                  : []
                return <div style={{ height: (offices?.length || 0) * 10 }} />
              }, [instance?.data]),
          }),
        ],
      },
      ColumnTimelineImputation({
        save: (_original, _value, _cell, _instance, _type) => {
          const saveParams = {
            resource: _original.__resource,
            formatData: undefined,
            saveData: undefined,
          }

          if (contractShouldBeUpdated(_cell, _value, _instance)) {
            saveParams.saveData = (original, objectValue, cell, instance, type) => {
              const { updateCells, resetGanttCell } = instance
              const { __resource, subRows, includedResources, ...value } = objectValue
              const { id, contract, startDate: originalStartDate, endDate: originalEndDate } = original
              const { startDate, endDate } = value

              return resources.contracts.update({ id: contract, startDate, endDate }).then((resContract) =>
                resources.imputations[id ? 'update' : 'create'](value).catch(() => {
                  const newContract = resContract.resources[0]
                  resources.contracts[contract ? 'update' : 'delete'](
                    contract
                      ? { id: contract, startDate: originalStartDate, endDate: originalEndDate }
                      : newContract.id,
                  ).then(() => {
                    updateCells({ [cell.id]: cell })
                    resetGanttCell?.(cell)
                  })
                }),
              )
            }
          } else {
            saveParams.formatData = (original, value, cell, instance, type) => {
              const { __resource, subRows, includedResources, ...newOriginal } = value
              return newOriginal
            }
          }

          return saveParams
        },
        Total: (instance) =>
          useMemo(() => {
            const { timelineUnit, data } = instance.getLastestInstance?.() || instance
            if (!timelineUnit) return null
            const { unit, unitFormat } = timelineUnit

            const contractNumberByOffice = data?.length ? getContractsByOffices(data) : []

            const offices = Object.keys(contractNumberByOffice)

            return (
              <div
                style={{
                  width: '100%',
                  height: Object.keys(contractNumberByOffice).length * 10,
                  position: 'relative',
                  backgroundColor: '#ffffff',
                }}
              >
                <HeaderSVG
                  instance={instance}
                  height={(offices?.length || 0) * 10}
                  unit={unit}
                  style={{ left: -2 }}
                  unitFormat={unitFormat}
                  fontSize={9}
                  dontDrawNow={unit !== 'day'}
                  formatLabel={(day) => {
                    return map(contractNumberByOffice, (contractNumber, officeName: string) => {
                      const byUnit = contractNumber[unit]
                      let accessor = ''
                      const yearNumber = day.year()
                      if (unit === 'day') accessor = `${day.dayOfYear()}-${yearNumber}`
                      if (unit === 'week') accessor = `${day.week()}-${yearNumber}`
                      if (unit === 'month') accessor = `${day.month()}-${yearNumber}`
                      if (unit === 'quarter') accessor = `${day.quarter()}-${yearNumber}`
                      return String((byUnit[accessor] || []).length)
                    })
                  }}
                />
              </div>
            )
          }, [instance?.data]),
      }),
    ],
    [offices],
  )

  function toggleButtons(instance) {
    return [
      ...(!readOnly
        ? [
            {
              key: 'addProjectPlan',
              label: 'Add a project',
              tooltip: 'Add a project',
              icon: 'fas-plus',
              onClick: (e) => {
                editProject(e, instance, undefined, undefined, 'create')
              },
            },
          ]
        : []),
      {
        key: 'filterUncoveredJobs',
        label: 'Filter uncovered jobs',
        icon: 'fas-filter',
        selected: filterUncoveredJobs,
        onClick: (e) => {
          setFilterUncoveredJobs(!filterUncoveredJobs)
        },
      },
      {
        key: 'showRowImputations',
        tooltip: 'Show row imputations',
        icon: 'fas-info',
        selected: Boolean(showRowImputations),
        onClick: (e) => {
          setShowRowImputations((showRowImputations) => {
            instance.setGanttState?.({ showRowImputations: !showRowImputations })
            return !showRowImputations
          })
        },
      },
    ]
  }

  function extendToolbar(instance) {
    function onSearchPeople(value: string) {
      return resources.people
        .search({ query: value }, { [window.OVM_PROJECT_HEADER]: '' })
        .then(({ plainResponse }) => {
          return plainResponse.results.map((person) => {
            const { fullName, id } = person
            return {
              label: fullName,
              value: id,
              data: person,
            }
          })
        })
    }

    function getOptions(list: ResourcesList<Object>, label: string) {
      return [{ label: `All ${label}`, value: 'all' }].concat(
        sortBy(
          map(list, (item) => ({ label: item.name, value: item.id })),
          ['label'],
        ),
      )
    }

    function getOnChange(onChange: Function, label: string) {
      return (values) => {
        const allOpt = values.find((opt) => opt.value === 'all')
        if (values.length === 0) return onChange([{ label: `All ${label}`, value: 'all' }])
        if (values[values.length - 1]?.value === 'all') return onChange([values[values.length - 1]])
        if (allOpt && values.length > 1) return onChange(values.filter((opt) => opt.value !== 'all'))
        return onChange(values)
      }
    }

    return (
      <div
        className="fullHeight flex row noWrap alignCenter marginRight10 marginTop5"
        style={{ position: 'relative', zIndex: 10, width: 1050 }}
      >
        <MUIAutocompleteSelect
          options={getOptions(projectPlans, 'projects')}
          label="Projects"
          value={specificProject}
          style={{ width: 200, marginRight: 10, position: 'absolute', top: -15, left: 0 }}
          onChange={getOnChange(setSpecificProject, 'projects')}
          multiple={true}
          titled={true}
        />
        <MUIAutocompleteSelect
          options={getOptions(departments, 'departments')}
          label="Departments"
          value={specificDepartment}
          style={{ width: 200, marginRight: 10, position: 'absolute', top: -15, left: 0 }}
          onChange={getOnChange(setSpecificDepartment, 'departments')}
          multiple={true}
          titled={true}
        />
        <MUIAutocompleteSelect
          options={getOptions(jobs, 'jobs')}
          label="Jobs"
          value={specificJobs}
          style={{ width: 200, marginRight: 10, position: 'absolute', top: -15, left: 0 }}
          onChange={getOnChange(setSpecificJobs, 'jobs')}
          multiple={true}
          titled={true}
        />
        <MUIAutocompleteSelect
          options={[{ label: 'All people', value: 'all' }]}
          onSearch={onSearchPeople}
          label="People"
          value={specificPeoples}
          style={{ width: 200, marginRight: 10, position: 'absolute', top: -15, left: 0 }}
          onChange={getOnChange(setSpecificPeoples, 'people')}
          multiple={true}
          titled={true}
        />
        <MUIAutocompleteSelect
          options={[
            { label: 'All contract types', value: 'all' },
            { label: 'Candidat', value: 'candidat' },
          ].concat(contractTypesOptions)}
          label="Contract types"
          value={specificContractTypes}
          style={{ width: 200, marginRight: 10, position: 'absolute', top: -15, left: 0 }}
          onChange={getOnChange(setSpecificContractTypes, 'contract types')}
          multiple={true}
          titled={true}
        />
      </div>
    )
  }

  const resourcesParams = useMemo(() => {
    if (!dataLoaded) return null
    return {
      resourceType: 'projectDepartmentJobs',
      requestName: 'fetchAllImputations',
      includedResources: [
        'projectDepartmentInst',
        'projectDepartmentInst.departmentInst',
        'jobInst',
        'imputationsInst',
        'imputationsInst.personInst',
        'imputationsInst.contractInst',
        'imputationsInst.contractInst.personInst',
        'imputationsInst.contractInst.secureDataInst',
      ],
      queries: {
        missingImputations: filterUncoveredJobs || undefined,
      },
    }
  }, [dataLoaded, filterUncoveredJobs])

  return (
    <div className="fullWidth fullHeight">
      <Table
        tableId={tableId}
        resourcesParams={resourcesParams}
        columns={columns}
        formatData={formatData}
        loadingProgress={progress}
        isGantt={true}
        showGantt={true}
        ganttActions={ganttActions}
        toggleButtons={toggleButtons}
        extendToolbar={extendToolbar}
        getRowHeight={(row, instance, estimatedRowHeight) => {
          if (row.isGrouped) return 50
          if (row.original?.__resource === 'projectPlans') return 40
          return estimatedRowHeight
        }}
        noPagination={true}
        defaultPrefs={{
          minLineHeight: 20,
        }}
      />
    </div>
  )
}
