// @flow
import type { StoreResourceDef } from 'app/core/types'

export const projectPlans: StoreResourceDef<> = {
  resourceType: 'projectPlans',
  relations: {
    projectInst: {
      type: 'hasOne',
      resourceType: 'assets',
    },
  },
}
