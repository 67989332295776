/** @flow */
import React from 'react'
import { map, isNumber } from 'lodash'
import { MUIButton } from 'app/components/Form'

import { ModalPie } from './ModalPie.jsx'
import { mountModal } from '../../TableView/Elements/ModalEdition.jsx'

type Props = {|
  values: Array<{ [key: string]: mixed }>,
  referenceName: string,
  formatValue?: (value: any) => React$Node,
  radialLabel?: (values: Object) => React$Node,
  formatLabel?: Function,
  style?: Object,
  tableId: ?string,
  disablePie?: boolean,
|}

export function MakeTableFromArray(props: Props): React$Node {
  const { values, referenceName, formatValue, formatLabel, radialLabel, style, tableId, disablePie } = props
  let count = false

  if (values.length === 0) return null

  if (values[0] && isNumber(values[0].count)) count = true

  function showPieModal() {
    if (count === false) return null
    return (
      <div className="marginBottom5 flex end" style={{ maxWidth: '100%', height: 30 }}>
        <MUIButton
          onClick={() =>
            mountModal(ModalPie, tableId, {
              /* $FlowFixMe[incompatible-call] $FlowFixMe Error when updating
               * flow */
              data: map(values, (value: Object, index: string) => {
                // $FlowFixMe
                if (value[referenceName] === null) return undefined
                return {
                  value: value.count,
                  label: String(value[referenceName]),
                  id: String(value[referenceName]),
                }
              }).filter((_) => _),
              radialLabel: radialLabel || (formatValue ? (vals) => formatValue(vals.label) : undefined),
              formatLabel,
              formatValue,
            })
          }
          bgColor="#ffffff"
          style={{ color: '#000000' }}
          icon="fas-chart-pie"
          dataCy="table-open-pie"
        />
      </div>
    )
  }

  const onClickArray = values.map((val) => val.onClick)
  values.forEach((val) => delete val.onClick)

  return (
    <div style={style || { height: '100%' }}>
      {!disablePie ? showPieModal() : null}
      <div
        className="fullHeight overflowYAuto relative"
        style={{ height: 'calc(100% - 30px)', border: '1px solid lightgrey' }}
      >
        <div className={`grid col${Object.keys(values[0]).length}`} style={{ gridRowGap: 0, gridColumnGap: 2 }}>
          {Object.keys(values[0]).map((name, index) => (
            <div
              key={`${name}-${String(index)}`}
              className="textCenter sticky textNoWrap"
              style={{ backgroundColor: 'lightgrey', top: 0, padding: '1px 5px' }}
            >
              {name}
            </div>
          ))}
          {values.map((value, index: number) => {
            if (value[referenceName] === null) {
              count = false
              return null
            }
            return map(value, (val, attr: string) => (
              <div
                key={`${String(val)}-${String(attr)}`}
                className={onClickArray[index] && 'pointer'}
                style={{ padding: '1px 2px', whiteSpace: 'nowrap' }}
                onClick={onClickArray[index]}
              >
                {attr === referenceName
                  ? (formatLabel && formatLabel(val)) || (formatValue && formatValue(val)) || String(val)
                  : String(val)}
              </div>
            ))
          })}
        </div>
      </div>
    </div>
  )
}
