/** @flow */

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import history from 'app/main/routerHistory'
import settings from '../settings'

const {
  env: { NODE_ENV },
  sentry: { SENTRY_DSN, SENTRY_PROJECT, SENTRY_ORG, SENTRY_URL },
  git: { CI_COMMIT_TAG, CI_COMMIT_REF_NAME },
} = settings

export const initSentry = () => {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      environment: NODE_ENV,
      branch: CI_COMMIT_REF_NAME,
      org: SENTRY_ORG,
      project: SENTRY_PROJECT,
      dist: CI_COMMIT_TAG,
      release: CI_COMMIT_TAG,
      url: SENTRY_URL,
      tracesSampleRate: 1.0,
    })
  }
}
