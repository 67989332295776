// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Button-___ButtonList-module__buttonList {
  display: flex;
  align-items: center;
}

.app-components-Form-Button-___ButtonList-module__buttonList > *:not(:first-child) {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Button/ButtonList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".buttonList {\n  display: flex;\n  align-items: center;\n}\n\n.buttonList > *:not(:first-child) {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonList": `app-components-Form-Button-___ButtonList-module__buttonList`
};
export default ___CSS_LOADER_EXPORT___;
