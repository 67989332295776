// @flow
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import isPlainObject from 'lodash/isPlainObject'

const cleanObject = (obj: Object) => {
  return pickBy(obj, (v) => v !== undefined)
}

const replaceKeyObject = (prevKey: string, nextKey: string) => (obj) => {
  if (!isPlainObject(obj)) return obj

  return cleanObject({
    ...mapValues(obj, (value, key) => {
      if (isPlainObject(value)) {
        return replaceKeyObject(prevKey, nextKey)(value)
      }
      if (Array.isArray(value)) {
        return value.map(replaceKeyObject(prevKey, nextKey))
      }

      return value
    }),
    [nextKey]: obj[prevKey],
    [prevKey]: undefined,
  })
}

export const replacekey =
  (prevKey: string, nextKey: string): Function =>
  (data: Object | Array<any>) => {
    return Array.isArray(data)
      ? // $FlowFixMe
        data.map(replaceKeyObject(prevKey, nextKey))
      : replaceKeyObject(prevKey, nextKey)(data)
  }

export const idToUuid = (data: Object): Array<string> => replacekey('id', 'uuid')(replacekey('ids', 'uuids')(data))
export const uuidToId = (data: Object): Array<string> => replacekey('uuid', 'id')(replacekey('uuids', 'ids')(data))
