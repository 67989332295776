import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon.jsx'

export default class Warning extends React.PureComponent {
  static propTypes = {
    fillWarning: PropTypes.string,
  }

  static defaultProps = {
    width: 24,
    height: 19,
    fill: 'mainColor',
    fillHover: 'mainColorMedium',
  }

  render() {
    return (
      <Icon viewBox="0 0 24 19" {...this.props}>
        <g>
          <g id="PICTOS" transform="translate(-646.000000, -145.000000)">
            <path d="M657.737818,145 L646,163.718519 L669.476727,163.718519 L657.737818,145 Z M656.742182,152.076796 C656.742182,151.544444 657.188,151.113074 657.737818,151.113074 C658.287636,151.113074 658.733455,151.544444 658.733455,152.076796 L658.733455,157.822889 C658.733455,158.355593 658.287273,158.786259 657.737818,158.786259 C657.188,158.786259 656.742182,158.355241 656.742182,157.822889 L656.742182,152.076796 Z M657.737818,161.814648 C657.401818,161.814648 657.073455,161.681648 656.836727,161.452593 C656.6,161.223889 656.465091,160.905463 656.465091,160.583167 C656.465091,160.258056 656.6,159.940333 656.836727,159.710926 C657.073455,159.482222 657.401818,159.351685 657.737818,159.351685 C658.073455,159.351685 658.399636,159.482222 658.636364,159.710926 C658.875636,159.939981 659.010545,160.258056 659.010545,160.583167 C659.010545,160.905463 658.875636,161.223537 658.636364,161.452593 C658.399636,161.682 658.073455,161.814648 657.737818,161.814648 Z" />
          </g>
        </g>
      </Icon>
    )
  }
}
