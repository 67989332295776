// @flow

import React from 'react'
import { VerticalCollapse } from 'app/components/Transitions/VerticalCollapse.jsx'
import { Nav as NavContainer, NavItem, LeftContent } from 'app/components/LeftNav'
import { NavTitle } from 'app/components/LeftNav/NavTitle.jsx'
import { NavSubTitle } from 'app/components/LeftNav/NavSubTitle.jsx'
import type { Asset } from 'app/core/types/Asset'
import classes from './Project.module.scss'

type Props = {|
  project?: Asset,
|}

export function Nav(props: Props): React$Node {
  const { project } = props

  if (!project) return null
  const { thumbnailInst } = project

  function getProjectURI(uri: string): string {
    return `/projects/${project.id}/${uri}`
  }

  return (
    /* prettier-ignore */
    <LeftContent>
        {isOpen => (
          <>
            <VerticalCollapse open={isOpen}>
              <div
                style={{
                  background: `center / cover no-repeat url('${thumbnailInst?.url || ''}')`,
                  position: 'relative',
                }}
              >
                <div className={classes.overlay} />
                <div className={classes.projectName}>{project.name}</div>
              </div>
            </VerticalCollapse>
            <NavContainer>
              {/* <NavTitle permissions={['projet_dashboard__read']} label="Dashboard" icon="fas-chart-line" collapse={!isOpen}>
                <NavItem permissions={['projet_dashboard__read']} label="Dashboard" to={getProjectURI('dashboard')} collapse={!isOpen} />
                <NavItem permissions={['projet_teams__read']} label="Teams" to={getProjectURI('teams')} collapse={!isOpen} />
              </NavTitle> */}

              <NavTitle permissions={['projet_groups__read', 'projet_steps__read', 'projet_follow-up_table management_update', 'projet_flags__read', 'projet_flags__update']} label="Settings" icon="fas-cog" collapse={!isOpen}>
                <NavItem permissions={['projet_follow-up_table management_update']} label="Follow-up" to={getProjectURI('settings/follow-up')} collapse={!isOpen} />
                <NavItem permissions={['projet_groups__read']} label="Groups & Users" to={getProjectURI('settings/groups/users-by-group')} collapse={!isOpen} />
                <NavItem permissions={['projet_steps__read']} label="Steps" to={getProjectURI('settings/steps')} collapse={!isOpen} />
                <NavItem permissions={['projet_flags__read', 'projet_flags__update']} label="Flags" to={getProjectURI('settings/flags')} collapse={!isOpen} />
              </NavTitle>

              <NavTitle permissions={['projet_production notes__read', 'projet_breakdown__read']} label="Production Workflow" icon="fas-sitemap" collapse={!isOpen}>
                <NavSubTitle permissions={['projet_production notes__read', 'projet_breakdown__read']} label="Pre-production" />
                <NavItem permissions={['projet_production notes__read']} label="Models Production Notes" to={getProjectURI('postboard-models')} collapse={!isOpen} />
                <NavItem permissions={['projet_production notes__read']} label="Shots Production Notes" to={getProjectURI('postboard-shots')} collapse={!isOpen} />
                <NavItem permissions={['projet_breakdown__read']} label="Breakdown" to={getProjectURI('breakdown')} collapse={!isOpen} />
              </NavTitle>

              <NavTitle permissions={['projet_follow-up__read', 'projet_external follow-up__read']} label="Production Management" icon="calendarCheckO" collapse={!isOpen}>
                <NavItem permissions={['projet_follow-up__read']} label="Follow-up" to={getProjectURI('follow-up')} collapse={!isOpen} />
                <NavItem permissions={['projet_external follow-up__read']} label="External Follow-up" to={getProjectURI('external-follow-up')} collapse={!isOpen} />
              </NavTitle>

              <NavTitle permissions={['projet_tasks__read']} label="Planner" icon="fas-tasks" collapse={!isOpen}>
                <NavItem permissions={['projet_tasks__read']} label="Tasks" to={getProjectURI('tasks')} collapse={!isOpen} />
              </NavTitle>

              <NavTitle permissions={['projet_assets list__read', 'projet_files table__read']} label="Assets" icon="fas-cubes" collapse={!isOpen}>
                <NavItem permissions={['projet_assets list__read']} label="Assets list" to={getProjectURI('assets')} collapse={!isOpen} />
                <NavItem label="Library" to={getProjectURI('library')} collapse={!isOpen} />
                <NavItem permissions={['projet_files table__read']} label="Files" to={getProjectURI('files')} collapse={!isOpen} />
              </NavTitle>

              <NavTitle permissions={['wiki___read']} label="Informations" icon="fas-info" collapse={!isOpen}>
                <NavItem permissions={['wiki___read']} label="Project wiki" to={getProjectURI('wiki')}  collapse={!isOpen} />
              </NavTitle>
            </NavContainer>
          </>
        )}
      </LeftContent>
  )
}
