import { createWarningOnce } from 'app/libs/devWarning'
import { isPlainObject } from 'lodash'
import { http } from './http'
import { methods } from './methods'
import Authorization from './Authorization'
import Collection from './Collection'

const findAllDeprecated = createWarningOnce(() =>
  console.warn(`api.resource.findAll() is deprecated. Use fetchAll() instead`),
)
const findDeprecated = createWarningOnce(() =>
  console.warn(`api.resource.find() is deprecated. Use fetchOne() instead`),
)
const addDeprecated = createWarningOnce(() => console.warn(`api.resource.add() is deprecated. Use create() instead`))

export class Model extends Authorization {
  /**
   * @constructor
   *
   * @param string uri
   * @param bool   hasCollection
   *
   * @return Model
   */
  constructor(uri, bulk = false, subResources) {
    super()

    this.uri = uri

    this.collection = bulk ? new Collection(this.uri) : null

    if (subResources) {
      for (const childKey of Object.keys(subResources)) {
        const child = subResources[childKey]

        if (this[childKey]) {
          console.error(`${childKey} in ${this.uri} is a reserved property`)
          continue
        }

        const childURL = `${this.uri}/${child.uri}/`

        this[childKey] = (data, queries, headers, getHttpProgress, requestController) => {
          return http.request({
            method: methods[child.method],
            url: childURL,
            data,
            queries,
            headers,
            getHttpProgress,
            requestController,
          })
        }
      }
    }
  }

  /**
   * @param string
   * @return Promise
   */
  fetchAll(queries = {}, headers, getHttpProgress, requestController) {
    const { parent, project, status, step, asset, assignedUser, firstShot, ...rest } = queries

    // TODO: PATCH FOR RENAME NEW FILTERS TO DELETE
    const newQueries = Object.assign(
      rest,
      parent && { parent__uuid: parent },
      project && { project__uuid: project },
      status && { status__uuid: status },
      step && { step__uuid: step },
      asset && { asset__uuid: asset },
      assignedUser && { assignedUser__uuid: assignedUser },
      firstShot && { firstShot__uuid: firstShot },
    )

    return http.request({
      method: methods.GET,
      url: `${this.uri}/`,
      queries: newQueries,
      headers,
      getHttpProgress,
      requestController,
    })
  }

  // TO REMOVE
  findAll(...args) {
    findAllDeprecated()
    return this.fetchAll(...args)
  }

  /**
   * @param string
   *
   * @return Promise
   */
  fetchOne(id, queries, headers, getHttpProgress, requestController) {
    if (!id || !['string', 'number'].includes(typeof id)) {
      throw Error(`resource.fetchOne() take 1 string or number parameters - resource ${this.uri}`)
    }

    return http.request({
      method: methods.GET,
      url: `${this.uri}/{id}/`,
      data: { id },
      queries,
      headers,
      getHttpProgress,
      requestController,
    })
  }

  // TO REMOVE
  find(...args) {
    findDeprecated()
    return this.fetchOne(...args)
  }

  /**
   * @param object data
   *
   * @return Promise
   */
  create(data, queries, headers, getHttpProgress, requestController) {
    if (!isPlainObject(data)) {
      throw Error(`resource.create() take 1 object parameter - resource ${this.uri}`)
    }

    return http.request({
      method: methods.POST,
      url: `${this.uri}/`,
      data,
      queries,
      headers,
      getHttpProgress,
      requestController,
    })
  }

  // TO REMOVE
  add(...args) {
    addDeprecated()
    return this.create(...args)
  }

  /**
   * @param object data
   * @param object opts
   *
   * @return Promise
   */
  update(data, queries, headers, getHttpProgress, requestController, opts) {
    const _opts = {
      put: false,
      idKey: 'id',
      ...opts,
    }

    if (!data || typeof data !== 'object' || !data[_opts.idKey]) {
      throw Error(`resource.update() must have object parameter with '${_opts.idKey}' key - resource ${this.uri}`)
    }

    return http.request({
      method: _opts.put ? methods.PUT : methods.PATCH,
      url: `${this.uri}/{${_opts.idKey}}/`,
      data,
      queries,
      headers,
      getHttpProgress,
      requestController,
    })
  }

  /**
   * @param string id
   *
   * @return Promise
   */
  delete(id, queries, headers, getHttpProgress, requestController) {
    if (!id || !['string', 'number'].includes(typeof id)) {
      throw Error(
        `resource.delete() take 1 string or number parameters - resource ${this.uri}.\n${JSON.stringify(id, null, 4)}`,
      )
    }

    if (!id) throw Error('No id')

    return http.request({
      method: methods.DELETE,
      url: `${this.uri}/{id}/`,
      data: { id },
      queries,
      headers,
      getHttpProgress,
      requestController,
    })
  }
}

export default Model
