// @flow

export function ObjectValues<Obj: Object>(o: Obj): Array<$Values<Obj>> {
  return Object.values(o)
}

export function ObjectEntries<T, V: string>(obj: { [V]: T }): Array<[V, T]> {
  const keys: V[] = Object.keys(obj)
  return keys.map((key) => [key, obj[key]])
}

export default ObjectValues
