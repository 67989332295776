/** @flow */
import React from 'react'
import classes from './Checkbox.module.scss'
import Validators from '../Validators/Validators.jsx'
import { MUICheckbox, type MUICheckboxProps } from './MUICheckbox.jsx'

export interface TSCheckboxProps extends MUICheckboxProps {
  isRequired?: boolean;
  validators?: {};
  serverErrors?: [];
  validatorRef?: () => void;
}

export type CheckboxProps = {|
  ...MUICheckboxProps,
  isRequired?: boolean,
  validators?: Object,
  serverErrors?: Array<*>,
  validatorRef?: Function,
|}

export default class Checkbox extends React.PureComponent<CheckboxProps> {
  getValidators: Function = () => {
    return {
      required: this.props.isRequired,
      ...this.props.validators,
    }
  }

  render(): React$Node {
    const { serverErrors, checked, onChange, validators, isRequired, validatorRef, ...props } = this.props

    return (
      <div>
        <MUICheckbox checked={checked} onChange={onChange} {...props} />
        <Validators
          ref={validatorRef}
          className={classes.fieldError}
          value={checked}
          errors={serverErrors}
          validators={this.getValidators()}
        />
      </div>
    )
  }
}
