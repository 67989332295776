/** @flow */
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import cx from 'classnames'
import css from './Loader.module.scss'

type Props = {|
  overlay?: boolean,
  className?: string,
  size?: number,
|}

export default function (props: Props): React$Node {
  const { overlay = false, className, size = 24 } = props

  const _className = cx(css.loader, className, {
    [css.overlay]: overlay,
  })

  return (
    <div className={_className}>
      <CircularProgress disableShrink={true} size={size} color="primary" variant="indeterminate" />
    </div>
  )
}
