/** @flow */
import React from 'react'
import type { ElementProps } from 'app/core/types'
import { getUserSettings, setUserSettings } from 'app/libs/helpers/userSettings'
import classes from './ResizableCol.module.scss'
import FontIcon from '../FontIcon/FontIcon.jsx'

type Props = {|
  ...ElementProps,
  flexLeft?: number,
  flexRight?: number,
  defaultLeftSize?: number,
  defaultRightSize?: number,
  left: React$Node,
  right: React$Node,
  optionName?: string,
  rightMin?: number,
  leftMin?: number,
  toggleRight?: boolean,
  toggleLeft?: boolean,
  disabled?: 'left' | 'right',
|}

export function ResizableCol(props: Props): React$Node {
  const {
    children,
    className,
    optionName,
    flexLeft,
    flexRight,
    defaultLeftSize,
    defaultRightSize,
    left,
    leftMin,
    toggleLeft,
    right,
    rightMin,
    toggleRight,
    disabled,
  } = props

  const { leftResized, rightResized } = getUserSettings(props.optionName) || {}

  let containerSize = 0
  let leftWidth = 0
  let origMouseX = 0
  let mouseVector = 0
  const leftEl: React$ElementRef<any> = React.useRef()
  const rightEl: React$ElementRef<any> = React.useRef()
  const containerEl: React$ElementRef<any> = React.useRef()

  function resize(e) {
    const memorizeMouseVector = mouseVector
    mouseVector = e.pageX - origMouseX
    const leftPercentage = ((leftWidth + mouseVector) / containerSize) * 100

    if (rightMin || leftMin) {
      const $topDOMEl = document.querySelector('.resizableLeftContent')
      const $bottomDOMEl = document.querySelector('.resizableRightContent')

      if ($topDOMEl && $bottomDOMEl) {
        if (origMouseX > e.pageX && leftMin && $topDOMEl.clientWidth <= leftMin) {
          mouseVector = memorizeMouseVector
          return
        }
        if (origMouseX < e.pageX && rightMin && $bottomDOMEl.clientWidth <= rightMin) {
          mouseVector = memorizeMouseVector
          return
        }
      }
    }

    if (leftEl.current && rightEl.current) {
      leftEl.current.style.flex = leftPercentage
      rightEl.current.style.flex = 100 - leftPercentage
    }
  }

  function stopResize(e) {
    const leftPercentage = ((leftWidth + mouseVector) / containerSize) * 100
    const $leftDOMEl = document.querySelector('.resizableLeftContent')
    const $rightDOMEl = document.querySelector('.resizableRightContent')

    if ($leftDOMEl && $rightDOMEl) {
      $leftDOMEl.style.flex = `${leftPercentage}`
      $rightDOMEl.style.flex = `${100 - leftPercentage}`
    }

    if (optionName) setUserSettings(optionName, { leftResized: leftPercentage, rightResized: 100 - leftPercentage })

    window.removeEventListener('mousemove', resize)
  }

  function onMouseDown(e) {
    e.preventDefault()
    containerSize = containerEl.current.clientWidth
    leftWidth = leftEl.current.clientWidth
    origMouseX = e.pageX
    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResize)
  }

  if (disabled === 'right') return left
  if (disabled === 'left') return right

  return (
    <div ref={containerEl} className={`${className || ''} flex row noWrap flex1`}>
      <div
        ref={leftEl}
        style={{ flex: flexLeft || leftResized || defaultLeftSize || 1 }}
        className="flex row noWrap resizableLeftContent"
      >
        {toggleLeft === false ? null : (
          <div className="fullWidth fullHeight" style={{ minWidth: 20 }}>
            {left}
          </div>
        )}
        {toggleRight === false || !right || toggleLeft === false || !left ? null : (
          <div className={classes.draggablePart} onMouseDown={onMouseDown}>
            <div className={classes.verticalResizeBar} />
            <div className={classes.buttonResizeBar}>
              <FontIcon icon="fas-grip-lines-vertical" />
            </div>
          </div>
        )}
      </div>
      {toggleRight === false || !right ? null : (
        <div
          ref={rightEl}
          style={{ flex: flexRight || rightResized || defaultRightSize || 1, minWidth: 20, zIndex: 10 }}
          className="resizableRightContent"
        >
          {right}
        </div>
      )}
      {children}
    </div>
  )
}
