var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { ModalConfirmForm } from 'app/components/Modal';
import { FormData } from 'app/components/Form';
import { useRef } from 'react';
import { ExternalJob } from './ExternalJob';
export const ModalExternalJob = (props) => {
    const { externalJob, onSave, autoFocus } = props, rest = __rest(props, ["externalJob", "onSave", "autoFocus"]);
    const formDataRef = useRef();
    return (_jsx(ModalConfirmForm, Object.assign({ draggable: true, title: "External Job", validateLabel: "Save" }, rest, { children: _jsx(FormData, { ref: formDataRef, defaultData: { externalJob: externalJob || {} }, properties: [
                {
                    label: 'External Job',
                    key: 'externalJob',
                    type: 'autocomplete',
                    element: ExternalJob,
                    elementProps: {
                        autoFocus,
                        required: true,
                    },
                },
            ], onSave: (data) => onSave(data.externalJob.value) }) })));
};
