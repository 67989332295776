// @flow
import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { SubscriptionsActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const subscriptions: StoreResourceDef<SubscriptionsActions<>> = {
  resourceType: 'subscriptions',
  actions: (getResources) => ({
    fetchAllUserSubscriptions: (userId, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => api.users.feeds({ id: userId }, params.queries).then(transformResults(config)),
      }
    },
    checkSubscription: (ids, config) => {
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => api.checkSubscription({ ids }).then(transformResults(config)),
      }
    },
  }),
}
