/** @flow */
import type { Asset, Step } from 'app/core/types'
import { error } from '../../../components/Notifications/notify'
import { getResources } from '../../../store/selectors'

export const createRecursiveRequestIfNoPostboardLink = (
  asset: Asset,
  episode?: Asset,
  value: string,
  step: Step | null,
): * => {
  const potentialEpisode = episode || getResources(undefined, 'assets', asset.parent)

  function returnRecursiveRequest(episode: Asset) {
    return {
      type: 'recursive',
      requests: [
        () => ({
          resource: 'postBoardLinks',
          toCreate: { episode: episode.id, asset: asset.id, linkType: 'create', notes: [] },
        }),
        (res: Array<Object>) => {
          const link = res?.[0]?.postBoardLinks?.created?.resources?.[0]
          return {
            resource: 'postBoardNotes',
            toCreate: { link: link.id, text: value, step: step === null ? null : step.id },
          }
        },
      ],
    }
  }

  if (potentialEpisode?.assetType === 'ep') {
    return returnRecursiveRequest(potentialEpisode)
  }
  error('No postboardlink. Use the Shots Production view to edit the note.')
  return null
}
