/** @flow */
import React from 'react'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import type { AssetTypes, ID } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import resources from 'app/store/resources'
import FontIcon from '../FontIcon/FontIcon.jsx'

export type BreadcrumbsProps = {|
  classes: Object,
  className: string,
  disableClickItem?: boolean,
  breadcrumb: Array<{|
    id: string,
    name: string,
    assetType?: AssetTypes,
    parent: string,
  |}>,
  onClick?: Function,
|}

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(1)}px 10px ${theme.spacing(1)}px 0px`,
    maxWidth: '100%',
    overflow: 'hidden',
    '&>nav>ol': {
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
  },
  icon: {
    marginRight: theme.spacing(1) / 2,
    fontSize: 14,
  },
})

function MuiBreadcrumbs(props: BreadcrumbsProps) {
  const { classes, onClick, disableClickItem, className, breadcrumb } = props

  return (
    <div className={cx(classes.root, className)}>
      <Breadcrumbs arial-label="Breadcrumb" itemsAfterCollapse={2} itemsBeforeCollapse={1} maxItems={5}>
        {breadcrumb.map((asset, index) => {
          return (
            <Link
              key={asset.parent}
              color="inherit"
              onClick={() => {
                !disableClickItem && onClick && onClick(asset)
              }}
              className={classes.link}
            >
              {asset.assetType && <FontIcon className={classes.icon} icon={assetIcons(asset.assetType)} />} {asset.name}
            </Link>
          )
        })}
      </Breadcrumbs>
    </div>
  )
}

const StyledBreadcrumbs = withStyles(styles)(MuiBreadcrumbs)

const pipeInst: Pipe<
  {
    asset: Object,
    fullpath?: boolean,
    startAtAssetId?: ID,
  },
  typeof StyledBreadcrumbs,
> = pipe()

export const Breadcrumb: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => {
    let { asset } = props
    const { location } = props

    const splitedPath = location.pathname.split('/')

    const isUnderProject = splitedPath[1] === 'projects' && !!splitedPath[2]

    const breadcrumb = []

    while (asset) {
      if (isUnderProject && asset.assetType === 'pj') break

      const parentAsset = asset.parent && state.assets.resources[asset.parent]
      breadcrumb.push(asset)
      asset = parentAsset
    }

    return {
      breadcrumb: breadcrumb.reverse(),
    }
  })
  .request((props) => {
    if (!props || !props.asset) return Promise.resolve()

    const { id, project, parent } = props.asset

    if (!parent) return Promise.resolve()

    return project ? resources.assets.fetchWithShortPath(id) : resources.assets.fetchWithFullPath(id)
  })
  .renderLoader(() => 'Loading...')
  .render(({ assetId, fullpath, ...rest }) => <StyledBreadcrumbs {...rest} />)
