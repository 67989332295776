// @flow
import React from 'react'
import { NavLink } from 'react-router-dom'
import { startsWith } from 'lodash'
import cx from 'classnames'
import pipe from 'app/core/utils/pipe'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import history from 'app/main/routerHistory'
import { Permission } from 'app/containers/Permissions/index.js'
import { type IconType } from 'app/core/types'
import { VerticalCollapse } from '../Transitions/VerticalCollapse.jsx'
import classes from './NavItem.module.scss'

type Props = {|
  label: string,
  icon?: IconType | React$Element<any>,
  to?: string,
  permissions?: Array<string>,
  collapse?: boolean,
  children?: any,
  location: Object,
|}

class NavTitleComponent extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    const collapseChange = this.props.collapse !== nextProps.collapse
    const pathChange = this.props.location.pathname !== nextProps.location.pathname

    return collapseChange || pathChange
  }

  renderIcon() {
    const { icon, collapse, label, children } = this.props

    if (typeof icon === 'string')
      return (
        <div className={classes.iconContainer} style={{ fontSize: !collapse ? 20 : 24, height: !collapse ? 30 : 40 }}>
          <FontIcon icon={icon} />
          {collapse && children ? (
            <div className={classes.menu} style={{ minWidth: 300 }}>
              <div className={classes.titleContainer} style={{ padding: '4px 10px' }}>
                <div className={classes.inner}>{label}</div>
              </div>
              {children}
            </div>
          ) : null}
        </div>
      )
    if (icon) return icon
    return null
  }

  getActive() {
    const { to, children, collapse } = this.props
    const active = startsWith(history.location.pathname, to)
    let activeChildren

    if (Array.isArray(children)) {
      activeChildren = children.reduce((acc, child) => history.location.pathname.includes(child.props.to) || acc, false)
    } else if (typeof children === 'object') {
      activeChildren = history.location.pathname.includes(children.props.to)
    }

    return collapse && (active || activeChildren)
  }

  render(): React$Node {
    const { permissions, label, collapse = false, children, to } = this.props
    const active = this.getActive()

    let item = (
      <div style={{ position: 'relative' }}>
        <div className={cx(classes.container, classes.titleContainer)}>
          {active && (
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                height: 'calc(100% - 1px)',
                width: '5px',
                backgroundColor: '#3dc3d2b3',
                color: '#ffffff',
              }}
            />
          )}
          {this.renderIcon()}
          {!collapse ? (
            <div className={classes.titleDiv}>
              <div className={classes.inner}>{label}</div>
            </div>
          ) : null}
        </div>
        <VerticalCollapse open={!collapse}>{children}</VerticalCollapse>
      </div>
    )

    if (to) item = <NavLink to={to}>{item}</NavLink>

    return (
      <Permission actions={permissions} operator="or">
        {item}
      </Permission>
    )
  }
}

export const NavTitle: React$AbstractComponent<*, *> = pipe().withRouter().render(NavTitleComponent)
