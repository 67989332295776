/** @flow */
import React from 'react'
import ModalConfirmForm from 'app/components/Modal/ModalConfirmForm'
import FormData from 'app/components/Form/FormData/FormData'
import type { ModalProps } from 'app/components/Modal/MUIModal.jsx'
import type { SchemaColumn } from 'app/core/types'
import { uuid } from 'app/libs/uuid'

type ModalEditSchemaColumnProps<CS> = {|
  ...$Shape<$Diff<ModalProps, { children: any }>>,
  column: SchemaColumn<CS>,
  onSave: (data: SchemaColumn<CS>) => Promise<any>,
|}

export function ModalEditSchemaColumn<CS>(props: ModalEditSchemaColumnProps<CS>): React$Node {
  const { column, onSave, ...rest } = props
  const { settings, name = '' } = column || {}

  return (
    <ModalConfirmForm title={`Edit column ${name}`} draggable={false} {...rest}>
      <FormData
        defaultData={{
          name: settings?.columnName || settings?.name || name,
          description: settings?.description,
          fixed: settings?.fixed,
          fixable: settings?.fixable ?? true,
          hiddenable: settings?.hiddenable ?? true,
          isVisible: settings?.isVisible ?? true,
          readOnly: settings?.readOnly ?? false,
          hidden: settings?.hidden,
        }}
        properties={[
          {
            key: 'name',
            label: 'Column name',
            type: 'string',
            elementProps: {
              isRequired: true,
              placeholder: 'ex: Created by',
            },
          },
          {
            key: 'description',
            label: 'Description',
            type: 'textarea',
            elementProps: {
              style: { width: '100%' },
              placeholder: 'Column description (optional)',
            },
          },
          {
            label: 'Hiddenable',
            key: 'hiddenable',
            type: 'checkbox',
          },
          {
            label: 'Visible by default',
            key: 'isVisible',
            type: 'checkbox',
          },
          {
            label: 'Visible only for filters',
            key: 'hidden',
            type: 'checkbox',
          },
          {
            label: 'Pinnable',
            key: 'fixable',
            type: 'checkbox',
          },
          {
            key: 'fixed',
            label: 'Pinned by default',
            type: 'select',
            elementProps: {
              fullWidth: true,
              options: [
                { label: 'none', value: undefined },
                { label: 'left', value: 'left' },
                { label: 'right', value: 'right' },
              ],
            },
          },
          {
            key: 'readOnly',
            label: 'Read only',
            type: 'checkbox',
          },
        ]}
        onSave={(settings) =>
          onSave({
            ...column,
            key: column?.key || uuid(),
            settings: {
              ...column?.settings,
              ...settings,
              columnName: settings.name,
              name: column.name,
            },
          })
        }
      />
    </ModalConfirmForm>
  )
}
