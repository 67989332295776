// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellAggregatedDynamicApproval-___CellAggregatedDynamicApproval-module__status {
  padding: 2px 6px;
  border-radius: 3px;
  margin: 2px;
}

.app-components-Table-Cells-CellAggregatedDynamicApproval-___CellAggregatedDynamicApproval-module__count {
  font-weight: bold;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellAggregatedDynamicApproval/CellAggregatedDynamicApproval.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".status {\n  padding: 2px 6px;\n  border-radius: 3px;\n  margin: 2px;\n}\n\n.count {\n  font-weight: bold;\n  margin-left: 20px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `app-components-Table-Cells-CellAggregatedDynamicApproval-___CellAggregatedDynamicApproval-module__status`,
	"count": `app-components-Table-Cells-CellAggregatedDynamicApproval-___CellAggregatedDynamicApproval-module__count`
};
export default ___CSS_LOADER_EXPORT___;
