// @flow
import React from 'react'
import sortBy from 'lodash/sortBy'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData } from 'app/components/Form'
import type { ResourcesList, PersonAttribute, JobSkillLevel, ID, Option } from 'app/core/types'
import { getConfigEntryOption } from 'app/pages/Project/Settings/FollowUp/AttributesEditor/attributeAssets.jsx'

type Props = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  jobSkillLevel?: ?JobSkillLevel,
  onChange: ($Shape<JobSkillLevel>) => void,
  personAttributes: ResourcesList<PersonAttribute>,
|}

export function ModalAddJobSkillLevel(props: Props): React$Node {
  const { personAttributes, onChange, jobSkillLevel, ...modalProps } = props

  function onSave(data: { skill: ID, levelMin: Option, levelMax: Option }) {
    const { skill, levelMin, levelMax } = data
    onChange({ ...jobSkillLevel, skill, levelMin: levelMin.value, levelMax: levelMax.value })
    return Promise.resolve()
  }

  return (
    <ModalConfirmForm title="Required skill" draggable={true} {...modalProps}>
      <FormData
        onSave={onSave}
        defaultData={{
          skill: jobSkillLevel?.skill,
          levelMin: jobSkillLevel?.levelMin
            ? { value: jobSkillLevel?.levelMin, label: jobSkillLevel?.levelMin }
            : undefined,
          levelMax: jobSkillLevel?.levelMin
            ? { value: jobSkillLevel?.levelMax, label: jobSkillLevel?.levelMax }
            : undefined,
        }}
        properties={[
          {
            label: 'Skill',
            key: 'skill',
            type: 'select',
            elementProps: {
              options: sortBy(personAttributes, ['name']).map((personAttribute) => ({
                label: personAttribute.name,
                value: personAttribute.id,
                data: personAttribute,
              })),
              isRequired: true,
              style: { width: '100%' },
              placeholder: 'Select a skill',
            },
          },
          {
            label: 'Minimum level',
            key: 'levelMin',
            type: 'select',
            elementProps: (data, setData) => ({
              value: data.levelMin,
              onChange: (value) => setData({ ...data, levelMin: value }),
              disabled: !data.skill,
              options: getConfigEntryOption('SKILL_LEVELS'),
              style: { width: '100%' },
              placeholder: 'Select a minimum level',
            }),
          },
          {
            label: 'Maximum level',
            key: 'levelMax',
            type: 'select',
            elementProps: (data, setData) => ({
              value: data.levelMax,
              onChange: (value) => setData({ ...data, levelMax: value }),
              disabled: !data.skill,
              options: getConfigEntryOption('SKILL_LEVELS'),
              style: { width: '100%' },
              placeholder: 'Select a maximum level',
            }),
          },
        ]}
      />
    </ModalConfirmForm>
  )
}
