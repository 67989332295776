/** @flow */
import React, { useEffect, useState } from 'react'
import { lowerCase, find } from 'lodash'
import type { ID, ResourcesList, ProgressionStatus } from 'app/core/types'
import type { TableColumn } from 'app/components/Table/types'
import { optionsDynamicApprovalStatus } from 'app/core/utils/optionsDynamicApprovalStatus'
import { optionsProgressionStatus, progressionStatusFilter } from 'app/core/utils/optionsProgressionStatus'
import { MUIModal } from 'app/components/Modal'
import { MUIButton, Autocomplete } from 'app/components/Form'
import { dynamicApprovalStatus } from 'app/core/constants/dynamicApprovalStatus'

export type Status = ID | $Keys<typeof dynamicApprovalStatus>
type Props = {|
  merge: boolean,
  sourceStatus?: Array<Status>,
  srcType: 'dynamicApprovals' | 'steps',
  destType: 'dynamicApprovals' | 'steps',
  columnSrc: TableColumn,
  columnDest: TableColumn,
  progressionStatus: ResourcesList<ProgressionStatus>,
  onSave: (Array<{ src: Status | null, dest: Status | null }>) => Promise<any>,
|}

export function PushToColumnModal(props: Props): React$Node {
  const { srcType, destType, progressionStatus, onSave, sourceStatus, merge, columnSrc, columnDest, ...rest } = props
  const progressionStatusOption = optionsProgressionStatus(
    progressionStatusFilter(progressionStatus, 'without', ['retake']),
  )

  const [srcOptions, setSrcOptions] = useState(null)
  const [destOptions, setDestOptions] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [statusToPush, setStatusToPush] = useState([
    { src: { value: null, label: '' }, dest: { value: null, label: '' } },
  ])

  let isMounted = true

  useEffect(() => {
    isMounted = true
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (srcType === 'steps') setSrcOptions(progressionStatusOption)
    if (destType === 'steps') setDestOptions(progressionStatusOption)
    if (srcType === 'dynamicApprovals') setSrcOptions(optionsDynamicApprovalStatus)
    if (destType === 'dynamicApprovals') setDestOptions(optionsDynamicApprovalStatus)
  }, [srcType, destType])

  useEffect(() => {
    if (sourceStatus) {
      const sourceValues: Array<{
        src: { value: Status | null, label: string },
        dest: { value: Status | null, label: string },
      }> = []

      sourceStatus.forEach((status) => {
        const src = find(srcOptions, (opt: { value: Status, label: string }) => opt.value === status)
        const dest = find(destOptions, (opt: { value: Status, label: string }) => opt.value === status)
        if (src) sourceValues.push({ src, dest: dest || { value: null, label: '' } })
      })

      setStatusToPush(sourceValues)
    }
  }, [sourceStatus, srcOptions, destOptions])

  function addRelation() {
    const modifiedStatusToPush = Array.from(statusToPush)
    modifiedStatusToPush.push({ src: { value: null, label: '' }, dest: { value: null, label: '' } })
    setStatusToPush(modifiedStatusToPush)
  }

  function changeRelationValue(option: { value: Status, label: string }, index: number, target: 'src' | 'dest') {
    const modifiedStatusToPush = Array.from(statusToPush)
    // $FlowFixMe[invalid-computed-prop] $FlowFixMe Error when updating flow
    modifiedStatusToPush[index] = { ...modifiedStatusToPush[index], [target]: option }

    // $FlowFixMe
    setStatusToPush(modifiedStatusToPush)
  }

  let warning

  if (
    srcType === 'dynamicApprovals' &&
    destType === 'steps' &&
    statusToPush.find((status) => status.src.value === 'rt')
  ) {
    warning = (
      <span className="flex alignCenter padding20" style={{ display: 'inline' }}>
        <span className="fontSize20 marginRight5 bold">⚠</span>Pushing a{' '}
        <span className="bold">&nbsp;Dynamic Approval&nbsp;</span> with status
        <span className="bold">&nbsp;Retake&nbsp;</span> to a <span className="bold">&nbsp;Step&nbsp;</span> will create
        a new take.
      </span>
    )
  }

  return (
    <MUIModal
      onValidate={() => {
        isMounted && setIsLoading(true)
        return onSave(statusToPush.map((rel) => ({ src: rel.src.value, dest: rel.dest.value }))).then(() => {
          isMounted && setIsLoading(false)
        })
      }}
      disableCancel={isLoading}
      validateLabel={
        isLoading ? 'This action can take few minutes...' : merge ? 'Push status and merge' : 'Push status and replace'
      }
      title={`Push ${String(columnSrc.Header)} to ${String(columnDest.Header)}`}
      width={700}
      extendsButtons={
        !sourceStatus ? (
          <MUIButton onClick={addRelation} disabled={isLoading}>
            Add a {lowerCase(srcType)} status to push
          </MUIButton>
        ) : undefined
      }
      {...rest}
    >
      <div className="flex column">
        {warning}
        <div className="grid col2 marginTop20 marginBottom20">
          <div className="flex center bold fontSize18 capitalize">{String(columnSrc.Header)}</div>
          <div className="flex center bold fontSize18 capitalize">{String(columnDest.Header)}</div>
          {statusToPush.map((relation, index) => {
            return [
              <div style={{ width: '300px' }} key="src">
                <Autocomplete
                  value={relation.src}
                  options={srcOptions}
                  onChange={(option) => changeRelationValue(option, index, 'src')}
                  style={{ width: '100%' }}
                  placeholder={`Select a ${lowerCase(srcType)} status`}
                  disabled={sourceStatus}
                />
              </div>,
              <div style={{ width: '300px' }} key="dest">
                <Autocomplete
                  value={relation.dest}
                  options={destOptions}
                  onChange={(option) => changeRelationValue(option, index, 'dest')}
                  style={{ width: '100%' }}
                  placeholder={`Select a ${lowerCase(destType)} status`}
                />
              </div>,
            ].filter((_) => _)
          })}
        </div>
      </div>
    </MUIModal>
  )
}
