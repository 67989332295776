// @flow
import map from 'lodash/map'
import sum from 'lodash/sum'
import type { StoreState } from 'app/core/types'
import { DurationRead } from 'app/components/Duration/Duration.tsx'
import { getResources } from './getResources'

export const getTaskTimeEstim = (state: StoreState, taskId: string): React$Node => {
  const task = getResources(state, 'tasks', taskId, ['takesInst'])
  const timeEstim = sum(map(task.takesInst, (take) => take.estimLength))
  return DurationRead({ value: timeEstim })
}
