// @flow

import React from 'react'
import Loader from 'app/components/Loader/Loader.jsx'
import { MUIModal } from './MUIModal.jsx'

export function ModalLoader(props: Object): React$Node {
  return (
    <MUIModal hideValidate={true} {...props}>
      <div style={{ height: 120 }} className="flex center alignCenter fullWidth">
        <Loader />
      </div>
    </MUIModal>
  )
}
