import { SET_NOTIFS_COUNT } from 'app/store/actions/notifsCount'

const notifsCount = (state = 0, action) => {
  switch (action.type) {
    case SET_NOTIFS_COUNT:
      return action.notifsCount
    default:
      return state
  }
}

export default notifsCount
