// @flow
import type { StoreResourceDef } from 'app/core/types'
import api from 'app/core/api/api.js'
import { afterGet, afterGetAll, verifyArrayTrackingSchemaGroupKey } from 'app/store/hooks/trackingSchemas.js'
import type { TrackingSchemasActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

// filter that returns only the tracking schemas that includes the item. Avoid useless requests
const trackingSchemaFilter = (ts, id) => {
  return ts.schema.some((schema) => {
    return schema.items.some((item) => {
      return item.id === id
    })
  })
}

export const trackingSchemas: StoreResourceDef<TrackingSchemasActions<>> = {
  resourceType: 'trackingSchemas',
  hooks: (getResourcesAPI, getResources) => ({
    afterGet: afterGet(getResourcesAPI, getResources),
    afterGetAll: afterGetAll(getResourcesAPI, getResources),
  }),
  actions: (getResourcesAPI) => ({
    fetchByProject: (projectId, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.projects
            .trackingSchemas(
              { id: projectId },
              params.queries,
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(async (resParams) => {
              const res = resParams

              try {
                res.results = await verifyArrayTrackingSchemaGroupKey(res.results, getResourcesAPI)
              } catch (error) {
                console.error(error)
              }
              return transformResults(config)(res)
            }),
      }
    },
    deleteItemByID: ({ id, projectID }, config) => {
      const { params = {} } = config || {}
      return {
        type: 'update',
        requestKey: config?.requestKey,
        request: () =>
          api.projects
            .trackingSchemas({ id: projectID, ...params.queries })
            .then(({ results }) => {
              const parsedTrackingSchemas = results
                .filter((ts) => trackingSchemaFilter(ts, id))
                .map((ts) => {
                  return {
                    ...ts,
                    schema: ts.schema.map((group) => {
                      return {
                        ...group,
                        items: group.items?.filter((item) => item.id !== id),
                      }
                    }),
                  }
                })
              return Promise.all(parsedTrackingSchemas.map((ts) => api.trackingSchemas.update(ts)))
            })
            .then(transformResults(config)),
      }
    },
    updateAttributeByID: ({ id, projectID, newAttribute }, config) => {
      const { params = {} } = config || {}
      return {
        type: 'update',
        requestKey: config?.requestKey,
        request: () =>
          api.projects
            .trackingSchemas({ id: projectID, ...params.queries })
            .then(({ results }) => {
              const parsedTrackingSchemas = results
                .filter((ts) => trackingSchemaFilter(ts, id))
                .map((ts) => {
                  return {
                    ...ts,
                    schema: ts.schema.map((group) => {
                      return {
                        ...group,
                        items: group.items?.map((item) => {
                          if (item.id === newAttribute.id) {
                            const updatedItem = {
                              ...item,
                              ...newAttribute,
                            }
                            return updatedItem
                          }
                          return item
                        }),
                      }
                    }),
                  }
                })
              return Promise.all(parsedTrackingSchemas.map((ts) => api.trackingSchemas.update(ts)))
            })
            .then(transformResults(config)),
      }
    },
    updateDynamicApprovalByID: ({ id, projectID, dynamicApproval }, config) => {
      const { params = {} } = config || {}
      return {
        type: 'update',
        requestKey: config?.requestKey,
        request: () =>
          api.projects
            .trackingSchemas({ id: projectID, ...params.queries })
            .then(({ results }) => {
              const parsedTrackingSchemas = results
                .filter((ts) => trackingSchemaFilter(ts, id))
                .map((ts) => {
                  return {
                    ...ts,
                    schema: ts.schema.map((group) => {
                      return {
                        ...group,
                        items: group.items?.map((item) => {
                          if (item.id === dynamicApproval.id) {
                            const updatedItem = {
                              ...item,
                              name: dynamicApproval.name,
                              targetStep: dynamicApproval.targetStep,
                            }
                            return updatedItem
                          }
                          return item
                        }),
                      }
                    }),
                  }
                })
              return Promise.all(parsedTrackingSchemas.map((ts) => api.trackingSchemas.update(ts)))
            })
            .then(transformResults(config)),
      }
    },
    fetchExternals: (config?) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.trackingSchemas
            .externals(undefined, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(transformResults(config)),
      }
    },
    fetchExternalsByProject: (projectId, config?) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.projects
            .externalTrackingSchemas(
              { id: projectId },
              params.queries,
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(config)),
      }
    },
  }),
}
