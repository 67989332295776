/** @flow */
import React from 'react'
import { MUIModal, openModal } from '../Modal'

function Clipboard(props) {
  return (
    <MUIModal hideCancel={true} {...props}>
      <span>
        To allow special pasting from the clipboard, you need to access the rule in your browser.
        <br />
        To do this, click on the {`"`}Allow{`"`} button in the top left popup.
        <br />
        If you refuse, you can always change this in your browser preferences.
      </span>
    </MUIModal>
  )
}

export const getAuthorizationDescription = (type: 'clipboard'): void | null => {
  if (type === 'clipboard') {
    return openModal(<Clipboard />)
  }
  return null
}
