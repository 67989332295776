import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import Tooltip from '@material-ui/core/Tooltip';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import resources from 'app/store/resources';
import { openPictures } from 'app/components/PictureVisualization/PictureVisualization';
import { Permission } from 'app/containers/Permissions';
import classes from './Mozaic.module.scss';
export function Mozaic(props) {
    const { pinned, projectId, asset } = props;
    const [mediasIds, setMediasIds] = useState([]);
    const medias = useSelector((state) => {
        return mediasIds.map((mediaId) => state.medias.resources[mediaId]);
    });
    function onClick(index) {
        openPictures(medias, true, true, index);
    }
    useEffect(() => {
        const requestsConfig = {
            params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } },
        };
        resources.medias[pinned ? 'fetchAllTakesPinnedMedias' : 'fetchAllTakesValidationMedias']({ id: asset.id }, requestsConfig).then((res) => {
            setMediasIds(uniq(res.resources.map((m) => m.id)));
        });
    }, [pinned, projectId]);
    if (medias.length === 0) {
        return _jsx("div", { className: "fullWidth fullHeight flex center alignCenter lightgrey", children: "No validations medias" });
    }
    return (_jsx("div", { className: "fullWidth fullHeight padding10", children: _jsx("div", { id: "scrollableDiv", className: "fullWidth fullHeight", style: { overflow: 'auto' }, children: _jsx("div", { className: classes.imageGrid, children: medias.map((empty, index) => {
                    const media = medias[index];
                    if (!media)
                        return null;
                    return (_jsxs("div", { className: classes.imageItem, onClick: () => onClick(index), children: [!pinned ? (_jsx(Permission, { actions: ['projet_medias__Pin validation medias'], projectId: projectId, children: _jsx(Tooltip, { title: "Pin media to the mozaic", placement: "top", children: _jsx("div", { onClick: (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            resources.medias.update({ id: media.id, pinned: !media.pinned });
                                        }, children: _jsx(FontIcon, { icon: "fas-thumbtack", className: classes.btn, style: { color: media.pinned ? '#3DC3D2' : 'rgba(0,0,0,0.6)' } }) }) }) })) : null, _jsx("img", { src: media.thumbnail || media.url })] }, media.url));
                }) }) }) }));
}
