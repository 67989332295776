// @flow
import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import { MUIModal, type ModalProps } from 'app/components/Modal'
import { Input, MUISelect, MUIAutocomplete, MUIButton } from 'app/components/Form'
import { getResources } from 'app/store/selectors'
import resources from 'app/store/resources'
import type {
  Job,
  Imputation,
  ProjectDepartmentJob,
  Contract,
  Option,
  Person,
  DateDay,
  CollectiveAgreementTitle,
  ContractType,
  ContractStatus,
  Asset,
  ID,
} from 'app/core/types'
import { getMinMaxDatesFromObjects } from 'app/core/utils/dates'
import Loader from 'app/components/Loader/Loader.jsx'
import api from 'app/core/api/api'
import { getContractStatusOptions } from 'app/core/utils/getContractsStatus'

import { useSelector } from 'react-redux'
import { contractTypesSelector } from 'app/store/selectors/serverConfig.ts'
import classes from './ModalEditImputations.module.scss'
import { ContractCalendar, type ContractCalendarProps } from './ContractCalendar.jsx'
import AssetsSelect from '../../Assets/AssetsSelect/AssetsSelect.jsx'
import { getContractTypeKey, getContractTypesOptions } from '../Contracts/ModalEditContract/utils'

export type ModalEditImputationsProps = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  imputation?: ?Imputation,
  onSave?: ($Shape<Imputation>) => Promise<any>,
  onChange?: ($Shape<Imputation>) => void,
  projectDepartmentJob?: ProjectDepartmentJob,
  parentSubRows?: Array<{ ...any, startDate: DateDay, endDate: DateDay }>,
|}

export function ModalEditImputations(props: ModalEditImputationsProps): React$Element<any> {
  const {
    imputation,
    onSave,
    onChange,
    projectDepartmentJob: defaultProjectDepartmentJob,
    parentSubRows,
    ...modalProps
  } = props
  const { contractInst } = imputation || {}

  const [projectDepartmentJobs, setProjectDepartmentJobs] = useState<Array<ProjectDepartmentJob>>([])
  const [projectDepartmentJob, setProjectDepartmentJob] = useState<ProjectDepartmentJob | void>()
  const [startDate, _setStartDate] = useState<void | DateDay>(imputation?.startDate)
  const [endDate, _setEndDate] = useState<void | DateDay>(imputation?.endDate)
  const [length, setLength] = useState<number>(0)
  const [salary, setSalary] = useState<number | void>(imputation?.contractInst?.secureDataInst?.salary)
  const [contractStatus, setContractStatus] = useState<ContractStatus | void>(contractInst?.contractStatus)
  const [person, setPerson] = useState<Person | void>()
  const [contracts, setContracts] = useState<Array<Contract>>([])
  const [contract, setContract] = useState<$Shape<Contract> | void | null>(contractInst)
  const [offices, setOffices] = useState<Array<Asset>>([])
  const [office, setOffice] = useState<ID | void | null>(contractInst?.office)
  const [imputationOffice, setImputationOffice] = useState<ID | void | null>(imputation?.office)
  const [note, setNote] = useState<string | void>(imputation?.note)
  const [collectiveAgreementTitles, setCollectiveAgreementTitles] = useState<Array<CollectiveAgreementTitle> | void>()
  const [collectiveAgreementTitle, setCollectiveAgreementTitle] = useState<CollectiveAgreementTitle | void>(
    contractInst?.collectiveAgreementTitleData
      ? { ...contractInst?.collectiveAgreementTitleData, id: contractInst?.collectiveAgreementTitle }
      : undefined,
  )
  const [imputations, setImputations] = useState<Array<Imputation>>([])
  const [contractType, setContractType] = useState<void | ContractType>()
  const [errors, setErrors] = useState<{ [key: string]: Array<string> }>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false)
  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  const [legendControl, setLegendControl] = useState<$ElementType<ContractCalendarProps, 'legendControl'>>({
    isJob: true,
    isJobImputation: true,
    selectedImputation: true,
    isImputation: false,
    selectedContract: false,
    isContract: false,
    errors: true,
  })

  const contractTypes = useSelector(contractTypesSelector)
  const contractTypesOptions = getContractTypesOptions(contractTypes)
  const contractStatusOptions = useMemo(() => getContractStatusOptions(), [])
  const contractIsEditable = useMemo(() => ['signed'].includes(contract?.contractStatus), [contract])

  const initialPerson = useRef()

  const setStartDate = (value: DateDay) => {
    const newStartDate = endDate && value > endDate ? endDate : value
    _setStartDate(newStartDate)
    if (contract && !contract.id) setContract((contract) => ({ ...contract, startDate: newStartDate }))
  }

  const setEndDate = (value: DateDay) => {
    const newEndDate = startDate && value < startDate ? startDate : value
    _setEndDate(newEndDate)
    if (contract && !contract.id) setContract((contract) => ({ ...contract, endDate: newEndDate }))
  }

  useEffect(() => {
    if (!imputation) {
      if (parentSubRows) {
        const [, max] = getMinMaxDatesFromObjects(parentSubRows)
        if (max) {
          const date = moment(max).add(1, 'day').format('YYYY-MM-DD') // prettier-ignore
          setStartDate(date)
          if (projectDepartmentJob && projectDepartmentJob.endDate > date) setEndDate(projectDepartmentJob.endDate)
          else setEndDate(date)
        } else if (projectDepartmentJob) {
          setStartDate(projectDepartmentJob.startDate)
          setEndDate(projectDepartmentJob.endDate)
        }
      } else if (projectDepartmentJob) {
        setStartDate(projectDepartmentJob.startDate)
        setEndDate(projectDepartmentJob.endDate)
      }
    } else {
      setPerson(imputation.personInst)
      if (!contract) setContract({ id: 'candidat' })
    }
  }, [projectDepartmentJob, imputation])

  useEffect(() => {
    if (projectDepartmentJob?.jobInst?.id) {
      resources.jobs.fetchOne(projectDepartmentJob.jobInst.id).then((res) => {
        const job: Job = res.plainResponse
        setCollectiveAgreementTitles(job.collectiveAgreementTitlesInst)
      })
    } else {
      setCollectiveAgreementTitles()
    }
  }, [projectDepartmentJob])

  useEffect(() => {
    if (endDate && startDate) {
      setLength(moment(endDate).diff(moment(startDate), 'days') + 1)
    }
  }, [startDate, endDate])

  useEffect(() => {
    setModalIsLoading(true)
    Promise.all([
      resources.assets.fetchAll({ params: { queries: { page_size: 1000, assetType: 'of' } } }).then((res) => {
        setOffices(res.resources)
      }),
      resources.projectDepartmentJobs.fetchAll({ params: { queries: { page_size: 1000 } } }).then(() => {
        setProjectDepartmentJobs(sortBy(getResources(undefined, 'projectDepartmentJobs', undefined), ['name']))
        if (defaultProjectDepartmentJob) {
          setProjectDepartmentJob({
            ...defaultProjectDepartmentJob,
            imputationsInst: map(getResources(undefined, 'imputations', defaultProjectDepartmentJob.imputations)),
          })
        } else if (imputation) {
          let prjDptJob = getResources(undefined, 'projectDepartmentJobs', imputation.projectDepartmentJob, ['jobInst'])
          if (prjDptJob?.imputations) {
            prjDptJob = {
              ...prjDptJob,
              imputationsInst: map(getResources(undefined, 'imputations', prjDptJob.imputations)),
            }
          }
          setProjectDepartmentJob(prjDptJob)
        }
      }),
      imputation?.contract &&
        resources.contracts.fetchOne(imputation.contract).then(() => {
          const contract = getResources(undefined, 'contracts', imputation.contract, ['personInst', 'secureDataInst'])
          const person = contract.personInst
          setContract(contract)
          setPerson(person)
          initialPerson.current = person
        }),
    ])
      .then(() => {
        setModalIsLoading(false)
      })
      .catch(() => {
        setModalIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (person) {
      if (person.id !== initialPerson.current?.id) setContract()
      setIsLoading(true)

      const onError = () => {
        setContracts([])
        setImputations([])
        setIsLoading(false)
      }

      const getParams = (query) => ({ params: { queries: { page_size: 1000, ...query } } })

      resources.contracts
        .fetchAll(getParams({ person__uuid: person.id }))
        .then((contractsRes) => {
          setContracts(sortBy(contractsRes.resources || [], ['startDate', 'endDate']))
          const contractsIds = contractsRes.resources?.map((c) => c.id) || []
          if (contractsIds.length > 0) {
            return resources.imputations
              .fetchAll(getParams({ contract__uuid__in: contractsIds.toString() }))
              .then((res) => {
                setImputations(sortBy(res.resources || [], ['startDate', 'endDate']))
                setIsLoading(false)
              })
              .catch(onError)
          }
          setImputations([])
          setIsLoading(false)
          return undefined
        })
        .catch(onError)
    }
    return () => {
      setContracts([])
      setImputations([])
    }
  }, [person])

  useEffect(() => {
    if (contract?.id && contract.id !== 'candidat') {
      const { collectiveAgreementTitle, collectiveAgreementTitleData, secureDataInst, contractStatus } = contract
      setCollectiveAgreementTitle({ ...collectiveAgreementTitleData, id: collectiveAgreementTitle })
      setSalary(secureDataInst?.salary)
      setContractStatus(contractStatus)
    } else {
      setSalary()
      setCollectiveAgreementTitle()
      setContractType()
      setContractStatus('creation')
    }
  }, [contract])

  function getPersonLabel(person: Person) {
    const { firstName, lastName } = person
    return `${firstName} ${lastName}`
  }

  function getContractLabel(contract: Contract) {
    const { startDate, endDate } = contract

    const contractName = getContractTypeKey(contract.contractType)

    return `${contractName} - ${moment(startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')}`
  }

  function getErrors() {
    const errors = {}
    if (!contract) errors.contract = [...(errors.contract || []), 'Contract is empty']
    if (!person) errors.person = [...(errors.person || []), 'Person is empty']
    if (!projectDepartmentJob) errors.projectDepartmentJob = [...(errors.projectDepartmentJob || []), 'Job is empty']
    if (!startDate) errors.startDate = [...(errors.startDate || []), 'Start date is empty']
    if (!endDate) errors.endDate = [...(errors.endDate || []), 'End date is empty']

    if (!contract || (person && contract && !contract?.id)) {
      if (!collectiveAgreementTitle) {
        errors.collectiveAgreementTitle = [
          ...(errors.collectiveAgreementTitle || []),
          'Collective agreement title is empty',
        ]
      }
      if (!office) errors.office = [...(errors.office || []), 'Office is empty']
      if (!salary) errors.salary = [...(errors.salary || []), 'Salary is empty']
      if (!contractType) errors.contractType = [...(errors.contractType || []), 'Contract type is empty']
      if (!contractStatus) errors.contractStatus = [...(errors.contractStatus || []), 'Contract status is empty']
    }

    if (Object.keys(errors).length === 0) return null
    return errors
  }

  function backContractToOriginal(newContractCreated: Contract) {
    if (!newContractCreated) return Promise.resolve()
    if (contract?.id) {
      return resources.contracts.update(contract)
    }
    return resources.contracts.delete(newContractCreated.id)
  }

  function backSecureDataToOriginal(newSecureDataCreated) {
    if (!newSecureDataCreated) return Promise.resolve()
    if (contract?.secureDataInst?.id) {
      return resources.contractSecureDatas.update(contract?.secureDataInst)
    }
    return resources.contractSecureDatas.delete(newSecureDataCreated.id)
  }

  function save() {
    const errors = getErrors()

    if (errors) {
      setErrors(errors)
      console.error(errors)
      return Promise.reject()
    }

    if (contract?.id === 'candidat') {
      const newImputation = {
        id: imputation?.id,
        startDate,
        endDate,
        projectDepartmentJob: projectDepartmentJob?.id,
        person: person?.id,
        office: imputationOffice,
        contract: null,
        note,
      }

      return resources.imputations[imputation?.id ? 'update' : 'create'](newImputation).then((res) => {
        onChange?.(res.resources[0])
      })
    }

    const newContract = {
      ...contract,
      person: person?.id,
      collectiveAgreementTitle: collectiveAgreementTitle?.id,
      contractType,
      contractStatus,
      office,
      project: projectDepartmentJob?.projectDepartmentInst?.projectPlan,
    }

    return resources.contracts[newContract?.id ? 'update' : 'create'](newContract).then((contractRes) => {
      const newContractCreated = contractRes.resources[0]

      let promise = Promise.resolve()
      let newSecureDataCreated

      if (typeof salary === 'number' && salary !== newContractCreated?.secureDataInst?.salary) {
        promise = api.contractSecureDatas[contract?.secureData ? 'update' : 'create']({
          id: newContractCreated.secureData,
          salary,
          contract: newContractCreated.id,
        })
          .then((newSecureDataRes) => {
            newSecureDataCreated = newSecureDataRes
            newContractCreated.secureData = newSecureDataCreated.id
            newContractCreated.secureDataInst = newSecureDataCreated
          })
          .catch((err) => {
            console.error(err)
            backContractToOriginal(newContractCreated)
            return err
          })
      }

      promise
        .then(() => {
          const newImputation = {
            id: imputation?.id,
            startDate,
            endDate,
            projectDepartmentJob: projectDepartmentJob?.id,
            contract: newContractCreated.id,
            person: person?.id,
          }

          return resources.imputations[imputation?.id ? 'update' : 'create'](newImputation).then((res) => {
            onChange?.(res.resources[0])
          })
        })
        .catch((err) => {
          console.error(err)
          backContractToOriginal(newContractCreated)
          backSecureDataToOriginal(newSecureDataCreated)
          return err
        })
    })
  }

  const loader = (
    <div style={{ height: 600 }} className="flex center alignCenter fullWidth">
      <Loader />
    </div>
  )

  const calendar = contract ? (
    <div className={classes.rightPanel}>
      <ContractCalendar
        startDate={projectDepartmentJob?.startDate || startDate}
        endDate={projectDepartmentJob?.endDate || endDate}
        contract={contract}
        contracts={contracts}
        imputation={{ ...(imputation || {}), startDate, endDate }}
        imputations={imputations.filter((i) => i.id !== imputation?.id)}
        projectDepartmentJob={projectDepartmentJob}
        legendControl={legendControl}
        setDates={([start, end]) => {
          setStartDate(start)
          setEndDate(end)
        }}
      />
    </div>
  ) : null

  const legend = (
    <div className={classes.legend}>
      <div className={classes.item}>
        <input
          type="checkbox"
          checked={legendControl.isJob}
          onChange={(e) => setLegendControl((s) => ({ ...s, isJob: e.target.checked }))}
        />
        <div legend-type="job" />
        <span>Job</span>
      </div>
      <div className={classes.item}>
        <input
          type="checkbox"
          checked={legendControl.isJobImputation}
          onChange={(e) => setLegendControl((s) => ({ ...s, isJobImputation: e.target.checked }))}
        />
        <div legend-type="jobImputations" />
        <span>Job imputations</span>
      </div>
      <div className={classes.item}>
        <input
          type="checkbox"
          checked={legendControl.selectedImputation}
          onChange={(e) => setLegendControl((s) => ({ ...s, selectedImputation: e.target.checked }))}
        />
        <div legend-type="currentImputation" />
        <span>Current imputation</span>
      </div>
      <div className={classes.item}>
        <input
          type="checkbox"
          checked={legendControl.selectedContract}
          onChange={(e) => setLegendControl((s) => ({ ...s, selectedContract: e.target.checked }))}
        />
        <div legend-type="currentContract" />
        <span>Current contract</span>
      </div>
      <div className={classes.item}>
        <input
          type="checkbox"
          checked={legendControl.isContract}
          onChange={(e) => setLegendControl((s) => ({ ...s, isContract: e.target.checked }))}
        />
        <div legend-type="contract" />
        <span>
          {person ? `${person?.firstName} ${person?.lastName}'s c` : 'C'}ontracts ({contracts.length})
        </span>
      </div>
      <div className={classes.item}>
        <input
          type="checkbox"
          checked={legendControl.isImputation}
          onChange={(e) => setLegendControl((s) => ({ ...s, isImputation: e.target.checked }))}
        />
        <div legend-type="imputation" />
        <span>
          {person ? `${person?.firstName} ${person?.lastName}'s i` : 'I'}mputations ({imputations.length})
        </span>
      </div>
      <div className={classes.item}>
        <input
          type="checkbox"
          checked={legendControl.errors}
          onChange={(e) => setLegendControl((s) => ({ ...s, errors: e.target.checked }))}
        />
        <div legend-type="error" />
        <span>Errors</span>
      </div>
    </div>
  )

  const fields = (
    <div className={classes.fields}>
      <div className={classes.field}>
        <span>Job title</span>
        <MUIAutocomplete
          errors={errors.projectDepartmentJob}
          value={
            projectDepartmentJob
              ? { label: projectDepartmentJob.name, value: projectDepartmentJob.id, data: projectDepartmentJob }
              : null
          }
          options={projectDepartmentJobs.map((pjDptJob) => ({
            label: pjDptJob.name,
            value: pjDptJob.id,
            data: pjDptJob,
          }))}
          onChange={(opt: { ...Option, data: ProjectDepartmentJob }) => {
            setProjectDepartmentJob(opt.data)
          }}
          disabled={!!defaultProjectDepartmentJob || !!imputation?.projectDepartmentJob}
        />
      </div>
      <div className={classes.field}>
        <span>Start date</span>
        <Input
          value={startDate || ''}
          type="date"
          showDateDay={true}
          errors={errors.startDate}
          onChange={(e) => setStartDate(e.target.value)}
          dataCy="input-startDate"
          style={{ height: 32 }}
          max={endDate}
        />
      </div>
      <div className={classes.field}>
        <span>End date</span>
        <Input
          value={endDate || ''}
          type="date"
          showDateDay={true}
          errors={errors.endDate}
          onChange={(e) => setEndDate(e.target.value)}
          dataCy="input-endDate"
          style={{ height: 32 }}
          min={startDate}
        />
      </div>
      <div className={classes.field}>
        <span>Duration</span>
        <Input
          value={length}
          type="number"
          errors={errors.endDate}
          onChange={(e) => {
            const { value } = e.target
            if (Number(value) < 1) return
            const _startDate = moment(startDate)
            const _endDate = moment(startDate).add(Number(value) - 1, 'days')
            setStartDate(_startDate.format('YYYY-MM-DD'))
            setEndDate(_endDate.format('YYYY-MM-DD'))
          }}
          dataCy="input-length"
          adornment={<span className="padding5">days</span>}
          adornmentWidth={40}
          alignRight={true}
          style={{ height: 32 }}
          min={startDate}
        />
      </div>
      <div className={classes.field}>
        <span>People</span>
        <AssetsSelect
          errors={errors.person}
          value={person ? { label: getPersonLabel(person), value: person.id } : null}
          model="people"
          formatMetaOnSearchResult={true}
          onChange={(opt: Option, data) => {
            setContract()
            setPerson(opt.data)
          }}
        />
      </div>
      <div className={classes.field}>
        <span>Contract ({contracts.length})</span>
        <MUISelect
          errors={errors.contract}
          value={contract ? { label: getContractLabel(contract), value: contract.id, data: contract } : null}
          options={[
            { label: 'New contract', value: undefined, data: { startDate, endDate } },
            { label: 'Candidat', value: 'candidat', data: { id: 'candidat' } },
          ].concat(
            contracts.map((contract) => ({
              label: getContractLabel(contract),
              value: contract.id,
              data: contract,
            })),
          )}
          onChange={(opt: { ...Option, data: Contract }) => {
            setContract(opt.data)
          }}
          style={{ width: '100%' }}
          isLoading={isLoading}
        />
      </div>

      {contract?.id === 'candidat' ? (
        <>
          <div className={classes.field}>
            <span>Office</span>
            <MUISelect
              errors={errors.office || null}
              value={imputationOffice}
              options={offices.map((office) => {
                const name = office.name[0].toUpperCase() + office.name.slice(1)
                return { value: office.id, label: name }
              })}
              onChange={(opt: { label: string, value: ID }) => {
                setImputationOffice(opt.value)
              }}
              style={{ width: '100%' }}
            />
          </div>
          <div className={classes.field}>
            <span>Note</span>
            <textarea
              style={{
                height: 120,
                minHeight: 55,
                width: '100%',
                maxWidth: '100%',
                minWidth: '100%',
                padding: '6px 7px',
                border: '1px solid #d2d6dc',
                borderRadius: '3px',
              }}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </>
      ) : null}

      {person && contract && contract.id !== 'candidat' ? (
        <>
          <div className={classes.field}>
            <span>Office</span>
            <MUISelect
              errors={errors.office || null}
              value={contract?.office || office}
              options={offices.map((office) => {
                const name = office.name[0].toUpperCase() + office.name.slice(1)
                return { value: office.id, label: name }
              })}
              onChange={(opt: { label: string, value: ID }) => {
                setOffice(opt.value)
              }}
              style={{ width: '100%' }}
            />
          </div>

          <div className={classes.field}>
            <span>Contract type</span>
            <MUISelect
              errors={errors.contractType || null}
              value={contract?.contractType || contractType}
              options={contractTypesOptions}
              onChange={(opt: { label: string, value: ContractType }) => {
                setContractType(opt?.value)
              }}
              style={{ width: '100%' }}
              disabled={contractIsEditable}
            />
          </div>

          <div className={classes.field}>
            <span>Collective agreement title</span>
            <MUISelect
              errors={errors.collectiveAgreementTitle}
              disabled={contractIsEditable}
              value={
                collectiveAgreementTitle
                  ? {
                      label: collectiveAgreementTitle.jobTitle,
                      value: collectiveAgreementTitle.id,
                      data: collectiveAgreementTitle,
                    }
                  : null
              }
              options={
                collectiveAgreementTitles
                  ? collectiveAgreementTitles.map((collectiveAgreementTitle) => ({
                      label: collectiveAgreementTitle.jobTitle,
                      value: collectiveAgreementTitle.id,
                      data: collectiveAgreementTitle,
                    }))
                  : []
              }
              onChange={(opt: { ...Option, data: CollectiveAgreementTitle }) => {
                setCollectiveAgreementTitle(opt.data)
                setSalary(opt.data.minimumSalary || 0)
              }}
              style={{ width: '100%' }}
            />
          </div>

          <div className={classes.field}>
            <span>Salary</span>
            <Input
              value={typeof salary === 'number' ? salary : ''}
              type="number"
              errors={errors.salary}
              onChange={(e) => {
                setSalary(Number(e.target.value))
              }}
              dataCy="input-salary"
              style={{ height: 32 }}
              min={0}
              disabled={contractIsEditable}
            />
          </div>

          <div className={classes.field}>
            <span>Contract status</span>
            <MUISelect
              errors={errors.contractStatus || null}
              value={contractStatus}
              options={contractStatusOptions}
              onChange={(opt: { label: string, value: ContractStatus }) => {
                setContractStatus(opt?.value)
              }}
              style={{ width: '100%' }}
              disabled={contractIsEditable}
            />
          </div>
        </>
      ) : null}
    </div>
  )

  return (
    <MUIModal
      title={imputation ? 'Edit imputation' : 'New imputation'}
      draggable={true}
      resizable={showCalendar}
      minWidth={showCalendar ? 1000 : undefined}
      minHeight={showCalendar ? 640 : 400}
      width={showCalendar ? undefined : 350}
      onValidate={save}
      extendsButtons={
        <MUIButton onClick={() => setShowCalendar(!showCalendar)} color={showCalendar ? 'primary' : undefined}>
          Calendar
        </MUIButton>
      }
      {...modalProps}
    >
      {modalIsLoading ? (
        loader
      ) : (
        <div className={classes.container}>
          <div className={classes.leftPanel} style={{ width: showCalendar ? 250 : '100%' }}>
            {fields}
            {showCalendar ? legend : null}
          </div>
          {showCalendar ? calendar : null}
        </div>
      )}
    </MUIModal>
  )
}
