// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetsTree-___AssetsTreeSearch-module__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  align-items: baseline;
}

.app-containers-Assets-AssetsTree-___AssetsTreeSearch-module__tree {
  flex: 1;
  overflow: auto;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetsTree/AssetsTreeSearch.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,OAAO;EACP,cAAc;EACd,WAAW;AACb","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow: hidden;\n  box-sizing: border-box;\n  align-items: baseline;\n}\n\n.tree {\n  flex: 1;\n  overflow: auto;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-containers-Assets-AssetsTree-___AssetsTreeSearch-module__container`,
	"tree": `app-containers-Assets-AssetsTree-___AssetsTreeSearch-module__tree`
};
export default ___CSS_LOADER_EXPORT___;
