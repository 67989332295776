// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetDetail-Usage-___Usage-module__container {
  overflow-y: auto;
  flex: 1;
  margin: 5px 5px 5px 5px;
  display: flex;
  flex-direction: column;
}

.app-containers-Assets-AssetDetail-Usage-___Usage-module__copyButton {
  align-self: flex-end;
  margin-bottom: 5px;
}

.app-containers-Assets-AssetDetail-Usage-___Usage-module__shotLink:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetDetail/Usage/Usage.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,OAAO;EACP,uBAAuB;EACvB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".container {\n  overflow-y: auto;\n  flex: 1;\n  margin: 5px 5px 5px 5px;\n  display: flex;\n  flex-direction: column;\n}\n\n.copyButton {\n  align-self: flex-end;\n  margin-bottom: 5px;\n}\n\n.shotLink:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-containers-Assets-AssetDetail-Usage-___Usage-module__container`,
	"copyButton": `app-containers-Assets-AssetDetail-Usage-___Usage-module__copyButton`,
	"shotLink": `app-containers-Assets-AssetDetail-Usage-___Usage-module__shotLink`
};
export default ___CSS_LOADER_EXPORT___;
