// @flow
import { get, set } from 'lodash'

export function createLocalResource(getLocalStorageKey: () => string): {
  getData: (itemPath: string, defaultValue?: any, session?: boolean) => any,
  setData: (itemPath: string, value: any, session?: boolean) => void,
  ...
} {
  const getData = (itemPath?: string, defaultValue?: any, session?: boolean) => {
    const strData = (session ? sessionStorage : localStorage).getItem(getLocalStorageKey()) || ''
    let data
    try {
      data = JSON.parse(strData) || {}
    } catch (e) {
      data = {}
    }

    if (itemPath) {
      return get(data, itemPath, defaultValue)
    }

    return data
  }

  const setData = (itemPath: string, value: any, session?: boolean) => {
    const data = getData()
    const nextData = set(data, itemPath, value)
    ;(session ? sessionStorage : localStorage).setItem(getLocalStorageKey(), JSON.stringify(nextData))
  }

  return {
    getData,
    setData,
  }
}
