// @flow
import React, { useEffect } from 'react'
import type { Match } from 'react-router-dom'
import type { ID, ResourcesList, TrackingSchema, Asset } from 'app/core/types'
import history from 'app/main/routerHistory'
import { Autocomplete } from 'app/components/Form'
import pipe from 'app/core/utils/pipe'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import { getResources } from 'app/store/selectors'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'

import { TableFollowUp, type FollowUpPermissions } from './TableFollowUp.jsx'
import classes from './RightContent.module.scss'
import { sortTrackingSchemasOptions } from './utils.jsx'

type Props = {|
  match: Match,
  trackingSchemas: ResourcesList<TrackingSchema>,
  trackingSchemaId: ?ID,
  asset: Asset,
  resetTable: boolean,
  permissions?: FollowUpPermissions,
|}

function RightContentView(props: Props): React$Node {
  const { trackingSchemaId, asset, trackingSchemas, match, resetTable, permissions } = props

  const options = sortTrackingSchemasOptions(trackingSchemas, asset.assetType)
  const selected = options.find((option) => option.value === trackingSchemaId) ? trackingSchemaId : null

  function onChangeTrackingSchema({ value }: Object, e: SyntheticMouseEvent<>) {
    history.pushWithParams(props.match, { trackingSchemaId: value }, e.shiftKey ? '?resetTable=true' : undefined)
  }

  function toolbar(selected, options) {
    return (
      <div className={classes.toolbarContainer}>
        <div className={classes.toolbarContainer}>
          <Autocomplete
            selected={selected}
            options={options}
            onChange={onChangeTrackingSchema}
            style={{ marginRight: 5, height: 35, width: 250 }}
            animeKeyPressed={{ shift: true }}
          />
        </div>
      </div>
    )
  }

  const currentTrackingSchema = trackingSchemaId ? trackingSchemas[trackingSchemaId] : null
  const projectId = match.params.projectId || ''

  useEffect(() => {
    if (trackingSchemaId) {
      let title = trackingSchemas[trackingSchemaId]?.name || 'Follow-up'
      if (asset?.name) title = `${asset.name} - ${title}`
      documentTitle(title, 'page')
    }
  }, [asset, trackingSchemaId])

  return (
    <TableFollowUp
      asset={asset}
      trackingSchema={currentTrackingSchema}
      extendToolbar={toolbar(selected, options)}
      projectId={projectId}
      resetTable={resetTable}
      permissions={permissions}
    />
  )
}

const pipeInst: Pipe<{ isExternal?: boolean }, typeof RightContentView> = pipe()

export const RightContentContainer: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => {
    const { isExternal } = props
    const { projectId, assetId, trackingSchemaId = '' } = props.match.params
    const resetTable = new URLSearchParams(window.location.search).get('resetTable') === 'true'

    const trackingSchemas = getResources(state, 'trackingSchemas', (ts) => {
      if (ts.isVisible && ts.project === projectId) {
        if (!isExternal && !ts.isExternal) return true
        if (isExternal && ts.isExternal) return true
        return false
      }
      return false
    })

    return {
      projectId,
      trackingSchemaId,
      trackingSchemas,
      asset: getResources(state, 'assets', assetId),
      resetTable,
    }
  })
  .isLoading((props) => !props.asset)
  .render(({ projectId, history, isExternal, location, ...props }) => <RightContentView {...props} />)
