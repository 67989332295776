/** @flow */
import React, { Fragment, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { map, startsWith } from 'lodash'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { MUIButton } from 'app/components/Form/Button/MUIButton.jsx'
import pipe from 'app/core/utils/pipe'
import { permission, Permission } from 'app/containers/Permissions'
import type { IconType } from 'app/core/types'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'

type MenuItemProps = {|
  label?: string,
  onClick?: Function,
  to?: string,
  image?: string,
  custom?: string | Element,
  permission?: Array<string>,
  operator?: 'and' | 'or',
  icon?: IconType,
  style?: Object,
|}

type Props = {|
  children: any,
  to?: string,
  location: {
    pathname: string,
  },
  items?: Array<MenuItemProps>,
  permission?: Array<string>,
  operator?: 'and' | 'or',
|}

const checkIfActive = (values: Array<string>, pathname: string): boolean => {
  for (let index = 0; index < values.length; index += 1) {
    if (startsWith(pathname, values[index])) return true
  }
  return false
}

function Menu({ items, open, anchorEl, handleClose }: *) {
  const onClickMenuItem = (onClick) => (e) => {
    if (onClick) onClick(e)
    handleClose(e)
  }

  const renderMenuItems = ({
    label,
    style,
    icon,
    to,
    image,
    onClick,
    custom,
    permission: perms,
    operator,
  }: MenuItemProps) => {
    if (custom) return custom

    return (
      <Permission actions={perms} operator={operator} key={label}>
        <MenuItem
          to={to}
          onClick={onClickMenuItem(onClick)}
          component={to ? Link : 'div'}
          style={{ color: '#4a4a4a', fontFamily: 'ralewaybold', ...style }}
        >
          {image && (
            <div
              style={{
                height: 38,
                width: 50,
                margin: '-5px 10px -5px -12px',
                borderRadius: 3,
                background: `center / cover no-repeat url('${image}')`,
              }}
              src={image}
            />
          )}
          {icon ? <FontIcon icon={icon} className="marginRight10" style={{ color: '#666666' }} /> : null}
          <span className="bold fontSize14">{label}</span>
        </MenuItem>
      </Permission>
    )
  }

  return (
    !!items && (
      <Popper open={open} anchorEl={anchorEl.current} transition={true} disablePortal={true}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  style={{
                    maxHeight: 260,
                    position: 'relative',
                    overflow: 'auto',
                    padding: 0,
                  }}
                >
                  {map(items, renderMenuItems)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    )
  )
}

export const NavMenuItem: React$AbstractComponent<any, any> = pipe()
  .withRouter()
  .render((props: Props): React$Node => {
    const { children, to, items, permission: perms, operator, location = {} } = props

    const [open, setOpen] = useState(false)
    const anchorEl = useRef(null)

    function handleToggle() {
      setOpen(!open)
    }

    function handleClose(event) {
      if (event && anchorEl.current && anchorEl.current.contains(event.target)) return
      setOpen(false)
    }

    const isActive = checkIfActive(
      // $FlowFixMe
      [to, ...map(items, (item) => item.to)].filter((_) => _),
      location.pathname,
    )

    return permission(perms, operator) ? (
      <>
        <MUIButton
          style={{
            height: 22,
            boxShadow: 'none',
            borderRadius: 5,
            fontFamily: 'ralewaybold',
            border: isActive ? '2px solid #9be0e7' : undefined,
            backgroundColor: isActive ? '#3DC3D2' : '#ffffff',
            color: isActive ? '#ffffff' : '#4A4A4A',
            fontSize: 15,
            marginRight: 8,
            padding: '0 12px',
          }}
          to={to}
          buttonRef={anchorEl}
          onClick={handleToggle}
        >
          {children}
        </MUIButton>
        <Menu items={items} anchorEl={anchorEl} open={open} handleClose={handleClose} />
      </>
    ) : null
  })
