// @flow

import PubSub from 'pubsub-js'

import { mouseTooltipEventName } from './MouseTooltip'

export type MouseTooltipPublishParams = {|
  tooltip: React$Node,
  clientX: number,
  clientY: number,
|}

export const publishMouseTooltip = (props?: MouseTooltipPublishParams) => {
  PubSub.publish(mouseTooltipEventName, props)
}
