/** @flow */
import React, { useEffect, useState } from 'react'
import type { JobSkillLevel, ResourcesList, PersonAttribute, ID } from 'app/core/types'
import { openModal } from 'app/components/Modal/openModal'
import { ModalAddJobSkillLevel } from './ModalAddJobSkillLevel.jsx'
import { MUIButton } from '../Form/Button/MUIButton.jsx'
import classes from './JobSkillLevelRead.module.scss'

type Props = {|
  jobSkillLevels: ($Shape<JobSkillLevel> | ID)[],
  onChange?: (jobSkillLevels: ($Shape<JobSkillLevel> | ID)[]) => void,
  skillAttributes: PersonAttribute[], // attrType === skill
  editable?: boolean,
  style?: Object,
|}

export function JobSkillLevelInput(props: Props): React$Node {
  const { jobSkillLevels = [], onChange, editable, style, skillAttributes } = props
  const [personAttributes, setPersonAttributes] = useState<ResourcesList<PersonAttribute>>({})

  useEffect(() => {
    setPersonAttributes(
      skillAttributes.reduce(
        (acc: ResourcesList<PersonAttribute>, personAttribute: PersonAttribute) => ({
          ...acc,
          [personAttribute.id]: personAttribute,
        }),
        {},
      ),
    )
  }, [skillAttributes])

  function onRemoveSkillLevel(index: number) {
    const jobSkillLevel = jobSkillLevels[index]

    if (typeof jobSkillLevel === 'string') onChange?.(jobSkillLevels)
    else if (jobSkillLevel.id) {
      const newJobSkillLevels = [...jobSkillLevels]
      newJobSkillLevels[index] = jobSkillLevel.id
      onChange?.(newJobSkillLevels)
    } else {
      const newJobSkillLevels = [...jobSkillLevels]
      newJobSkillLevels.splice(index, 1)

      onChange?.(newJobSkillLevels)
    }
  }

  function openAddSkillLevel(index: number | void) {
    let jobSkillLevel

    if (typeof index === 'number' && typeof jobSkillLevels[index] !== 'string') jobSkillLevel = jobSkillLevels[index]

    openModal(
      <ModalAddJobSkillLevel
        personAttributes={personAttributes}
        onChange={(skillLevel) => {
          if (typeof index === 'number') {
            const newJsl = [...jobSkillLevels]
            newJsl[index] = skillLevel
            onChange?.(newJsl)
          } else onChange?.([...jobSkillLevels, skillLevel])
        }}
        jobSkillLevel={jobSkillLevel}
      />,
    )
  }

  return (
    <div className="fullWidth" style={style}>
      {editable ? (
        <div className="flex end fullWidth paddingBottom5 paddingTop5">
          <MUIButton
            icon="fas-plus"
            tooltip="Add a skill level"
            onClick={() => openAddSkillLevel()}
            style={{ maxWidth: 20, maxHeight: 20, minWidth: 20, minHeight: 20 }}
          />
        </div>
      ) : null}
      {jobSkillLevels.length > 0 ? (
        <div className={classes.grid} style={{ gridTemplateColumns: !editable ? 'auto auto auto' : undefined }}>
          <div className={classes.gridHeader}>Skill</div>
          <div className={classes.gridHeader}>Minimum level</div>
          <div className={classes.gridHeader}>Maximum level</div>
          {editable ? <div className={classes.gridHeader}>Edit</div> : null}
          {jobSkillLevels
            .map((sl, index) => {
              if (typeof sl === 'string') return null
              return [
                <div className={classes.gridItem} key={`${sl.id}-skill`}>
                  {personAttributes[sl.skill]?.name}
                </div>,
                <div className={classes.gridItem} key={`${sl.id}-levelMin`}>
                  {sl.levelMin}
                </div>,
                <div className={classes.gridItem} key={`${sl.id}-levelMax`}>
                  {sl.levelMax}
                </div>,
                editable ? (
                  <div className={`${classes.gridItem} flex row spaceAround alignCenter`} key={`${sl.id}-edit`}>
                    <MUIButton
                      icon="fas-edit"
                      tooltip="Edit"
                      variant="text"
                      onClick={() => openAddSkillLevel(index)}
                      style={{ maxWidth: 20, maxHeight: 20, minWidth: 20, minHeight: 20 }}
                    />
                    <MUIButton
                      icon="fas-trash"
                      tooltip="Remove"
                      variant="text"
                      onClick={() => onRemoveSkillLevel(index)}
                      style={{ maxWidth: 20, maxHeight: 20, minWidth: 20, minHeight: 20 }}
                    />
                  </div>
                ) : null,
              ]
            })
            .filter(Boolean)}
        </div>
      ) : null}
    </div>
  )
}
