// @flow
import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { getImpersonificationUser } from 'app/pages/Admin/Impersonification/impersonificationUser'
import { Container, Nav, LeftContent, RightContent } from 'app/components/LeftNav'
import { Permission } from 'app/containers/Permissions/index.js'
import { NavTitle } from 'app/components/LeftNav/NavTitle.jsx'
import Impersonification from './Impersonification/Impersonification.jsx'
import Messages from './Messages/Messages.jsx'
import Assets from './Assets/Assets.jsx'
import { ProjectsList } from './ProjectsList/ProjectsList.jsx'
import { Groups } from './Groups/Groups.jsx'
import { StatusEditing } from './StatusEditing/StatusEditing.jsx'
import TokensManagement from './TokensManagement/TokensManagement.tsx'

type Props = {}

export default function (props: Props): React$Node {
  const impersonificationUser = getImpersonificationUser()
  // if user is an imitator, admin is set to false in order to access admin pages
  const admin = impersonificationUser

  return (
    <Permission isAdmin={admin} actions={['']}>
      <Container data-cy="page-admin">
        <LeftContent>
          {(isOpen) => (
            <Nav>
              <NavTitle label="Impersonification" to="/admin/impersonification" icon="fas-user-secret" />
              <NavTitle label="Messages" to="/admin/messages" icon="fas-envelope" />
              <NavTitle label="Groups" to="/admin/groups" icon="fas-users" />
              <NavTitle label="Assets" to="/admin/assets" icon="fas-cubes" />
              <NavTitle label="Projects list" to="/admin/projects" icon="fas-th-list" />
              <NavTitle label="Status editing" to="/admin/status" icon="fas-flag" />
              <NavTitle label="Tokens management" to="/admin/tokens" icon="fas-key" />
            </Nav>
          )}
        </LeftContent>
        <RightContent>
          <Switch>
            <Route path="/admin/impersonification" component={(props) => <Impersonification {...props} />} />
            <Route path="/admin/messages" component={Messages} />
            <Route path="/admin/groups/:groupId?" component={Groups} />
            <Route path="/admin/assets" component={Assets} />
            <Route path="/admin/projects" component={ProjectsList} />
            <Route path="/admin/status" component={StatusEditing} />
            <Route path="/admin/tokens" component={TokensManagement} />
          </Switch>
        </RightContent>
      </Container>
    </Permission>
  )
}
