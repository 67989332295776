export default (assetInst) => {
  if (!assetInst) return ''
  let userName = assetInst.name

  if (assetInst.attributes.firstName && assetInst.attributes.lastName) {
    userName = `${assetInst.attributes.firstName} ${assetInst.attributes.lastName}`
  }

  return userName
}
