/** @flow */
import React, { useEffect, useMemo } from 'react'
import type { Match, Asset } from 'app/core/types'
import Widget from 'app/components/Widget/Widget.jsx'
import { AssetsTreeSearch } from 'app/containers/Assets/AssetsTree'
import history from 'app/main/routerHistory'
import { BreakdownTable } from 'app/pages/Project/Breakdown/BreakdownTable.jsx'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import { permission } from 'app/containers/Permissions'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import { getResources } from 'app/store/selectors'

type Props = {|
  match: Match,
|}

export function Breakdown(props: Props): React$Node {
  const { match } = props
  const { projectId, breakdownItemId } = match.params

  const onClickTreeItem = ({ metadata }) => {
    history.push(`/projects/${projectId || ''}/breakdown/${metadata.id}`)
  }

  const onSearchAsset = (assetId) => {
    const { projectId } = match.params
    history.push(`/projects/${projectId || ''}/breakdown/${assetId}`)
  }

  const episode: Asset = useMemo(() => getResources(undefined, 'assets', breakdownItemId)?.[0], [breakdownItemId])

  useEffect(() => {
    documentTitle(episode?.name)
  }, [episode])

  const resourceParams = useMemo(
    () =>
      breakdownItemId
        ? {
            resourceType: 'assets',
            requestName: 'childrenWithLinks',
            headers: episode ? { [window.OVM_PROJECT_HEADER]: episode.project || '' } : undefined,
            includedResources: [
              'assetLinksInst.to_assetInst',
              'assetFlagsInst',
              'assetFlagsInst.flagInst',
              'thumbnailInst',
            ],
            requestData: {
              id: breakdownItemId,
            },
          }
        : undefined,
    [episode, breakdownItemId],
  )

  return (
    <div style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
      <Widget
        style={{ paddingRight: 0 }}
        enableHideColumn={true}
        collapseTitle="Asset list"
        resizable={true}
        optionName="widget-breakdown"
        defaultWidth={300}
      >
        <AssetsTreeSearch
          onClickItem={onClickTreeItem}
          currentAssetId={breakdownItemId}
          allowCreate={true}
          rootAssetId={projectId}
          resizable={true}
          onSearchAsset={onSearchAsset}
        />
      </Widget>
      <div className="fullHeight flex1">
        <BreakdownTable
          parentAssetId={breakdownItemId}
          enableEdit={permission(['projet_breakdown_links_create'])}
          projectId={episode?.project || projectId || getProjectIdFromURL()}
          resourcesParams={resourceParams}
        />
      </div>
    </div>
  )
}
