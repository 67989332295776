// @flow
import React, { useContext, type Element } from 'react'
import { HorizontalCollapse } from 'app/components/Transitions/HorizontalCollapse.jsx'
import { MenuContext } from 'app/main/Authenticated/Authenticated.tsx'
import cx from 'classnames'
import classes from './Nav.module.scss'

type Props = {|
  className?: string,
  children: (isOpen: boolean) => Array<Element<any>> | Element<any>,
|}

export default function (props: Props): React$Node {
  const { children, className, ...rest } = props
  const { isOpen } = useContext(MenuContext)

  return (
    <HorizontalCollapse open={isOpen} minWidth={40} noHiddenOverflow={true}>
      <div className={cx([classes.leftContent, className])} style={{ minWidth: 300 }} {...rest}>
        {children(isOpen)}
      </div>
    </HorizontalCollapse>
  )
}
