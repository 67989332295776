/** @flow */
import React from 'react'
import resources from 'app/store/resources'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import type { Schema } from 'app/core/types'
import FormData from 'app/components/Form/FormData/FormData.jsx'

type FormDataDefaultData = {|
  name: string,
  lockColumnOrder: boolean,
|}

type ModalEditPeopleTableSchemaProps<I, GS, CS, SS> = {|
  ...$Shape<$Diff<ModalProps, { children: any }>>,
  peopleSchema?: Schema<I, GS, CS, SS>,
  onChange?: (data: FormDataDefaultData) => void,
  onSaved?: (peopleSchema: Schema<I, GS, CS, SS>) => void,
|}

export function ModalEditPeopleTableSchema<I, GS, CS, SS>(
  props: ModalEditPeopleTableSchemaProps<I, GS, CS, SS>,
): React$Node {
  const { peopleSchema, onChange, onSaved, ...rest } = props

  async function onSave(data: FormDataDefaultData) {
    const { name, lockColumnOrder } = data
    if (onChange) return onChange(data)
    const res = await resources.peopleSchemas[peopleSchema?.id ? 'update' : 'create']({
      schema: [],
      ...peopleSchema,
      name,
      settings: {
        ...peopleSchema?.settings,
        lockColumnOrder,
      },
    })
    if (onSaved) onSaved(res.resources[0])
    return res
  }

  return (
    <ModalConfirmForm
      title={peopleSchema ? `Update ${peopleSchema.name}` : 'Create a new table'}
      draggable={false}
      {...rest}
    >
      <FormData
        defaultData={
          ({
            name: peopleSchema?.name || '',
            lockColumnOrder: Boolean(peopleSchema?.settings?.lockColumnOrder),
          }: FormDataDefaultData)
        }
        properties={[
          {
            key: 'name',
            label: 'Name',
            type: 'string',
            elementProps: {
              isRequired: true,
              placeholder: 'Table name',
            },
          },
          {
            key: 'lockColumnOrder',
            label: 'Lock column order',
            type: 'checkbox',
          },
        ]}
        onSave={onSave}
        flashNotifSuccessLabel={`Table ${peopleSchema ? 'updated' : 'created'}`}
      />
    </ModalConfirmForm>
  )
}
