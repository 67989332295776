import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { MUIButton } from 'app/components/Form'
import settings from 'app/core/settings'
import { dateToLocaleStringFull } from 'app/libs/helpers'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { functionsByContentType, getActionObjectTypeIdentifier, redirectAction } from 'app/core/utils/contentTypesUtils'
import classes from './Notification.module.scss'

export default class Notification extends React.Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    isLastNotif: PropTypes.bool,
    onClickNotif: PropTypes.func,
  }

  dateToString() {
    const { timestamp } = this.props.notification.actionInst
    return dateToLocaleStringFull(new Date(timestamp))
  }

  onClickNotif = (event) => {
    const { notification, onClickNotif } = this.props

    if ((onClickNotif && event.button === 0) || event.button === 1) onClickNotif(notification)
  }

  renderXLSLDownload = () => {
    const { notification, isLastNotif } = this.props
    const { actionInst } = notification

    const className = cx(classes.container, {
      [classes.isLastNotif]: isLastNotif,
      [classes.isRead]: !notification.unread,
    })

    return (
      <div className={`${className} flex row alignCenter cursor`} onMouseDown={this.onClickNotif}>
        <div style={{ color: 'grey', fontSize: 28, margin: '10px 20px' }}>
          <FontIcon icon="fas-file-excel" />
        </div>
        <div className="flex column">
          <div className={classes.title}>
            {functionsByContentType[
              getActionObjectTypeIdentifier(actionInst.actionObjectType, actionInst.verb)
            ].labelNotif(notification)}
          </div>
          <div className={classes.notifDate}>{this.dateToString()}</div>
        </div>
        <a
          href={`${settings.env.API_URL}/${actionInst.datas.fileName}`}
          download={actionInst.datas.fileName}
          target="_blank"
          rel="noreferrer noopener"
          style={{ margin: '10px 20px 10px auto' }}
        >
          <MUIButton
            icon="fas-save"
            style={{ height: 40, width: 40, fontSize: 20 }}
            tooltip="Download"
            variant="text"
            size="large"
          />
        </a>
      </div>
    )
  }

  render() {
    const { notification, isLastNotif } = this.props
    const { actionInst } = notification

    const { verb } = actionInst

    if (verb === 'xlslExport') return this.renderXLSLDownload()

    const className = cx(classes.container, {
      [classes.isLastNotif]: isLastNotif,
      [classes.isRead]: !notification.unread,
    })

    return (
      // TODO add icon for each type of notif
      <Link className={className} to={redirectAction(notification, { getPath: true })} onMouseDown={this.onClickNotif}>
        <div className={classes.title}>
          {functionsByContentType[
            getActionObjectTypeIdentifier(actionInst.actionObjectType, actionInst.verb)
          ].labelNotif(notification)}
        </div>

        <div className={classes.notifDate}>{this.dateToString()}</div>
      </Link>
    )
  }
}
