/** @flow */
import React, { useState, type Element } from 'react'
import { MUIModal, type ModalProps } from 'app/components/Modal/MUIModal.jsx'
import { setHowToVisitedPages } from 'app/components/HowTo/utils.js'

type Props = {|
  ...ModalProps,
  pageName: string,
  children: string | Element<any>,
  onValidate?: Function,
|}

export function HowToModal(props: Props): React$Node {
  const { pageName, children, onValidate, ...rest } = props
  const [openModal, setOpenModal] = useState(true)

  function _onValidate() {
    setOpenModal(false)
    setHowToVisitedPages(pageName, true)

    // $FlowFixMe
    if (onValidate) onValidate()

    return Promise.resolve()
  }

  return (
    <MUIModal hideCancel={true} open={openModal} onValidate={_onValidate} {...rest}>
      {children}
    </MUIModal>
  )
}
