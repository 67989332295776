import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import classes from './Nav.module.scss'

function Container({ children, className, ...props }) {
  return (
    <div className={cx([classes.container, className])} {...props}>
      {children}
    </div>
  )
}

Container.propTypes = {
  className: PropTypes.string,
}

export default Container
