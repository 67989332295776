import PropTypes from 'prop-types'

export default (propType, test) => {
  return (props, propName, componentName) => {
    const check = PropTypes.checkPropTypes({ [propName]: propType }, props, 'prop', componentName)

    if (check) return check

    const error = test(props, propName, componentName)

    if (error) {
      throw new Error(error)
    }

    return null
  }
}
