// @flow

import React from 'react'
import cx from 'classnames'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import type { DefaultCellProps, Column, Cell } from 'app/components/Table/types.js'
import { MUISelect, type SelectProps } from 'app/components/Form/Select/MUISelect.jsx'
import type { Options, Option } from 'app/core/types'
import classes from './CellSelect.module.scss'

const shortcuts = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]

export type CellSelectParams = {|
  ...DefaultCellProps,
  readMask?: Function,
  getColor?: (cell: Cell) => string,
  options: (cell: Cell) => Options,
  isHashed?: (cell: Cell) => boolean,
  componentProps?: $Shape<SelectProps>,
|}

type ReadProps = {|
  value?: Option,
  readMask: Function,
  isHashed: boolean,
|}

function Read({ value, readMask, isHashed }: ReadProps) {
  if (!value) return null
  const { backgroundColor, label } = value

  return (
    <div
      style={
        backgroundColor
          ? {
              background: isHashed
                ? `repeating-linear-gradient(120deg, ${backgroundColor}, ${backgroundColor} 6px, ${backgroundColor}99 6px, ${backgroundColor}99 12px)`
                : backgroundColor,
              fontWeight: 'bold',
              color: getColorFromBackground(backgroundColor),
            }
          : undefined
      }
      className={cx(classes.read, {
        [classes.backgroundColor]: !!backgroundColor,
      })}
    >
      <div style={isHashed && backgroundColor ? { backgroundColor, padding: '0 5px', borderRadius: 4 } : undefined}>
        {readMask(label)}
      </div>
    </div>
  )
}

export const CellSelect = ({
  getColor,
  options,
  actions,
  componentProps = {},
  readMask = (value) => value,
  isHashed,
  cellName = 'Cell',
  ...data
}: CellSelectParams): $Shape<Column> => ({
  cellType: 'CellSelect',
  Cell: (instance) => {
    const { cell } = instance
    const { getCellProps } = cell
    const { edition } = getCellProps()
    const { isBeingEdit, endEdit, save } = edition
    const optionsValue = options(cell)
    const currentValue = optionsValue.find(({ value }) => value === cell.value)
    const backgroundIsHashed = isHashed ? isHashed(cell) : false

    return (
      <div className={classes.container}>
        {isBeingEdit ? (
          <div style={{ height: '100%', overflow: 'hidden', position: 'absolute', top: 0, left: 0 }}>
            <MUISelect
              value={cell.value}
              options={optionsValue}
              onChange={({ value }) => {
                save(value)
                cell.focusCell()
              }}
              open={isBeingEdit}
              onClose={endEdit}
              style={{ width: '100%' }}
              hidden={true}
              {...componentProps}
            />
          </div>
        ) : (
          <Read value={currentValue} readMask={readMask} isHashed={backgroundIsHashed} />
        )}
      </div>
    )
  },
  actions: (instance, cell) => {
    const { edition } = cell.getCellProps()
    const { save } = edition
    const { disabledOptions } = componentProps || {}

    const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []

    let optionsValue = options(cell)
    if (disabledOptions) {
      optionsValue = optionsValue.filter((opt) => !disabledOptions.includes(opt.value))
    }

    if (
      // $FlowFixMe
      resolvedActions.find((act) => act === 'edit' || (typeof act === 'object' && act?.label === 'Edit'))
    ) {
      return [
        ...resolvedActions,
        { separator: true },
        {
          label: 'Select',
          editAction: true,
          items: optionsValue.map((opt, index) => ({
            onClick: () => {
              cell.focusCell()
              save(opt.value)
            },
            editAction: true,
            label: opt.label,
            color: opt.backgroundColor,
            hotKeys: [shortcuts[index]],
            rightLabel: shortcuts[index],
          })),
        },
      ]
    }

    return resolvedActions
  },
  groupingFns: {
    rating: {
      fn: (rows, columnId, instance) =>
        rows.reduce((prev, row, i) => {
          const resKey = String(row.values[columnId])
          prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
          prev[resKey].push(row)
          return prev
        }, {}),
      label: 'Value',
      headerRow: (cell) => {
        if (cell.value === undefined) return null
        return <span className="bold">{String(cell.value)}</span>
      },
    },
  },
  width: 150,
  ...data,
})
