// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-ButtonMenu-___ButtonMenu-module__paper {
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./app/components/ButtonMenu/ButtonMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[".paper {\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `app-components-ButtonMenu-___ButtonMenu-module__paper`
};
export default ___CSS_LOADER_EXPORT___;
