// @flow
import { padStart, map } from 'lodash'
import { operators as op, presetOperators as pop } from 'app/components/Table/TableView/Elements/Filters/operators.ts'
import type { Option, ColumnFilter } from 'app/core/types'

type Params = {|
  notes: Array<Option>,
|}

export class Filters {
  constructor({ notes }: Params) {
    this.params = {
      ...this.params,
      notesOptions: notes,
    }
  }

  params: { notesOptions: Array<Option> } = {}

  formatMultipleValues: (value: string, filterKey?: string) => string = (value, filterKey) => {
    if (filterKey) {
      return `${filterKey}=${JSON.stringify({
        foreignKeyName: 'step',
        field: 'text',
        foreignKeyList: value.split(','),
      })}`
        .replace('undefined', 'general')
        .replace('null', 'general')
    }
    return JSON.stringify({
      foreignKeyName: 'step',
      field: 'text',
      foreignKeyList: value.split(','),
    })
      .replace('undefined', 'general')
      .replace('null', 'general')
  }

  getFilters(): Array<ColumnFilter> {
    return [
      {
        label: 'Asset',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => ({
            ...op,
            attr: 'uuid',
            assetTypes: ['sh'],
            multiple: true,
            inputType: 'asset',
            getSubLabel: (asset: Object) => {
              if (!asset) return undefined
              return asset.parent__name
            },
          })),
          ...map(pop.text, (op) => ({
            ...op,
            attr: 'name',
            inputType: 'text',
          })),
        ],
      },
      {
        label: 'Sequence',
        type: 'column',
        operators: [
          ...map([op.exact, op.notExact].concat(pop.list), (op) => ({
            ...op,
            attr: 'attributes__sequence',
            formatValue: (value: string) => padStart(value, 3, '0'),
            inputType: 'number',
          })),
          ...map([op.isNull, op.isNotNull], (op) => ({
            ...op,
            attr: 'attributes__sequence',
          })),
        ],
      },
      {
        label: 'Flags',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => ({
            ...op,
            attr: 'flags__uuid',
            multiple: true,
            inputType: 'flags',
          })),
          ...map([op.countIsEmpty, op.countIsNotEmpty], (op) => ({
            ...op,
            attr: 'flags__uuid',
          })),
        ],
      },
      {
        label: 'Refs medias',
        type: 'column',
        operators: [
          ...map([op.countIsEmpty, op.countIsNotEmpty], (op) => ({
            ...op,
            attr: 'mediaGroups__medias__uuid',
          })),
        ],
      },
      {
        label: 'Notes',
        type: 'column',
        operators: [
          ...map(pop.text, (op) => ({
            ...op,
            attr: 'assetPostBoardLinks__notes__text',
            inputType: 'text',
          })),
          {
            value: 'relationEmptyWithValue',
            label: 'Is empty',
            type: 'operator',
            attr: 'assetPostBoardLinks__notes',
            formatMultipleValues: this.formatMultipleValues,
            serverKey: 'relationempty',
            resourceValue: 'steps',
            multiple: true,
            options: {
              label: 'Step',
              data: this.params.notesOptions,
            },
            inputType: 'select',
          },
          {
            value: 'relationNotEmptyWithValue',
            label: 'Is not empty',
            type: 'operator',
            attr: 'assetPostBoardLinks__notes',
            formatMultipleValues: this.formatMultipleValues,
            serverKey: 'relationempty!',
            resourceValue: 'steps',
            multiple: true,
            options: {
              label: 'Step',
              data: this.params.notesOptions,
            },
            inputType: 'select',
          },
        ],
      },
    ]
  }
}
