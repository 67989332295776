// @flow
import React from 'react'
import { pickBy, map, find, sortBy } from 'lodash'
import ExpansionPanel from 'app/components/ExpansionPanel/ExpansionPanel.jsx'
import pipe from 'app/core/utils/pipe'
import { type Pipe } from 'app/core/utils/pipeNext.type'
import resources from 'app/store/resources'
import { getResources } from 'app/store/selectors/getResources'
import type { ID } from 'app/core/types'

import { DisplayTakeValidationMedias } from './DisplayTakeValidationMedias.jsx'

type Props = {|
  tasks: Object,
  progressionStatus: Object,
|}

function ValidationMedias(props: Props): React$Node {
  const { tasks = {}, progressionStatus } = props

  return (
    <div className="fullWidth overflowYAuto padding15 fullHeight">
      {map(tasks, (task, index: number | string) => {
        return (
          <ExpansionPanel
            key={task.stepInst.name}
            title={task.stepInst.name}
            defaultExpanded={index === tasks.length - 1}
          >
            <div className="fullWidth">
              {map(task.takesInst, (take) => {
                const status = progressionStatus[take.status]

                const [validatedMedias, totalValidatedMedias] = take.takeValidationMediasInst.reduce(
                  (acc, takeMedia: Object) => {
                    if (typeof takeMedia.mediaInst?.validated === 'boolean') acc[1] += 1
                    if (takeMedia.mediaInst?.validated === true) acc[0] += 1
                    return acc
                  },
                  [0, 0],
                )

                return (
                  <ExpansionPanel
                    key={take.number}
                    defaultExpanded={take.validationMedias.length > 0}
                    title={
                      <div className="flex row noWrap alignCenter">
                        Take {take.number}{' '}
                        {status ? (
                          <div
                            style={{
                              backgroundColor: status.color,
                              color: '#ffffff',
                              padding: '1px 8px',
                              marginLeft: 20,
                              fontSize: 12,
                              fontWeight: 'bold',
                              borderRadius: 3,
                            }}
                          >
                            {status.name}
                          </div>
                        ) : null}
                      </div>
                    }
                    centerContent={
                      take.validationMedias.length === 0
                        ? 'No validation media'
                        : `${take.validationMedias.length} validation medias`
                    }
                    rightContent={
                      totalValidatedMedias > 0 ? (
                        <span className="marginRight20">
                          {validatedMedias}/{totalValidatedMedias} medias validated
                        </span>
                      ) : null
                    }
                    disableExpansionPanel={take.validationMedias.length === 0}
                  >
                    <div className="flex center alignCenter fullWidth">
                      {take.validationMedias.length > 0 ? <DisplayTakeValidationMedias take={take} /> : null}
                    </div>
                  </ExpansionPanel>
                )
              })}
            </div>
          </ExpansionPanel>
        )
      })}
    </div>
  )
}

type PipeType = Pipe<{ params: { assetId: ID }, projectId?: ID }, typeof ValidationMedias>

const pipeInst: PipeType = pipe()

const ValidationMediasComponent: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => {
    const { projectId } = props
    const stepProjects = map(state.stepProjects.resources)
    const tasks = getResources(state, 'tasks', { asset: props.params.assetId }, [
      'stepInst',
      'statusInst',
      'takesInst',
      'takesInst.takeValidationMediasInst.mediaInst',
    ])
    const progressionStatus = getResources(state, 'progressionStatus')
    const rankedTasks = map(
      pickBy(
        tasks,
        (task) => !!task && !!task.stepInst && ['fo', 'ep', 'sh', 'mo'].indexOf(task.stepInst.assetType) !== -1,
      ),
      (task) => {
        const step = task.stepInst
        const projectStep = find(stepProjects, (sp) => sp.step === step.id)

        return { ...task, stepInst: { ...step, rank: projectStep && projectStep.rank } }
      },
    )

    return {
      progressionStatus,
      projectId,
      tasks: sortBy(rankedTasks, ['stepInst.rank']),
    }
  })
  .request(async (props) => {
    const { projectId } = props
    const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } } }

    return Promise.all([
      resources.tasks.fetchByAsset({ id: props.params.assetId }, requestsConfig),
      resources.progressionStatus.fetchAll(requestsConfig),
      resources.stepProjects.fetchAll(requestsConfig),
    ])
  })
  .render(({ projectId, history, location, match, params, ...props }) => <ValidationMedias {...props} />)

export default ValidationMediasComponent
