// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__container {
  position: relative;
}

  .app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__container.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__error {
    border-color: var(--colors-red);
    box-shadow: 0 0 3px var(--colors-red);
  }

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__icon {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px var(--colors-grey);
  pointer-events: none;
}

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__searchResults {
  border: var(--mainBorder);
  overflow-y: auto;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0px 3px 6px 1px #ccc;
  height: 100%;
  min-width: 100%;
}

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__searchResultsItem {
  padding: 5px 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__searchResultsItem:not(.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__disabled):hover,
  .app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__searchResultsItem.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__highlighted {
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.1) inset !important;
  }

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__searchResultsItem .autocomplete-highlight {
      font-weight: bold;
      color: var(--colors-red);
    }

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__noResults {
  padding: 5px 10px;
}

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__fieldError {
  position: absolute;
  right: 0px;
}

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__shiftPressed:hover {
  cursor: context-menu;
}

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__ctrlPressed:hover {
  cursor: copy;
}

.app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__metaPressed:hover {
  cursor: no-drop;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Autocomplete/AutocompleteSingle/AutocompleteSingle.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AAMpB;;EAJE;IACE,+BAA+B;IAC/B,qCAAqC;EACvC;;AAGF;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yCAAyC;EACzC,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,sBAAsB;EACtB,YAAY;EACZ,gCAAgC;EAChC,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,mBAAmB;AAarB;;AAXE;;IAEE,4DAA4D;EAC9D;;AAGE;MACE,iBAAiB;MACjB,wBAAwB;IAC1B;;AAIJ;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  position: relative;\n\n  &.error {\n    border-color: var(--colors-red);\n    box-shadow: 0 0 3px var(--colors-red);\n  }\n}\n\n.icon {\n  position: absolute;\n  right: 0px;\n  top: 0px;\n  height: 100%;\n  width: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-left: solid 1px var(--colors-grey);\n  pointer-events: none;\n}\n\n.searchResults {\n  border: var(--mainBorder);\n  overflow-y: auto;\n  background-color: #fff;\n  z-index: 100;\n  box-shadow: 0px 3px 6px 1px #ccc;\n  height: 100%;\n  min-width: 100%;\n}\n\n.searchResultsItem {\n  padding: 5px 10px;\n  user-select: none;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n\n  &:not(.disabled):hover,\n  &.highlighted {\n    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.1) inset !important;\n  }\n\n  :global {\n    .autocomplete-highlight {\n      font-weight: bold;\n      color: var(--colors-red);\n    }\n  }\n}\n\n.noResults {\n  padding: 5px 10px;\n}\n\n.fieldError {\n  position: absolute;\n  right: 0px;\n}\n\n.shiftPressed:hover {\n  cursor: context-menu;\n}\n\n.ctrlPressed:hover {\n  cursor: copy;\n}\n\n.metaPressed:hover {\n  cursor: no-drop;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__container`,
	"error": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__error`,
	"icon": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__icon`,
	"searchResults": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__searchResults`,
	"searchResultsItem": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__searchResultsItem`,
	"disabled": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__disabled`,
	"highlighted": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__highlighted`,
	"noResults": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__noResults`,
	"fieldError": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__fieldError`,
	"shiftPressed": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__shiftPressed`,
	"ctrlPressed": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__ctrlPressed`,
	"metaPressed": `app-components-Form-Autocomplete-AutocompleteSingle-___AutocompleteSingle-module__metaPressed`
};
export default ___CSS_LOADER_EXPORT___;
