import history from 'app/main/routerHistory'
import { userPrefLocalResource } from 'app/core/utils/localUserPrefs'

/**
 * @param object query
 *
 * @return string
 *
 * If you want to remove an existing query, use '$remove' as vlaue of key.
 *
 * example:
 *
 *     createAbsolutePathWithQuery({
 *         foo: 'bar',
 *         baz: '$remove'
 *     })
 *
 */
export const createAbsolutePathWithQuery = (query) => {
  const { location } = window

  const path = location.pathname

  const queries = location.search
    .replace('?', '')
    .split('&')
    .filter((field) => field)
    .map((param) => param.split('='))

  for (const entry of Object.entries(query)) {
    if (entry[1] === '$remove') {
      const indexToRemove = queries.findIndex((field) => field[0] === entry[0])

      if (indexToRemove !== -1) {
        queries.splice(indexToRemove, 1)
      }
    } else {
      const index = queries.findIndex((_entry) => _entry[0] === entry[0])

      if (index !== -1) {
        queries[index][1] = entry[1]
      } else {
        queries.push([entry[0], entry[1]])
      }
    }
  }

  return queries.length === 0 ? path : `${path}?${queries.map((field) => field.join('=')).join('&')}`
}

/**
 * @return object
 */
export const getQuery = () => {
  const { location } = window

  const queries = location.search
    .replace('?', '')
    .split('&')
    .filter((field) => field)
    .map((param) => param.split('='))

  const output = {}

  for (const entry of queries) {
    output[entry[0]] = entry[1]
  }

  return output
}

/**
 * @param object query
 */
export const setQuery = (query) => history.push(createAbsolutePathWithQuery(query))

/**
 * @param string name
 *
 * @return string
 */
export const getQueryField = (name) => getQuery()[name]

/**
 * @param string name
 * @param string value
 */
export const setQueryField = (name, value) => setQuery({ [name]: value })

/**
 * @param string str
 * @param object data
 *
 * example:
 *      createPath('/foo/:bar', {bar: 'test'})
 *
 *      :bar will be replace by test. The output is /foo/test
 */
export const createPath = (str, data) => {
  // eslint-disable-next-line
  const matched = str.match(/(:[^\/]+[a-zA-Z])/g)

  if (!matched) return str

  let output = str

  for (const match of matched) {
    let key = match.substring(1)

    if (key.startsWith('!')) key = key.substring(1)

    output = output.replace(match, data[key])
  }

  return output
}

/**
 * @param string path
 */
// export const push = path => browserHistory.push(path);

export const linkPropsLocalStorageSetter = (tabName) => (props) => {
  const { name, activeName, onClick } = props
  return {
    ...props,
    onClick: () => {
      if (onClick) onClick()
      if (activeName || name) userPrefLocalResource.setData(`tabs.${tabName}`, activeName || name)
    },
  }
}

export const pathInterceptorLocalStorageGetter = (tabName) => (routeName, path) => {
  if (routeName === 'index') {
    const lastTab = userPrefLocalResource.getData(`tabs.${tabName}`)
    if (!lastTab) return path
    const typeId = path.split('/')[1]
    return `/${typeId}/${lastTab}`
  }
  return path
}
