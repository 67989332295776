// @flow
import type { AssetsTypesParentTypeList } from './AssetsTypesParentTypeList.type'

export const assetsTypesParentTypeList: AssetsTypesParentTypeList = {
  ep: ['fo'],
  fo: ['fo', 'pj'],
  gp: ['fo'],
  lc: ['fo'],
  mo: ['fo'],
  pc: ['fo'],
  pj: ['fo'],
  sh: ['ep'],
  sq: ['ep'],
  us: ['fo'],
  of: ['fo'],
}
