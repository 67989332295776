/** @flow */
import React from 'react'
import classes from './CustomLogo.module.css'

export function CustomLogo(
  colors: Array<string>,
  name: string,
  className?: string,
  splitter?: string,
): React$Element<'div'> {
  const color = colors[(name.length * 100) % colors.length]
  const words = name.split(splitter || ' ')
  const firstLetters = words
    .slice(0, 3)
    .map((word) => word && word[0])
    .filter((_) => _)
    .join('')

  const { length } = firstLetters

  return (
    <div
      className={`${classes} flex center alignCenter bold ${className || ''}`}
      style={{ backgroundColor: color, fontSize: length === 3 ? 17 : length === 2 ? 20 : 26 }}
    >
      {firstLetters}
    </div>
  )
}
