/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { TextEditor } from './Editor.jsx'

export function Tools() {
  return (
    <Switch>
      <Route
        exact={true}
        path="/tools"
        component={() => (
          <div className="padding20 overflowXAuto overflowYAuto">
            <p className="margin10">
              <h3>
                <Link to="/tools/editor">Editor</Link>
              </h3>
              <div className="padding20">
                <div>You can access the CKEditor module through the window.CKEditor shortcut</div>
                <div>
                  The available API for the module is here:{' '}
                  <a href="https://ckeditor.com/docs/ckeditor5/latest/api/index.html">CKEditor API</a>
                </div>
                <div className="paddingTop20 paddingBottom20">
                  <div className="bold">Features</div>
                  <div className="padding20">
                    Add <code>?toolbar={`<base64Encoded Array>`}</code> to determine toolbar elements.
                    <br />
                    Default value is:
                    <pre>
                      {`[
  'heading',
  '|',
  'fontSize',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'outdent',
  'indent',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'bulletedList',
  'numberedList',
  'alignment',
  'blockQuote',
  'link',
  'horizontalLine',
  '|',
  'imageUpload',
  'insertFile',
  'insertTable',
  '|',
  'codeBlock',
  'code',
  '|',
  'undo',
  'redo',
]`}
                    </pre>
                  </div>
                </div>
              </div>
            </p>
            <p className="margin10">
              <h3>
                <Link to="/tools/editor-raw-css">Editor css</Link>
              </h3>
              <div className="padding20">
                Raw editor css selectable with id: <code>#editor-raw-css</code>
              </div>
            </p>
            <p className="margin10">
              <h3>
                <Link to="/tools/reader-raw-css">Reader css</Link>
              </h3>
              <div className="padding20">
                Raw reader css selectable with id: <code>#reader-raw-css</code>
              </div>
            </p>
          </div>
        )}
      />
      <Route exact={true} path="/tools/editor" component={TextEditor} />
    </Switch>
  )
}
