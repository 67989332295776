// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Clipboard-___ClipboardText-module__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 30px;
  width: 250px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.app-components-Clipboard-___ClipboardText-module__text {
  width: 100%;
  border: none;
  padding: 0px 5px 0px 8px;
  height: 100%;
  line-height: 27px;
  max-width: calc(100% - 30px);
  overflow: auto hidden;
  white-space: nowrap;
  margin: 0px;
}

.app-components-Clipboard-___ClipboardText-module__text::-webkit-scrollbar {
  display: none;
}
.app-components-Clipboard-___ClipboardText-module__text {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
`, "",{"version":3,"sources":["webpack://./app/components/Clipboard/ClipboardText.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,YAAY;EACZ,iBAAiB;EACjB,4BAA4B;EAC5B,qBAAqB;EACrB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;AACA;EACE,wBAAwB;EACxB,qBAAqB;AACvB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  height: 30px;\n  width: 250px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  border-radius: 3px;\n  overflow: hidden;\n}\n\n.text {\n  width: 100%;\n  border: none;\n  padding: 0px 5px 0px 8px;\n  height: 100%;\n  line-height: 27px;\n  max-width: calc(100% - 30px);\n  overflow: auto hidden;\n  white-space: nowrap;\n  margin: 0px;\n}\n\n.text::-webkit-scrollbar {\n  display: none;\n}\n.text {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Clipboard-___ClipboardText-module__container`,
	"text": `app-components-Clipboard-___ClipboardText-module__text`
};
export default ___CSS_LOADER_EXPORT___;
