// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-JobSkillLevelInput-___JobSkillLevelRead-module__grid {
  display: grid;
  grid-column: 4;
  grid-template-columns: auto auto auto 40px;
  width: 100%;
  gap: 2px;
  border: 1px solid gba(0, 0, 0, 0.1);
}

.app-components-JobSkillLevelInput-___JobSkillLevelRead-module__gridHeader {
  background-color: rgba(0, 0, 0, 0.04);
  font-weight: bold;
}

.app-components-JobSkillLevelInput-___JobSkillLevelRead-module__gridHeader,
.app-components-JobSkillLevelInput-___JobSkillLevelRead-module__gridItem {
  padding: 3px 6px;
}
`, "",{"version":3,"sources":["webpack://./app/components/JobSkillLevelInput/JobSkillLevelRead.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,0CAA0C;EAC1C,WAAW;EACX,QAAQ;EACR,mCAAmC;AACrC;;AAEA;EACE,qCAAqC;EACrC,iBAAiB;AACnB;;AAEA;;EAEE,gBAAgB;AAClB","sourcesContent":[".grid {\n  display: grid;\n  grid-column: 4;\n  grid-template-columns: auto auto auto 40px;\n  width: 100%;\n  gap: 2px;\n  border: 1px solid gba(0, 0, 0, 0.1);\n}\n\n.gridHeader {\n  background-color: rgba(0, 0, 0, 0.04);\n  font-weight: bold;\n}\n\n.gridHeader,\n.gridItem {\n  padding: 3px 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `app-components-JobSkillLevelInput-___JobSkillLevelRead-module__grid`,
	"gridHeader": `app-components-JobSkillLevelInput-___JobSkillLevelRead-module__gridHeader`,
	"gridItem": `app-components-JobSkillLevelInput-___JobSkillLevelRead-module__gridItem`
};
export default ___CSS_LOADER_EXPORT___;
