/** @flow */
import React from 'react'
import { forEach } from 'lodash'
import { ModalFlagsRelations, type FlagsRelationProps } from 'app/components/Form'
import type { DefaultCellProps, Column, Cell, PrefsProps } from 'app/components/Table/types.js'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import { getTextWidth } from 'app/libs/helpers/getTextWidth.js'

import { groupingFns } from './groupingFns.jsx'
import classes from './CellFlags.module.scss'

export type CellFlagsParams = {|
  ...DefaultCellProps,
  foreignKey: $ElementType<FlagsRelationProps, 'foreignKey'>,
  getResourceID: (cell: Cell) => $ElementType<FlagsRelationProps, 'resourceID'>,
  category:
    | $ElementType<FlagsRelationProps, 'category'>
    | ((cell: Cell) => $ElementType<FlagsRelationProps, 'category'>),
  aggregated?: boolean,
|}

type ReadParams = {|
  cell: Cell,
  aggregated?: boolean,
  cellWidth: number,
  isRowExpanded: boolean,
  prefs: PrefsProps,
|}

const FLAG_HEIGHT = 19
const FLAG_MARGIN_WIDTH = 22
const REST_LABEL = 29

export function Read({ cell, aggregated, cellWidth, isRowExpanded, prefs }: ReadParams): React$Node {
  const { minLineHeight, maxLineHeight } = prefs

  const flags = []
  const hiddenFlags = []

  const NB_LINES_TO_DISPLAY = Math.floor((minLineHeight - 2) / FLAG_HEIGHT)

  let rest = 0
  let currentLine = NB_LINES_TO_DISPLAY - 1
  let currentLineWidth = cellWidth

  forEach(cell.value, (flagRelation) => {
    if (flagRelation?.flagInst) {
      if (isRowExpanded) {
        flags.push(flagRelation.flagInst)
        return
      }

      if (rest > 0) {
        hiddenFlags.push(flagRelation.flagInst)
        rest += 1
        return
      }

      if (aggregated) {
        if (currentLine === 0) {
          rest += 1
          hiddenFlags.push(flagRelation.flagInst)
          return
        }
        currentLine -= 1
        flags.push(flagRelation.flagInst)
        return
      }

      const flagWidth =
        getTextWidth(flagRelation.flagInst.name, 12, '"Helvetica", "Arial", sans-serif') + FLAG_MARGIN_WIDTH

      if (currentLine > 0) {
        if (currentLineWidth - flagWidth >= 0) {
          currentLineWidth -= flagWidth
          flags.push(flagRelation.flagInst)
          return
        }
        if (currentLineWidth === cellWidth) {
          flags.push(flagRelation.flagInst)
          currentLine -= 1
          return
        }
        currentLine -= 1
        currentLineWidth = cellWidth

        if (currentLine > 0) {
          currentLineWidth -= flagWidth
          flags.push(flagRelation.flagInst)
          return
        }
      }

      if (currentLine === 0 && currentLineWidth - (flagWidth + REST_LABEL) >= 0) {
        flags.push(flagRelation.flagInst)
        currentLineWidth -= flagWidth
        return
      }
      hiddenFlags.push(flagRelation.flagInst)
      rest += 1
    }
  })

  return (
    <div
      className={`${classes.container} ${aggregated ? 'grid col2 noGridGap YScrollAuto' : 'overflowHidden'}`}
      style={aggregated ? { maxHeight: maxLineHeight } : undefined}
    >
      {flags.map((flag) => {
        const { id, color, name, count, description } = flag

        return [
          <Tooltip title={`Description: ${description || 'No description'}`}>
            <span
              key={id}
              className={classes.flagReadOnly}
              style={{
                backgroundColor: color,
                color: getColorFromBackground(color),
                marginRight: count ? 'auto' : undefined,
              }}
            >
              {name}
            </span>
          </Tooltip>,
          count ? (
            <span key={`count_${id}`} className={classes.exponent}>
              x{count}
            </span>
          ) : null,
        ]
      })}
      {rest ? (
        <Tooltip
          title={
            <div
              className={`${classes.container} ${aggregated ? 'grid col2 noGridGap YScrollAuto' : 'overflowHidden'}`}
              style={aggregated ? { maxHeight: maxLineHeight } : undefined}
            >
              {hiddenFlags.map((flag) => {
                const { id, color, name, count } = flag

                return [
                  <span
                    key={id}
                    className={classes.flagReadOnly}
                    style={{
                      backgroundColor: color,
                      color: getColorFromBackground(color),
                      marginRight: count ? 'auto' : undefined,
                    }}
                  >
                    {name}
                  </span>,
                  count ? (
                    <span key={`count_${id}`} className={classes.exponent}>
                      x{count}
                    </span>
                  ) : null,
                ]
              })}
            </div>
          }
        >
          <div className={classes.exponent}>+{rest}</div>
        </Tooltip>
      ) : null}
    </div>
  )
}

const getCellType = (type: $ElementType<FlagsRelationProps, 'foreignKey'>) => {
  switch (type) {
    case 'take':
      return 'CellFlags_take'
    case 'asset':
    default:
      return 'CellFlags_asset'
  }
}

export const CellFlags = ({
  category,
  getResourceID,
  aggregated,
  foreignKey,
  actions,
  ...data
}: CellFlagsParams): Column => ({
  cellType: getCellType(foreignKey),
  Cell: (instance) => {
    const { cell, prefs } = instance
    const { getCellProps, column } = cell
    const { isRowExpanded } = getCellProps()

    return (
      <Read
        cell={cell}
        aggregated={aggregated}
        cellWidth={column.width || 80}
        isRowExpanded={isRowExpanded}
        prefs={prefs}
      />
    )
  },
  EditModal: (instance) => {
    const {
      cell,
      state: { selectedCells },
      cellPositionLabel,
    } = instance
    const { edition } = cell.getCellProps()
    const { endEdit, save } = edition || {}

    const flagsCategory = typeof category === 'function' ? category(cell) : category

    const multipleCellsEdition = selectedCells ? Object.keys(selectedCells).length > 1 : false

    return (
      <ModalFlagsRelations
        flagsRelations={cell.value}
        resourceID={getResourceID(cell)}
        foreignKey={foreignKey}
        itemCategory={flagsCategory}
        category={flagsCategory}
        onSave={(data, type) => {
          const { flagsRelations = {} } = data
          if (multipleCellsEdition) {
            const { saveData } = instance
            // $FlowFixMe
            return saveData({ cell, instance, value: flagsRelations, type })
          }
          return save(flagsRelations)
        }}
        onRequestClose={endEdit}
        autoFocus={true}
        multiple={multipleCellsEdition}
        exponentTitle={cellPositionLabel}
      />
    )
  },
  actions: (instance, cell) => {
    const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []

    const pastIndex = resolvedActions.findIndex((action) =>
      // $FlowFixMe
      typeof action === 'string' ? action === 'past' : action.label === 'Past',
    )

    if (pastIndex > -1) {
      resolvedActions[pastIndex] = {
        label: 'Past',
        hotKeys: ['ctrl+shift+v', 'command+shift+v'],
        onClick: () => cell.getCellProps()?.edition?.past(),
        rightLabel: 'Ctrl + Shift + V',
        editAction: true,
      }
      resolvedActions.splice(pastIndex, 0, {
        label: 'Merge',
        rightLabel: 'Ctrl + V',
        onClick: (event: SyntheticKeyboardEvent<>) => {
          const { state, saveData } = instance
          const { copiedCells } = state

          if (!copiedCells || Object.keys(copiedCells).length === 0) return

          const copiedCellsValues = {}

          forEach(copiedCells, (cell) => {
            forEach(cell.value, (flagRel, flagRelId) => {
              copiedCellsValues[flagRelId] = flagRel
            })
          })

          saveData({ cell, instance, value: copiedCellsValues, type: 'merge' })
        },
        hotKeys: ['ctrl+v', 'command+v'],
        editAction: true,
      })
    }

    return resolvedActions
  },
  groupingFns: groupingFns(aggregated),
  width: 100,
  ...data,
})
