import moment from 'moment'

/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */
export function getDaysInMonth(month, year) {
  const date = new Date(year, month, 1)
  const days = []

  while (date.getMonth() === month) {
    days.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return days
}

export function getTabCalendar(month, year, _weekStartDay) {
  const weekStartDay = _weekStartDay !== undefined ? _weekStartDay : 0
  let newMonth = month + 1
  newMonth = newMonth < 10 ? `0${newMonth}` : newMonth

  const strString = `${year}-${newMonth}-01`
  const firstDayOfMonth = new Date(strString)

  firstDayOfMonth.setHours(0)

  let numberOfDayVisibleFromPrevMonth = firstDayOfMonth.getDay()
  numberOfDayVisibleFromPrevMonth -= weekStartDay

  if (numberOfDayVisibleFromPrevMonth < 0) {
    numberOfDayVisibleFromPrevMonth = 7 + numberOfDayVisibleFromPrevMonth
  }

  const numberOfDayVisibleFromCurrentMonth = getDaysInMonth(month, year).length
  const numberOfDaysVisibleFromNextMont =
    (7 - ((numberOfDayVisibleFromPrevMonth + numberOfDayVisibleFromCurrentMonth) % 7)) % 7
  const totalDaysVisible =
    numberOfDayVisibleFromPrevMonth + numberOfDayVisibleFromCurrentMonth + numberOfDaysVisibleFromNextMont
  const firstDayOfCalendar = new Date(strString)

  firstDayOfCalendar.setHours(0)
  firstDayOfCalendar.setDate(firstDayOfMonth.getDate() - numberOfDayVisibleFromPrevMonth)

  const currentLoopDay = firstDayOfCalendar
  const weeksNumber = totalDaysVisible / 7
  const monthOutput = []

  for (let i = 0; i < weeksNumber; i += 1) {
    const weekTab = []

    for (let j = 0; j < 7; j += 1) {
      weekTab.push(currentLoopDay.toString())
      currentLoopDay.setDate(currentLoopDay.getDate() + 1)
    }
    monthOutput.push(weekTab)
  }
  return monthOutput
}

export const translate = {
  months: {
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    fr: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Decembre',
    ],
  },
  days: {
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  },
}

export default class CalendarJS {
  constructor(data) {
    const { weekStartDay, selectedDate, currentDate } = data || {}

    this.weekStartDay = weekStartDay !== undefined ? weekStartDay : 0

    this.currentDate = currentDate ? new Date(currentDate) : new Date()
    this.selectedDate = selectedDate ? new Date(selectedDate) : new Date()
  }

  /**
   * @return object
   */
  getMonthTab = () => {
    const monthTab = getTabCalendar(this.currentDate.getMonth(), this.currentDate.getFullYear(), this.weekStartDay)

    return {
      month: moment(this.currentDate).format('MMMM'),
      year: moment(this.currentDate).format('YYYY'),
      weeks: monthTab,
    }
  }

  goToNextMonth() {
    this.currentDate.setDate(1)
    this.currentDate.setMonth(this.currentDate.getMonth() + 1)
  }

  goToPrevMonth() {
    this.currentDate.setDate(1)
    this.currentDate.setMonth(this.currentDate.getMonth() - 1)
  }

  goToNextYear() {
    this.currentDate.setDate(1)
    this.currentDate.setFullYear(this.currentDate.getFullYear() + 1)
  }

  goToPrevYear() {
    this.currentDate.setDate(1)
    this.currentDate.setFullYear(this.currentDate.getFullYear() - 1)
  }

  getDayName(_day) {
    let day = _day + this.weekStartDay

    if (day > 6) day -= 7

    return translate.days[this.language][day]
  }
}
