/** @flow */
export const enumProxy = (data: { [key: string]: string }): { [key: string]: string } => {
  return data
}

export const enumProxyFromArray = (items: Array<string>): { [key: string]: string } => {
  const obj = {}
  for (const item of items) obj[item] = item
  return obj
}
