// @flow
import React from 'react'
import { confirmDelete } from 'app/components/Modal'

export default ({ userId, article, articleHasChilds, onValidate }: *): Promise<any> => {
  const config = {
    title: `Delete ${article.title}`,
  }

  if (articleHasChilds) {
    return confirmDelete({
      ...config,
      cancelLabel: 'Ok',
      hideValidate: true,
      render: (
        <div>
          <div>You cannot delete this article because it has child wiki.</div>
          <div>Delete child wiki before</div>
        </div>
      ),
    })
  }

  return confirmDelete({
    ...config,
    onValidate,
    validateLabel: 'Delete',
    validateLabelColor: '#E56D7A',
    render: 'Delete this article ?',
  })
}
