import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import { find, map } from 'lodash';
import classes from './EmblaCarousel.module.scss';
import noImage from 'app/styles/images/defaultImage.png';
import { useHistory } from 'react-router-dom';
const Cards = (props) => {
    var _a, _b, _c;
    const { people, offices, projectPlans } = props;
    const history = useHistory();
    const colors = ['#f1948a', '#808b96', '#e59866', '#7fb3d5', '#c39bd3', '#f7dc6f', '#f0b27a', '#73c6b6'];
    function getLatestStudio(id) {
        const arrayOffices = map(offices, (office) => office);
        for (let i = 0; i < arrayOffices.length; i++) {
            const selectedOffice = arrayOffices[i];
            selectedOffice.color = colors[i];
        }
        const office = find(arrayOffices, (office) => office.id === id);
        return office;
    }
    function getLatestProject(id) {
        return find(projectPlans, (project) => project.id === id);
    }
    const peoplePicture = people.thumbnailInst
        ? people.thumbnailInst.thumbnail || people.thumbnailInst.url
        : ((_a = people.userInst) === null || _a === void 0 ? void 0 : _a.thumbnailInst)
            ? people.userInst.thumbnailInst.thumbnail || people.userInst.thumbnailInst.url
            : noImage;
    return (_jsx("div", { onClick: () => history.push(`/organization-chart/${people.id}`), style: { cursor: 'pointer' }, children: _jsxs(Card, { className: classes.cards, "data-cy": `card-container-${people.id}`, children: [_jsx(CardMedia, { image: peoplePicture, className: classes.media, "data-cy": `card-media-${people.id}`, component: "div" }), _jsxs(CardContent, { className: classes.cardContent, style: { backgroundColor: getLatestStudio(people.currentContract.office).color }, children: [_jsxs(Typography, { className: classes.title, "data-cy": `card-name-${people.id}`, children: [people.firstName, " ", people.lastName] }), people.currentContract && (_jsxs(_Fragment, { children: [_jsx(Typography, { className: classes.text, "data-cy": `card-project-${people.id}`, children: (_b = getLatestProject(people.currentContract.projectPlan)) === null || _b === void 0 ? void 0 : _b.name }), _jsx(Typography, { className: classes.text, "data-cy": `card-office-${people.id}`, children: (_c = getLatestStudio(people.currentContract.office)) === null || _c === void 0 ? void 0 : _c.name })] }))] })] }) }));
};
export default Cards;
