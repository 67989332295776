// @flow

export default {
  checkCircle: 'far fa-check-circle',
  close: 'fas fa-times',
  check: 'fas fa-check',
  search: 'fas fa-search',
  arrowDown: 'fas fa-angle-down',
  trash: 'fas fa-trash',
  ellipsisH: 'fas fa-ellipsis-h',
  'fas-500px': 'fas fa-500px',
  'far-500px': 'far fa-500px',
  'fas-accessible-icon': 'fas fa-accessible-icon',
  'far-accessible-icon': 'far fa-accessible-icon',
  'fas-accusoft': 'fas fa-accusoft',
  'far-accusoft': 'far fa-accusoft',
  'fas-acquisitions-incorporated': 'fas fa-acquisitions-incorporated',
  'far-acquisitions-incorporated': 'far fa-acquisitions-incorporated',
  'fas-ad': 'fas fa-ad',
  'far-ad': 'far fa-ad',
  'fas-address-book': 'fas fa-address-book',
  'far-address-book': 'far fa-address-book',
  'fas-address-card': 'fas fa-address-card',
  'far-address-card': 'far fa-address-card',
  'fas-adjust': 'fas fa-adjust',
  'far-adjust': 'far fa-adjust',
  'fas-adn': 'fas fa-adn',
  'far-adn': 'far fa-adn',
  'fas-adversal': 'fas fa-adversal',
  'far-adversal': 'far fa-adversal',
  'fas-affiliatetheme': 'fas fa-affiliatetheme',
  'far-affiliatetheme': 'far fa-affiliatetheme',
  'fas-air-freshener': 'fas fa-air-freshener',
  'far-air-freshener': 'far fa-air-freshener',
  'fas-airbnb': 'fas fa-airbnb',
  'far-airbnb': 'far fa-airbnb',
  'fas-algolia': 'fas fa-algolia',
  'far-algolia': 'far fa-algolia',
  'fas-align-center': 'fas fa-align-center',
  'far-align-center': 'far fa-align-center',
  'fas-align-justify': 'fas fa-align-justify',
  'far-align-justify': 'far fa-align-justify',
  'fas-align-left': 'fas fa-align-left',
  'far-align-left': 'far fa-align-left',
  'fas-align-right': 'fas fa-align-right',
  'far-align-right': 'far fa-align-right',
  'fas-alipay': 'fas fa-alipay',
  'far-alipay': 'far fa-alipay',
  'fas-allergies': 'fas fa-allergies',
  'far-allergies': 'far fa-allergies',
  'fas-amazon': 'fas fa-amazon',
  'far-amazon': 'far fa-amazon',
  'fas-amazon-pay': 'fas fa-amazon-pay',
  'far-amazon-pay': 'far fa-amazon-pay',
  'fas-ambulance': 'fas fa-ambulance',
  'far-ambulance': 'far fa-ambulance',
  'fas-american-sign-language-interpreting': 'fas fa-american-sign-language-interpreting',
  'far-american-sign-language-interpreting': 'far fa-american-sign-language-interpreting',
  'fas-amilia': 'fas fa-amilia',
  'far-amilia': 'far fa-amilia',
  'fas-anchor': 'fas fa-anchor',
  'far-anchor': 'far fa-anchor',
  'fas-android': 'fas fa-android',
  'far-android': 'far fa-android',
  'fas-angellist': 'fas fa-angellist',
  'far-angellist': 'far fa-angellist',
  'fas-angle-double-down': 'fas fa-angle-double-down',
  'far-angle-double-down': 'far fa-angle-double-down',
  'fas-angle-double-left': 'fas fa-angle-double-left',
  'far-angle-double-left': 'far fa-angle-double-left',
  'fas-angle-double-right': 'fas fa-angle-double-right',
  'far-angle-double-right': 'far fa-angle-double-right',
  'fas-angle-double-up': 'fas fa-angle-double-up',
  'far-angle-double-up': 'far fa-angle-double-up',
  'fas-angle-down': 'fas fa-angle-down',
  'far-angle-down': 'far fa-angle-down',
  'fas-angle-left': 'fas fa-angle-left',
  'far-angle-left': 'far fa-angle-left',
  'fas-angle-right': 'fas fa-angle-right',
  'far-angle-right': 'far fa-angle-right',
  'fas-angle-up': 'fas fa-angle-up',
  'far-angle-up': 'far fa-angle-up',
  'fas-angry': 'fas fa-angry',
  'far-angry': 'far fa-angry',
  'fas-angrycreative': 'fas fa-angrycreative',
  'far-angrycreative': 'far fa-angrycreative',
  'fas-angular': 'fas fa-angular',
  'far-angular': 'far fa-angular',
  'fas-ankh': 'fas fa-ankh',
  'far-ankh': 'far fa-ankh',
  'fas-app-store': 'fas fa-app-store',
  'far-app-store': 'far fa-app-store',
  'fas-app-store-ios': 'fas fa-app-store-ios',
  'far-app-store-ios': 'far fa-app-store-ios',
  'fas-apper': 'fas fa-apper',
  'far-apper': 'far fa-apper',
  'fas-apple': 'fas fa-apple',
  'far-apple': 'far fa-apple',
  'fas-apple-alt': 'fas fa-apple-alt',
  'far-apple-alt': 'far fa-apple-alt',
  'fas-apple-pay': 'fas fa-apple-pay',
  'far-apple-pay': 'far fa-apple-pay',
  'fas-archive': 'fas fa-archive',
  'far-archive': 'far fa-archive',
  'fas-archway': 'fas fa-archway',
  'far-archway': 'far fa-archway',
  'fas-arrow-alt-circle-down': 'fas fa-arrow-alt-circle-down',
  'far-arrow-alt-circle-down': 'far fa-arrow-alt-circle-down',
  'fas-arrow-alt-circle-left': 'fas fa-arrow-alt-circle-left',
  'far-arrow-alt-circle-left': 'far fa-arrow-alt-circle-left',
  'fas-arrow-alt-circle-right': 'fas fa-arrow-alt-circle-right',
  'far-arrow-alt-circle-right': 'far fa-arrow-alt-circle-right',
  'fas-arrow-alt-circle-up': 'fas fa-arrow-alt-circle-up',
  'far-arrow-alt-circle-up': 'far fa-arrow-alt-circle-up',
  'fas-arrow-circle-down': 'fas fa-arrow-circle-down',
  'far-arrow-circle-down': 'far fa-arrow-circle-down',
  'fas-arrow-circle-left': 'fas fa-arrow-circle-left',
  'far-arrow-circle-left': 'far fa-arrow-circle-left',
  'fas-arrow-circle-right': 'fas fa-arrow-circle-right',
  'far-arrow-circle-right': 'far fa-arrow-circle-right',
  'fas-arrow-circle-up': 'fas fa-arrow-circle-up',
  'far-arrow-circle-up': 'far fa-arrow-circle-up',
  'fas-arrow-down': 'fas fa-arrow-down',
  'far-arrow-down': 'far fa-arrow-down',
  'fas-arrow-left': 'fas fa-arrow-left',
  'far-arrow-left': 'far fa-arrow-left',
  'fas-arrow-right': 'fas fa-arrow-right',
  'far-arrow-right': 'far fa-arrow-right',
  'fas-arrow-up': 'fas fa-arrow-up',
  'far-arrow-up': 'far fa-arrow-up',
  'fas-arrows-alt': 'fas fa-arrows-alt',
  'far-arrows-alt': 'far fa-arrows-alt',
  'fas-arrows-alt-h': 'fas fa-arrows-alt-h',
  'far-arrows-alt-h': 'far fa-arrows-alt-h',
  'fas-arrows-alt-v': 'fas fa-arrows-alt-v',
  'far-arrows-alt-v': 'far fa-arrows-alt-v',
  'fas-artstation': 'fas fa-artstation',
  'far-artstation': 'far fa-artstation',
  'fas-assistive-listening-systems': 'fas fa-assistive-listening-systems',
  'far-assistive-listening-systems': 'far fa-assistive-listening-systems',
  'fas-asterisk': 'fas fa-asterisk',
  'far-asterisk': 'far fa-asterisk',
  'fas-asymmetrik': 'fas fa-asymmetrik',
  'far-asymmetrik': 'far fa-asymmetrik',
  'fas-at': 'fas fa-at',
  'far-at': 'far fa-at',
  'fas-atlas': 'fas fa-atlas',
  'far-atlas': 'far fa-atlas',
  'fas-atlassian': 'fas fa-atlassian',
  'far-atlassian': 'far fa-atlassian',
  'fas-atom': 'fas fa-atom',
  'far-atom': 'far fa-atom',
  'fas-audible': 'fas fa-audible',
  'far-audible': 'far fa-audible',
  'fas-audio-description': 'fas fa-audio-description',
  'far-audio-description': 'far fa-audio-description',
  'fas-autoprefixer': 'fas fa-autoprefixer',
  'far-autoprefixer': 'far fa-autoprefixer',
  'fas-avianex': 'fas fa-avianex',
  'far-avianex': 'far fa-avianex',
  'fas-aviato': 'fas fa-aviato',
  'far-aviato': 'far fa-aviato',
  'fas-award': 'fas fa-award',
  'far-award': 'far fa-award',
  'fas-aws': 'fas fa-aws',
  'far-aws': 'far fa-aws',
  'fas-baby': 'fas fa-baby',
  'far-baby': 'far fa-baby',
  'fas-baby-carriage': 'fas fa-baby-carriage',
  'far-baby-carriage': 'far fa-baby-carriage',
  'fas-backspace': 'fas fa-backspace',
  'far-backspace': 'far fa-backspace',
  'fas-backward': 'fas fa-backward',
  'far-backward': 'far fa-backward',
  'fas-bacon': 'fas fa-bacon',
  'far-bacon': 'far fa-bacon',
  'fas-bacteria': 'fas fa-bacteria',
  'far-bacteria': 'far fa-bacteria',
  'fas-bacterium': 'fas fa-bacterium',
  'far-bacterium': 'far fa-bacterium',
  'fas-bahai': 'fas fa-bahai',
  'far-bahai': 'far fa-bahai',
  'fas-balance-scale': 'fas fa-balance-scale',
  'far-balance-scale': 'far fa-balance-scale',
  'fas-balance-scale-left': 'fas fa-balance-scale-left',
  'far-balance-scale-left': 'far fa-balance-scale-left',
  'fas-balance-scale-right': 'fas fa-balance-scale-right',
  'far-balance-scale-right': 'far fa-balance-scale-right',
  'fas-ban': 'fas fa-ban',
  'far-ban': 'far fa-ban',
  'fas-band-aid': 'fas fa-band-aid',
  'far-band-aid': 'far fa-band-aid',
  'fas-bandcamp': 'fas fa-bandcamp',
  'far-bandcamp': 'far fa-bandcamp',
  'fas-barcode': 'fas fa-barcode',
  'far-barcode': 'far fa-barcode',
  'fas-bars': 'fas fa-bars',
  'far-bars': 'far fa-bars',
  'fas-baseball-ball': 'fas fa-baseball-ball',
  'far-baseball-ball': 'far fa-baseball-ball',
  'fas-basketball-ball': 'fas fa-basketball-ball',
  'far-basketball-ball': 'far fa-basketball-ball',
  'fas-bath': 'fas fa-bath',
  'far-bath': 'far fa-bath',
  'fas-battery-empty': 'fas fa-battery-empty',
  'far-battery-empty': 'far fa-battery-empty',
  'fas-battery-full': 'fas fa-battery-full',
  'far-battery-full': 'far fa-battery-full',
  'fas-battery-half': 'fas fa-battery-half',
  'far-battery-half': 'far fa-battery-half',
  'fas-battery-quarter': 'fas fa-battery-quarter',
  'far-battery-quarter': 'far fa-battery-quarter',
  'fas-battery-three-quarters': 'fas fa-battery-three-quarters',
  'far-battery-three-quarters': 'far fa-battery-three-quarters',
  'fas-battle-net': 'fas fa-battle-net',
  'far-battle-net': 'far fa-battle-net',
  'fas-bed': 'fas fa-bed',
  'far-bed': 'far fa-bed',
  'fas-beer': 'fas fa-beer',
  'far-beer': 'far fa-beer',
  'fas-behance': 'fas fa-behance',
  'far-behance': 'far fa-behance',
  'fas-behance-square': 'fas fa-behance-square',
  'far-behance-square': 'far fa-behance-square',
  'fas-bell': 'fas fa-bell',
  'far-bell': 'far fa-bell',
  'fas-bell-slash': 'fas fa-bell-slash',
  'far-bell-slash': 'far fa-bell-slash',
  'fas-bezier-curve': 'fas fa-bezier-curve',
  'far-bezier-curve': 'far fa-bezier-curve',
  'fas-bible': 'fas fa-bible',
  'far-bible': 'far fa-bible',
  'fas-bicycle': 'fas fa-bicycle',
  'far-bicycle': 'far fa-bicycle',
  'fas-biking': 'fas fa-biking',
  'far-biking': 'far fa-biking',
  'fas-bimobject': 'fas fa-bimobject',
  'far-bimobject': 'far fa-bimobject',
  'fas-binoculars': 'fas fa-binoculars',
  'far-binoculars': 'far fa-binoculars',
  'fas-biohazard': 'fas fa-biohazard',
  'far-biohazard': 'far fa-biohazard',
  'fas-birthday-cake': 'fas fa-birthday-cake',
  'far-birthday-cake': 'far fa-birthday-cake',
  'fas-bitbucket': 'fas fa-bitbucket',
  'far-bitbucket': 'far fa-bitbucket',
  'fas-bitcoin': 'fas fa-bitcoin',
  'far-bitcoin': 'far fa-bitcoin',
  'fas-bity': 'fas fa-bity',
  'far-bity': 'far fa-bity',
  'fas-black-tie': 'fas fa-black-tie',
  'far-black-tie': 'far fa-black-tie',
  'fas-blackberry': 'fas fa-blackberry',
  'far-blackberry': 'far fa-blackberry',
  'fas-blender': 'fas fa-blender',
  'far-blender': 'far fa-blender',
  'fas-blender-phone': 'fas fa-blender-phone',
  'far-blender-phone': 'far fa-blender-phone',
  'fas-blind': 'fas fa-blind',
  'far-blind': 'far fa-blind',
  'fas-blog': 'fas fa-blog',
  'far-blog': 'far fa-blog',
  'fas-blogger': 'fas fa-blogger',
  'far-blogger': 'far fa-blogger',
  'fas-blogger-b': 'fas fa-blogger-b',
  'far-blogger-b': 'far fa-blogger-b',
  'fas-bluetooth': 'fas fa-bluetooth',
  'far-bluetooth': 'far fa-bluetooth',
  'fas-bluetooth-b': 'fas fa-bluetooth-b',
  'far-bluetooth-b': 'far fa-bluetooth-b',
  'fas-bold': 'fas fa-bold',
  'far-bold': 'far fa-bold',
  'fas-bolt': 'fas fa-bolt',
  'far-bolt': 'far fa-bolt',
  'fas-bomb': 'fas fa-bomb',
  'far-bomb': 'far fa-bomb',
  'fas-bone': 'fas fa-bone',
  'far-bone': 'far fa-bone',
  'fas-bong': 'fas fa-bong',
  'far-bong': 'far fa-bong',
  'fas-book': 'fas fa-book',
  'far-book': 'far fa-book',
  'fas-book-dead': 'fas fa-book-dead',
  'far-book-dead': 'far fa-book-dead',
  'fas-book-medical': 'fas fa-book-medical',
  'far-book-medical': 'far fa-book-medical',
  'fas-book-open': 'fas fa-book-open',
  'far-book-open': 'far fa-book-open',
  'fas-book-reader': 'fas fa-book-reader',
  'far-book-reader': 'far fa-book-reader',
  'fas-bookmark': 'fas fa-bookmark',
  'far-bookmark': 'far fa-bookmark',
  'fas-bootstrap': 'fas fa-bootstrap',
  'far-bootstrap': 'far fa-bootstrap',
  'fas-border-all': 'fas fa-border-all',
  'far-border-all': 'far fa-border-all',
  'fas-border-none': 'fas fa-border-none',
  'far-border-none': 'far fa-border-none',
  'fas-border-style': 'fas fa-border-style',
  'far-border-style': 'far fa-border-style',
  'fas-bowling-ball': 'fas fa-bowling-ball',
  'far-bowling-ball': 'far fa-bowling-ball',
  'fas-box': 'fas fa-box',
  'far-box': 'far fa-box',
  'fas-box-open': 'fas fa-box-open',
  'far-box-open': 'far fa-box-open',
  'fas-box-tissue': 'fas fa-box-tissue',
  'far-box-tissue': 'far fa-box-tissue',
  'fas-boxes': 'fas fa-boxes',
  'far-boxes': 'far fa-boxes',
  'fas-braille': 'fas fa-braille',
  'far-braille': 'far fa-braille',
  'fas-brain': 'fas fa-brain',
  'far-brain': 'far fa-brain',
  'fas-bread-slice': 'fas fa-bread-slice',
  'far-bread-slice': 'far fa-bread-slice',
  'fas-briefcase': 'fas fa-briefcase',
  'far-briefcase': 'far fa-briefcase',
  'fas-briefcase-medical': 'fas fa-briefcase-medical',
  'far-briefcase-medical': 'far fa-briefcase-medical',
  'fas-broadcast-tower': 'fas fa-broadcast-tower',
  'far-broadcast-tower': 'far fa-broadcast-tower',
  'fas-broom': 'fas fa-broom',
  'far-broom': 'far fa-broom',
  'fas-brush': 'fas fa-brush',
  'far-brush': 'far fa-brush',
  'fas-btc': 'fas fa-btc',
  'far-btc': 'far fa-btc',
  'fas-buffer': 'fas fa-buffer',
  'far-buffer': 'far fa-buffer',
  'fas-bug': 'fas fa-bug',
  'far-bug': 'far fa-bug',
  'fas-building': 'fas fa-building',
  'far-building': 'far fa-building',
  'fas-bullhorn': 'fas fa-bullhorn',
  'far-bullhorn': 'far fa-bullhorn',
  'fas-bullseye': 'fas fa-bullseye',
  'far-bullseye': 'far fa-bullseye',
  'fas-burn': 'fas fa-burn',
  'far-burn': 'far fa-burn',
  'fas-buromobelexperte': 'fas fa-buromobelexperte',
  'far-buromobelexperte': 'far fa-buromobelexperte',
  'fas-bus': 'fas fa-bus',
  'far-bus': 'far fa-bus',
  'fas-bus-alt': 'fas fa-bus-alt',
  'far-bus-alt': 'far fa-bus-alt',
  'fas-business-time': 'fas fa-business-time',
  'far-business-time': 'far fa-business-time',
  'fas-buy-n-large': 'fas fa-buy-n-large',
  'far-buy-n-large': 'far fa-buy-n-large',
  'fas-buysellads': 'fas fa-buysellads',
  'far-buysellads': 'far fa-buysellads',
  'fas-calculator': 'fas fa-calculator',
  'far-calculator': 'far fa-calculator',
  'fas-calendar': 'fas fa-calendar',
  'far-calendar': 'far fa-calendar',
  'fas-calendar-alt': 'fas fa-calendar-alt',
  'far-calendar-alt': 'far fa-calendar-alt',
  'fas-calendar-check': 'fas fa-calendar-check',
  'far-calendar-check': 'far fa-calendar-check',
  'fas-calendar-day': 'fas fa-calendar-day',
  'far-calendar-day': 'far fa-calendar-day',
  'fas-calendar-minus': 'fas fa-calendar-minus',
  'far-calendar-minus': 'far fa-calendar-minus',
  'fas-calendar-plus': 'fas fa-calendar-plus',
  'far-calendar-plus': 'far fa-calendar-plus',
  'fas-calendar-times': 'fas fa-calendar-times',
  'far-calendar-times': 'far fa-calendar-times',
  'fas-calendar-week': 'fas fa-calendar-week',
  'far-calendar-week': 'far fa-calendar-week',
  'fas-camera': 'fas fa-camera',
  'far-camera': 'far fa-camera',
  'fas-camera-retro': 'fas fa-camera-retro',
  'far-camera-retro': 'far fa-camera-retro',
  'fas-campground': 'fas fa-campground',
  'far-campground': 'far fa-campground',
  'fas-canadian-maple-leaf': 'fas fa-canadian-maple-leaf',
  'far-canadian-maple-leaf': 'far fa-canadian-maple-leaf',
  'fas-candy-cane': 'fas fa-candy-cane',
  'far-candy-cane': 'far fa-candy-cane',
  'fas-cannabis': 'fas fa-cannabis',
  'far-cannabis': 'far fa-cannabis',
  'fas-capsules': 'fas fa-capsules',
  'far-capsules': 'far fa-capsules',
  'fas-car': 'fas fa-car',
  'far-car': 'far fa-car',
  'fas-car-alt': 'fas fa-car-alt',
  'far-car-alt': 'far fa-car-alt',
  'fas-car-battery': 'fas fa-car-battery',
  'far-car-battery': 'far fa-car-battery',
  'fas-car-crash': 'fas fa-car-crash',
  'far-car-crash': 'far fa-car-crash',
  'fas-car-side': 'fas fa-car-side',
  'far-car-side': 'far fa-car-side',
  'fas-caravan': 'fas fa-caravan',
  'far-caravan': 'far fa-caravan',
  'fas-caret-down': 'fas fa-caret-down',
  'far-caret-down': 'far fa-caret-down',
  'fas-caret-left': 'fas fa-caret-left',
  'far-caret-left': 'far fa-caret-left',
  'fas-caret-right': 'fas fa-caret-right',
  'far-caret-right': 'far fa-caret-right',
  'fas-caret-square-down': 'fas fa-caret-square-down',
  'far-caret-square-down': 'far fa-caret-square-down',
  'fas-caret-square-left': 'fas fa-caret-square-left',
  'far-caret-square-left': 'far fa-caret-square-left',
  'fas-caret-square-right': 'fas fa-caret-square-right',
  'far-caret-square-right': 'far fa-caret-square-right',
  'fas-caret-square-up': 'fas fa-caret-square-up',
  'far-caret-square-up': 'far fa-caret-square-up',
  'fas-caret-up': 'fas fa-caret-up',
  'far-caret-up': 'far fa-caret-up',
  'fas-carrot': 'fas fa-carrot',
  'far-carrot': 'far fa-carrot',
  'fas-cart-arrow-down': 'fas fa-cart-arrow-down',
  'far-cart-arrow-down': 'far fa-cart-arrow-down',
  'fas-cart-plus': 'fas fa-cart-plus',
  'far-cart-plus': 'far fa-cart-plus',
  'fas-cash-register': 'fas fa-cash-register',
  'far-cash-register': 'far fa-cash-register',
  'fas-cat': 'fas fa-cat',
  'far-cat': 'far fa-cat',
  'fas-cc-amazon-pay': 'fas fa-cc-amazon-pay',
  'far-cc-amazon-pay': 'far fa-cc-amazon-pay',
  'fas-cc-amex': 'fas fa-cc-amex',
  'far-cc-amex': 'far fa-cc-amex',
  'fas-cc-apple-pay': 'fas fa-cc-apple-pay',
  'far-cc-apple-pay': 'far fa-cc-apple-pay',
  'fas-cc-diners-club': 'fas fa-cc-diners-club',
  'far-cc-diners-club': 'far fa-cc-diners-club',
  'fas-cc-discover': 'fas fa-cc-discover',
  'far-cc-discover': 'far fa-cc-discover',
  'fas-cc-jcb': 'fas fa-cc-jcb',
  'far-cc-jcb': 'far fa-cc-jcb',
  'fas-cc-mastercard': 'fas fa-cc-mastercard',
  'far-cc-mastercard': 'far fa-cc-mastercard',
  'fas-cc-paypal': 'fas fa-cc-paypal',
  'far-cc-paypal': 'far fa-cc-paypal',
  'fas-cc-stripe': 'fas fa-cc-stripe',
  'far-cc-stripe': 'far fa-cc-stripe',
  'fas-cc-visa': 'fas fa-cc-visa',
  'far-cc-visa': 'far fa-cc-visa',
  'fas-centercode': 'fas fa-centercode',
  'far-centercode': 'far fa-centercode',
  'fas-centos': 'fas fa-centos',
  'far-centos': 'far fa-centos',
  'fas-certificate': 'fas fa-certificate',
  'far-certificate': 'far fa-certificate',
  'fas-chair': 'fas fa-chair',
  'far-chair': 'far fa-chair',
  'fas-chalkboard': 'fas fa-chalkboard',
  'far-chalkboard': 'far fa-chalkboard',
  'fas-chalkboard-teacher': 'fas fa-chalkboard-teacher',
  'far-chalkboard-teacher': 'far fa-chalkboard-teacher',
  'fas-charging-station': 'fas fa-charging-station',
  'far-charging-station': 'far fa-charging-station',
  'fas-chart-area': 'fas fa-chart-area',
  'far-chart-area': 'far fa-chart-area',
  'fas-chart-bar': 'fas fa-chart-bar',
  'far-chart-bar': 'far fa-chart-bar',
  'fas-chart-line': 'fas fa-chart-line',
  'far-chart-line': 'far fa-chart-line',
  'fas-chart-pie': 'fas fa-chart-pie',
  'far-chart-pie': 'far fa-chart-pie',
  'fas-check': 'fas fa-check',
  'far-check': 'far fa-check',
  'fas-check-circle': 'fas fa-check-circle',
  'far-check-circle': 'far fa-check-circle',
  'fas-check-double': 'fas fa-check-double',
  'far-check-double': 'far fa-check-double',
  'fas-check-square': 'fas fa-check-square',
  'far-check-square': 'far fa-check-square',
  'fas-cheese': 'fas fa-cheese',
  'far-cheese': 'far fa-cheese',
  'fas-chess': 'fas fa-chess',
  'far-chess': 'far fa-chess',
  'fas-chess-bishop': 'fas fa-chess-bishop',
  'far-chess-bishop': 'far fa-chess-bishop',
  'fas-chess-board': 'fas fa-chess-board',
  'far-chess-board': 'far fa-chess-board',
  'fas-chess-king': 'fas fa-chess-king',
  'far-chess-king': 'far fa-chess-king',
  'fas-chess-knight': 'fas fa-chess-knight',
  'far-chess-knight': 'far fa-chess-knight',
  'fas-chess-pawn': 'fas fa-chess-pawn',
  'far-chess-pawn': 'far fa-chess-pawn',
  'fas-chess-queen': 'fas fa-chess-queen',
  'far-chess-queen': 'far fa-chess-queen',
  'fas-chess-rook': 'fas fa-chess-rook',
  'far-chess-rook': 'far fa-chess-rook',
  'fas-chevron-circle-down': 'fas fa-chevron-circle-down',
  'far-chevron-circle-down': 'far fa-chevron-circle-down',
  'fas-chevron-circle-left': 'fas fa-chevron-circle-left',
  'far-chevron-circle-left': 'far fa-chevron-circle-left',
  'fas-chevron-circle-right': 'fas fa-chevron-circle-right',
  'far-chevron-circle-right': 'far fa-chevron-circle-right',
  'fas-chevron-circle-up': 'fas fa-chevron-circle-up',
  'far-chevron-circle-up': 'far fa-chevron-circle-up',
  'fas-chevron-down': 'fas fa-chevron-down',
  'far-chevron-down': 'far fa-chevron-down',
  'fas-chevron-left': 'fas fa-chevron-left',
  'far-chevron-left': 'far fa-chevron-left',
  'fas-chevron-right': 'fas fa-chevron-right',
  'far-chevron-right': 'far fa-chevron-right',
  'fas-chevron-up': 'fas fa-chevron-up',
  'far-chevron-up': 'far fa-chevron-up',
  'fas-child': 'fas fa-child',
  'far-child': 'far fa-child',
  'fas-chrome': 'fas fa-chrome',
  'far-chrome': 'far fa-chrome',
  'fas-chromecast': 'fas fa-chromecast',
  'far-chromecast': 'far fa-chromecast',
  'fas-church': 'fas fa-church',
  'far-church': 'far fa-church',
  'fas-circle': 'fas fa-circle',
  'far-circle': 'far fa-circle',
  'fas-circle-notch': 'fas fa-circle-notch',
  'far-circle-notch': 'far fa-circle-notch',
  'fas-city': 'fas fa-city',
  'far-city': 'far fa-city',
  'fas-clinic-medical': 'fas fa-clinic-medical',
  'far-clinic-medical': 'far fa-clinic-medical',
  'fas-clipboard': 'fas fa-clipboard',
  'far-clipboard': 'far fa-clipboard',
  'fas-clipboard-check': 'fas fa-clipboard-check',
  'far-clipboard-check': 'far fa-clipboard-check',
  'fas-clipboard-list': 'fas fa-clipboard-list',
  'far-clipboard-list': 'far fa-clipboard-list',
  'fas-clock': 'fas fa-clock',
  'far-clock': 'far fa-clock',
  'fas-clone': 'fas fa-clone',
  'far-clone': 'far fa-clone',
  'fas-closed-captioning': 'fas fa-closed-captioning',
  'far-closed-captioning': 'far fa-closed-captioning',
  'fas-cloud': 'fas fa-cloud',
  'far-cloud': 'far fa-cloud',
  'fas-cloud-download-alt': 'fas fa-cloud-download-alt',
  'far-cloud-download-alt': 'far fa-cloud-download-alt',
  'fas-cloud-meatball': 'fas fa-cloud-meatball',
  'far-cloud-meatball': 'far fa-cloud-meatball',
  'fas-cloud-moon': 'fas fa-cloud-moon',
  'far-cloud-moon': 'far fa-cloud-moon',
  'fas-cloud-moon-rain': 'fas fa-cloud-moon-rain',
  'far-cloud-moon-rain': 'far fa-cloud-moon-rain',
  'fas-cloud-rain': 'fas fa-cloud-rain',
  'far-cloud-rain': 'far fa-cloud-rain',
  'fas-cloud-showers-heavy': 'fas fa-cloud-showers-heavy',
  'far-cloud-showers-heavy': 'far fa-cloud-showers-heavy',
  'fas-cloud-sun': 'fas fa-cloud-sun',
  'far-cloud-sun': 'far fa-cloud-sun',
  'fas-cloud-sun-rain': 'fas fa-cloud-sun-rain',
  'far-cloud-sun-rain': 'far fa-cloud-sun-rain',
  'fas-cloud-upload-alt': 'fas fa-cloud-upload-alt',
  'far-cloud-upload-alt': 'far fa-cloud-upload-alt',
  'fas-cloudflare': 'fas fa-cloudflare',
  'far-cloudflare': 'far fa-cloudflare',
  'fas-cloudscale': 'fas fa-cloudscale',
  'far-cloudscale': 'far fa-cloudscale',
  'fas-cloudsmith': 'fas fa-cloudsmith',
  'far-cloudsmith': 'far fa-cloudsmith',
  'fas-cloudversify': 'fas fa-cloudversify',
  'far-cloudversify': 'far fa-cloudversify',
  'fas-cocktail': 'fas fa-cocktail',
  'far-cocktail': 'far fa-cocktail',
  'fas-code': 'fas fa-code',
  'far-code': 'far fa-code',
  'fas-code-branch': 'fas fa-code-branch',
  'far-code-branch': 'far fa-code-branch',
  'fas-codepen': 'fas fa-codepen',
  'far-codepen': 'far fa-codepen',
  'fas-codiepie': 'fas fa-codiepie',
  'far-codiepie': 'far fa-codiepie',
  'fas-coffee': 'fas fa-coffee',
  'far-coffee': 'far fa-coffee',
  'fas-cog': 'fas fa-cog',
  'far-cog': 'far fa-cog',
  'fas-cogs': 'fas fa-cogs',
  'far-cogs': 'far fa-cogs',
  'fas-coins': 'fas fa-coins',
  'far-coins': 'far fa-coins',
  'fas-columns': 'fas fa-columns',
  'far-columns': 'far fa-columns',
  'fas-comment': 'fas fa-comment',
  'far-comment': 'far fa-comment',
  'fas-comment-alt': 'fas fa-comment-alt',
  'far-comment-alt': 'far fa-comment-alt',
  'fas-comment-dollar': 'fas fa-comment-dollar',
  'far-comment-dollar': 'far fa-comment-dollar',
  'fas-comment-dots': 'fas fa-comment-dots',
  'far-comment-dots': 'far fa-comment-dots',
  'fas-comment-medical': 'fas fa-comment-medical',
  'far-comment-medical': 'far fa-comment-medical',
  'fas-comment-slash': 'fas fa-comment-slash',
  'far-comment-slash': 'far fa-comment-slash',
  'fas-comments': 'fas fa-comments',
  'far-comments': 'far fa-comments',
  'fas-comments-dollar': 'fas fa-comments-dollar',
  'far-comments-dollar': 'far fa-comments-dollar',
  'fas-compact-disc': 'fas fa-compact-disc',
  'far-compact-disc': 'far fa-compact-disc',
  'fas-compass': 'fas fa-compass',
  'far-compass': 'far fa-compass',
  'fas-compress': 'fas fa-compress',
  'far-compress': 'far fa-compress',
  'fas-compress-alt': 'fas fa-compress-alt',
  'far-compress-alt': 'far fa-compress-alt',
  'fas-compress-arrows-alt': 'fas fa-compress-arrows-alt',
  'far-compress-arrows-alt': 'far fa-compress-arrows-alt',
  'fas-concierge-bell': 'fas fa-concierge-bell',
  'far-concierge-bell': 'far fa-concierge-bell',
  'fas-confluence': 'fas fa-confluence',
  'far-confluence': 'far fa-confluence',
  'fas-connectdevelop': 'fas fa-connectdevelop',
  'far-connectdevelop': 'far fa-connectdevelop',
  'fas-contao': 'fas fa-contao',
  'far-contao': 'far fa-contao',
  'fas-cookie': 'fas fa-cookie',
  'far-cookie': 'far fa-cookie',
  'fas-cookie-bite': 'fas fa-cookie-bite',
  'far-cookie-bite': 'far fa-cookie-bite',
  'fas-copy': 'fas fa-copy',
  'far-copy': 'far fa-copy',
  'fas-copyright': 'fas fa-copyright',
  'far-copyright': 'far fa-copyright',
  'fas-cotton-bureau': 'fas fa-cotton-bureau',
  'far-cotton-bureau': 'far fa-cotton-bureau',
  'fas-couch': 'fas fa-couch',
  'far-couch': 'far fa-couch',
  'fas-cpanel': 'fas fa-cpanel',
  'far-cpanel': 'far fa-cpanel',
  'fas-creative-commons': 'fas fa-creative-commons',
  'far-creative-commons': 'far fa-creative-commons',
  'fas-creative-commons-by': 'fas fa-creative-commons-by',
  'far-creative-commons-by': 'far fa-creative-commons-by',
  'fas-creative-commons-nc': 'fas fa-creative-commons-nc',
  'far-creative-commons-nc': 'far fa-creative-commons-nc',
  'fas-creative-commons-nc-eu': 'fas fa-creative-commons-nc-eu',
  'far-creative-commons-nc-eu': 'far fa-creative-commons-nc-eu',
  'fas-creative-commons-nc-jp': 'fas fa-creative-commons-nc-jp',
  'far-creative-commons-nc-jp': 'far fa-creative-commons-nc-jp',
  'fas-creative-commons-nd': 'fas fa-creative-commons-nd',
  'far-creative-commons-nd': 'far fa-creative-commons-nd',
  'fas-creative-commons-pd': 'fas fa-creative-commons-pd',
  'far-creative-commons-pd': 'far fa-creative-commons-pd',
  'fas-creative-commons-pd-alt': 'fas fa-creative-commons-pd-alt',
  'far-creative-commons-pd-alt': 'far fa-creative-commons-pd-alt',
  'fas-creative-commons-remix': 'fas fa-creative-commons-remix',
  'far-creative-commons-remix': 'far fa-creative-commons-remix',
  'fas-creative-commons-sa': 'fas fa-creative-commons-sa',
  'far-creative-commons-sa': 'far fa-creative-commons-sa',
  'fas-creative-commons-sampling': 'fas fa-creative-commons-sampling',
  'far-creative-commons-sampling': 'far fa-creative-commons-sampling',
  'fas-creative-commons-sampling-plus': 'fas fa-creative-commons-sampling-plus',
  'far-creative-commons-sampling-plus': 'far fa-creative-commons-sampling-plus',
  'fas-creative-commons-share': 'fas fa-creative-commons-share',
  'far-creative-commons-share': 'far fa-creative-commons-share',
  'fas-creative-commons-zero': 'fas fa-creative-commons-zero',
  'far-creative-commons-zero': 'far fa-creative-commons-zero',
  'fas-credit-card': 'fas fa-credit-card',
  'far-credit-card': 'far fa-credit-card',
  'fas-critical-role': 'fas fa-critical-role',
  'far-critical-role': 'far fa-critical-role',
  'fas-crop': 'fas fa-crop',
  'far-crop': 'far fa-crop',
  'fas-crop-alt': 'fas fa-crop-alt',
  'far-crop-alt': 'far fa-crop-alt',
  'fas-cross': 'fas fa-cross',
  'far-cross': 'far fa-cross',
  'fas-crosshairs': 'fas fa-crosshairs',
  'far-crosshairs': 'far fa-crosshairs',
  'fas-crow': 'fas fa-crow',
  'far-crow': 'far fa-crow',
  'fas-crown': 'fas fa-crown',
  'far-crown': 'far fa-crown',
  'fas-crutch': 'fas fa-crutch',
  'far-crutch': 'far fa-crutch',
  'fas-css3': 'fas fa-css3',
  'far-css3': 'far fa-css3',
  'fas-css3-alt': 'fas fa-css3-alt',
  'far-css3-alt': 'far fa-css3-alt',
  'fas-cube': 'fas fa-cube',
  'far-cube': 'far fa-cube',
  'fas-cubes': 'fas fa-cubes',
  'far-cubes': 'far fa-cubes',
  'fas-cut': 'fas fa-cut',
  'far-cut': 'far fa-cut',
  'fas-cuttlefish': 'fas fa-cuttlefish',
  'far-cuttlefish': 'far fa-cuttlefish',
  'fas-d-and-d': 'fas fa-d-and-d',
  'far-d-and-d': 'far fa-d-and-d',
  'fas-d-and-d-beyond': 'fas fa-d-and-d-beyond',
  'far-d-and-d-beyond': 'far fa-d-and-d-beyond',
  'fas-dailymotion': 'fas fa-dailymotion',
  'far-dailymotion': 'far fa-dailymotion',
  'fas-dashcube': 'fas fa-dashcube',
  'far-dashcube': 'far fa-dashcube',
  'fas-database': 'fas fa-database',
  'far-database': 'far fa-database',
  'fas-deaf': 'fas fa-deaf',
  'far-deaf': 'far fa-deaf',
  'fas-deezer': 'fas fa-deezer',
  'far-deezer': 'far fa-deezer',
  'fas-delicious': 'fas fa-delicious',
  'far-delicious': 'far fa-delicious',
  'fas-democrat': 'fas fa-democrat',
  'far-democrat': 'far fa-democrat',
  'fas-deploydog': 'fas fa-deploydog',
  'far-deploydog': 'far fa-deploydog',
  'fas-deskpro': 'fas fa-deskpro',
  'far-deskpro': 'far fa-deskpro',
  'fas-desktop': 'fas fa-desktop',
  'far-desktop': 'far fa-desktop',
  'fas-dev': 'fas fa-dev',
  'far-dev': 'far fa-dev',
  'fas-deviantart': 'fas fa-deviantart',
  'far-deviantart': 'far fa-deviantart',
  'fas-dharmachakra': 'fas fa-dharmachakra',
  'far-dharmachakra': 'far fa-dharmachakra',
  'fas-dhl': 'fas fa-dhl',
  'far-dhl': 'far fa-dhl',
  'fas-diagnoses': 'fas fa-diagnoses',
  'far-diagnoses': 'far fa-diagnoses',
  'fas-diaspora': 'fas fa-diaspora',
  'far-diaspora': 'far fa-diaspora',
  'fas-dice': 'fas fa-dice',
  'far-dice': 'far fa-dice',
  'fas-dice-d20': 'fas fa-dice-d20',
  'far-dice-d20': 'far fa-dice-d20',
  'fas-dice-d6': 'fas fa-dice-d6',
  'far-dice-d6': 'far fa-dice-d6',
  'fas-dice-five': 'fas fa-dice-five',
  'far-dice-five': 'far fa-dice-five',
  'fas-dice-four': 'fas fa-dice-four',
  'far-dice-four': 'far fa-dice-four',
  'fas-dice-one': 'fas fa-dice-one',
  'far-dice-one': 'far fa-dice-one',
  'fas-dice-six': 'fas fa-dice-six',
  'far-dice-six': 'far fa-dice-six',
  'fas-dice-three': 'fas fa-dice-three',
  'far-dice-three': 'far fa-dice-three',
  'fas-dice-two': 'fas fa-dice-two',
  'far-dice-two': 'far fa-dice-two',
  'fas-digg': 'fas fa-digg',
  'far-digg': 'far fa-digg',
  'fas-digital-ocean': 'fas fa-digital-ocean',
  'far-digital-ocean': 'far fa-digital-ocean',
  'fas-digital-tachograph': 'fas fa-digital-tachograph',
  'far-digital-tachograph': 'far fa-digital-tachograph',
  'fas-directions': 'fas fa-directions',
  'far-directions': 'far fa-directions',
  'fas-discord': 'fas fa-discord',
  'far-discord': 'far fa-discord',
  'fas-discourse': 'fas fa-discourse',
  'far-discourse': 'far fa-discourse',
  'fas-disease': 'fas fa-disease',
  'far-disease': 'far fa-disease',
  'fas-divide': 'fas fa-divide',
  'far-divide': 'far fa-divide',
  'fas-dizzy': 'fas fa-dizzy',
  'far-dizzy': 'far fa-dizzy',
  'fas-dna': 'fas fa-dna',
  'far-dna': 'far fa-dna',
  'fas-dochub': 'fas fa-dochub',
  'far-dochub': 'far fa-dochub',
  'fas-docker': 'fas fa-docker',
  'far-docker': 'far fa-docker',
  'fas-dog': 'fas fa-dog',
  'far-dog': 'far fa-dog',
  'fas-dollar-sign': 'fas fa-dollar-sign',
  'far-dollar-sign': 'far fa-dollar-sign',
  'fas-dolly': 'fas fa-dolly',
  'far-dolly': 'far fa-dolly',
  'fas-dolly-flatbed': 'fas fa-dolly-flatbed',
  'far-dolly-flatbed': 'far fa-dolly-flatbed',
  'fas-donate': 'fas fa-donate',
  'far-donate': 'far fa-donate',
  'fas-door-closed': 'fas fa-door-closed',
  'far-door-closed': 'far fa-door-closed',
  'fas-door-open': 'fas fa-door-open',
  'far-door-open': 'far fa-door-open',
  'fas-dot-circle': 'fas fa-dot-circle',
  'far-dot-circle': 'far fa-dot-circle',
  'fas-dove': 'fas fa-dove',
  'far-dove': 'far fa-dove',
  'fas-download': 'fas fa-download',
  'far-download': 'far fa-download',
  'fas-draft2digital': 'fas fa-draft2digital',
  'far-draft2digital': 'far fa-draft2digital',
  'fas-drafting-compass': 'fas fa-drafting-compass',
  'far-drafting-compass': 'far fa-drafting-compass',
  'fas-dragon': 'fas fa-dragon',
  'far-dragon': 'far fa-dragon',
  'fas-draw-polygon': 'fas fa-draw-polygon',
  'far-draw-polygon': 'far fa-draw-polygon',
  'fas-dribbble': 'fas fa-dribbble',
  'far-dribbble': 'far fa-dribbble',
  'fas-dribbble-square': 'fas fa-dribbble-square',
  'far-dribbble-square': 'far fa-dribbble-square',
  'fas-dropbox': 'fas fa-dropbox',
  'far-dropbox': 'far fa-dropbox',
  'fas-drum': 'fas fa-drum',
  'far-drum': 'far fa-drum',
  'fas-drum-steelpan': 'fas fa-drum-steelpan',
  'far-drum-steelpan': 'far fa-drum-steelpan',
  'fas-drumstick-bite': 'fas fa-drumstick-bite',
  'far-drumstick-bite': 'far fa-drumstick-bite',
  'fas-drupal': 'fas fa-drupal',
  'far-drupal': 'far fa-drupal',
  'fas-dumbbell': 'fas fa-dumbbell',
  'far-dumbbell': 'far fa-dumbbell',
  'fas-dumpster': 'fas fa-dumpster',
  'far-dumpster': 'far fa-dumpster',
  'fas-dumpster-fire': 'fas fa-dumpster-fire',
  'far-dumpster-fire': 'far fa-dumpster-fire',
  'fas-dungeon': 'fas fa-dungeon',
  'far-dungeon': 'far fa-dungeon',
  'fas-dyalog': 'fas fa-dyalog',
  'far-dyalog': 'far fa-dyalog',
  'fas-earlybirds': 'fas fa-earlybirds',
  'far-earlybirds': 'far fa-earlybirds',
  'fas-ebay': 'fas fa-ebay',
  'far-ebay': 'far fa-ebay',
  'fas-edge': 'fas fa-edge',
  'far-edge': 'far fa-edge',
  'fas-edge-legacy': 'fas fa-edge-legacy',
  'far-edge-legacy': 'far fa-edge-legacy',
  'fas-edit': 'fas fa-edit',
  'far-edit': 'far fa-edit',
  'fas-egg': 'fas fa-egg',
  'far-egg': 'far fa-egg',
  'fas-eject': 'fas fa-eject',
  'far-eject': 'far fa-eject',
  'fas-elementor': 'fas fa-elementor',
  'far-elementor': 'far fa-elementor',
  'fas-ellipsis-h': 'fas fa-ellipsis-h',
  'far-ellipsis-h': 'far fa-ellipsis-h',
  'fas-ellipsis-v': 'fas fa-ellipsis-v',
  'far-ellipsis-v': 'far fa-ellipsis-v',
  'fas-ello': 'fas fa-ello',
  'far-ello': 'far fa-ello',
  'fas-ember': 'fas fa-ember',
  'far-ember': 'far fa-ember',
  'fas-empire': 'fas fa-empire',
  'far-empire': 'far fa-empire',
  'fas-envelope': 'fas fa-envelope',
  'far-envelope': 'far fa-envelope',
  'fas-envelope-open': 'fas fa-envelope-open',
  'far-envelope-open': 'far fa-envelope-open',
  'fas-envelope-open-text': 'fas fa-envelope-open-text',
  'far-envelope-open-text': 'far fa-envelope-open-text',
  'fas-envelope-square': 'fas fa-envelope-square',
  'far-envelope-square': 'far fa-envelope-square',
  'fas-envira': 'fas fa-envira',
  'far-envira': 'far fa-envira',
  'fas-equals': 'fas fa-equals',
  'far-equals': 'far fa-equals',
  'fas-eraser': 'fas fa-eraser',
  'far-eraser': 'far fa-eraser',
  'fas-erlang': 'fas fa-erlang',
  'far-erlang': 'far fa-erlang',
  'fas-ethereum': 'fas fa-ethereum',
  'far-ethereum': 'far fa-ethereum',
  'fas-ethernet': 'fas fa-ethernet',
  'far-ethernet': 'far fa-ethernet',
  'fas-etsy': 'fas fa-etsy',
  'far-etsy': 'far fa-etsy',
  'fas-euro-sign': 'fas fa-euro-sign',
  'far-euro-sign': 'far fa-euro-sign',
  'fas-evernote': 'fas fa-evernote',
  'far-evernote': 'far fa-evernote',
  'fas-exchange-alt': 'fas fa-exchange-alt',
  'far-exchange-alt': 'far fa-exchange-alt',
  'fas-exclamation': 'fas fa-exclamation',
  'far-exclamation': 'far fa-exclamation',
  'fas-exclamation-circle': 'fas fa-exclamation-circle',
  'far-exclamation-circle': 'far fa-exclamation-circle',
  'fas-exclamation-triangle': 'fas fa-exclamation-triangle',
  'far-exclamation-triangle': 'far fa-exclamation-triangle',
  'fas-expand': 'fas fa-expand',
  'far-expand': 'far fa-expand',
  'fas-expand-alt': 'fas fa-expand-alt',
  'far-expand-alt': 'far fa-expand-alt',
  'fas-expand-arrows-alt': 'fas fa-expand-arrows-alt',
  'far-expand-arrows-alt': 'far fa-expand-arrows-alt',
  'fas-expeditedssl': 'fas fa-expeditedssl',
  'far-expeditedssl': 'far fa-expeditedssl',
  'fas-external-link-alt': 'fas fa-external-link-alt',
  'far-external-link-alt': 'far fa-external-link-alt',
  'fas-external-link-square-alt': 'fas fa-external-link-square-alt',
  'far-external-link-square-alt': 'far fa-external-link-square-alt',
  'fas-eye': 'fas fa-eye',
  'far-eye': 'far fa-eye',
  'fas-eye-dropper': 'fas fa-eye-dropper',
  'far-eye-dropper': 'far fa-eye-dropper',
  'fas-eye-slash': 'fas fa-eye-slash',
  'far-eye-slash': 'far fa-eye-slash',
  'fas-facebook': 'fas fa-facebook',
  'far-facebook': 'far fa-facebook',
  'fas-facebook-f': 'fas fa-facebook-f',
  'far-facebook-f': 'far fa-facebook-f',
  'fas-facebook-messenger': 'fas fa-facebook-messenger',
  'far-facebook-messenger': 'far fa-facebook-messenger',
  'fas-facebook-square': 'fas fa-facebook-square',
  'far-facebook-square': 'far fa-facebook-square',
  'fas-fan': 'fas fa-fan',
  'far-fan': 'far fa-fan',
  'fas-fantasy-flight-games': 'fas fa-fantasy-flight-games',
  'far-fantasy-flight-games': 'far fa-fantasy-flight-games',
  'fas-fast-backward': 'fas fa-fast-backward',
  'far-fast-backward': 'far fa-fast-backward',
  'fas-fast-forward': 'fas fa-fast-forward',
  'far-fast-forward': 'far fa-fast-forward',
  'fas-faucet': 'fas fa-faucet',
  'far-faucet': 'far fa-faucet',
  'fas-fax': 'fas fa-fax',
  'far-fax': 'far fa-fax',
  'fas-feather': 'fas fa-feather',
  'far-feather': 'far fa-feather',
  'fas-feather-alt': 'fas fa-feather-alt',
  'far-feather-alt': 'far fa-feather-alt',
  'fas-fedex': 'fas fa-fedex',
  'far-fedex': 'far fa-fedex',
  'fas-fedora': 'fas fa-fedora',
  'far-fedora': 'far fa-fedora',
  'fas-female': 'fas fa-female',
  'far-female': 'far fa-female',
  'fas-fighter-jet': 'fas fa-fighter-jet',
  'far-fighter-jet': 'far fa-fighter-jet',
  'fas-figma': 'fas fa-figma',
  'far-figma': 'far fa-figma',
  'fas-file': 'fas fa-file',
  'far-file': 'far fa-file',
  'fas-file-alt': 'fas fa-file-alt',
  'far-file-alt': 'far fa-file-alt',
  'fas-file-archive': 'fas fa-file-archive',
  'far-file-archive': 'far fa-file-archive',
  'fas-file-audio': 'fas fa-file-audio',
  'far-file-audio': 'far fa-file-audio',
  'fas-file-code': 'fas fa-file-code',
  'far-file-code': 'far fa-file-code',
  'fas-file-contract': 'fas fa-file-contract',
  'far-file-contract': 'far fa-file-contract',
  'fas-file-csv': 'fas fa-file-csv',
  'far-file-csv': 'far fa-file-csv',
  'fas-file-download': 'fas fa-file-download',
  'far-file-download': 'far fa-file-download',
  'fas-file-excel': 'fas fa-file-excel',
  'far-file-excel': 'far fa-file-excel',
  'fas-file-export': 'fas fa-file-export',
  'far-file-export': 'far fa-file-export',
  'fas-file-image': 'fas fa-file-image',
  'far-file-image': 'far fa-file-image',
  'fas-file-import': 'fas fa-file-import',
  'far-file-import': 'far fa-file-import',
  'fas-file-invoice': 'fas fa-file-invoice',
  'far-file-invoice': 'far fa-file-invoice',
  'fas-file-invoice-dollar': 'fas fa-file-invoice-dollar',
  'far-file-invoice-dollar': 'far fa-file-invoice-dollar',
  'fas-file-medical': 'fas fa-file-medical',
  'far-file-medical': 'far fa-file-medical',
  'fas-file-medical-alt': 'fas fa-file-medical-alt',
  'far-file-medical-alt': 'far fa-file-medical-alt',
  'fas-file-pdf': 'fas fa-file-pdf',
  'far-file-pdf': 'far fa-file-pdf',
  'fas-file-powerpoint': 'fas fa-file-powerpoint',
  'far-file-powerpoint': 'far fa-file-powerpoint',
  'fas-file-prescription': 'fas fa-file-prescription',
  'far-file-prescription': 'far fa-file-prescription',
  'fas-file-signature': 'fas fa-file-signature',
  'far-file-signature': 'far fa-file-signature',
  'fas-file-upload': 'fas fa-file-upload',
  'far-file-upload': 'far fa-file-upload',
  'fas-file-video': 'fas fa-file-video',
  'far-file-video': 'far fa-file-video',
  'fas-file-word': 'fas fa-file-word',
  'far-file-word': 'far fa-file-word',
  'fas-fill': 'fas fa-fill',
  'far-fill': 'far fa-fill',
  'fas-fill-drip': 'fas fa-fill-drip',
  'far-fill-drip': 'far fa-fill-drip',
  'fas-film': 'fas fa-film',
  'far-film': 'far fa-film',
  'fas-filter': 'fas fa-filter',
  'far-filter': 'far fa-filter',
  'fas-fingerprint': 'fas fa-fingerprint',
  'far-fingerprint': 'far fa-fingerprint',
  'fas-fire': 'fas fa-fire',
  'far-fire': 'far fa-fire',
  'fas-fire-alt': 'fas fa-fire-alt',
  'far-fire-alt': 'far fa-fire-alt',
  'fas-fire-extinguisher': 'fas fa-fire-extinguisher',
  'far-fire-extinguisher': 'far fa-fire-extinguisher',
  'fas-firefox': 'fas fa-firefox',
  'far-firefox': 'far fa-firefox',
  'fas-firefox-browser': 'fas fa-firefox-browser',
  'far-firefox-browser': 'far fa-firefox-browser',
  'fas-first-aid': 'fas fa-first-aid',
  'far-first-aid': 'far fa-first-aid',
  'fas-first-order': 'fas fa-first-order',
  'far-first-order': 'far fa-first-order',
  'fas-first-order-alt': 'fas fa-first-order-alt',
  'far-first-order-alt': 'far fa-first-order-alt',
  'fas-firstdraft': 'fas fa-firstdraft',
  'far-firstdraft': 'far fa-firstdraft',
  'fas-fish': 'fas fa-fish',
  'far-fish': 'far fa-fish',
  'fas-fist-raised': 'fas fa-fist-raised',
  'far-fist-raised': 'far fa-fist-raised',
  'fas-flag': 'fas fa-flag',
  'far-flag': 'far fa-flag',
  'fas-flag-checkered': 'fas fa-flag-checkered',
  'far-flag-checkered': 'far fa-flag-checkered',
  'fas-flag-usa': 'fas fa-flag-usa',
  'far-flag-usa': 'far fa-flag-usa',
  'fas-flask': 'fas fa-flask',
  'far-flask': 'far fa-flask',
  'fas-flickr': 'fas fa-flickr',
  'far-flickr': 'far fa-flickr',
  'fas-flipboard': 'fas fa-flipboard',
  'far-flipboard': 'far fa-flipboard',
  'fas-flushed': 'fas fa-flushed',
  'far-flushed': 'far fa-flushed',
  'fas-fly': 'fas fa-fly',
  'far-fly': 'far fa-fly',
  'fas-folder': 'fas fa-folder',
  'far-folder': 'far fa-folder',
  'fas-folder-minus': 'fas fa-folder-minus',
  'far-folder-minus': 'far fa-folder-minus',
  'fas-folder-open': 'fas fa-folder-open',
  'far-folder-open': 'far fa-folder-open',
  'fas-folder-plus': 'fas fa-folder-plus',
  'far-folder-plus': 'far fa-folder-plus',
  'fas-font': 'fas fa-font',
  'far-font': 'far fa-font',
  'fas-font-awesome': 'fas fa-font-awesome',
  'far-font-awesome': 'far fa-font-awesome',
  'fas-font-awesome-alt': 'fas fa-font-awesome-alt',
  'far-font-awesome-alt': 'far fa-font-awesome-alt',
  'fas-font-awesome-flag': 'fas fa-font-awesome-flag',
  'far-font-awesome-flag': 'far fa-font-awesome-flag',
  'fas-font-awesome-logo-full': 'fas fa-font-awesome-logo-full',
  'far-font-awesome-logo-full': 'far fa-font-awesome-logo-full',
  'fas-fonticons': 'fas fa-fonticons',
  'far-fonticons': 'far fa-fonticons',
  'fas-fonticons-fi': 'fas fa-fonticons-fi',
  'far-fonticons-fi': 'far fa-fonticons-fi',
  'fas-football-ball': 'fas fa-football-ball',
  'far-football-ball': 'far fa-football-ball',
  'fas-fort-awesome': 'fas fa-fort-awesome',
  'far-fort-awesome': 'far fa-fort-awesome',
  'fas-fort-awesome-alt': 'fas fa-fort-awesome-alt',
  'far-fort-awesome-alt': 'far fa-fort-awesome-alt',
  'fas-forumbee': 'fas fa-forumbee',
  'far-forumbee': 'far fa-forumbee',
  'fas-forward': 'fas fa-forward',
  'far-forward': 'far fa-forward',
  'fas-foursquare': 'fas fa-foursquare',
  'far-foursquare': 'far fa-foursquare',
  'fas-free-code-camp': 'fas fa-free-code-camp',
  'far-free-code-camp': 'far fa-free-code-camp',
  'fas-freebsd': 'fas fa-freebsd',
  'far-freebsd': 'far fa-freebsd',
  'fas-frog': 'fas fa-frog',
  'far-frog': 'far fa-frog',
  'fas-frown': 'fas fa-frown',
  'far-frown': 'far fa-frown',
  'fas-frown-open': 'fas fa-frown-open',
  'far-frown-open': 'far fa-frown-open',
  'fas-fulcrum': 'fas fa-fulcrum',
  'far-fulcrum': 'far fa-fulcrum',
  'fas-funnel-dollar': 'fas fa-funnel-dollar',
  'far-funnel-dollar': 'far fa-funnel-dollar',
  'fas-futbol': 'fas fa-futbol',
  'far-futbol': 'far fa-futbol',
  'fas-galactic-republic': 'fas fa-galactic-republic',
  'far-galactic-republic': 'far fa-galactic-republic',
  'fas-galactic-senate': 'fas fa-galactic-senate',
  'far-galactic-senate': 'far fa-galactic-senate',
  'fas-gamepad': 'fas fa-gamepad',
  'far-gamepad': 'far fa-gamepad',
  'fas-gas-pump': 'fas fa-gas-pump',
  'far-gas-pump': 'far fa-gas-pump',
  'fas-gavel': 'fas fa-gavel',
  'far-gavel': 'far fa-gavel',
  'fas-gem': 'fas fa-gem',
  'far-gem': 'far fa-gem',
  'fas-genderless': 'fas fa-genderless',
  'far-genderless': 'far fa-genderless',
  'fas-get-pocket': 'fas fa-get-pocket',
  'far-get-pocket': 'far fa-get-pocket',
  'fas-gg': 'fas fa-gg',
  'far-gg': 'far fa-gg',
  'fas-gg-circle': 'fas fa-gg-circle',
  'far-gg-circle': 'far fa-gg-circle',
  'fas-ghost': 'fas fa-ghost',
  'far-ghost': 'far fa-ghost',
  'fas-gift': 'fas fa-gift',
  'far-gift': 'far fa-gift',
  'fas-gifts': 'fas fa-gifts',
  'far-gifts': 'far fa-gifts',
  'fas-git': 'fas fa-git',
  'far-git': 'far fa-git',
  'fas-git-alt': 'fas fa-git-alt',
  'far-git-alt': 'far fa-git-alt',
  'fas-git-square': 'fas fa-git-square',
  'far-git-square': 'far fa-git-square',
  'fas-github': 'fas fa-github',
  'far-github': 'far fa-github',
  'fas-github-alt': 'fas fa-github-alt',
  'far-github-alt': 'far fa-github-alt',
  'fas-github-square': 'fas fa-github-square',
  'far-github-square': 'far fa-github-square',
  'fas-gitkraken': 'fas fa-gitkraken',
  'far-gitkraken': 'far fa-gitkraken',
  'fas-gitlab': 'fas fa-gitlab',
  'far-gitlab': 'far fa-gitlab',
  'fas-gitter': 'fas fa-gitter',
  'far-gitter': 'far fa-gitter',
  'fas-glass-cheers': 'fas fa-glass-cheers',
  'far-glass-cheers': 'far fa-glass-cheers',
  'fas-glass-martini': 'fas fa-glass-martini',
  'far-glass-martini': 'far fa-glass-martini',
  'fas-glass-martini-alt': 'fas fa-glass-martini-alt',
  'far-glass-martini-alt': 'far fa-glass-martini-alt',
  'fas-glass-whiskey': 'fas fa-glass-whiskey',
  'far-glass-whiskey': 'far fa-glass-whiskey',
  'fas-glasses': 'fas fa-glasses',
  'far-glasses': 'far fa-glasses',
  'fas-glide': 'fas fa-glide',
  'far-glide': 'far fa-glide',
  'fas-glide-g': 'fas fa-glide-g',
  'far-glide-g': 'far fa-glide-g',
  'fas-globe': 'fas fa-globe',
  'far-globe': 'far fa-globe',
  'fas-globe-africa': 'fas fa-globe-africa',
  'far-globe-africa': 'far fa-globe-africa',
  'fas-globe-americas': 'fas fa-globe-americas',
  'far-globe-americas': 'far fa-globe-americas',
  'fas-globe-asia': 'fas fa-globe-asia',
  'far-globe-asia': 'far fa-globe-asia',
  'fas-globe-europe': 'fas fa-globe-europe',
  'far-globe-europe': 'far fa-globe-europe',
  'fas-gofore': 'fas fa-gofore',
  'far-gofore': 'far fa-gofore',
  'fas-golf-ball': 'fas fa-golf-ball',
  'far-golf-ball': 'far fa-golf-ball',
  'fas-goodreads': 'fas fa-goodreads',
  'far-goodreads': 'far fa-goodreads',
  'fas-goodreads-g': 'fas fa-goodreads-g',
  'far-goodreads-g': 'far fa-goodreads-g',
  'fas-google': 'fas fa-google',
  'far-google': 'far fa-google',
  'fas-google-drive': 'fas fa-google-drive',
  'far-google-drive': 'far fa-google-drive',
  'fas-google-pay': 'fas fa-google-pay',
  'far-google-pay': 'far fa-google-pay',
  'fas-google-play': 'fas fa-google-play',
  'far-google-play': 'far fa-google-play',
  'fas-google-plus': 'fas fa-google-plus',
  'far-google-plus': 'far fa-google-plus',
  'fas-google-plus-g': 'fas fa-google-plus-g',
  'far-google-plus-g': 'far fa-google-plus-g',
  'fas-google-plus-square': 'fas fa-google-plus-square',
  'far-google-plus-square': 'far fa-google-plus-square',
  'fas-google-wallet': 'fas fa-google-wallet',
  'far-google-wallet': 'far fa-google-wallet',
  'fas-gopuram': 'fas fa-gopuram',
  'far-gopuram': 'far fa-gopuram',
  'fas-graduation-cap': 'fas fa-graduation-cap',
  'far-graduation-cap': 'far fa-graduation-cap',
  'fas-gratipay': 'fas fa-gratipay',
  'far-gratipay': 'far fa-gratipay',
  'fas-grav': 'fas fa-grav',
  'far-grav': 'far fa-grav',
  'fas-greater-than': 'fas fa-greater-than',
  'far-greater-than': 'far fa-greater-than',
  'fas-greater-than-equal': 'fas fa-greater-than-equal',
  'far-greater-than-equal': 'far fa-greater-than-equal',
  'fas-grimace': 'fas fa-grimace',
  'far-grimace': 'far fa-grimace',
  'fas-grin': 'fas fa-grin',
  'far-grin': 'far fa-grin',
  'fas-grin-alt': 'fas fa-grin-alt',
  'far-grin-alt': 'far fa-grin-alt',
  'fas-grin-beam': 'fas fa-grin-beam',
  'far-grin-beam': 'far fa-grin-beam',
  'fas-grin-beam-sweat': 'fas fa-grin-beam-sweat',
  'far-grin-beam-sweat': 'far fa-grin-beam-sweat',
  'fas-grin-hearts': 'fas fa-grin-hearts',
  'far-grin-hearts': 'far fa-grin-hearts',
  'fas-grin-squint': 'fas fa-grin-squint',
  'far-grin-squint': 'far fa-grin-squint',
  'fas-grin-squint-tears': 'fas fa-grin-squint-tears',
  'far-grin-squint-tears': 'far fa-grin-squint-tears',
  'fas-grin-stars': 'fas fa-grin-stars',
  'far-grin-stars': 'far fa-grin-stars',
  'fas-grin-tears': 'fas fa-grin-tears',
  'far-grin-tears': 'far fa-grin-tears',
  'fas-grin-tongue': 'fas fa-grin-tongue',
  'far-grin-tongue': 'far fa-grin-tongue',
  'fas-grin-tongue-squint': 'fas fa-grin-tongue-squint',
  'far-grin-tongue-squint': 'far fa-grin-tongue-squint',
  'fas-grin-tongue-wink': 'fas fa-grin-tongue-wink',
  'far-grin-tongue-wink': 'far fa-grin-tongue-wink',
  'fas-grin-wink': 'fas fa-grin-wink',
  'far-grin-wink': 'far fa-grin-wink',
  'fas-grip-horizontal': 'fas fa-grip-horizontal',
  'far-grip-horizontal': 'far fa-grip-horizontal',
  'fas-grip-lines': 'fas fa-grip-lines',
  'far-grip-lines': 'far fa-grip-lines',
  'fas-grip-lines-vertical': 'fas fa-grip-lines-vertical',
  'far-grip-lines-vertical': 'far fa-grip-lines-vertical',
  'fas-grip-vertical': 'fas fa-grip-vertical',
  'far-grip-vertical': 'far fa-grip-vertical',
  'fas-gripfire': 'fas fa-gripfire',
  'far-gripfire': 'far fa-gripfire',
  'fas-grunt': 'fas fa-grunt',
  'far-grunt': 'far fa-grunt',
  'fas-guilded': 'fas fa-guilded',
  'far-guilded': 'far fa-guilded',
  'fas-guitar': 'fas fa-guitar',
  'far-guitar': 'far fa-guitar',
  'fas-gulp': 'fas fa-gulp',
  'far-gulp': 'far fa-gulp',
  'fas-h-square': 'fas fa-h-square',
  'far-h-square': 'far fa-h-square',
  'fas-hacker-news': 'fas fa-hacker-news',
  'far-hacker-news': 'far fa-hacker-news',
  'fas-hacker-news-square': 'fas fa-hacker-news-square',
  'far-hacker-news-square': 'far fa-hacker-news-square',
  'fas-hackerrank': 'fas fa-hackerrank',
  'far-hackerrank': 'far fa-hackerrank',
  'fas-hamburger': 'fas fa-hamburger',
  'far-hamburger': 'far fa-hamburger',
  'fas-hammer': 'fas fa-hammer',
  'far-hammer': 'far fa-hammer',
  'fas-hamsa': 'fas fa-hamsa',
  'far-hamsa': 'far fa-hamsa',
  'fas-hand-holding': 'fas fa-hand-holding',
  'far-hand-holding': 'far fa-hand-holding',
  'fas-hand-holding-heart': 'fas fa-hand-holding-heart',
  'far-hand-holding-heart': 'far fa-hand-holding-heart',
  'fas-hand-holding-medical': 'fas fa-hand-holding-medical',
  'far-hand-holding-medical': 'far fa-hand-holding-medical',
  'fas-hand-holding-usd': 'fas fa-hand-holding-usd',
  'far-hand-holding-usd': 'far fa-hand-holding-usd',
  'fas-hand-holding-water': 'fas fa-hand-holding-water',
  'far-hand-holding-water': 'far fa-hand-holding-water',
  'fas-hand-lizard': 'fas fa-hand-lizard',
  'far-hand-lizard': 'far fa-hand-lizard',
  'fas-hand-middle-finger': 'fas fa-hand-middle-finger',
  'far-hand-middle-finger': 'far fa-hand-middle-finger',
  'fas-hand-paper': 'fas fa-hand-paper',
  'far-hand-paper': 'far fa-hand-paper',
  'fas-hand-peace': 'fas fa-hand-peace',
  'far-hand-peace': 'far fa-hand-peace',
  'fas-hand-point-down': 'fas fa-hand-point-down',
  'far-hand-point-down': 'far fa-hand-point-down',
  'fas-hand-point-left': 'fas fa-hand-point-left',
  'far-hand-point-left': 'far fa-hand-point-left',
  'fas-hand-point-right': 'fas fa-hand-point-right',
  'far-hand-point-right': 'far fa-hand-point-right',
  'fas-hand-point-up': 'fas fa-hand-point-up',
  'far-hand-point-up': 'far fa-hand-point-up',
  'fas-hand-pointer': 'fas fa-hand-pointer',
  'far-hand-pointer': 'far fa-hand-pointer',
  'fas-hand-rock': 'fas fa-hand-rock',
  'far-hand-rock': 'far fa-hand-rock',
  'fas-hand-scissors': 'fas fa-hand-scissors',
  'far-hand-scissors': 'far fa-hand-scissors',
  'fas-hand-sparkles': 'fas fa-hand-sparkles',
  'far-hand-sparkles': 'far fa-hand-sparkles',
  'fas-hand-spock': 'fas fa-hand-spock',
  'far-hand-spock': 'far fa-hand-spock',
  'fas-hands': 'fas fa-hands',
  'far-hands': 'far fa-hands',
  'fas-hands-helping': 'fas fa-hands-helping',
  'far-hands-helping': 'far fa-hands-helping',
  'fas-hands-wash': 'fas fa-hands-wash',
  'far-hands-wash': 'far fa-hands-wash',
  'fas-handshake': 'fas fa-handshake',
  'far-handshake': 'far fa-handshake',
  'fas-handshake-alt-slash': 'fas fa-handshake-alt-slash',
  'far-handshake-alt-slash': 'far fa-handshake-alt-slash',
  'fas-handshake-slash': 'fas fa-handshake-slash',
  'far-handshake-slash': 'far fa-handshake-slash',
  'fas-hanukiah': 'fas fa-hanukiah',
  'far-hanukiah': 'far fa-hanukiah',
  'fas-hard-hat': 'fas fa-hard-hat',
  'far-hard-hat': 'far fa-hard-hat',
  'fas-hashtag': 'fas fa-hashtag',
  'far-hashtag': 'far fa-hashtag',
  'fas-hat-cowboy': 'fas fa-hat-cowboy',
  'far-hat-cowboy': 'far fa-hat-cowboy',
  'fas-hat-cowboy-side': 'fas fa-hat-cowboy-side',
  'far-hat-cowboy-side': 'far fa-hat-cowboy-side',
  'fas-hat-wizard': 'fas fa-hat-wizard',
  'far-hat-wizard': 'far fa-hat-wizard',
  'fas-hdd': 'fas fa-hdd',
  'far-hdd': 'far fa-hdd',
  'fas-head-side-cough': 'fas fa-head-side-cough',
  'far-head-side-cough': 'far fa-head-side-cough',
  'fas-head-side-cough-slash': 'fas fa-head-side-cough-slash',
  'far-head-side-cough-slash': 'far fa-head-side-cough-slash',
  'fas-head-side-mask': 'fas fa-head-side-mask',
  'far-head-side-mask': 'far fa-head-side-mask',
  'fas-head-side-virus': 'fas fa-head-side-virus',
  'far-head-side-virus': 'far fa-head-side-virus',
  'fas-heading': 'fas fa-heading',
  'far-heading': 'far fa-heading',
  'fas-headphones': 'fas fa-headphones',
  'far-headphones': 'far fa-headphones',
  'fas-headphones-alt': 'fas fa-headphones-alt',
  'far-headphones-alt': 'far fa-headphones-alt',
  'fas-headset': 'fas fa-headset',
  'far-headset': 'far fa-headset',
  'fas-heart': 'fas fa-heart',
  'far-heart': 'far fa-heart',
  'fas-heart-broken': 'fas fa-heart-broken',
  'far-heart-broken': 'far fa-heart-broken',
  'fas-heartbeat': 'fas fa-heartbeat',
  'far-heartbeat': 'far fa-heartbeat',
  'fas-helicopter': 'fas fa-helicopter',
  'far-helicopter': 'far fa-helicopter',
  'fas-highlighter': 'fas fa-highlighter',
  'far-highlighter': 'far fa-highlighter',
  'fas-hiking': 'fas fa-hiking',
  'far-hiking': 'far fa-hiking',
  'fas-hippo': 'fas fa-hippo',
  'far-hippo': 'far fa-hippo',
  'fas-hips': 'fas fa-hips',
  'far-hips': 'far fa-hips',
  'fas-hire-a-helper': 'fas fa-hire-a-helper',
  'far-hire-a-helper': 'far fa-hire-a-helper',
  'fas-history': 'fas fa-history',
  'far-history': 'far fa-history',
  'fas-hive': 'fas fa-hive',
  'far-hive': 'far fa-hive',
  'fas-hockey-puck': 'fas fa-hockey-puck',
  'far-hockey-puck': 'far fa-hockey-puck',
  'fas-holly-berry': 'fas fa-holly-berry',
  'far-holly-berry': 'far fa-holly-berry',
  'fas-home': 'fas fa-home',
  'far-home': 'far fa-home',
  'fas-hooli': 'fas fa-hooli',
  'far-hooli': 'far fa-hooli',
  'fas-hornbill': 'fas fa-hornbill',
  'far-hornbill': 'far fa-hornbill',
  'fas-horse': 'fas fa-horse',
  'far-horse': 'far fa-horse',
  'fas-horse-head': 'fas fa-horse-head',
  'far-horse-head': 'far fa-horse-head',
  'fas-hospital': 'fas fa-hospital',
  'far-hospital': 'far fa-hospital',
  'fas-hospital-alt': 'fas fa-hospital-alt',
  'far-hospital-alt': 'far fa-hospital-alt',
  'fas-hospital-symbol': 'fas fa-hospital-symbol',
  'far-hospital-symbol': 'far fa-hospital-symbol',
  'fas-hospital-user': 'fas fa-hospital-user',
  'far-hospital-user': 'far fa-hospital-user',
  'fas-hot-tub': 'fas fa-hot-tub',
  'far-hot-tub': 'far fa-hot-tub',
  'fas-hotdog': 'fas fa-hotdog',
  'far-hotdog': 'far fa-hotdog',
  'fas-hotel': 'fas fa-hotel',
  'far-hotel': 'far fa-hotel',
  'fas-hotjar': 'fas fa-hotjar',
  'far-hotjar': 'far fa-hotjar',
  'fas-hourglass': 'fas fa-hourglass',
  'far-hourglass': 'far fa-hourglass',
  'fas-hourglass-end': 'fas fa-hourglass-end',
  'far-hourglass-end': 'far fa-hourglass-end',
  'fas-hourglass-half': 'fas fa-hourglass-half',
  'far-hourglass-half': 'far fa-hourglass-half',
  'fas-hourglass-start': 'fas fa-hourglass-start',
  'far-hourglass-start': 'far fa-hourglass-start',
  'fas-house-damage': 'fas fa-house-damage',
  'far-house-damage': 'far fa-house-damage',
  'fas-house-user': 'fas fa-house-user',
  'far-house-user': 'far fa-house-user',
  'fas-houzz': 'fas fa-houzz',
  'far-houzz': 'far fa-houzz',
  'fas-hryvnia': 'fas fa-hryvnia',
  'far-hryvnia': 'far fa-hryvnia',
  'fas-html5': 'fas fa-html5',
  'far-html5': 'far fa-html5',
  'fas-hubspot': 'fas fa-hubspot',
  'far-hubspot': 'far fa-hubspot',
  'fas-i-cursor': 'fas fa-i-cursor',
  'far-i-cursor': 'far fa-i-cursor',
  'fas-ice-cream': 'fas fa-ice-cream',
  'far-ice-cream': 'far fa-ice-cream',
  'fas-icicles': 'fas fa-icicles',
  'far-icicles': 'far fa-icicles',
  'fas-icons': 'fas fa-icons',
  'far-icons': 'far fa-icons',
  'fas-id-badge': 'fas fa-id-badge',
  'far-id-badge': 'far fa-id-badge',
  'fas-id-card': 'fas fa-id-card',
  'far-id-card': 'far fa-id-card',
  'fas-id-card-alt': 'fas fa-id-card-alt',
  'far-id-card-alt': 'far fa-id-card-alt',
  'fas-ideal': 'fas fa-ideal',
  'far-ideal': 'far fa-ideal',
  'fas-igloo': 'fas fa-igloo',
  'far-igloo': 'far fa-igloo',
  'fas-image': 'fas fa-image',
  'far-image': 'far fa-image',
  'fas-images': 'fas fa-images',
  'far-images': 'far fa-images',
  'fas-imdb': 'fas fa-imdb',
  'far-imdb': 'far fa-imdb',
  'fas-inbox': 'fas fa-inbox',
  'far-inbox': 'far fa-inbox',
  'fas-indent': 'fas fa-indent',
  'far-indent': 'far fa-indent',
  'fas-industry': 'fas fa-industry',
  'far-industry': 'far fa-industry',
  'fas-infinity': 'fas fa-infinity',
  'far-infinity': 'far fa-infinity',
  'fas-info': 'fas fa-info',
  'far-info': 'far fa-info',
  'fas-info-circle': 'fas fa-info-circle',
  'far-info-circle': 'far fa-info-circle',
  'fas-innosoft': 'fas fa-innosoft',
  'far-innosoft': 'far fa-innosoft',
  'fas-instagram': 'fas fa-instagram',
  'far-instagram': 'far fa-instagram',
  'fas-instagram-square': 'fas fa-instagram-square',
  'far-instagram-square': 'far fa-instagram-square',
  'fas-instalod': 'fas fa-instalod',
  'far-instalod': 'far fa-instalod',
  'fas-intercom': 'fas fa-intercom',
  'far-intercom': 'far fa-intercom',
  'fas-internet-explorer': 'fas fa-internet-explorer',
  'far-internet-explorer': 'far fa-internet-explorer',
  'fas-invision': 'fas fa-invision',
  'far-invision': 'far fa-invision',
  'fas-ioxhost': 'fas fa-ioxhost',
  'far-ioxhost': 'far fa-ioxhost',
  'fas-italic': 'fas fa-italic',
  'far-italic': 'far fa-italic',
  'fas-itch-io': 'fas fa-itch-io',
  'far-itch-io': 'far fa-itch-io',
  'fas-itunes': 'fas fa-itunes',
  'far-itunes': 'far fa-itunes',
  'fas-itunes-note': 'fas fa-itunes-note',
  'far-itunes-note': 'far fa-itunes-note',
  'fas-java': 'fas fa-java',
  'far-java': 'far fa-java',
  'fas-jedi': 'fas fa-jedi',
  'far-jedi': 'far fa-jedi',
  'fas-jedi-order': 'fas fa-jedi-order',
  'far-jedi-order': 'far fa-jedi-order',
  'fas-jenkins': 'fas fa-jenkins',
  'far-jenkins': 'far fa-jenkins',
  'fas-jira': 'fas fa-jira',
  'far-jira': 'far fa-jira',
  'fas-joget': 'fas fa-joget',
  'far-joget': 'far fa-joget',
  'fas-joint': 'fas fa-joint',
  'far-joint': 'far fa-joint',
  'fas-joomla': 'fas fa-joomla',
  'far-joomla': 'far fa-joomla',
  'fas-journal-whills': 'fas fa-journal-whills',
  'far-journal-whills': 'far fa-journal-whills',
  'fas-js': 'fas fa-js',
  'far-js': 'far fa-js',
  'fas-js-square': 'fas fa-js-square',
  'far-js-square': 'far fa-js-square',
  'fas-jsfiddle': 'fas fa-jsfiddle',
  'far-jsfiddle': 'far fa-jsfiddle',
  'fas-kaaba': 'fas fa-kaaba',
  'far-kaaba': 'far fa-kaaba',
  'fas-kaggle': 'fas fa-kaggle',
  'far-kaggle': 'far fa-kaggle',
  'fas-key': 'fas fa-key',
  'far-key': 'far fa-key',
  'fas-keybase': 'fas fa-keybase',
  'far-keybase': 'far fa-keybase',
  'fas-keyboard': 'fas fa-keyboard',
  'far-keyboard': 'far fa-keyboard',
  'fas-keycdn': 'fas fa-keycdn',
  'far-keycdn': 'far fa-keycdn',
  'fas-khanda': 'fas fa-khanda',
  'far-khanda': 'far fa-khanda',
  'fas-kickstarter': 'fas fa-kickstarter',
  'far-kickstarter': 'far fa-kickstarter',
  'fas-kickstarter-k': 'fas fa-kickstarter-k',
  'far-kickstarter-k': 'far fa-kickstarter-k',
  'fas-kiss': 'fas fa-kiss',
  'far-kiss': 'far fa-kiss',
  'fas-kiss-beam': 'fas fa-kiss-beam',
  'far-kiss-beam': 'far fa-kiss-beam',
  'fas-kiss-wink-heart': 'fas fa-kiss-wink-heart',
  'far-kiss-wink-heart': 'far fa-kiss-wink-heart',
  'fas-kiwi-bird': 'fas fa-kiwi-bird',
  'far-kiwi-bird': 'far fa-kiwi-bird',
  'fas-korvue': 'fas fa-korvue',
  'far-korvue': 'far fa-korvue',
  'fas-landmark': 'fas fa-landmark',
  'far-landmark': 'far fa-landmark',
  'fas-language': 'fas fa-language',
  'far-language': 'far fa-language',
  'fas-laptop': 'fas fa-laptop',
  'far-laptop': 'far fa-laptop',
  'fas-laptop-code': 'fas fa-laptop-code',
  'far-laptop-code': 'far fa-laptop-code',
  'fas-laptop-house': 'fas fa-laptop-house',
  'far-laptop-house': 'far fa-laptop-house',
  'fas-laptop-medical': 'fas fa-laptop-medical',
  'far-laptop-medical': 'far fa-laptop-medical',
  'fas-laravel': 'fas fa-laravel',
  'far-laravel': 'far fa-laravel',
  'fas-lastfm': 'fas fa-lastfm',
  'far-lastfm': 'far fa-lastfm',
  'fas-lastfm-square': 'fas fa-lastfm-square',
  'far-lastfm-square': 'far fa-lastfm-square',
  'fas-laugh': 'fas fa-laugh',
  'far-laugh': 'far fa-laugh',
  'fas-laugh-beam': 'fas fa-laugh-beam',
  'far-laugh-beam': 'far fa-laugh-beam',
  'fas-laugh-squint': 'fas fa-laugh-squint',
  'far-laugh-squint': 'far fa-laugh-squint',
  'fas-laugh-wink': 'fas fa-laugh-wink',
  'far-laugh-wink': 'far fa-laugh-wink',
  'fas-layer-group': 'fas fa-layer-group',
  'far-layer-group': 'far fa-layer-group',
  'fas-leaf': 'fas fa-leaf',
  'far-leaf': 'far fa-leaf',
  'fas-leanpub': 'fas fa-leanpub',
  'far-leanpub': 'far fa-leanpub',
  'fas-lemon': 'fas fa-lemon',
  'far-lemon': 'far fa-lemon',
  'fas-less': 'fas fa-less',
  'far-less': 'far fa-less',
  'fas-less-than': 'fas fa-less-than',
  'far-less-than': 'far fa-less-than',
  'fas-less-than-equal': 'fas fa-less-than-equal',
  'far-less-than-equal': 'far fa-less-than-equal',
  'fas-level-down-alt': 'fas fa-level-down-alt',
  'far-level-down-alt': 'far fa-level-down-alt',
  'fas-level-up-alt': 'fas fa-level-up-alt',
  'far-level-up-alt': 'far fa-level-up-alt',
  'fas-life-ring': 'fas fa-life-ring',
  'far-life-ring': 'far fa-life-ring',
  'fas-lightbulb': 'fas fa-lightbulb',
  'far-lightbulb': 'far fa-lightbulb',
  'fas-line': 'fas fa-line',
  'far-line': 'far fa-line',
  'fas-link': 'fas fa-link',
  'far-link': 'far fa-link',
  'fas-linkedin': 'fas fa-linkedin',
  'far-linkedin': 'far fa-linkedin',
  'fas-linkedin-in': 'fas fa-linkedin-in',
  'far-linkedin-in': 'far fa-linkedin-in',
  'fas-linode': 'fas fa-linode',
  'far-linode': 'far fa-linode',
  'fas-linux': 'fas fa-linux',
  'far-linux': 'far fa-linux',
  'fas-lira-sign': 'fas fa-lira-sign',
  'far-lira-sign': 'far fa-lira-sign',
  'fas-list': 'fas fa-list',
  'far-list': 'far fa-list',
  'fas-list-alt': 'fas fa-list-alt',
  'far-list-alt': 'far fa-list-alt',
  'fas-list-ol': 'fas fa-list-ol',
  'far-list-ol': 'far fa-list-ol',
  'fas-list-ul': 'fas fa-list-ul',
  'far-list-ul': 'far fa-list-ul',
  'fas-location-arrow': 'fas fa-location-arrow',
  'far-location-arrow': 'far fa-location-arrow',
  'fas-lock': 'fas fa-lock',
  'far-lock': 'far fa-lock',
  'fas-lock-open': 'fas fa-lock-open',
  'far-lock-open': 'far fa-lock-open',
  'fas-long-arrow-alt-down': 'fas fa-long-arrow-alt-down',
  'far-long-arrow-alt-down': 'far fa-long-arrow-alt-down',
  'fas-long-arrow-alt-left': 'fas fa-long-arrow-alt-left',
  'far-long-arrow-alt-left': 'far fa-long-arrow-alt-left',
  'fas-long-arrow-alt-right': 'fas fa-long-arrow-alt-right',
  'far-long-arrow-alt-right': 'far fa-long-arrow-alt-right',
  'fas-long-arrow-alt-up': 'fas fa-long-arrow-alt-up',
  'far-long-arrow-alt-up': 'far fa-long-arrow-alt-up',
  'fas-low-vision': 'fas fa-low-vision',
  'far-low-vision': 'far fa-low-vision',
  'fas-luggage-cart': 'fas fa-luggage-cart',
  'far-luggage-cart': 'far fa-luggage-cart',
  'fas-lungs': 'fas fa-lungs',
  'far-lungs': 'far fa-lungs',
  'fas-lungs-virus': 'fas fa-lungs-virus',
  'far-lungs-virus': 'far fa-lungs-virus',
  'fas-lyft': 'fas fa-lyft',
  'far-lyft': 'far fa-lyft',
  'fas-magento': 'fas fa-magento',
  'far-magento': 'far fa-magento',
  'fas-magic': 'fas fa-magic',
  'far-magic': 'far fa-magic',
  'fas-magnet': 'fas fa-magnet',
  'far-magnet': 'far fa-magnet',
  'fas-mail-bulk': 'fas fa-mail-bulk',
  'far-mail-bulk': 'far fa-mail-bulk',
  'fas-mailchimp': 'fas fa-mailchimp',
  'far-mailchimp': 'far fa-mailchimp',
  'fas-male': 'fas fa-male',
  'far-male': 'far fa-male',
  'fas-mandalorian': 'fas fa-mandalorian',
  'far-mandalorian': 'far fa-mandalorian',
  'fas-map': 'fas fa-map',
  'far-map': 'far fa-map',
  'fas-map-marked': 'fas fa-map-marked',
  'far-map-marked': 'far fa-map-marked',
  'fas-map-marked-alt': 'fas fa-map-marked-alt',
  'far-map-marked-alt': 'far fa-map-marked-alt',
  'fas-map-marker': 'fas fa-map-marker',
  'far-map-marker': 'far fa-map-marker',
  'fas-map-marker-alt': 'fas fa-map-marker-alt',
  'far-map-marker-alt': 'far fa-map-marker-alt',
  'fas-map-pin': 'fas fa-map-pin',
  'far-map-pin': 'far fa-map-pin',
  'fas-map-signs': 'fas fa-map-signs',
  'far-map-signs': 'far fa-map-signs',
  'fas-markdown': 'fas fa-markdown',
  'far-markdown': 'far fa-markdown',
  'fas-marker': 'fas fa-marker',
  'far-marker': 'far fa-marker',
  'fas-mars': 'fas fa-mars',
  'far-mars': 'far fa-mars',
  'fas-mars-double': 'fas fa-mars-double',
  'far-mars-double': 'far fa-mars-double',
  'fas-mars-stroke': 'fas fa-mars-stroke',
  'far-mars-stroke': 'far fa-mars-stroke',
  'fas-mars-stroke-h': 'fas fa-mars-stroke-h',
  'far-mars-stroke-h': 'far fa-mars-stroke-h',
  'fas-mars-stroke-v': 'fas fa-mars-stroke-v',
  'far-mars-stroke-v': 'far fa-mars-stroke-v',
  'fas-mask': 'fas fa-mask',
  'far-mask': 'far fa-mask',
  'fas-mastodon': 'fas fa-mastodon',
  'far-mastodon': 'far fa-mastodon',
  'fas-maxcdn': 'fas fa-maxcdn',
  'far-maxcdn': 'far fa-maxcdn',
  'fas-mdb': 'fas fa-mdb',
  'far-mdb': 'far fa-mdb',
  'fas-medal': 'fas fa-medal',
  'far-medal': 'far fa-medal',
  'fas-medapps': 'fas fa-medapps',
  'far-medapps': 'far fa-medapps',
  'fas-medium': 'fas fa-medium',
  'far-medium': 'far fa-medium',
  'fas-medium-m': 'fas fa-medium-m',
  'far-medium-m': 'far fa-medium-m',
  'fas-medkit': 'fas fa-medkit',
  'far-medkit': 'far fa-medkit',
  'fas-medrt': 'fas fa-medrt',
  'far-medrt': 'far fa-medrt',
  'fas-meetup': 'fas fa-meetup',
  'far-meetup': 'far fa-meetup',
  'fas-megaport': 'fas fa-megaport',
  'far-megaport': 'far fa-megaport',
  'fas-meh': 'fas fa-meh',
  'far-meh': 'far fa-meh',
  'fas-meh-blank': 'fas fa-meh-blank',
  'far-meh-blank': 'far fa-meh-blank',
  'fas-meh-rolling-eyes': 'fas fa-meh-rolling-eyes',
  'far-meh-rolling-eyes': 'far fa-meh-rolling-eyes',
  'fas-memory': 'fas fa-memory',
  'far-memory': 'far fa-memory',
  'fas-mendeley': 'fas fa-mendeley',
  'far-mendeley': 'far fa-mendeley',
  'fas-menorah': 'fas fa-menorah',
  'far-menorah': 'far fa-menorah',
  'fas-mercury': 'fas fa-mercury',
  'far-mercury': 'far fa-mercury',
  'fas-meteor': 'fas fa-meteor',
  'far-meteor': 'far fa-meteor',
  'fas-microblog': 'fas fa-microblog',
  'far-microblog': 'far fa-microblog',
  'fas-microchip': 'fas fa-microchip',
  'far-microchip': 'far fa-microchip',
  'fas-microphone': 'fas fa-microphone',
  'far-microphone': 'far fa-microphone',
  'fas-microphone-alt': 'fas fa-microphone-alt',
  'far-microphone-alt': 'far fa-microphone-alt',
  'fas-microphone-alt-slash': 'fas fa-microphone-alt-slash',
  'far-microphone-alt-slash': 'far fa-microphone-alt-slash',
  'fas-microphone-slash': 'fas fa-microphone-slash',
  'far-microphone-slash': 'far fa-microphone-slash',
  'fas-microscope': 'fas fa-microscope',
  'far-microscope': 'far fa-microscope',
  'fas-microsoft': 'fas fa-microsoft',
  'far-microsoft': 'far fa-microsoft',
  'fas-minus': 'fas fa-minus',
  'far-minus': 'far fa-minus',
  'fas-minus-circle': 'fas fa-minus-circle',
  'far-minus-circle': 'far fa-minus-circle',
  'fas-minus-square': 'fas fa-minus-square',
  'far-minus-square': 'far fa-minus-square',
  'fas-mitten': 'fas fa-mitten',
  'far-mitten': 'far fa-mitten',
  'fas-mix': 'fas fa-mix',
  'far-mix': 'far fa-mix',
  'fas-mixcloud': 'fas fa-mixcloud',
  'far-mixcloud': 'far fa-mixcloud',
  'fas-mixer': 'fas fa-mixer',
  'far-mixer': 'far fa-mixer',
  'fas-mizuni': 'fas fa-mizuni',
  'far-mizuni': 'far fa-mizuni',
  'fas-mobile': 'fas fa-mobile',
  'far-mobile': 'far fa-mobile',
  'fas-mobile-alt': 'fas fa-mobile-alt',
  'far-mobile-alt': 'far fa-mobile-alt',
  'fas-modx': 'fas fa-modx',
  'far-modx': 'far fa-modx',
  'fas-monero': 'fas fa-monero',
  'far-monero': 'far fa-monero',
  'fas-money-bill': 'fas fa-money-bill',
  'far-money-bill': 'far fa-money-bill',
  'fas-money-bill-alt': 'fas fa-money-bill-alt',
  'far-money-bill-alt': 'far fa-money-bill-alt',
  'fas-money-bill-wave': 'fas fa-money-bill-wave',
  'far-money-bill-wave': 'far fa-money-bill-wave',
  'fas-money-bill-wave-alt': 'fas fa-money-bill-wave-alt',
  'far-money-bill-wave-alt': 'far fa-money-bill-wave-alt',
  'fas-money-check': 'fas fa-money-check',
  'far-money-check': 'far fa-money-check',
  'fas-money-check-alt': 'fas fa-money-check-alt',
  'far-money-check-alt': 'far fa-money-check-alt',
  'fas-monument': 'fas fa-monument',
  'far-monument': 'far fa-monument',
  'fas-moon': 'fas fa-moon',
  'far-moon': 'far fa-moon',
  'fas-mortar-pestle': 'fas fa-mortar-pestle',
  'far-mortar-pestle': 'far fa-mortar-pestle',
  'fas-mosque': 'fas fa-mosque',
  'far-mosque': 'far fa-mosque',
  'fas-motorcycle': 'fas fa-motorcycle',
  'far-motorcycle': 'far fa-motorcycle',
  'fas-mountain': 'fas fa-mountain',
  'far-mountain': 'far fa-mountain',
  'fas-mouse': 'fas fa-mouse',
  'far-mouse': 'far fa-mouse',
  'fas-mouse-pointer': 'fas fa-mouse-pointer',
  'far-mouse-pointer': 'far fa-mouse-pointer',
  'fas-mug-hot': 'fas fa-mug-hot',
  'far-mug-hot': 'far fa-mug-hot',
  'fas-music': 'fas fa-music',
  'far-music': 'far fa-music',
  'fas-napster': 'fas fa-napster',
  'far-napster': 'far fa-napster',
  'fas-neos': 'fas fa-neos',
  'far-neos': 'far fa-neos',
  'fas-network-wired': 'fas fa-network-wired',
  'far-network-wired': 'far fa-network-wired',
  'fas-neuter': 'fas fa-neuter',
  'far-neuter': 'far fa-neuter',
  'fas-newspaper': 'fas fa-newspaper',
  'far-newspaper': 'far fa-newspaper',
  'fas-nimblr': 'fas fa-nimblr',
  'far-nimblr': 'far fa-nimblr',
  'fas-node': 'fas fa-node',
  'far-node': 'far fa-node',
  'fas-node-js': 'fas fa-node-js',
  'far-node-js': 'far fa-node-js',
  'fas-not-equal': 'fas fa-not-equal',
  'far-not-equal': 'far fa-not-equal',
  'fas-notes-medical': 'fas fa-notes-medical',
  'far-notes-medical': 'far fa-notes-medical',
  'fas-npm': 'fas fa-npm',
  'far-npm': 'far fa-npm',
  'fas-ns8': 'fas fa-ns8',
  'far-ns8': 'far fa-ns8',
  'fas-nutritionix': 'fas fa-nutritionix',
  'far-nutritionix': 'far fa-nutritionix',
  'fas-object-group': 'fas fa-object-group',
  'far-object-group': 'far fa-object-group',
  'fas-object-ungroup': 'fas fa-object-ungroup',
  'far-object-ungroup': 'far fa-object-ungroup',
  'fas-octopus-deploy': 'fas fa-octopus-deploy',
  'far-octopus-deploy': 'far fa-octopus-deploy',
  'fas-odnoklassniki': 'fas fa-odnoklassniki',
  'far-odnoklassniki': 'far fa-odnoklassniki',
  'fas-odnoklassniki-square': 'fas fa-odnoklassniki-square',
  'far-odnoklassniki-square': 'far fa-odnoklassniki-square',
  'fas-oil-can': 'fas fa-oil-can',
  'far-oil-can': 'far fa-oil-can',
  'fas-old-republic': 'fas fa-old-republic',
  'far-old-republic': 'far fa-old-republic',
  'fas-om': 'fas fa-om',
  'far-om': 'far fa-om',
  'fas-opencart': 'fas fa-opencart',
  'far-opencart': 'far fa-opencart',
  'fas-openid': 'fas fa-openid',
  'far-openid': 'far fa-openid',
  'fas-opera': 'fas fa-opera',
  'far-opera': 'far fa-opera',
  'fas-optin-monster': 'fas fa-optin-monster',
  'far-optin-monster': 'far fa-optin-monster',
  'fas-orcid': 'fas fa-orcid',
  'far-orcid': 'far fa-orcid',
  'fas-osi': 'fas fa-osi',
  'far-osi': 'far fa-osi',
  'fas-otter': 'fas fa-otter',
  'far-otter': 'far fa-otter',
  'fas-outdent': 'fas fa-outdent',
  'far-outdent': 'far fa-outdent',
  'fas-page4': 'fas fa-page4',
  'far-page4': 'far fa-page4',
  'fas-pagelines': 'fas fa-pagelines',
  'far-pagelines': 'far fa-pagelines',
  'fas-pager': 'fas fa-pager',
  'far-pager': 'far fa-pager',
  'fas-paint-brush': 'fas fa-paint-brush',
  'far-paint-brush': 'far fa-paint-brush',
  'fas-paint-roller': 'fas fa-paint-roller',
  'far-paint-roller': 'far fa-paint-roller',
  'fas-palette': 'fas fa-palette',
  'far-palette': 'far fa-palette',
  'fas-palfed': 'fas fa-palfed',
  'far-palfed': 'far fa-palfed',
  'fas-pallet': 'fas fa-pallet',
  'far-pallet': 'far fa-pallet',
  'fas-paper-plane': 'fas fa-paper-plane',
  'far-paper-plane': 'far fa-paper-plane',
  'fas-paperclip': 'fas fa-paperclip',
  'far-paperclip': 'far fa-paperclip',
  'fas-parachute-box': 'fas fa-parachute-box',
  'far-parachute-box': 'far fa-parachute-box',
  'fas-paragraph': 'fas fa-paragraph',
  'far-paragraph': 'far fa-paragraph',
  'fas-parking': 'fas fa-parking',
  'far-parking': 'far fa-parking',
  'fas-passport': 'fas fa-passport',
  'far-passport': 'far fa-passport',
  'fas-pastafarianism': 'fas fa-pastafarianism',
  'far-pastafarianism': 'far fa-pastafarianism',
  'fas-paste': 'fas fa-paste',
  'far-paste': 'far fa-paste',
  'fas-patreon': 'fas fa-patreon',
  'far-patreon': 'far fa-patreon',
  'fas-pause': 'fas fa-pause',
  'far-pause': 'far fa-pause',
  'fas-pause-circle': 'fas fa-pause-circle',
  'far-pause-circle': 'far fa-pause-circle',
  'fas-paw': 'fas fa-paw',
  'far-paw': 'far fa-paw',
  'fas-paypal': 'fas fa-paypal',
  'far-paypal': 'far fa-paypal',
  'fas-peace': 'fas fa-peace',
  'far-peace': 'far fa-peace',
  'fas-pen': 'fas fa-pen',
  'far-pen': 'far fa-pen',
  'fas-pen-alt': 'fas fa-pen-alt',
  'far-pen-alt': 'far fa-pen-alt',
  'fas-pen-fancy': 'fas fa-pen-fancy',
  'far-pen-fancy': 'far fa-pen-fancy',
  'fas-pen-nib': 'fas fa-pen-nib',
  'far-pen-nib': 'far fa-pen-nib',
  'fas-pen-square': 'fas fa-pen-square',
  'far-pen-square': 'far fa-pen-square',
  'fas-pencil-alt': 'fas fa-pencil-alt',
  'far-pencil-alt': 'far fa-pencil-alt',
  'fas-pencil-ruler': 'fas fa-pencil-ruler',
  'far-pencil-ruler': 'far fa-pencil-ruler',
  'fas-penny-arcade': 'fas fa-penny-arcade',
  'far-penny-arcade': 'far fa-penny-arcade',
  'fas-people-arrows': 'fas fa-people-arrows',
  'far-people-arrows': 'far fa-people-arrows',
  'fas-people-carry': 'fas fa-people-carry',
  'far-people-carry': 'far fa-people-carry',
  'fas-pepper-hot': 'fas fa-pepper-hot',
  'far-pepper-hot': 'far fa-pepper-hot',
  'fas-perbyte': 'fas fa-perbyte',
  'far-perbyte': 'far fa-perbyte',
  'fas-percent': 'fas fa-percent',
  'far-percent': 'far fa-percent',
  'fas-percentage': 'fas fa-percentage',
  'far-percentage': 'far fa-percentage',
  'fas-periscope': 'fas fa-periscope',
  'far-periscope': 'far fa-periscope',
  'fas-person-booth': 'fas fa-person-booth',
  'far-person-booth': 'far fa-person-booth',
  'fas-phabricator': 'fas fa-phabricator',
  'far-phabricator': 'far fa-phabricator',
  'fas-phoenix-framework': 'fas fa-phoenix-framework',
  'far-phoenix-framework': 'far fa-phoenix-framework',
  'fas-phoenix-squadron': 'fas fa-phoenix-squadron',
  'far-phoenix-squadron': 'far fa-phoenix-squadron',
  'fas-phone': 'fas fa-phone',
  'far-phone': 'far fa-phone',
  'fas-phone-alt': 'fas fa-phone-alt',
  'far-phone-alt': 'far fa-phone-alt',
  'fas-phone-slash': 'fas fa-phone-slash',
  'far-phone-slash': 'far fa-phone-slash',
  'fas-phone-square': 'fas fa-phone-square',
  'far-phone-square': 'far fa-phone-square',
  'fas-phone-square-alt': 'fas fa-phone-square-alt',
  'far-phone-square-alt': 'far fa-phone-square-alt',
  'fas-phone-volume': 'fas fa-phone-volume',
  'far-phone-volume': 'far fa-phone-volume',
  'fas-photo-video': 'fas fa-photo-video',
  'far-photo-video': 'far fa-photo-video',
  'fas-php': 'fas fa-php',
  'far-php': 'far fa-php',
  'fas-pied-piper': 'fas fa-pied-piper',
  'far-pied-piper': 'far fa-pied-piper',
  'fas-pied-piper-alt': 'fas fa-pied-piper-alt',
  'far-pied-piper-alt': 'far fa-pied-piper-alt',
  'fas-pied-piper-hat': 'fas fa-pied-piper-hat',
  'far-pied-piper-hat': 'far fa-pied-piper-hat',
  'fas-pied-piper-pp': 'fas fa-pied-piper-pp',
  'far-pied-piper-pp': 'far fa-pied-piper-pp',
  'fas-pied-piper-square': 'fas fa-pied-piper-square',
  'far-pied-piper-square': 'far fa-pied-piper-square',
  'fas-piggy-bank': 'fas fa-piggy-bank',
  'far-piggy-bank': 'far fa-piggy-bank',
  'fas-pills': 'fas fa-pills',
  'far-pills': 'far fa-pills',
  'fas-pinterest': 'fas fa-pinterest',
  'far-pinterest': 'far fa-pinterest',
  'fas-pinterest-p': 'fas fa-pinterest-p',
  'far-pinterest-p': 'far fa-pinterest-p',
  'fas-pinterest-square': 'fas fa-pinterest-square',
  'far-pinterest-square': 'far fa-pinterest-square',
  'fas-pizza-slice': 'fas fa-pizza-slice',
  'far-pizza-slice': 'far fa-pizza-slice',
  'fas-place-of-worship': 'fas fa-place-of-worship',
  'far-place-of-worship': 'far fa-place-of-worship',
  'fas-plane': 'fas fa-plane',
  'far-plane': 'far fa-plane',
  'fas-plane-arrival': 'fas fa-plane-arrival',
  'far-plane-arrival': 'far fa-plane-arrival',
  'fas-plane-departure': 'fas fa-plane-departure',
  'far-plane-departure': 'far fa-plane-departure',
  'fas-plane-slash': 'fas fa-plane-slash',
  'far-plane-slash': 'far fa-plane-slash',
  'fas-play': 'fas fa-play',
  'far-play': 'far fa-play',
  'fas-play-circle': 'fas fa-play-circle',
  'far-play-circle': 'far fa-play-circle',
  'fas-playstation': 'fas fa-playstation',
  'far-playstation': 'far fa-playstation',
  'fas-plug': 'fas fa-plug',
  'far-plug': 'far fa-plug',
  'fas-plus': 'fas fa-plus',
  'far-plus': 'far fa-plus',
  'fas-plus-circle': 'fas fa-plus-circle',
  'far-plus-circle': 'far fa-plus-circle',
  'fas-plus-square': 'fas fa-plus-square',
  'far-plus-square': 'far fa-plus-square',
  'fas-podcast': 'fas fa-podcast',
  'far-podcast': 'far fa-podcast',
  'fas-poll': 'fas fa-poll',
  'far-poll': 'far fa-poll',
  'fas-poll-h': 'fas fa-poll-h',
  'far-poll-h': 'far fa-poll-h',
  'fas-poo': 'fas fa-poo',
  'far-poo': 'far fa-poo',
  'fas-poo-storm': 'fas fa-poo-storm',
  'far-poo-storm': 'far fa-poo-storm',
  'fas-poop': 'fas fa-poop',
  'far-poop': 'far fa-poop',
  'fas-portrait': 'fas fa-portrait',
  'far-portrait': 'far fa-portrait',
  'fas-pound-sign': 'fas fa-pound-sign',
  'far-pound-sign': 'far fa-pound-sign',
  'fas-power-off': 'fas fa-power-off',
  'far-power-off': 'far fa-power-off',
  'fas-pray': 'fas fa-pray',
  'far-pray': 'far fa-pray',
  'fas-praying-hands': 'fas fa-praying-hands',
  'far-praying-hands': 'far fa-praying-hands',
  'fas-prescription': 'fas fa-prescription',
  'far-prescription': 'far fa-prescription',
  'fas-prescription-bottle': 'fas fa-prescription-bottle',
  'far-prescription-bottle': 'far fa-prescription-bottle',
  'fas-prescription-bottle-alt': 'fas fa-prescription-bottle-alt',
  'far-prescription-bottle-alt': 'far fa-prescription-bottle-alt',
  'fas-print': 'fas fa-print',
  'far-print': 'far fa-print',
  'fas-procedures': 'fas fa-procedures',
  'far-procedures': 'far fa-procedures',
  'fas-product-hunt': 'fas fa-product-hunt',
  'far-product-hunt': 'far fa-product-hunt',
  'fas-project-diagram': 'fas fa-project-diagram',
  'far-project-diagram': 'far fa-project-diagram',
  'fas-pump-medical': 'fas fa-pump-medical',
  'far-pump-medical': 'far fa-pump-medical',
  'fas-pump-soap': 'fas fa-pump-soap',
  'far-pump-soap': 'far fa-pump-soap',
  'fas-pushed': 'fas fa-pushed',
  'far-pushed': 'far fa-pushed',
  'fas-puzzle-piece': 'fas fa-puzzle-piece',
  'far-puzzle-piece': 'far fa-puzzle-piece',
  'fas-python': 'fas fa-python',
  'far-python': 'far fa-python',
  'fas-qq': 'fas fa-qq',
  'far-qq': 'far fa-qq',
  'fas-qrcode': 'fas fa-qrcode',
  'far-qrcode': 'far fa-qrcode',
  'fas-question': 'fas fa-question',
  'far-question': 'far fa-question',
  'fas-question-circle': 'fas fa-question-circle',
  'far-question-circle': 'far fa-question-circle',
  'fas-quidditch': 'fas fa-quidditch',
  'far-quidditch': 'far fa-quidditch',
  'fas-quinscape': 'fas fa-quinscape',
  'far-quinscape': 'far fa-quinscape',
  'fas-quora': 'fas fa-quora',
  'far-quora': 'far fa-quora',
  'fas-quote-left': 'fas fa-quote-left',
  'far-quote-left': 'far fa-quote-left',
  'fas-quote-right': 'fas fa-quote-right',
  'far-quote-right': 'far fa-quote-right',
  'fas-quran': 'fas fa-quran',
  'far-quran': 'far fa-quran',
  'fas-r-project': 'fas fa-r-project',
  'far-r-project': 'far fa-r-project',
  'fas-radiation': 'fas fa-radiation',
  'far-radiation': 'far fa-radiation',
  'fas-radiation-alt': 'fas fa-radiation-alt',
  'far-radiation-alt': 'far fa-radiation-alt',
  'fas-rainbow': 'fas fa-rainbow',
  'far-rainbow': 'far fa-rainbow',
  'fas-random': 'fas fa-random',
  'far-random': 'far fa-random',
  'fas-raspberry-pi': 'fas fa-raspberry-pi',
  'far-raspberry-pi': 'far fa-raspberry-pi',
  'fas-ravelry': 'fas fa-ravelry',
  'far-ravelry': 'far fa-ravelry',
  'fas-react': 'fas fa-react',
  'far-react': 'far fa-react',
  'fas-reacteurope': 'fas fa-reacteurope',
  'far-reacteurope': 'far fa-reacteurope',
  'fas-readme': 'fas fa-readme',
  'far-readme': 'far fa-readme',
  'fas-rebel': 'fas fa-rebel',
  'far-rebel': 'far fa-rebel',
  'fas-receipt': 'fas fa-receipt',
  'far-receipt': 'far fa-receipt',
  'fas-record-vinyl': 'fas fa-record-vinyl',
  'far-record-vinyl': 'far fa-record-vinyl',
  'fas-recycle': 'fas fa-recycle',
  'far-recycle': 'far fa-recycle',
  'fas-red-river': 'fas fa-red-river',
  'far-red-river': 'far fa-red-river',
  'fas-reddit': 'fas fa-reddit',
  'far-reddit': 'far fa-reddit',
  'fas-reddit-alien': 'fas fa-reddit-alien',
  'far-reddit-alien': 'far fa-reddit-alien',
  'fas-reddit-square': 'fas fa-reddit-square',
  'far-reddit-square': 'far fa-reddit-square',
  'fas-redhat': 'fas fa-redhat',
  'far-redhat': 'far fa-redhat',
  'fas-redo': 'fas fa-redo',
  'far-redo': 'far fa-redo',
  'fas-redo-alt': 'fas fa-redo-alt',
  'far-redo-alt': 'far fa-redo-alt',
  'fas-registered': 'fas fa-registered',
  'far-registered': 'far fa-registered',
  'fas-remove-format': 'fas fa-remove-format',
  'far-remove-format': 'far fa-remove-format',
  'fas-renren': 'fas fa-renren',
  'far-renren': 'far fa-renren',
  'fas-reply': 'fas fa-reply',
  'far-reply': 'far fa-reply',
  'fas-reply-all': 'fas fa-reply-all',
  'far-reply-all': 'far fa-reply-all',
  'fas-replyd': 'fas fa-replyd',
  'far-replyd': 'far fa-replyd',
  'fas-republican': 'fas fa-republican',
  'far-republican': 'far fa-republican',
  'fas-researchgate': 'fas fa-researchgate',
  'far-researchgate': 'far fa-researchgate',
  'fas-resolving': 'fas fa-resolving',
  'far-resolving': 'far fa-resolving',
  'fas-restroom': 'fas fa-restroom',
  'far-restroom': 'far fa-restroom',
  'fas-retweet': 'fas fa-retweet',
  'far-retweet': 'far fa-retweet',
  'fas-rev': 'fas fa-rev',
  'far-rev': 'far fa-rev',
  'fas-ribbon': 'fas fa-ribbon',
  'far-ribbon': 'far fa-ribbon',
  'fas-ring': 'fas fa-ring',
  'far-ring': 'far fa-ring',
  'fas-road': 'fas fa-road',
  'far-road': 'far fa-road',
  'fas-robot': 'fas fa-robot',
  'far-robot': 'far fa-robot',
  'fas-rocket': 'fas fa-rocket',
  'far-rocket': 'far fa-rocket',
  'fas-rocketchat': 'fas fa-rocketchat',
  'far-rocketchat': 'far fa-rocketchat',
  'fas-rockrms': 'fas fa-rockrms',
  'far-rockrms': 'far fa-rockrms',
  'fas-route': 'fas fa-route',
  'far-route': 'far fa-route',
  'fas-rss': 'fas fa-rss',
  'far-rss': 'far fa-rss',
  'fas-rss-square': 'fas fa-rss-square',
  'far-rss-square': 'far fa-rss-square',
  'fas-ruble-sign': 'fas fa-ruble-sign',
  'far-ruble-sign': 'far fa-ruble-sign',
  'fas-ruler': 'fas fa-ruler',
  'far-ruler': 'far fa-ruler',
  'fas-ruler-combined': 'fas fa-ruler-combined',
  'far-ruler-combined': 'far fa-ruler-combined',
  'fas-ruler-horizontal': 'fas fa-ruler-horizontal',
  'far-ruler-horizontal': 'far fa-ruler-horizontal',
  'fas-ruler-vertical': 'fas fa-ruler-vertical',
  'far-ruler-vertical': 'far fa-ruler-vertical',
  'fas-running': 'fas fa-running',
  'far-running': 'far fa-running',
  'fas-rupee-sign': 'fas fa-rupee-sign',
  'far-rupee-sign': 'far fa-rupee-sign',
  'fas-rust': 'fas fa-rust',
  'far-rust': 'far fa-rust',
  'fas-sad-cry': 'fas fa-sad-cry',
  'far-sad-cry': 'far fa-sad-cry',
  'fas-sad-tear': 'fas fa-sad-tear',
  'far-sad-tear': 'far fa-sad-tear',
  'fas-safari': 'fas fa-safari',
  'far-safari': 'far fa-safari',
  'fas-salesforce': 'fas fa-salesforce',
  'far-salesforce': 'far fa-salesforce',
  'fas-sass': 'fas fa-sass',
  'far-sass': 'far fa-sass',
  'fas-satellite': 'fas fa-satellite',
  'far-satellite': 'far fa-satellite',
  'fas-satellite-dish': 'fas fa-satellite-dish',
  'far-satellite-dish': 'far fa-satellite-dish',
  'fas-save': 'fas fa-save',
  'far-save': 'far fa-save',
  'fas-schlix': 'fas fa-schlix',
  'far-schlix': 'far fa-schlix',
  'fas-school': 'fas fa-school',
  'far-school': 'far fa-school',
  'fas-screwdriver': 'fas fa-screwdriver',
  'far-screwdriver': 'far fa-screwdriver',
  'fas-scribd': 'fas fa-scribd',
  'far-scribd': 'far fa-scribd',
  'fas-scroll': 'fas fa-scroll',
  'far-scroll': 'far fa-scroll',
  'fas-sd-card': 'fas fa-sd-card',
  'far-sd-card': 'far fa-sd-card',
  'fas-search': 'fas fa-search',
  'far-search': 'far fa-search',
  'fas-search-dollar': 'fas fa-search-dollar',
  'far-search-dollar': 'far fa-search-dollar',
  'fas-search-location': 'fas fa-search-location',
  'far-search-location': 'far fa-search-location',
  'fas-search-minus': 'fas fa-search-minus',
  'far-search-minus': 'far fa-search-minus',
  'fas-search-plus': 'fas fa-search-plus',
  'far-search-plus': 'far fa-search-plus',
  'fas-searchengin': 'fas fa-searchengin',
  'far-searchengin': 'far fa-searchengin',
  'fas-seedling': 'fas fa-seedling',
  'far-seedling': 'far fa-seedling',
  'fas-sellcast': 'fas fa-sellcast',
  'far-sellcast': 'far fa-sellcast',
  'fas-sellsy': 'fas fa-sellsy',
  'far-sellsy': 'far fa-sellsy',
  'fas-server': 'fas fa-server',
  'far-server': 'far fa-server',
  'fas-servicestack': 'fas fa-servicestack',
  'far-servicestack': 'far fa-servicestack',
  'fas-shapes': 'fas fa-shapes',
  'far-shapes': 'far fa-shapes',
  'fas-share': 'fas fa-share',
  'far-share': 'far fa-share',
  'fas-share-alt': 'fas fa-share-alt',
  'far-share-alt': 'far fa-share-alt',
  'fas-share-alt-square': 'fas fa-share-alt-square',
  'far-share-alt-square': 'far fa-share-alt-square',
  'fas-share-square': 'fas fa-share-square',
  'far-share-square': 'far fa-share-square',
  'fas-shekel-sign': 'fas fa-shekel-sign',
  'far-shekel-sign': 'far fa-shekel-sign',
  'fas-shield-alt': 'fas fa-shield-alt',
  'far-shield-alt': 'far fa-shield-alt',
  'fas-shield-virus': 'fas fa-shield-virus',
  'far-shield-virus': 'far fa-shield-virus',
  'fas-ship': 'fas fa-ship',
  'far-ship': 'far fa-ship',
  'fas-shipping-fast': 'fas fa-shipping-fast',
  'far-shipping-fast': 'far fa-shipping-fast',
  'fas-shirtsinbulk': 'fas fa-shirtsinbulk',
  'far-shirtsinbulk': 'far fa-shirtsinbulk',
  'fas-shoe-prints': 'fas fa-shoe-prints',
  'far-shoe-prints': 'far fa-shoe-prints',
  'fas-shopify': 'fas fa-shopify',
  'far-shopify': 'far fa-shopify',
  'fas-shopping-bag': 'fas fa-shopping-bag',
  'far-shopping-bag': 'far fa-shopping-bag',
  'fas-shopping-basket': 'fas fa-shopping-basket',
  'far-shopping-basket': 'far fa-shopping-basket',
  'fas-shopping-cart': 'fas fa-shopping-cart',
  'far-shopping-cart': 'far fa-shopping-cart',
  'fas-shopware': 'fas fa-shopware',
  'far-shopware': 'far fa-shopware',
  'fas-shower': 'fas fa-shower',
  'far-shower': 'far fa-shower',
  'fas-shuttle-van': 'fas fa-shuttle-van',
  'far-shuttle-van': 'far fa-shuttle-van',
  'fas-sign': 'fas fa-sign',
  'far-sign': 'far fa-sign',
  'fas-sign-in-alt': 'fas fa-sign-in-alt',
  'far-sign-in-alt': 'far fa-sign-in-alt',
  'fas-sign-language': 'fas fa-sign-language',
  'far-sign-language': 'far fa-sign-language',
  'fas-sign-out-alt': 'fas fa-sign-out-alt',
  'far-sign-out-alt': 'far fa-sign-out-alt',
  'fas-signal': 'fas fa-signal',
  'far-signal': 'far fa-signal',
  'fas-signature': 'fas fa-signature',
  'far-signature': 'far fa-signature',
  'fas-sim-card': 'fas fa-sim-card',
  'far-sim-card': 'far fa-sim-card',
  'fas-simplybuilt': 'fas fa-simplybuilt',
  'far-simplybuilt': 'far fa-simplybuilt',
  'fas-sink': 'fas fa-sink',
  'far-sink': 'far fa-sink',
  'fas-sistrix': 'fas fa-sistrix',
  'far-sistrix': 'far fa-sistrix',
  'fas-sitemap': 'fas fa-sitemap',
  'far-sitemap': 'far fa-sitemap',
  'fas-sith': 'fas fa-sith',
  'far-sith': 'far fa-sith',
  'fas-skating': 'fas fa-skating',
  'far-skating': 'far fa-skating',
  'fas-sketch': 'fas fa-sketch',
  'far-sketch': 'far fa-sketch',
  'fas-skiing': 'fas fa-skiing',
  'far-skiing': 'far fa-skiing',
  'fas-skiing-nordic': 'fas fa-skiing-nordic',
  'far-skiing-nordic': 'far fa-skiing-nordic',
  'fas-skull': 'fas fa-skull',
  'far-skull': 'far fa-skull',
  'fas-skull-crossbones': 'fas fa-skull-crossbones',
  'far-skull-crossbones': 'far fa-skull-crossbones',
  'fas-skyatlas': 'fas fa-skyatlas',
  'far-skyatlas': 'far fa-skyatlas',
  'fas-skype': 'fas fa-skype',
  'far-skype': 'far fa-skype',
  'fas-slack': 'fas fa-slack',
  'far-slack': 'far fa-slack',
  'fas-slack-hash': 'fas fa-slack-hash',
  'far-slack-hash': 'far fa-slack-hash',
  'fas-slash': 'fas fa-slash',
  'far-slash': 'far fa-slash',
  'fas-sleigh': 'fas fa-sleigh',
  'far-sleigh': 'far fa-sleigh',
  'fas-sliders-h': 'fas fa-sliders-h',
  'far-sliders-h': 'far fa-sliders-h',
  'fas-slideshare': 'fas fa-slideshare',
  'far-slideshare': 'far fa-slideshare',
  'fas-smile': 'fas fa-smile',
  'far-smile': 'far fa-smile',
  'fas-smile-beam': 'fas fa-smile-beam',
  'far-smile-beam': 'far fa-smile-beam',
  'fas-smile-wink': 'fas fa-smile-wink',
  'far-smile-wink': 'far fa-smile-wink',
  'fas-smog': 'fas fa-smog',
  'far-smog': 'far fa-smog',
  'fas-smoking': 'fas fa-smoking',
  'far-smoking': 'far fa-smoking',
  'fas-smoking-ban': 'fas fa-smoking-ban',
  'far-smoking-ban': 'far fa-smoking-ban',
  'fas-sms': 'fas fa-sms',
  'far-sms': 'far fa-sms',
  'fas-snapchat': 'fas fa-snapchat',
  'far-snapchat': 'far fa-snapchat',
  'fas-snapchat-ghost': 'fas fa-snapchat-ghost',
  'far-snapchat-ghost': 'far fa-snapchat-ghost',
  'fas-snapchat-square': 'fas fa-snapchat-square',
  'far-snapchat-square': 'far fa-snapchat-square',
  'fas-snowboarding': 'fas fa-snowboarding',
  'far-snowboarding': 'far fa-snowboarding',
  'fas-snowflake': 'fas fa-snowflake',
  'far-snowflake': 'far fa-snowflake',
  'fas-snowman': 'fas fa-snowman',
  'far-snowman': 'far fa-snowman',
  'fas-snowplow': 'fas fa-snowplow',
  'far-snowplow': 'far fa-snowplow',
  'fas-soap': 'fas fa-soap',
  'far-soap': 'far fa-soap',
  'fas-socks': 'fas fa-socks',
  'far-socks': 'far fa-socks',
  'fas-solar-panel': 'fas fa-solar-panel',
  'far-solar-panel': 'far fa-solar-panel',
  'fas-sort': 'fas fa-sort',
  'far-sort': 'far fa-sort',
  'fas-sort-alpha-down': 'fas fa-sort-alpha-down',
  'far-sort-alpha-down': 'far fa-sort-alpha-down',
  'fas-sort-alpha-down-alt': 'fas fa-sort-alpha-down-alt',
  'far-sort-alpha-down-alt': 'far fa-sort-alpha-down-alt',
  'fas-sort-alpha-up': 'fas fa-sort-alpha-up',
  'far-sort-alpha-up': 'far fa-sort-alpha-up',
  'fas-sort-alpha-up-alt': 'fas fa-sort-alpha-up-alt',
  'far-sort-alpha-up-alt': 'far fa-sort-alpha-up-alt',
  'fas-sort-amount-down': 'fas fa-sort-amount-down',
  'far-sort-amount-down': 'far fa-sort-amount-down',
  'fas-sort-amount-down-alt': 'fas fa-sort-amount-down-alt',
  'far-sort-amount-down-alt': 'far fa-sort-amount-down-alt',
  'fas-sort-amount-up': 'fas fa-sort-amount-up',
  'far-sort-amount-up': 'far fa-sort-amount-up',
  'fas-sort-amount-up-alt': 'fas fa-sort-amount-up-alt',
  'far-sort-amount-up-alt': 'far fa-sort-amount-up-alt',
  'fas-sort-down': 'fas fa-sort-down',
  'far-sort-down': 'far fa-sort-down',
  'fas-sort-numeric-down': 'fas fa-sort-numeric-down',
  'far-sort-numeric-down': 'far fa-sort-numeric-down',
  'fas-sort-numeric-down-alt': 'fas fa-sort-numeric-down-alt',
  'far-sort-numeric-down-alt': 'far fa-sort-numeric-down-alt',
  'fas-sort-numeric-up': 'fas fa-sort-numeric-up',
  'far-sort-numeric-up': 'far fa-sort-numeric-up',
  'fas-sort-numeric-up-alt': 'fas fa-sort-numeric-up-alt',
  'far-sort-numeric-up-alt': 'far fa-sort-numeric-up-alt',
  'fas-sort-up': 'fas fa-sort-up',
  'far-sort-up': 'far fa-sort-up',
  'fas-soundcloud': 'fas fa-soundcloud',
  'far-soundcloud': 'far fa-soundcloud',
  'fas-sourcetree': 'fas fa-sourcetree',
  'far-sourcetree': 'far fa-sourcetree',
  'fas-spa': 'fas fa-spa',
  'far-spa': 'far fa-spa',
  'fas-space-shuttle': 'fas fa-space-shuttle',
  'far-space-shuttle': 'far fa-space-shuttle',
  'fas-speakap': 'fas fa-speakap',
  'far-speakap': 'far fa-speakap',
  'fas-speaker-deck': 'fas fa-speaker-deck',
  'far-speaker-deck': 'far fa-speaker-deck',
  'fas-spell-check': 'fas fa-spell-check',
  'far-spell-check': 'far fa-spell-check',
  'fas-spider': 'fas fa-spider',
  'far-spider': 'far fa-spider',
  'fas-spinner': 'fas fa-spinner',
  'far-spinner': 'far fa-spinner',
  'fas-splotch': 'fas fa-splotch',
  'far-splotch': 'far fa-splotch',
  'fas-spotify': 'fas fa-spotify',
  'far-spotify': 'far fa-spotify',
  'fas-spray-can': 'fas fa-spray-can',
  'far-spray-can': 'far fa-spray-can',
  'fas-square': 'fas fa-square',
  'far-square': 'far fa-square',
  'fas-square-full': 'fas fa-square-full',
  'far-square-full': 'far fa-square-full',
  'fas-square-root-alt': 'fas fa-square-root-alt',
  'far-square-root-alt': 'far fa-square-root-alt',
  'fas-squarespace': 'fas fa-squarespace',
  'far-squarespace': 'far fa-squarespace',
  'fas-stack-exchange': 'fas fa-stack-exchange',
  'far-stack-exchange': 'far fa-stack-exchange',
  'fas-stack-overflow': 'fas fa-stack-overflow',
  'far-stack-overflow': 'far fa-stack-overflow',
  'fas-stackpath': 'fas fa-stackpath',
  'far-stackpath': 'far fa-stackpath',
  'fas-stamp': 'fas fa-stamp',
  'far-stamp': 'far fa-stamp',
  'fas-star': 'fas fa-star',
  'far-star': 'far fa-star',
  'fas-star-and-crescent': 'fas fa-star-and-crescent',
  'far-star-and-crescent': 'far fa-star-and-crescent',
  'fas-star-half': 'fas fa-star-half',
  'far-star-half': 'far fa-star-half',
  'fas-star-half-alt': 'fas fa-star-half-alt',
  'far-star-half-alt': 'far fa-star-half-alt',
  'fas-star-of-david': 'fas fa-star-of-david',
  'far-star-of-david': 'far fa-star-of-david',
  'fas-star-of-life': 'fas fa-star-of-life',
  'far-star-of-life': 'far fa-star-of-life',
  'fas-staylinked': 'fas fa-staylinked',
  'far-staylinked': 'far fa-staylinked',
  'fas-steam': 'fas fa-steam',
  'far-steam': 'far fa-steam',
  'fas-steam-square': 'fas fa-steam-square',
  'far-steam-square': 'far fa-steam-square',
  'fas-steam-symbol': 'fas fa-steam-symbol',
  'far-steam-symbol': 'far fa-steam-symbol',
  'fas-step-backward': 'fas fa-step-backward',
  'far-step-backward': 'far fa-step-backward',
  'fas-step-forward': 'fas fa-step-forward',
  'far-step-forward': 'far fa-step-forward',
  'fas-stethoscope': 'fas fa-stethoscope',
  'far-stethoscope': 'far fa-stethoscope',
  'fas-sticker-mule': 'fas fa-sticker-mule',
  'far-sticker-mule': 'far fa-sticker-mule',
  'fas-sticky-note': 'fas fa-sticky-note',
  'far-sticky-note': 'far fa-sticky-note',
  'fas-stop': 'fas fa-stop',
  'far-stop': 'far fa-stop',
  'fas-stop-circle': 'fas fa-stop-circle',
  'far-stop-circle': 'far fa-stop-circle',
  'fas-stopwatch': 'fas fa-stopwatch',
  'far-stopwatch': 'far fa-stopwatch',
  'fas-stopwatch-20': 'fas fa-stopwatch-20',
  'far-stopwatch-20': 'far fa-stopwatch-20',
  'fas-store': 'fas fa-store',
  'far-store': 'far fa-store',
  'fas-store-alt': 'fas fa-store-alt',
  'far-store-alt': 'far fa-store-alt',
  'fas-store-alt-slash': 'fas fa-store-alt-slash',
  'far-store-alt-slash': 'far fa-store-alt-slash',
  'fas-store-slash': 'fas fa-store-slash',
  'far-store-slash': 'far fa-store-slash',
  'fas-strava': 'fas fa-strava',
  'far-strava': 'far fa-strava',
  'fas-stream': 'fas fa-stream',
  'far-stream': 'far fa-stream',
  'fas-street-view': 'fas fa-street-view',
  'far-street-view': 'far fa-street-view',
  'fas-strikethrough': 'fas fa-strikethrough',
  'far-strikethrough': 'far fa-strikethrough',
  'fas-stripe': 'fas fa-stripe',
  'far-stripe': 'far fa-stripe',
  'fas-stripe-s': 'fas fa-stripe-s',
  'far-stripe-s': 'far fa-stripe-s',
  'fas-stroopwafel': 'fas fa-stroopwafel',
  'far-stroopwafel': 'far fa-stroopwafel',
  'fas-studiovinari': 'fas fa-studiovinari',
  'far-studiovinari': 'far fa-studiovinari',
  'fas-stumbleupon': 'fas fa-stumbleupon',
  'far-stumbleupon': 'far fa-stumbleupon',
  'fas-stumbleupon-circle': 'fas fa-stumbleupon-circle',
  'far-stumbleupon-circle': 'far fa-stumbleupon-circle',
  'fas-subscript': 'fas fa-subscript',
  'far-subscript': 'far fa-subscript',
  'fas-subway': 'fas fa-subway',
  'far-subway': 'far fa-subway',
  'fas-suitcase': 'fas fa-suitcase',
  'far-suitcase': 'far fa-suitcase',
  'fas-suitcase-rolling': 'fas fa-suitcase-rolling',
  'far-suitcase-rolling': 'far fa-suitcase-rolling',
  'fas-sun': 'fas fa-sun',
  'far-sun': 'far fa-sun',
  'fas-superpowers': 'fas fa-superpowers',
  'far-superpowers': 'far fa-superpowers',
  'fas-superscript': 'fas fa-superscript',
  'far-superscript': 'far fa-superscript',
  'fas-supple': 'fas fa-supple',
  'far-supple': 'far fa-supple',
  'fas-surprise': 'fas fa-surprise',
  'far-surprise': 'far fa-surprise',
  'fas-suse': 'fas fa-suse',
  'far-suse': 'far fa-suse',
  'fas-swatchbook': 'fas fa-swatchbook',
  'far-swatchbook': 'far fa-swatchbook',
  'fas-swift': 'fas fa-swift',
  'far-swift': 'far fa-swift',
  'fas-swimmer': 'fas fa-swimmer',
  'far-swimmer': 'far fa-swimmer',
  'fas-swimming-pool': 'fas fa-swimming-pool',
  'far-swimming-pool': 'far fa-swimming-pool',
  'fas-symfony': 'fas fa-symfony',
  'far-symfony': 'far fa-symfony',
  'fas-synagogue': 'fas fa-synagogue',
  'far-synagogue': 'far fa-synagogue',
  'fas-sync': 'fas fa-sync',
  'far-sync': 'far fa-sync',
  'fas-sync-alt': 'fas fa-sync-alt',
  'far-sync-alt': 'far fa-sync-alt',
  'fas-syringe': 'fas fa-syringe',
  'far-syringe': 'far fa-syringe',
  'fas-table': 'fas fa-table',
  'far-table': 'far fa-table',
  'fas-table-tennis': 'fas fa-table-tennis',
  'far-table-tennis': 'far fa-table-tennis',
  'fas-tablet': 'fas fa-tablet',
  'far-tablet': 'far fa-tablet',
  'fas-tablet-alt': 'fas fa-tablet-alt',
  'far-tablet-alt': 'far fa-tablet-alt',
  'fas-tablets': 'fas fa-tablets',
  'far-tablets': 'far fa-tablets',
  'fas-tachometer-alt': 'fas fa-tachometer-alt',
  'far-tachometer-alt': 'far fa-tachometer-alt',
  'fas-tag': 'fas fa-tag',
  'far-tag': 'far fa-tag',
  'fas-tags': 'fas fa-tags',
  'far-tags': 'far fa-tags',
  'fas-tape': 'fas fa-tape',
  'far-tape': 'far fa-tape',
  'fas-tasks': 'fas fa-tasks',
  'far-tasks': 'far fa-tasks',
  'fas-taxi': 'fas fa-taxi',
  'far-taxi': 'far fa-taxi',
  'fas-teamspeak': 'fas fa-teamspeak',
  'far-teamspeak': 'far fa-teamspeak',
  'fas-teeth': 'fas fa-teeth',
  'far-teeth': 'far fa-teeth',
  'fas-teeth-open': 'fas fa-teeth-open',
  'far-teeth-open': 'far fa-teeth-open',
  'fas-telegram': 'fas fa-telegram',
  'far-telegram': 'far fa-telegram',
  'fas-telegram-plane': 'fas fa-telegram-plane',
  'far-telegram-plane': 'far fa-telegram-plane',
  'fas-temperature-high': 'fas fa-temperature-high',
  'far-temperature-high': 'far fa-temperature-high',
  'fas-temperature-low': 'fas fa-temperature-low',
  'far-temperature-low': 'far fa-temperature-low',
  'fas-tencent-weibo': 'fas fa-tencent-weibo',
  'far-tencent-weibo': 'far fa-tencent-weibo',
  'fas-tenge': 'fas fa-tenge',
  'far-tenge': 'far fa-tenge',
  'fas-terminal': 'fas fa-terminal',
  'far-terminal': 'far fa-terminal',
  'fas-text-height': 'fas fa-text-height',
  'far-text-height': 'far fa-text-height',
  'fas-text-width': 'fas fa-text-width',
  'far-text-width': 'far fa-text-width',
  'fas-th': 'fas fa-th',
  'far-th': 'far fa-th',
  'fas-th-large': 'fas fa-th-large',
  'far-th-large': 'far fa-th-large',
  'fas-th-list': 'fas fa-th-list',
  'far-th-list': 'far fa-th-list',
  'fas-the-red-yeti': 'fas fa-the-red-yeti',
  'far-the-red-yeti': 'far fa-the-red-yeti',
  'fas-theater-masks': 'fas fa-theater-masks',
  'far-theater-masks': 'far fa-theater-masks',
  'fas-themeco': 'fas fa-themeco',
  'far-themeco': 'far fa-themeco',
  'fas-themeisle': 'fas fa-themeisle',
  'far-themeisle': 'far fa-themeisle',
  'fas-thermometer': 'fas fa-thermometer',
  'far-thermometer': 'far fa-thermometer',
  'fas-thermometer-empty': 'fas fa-thermometer-empty',
  'far-thermometer-empty': 'far fa-thermometer-empty',
  'fas-thermometer-full': 'fas fa-thermometer-full',
  'far-thermometer-full': 'far fa-thermometer-full',
  'fas-thermometer-half': 'fas fa-thermometer-half',
  'far-thermometer-half': 'far fa-thermometer-half',
  'fas-thermometer-quarter': 'fas fa-thermometer-quarter',
  'far-thermometer-quarter': 'far fa-thermometer-quarter',
  'fas-thermometer-three-quarters': 'fas fa-thermometer-three-quarters',
  'far-thermometer-three-quarters': 'far fa-thermometer-three-quarters',
  'fas-think-peaks': 'fas fa-think-peaks',
  'far-think-peaks': 'far fa-think-peaks',
  'fas-thumbs-down': 'fas fa-thumbs-down',
  'far-thumbs-down': 'far fa-thumbs-down',
  'fas-thumbs-up': 'fas fa-thumbs-up',
  'far-thumbs-up': 'far fa-thumbs-up',
  'fas-thumbtack': 'fas fa-thumbtack',
  'far-thumbtack': 'far fa-thumbtack',
  'fas-ticket-alt': 'fas fa-ticket-alt',
  'far-ticket-alt': 'far fa-ticket-alt',
  'fas-tiktok': 'fas fa-tiktok',
  'far-tiktok': 'far fa-tiktok',
  'fas-times': 'fas fa-times',
  'far-times': 'far fa-times',
  'fas-times-circle': 'fas fa-times-circle',
  'far-times-circle': 'far fa-times-circle',
  'fas-tint': 'fas fa-tint',
  'far-tint': 'far fa-tint',
  'fas-tint-slash': 'fas fa-tint-slash',
  'far-tint-slash': 'far fa-tint-slash',
  'fas-tired': 'fas fa-tired',
  'far-tired': 'far fa-tired',
  'fas-toggle-off': 'fas fa-toggle-off',
  'far-toggle-off': 'far fa-toggle-off',
  'fas-toggle-on': 'fas fa-toggle-on',
  'far-toggle-on': 'far fa-toggle-on',
  'fas-toilet': 'fas fa-toilet',
  'far-toilet': 'far fa-toilet',
  'fas-toilet-paper': 'fas fa-toilet-paper',
  'far-toilet-paper': 'far fa-toilet-paper',
  'fas-toilet-paper-slash': 'fas fa-toilet-paper-slash',
  'far-toilet-paper-slash': 'far fa-toilet-paper-slash',
  'fas-toolbox': 'fas fa-toolbox',
  'far-toolbox': 'far fa-toolbox',
  'fas-tools': 'fas fa-tools',
  'far-tools': 'far fa-tools',
  'fas-tooth': 'fas fa-tooth',
  'far-tooth': 'far fa-tooth',
  'fas-torah': 'fas fa-torah',
  'far-torah': 'far fa-torah',
  'fas-torii-gate': 'fas fa-torii-gate',
  'far-torii-gate': 'far fa-torii-gate',
  'fas-tractor': 'fas fa-tractor',
  'far-tractor': 'far fa-tractor',
  'fas-trade-federation': 'fas fa-trade-federation',
  'far-trade-federation': 'far fa-trade-federation',
  'fas-trademark': 'fas fa-trademark',
  'far-trademark': 'far fa-trademark',
  'fas-traffic-light': 'fas fa-traffic-light',
  'far-traffic-light': 'far fa-traffic-light',
  'fas-trailer': 'fas fa-trailer',
  'far-trailer': 'far fa-trailer',
  'fas-train': 'fas fa-train',
  'far-train': 'far fa-train',
  'fas-tram': 'fas fa-tram',
  'far-tram': 'far fa-tram',
  'fas-transgender': 'fas fa-transgender',
  'far-transgender': 'far fa-transgender',
  'fas-transgender-alt': 'fas fa-transgender-alt',
  'far-transgender-alt': 'far fa-transgender-alt',
  'fas-trash': 'fas fa-trash',
  'far-trash': 'far fa-trash',
  'fas-trash-alt': 'fas fa-trash-alt',
  'far-trash-alt': 'far fa-trash-alt',
  'fas-trash-restore': 'fas fa-trash-restore',
  'far-trash-restore': 'far fa-trash-restore',
  'fas-trash-restore-alt': 'fas fa-trash-restore-alt',
  'far-trash-restore-alt': 'far fa-trash-restore-alt',
  'fas-tree': 'fas fa-tree',
  'far-tree': 'far fa-tree',
  'fas-trello': 'fas fa-trello',
  'far-trello': 'far fa-trello',
  'fas-tripadvisor': 'fas fa-tripadvisor',
  'far-tripadvisor': 'far fa-tripadvisor',
  'fas-trophy': 'fas fa-trophy',
  'far-trophy': 'far fa-trophy',
  'fas-truck': 'fas fa-truck',
  'far-truck': 'far fa-truck',
  'fas-truck-loading': 'fas fa-truck-loading',
  'far-truck-loading': 'far fa-truck-loading',
  'fas-truck-monster': 'fas fa-truck-monster',
  'far-truck-monster': 'far fa-truck-monster',
  'fas-truck-moving': 'fas fa-truck-moving',
  'far-truck-moving': 'far fa-truck-moving',
  'fas-truck-pickup': 'fas fa-truck-pickup',
  'far-truck-pickup': 'far fa-truck-pickup',
  'fas-tshirt': 'fas fa-tshirt',
  'far-tshirt': 'far fa-tshirt',
  'fas-tty': 'fas fa-tty',
  'far-tty': 'far fa-tty',
  'fas-tumblr': 'fas fa-tumblr',
  'far-tumblr': 'far fa-tumblr',
  'fas-tumblr-square': 'fas fa-tumblr-square',
  'far-tumblr-square': 'far fa-tumblr-square',
  'fas-tv': 'fas fa-tv',
  'far-tv': 'far fa-tv',
  'fas-twitch': 'fas fa-twitch',
  'far-twitch': 'far fa-twitch',
  'fas-twitter': 'fas fa-twitter',
  'far-twitter': 'far fa-twitter',
  'fas-twitter-square': 'fas fa-twitter-square',
  'far-twitter-square': 'far fa-twitter-square',
  'fas-typo3': 'fas fa-typo3',
  'far-typo3': 'far fa-typo3',
  'fas-uber': 'fas fa-uber',
  'far-uber': 'far fa-uber',
  'fas-ubuntu': 'fas fa-ubuntu',
  'far-ubuntu': 'far fa-ubuntu',
  'fas-uikit': 'fas fa-uikit',
  'far-uikit': 'far fa-uikit',
  'fas-umbraco': 'fas fa-umbraco',
  'far-umbraco': 'far fa-umbraco',
  'fas-umbrella': 'fas fa-umbrella',
  'far-umbrella': 'far fa-umbrella',
  'fas-umbrella-beach': 'fas fa-umbrella-beach',
  'far-umbrella-beach': 'far fa-umbrella-beach',
  'fas-uncharted': 'fas fa-uncharted',
  'far-uncharted': 'far fa-uncharted',
  'fas-underline': 'fas fa-underline',
  'far-underline': 'far fa-underline',
  'fas-undo': 'fas fa-undo',
  'far-undo': 'far fa-undo',
  'fas-undo-alt': 'fas fa-undo-alt',
  'far-undo-alt': 'far fa-undo-alt',
  'fas-uniregistry': 'fas fa-uniregistry',
  'far-uniregistry': 'far fa-uniregistry',
  'fas-unity': 'fas fa-unity',
  'far-unity': 'far fa-unity',
  'fas-universal-access': 'fas fa-universal-access',
  'far-universal-access': 'far fa-universal-access',
  'fas-university': 'fas fa-university',
  'far-university': 'far fa-university',
  'fas-unlink': 'fas fa-unlink',
  'far-unlink': 'far fa-unlink',
  'fas-unlock': 'fas fa-unlock',
  'far-unlock': 'far fa-unlock',
  'fas-unlock-alt': 'fas fa-unlock-alt',
  'far-unlock-alt': 'far fa-unlock-alt',
  'fas-unsplash': 'fas fa-unsplash',
  'far-unsplash': 'far fa-unsplash',
  'fas-untappd': 'fas fa-untappd',
  'far-untappd': 'far fa-untappd',
  'fas-upload': 'fas fa-upload',
  'far-upload': 'far fa-upload',
  'fas-ups': 'fas fa-ups',
  'far-ups': 'far fa-ups',
  'fas-usb': 'fas fa-usb',
  'far-usb': 'far fa-usb',
  'fas-user': 'fas fa-user',
  'far-user': 'far fa-user',
  'fas-user-alt': 'fas fa-user-alt',
  'far-user-alt': 'far fa-user-alt',
  'fas-user-alt-slash': 'fas fa-user-alt-slash',
  'far-user-alt-slash': 'far fa-user-alt-slash',
  'fas-user-astronaut': 'fas fa-user-astronaut',
  'far-user-astronaut': 'far fa-user-astronaut',
  'fas-user-check': 'fas fa-user-check',
  'far-user-check': 'far fa-user-check',
  'fas-user-circle': 'fas fa-user-circle',
  'far-user-circle': 'far fa-user-circle',
  'fas-user-clock': 'fas fa-user-clock',
  'far-user-clock': 'far fa-user-clock',
  'fas-user-cog': 'fas fa-user-cog',
  'far-user-cog': 'far fa-user-cog',
  'fas-user-edit': 'fas fa-user-edit',
  'far-user-edit': 'far fa-user-edit',
  'fas-user-friends': 'fas fa-user-friends',
  'far-user-friends': 'far fa-user-friends',
  'fas-user-graduate': 'fas fa-user-graduate',
  'far-user-graduate': 'far fa-user-graduate',
  'fas-user-injured': 'fas fa-user-injured',
  'far-user-injured': 'far fa-user-injured',
  'fas-user-lock': 'fas fa-user-lock',
  'far-user-lock': 'far fa-user-lock',
  'fas-user-md': 'fas fa-user-md',
  'far-user-md': 'far fa-user-md',
  'fas-user-minus': 'fas fa-user-minus',
  'far-user-minus': 'far fa-user-minus',
  'fas-user-ninja': 'fas fa-user-ninja',
  'far-user-ninja': 'far fa-user-ninja',
  'fas-user-nurse': 'fas fa-user-nurse',
  'far-user-nurse': 'far fa-user-nurse',
  'fas-user-plus': 'fas fa-user-plus',
  'far-user-plus': 'far fa-user-plus',
  'fas-user-secret': 'fas fa-user-secret',
  'far-user-secret': 'far fa-user-secret',
  'fas-user-shield': 'fas fa-user-shield',
  'far-user-shield': 'far fa-user-shield',
  'fas-user-slash': 'fas fa-user-slash',
  'far-user-slash': 'far fa-user-slash',
  'fas-user-tag': 'fas fa-user-tag',
  'far-user-tag': 'far fa-user-tag',
  'fas-user-tie': 'fas fa-user-tie',
  'far-user-tie': 'far fa-user-tie',
  'fas-user-times': 'fas fa-user-times',
  'far-user-times': 'far fa-user-times',
  'fas-users': 'fas fa-users',
  'far-users': 'far fa-users',
  'fas-users-cog': 'fas fa-users-cog',
  'far-users-cog': 'far fa-users-cog',
  'fas-users-slash': 'fas fa-users-slash',
  'far-users-slash': 'far fa-users-slash',
  'fas-usps': 'fas fa-usps',
  'far-usps': 'far fa-usps',
  'fas-ussunnah': 'fas fa-ussunnah',
  'far-ussunnah': 'far fa-ussunnah',
  'fas-utensil-spoon': 'fas fa-utensil-spoon',
  'far-utensil-spoon': 'far fa-utensil-spoon',
  'fas-utensils': 'fas fa-utensils',
  'far-utensils': 'far fa-utensils',
  'fas-vaadin': 'fas fa-vaadin',
  'far-vaadin': 'far fa-vaadin',
  'fas-vector-square': 'fas fa-vector-square',
  'far-vector-square': 'far fa-vector-square',
  'fas-venus': 'fas fa-venus',
  'far-venus': 'far fa-venus',
  'fas-venus-double': 'fas fa-venus-double',
  'far-venus-double': 'far fa-venus-double',
  'fas-venus-mars': 'fas fa-venus-mars',
  'far-venus-mars': 'far fa-venus-mars',
  'fas-vest': 'fas fa-vest',
  'far-vest': 'far fa-vest',
  'fas-vest-patches': 'fas fa-vest-patches',
  'far-vest-patches': 'far fa-vest-patches',
  'fas-viacoin': 'fas fa-viacoin',
  'far-viacoin': 'far fa-viacoin',
  'fas-viadeo': 'fas fa-viadeo',
  'far-viadeo': 'far fa-viadeo',
  'fas-viadeo-square': 'fas fa-viadeo-square',
  'far-viadeo-square': 'far fa-viadeo-square',
  'fas-vial': 'fas fa-vial',
  'far-vial': 'far fa-vial',
  'fas-vials': 'fas fa-vials',
  'far-vials': 'far fa-vials',
  'fas-viber': 'fas fa-viber',
  'far-viber': 'far fa-viber',
  'fas-video': 'fas fa-video',
  'far-video': 'far fa-video',
  'fas-video-slash': 'fas fa-video-slash',
  'far-video-slash': 'far fa-video-slash',
  'fas-vihara': 'fas fa-vihara',
  'far-vihara': 'far fa-vihara',
  'fas-vimeo': 'fas fa-vimeo',
  'far-vimeo': 'far fa-vimeo',
  'fas-vimeo-square': 'fas fa-vimeo-square',
  'far-vimeo-square': 'far fa-vimeo-square',
  'fas-vimeo-v': 'fas fa-vimeo-v',
  'far-vimeo-v': 'far fa-vimeo-v',
  'fas-vine': 'fas fa-vine',
  'far-vine': 'far fa-vine',
  'fas-virus': 'fas fa-virus',
  'far-virus': 'far fa-virus',
  'fas-virus-slash': 'fas fa-virus-slash',
  'far-virus-slash': 'far fa-virus-slash',
  'fas-viruses': 'fas fa-viruses',
  'far-viruses': 'far fa-viruses',
  'fas-vk': 'fas fa-vk',
  'far-vk': 'far fa-vk',
  'fas-vnv': 'fas fa-vnv',
  'far-vnv': 'far fa-vnv',
  'fas-voicemail': 'fas fa-voicemail',
  'far-voicemail': 'far fa-voicemail',
  'fas-volleyball-ball': 'fas fa-volleyball-ball',
  'far-volleyball-ball': 'far fa-volleyball-ball',
  'fas-volume-down': 'fas fa-volume-down',
  'far-volume-down': 'far fa-volume-down',
  'fas-volume-mute': 'fas fa-volume-mute',
  'far-volume-mute': 'far fa-volume-mute',
  'fas-volume-off': 'fas fa-volume-off',
  'far-volume-off': 'far fa-volume-off',
  'fas-volume-up': 'fas fa-volume-up',
  'far-volume-up': 'far fa-volume-up',
  'fas-vote-yea': 'fas fa-vote-yea',
  'far-vote-yea': 'far fa-vote-yea',
  'fas-vr-cardboard': 'fas fa-vr-cardboard',
  'far-vr-cardboard': 'far fa-vr-cardboard',
  'fas-vuejs': 'fas fa-vuejs',
  'far-vuejs': 'far fa-vuejs',
  'fas-walking': 'fas fa-walking',
  'far-walking': 'far fa-walking',
  'fas-wallet': 'fas fa-wallet',
  'far-wallet': 'far fa-wallet',
  'fas-warehouse': 'fas fa-warehouse',
  'far-warehouse': 'far fa-warehouse',
  'fas-watchman-monitoring': 'fas fa-watchman-monitoring',
  'far-watchman-monitoring': 'far fa-watchman-monitoring',
  'fas-water': 'fas fa-water',
  'far-water': 'far fa-water',
  'fas-wave-square': 'fas fa-wave-square',
  'far-wave-square': 'far fa-wave-square',
  'fas-waze': 'fas fa-waze',
  'far-waze': 'far fa-waze',
  'fas-weebly': 'fas fa-weebly',
  'far-weebly': 'far fa-weebly',
  'fas-weibo': 'fas fa-weibo',
  'far-weibo': 'far fa-weibo',
  'fas-weight': 'fas fa-weight',
  'far-weight': 'far fa-weight',
  'fas-weight-hanging': 'fas fa-weight-hanging',
  'far-weight-hanging': 'far fa-weight-hanging',
  'fas-weixin': 'fas fa-weixin',
  'far-weixin': 'far fa-weixin',
  'fas-whatsapp': 'fas fa-whatsapp',
  'far-whatsapp': 'far fa-whatsapp',
  'fas-whatsapp-square': 'fas fa-whatsapp-square',
  'far-whatsapp-square': 'far fa-whatsapp-square',
  'fas-wheelchair': 'fas fa-wheelchair',
  'far-wheelchair': 'far fa-wheelchair',
  'fas-whmcs': 'fas fa-whmcs',
  'far-whmcs': 'far fa-whmcs',
  'fas-wifi': 'fas fa-wifi',
  'far-wifi': 'far fa-wifi',
  'fas-wikipedia-w': 'fas fa-wikipedia-w',
  'far-wikipedia-w': 'far fa-wikipedia-w',
  'fas-wind': 'fas fa-wind',
  'far-wind': 'far fa-wind',
  'fas-window-close': 'fas fa-window-close',
  'far-window-close': 'far fa-window-close',
  'fas-window-maximize': 'fas fa-window-maximize',
  'far-window-maximize': 'far fa-window-maximize',
  'fas-window-minimize': 'fas fa-window-minimize',
  'far-window-minimize': 'far fa-window-minimize',
  'fas-window-restore': 'fas fa-window-restore',
  'far-window-restore': 'far fa-window-restore',
  'fas-windows': 'fas fa-windows',
  'far-windows': 'far fa-windows',
  'fas-wine-bottle': 'fas fa-wine-bottle',
  'far-wine-bottle': 'far fa-wine-bottle',
  'fas-wine-glass': 'fas fa-wine-glass',
  'far-wine-glass': 'far fa-wine-glass',
  'fas-wine-glass-alt': 'fas fa-wine-glass-alt',
  'far-wine-glass-alt': 'far fa-wine-glass-alt',
  'fas-wix': 'fas fa-wix',
  'far-wix': 'far fa-wix',
  'fas-wizards-of-the-coast': 'fas fa-wizards-of-the-coast',
  'far-wizards-of-the-coast': 'far fa-wizards-of-the-coast',
  'fas-wodu': 'fas fa-wodu',
  'far-wodu': 'far fa-wodu',
  'fas-wolf-pack-battalion': 'fas fa-wolf-pack-battalion',
  'far-wolf-pack-battalion': 'far fa-wolf-pack-battalion',
  'fas-won-sign': 'fas fa-won-sign',
  'far-won-sign': 'far fa-won-sign',
  'fas-wordpress': 'fas fa-wordpress',
  'far-wordpress': 'far fa-wordpress',
  'fas-wordpress-simple': 'fas fa-wordpress-simple',
  'far-wordpress-simple': 'far fa-wordpress-simple',
  'fas-wpbeginner': 'fas fa-wpbeginner',
  'far-wpbeginner': 'far fa-wpbeginner',
  'fas-wpexplorer': 'fas fa-wpexplorer',
  'far-wpexplorer': 'far fa-wpexplorer',
  'fas-wpforms': 'fas fa-wpforms',
  'far-wpforms': 'far fa-wpforms',
  'fas-wpressr': 'fas fa-wpressr',
  'far-wpressr': 'far fa-wpressr',
  'fas-wrench': 'fas fa-wrench',
  'far-wrench': 'far fa-wrench',
  'fas-x-ray': 'fas fa-x-ray',
  'far-x-ray': 'far fa-x-ray',
  'fas-xbox': 'fas fa-xbox',
  'far-xbox': 'far fa-xbox',
  'fas-xing': 'fas fa-xing',
  'far-xing': 'far fa-xing',
  'fas-xing-square': 'fas fa-xing-square',
  'far-xing-square': 'far fa-xing-square',
  'fas-y-combinator': 'fas fa-y-combinator',
  'far-y-combinator': 'far fa-y-combinator',
  'fas-yahoo': 'fas fa-yahoo',
  'far-yahoo': 'far fa-yahoo',
  'fas-yammer': 'fas fa-yammer',
  'far-yammer': 'far fa-yammer',
  'fas-yandex': 'fas fa-yandex',
  'far-yandex': 'far fa-yandex',
  'fas-yandex-international': 'fas fa-yandex-international',
  'far-yandex-international': 'far fa-yandex-international',
  'fas-yarn': 'fas fa-yarn',
  'far-yarn': 'far fa-yarn',
  'fas-yelp': 'fas fa-yelp',
  'far-yelp': 'far fa-yelp',
  'fas-yen-sign': 'fas fa-yen-sign',
  'far-yen-sign': 'far fa-yen-sign',
  'fas-yin-yang': 'fas fa-yin-yang',
  'far-yin-yang': 'far fa-yin-yang',
  'fas-yoast': 'fas fa-yoast',
  'far-yoast': 'far fa-yoast',
  'fas-youtube': 'fas fa-youtube',
  'far-youtube': 'far fa-youtube',
  'fas-youtube-square': 'fas fa-youtube-square',
  'far-youtube-square': 'far fa-youtube-square',
  'fas-zhihu': 'fas fa-zhihu',
  'far-zhihu': 'far fa-zhihu',
}
