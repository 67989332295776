var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    calculatedFields: {
        data: [],
        loading: false,
    },
    blocks: {
        data: [],
        loading: false,
    },
    search: '',
    error: '',
};
export const fetchCalculatedFields = createAsyncThunk('calculatedFields/fetch', (_a) => __awaiter(void 0, [_a], void 0, function* ({ projectId, params }) {
    try {
        const response = yield api.calculatedFields.fetchAll(Object.assign({ project: projectId }, params.queries));
        return response;
    }
    catch (error) {
        return error;
    }
}));
export const fetchCalculatedFieldsBlocks = createAsyncThunk('calculatedFields/blocks/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield api.calculatedFields.blocks();
        return response;
    }
    catch (error) {
        return error;
    }
}), {
    condition: (_, { getState }) => {
        const { blocks: { data }, } = getState().calculatedFields;
        return !data.length;
    },
});
export const deleteCalculatedField = createAsyncThunk('calculatedFields/delete', (calculatedFieldId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield api.calculatedFields.delete(calculatedFieldId);
        return calculatedFieldId;
    }
    catch (error) {
        return error;
    }
}));
export const createCalculatedField = createAsyncThunk('calculatedFields/create', (data) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield api.calculatedFields.create(data);
        return response;
    }
    catch (error) {
        return error;
    }
}));
export const updateCalculatedField = createAsyncThunk('calculatedFields/update', (data) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield api.calculatedFields.update(data);
        return response;
    }
    catch (error) {
        return error;
    }
}));
const { actions, reducer } = createSlice({
    name: 'calculatedFields',
    initialState,
    reducers: {
        setSearch: (state, { payload }) => {
            state.search = payload;
        },
    },
    extraReducers: (builder) => {
        // get calculated fields
        builder.addCase(fetchCalculatedFields.pending, (state) => {
            state.calculatedFields.loading = true;
        });
        builder.addCase(fetchCalculatedFields.fulfilled, (state, { payload }) => {
            state.calculatedFields.data = payload.results;
            state.calculatedFields.loading = false;
        });
        builder.addCase(fetchCalculatedFields.rejected, (state, { payload }) => {
            state.error = payload;
        });
        // get calculated fields blocks
        builder.addCase(fetchCalculatedFieldsBlocks.pending, (state) => {
            state.blocks.loading = true;
        });
        builder.addCase(fetchCalculatedFieldsBlocks.fulfilled, (state, { payload }) => {
            state.blocks.data = payload.results;
            state.blocks.loading = false;
        });
        builder.addCase(fetchCalculatedFieldsBlocks.rejected, (state, { payload }) => {
            state.error = payload;
            state.blocks.loading = false;
        });
        // delete calculated field
        builder.addCase(deleteCalculatedField.fulfilled, (state, { payload }) => {
            const newCalculatedFields = state.calculatedFields.data.filter((cf) => cf.id !== payload);
            state.calculatedFields.loading = false;
            state.calculatedFields.data = newCalculatedFields;
        });
        builder.addCase(deleteCalculatedField.rejected, (state, { payload }) => {
            state.error = payload;
        });
        // create calculated field
        builder.addCase(createCalculatedField.pending, (state, { payload }) => {
            state.calculatedFields.loading = true;
        });
        builder.addCase(createCalculatedField.fulfilled, (state, { payload }) => {
            state.calculatedFields.data = [...state.calculatedFields.data, payload];
            state.calculatedFields.loading = false;
        });
        builder.addCase(createCalculatedField.rejected, (state, { payload }) => {
            state.error = payload;
        });
        // update calculated field
        builder.addCase(updateCalculatedField.pending, (state, { payload }) => {
            state.calculatedFields.loading = true;
        });
        builder.addCase(updateCalculatedField.fulfilled, (state, { payload }) => {
            const newCalculatedFields = [...state.calculatedFields.data];
            const updatedCfIndex = newCalculatedFields.findIndex((el) => el.id === payload.id);
            newCalculatedFields[updatedCfIndex] = payload;
            state.calculatedFields.data = newCalculatedFields;
            state.calculatedFields.loading = false;
        });
        builder.addCase(updateCalculatedField.rejected, (state, { payload }) => {
            state.error = payload;
            state.calculatedFields.loading = false;
        });
    },
});
export const { setSearch } = actions;
export default reducer;
