/** @flow */
import React from 'react'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import { getOptionsValue } from 'app/core/utils/optionsPriority'

import type { Column } from '../../types'
import classes from './groupingFns.module.scss'

export const groupingFns: $ElementType<Column, 'groupingFns'> = {
  priority: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const option = getOptionsValue(row.values[columnId])

        const resKey = String(option?.value)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Priority',
    headerRow: (cell) => {
      if (!cell.value) return null

      const option = getOptionsValue(cell.value)

      return option ? (
        <div
          className={classes.priority}
          style={{
            backgroundColor: option.backgroundColor,
            color: getColorFromBackground(option.backgroundColor),
          }}
        >
          {option.label}
        </div>
      ) : null
    },
  },
}
