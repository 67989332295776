// @flow
import type { StoreResourceDef } from 'app/core/types'

export const contracts: StoreResourceDef<> = {
  resourceType: 'contracts',
  relations: {
    personInst: {
      type: 'hasOne',
      resourceType: 'people',
      foreignKey: 'person',
    },
    secureDataInst: {
      type: 'hasOne',
      resourceType: 'contractSecureDatas',
      foreignKey: 'secureData',
    },
    projectPlanInst: {
      type: 'hasOne',
      resourceType: 'projectPlans',
      foreignKey: 'projectPlan',
    },
  },
}
