var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo } from 'react';
import Loader from 'app/components/Loader/Loader.jsx';
import { permission } from 'app/containers/Permissions/index.js';
import { ModalEditExternalJob } from './ModalEditExternalJob';
import { EditList } from '../EditList';
import { useDispatch, useSelector } from 'react-redux';
import { deleteExternalJob, fetchExternalJobs } from 'app/store/reducers/externalJobs';
import { externalJobsReducer } from 'app/store/selectors/externalJobs';
export function ExternalJobsEditor() {
    const dispatch = useDispatch();
    const { loading, data: externalJobs } = useSelector(externalJobsReducer);
    useEffect(() => {
        dispatch(fetchExternalJobs());
    }, []);
    const onRemove = (id) => __awaiter(this, void 0, void 0, function* () {
        yield dispatch(deleteExternalJob(id));
    });
    const canEdit = useMemo(() => permission(['human resources_settings_posts_edit']), []);
    return loading ? (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter", children: _jsx(Loader, {}) })) : (_jsx("div", { className: "padding10", style: { width: 'fit-content', maxHeight: '100%', height: '100%' }, children: _jsx(EditList, { title: "External Jobs", unity: "External job", titles: ['External Job title'], columnsSizes: [1000, 200], readOnly: !canEdit, labelSelectors: ['name'], itemsList: externalJobs || {}, 
            // Temp parameter to delete when redux toolkit migration done
            useRedux: true, onRemove: (job) => onRemove(job === null || job === void 0 ? void 0 : job.id), EditModal: (item) => (_jsx(ModalEditExternalJob, { job: item, onChange: (job) => {
                    const newJobs = externalJobs ? Object.assign({}, externalJobs) : {};
                    if (job === null || job === void 0 ? void 0 : job.id)
                        newJobs[job.id] = job;
                } })) }) }));
}
