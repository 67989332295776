// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-MyHours-___ModalAddAbsenceActivity-module__leavesReminder {
  margin-bottom: 20px;
}

  .app-pages-MyHours-___ModalAddAbsenceActivity-module__leavesReminder h4 {
    display: inline-block;
    margin-bottom: 5px;
    background-color: rgb(251, 83, 83);
    padding: 1px;
  }
`, "",{"version":3,"sources":["webpack://./app/pages/MyHours/ModalAddAbsenceActivity.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AAQrB;;EANE;IACE,qBAAqB;IACrB,kBAAkB;IAClB,kCAAkC;IAClC,YAAY;EACd","sourcesContent":[".leavesReminder {\n  margin-bottom: 20px;\n\n  & h4 {\n    display: inline-block;\n    margin-bottom: 5px;\n    background-color: rgb(251, 83, 83);\n    padding: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leavesReminder": `app-pages-MyHours-___ModalAddAbsenceActivity-module__leavesReminder`
};
export default ___CSS_LOADER_EXPORT___;
