/** @flow */
import vars from 'app/styles/vars.js'

const { getPalette } = vars

export const getSequenceColor = (sequence: number): null | string => {
  if (!sequence && typeof sequence !== 'number') return null

  const colors = getPalette(['c400', 'c300', 'c600', 'c200', 'c500', 'c700'])

  /* [
    '#F5A9A9',
    '#F5BCA9',
    '#F5D0A9',
    '#F3E2A9',
    '#F2F5A9',
    '#E1F5A9',
    '#D0F5A9',
    '#BCF5A9',
    '#A9F5A9',
    '#A9F5BC',
    '#A9F5D0',
    '#A9F5E1',
    '#A9F5F2',
    '#A9E2F3',
    '#A9D0F5',
    '#A9BCF5',
    '#A9A9F5',
    '#BCA9F5',
    '#D0A9F5',
    '#E2A9F3',
    '#F5A9F2',
    '#F5A9E1',
    '#F5A9D0',
    '#F5A9BC',
    '#E6E6E6',
  ]
  */

  return colors[sequence % colors.length]
}
