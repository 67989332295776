import React, { useEffect } from 'react'
import { showPanel } from 'app/components/OverPage/OverPage.jsx'
import Task from './Task.jsx'
import { router } from './router'

export default () => {
  const open = router.getConfig().rightPanel && router.exist()

  useEffect(() => {
    if (open) showPanel('task', <Task />, () => router.remove())
    else showPanel('task', null)
  }, [open])

  return null
}
