var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ModalConfirmForm } from 'app/components/Modal';
import { FormData } from 'app/components/Form';
import { useDispatch } from 'react-redux';
import { createExternalJob, updateExternalJob } from 'app/store/reducers/externalJobs';
export function ModalEditExternalJob(props) {
    const dispatch = useDispatch();
    const { job } = props, modalProps = __rest(props, ["job"]);
    function onSave(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { name } = data;
            const res = dispatch((job === null || job === void 0 ? void 0 : job.id) ? updateExternalJob({ name, id: job === null || job === void 0 ? void 0 : job.id }) : createExternalJob(name));
            return res;
        });
    }
    return (_jsx(ModalConfirmForm, Object.assign({ title: job ? `Edit external job ${job.name || ''}` : 'Create a new external job ', draggable: true }, modalProps, { children: _jsx(FormData, { onSave: onSave, flashNotifSuccessLabel: `External job ${job ? 'edited' : 'created'}`, defaultData: job, properties: [
                {
                    label: 'Name',
                    key: 'name',
                    type: 'string',
                    elementProps: {
                        placeholder: 'External job title',
                        isRequired: true,
                    },
                },
            ] }) })));
}
