// @flow
import React from 'react'
import { SketchPicker } from 'react-color'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData } from 'app/components/Form'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import resources from 'app/store/resources'
import type { ProjectPlan } from 'app/core/types'
import styles from 'app/styles/vars.js'

export type ModalEditProjectPlanProps = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  projectPlan?: ?ProjectPlan,
  onSave?: ($Shape<ProjectPlan>) => Promise<any>,
  onChange?: ($Shape<ProjectPlan>) => any,
|}

export function ModalEditProjectPlan(props: ModalEditProjectPlanProps): React$Element<any> {
  const { projectPlan, onSave, onChange, ...modalProps } = props

  async function save(data: Object) {
    const { project, startDate, endDate, name, color } = data

    const newProjectPlan = {
      id: projectPlan?.id,
      startDate,
      endDate,
      project: project?.value,
      name,
      color,
    }

    if (onSave) return onSave(newProjectPlan)
    const res = await resources.projectPlans[projectPlan?.id ? 'update' : 'create'](newProjectPlan)
    onChange?.(res.resources[0])
    return res
  }

  const defaultData = {
    ...projectPlan,
    project: projectPlan?.projectInst
      ? { label: projectPlan.projectInst.name, value: projectPlan.projectInst.id }
      : undefined,
    startDate: projectPlan?.startDate,
    endDate: projectPlan?.endDate,
  }

  return (
    <ModalConfirmForm
      title={projectPlan ? `Edit project ${projectPlan.name || ''}` : 'Create new project'}
      draggable={true}
      {...modalProps}
    >
      <FormData
        onSave={save}
        flashNotifSuccessLabel={`Project ${projectPlan ? 'edited' : 'created'}`}
        defaultData={defaultData}
        properties={[
          {
            label: 'Name',
            key: 'name',
            type: 'string',
            elementProps: {
              isRequired: true,
            },
          },
          {
            label: 'Start date',
            key: 'startDate',
            type: 'string',
            elementProps: {
              type: 'date',
              isRequired: true,
            },
          },
          {
            label: 'End date',
            key: 'endDate',
            type: 'string',
            elementProps: {
              type: 'date',
              isRequired: true,
            },
          },
          {
            label: 'Project',
            key: 'project',
            type: 'autocomplete',
            element: AssetsSelect,
            elementProps: {
              assetTypes: ['pj'],
              placeholder: 'Select a project',
            },
          },
          {
            key: 'color',
            label: 'Color',
            type: 'custom',
            elementProps: {
              isRequired: true,
            },
            element: (data, setData) => {
              return (
                <SketchPicker
                  onChangeComplete={(color) => {
                    setData({ ...data, color: color.hex })
                  }}
                  disableAlpha={true}
                  presetColors={styles.randomColors}
                  color={data.color}
                />
              )
            },
          },
        ]}
      />
    </ModalConfirmForm>
  )
}
