/** @flow */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { rootContextMenu } from 'app/main/rootContainers.js'
import moment from 'moment'
import cx from 'classnames'
import type { ID } from 'app/core/types'
import { lightenDarkenColor } from 'app/libs/lightenDarkenColor'
import { targetIsChild } from 'app/libs/helpers/dom'
import resources from 'app/store/resources'
import { ContextMenu } from 'app/components/ContextMenu'
import classesTooltip from '../../Tooltips.module.scss'

import type { CellInstance, TimelineEditionItems } from '../../../../types'
import { useCellTimelineEdition } from '../../useCellTimelineEdition'
import { TimelineMaxResizer } from '../../TimelineMaxResizer.jsx'

import classes from './TimelineCellComponent.module.scss'

type Props = {|
  item: Object,
  timelineEditionItems: ?TimelineEditionItems,
  instance: CellInstance,
  onSelect: (id: ?ID) => void,
  selected: ?ID,
|}

export function TimelineCellComponent(props: Props): React$Node {
  const { instance, item, onSelect, selected, timelineEditionItems } = props
  const { dimensions, ganttProperties, setSavingCells, unsetSavingCells, cell } = instance

  const {
    startDate: _startDate,
    endDate: _endDate,
    earliestStartDate: _earliestStartDate,
    latestEndDate: _latestEndDate,
  } = item

  const { ganttStart } = ganttProperties || {}
  const { dayWidth } = dimensions || {}

  const [{ startDate, endDate, earliestStartDate, latestEndDate }, setDates] = useState<{
    startDate: ?moment,
    endDate: ?moment,
    earliestStartDate: ?moment,
    latestEndDate: ?moment,
  }>({
    startDate: undefined,
    endDate: undefined,
    earliestStartDate: undefined,
    latestEndDate: undefined,
  })
  const [{ leftDiff, widthDiff }, setPositionsChange] = useState<{ leftDiff: number, widthDiff: number }>({
    leftDiff: 0,
    widthDiff: 0,
  })

  const [{ earliestLeft, latestLeft }, setEarliestLatestLeft] = useState<{ earliestLeft: number, latestLeft: number }>({
    earliestLeft: 0,
    latestLeft: 0,
  })

  const elementRef = useRef()
  const opacified = useMemo(() => (selected ? item.id !== selected : false), [selected])

  const { start, end, earliest, latest, label, resource, color, contextMenuItems } = timelineEditionItems || {}

  useEffect(() => {
    const startDate = _startDate ? moment(_startDate) : undefined
    const endDate = _endDate ? moment(_endDate) : undefined
    const earliestStartDate = _earliestStartDate ? moment(_earliestStartDate) : undefined
    const latestEndDate = _latestEndDate ? moment(_latestEndDate) : undefined

    setDates({ startDate, endDate, earliestStartDate, latestEndDate })
    setPositionsChange({ leftDiff: 0, widthDiff: 0 })
  }, [_startDate, _endDate, _earliestStartDate, _latestEndDate])

  function onUpdate({
    startDate,
    endDate,
    earliestStartDate,
    latestEndDate,
  }: {
    startDate?: ?moment,
    endDate?: ?moment,
    earliestStartDate?: ?moment,
    latestEndDate?: ?moment,
  }) {
    if (!resource) return Promise.resolve()

    setSavingCells({ [cell.id]: cell })
    const value: Object = { id: item.id }

    if (startDate) {
      value[start?.attr || 'startDate'] = start?.formatValue?.(startDate) || startDate.toISOString()
    }
    if (endDate) {
      value[end?.attr || 'endDate'] = end?.formatValue?.(endDate) || endDate.toISOString()
    }
    if (earliestStartDate) {
      value[earliest.attr || 'earliestStartDate'] =
        earliest.formatValue?.(earliestStartDate) || earliestStartDate.toISOString()
    }
    if (latestEndDate) {
      value[latest.attr || 'latestEndDate'] = latest.formatValue?.(latestEndDate) || latestEndDate.toISOString()
    }

    return resources[resource]?.update(value).finally(() => {
      const { cell, updateCells } = instance
      updateCells({ [cell.id]: cell })
      unsetSavingCells({ [cell.id]: cell })
    })
  }

  const { onMove, leftResizer, rightResizer } = useCellTimelineEdition({
    instance,
    timelineEditionItems,
    startDate,
    endDate,
    onPositionsChange: (leftDiff, widthDiff) => setPositionsChange({ leftDiff, widthDiff }),
    onUpdate,
    leftTootlip: moment(startDate)
      .add(Math.round(leftDiff / dayWidth), 'day')
      .format('dd DD/MM/YYYY'),
    rightTootlip: moment(endDate)
      .add(Math.round((leftDiff + widthDiff) / dayWidth), 'day')
      .format('dd DD/MM/YYYY'),
    disabled: !resource || timelineEditionItems?.earliest?.readOnly || timelineEditionItems?.latest?.readOnly,
  })

  const daysLeft = useMemo(
    () => startDate?.diff(ganttStart, 'days') || 0,
    [startDate?.format('YYYY-MM-DD'), ganttStart.format('YYYY-MM-DD')],
  )
  const difference = useMemo(
    () => (endDate?.diff(moment(startDate), 'days') || 0) + 1,
    [startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD')],
  )
  const left = useMemo(() => dayWidth * daysLeft, [dayWidth, daysLeft])
  const width = useMemo(() => Math.max(1, dayWidth * difference), [dayWidth, difference])

  const _style = useMemo(
    () => ({ left: left + leftDiff, width: width + widthDiff }),
    [left, width, leftDiff, widthDiff],
  )

  function onFocus(e: SyntheticFocusEvent<HTMLElement>) {
    e.nativeEvent.stopImmediatePropagation()
    onSelect(item.id)
  }

  function onContextMenu(event: SyntheticMouseEvent<>) {
    event.preventDefault()

    if (!contextMenuItems || contextMenuItems.length < 1) return

    const { pageX, pageY } = event

    setTimeout(() => {
      rootContextMenu.render(<ContextMenu clientX={pageX} clientY={pageY} items={contextMenuItems} />)
    }, 0)
  }

  if (!startDate || !endDate) return null

  const itemColor = timelineEditionItems?.color ? lightenDarkenColor(0.7, timelineEditionItems.color) : undefined
  const exceedColor = timelineEditionItems?.color ? lightenDarkenColor(0, timelineEditionItems.color) || 'red' : 'red'
  const exceedBackground = `repeating-linear-gradient(120deg, ${exceedColor}, ${exceedColor} 6px, transparent 6px, transparent 12px)`

  return (
    <div
      className={cx(classes.container, classesTooltip.tooltipContainer)}
      tabIndex={0}
      onContextMenu={(e) => e.preventDefault()}
      onBlur={(e) => {
        if (!targetIsChild(e)) onSelect()
      }}
    >
      {timelineEditionItems?.earliest && earliestStartDate ? (
        <TimelineMaxResizer
          date={earliestStartDate}
          instance={instance}
          onUpdate={(earliestStartDate) => onUpdate({ earliestStartDate })}
          onPositionChange={(left) =>
            setEarliestLatestLeft(({ earliestLeft, ...rest }) => ({ ...rest, earliestLeft: left }))
          }
          position="min"
          color={color}
          tooltip={earliest?.tooltip}
          style={{ opacity: opacified ? 0.1 : 1 }}
          onFocus={onFocus}
          disabled={!resource || timelineEditionItems.earliest.readOnly}
        />
      ) : null}
      <div
        className={cx(classes.item, classesTooltip.item)}
        tabIndex="0"
        style={_style}
        onFocus={onFocus}
        onMouseDown={resource ? onMove : undefined}
        onContextMenu={onContextMenu}
        title={label}
      >
        <div
          className={cx(classes.editionContainer, classesTooltip.editionContainer, {
            [classes.opacified]: opacified,
          })}
          style={{
            backgroundColor: itemColor,
            opacity: opacified ? 0.1 : 1,
          }}
          ref={(ref) => {
            elementRef.current = ref
          }}
        >
          {earliestStartDate && earliestLeft - _style.left > 0 ? (
            <div
              className={classes.exceeded}
              style={{
                left: 0,
                width: earliestLeft - _style.left,
                maxWidth: _style.width - 2,
                background: exceedBackground,
              }}
            />
          ) : null}
          {latestEndDate && _style.left + _style.width - latestLeft - 2 > 0 ? (
            <div
              className={classes.exceeded}
              style={{
                right: 0,
                width: _style.left + _style.width - latestLeft - 2,
                maxWidth: _style.width - 2,
                background: exceedBackground,
              }}
            />
          ) : null}
          {leftResizer}
          <div className={classes.title}>
            <div className={classes.label} style={{ backgroundColor: itemColor, opacity: 0.75 }}>
              {label}
            </div>
          </div>
          {rightResizer}
        </div>
      </div>
      {timelineEditionItems?.latest && latestEndDate ? (
        <TimelineMaxResizer
          date={latestEndDate}
          instance={instance}
          onUpdate={(latestEndDate) => onUpdate({ latestEndDate })}
          onPositionChange={(left) =>
            setEarliestLatestLeft(({ latestLeft, ...rest }) => ({ ...rest, latestLeft: left }))
          }
          position="max"
          color={color}
          tooltip={latest?.tooltip}
          style={{ opacity: opacified ? 0.1 : 1 }}
          onFocus={onFocus}
          disabled={!resource || timelineEditionItems.latest.readOnly}
        />
      ) : null}
    </div>
  )
}
