import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useState } from 'react';
import { map, filter } from 'lodash';
import Cards from 'app/components/OrganizationChart/GlobalPage/Cards';
const OrganizationChartBottom = (props) => {
    const { searchInput, people, offices, projectPlans, filterStudio, filterProject } = props;
    const [searchPeople, setSearchPeople] = useState([]);
    const [studioId, setStudioId] = useState([]);
    const [projectId, setProjectId] = useState([]);
    useEffect(() => setSearchPeople(people), [people]);
    useEffect(() => {
        if (filterStudio[0].value !== 'all') {
            if (studioId.length > 0)
                setStudioId([]);
            filterStudio.map((studio) => {
                const studioId = studio.value;
                if (studioId !== 'all')
                    setStudioId((prevArray) => [...prevArray, studioId]);
            });
        }
        if (filterStudio[0].value === 'all')
            setStudioId(map(offices, (office) => office.id));
        if (filterProject[0].value !== 'all') {
            if (projectId.length > 0)
                setProjectId([]);
            filterProject.map((project) => {
                const projectId = project.value;
                if (projectId !== 'all')
                    setProjectId((prevArray) => [...prevArray, projectId]);
            });
        }
        if (filterProject[0].value === 'all')
            setProjectId(map(projectPlans, (pp) => pp.id));
    }, [filterStudio, filterProject]);
    useEffect(() => {
        const filteredPeople = filter(people, (p) => {
            return (studioId.includes(p.currentContract.office) &&
                projectId.includes(p.currentContract.projectPlan) &&
                (p.lastName.toLowerCase().includes(searchInput) || p.firstName.toLowerCase().includes(searchInput)));
        });
        setSearchPeople(filteredPeople);
    }, [searchInput, people, studioId, projectId]);
    return (_jsx(_Fragment, { children: map(searchPeople, (p) => (_jsx(Cards, { people: p, offices: offices, projectPlans: projectPlans }, p.id))) }));
};
export default OrganizationChartBottom;
