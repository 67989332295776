// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Notifications-ManageSubscriptions-___ManageSubscriptions-module__buttons {
  margin-bottom: 20px;
}

.app-pages-Notifications-ManageSubscriptions-___ManageSubscriptions-module__item {
  border-top: var(--mainBorder);
  padding: 8px 12px;
}

.app-pages-Notifications-ManageSubscriptions-___ManageSubscriptions-module__item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
`, "",{"version":3,"sources":["webpack://./app/pages/Notifications/ManageSubscriptions/ManageSubscriptions.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,iBAAiB;AACnB;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".buttons {\n  margin-bottom: 20px;\n}\n\n.item {\n  border-top: var(--mainBorder);\n  padding: 8px 12px;\n}\n\n.item:hover {\n  background-color: rgba(0, 0, 0, 0.02);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `app-pages-Notifications-ManageSubscriptions-___ManageSubscriptions-module__buttons`,
	"item": `app-pages-Notifications-ManageSubscriptions-___ManageSubscriptions-module__item`
};
export default ___CSS_LOADER_EXPORT___;
