// @flow
import { normalize, schema } from 'normalizr'
import forEach from 'lodash/forEach'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import type { StoreResourceName } from 'app/core/types'
import * as resourcesAPI from '../resources'

const schemas = mapValues(resourcesAPI, (resourceAPI, resourceType) => {
  return new schema.Entity(resourceType, {}, { idAttribute: 'id' })
})

forEach(schemas, (schema, resourceType) => {
  const resourceAPI = resourcesAPI[resourceType]
  const { relations = {} } = resourceAPI
  const relationsWithServerKey = mapKeys(relations, (value, key) => value.serverKey || key)

  const schemaRelations = mapValues(relationsWithServerKey, (relation, key) => {
    const relationSchema = schemas[relation.resourceType]
    if (relation.type === 'hasOne') {
      return relationSchema
    }
    if (relation.type === 'hasMany') {
      return [relationSchema]
    }

    throw new Error(`getIncludedResources: bad relation type ${relation.type}`)
  })

  schema.define(schemaRelations)
})

export const getIncludedResources = (resourceType: StoreResourceName, data: Array<Object>): Object => {
  const resourceAPI = resourcesAPI[resourceType]
  if (!resourceAPI.relations) return {}
  const schema = schemas[resourceType]
  const { entities } = normalize(data, [schema])

  return entities
}
