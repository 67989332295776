// @flow
import { forEach, mapValues, pickBy, keyBy, reduce } from 'lodash'
import type { createGetResourcesType } from './types'
import { convertSchema } from './convertSchema'

const throwUndefinedResourceType = (resourceType: string): void => {
  throw new Error(`getResources(state, '${resourceType}') : ${resourceType} dosen't exist.`)
}

export const createGetResources: createGetResourcesType = (resourcesSchema) => {
  forEach(resourcesSchema, (relations, resourceName) => {
    forEach(relations, (rel, relName) => {
      if (!resourcesSchema[rel.resourceType]) {
        throw new Error(`redux-orm-selector: resource ${resourceName}: resourceType ${rel.resourceType} doesn't exist.`)
      }

      if (!['hasOne', 'hasMany'].includes(rel.type)) {
        throw new Error(
          `redux-orm-selector: resource ${resourceName}: relation ${relName} has invalid type '${rel.type}'`,
        )
      }
    })
  })

  const getOneResource = (state: Object, resourceType: string, resourceId: string, schema: ?Object) => {
    const resource = state[resourceType].resources[resourceId]

    if (!resource) return null
    if (!schema) return resource

    const relations = resourcesSchema[resourceType]
    const relationInsts = {}

    forEach(schema, (nestedSchema, relationName) => {
      const relation = relations[relationName]
      if (!relation) {
        throw new Error(
          `redux-orm-selector: ${resourceType}: relation ${relationName} doesn't exist ! \n\nRelations:\n\n${Object.keys(
            relations,
          ).join(', ')}\n`,
        )
      }

      const { type, resourceType: relatedResourceType, foreignKey } = relation

      // here, schema is boolean or object
      const finalNestedSchema = nestedSchema === true ? null : nestedSchema

      if (type === 'hasOne') {
        const relatedResourceId = resource[foreignKey] || resource[relationName]
        relationInsts[relationName] =
          relatedResourceId && getOneResource(state, relatedResourceType, relatedResourceId, finalNestedSchema)
      } else if (type === 'hasMany') {
        relationInsts[relationName] = reduce(
          state[relatedResourceType].resources,
          (values, item, key) => {
            if (item[foreignKey] === resourceId) {
              values.push(getOneResource(state, relatedResourceType, key, finalNestedSchema))
            }
            return values
          },
          [],
        )
      }
    })

    return {
      ...resource,
      ...relationInsts,
    }
  }

  const getResources = (
    state: Object,
    resourceTypeOrPath: string,
    filterOrResourceId?: Object | Function | string | Array<string>,
    schema?: Array<string>,
  ) => {
    if (!resourceTypeOrPath) return state

    let resourceType = resourceTypeOrPath
    const hasFilter = ['function', 'object'].includes(typeof filterOrResourceId)

    const convertedSchema = schema && convertSchema(schema)
    if (Array.isArray(filterOrResourceId)) {
      return filterOrResourceId.map((id) => getOneResource(state, resourceType, id, convertedSchema))
    }

    if (typeof filterOrResourceId === 'string') {
      return getOneResource(state, resourceType, filterOrResourceId, convertedSchema)
    }

    // get lists or requests ids
    if (resourceTypeOrPath.includes('.')) {
      const resourceTypeSplited = resourceTypeOrPath.split('.')
      if (resourceTypeSplited.length !== 3) {
        throw new Error(`getResources(state, '${resourceType}') is invalid`)
      }

      resourceType = resourceTypeSplited[0]
      if (!state[resourceType]) {
        throwUndefinedResourceType(resourceType)
      }

      const type: string = resourceTypeSplited[1]
      const value: string = resourceTypeSplited[2]

      let listIds = []

      if (type === 'lists') {
        const list = state[resourceType].lists[value]
        if (list) {
          listIds = list
        }
      } else if (type === 'paginatedLists') {
        const paginatedList = state[resourceType].paginatedLists[value]
        if (paginatedList) {
          listIds = paginatedList.ids || []
        }
      } else if (type === 'requests') {
        const request = state[resourceType].requests[value]
        if (request) {
          listIds = request.ids || []
        }
      } else {
        // if it's not lists or request
        throw new Error(
          `getResources(state, '${resourceType}') is invalide. It must start by '${resourceType}.lists' or '${resourceType}.requests'  or '${resourceType}.paginatedLists'`,
        )
      }

      const listIdsAsObject = keyBy(
        listIds.map((id) => ({ id })),
        'id',
      )

      const resources = mapValues(listIdsAsObject, (val, id) =>
        getOneResource(state, resourceType, id, convertedSchema),
      )

      return hasFilter ? pickBy(resources, filterOrResourceId) : resources
    }

    if (!state[resourceType]) {
      throwUndefinedResourceType(resourceType)
    }

    let { resources } = state[resourceType]

    const relations = resourcesSchema[resourceType]

    if (hasFilter) {
      resources = pickBy(resources, filterOrResourceId)
    }

    if (relations) {
      return reduce(
        resources,
        (values, resource, key: string) => ({
          ...values,
          [key]: getOneResource(state, resourceType, key, convertedSchema),
        }),
        {},
      )
    }

    return resources
  }

  return getResources
}
