/** @flow */
import React, { useEffect, useRef } from 'react'
import { useImmer } from 'use-immer'
import { map } from 'lodash'
import type { Asset, Post, User, Pipe, ID } from 'app/core/types'
import socket from 'app/core/api/socket'
import resources from 'app/store/resources/index.js'
import { getResources } from 'app/store/selectors/index.js'
import pipe from 'app/core/utils/pipe.jsx'
import Topics from './Topics.jsx'
import Threads from './Threads.jsx'
import Chat from './Chat.jsx'

type Props = {|
  router: Object,
  asset: Asset,
  user: User,
  projectId: ID,
|}

function Discussions(props: Props) {
  const { router, asset, user, projectId } = props
  const { Route } = router
  const [collapsabeWidth, _setCollapsableWidth] = useImmer([300, 400])
  const selectedThread = useRef(null)
  const room = useRef()

  const setSelectedThread = (threadId) => {
    selectedThread.current = threadId
  }

  const setCollapsabeWidth = (index: 1 | 0) => (width: number) => {
    _setCollapsableWidth((widths) => {
      widths[index] = width
    })
  }

  useEffect(() => {
    if (!room.current) {
      room.current = socket.subscribe(`asset_${asset.id}`)
      room.current.on('create', (post: Post) => {
        resources.posts.create(post, { localOnly: true })
        const thread = map(getResources(undefined, 'threads', { id: post.thread }))[0]

        if (thread) {
          resources.threads.update(
            {
              id: post.thread,
              lastPost: post.id,
              lastPostInst: post,
              postCount: thread.postCount + 1,
              unreadPostCount:
                user.asset === post.authorInst.id || selectedThread.current === post.thread
                  ? 0
                  : thread.unreadPostCount + 1,
            },
            { localOnly: true },
          )
        }
      })
      // $FlowFixMe
      room.current.on('update', (post: Post) => {
        resources.posts.update(post, { localOnly: true })
      })
    }
  }, [])

  return (
    <div
      className="flex row noWrap fullWidth"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      }}
    >
      <Topics router={router} asset={asset} getWidth={setCollapsabeWidth(0)} projectId={projectId} />
      <Route
        name="topic"
        component={Threads}
        componentProps={{
          getWidth: setCollapsabeWidth(1),
          selectedThread: selectedThread.current,
          project: projectId,
        }}
      />
      <Route
        name="thread"
        component={Chat}
        componentProps={{
          router,
          collapsabeWidth: collapsabeWidth.reduce((a, b) => a + b),
          selectedThread: setSelectedThread,
          project: projectId,
        }}
      />
    </div>
  )
}

const pipeInst: Pipe<{ projectId: ID }, typeof Discussions> = pipe()
const DiscussionsComponent: React$ComponentType<any> = pipeInst
  .connect((state, props) => ({ user: state.user }))
  .render(Discussions)
export default DiscussionsComponent
