// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetDetail-ValidationMedias-___Mozaic-module__imageGrid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(50px, 250px);
}

  .app-containers-Assets-AssetDetail-ValidationMedias-___Mozaic-module__imageGrid .app-containers-Assets-AssetDetail-ValidationMedias-___Mozaic-module__imageItem:hover {
    cursor: pointer;
  }

  .app-containers-Assets-AssetDetail-ValidationMedias-___Mozaic-module__imageGrid .app-containers-Assets-AssetDetail-ValidationMedias-___Mozaic-module__imageItem:nth-child(5n) {
    grid-column-end: span 2;
  }

  .app-containers-Assets-AssetDetail-ValidationMedias-___Mozaic-module__imageGrid img {
    display: flex;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetDetail/ValidationMedias/Mozaic.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,4DAA4D;EAC5D,mCAAmC;AAgBrC;;EAdE;IACE,eAAe;EACjB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,YAAY;IACZ,oBAAiB;OAAjB,iBAAiB;EACnB","sourcesContent":[".imageGrid {\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  grid-auto-rows: minmax(50px, 250px);\n\n  .imageItem:hover {\n    cursor: pointer;\n  }\n\n  .imageItem:nth-child(5n) {\n    grid-column-end: span 2;\n  }\n\n  img {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageGrid": `app-containers-Assets-AssetDetail-ValidationMedias-___Mozaic-module__imageGrid`,
	"imageItem": `app-containers-Assets-AssetDetail-ValidationMedias-___Mozaic-module__imageItem`
};
export default ___CSS_LOADER_EXPORT___;
