// @flow
import { map } from 'lodash'
import { operators as op, presetOperators as pOp } from 'app/components/Table/TableView/Elements/Filters/operators.ts'
import type {
  Person,
  ColumnFilter,
  ResourcesList,
  PersonAttribute,
  ExternalJob,
  ProjectPlan,
  Asset,
  Department,
} from 'app/core/types'
import type { ColumnHeader, Column } from 'app/components/Table/types.js'
import {
  getConfigEntryOption,
  getConfigEntries,
} from 'app/pages/Project/Settings/FollowUp/AttributesEditor/attributeAssets'
import { editorTypeToInputType } from 'app/components/Table/hooks/useFiltersUtils'
import { getContractStatusOptions } from '../../../core/utils/getContractsStatus'
import { getContractTypesOptions } from '../../../containers/Modals/Contracts/ModalEditContract/utils'

type Props = {|
  columns: Array<ColumnHeader>,
  personAttributes: ResourcesList<PersonAttribute>,
  externalJobs: ResourcesList<ExternalJob>,
  projectPlans: ResourcesList<ProjectPlan>,
  offices: Asset[],
  departments: ResourcesList<Department>,
|}

export class Filters {
  params: {
    personAttributes: ResourcesList<PersonAttribute>,
    columns: Array<ColumnHeader>,
    externalJobs: ResourcesList<ExternalJob>,
    projectPlans: ResourcesList<ProjectPlan>,
    offices: Asset[],
    departments: ResourcesList<Department>,
  } = {}

  filters: { [key: string]: Array<ColumnFilter> } = {}

  constructor({ columns, personAttributes, externalJobs, projectPlans, offices, departments }: Props) {
    this.params = {
      personAttributes,
      columns,
      externalJobs,
      projectPlans,
      offices,
      departments,
    }

    this.filters = {
      person: [
        {
          label: 'People',
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => {
              return {
                ...op,
                attr: 'uuid',
                multiple: true,
                inputType: 'person',
                resourceValue: 'people',
                formatResourceValues: (person: Person) => {
                  const { firstName, lastName, id } = person
                  return {
                    label: `${lastName} ${firstName}`,
                    value: id,
                  }
                },
              }
            }),
          ],
        },
        {
          label: 'First name',
          type: 'column',
          operators: [
            ...map(pOp.str, (op) => ({
              ...op,
              attr: 'firstName',
            })),
          ],
        },
        {
          label: 'Last name',
          type: 'column',
          operators: [
            ...map(pOp.str, (op) => ({
              ...op,
              attr: 'lastName',
            })),
          ],
        },
        {
          label: 'Linked user',
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => ({
              ...op,
              attr: 'user__uuid',
              multiple: true,
              inputType: 'asset',
              assetTypes: ['us'],
            })),
          ],
        },
        {
          label: 'External Job 1',
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => ({
              ...op,
              attr: 'externalJob1__uuid',
              multiple: true,
              inputType: 'select',
              options: {
                label: 'External Jobs',
                data: map(this.params.externalJobs, (job) => ({ label: job.name, value: job.id })),
              },
            })),
          ],
        },
        {
          label: 'External Job 2',
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => ({
              ...op,
              attr: 'externalJob2__uuid',
              multiple: true,
              inputType: 'select',
              options: {
                label: 'External Jobs',
                data: map(this.params.externalJobs, (job) => ({ label: job.name, value: job.id })),
              },
            })),
          ],
        },
        {
          label: 'Department',
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => ({
              ...op,
              attr: 'imputations__projectDepartmentJob__job__departments__uuid',
              multiple: true,
              inputType: 'select',
              options: {
                label: 'Departments',
                data: map(this.params.departments, (department) => ({ label: department.name, value: department.id })),
              },
            })),
          ],
        },
        {
          label: 'Last login',
          type: 'column',
          operators: [
            ...map(pOp.date, (op) => ({
              ...op,
              attr: `user__djangoUser__last_login`,
              inputType: 'datetime',
            })),
          ],
        },
      ],
      contract: [
        {
          label: 'Contract type',
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => {
              return {
                ...op,
                attr: 'contracts__contractType',
                multiple: true,
                inputType: 'select',
                options: {
                  label: 'Contract type',
                  data: getContractTypesOptions(),
                },
              }
            }),
          ],
        },
        {
          label: 'Contract status',
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => {
              return {
                ...op,
                attr: 'contracts__contractStatus',
                multiple: true,
                inputType: 'select',
                options: {
                  label: 'Contract status',
                  data: getContractStatusOptions(),
                },
              }
            }),
          ],
        },
        {
          label: 'Start date',
          type: 'column',
          operators: [
            ...map(pOp.date, (op) => {
              return {
                ...op,
                attr: 'contracts__startDate',
                inputType: 'date',
              }
            }),
          ],
        },
        {
          label: 'End date',
          type: 'column',
          operators: [
            ...map(pOp.date, (op) => {
              return {
                ...op,
                attr: 'contracts__endDate',
                inputType: 'date',
              }
            }),
          ],
        },
        {
          label: 'Mission estimated start date',
          type: 'column',
          operators: [
            ...map(pOp.date, (op) => {
              return {
                ...op,
                attr: 'contracts__missionEstimatedStartDate',
                inputType: 'date',
              }
            }),
          ],
        },
        {
          label: 'Mission estimated end date',
          type: 'column',
          operators: [
            ...map(pOp.date, (op) => {
              return {
                ...op,
                attr: 'contracts__missionEstimatedEndDate',
                inputType: 'date',
              }
            }),
          ],
        },
        {
          label: 'Salary',
          type: 'column',
          operators: [
            ...map(pOp.number, (op) => {
              return {
                ...op,
                attr: 'contracts__salary',
                inputType: 'number',
              }
            }),
          ],
        },
        {
          label: 'Project',
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => {
              return {
                ...op,
                attr: 'contracts__projectPlan__uuid',
                multiple: true,
                inputType: 'select',
                options: {
                  label: 'Project',
                  data: map(this.params.projectPlans, (pp) => ({ label: pp.name, value: pp.id })),
                },
              }
            }),
          ],
        },
        {
          label: 'Office',
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => {
              return {
                ...op,
                attr: 'contracts__office__uuid',
                multiple: true,
                inputType: 'asset',
                assetTypes: ['of'],
                options: {
                  label: 'Office',
                  data: map(this.params.offices, (pp) => ({ label: pp.name, value: pp.id })),
                },
              }
            }),
          ],
        },
      ],
      updates: [
        {
          label: 'Created at',
          type: 'column',
          operators: [
            ...map(pOp.date, (op) => ({
              ...op,
              attr: `createdAt`,
              inputType: 'datetime',
            })),
          ],
        },
        {
          label: 'Updated at',
          type: 'column',
          operators: [
            ...map(pOp.date, (op) => ({
              ...op,
              attr: `updatedAt`,
              inputType: 'datetime',
            })),
          ],
        },
      ],
    }
  }

  renderAttributeFilters: (column: Column) => void = (column) => {
    if (!column?.extends?.personAttribute) return
    const personAttribute: PersonAttribute = column.extends.personAttribute || {}
    if (this.filters[`attributes_${personAttribute.id}`]) return

    if (personAttribute.attrType === 'skill') {
      this.filters[`attributes_${personAttribute.id}`] = [
        {
          label: personAttribute.name,
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => ({
              ...op,
              attr: `personAttribute_${personAttribute.id}`,
              inputType: 'select',
              options: {
                label: personAttribute.name,
                data: getConfigEntryOption('SKILL_LEVELS'),
              },
              multiple: true,
            })),
            ...map([op.isNull, op.isNotNull], (op) => ({
              ...op,
              attr: `personAttribute_${personAttribute.id}`,
            })),
          ],
        },
      ]
      return
    }

    if (Array.isArray(personAttribute?.editorParams?.choice)) {
      this.filters[`attributes_${personAttribute.id}`] = [
        {
          label: personAttribute.name,
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => ({
              ...op,
              attr: `personAttribute_${personAttribute.id}`,
              inputType: 'select',
              valueType: editorTypeToInputType(personAttribute.editorType),
              options: {
                label: personAttribute.name,
                data: personAttribute.editorParams.choice?.map((value) => ({ label: value, value })) || [],
              },
              multiple: true,
            })),
            ...map([op.isNull, op.isNotNull], (op) => ({
              ...op,
              attr: `personAttribute_${personAttribute.id}`,
            })),
          ],
        },
      ]
      return
    }

    if (personAttribute?.editorParams?.configEntry) {
      const entries = getConfigEntries(personAttribute?.editorParams?.configEntry)

      if (!entries) {
        console.warn(`Unreconized configEntry "${personAttribute?.editorParams?.configEntry || 'undefined'}"`)
        return
      }

      this.filters[`attributes_${personAttribute.id}`] = [
        {
          label: personAttribute.name,
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => ({
              ...op,
              attr: `personAttribute_${personAttribute.id}`,
              inputType: 'select',
              valueType: editorTypeToInputType(personAttribute.editorType),
              options: {
                label: personAttribute.name,
                data: map(entries, (label: string, value: string) => ({ label, value })),
              },
              multiple: true,
            })),
            ...map([op.isNull, op.isNotNull], (op) => ({
              ...op,
              attr: `personAttribute_${personAttribute.id}`,
            })),
          ],
        },
      ]
      return
    }

    switch (personAttribute.attrType) {
      case 'float':
      case 'integer':
        this.filters[`attributes_${personAttribute.name}`] = [
          {
            label: personAttribute.name,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
                inputType: 'number',
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
            ],
          },
        ]
        break
      case 'duration':
        this.filters[`attributes_${personAttribute.name}`] = [
          {
            label: personAttribute.name,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
                inputType: 'duration',
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
            ],
          },
        ]
        break
      case 'date':
        this.filters[`attributes_${personAttribute.name}`] = [
          {
            label: personAttribute.name,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
                inputType: 'date',
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
            ],
          },
        ]
        break
      case 'datetime':
        this.filters[`attributes_${personAttribute.name}`] = [
          {
            label: personAttribute.name,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
                inputType: 'datetime',
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
            ],
          },
        ]
        break
      case 'bool':
        this.filters[`attributes_${personAttribute.name}`] = [
          {
            label: personAttribute.name,
            type: 'column',
            operators: [
              ...map(pOp.boolean, (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
            ],
          },
        ]
        break
      case 'text':
        this.filters[`attributes_${personAttribute.name}`] = [
          {
            label: personAttribute.name,
            type: 'column',
            operators: [
              ...map(pOp.text, (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
            ],
          },
        ]
        break
      case 'email':
      case 'url':
      case 'char':
      case 'ip':
        this.filters[`attributes_${personAttribute.name}`] = [
          {
            label: personAttribute.name,
            type: 'column',
            operators: [
              ...map(pOp.str, (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
            ],
          },
        ]
        break
      case 'time':
        this.filters[`attributes_${personAttribute.name}`] = [
          {
            label: personAttribute.name,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
                inputType: 'time',
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `personAttribute_${personAttribute.id}`,
              })),
            ],
          },
        ]
        break

      default: {
        break
      }
    }
  }

  getFilters(): Array<ColumnFilter> {
    const { columns } = this.params

    columns.forEach((header) => header.columns?.forEach(this.renderAttributeFilters))

    return map(this.filters).flat()
  }
}
