import { validateEmail, validateURL } from 'app/libs/helpers'

/**
 * Warning ! A validator must return false is there is no error
 */

export const required = (value) => {
  if ([null, undefined, ''].indexOf(value) !== -1) return 'Field Required'

  return false
}

export const email = (email) => {
  if (!validateEmail(email)) return 'Field must be an email'

  return false
}

export const URL = (textURL) => {
  if (!validateURL(textURL)) return 'Field must be an URL'

  return false
}

export const phone = (phoneNumber) => {
  return false
}
