// @flow
import { map, uniqBy } from 'lodash'
import { optionsPiority } from 'app/core/utils/optionsPriority'
import type { Asset, ColumnFilter, Attribute, ResourcesList } from 'app/core/types'
import { optionsProgressionStatus } from 'app/core/utils/optionsProgressionStatus'
import { assetsTypes } from 'app/core/constants'
import { operators as op, presetOperators as pOp } from 'app/components/Table/TableView/Elements/Filters/operators.ts'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import { getResources } from 'app/store/selectors'
import { editorTypeToInputType } from 'app/components/Table/hooks/useFiltersUtils'

type Params = {|
  episodes: Object,
  categories: Object,
  progressionStatus: Object,
  assetsAttributes: Array<Attribute> | void,
  projects: ResourcesList<Asset>,
|}

export class Filters {
  constructor({ episodes, categories, progressionStatus, assetsAttributes, projects }: Params) {
    this.params = {
      ...this.params,
      episodesOptions: map(episodes, (episode) => ({ value: episode.id, label: episode.name })),
      categoriesOptions: categories.map((category) => ({ value: JSON.stringify(category), label: category })),
      progressionStatus,
      assetsAttributes,
      projects,
    }
  }

  params: Object = {}

  getAssetsAttributes: () => Array<ColumnFilter> = () => {
    const filters: Array<ColumnFilter> = []
    const attributes = this.params.assetsAttributes || []

    uniqBy(attributes, 'name').forEach((attribute) => {
      const project = this.params.projects[attribute.project]
      if (!project) return

      if (attribute.editorParams.choice) {
        filters.push({
          label: attribute.name,
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => ({
              ...op,
              attr: `asset__attributes__${attribute.name}`,
              inputType: 'select',
              valueType: editorTypeToInputType(attribute.editorType),
              options: {
                label: attribute.name,
                data: attribute.editorParams.choice?.map((value: string) => ({ label: value, value })),
              },
              multiple: true,
            })),
            ...map([op.hasKey, op.hasNotKey], (op) => ({
              ...op,
              filterValue: `"${attribute.name}"`,
              attr: `attributes`,
              label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
            })),
            ...map([op.isNull, op.isNotNull], (op) => ({
              ...op,
              attr: `asset__attributes__${attribute.name}`,
            })),
          ],
        })
        return
      }

      switch (attribute.attrType) {
        case 'float':
        case 'integer':
          filters.push({
            label: attribute.name,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
                inputType: 'number',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
            ],
          })
          break
        case 'duration':
          filters.push({
            label: attribute.name,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
                inputType: 'duration',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
            ],
          })
          break
        case 'date':
          filters.push({
            label: attribute.name,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
                inputType: 'date',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
            ],
          })
          break
        case 'datetime':
          filters.push({
            label: attribute.name,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
                inputType: 'datetime',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
            ],
          })
          break
        case 'smartdate':
          break
        case 'bool':
          filters.push({
            label: attribute.name,
            type: 'column',
            operators: [
              ...map(pOp.boolean, (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
            ],
          })
          break
        case 'text':
          filters.push({
            label: attribute.name,
            type: 'column',
            operators: [
              ...map(pOp.text, (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
            ],
          })
          break
        case 'email':
        case 'url':
        case 'char':
        case 'ip':
          filters.push({
            label: attribute.name,
            type: 'column',
            operators: [
              ...map(pOp.str, (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
            ],
          })
          break
        case 'time':
          filters.push({
            label: attribute.name,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
                inputType: 'time',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `asset__attributes__${attribute.name}`,
              })),
            ],
          })
          break

        default:
          console.warn(`attribute attrType ${attribute.attrType} unknown`)
      }
    })

    return filters
  }

  getFilters(): Array<ColumnFilter> {
    /*
     * available in api
     *
     * "uuid": ["exact", "in"],
     * "priority": ["exact", "gte", "gt", "lte", "lt"],
     * "status__uuid": ["exact", "in"],
     * "parent__uuid": ["exact", "in"],
     * "taskType__uuid": ["exact", "in"],
     * "taskType__name": ["exact", "in"],
     * "step__uuid": ["exact", "in", "isnull"],
     * "asset__uuid": ["exact", "in"],
     * "asset__name": ["exact", "iexact", "isnull", "isempty", "contains", "icontains"],
     * "asset__thumbnail": ["exact", "isnull"],
     * "startDate": ["exact", "range", "gte", "gt", "lte", "lt"],
     * "endDate": ["exact", "range", "gte", "gt", "lte", "lt"],
     * "assignedUser__uuid": ["exact", "in", "isnull"],
     * "takes__flags__uuid": ["exact", "in", "count"],
     * "takes__uuid": ["count"],
     * "takes__number": ["exact", "range", "gte", "gt", "lte", "lt"],
     * "takes__comment": ["exact", "iexact", "isnull", "isempty", "contains", "icontains"],
     * "takes__refMedias__uuid": ["exact", "isnull"],
     * "takes__estimLength": ["exact", "range", "gte", "gt", "lte", "lt", "sum"],
     * "activities__duration": ["exact", "range", "gte", "gt", "lte", "lt", "sum"],
     * "activities__date": ["exact", "range", "gte", "gt", "lte", "lt"],
     */

    return [
      {
        label: 'Priority',
        type: 'column',
        operators: [
          ...map(pOp.number, (op) => ({
            ...op,
            attr: 'priority',
            options: {
              label: 'Priority',
              data: optionsPiority,
            },
            inputType: 'select',
            valueType: 'number',
          })),
        ],
      },
      {
        label: 'Episode',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => {
            return {
              ...op,
              attr: 'episode',
              multiple: true,
              inputType: 'asset',
              assetTypes: ['ep'],
              getSubLabel: (asset: Asset) => {
                const project = getResources(undefined, 'assets', asset.project)
                if (!project) return undefined
                return project.name
              },
              getProjectHeader: (asset: Asset) => {
                const project = getResources(undefined, 'assets', asset.project)
                if (!project) return undefined
                return project.id
              },
            }
          }),
        ],
      },
      {
        label: 'Project',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'asset__project__uuid',
            multiple: true,
            inputType: 'asset',
            assetTypes: ['pj'],
          })),
        ],
      },
      {
        label: 'Asset category',
        type: 'column',
        operators: [
          ...map([op.exact, op.notExact], (op) => ({
            ...op,
            attr: 'asset__attributes__category',
            inputType: 'select',
            options: {
              label: 'Asset category',
              data: this.params.categoriesOptions,
            },
          })),
          ...map(pOp.isNull, (op) => ({
            ...op,
            attr: 'asset__attributes__category',
          })),
          ...map([op.hasKey, op.hasNotKey], (op) => ({
            ...op,
            filterValue: '"category"',
            attr: 'asset__attributes',
          })),
        ],
      },
      {
        label: 'Asset type',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            multiple: true,
            inputType: 'select',
            options: {
              label: 'Asset type',
              // $FlowFixMe
              data: map(assetsTypes, (label: string, value: AssetTypes) => ({
                value,
                label,
                icon: assetIcons(value),
              })),
            },
            attr: 'asset__assetType',
          })),
        ],
      },
      {
        label: 'Asset',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'asset__uuid',
            multiple: true,
            inputType: 'asset',
            getSubLabel: (asset: Object) => {
              if (!asset) return undefined
              return asset.parent__name
            },
          })),
          ...map(pOp.text, (op) => ({
            ...op,
            attr: 'asset__name',
            inputType: 'text',
          })),
        ],
      },
      {
        label: 'Asset parent',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'asset__parent__uuid',
            multiple: true,
            inputType: 'asset',
            assetTypes: ['fo', 'ep'],
            getSubLabel: (asset: Object) => {
              const project = getResources(undefined, 'assets', asset.project)
              if (!asset) return undefined
              return `${asset.parent__name || ''} (${project.name || 'unknown project'})`
            },
          })),
        ],
      },
      {
        label: 'Assigned user',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'assignedUser__uuid',
            multiple: true,
            inputType: 'asset',
            assetTypes: ['us'],
          })),
          ...map(pOp.isNull, (op) => ({
            ...op,
            attr: 'assignedUser__uuid',
          })),
        ],
      },
      {
        label: 'Auto assigned user',
        type: 'column',
        operators: [
          ...map(pOp.isNull, (op) => ({
            ...op,
            attr: 'suggestedUser__uuid',
            label: op.value === 'isNull' ? 'Is not auto-assigned' : 'Is auto-assigned',
          })),
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'suggestedUser__uuid',
            multiple: true,
            inputType: 'asset',
            assetTypes: ['us'],
          })),
        ],
      },
      {
        label: 'Status',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'status__uuid',
            options: {
              label: 'Status',
              data: optionsProgressionStatus(this.params.progressionStatus),
            },
            multiple: true,
            inputType: 'select',
            resourceValue: 'progressionStatus',
          })),
        ],
      },
      {
        label: 'Step',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'step__uuid',
            multiple: true,
            inputType: 'step',
            resourceValue: 'steps',
          })),
        ],
      },
      {
        label: 'Start date',
        type: 'column',
        operators: [
          ...map(pOp.date, (op) => ({
            ...op,
            attr: 'startDate',
            inputType: 'date',
            // This formatValue function seems to be useless. We already have a formatValue on operators here : app/components/Table/TableView/Elements/Filters/operators.ts
            // In case of bug, check again
            // formatValue: (value, operator) => {
            //   let opValue
            //   if (op.formatValue) {
            //     try {
            //       opValue = JSON.parse(op.formatValue?.(value, operator))
            //     } catch (error) {
            //       opValue = value
            //     }
            //   } else opValue = value
            //   if (Array.isArray(opValue)) return opValue.map((v) => moment(v).toISOString()).toString()
            //   return moment(opValue).toISOString()
            // },
          })),
        ],
      },
      {
        label: 'End date',
        type: 'column',
        operators: [
          ...map(pOp.date, (op) => ({
            ...op,
            attr: 'endDate',
            inputType: 'date',
            // This formatValue function seems to be useless. We already have a formatValue on operators here : app/components/Table/TableView/Elements/Filters/operators.ts
            // In case of bug, check again
            // formatValue: (value, operator) => {
            //   let opValue
            //   if (op.formatValue) {
            //     try {
            //       opValue = JSON.parse(op.formatValue?.(value, operator))
            //     } catch (error) {
            //       opValue = value
            //     }
            //   } else opValue = value
            //   if (Array.isArray(opValue)) return opValue.map((v) => moment(v).toISOString()).toString()
            //   return moment(opValue).toISOString()
            // },
          })),
        ],
      },
      {
        label: 'Assumption Estimation',
        type: 'column',
        operators: [
          ...map(pOp.number, (op) => ({
            ...op,
            attr: 'quoteEstimLength',
            inputType: 'duration',
          })),
        ],
      },
      {
        label: 'Supervisor Estimation',
        type: 'column',
        operators: [
          ...map(pOp.number, (op) => ({
            ...op,
            attr: 'realEstimLength',
            inputType: 'duration',
          })),
        ],
      },
      {
        label: 'All takes estimation',
        type: 'column',
        operators: [
          ...map(pOp.sum, (op) => ({
            ...op,
            attr: 'takes__estimLength',
            inputType: 'duration',
          })),
        ],
      },
      {
        label: 'Last take estimation time',
        type: 'column',
        operators: [
          ...map(pOp.number, (op) => ({
            ...op,
            attr: 'lastTake__estimLength',
            inputType: 'duration',
          })),
        ],
      },
      {
        label: 'Spent time',
        type: 'column',
        operators: [
          ...map(pOp.sum, (op) => ({
            ...op,
            attr: 'activities__duration',
            inputType: 'duration',
          })),
        ],
      },
      {
        label: 'Flags',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'takes__flags__uuid',
            multiple: true,
            inputType: 'flags',
          })),
          ...map([op.countIsEmpty, op.countIsNotEmpty], (op) => ({
            ...op,
            attr: 'takes__flags__uuid',
          })),
        ],
      },
      {
        label: 'Number (takes)',
        type: 'column',
        operators: [
          ...map(pOp.number, (op) => ({
            ...op,
            attr: 'takes__number',
            inputType: 'number',
          })),
        ],
      },
      {
        label: 'Brief',
        type: 'column',
        operators: [
          ...map(pOp.text, (op) => ({
            ...op,
            attr: 'takes__comment',
            inputType: 'text',
          })),
        ],
      },
      ...this.getAssetsAttributes(),
    ]
  }
}
