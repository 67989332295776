// @flow

import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { ThreadsActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const threads: StoreResourceDef<ThreadsActions<>> = {
  resourceType: 'threads',
  actions: (actionsCreators) => ({
    fetchThreadsByTopic: (topicId, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.topics
            .threads({ id: topicId, ...params.queries }, params.queries, params.headers)
            .then(transformResults(config)),
      }
    },
    lastReadedPost: (data, config) => {
      const { params = {} } = config || {}
      const { postId, threadId } = data
      return {
        type: 'create',
        requestKey: config?.requestKey,
        request: () => api.threads.lastReadedPost({ id: threadId, post: postId }, params.queries, params.headers),
      }
    },
  }),
  relations: {
    participantsInst: {
      type: 'hasMany',
      resourceType: 'assets',
      foreignKey: 'participants',
    },
    moderatorsInst: {
      type: 'hasMany',
      resourceType: 'assets',
      foreignKey: 'moderators',
    },
    createdByInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'createdBy',
    },
    lastPostInst: {
      type: 'hasOne',
      resourceType: 'posts',
      foreignKey: 'lastPost',
    },
  },
}
