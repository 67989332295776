/** @flow */
import React, { useState, useEffect, type Element, useRef } from 'react'
import settings from 'app/core/settings'
import { MUIButton } from 'app/components/Form'
import LoaderSmall from '../LoaderSmall/LoaderSmall.jsx'

type Props = {|
  onClose: Function,
  downloadUrl?: string,
  text: Function | string | Element<any>,
  status: 'init' | 'started' | 'finished' | 'aborted',
  socket: Object,
  ovmExportId: string,
|}

export function ExportNotification(props: Props): React$Node {
  const { onClose, socket, text, downloadUrl, status, ovmExportId } = props

  const [_text, setTextContent] = useState(text)
  const [_downloadUrl, setDownloadUrl] = useState(downloadUrl)
  const [_status, setStatus] = useState(status)

  const mounted = useRef(true)

  useEffect(() => {
    if (socket.on && status !== 'finished') {
      socket.on('export_finished', (data) => {
        if (mounted.current) {
          if (!data) {
            setTextContent(
              'A problem occurred with the table export. Please repeat later or contact the administrator.',
            )
            setStatus('aborted')
          } else {
            const { fileName, exportId } = data

            if (exportId !== ovmExportId) return

            const splitedFilename = fileName.split('/')
            const filename = splitedFilename[splitedFilename.length - 1].split('.')[0]

            setTextContent(
              <span>
                The export of the table <b>{filename}</b> you have requested is ready to be downloaded.
              </span>,
            )
            setDownloadUrl(`${settings.env.API_URL}/${fileName}`)
            setStatus('finished')
          }
        }
      })
    }
    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <div style={{ color: '#ffffff' }} className="flex row noWrap alignCenter">
      {_downloadUrl ? (
        <a href={_downloadUrl} download={_downloadUrl} target="_blank" rel="noreferrer noopener">
          <MUIButton
            icon="fas-save"
            style={{
              height: '100%',
              width: 30,
              fontSize: 20,
              marginRight: 10,
            }}
            tooltip="Download"
            variant="text"
            dataCy="notif-download"
          />
        </a>
      ) : _status !== 'aborted' ? (
        <LoaderSmall className="marginRight10" style={{ width: 25, height: 25 }} />
      ) : null}
      <div className="margin10">{_text}</div>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <MUIButton icon="fas-times" onClick={() => onClose()} variant="text" dataCy="notif-close" />
      </div>
    </div>
  )
}
