/** @flow */
import React from 'react'
import cx from 'classnames'
import pipe from 'app/core/utils/pipe'
import type { ID, AssetTypes, Option, Asset, Pipe } from 'app/core/types'
import { getResources } from 'app/store/selectors'
import resources from 'app/store/resources/index.js'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import type { Props as AssetTreeProps } from 'app/containers/Assets/AssetsTree/AssetsTree.jsx'
import { assetIcons } from 'app/components/Icons/assetsIcons.js'

import AssetsTree from './AssetsTree.jsx'
import classes from './AssetsTreeSearch.module.scss'

type Props = {|
  ...AssetTreeProps,
  selectedAsset: ?Asset,
  className?: string,
  style?: Object,
  onSearchAsset?: (assetId: ID) => void,
  assetTypes?: Array<AssetTypes>,
  parentSearch?: ?ID,
|}

type State = {|
  currentAssetId?: ?ID,
  selectedAsset: ?Asset,
|}

class AssetsTreeSearch extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      currentAssetId: props.currentAssetId,
      selectedAsset: props.selectedAsset,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.currentAssetId !== this.state.currentAssetId) {
      this.setState({ currentAssetId: nextProps.currentAssetId })
    }
  }

  onSearchAsset = (value: { ...Option, data: Object }) => {
    const { id } = value.data

    return resources.assets.fetchWithFullPath(id).then(() => {
      const { onSearchAsset } = this.props

      if (onSearchAsset) onSearchAsset(id)

      this.setState({
        currentAssetId: id,
        selectedAsset: value.data,
      })
    })
  }

  renderAssetSearch() {
    const { assetTypes = ['ep', 'fo', 'mo', 'sh', 'gp', 'mi'] } = this.props
    const { selectedAsset } = this.state

    return (
      <div className="marginRight10 flex flex1 fullWidth">
        <AssetsSelect
          assetTypes={assetTypes}
          onChange={this.onSearchAsset}
          placeholder="Search asset"
          style={{ width: '100%' }}
          showAssetType={true}
          showAssetParent={true}
          // parentSearch={parentSearch}
          value={
            selectedAsset
              ? { value: selectedAsset.id, label: selectedAsset.name, icon: assetIcons(selectedAsset.assetType) }
              : null
          }
        />
      </div>
    )
  }

  onClickItem = (item) => {
    const { onClickItem } = this.props
    if (onClickItem) onClickItem(item)

    this.setState({ selectedAsset: item.metadata, currentAssetId: item.id })
  }

  render(): React$Node {
    const { currentAssetId, className, style, onClickItem, parentSearch, onSearchAsset, selectedAsset, ...rest } =
      this.props

    const _className = cx([className, classes.container])

    return (
      <div className={_className} style={style}>
        <div className={classes.tree}>
          <AssetsTree
            searchInput={this.renderAssetSearch()}
            currentAssetId={this.state.currentAssetId}
            onClickItem={this.onClickItem}
            {...rest}
          />
        </div>
      </div>
    )
  }
}

const pipeInst: Pipe<{ currentAssetId?: ?ID }, typeof AssetsTreeSearch> = pipe()

const AssetsTreeSearchComponent: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { currentAssetId } = props

    return {
      selectedAsset: currentAssetId ? getResources(state, 'assets', currentAssetId) : null,
    }
  })
  .request((props) => {
    const { currentAssetId } = props
    return currentAssetId ? resources.assets.fetchOne(currentAssetId).catch((err) => err) : Promise.resolve()
  })
  .render(AssetsTreeSearch)

export default AssetsTreeSearchComponent
