// @flow
import React, { useRef } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import type { Media } from 'app/core/types'
import keyCode from 'app/libs/keyCode'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { openPictures } from 'app/components/PictureVisualization/PictureVisualization.tsx'
import { isImageType } from 'app/containers/Medias/utils'
import { Permission } from 'app/containers/Permissions'
import resources from 'app/store/resources'
import { colors } from 'app/styles/colors'

import classes from './MediaOverlay.module.scss'

type OverlayProps = {|
  onDelete?: Function,
  folder?: Array<Media>,
  style?: Object,
  media: Media,
  allowPinMedia: boolean,
  allowValidateMedia: boolean,
|}

export function Overlay(props: OverlayProps): React$Node {
  const { media, folder, onDelete, style, allowPinMedia, allowValidateMedia } = props
  const downloadRef: React$ElementRef<any> = useRef()

  function onClick() {
    const isImage = isImageType(media.url)

    if (folder) openPictures(folder, allowPinMedia, allowValidateMedia, 0)
    else if (isImage) openPictures([media], allowPinMedia, allowValidateMedia, 0)
    else window.open(media.url)
  }

  function onKeyDown(event: SyntheticKeyboardEvent<any>) {
    if (event.keyCode === keyCode.ENTER) {
      event.preventDefault()
      onClick()
    }
    if (onDelete && ((event.metaKey && event.keyCode === keyCode.BACKSPACE) || event.keyCode === keyCode.DELETE)) {
      event.preventDefault()
      onDelete()
    }
    if (media.id && downloadRef.current && (event.ctrlKey || event.metaKey) && event.keyCode === keyCode.D) {
      event.preventDefault()
      downloadRef.current.click()
    }
  }

  return (
    <div onKeyDown={onKeyDown} className={classes.overlay} style={style} tabIndex="0" onClick={onClick}>
      <div className={classes.buttons}>
        <div
          className="flex spaceBetween row wrap fullWidth"
          onClick={(e: SyntheticMouseEvent<any>) => e.stopPropagation()}
        >
          <div className="flex row wrap">
            {media.id && allowPinMedia ? (
              <Permission actions={['projet_medias__Pin validation medias']}>
                <Tooltip title="Pin media to the mozaic" placement="top">
                  <div onClick={() => resources.medias.update({ id: media.id, pinned: !media.pinned })}>
                    <FontIcon
                      icon="fas-thumbtack"
                      className={classes.btn}
                      style={{ color: media.pinned ? '#ffffff' : 'rgba(255,255,255,0.4)' }}
                    />
                  </div>
                </Tooltip>
              </Permission>
            ) : null}

            {media.id && allowValidateMedia ? (
              <Permission actions={['projet_medias__Validate validation medias']}>
                <Tooltip title="Validate media" placement="top">
                  <div onClick={() => resources.medias.update({ id: media.id, validated: !media.validated })}>
                    <FontIcon
                      icon="fas-check"
                      className={classes.btn}
                      style={{ color: media.validated ? colors.green : 'rgba(255,255,255,0.4)' }}
                    />
                  </div>
                </Tooltip>
              </Permission>
            ) : null}
          </div>
          <div>
            {media.id ? (
              <Tooltip title="Download" placement="top">
                <a
                  ref={downloadRef}
                  tabIndex="-1"
                  href={media.url.replace('/medias/', '/download/medias/')}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontIcon icon="fas-download" className={classes.btn} />
                </a>
              </Tooltip>
            ) : null}
            {onDelete && (
              <Tooltip title="Delete" classes={{ tooltip: classes.lightTooltip }} placement="top">
                <FontIcon icon="fas-trash-alt" className={classes.btn} onClick={onDelete} />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
