// @flow
import React, { useEffect, useState, useRef } from 'react'

type Props = {|
  height?: number,
  minHeight?: number,
  open: boolean,
  children: any,
  className?: string,
  useTransition?: boolean,
|}

export function VerticalCollapse(props: Props): React$Node {
  const { children, height: _height, minHeight = 0, open = true, className, useTransition, ...rest } = props

  const [height, setHeight] = useState<number | void>(_height)
  const ref = useRef()

  useEffect(() => {
    if (typeof height !== 'number' && ref.current) {
      if (ref.current.clientHeight > 0) {
        setHeight(Number(ref.current.clientHeight))
      }
    }
  }, [ref.current, height])

  return (
    <div
      ref={ref}
      className={className}
      style={{
        overflow: 'hidden',
        height: open ? height : minHeight,
        transition: useTransition ? 'height 100ms ease-out' : undefined,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}
