/**
 * https://thenounproject.com/search/?q=return%20arrow%20bottom&i=1227923
 * License CC
 */

import React from 'react'
import Icon from '../Icon.jsx'

export default class ArrowReturnBottom extends React.Component {
  static defaultProps = {
    width: 24,
    height: 30,
  }

  static buttonColors = {
    iconFillClassName: 'black',
    iconFillHoverClassName: 'mainColor',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 24 30" enableBackground="new 0 0 24 24">
        <g>
          <path d="M12.118,23.89c-0.133,0-0.265-0.041-0.378-0.122l-7.965-5.698c-0.292-0.209-0.359-0.614-0.15-0.906   c0.21-0.292,0.615-0.359,0.907-0.15l7.587,5.428l7.588-5.428c0.291-0.21,0.698-0.142,0.906,0.15   c0.209,0.292,0.142,0.697-0.15,0.906l-7.966,5.698C12.383,23.849,12.25,23.89,12.118,23.89z" />
          <g>
            <path d="M12.118,23.89c-0.359,0-0.65-0.291-0.65-0.65V4.848c0-1.895-1.542-3.437-3.438-3.437H3.916c-0.359,0-0.65-0.291-0.65-0.65    s0.291-0.65,0.65-0.65h4.115c2.612,0,4.738,2.125,4.738,4.737v18.391C12.769,23.599,12.477,23.89,12.118,23.89z" />
          </g>
        </g>
      </Icon>
    )
  }
}
