// @flow
import React, { useEffect, useMemo, useRef, useState } from 'react'
import reduce from 'lodash/reduce'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import api from 'app/core/api/api.js'
import { useSelector } from 'react-redux'
import { MUICheckbox } from 'app/components/Form/Checkbox/MUICheckbox.jsx'
import type { ID, PermissionType, User } from 'app/core/types'
import { Table } from 'app/components/Table'
import { CellText, CellCheckbox } from 'app/components/Table/Cells'
import resources from 'app/store/resources'
import { confirmDelete } from 'app/components/Modal'
import settings from 'app/core/settings'
import { getResources } from 'app/store/selectors'
import { ButtonMenu } from 'app/components/ButtonMenu/ButtonMenu.jsx'
import { MUIButton, Input } from 'app/components/Form'
import { userIsSuperAdmin } from './utils/userPermissions.js'

const tableId = 'table-groupPermissions'

const isDev = settings.env.NODE_ENV === 'development'

const permissionsKeys: Array<{ key: string, label: string }> = [
  { key: 'read', label: 'Read' },
  { key: 'create', label: 'Create' },
  { key: 'update', label: 'Update' },
  { key: 'delete', label: 'Delete' },
  { key: 'readOwn', label: 'Read Own' },
  { key: 'updateOwn', label: 'Update Own' },
  { key: 'deleteOwn', label: 'Delete Own' },
]

type Props = {|
  permissions: Array<PermissionType>,
  actionPermissionId: ID,
  isGlobal: boolean,
  category: string,
  subCategory: string,
  actionGroup: string,
  action: string,
  user: User,
|}

type State = {|
  updateButton: boolean,
  removeButton: boolean,
  createButton: boolean,
  permissions: Array<PermissionType>,
  isGlobal: boolean,
  category: string,
  subCategory: string,
  actionGroup: string,
  action: string,
|}

export function PermissionPanelEditPermModal(props: Props): React$Node {
  const {
    user,
    permissions: _permissions,
    actionPermissionId,
    isGlobal: _isGlobal,
    category: _category,
    subCategory: _subCategory,
    actionGroup: _actionGroup,
    action: _action,
  } = props

  const [endpoints, setEndpoints] = useState([])

  const [search, _setSearch] = useState<string>('')
  const setSearch = debounce(_setSearch, 500)

  const [state, _setState] = useState<State>({
    permissions: _permissions,
    isGlobal: _isGlobal || false,
    category: _category || '',
    subCategory: _subCategory || '',
    actionGroup: _actionGroup || '',
    action: _action || '',
    updateButton: false,
    removeButton: false,
    createButton: false,
  })
  const setState = (entry: $Shape<State>) => _setState((_state) => ({ ..._state, ...entry }))

  const mounted = useRef(true)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    api.endPoints().then((endpoints) => setEndpoints(endpoints))
  }, [])

  function onChangeInput(type: 'category' | 'subCategory' | 'actionGroup' | 'action') {
    return (event) => {
      // $FlowFixMe[invalid-computed-prop] $FlowFixMe Error when updating flow
      setState({ [type]: event.target.value })
    }
  }

  function updateActionPermission(
    action: 'update' | 'create' | 'delete',
    data:
      | {|
          id?: ?ID,
          permissions?: Array<PermissionType>,
          isGlobal?: boolean,
          category?: string,
          subCategory?: string,
          actionGroup?: string,
          action?: string,
        |}
      | ID,
  ) {
    // $FlowFixMe
    return resources.actionPermissions[action](data).then((res) => {
      setState({ updateButton: false, removeButton: false, createButton: false })
    })
  }

  function actionPermissionForm() {
    const { updateButton, removeButton, createButton, isGlobal, category, subCategory, actionGroup, action } = state

    return (
      <div className="flex column padding10">
        <div className="grid col2">
          <div className="flex alignCenter bold">isGlobal</div>
          <MUICheckbox checked={isGlobal} onChange={() => setState({ isGlobal: !state.isGlobal })} />
          <div className="flex alignCenter bold">category:</div>
          <div className="cell">
            <Input value={category} onChange={onChangeInput('category')} dataCy="category" />
          </div>
          <div className="flex alignCenter bold">subCategory:</div>
          <div className="cell">
            <Input value={subCategory} onChange={onChangeInput('subCategory')} dataCy="subCategory" />
          </div>
          <div className="flex alignCenter bold">actionGroup:</div>
          <div className="cell">
            <Input value={actionGroup} onChange={onChangeInput('actionGroup')} dataCy="actionGroup" />
          </div>
          <div className="flex alignCenter bold">action:</div>
          <div className="cell">
            <Input value={action} onChange={onChangeInput('action')} dataCy="action" />
          </div>
        </div>
        <div className="fullWidth flex row wrap end marginTop10">
          <div className="marginRight10">
            <MUIButton
              color="primary"
              onClick={async () => {
                setState({ updateButton: true })
                const from = `${props.category}_${props.subCategory}_${props.actionGroup}_${props.action}`
                const to = `${category}_${subCategory}_${actionGroup}_${action}`

                await updateActionPermission('update', {
                  id: actionPermissionId,
                  isGlobal,
                  category,
                  subCategory,
                  actionGroup,
                  action,
                })
                // eslint-disable-next-line no-alert
                window.alert(`Replace "${from}" by "${to}" in overmind codebase.`)
              }}
              loader={updateButton}
            >
              Update
            </MUIButton>
          </div>
          <div className="marginRight10">
            <MUIButton
              color="primary"
              onClick={async () => {
                setState({ removeButton: true })

                await confirmDelete({
                  render: 'Are you sure you want to delete this action permission ?',
                  onValidate: () => updateActionPermission('delete', actionPermissionId),
                  onCancel: () => {
                    setState({ removeButton: false })
                    return Promise.resolve()
                  },
                })
              }}
              loader={removeButton}
            >
              Remove
            </MUIButton>
          </div>
          <div className="marginRight10">
            <MUIButton
              color="primary"
              onClick={async () => {
                setState({ createButton: true })
                await updateActionPermission('create', {
                  isGlobal,
                  category,
                  subCategory,
                  actionGroup,
                  action,
                })
              }}
              loader={createButton}
            >
              Create
            </MUIButton>
          </div>
        </div>
      </div>
    )
  }

  function hasPerm(groupPermission) {
    if (
      groupPermission.create === true ||
      groupPermission.delete === true ||
      groupPermission.read === true ||
      groupPermission.update === true ||
      groupPermission.readOwn === true ||
      groupPermission.deleteOwn === true ||
      groupPermission.updateOwn === true
    ) {
      return true
    }
    return false
  }

  const columns = useMemo(() => {
    return [
      {
        id: 'permissions',
        Header: ' ',
        columns: [
          CellText({
            id: 'endPoint',
            Header: 'End point',
            accessor: 'endpoint',
            readOnly: true,
            width: 250,
            containerStyle: {
              style: { alignSelf: 'center', paddingLeft: 10 },
            },
          }),
          ...permissionsKeys
            .sort((a, b) => {
              const permsValues = {
                createPermission: 0,
                readPermission: 1,
                updatePermission: 2,
                deletePermission: 3,
                readOwnPermission: 4,
                updateOwnPermission: 5,
                deleteOwnPermission: 6,
              }
              return permsValues[a.key] - permsValues[b.key]
            })
            .map((permType, index) =>
              CellCheckbox({
                id: permType.key,
                Header: permType.label,
                accessor: permType.key,
                width: 75,
                alwaysBeingEdit: true,
                actions: () => ['edit'],
                readOnly: false,
                save: {
                  resource: 'actionPermissions',
                  saveData: (item, value, cell, instance, meta) => {
                    const { data = [] } = instance.getLastestInstance?.() || instance
                    function removeFalse(perm) {
                      return reduce(
                        perm,
                        (acc, value, key: string) => (!value || key === 'id' ? acc : { ...acc, [key]: value }),
                        {},
                      )
                    }

                    const { endpoint } = item
                    const permission = data.find((perm) => perm.endpoint === endpoint) || { endpoint }

                    const checked = Boolean(value)
                    if (checked === false) delete permission[permType.key]
                    else permission[permType.key] = true

                    const newPermissions = filter(data, hasPerm)

                    const finalPermissions = newPermissions.reduce((acc, perm) => {
                      if (perm.endpoint === permission.endpoint) {
                        const cleanedPerm = removeFalse(permission)
                        if (hasPerm(cleanedPerm)) acc.push(cleanedPerm)
                      } else {
                        const cleanedPerm = removeFalse(perm)
                        if (hasPerm(cleanedPerm)) acc.push(cleanedPerm)
                      }
                      return acc
                    }, [])

                    return resources.actionPermissions[actionPermissionId ? 'update' : 'create']({
                      id: actionPermissionId,
                      permissions: finalPermissions,
                    })
                  },
                },
              }),
            ),
        ],
      },
    ]
  }, [])

  const data = useSelector((state) => {
    const permissions: Array<PermissionType> = actionPermissionId
      ? getResources(state, 'actionPermissions', actionPermissionId)?.permissions || []
      : []

    return endpoints
      ?.map((endpoint) => {
        const permission = find(permissions, (groupPermission) => groupPermission.endpoint === endpoint)
        if (permission) {
          return {
            ...permission,
            name: permission.endpoint,
            id: endpoint,
          }
        }

        return { name: endpoint, endpoint, id: endpoint }
      })
      .sort((a, b) => {
        const aHasPerm = hasPerm(a)
        const bHasPerm = hasPerm(b)
        if (aHasPerm && !bHasPerm) return -1
        if (aHasPerm && bHasPerm) return 0
        return 1
      })
  })

  useEffect(() => {
    if (actionPermissionId) {
      resources.actionPermissionGroups.getPermissionsByActionPermission(actionPermissionId)
    }
  }, [actionPermissionId])

  return (
    <Table
      tableId={tableId}
      columns={columns}
      extendToolbar={
        <div className="fullWidth flex row spaceBetween">
          <div style={{ width: 200 }}>
            <Input
              dataCy="groupPermission-input"
              onChange={(e, value) => setSearch(value ? String(value) : '')}
              adornmentIcon="fas-search"
              placeholder="Search an endpoint"
            />
          </div>
          <div>
            {isDev || userIsSuperAdmin(user.username) ? (
              <ButtonMenu label="Edit action permission">{actionPermissionForm()}</ButtonMenu>
            ) : null}
          </div>
        </div>
      }
      localFilter={(item) => item.name.toLowerCase().includes(search.toLowerCase())}
      customData={data}
    />
  )
}
