var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useRef, useState } from 'react';
import { MUIModal } from 'app/components/Modal';
import Webcam from 'react-webcam';
import classes from './WebcamModal.module.scss';
import { MUIButton } from 'app/components/Form';
const WebcamModal = (props) => {
    const { onSave, title, defaultMedia, personId } = props, rest = __rest(props, ["onSave", "title", "defaultMedia", "personId"]);
    const [files, setFiles] = useState();
    const webcamRef = useRef(null);
    function dataUrlToFile(dataUrl, fileName) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(dataUrl);
            const blob = yield res.blob();
            const url = window.URL.createObjectURL(blob);
            const imgFile = new File([blob], fileName, { type: 'image/jpeg' });
            imgFile.url = url;
            imgFile.path = `webcam-picture-${personId}.jpeg`;
            return imgFile;
        });
    }
    const capture = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const imageSrc = (_a = webcamRef === null || webcamRef === void 0 ? void 0 : webcamRef.current) === null || _a === void 0 ? void 0 : _a.getScreenshot();
        if (imageSrc) {
            const file = yield dataUrlToFile(imageSrc, `webcam-picture-${personId}.jpeg`);
            setFiles(file);
        }
    });
    const retake = () => {
        setFiles(null);
    };
    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: 'user',
    };
    function _onValidate() {
        return onSave([files]);
    }
    return (_jsx(MUIModal, Object.assign({ title: title, onValidate: _onValidate, height: 650, width: 950, resizable: true, draggable: true, validateLabel: "Save" }, rest, { children: _jsxs("div", { className: classes.modalContainer, children: [_jsxs("div", { className: classes.pictureContainer, children: [!files ? (_jsx(Webcam, { audio: false, height: 500, ref: webcamRef, screenshotFormat: "image/jpeg", width: 900, videoConstraints: videoConstraints })) : (_jsx("img", { src: files.url, alt: "webcam", className: classes.picture })), _jsx("div", { className: classes.leftPictureOverlay }), _jsx("div", { className: classes.rightPictureOverlay })] }), _jsx("div", { children: files ? (_jsx(MUIButton, { onClick: retake, children: "Reprendre la photo" })) : (_jsx("div", { className: classes.buttonContainer, children: _jsx("button", { onClick: capture, className: classes.takeButton }) })) })] }) })));
};
export default WebcamModal;
