// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetDetail-Discussions-___Discussions-module__linkActive {
  background-color: var(--colors-mainColorLight);
  font-weight: bold;
}

.app-containers-Assets-AssetDetail-Discussions-___Discussions-module__itemContainer {
  background-color: rgba(250, 250, 250);
}

.app-containers-Assets-AssetDetail-Discussions-___Discussions-module__itemContainer:last-child {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetDetail/Discussions/Discussions.module.scss"],"names":[],"mappings":"AAAA;EACE,8CAA8C;EAC9C,iBAAiB;AACnB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".linkActive {\n  background-color: var(--colors-mainColorLight);\n  font-weight: bold;\n}\n\n.itemContainer {\n  background-color: rgba(250, 250, 250);\n}\n\n.itemContainer:last-child {\n  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkActive": `app-containers-Assets-AssetDetail-Discussions-___Discussions-module__linkActive`,
	"itemContainer": `app-containers-Assets-AssetDetail-Discussions-___Discussions-module__itemContainer`
};
export default ___CSS_LOADER_EXPORT___;
