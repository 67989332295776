import React from 'react'
import PropTypes from 'prop-types'
import cookies from 'js-cookie'
import { bindActionCreators } from 'redux'
import pipe from 'app/core/utils/pipe'
import resources from 'app/store/resources'
import api from 'app/core/api/api.js'
import settings from 'app/core/settings'
import { Logo } from 'app/components/Icons'
import { MUIButton, Input, Validators } from 'app/components/Form'
import { oldNotif } from 'app/components/Notifications/notify'
import queryString from 'query-string'
import * as userActions from 'app/store/actions/user'
import history from 'app/main/routerHistory'
import classes from './login.module.scss'

class Login extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    actions: PropTypes.object,
  }

  constructor(props) {
    super(props)

    const hasGetToken = !!settings.env.getToken

    this.state = {
      isLoading: hasGetToken,
      processingGetToken: hasGetToken,
      username: '',
      password: '',
      serverErrors: {},
    }
  }

  componentDidMount() {
    resources.messages.findPublicMessages()

    if (settings.env.getToken) {
      settings.env
        .getToken()
        .then((token) => {
          if (typeof token !== 'string') return Promise.reject()
          this.saveTokenToCookies(token)
          return this.props.actions.fetchUser()
        })
        .then(() => this.redirectAfterLogin())
        .catch(() => {
          this.setState({ processingGetToken: false, isLoading: false })
        })
    }
  }

  saveTokenToCookies(token) {
    cookies.set(settings.cookieToken, token, {
      expires: 10,
      domain: settings.env.AUTH_COOKIE_DOMAIN || null,
    })
  }

  redirectAfterLogin() {
    const locationQueryParsed = queryString.parse(this.props.location.search)
    const url = (locationQueryParsed && locationQueryParsed.redirect) || '/'

    history.push(url)
  }

  login = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!(await Validators.validAll([this.refs.username, this.refs.password]))) return

    this.setState({ isLoading: true })

    api
      .login({ username: this.state.username, password: this.state.password })
      .then((success) => {
        this.setState({ isLoading: false })
        if (success.key) {
          this.saveTokenToCookies(success.key)
          this.redirectAfterLogin()
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false })

        if (err?.serverError && err?.serverError.status === 400) {
          const infos = err?.serverError.infos.non_field_errors
          if (infos) {
            oldNotif({
              error: infos.join('\n'),
            })
          }

          this.setState({ serverErrors: err?.serverError.infos })
        }
      })

    this.setState({ password: '' })
  }

  render() {
    const { processingGetToken, isLoading, username, password, serverErrors } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.project}>
          <Logo width={70} height={70} className={isLoading ? classes.animation : null} />

          <div className={classes.name}>
            <span className={classes.nameStart}>Over</span>
            <span>mind</span>
          </div>
        </div>

        {!processingGetToken && (
          <form className={classes.form} onSubmit={this.login}>
            <div className="flex column alignEnd">
              <Input
                ref="username"
                dataCy="username"
                serverErrors={serverErrors.username}
                placeholder="username"
                value={username}
                onChange={(e) => this.setState({ username: e.target.value })}
                isRequired={true}
                validatorStyle={{ top: 6, right: -100 }}
                style={{ marginBottom: 10 }}
              />

              <Input
                ref="password"
                dataCy="password"
                type="password"
                value={password}
                serverErrors={serverErrors.password}
                placeholder="password"
                onChange={(e) => this.setState({ password: e.target.value })}
                isRequired={true}
                validatorStyle={{ top: 6, right: -100 }}
                style={{ marginBottom: 20 }}
              />

              <MUIButton type="submit" className={classes.loginBtn} loader={isLoading} data-cy="submit">
                Login
              </MUIButton>
            </div>
          </form>
        )}
      </div>
    )
  }
}

export default pipe()
  .connect(null, (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
  }))
  .render(Login)
