var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import ResizeObserver from 'react-resize-detector';
import { last, map, sortBy, values, isEqualWith } from 'lodash';
import { WikiEditorButton } from 'app/containers/Wiki/WikiEditor/WikiEditorButtons.jsx';
import pipe from 'app/core/utils/pipe';
import resources from 'app/store/resources';
import { Input, Flags } from 'app/components/Form';
import history from 'app/main/routerHistory';
import { Breadcrumb } from 'app/components/Breadcrumb/Breadcrumb.jsx';
import { dateToLocaleStringFull } from 'app/libs/helpers';
import onLeaveRoute from 'app/core/utils/onLeaveRoute';
import { ButtonMenu } from 'app/components/ButtonMenu/ButtonMenu.jsx';
import MediasRelation from 'app/containers/Medias/MediasRelation/MediasRelation.jsx';
import SubscribeButton from 'app/containers/Subscription/SubscribeButton.jsx';
import { getArticleMedias } from 'app/store/selectors/getArticleMedias';
import deleteMediasFromHTML from 'app/core/utils/deleteMediasFromHTML';
import TextRead from 'app/components/TextEditor/TextRead.jsx';
import { Editor } from 'app/components/TextEditor/Editor.jsx';
import { confirm, confirmDelete } from 'app/components/Modal';
import { getArticles } from 'app/store/selectors/getArticles';
import { getArticleContents } from 'app/store/selectors/getArticleContents';
import { getResources } from 'app/store/selectors/getResources';
import { permission, Permission } from 'app/containers/Permissions/Permission.jsx';
import { success, error } from 'app/components/Notifications/notify';
import copy from 'copy-to-clipboard';
import { keyMapper } from 'app/components/Table/utils.js';
import readableClasses from './printable.module.scss';
import SelectArticles from '../SelectArticles.jsx';
import classes from './WikiEditor.module.scss';
import modalWantToDelete from './modalWantToDelete.jsx';
import History from './History.jsx';
import { EditorFullScreen } from './EditorFullScreen.jsx';
import { userPrefLocalResource } from '../../../core/utils/localUserPrefs';
import { useSelector } from 'react-redux';
const MODE = { READ_ONLY: 'READ_ONLY', PREVIEW: 'PREVIEW', EDITION: 'EDITION' };
export const WikiEditorView = (props) => {
    var _a, _b, _c, _d, _e;
    const [mode, setmode] = useState(MODE.READ_ONLY);
    const [title, setTitle] = useState(props.article ? props.article.title : '');
    const [parent, setParent] = useState(props.article ? props.article.parent : '');
    const [fullscreen, setFullscreen] = useState(new URLSearchParams(props.location.search).get('fullscreen') === 'true');
    const [currentText, setCurrentText] = useState(props.articleContent ? ((_a = props.articleContent) === null || _a === void 0 ? void 0 : _a.content) || '' : '');
    const [dataHasChanged, setDataHasChanged] = useState(false);
    const [isSavingMedias, setIsSavingMedias] = useState(false);
    const [diffValue, setDiffValue] = useState(null);
    const [flagsList, setFlagsList] = useState([]);
    const [defaultFlags, setDefaultFlags] = useState([]);
    const previousFlags = useSelector((state) => state.articleFlags);
    // Check for flags changes
    useEffect(() => {
        const previousFlagsIds = values(previousFlags.resources)
            .map((el) => el.flag)
            .sort();
        const newFlagsIds = flagsList.map((el) => el.flag).sort();
        if (!isEqualWith(previousFlagsIds, newFlagsIds)) {
            setDataHasChanged(true);
        }
        else {
            setDataHasChanged(false);
        }
    }, [previousFlags, flagsList]);
    useEffect(() => {
        setFlagsList(defaultFlags);
    }, [defaultFlags]);
    const editor = useRef();
    let printableZoneEl = useRef();
    let isMounted = true;
    let unblock = undefined;
    let printButtonEl;
    const toggleFullScreen = (open) => {
        setFullscreen(open);
    };
    const onCopyTextToClipboard = () => {
        const { article, asset } = props;
        copy(`${window.location.origin}/assets-wiki/${asset.id}/${article.id}`);
        success('Copy to clipboard');
    };
    const switchToReadOnly = () => {
        isMounted && setmode(MODE.READ_ONLY);
    };
    const cancel = () => {
        return new Promise((resolve, reject) => {
            var _a, _b;
            const { articleContent } = props;
            setFlagsList(dataHasChanged ? defaultFlags : flagsList);
            clearOlderCachedVersions();
            if (!articleContent)
                reject();
            const text = (_b = (_a = editor.current) === null || _a === void 0 ? void 0 : _a.getData) === null || _b === void 0 ? void 0 : _b.call(_a);
            if (text !== currentText) {
                confirm({
                    title: 'Cancel changes',
                    validateLabel: 'Save',
                    cancelLabel: 'Cancel',
                    render: 'Several changes have been made to the article. Are you sure you want to cancel them?',
                    onCancel: () => {
                        if (isMounted) {
                            setCurrentText(articleContent === null || articleContent === void 0 ? void 0 : articleContent.content);
                            switchToReadOnly();
                        }
                        resolve();
                        return Promise.resolve();
                    },
                    onValidate: () => save(text)
                        .then((res) => {
                        switchToReadOnly();
                        resolve();
                        return Promise.resolve();
                    })
                        .catch((err) => {
                        switchToReadOnly();
                        reject(err);
                        return Promise.reject(err);
                    }),
                });
            }
            else if (diffValue) {
                confirmDelete({
                    render: 'Are you sure you want to delete your modifications ?',
                    title: 'Cancel article changes',
                    onValidate: () => {
                        setCurrentText(articleContent === null || articleContent === void 0 ? void 0 : articleContent.content);
                        setDiffValue(null);
                        isMounted && switchToReadOnly();
                        resolve();
                        return Promise.resolve();
                    },
                });
            }
            else {
                switchToReadOnly();
                resolve();
            }
        });
    };
    const focusEditor = (delay = 500) => {
        if (!isMounted)
            return;
        setTimeout(() => {
            var _a, _b;
            const currentEditor = editor.current;
            if ((_b = (_a = currentEditor === null || currentEditor === void 0 ? void 0 : currentEditor.editing) === null || _a === void 0 ? void 0 : _a.view) === null || _b === void 0 ? void 0 : _b.focus)
                currentEditor.editing.view.focus();
        }, delay);
    };
    const switchToEdition = () => {
        if (permission(['wiki__articles_update', 'wiki__articles_create', 'wiki__articles_delete'], 'or')) {
            isMounted && setmode(MODE.EDITION);
            focusEditor(500);
        }
    };
    const clearOlderCachedVersions = () => {
        userPrefLocalResource.setData('editor_current_edition', null);
    };
    const articleWasUpdatedDuringEdit = () => {
        const { article } = props;
        error('A more recent version of the article has been registered on the server.', {
            autoHideDuration: 15000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        return resources.articleContents.fetchContent(article === null || article === void 0 ? void 0 : article.id).then((res) => {
            var _a, _b;
            if (!res || !res.resources || !res.resources[0])
                return;
            const currentArticle = (_b = (_a = editor.current) === null || _a === void 0 ? void 0 : _a.getData) === null || _b === void 0 ? void 0 : _b.call(_a);
            const newArticle = res.resources[0].content;
            setDiffValue(currentArticle);
            setCurrentText(newArticle);
            editor.current.setData(newArticle);
        });
    };
    const save = (value_1, ...args_1) => __awaiter(void 0, [value_1, ...args_1], void 0, function* (value, editorInstance = editor) {
        var _a, _b;
        const { article, articleContent } = props;
        const { flagsInst: previousFlagsIds } = article;
        const flagsListIds = flagsList.map((flag) => flag.flag);
        const flagsToDeleteIds = previousFlagsIds.filter((obj) => flagsListIds.indexOf(obj) !== -1);
        const flagsToCreate = flagsList.filter((obj) => previousFlagsIds.indexOf(obj.flag) === -1);
        const flagsToDelete = values(previousFlags.resources)
            .filter((flag) => !flagsToDeleteIds.includes(flag.flag))
            .map((flag) => flag.id);
        if (!editorInstance.current || mode !== MODE.EDITION)
            return Promise.resolve();
        const text = value !== undefined ? value : (_b = (_a = editorInstance.current) === null || _a === void 0 ? void 0 : _a.getData) === null || _b === void 0 ? void 0 : _b.call(_a);
        const promises = [];
        isMounted && setIsSavingMedias(true);
        promises.push(flagsToDelete.map((el) => Promise.resolve(resources['articleFlags'].delete(el))));
        promises.push(flagsToCreate.map((el) => Promise.resolve(resources['articleFlags'].create(el))));
        if (text !== (articleContent === null || articleContent === void 0 ? void 0 : articleContent.content)) {
            promises.push(deleteMediasFromHTML(text, articleContent.content));
            promises.push(resources.articleContents
                .create({
                article: article === null || article === void 0 ? void 0 : article.id,
                content: text,
                lastCreatedDate: articleContent.createdAt,
            })
                .then((res) => {
                setDiffValue(null);
                setCurrentText(text);
                return res;
            })
                .catch((err) => {
                if (err && (err === null || err === void 0 ? void 0 : err.serverError) && (err === null || err === void 0 ? void 0 : err.serverError.status) === 409) {
                    articleWasUpdatedDuringEdit();
                }
                else if (err && (err === null || err === void 0 ? void 0 : err.serverError) && (err === null || err === void 0 ? void 0 : err.serverError.infos) && (err === null || err === void 0 ? void 0 : err.serverError.infos.content)) {
                    error(err === null || err === void 0 ? void 0 : err.serverError.infos.content[0]);
                }
                isMounted && setIsSavingMedias(false);
                return err;
            }));
        }
        if ((article === null || article === void 0 ? void 0 : article.title) !== title || (article === null || article === void 0 ? void 0 : article.parent) !== parent) {
            promises.push(resources.articles.update({
                id: article === null || article === void 0 ? void 0 : article.id,
                title,
                parent,
            }));
        }
        try {
            const res = yield Promise.all(promises);
            if (isMounted) {
                setIsSavingMedias(false);
                setDataHasChanged(false);
                setCurrentText(text);
            }
            clearOlderCachedVersions();
            success('Wiki edited');
            return res;
        }
        catch (err) {
            isMounted && setIsSavingMedias(false);
            return err;
        }
    });
    const onKeyDown = (event) => {
        if (!permission(['wiki__articles_update', 'wiki__articles_create', 'wiki__articles_delete'], 'or'))
            return;
        const authorizedKey = 'ctrl+p,command+p,ctrl+s,command+s,ctrl+e,command+e,ctrl+enter,command+enter';
        const keyName = keyMapper(event);
        if (!authorizedKey.split(',').includes(keyName))
            return;
        if (keyName === 'ctrl+p' || keyName === 'command+p')
            printButtonEl.click();
        if (keyName === 'ctrl+s' || keyName === 'command+s') {
            if (mode === MODE.EDITION)
                save();
        }
        if (keyName === 'ctrl+e' || keyName === 'command+e') {
            if (mode !== MODE.EDITION)
                switchToEdition();
            else
                cancel();
        }
        if ((keyName === 'ctrl+enter' || keyName === 'command+enter') && mode !== MODE.EDITION) {
            toggleFullScreen(!fullscreen);
        }
        event.preventDefault();
        event.stopPropagation();
    };
    const onClickBreadcrumbItem = (item) => {
        history.push(`/assets-wiki/${item.id}`);
    };
    const switchToPreview = () => {
        var _a, _b;
        if (isMounted) {
            setCurrentText((_b = (_a = editor.current) === null || _a === void 0 ? void 0 : _a.getData) === null || _b === void 0 ? void 0 : _b.call(_a));
            setmode(MODE.PREVIEW);
        }
    };
    const verifyOlderCachedVersions = () => {
        var _a, _b;
        if (!editor.current)
            return;
        const savedEdition = userPrefLocalResource.getData('editor_current_edition');
        if (((_a = savedEdition === null || savedEdition === void 0 ? void 0 : savedEdition.article) === null || _a === void 0 ? void 0 : _a.id) && savedEdition.article.id === ((_b = props.article) === null || _b === void 0 ? void 0 : _b.id)) {
            if (savedEdition.text) {
                confirm({
                    title: 'Older version in cache',
                    render: "A version of the article was saved in the browser's cache and it seems that it is different from the current version of the article. Would you like to copy the data into the editor?",
                    validateLabel: 'Yes',
                    cancelLabel: 'No',
                    disableEnforceFocus: false,
                    draggable: true,
                    onValidate: () => {
                        var _a;
                        (_a = editor.current) === null || _a === void 0 ? void 0 : _a.setData(savedEdition.text);
                        return Promise.resolve();
                    },
                    onCancel: () => {
                        clearOlderCachedVersions();
                        return Promise.resolve();
                    },
                });
            }
        }
    };
    const getMediasUploaded = (file) => {
        const { article } = props;
        return resources.articleMedias.create({
            article: article.id,
            media: file.uuid,
        });
    };
    const onChange = (value, editorInstance) => {
        const { article, articleContent } = props;
        userPrefLocalResource.setData('editor_current_edition', { article, articleContent, text: value });
        setDataHasChanged(value !== articleContent.content);
    };
    const onChangeField = (event) => {
        const { value } = event.target;
        setTitle(value);
        setDataHasChanged(true);
    };
    useEffect(() => {
        unblock = onLeaveRoute({
            askRequired: () => {
                var _a, _b;
                const { articleContent, article } = props;
                if (mode === MODE.EDITION) {
                    if (title !== article.title) {
                        return true;
                    }
                    if (currentText !== articleContent.content) {
                        return true;
                    }
                    const editiorValue = (_b = (_a = editor.current) === null || _a === void 0 ? void 0 : _a.getData) === null || _b === void 0 ? void 0 : _b.call(_a);
                    if (articleContent.content !== editiorValue) {
                        return true;
                    }
                }
                return false;
            },
        });
        window.addEventListener('keydown', onKeyDown);
    }, []);
    const deleteFn = () => __awaiter(void 0, void 0, void 0, function* () {
        const { asset, articleMedias, articleContent, article } = props;
        if (!articleContent || !article)
            return Promise.reject();
        const promiseDeleteMedias = deleteMediasFromHTML('', articleContent.content);
        const promiseDeleteMediasOfRelation = resources.medias.delete(map(articleMedias, ({ mediaInst }) => mediaInst.id));
        const promiseDeleteArticlesMedias = resources.articleMedias.delete(map(articleMedias, ({ id }) => id));
        const promiseDeleteArticle = resources.articles.delete(article.id);
        const res = yield Promise.all([
            promiseDeleteMedias,
            promiseDeleteMediasOfRelation,
            promiseDeleteArticlesMedias,
            promiseDeleteArticle,
        ]);
        history.push(`/assets-wiki/${asset.id}`);
        clearOlderCachedVersions();
        success('Wiki deleted');
    });
    const wantDelete = () => {
        const { articles, article, user } = props;
        const articleHasChilds = !!(articles === null || articles === void 0 ? void 0 : articles.find(({ parent }) => parent === (article === null || article === void 0 ? void 0 : article.id)));
        modalWantToDelete({
            userId: user.asset,
            article,
            articleHasChilds,
            onValidate: deleteFn,
        });
    };
    // use to prevent media deletion if it's include into wiki content
    const canDeleteMedia = (media) => !currentText.includes(media.url);
    const removeWrongThumbnailUrl = (url) => (url === null || url === void 0 ? void 0 : url.startsWith('http://intranet')) ? null : url;
    const header = () => {
        var _a;
        const { articleContent, article, articles = [], assetBreadcrumProps, asset, hideBreadcrumb } = props;
        const { authorInst: createAuthor } = article;
        const { authorInst: updateAuthor } = articleContent;
        const authorThumbnailUrl = removeWrongThumbnailUrl((_a = createAuthor === null || createAuthor === void 0 ? void 0 : createAuthor.thumbnailInst) === null || _a === void 0 ? void 0 : _a.url);
        return (_jsx(ResizeObserver, { handleWidth: true, children: ({ width }) => (_jsxs("div", { className: classes.header, children: [!hideBreadcrumb ? (_jsx("div", { className: "flex row", children: _jsx(Breadcrumb, Object.assign({ asset: asset, onClick: onClickBreadcrumbItem }, assetBreadcrumProps)) })) : null, _jsx("div", { style: { position: 'absolute', top: 0, right: 0 }, children: _jsx(Permission, { actions: ['notifications___read'], children: _jsx(SubscribeButton, { tooltip: "Subscribe to this article", target: article, targetType: "article" }) }) }), _jsxs("div", { className: classes.headerRightContainer, children: [mode === MODE.EDITION ? (_jsxs("div", { className: "flex row", children: [_jsxs("div", { className: "marginRight10 flex row center alignCenter", children: [_jsx("div", { className: "marginRight10 fontSize16 bold", children: "Name" }), _jsx(Input, { name: "title", value: title, onChange: onChangeField, dataCy: "wiki-editor-title" })] }), _jsxs("div", { className: "flex row center alignCenter", children: [_jsx("div", { className: "marginRight10 fontSize16 bold", children: "Parent" }), _jsx(SelectArticles, { articles: articles, onChange: (val) => isMounted && setParent(val.value), value: parent || undefined, excludeId: article.id })] })] })) : (_jsx("div", { className: classes.title, children: title })), !articleContent.empty && (_jsxs("div", { className: "flex row alignCenter textNoWrap fontSize12", children: ["Created by", _jsxs("span", { className: `${classes.authorName} flex row alignCenter`, children: [authorThumbnailUrl ? (_jsx("div", { style: {
                                                    marginLeft: 5,
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: '50%',
                                                    background: `url(${authorThumbnailUrl}) center center / cover no-repeat`,
                                                } })) : null, "\u00A0", _jsx("span", { children: createAuthor === null || createAuthor === void 0 ? void 0 : createAuthor.name })] }), ",\u00A0", _jsx("span", { className: classes.metadataDate, children: dateToLocaleStringFull(new Date(article.createdAt)) }), ". Updated by", _jsxs("span", { className: `${classes.authorName} flex row alignCenter`, children: [authorThumbnailUrl ? (_jsx("div", { style: {
                                                    marginLeft: 5,
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: '50%',
                                                    background: `url(${authorThumbnailUrl}) center center / cover no-repeat`,
                                                } })) : null, "\u00A0", _jsx("span", { children: updateAuthor === null || updateAuthor === void 0 ? void 0 : updateAuthor.name })] }), ",\u00A0", _jsx("span", { className: classes.metadataDate, children: dateToLocaleStringFull(new Date(articleContent.createdAt || '')) }), "."] })), _jsx("div", { className: classes.flags, children: _jsx(Flags, { resourceType: "article", itemCategory: "article", id: article.id, isBeingEdit: mode === MODE.EDITION, setDefaultFlags: setDefaultFlags, flagsList: flagsList, title: "Flags", onChange: (flags) => {
                                        setFlagsList(flags);
                                    } }) }), _jsx("div", { className: classes.menuButtons, children: editToolbar(width) })] })] })) }));
    };
    const editToolbar = (width) => {
        const { article, asset, enableEdit = false, articleVersion, relationNumbers } = props;
        const smallFormat = width ? width < 700 : true;
        if (articleVersion) {
            return (_jsx(WikiEditorButton, { to: `/assets-wiki/${asset.id}/${article.id}`, icon: "fas-arrow-left", children: "Back to the latest version" }));
        }
        function tooltipOrLabel(label, smallFormat, keycode) {
            const formatedKeycode = keycode && (_jsx("span", { children: keycode.split('+').map((str, i) => i !== 0 ? (_jsxs(Fragment, { children: [' ', "+ ", _jsx("kbd", { children: str })] }, String(i))) : (_jsx("kbd", { children: str }, String(i)))) }));
            if (!smallFormat) {
                return {
                    tooltip: formatedKeycode,
                    label,
                };
            }
            return {
                tooltip: (_jsxs("span", { className: "flex column alignCenter", children: [_jsx("span", { children: label }), formatedKeycode ? _jsx("span", { className: "capitalize", children: formatedKeycode }) : null] })),
            };
        }
        const separator = (key) => (_jsx("div", { style: {
                display: 'inline-block',
                position: 'relative',
                height: 22,
                width: 1,
                backgroundColor: 'lightgrey',
                marginLeft: 5,
                top: 6,
            } }, `separator-${key}`));
        const attachments = (_jsx(ButtonMenu, Object.assign({ icon: "fas-paperclip" }, tooltipOrLabel(`Attachments (${relationNumbers})`, smallFormat), { component: WikiEditorButton, children: _jsx(MediasRelation, { canDeleteMedia: canDeleteMedia, articleId: article.id }) }), "attachments"));
        const history = (_jsx(ButtonMenu, Object.assign({ icon: "fas-history" }, tooltipOrLabel('History', smallFormat), { component: WikiEditorButton, children: _jsx(History, { article: article }) }), "history"));
        const btnAsset = (asset) => {
            return (_jsx(WikiEditorButton, Object.assign({ to: `?asset=/${asset.id}/informations&asset-rightPanel=true`, icon: "fas-external-link-alt" }, tooltipOrLabel('Show asset', smallFormat)), "btnAsset"));
        };
        const btnLink = (_jsx(WikiEditorButton, { onClick: onCopyTextToClipboard, icon: "fas-link", tooltip: "Copy url to the clipboard" }));
        const btnFullscreen = (_jsx(WikiEditorButton, Object.assign({ onClick: () => toggleFullScreen(true), icon: "fas-compress" }, tooltipOrLabel('Fullscreen', smallFormat, 'ctrl+Enter')), "btnFullscreen"));
        const reactToPrintPageStyle = `
      * {
        padding: 10px 5px
      }
    `;
        const print = (_jsx(ReactToPrint, { trigger: () => {
                const PrintButton = React.forwardRef((props, ref) => _jsx(WikiEditorButton, Object.assign({ buttonRef: ref }, props)));
                return (_jsx(PrintButton, { icon: "fas-print", tooltip: _jsxs("span", { className: "flex column alignCenter", children: [_jsx("span", { children: "Print article" }), _jsxs("span", { children: [_jsx("kbd", { children: "Ctrl" }), " + ", _jsx("kbd", { children: "P" })] })] }), target: "_blank", buttonRef: (ref) => {
                        printButtonEl = ref;
                    } }));
            }, content: () => printableZoneEl, bodyClass: readableClasses.printable, pageStyle: reactToPrintPageStyle }, "print"));
        const btnPreview = (_jsx(WikiEditorButton, Object.assign({ onClick: switchToPreview, icon: "fas-eye" }, tooltipOrLabel('Preview', smallFormat)), "btnPreview"));
        const btnEdit = (_jsx(WikiEditorButton, Object.assign({ onClick: switchToEdition, icon: "fas-pencil-alt" }, tooltipOrLabel('Edit', smallFormat, 'ctrl + e')), "btnEdit"));
        const btnEditBack = (_jsx(WikiEditorButton, Object.assign({ onClick: switchToEdition, icon: "fas-arrow-left" }, tooltipOrLabel('Back to edition', smallFormat, 'ctrl + e')), "btnEditBack"));
        const btnSave = (_jsx(WikiEditorButton, Object.assign({ bgColor: dataHasChanged ? '#48C27E' : undefined, loader: isSavingMedias, onClick: () => save(), icon: "fas-save" }, tooltipOrLabel('Save', smallFormat, 'ctrl + s')), "btnSave"));
        const btnCancel = (_jsx(WikiEditorButton, Object.assign({ bgColor: "#B3B9C7", onClick: cancel, icon: "fas-times-circle" }, tooltipOrLabel('Close', smallFormat, 'ctrl + E')), "btnCancel"));
        const deleteBtn = (_jsx(WikiEditorButton, Object.assign({ icon: "fas-trash", bgColor: "#E56D7A", onClick: wantDelete }, tooltipOrLabel('Delete article', smallFormat)), "deleteBtn"));
        let output = [btnAsset(asset)];
        if (enableEdit && permission(['wiki__articles_update'])) {
            switch (mode) {
                case MODE.EDITION:
                    output = output.concat([
                        separator('left'),
                        btnPreview,
                        btnSave,
                        permission(['wiki__articles_delete']) && deleteBtn,
                        btnCancel,
                    ]);
                    break;
                case MODE.PREVIEW:
                    output = output.concat([separator('left'), btnEditBack, btnSave, btnCancel]);
                    break;
                case MODE.READ_ONLY:
                    output = output.concat([separator('left'), btnEdit]);
                    break;
                default:
                    break;
            }
        }
        output = output.concat([
            separator('middle'),
            attachments,
            permission(['wiki__articles_history']) && history,
            separator('right'),
            btnLink,
            print,
            btnFullscreen,
        ]);
        return _jsx("div", { className: "marginRight15", children: output });
    };
    useEffect(() => {
        return () => {
            if (unblock)
                unblock();
            window.removeEventListener('keydown', onKeyDown);
            isMounted = false;
        };
    }, []);
    useEffect(() => {
        if (mode === MODE.EDITION) {
            verifyOlderCachedVersions();
        }
        setCurrentText(props.articleContent ? props.articleContent.content || '' : '');
    }, [mode, props.articleContent]);
    const { articleContent } = props;
    return (_jsxs("div", { className: classes.container, children: [header(), _jsx("div", { className: classes.content, children: fullscreen ? (_jsx(EditorFullScreen, { onInit: (editor) => {
                        var _a;
                        if (editor.current) {
                            const text = (_a = editor.current) === null || _a === void 0 ? void 0 : _a.getData();
                            setCurrentText(text);
                        }
                        editor === null || editor === void 0 ? void 0 : editor.editing.view.focus();
                        editor.current = editor;
                    }, isSaving: isSavingMedias, dataHasChanged: dataHasChanged, mode: mode, diffValue: diffValue, onSave: save, onClose: () => toggleFullScreen(false), onDelete: wantDelete, onEdit: switchToEdition, onLeaveEdit: cancel, onChange: onChange, value: ((_c = (_b = editor === null || editor === void 0 ? void 0 : editor.current) === null || _b === void 0 ? void 0 : _b.getData) === null || _c === void 0 ? void 0 : _c.call(_b)) || currentText, editor: editor, style: { height: '100%' }, getMediasUploaded: getMediasUploaded })) : mode === MODE.EDITION ? (_jsx(Editor, { onInit: (edit) => {
                        editor.current = edit;
                    }, onChange: (value, editorInstance) => {
                        onChange(value, editor);
                        setDataHasChanged(value !== articleContent.content);
                    }, diffValue: diffValue, value: ((_e = (_d = editor === null || editor === void 0 ? void 0 : editor.current) === null || _d === void 0 ? void 0 : _d.getData) === null || _e === void 0 ? void 0 : _e.call(_d)) || currentText, style: { height: '100%' }, getMediasUploaded: getMediasUploaded })) : !currentText ? (_jsx("div", { className: classes.emptyContent, children: "Content not set" })) : (_jsx(TextRead, { text: currentText, padding: 15, style: {
                        padding: '30px 5%',
                    }, getRef: (el) => {
                        printableZoneEl = el;
                    } })) })] }));
};
const pipeInst = pipe();
const Component = pipeInst
    .withRouter()
    .connect((state, props) => {
    const { assetId, articleId, articleVersion } = props;
    const article = state.articles.resources[articleId];
    const asset = map(getResources(state, 'assets', { id: assetId }))[0] || {};
    const defaultArticleContent = {
        article: articleId,
        content: '',
        empty: true,
        id: '',
        author: '',
        authorInst: undefined,
        createdAt: new Date(),
    };
    const articleContents = getArticleContents(state, articleId);
    let articleContent = defaultArticleContent;
    if (articleVersion)
        articleContent = articleContents[articleVersion];
    else {
        const lastArticleContent = last(sortBy(articleContents, ['version']));
        if (lastArticleContent)
            articleContent = lastArticleContent;
    }
    return {
        asset,
        article,
        articleContent,
        articles: map(getArticles(state, asset.id)),
        articleMedias: getResources(undefined, 'articleMedias', { article: articleId }, ['mediaInst']),
        editorKey: `${article && article.title}${articleContent && articleContent.id + (articleContent.content || '')}`,
        user: state.user,
        relationNumbers: map(getArticleMedias(state, props.articleId)).length,
    };
})
    .request((props) => {
    const { articleId, articleVersion, assetId } = props;
    return Promise.all([
        articleId ? resources.articleContents.fetchContent(articleId, articleVersion) : null,
        articleId ? resources.articles.fetchOne(articleId) : null,
        assetId ? resources.assets.fetchOne(assetId) : null,
    ]);
})
    .render((props) => (props.article ? _jsx(WikiEditorView, Object.assign({}, props)) : _jsx("div", {})));
export default Component;
