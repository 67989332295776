// @flow
import React, { useMemo } from 'react'
import type { Step, AssetTypes } from 'app/core/types'
import { assetsTypes } from 'app/core/constants'
import { NODE_DIRECTORY, NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'
import classes from './SettingsFollowUp.module.scss'
import type { SchemaColumnSettings } from '../../../../core/types/Schema'
import type { TrackingSchemaColumnSettings } from './utils'

type Props = {|
  steps: Array<Step>,
  assetType: AssetTypes,
|}

const styledLabel = (label, type) => {
  return (
    <span>
      {label} <span style={{ color: 'grey', fontSize: 11 }}>({type})</span>
    </span>
  )
}

type NoteNodeData = {|
  ...$Shape<TrackingSchemaColumnSettings>,
  step: Step,
|}

export function Notes(props: Props): React$Node {
  const { steps, assetType } = props

  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: steps.map((step: Step) => {
        return new Node<$Shape<SchemaColumnSettings<NoteNodeData>>>({
          key: step.name,
          name: styledLabel(step.name, `${assetsTypes[assetType]} production notes`.toLowerCase()),
          parent: rootNode,
          icon: 'fas-grip-vertical',
          data: {
            name: `${step.name} notes`,
            step,
            columnType: `${assetType ? assetType[0].toUpperCase() : ''}PN - ${step.name}`,
            type: 'postBoardNotes',
            attr: 'postBoardLinksInst',
          },
          type: NODE_ITEM,
        })
      }),
    })

    return rootNode
  }, [steps.length])

  return (
    <div className={classes.AttributesBlockContainer} style={{ padding: '0 10px 10px 10px' }}>
      <TreeDnD rootNode={rootNode} />
    </div>
  )
}
