// @flow
import * as React from 'react'
import PubSub from 'pubsub-js'
import uniqid from 'app/libs/uniqid.js'
import { openModalEventName } from './openModal'

type State = {
  modals: Array<{
    id: string,
    element: React.Element<any>,
  }>,
}

export class ModalInstances extends React.PureComponent<{}, State> {
  state: State = {
    modals: [],
  }

  componentDidMount() {
    PubSub.subscribe(openModalEventName, (msg, element) => {
      const modalId = uniqid()

      const onRequestClose = () => this.closeModal(modalId)

      const modal = {
        id: modalId,
        element: React.cloneElement(element, { key: modalId, onRequestClose }),
      }

      this.setState((state) => ({
        modals: [...state.modals, modal],
      }))
    })
  }

  closeModal(modalId: string) {
    this.setState((state) => ({
      modals: state.modals.filter(({ id }) => id !== modalId),
    }))
  }

  render(): React$Node {
    const { modals } = this.state
    return <>{modals.map(({ element }) => element)}</>
  }
}
