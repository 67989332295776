// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellLink-___CellLink-module__container {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: pre-wrap;
  text-align: center;
  text-overflow: ellipsis;
  z-index: 0;
  position: relative;
}

.app-components-Table-Cells-CellLink-___CellLink-module__label {
  font-weight: bolder;
  color: rgba(0, 0, 0, 0.7);
  overflow-wrap: anywhere;
}

.app-components-Table-Cells-CellLink-___CellLink-module__reduce {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.app-components-Table-Cells-CellLink-___CellLink-module__container:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.app-components-Table-Cells-CellLink-___CellLink-module__removedTag {
  color: #ffffff;
  background-color: red;
  padding: 0 10px;
  border-radius: 4px;
  font-weight: bold;
  text-decoration: none;
}

.app-components-Table-Cells-CellLink-___CellLink-module__isDisabled > * {
    color: lightgrey;
  }

.app-components-Table-Cells-CellLink-___CellLink-module__isDisabled {
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellLink/CellLink.module.scss","<no source>"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,qBAAqB;EACrB,kBAAkB;EAClB,uBAAuB;EACvB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;AACvB;;AAGE;IACE,gBAAgB;EAClB;;AC5CF;ED6CE;CC7CF","sourcesContent":[".container {\n  height: 100%;\n  max-width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  white-space: pre-wrap;\n  text-align: center;\n  text-overflow: ellipsis;\n  z-index: 0;\n  position: relative;\n}\n\n.label {\n  font-weight: bolder;\n  color: rgba(0, 0, 0, 0.7);\n  overflow-wrap: anywhere;\n}\n\n.reduce {\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n\n.container:hover {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n.removedTag {\n  color: #ffffff;\n  background-color: red;\n  padding: 0 10px;\n  border-radius: 4px;\n  font-weight: bold;\n  text-decoration: none;\n}\n\n.isDisabled {\n  & > * {\n    color: lightgrey;\n  }\n  pointer-events: none;\n}\n",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellLink-___CellLink-module__container`,
	"label": `app-components-Table-Cells-CellLink-___CellLink-module__label`,
	"reduce": `app-components-Table-Cells-CellLink-___CellLink-module__reduce`,
	"removedTag": `app-components-Table-Cells-CellLink-___CellLink-module__removedTag`,
	"isDisabled": `app-components-Table-Cells-CellLink-___CellLink-module__isDisabled`
};
export default ___CSS_LOADER_EXPORT___;
