// @flow
import api from 'app/core/api/api'
import type { StoreResourceDef } from 'app/core/types'
import type { FlagsActions } from 'app/core/types/StoreResourcesCustomActions'
import { autocomplete } from './autocomplete.js'
import { transformResults } from './utils/transformResults'

export const flags: StoreResourceDef<FlagsActions<>> = {
  resourceType: 'flags',
  actions: (getResources: Object) => ({
    search: autocomplete('flag'),
    fetchFlags: (config = {}) => {
      const { params = {}, ...restConfig } = config || {}
      const fetchFlags = (page, flags = { results: [] }) => {
        return api.flags
          .fetchAll(
            { ...params.queries, page, page_size: 200 },
            params.headers,
            params.getHttpProgress,
            params.requestController,
          )
          .then((res) => {
            const results = {
              ...res,
              results: flags?.results.concat(res?.results) || [],
            }
            if (res?.next) return fetchFlags(page + 1, results)
            return results
          })
      }
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => fetchFlags(1).then(transformResults(restConfig)),
      }
    },
  }),
}
