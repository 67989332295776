// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__container {
  height: inherit;
  overflow: hidden;
  height: 100%;
}

.app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__isRowExpanded {
  overflow-y: auto;
  height: 100%;
}

.app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__readContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__read {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__item {
  position: relative;
  margin: 2px;
  background-color: #555;
  color: #fff;
  padding: 3px 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  max-width: 100%;
  white-space: nowrap;
}

.app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__itemLabel {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__edit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 3px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellAssetsSelect/CellAssetsSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,YAAY;AACd","sourcesContent":[".container {\n  height: inherit;\n  overflow: hidden;\n  height: 100%;\n}\n\n.isRowExpanded {\n  overflow-y: auto;\n  height: 100%;\n}\n\n.readContainer {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n  box-sizing: border-box;\n  position: relative;\n}\n\n.read {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  width: 100%;\n}\n\n.item {\n  position: relative;\n  margin: 2px;\n  background-color: #555;\n  color: #fff;\n  padding: 3px 7px;\n  border-radius: 3px;\n  display: flex;\n  align-items: center;\n  max-width: 100%;\n  white-space: nowrap;\n}\n\n.itemLabel {\n  white-space: nowrap;\n  max-width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.edit {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  padding: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__container`,
	"isRowExpanded": `app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__isRowExpanded`,
	"readContainer": `app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__readContainer`,
	"read": `app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__read`,
	"item": `app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__item`,
	"itemLabel": `app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__itemLabel`,
	"edit": `app-components-Table-Cells-CellAssetsSelect-___CellAssetsSelect-module__edit`
};
export default ___CSS_LOADER_EXPORT___;
