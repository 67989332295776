/** @flow */
import React from 'react'
import Loader from 'app/components/Loader/Loader.jsx'
import api from 'app/core/api/api.js'
import type { Match } from 'react-router-dom'

type Props = {|
  match: Match,
|}

export default class WikiFallback extends React.PureComponent<Props> {
  componentDidMount() {
    const { articleId } = this.props.match.params
    if (articleId) {
      api.articles.fetchOne(articleId).then((article) => {
        window.location.href = `/assets-wiki/${article.asset}/${articleId}`
      })
    } else {
      window.location.href = '/assets-wiki'
    }
  }

  render(): React$Node {
    return <Loader />
  }
}
