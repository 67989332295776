// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Wiki-WikiTree-___WikiTree-module__container {
  height: 100%;
}

.app-containers-Wiki-WikiTree-___WikiTree-module__btn {
  margin-bottom: 10px;
  margin-left: 8px;
}

.app-containers-Wiki-WikiTree-___WikiTree-module__treeOverflow {
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Wiki/WikiTree/WikiTree.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".container {\n  height: 100%;\n}\n\n.btn {\n  margin-bottom: 10px;\n  margin-left: 8px;\n}\n\n.treeOverflow {\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-containers-Wiki-WikiTree-___WikiTree-module__container`,
	"btn": `app-containers-Wiki-WikiTree-___WikiTree-module__btn`,
	"treeOverflow": `app-containers-Wiki-WikiTree-___WikiTree-module__treeOverflow`
};
export default ___CSS_LOADER_EXPORT___;
