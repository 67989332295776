// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Notif-___Notif-module__container {
  position: relative;
  width: 100%;
}

.app-containers-Notif-___Notif-module__message {
  color: #fff;
  position: relative;
  padding: 5px 0;
  text-align: center;
  border-bottom: solid 1px #fff;
}

/**
     * High
     */

.app-containers-Notif-___Notif-module__message.app-containers-Notif-___Notif-module__messageType_0 {
    background-color: var(--colors-red);
  }

/**
     * Medium
     */

.app-containers-Notif-___Notif-module__message.app-containers-Notif-___Notif-module__messageType_1 {
    background-color: var(--colors-yellowDark);
  }

/**
     * Low
     */

.app-containers-Notif-___Notif-module__message.app-containers-Notif-___Notif-module__messageType_2 {
    background-color: var(--colors-blue);
  }

/* display: flex; */

.app-containers-Notif-___Notif-module__message a {
    text-decoration: underline;
  }

.app-containers-Notif-___Notif-module__message a:hover {
    color: var(--colors-black);
  }

.app-containers-Notif-___Notif-module__messageText {
}

.app-containers-Notif-___Notif-module__messageClose {
  position: absolute;
  top: 0px;
  padding: 10px;
  right: 0px;
  cursor: pointer;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Notif/Notif.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,6BAA6B;AA+B/B;;AA5BE;;MAEI;;AACJ;IACE,mCAAmC;EACrC;;AAEA;;MAEI;;AACJ;IACE,0CAA0C;EAC5C;;AAEA;;MAEI;;AACJ;IACE,oCAAoC;EACtC;;AArBA,mBAAmB;;AAuBnB;IACE,0BAA0B;EAC5B;;AAEA;IACE,0BAA0B;EAC5B;;AAGF;AACA;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,aAAa;EACb,UAAU;EACV,eAAe;EACf,WAAW;AACb","sourcesContent":[".container {\n  position: relative;\n  width: 100%;\n}\n\n.message {\n  color: #fff;\n  position: relative;\n  padding: 5px 0;\n  text-align: center;\n  border-bottom: solid 1px #fff;\n  /* display: flex; */\n\n  /**\n     * High\n     */\n  &.messageType_0 {\n    background-color: var(--colors-red);\n  }\n\n  /**\n     * Medium\n     */\n  &.messageType_1 {\n    background-color: var(--colors-yellowDark);\n  }\n\n  /**\n     * Low\n     */\n  &.messageType_2 {\n    background-color: var(--colors-blue);\n  }\n\n  a {\n    text-decoration: underline;\n  }\n\n  a:hover {\n    color: var(--colors-black);\n  }\n}\n\n.messageText {\n}\n\n.messageClose {\n  position: absolute;\n  top: 0px;\n  padding: 10px;\n  right: 0px;\n  cursor: pointer;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-containers-Notif-___Notif-module__container`,
	"message": `app-containers-Notif-___Notif-module__message`,
	"messageType_0": `app-containers-Notif-___Notif-module__messageType_0`,
	"messageType_1": `app-containers-Notif-___Notif-module__messageType_1`,
	"messageType_2": `app-containers-Notif-___Notif-module__messageType_2`,
	"messageText": `app-containers-Notif-___Notif-module__messageText`,
	"messageClose": `app-containers-Notif-___Notif-module__messageClose`
};
export default ___CSS_LOADER_EXPORT___;
