// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Medias-MediaItem-___MediaOverlay-module__overlay {
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s;
  cursor: pointer;
  border-radius: 5px;
  z-index: 2;
}
.app-components-Medias-MediaItem-___MediaOverlay-module__overlay:focus-within {
  opacity: 1;
}

.app-components-Medias-MediaItem-___MediaOverlay-module__overlay:hover {
  opacity: 1;
}

.app-components-Medias-MediaItem-___MediaOverlay-module__buttons {
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.app-components-Medias-MediaItem-___MediaOverlay-module__btn {
  font-size: 18px;
  color: #ffffff;
  margin: 5px;
  cursor: pointer;
}

.app-components-Medias-MediaItem-___MediaOverlay-module__name {
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  white-space: nowrap;
  padding: 4px;
  bottom: 0px;
  width: 100%;
  max-height: 15px;
}

.app-components-Medias-MediaItem-___MediaOverlay-module__lightTooltip {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.87);
  font-size: 12;
  opacity: 0.9;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./app/components/Medias/MediaItem/MediaOverlay.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,eAAe;EACf,cAAc;EACd,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,2CAA2C;EAC3C,aAAa;EACb,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".overlay {\n  opacity: 0;\n  position: absolute;\n  left: 0px;\n  top: 0px;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.4);\n  transition: opacity 0.2s;\n  cursor: pointer;\n  border-radius: 5px;\n  z-index: 2;\n}\n.overlay:focus-within {\n  opacity: 1;\n}\n\n.overlay:hover {\n  opacity: 1;\n}\n\n.buttons {\n  display: flex;\n  align-content: center;\n  flex-direction: row;\n  flex-wrap: wrap;\n  padding: 10px;\n}\n\n.btn {\n  font-size: 18px;\n  color: #ffffff;\n  margin: 5px;\n  cursor: pointer;\n}\n\n.name {\n  color: #ffffff;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  position: absolute;\n  white-space: nowrap;\n  padding: 4px;\n  bottom: 0px;\n  width: 100%;\n  max-height: 15px;\n}\n\n.lightTooltip {\n  background-color: #ffffff;\n  color: rgba(0, 0, 0, 0.87);\n  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.87);\n  font-size: 12;\n  opacity: 0.9;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `app-components-Medias-MediaItem-___MediaOverlay-module__overlay`,
	"buttons": `app-components-Medias-MediaItem-___MediaOverlay-module__buttons`,
	"btn": `app-components-Medias-MediaItem-___MediaOverlay-module__btn`,
	"name": `app-components-Medias-MediaItem-___MediaOverlay-module__name`,
	"lightTooltip": `app-components-Medias-MediaItem-___MediaOverlay-module__lightTooltip`
};
export default ___CSS_LOADER_EXPORT___;
