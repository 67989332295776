/*eslint-disable */

import React from 'react'
import Icon from '../Icon.jsx'

export default class Planet_7 extends React.Component {
  static defaultProps = {
    width: 18,
    height: 18,
    fill: 'grey',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 18 18">
        <circle cx="7.3" cy="13.7" r="0.7" />
        <circle cx="2.9" cy="8.8" r="0.7" />
        <circle cx="13.8" cy="10.6" r="0.7" />
        <circle cx="6.8" cy="10.3" r="1.5" />
        <path
          d="M15.4,2.6c-3.5-3.5-9.2-3.5-12.7,0C0.9,4.3,0,6.6,0,9c0,5,4,9,9,9c2.4,0,4.7-0.9,6.4-2.6
                    C18.9,11.8,18.9,6.2,15.4,2.6z M1.1,9c0-3.8,2.7-7,6.2-7.7c0,0.4,0.3,0.6,0.7,0.6c0.4,0,0.7-0.3,0.7-0.7c0,0,0-0.1,0-0.1
                    c0.1,0,0.2,0,0.4,0c2.1,0,4.1,0.8,5.6,2.3c2.8,2.8,3.1,7.1,0.8,10.2c-0.1-0.2-0.3-0.3-0.6-0.3c-0.4,0-0.7,0.3-0.7,0.7
                    c0,0.3,0.2,0.5,0.4,0.6c-0.9,0.8-1.8,1.5-2.9,1.8c0-0.1,0-0.2,0-0.3c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5c0,0.3,0.1,0.5,0.2,0.7
                    c-2,0-3.9-0.8-5.4-2.3C1.9,13.1,1.1,11.1,1.1,9z"
        />
      </Icon>
    )
  }
}

/* eslint-enable */
