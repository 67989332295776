var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import resources from 'app/store/resources';
import FormData from 'app/components/Form/FormData/FormData.jsx';
import { ModalConfirmForm } from 'app/components/Modal';
import { getResources } from 'app/store/selectors';
const ModalEditGroupComponent = (props) => {
    const { groupId } = props, rest = __rest(props, ["groupId"]);
    const group = getResources(undefined, 'assets', groupId);
    const onSave = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield resources.assets.update({ id: group.id, name: data.name });
        return res;
    });
    return (
    // $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow
    _jsx(ModalConfirmForm, Object.assign({ title: `Edit group ${group.name}`, validateLabel: "Save" }, rest, { children: _jsx(FormData, { defaultData: group, properties: [
                {
                    key: 'name',
                    label: 'name',
                    type: FormData.types.string,
                    elementProps: { isRequired: true },
                },
            ], onSave: onSave, flashNotifSuccessLabel: "Group updated" }) })));
};
export default ModalEditGroupComponent;
