// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Wiki-WikiEditor-___printable-module__printable {
  height: -moz-fit-content;
  height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Wiki/WikiEditor/printable.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAmB;EAAnB,mBAAmB;AACrB","sourcesContent":[".printable {\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"printable": `app-containers-Wiki-WikiEditor-___printable-module__printable`
};
export default ___CSS_LOADER_EXPORT___;
