// @flow

export const dynamicApprovalStatus = {
  td: 'To do',
  wp: 'Work in Progress',
  wa: 'Waiting for Approval',
  sb: 'Stand By',
  cb: 'Retake CBB',
  rt: 'Retake',
  ca: 'Cancelled',
  cl: 'Closed',
  ok: 'OK',
}

export const dynamicApprovalKeys = {
  td: '1', // 'To do',
  wp: '2', // 'Work in Progress',
  wa: '3', // 'Waiting for Approval',
  sb: '6', // 'Stand By',
  cb: '8', // 'Retake CBB',
  rt: '4', // 'Retake',
  ca: '9', // 'Cancelled',
  cl: '7', // 'Closed',
  ok: '5', // 'OK',
}

export const dynamicApprovalColors = {
  td: '#3dc3d2',
  wp: '#e3e05b',
  wa: '#eeb413',
  nw: '#b8e986',
  cl: '#b8b8b8',
  sb: '#d9d9d9',
  ca: '#e56d7a',
  ok: '#48c27e',
  rt: '#E50F26',
  cb: '#ff8895',
}
