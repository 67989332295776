export const validExtensions = [
  'image/*',
  'video/*',
  'audio/*',
  '.aac',
  '.avi',
  '.bat',
  '.bmp',
  '.doc',
  '.dmg',
  '.docx',
  '.exe',
  '.gif',
  '.gz',
  '.iso',
  '.jpeg',
  '.jpg',
  '.jpe',
  '.mkv',
  '.mp3',
  '.odt',
  '.odp',
  '.ods',
  '.odg',
  '.pdf',
  '.png',
  '.pps',
  '.ppt',
  '.rar',
  '.tar',
  '.torrent',
  '.xls',
  '.xlsx',
  '.wav',
  '.zip',
  '.7z',
  '.sh',
  '.h',
  '.py',
  '.odt',
  '.odp',
  '.ods',
  '.odg',
  '.mpg',
  '.mpe',
  '.mpeg',
  '.tgz',
  '.tar.gz',
  '.txt',
  '.ext',
  '.json',
  '.fbx',
  '.abc',
  '.export',
  '.colanium',
  '.rum',
  '.colasset',
  '.rumba',
  '.nk',
  '.mamb',
]
