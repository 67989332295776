/** @flow */
import { useRef } from 'react'
import moment from 'moment'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import keyCode from 'app/libs/keyCode'

import type { CellInstance, Cell } from '../../../types'

type Params = {|
  instance: CellInstance,
  startDate: ?moment,
  endDate: ?moment,
  cell: Cell,
  onPositionChange: ({ leftDiff: number, widthDiff: number }) => void,
|}

export const useRangeEdition = (
  params: Params,
): {
  onLengthResize: Function,
  onLengthStopResize: Function,
  onMouseDown: Function,
} => {
  const { instance, startDate, endDate, cell, onPositionChange } = params
  const { setGanttIsUnderEditing, getDatesFromPositions } = instance

  function changeDomPositionForSelectedCells(leftDiff, widthDiff) {
    onPositionChange({ leftDiff, widthDiff })
    const { getSelectedTimelineCells, updateCellDOMPostion } = instance
    const selectedCells = getSelectedTimelineCells?.() || []
    selectedCells.forEach((cell) => updateCellDOMPostion?.(cell, leftDiff, widthDiff))
  }

  async function onSave(leftDiff: number, widthDiff: number) {
    const { getSelectedTimelineCells } = instance
    const selectedCells = getSelectedTimelineCells?.() || []

    let sortedCells = sortBy(selectedCells, ['row.original.startDate'])
    if (leftDiff > 0) sortedCells = sortedCells.reverse()

    const promises: Array<Promise<void>> = map(sortedCells, (cell) => {
      const { saveCellFromPositions } = cell.getCellProps()
      if (!saveCellFromPositions) return Promise.resolve()
      return saveCellFromPositions?.(leftDiff, widthDiff)
    })

    Promise.all(promises)
  }

  const rangeDimensions = useRef({ originX: 0, vector: 0 })

  function resetRangeDimensions() {
    rangeDimensions.current = { originX: 0, vector: 0 }
  }

  function move(e: MouseEvent) {
    const { originX } = rangeDimensions.current
    const vector = e.clientX - originX
    rangeDimensions.current.vector = vector
    changeDomPositionForSelectedCells(vector, 0)
  }

  function stopMove(e: MouseEvent) {
    window.removeEventListener('mousemove', move)
    window.removeEventListener('mouseup', stopMove)

    setGanttIsUnderEditing?.(false)

    const { vector } = rangeDimensions.current

    if (vector === 0) {
      resetRangeDimensions()
      return
    }

    if (startDate && endDate) {
      const { startDate: newStartDate, endDate: newEndDate } = getDatesFromPositions?.(cell, vector, 0) || {}
      if (newStartDate.isSame(startDate) && newEndDate.isSame(endDate)) {
        changeDomPositionForSelectedCells(0, 0)
        return
      }
    }

    onSave(vector, 0)
      .then(() => {
        resetRangeDimensions()
      })
      .catch(() => {
        resetRangeDimensions()
        changeDomPositionForSelectedCells(0, 0)
      })
  }

  function cancel(e: KeyboardEvent) {
    if (e.keyCode === keyCode.ESCAPE) {
      setGanttIsUnderEditing?.(false)

      resetRangeDimensions()
      changeDomPositionForSelectedCells(0, 0)
      window.removeEventListener('mousemove', move)
      window.removeEventListener('mouseup', stopMove)
      window.removeEventListener('keydown', cancel)
    }
  }

  function onMouseDown(e: SyntheticMouseEvent<any>) {
    e.stopPropagation()

    if (e.button !== 0) return

    resetRangeDimensions()
    rangeDimensions.current.originX = e.clientX

    setGanttIsUnderEditing?.(true)

    window.addEventListener('mousemove', move)
    window.addEventListener('mouseup', stopMove)
    window.addEventListener('keydown', cancel)
  }

  function onLengthResize(position: 'left' | 'right') {
    return (e: MouseEvent, { diff }: { diff: number }) => {
      if (position === 'left') changeDomPositionForSelectedCells(diff, -diff)
      if (position === 'right') changeDomPositionForSelectedCells(0, diff)
    }
  }

  function onLengthStopResize(position: 'left' | 'right') {
    return (e: MouseEvent, { diff }: { diff: number }) => {
      if (diff === 0) return

      let leftDiff = 0
      let widthDiff = 0

      if (position === 'left') {
        leftDiff = diff
        widthDiff = -diff
      }
      if (position === 'right') {
        widthDiff = diff
      }

      onSave(leftDiff, widthDiff).catch(() => {
        resetRangeDimensions()
        changeDomPositionForSelectedCells(0, 0)
      })
    }
  }

  return {
    onLengthResize,
    onLengthStopResize,
    onMouseDown,
  }
}
