/** @flow */
import React from 'react'
import type { ProgressionStatus, ResourcesList } from 'app/core/types'
import { durationStr } from 'app/components/Duration/Duration.tsx'
import type { Users__count } from './StatsTypes'
import { MakeTableFromArray } from './index'
import type { UseFiltersOutput } from '../../hooks/useFilters'

type Props = {|
  users: Users__count,
  progressionStatus: ResourcesList<ProgressionStatus>,
  columnName: string,
  setFilters?: $ElementType<UseFiltersOutput, 'setUrlFilters'>,
  tableId: ?string,
|}

export function DisplayEstimations(props: Props): React$Node {
  const { users, progressionStatus, columnName, setFilters, tableId } = props

  const columnsId = {}
  users.forEach((user) =>
    user?.estimLength__take1__counts?.forEach((takeStatus) => {
      if (!takeStatus.status__uuid) return
      const status = progressionStatus[takeStatus.status__uuid]
      if (!status) return
      columnsId[`Estim ${status.name} take 1`] = '-'
    }),
  )

  const values = users.map((user) => {
    const vals = {
      User: user.fAssignedUser__name,
      ...columnsId,
      onClick: () => {
        const filters = btoa(
          JSON.stringify([
            [`${columnName} - assigned user`, 'inList', user.fAssignedUser__uuid],
            [`${columnName} - take number`, 'exact', '1'],
            [
              `${columnName} - status`,
              'inList',
              ...(user?.estimLength__take1__counts.filter((s) => s.status__uuid).map((s) => s.status__uuid) || {}),
            ],
          ]),
        )
        setFilters?.(filters, true, true)
      },
    }
    user?.estimLength__take1__counts?.forEach((takeStatus) => {
      if (!takeStatus.status__uuid) return
      const status = progressionStatus[takeStatus.status__uuid]
      if (!status) return
      vals[`Estim ${status.name} take 1`] = durationStr(takeStatus.estimLength__total || 0, 7.8)
    })
    return vals
  })

  return <MakeTableFromArray values={values} referenceName="User" tableId={tableId} />
}
