// @flow
import type { StoreResourceDef } from 'app/core/types'
import api from 'app/core/api/api.js'
import type { ActionPermissionGroupsActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const actionPermissionGroups: StoreResourceDef<ActionPermissionGroupsActions<>> = {
  resourceType: 'actionPermissionGroups',
  actions: (actionCreators) => ({
    getPermissionsByGroup: (groupId, config) => {
      const { requestKey } = config || {}
      return {
        type: 'read',
        requestKey,
        request: () => {
          return api.groups
            .actionPermissions({ id: groupId })
            .then((res) => ({
              ...res,
              results: res.results.rel_actionPermissionGroupsInst,
            }))
            .then(transformResults(config))
        },
      }
    },
    getPermissionsByActionPermission: (actionPermissionId, config) => {
      const { requestKey } = config || {}
      return {
        type: 'read',
        requestKey,
        request: () => {
          return api.actionPermissions.fetchOne(actionPermissionId).then(transformResults(config))
        },
      }
    },
  }),
}
