// @flow
import * as React from 'react'
import { optionsDynamicApprovalStatus } from 'app/core/utils/optionsDynamicApprovalStatus'
import type { ID, DynamicApprovalValue, Pipe } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData } from 'app/components/Form'

type Props = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  authorId: ID,
  dynamicApprovalValue: DynamicApprovalValue,
  onChange: (data: Object) => Promise<any>,
  multipleCellsEdit: boolean,
|}

export class ModalEditDynamicApprovalView extends React.PureComponent<Props> {
  onSave: Function = (data: *) => {
    const { authorId, onChange, dynamicApprovalValue } = this.props

    const dynamicApprovalValueData = {
      status: dynamicApprovalValue.status !== data.status ? data.status : undefined,
      comment: dynamicApprovalValue.comment !== data.comment ? data.comment : undefined,
      author: dynamicApprovalValue.author !== authorId ? authorId : undefined,
    }

    return onChange(dynamicApprovalValueData)
  }

  render(): React$Node {
    const { dynamicApprovalValue, multipleCellsEdit, authorId, onChange, ...rest } = this.props

    const defaultData = !dynamicApprovalValue
      ? {}
      : {
          status: dynamicApprovalValue.status,
          comment: dynamicApprovalValue.comment,
        }

    return (
      <ModalConfirmForm
        title="Edit Dynamic Approval"
        resizable={true}
        minHeight={450}
        minWidth={420}
        width={700}
        height={510}
        {...rest}
      >
        <FormData
          onSave={this.onSave}
          defaultData={defaultData}
          selectableFields={multipleCellsEdit}
          properties={[
            {
              label: 'Status',
              key: 'status',
              type: 'select',
              elementProps: {
                isRequired: true,
                options: optionsDynamicApprovalStatus,
                fullWidth: true,
                placeholder: 'Select a status',
              },
            },
            {
              label: 'Comment',
              key: 'comment',
              type: 'richtext',
              elementProps: {
                defaultHeight: 350,
                onInit: (editor) => editor?.editing.view.focus(),
              },
            },
          ]}
        />
      </ModalConfirmForm>
    )
  }
}

const pipeInst: Pipe<{}, typeof ModalEditDynamicApprovalView> = pipe()

export const ModalEditDynamicApproval: React$ComponentType<any> = pipeInst
  .connect((state) => {
    return {
      authorId: state.user.asset,
    }
  })
  .render(ModalEditDynamicApprovalView)
