const getPalette = (list) => {
  const colors = {
    /*
     * pomegranate
     * orange
     * turquoise
     * nephritis
     * wisteria
     * alizarin
     * midnight-blue
     * pumpkin
     * belize-hole
     * emerald
     * amethyst
     * sunflower
     * carrot
     * green-sea
     */

    c200: [
      '#e6b0aa',
      '#fad7a0',
      '#a3e4d7',
      '#a9dfbf',
      '#d2b4de',
      '#f5b7b1',
      '#abb2b9',
      '#edbb99',
      '#a9cce3',
      '#abebc6',
      '#d7bde2',
      '#f9e79f',
      '#f5cba7',
      '#a2d9ce',
    ],
    c300: [
      '#d98880',
      '#f8c471',
      '#76d7c4',
      '#7dcea0',
      '#bb8fce',
      '#f1948a',
      '#808b96',
      '#e59866',
      '#7fb3d5',
      '#82e0aa',
      '#c39bd3',
      '#f7dc6f',
      '#f0b27a',
      '#73c6b6',
    ],
    c400: [
      '#cd6155',
      '#f5b041',
      '#48c9b0',
      '#52be80',
      '#a569bd',
      '#ec7063',
      '#566573',
      '#dc7633',
      '#5499c7',
      '#58d68d',
      '#af7ac5',
      '#f4d03f',
      '#eb984e',
      '#45b39d',
    ],
    c500: [
      '#c0392b',
      '#f39c12',
      '#1abc9c',
      '#27ae60',
      '#8e44ad',
      '#e74c3c',
      '#2c3e50',
      '#d35400',
      '#2980b9',
      '#2ecc71',
      '#9b59b6',
      '#f1c40f',
      '#e67e22',
      '#16a085',
    ],
    c600: [
      '#a93226',
      '#d68910',
      '#17a589',
      '#229954',
      '#7d3c98',
      '#cb4335',
      '#273746',
      '#ba4a00',
      '#2471a3',
      '#28b463',
      '#884ea0',
      '#d4ac0d',
      '#ca6f1e',
      '#138d75',
    ],
    c700: [
      '#922b21',
      '#b9770e',
      '#148f77',
      '#1e8449',
      '#6c3483',
      '#b03a2e',
      '#212f3d',
      '#a04000',
      '#1f618d',
      '#239b56',
      '#76448a',
      '#b7950b',
      '#af601a',
      '#117a65',
    ],
    c800: [
      '#7b241c',
      '#9c640c',
      '#117864',
      '#196f3d',
      '#5b2c6f',
      '#943126',
      '#1c2833',
      '#873600',
      '#1a5276',
      '#1d8348',
      '#633974',
      '#9a7d0a',
      '#935116',
      '#0e6655',
    ],
    c900: [
      '#641e16',
      '#7e5109',
      '#0e6251',
      '#145a32',
      '#4a235a',
      '#78281f',
      '#17202a',
      '#6e2c00',
      '#154360',
      '#186a3b',
      '#512e5f',
      '#7d6608',
      '#784212',
      '#0b5345',
    ],
  }

  if (!list || list.length === 0) {
    return ['c200', 'c300', 'c400', 'c500', 'c600', 'c700', 'c800', 'c900'].map((name) => colors[name]).flat()
  }

  return list.map((name) => colors[name]).flat()
}

const vars = {
  randomColors: [
    '#00639A',
    '#E56D7A',
    '#B8E986',
    '#E3E05B',
    '#EEB413',
    '#48C27E',
    '#E50F26',
    '#3DC3D2',
    '#008533',
    '#ff00f2',
    '#2800db',
    '#5c50ff',
    '#ff5050',
  ],
  borderField: 'solid 1px #B3B9c7',
  mainBorderRadius: '4px',
  mainBorder: 'solid 1px #E8E8E8',
  mainBorderColor: '#E8E8E8',
  mainFontItalic: "'gothamlightitalic'",
  mainFontBoldItalic: "'gothambolditalic'",
  mainFont: '"Helvetica Neue", Helvetica, Arial',
  tableFont: '"Helvetica", Arial, sans-serif',
  colors: {
    mainColor: '#3DC3D2',
    mainColorMedium: 'rgba(61, 195, 209, 0.34)',
    mainColorLight: '#EBF9FA',
    cyan: '#3DC3D2',
    cyanLight: '#EBF9FA',
    white: '#FFFFFF',
    black: '#4A4A4A',
    blackLight: '#41474d',
    greyExtraLight: '#F7F8FB',
    greyLight: '#E8E8E8',
    greyMedium: 'rgba(206, 209, 212, 0.75)',
    grey: '#B3B9c7',
    greyDark: '#596169',
    blue: '#008ae6',
    green: '#48C27E',
    red: '#E56D7A',
    redDark: '#E50F26',
    greenLight: '#B8E986',
    yellow: '#E3E05B',
    yellowDark: '#EEB413',
    orange: '#EEB413',
    transparent: 'transparent',
  },

  getPalette,
}

module.exports = vars
