// @flow
import * as React from 'react'
import type { AssetTypes, ID } from 'app/core/types'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData } from 'app/components/Form'
import { AssetsRelations } from './AssetsRelations.jsx'

type Props = {|
  assetTypes?: Array<AssetTypes>,
  placeholder: string,
  assets: Array<any> | Object,
  onClose?: Function,
  ressourceID?: ID,
  groupId?: ID,
  noProjectHeader?: boolean,
  inputRef?: Function,
  foreignKey?: string,
  onSave: (data: Object) => Promise<any>,
  inputProps?: Object,
  flashNotifSuccessLabelKey?: string,
  ...$Rest<ModalProps, { children: React$Node }>,
|}

export function ModalAssetsRelations(props: Props): React$Element<any> {
  const {
    assets,
    onSave,
    placeholder,
    onClose,
    ressourceID,
    groupId,
    noProjectHeader,
    foreignKey,
    inputRef,
    assetTypes,
    inputProps,
    flashNotifSuccessLabelKey,
    ...rest
  } = props

  const input = React.useRef()

  const [localData, setLocalData] = React.useState([])

  return (
    <ModalConfirmForm title={placeholder} onRequestClose={onClose} {...rest}>
      <FormData
        defaultData={{
          assets,
        }}
        properties={[
          {
            key: 'assets',
            label: placeholder,
            type: 'custom',
            element: (data: any, setData: Function) => {
              return (
                <AssetsRelations
                  assetTypes={assetTypes}
                  placeholder={placeholder}
                  value={data.assets}
                  foreignKey={foreignKey}
                  groupId={groupId}
                  noProjectHeader={noProjectHeader}
                  ressourceID={ressourceID}
                  multiple={true}
                  onChange={(assets) => {
                    setData({ assets })
                    setLocalData(assets)
                  }}
                  inputProps={inputProps}
                  inputRef={(ref) => {
                    if (inputRef) inputRef(ref)
                    if (!input.current) {
                      input.current = ref
                      ref.focus()
                    }
                  }}
                />
              )
            },
          },
        ]}
        onSave={onSave}
        flashNotifSuccessLabel={`${
          localData.length > 1 ? `${flashNotifSuccessLabelKey}s` : flashNotifSuccessLabelKey
        } added`}
      />
    </ModalConfirmForm>
  )
}
