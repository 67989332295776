// @flow
import type { StoreResourceDef } from 'app/core/types'
import api from 'app/core/api/api.js'
import type { AttributesActions } from 'app/core/types/StoreResourcesCustomActions'
import { autocomplete } from './autocomplete.js'
import { transformResults } from './utils/transformResults'

export const attributes: StoreResourceDef<AttributesActions<>> = {
  resourceType: 'attributes',
  actions: (actionsCreators) => ({
    fetchByProject: (projectId, config = {}) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => api.projects.attributes({ id: projectId, ...params.queries }).then(transformResults(config)),
      }
    },
    search: autocomplete('attribute'),
  }),
}
