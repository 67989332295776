// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Groups-___Groups-module__container {
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  grid-template-areas: 'list group group';
  grid-auto-rows: 100%;
}

.app-containers-Groups-___Groups-module__leftContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-containers-Groups-___Groups-module__rightContent {
  flex: 1;
}

.app-containers-Groups-___Groups-module__groupsList {
  display: table;
  margin-top: 20px;
}

.app-containers-Groups-___Groups-module__group {
  justify-content: space-between;
  display: flex;
  align-items: center;
  border: solid 1px var(--colors-grey);
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;
  overflow: hidden;
}

.app-containers-Groups-___Groups-module__groupName {
  margin-right: 10px;
  padding: 0 10px;
}

.app-containers-Groups-___Groups-module__groupListArea {
  grid-area: list;
}

.app-containers-Groups-___Groups-module__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 100%;
  padding-left: 20px;
  font-size: 15px;
  min-height: 35px;
}

.app-containers-Groups-___Groups-module__item:hover {
  background-color: var(--colors-mainColor) 55;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Groups/Groups.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,aAAa;EACb,uCAAuC;EACvC,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,oCAAoC;EACpC,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".container {\n  height: 100%;\n  box-sizing: border-box;\n  display: grid;\n  grid-gap: 10px;\n  padding: 10px;\n  grid-template-areas: 'list group group';\n  grid-auto-rows: 100%;\n}\n\n.leftContent {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.rightContent {\n  flex: 1;\n}\n\n.groupsList {\n  display: table;\n  margin-top: 20px;\n}\n\n.group {\n  justify-content: space-between;\n  display: flex;\n  align-items: center;\n  border: solid 1px var(--colors-grey);\n  border-radius: 5px;\n  margin-bottom: 10px;\n  background-color: #fff;\n  overflow: hidden;\n}\n\n.groupName {\n  margin-right: 10px;\n  padding: 0 10px;\n}\n\n.groupListArea {\n  grid-area: list;\n}\n\n.item {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  align-content: center;\n  height: 100%;\n  padding-left: 20px;\n  font-size: 15px;\n  min-height: 35px;\n}\n\n.item:hover {\n  background-color: var(--colors-mainColor) 55;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-containers-Groups-___Groups-module__container`,
	"leftContent": `app-containers-Groups-___Groups-module__leftContent`,
	"rightContent": `app-containers-Groups-___Groups-module__rightContent`,
	"groupsList": `app-containers-Groups-___Groups-module__groupsList`,
	"group": `app-containers-Groups-___Groups-module__group`,
	"groupName": `app-containers-Groups-___Groups-module__groupName`,
	"groupListArea": `app-containers-Groups-___Groups-module__groupListArea`,
	"item": `app-containers-Groups-___Groups-module__item`
};
export default ___CSS_LOADER_EXPORT___;
