/** @flow */
import React, { useMemo, useRef, useState } from 'react'
// eslint-disable-next-line no-unused-vars
import emitter from 'tiny-emitter/instance'
import type { TreeViewItem } from './TreeViewItem'
import type { TreeViewProps } from './TreeViewProps'
import { useTreeViewSates, type DropLocation, type UseTreeViewSatesOutput } from './useTreeViewSates'
import uniqid from '../../libs/uniqid'
import classes from './TreeRow.module.scss'

function DraggableElement({
  state,
  index,
  dragImageRef,
  renderRow,
}: {
  state: UseTreeViewSatesOutput,
  index: number,
  dragImageRef: { current: ?HTMLDivElement },
  renderRow: $ElementType<TreeViewProps<TreeViewItem<>>, 'renderRow'>,
}) {
  const {
    indentation,
    rows,
    itemToDOM,
    onRowDragStart,
    onRowDragEnd,
    onRowDragEnter,
    onRowDragLeave,
    onRowDrop,
    onRowDragOver,
  } = state
  const { item, depth, selected, ancestorSelected, select, deselect, collapsed, collapse } = rows[index]

  return (
    <div
      ref={(e) => e && itemToDOM.set(item, e)}
      className={classes.draggableItem}
      draggable={!item.node.disableDrag}
      onDragStart={(e: SyntheticDragEvent<HTMLElement>) => onRowDragStart(index, e, e.currentTarget)}
      onDragEnd={(e: SyntheticDragEvent<HTMLElement>) => onRowDragEnd(index, e)}
      onDragEnter={(e: SyntheticDragEvent<HTMLElement>) => onRowDragEnter(index, e)}
      onDragLeave={onRowDragLeave}
      onDrop={(e: SyntheticDragEvent<HTMLElement>) => onRowDrop(index, e)}
      onDragOver={(e: SyntheticDragEvent<HTMLElement>) => onRowDragOver(index, e)}
    >
      {renderRow(item, {
        depth,
        indentation,
        selected,
        select,
        collapsed,
        collapse,
        ancestorSelected,
        deselect,
      })}
    </div>
  )
}

function Background({ state }: { state: UseTreeViewSatesOutput }) {
  const { onBackgroundDragEnter, onBackgroundDragLeave, onBackgroundDragOver, onBackgroundDrop, onBackgroundClick } =
    state

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
      }}
      onDragEnter={onBackgroundDragEnter}
      onDragLeave={onBackgroundDragLeave}
      onDragOver={onBackgroundDragOver}
      onDrop={onBackgroundDrop}
      onClick={(e) => onBackgroundClick?.(e, state)}
    />
  )
}

function DropIndicator({
  renderDropIndicator,
  indentation,
  dropIndicatorOffset,
  treeId,
}: {
  renderDropIndicator: $ElementType<TreeViewProps<TreeViewItem<>>, 'renderDropIndicator'>,
  indentation: $ElementType<UseTreeViewSatesOutput, 'indentation'>,
  dropIndicatorOffset: $ElementType<UseTreeViewSatesOutput, 'dropIndicatorOffset'>,
  treeId: string,
}) {
  const [dropLocation, setDropLocation] = useState<DropLocation | void>()

  emitter.on(`dropLocationChange-${treeId}`, (message, dropLocation) => setDropLocation(dropLocation))

  const indicator = dropLocation?.indication
  if (!indicator) return null

  return renderDropIndicator({
    indication: indicator,
    indentation,
    dropIndicatorOffset,
  })
}

/// / TreeView

export function TreeView(props: TreeViewProps<TreeViewItem<>>): React$Node | null {
  const {
    rootNode,
    indentation,
    dropIndicatorOffset,
    canDropData,
    handleDrop,
    nonReorderable,
    handleDragStart,
    handleDragEnd,
    onBackgroundClick,
    renderDropIndicator,
    renderRow,
    noHiddingOnDrag,
  } = props

  const dragImageRef = useRef<HTMLDivElement | null | void>()

  const treeId = useMemo(() => uniqid(), [])

  const state = useTreeViewSates({
    rootNode,
    indentation,
    dropIndicatorOffset,
    canDropData,
    handleDrop,
    nonReorderable,
    handleDragStart,
    handleDragEnd,
    onBackgroundClick,
    treeId,
    noHiddingOnDrag,
  })

  return (
    <div
      className={props.className}
      hidden={props.hidden}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...props.style,
      }}
    >
      <div
        style={{
          flex: '1',
          position: 'relative',
          zIndex: '0',
        }}
      >
        <div
          style={{
            position: 'fixed',
            left: '-10000px',
            top: '-10000px',
            width: '1px',
            height: '1px',
            visibility: 'hidden',
          }}
          ref={dragImageRef}
        />
        <Background state={state} />
        <div
          ref={(e) => {
            state.setHeaderDOM(e)
            return e ?? undefined
          }}
        >
          {props.header}
        </div>
        {state.rows.map((row, i) => {
          return (
            <DraggableElement
              key={row.item.key}
              state={state}
              index={i}
              dragImageRef={dragImageRef}
              selected={row.selected}
              select={row.select}
              collapsed={row.collapsed}
              collapse={row.collapse}
              renderRow={renderRow}
            />
          )
        })}
        {props.footer}
        <DropIndicator
          renderDropIndicator={renderDropIndicator}
          indentation={state.indentation}
          dropIndicatorOffset={state.dropIndicatorOffset}
          treeId={treeId}
        />
      </div>
    </div>
  )
}
