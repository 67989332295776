/** @flow */
import React from 'react'

import type { ColumnHeader, Column, DefaultCellProps, CellInstance, TableInstance } from '../../types'
import classes from './ColumnTimeline.module.scss'
import { HeaderSVG } from '../ColumnTimelineImputation/HeaderSVG.jsx'
import { CellTimeline, type TimelineCellComponent, type TimelineDrawerComponent } from './CellTimeline'

type Params = {|
  ...DefaultCellProps,
  Header?: React$Node | ((instance: CellInstance | TableInstance) => React$Node),
  id?: string,
  readOnly?: boolean,
  CellComponent: TimelineCellComponent,
  Drawer?: TimelineDrawerComponent,
|}

export const ColumnTimeline = (params: Params): ColumnHeader => {
  const { CellComponent, Drawer, ...rest } = params
  const columns: Array<$Shape<Column>> = [
    {
      id: 'timeline-days',
      readOnly: false,
      cellType: 'CellTimeline',
      isGanttColumn: true,
      minWidth: 800,
      width: null,
      Header: (instance) => {
        const { timelineUnit } = instance

        if (!timelineUnit) return <div className={`${classes.header} ${classes.headerDays}`} />

        const { unit, unitFormat } = timelineUnit

        return (
          <div className={`${classes.header} ${classes.headerDays}`}>
            <HeaderSVG instance={instance} height={41} unit={unit} unitFormat={unitFormat} />
          </div>
        )
      },
      Cell: (instance) => <CellTimeline instance={instance} CellComponent={CellComponent} Drawer={Drawer} />,
      ...rest,
    },
  ]

  return {
    id: 'gantt-headers',
    isGanttColumn: true,
    Header: (instance) => {
      const { ganttProperties, timelineUnit, dimensions } = instance
      if (!timelineUnit || !dimensions || !ganttProperties) return <div className={classes.headerMonths} />

      const { headUnit, headUnitFormat } = timelineUnit

      return <HeaderSVG instance={instance} height={41} unit={headUnit} unitFormat={headUnitFormat} />
    },
    columns,
  }
}
