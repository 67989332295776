/** @flow */
import React, { useEffect, useMemo, useRef } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { rootContextMenu } from 'app/main/rootContainers.js'
import classes from './ContextMenu.module.scss'
import { MenuItem, type ContextMenuItemProps } from './MenuItem.jsx'
import keyCode from '../../libs/keyCode'

export const hideContextMenu = () => {
  rootContextMenu.render(<div />)
}

type Props = {|
  clientY?: number,
  clientX?: number,
  items: Array<ContextMenuItemProps>,
  onExit?: Function,
  onClose?: () => void,
  targetRef?: React$ElementRef<any>,
  position?: 'top' | 'bottom',
  style?: Object,
|}

export function ContextMenu(props: Props): React$Node {
  const { clientY: _clientY, clientX: _clientX, items, onExit, onClose, targetRef, position, style } = props

  const menuRef = useRef()

  const { clientX, clientY } = useMemo(() => {
    if (!targetRef || !targetRef.current) return { clientY: _clientY || 0, clientX: _clientX || 0 }

    const { offsetWidth, offsetHeight, offsetTop, offsetLeft } = targetRef.current || {}

    switch (position) {
      case 'top':
        return { clientX: offsetLeft + offsetWidth / 2 - 250 / 2, clientY: offsetTop - items.length * 28 - 10 }
      default:
        return {
          clientX: offsetLeft + offsetWidth / 2 - 250 / 2,
          clientY: offsetTop + items.length * 28 + 10 + offsetHeight,
        }
    }
  }, [targetRef, _clientX, _clientY, position])

  function handleClose() {
    hideContextMenu()
  }

  function handleClickAway() {
    if (onClose) onClose()
  }

  function itemsToChildrens(items: Array<ContextMenuItemProps>) {
    return items.map((action: ContextMenuItemProps, index: number) => (
      <MenuItem
        onClose={() => {
          hideContextMenu()
          handleClickAway()
        }}
        key={String(index)}
        index={String(index)}
        {...action}
      />
    ))
  }

  function recalculatePosition() {
    const contextMenu = document.getElementById('ovm-contextmenu')
    if (contextMenu) {
      const { innerHeight, innerWidth } = window
      const { clientHeight, clientWidth } = contextMenu

      if (clientWidth + clientX > innerWidth) contextMenu.style.left = `${innerWidth - clientWidth - 50}px`
      else contextMenu.style.left = `${clientX + 5}px`
      if (clientHeight + clientY > innerHeight - 50) contextMenu.style.top = `${innerHeight - clientHeight - 50}px`
      else contextMenu.style.top = `${clientY + 5}px`
    }
  }

  useEffect(() => {
    const appEl = document.getElementById('render-app')

    if (appEl) {
      appEl.addEventListener('contextmenu', handleClose, true)
      appEl.addEventListener('click', handleClose, true)
      appEl.addEventListener('mousewheel', handleClose, true)
    }
    return () => {
      if (appEl) {
        appEl.removeEventListener('contextmenu', handleClose, true)
        appEl.removeEventListener('mousewheel', handleClose, true)
        appEl.removeEventListener('click', handleClose, true)
      }
    }
  }, [])

  useEffect(() => {
    recalculatePosition()
    return () => {
      if (onExit) onExit()
    }
  }, [])

  useEffect(() => {
    if (menuRef.current) menuRef.current.focus()
  }, [menuRef.current])

  function onKeyDown(e: SyntheticKeyboardEvent<>) {
    switch (e.keyCode) {
      case keyCode.DOWN:
        e.preventDefault()
        e.stopPropagation()
        // $FlowFixMe
        menuRef.current?.firstChild?.focus()
        break

      case keyCode.UP:
        e.preventDefault()
        e.stopPropagation()
        // $FlowFixMe
        menuRef.current?.lastChild?.focus()
        break

      case keyCode.ESCAPE:
        e.preventDefault()
        e.stopPropagation()
        hideContextMenu()
        handleClickAway()
        break

      default:
        break
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        id="ovm-contextmenu"
        className={classes.container}
        onClick={hideContextMenu}
        onContextMenu={(e) => {
          e.preventDefault()
        }}
        tabIndex="0"
        ref={(ref) => {
          menuRef.current = ref
        }}
        onKeyDown={onKeyDown}
        style={{
          top: clientY + 5,
          left: clientX + 5,
          ...style,
        }}
      >
        {itemsToChildrens(items)}
      </div>
    </ClickAwayListener>
  )
}

export { MenuItem } from './MenuItem.jsx'
export type { ContextMenuItemProps } from './MenuItem.jsx'
