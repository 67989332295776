// @flow
import uniqid from 'app/libs/uniqid.js'
import { map, mapValues, find, pickBy } from 'lodash'
import type { StoreState } from 'app/core/types'
import store from 'app/store/index.ts'
import { getResources } from './getResources'

export function getPostBoardLinksModels<T>(state: ?StoreState = store.getState(), paginatedList: string): T {
  const postBoardLinks = getResources(state, `postBoardLinks.paginatedLists.${paginatedList}`, undefined, [
    'assetInst.assetFlagsInst.flagInst',
    'assetInst.assetMediaGroupsInst.mediaGroupInst.mediasInst',
    'notesInst',
    'episodeInst',
  ])

  return mapValues(postBoardLinks, (postBoardLink) => {
    const postBoardNotes = postBoardLink.notesInst
    const stepProjects = pickBy(
      getResources(state, 'stepProjects', { project: postBoardLink.assetInst.project }, ['stepInst']),
      (stepProject) => stepProject.stepInst.assetType === 'mo',
    )
    const notesInst = {}

    const stepsWithGeneral = [
      {
        name: 'General',
        id: null,
      },
      ...map(stepProjects, (stepProject) => stepProject.stepInst),
    ]

    stepsWithGeneral.forEach((step) => {
      const existingNote = find(postBoardNotes, (note) => note.step === step.id)
      if (existingNote) {
        notesInst[existingNote.id] = existingNote
      } else {
        notesInst[`LOCAL_${uniqid()}`] = {
          step: step.id,
          link: postBoardLink.id,
          id: null,
          text: '',
        }
      }
    })

    return {
      ...postBoardLink,
      notesInst,
      rowId: postBoardLink.id,
    }
  })
}
