// $FlowFixMe
import moment from 'moment';
import { isAnAttributeOperator } from 'app/components/Table/hooks/useFiltersUtils';
import { listOperators } from 'app/components/Table/TableView/Elements/Filters/utils';
function formatTypeValue(value, operator, type) {
    let formatedValue;
    switch (type) {
        case 'date':
            formatedValue = moment(value).format('YYYY-MM-DD');
            break;
        case 'datetime':
            formatedValue = moment(value).set({ second: 0, millisecond: 0 }).utcOffset(0).toISOString().replace('.000', '');
            break;
        case 'time':
            formatedValue = `${value}:00`;
            break;
        case 'duration':
        case 'number':
            formatedValue = value ? Number(value) : 0;
            break;
        default:
            formatedValue = value;
            break;
    }
    if (isAnAttributeOperator(operator) && !listOperators(operator.value)) {
        formatedValue = JSON.stringify(formatedValue);
    }
    return formatedValue;
}
export const operators = {
    /* compte les colonnes qui remplissent la condition.
     * ex: tasks_takes_estimLength_count=134 => toutes les tasks
     * dont l'estimLength des takes sont egale à 134
     * types: *
     */
    count: {
        type: 'operator',
        value: 'count',
        label: 'Equal',
        serverKey: 'count',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    countGt: {
        type: 'operator',
        value: 'countGt',
        label: 'More than',
        serverKey: 'count_gt',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    countLt: {
        type: 'operator',
        value: 'countLt',
        label: 'Less than',
        serverKey: 'count_lt',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    countIsEmpty: {
        type: 'operator',
        value: 'countIsEmpty',
        label: 'Is empty',
        filterValue: 0,
        serverKey: 'count',
    },
    countIsNotEmpty: {
        type: 'operator',
        value: 'countIsNotEmpty',
        label: 'Is not empty',
        filterValue: 0,
        serverKey: 'count_gt',
    },
    /* ex: name_exact="toto" => retourne tout les name === a "toto"
     * types: *
     */
    exact: {
        type: 'operator',
        value: 'exact',
        label: 'Equal to',
        serverKey: 'exact',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    notExact: {
        type: 'operator',
        value: 'notExact',
        label: 'Not equal to',
        serverKey: 'exact!',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    /* La condition doit être exacte.
     * ex: name_exact="toto" => retourne tout les name === a "toto"
     * types: char, str
     */
    contain: {
        type: 'operator',
        value: 'icontains',
        label: 'Contain',
        serverKey: 'icontains',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    notContain: {
        type: 'operator',
        value: 'notIcontains',
        label: 'Not contain',
        serverKey: 'icontains!',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    /* ex: name_gt="b" => return c, d, ...
    /* ex: name_lt=5 => return 4, 3, ...
     * types: *
     */
    gt: {
        type: 'operator',
        value: 'gt',
        label: 'More than',
        serverKey: 'gt',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    gte: {
        type: 'operator',
        value: 'gte',
        label: 'More or equal than',
        serverKey: 'gte',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    lt: {
        type: 'operator',
        value: 'lt',
        label: 'Less than',
        serverKey: 'lt',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    lte: {
        type: 'operator',
        value: 'lte',
        label: 'Less or equal than',
        serverKey: 'lte',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    /* ex: attributes__hasKey="category" => return all assets who have key category
     * types: JSON
     */
    hasKey: {
        type: 'operator',
        value: 'hasKey',
        label: 'Exist',
        serverKey: 'has_key',
    },
    hasNotKey: {
        type: 'operator',
        value: 'hasNotKey',
        label: 'Not exist',
        serverKey: 'has_key!',
    },
    /* ex: asset_name__in="toto,tata,titi", => return all assets who dont have name in toto, tata or titi
     * types: *
     */
    in: {
        type: 'operator',
        value: 'in',
        label: 'Included in',
        serverKey: 'in',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    notIn: {
        type: 'operator',
        value: 'notIn',
        label: 'Not included in',
        serverKey: 'in!',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    inList: {
        type: 'operator',
        value: 'inList',
        label: 'In List',
        serverKey: 'in',
        formatValue: (value, operator) => {
            return formatTypeValue(value, operator, operator.valueType || operator.inputType);
        },
    },
    notInList: {
        type: 'operator',
        value: 'notInList',
        label: 'Not in list',
        serverKey: 'in!',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    /* ex: asset_comment__isEmpty=true => return all assets who have comment or just html without text
     * types: text, html
     */
    isEmpty: {
        type: 'operator',
        value: 'isEmpty',
        label: 'Is empty',
        filterValue: true,
        serverKey: 'isempty',
    },
    isNotEmpty: {
        type: 'operator',
        value: 'isNotEmpty',
        label: 'Is not empty',
        filterValue: false,
        serverKey: 'isempty',
    },
    /* ex: asset_comment__isNull=true => return all assets who have comment
     * types: *
     */
    isNull: {
        type: 'operator',
        value: 'isNull',
        label: 'Is null',
        filterValue: true,
        serverKey: 'isnull',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    isNotNull: {
        type: 'operator',
        value: 'isNotNull',
        label: 'Is not null',
        filterValue: false,
        serverKey: 'isnull',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    /* ex: asset_flags__relationEmpty=true => return all assets who dont have flags
     * types: *
     */
    relationEmpty: {
        type: 'operator',
        value: 'relationEmpty',
        label: 'Is empty',
        serverKey: 'relationempty',
    },
    relationNotEmpty: {
        type: 'operator',
        value: 'relationNotEmpty',
        label: 'Is not empty',
        serverKey: 'relationempty!',
    },
    /* additionne les case d'une colonne qui remplissent la condition.
     * ex: tasks_takes_estimLength_sum=134 => toutes les tasks
     * dont la somme des estimLength des takes sont egale à 134
     * types: numbers
     */
    sum: {
        type: 'operator',
        value: 'sum',
        label: 'Equal',
        serverKey: 'sum',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    sumGt: {
        type: 'operator',
        value: 'sumGt',
        label: 'More than',
        serverKey: 'sum_gt',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    sumLt: {
        type: 'operator',
        value: 'sumLt',
        label: 'Less than',
        serverKey: 'sum_lt',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    /* ex: tasks_startDate__range="13/02/2018,20/02/2018" => return all tasks
     * who have startDate between 13/02/2018 and 20/02/2018
     * types: *
     */
    range: {
        type: 'operator',
        value: 'range',
        label: 'In interval',
        serverKey: 'range',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    /* types: date
     */
    dateGt: {
        type: 'operator',
        value: 'dateGt',
        label: 'After',
        serverKey: 'gt',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    dateLt: {
        type: 'operator',
        value: 'dateLt',
        label: 'Before',
        serverKey: 'lt',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    dateGte: {
        type: 'operator',
        value: 'dateGte',
        label: 'During or after',
        serverKey: 'gte',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    dateLte: {
        type: 'operator',
        value: 'dateLte',
        label: 'During or before',
        serverKey: 'lte',
        formatValue: (value, operator) => formatTypeValue(value, operator, operator.valueType || operator.inputType),
    },
    // custom dates
    today: {
        type: 'operator',
        value: 'today',
        filterValue: true,
        label: 'Today',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment().startOf('day'), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment().endOf('day'), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    now: {
        type: 'operator',
        value: 'now',
        filterValue: false,
        label: 'Now',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment(), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment().add(1, 'day'), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    next1w: {
        type: 'operator',
        value: 'now+1w',
        filterValue: false,
        label: 'Next 1 week',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment(), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment().add(1, 'week'), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    next2w: {
        type: 'operator',
        value: 'now+2w',
        filterValue: false,
        label: 'Next 2 weeks',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment(), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment().add(2, 'week'), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    next1m: {
        type: 'operator',
        value: 'now+1m',
        filterValue: false,
        label: 'Next 1 month',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment(), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment().add(1, 'month'), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    next2m: {
        type: 'operator',
        value: 'now+2m',
        filterValue: false,
        label: 'Next 2 months',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment(), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment().add(2, 'month'), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    last1w: {
        type: 'operator',
        value: 'now-1w',
        filterValue: false,
        label: 'Last week',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment().subtract(1, 'week'), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment(), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    last2w: {
        type: 'operator',
        value: 'now-2w',
        filterValue: false,
        label: 'Last 2 weeks',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment().subtract(2, 'week'), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment(), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    last1m: {
        type: 'operator',
        value: 'now-1m',
        filterValue: false,
        label: 'Last month',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment().subtract(1, 'month'), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment(), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    last2m: {
        type: 'operator',
        value: 'now-2m',
        filterValue: false,
        label: 'Last 2 months',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment().subtract(2, 'month'), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment(), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    thisMonth: {
        type: 'operator',
        value: 'thisMonth',
        filterValue: false,
        label: 'This month',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment().startOf('month'), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment().endOf('month'), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    thisYear: {
        type: 'operator',
        value: 'thisYear',
        filterValue: false,
        label: 'This year',
        serverKey: 'range',
        formatValue: (value, operator) => {
            const values = [
                formatTypeValue(moment().startOf('year'), operator, operator.valueType || operator.inputType),
                formatTypeValue(moment().endOf('year'), operator, operator.valueType || operator.inputType),
            ];
            if (isAnAttributeOperator(operator))
                return JSON.stringify(values);
            return values.toString();
        },
    },
    isTrue: {
        type: 'operator',
        value: 'isTrue',
        filterValue: true,
        label: 'Is true',
        serverKey: 'exact',
    },
    isFalse: {
        type: 'operator',
        value: 'isFalse',
        filterValue: false,
        label: 'Is false',
        serverKey: 'exact',
    },
};
const op = operators;
export const presetOperators = {
    list: [op.inList, op.notInList],
    str: [op.contain, op.notContain, op.exact, op.notExact, op.in, op.notIn],
    text: [op.contain, op.notContain],
    number: [op.gt, op.gte, op.lt, op.lte, op.exact, op.notExact, op.range],
    date: [
        op.dateGt,
        op.dateLt,
        op.dateGte,
        op.dateLte,
        op.exact,
        op.notExact,
        op.in,
        op.notIn,
        op.range,
        op.today,
        op.now,
        op.next1w,
        op.next2w,
        op.next1m,
        op.next2m,
        op.last1w,
        op.last2w,
        op.last1m,
        op.last2m,
        op.thisMonth,
        op.thisYear,
    ],
    boolean: [op.isTrue, op.isFalse],
    sum: [op.sum, op.sumGt, op.sumLt],
    count: [op.count, op.countGt, op.countLt],
    relation: [op.relationEmpty, op.relationNotEmpty],
    empty: [op.isEmpty, op.isNotEmpty],
    isNull: [op.isNull, op.isNotNull],
    exact: [op.exact, op.notExact],
};
