// @flow
// prettier-ignore
export const permissionKeys = {
  'actions-detail': 'actions-detail',
  'actions-list': 'actions-list',
  'activities-detail': 'activities-detail',
  'activities-list': 'activities-list',
  'api-root': 'api-root',
  'approvalStatus-detail': 'approvalStatus-detail',
  'approvalStatus-list': 'approvalStatus-list',
  'approvalStatusValues-detail': 'approvalStatusValues-detail',
  'approvalStatusValues-list': 'approvalStatusValues-list',
  'approvalWorkflow-approvalStatus-detail': 'approvalWorkflow-approvalStatus-detail',
  'approvalWorkflow-approvalStatus-list': 'approvalWorkflow-approvalStatus-list',
  'approvalWorkflows-approvalStatus': 'approvalWorkflows-approvalStatus',
  'approvalWorkflows-detail': 'approvalWorkflows-detail',
  'approvalWorkflows-graph': 'approvalWorkflows-graph',
  'approvalWorkflows-list': 'approvalWorkflows-list',
  'article-flags-detail': 'article-flags-detail',
  'article-flags-list': 'article-flags-list',
  'article-medias-detail': 'article-medias-detail',
  'article-medias-list': 'article-medias-list',
  'articleContents-detail': 'articleContents-detail',
  'articleContents-list': 'articleContents-list',
  'articles-children': 'articles-children',
  'articles-detail': 'articles-detail',
  'articles-lastVersion': 'articles-lastVersion',
  'articles-list': 'articles-list',
  'articles-versions': 'articles-versions',
  'asset-flags-detail': 'asset-flags-detail',
  'asset-flags-list': 'asset-flags-list',
  'asset-links-detail': 'asset-links-detail',
  'asset-links-list': 'asset-links-list',
  'asset-mediaGroups-detail': 'asset-mediaGroups-detail',
  'asset-mediaGroups-list': 'asset-mediaGroups-list',
  'assetBookmarks-detail': 'assetBookmarks-detail',
  'assetBookmarks-list': 'assetBookmarks-list',
  'assetLinkTypes-detail': 'assetLinkTypes-detail',
  'assetLinkTypes-list': 'assetLinkTypes-list',
  'assets-activities': 'assets-activities',
  'assets-articles': 'assets-articles',
  'assets-bookmarks': 'assets-bookmarks',
  'assets-children': 'assets-children',
  'assets-childrenWithLinks': 'assets-childrenWithLinks',
  'assets-childrenWithPostBoardLinks': 'assets-childrenWithPostBoardLinks',
  'assets-customAttributValues': 'assets-customAttributValues',
  'assets-detail': 'assets-detail',
  'assets-flags': 'assets-flags',
  'assets-fullPath': 'assets-fullPath',
  'assets-links': 'assets-links',
  'assets-list': 'assets-list',
  'assets-mediaGroups': 'assets-mediaGroups',
  'assets-postBoardLinks': 'assets-postBoardLinks',
  'assets-rootFolders': 'assets-rootFolders',
  'assets-shortPath': 'assets-shortPath',
  'assets-tasks': 'assets-tasks',
  'assets-toc': 'assets-toc',
  'assets-topics': 'assets-topics',
  'assets-usage': 'assets-usage',
  'attributes-detail': 'attributes-detail',
  'attributes-list': 'attributes-list',
  'auth-login': 'auth-login',
  'auth-user': 'auth-user',
  'bookmarks-childs': 'bookmarks-childs',
  'bookmarks-detail': 'bookmarks-detail',
  'bookmarks-list': 'bookmarks-list',
  'bookmarks-sharedWith': 'bookmarks-sharedWith',
  'checkSubscription': 'checkSubscription',
  'config': 'config',
  'contracts-detail': 'contracts-detail',
  'contracts-list': 'contracts-list',
  'endpoints': 'endpoints',
  'episodes-seqBd': 'episodes-seqBd',
  'episodes-shotBd': 'episodes-shotBd',
  'filters-detail': 'filters-detail',
  'filters-list': 'filters-list',
  'flags-assets': 'flags-assets',
  'flags-detail': 'flags-detail',
  'flags-list': 'flags-list',
  'flags-takes': 'flags-takes',
  'groupPermissions-detail': 'groupPermissions-detail',
  'groupPermissions-list': 'groupPermissions-list',
  'groupUsers-detail': 'groupUsers-detail',
  'groupUsers-list': 'groupUsers-list',
  'groups-detail': 'groups-detail',
  'groups-list': 'groups-list',
  'groups-users': 'groups-users',
  'mediaGroups-detail': 'mediaGroups-detail',
  'mediaGroups-list': 'mediaGroups-list',
  'medias-detail': 'medias-detail',
  'medias-list': 'medias-list',
  'messages-detail': 'messages-detail',
  'messages-list': 'messages-list',
  'notifications-detail': 'notifications-detail',
  'post-medias-detail': 'post-medias-detail',
  'post-medias-list': 'post-medias-list',
  'postBoardLinks-detail': 'postBoardLinks-detail',
  'postBoardLinks-list': 'postBoardLinks-list',
  'postBoardNotes-detail': 'postBoardNotes-detail',
  'postBoardNotes-list': 'postBoardNotes-list',
  'posts-detail': 'posts-detail',
  'posts-list': 'posts-list',
  'privateData-medias-detail': 'privateData-medias-detail',
  'privateData-medias-list': 'privateData-medias-list',
  'privateDatas-detail': 'privateDatas-detail',
  'privateDatas-list': 'privateDatas-list',
  'progressionStatus-detail': 'progressionStatus-detail',
  'progressionStatus-list': 'progressionStatus-list',
  'projects-episodes': 'projects-episodes',
  'projects-groups': 'projects-groups',
  'projects-steps': 'projects-steps',
  'projects-tasks': 'projects-tasks',
  'projects-users': 'projects-users',
  'publicMessages': 'publicMessages',
  'search': 'search',
  'searchAndReplaceBreakdown': 'searchAndReplaceBreakdown',
  'searchAndAddBreakdown': 'searchAndAddBreakdown',
  'searchAndDeleteBreakdown': 'searchAndDeleteBreakdown',
  'stepGraphs-detail': 'stepGraphs-detail',
  'stepGraphs-list': 'stepGraphs-list',
  'stepPaths-detail': 'stepPaths-detail',
  'stepPaths-list': 'stepPaths-list',
  'stepProjects-detail': 'stepProjects-detail',
  'stepProjects-list': 'stepProjects-list',
  'stepProjects-updateRank': 'stepProjects-updateRank',
  'stepWorkflows-detail': 'stepWorkflows-detail',
  'stepWorkflows-list': 'stepWorkflows-list',
  'steps-detail': 'steps-detail',
  'steps-list': 'steps-list',
  'stepsWorkflowsAssociations-detail': 'stepsWorkflowsAssociations-detail',
  'stepsWorkflowsAssociations-list': 'stepsWorkflowsAssociations-list',
  'subscriptions-detail': 'subscriptions-detail',
  'subscriptions-list': 'subscriptions-list',
  'take-flags-detail': 'take-flags-detail',
  'take-flags-list': 'take-flags-list',
  'take-refMedias-detail': 'take-refMedias-detail',
  'take-refMedias-list': 'take-refMedias-list',
  'take-validationMedias-detail': 'take-validationMedias-detail',
  'take-validationMedias-list': 'take-validationMedias-list',
  'takes-detail': 'takes-detail',
  'takes-list': 'takes-list',
  'task-linkedTasks-detail': 'task-linkedTasks-detail',
  'task-linkedTasks-list': 'task-linkedTasks-list',
  'task-requires-detail': 'task-requires-detail',
  'task-requires-list': 'task-requires-list',
  'task-viewers-detail': 'task-viewers-detail',
  'task-viewers-list': 'task-viewers-list',
  'taskType-statusAvailable-detail': 'taskType-statusAvailable-detail',
  'taskType-statusAvailable-list': 'taskType-statusAvailable-list',
  'tasks-activities': 'tasks-activities',
  'tasks-children': 'tasks-children',
  'tasks-currentApproval': 'tasks-currentApproval',
  'tasks-detail': 'tasks-detail',
  'tasks-list': 'tasks-list',
  'tasks-takes': 'tasks-takes',
  'thread-moderators-detail': 'thread-moderators-detail',
  'thread-moderators-list': 'thread-moderators-list',
  'thread-participants-detail': 'thread-participants-detail',
  'thread-participants-list': 'thread-participants-list',
  'threads-detail': 'threads-detail',
  'threads-lastReadedPost': 'threads-lastReadedPost',
  'threads-list': 'threads-list',
  'threads-posts': 'threads-posts',
  'topics-detail': 'topics-detail',
  'topics-list': 'topics-list',
  'topics-threads': 'topics-threads',
  'updatePermissions': 'updatePermissions',
  'upload': 'upload',
  'upload-b64': 'upload-b64',
  'users-actions': 'users-actions',
  'users-activities': 'users-activities',
  'users-feedActions': 'users-feedActions',
  'users-feeds': 'users-feeds',
  'users-groups': 'users-groups',
  'users-notifications': 'users-notifications',
  'users-projects': 'users-projects',
  'users-tasks': 'users-tasks',
  'users-validations': 'users-validations',
  'viewSchemas-detail': 'viewSchemas-detail',
  'viewSchemas-list': 'viewSchemas-list',
  'votes-detail': 'votes-detail',
  'votes-list': 'votes-list',
}

export const actions = {
  create: 'create',
  update: 'update',
  read: 'read',
  delete: 'delete',
}

export type PermKeyType = $Keys<typeof permissionKeys>

export type PermValueType = $Keys<typeof actions>

export type PermType = {
  [permKey: PermKeyType]: Array<PermValueType>,
}
