// @flow

import cx from 'classnames'
import iconsClassName from 'app/styles/fonts/overmind-icons/overmind-icons.module.scss'
import { iconsClassName as OVMIcons } from 'app/styles/iconsList'
import type { IconType } from 'app/core/types'
import FA from 'app/styles/fonts/fontAwesome/FA.js'

export const icons = {
  ...FA,
  ...OVMIcons,
}

// $FlowFixMe
export const getClassName = (icon: IconType) => cx(iconsClassName[OVMIcons[icon]], icons[icon])
