/** @flow */
import { useEffect } from 'react'

type Props = {|
  width: number,
  height: number,
  setter: (number) => void,
  ref: React$ElementRef<any>,
|}

export const useResize = (props: Props): void => {
  const { width, height, ref, setter } = props

  useEffect(() => {
    const el = ref.current
    if (el) {
      const observer = new ResizeObserver(([e]) => {
        const { clientHeight, clientWidth } = e.target

        const minWidth = Math.floor(clientWidth / width)
        const minHeight = Math.floor(clientHeight / height)

        setter(minWidth * minHeight)
      })
      if (el) observer.observe(el)
      return () => {
        if (observer) observer.disconnect()
      }
    }
    return undefined
  }, [width, height, ref])
}
