// @flow
import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { PostBoardLinksActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const postBoardLinks: StoreResourceDef<PostBoardLinksActions<>> = {
  resourceType: 'postBoardLinks',
  actions: (actionsCreators) => ({
    fetchAllAndStoreDependentInsts: (episodeId, config = {}) => {
      const { params = {} } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.assets
            .postBoardLinks(
              { id: episodeId },
              { ordering: 'timestamp', ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(config)),
      }
    },
    fetchLinksOfAsset: (assetId, config) => {
      const { params = {}, ...restConfig } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.postBoardLinks
            .fetchAll(
              { asset: assetId, ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(restConfig)),
      }
    },
  }),
  relations: {
    assetInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'asset',
    },
    notesInst: {
      type: 'hasMany',
      resourceType: 'postBoardNotes',
      foreignKey: 'link',
    },
    episodeInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'episode',
    },
  },
}
