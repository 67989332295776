// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__container .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
  .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__container .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__scroll {
    height: 100%;
    position: relative;
    overflow-y: auto;
  }
  .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__container .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__scroll .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__grid {
      display: grid;
      grid-template-columns: repeat(9, fit-content(100%));
    }
  .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__container .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__scroll .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__grid .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__header {
        position: sticky;
        top: 0;
        background-color: #d5d5d5;
      }
  .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__container .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__scroll .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__grid .app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__cell {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 6px;
      }
`, "",{"version":3,"sources":["webpack://./app/pages/HR/HRSettings/PeopleTableEditor/PersonAttributesEditor/ModalGroupsAuthorizations/ModalGroupsAuthorizations.module.scss"],"names":[],"mappings":"AACE;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;EACjB;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;EAmBlB;EAjBE;MACE,aAAa;MACb,mDAAmD;IAcrD;EAZE;QACE,gBAAgB;QAChB,MAAM;QACN,yBAAyB;MAC3B;EAEA;QACE,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,gBAAgB;MAClB","sourcesContent":[".container {\n  .toolbar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 0;\n  }\n\n  .scroll {\n    height: 100%;\n    position: relative;\n    overflow-y: auto;\n\n    .grid {\n      display: grid;\n      grid-template-columns: repeat(9, fit-content(100%));\n\n      .header {\n        position: sticky;\n        top: 0;\n        background-color: #d5d5d5;\n      }\n\n      .cell {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        padding: 3px 6px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__container`,
	"toolbar": `app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__toolbar`,
	"scroll": `app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__scroll`,
	"grid": `app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__grid`,
	"header": `app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__header`,
	"cell": `app-pages-HR-HRSettings-PeopleTableEditor-PersonAttributesEditor-ModalGroupsAuthorizations-___ModalGroupsAuthorizations-module__cell`
};
export default ___CSS_LOADER_EXPORT___;
