import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import getIconClass from 'font-awesome-filetypes';
import { getMediaExtension, getGroupExtension } from 'app/containers/Medias/utils';
import { colors } from 'app/styles/colors';
import classes from './MediaItem.module.scss';
import { Overlay } from './MediaOverlay.jsx';
export function MediaItem(props) {
    const { onDelete, itemStyle, style, medias, className, disableOverlay, media: _media, allowPinMedia, allowValidateMedia, } = props;
    const media = useSelector((state) => {
        if (!(_media === null || _media === void 0 ? void 0 : _media.id))
            return _media;
        return state.medias.resources[_media.id];
    });
    if (!media)
        return null;
    const extension = getMediaExtension(media);
    const mediaGroup = getGroupExtension(extension);
    let folder;
    if (medias) {
        folder = filter(medias, (_media) => {
            const mediaGroup = getGroupExtension(getMediaExtension(_media));
            return mediaGroup === 'image' && _media.url !== media.url;
        });
        if (mediaGroup === 'image')
            folder.unshift(media);
    }
    const extensionIcon = getIconClass(extension || '');
    const splitedClass = extensionIcon.split('-');
    splitedClass[0] = `${splitedClass[0]}s`;
    return (_jsxs("div", { className: `${classes.media} ${className || ''}`, style: style, children: [_jsxs("div", { className: classes.pinnedIconContainer, children: [media.pinned ? (_jsx("div", { className: classes.pinnedIcon, children: _jsx(FontIcon, { icon: "fas-thumbtack" }) })) : null, media.validated ? (_jsx("div", { className: classes.pinnedIcon, style: { color: colors.green, left: 23 }, children: _jsx(FontIcon, { icon: "fas-check" }) })) : null] }), _jsxs("div", { className: "flex center relative", children: [_jsxs("div", { className: classes.other, style: itemStyle, children: [mediaGroup === 'image' ? (_jsx("div", { className: classes.imageContainer, children: _jsx("img", { src: media.thumbnail || media.url, className: classes.image }) })) : (_jsx("div", { style: { fontSize: 40, marginBottom: 5 }, children: _jsx(FontIcon, { icon: splitedClass.join('-') }) })), _jsxs("span", { style: { fontSize: 11, wordBreak: 'break-all', maxWidth: '100%', whiteSpace: 'pre-wrap' }, children: [media.name || '', media.id && extension && !media.name.endsWith(extension) ? `.${extension}` : null] })] }), !disableOverlay ? (_jsx(Overlay, { media: media, folder: mediaGroup === 'image' ? folder : undefined, onDelete: onDelete ? (e) => onDelete(media) : undefined, allowPinMedia: allowPinMedia, allowValidateMedia: allowValidateMedia })) : null] })] }));
}
