/** @flow */
import React, { useEffect, useMemo, useState } from 'react'
import { filter, uniq, forEach, map } from 'lodash'
import { MUIModal, type ModalProps } from 'app/components/Modal'
import { AssetsSelectMultiple } from 'app/containers/Assets/AssetsSelectMultiple/AssetsSelectMultiple.jsx'
import type { ResourcesList, ID, AssetLink, AssetLinkTypes, Asset, Option as DefaultOption } from 'app/core/types'
import { getResources } from 'app/store/selectors'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { MUIButton } from 'app/components/Form'
import vars from 'app/styles/vars.js'
import resources from 'app/store/resources'
import store from 'app/store/index.ts'

import classes from './ModalLinks.module.scss'

const { colors } = vars

export type Option = { ...$Exact<DefaultOption>, count: number, data: Asset }

type Props = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  fromAssetsId: Array<ID>,
  onChange?: ({
    toCreate: Array<{ ...$Rest<AssetLink, { id: ID }>, assetInst: Asset }>,
    toDelete: Array<ID>,
  }) => Promise<any>,
  categories?: Array<string>,
  title: string,
  open?: boolean,
  selectedCells: ?{ [string]: Object },
  value?: ?Array<AssetLink>,
  exponentTitle?: string,
|}

export function ModalLinks(props: Props): React$Node {
  const { fromAssetsId, categories, title, onChange: _onChange, selectedCells, value, ...rest } = props
  const [assets, setAssets] = useState([])
  const [prevAssets, setPrevAssets] = useState([])
  const [assetLinkType, setAssetLinkType] = useState<AssetLinkTypes | void>()

  const multipleCells = useMemo(() => (selectedCells ? Object.keys(selectedCells).length > 1 : false), [])

  useEffect(() => {
    resources.assetLinkTypes.fetchAll().then(() => {
      const state = store.getState()
      setAssetLinkType(map(state.assetLinkTypes.resources).find((resource) => resource.name === 'breakdown'))
    })
  }, [])

  const assetsLinks = useMemo(
    () =>
      !multipleCells
        ? value ||
          filter(
            getResources<ResourcesList<AssetLink>>(undefined, 'assetLinks', undefined, ['to_assetInst']),
            (link) =>
              fromAssetsId.includes(link.from_asset) &&
              (categories ? categories.includes(link.to_assetInst.attributes.category) : true),
          )
        : [],
    [],
  )

  const computeAssets = (assets: Array<Option>) => {
    const nextAssets = []
    assets.forEach((asset) => {
      const existingAsset = nextAssets.find(({ data }) => data.id === asset.data.id)
      if (existingAsset) existingAsset.count += 1
      else nextAssets.push({ ...asset, count: asset.count || 1 })
    })
    return nextAssets
  }

  useEffect(() => {
    const options = assetsLinks.map((link) => ({
      label: link.to_assetInst.name,
      value: link.to_assetInst.id,
      data: link.to_assetInst,
      count: 0,
    }))
    const _assets = computeAssets(options)
    setAssets(_assets)
    setPrevAssets(_assets)
  }, [assetsLinks])

  const onChange = (assets: Array<Option>) => {
    setAssets(computeAssets(assets))
  }

  const onValidate = (type?: 'replace' | 'delete'): Promise<any> => {
    const linksToCreate = []
    let linksToDelete = []

    if (multipleCells && type === 'delete') {
      const assetsIdsToDelete = assets.map((asset) => asset.data.id)
      forEach(selectedCells, (cell) => {
        linksToDelete = [
          ...linksToDelete,
          ...cell.value.filter((link) => assetsIdsToDelete.includes(link.to_asset)).map((link) => link.id),
        ]
      })
    } else if (multipleCells) {
      if (type === 'replace') {
        forEach(selectedCells, (cell) => {
          linksToDelete = [...linksToDelete, ...cell.value.map((link) => link.id)]
        })
      }

      assets.forEach((item) => {
        uniq(fromAssetsId).forEach((fromAssetId) => {
          for (let index = 0; index < item.count || 0; index += 1) {
            if (assetLinkType) {
              linksToCreate.push({
                assetInst: item.data,
                from_asset: fromAssetId,
                linkType: assetLinkType.id,
                to_asset: item.data.id,
              })
            }
          }
        })
      })
    } else {
      let linksHandled = assets

      linksHandled = assets.map((item) => {
        const linksExisting = assetsLinks.filter((link) => link.to_asset === item.data.id)
        if (!linksExisting.length) return item
        return { ...item, count: item.count - linksExisting.length }
      })

      // get full delete
      const assetsDeleted = prevAssets
        .filter((item) => {
          return !assets.find((_asset) => _asset.data.id === item.data.id)
        })
        .map((item) => ({ ...item, count: -item.count }))

      linksHandled = [...linksHandled, ...assetsDeleted]

      linksHandled.forEach((link) => {
        if (link.count > 0) {
          for (let i = 0; i < link.count; i += 1) {
            if (assetLinkType) {
              uniq(fromAssetsId).forEach((fromAssetId) => {
                linksToCreate.push({
                  assetInst: link.data,
                  from_asset: fromAssetId,
                  linkType: assetLinkType.id,
                  to_asset: link.data.id,
                })
              })
            }
          }
        } else if (link.count < 0) {
          // links to delete
          const linksExisting = assetsLinks.filter((_link) => _link.to_asset === link.data.id)
          for (let i = 0; i < Math.abs(link.count); i += 1) {
            linksToDelete.push(linksExisting[i].id)
          }
        }
      })
    }

    if (_onChange) return _onChange({ toCreate: linksToCreate, toDelete: linksToDelete })

    const promises = []

    if (linksToCreate.length) promises.push(resources.assetLinks.create(linksToCreate))
    if (linksToDelete.length) promises.push(resources.assetLinks.delete(linksToDelete))
    return Promise.all(promises)
  }

  const updateCount = (asset: Option, value: number) => {
    const index = assets.findIndex(({ value }) => value === asset.value)
    const _assets = assets.map((asset, i) => {
      if (i === index) return { ...asset, count: asset.count + value }
      return asset
    })
    setAssets(_assets)
  }

  const onClickIncrement = (asset: Option) => updateCount(asset, 1)
  const onClickDecrement = (asset: Option) => updateCount(asset, -1)
  const renderIncrementDecrement = (asset: Option) => {
    return [
      <span key="1" className={classes.increment} onClick={() => onClickIncrement(asset)}>
        +
      </span>,
      <span key="2" className={classes.increment} onClick={() => onClickDecrement(asset)}>
        -
      </span>,
    ]
  }

  const renderLabel = (asset: Option) => {
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: asset.label }} />
        &nbsp;(x{asset.count || '1'}){renderIncrementDecrement(asset)}
      </div>
    )
  }

  return (
    <MUIModal
      title={title}
      onValidate={() => onValidate()}
      draggable={true}
      resizable={true}
      validateLabel={multipleCells ? 'Merge' : 'Save'}
      extendsButtons={
        multipleCells
          ? ({ onRequestClose }) => [
              <MUIButton
                key="delete"
                bgColor={colors.red}
                onClick={() => onValidate('delete').then(onRequestClose)}
                style={{ marginRight: 8 }}
              >
                Delete
              </MUIButton>,
              <MUIButton key="replace" bgColor={colors.blue} onClick={() => onValidate('replace').then(onRequestClose)}>
                Replace
              </MUIButton>,
            ]
          : undefined
      }
      {...rest}
    >
      <AssetsSelectMultiple
        placeholder="Search assets"
        autoFocus={true}
        assetTypes={['mo']}
        assets={assets.filter((item) => item.count)}
        onChange={onChange}
        style={{ height: '100%' }}
        showAssetCateg={true}
        renderLabel={renderLabel}
        renderDeleteIcon={({ onDelete }) => (
          <FontIcon
            icon="trash"
            style={{ padding: 4, cursor: 'pointer' }}
            onClick={onDelete}
            className={classes.trashIcon}
          />
        )}
        filterByAttribut={categories ? 'category' : ''}
        filterByAttributValue={categories}
      />
    </MUIModal>
  )
}
