// @flow
import { pickBy } from 'lodash'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import type { ResourcesList, Asset } from 'app/core/types'
import store from '../index.ts'

export const getEpisodes = (state?: Object): ResourcesList<Asset> => {
  if (!state) state = store.getState()
  return pickBy(state.assets.resources, (asset) => asset.assetType === 'ep' && asset.project === getProjectIdFromURL())
}
