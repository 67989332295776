// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellSmartDate-___CellSmartDate-module__input {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellSmartDate/CellSmartDate.module.scss"],"names":[],"mappings":"AAAA;EACE;wCACsC;AACxC","sourcesContent":[".input {\n  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),\n    0px 3px 14px 2px rgba(0, 0, 0, 0.12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `app-components-Table-Cells-CellSmartDate-___CellSmartDate-module__input`
};
export default ___CSS_LOADER_EXPORT___;
