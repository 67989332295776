// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Select-___MUISelect-module__hidden {
  max-height: 1px;
  overflow: hidden;
}

.app-components-Form-Select-___MUISelect-module__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 25px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Select/MUISelect.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,UAAU;EACV,eAAe;AACjB","sourcesContent":[".hidden {\n  max-height: 1px;\n  overflow: hidden;\n}\n\n.loader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 25px;\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: 0;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `app-components-Form-Select-___MUISelect-module__hidden`,
	"loader": `app-components-Form-Select-___MUISelect-module__loader`
};
export default ___CSS_LOADER_EXPORT___;
