// @flow

export default (
  date: Date,
  language: string = navigator.language,
  options: Object = {},
  type: string = 'datetime',
): string => {
  const _options = {
    year: type === 'date' || type === 'datetime' ? 'numeric' : undefined,
    month: type === 'date' || type === 'datetime' ? 'short' : undefined,
    day: type === 'date' || type === 'datetime' ? 'numeric' : undefined,
    hour: type === 'datetime' || type === 'time' ? 'numeric' : undefined,
    minute: type === 'datetime' || type === 'time' ? 'numeric' : undefined,
    ...options,
  }

  return date.toLocaleString(language, _options)
}
