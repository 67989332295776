// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-MyHours-___MyHours-module__container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.app-pages-MyHours-___MyHours-module__title {
  flex: none;
  font-size: 24px;
  margin: auto;
  margin-top: 10px;
  align-content: center;
  color: var(--colors-blackLight);
  height: 32px;
}

.app-pages-MyHours-___MyHours-module__leftCalendar {
  flex: none;
  height: 100%;
}

.app-pages-MyHours-___MyHours-module__rightItems {
  flex: 1;
  color: var(--colors-blackLight);
}

.app-pages-MyHours-___MyHours-module__tableActivities {
  flex: 1;
}

.app-pages-MyHours-___MyHours-module__noDuration input {
  color: var(--colors-greyLight);
}

.app-pages-MyHours-___MyHours-module__cellEditing {
  color: var(--colors-blackLight);
}

.app-pages-MyHours-___MyHours-module__cellEditing input {
  color: var(--colors-blackLight);
}

.app-pages-MyHours-___MyHours-module__totalPerTask {
  font-weight: bold;
}

.app-pages-MyHours-___MyHours-module__rowFooter {
  font-weight: bold;
  background-color: var(--colors-greyExtraLight);
  height: 45px;
}

.app-pages-MyHours-___MyHours-module__tableTotal {
  flex: 1;
}

.app-pages-MyHours-___MyHours-module__tableTasks {
  height: 100%;
}

.app-pages-MyHours-___MyHours-module__infoButton {
  font-size: 20px;
  margin: 0 10px;
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.app-pages-MyHours-___MyHours-module__infoButton:hover {
  color: var(--colors-mainColor);
}
`, "",{"version":3,"sources":["webpack://./app/pages/MyHours/MyHours.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;EACrB,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,OAAO;EACP,+BAA+B;AACjC;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,8CAA8C;EAC9C,YAAY;AACd;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;EACd,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".container {\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n}\n\n.title {\n  flex: none;\n  font-size: 24px;\n  margin: auto;\n  margin-top: 10px;\n  align-content: center;\n  color: var(--colors-blackLight);\n  height: 32px;\n}\n\n.leftCalendar {\n  flex: none;\n  height: 100%;\n}\n\n.rightItems {\n  flex: 1;\n  color: var(--colors-blackLight);\n}\n\n.tableActivities {\n  flex: 1;\n}\n\n.noDuration input {\n  color: var(--colors-greyLight);\n}\n\n.cellEditing {\n  color: var(--colors-blackLight);\n}\n\n.cellEditing input {\n  color: var(--colors-blackLight);\n}\n\n.totalPerTask {\n  font-weight: bold;\n}\n\n.rowFooter {\n  font-weight: bold;\n  background-color: var(--colors-greyExtraLight);\n  height: 45px;\n}\n\n.tableTotal {\n  flex: 1;\n}\n\n.tableTasks {\n  height: 100%;\n}\n\n.infoButton {\n  font-size: 20px;\n  margin: 0 10px;\n  display: flex;\n  align-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.infoButton:hover {\n  color: var(--colors-mainColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-MyHours-___MyHours-module__container`,
	"title": `app-pages-MyHours-___MyHours-module__title`,
	"leftCalendar": `app-pages-MyHours-___MyHours-module__leftCalendar`,
	"rightItems": `app-pages-MyHours-___MyHours-module__rightItems`,
	"tableActivities": `app-pages-MyHours-___MyHours-module__tableActivities`,
	"noDuration": `app-pages-MyHours-___MyHours-module__noDuration`,
	"cellEditing": `app-pages-MyHours-___MyHours-module__cellEditing`,
	"totalPerTask": `app-pages-MyHours-___MyHours-module__totalPerTask`,
	"rowFooter": `app-pages-MyHours-___MyHours-module__rowFooter`,
	"tableTotal": `app-pages-MyHours-___MyHours-module__tableTotal`,
	"tableTasks": `app-pages-MyHours-___MyHours-module__tableTasks`,
	"infoButton": `app-pages-MyHours-___MyHours-module__infoButton`
};
export default ___CSS_LOADER_EXPORT___;
