/** @flow */
import React from 'react'
import moment from 'moment'
import type { Asset, ID, Pipe, Activity } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import { MUIModal } from 'app/components/Modal'
import { TableTasks } from 'app/pages/Project/Tasks/TableTasks.tsx'
import { getResources } from 'app/store/selectors'
import resources from '../../../store/resources'

type Props = {|
  user: Asset,
  date: Date,
  onAddActivities?: (activities: Array<Activity>) => void,
|}

function ModalAddActivitiesForUserView(props: Props): React$Node {
  const { date, user, onAddActivities, ...rest } = props

  return (
    <MUIModal
      title={`Add activities for ${user.name} ${date ? ` - ${moment(date).format('dddd DD MMM YYYY')}` : ''}`}
      width={1600}
      height={1000}
      resizable={true}
      draggable={true}
      {...rest}
    >
      <TableTasks
        tableId="AddActivitiesTableTAsks"
        enableAddTime={true}
        addTimeToSpecifiedDate={date}
        customUser={user}
        onAddActivities={onAddActivities}
        resources={{
          resourceType: 'tasks',
          requestName: 'fetchByUser',
          requestData: {
            userId: user.id,
          },
          includedResources: [
            'assetInst',
            'assetInst.thumbnailInst',
            'stepInst',
            'assignedUserInst',
            'statusInst',
            'activitiesInst',
            'takesInst.statusInst',
            'takesInst.takeFlagsInst.flagInst',
            'takesInst.takeRefMediasInst.mediaInst',
            'takesInst.takeValidationMediasInst.mediaInst',
          ],
        }}
      />
    </MUIModal>
  )
}

const pipeInst: Pipe<{ userId: ID }, typeof ModalAddActivitiesForUserView> = pipe()

export const ModalAddActivitiesForUser: React$AbstractComponent<any, any> = pipeInst
  .connect((state, props) => {
    return {
      user: getResources(state, 'assets', props.userId),
    }
  })
  .request((props) => {
    const { userId } = props
    return resources.assets.fetchOne(userId)
  })
  .renderLoader(() => null)
  .render(({ userId, ...props }) => <ModalAddActivitiesForUserView {...props} />)
