// @flow

import map from 'lodash/map'

import { dynamicApprovalStatus, dynamicApprovalColors } from 'app/core/constants/dynamicApprovalStatus'

export const optionsDynamicApprovalStatus: Array<Object> = map(dynamicApprovalStatus, (label, value) => ({
  value,
  label,
  backgroundColor: dynamicApprovalColors[value],
}))
