/* eslint-disable react/function-component-definition */
import React from 'react'
import PropTypes from 'prop-types'
import { pickBy, mapValues, fromPairs, forEach } from 'lodash'
import { getQueryField, getQuery, setQuery, setQueryField, createAbsolutePathWithQuery, createPath } from './utils'
import Link from './Link.jsx'
import Route from './Route.jsx'

export default class Router {
  static paramsArrayToObject(paramsAsArray) {
    const params = {}

    for (const param of paramsAsArray) {
      params[param[0]] = param[1]
    }

    return params
  }

  static push(routerName, relativePath) {
    setQueryField(routerName, relativePath)
  }

  constructor(name, routes, config = {}, options = {}) {
    this.name = name
    this.routes = routes
    this.options = options
    this.cache = {
      parents: {},
    }
    this.Link = (props) => {
      const { linkProps } = options
      if (linkProps) props = linkProps(props)
      return <Link {...props} localRouter={this} />
    }

    this.Link.propTypes = {
      name: PropTypes.string,
      onClick: PropTypes.func,
    }

    this.Route = (props) => {
      return <Route localRouter={this} {...props} />
    }
    this.config = config
  }

  exist() {
    return !!getQueryField(this.name)
  }

  remove() {
    const query = getQuery()

    const entriesCustomParams = pickBy(query, (value, key) => key.startsWith(`${this.name}-`))
    const entriesCustomParamsObj = mapValues(entriesCustomParams, (value, key) => '$remove')

    setQuery({
      [this.name]: '$remove',
      ...entriesCustomParamsObj,
    })
  }

  getPathName() {
    return getQueryField(this.name)
  }

  getConfig() {
    const prefix = `${this.name}-`

    const entries = Object.entries(getQuery())
    const output = entries
      .filter(([key, value]) => key.startsWith(prefix))
      .map(([key, value]) => {
        if (value === 'true') {
          value = true
        } else if (value === 'false') {
          value = false
        }

        return [key.replace(prefix, ''), value]
      })
    return fromPairs(output)
  }

  process() {
    const pathName = this.getPathName()

    if (pathName && pathName === this.cache.pathName) {
      return this.cache.routes
    }

    this.cache.params = {}

    const routerQueryAsArray = pathName ? pathName.substring(1).split('/') : []
    const routes = []

    for (const [routeName, path] of Object.entries(this.routes)) {
      let customPath
      const { pathInterceptor } = this.options

      if (pathInterceptor) customPath = pathInterceptor(routeName, path, this.routes)

      const route = {
        path: customPath || path,
        pathWithParams: '',
        name: routeName,
        paramsList: [],
        params: {},
      }

      const routePathAsArray = path.substring(1).split('/')

      for (const index in routePathAsArray) {
        if (Object.prototype.hasOwnProperty.call(routePathAsArray, index)) {
          const routeParam = []

          const routeFieldName = routePathAsArray[index]
          const routerFieldValue = routerQueryAsArray[index]

          if (!routerFieldValue && !routeFieldName.startsWith(':!')) route.isActive = false

          if (routeFieldName.startsWith(':')) {
            routeParam[0] = routeFieldName.substring(1)
            // use for optional field
            if (routeParam[0].startsWith('!')) {
              routeParam[0] = routeParam[0].substring(1)
            }
            routeParam[1] = routerFieldValue || ''
          } else if (routeFieldName === routerFieldValue) {
            // if route are not variable
            routeParam[0] = routeFieldName
            routeParam[1] = routerFieldValue
          } else {
            route.isActive = false
          }

          routeParam.length !== 0 && route.paramsList.push(routeParam)
          route.pathWithParams += `/${routerFieldValue}`
          route.params = this.constructor.paramsArrayToObject(route.paramsList)
          this.cache.params = { ...this.cache.params, ...route.params }
        }
      }

      route.isActive = !(route.isActive === false)
      routes.push(route)
    }

    this.cache.routes = routes
    this.cache.pathName = pathName

    return routes
  }

  getActivesRoutes() {
    this.process()
    return this.cache.routes.filter((route) => route.isActive)
  }

  setParent(name, parent) {
    this.cache.parents[name] = parent
  }

  getRoute(routeName) {
    this.process()
    const route = this.cache.routes.find(({ name }) => name === routeName) || ''
    return route
  }

  getRelativeLink(routeName, params) {
    const route = this.getRoute(routeName)
    const _params = { ...route.params, ...params }
    return createPath(route.path, _params)
  }

  getAbsoluteLink(routeName, params) {
    const relativeLink = this.getRelativeLink(routeName, params)
    return createAbsolutePathWithQuery({ [this.name]: relativeLink })
  }

  routeIsActive(routeName, params) {
    let childrenIsActive = false

    if (this.cache.parents && Object.values(this.cache.parents).includes(routeName)) {
      forEach(this.cache.parents, (parent, route) => {
        if (parent === routeName) {
          if (this.routeIsActive(route, params)) childrenIsActive = true
        }
      })
    }

    return childrenIsActive || this.getRoute(routeName).pathWithParams === this.getRelativeLink(routeName, params)
  }

  goTo(routeName, params, config) {
    this.config = {
      ...this.config,
      ...config,
    }
    const relativePath = this.getRelativeLink(routeName, params)
    const customParamQueryField = {}

    Object.entries(this.config).forEach(([key, value]) => {
      customParamQueryField[`${this.name}-${key}`] = value
    })

    setQuery({ [this.name]: relativePath, ...customParamQueryField })
  }

  getParam(paramName) {
    this.process()

    return this.cache.params[paramName]
  }
}
