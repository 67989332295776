// @flow
import * as React from 'react'
import TooltipBase from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core'
import type { ElementProps } from 'app/core/types'

type Props = {|
  classes?: Object,
  children: React$Node,
  disableFocusListener?: boolean,
  disableHoverListener?: boolean,
  disableTouchListener?: boolean,
  enterDelay?: number,
  enterTouchDelay?: number,
  id?: string,
  interactive?: boolean,
  leaveDelay?: number,
  leaveTouchDelay?: number,
  onClose?: Function,
  onOpen?: Function,
  open?: boolean,
  arrow?: boolean,
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top',
  PopperProps?: Object,
  title: Object,
  TransitionComponent?: React.Element<any>,
  TransitionProps?: Object,
  style?: Object,
  onClick?: Function,
  className?: string,
  emptyStyle?: boolean,
  notActive?: boolean,
  disabled?: boolean,
  ...ElementProps,
|}

const useStyles = makeStyles((theme) => ({
  emptyTooltip: {
    backgroundColor: 'none',
    borderRadius: 0,
    padding: 0,
    border: 'none',
  },
  tooltip: (style) => {
    return {
      fontSize: 11,
      color: '#ffffff',
      ...style,
    }
  },
}))

export function Tooltip({
  children,
  emptyStyle,
  notActive,
  classes,
  style,
  arrow = true,
  disabled,
  title,
  ...props
}: Props): React$Node {
  const { emptyTooltip, tooltip } = useStyles(style)

  if (disabled || notActive || !title) return children

  return (
    <TooltipBase
      classes={{
        tooltip: `${emptyStyle ? emptyTooltip : ''} ${tooltip || ''}`,
        ...classes,
      }}
      arrow={arrow}
      title={title}
      {...props}
    >
      {children}
    </TooltipBase>
  )
}
