// @flow

import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import { afterUpdate, afterCreate, beforeUpdate } from 'app/store/hooks/takes.js'
import type { TakesActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const takes: StoreResourceDef<TakesActions<>> = {
  resourceType: 'takes',
  hooks: (getResourcesAPI, getResources) => ({
    beforeUpdate: beforeUpdate(getResourcesAPI, getResources),
    afterCreate: afterCreate(getResourcesAPI, getResources),
    afterUpdate: afterUpdate(getResourcesAPI, getResources),
  }),
  actions: (actionsCreators) => ({
    pushTo: (data, config) => {
      const { params = {} } = config || {}
      return {
        type: 'update',
        requestKey: config?.requestKey,
        request: () => {
          return api
            .pushTo({ ...data, ...params.queries })
            .then((res) => {
              const resourceAPI = actionsCreators()

              resourceAPI.tasks.update(
                res.results.map((lastTake) => ({
                  id: lastTake.task,
                  status: lastTake.status,
                })),
                { localOnly: true },
              )

              return res
            })
            .then(transformResults(config))
        },
      }
    },
    pushToFromRetake: (data, config) => {
      const { params = {} } = config || {}
      return {
        type: 'create',
        requestKey: config?.requestKey,
        request: () =>
          api
            .pushTo({ ...data, ...params.queries })
            .then((res) => {
              const resourceAPI = actionsCreators()

              resourceAPI.tasks.update(
                res.results.map((lasttake) => ({
                  id: lasttake.task,
                  status: lasttake.status,
                })),
                { localOnly: true },
              )

              return res
            })
            .then(transformResults(config)),
      }
    },
    fetchAllByTask: (taskId, config) => {
      const { params = {} } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.tasks
            .takes(
              { id: taskId, ...params.queries },
              params.queries,
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(config)),
      }
    },
    history: ({ id }: { id: string }, config) => {
      const { params = {}, ...restConfig } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.takes
            .history({ id }, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(transformResults(restConfig)),
      }
    },
  }),
  relations: {
    takeFlagsInst: {
      type: 'hasMany',
      resourceType: 'takeFlags',
      foreignKey: 'take',
      serverKey: 'rel_takeFlagsInst',
    },
    flagsInst: {
      // Hydrate only
      type: 'hasMany',
      resourceType: 'flags',
    },
    takeRefMediasInst: {
      type: 'hasMany',
      resourceType: 'takeRefMedias',
      foreignKey: 'take',
      serverKey: 'rel_takeRefMediasInst',
    },
    refMediasInst: {
      // Hydrate only
      type: 'hasMany',
      resourceType: 'medias',
    },
    takeValidationMediasInst: {
      type: 'hasMany',
      resourceType: 'takeValidationMedias',
      foreignKey: 'take',
      serverKey: 'rel_takeValidationMediasInst',
    },
    validationMediasInst: {
      // Hydrate only
      type: 'hasMany',
      resourceType: 'medias',
    },
    statusInst: {
      type: 'hasOne',
      resourceType: 'progressionStatus',
      foreignKey: 'status',
    },
    taskInst: {
      type: 'hasOne',
      resourceType: 'tasks',
      foreignKey: 'task',
    },
  },
}
