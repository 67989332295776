// @flow
import React, { useState } from 'react'
import { Checkbox } from 'app/components/Form/index.js'
import pipe from 'app/core/utils/pipe'
import resources from 'app/store/resources'
import type { ID, PermissionType, User } from 'app/core/types'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import { ModalTrigger, MUIModal } from 'app/components/Modal/index.js'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import settings from 'app/core/settings'
import { PermissionPanelEditPermModal } from './PermissionPanelEditPermModal.jsx'
import { Permission } from './Permission.jsx'
import { userIsSuperAdmin } from './utils/userPermissions.js'

const isDev = settings.env.NODE_ENV === 'development'

type Props = {|
  disabled: boolean,
  action: string,
  id: ID,
  groupId: ID,
  actionPermissionId: ID,
  permissions: Array<PermissionType>,
  isGlobal: boolean,
  category: string,
  subCategory: string,
  actionGroup: string,
  user: User,
|}

function PermissionCheckboxView(props: Props): React$Node {
  const {
    action,
    id,
    groupId,
    actionPermissionId,
    permissions,
    isGlobal,
    category,
    subCategory,
    actionGroup,
    disabled,
    user,
  } = props
  const [loader, setLoader] = useState(false)

  const switchAction = (group: ID, actionPermission: ID) => () => {
    setLoader(true)
    const setLoaderFalse = () => setLoader(false)

    if (id) {
      return resources.actionPermissionGroups.delete(id).then(setLoaderFalse).catch(setLoaderFalse)
    }
    return resources.actionPermissionGroups
      .create({ actionPermission, group })
      .then(setLoaderFalse)
      .catch(setLoaderFalse)
  }

  return (
    <div style={{ display: 'inline-flex' }} className="row noWrap alignCenter">
      {loader ? (
        <LoaderSmall />
      ) : (
        <Checkbox checked={Boolean(id)} onChange={switchAction(groupId, actionPermissionId)} disabled={disabled} />
      )}
      <div className="marginRight15 marginLeft5 flex row noWrap alignCenter">
        {action}
        {isDev || userIsSuperAdmin(user.username) ? (
          <Permission actions={[]}>
            <ModalTrigger
              modal={
                <MUIModal title="Edit permissions" height={600} width={800} resizable={true}>
                  <PermissionPanelEditPermModal
                    permissions={permissions}
                    actionPermissionId={actionPermissionId}
                    isGlobal={isGlobal}
                    category={category}
                    subCategory={subCategory}
                    actionGroup={actionGroup}
                    action={action}
                    user={user}
                  />
                </MUIModal>
              }
            >
              <FontIcon className="marginLeft5 pointer" style={{ color: 'lightgrey' }} icon="fas-info-circle" />
            </ModalTrigger>
          </Permission>
        ) : null}
      </div>
    </div>
  )
}

export const PermissionCheckbox: React$ComponentType<any> = pipe()
  .connect((state, props) => {
    return {
      user: state.user,
    }
  })
  .render(PermissionCheckboxView)
