/** @flow */
import React, { useMemo } from 'react'
import { sortBy } from 'lodash'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import { dynamicApprovalColors } from 'app/core/constants/dynamicApprovalStatus'
import { MUIButton } from 'app/components/Form'

import { ModalPie } from './ModalPie.jsx'
import { mountModal } from '../../TableView/Elements/ModalEdition.jsx'
import type { UseFiltersOutput } from '../../hooks/useFilters'

type Props = {|
  progressionStatus: { [key: string]: Object },
  statusList: Array<Object>,
  setFilters?: $ElementType<UseFiltersOutput, 'setUrlFilters'>,
  onFilter?: Function,
  columnName?: string,
  tableId: ?string,
|}

export function DisplayStatus(props: Props): React$Node {
  const { progressionStatus, statusList, setFilters, columnName, onFilter, tableId } = props

  const filterByStatus = (statusId) => {
    if (!columnName) return
    let filters

    if (statusId) filters = btoa(JSON.stringify([[`${columnName} - status`, 'inList', statusId]]))
    else filters = btoa(JSON.stringify([[columnName, 'isNull']]))

    if (setFilters) setFilters(filters, true, true)
  }

  const parsedStatus = useMemo(() => {
    const parsedStatus = []

    sortBy(statusList, ['status_id', 'status']).forEach(({ count, status_id, status }) => {
      let finalStatus

      if (status || status_id) {
        finalStatus = {
          id: status_id || status,
          count,
          name: status ? progressionStatus[status] : progressionStatus[status_id]?.name,
          backgroundColor: status ? dynamicApprovalColors[status] : progressionStatus[status_id]?.color,
        }
      }

      if (status === null) {
        finalStatus = {
          id: null,
          count,
          name: 'null',
          backgroundColor: 'rgba(0,0,0,0.05)',
        }
      }

      if (finalStatus) parsedStatus.push(finalStatus)
    })

    return parsedStatus
  }, [statusList])

  return (
    <div
      className="relative overflowXAuto flex column"
      style={{ border: '1px solid rgba(0,0,0,0.1)', borderRadius: 3, minHeight: 42 }}
    >
      <div className="flex flex1 row noWrap spaceBetween">
        <div className="relative padding5">
          <div className="grid col2 noGridGap">
            {parsedStatus.map(({ count, name, backgroundColor, id }) => {
              return [
                <span key={`${name}${count}`} className="bold">
                  {count}
                </span>,
                <div
                  key={name}
                  onClick={(e) => {
                    if (onFilter && name) onFilter(name)
                    else filterByStatus(id)
                  }}
                  style={{
                    borderRadius: 3,
                    padding: '1px 2px',
                    margin: '1px 0 1px 5px',
                    fontSize: 10,
                    cursor: 'pointer',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    backgroundColor,
                    color: getColorFromBackground(backgroundColor),
                  }}
                >
                  {name}
                </div>,
              ]
            })}
          </div>
        </div>
        <div style={{ margin: 3 }}>
          <MUIButton
            onClick={() =>
              mountModal(ModalPie, tableId, {
                data: parsedStatus
                  .map(({ count, name, backgroundColor }) =>
                    name
                      ? {
                          value: count,
                          id: name,
                          label: name,
                          color: backgroundColor,
                        }
                      : null,
                  )
                  .filter((_) => _),
                colors: (data) => data.color,
                additionnalButtons: (data, setDataFunction, buttonsData, setButtonsData) => {
                  return (
                    <div className="fullWidth flex end alignCenter noUserSelect">
                      <label>
                        <input
                          type="checkbox"
                          value={Boolean(!buttonsData.hideNullValues)}
                          onChange={(e) => {
                            if (buttonsData.hideNullValues) {
                              setDataFunction((dataFunctions) => {
                                const newDataFunctions = { ...dataFunctions }
                                delete newDataFunctions.hideNullValues
                                return newDataFunctions
                              })
                            } else {
                              setDataFunction((dataFunctions) => ({
                                ...dataFunctions,
                                hideNullValues: (data) => data.filter((d) => d.label !== 'null'),
                              }))
                            }
                            setButtonsData((buttonsData) => ({
                              ...buttonsData,
                              hideNullValues: !buttonsData.hideNullValues,
                            }))
                          }}
                        />{' '}
                        hide null values
                      </label>
                    </div>
                  )
                },
              })
            }
            bgColor="#ffffff"
            style={{ color: '#000000' }}
            icon="fas-chart-pie"
            dataCy="status-open-pie"
          />
        </div>
      </div>
    </div>
  )
}
