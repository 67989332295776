// @flow
import transform from 'lodash/transform'

export const getSubResources = (resources: Object, subResourcesKey: string): any =>
  transform(
    resources,
    (result, value, key) => {
      if (value[subResourcesKey] && value[subResourcesKey].id && result)
        result[value[subResourcesKey].id] = value[subResourcesKey]
    },
    {},
  )
