// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { map, sortBy } from 'lodash'
import { type Pipe } from 'app/core/utils/pipeNext.type'
import pipe from 'app/core/utils/pipe'
import { type Group as GroupType, type ID } from 'app/core/types'
import { Permission } from 'app/containers/Permissions/index.js'
import { MUIButton } from 'app/components/Form'
import { ModulableTree } from 'app/components/ModulableTree/ModulableTree.jsx'
import { labelFullWidth } from 'app/components/ModulableTree/styles'
import { getResources } from 'app/store/selectors/index.js'
import { ModalTrigger } from 'app/components/Modal'
import Widget from 'app/components/Widget/Widget.jsx'
import ModalCreateGroup from './ModalCreateGroup.tsx'
import classes from './Groups.module.scss'
import Group from './Group.jsx'

export type Props = {|
  groups: Array<GroupType>,
  selectedGroupId?: ID,
  history: Object,
  route: string,
  routing: string,
  projectId?: ID,
|}

class Groups extends React.Component<Props> {
  onSelectGroup = (group) => {
    const { route, history } = this.props

    history.push(`${route}/${group.id}`)
  }

  render(): React$Node {
    const { routing, groups, selectedGroupId, projectId } = this.props

    return (
      <div className={classes.container} key={groups.length}>
        <Widget className={classes.groupListArea} style={{ height: '100%', width: '100%' }}>
          <ModulableTree
            title="Groups"
            items={map(sortBy(groups, ['name']), (group) => {
              return {
                classes: labelFullWidth,
                noLevel: true,
                key: group.name,
                label: (
                  <div
                    onClick={() => this.onSelectGroup(group)}
                    className={classes.item}
                    style={
                      group.id === selectedGroupId
                        ? { backgroundColor: '#3DC3D2', color: '#ffffff', fontWeight: 'bold' }
                        : {}
                    }
                  >
                    {group.name}
                  </div>
                ),
              }
            })}
            Header={
              <Permission actions={['projet_groups_group_create']}>
                <ModalTrigger modal={<ModalCreateGroup projectId={projectId} />}>
                  <MUIButton icon="fas-plus" />
                </ModalTrigger>
              </Permission>
            }
          />
        </Widget>
        <Switch>
          <Route path={`${routing}/:groupId`} component={Group} />
        </Switch>
      </div>
    )
  }
}

type PipeType = Pipe<{ projectId?: ID }, typeof Groups>

const pipeInst: PipeType = pipe()

const GroupsComponent: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => {
    const { groupId } = props.match.params
    const { projectId, history } = props

    return {
      groups: map(getResources(state, 'assets', { assetType: 'gp', project: projectId || null })),
      selectedGroupId: groupId || undefined,
      history,
      projectId,
    }
  })
  .render(({ match, location, ...props }) => <Groups {...props} />)
export default GroupsComponent
