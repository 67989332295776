/** @flow */
import React, { useEffect, useMemo, useState } from 'react'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import resources from 'app/store/resources'
import { getResources } from 'app/store/selectors'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import type { ContractAttribute, ResourcesList } from 'app/core/types'
import { MUIButton, Input } from 'app/components/Form'
import { permission } from 'app/containers/Permissions/Permission.jsx'
import { confirmDelete } from 'app/components/Modal/confirmDelete.jsx'
import Loader from 'app/components/Loader/Loader.jsx'
import { NODE_DIRECTORY, Node } from 'app/components/TreeDnD/Node'
import { NewAttributeModal } from 'app/pages/Project/Settings/FollowUp/AttributesEditor/NewAttributeModal.jsx'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'
import Widget from 'app/components/Widget/Widget.jsx'
import { DisplayAttribute } from 'app/pages/Project/Settings/FollowUp/AttributesEditor/DisplayAttribute.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'
import classes from './ContractAttributesEditor.module.scss'
import { getContractAttributeNode } from './getContractAttributeNode.jsx'

type ContractAttributesEditorProps = {|
  style?: Object,
|}

export function ContractAttributesEditor(props: ContractAttributesEditorProps): React$Node {
  const { style } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentAttribute, setCurrentAttribute] = useState<void | ContractAttribute>()
  const [contractAttributes, setContractAttributes] = useState<ResourcesList<ContractAttribute> | void>()
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    setIsLoading(true)
    const config = { params: { queries: { page_size: 1000 } } }
    resources.contractAttributes.fetchAll(config).then(() => {
      setContractAttributes(getResources(undefined, 'contractAttributes'))
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    documentTitle('Contract attributes', 'page')
  }, [])

  const canEdit = useMemo(() => permission(['human resources_settings_contract attributes_edit']), [])

  async function onSaveAttribute(attribute: ContractAttribute) {
    const res = await resources.contractAttributes[attribute.id ? 'update' : 'create'](attribute)
    const newAttribute = res.resources[0]
    const newContractAttributes: ResourcesList<ContractAttribute> = contractAttributes ? { ...contractAttributes } : {}
    newContractAttributes[newAttribute.id] = newAttribute
    setCurrentAttribute(newAttribute)
    setContractAttributes(newContractAttributes)
    return res
  }

  function onDelete(attribute: ContractAttribute) {
    confirmDelete({
      render: (
        <>
          Are you sure you want to delete <span className="bold">{attribute.displayName || attribute.name}</span> ?
        </>
      ),
      onValidate: async () => {
        const res = await resources.contractAttributes.delete(attribute.id)
        const newContractAttributes: ResourcesList<ContractAttribute> = contractAttributes
          ? { ...contractAttributes }
          : {}
        delete newContractAttributes[attribute.id]
        setContractAttributes(newContractAttributes)
        setCurrentAttribute()
        return res
      },
      validateMessage: 'Attribute deleted',
    })
  }

  const filteredContractAttributes = useMemo(
    () =>
      sortBy(
        search
          ? filter(contractAttributes, (attr) => attr.name.toLowerCase().includes(search.toLowerCase()))
          : contractAttributes,
        ['rank'],
      ),
    [search, contractAttributes],
  )

  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: filteredContractAttributes.map((contractAttribute, index) =>
        getContractAttributeNode({
          contractAttribute,
          onSelect: (contractAttribute) => setCurrentAttribute(contractAttribute),
          parentNode: rootNode,
        }),
      ),
    })

    return rootNode
  }, [filteredContractAttributes.length])

  return isLoading ? (
    <div className="fullWidth fullHeight flex center alignCenter">
      <Loader />
    </div>
  ) : (
    <div className={classes.container} style={style}>
      <div className={classes.toolbar}>
        <div>
          <Input
            placeholder="Search an attribute"
            value={search}
            onChange={setSearch}
            style={{ width: 300 }}
            dataCy="dynApprovals-search"
          />
        </div>
        {canEdit ? (
          <NewAttributeModal
            title="Create new contract attribute"
            onSave={onSaveAttribute}
            edit={false}
            noNameVerification={true}
          >
            <MUIButton icon="fas-plus" tooltip="Add new contract attribute" />
          </NewAttributeModal>
        ) : null}
      </div>
      <div className={classes.subContainer}>
        <div className={classes.scrollBlock}>
          {!filteredContractAttributes.length ? (
            <div className={classes.noDynamicApprovalItem}>No contract attrubute</div>
          ) : (
            <TreeDnD rootNode={rootNode} />
          )}
        </div>
        {currentAttribute ? (
          <Widget style={{ flex: 3, maxWidth: 380, height: 'fit-content' }}>
            <DisplayAttribute
              attribute={currentAttribute}
              EditButtons={
                canEdit ? (
                  <div className={classes.buttonsContainer}>
                    <NewAttributeModal
                      title={currentAttribute.id ? `Edit ${currentAttribute.name}` : 'Create new attribute'}
                      onSave={onSaveAttribute}
                      attribute={currentAttribute}
                      edit={true}
                      noNameVerification={true}
                    >
                      <FontIcon
                        className={classes.iconButton}
                        data-cy={cyLabelFormater('attribut-item-edit', currentAttribute?.name)}
                        icon="fas-edit"
                      />
                    </NewAttributeModal>
                    <FontIcon
                      className={classes.iconButton}
                      data-cy={cyLabelFormater('attribut-item-edit', currentAttribute?.name)}
                      icon="fas-trash"
                      onClick={() => onDelete(currentAttribute)}
                    />
                  </div>
                ) : undefined
              }
            />
          </Widget>
        ) : null}
      </div>
    </div>
  )
}
