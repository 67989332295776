var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { ModalConfirmForm } from 'app/components/Modal';
import { SketchPicker } from 'react-color';
import { FormData } from 'app/components/Form';
import resources from 'app/store/resources';
import styles from 'app/styles/vars.js';
export function ModalEditDepartment(props) {
    const { department, onSave, onChange } = props, modalProps = __rest(props, ["department", "onSave", "onChange"]);
    function save(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const newDepartment = Object.assign(Object.assign({}, department), data);
            if (onSave)
                return onSave(newDepartment);
            const res = yield resources.departments[(newDepartment === null || newDepartment === void 0 ? void 0 : newDepartment.id) ? 'update' : 'create'](newDepartment);
            onChange === null || onChange === void 0 ? void 0 : onChange(res.resources[0]);
            return res;
        });
    }
    const colorPicker = (data, setData) => {
        return (_jsx(SketchPicker, { onChangeComplete: (color) => {
                setData(Object.assign(Object.assign({}, data), { color: color.hex }));
            }, disableAlpha: true, presetColors: styles.randomColors, color: data.color }));
    };
    return (_jsx(ModalConfirmForm, Object.assign({ title: department ? `Edit department ${department.name || ''}` : 'Create a new department', draggable: true }, modalProps, { children: _jsx(FormData, { onSave: onSave || save, flashNotifSuccessLabel: `Department ${department ? 'edited' : 'created'}`, defaultData: department, properties: [
                {
                    label: 'Department name',
                    key: 'name',
                    type: 'string',
                    elementProps: {
                        isRequired: true,
                    },
                },
                {
                    label: 'Quotation reference',
                    key: 'quoteReference',
                    type: 'string',
                },
                {
                    key: 'color',
                    label: 'Color',
                    type: 'custom',
                    elementProps: {
                        isRequired: true,
                    },
                    element: colorPicker,
                },
            ] }) })));
}
