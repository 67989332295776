/** @flow */
import React, { useEffect } from 'react'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import store from 'app/store/index.ts'
import { ModalInstances } from 'app/components/Modal'
import { MouseTooltip } from 'app/components/MouseTooltip/MouseTooltip'
import Notif from 'app/containers/Notif/Notif.jsx'
import { OverPage } from 'app/components/OverPage/OverPage.jsx'
import CheckUpdates from 'app/containers/CheckUpdates/CheckUpdates.jsx'
import Error from 'app/components/Error/Error.jsx'
import { HowTo } from 'app/components/HowTo/HowTo.jsx'
import theme from 'app/styles/createTheme.js'
import { NotificationsHOC } from 'app/components/Notifications/NotificationsHOC.jsx'
import { PictureVisualization } from 'app/components/PictureVisualization/PictureVisualization.tsx'
import { DocumentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import history from './routerHistory'
import MainRoutes from './MainRoutes.tsx'
import classes from './Main.module.scss'

export default function (): React$Element<React$FragmentType> {
  useEffect(() => {
    const $HTML = document.querySelector('html')
    if (window.gitlab) {
      Object.entries(window.gitlab).forEach(([key, value]) => {
        $HTML?.setAttribute(`data-${key}`, String(value))
      })
    }
  }, [])

  return (
    <>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <Error>
            <DndProvider backend={HTML5Backend}>
              <Provider store={store}>
                <DocumentTitle />
                <div className={classes.mainLayout}>
                  <NotificationsHOC>
                    <CheckUpdates />
                    <Notif />
                    <HowTo history={history} />
                    <PictureVisualization />
                    <MainRoutes />
                    <OverPage />
                    <ModalInstances />
                    <MouseTooltip />
                  </NotificationsHOC>
                </div>
              </Provider>
            </DndProvider>
          </Error>
        </Router>
      </MuiThemeProvider>
    </>
  )
}
