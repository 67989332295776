// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --borderField: solid 1px #b3b9c7;
  --mainBorderRadius: 4px;
  --mainBorder: solid 1px #e8e8e8;
  --mainBorderColor: #e8e8e8;
  --mainFontItalic: 'gothamlightitalic';
  --mainFontBoldItalic: 'gothambolditalic';
  --mainFont: 'Helvetica Neue', Helvetica, Arial;
  --tableFont: 'Helvetica', Arial, sans-serif;

  --colors-mainColor: #3dc3d2;
  --colors-mainColorMedium: rgba(61, 195, 209, 0.34);
  --colors-mainColorLight: #ebf9fa;
  --colors-cyan: #3dc3d2;
  --colors-cyanLight: #ebf9fa;
  --colors-white: #ffffff;
  --colors-black: #4a4a4a;
  --colors-blackLight: #41474d;
  --colors-greyExtraLight: #f7f8fb;
  --colors-greyLight: #e8e8e8;
  --colors-greyMedium: rgba(206, 209, 212, 0.75);
  --colors-grey: #b3b9c7;
  --colors-greyDark: #596169;
  --colors-blue: #008ae6;
  --colors-green: #48c27e;
  --colors-red: #e56d7a;
  --colors-redDark: #e50f26;
  --colors-greenLight: #b8e986;
  --colors-yellow: #e3e05b;
  --colors-yellowDark: #eeb413;
  --colors-orange: #eeb413;
  --colors-transparent: transparent;
}
`, "",{"version":3,"sources":["webpack://./app/styles/rootVars.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,uBAAuB;EACvB,+BAA+B;EAC/B,0BAA0B;EAC1B,qCAAqC;EACrC,wCAAwC;EACxC,8CAA8C;EAC9C,2CAA2C;;EAE3C,2BAA2B;EAC3B,kDAAkD;EAClD,gCAAgC;EAChC,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,uBAAuB;EACvB,4BAA4B;EAC5B,gCAAgC;EAChC,2BAA2B;EAC3B,8CAA8C;EAC9C,sBAAsB;EACtB,0BAA0B;EAC1B,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,yBAAyB;EACzB,4BAA4B;EAC5B,wBAAwB;EACxB,4BAA4B;EAC5B,wBAAwB;EACxB,iCAAiC;AACnC","sourcesContent":[":root {\n  --borderField: solid 1px #b3b9c7;\n  --mainBorderRadius: 4px;\n  --mainBorder: solid 1px #e8e8e8;\n  --mainBorderColor: #e8e8e8;\n  --mainFontItalic: 'gothamlightitalic';\n  --mainFontBoldItalic: 'gothambolditalic';\n  --mainFont: 'Helvetica Neue', Helvetica, Arial;\n  --tableFont: 'Helvetica', Arial, sans-serif;\n\n  --colors-mainColor: #3dc3d2;\n  --colors-mainColorMedium: rgba(61, 195, 209, 0.34);\n  --colors-mainColorLight: #ebf9fa;\n  --colors-cyan: #3dc3d2;\n  --colors-cyanLight: #ebf9fa;\n  --colors-white: #ffffff;\n  --colors-black: #4a4a4a;\n  --colors-blackLight: #41474d;\n  --colors-greyExtraLight: #f7f8fb;\n  --colors-greyLight: #e8e8e8;\n  --colors-greyMedium: rgba(206, 209, 212, 0.75);\n  --colors-grey: #b3b9c7;\n  --colors-greyDark: #596169;\n  --colors-blue: #008ae6;\n  --colors-green: #48c27e;\n  --colors-red: #e56d7a;\n  --colors-redDark: #e50f26;\n  --colors-greenLight: #b8e986;\n  --colors-yellow: #e3e05b;\n  --colors-yellowDark: #eeb413;\n  --colors-orange: #eeb413;\n  --colors-transparent: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
