// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Medias-MediasRelation-___MediasRelation-module__container {
  display: block;
  max-width: 380px;
  height: 50vh;
  min-width: 300px;
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__overflow {
  overflow: auto;
  height: calc(100% - 40px);
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__items {
  display: flex;
  flex-wrap: wrap;
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: lightgrey;
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__item {
  display: flex;
  margin-bottom: 10px;
  border: var(--mainBorder);
  align-items: center;
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__item:not(:first-child) {
    margin-left: 10px;
  }

.app-containers-Medias-MediasRelation-___MediasRelation-module__item:not(:last-child) {
    margin-right: 10px;
  }

.app-containers-Medias-MediasRelation-___MediasRelation-module__item a {
  padding: 10px 10px 10px 10px;
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__item a:hover {
  color: #fff;
  background-color: var(--colors-mainColor);
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__item .app-containers-Medias-MediasRelation-___MediasRelation-module__bin {
  height: 100%;
  display: flex;
  align-items: center;
  border-left: var(--mainBorder);
  cursor: pointer;
  padding: 0 10px 0 10px;
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__item .app-containers-Medias-MediasRelation-___MediasRelation-module__bin:hover {
  background-color: var(--colors-mainColor);
}

.app-containers-Medias-MediasRelation-___MediasRelation-module__item .app-containers-Medias-MediasRelation-___MediasRelation-module__binIcon {
}
`, "",{"version":3,"sources":["webpack://./app/containers/Medias/MediasRelation/MediasRelation.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;AAOrB;;AANE;IACE,iBAAiB;EACnB;;AACA;IACE,kBAAkB;EACpB;;AAGF;EACE,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;AACA","sourcesContent":[".container {\n  display: block;\n  max-width: 380px;\n  height: 50vh;\n  min-width: 300px;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.overflow {\n  overflow: auto;\n  height: calc(100% - 40px);\n}\n\n.items {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.center {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  user-select: none;\n  color: lightgrey;\n}\n\n.item {\n  display: flex;\n  margin-bottom: 10px;\n  border: var(--mainBorder);\n  align-items: center;\n  &:not(:first-child) {\n    margin-left: 10px;\n  }\n  &:not(:last-child) {\n    margin-right: 10px;\n  }\n}\n\n.item a {\n  padding: 10px 10px 10px 10px;\n}\n\n.item a:hover {\n  color: #fff;\n  background-color: var(--colors-mainColor);\n}\n\n.item .bin {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  border-left: var(--mainBorder);\n  cursor: pointer;\n  padding: 0 10px 0 10px;\n}\n\n.item .bin:hover {\n  background-color: var(--colors-mainColor);\n}\n\n.item .binIcon {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-containers-Medias-MediasRelation-___MediasRelation-module__container`,
	"list": `app-containers-Medias-MediasRelation-___MediasRelation-module__list`,
	"overflow": `app-containers-Medias-MediasRelation-___MediasRelation-module__overflow`,
	"items": `app-containers-Medias-MediasRelation-___MediasRelation-module__items`,
	"center": `app-containers-Medias-MediasRelation-___MediasRelation-module__center`,
	"item": `app-containers-Medias-MediasRelation-___MediasRelation-module__item`,
	"bin": `app-containers-Medias-MediasRelation-___MediasRelation-module__bin`,
	"binIcon": `app-containers-Medias-MediasRelation-___MediasRelation-module__binIcon`
};
export default ___CSS_LOADER_EXPORT___;
