// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellRichText-___CellRichText-module__read {
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

.app-components-Table-Cells-CellRichText-___CellRichText-module__isCollapsedTextIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgba(0, 0, 0, 0.1);
  width: 30px;
  z-index: 2;
}

.app-components-Table-Cells-CellRichText-___CellRichText-module__isCollapsedTextIcon:hover {
  color: rgba(0, 0, 0, 0.7);
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellRichText/CellRichText.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,yBAAyB;EACzB,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".read {\n  padding: 5px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  position: relative;\n  z-index: 1;\n}\n\n.isCollapsedTextIcon {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  color: rgba(0, 0, 0, 0.1);\n  width: 30px;\n  z-index: 2;\n}\n\n.isCollapsedTextIcon:hover {\n  color: rgba(0, 0, 0, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"read": `app-components-Table-Cells-CellRichText-___CellRichText-module__read`,
	"isCollapsedTextIcon": `app-components-Table-Cells-CellRichText-___CellRichText-module__isCollapsedTextIcon`
};
export default ___CSS_LOADER_EXPORT___;
