// @flow

import type { StoreResourceDef } from 'app/core/types'

export const actionObjects: StoreResourceDef<> = {
  resourceType: 'actionObjects',
  relations: {
    assetFlagsInst: {
      type: 'hasMany',
      resourceType: 'assetFlags',
      foreignKey: 'asset',
      serverKey: 'rel_assetFlagsInst',
    },
    flagsInst: {
      // hydrate only. Linked to assetFlagsInst
      type: 'hasMany',
      resourceType: 'flags',
    },
    assetLinksInst: {
      type: 'hasMany',
      resourceType: 'assetLinks',
      foreignKey: 'from_asset',
      serverKey: 'rel_assetLinksInst',
    },
    linksInst: {
      // hydrate only. Linked to assetLinksInst
      type: 'hasMany',
      resourceType: 'assets',
    },
    assetMediaGroupsInst: {
      type: 'hasMany',
      resourceType: 'assetMediaGroups',
      foreignKey: 'asset',
      serverKey: 'rel_assetMediaGroupsInst',
    },
    mediaGroupsInst: {
      // hydrate only. Linked to assetMediaGroupsInst
      type: 'hasMany',
      resourceType: 'mediaGroups',
    },
    postBoardLinksInst: {
      type: 'hasMany',
      resourceType: 'postBoardLinks',
      foreignKey: 'asset',
    },
    activitiesInst: {
      type: 'hasMany',
      resourceType: 'activities',
      foreignKey: 'user',
    },
    thumbnailInst: {
      type: 'hasOne',
      resourceType: 'medias',
      foreignKey: 'thumbnail',
    },
    parentInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'parent',
    },
    tasksInst: {
      type: 'hasMany',
      resourceType: 'tasks',
      foreignKey: 'asset',
    },
    dynamicApprovalValuesInst: {
      type: 'hasMany',
      resourceType: 'dynamicApprovalValues',
      foreignKey: 'asset',
    },
  },
}
