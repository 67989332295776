/*eslint-disable */

import React from 'react'
import Icon from '../Icon.jsx'

export default class Planet_6 extends React.Component {
  static defaultProps = {
    width: 18,
    height: 18,
    fill: 'grey',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 18 18">
        <path
          d="M17.2,5.2c0-0.1-0.1-0.2-0.1-0.3c-0.4-0.8-1-1.6-1.6-2.2c-3.5-3.5-9.2-3.5-12.7,0C0.9,4.3,0,6.6,0,9
                    c0,5,4,9,9,9c2.4,0,4.7-0.9,6.4-2.6C18.1,12.6,18.7,8.5,17.2,5.2z M2.4,4.6C4.5,4,6.8,4,9,4c1.9,0,3.9,0,5.8-0.4
                    c0.3,0.3,0.5,0.6,0.8,1c-1.8,0-3.5,0.4-5.2,0.9c-0.1,0-0.3,0.1-0.4,0.2C9.6,5.9,9.1,6,8.7,6.1C8,6.2,7.2,6.2,6.5,6.2L1.6,6.1
                    C1.8,5.6,2.1,5.1,2.4,4.6z M6.4,7.6c0.3,0,0.5,0,0.8,0c0.6,0,1.2,0,1.9-0.2c0.5-0.1,1-0.3,1.4-0.4c0.1,0,0.3-0.1,0.4-0.1
                    c1.8-0.6,3.6-0.9,5.5-0.9c0.4,0.9,0.5,1.8,0.6,2.8c-1.4,0-2.8,0.4-4.2,0.9c-1.2,0.4-2.5,0.8-3.8,0.8c-0.9,0.1-1.8-0.1-2.7-0.2
                    c-0.4,0-0.8-0.1-1.3-0.1c-1.2-0.1-2.5-0.1-3.7,0.1C1.1,9.9,1.1,9.4,1.1,9c0-0.5,0-1,0.1-1.5L6.4,7.6z M1.3,10.7
                    c1.2-0.2,2.4-0.2,3.6-0.1c0.4,0,0.8,0.1,1.3,0.1c0.8,0.1,1.5,0.2,2.3,0.2c0.2,0,0.3,0,0.5,0c1.3-0.1,2.6-0.5,3.9-0.8
                    c1.4-0.4,2.7-0.8,4.1-0.8c0,0.5-0.1,1-0.2,1.5c-1.3,0-2.6,0.2-3.9,0.5c-1,0.2-1.9,0.4-2.9,0.5c-1.3,0.1-2.6,0-3.9-0.2
                    c-1.5-0.1-3-0.3-4.5-0.1C1.4,11.2,1.3,10.9,1.3,10.7z M10.5,1.2C10.5,1.2,10.5,1.2,10.5,1.2c-0.1,0.1-0.2,0.1-0.3,0.2
                    c-0.8,0.7-2,0.7-3.1,0.7C6.6,2.1,5.9,2,5.3,2C6.4,1.4,7.7,1.1,9,1.1C9.5,1.1,10,1.1,10.5,1.2z M4.8,2.3c0.8,0.1,1.6,0.2,2.3,0.2
                    c0.2,0,0.3,0,0.5,0c1,0,2-0.1,2.8-0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2c1.4,0.3,2.6,1,3.6,2
                    C12.7,3.7,10.9,3.7,9,3.7c-2.1,0-4.2,0-6.3,0.5C3.3,3.4,4,2.8,4.8,2.3z M1.6,11.8c1.4-0.2,2.9,0,4.4,0.1c1,0.1,2,0.2,3,0.2
                    c0.3,0,0.7,0,1,0c1-0.1,2-0.3,3-0.5c1.3-0.3,2.5-0.5,3.8-0.5c-0.3,1-0.7,1.9-1.3,2.8l-2.8-0.1c-2.7-0.1-5.8-0.2-8.6,1.3
                    c-0.2-0.1-0.3-0.3-0.5-0.4C2.6,13.8,2,12.8,1.6,11.8z M6.4,16.5C7.2,15.9,8,15.6,9,15.4c1-0.2,2.1-0.1,3-0.1l1.8,0.1
                    C11.6,16.9,8.9,17.3,6.4,16.5z M14.2,15C14.1,15,14.1,15,14.2,15L12,14.9c-1.9-0.1-4-0.2-6,1.4c-0.7-0.3-1.3-0.6-1.9-1.1
                    c2.7-1.4,5.7-1.3,8.3-1.2l2.6,0.1c-0.1,0.2-0.3,0.3-0.4,0.5C14.5,14.7,14.3,14.9,14.2,15z"
        />
      </Icon>
    )
  }
}

/* eslint-enable */
