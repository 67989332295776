/** @flow */
import React from 'react'
import type { ElementProps } from 'app/core/types'
import classes from './FormField.module.scss'
import { Checkbox, FormFieldRight } from '..'
import Label from '../Label/Label.jsx'

type Props = {|
  ...ElementProps,
  children: Array<Array<?React$Element<typeof Checkbox | typeof Label | typeof FormFieldRight>>>,
  selectable: boolean,
  resizable?: boolean,
|}

export default function (props: Props): React$Node {
  const { children, selectable, resizable, style, ...rest } = props

  return (
    <div
      className={classes.formField}
      style={{
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}
