/** @flow */
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import PubSub from 'pubsub-js'
import uniqid from 'app/libs/uniqid.js'

const generateModalEditionId = (tableId: ?string) => `${tableId || ''}-MODAL`

export const mountModal = (
  element: (props: any) => React$Node,
  tableId: ?string,
  props: ?any,
): ({ closeModal: () => void }) => {
  const id = uniqid()
  const modalEditionId = generateModalEditionId(tableId)

  PubSub.publish(modalEditionId, { element, id, props })

  return {
    closeModal: () => PubSub.publish(modalEditionId, { element: null, id }),
  }
}

type Props = {
  tableId: ?string,
}

export function ModalEdition(props: Props): React$Node {
  const { tableId } = props
  const modals = useRef([])
  const [key, setKey] = useState()

  const _setModals = (_modals) => {
    modals.current = _modals
    setKey(Date.now())
  }

  const closeModal = useCallback(
    (elementId: string) => {
      const _modals = modals.current.filter(({ id }) => id !== elementId)
      _setModals(_modals)
    },
    [key],
  )

  const tableModalId = useMemo(() => generateModalEditionId(tableId), [tableId])

  useEffect(() => {
    PubSub.subscribe(tableModalId, (msg, { element, id, props }) => {
      if (element) {
        const modal = {
          id,
          element,
          props: {
            ...props,
            onRequestClose: () => closeModal(id),
            disableBackdrop: true,
          },
        }

        _setModals([...modals.current, modal])
      } else {
        closeModal(id)
      }
    })
    return () => {
      PubSub.unsubscribe(tableModalId)
    }
  }, [key])

  return (
    <div>
      {modals.current.map(({ element: El, props: elProps, id }, index) => (
        <El key={id} {...elProps} />
      ))}
    </div>
  )
}
