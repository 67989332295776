/** @flow */
import React from 'react'
import { SmartDateRead, SmartDateInput, type SmartDateInputProps } from 'app/components/Form/SmartDate/SmartDate.jsx'
import type { DefaultCellProps, Column } from '../../types'

import { groupingFns } from './groupingFns.jsx'
import classes from './CellSmartDate.module.scss'

type CellSmartDateParams = {|
  ...$Exact<DefaultCellProps>,
  smartDateProps?: $Shape<SmartDateInputProps>,
  disableSelection?: boolean,
|}

export const CellSmartDate = ({ disableSelection, smartDateProps, ...data }: CellSmartDateParams): Column => ({
  cellType: 'CellSmartDate',
  Cell: (instance) => {
    const { value, cell } = instance
    const { getCellProps } = cell
    const { edition } = getCellProps()
    const { isBeingEdit, endEdit, save } = edition

    return (
      <>
        <SmartDateRead value={value} style={{ height: '100%', width: '100%' }} />
        {isBeingEdit ? (
          <SmartDateInput
            value={value}
            className={classes.input}
            onCancel={() => {
              endEdit()
              setTimeout(() => cell.focusCell(), 0)
            }}
            onEnter={(newValue) => {
              endEdit()
              cell.focusCell()
            }}
            onBlur={(newValue) => {
              if (
                value?.initialExpectedDate !== newValue?.initialExpectedDate ||
                value?.currentExpectedDate !== newValue?.currentExpectedDate ||
                value?.realDate !== newValue?.realDate
              ) {
                save(newValue)
              }
              endEdit()
            }}
            autoFocus={true}
            {...smartDateProps}
          />
        ) : null}
      </>
    )
  },
  width: 100,
  groupingFns,
  ...data,
})
