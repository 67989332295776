/** @flow */
import React, { useEffect, useMemo, useState } from 'react'
import { last, dropRight, forEach } from 'lodash'
import type { Step, Match, Asset, Option } from 'app/core/types'
import { Table } from 'app/components/Table/Table.jsx'
import type { ColumnHeader } from 'app/components/Table/types'
import { router as routerAssetDetail } from 'app/containers/Assets/AssetDetail/router'
import vars from 'app/styles/vars.js'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { AssetsTreeSearch } from 'app/containers/Assets/AssetsTree/index.js'
import Widget from 'app/components/Widget/Widget.jsx'
import history from 'app/main/routerHistory.js'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL.js'
import resources from 'app/store/resources/index.js'

import { CellText, CellLink, CellAssetsSelect, CellRichText, CellSelect } from 'app/components/Table/Cells'
import { Filters } from './Filters.js'

const { colors, getPalette } = vars

const tableId = 'table-files'

type Props = {|
  match: Match,
|}

const palette = getPalette(['c500', 'c700', 'c300', 'c900', 'c400', 'c600', 'c100', 'c800'])
const colorsTable = {}

export const filesStatus = [
  { label: 'Not publishable', color: colors.orange },
  { label: 'Publishable', color: colors.blue },
  { label: 'Published', color: colors.green },
]
export const fileTypes = [
  { label: 'undefined', color: colors.greyLight },
  { label: 'work', color: colors.greyMedium },
  { label: 'publish', color: colors.grey },
]

export function TableFiles(props: Props): React$Node {
  const { match } = props
  const { assetId, projectId } = match.params

  const statusOptions: Array<Option> = useMemo(() => {
    return filesStatus.map(({ label, color }, value) => ({ label, value, backgroundColor: color }))
  }, [])
  const fileTypesOptions: Array<Option> = useMemo(() => {
    return fileTypes.map(({ label, color }, value) => ({ label, value, backgroundColor: color }))
  }, [])

  const columns: Array<ColumnHeader> = useMemo(
    () => [
      {
        Header: 'Asset',
        id: 'asset',
        columns: [
          CellLink({
            id: 'assetName',
            readOnly: true,
            hiddenByDefault: true,
            fixable: true,
            hiddenable: true,
            showRemoved: true,
            Header: 'Asset name',
            accessor: 'assetInst.name',
            onClick: (row) =>
              routerAssetDetail.goTo('index', { assetId: row.original.assetInst.id }, { rightPanel: true }),
            sortingKey: 'asset__name',
          }),
        ],
      },
      {
        Header: 'Step',
        id: 'step',
        columns: [
          CellText({
            id: 'step',
            readOnly: true,
            fixable: true,
            hiddenable: true,
            Header: 'Step',
            accessor: 'stepPathInst.stepInst',
            RenderRead: (cell, step: Step) => {
              let color
              if (colorsTable[step.name]) color = colorsTable[step.name]
              else {
                colorsTable[step.name] = palette[Object.keys(colorsTable).length]
                color = colorsTable[step.name]
              }

              return (
                <div
                  className="fullWidth fullHeight flex center alignCenter"
                  style={{ backgroundColor: color, color: getColorFromBackground(color) }}
                >
                  {step.name}
                </div>
              )
            },
            sortingKey: 'stepPath__step__name',
          }),
        ],
      },
      {
        Header: 'File',
        id: 'file',
        columns: [
          CellText({
            id: 'createdAt',
            Header: 'Created date',
            readOnly: true,
            fixable: true,
            hiddenable: true,
            accessor: 'createdAt',
            hiddenByDefault: true,
            width: 170,
            inputProps: {
              type: 'datetime',
            },
            sortingKey: 'createdAt',
          }),
          CellText({
            id: 'fileUploadedAt',
            Header: 'Uploaded date',
            readOnly: true,
            hiddenByDefault: true,
            fixable: true,
            hiddenable: true,
            accessor: 'fileUpdatedAt',
            width: 170,
            inputProps: {
              type: 'datetime',
            },
            sortingKey: 'fileUpdatedAt',
          }),
          CellText({
            id: 'updatedAt',
            Header: 'Last update date',
            readOnly: true,
            fixable: true,
            hiddenable: true,
            accessor: 'updatedAt',
            width: 170,
            inputProps: {
              type: 'datetime',
            },
            sortingKey: 'updatedAt',
          }),
          CellText({
            id: 'fileName',
            Header: 'File name',
            readOnly: true,
            fixable: true,
            hiddenable: true,
            actions: () => [],
            RenderRead: (cell, val) => <code style={{ wordBreak: 'break-word' }}>{val}</code>,
            accessor: (item) => {
              const {
                version,
                stepPathInst,
                assetInst: { name },
              } = item
              const { path, extension } = stepPathInst
              const fileNameParts = last(path.split('/')).split('$')
              let fileName = ''

              forEach(fileNameParts, (part) => {
                if (part === '{assetname}') fileName = `${fileName}${name}`
                else if (part === '{extension}') {
                  fileName = `${fileName}.${extension}`
                } else if (part.startsWith('_v{')) {
                  const padInfo = part.substr(3, part.length - 6)
                  const [padValue, padLength]: Array<string> = padInfo.split(':')
                  fileName = `${fileName}_v${String(version).padStart(Number(padLength), padValue)}`
                }
              })

              return fileName
            },
          }),
          CellText({
            id: 'fileExtension',
            Header: 'File extension',
            readOnly: true,
            fixable: true,
            hiddenByDefault: true,
            hiddenable: true,
            actions: () => [],
            RenderRead: (cell, val) => <code>{val}</code>,
            accessor: 'stepPathInst.extension',
            sortingKey: 'stepPath__extension',
            width: 70,
          }),
          CellText({
            id: 'filePath',
            Header: 'File path',
            readOnly: true,
            fixable: true,
            hiddenByDefault: true,
            hiddenable: true,
            actions: () => [],
            RenderRead: (cell, val) => <code>{val}</code>,
            accessor: (item) => {
              const {
                version,
                stepPathInst,
                assetInst: { name },
              } = item
              const { path, extension } = stepPathInst
              const fileNameParts = last(path.split('/')).split('$')
              let fileName = ''

              forEach(fileNameParts, (part) => {
                if (part === '{assetname}') fileName = `${fileName}${name}`
                else if (part === '{extension}') fileName = `${fileName}.${extension}`
                else if (part.startsWith('_v{')) {
                  const padInfo = part.substr(3, part.length - 6)
                  const [padValue, padLength]: Array<string> = padInfo.split(':')
                  fileName = `${fileName}_v${String(version).padStart(Number(padLength), padValue)}`
                }
              })

              fileName = dropRight(path.split('/')).concat(fileName).join('/')

              return fileName
            },
          }),
          CellAssetsSelect({
            id: 'author',
            Header: 'Author',
            readOnly: true,
            fixable: true,
            placeholder: '',
            hiddenable: true,
            showIcon: false,
            width: 170,
            actions: () => [],
            accessor: 'authorInst',
            sortingKey: 'author__name',
          }),
          CellSelect({
            id: 'fileType',
            Header: 'File type',
            readOnly: true,
            fixable: true,
            hiddenByDefault: true,
            hiddenable: true,
            actions: () => [],
            accessor: 'fileType',
            options: () => fileTypesOptions,
            sortingKey: 'fileType',
          }),
          CellSelect({
            id: 'status',
            Header: 'Status',
            readOnly: true,
            hiddenByDefault: true,
            fixable: true,
            hiddenable: true,
            actions: () => [],
            accessor: 'status',
            options: () => statusOptions,
            sortingKey: 'status',
          }),
          CellText({
            id: 'version',
            Header: 'Version',
            inputProps: {
              type: 'number',
            },
            readOnly: true,
            fixable: true,
            hiddenByDefault: true,
            hiddenable: true,
            actions: () => [],
            accessor: 'version',
            sortingKey: 'version',
            width: 30,
          }),
          CellRichText({
            id: 'comment',
            Header: 'Comment',
            readOnly: true,
            fixable: true,
            hiddenable: true,
            accessor: 'comment',
            width: 400,
          }),
          CellRichText({
            id: 'userField',
            Header: 'User field',
            readOnly: true,
            hiddenByDefault: true,
            fixable: true,
            hiddenable: true,
            accessor: 'userField',
            width: 400,
          }),
        ],
      },
    ],
    [],
  )

  const [asset, setAsset] = useState<Asset | void>()

  function onClickTreeItem({ metadata }: Object) {
    const { id } = metadata
    history.push(`/projects/${projectId || getProjectIdFromURL() || ''}/files/${id}`)
  }

  useEffect(() => {
    if (assetId) {
      resources.assets.fetchOne(assetId).then((res) => {
        setAsset(res?.resources?.[0])
      })
    }
  }, [assetId])

  const resourcesParams = useMemo(() => {
    const requestData = { projectId, parentId: undefined, assetId: undefined }

    const isDirectory = asset ? !['sh', 'mo'].includes(asset.assetType) : false
    if (asset && isDirectory) requestData.parentId = asset.id
    if (asset && !isDirectory) requestData.assetId = asset.id

    return {
      resourceType: 'files',
      requestName: 'fetchAllByProject',
      includedResources: ['authorInst', 'assetInst'],
      requestData,
      queries: {
        statMode: 'datasOnly',
      },
    }
  }, [columns, asset, projectId])

  const filters = useMemo(
    () => new Filters({ status: statusOptions, fileTypes: fileTypesOptions }).getFilters(),
    [columns, statusOptions, fileTypesOptions],
  )

  return (
    <div className="fullHeight fullWidth flex row noWrap">
      <Widget
        enableHideColumn={true}
        collapseTitle="Asset list"
        styleOpen={{ minWidth: 220 }}
        resizable={true}
        optionName="widget-files"
        defaultWidth={300}
      >
        <AssetsTreeSearch
          assetTypes={['ep', 'fo', 'mo', 'sh', 'mi']}
          showRoot={true}
          onClickItem={onClickTreeItem}
          currentAssetId={assetId}
          rootAssetId={projectId}
          expandedLevel={1}
          resizable={true}
        />
      </Widget>
      <Table
        tableId={tableId}
        projectId={projectId}
        resourcesParams={resourcesParams}
        filters={filters}
        rowExpander={true}
        columns={columns}
      />
    </div>
  )
}
