// @flow
import React, { useEffect, useMemo, useState } from 'react'
import sortBy from 'lodash/sortBy'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData } from 'app/components/Form'
import { getResources } from 'app/store/selectors'
import resources from 'app/store/resources'
import type { ProjectDepartmentJob, ProjectDepartment, Job, ResourcesList, DateDay, Department } from 'app/core/types'
import Loader from 'app/components/Loader/Loader.jsx'
import { filter } from 'lodash'

export type ModalEditProjectDepartmentJobProps = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  projectDepartmentJob?: ?ProjectDepartmentJob,
  projectDepartment?: ProjectDepartment,
  onSave?: ($Shape<ProjectDepartmentJob>) => Promise<any>,
  onChange?: ($Shape<ProjectDepartmentJob>) => any,
  parentSubRows?: Array<{ ...any, startDate: DateDay, endDate: DateDay }>,
|}

export function ModalEditProjectDepartmentJob(props: ModalEditProjectDepartmentJobProps): React$Element<any> {
  const {
    projectDepartmentJob,
    onSave,
    onChange,
    projectDepartment: defaultProjectDepartment,
    parentSubRows,
    ...modalProps
  } = props

  const [projectDepartments, setProjectDepartments] = useState<ResourcesList<ProjectDepartment>>({})
  const [projectDepartment, setProjectDepartment] = useState<ProjectDepartment | void>(
    defaultProjectDepartment || projectDepartmentJob?.projectDepartmentInst,
  )
  const [jobs, setJobs] = useState<ResourcesList<Job>>({})
  const [job, setJob] = useState<Job | void>()
  const [departments, setDepartments] = useState<ResourcesList<Department>>({})
  const [department, setDepartment] = useState<Department | void>()
  const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false)

  useEffect(() => {
    Promise.all([
      resources.projectDepartments.fetchAll({ params: { queries: { page_size: 1000 } } }).then(() => {
        const projectDepartments = getResources(undefined, 'projectDepartments', undefined, ['departmentInst'])
        setProjectDepartments(projectDepartments)
        if (!defaultProjectDepartment && projectDepartmentJob) {
          setProjectDepartment(
            getResources(undefined, 'projectDepartments', projectDepartmentJob.projectDepartment, ['departmentInst']),
          )
        }
      }),
      resources.jobs.fetchAll({ params: { queries: { page_size: 1000 } } }).then(() => {
        setJobs(getResources(undefined, 'jobs'))
        if (projectDepartmentJob) {
          setJob(getResources(undefined, 'jobs', projectDepartmentJob.job))
        }
      }),
      resources.departments.fetchAll({ params: { queries: { page_size: 1000 } } }).then(() => {
        setDepartments(getResources(undefined, 'departments'))
      }),
    ]).then(() => setResourcesLoaded(true))
  }, [])

  useEffect(() => {
    if (projectDepartment && departments) {
      setDepartment(departments[projectDepartment.department])
    }
  }, [projectDepartment, departments])

  async function save(values) {
    const { job, projectDepartment, name, startDate, endDate } = values

    const newProjectDepartmentJob = {
      id: projectDepartmentJob?.id,
      name,
      startDate,
      endDate,
      projectDepartment: projectDepartment?.value,
      job: job?.value,
    }

    if (onSave) return onSave(newProjectDepartmentJob)

    const res = await resources.projectDepartmentJobs[newProjectDepartmentJob?.id ? 'update' : 'create'](
      newProjectDepartmentJob,
    )
    onChange?.(res.resources[0])
    return res
  }

  const filteredProjectDepartments = useMemo(() => {
    if (!projectDepartment?.projectPlan) return []
    return filter(projectDepartments, (pd) => pd.projectPlan === projectDepartment?.projectPlan)
  }, [projectDepartment, projectDepartments])

  const { estimatedStartDate, estimatedEndDate } = useMemo(() => {
    if (defaultProjectDepartment) {
      return {
        estimatedStartDate: defaultProjectDepartment.startDate,
        estimatedEndDate: defaultProjectDepartment.endDate,
      }
    }

    return {
      estimatedStartDate,
      estimatedEndDate,
    }
  }, [defaultProjectDepartment])

  const defaultData = {
    ...projectDepartmentJob,
    projectDepartment:
      department && projectDepartment ? { label: department.name, value: projectDepartment.id } : undefined,
    job: job ? { label: job.name, value: job.id } : undefined,
    startDate: projectDepartmentJob?.startDate || estimatedStartDate,
    endDate: projectDepartmentJob?.endDate || estimatedEndDate,
  }

  return (
    <ModalConfirmForm
      title={projectDepartmentJob ? `Edit post of ${projectDepartmentJob.name || ''}` : 'Create a new post'}
      draggable={true}
      {...modalProps}
    >
      {!resourcesLoaded ? (
        <div style={{ height: 300 }} className="fullWidth flex center alignCenter">
          <Loader />
        </div>
      ) : (
        <FormData
          onSave={save}
          flashNotifSuccessLabel={`Post ${projectDepartmentJob ? 'edited' : 'created'}`}
          defaultData={defaultData}
          properties={[
            {
              label: 'Department',
              key: 'projectDepartment',
              type: 'select',
              elementProps: {
                options: sortBy(filteredProjectDepartments, ['departmentInst.name']).map((pjDpt) => ({
                  label: departments[pjDpt.department].name,
                  value: pjDpt.id,
                })),
                isRequired: true,
                fullWidth: true,
                disabled: !!defaultProjectDepartment,
                placeholder: 'Select a department',
              },
            },
            {
              label: 'Job',
              key: 'job',
              type: 'select',
              elementProps: (data, setData) => {
                return {
                  value: data.job,
                  onChange: (opt) => {
                    if (!data.name) setData({ ...data, job: opt, name: opt?.label ? `${opt?.label}-1` : '' })
                    else setData({ ...data, job: opt })
                  },
                  options: sortBy(jobs, ['name']).map((job) => ({ label: job.name, value: job.id })),
                  isRequired: true,
                  placeholder: 'Select a job',
                  style: { width: '100%' },
                }
              },
            },
            {
              label: 'Name',
              key: 'name',
              type: 'string',
              elementProps: {
                isRequired: true,
              },
            },
            {
              label: 'Start date',
              key: 'startDate',
              type: 'string',
              elementProps: (data, setData) => {
                return {
                  value: data.startDate,
                  onChange: (e) => {
                    if (!data.endDate) setData({ ...data, startDate: e.target.value, endDate: e.target.value })
                    else setData({ ...data, startDate: e.target.value })
                  },
                  type: 'date',
                  isRequired: true,
                }
              },
            },
            {
              label: 'End date',
              key: 'endDate',
              type: 'string',
              elementProps: (data, setData) => {
                return {
                  value: data.endDate,
                  onChange: (e) => {
                    if (!data.startDate) setData({ ...data, endDate: e.target.value, startDate: e.target.value })
                    else setData({ ...data, endDate: e.target.value })
                  },
                  type: 'date',
                  isRequired: true,
                }
              },
            },
          ]}
        />
      )}
    </ModalConfirmForm>
  )
}
