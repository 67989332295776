/** @flow */
import { startsWith, isNumber, map } from 'lodash'
import type { CalculatedField } from 'app/core/types'
import { getResources } from 'app/store/selectors'
import { calculatedFieldsModels } from './calculatedFieldsModels'
import type { CalculatedFieldsUnit, FormulaItem } from './calculatedFieldsTypes'

const findUnit = (calculatedFieldType: string, prefix: string) => {
  const model = calculatedFieldsModels.find((mo) => mo.value === calculatedFieldType)
  if (!model) return null
  return Array.isArray(model.units) ? model.units.find((unit) => unit.prefix === prefix) : model.units
}

const findAttributeFromValue = (value: string, unit: ?CalculatedFieldsUnit) => {
  if (!unit) return null
  if (Array.isArray(unit.attributes)) {
    const separatorIndex = value.indexOf('.')
    const attrKey = value.substr(separatorIndex + 1)

    return Array.isArray(unit.attributes) ? unit.attributes.find((attr) => attr.key === attrKey) : null
  }
  return unit.attributes
}

const strToValue = (item: string, calculatedFieldType: string) => {
  const strValue = item.substr(1, item.length - 2)

  const [prefix, value] = strValue.split('|')

  const unit = findUnit(calculatedFieldType, prefix)
  const attribute = findAttributeFromValue(value, unit)

  const label = attribute?.parser?.(value)

  const resources = unit ? map(getResources(undefined, unit?.resourceName, { name: label }))[0] : null

  return {
    type: 'value',
    label,
    unit,
    prefix,
    attribute,
    value: resources,
  }
}

const splitPart = (parts: string, calculatedFieldType: string, ops?: Array<string> = [' + ', ' - ', ' / ', ' * ']) => {
  const splittedParts = []

  const _ops = ops.sort((prev, op) => (op.length > prev.length ? 1 : 0))

  let startIndex = 0
  for (let index = 0; index < parts.length; index += 1) {
    for (let i = 0; i < _ops.length; i += 1) {
      const op = _ops[i]

      if (startsWith(parts, op, index)) {
        const prevValue = parts.substr(startIndex, index - startIndex)
        const opValue = parts.substr(index, op.length).trim()
        const operator = { type: 'operator', value: opValue, label: opValue }

        splittedParts.push(strToValue(prevValue, calculatedFieldType), operator)
        index += op.length
        startIndex = index
        break
      }
    }
  }

  const prevValue = parts.substr(startIndex, parts.length - startIndex)
  if (prevValue) splittedParts.push(strToValue(prevValue, calculatedFieldType))
  return splittedParts
}

const execFunc = (str: string, calculatedFieldType: string) => {
  let label = ''
  let items = []

  let startIndex
  let endIndex

  for (let i = 0; i < str.length; i += 1) {
    if (str[i] === '(') {
      label = str.substr(0, i)
      startIndex = i + 1
    }
    if (str[i] === ')') endIndex = i
  }

  if (isNumber(startIndex) && isNumber(endIndex)) {
    items = splitPart(str.substr(startIndex || 0, (endIndex || 0) - (startIndex || 0)), calculatedFieldType, [', '])
  }

  return {
    type: 'function',
    label,
    items,
  }
}

export const parse = (calculatedField: ?$Shape<CalculatedField>): Array<FormulaItem> => {
  if (!calculatedField) return []

  const { calculatedFieldType, formula } = calculatedField

  if (!formula || !calculatedFieldType) return []

  const toReturn = []

  let lastPushedIndex = 0

  let startOfFunc = 0
  let execIndex = 0

  for (let index = 0; index < formula.length; index += 1) {
    if (formula[index] === '(') {
      execIndex += 1
      if (execIndex === 1) {
        for (let i = index; i > 0; i -= 1) {
          if (/[a-zA-Z0-9]/.test(formula[index]) && startOfFunc > 0) {
            startOfFunc = i
          } else break
        }
      }
    } else if (formula[index] === ')') {
      if (execIndex === 1) {
        if (lastPushedIndex < startOfFunc - 1) {
          toReturn.push(
            ...splitPart(formula.substr(lastPushedIndex, startOfFunc - lastPushedIndex), calculatedFieldType),
          )
        }
        toReturn.push(execFunc(formula.substr(startOfFunc, index + 1 - startOfFunc), calculatedFieldType))
        lastPushedIndex = index + 1
      }
      execIndex -= 1
    }
  }

  if (execIndex > 0 || execIndex < 0) return []

  if (lastPushedIndex < formula.length)
    toReturn.push(...splitPart(formula.substr(lastPushedIndex, formula.length), calculatedFieldType))

  // $FlowFixMe
  return toReturn
}
