/** @flow */
import React from 'react'
import { MUISelect } from 'app/components/Form'
import type { ID, Article } from 'app/core/types'

type Props = {|
  onChange: Function,
  value?: ID,
  excludeId?: ID,
  articles: Array<Article>,
|}

export default function (props: Props): React$Node {
  const { value, onChange, articles, excludeId } = props

  const options = articles
    .filter(({ id }) => id !== excludeId)
    .map((article) => ({ value: article.id, label: article.title }))

  return <MUISelect value={value} onChange={onChange} options={[{ label: 'No parent', value: '' }, ...options]} />
}
