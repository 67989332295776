/** @flow */
import React, { useContext } from 'react'
import cx from 'classnames'
import { MenuContext } from 'app/main/Authenticated/Authenticated.tsx'
import classes from './Nav.module.scss'
import FontIcon from '../FontIcon/FontIcon.jsx'

type Props = {
  className?: string,
  children: any,
}

export default function (props: Props): React$Node {
  const { children, className, ...rest } = props
  const { toggleMenu, isOpen } = useContext(MenuContext)

  return (
    // $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow
    <div className={cx(classes.leftNav, className)} {...rest}>
      <div className={classes.itemsContainer}>{children}</div>
      <div className={cx(classes.resizeContainer)} onClick={toggleMenu} style={{ width: isOpen ? '100%' : 40 }}>
        <div className={classes.resizeInner}>
          <div style={{ minWidth: 18 }} className={classes.resizeInner}>
            <FontIcon icon={isOpen ? 'fas-angle-left' : 'fas-angle-right'} />
          </div>
          <div
            style={{
              paddingRight: 5,
              opacity: isOpen ? 1 : 0,
              position: isOpen ? 'initial' : 'absolute',
            }}
          >
            Collapse
          </div>
        </div>
      </div>
    </div>
  )
}
