// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Project-Breakdown-___ModalLinks-module__increment {
  padding: 0px 5px;
  color: #fff;
  font-size: 20px;
  line-height: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}

  .app-pages-Project-Breakdown-___ModalLinks-module__increment:hover {
    color: var(--colors-black);
  }

.app-pages-Project-Breakdown-___ModalLinks-module__trashIcon:hover {
    color: var(--colors-black);
  }
`, "",{"version":3,"sources":["webpack://./app/pages/Project/Breakdown/ModalLinks.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,eAAe;AAKjB;;EAHE;IACE,0BAA0B;EAC5B;;AAIA;IACE,0BAA0B;EAC5B","sourcesContent":[".increment {\n  padding: 0px 5px;\n  color: #fff;\n  font-size: 20px;\n  line-height: 10px;\n  user-select: none;\n  cursor: pointer;\n\n  &:hover {\n    color: var(--colors-black);\n  }\n}\n\n.trashIcon {\n  &:hover {\n    color: var(--colors-black);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"increment": `app-pages-Project-Breakdown-___ModalLinks-module__increment`,
	"trashIcon": `app-pages-Project-Breakdown-___ModalLinks-module__trashIcon`
};
export default ___CSS_LOADER_EXPORT___;
