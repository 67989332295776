/** @flow */
import React from 'react'
import { map } from 'lodash'
import { MUIButton } from 'app/components/Form'

import { ModalPie } from './ModalPie.jsx'
import { mountModal } from '../../TableView/Elements/ModalEdition.jsx'
import type { CellInstance } from '../../types'
import { Container, MakeTableFromArray } from './index'

type Props = {|
  formatValue?: (value: any) => React$Node,
  radialLabel?: (values: Object) => React$Node,
  formatLabel?: (value: any) => React$Node,
  name: string,
  instance: CellInstance,
  showModal?: boolean,
|}

export function CellStatsAttributes(props: Props): React$Node {
  const { instance, name, formatValue, formatLabel, radialLabel, showModal } = props
  const { stats, tableId } = instance

  if (!stats || !stats.default) return <div />
  const attrStats = stats.default[name]
  if (!attrStats) return <div />

  function displayValue(value, attr) {
    if (!value && value !== 0)
      return (
        <span key={attr} style={{ color: 'lightgrey' }}>
          null
        </span>
      )

    if (Array.isArray(value)) {
      return (
        <MakeTableFromArray
          tableId={tableId}
          style={{ height: '100%' }}
          key={attr}
          values={value}
          referenceName={name}
          formatValue={formatValue}
          formatLabel={formatLabel}
          radialLabel={radialLabel}
        />
      )
    }

    return (
      <div key={attr} className="flex row wrap alignCenter">
        <span className="marginRight5 bold">{attr}: </span>
        {formatValue ? formatValue(value) : value}
      </div>
    )
  }

  function showPieModal() {
    return (
      <div className="floatRight marginBottom5" style={{ maxWidth: '100%', height: 30 }}>
        <MUIButton
          onClick={() =>
            mountModal(ModalPie, tableId, {
              data: map(attrStats, (value: Object, label: string) => ({
                value,
                label: String(label),
                id: String(label),
              })),
              radialLabel: radialLabel || (formatValue ? (vals) => formatValue(vals.label) : undefined),
              formatLabel,
              formatValue,
            })
          }
          bgColor="#ffffff"
          style={{ color: '#000000' }}
          icon="fas-chart-pie"
          dataCy="table-open-pie"
        />
      </div>
    )
  }

  if (Array.isArray(attrStats)) {
    return (
      <Container>
        <MakeTableFromArray
          tableId={tableId}
          style={{ height: '100%' }}
          values={attrStats}
          referenceName={name}
          formatValue={formatValue}
          formatLabel={formatLabel}
        />
      </Container>
    )
  }

  return (
    <Container>
      <div className="fullWidth">
        {showModal ? showPieModal() : null}
        <div className="fitContent">{map(attrStats, displayValue)}</div>
      </div>
    </Container>
  )
}
