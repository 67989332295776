import { jsx as _jsx } from "react/jsx-runtime";
import { TableTasks } from 'app/pages/Project/Tasks/TableTasks';
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL';
import { permission } from 'app/containers/Permissions';
export const tableId = 'AssetDetailsTableTask';
function Tasks(props) {
    const { asset } = props;
    const projectId = asset.project || getProjectIdFromURL();
    if (!projectId) {
        return (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter lightgrey", style: { padding: 10, fontSize: 14 }, children: `You have to be in the ${asset.name} project to be able to see the tasks` }));
    }
    if (projectId && !permission(['projet_tasks__read'], undefined, undefined, projectId)) {
        return (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter lightgrey", style: { padding: 10, fontSize: 14 }, children: `You don't have permission to see the tasks table.` }));
    }
    return (_jsx(TableTasks, { tableId: tableId, projectId: projectId, fromAsset: asset, resources: {
            resourceType: 'tasks',
            requestName: 'fetchByAsset',
            headers: { [window.OVM_PROJECT_HEADER]: projectId || '' },
            requestData: {
                id: asset.id,
            },
            includedResources: [
                'assetInst',
                'assetInst.thumbnailInst',
                'stepInst',
                'assignedUserInst',
                'statusInst',
                'activitiesInst',
                'takesInst.statusInst',
                'takesInst.takeFlagsInst.flagInst',
                'takesInst.takeRefMediasInst.mediaInst',
                'takesInst.takeValidationMediasInst.mediaInst',
            ],
        } }));
}
export default Tasks;
