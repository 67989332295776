/** @flow */
import React from 'react'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import { dynamicApprovalStatus, dynamicApprovalColors } from 'app/core/constants/dynamicApprovalStatus'
import { getAge } from 'app/components/Table/utils'

import type { Column } from '../../types'
import classes from './groupingFns.module.scss'

export const groupingFns: $ElementType<Column, 'groupingFns'> = {
  status: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const resKey = String(row.values[columnId]?.status)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Status',
    headerRow: (cell) => {
      if (!cell.value) return null
      const { status } = cell.value
      if (!status) return null

      return (
        <div
          className={classes.status}
          style={{
            backgroundColor: dynamicApprovalColors[status],
            color: getColorFromBackground(dynamicApprovalColors[status]),
          }}
        >
          {dynamicApprovalStatus[status]}
        </div>
      )
    },
  },
  author: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const resKey = String(row.values[columnId]?.author)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Author',
    headerRow: (cell) =>
      cell.value?.authorInst?.name ? <div className={classes.assignedUser}>{cell.value?.authorInst?.name}</div> : null,
  },
  updatedAt: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const resKey = String(row.values[columnId].updatedAt ? getAge(row.values[columnId].updatedAt) : undefined)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Update',
    headerRow: (cell) => (cell.value?.updatedAt ? <div className="bold">{getAge(cell.value.updatedAt)}</div> : null),
  },
  statusUpdatedAt: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const resKey = String(
          row.values[columnId].statusUpdatedAt ? getAge(row.values[columnId].statusUpdatedAt) : undefined,
        )
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Status update',
    headerRow: (cell) =>
      cell.value?.statusUpdatedAt ? <div className="bold">{getAge(cell.value.statusUpdatedAt)}</div> : null,
  },
  hasComment: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const resKey = String(
          row.values[columnId].id ? (row.values[columnId]?.comment ? 'comment' : 'nocomment') : undefined,
        )
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Have a comment',
    headerRow: (cell) =>
      cell.value.id ? (
        cell.value?.comment ? (
          <div className="bold">Have a comment</div>
        ) : (
          <div className="bold">Do not have a comment</div>
        )
      ) : null,
  },
}
