/** @flow */
import type { AssetTypes, IconType } from 'app/core/types'

export const assetIcons = (type: ?AssetTypes): IconType => {
  // prettier-ignore
  switch (type) {
    case 'pc': return 'fas-desktop'
    case 'lc': return 'fas-map-marker-alt'
    case 'gp': return 'fas-users'
    case 'us': return 'fas-user'
    case 'pj': return 'fas-project-diagram'
    case 'fo': return 'fas-folder'
    case 'mo': return 'fas-cube'
    case 'mi': return 'fas-crosshairs'
    case 'ep': return 'fas-film'
    case 'sq': return 'fas-images'
    case 'sh': return 'fas-image'
    case 'of': return 'fas-building'
    default: return 'fas-question'
  }
}
