// $FlowFixMe
import { flatten, map } from 'lodash';
import { operators as op } from 'app/components/Table/TableView/Elements/Filters/operators';
export class Filters {
    constructor({ columns }) {
        this.params = {};
        this.filters = {};
        this.params = { columns };
        this.filters = {
            user: [
                {
                    label: 'User name',
                    type: 'column',
                    operators: [
                        ...map([op.exact, op.contain], (op) => (Object.assign(Object.assign({}, op), { attr: 'user__username' }))),
                    ],
                },
            ],
            date: [
                {
                    label: 'Expiration date',
                    type: 'column',
                    operators: [
                        ...map([op.exact, op.range, op.dateGt, op.dateGte, op.dateLt, op.dateLte], (op) => (Object.assign(Object.assign({}, op), { attr: 'expiration', inputType: 'datetime' }))),
                    ],
                },
            ],
        };
    }
    getFilters() {
        const list = flatten(map(this.filters));
        return list;
    }
}
