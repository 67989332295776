/** @flow */
import React from 'react'
import { map, forEach, uniq, sortBy } from 'lodash'
import { ModalLinks } from 'app/pages/Project/Breakdown/ModalLinks.jsx'
import type { Asset } from 'app/core/types'
import { getTextWidth } from 'app/libs/helpers/getTextWidth'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import type { DefaultCellProps, Column, TableInstance } from 'app/components/Table/types.js'

import { groupingFns } from './groupingFns.jsx'
import classes from './CellAssetsList.module.scss'

type CellAssetsListParams = {|
  ...DefaultCellProps,
  onOpenItem?: (asset: $Shape<Asset>) => void,
  disableClickableAssets?: boolean,
  category: string,
  aggregated?: boolean,
|}

const LINK_MARGIN_WIDTH = 24
const REST_LABEL = 60
const ITEM_HEIGHT = 23

const reduceItemsOnReducedRow = (assets, isRowExpanded, cellWidth, prefs) => {
  const { minLineHeight } = prefs || {}

  const links = []
  const hiddenLinks = []

  const nbLinesToDisplay = Math.floor((minLineHeight - 2) / ITEM_HEIGHT)

  let rest = 0
  let currentLine = nbLinesToDisplay - 1
  let currentLineWidth = cellWidth

  assets.forEach((link) => {
    if (isRowExpanded) {
      links.push(link)
      return
    }

    if (rest > 0) {
      hiddenLinks.push(link)
      rest += 1
      return
    }

    const linkWidth = getTextWidth(link.name, 14, '"Helvetica", "Arial", sans-serif') + LINK_MARGIN_WIDTH

    if (currentLine > 0) {
      if (currentLineWidth - linkWidth > 0) {
        currentLineWidth -= linkWidth
        links.push(link)
        return
      }
      currentLine -= 1
      currentLineWidth = cellWidth

      if (currentLine > 0) {
        currentLineWidth -= linkWidth
        links.push(link)
        return
      }
    }

    if (currentLine <= 0 && currentLineWidth - (linkWidth + REST_LABEL) >= 0) {
      links.push(link)
      currentLineWidth -= linkWidth
      return
    }
    hiddenLinks.push(link)
    rest += 1
  })
  return {
    rest,
    hiddenLinks,
    links,
  }
}

type AssetListReadProps = {|
  assets: Array<{ ...Asset, count: number }>,
  onOpenItem?: $ElementType<CellAssetsListParams, 'onOpenItem'>,
  cellWidth?: number,
  isRowExpanded: boolean,
  disableClickableAssets: boolean,
  prefs: $ElementType<TableInstance, 'prefs'>,
|}

export const AssetListRead = (props: AssetListReadProps): React$Node => {
  const { assets, onOpenItem, cellWidth = 80, isRowExpanded, disableClickableAssets, prefs } = props

  let links = assets
  let rest = 0
  let hiddenLinks = []

  if (assets.length > 3)
    ({ links, rest, hiddenLinks } = reduceItemsOnReducedRow(assets, isRowExpanded, cellWidth, prefs))

  return [
    ...links.map(({ count, ...asset }) => (
      <span
        key={asset.id}
        className={`${classes.asset} activeSelection`}
        onClick={
          disableClickableAssets
            ? undefined
            : (e) => {
                if (e.detail === 2) return
                if (onOpenItem) onOpenItem(asset)
              }
        }
      >
        {asset.name} {count === undefined || count === 1 ? '' : `(x${count})`}
      </span>
    )),
    rest ? (
      <Tooltip
        key="tooltip"
        placement="right"
        interactive={true}
        style={{ minWidth: 300, maxHeight: '80vh' }}
        title={
          <div className="flex wrap fullHeight overflowYAuto" style={{ maxHeight: 'calc(80vh - 30px)' }}>
            {hiddenLinks.map(({ count, ...asset }) => (
              <span key={asset.id} className={`${classes.asset} ${classes.assetTooltip}`}>
                {asset.name} {count === undefined || count === 1 ? '' : `(x${count})`}
              </span>
            ))}
          </div>
        }
      >
        <div className={classes.restLabel}>+{rest}</div>
      </Tooltip>
    ) : null,
  ]
}

export const CellAssetsList = ({
  onOpenItem,
  category,
  aggregated,
  disableClickableAssets,
  actions,
  ...data
}: CellAssetsListParams): Column => {
  return {
    cellType: 'CellAssetsList',
    Cell: (instance) => {
      const { cell, prefs } = instance
      const {
        value,
        getCellProps,
        column: { width },
      } = cell
      const { isRowExpanded } = getCellProps()
      const { minLineHeight, maxLineHeight } = prefs

      let aggregatedValues: Array<{ ...Asset, count: number }>
      if (aggregated) aggregatedValues = value
      else {
        aggregatedValues = []

        value.forEach((link) => {
          const existingLink = aggregatedValues.find((_link) => _link.id === link.to_asset)
          if (existingLink) {
            existingLink.count += 1
          } else {
            aggregatedValues.push({ count: 1, ...link.to_assetInst })
          }
        })
      }

      return (
        <div className={classes.container} style={{ maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2 }}>
          <AssetListRead
            assets={sortBy(aggregatedValues, ['name'])}
            onOpenItem={onOpenItem}
            cellWidth={width}
            isRowExpanded={isRowExpanded}
            disableClickableAssets={Boolean(disableClickableAssets)}
            prefs={prefs}
          />
        </div>
      )
    },
    EditModal: (instance) => {
      const {
        state: { selectedCells },
        cell,
        cellPositionLabel,
      } = instance
      const { value, getCellProps } = cell
      const { edition } = getCellProps()
      const { endEdit, save } = edition

      const fromAssetsId = map(selectedCells, (cell) => cell.row.original?.id)
      let categories: Array<string> = []

      forEach(selectedCells, (cell) => {
        if (cell.column.extends?.category) categories.push(cell.column.extends.category)
      })

      categories = uniq(categories)

      return (
        <ModalLinks
          title={category}
          fromAssetsId={fromAssetsId}
          onRequestClose={endEdit}
          categories={categories}
          onChange={save}
          value={value}
          selectedCells={selectedCells}
          exponentTitle={cellPositionLabel}
        />
      )
    },
    extends: {
      category,
    },
    actions: (instance, cell) => {
      const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []

      const pastIndex = resolvedActions.findIndex((action) =>
        // $FlowFixMe
        typeof action === 'string' ? action === 'past' : action.label === 'Past',
      )

      if (pastIndex > -1) {
        resolvedActions[pastIndex] = {
          label: 'Past',
          hotKeys: ['ctrl+shift+v', 'command+shift+v'],
          onClick: () => cell.getCellProps()?.edition.past(),
          rightLabel: 'Ctrl + Shift + V',
          editAction: true,
        }
        resolvedActions.splice(pastIndex, 0, {
          label: 'Merge',
          rightLabel: 'Ctrl + V',
          onClick: (event: SyntheticKeyboardEvent<>) => {
            const { state, saveData } = instance
            const { copiedCells } = state

            if (!copiedCells || Object.keys(copiedCells).length === 0) return

            saveData({ cell, instance, value: map(copiedCells, (cell) => cell.value).flat(), type: 'merge' })
          },
          hotKeys: ['ctrl+v', 'command+v'],
          editAction: true,
        })
      }

      return resolvedActions
    },
    groupingFns: groupingFns(aggregated),
    width: 200,
    ...data,
  }
}
