import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useState } from 'react';
import { Input } from 'app/components/Form';
import { BlocklyWorkspace } from 'react-blockly';
import Blockly from 'blockly';
import * as blocklyJavascript from 'blockly/javascript';
import { useDispatch, useSelector } from 'react-redux';
import './customBlocks/ovm_blocks';
import { fetchCalculatedFieldsBlocks } from '../../../../../store/reducers/calculatedFields';
import { toolbox } from './customBlocks/toolbox.js';
import { MUINewModal } from '../../../../../components/Modal/MUINewModal';
const ModalCreateCalculatedField = ({ calculatedField, onValidate, setModalIsOpen, cfLoading, }) => {
    const dispatch = useDispatch();
    const { blocks: { data: blocks, blocksLoading }, } = useSelector((state) => state.calculatedFields);
    const [name, setName] = useState((calculatedField === null || calculatedField === void 0 ? void 0 : calculatedField.name) || '');
    const [blocklyHasLoaded, setBlocklyHasLoaded] = useState(false);
    const [javascriptCode, setJavascriptCode] = useState('');
    const [blocklyWorkspace, setBlocklyWorkspace] = useState('');
    useEffect(() => {
        dispatch(fetchCalculatedFieldsBlocks());
    }, []);
    useEffect(() => {
        if (blocks.length && !blocksLoading) {
            Blockly.defineBlocksWithJsonArray(blocks);
            setBlocklyHasLoaded(true);
        }
    }, [blocks]);
    const workspaceDidChange = (workspace) => {
        const code = blocklyJavascript.javascriptGenerator.workspaceToCode(workspace);
        setJavascriptCode(code);
        const state = Blockly.serialization.workspaces.save(workspace);
        setBlocklyWorkspace(JSON.stringify(state));
    };
    const initWorkspace = (workspace) => {
        const checkResultType = (block) => block.type === 'result';
        if (!workspace.getTopBlocks().some(checkResultType)) {
            const parentBlock = workspace.newBlock('result');
            parentBlock.initSvg();
            parentBlock.render();
            parentBlock.setDeletable(false);
        }
        if (calculatedField === null || calculatedField === void 0 ? void 0 : calculatedField.blocklyWorkspace) {
            Blockly.serialization.workspaces.load(calculatedField.blocklyWorkspace, workspace);
        }
        workspace.toolbox_.flyout_.autoClose = false;
    };
    const onModalValidation = () => {
        if (!name || !javascriptCode) {
            const errors = [];
            if (!name)
                errors.push(' Name is required');
            if (!javascriptCode)
                errors.push(' Formula is required');
            return Promise.reject({ serverError: { infos: { errors } } });
        }
        // USELESS WITH TS. Remove when migrate CalculatedFields component to .tsx
        if (!onValidate)
            return Promise.reject({ serverError: { infos: { errors: ['onValidate function is required'] } } });
        return onValidate(Object.assign(Object.assign({}, calculatedField), { name, definition: JSON.parse(javascriptCode), blocklyWorkspace: JSON.parse(blocklyWorkspace) }));
    };
    return (_jsx(MUINewModal, { title: (calculatedField === null || calculatedField === void 0 ? void 0 : calculatedField.name) ? `Edit calculated field ${calculatedField.name}` : 'New calculated field', width: 1500, height: 1000, resizable: true, onValidate: onModalValidation, loading: cfLoading, setModalIsOpen: setModalIsOpen, children: _jsxs("div", { className: "fullWidth flex column", children: [_jsx("div", { className: "bold fontSize16", children: "Field name" }), _jsx("div", { className: "padding10 paddingBottom15", children: _jsx(Input, { value: name, onChange: (e) => setName(e.target.value), dataCy: "calculatedFieldEditor-fieldName" }) }), _jsx("div", { className: "bold fontSize16", children: "Formula" }), _jsxs("div", { className: "marginTop10 paddingLeft10 paddingRight10", children: [blocks.length && !blocksLoading && blocklyHasLoaded ? (_jsx(BlocklyWorkspace, { toolboxConfiguration: toolbox, className: "height500", workspaceConfiguration: {
                                grid: {
                                    spacing: 20,
                                    length: 3,
                                    colour: '#ccc',
                                    snap: true,
                                },
                            }, onWorkspaceChange: workspaceDidChange, onInject: initWorkspace })) : null, _jsx("textarea", { id: "code", style: { height: '200px', width: '400px' }, value: javascriptCode, readOnly: true })] })] }) }));
};
export default ModalCreateCalculatedField;
