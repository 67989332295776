// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellThumbnail-___CellThumbnail-module__picture {
  box-shadow: none;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
}

.app-components-Table-Cells-CellThumbnail-___CellThumbnail-module__isPicture:focus-within {
  pointer-events: auto;
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.app-components-Table-Cells-CellThumbnail-___CellThumbnail-module__isPicture:focus-within:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellThumbnail/CellThumbnail.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".picture {\n  box-shadow: none;\n  max-width: 100%;\n  max-height: 100%;\n  pointer-events: none;\n}\n\n.isPicture:focus-within {\n  pointer-events: auto;\n  position: relative;\n  cursor: pointer;\n  z-index: 2;\n}\n\n.isPicture:focus-within:hover {\n  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"picture": `app-components-Table-Cells-CellThumbnail-___CellThumbnail-module__picture`,
	"isPicture": `app-components-Table-Cells-CellThumbnail-___CellThumbnail-module__isPicture`
};
export default ___CSS_LOADER_EXPORT___;
