/** @flow */
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { MUIButton } from 'app/components/Form'

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    const { bgColor } = props

    return {
      '&:hover': {
        boxShadow: '0 0 30px rgba(0, 0, 0, 0.1) inset',
        backgroundColor: bgColor || 'initial',
      },
      boxShadow: 'none',
      marginLeft: 5,
      borderRadius: '3px 3px 0 0',
      backgroundColor: bgColor,
      color: bgColor ? '#ffffff' : 'grey',
    }
  },
  disabled: {
    color: '#ffffff',
  },
}))

export function WikiEditorButton({ bgColor, label, children, ...rest }: *): React$Element<any> {
  const classes = useStyles({ bgColor })

  return (
    <MUIButton classes={classes} {...rest} variant="text">
      {label || children}
    </MUIButton>
  )
}
