/** @flow */
import React from 'react'
import classes from './NotFound.module.scss'

export function NotFound(): React$Node {
  return (
    <div className={classes.notFound}>
      <div className="fontSize40" data-testid="404">
        404
      </div>
      <div data-testid="notFoundText">Page not found</div>
    </div>
  )
}
