// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-MyHoursCalendar-___MyHoursCalendar-module__dayInCalendar {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 0px;
  position: relative;
  flex-direction: column;
  padding: 3px;
}

.app-containers-MyHoursCalendar-___MyHoursCalendar-module__dayInCalendar:not(.app-containers-MyHoursCalendar-___MyHoursCalendar-module__isCurrentDay) .app-containers-MyHoursCalendar-___MyHoursCalendar-module__dayString {
  margin-top: 5px;
}

.app-containers-MyHoursCalendar-___MyHoursCalendar-module__dayString {
  font-weight: bold;
  line-height: 16px;
  font-size: 17px;
}

.app-containers-MyHoursCalendar-___MyHoursCalendar-module__dayInCalendar.app-containers-MyHoursCalendar-___MyHoursCalendar-module__isCurrentDay {
  margin-top: 4px;
}

.app-containers-MyHoursCalendar-___MyHoursCalendar-module__warning {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.app-containers-MyHoursCalendar-___MyHoursCalendar-module__warningHours {
  font-size: 13px;
  line-height: 14px;
}
`, "",{"version":3,"sources":["webpack://./app/containers/MyHoursCalendar/MyHoursCalendar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".dayInCalendar {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  margin-top: 0px;\n  position: relative;\n  flex-direction: column;\n  padding: 3px;\n}\n\n.dayInCalendar:not(.isCurrentDay) .dayString {\n  margin-top: 5px;\n}\n\n.dayString {\n  font-weight: bold;\n  line-height: 16px;\n  font-size: 17px;\n}\n\n.dayInCalendar.isCurrentDay {\n  margin-top: 4px;\n}\n\n.warning {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n}\n\n.warningHours {\n  font-size: 13px;\n  line-height: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dayInCalendar": `app-containers-MyHoursCalendar-___MyHoursCalendar-module__dayInCalendar`,
	"isCurrentDay": `app-containers-MyHoursCalendar-___MyHoursCalendar-module__isCurrentDay`,
	"dayString": `app-containers-MyHoursCalendar-___MyHoursCalendar-module__dayString`,
	"warning": `app-containers-MyHoursCalendar-___MyHoursCalendar-module__warning`,
	"warningHours": `app-containers-MyHoursCalendar-___MyHoursCalendar-module__warningHours`
};
export default ___CSS_LOADER_EXPORT___;
