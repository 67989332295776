// @flow
// DO NOT EDIT. THIS IS FILE AUTO-GENERATED

export const icons = {
  categoryAdd: 'categoryAdd',
  categoryEdit: 'categoryEdit',
  categoryRemove: 'categoryRemove',
  categorySettings: 'categorySettings',
  category: 'category',
  filepath: 'filepath',
  networkSans: 'networkSans',
  nounReset: 'nounReset-415758-2',
  nounTask: 'nounTask-1784216',
  calendar: 'calendar',
  calendarCheckO: 'calendarCheckO',
}

export const iconsClassName = {
  categoryAdd: 'ovm-icon-category-add',
  categoryEdit: 'ovm-icon-category-edit',
  categoryRemove: 'ovm-icon-category-remove',
  categorySettings: 'ovm-icon-category-settings',
  category: 'ovm-icon-category',
  filepath: 'ovm-icon-filepath',
  networkSans: 'ovm-icon-network-sans',
  nounReset: 'ovm-icon-noun-reset-415758-2',
  nounTask: 'ovm-icon-noun-task-1784216',
  calendar: 'ovm-icon-calendar',
  calendarCheckO: 'ovm-icon-calendar-check-o',
}
