/** @flow */
import React, { useRef, useState, useEffect } from 'react'
import { sortBy, forEach, last, trim, map, forEachRight } from 'lodash'
import moment from 'moment'
import type { Post, Pipe, ID, Thread, User } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import { getResources } from 'app/store/selectors'
import resources from 'app/store/resources'
import api from 'app/core/api/api.js'
import { Editor } from 'app/components/TextEditor/Editor.jsx'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import { error } from 'app/components/Notifications/notify.js'
import { MUIButton } from 'app/components/Form'
import { ResizableRow } from 'app/components/ResizableContainer'
import keyCode from 'app/libs/keyCode.js'

import { Messages } from './Messages.jsx'
import classes from './Chat.module.scss'

type Props = {|
  threadId: ID,
  posts: { [ID]: Post },
  router: Object,
  collapsabeWidth: number,
  loading?: boolean,
  thread: Thread,
  selectedPost?: ID,
  user: User,
  projectId: ID,
|}

function Chat(props: Props) {
  const { posts, threadId, router, loading, thread, collapsabeWidth, selectedPost, user, projectId } = props
  const [value, setValue] = useState('')
  const [messages, setMessages] = useState({})
  const [editPost, setEditPost] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(true)

  let isMounted = true

  const editor = useRef({ getData: () => value, setData: setValue })
  const scrollableZone = useRef()
  const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } } }

  useEffect(() => {
    isMounted = true
    return () => {
      isMounted = false
    }
  })

  useEffect(() => {
    const postsByUsers = {}
    let group = []
    let user
    let date
    let lastDate = 0

    forEach(posts, (post, index) => {
      if (!date) {
        date = post.postedOn
        postsByUsers[date] = {}
      } else if (date && moment(post.postedOn).diff(moment(date)) > 3600000) {
        if (group.length > 0) {
          postsByUsers[date][lastDate] = group
        }
        group = []
        date = post.postedOn
        postsByUsers[date] = {}
        lastDate = index
      }

      if (post.authorInst && post.authorInst.name !== user) {
        if (group.length > 0) postsByUsers[date][lastDate] = group
        user = post.authorInst.name
        group = []
        lastDate = index
      }
      group.push(post)
    })

    if (group.length > 0 && date) postsByUsers[date][lastDate] = group

    isMounted && setMessages(postsByUsers)
  }, [posts])

  function leaveEdit() {
    editor.current.setData(value)
    isMounted && setEditPost(null)
  }

  function sendEdit() {
    const text = trim(editor.current?.getData?.())

    if (!text && editPost) {
      return error("Message can't be empty.")
    }

    leaveEdit()

    return api.posts.update(
      {
        id: editPost && editPost.id,
        text,
      },
      null,
      requestsConfig.params.headers,
    )
  }

  function sendMessage() {
    if (editPost) return sendEdit()
    const text = trim(editor.current?.getData?.())

    if (!text) {
      editor.current.setData('')
      return Promise.resolve()
    }

    editor.current.setData('')
    isMounted && setValue('')
    return api.posts
      .create(
        {
          thread: threadId,
          text,
        },
        null,
        requestsConfig.params.headers,
      )
      .then((post) => {
        if (scrollableZone.current) scrollableZone.current.scrollTop = scrollableZone.current.scrollHeight
        api.threads
          .lastReadedPost({ id: thread.id, post: post.id }, null, requestsConfig.params.headers)
          .then((res) => {
            resources.threads.update({ id: thread.id, unreadPostCount: 0 }, { localOnly: true })
          })
      })
  }

  function onEdit(post: Post) {
    const text = editor.current?.getData?.()

    setEditPost(post)
    editor.current.setData(post.text)
    setValue(text)
  }

  function fetchPreviousPosts() {
    const mostOldPost: Post = map(posts)[0]
    if (!mostOldPost || !mostOldPost.postedOn) return Promise.resolve()

    return resources.posts
      .fetchPreviousPosts({ threadId: thread.id, before: mostOldPost.postedOn }, requestsConfig)
      .then((res) => {
        if (res.requestProperties.count <= map(getResources(undefined, 'posts', { thread: threadId })).length) {
          isMounted && setCanLoadMore(false)
        }
      })
  }

  function onKeyDown(event: SyntheticKeyboardEvent<>) {
    if (event.keyCode === keyCode.ESCAPE) {
      event.preventDefault()
      if (editPost) leaveEdit()
    }
  }

  function onInitEditor(editor) {
    editor.editing.view.document.on(
      'keydown',
      (evt, data) => {
        if (data.keyCode === 13 && !data.shiftKey) {
          sendMessage()
          evt.stop()
        }
      },
      { priority: 'highest' },
    )
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return (
    <ResizableRow
      style={{ maxHeight: '100%', height: '100%', maxWidth: `calc(100% - ${collapsabeWidth}px` }}
      bottomMin={180}
      topMin={180}
      defaultTopSize={70}
      defaultBottomSize={30}
      optionName="chatHorizResizing"
      top={
        loading ? (
          <div className="flex flex1 center alignCenter fullHeight">
            <LoaderSmall style={{ width: 30, height: 30 }} />
          </div>
        ) : (
          <Messages
            innerRef={(ref) => {
              scrollableZone.current = ref
            }}
            selectedPost={selectedPost}
            posts={messages}
            router={router}
            onEdit={onEdit}
            thread={thread}
            fetchPreviousPosts={fetchPreviousPosts}
            canLoadMore={canLoadMore}
            userId={user.asset}
            projectId={projectId}
          />
        )
      }
      bottom={
        <div
          className="flex row relative fullWidth fullHeight"
          style={editPost ? { border: '1px solid red', borderRadius: 3 } : undefined}
        >
          <Editor
            disabled={loading}
            value={value}
            onInit={(ref) => {
              editor.current = ref
              onInitEditor(ref)
            }}
            style={{ height: 'inherit', width: 'inherit' }}
            customToolbar={[
              'fontSize',
              'fontColor',
              'fontBackgroundColor',
              '|',
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'codeBlock',
              'code',
              'link',
              'bulletedList',
              'numberedList',
              'imageUpload',
              'insertFile',
              'insertTable',
              '|',
              'undo',
              'redo',
            ]}
          />
          <MUIButton
            icon="fas-paper-plane"
            className={classes.buttonSend}
            color="primary"
            onClick={sendMessage}
            dataCy="chat-send"
          />
        </div>
      }
    />
  )
}

const pipeInst: Pipe<{ params: Object, router: Object, selectedThread: Function, project: ID }, typeof Chat> = pipe()

const ChatComponent: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { project } = props
    const { params, router, selectedThread } = props
    const postsByThread = getResources(state, 'posts', { thread: params.threadId }, [
      'authorInst',
      'authorInst.thumbnailInst',
    ])

    selectedThread(params.threadId)

    const sortedPosts = sortBy(postsByThread, ['postedOn'])
    const lastPost = last(sortedPosts)
    const thread = getResources(state, 'threads', params.threadId, ['lastPostInst'])

    if (thread && thread.unreadPostCount > 0) {
      let newPosts = thread.unreadPostCount
      forEachRight(sortedPosts, (post, index) => {
        if (newPosts === 0) {
          sortedPosts[index] = { ...sortedPosts[index], nextIsNew: true }
          newPosts -= 1
        }
        if (newPosts > 0) newPosts -= 1
      })
    }

    return {
      router,
      threadId: params.threadId,
      thread,
      projectId: project,
      posts: sortedPosts,
      userId: state.user.asset,
      lastPostId: lastPost && lastPost.id,
      selectedPost: params.postId,
      user: state.user,
    }
  })
  .request(({ userId, lastPostId, threadId, project }) => {
    const config = { params: { headers: { [window.OVM_PROJECT_HEADER]: project || '' } } }

    return Promise.all([
      resources.posts.getByThread(threadId, {
        params: { headers: { [window.OVM_PROJECT_HEADER]: project || '' }, queries: { page_size: 50 } },
      }),
      resources.assets.fetchOne(userId, config),
    ])
  })
  .renderLoader(({ userId, params, lastPostId, selectedThread, project, ...rest }) => <Chat loading={true} {...rest} />)
  .render(({ userId, params, lastPostId, selectedThread, project, ...rest }) => <Chat {...rest} />)

export default ChatComponent
