// @flow
import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import { localResource } from 'app/containers/Notif/localResource'
import type { MessagesActions } from 'app/core/types/StoreResourcesCustomActions'

export const messages: StoreResourceDef<MessagesActions<>> = {
  resourceType: 'messages',
  actions: () => ({
    findPublicMessages: (config) => {
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.publicMessages().then((messages) => ({
            resources: messages.map((message) => ({
              ...message,
              isPublic: true,
            })),
            list: 'notif',
          })),
      }
    },
    hideMessage: (message, userId = '') => {
      if (!message.disablePermanentHide) {
        localResource.setData(`${message.id}__${userId}.hidden`, true)
      }
      return {
        type: 'delete',
        requestKey: null,
        request: {
          resources: {
            messages: [message.id],
          },
        },
      }
    },
    addAuthenticatedMessages: (messages, userId) => {
      return {
        type: 'read',
        requestKey: null,
        request: () => {
          return Promise.resolve({
            resources: messages.filter((message) => {
              return !localResource.getData(`${message.id}__${userId}.hidden`, false)
            }),
            list: 'notif',
          })
        },
      }
    },
  }),
}
