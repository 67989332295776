/** @flow */
import React, { useState, useRef, useEffect, type Element } from 'react'
import PubSub from 'pubsub-js'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import socket from 'app/core/api/socket'
import settings from 'app/core/settings'
import type { ID } from 'app/core/types'
import { ExportNotification } from './ExportNotification.jsx'
import { notifyExport } from './notify'

type Props = {|
  children: *,
|}

export type NotificationParams = {|
  key?: string | number,
  exportData?: {
    userId: ID,
    ovmExportId: string,
    status: 'init' | 'started' | 'finished' | 'aborted',
    downloadUrl?: string,
  },
  anchorOrigin?: {
    vertical: 'bottom' | 'top',
    horizontal: 'center' | 'right' | 'left',
  },
  persist?: boolean,
  variant?: 'default' | 'success' | 'error' | 'info',
  autoHideDuration?: number,
  preventDuplicate?: boolean,
  dense?: boolean,
  hideIconVariant?: boolean,
  disableWindowBlurListener?: boolean,
|}

function ListenersComponents({ userId }: { userId: ID }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const sub = useRef()
  const room = useRef()

  const [exportStatus, _setExportStatus] = useState<{
    [key: string]: 'init' | 'started' | 'finished' | 'aborted' | void,
  }>({})

  const setExportStatus = (key: string, value: 'init' | 'started' | 'finished' | 'aborted' | void) => {
    _setExportStatus((exportStatus) => ({ ...exportStatus, [key]: value }))
  }

  function publishNotification(message, notifParams: NotificationParams) {
    const { exportData, ...params } = notifParams || {}
    let content
    const additionalParams = {}
    let key

    if (!message) return

    if (exportData) {
      const { status, downloadUrl } = exportData
      let { ovmExportId } = exportData

      if (status === 'aborted' && exportStatus[ovmExportId]) {
        closeSnackbar(ovmExportId)
        ovmExportId = ''
        additionalParams.variant = 'error'
      }

      setExportStatus(ovmExportId, status)

      content = (
        <ExportNotification
          text={message}
          downloadUrl={downloadUrl}
          onClose={() => {
            closeSnackbar(key)
            setExportStatus(ovmExportId, undefined)
          }}
          status={status}
          ovmExportId={ovmExportId}
          socket={room.current}
        />
      )

      additionalParams.key = ovmExportId
    } else {
      content = typeof message === 'function' ? message({ onClose: () => closeSnackbar(key) }) : message
    }

    let options: Object = {
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      autoHideDuration: 5000,
      onClick: () => closeSnackbar(key),
      'data-cy': `notif${params.variant ? `-${params.variant}` : ''}`,
    }
    options = {
      ...options,
      ...additionalParams,
      ...params,
    }

    key = enqueueSnackbar(content, options)
  }

  useEffect(() => {
    sub.current = PubSub.subscribe(
      'NOTIFY',
      (
        msg: string,
        { message, notifParams }: { message: Function | string | Element<any>, notifParams: NotificationParams },
      ) => {
        publishNotification(message, notifParams)
      },
    )
    return () => PubSub.unsubscribe(sub.current)
  })

  useEffect(() => {
    if (userId) {
      room.current = socket.subscribe(`user_${userId}`)

      room.current.on('export_finished', (data) => {
        const { exportId, fileName } = data

        const splitedFilename = fileName.split('/')
        const filename = splitedFilename[splitedFilename.length - 1].split('.')[0]

        if (!exportStatus[exportId]) {
          notifyExport(
            <span>
              The export of the table <b>{filename}</b> you have requested is ready to be downloaded.
            </span>,
            {
              exportData: {
                ovmExportId: exportId,
                userId,
                downloadUrl: `${settings.env.API_URL}/${fileName}`,
                status: 'finished',
              },
            },
          )
        }
      })
    }

    return () => {
      room.current?.remove?.()
    }
  }, [userId, exportStatus])

  return null
}

const Listeners = connect((state, props) => {
  return {
    userId: state.user.asset,
  }
})(ListenersComponents)

export function NotificationsPublisher(props: Props): React$Node {
  const { children } = props

  return (
    <>
      <Listeners />
      {children}
    </>
  )
}
