// @flow
import api from 'app/core/api/api'
import type { StoreResourceDef } from 'app/core/types'
import type { ArticlesActions } from 'app/core/types/StoreResourcesCustomActions'
import { flattenTree } from 'app/libs/helpers'
import { transformResults } from './utils/transformResults'

export const articles: StoreResourceDef<ArticlesActions<>> = {
  resourceType: 'articles',
  actions: (actionCreators) => ({
    fetchTOC: (assetId, config = {}) => {
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets
            .toc({ id: assetId })
            .then((toc) => {
              const list = flattenTree(toc, 'articles')
              return list.map((article) => ({
                ...article,
                asset: assetId,
              }))
            })
            .then(transformResults(config))
        },
      }
    },
  }),
  relations: {
    articleFlagsInst: {
      type: 'hasMany',
      resourceType: 'articleFlags',
      foreignKey: 'article',
      serverKey: 'rel_articleFlagsInst',
    },
    flagsInst: {
      // hydrate only. Linked to assetFlagsInst
      type: 'hasMany',
      resourceType: 'flags',
    },
    articleMediasInst: {
      type: 'hasMany',
      resourceType: 'articleMedias',
      foreignKey: 'article',
      serverKey: 'rel_articleMediasInst',
    },
    mediasInst: {
      // Hydrate only
      type: 'hasMany',
      resourceType: 'medias',
    },
  },
}
