/** @flow */
import { mapValues, forEach, isPlainObject, keyBy, isEqual, isEmpty, omit, map, get, reduce, merge } from 'lodash'
import { createResources as createResourcesBase } from 'app/libs/redux-resource-request'
import createPreActions from 'app/libs/redux-resource-request-pre'
import { diffObject, cleanObject } from 'app/libs/helpers'
import type { StoreResourceName, StoreResourcesActions, ResourceActions, GetResourcesActions } from 'app/core/types'
import { error } from 'app/components/Notifications/notify'
import { getResources } from 'app/store/selectors'
import actionTypes from 'app/libs/redux-resource-request/actionTypes.js'
import type { Store } from 'app/store'
import { getIncludedResources } from './getIncludedResources'
import { getUpdatedRelations } from './getUpdatedRelations'

type ResourcesConfig = {
  [resourceType: StoreResourceName]: Object,
}

type CreateResources = (resourcesConfig: ResourcesConfig, api: Object, store: Store) => StoreResourcesActions

export const createResources: CreateResources = (resourcesConfig, api, store) => {
  let outputResources: StoreResourcesActions

  const getResourcesAPI: GetResourcesActions = () => outputResources

  const generateDefaultResourceActions = (apiResource, resourceType, resourceDef) => {
    const hooks = resourceDef.hooks ? resourceDef.hooks(getResourcesAPI, getResources) : {}
    return {
      fetchAll: (config = {}, otherValues) => {
        const { params = {}, ...restConfig } = merge(config, otherValues)

        return {
          type: 'read',
          requestKey: restConfig.requestKey,
          request: () => {
            if (params?.requestController?.signal?.aborted) return Promise.reject(new Error('Request cancelled'))

            return apiResource
              .fetchAll(params.queries, params.headers, params.getHttpProgress, params.requestController)
              .then(async (resParams) => {
                if (params?.requestController?.signal?.aborted) {
                  return Promise.reject(new Error('Request cancelled'))
                }

                const res = resParams

                if (hooks.afterGetAll) {
                  try {
                    res.results = await hooks.afterCreate(resParams.results)
                  } catch (error) {
                    console.error(error)
                    throw new Error(error)
                  }
                }

                return {
                  resources: res.results,
                  includedResources: getIncludedResources(resourceType, res.results),
                  plainResponse: res,
                  requestProperties: {
                    count: res.count,
                  },
                  ...restConfig,
                }
              })
          },
        }
      },
      fetchOne: (data, config = {}) => {
        const { params = {}, ...restConfig } = config
        return {
          type: 'read',
          requestKey: restConfig.requestKey,
          request: async () => {
            return apiResource
              .fetchOne(data, params.queries, params.headers, params.getHttpProgress, params.requestController)
              .then(async (paramsRes) => {
                let res = paramsRes

                if (hooks.afterGet) {
                  try {
                    res = await hooks.afterCreate(paramsRes)
                  } catch (error) {
                    console.error(error)
                    throw new Error(error)
                  }
                }

                return {
                  resources: [res],
                  plainResponse: res,
                  includedResources: getIncludedResources(resourceType, [res]),
                  ...restConfig,
                }
              })
          },
        }
      },
      create: (data, config = {}) => {
        const { params = {}, batch = false, localOnly, ...restConfig } = config

        const dataAsArray = Array.isArray(data) ? data : [data]

        if (localOnly) {
          return {
            type: 'create',
            request: {
              resources: {
                [resourceType]: dataAsArray,
              },
              ...restConfig,
            },
          }
        }

        return {
          type: 'create',
          requestKey: restConfig.requestKey,
          request: async () => {
            let dataAsArray = Array.isArray(data) ? data : [data]

            if (hooks.beforeCreate) {
              try {
                dataAsArray = await hooks.beforeCreate(dataAsArray)
                if (!Array.isArray(dataAsArray)) {
                  throw new Error('beforeCreate must return an array')
                }
              } catch (error) {
                console.error(error)
                throw new Error(error)
              }
            }

            const promise = batch
              ? apiResource.collection.create(
                  dataAsArray,
                  params.queries,
                  params.headers,
                  params.getHttpProgress,
                  params.requestController,
                )
              : Promise.all(dataAsArray.map((item) => apiResource.create(item, params.queries, params.headers)))

            return promise.then(async (res) => {
              let arrayData = Array.isArray(res) ? res : [res]

              if (hooks.afterCreate) {
                try {
                  arrayData = await hooks.afterCreate(res, dataAsArray)
                  if (!Array.isArray(arrayData)) {
                    throw new Error('afterCreate must return an array')
                  }
                } catch (error) {
                  console.error(error)
                  throw new Error(error)
                }
              }

              return {
                resources: arrayData,
                plainResponse: res,
                ...restConfig,
              }
            })
          },
        }
      },
      update: (data, config = {}) => {
        const { params = {}, batch = false, localOnly, updateBeforeRequest, ...restConfig } = config
        let dataAsArray = Array.isArray(data) ? data : [data]
        let originalValue

        if (updateBeforeRequest) {
          originalValue = dataAsArray.map((val) => getResources(store.getState(), resourceType, val.id))
        }

        const request = async () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            if (hooks.beforeUpdate) {
              try {
                dataAsArray = await hooks.beforeUpdate(dataAsArray)
                if (!Array.isArray(dataAsArray)) {
                  reject(new Error('beforeUpdate must return an array'))
                  return
                }
              } catch (error) {
                if (originalValue) {
                  store.dispatch({
                    resources: originalValue,
                    type: actionTypes.update.succeeded,
                    resourceType,
                    requestName: 'revertAfterError',
                  })
                }
                reject(error)
                return
              }
            }

            const promise = batch
              ? apiResource.collection.update(dataAsArray, params.queries, params.headers).catch((err) => {
                  reject(new Error(err))
                })
              : Promise.all(
                  dataAsArray.map((item) =>
                    apiResource.update(
                      item,
                      params.queries,
                      params.headers,
                      params.getHttpProgress,
                      params.requestController,
                    ),
                  ),
                ).catch((err) => {
                  reject(new Error(err))
                })

            promise
              .then(async (res) => {
                let results = Array.isArray(res) ? res : [res]
                if (hooks.afterUpdate) {
                  try {
                    results = await hooks.afterUpdate(results, dataAsArray)
                    if (!Array.isArray(results)) {
                      reject('afterUpdate must return an array')
                      return
                    }
                  } catch (error) {
                    console.error(error)
                    reject(error)
                    return
                  }
                }

                if (updateBeforeRequest) {
                  const validResults = []
                  const state = store.getState()

                  dataAsArray.forEach((data, index) => {
                    const modifiedValue = state[resourceType].resources[data.id]
                    let shouldModifyState = true

                    forEach(data, (val, key) => {
                      if (modifiedValue[key] !== val) {
                        shouldModifyState = false
                      }
                    })

                    if (shouldModifyState === true) validResults.push(results[index])
                  })

                  if (validResults.length > 0) {
                    store.dispatch({
                      type: actionTypes.update.succeeded,
                      resourceType,
                      resources: validResults,
                      plainResponse: res,
                      ...restConfig,
                      mergeResources: true,
                    })
                    resolve()
                    return
                  }
                }

                resolve({
                  type: 'update',
                  resources: results,
                  plainResponse: res,
                  ...restConfig,
                })
              })
              .catch((err) => {
                let messages = 'An error occured during the update'
                const errors = get(err, 'serverError.infos')

                if (errors) {
                  messages = `${messages}${map(errors, (err) =>
                    Array.isArray(err) ? err.map((message) => `, "${message}"`).join('') : '',
                  ).join('')}`
                }

                error(messages)

                if (originalValue) {
                  store.dispatch({
                    resources: originalValue,
                    type: actionTypes.update.succeeded,
                    resourceType,
                    requestName: 'revertAfterError',
                  })
                }
                reject(err)
              })

            if (updateBeforeRequest) resolve()
          })
        }

        if (localOnly || updateBeforeRequest) {
          const newResourcesMeta = mapValues(keyBy(dataAsArray, 'id'), ({ id }) => ({ preUpdated: null }))

          if (updateBeforeRequest) {
            store.dispatch({
              resources: dataAsArray,
              type: actionTypes.update.succeeded,
              resourceType,
              ...restConfig,
            })
            request()
          }

          return {
            type: 'update',
            request: () =>
              Promise.resolve({
                resources: dataAsArray,
                meta: {
                  [resourceType]: newResourcesMeta,
                },
                ...restConfig,
              }),
          }
        }

        return {
          type: 'update',
          requestKey: restConfig.requestKey,
          data: updateBeforeRequest && dataAsArray,
          request,
        }
      },
      delete: (data, config = {}) => {
        const { params = {}, batch = false, localOnly = false, ...restConfig } = config

        if (localOnly) {
          const dataAsArray = Array.isArray(data) ? data : [data]
          return {
            type: 'delete',
            request: {
              resources: {
                [resourceType]: dataAsArray,
              },
            },
          }
        }

        return {
          type: 'delete',
          requestKey: restConfig.requestKey,
          request: async () => {
            if (isPlainObject(data)) {
              throw new Error(
                `${resourceType}.delete(data) data must be an ID or an array of ID, not an object.
              \nReceived data:\n${JSON.stringify(data, null, 4)}`,
              )
            }

            let idsToRemove = Array.isArray(data) ? data : [data]

            if (hooks.beforeDelete) {
              try {
                idsToRemove = await hooks.beforeDelete(idsToRemove)
                if (!Array.isArray(idsToRemove)) throw new Error('beforeDelete must return an array of ids')
              } catch (error) {
                console.error(error)
                throw new Error(error)
              }
            }

            const promise = batch
              ? apiResource.collection.delete(
                  idsToRemove.map((id) => ({ id })),
                  params.queries,
                  params.headers,
                  params.getHttpProgress,
                  params.requestController,
                )
              : Promise.all(idsToRemove.map((id) => apiResource.delete(id, params.queries, params.headers)))

            return promise.then(async (res) => {
              if (hooks.afterDelete) {
                try {
                  idsToRemove = await hooks.afterDelete(idsToRemove)
                  if (!Array.isArray(idsToRemove)) throw new Error('afterDelete must return an array of ids')
                } catch (error) {
                  console.error(error)
                  throw new Error(error)
                }
              }

              return {
                resources: idsToRemove,
                plainResponse: res,
                ...restConfig,
              }
            })
          },
        }
      },
    }
  }

  const generateDefaultMetaActions = (resourceType) => ({
    save: async (data, config = {}) => {
      const resourcesAPI = getResourcesAPI()
      const resourceAPI = resourcesAPI[resourceType]
      const resourceConfig = resourcesConfig[resourceType]

      let res
      const relations = []

      const dataAsArray = Array.isArray(data) ? data : [data]

      return Promise.all(
        dataAsArray.map(async (data) => {
          let dataToSave = mapValues(data, (values, key) => {
            const relationConfig = resourceConfig.relations && resourceConfig.relations[key]

            if (relationConfig && values !== undefined) {
              relations.push({
                relationConfig,
                values,
              })

              return undefined
            }

            return values
          })

          dataToSave = cleanObject(dataToSave)
          const hasData = !isEmpty(omit(dataToSave, ['id']))

          if (hasData) {
            if (data.id) {
              // update
              res = await resourceAPI.update(dataToSave)
            } else {
              // create
              res = await resourceAPI.create(dataToSave)
            }
          }

          const id = res ? res.resources[0].id : dataToSave.id

          const promises = []

          /**
           * example of relations
           *
           *  {
           *     takeFlagsInst: [
           *       {
           *         id: <TAKE_FLAG_ID>,
           *         flag: <FLAG_ID>,
           *         take: <TAKE_ID>
           *       }
           *     ]
           *  }
           *
           */
          forEach(relations, ({ relationConfig, values }) => {
            const { type, foreignKey, resourceType } = relationConfig
            const resourceRelationAPI = resourcesAPI[resourceType]

            if (type === 'hasMany') {
              const currentResourceRelations = getResources(store.getState(), resourceType, { [foreignKey]: id })

              const { relationsToCreate, relationsToDelete } = getUpdatedRelations(
                relationConfig,
                currentResourceRelations,
                values,
              )

              relationsToCreate.length && promises.push(resourceRelationAPI.save(relationsToCreate))
              relationsToDelete.length && promises.push(resourceRelationAPI.delete(relationsToDelete))
            } else if (type === 'hasOne') {
              const currentValue = getResources(store.getState(), resourceType, values.id)

              if (!isEqual(currentValue, values)) {
                const diff = diffObject(values, currentValue)
                const itemToUpdate = {
                  id: currentValue.id,
                  ...diff,
                }

                promises.push(resourceRelationAPI.save(itemToUpdate))
              }

              /* $FlowFixMe[incompatible-call] $FlowFixMe Error when updating
               * flow */
              return Promise.resolve()
            }

            // $FlowFixMe[incompatible-call] $FlowFixMe Error when updating flow
            return Promise.resolve()
          })

          await Promise.all(promises)

          return Promise.resolve(res)
        }),
      )
    },
  })

  outputResources = createResourcesBase(
    store,
    mapValues(resourcesConfig, (resource: Object, resourceType) => {
      const apiResourceType = resource.resourceType

      const apiResource = apiResourceType && api[apiResourceType]

      const actions = {
        ...(apiResource && generateDefaultResourceActions(apiResource, resourceType, resource)),
        ...createPreActions(store, resourceType), // no utility ?
        ...(resource.actions && resource.actions(getResourcesAPI)),
      }

      // create id attribute from id
      const handledActionWithTransformId = (getResourcesAPI) =>
        mapValues(actions, (action) => {
          return (...args) => {
            const handleActionObj = (input: ?Object) => {
              if (!input) return null
              const { request, ...res } = input
              let newRequest

              if (typeof request === 'function') {
                newRequest = () => {
                  return request().then((response) => {
                    const { includedResources, resources } = response

                    return {
                      ...response,
                      resources,
                      includedResources: ['read', 'create', 'update'].includes(res.type)
                        ? includedResources || (resources && getIncludedResources(resourceType, resources))
                        : {},
                    }
                  })
                }
              } else {
                const { resources } = request

                newRequest = {
                  ...request,
                  resources,
                  includedResources: ['read', 'create', 'update'].includes(res.type)
                    ? resources && getIncludedResources(resourceType, resources)
                    : {},
                }
              }

              return {
                ...res,
                request: newRequest,
              }
            }

            const actionObj = action(...args)
            const output = Array.isArray(actionObj)
              ? actionObj.map((obj) => handleActionObj(obj))
              : handleActionObj(actionObj)
            return output
          }
        })

      return handledActionWithTransformId
    }),
  )

  // Add resources.[resource].save(...) function
  // $FlowFixMe
  outputResources = reduce(
    outputResources,
    (acc: StoreResourcesActions, resource: ResourceActions, resourceType: StoreResourceName) => {
      // $FlowFixMe[prop-missing]
      acc[resourceType] = Object.assign(resource, generateDefaultMetaActions(resourceType))
      return acc
    },
    outputResources,
  )

  return outputResources
}
