// @flow
import React, { useState, type Element } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/Accordion'
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary'
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { FAIcon } from 'app/core/types'
import FontIcon from '../FontIcon/FontIcon.jsx'

export type Props = {|
  classes?: Object,
  title: React$Node,
  children: React$Node,
  'data-cy'?: string,
  contentProps?: Object,
  titleProps?: Object,
  expandIcon?: Element<any>,
  timeout?: number,
  leftContent?: React$Node,
  centerContent?: React$Node,
  rightContent?: React$Node,
  defaultExpanded?: boolean,
  disableExpansionPanel?: boolean,
  expanded?: boolean,
  componentRef?: React$ElementRef<any>,
  autoload?: boolean,
  icon?: FAIcon,
|}

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    padding: '0 0 0 48px',
    minHeight: 48,
    maxHeight: 48,
    '&$expanded': {
      margin: 0,
      minHeight: 48,
      maxHeight: 48,
    },
  },
  expandIcon: {
    right: 'initial',
    left: 8,
    position: 'absolute',
    transform: 'translateY(-50%) rotate(90deg)',
  },
  expanded: {},
}))(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    borderTop: '1px solid lightgrey',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
}))(MuiExpansionPanelDetails)

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
  },
  expandIcon: {
    transform: 'rotate(90deg)',
  },
})

function ExpansionPanel(props: Props): React$Node {
  const {
    classes,
    contentProps,
    title,
    titleProps,
    children,
    expandIcon,
    timeout,
    leftContent,
    centerContent,
    rightContent,
    defaultExpanded,
    disableExpansionPanel,
    expanded: _expanded,
    componentRef,
    autoload,
    icon,
    ...rest
  } = props
  const [open, setOpen] = useState(defaultExpanded || _expanded || false)

  if (disableExpansionPanel) return children || null

  const onChange = (event, expanded) => {
    setOpen(expanded)
  }

  return (
    <MuiExpansionPanel
      expanded={_expanded || open}
      timeout={timeout || 150}
      onChange={onChange}
      ref={(ref) => {
        if (componentRef) componentRef.current = ref
      }}
      {...rest}
    >
      <ExpansionPanelSummary
        classes={{
          expandIcon: classes?.expandIcon,
        }}
        expandIcon={expandIcon || <FontIcon style={{ fontSize: 14 }} icon="fas-chevron-up" />}
      >
        <div className="flex row noWrap fullWidth" style={{ paddingRight: 0 }}>
          {leftContent ? <div className="flex start">{leftContent}</div> : null}
          <div className={`flex flex1 alignCenter textNoWrap ${classes?.heading || ''}`} {...titleProps}>
            {icon ? (
              <span style={{ marginRight: '10px', color: 'rgb(178, 185, 199)' }}>
                <FontAwesomeIcon icon={icon} />
              </span>
            ) : null}
            <span>{title}</span>
          </div>
          {centerContent ? <div className="flex flex1 alignCenter center">{centerContent}</div> : null}
          {rightContent ? <div className="flex flex1 alignCenter end">{rightContent}</div> : null}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{open || autoload ? children : <div />}</ExpansionPanelDetails>
    </MuiExpansionPanel>
  )
}

const Component: React$AbstractComponent<*, *> = withStyles(styles)(ExpansionPanel)

export default Component
