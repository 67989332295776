/** @flow */
import React, { useMemo } from 'react'
import { NODE_DIRECTORY, NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import type { SchemaColumnSettings } from 'app/core/types/Schema'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'

type MetadataType = {|
  name: string,
  type: 'lastLogin' | 'createdAt' | 'updatedAt',
|}

export type MetadataNodeData = {|
  metadata: MetadataType,
  contentType: 'metadata',
|}

const metadataItems: MetadataType[] = [
  { name: 'Last login', type: 'lastLogin' },
  { name: 'Created at', type: 'createdAt' },
  { name: 'Updated at', type: 'updatedAt' },
]

export function Metadatas(): React$Node {
  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: metadataItems.map((metadata) => {
        return new Node<$Shape<SchemaColumnSettings<MetadataNodeData>>>({
          key: metadata.name,
          name: metadata.name,
          parent: rootNode,
          data: { metadata, contentType: 'metadata' },
          icon: 'fas-grip-vertical',
          type: NODE_ITEM,
        })
      }),
    })

    return rootNode
  }, [])

  return (
    <div style={{ padding: '0 10px 10px 10px', maxWidth: '100%', width: '100%' }}>
      <TreeDnD rootNode={rootNode} />
    </div>
  )
}
