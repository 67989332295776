/** @flow */
import React from 'react'
import { FormData } from 'app/components/Form'
import { openModal, ModalConfirmForm } from 'app/components/Modal'
import { error } from 'app/components/Notifications/notify'
import type { User } from 'app/core/types'
import api from 'app/core/api/api'
import upload from 'app/core/utils/upload.js'
import { getUserSettings, setUserSettings } from 'app/libs/helpers/userSettings.js'

import { reportingActions } from './reporting.config.js'

export type ReportData = {|
  message: string,
  attachments: Array<File>,
  url: string,
  user: User,
  type: string,
|}

const uploadScreeshot = (files: $ElementType<ReportData, 'attachments'>) => {
  const mediaGroupName = '__reportingMedias__'

  return api.mediaGroups.create({ name: mediaGroupName, medias: [] }).then((mediaGroup) => {
    return upload({ files, groupId: mediaGroup.id }).then((res) => {
      if (!res) return []
      if (Array.isArray(res)) return res
      return [res]
    })
  })
}

const getApiTokken = () => {
  return new Promise((resolve, reject) => {
    const token = getUserSettings('RCAuthToken')

    if (token) resolve(JSON.parse(token))

    openModal(
      <ModalConfirmForm title="Rocket Chat Auth">
        <>
          <div className="padding15 black">
            <h2>Ajout du token Rocket Chat</h2>
            <div className="padding15">
              {`Pour pouvoir accéder à votre messagerie et poster un message de report à votre place, il est nécessaire de créer un Token d'authentification.`}
              <br />
              Sur Rocket Chat:
              <br />
              <br />
              <strong>Mon compte (cliquer sur sa photo de profil)</strong> {'→'}{' '}
              <strong>Jeton {`d'`}accès personnel</strong>
              <br />
              <br />
              {`Dans la barre en haut, saisissez un nom pour votre token (ex: "OVM") puis cliquez sur ajouter.`}
              <br />
              {`Un pop-up va s'ouvrir avec les informations du token.`}
              <br />
              Veuillez les copier dans les champs suivants puis validez.
              <br />
              {`Vous n'aurez plus à le faire par la suite sur ce navigateur.`}
              <br />
              {`Si vous voulez enregistrer plusieurs navigateurs, vous pouvez refaire l'opération avec un nom de token different (ex: "OVM Travail").`}
              <br />
            </div>
          </div>
          <FormData
            properties={[
              {
                key: 'token',
                label: 'Token',
                type: 'string',
                elementProps: {
                  isRequired: true,
                },
              },
              {
                key: 'userId',
                label: 'Your user ID',
                type: 'string',
                elementProps: {
                  isRequired: true,
                },
              },
            ]}
            onSave={async ({ token, userId }) => {
              setUserSettings('RCAuthToken', JSON.stringify({ token, userId }))
              resolve({ token, userId })
            }}
          />
        </>
      </ModalConfirmForm>,
    )
  })
}

export const postMessage = async (data: ReportData): Promise<any> => {
  const reportingAction = reportingActions.find((opt) => opt.label === data.type)
  const { attachments: _attachments } = data

  if (!reportingAction) {
    error('This reporting action is not well formated.')
    return Promise.reject()
  }

  const token = await getApiTokken()
  if (!token) return Promise.reject()

  const attachments = await uploadScreeshot(_attachments)

  const headers = new Headers({
    'X-Auth-Token': token.token,
    'X-User-Id': token.userId,
    'Content-type': 'application/json',
  })

  const { channel, action } = reportingAction

  return fetch('https://chat.teamto.fr/api/v1/chat.postMessage', {
    method: 'POST',
    headers,
    body: JSON.stringify({
      channel,
      ...action({
        ...data,
        attachments: attachments.map((att) => ({ image_url: att.url, title: att.name })),
      }),
    }),
  })
    .then(() => {
      const isChannel = channel[0] === '#'
      const isDirect = channel[0] === '@'
      const conv = isChannel ? 'channel' : isDirect ? 'direct' : 'group'
      const room = channel.replace(/[@#]/, '')

      window.open(`https://chat.teamto.fr/${conv}/${room}`)
    })
    .catch((err) => {
      error('Error with token')
      return err
    })
}
