import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PubSub from 'pubsub-js';
import Lightbox from 'app/libs/react-image-lightbox/index';
import 'app/libs/react-image-lightbox/style.scss';
import Tooltip from '@material-ui/core/Tooltip';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import resources from 'app/store/resources';
import { Permission } from 'app/containers/Permissions';
import { colors } from 'app/styles/colors';
import classes from './PictureVisualization.module.scss';
export const isPicture = (name) => {
    if (!name || !name.split)
        return false;
    const splitedName = name.split('.');
    const ext = splitedName[splitedName.length - 1];
    if (['jpg', 'jpeg', 'png', 'bmp', 'gif'].indexOf(ext) !== -1)
        return true;
    return false;
};
export function PictureVisualization() {
    const sub = useRef();
    const [photoIndex, setPhotoIndex] = useState(0);
    const [pictures, setPictures] = useState([]);
    const [initialPictures, setInitialPictures] = useState([]);
    const [allowPinMedia, setAllowPinMedia] = useState(false);
    const [allowValidateMedia, setAllowValidateMedia] = useState(false);
    useSelector((state) => {
        const newPictures = initialPictures.map((pic) => state.medias.resources[pic.id] || pic);
        if (JSON.stringify(newPictures) !== JSON.stringify(pictures))
            setPictures(newPictures);
    });
    useEffect(() => {
        sub.current = PubSub.subscribe('OPEN_PICTURE', (msg, { pictures = [], index, allowPinMedia: _allowPinMedia, allowValidateMedia: _allowValidateMedia }) => {
            setPhotoIndex(index || 0);
            setInitialPictures(pictures.filter((pic) => pic.url));
            setAllowPinMedia(_allowPinMedia);
            setAllowValidateMedia(_allowPinMedia);
        });
        return () => PubSub.unsubscribe(sub.current);
    }, []);
    const currentMedia = pictures[photoIndex];
    const nextMedia = pictures.length === 1 ? null : pictures[(photoIndex + 1) % pictures.length];
    const prevMedia = pictures.length === 1 ? null : pictures[(photoIndex === 0 ? pictures.length - 1 : photoIndex - 1) % pictures.length];
    function onPinMedia() {
        resources.medias.update({ id: currentMedia.id, pinned: !currentMedia.pinned });
    }
    function onValidateMedia() {
        resources.medias.update({ id: currentMedia.id, validated: !currentMedia.validated });
    }
    const toolbar = [];
    if (currentMedia === null || currentMedia === void 0 ? void 0 : currentMedia.id) {
        if (allowPinMedia) {
            toolbar.push(_jsx(Permission, { actions: ['projet_medias__Pin validation medias'], children: _jsx(Tooltip, { title: "Pin media to the mozaic", placement: "bottom", PopperProps: { style: { zIndex: 3000 } }, children: _jsx("div", { onClick: onPinMedia, children: _jsx(FontIcon, { icon: "fas-thumbtack", className: classes.btn, style: { color: currentMedia.pinned ? '#ffffff' : 'rgba(255,255,255,0.4)' } }) }) }, "pinMedias") }));
        }
        if (allowValidateMedia) {
            toolbar.push(_jsx(Permission, { actions: ['projet_medias__Validate validation medias'], children: _jsx(Tooltip, { title: "Validate media", placement: "bottom", PopperProps: { style: { zIndex: 3000 } }, children: _jsx("div", { onClick: onValidateMedia, children: _jsx(FontIcon, { icon: "fas-check", className: classes.btn, style: { color: currentMedia.validated ? colors.green : 'rgba(255,255,255,0.4)' } }) }) }, "validateMedias") }));
        }
    }
    return pictures.length > 0 ? (_jsx(Lightbox, { reactModalProps: {
            style: {
                overlay: { zIndex: 2000, backgroundColor: 'rgba(0,0,0,0.9)' },
                content: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'transparent',
                    padding: 30,
                    border: 'none',
                    maxWidth: '100vw',
                    overflow: 'hidden',
                },
            },
        }, mainSrc: currentMedia === null || currentMedia === void 0 ? void 0 : currentMedia.url, mainSrcThumbnail: currentMedia === null || currentMedia === void 0 ? void 0 : currentMedia.thumbnail, nextSrc: nextMedia === null || nextMedia === void 0 ? void 0 : nextMedia.url, nextSrcThumbnail: nextMedia === null || nextMedia === void 0 ? void 0 : nextMedia.thumbnail, prevSrc: prevMedia === null || prevMedia === void 0 ? void 0 : prevMedia.url, prevSrcThumbnail: prevMedia === null || prevMedia === void 0 ? void 0 : prevMedia.url, toolbarButtons: toolbar, imageTitle: currentMedia === null || currentMedia === void 0 ? void 0 : currentMedia.name, imageCaption: currentMedia === null || currentMedia === void 0 ? void 0 : currentMedia.url, onCloseRequest: () => setInitialPictures([]), onMovePrevRequest: () => setPhotoIndex((photoIndex + pictures.length - 1) % pictures.length), onMoveNextRequest: () => setPhotoIndex((photoIndex + 1) % pictures.length), closeLabel: "Close image viewer" })) : null;
}
export const openPictures = (pictures, allowPinMedia, allowValidateMedia, index) => {
    PubSub.publish('OPEN_PICTURE', { pictures, index, allowPinMedia });
};
