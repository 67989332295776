/** @flow */
import React from 'react'
import cx from 'classnames'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import classes from './ExpansionCell.module.scss'

type Props = {|
  title?: string,
  children: React$Element<any>,
  setOpen: (e: SyntheticMouseEvent<>, open: boolean) => void,
  open: boolean,
  headerStyle?: Object,
  style?: Object,
|}

export function ExpansionCell(props: Props): React$Node {
  const { title, children, setOpen, open, headerStyle, style } = props

  const toggle = (e: SyntheticMouseEvent<>) => setOpen(e, !open)

  return (
    <div className={classes.container} style={style}>
      <div style={headerStyle} className={`${classes.header} fullWidth flex row noWrap alignCenter`} onClick={toggle}>
        <div
          className={cx(classes.expanderBnt, {
            [classes.expanderBntPivoted]: open,
          })}
        >
          <FontIcon icon="fas-caret-right" />
        </div>
        {title}
      </div>
      {open ? children : null}
    </div>
  )
}
