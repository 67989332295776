/** @flow */
import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import MUISlider from '@material-ui/core/Slider'
import type { InputProps } from 'app/core/types'

export type SliderProps = {|
  ...InputProps,
  /*
   * The label of the slider.
   */
  'aria-label'?: string,
  /*
   * The id of the element containing a label for the slider.
   */
  'aria-labelledby'?: string,
  /*
   * A string value that provides a user-friendly name for the current value of the slider.
   */
  'aria-valuetext'?: string,
  /*
   * Override or extend the styles applied to the component. See CSS API below for more details.
   */
  classes?: Object,
  /*
   * 'primary'
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?: 'primary' | 'secondary',
  /*
   * 'span'
   * The component used for the root node. Either a string to use a HTML element or a component.
   */
  component?: React$Element<any>,
  /*
   * The default element value. Use when the component is not controlled.
   */
  defaultValue?: number | Array<number>,
  /*
   * false
   * If true, the slider will be disabled.
   */
  disabled?: boolean,
  /*
   * Accepts a function which returns a string value that provides a user-friendly name for the thumb labels of the slider.
   *
   * Signature:
   * function(index: number) => string
   * index: The thumb label's index to format.
   */
  getAriaLabel?: (index: number) => string,
  /*
   * Accepts a function which returns a string value that provides a user-friendly name for the current value of the slider.
   *
   * Signature:
   * function(value: number, index: number) => string
   * value: The thumb label's value to format.
   * index: The thumb label's index to format.
   */
  getAriaValueText?: (value: number, index: number) => string,
  /*
   * false
   * Marks indicate predetermined values to which the user can move the slider. If true the marks will be spaced according the value of the step prop. If an array, it should contain objects with value and an optional label keys.
   */
  marks?: boolean | Array<{ value: number, label?: string }>,
  /*
   * 100
   * The maximum allowed value of the slider. Should not be equal to min.
   */
  max?: number,
  /*
   * 0
   * The minimum allowed value of the slider. Should not be equal to max.
   */
  min?: number,
  /*
   * Name attribute of the hidden input element.
   */
  name?: string,
  /*
   * Callback function that is fired when the slider's value changed.
   *
   * Signature:
   * function(event: Object, value: number | number[]) => void
   * event: The event source of the callback.
   * value: The new value.
   */
  onChange?: (event: Object, value: number | Array<number>) => void,
  /*
   * Callback function that is fired when the mouseup is triggered.
   *
   * Signature:
   * function(event: Object, value: number | number[]) => void
   * event: The event source of the callback.
   * value: The new value.
   */
  onChangeCommitted?: (event: Object, value: number | Array<number>) => void,
  /*
   * 'horizontal'
   * The slider orientation.
   */
  orientation?: 'horizontal' | 'vertical',
  /*
   * (x) => x
   * A transformation function, to change the scale of the slider.
   */
  scale?: (x: any) => any,
  /*
   * 1
   * The granularity with which the slider can step through values. (A "discrete" slider.) The min prop serves as the origin for the valid values. We recommend (max - min) to be evenly divisible by the step.
   * When step is null, the thumb can only be slid onto marks provided with the marks prop.
   */
  step?: number,
  /*
   * 'span'
   * The component used to display the value label.
   */
  ThumbComponent?: React$Element<any>,
  /*
   * 'normal'
   * The track presentation:
   *   - normal the track will render a bar representing the slider value. - inverted the track will render a bar representing the remaining slider value. - false the track will render without a bar.
   */
  track?: 'normal' | false | 'inverted',
  /*
   * The value of the slider. For ranged sliders, provide an array with two values.
   */
  value?: number | Array<number>,
  /*
   * ValueLabel
   * The value label component.
   */
  ValueLabelComponent?: React$Element<any>,
  /*
   * 'off'
   * Controls when the value label is displayed:
   *   - auto the value label will display when the thumb is hovered or focused. - on will display persistently. - off will never display.
   */
  valueLabelDisplay?: 'on' | 'auto' | 'off',
  /*
   * (x) => x
   * The format function the value label's value.
   * When a function is provided, it should have the following signature:
   *   - {number} value The value label's value to format - {number} index The value label's index to format
   */
  valueLabelFormat?: string | ((x: any) => any),
|}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
}))

const SliderView = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    fontSize: '12px',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
  markLabel: {
    fontSize: '12px',
  },
})(MUISlider)

export function Slider(props: SliderProps): React$Node {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SliderView {...props} />
    </div>
  )
}
