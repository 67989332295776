// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Login-___login-module__loginLayout {
  flex: 1;
  overflow-y: auto;
}

.app-pages-Login-___login-module__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.app-pages-Login-___login-module__form {
  flex: 3;
}

.app-pages-Login-___login-module__loginBtn {
  margin-left: auto;
  margin-right: 0px;
  display: block;
}

.app-pages-Login-___login-module__project {
  display: flex;
  align-items: center;
  flex: 2;
}

.app-pages-Login-___login-module__name {
  display: flex;
  padding: 0 20px;
  font-size: 34px;
  font-family: 'ralewayregular';
}

.app-pages-Login-___login-module__nameStart {
  font-family: 'ralewaybold';
}

.app-pages-Login-___login-module__nameEnd {
  margin-top: -2px;
}

.app-pages-Login-___login-module__animation {
  animation: app-pages-Login-___login-module__LogoAnim 3s infinite;
}

@keyframes app-pages-Login-___login-module__LogoAnim {
  0% {
    transform: rotatez(0deg);
  }
  100% {
    transform: rotatez(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./app/pages/Login/login.module.scss"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,OAAO;AACT;;AAEA;EACE,aAAa;EACb,eAAe;EACf,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gEAA+B;AACjC;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".loginLayout {\n  flex: 1;\n  overflow-y: auto;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.form {\n  flex: 3;\n}\n\n.loginBtn {\n  margin-left: auto;\n  margin-right: 0px;\n  display: block;\n}\n\n.project {\n  display: flex;\n  align-items: center;\n  flex: 2;\n}\n\n.name {\n  display: flex;\n  padding: 0 20px;\n  font-size: 34px;\n  font-family: 'ralewayregular';\n}\n\n.nameStart {\n  font-family: 'ralewaybold';\n}\n\n.nameEnd {\n  margin-top: -2px;\n}\n\n.animation {\n  animation: LogoAnim 3s infinite;\n}\n\n@keyframes LogoAnim {\n  0% {\n    transform: rotatez(0deg);\n  }\n  100% {\n    transform: rotatez(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginLayout": `app-pages-Login-___login-module__loginLayout`,
	"container": `app-pages-Login-___login-module__container`,
	"form": `app-pages-Login-___login-module__form`,
	"loginBtn": `app-pages-Login-___login-module__loginBtn`,
	"project": `app-pages-Login-___login-module__project`,
	"name": `app-pages-Login-___login-module__name`,
	"nameStart": `app-pages-Login-___login-module__nameStart`,
	"nameEnd": `app-pages-Login-___login-module__nameEnd`,
	"animation": `app-pages-Login-___login-module__animation`,
	"LogoAnim": `app-pages-Login-___login-module__LogoAnim`
};
export default ___CSS_LOADER_EXPORT___;
