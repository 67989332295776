// @flow
import * as React from 'react'
import { MUIModal, type ModalProps } from './MUIModal.jsx'

export const ModalFormContext: Object = React.createContext()

export type ModalConfirmFormProps = {|
  ...ModalProps,
  multipleCellsEdit?: boolean,
|}

export default class ModalConfirmForm extends React.PureComponent<ModalConfirmFormProps> {
  formApi: Object

  onValidate: Function = () => {
    return this.formApi.submitForm().catch((error) => {
      if (error.propertyKey) return Promise.reject(error)
      throw error
    })
  }

  contextValue: Object = {
    multipleCellsEdit: this.props.multipleCellsEdit,
    getApi: (formApi) => {
      this.formApi = formApi
    },
  }

  render(): React$Node {
    const { multipleCellsEdit, ...rest } = this.props
    return (
      <ModalFormContext.Provider value={this.contextValue}>
        <MUIModal draggable={true} validateLabel="Save" {...rest} onValidate={this.onValidate} />
      </ModalFormContext.Provider>
    )
  }
}
