/** @flow */
import React, { useEffect, useState } from 'react'
import Widget from 'app/components/Widget/Widget.jsx'
import PaginatedComponent from 'app/components/PaginatedComponent/PaginatedComponent.jsx'
import Notification from 'app/containers/Notifications/Notification/Notification.jsx'
import { useSelector } from 'react-redux'
import resources from 'app/store/resources'
import type { User, ID } from 'app/core/types'
import { getStatusRequest } from 'app/store/resources/utils/getStatusRequest'
import { getResources } from 'app/store/selectors/getResources'

type Request = {
  count: number,
  ids: ID[],
  next: string | null,
  previous: string | null,
  requestKey: string,
  requestName: string,
  ressourceType: string,
  status: string,
  unread: number,
}

export default function NotificationsList(): React$Node {
  const user: User = useSelector((state) => state.user)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const request: Request = useSelector((state) => state.notifications.requests?.fetchUserNotifications)
  const [notifications, setNotifications] = useState<Array<Object>>([])
  const [count, setCount] = useState<number>(0)

  useEffect(() => {
    const load = getStatusRequest('notifications', 'fetchUserNotifications')
    setIsLoading(load.pending)
    if (request) {
      setNotifications(Object.values(getResources(undefined, 'notifications.requests.fetchUserNotifications')))
      setCount(request.count)
    }
  }, [request])

  function onRequestNeeded(pageSize: number, currentPage: number) {
    const params = {
      queries: {
        page_size: pageSize,
        page: currentPage,
      },
    }
    resources.notifications.fetchUserNotifications({ id: user.asset }, undefined, {
      params,
    })
  }

  function onClickNotif(notification: Object) {
    const _notif = { ...notification, unread: false }
    resources.notifications.update(_notif)
  }

  function renderNotifications(pageSize: number, currentPage: number) {
    if (!notifications || !notifications.length) return <div>No Notifications</div>

    return (
      <div style={{ flex: 1 }}>
        {notifications.map((notif, index) => (
          <div key={notif.id}>
            <Notification
              notification={notif}
              isLastNotif={index === notifications.length - 1}
              onClickNotif={onClickNotif}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <Widget style={{ margin: '0 auto', height: '100%', width: '100%', overflowY: 'auto' }}>
      <PaginatedComponent
        count={count}
        isLoading={isLoading}
        renderChildren={renderNotifications}
        onRequestNeeded={onRequestNeeded}
      />
    </Widget>
  )
}
