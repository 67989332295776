/** @flow */
import { useEffect } from 'react'

export type UseResizerProps = {|
  direction: 'vertical' | 'horizontal',
  resizer: $Exact<{ current: ?HTMLElement }>,
  resizable: $Exact<{ current: ?HTMLElement }>,
  onResized?: (size: number) => void,
  max?: number,
  min?: number,
  disabled?: boolean,
|}

export const useResizer = (props: UseResizerProps) => {
  const { direction, resizer, resizable, onResized, max, min, disabled } = props

  let originSize = 0
  let mouseOrigin = 0
  let newSize = 0

  function resize(e) {
    if (!resizable.current) return
    const mouseVector = (direction === 'horizontal' ? e.pageX : e.pageY) - mouseOrigin
    newSize = originSize + mouseVector

    if ((max || max === 0) && newSize > max) newSize = max
    if ((min || min === 0) && newSize < min) newSize = min

    if (direction === 'horizontal') resizable.current.style.width = `${newSize}px`
    else resizable.current.style.height = `${newSize}px`
  }

  function stopResize(e) {
    if (onResized) onResized(newSize)
    window.removeEventListener('mousemove', resize)
  }

  function onMouseDown(e: MouseEvent) {
    e.preventDefault()
    if (!resizable.current) return

    originSize = direction === 'horizontal' ? resizable.current.clientWidth : resizable.current.clientHeight
    mouseOrigin = direction === 'horizontal' ? e.pageX : e.pageY

    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResize)
  }

  useEffect(() => {
    if (resizer.current && resizable.current && !disabled) {
      resizer.current.addEventListener('mousedown', onMouseDown)
      return () => {
        if (resizer.current) resizer.current.removeEventListener('mousedown', onMouseDown)
      }
    }
    return undefined
  }, [resizer.current, resizable.current, onResized, direction, max, min])
}
