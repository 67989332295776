/** @flow */
import type { ID } from 'app/core/types'
import { getServerConfig } from './getServerConfig'

export const getCategoriesPermissions = (): {
  [categoryId: ID]: {
    [permKey: 'createPermission' | 'deletePermission' | 'readPermission' | 'updatePermission']: boolean,
  },
} => {
  const config = getServerConfig()
  return config.CATEGORIES_PERMISSIONS
}
