// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Icons-Planets-___planet_9-module__st1 {
  clip-path: url(#SVGID_2_);
}
`, "",{"version":3,"sources":["webpack://./app/components/Icons/Planets/planet_9.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[".st1 {\n  clip-path: url(#SVGID_2_);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"st1": `app-components-Icons-Planets-___planet_9-module__st1`
};
export default ___CSS_LOADER_EXPORT___;
