var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect } from 'react';
import cookies from 'js-cookie';
import api from 'app/core/api/api.js';
import settings from 'app/core/settings';
import history from 'app/main/routerHistory';
const Logout = () => {
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield api.logout();
            cookies.remove(settings.cookieToken);
            history.push('/authentication/login');
        }))();
    }, []);
    return _jsx("div", { children: "Redirect..." });
};
export default Logout;
