// @flow
import store from 'app/store/index.ts'
import { getStatus } from 'redux-resource'
import type { StoreResourceName } from 'app/core/types'

type StatusObject = {
  idle: boolean,
  pending: boolean,
  succeeded: boolean,
  failed: boolean,
}

export const getStatusRequest = (
  resourceType: StoreResourceName,
  requestKey: string,
  idleAsPending: boolean = false,
): StatusObject => {
  return getStatus(store.getState(), `${resourceType}.requests.${requestKey}.status`, idleAsPending)
}
