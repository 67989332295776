import React from 'react'
import PropTypes from 'prop-types'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { toHighlightJSX } from 'app/libs/helpers/toHighlightJSX.jsx'
import classes from './AutocompleteMultiple.module.scss'
import AutocompleteSingle from '../AutocompleteSingle/AutocompleteSingle.jsx'

export default class AutocompleteMultiple extends React.Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func,
  }

  static defaultProps = {
    renderLabel: ({ item, onDelete }) => (
      <>
        {/* eslint-disable */}
        <span>{toHighlightJSX(item.customLabel || item.label)}</span>
        {/* eslint-enable */}
        <FontIcon icon="close" style={{ padding: '0 0 0 4px', cursor: 'pointer' }} onClick={onDelete} />
      </>
    ),
  }

  constructor(props) {
    super(props)

    this.state = {}

    this.onAddItem = this.onAddItem.bind(this)
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  focus() {
    const { inputRef } = this.refs.autocomplete
    if (inputRef && inputRef.current) inputRef.current.focus()
  }

  onAddItem(incomingValue) {
    const { value, onChange } = this.props

    if (!incomingValue) return

    const existingValue = value.find((item) => item.value === incomingValue.value)
    if (existingValue) return

    const nextValue = [...value, incomingValue]

    onChange(nextValue)
  }

  onDeleteItem(_value) {
    const value = this.props.value.slice()

    if (!_value) return

    if (value.indexOf(_value) < 0) return

    value.splice(value.indexOf(_value), 1)

    this.props.onChange(value)
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  valid() {
    return this.refs.autocomplete.valid()
  }

  renderRead() {
    const { renderLabel, value } = this.props
    return value.length > 0 ? (
      <div className={classes.items}>
        {value.map((item) => (
          <div
            key={item.value}
            className={classes.item}
            style={{ backgroundColor: item.backgroundColor, color: getColorFromBackground(item.backgroundColor) }}
          >
            {renderLabel({ item, onDelete: () => this.onDeleteItem(item) })}
          </div>
        ))}
      </div>
    ) : null
  }

  render() {
    const { onChange, value, renderLabel, ...props } = this.props

    return (
      <div className={classes.container}>
        {this.renderRead()}

        <div className={classes.selectContainer}>
          <AutocompleteSingle
            ref="autocomplete"
            {...props}
            onChange={this.onAddItem}
            blurOnSelect={!props.searchable}
            style={{ maxWidth: '100%', ...props.style }}
          />
        </div>
      </div>
    )
  }
}
