/*eslint-disable */

import React from 'react'
import Icon from '../Icon.jsx'

export default class Planet_4 extends React.Component {
  static defaultProps = {
    width: 26,
    height: 18,
    fill: 'grey',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 26 18">
        <path
          d="M25.8,1c-1.2-2-6.2-0.7-10.5,1.2c-0.2-0.1-0.4-0.1-0.6-0.2c-1.7-0.4-3.5-0.2-5.1,0.7c-1.8,1-2.9,2.7-3.2,4.5
                    C2.6,10-1,13.6,0.2,15.6c0.4,0.7,1.2,1,2.5,1c0.1,0,0.3,0,0.4,0c0,0.1,0.1,0.2,0.1,0.2C3.7,17.6,4.5,18,5.4,18
                    c0.4,0,0.8-0.1,1.2-0.3c0.6-0.3,0.9-0.8,1.1-1.4c0.1-0.2,0.1-0.4,0.1-0.7c1-0.3,2-0.7,3-1.2c0.7,0.3,1.5,0.4,2.2,0.4
                    c1.2,0,2.3-0.3,3.4-0.9c1.7-1,2.9-2.6,3.2-4.5C23.8,6.3,26.9,2.9,25.8,1z M10.2,3.6c1.3-0.7,2.8-0.9,4.2-0.6
                    c1.4,0.4,2.7,1.2,3.4,2.5c0,0,0,0,0,0c-0.4,0-0.8,0.1-1.1,0.3c-0.9,0.5-1.3,1.6-0.9,2.6c-0.6,0.4-1.3,0.8-2,1.2
                    c-1.8,1-3.5,1.8-5,2.2c-0.2-0.3-0.4-0.5-0.6-0.8C6.6,8.5,7.5,5.2,10.2,3.6z M7.3,11.6c0.1,0.2,0.2,0.4,0.4,0.6
                    c-0.9,0.1-1.2,0-1.2-0.1c0-0.1,0-0.4,0.5-1.1C7,11.2,7.1,11.4,7.3,11.6z M6.6,16c-0.1,0.3-0.3,0.6-0.6,0.8c-0.2,0.1-0.4,0.2-0.7,0.2
                    c-0.5,0-0.9-0.2-1.1-0.6c-0.2-0.3-0.2-0.6-0.1-1c0.1-0.3,0.3-0.6,0.6-0.8c0.2-0.1,0.4-0.2,0.7-0.2c0.5,0,0.9,0.2,1.1,0.6
                    C6.7,15.3,6.7,15.7,6.6,16z M7.5,14.6C7.5,14.5,7.5,14.5,7.5,14.6c-0.5-0.8-1.3-1.2-2.1-1.2c-0.4,0-0.8,0.1-1.2,0.3
                    c-0.6,0.3-0.9,0.8-1.1,1.4C3,15.3,3,15.4,3,15.6c-1,0-1.6-0.1-1.8-0.5c-0.5-0.9,1.1-3.4,5.2-6.4c0,0.4,0.1,0.8,0.2,1.2
                    c-1.1,1.2-1.4,2.1-1,2.8C5.8,13,6.3,13.3,7,13.3c0.5,0,1.2-0.1,1.9-0.3c1.6-0.4,3.5-1.3,5.4-2.4c0.7-0.4,1.4-0.8,2-1.3
                    c0.2,0.2,0.5,0.3,0.8,0.4c-0.8,0.5-1.6,1-2.4,1.5C12.2,12.6,9.7,13.8,7.5,14.6z M15.8,13c-1.1,0.6-2.3,0.9-3.5,0.7c1-0.5,2-1,3-1.6
                    c1-0.6,1.9-1.2,2.9-1.8C17.7,11.4,16.9,12.4,15.8,13z M18.4,8.6c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4,0-0.7-0.2-0.9-0.5
                    c-0.3-0.5-0.1-1.2,0.4-1.5c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0,0,0,0,0,0l0,0c0.2,0.1,0.3,0.2,0.4,0.4
                    C19.1,7.7,18.9,8.3,18.4,8.6z M19.1,5.6C19,5.4,18.9,5.2,18.7,5c-0.1-0.2-0.2-0.4-0.4-0.6c0.9-0.1,1.2,0,1.2,0.1
                    C19.6,4.6,19.6,4.9,19.1,5.6z M20,7.7c0-0.4-0.1-0.7-0.3-1.1c0,0,0,0,0-0.1c0.9-1.1,1.2-1.9,0.8-2.6c-0.4-0.7-1.4-0.8-3-0.5
                    c-0.3-0.3-0.6-0.5-1-0.7c4.7-2,7.8-2.2,8.3-1.3C25.4,2.4,23.7,4.8,20,7.7z"
        />
      </Icon>
    )
  }
}

/* eslint-enable */
