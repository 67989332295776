/** @flow */
import React, { useMemo, useRef, useEffect } from 'react'
import moment from 'moment'
import cx from 'classnames'
import type { Contract as ContractType } from 'app/core/types'
import keyCode from 'app/libs/keyCode'
import { error } from 'app/components/Notifications/notify'
import resources from 'app/store/resources'

import type { CellInstance, Cell } from '../../../types'
import classes from './Contract.module.scss'
import { LengthButton } from './LengthButton.jsx'

type Props = {|
  contract: ContractType,
  instance: CellInstance,
  lockedWithContract: boolean,
  haveSelectedCells: boolean,
  selected: boolean,
  cell: Cell,
|}

export function Contract(props: Props): React$Node {
  const { cell, contract, instance, lockedWithContract, haveSelectedCells, selected } = props
  const { startDate: contractStartDate, endDate: contractEndDate, contractStatus } = contract
  const { setGanttIsUnderEditing, dimensions, ganttProperties, state } = instance
  const { savingCells } = state
  const { onSelectCell } = cell.column

  const { selectCell } = cell.getCellProps()

  const { dayWidth } = dimensions || {}
  const { ganttStart } = ganttProperties || {}

  const contrainerRef = useRef()
  const leftExponentRef = useRef()
  const rightExponentRef = useRef()

  const readOnly = useMemo(() => ['signed', 'signing'].includes(contractStatus), [contract])
  const isLoading = useMemo(() => Boolean(savingCells[cell.id]), [savingCells])

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  function getDimensionsFromDates(startDate, endDate) {
    const daysLeft = moment(startDate)?.diff(ganttStart, 'days') || 0
    const _left = dayWidth * daysLeft
    const _width = (moment(endDate).diff(moment(startDate), 'day') + 1) * dayWidth
    return { left: _left, width: _width }
  }

  const { left, width } = useMemo(
    () => getDimensionsFromDates(contractStartDate, contractEndDate),
    [contract, dimensions, ganttProperties],
  )

  function getDatesFromPositions(leftDiff, widthDiff) {
    const { dayWidth } = dimensions || {}

    const startDate = moment(contractStartDate, 'YYYY-MM-DD')
    const endDate = moment(contractEndDate, 'YYYY-MM-DD')

    const daysLeftDiff = Math.round(leftDiff / dayWidth)
    const daysWidthDiff = Math.round(widthDiff / dayWidth)
    const difference = endDate.diff(moment(startDate), 'days')

    const newStartDate = moment(startDate).add(daysLeftDiff, 'days')
    let newEndDate = moment(newStartDate).add(difference + daysWidthDiff, 'days')

    // optimisations
    if (leftDiff === -widthDiff) newEndDate = endDate

    return {
      startDate: newStartDate,
      endDate: newEndDate,
    }
  }

  function updateExponents(leftDiff, widthDiff) {
    if (!leftExponentRef.current || !rightExponentRef.current) return
    const { startDate, endDate } = getDatesFromPositions(leftDiff, widthDiff)
    leftExponentRef.current?.setAttribute('tooltip-date', startDate?.format('dd DD/MM/YYYY'))
    rightExponentRef.current?.setAttribute('tooltip-date', endDate?.format('dd DD/MM/YYYY'))
  }

  function changeDomPosition(leftDiff, widthDiff) {
    if (!contrainerRef.current) return

    if (contrainerRef.current) {
      contrainerRef.current.style.width = `${width + widthDiff}px`
      contrainerRef.current.style.left = `${left + leftDiff}px`
    }
  }

  function onSave(leftDiff: number, widthDiff: number) {
    const { cell } = props
    const { updateCells, setSavingCells, unsetSavingCells } = instance
    const { startDate, endDate } = getDatesFromPositions(leftDiff, widthDiff)

    if (startDate.isAfter(endDate)) {
      error("Error: Start date can't be after the end date.")
      changeDomPosition(0, 0)
      return Promise.reject()
    }
    setSavingCells({ [cell.id]: cell })

    return resources.contracts
      .update({
        id: cell.row.original.contract,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      })
      .then((newContract) => {
        unsetSavingCells({ [cell.id]: cell })
        updateCells({ [cell.id]: cell })
      })
      .catch(() => {
        unsetSavingCells({ [cell.id]: cell })
        updateCells({ [cell.id]: cell })
      })
  }

  function onFocus(event: SyntheticFocusEvent<HTMLElement>) {
    if (onSelectCell) {
      onSelectCell(cell, instance?.getLastestInstance?.() || instance, event)
    }
    if (selectCell) selectCell()
  }

  function onLengthResize(position: 'left' | 'right') {
    return (e: MouseEvent, { diff }: { diff: number }) => {
      if (position === 'left') {
        changeDomPosition(diff, -diff)
        updateExponents(diff, -diff)
      }
      if (position === 'right') {
        changeDomPosition(0, diff)
        updateExponents(0, diff)
      }
    }
  }

  function onLengthStopResize(position: 'left' | 'right') {
    return (e: MouseEvent, { diff }: { diff: number }) => {
      if (diff === 0) return

      let leftDiff = 0
      let widthDiff = 0

      if (position === 'left') {
        leftDiff = diff
        widthDiff = -diff
      }
      if (position === 'right') {
        widthDiff = diff
      }

      onSave(leftDiff, widthDiff)
    }
  }

  const rangeDimensions = useRef({ originX: 0, vector: 0 })

  function resetRangeDimensions() {
    rangeDimensions.current = { originX: 0, vector: 0 }
  }

  function move(e: MouseEvent) {
    const { originX } = rangeDimensions.current
    const vector = e.clientX - originX
    rangeDimensions.current.vector = vector
    changeDomPosition(vector, 0)
    updateExponents(vector, 0)
  }

  function stopMove(e: MouseEvent) {
    window.removeEventListener('mousemove', move)
    window.removeEventListener('mouseup', stopMove)

    setGanttIsUnderEditing?.(false)

    const { vector } = rangeDimensions.current

    if (vector === 0) {
      resetRangeDimensions()
      changeDomPosition(0, 0)
      return
    }

    onSave(vector, 0)
      .then(() => {
        resetRangeDimensions()
      })
      .catch(() => {
        resetRangeDimensions()
        changeDomPosition(0, 0)
      })
  }

  function cancel(e: KeyboardEvent) {
    if (e.keyCode === keyCode.ESCAPE) {
      setGanttIsUnderEditing?.(false)

      resetRangeDimensions()
      changeDomPosition(0, 0)
      window.removeEventListener('mousemove', move)
      window.removeEventListener('mouseup', stopMove)
      window.removeEventListener('keydown', cancel)
    }
  }

  function onMouseDown(e: SyntheticMouseEvent<any>) {
    e.stopPropagation()

    if (e.button !== 0) return

    setGanttIsUnderEditing?.(true)

    resetRangeDimensions()
    rangeDimensions.current.originX = e.clientX

    window.addEventListener('mousemove', move)
    window.addEventListener('mouseup', stopMove)
    window.addEventListener('keydown', cancel)
  }

  return (
    <div
      className={cx(classes.container, {
        [classes.haveSelectedCells]: haveSelectedCells,
        [classes.selected]: selected,
        [classes.cellIsLoading]: isLoading,
      })}
      style={{
        left,
        width,
        top: 0,
      }}
      ref={contrainerRef}
      tabIndex={0}
      onMouseDown={!lockedWithContract && selected && !readOnly ? onMouseDown : undefined}
      onFocus={onFocus}
      gantt-cell-contract={cell.id}
      contract-id={contract.id}
      contract-status={contractStatus}
      initial-left={left}
      initial-width={width}
    >
      <div className={classes.subContainer}>
        {!lockedWithContract && selected && !readOnly ? (
          <LengthButton
            onResize={onLengthResize('left')}
            onStopResize={onLengthStopResize('left')}
            className={classes.startPoint}
            position="left"
            instance={instance}
          />
        ) : null}
        <div
          className={classes.dateExponent}
          position="left"
          ref={leftExponentRef}
          tooltip-date={moment(contractStartDate)?.format('dd DD/MM/YYYY')}
        />
        <div
          className={classes.dateExponent}
          position="right"
          ref={rightExponentRef}
          tooltip-date={moment(contractEndDate)?.format('dd DD/MM/YYYY')}
        />
        {!lockedWithContract && selected && !readOnly ? (
          <LengthButton
            onResize={onLengthResize('right')}
            onStopResize={onLengthStopResize('right')}
            className={classes.endPoint}
            position="right"
            instance={instance}
          />
        ) : null}
      </div>
    </div>
  )
}
