/** @flow */
import React from 'react'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import cx from 'classnames'

import type { ColumnHeader, Column, DefaultCellProps, CellInstance, TableInstance } from '../../types'
import { Range } from './Range/Range.jsx'
import { EditModal } from './EditModals/EditModals.jsx'
import { HeaderSVG } from './HeaderSVG.jsx'

import classes from './ColumnTimelineImputation.module.scss'

type Params = {|
  ...DefaultCellProps,
  Header?: React$Node | ((instance: CellInstance | TableInstance) => React$Node),
  id?: string,
  readOnly?: boolean,
|}

export const ColumnTimelineImputation = (params: Params | void): ColumnHeader => {
  const rest = params || {}

  const columns: Array<$Shape<Column>> = [
    {
      id: 'timeline-days',
      readOnly: false,
      cellType: 'CellTimeline',
      isGanttColumn: true,
      minWidth: 800,
      width: null,
      actions: (instance, cell) => {
        const { ganttActions } = instance
        const ganttActionsResolved = ganttActions?.(instance, cell, cell.row.depth) || []
        return ganttActionsResolved
      },
      save: (original, finalValue, cell, instance, type) => {
        if (instance.ganttSave) {
          return instance.ganttSave(original, finalValue, cell, instance, type)
        }
        return {
          resource: original.__resource,
          formatData: (original, value, cell, instance, type) => {
            const { subRows, __resource, uneditableDates, ...rest } = value
            return rest
          },
        }
      },
      Header: (instance) => {
        const { timelineUnit } = instance

        if (!timelineUnit) {
          return <div className={`${classes.header} ${classes.headerDays}`} />
        }

        const { unit, unitFormat } = timelineUnit

        return (
          <div className={`${classes.header} ${classes.headerDays}`}>
            <HeaderSVG instance={instance} height={41} unit={unit} unitFormat={unitFormat} />
          </div>
        )
      },
      accessor: (_) => _,
      Cell: (instance) => {
        const { cell, state, dimensions, prefs, row } = instance
        const { savingCells, ganttCellsState, expandedRows } = state

        function getRowHeight() {
          if (row.original.__resource === 'projectPlans') return 39
          if (row.isGrouped) return 50
          const { minLineHeight, maxLineHeight } = prefs
          const estimatedHeight = !expandedRows[row.id] ? minLineHeight : maxLineHeight
          return estimatedHeight
        }

        if (!dimensions) return <div className={classes.cell} />

        if (cell.row.isGrouped) {
          return <div className={classes.cell} style={{ height: getRowHeight() }} />
        }

        return (
          <>
            <div className={classes.loaderContainer}>
              <div className={classes.loader}>
                {savingCells[cell.id] ? (
                  <div style={{ padding: '0 10px' }}>
                    <LoaderSmall size={16} thick={3} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cx(classes.cell, { [classes.disabled]: savingCells[cell.id] })}>
              <Range instance={instance} cell={cell} value={cell.value} key={ganttCellsState[cell.id]?.resetKey} />
            </div>
          </>
        )
      },
      EditModal: (instance) => {
        const { cell } = instance
        const { getCellProps } = cell
        const { edition } = getCellProps()
        const { endEdit } = edition
        return <EditModal instance={instance} onRequestClose={endEdit} />
      },
      ...rest,
    },
  ]

  return {
    id: 'gantt-headers',
    isGanttColumn: true,
    Header: (instance) => {
      const { ganttProperties, timelineUnit, dimensions } = instance
      if (!timelineUnit || !dimensions || !ganttProperties) return <div className={classes.headerMonths} />

      const { headUnit, headUnitFormat } = timelineUnit

      return <HeaderSVG instance={instance} height={41} unit={headUnit} unitFormat={headUnitFormat} />
    },
    columns,
  }
}
