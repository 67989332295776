var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    filters: {
        searchInput: '',
        filterStudio: [{ label: 'All studios', value: 'all' }],
        filterProject: [{ label: 'All projects', value: 'all' }],
    },
    layout: {
        title: null,
        subTitle: null,
        leftSection: null,
        mainSection: null,
    },
    people: [],
    personDatas: null,
    thumbnail: null,
    loading: false,
    updateLoading: false,
    error: null,
};
export const fetchOrganizationChartLayout = createAsyncThunk('organizationChart/fetchLayout', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.trombinoscope.layout();
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}), {
    condition: (_, { getState }) => {
        const { layout: { title, subTitle, leftSection, mainSection }, } = getState().organizationChart;
        return !title || !subTitle || !leftSection || !mainSection;
    },
});
export const fetchOrganizationChartPeople = createAsyncThunk('organizationChart/fetchAllPeople', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.people.fetchAll({
            page_size: 1000,
            ordering: 'firstName',
            underContract: 'thisMonth',
        });
        return fulfillWithValue(response.results);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchOrganizationChartPersonData = createAsyncThunk('organizationChart/fetchOnePeople', (id_1, _a) => __awaiter(void 0, [id_1, _a], void 0, function* (id, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.trombinoscope.personDatas({ id });
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const createPersonAttributeValue = createAsyncThunk('organizationChart/createPersonAttribute', (data_1, _a) => __awaiter(void 0, [data_1, _a], void 0, function* (data, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.personAttributeValues.create(data);
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const updatePersonAttributeValue = createAsyncThunk('organizationChart/updatePersonAttribute', (data_1, _a) => __awaiter(void 0, [data_1, _a], void 0, function* (data, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.personAttributeValues.update(data);
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { actions, reducer } = createSlice({
    name: 'organizationChart',
    initialState,
    reducers: {
        setFilter: {
            prepare: (filter, value) => ({ payload: { filter, value } }),
            reducer: (draft, action) => {
                const { filter, value } = action.payload;
                draft.filters[filter] = value;
            },
        },
        resetFilters: (draft) => {
            draft.filters = {
                searchInput: '',
                filterStudio: [{ label: 'All studios', value: 'all' }],
                filterProject: [{ label: 'All projects', value: 'all' }],
            };
        },
        updateTrombinoscopePeopleThumbnail: (draft, action) => {
            draft.thumbnail = action.payload;
        },
        clearPersonData: (state) => {
            state.personDatas = null;
            state.thumbnail = null;
        },
    },
    extraReducers: (builder) => {
        // fetchOrganizationChartLayout
        builder.addCase(fetchOrganizationChartLayout.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(fetchOrganizationChartLayout.fulfilled, (state, { payload }) => {
            state.layout = payload;
            state.loading = false;
        });
        builder.addCase(fetchOrganizationChartLayout.rejected, (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        });
        // fetchOrganizationChartPeople
        builder.addCase(fetchOrganizationChartPeople.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(fetchOrganizationChartPeople.fulfilled, (state, { payload }) => {
            state.people = payload;
            state.loading = false;
        });
        builder.addCase(fetchOrganizationChartPeople.rejected, (state, { payload }) => {
            state.loading = false;
        });
        // fetchOrganizationChartPersonData
        builder.addCase(fetchOrganizationChartPersonData.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(fetchOrganizationChartPersonData.fulfilled, (state, { payload }) => {
            state.personDatas = payload;
            state.loading = false;
        });
        builder.addCase(fetchOrganizationChartPersonData.rejected, (state, { payload }) => {
            state.loading = false;
        });
        // createPersonAttributeValue
        builder.addCase(createPersonAttributeValue.pending, (state, { payload }) => {
            state.updateLoading = true;
        });
        builder.addCase(createPersonAttributeValue.fulfilled, (state, { payload }) => {
            const filteredPersonAttributes = state.personDatas.attributesInst.filter((el) => el.personAttribute !== payload.personAttribute);
            const newValues = [...filteredPersonAttributes, payload];
            state.personDatas.attributesInst = newValues;
            state.updateLoading = false;
        });
        builder.addCase(createPersonAttributeValue.rejected, (state, { payload }) => {
            state.personDatas = payload;
            state.updateLoading = false;
        });
        // updatePersonAttributeValue
        builder.addCase(updatePersonAttributeValue.pending, (state, { payload }) => {
            state.updateLoading = true;
        });
        builder.addCase(updatePersonAttributeValue.fulfilled, (state, { payload }) => {
            const filteredPersonAttributes = state.personDatas.attributesInst.filter((el) => el.personAttribute !== payload.personAttribute);
            const newValues = [...filteredPersonAttributes, payload];
            state.personDatas.attributesInst = newValues;
            state.updateLoading = false;
        });
        builder.addCase(updatePersonAttributeValue.rejected, (state, { payload }) => {
            state.personDatas = payload;
            state.updateLoading = false;
        });
    },
});
export const { setFilter, resetFilters, updateTrombinoscopePeopleThumbnail, clearPersonData } = actions;
export default reducer;
