var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import settings from 'app/core/settings';
import * as Sentry from '@sentry/browser';
import ImpersonificationStart from 'app/pages/Admin/Impersonification/ImpersonificationStart.jsx';
import classes from './Authenticated.module.scss';
import { Header } from './Header/Header.jsx';
import TaskRightPanel from 'app/containers/Task/TaskRightPanel.jsx';
import AssetDetailRightPanel from 'app/containers/Assets/AssetDetail/AssetDetailRightPanel';
import AuthenticatedRoutes from './AuthenticatedRoutes.jsx';
import { fetchUser, setProperties } from 'app/store/actions/user.js';
import api from 'app/core/api/api';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectConfig } from 'app/store/reducers/config';
import PubSub from 'pubsub-js';
import { setServerConfig } from 'app/core/utils/getServerConfig';
import Loader from 'app/components/Loader/Loader.jsx';
export const MenuContext = createContext({
    toggleMenu: () => { },
    isOpen: window.localStorage.getItem('menu') !== 'false',
});
const Authenticated = () => {
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [menuIsOpen, setMenuIsOpen] = useState(window.localStorage.getItem('menu') !== 'false');
    useEffect(() => {
        if (settings.sentry.SENTRY_DSN && user) {
            const { username, email, assetInst } = user;
            Sentry.setUser({ username, email, id: assetInst === null || assetInst === void 0 ? void 0 : assetInst.id });
        }
    }, [user]);
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const [user, config] = yield Promise.all([dispatch(fetchUser()), api.config()]);
            setServerConfig(config);
            dispatch(setProjectConfig(config));
            PubSub.publish('ON_RECEIVE_CONFIG', config);
            dispatch(setProperties({
                permissions: config.PERMISSIONS,
                isAdmin: config.IS_ADMIN,
            }));
            setLoading(false);
        }))();
    }, []);
    const toggleMenu = useCallback((event) => {
        setMenuIsOpen((menuIsOpen) => {
            window.localStorage.setItem('menu', String(!menuIsOpen));
            return !menuIsOpen;
        });
    }, []);
    const contextValue = useMemo(() => ({
        toggleMenu,
        isOpen: menuIsOpen,
    }), [toggleMenu, menuIsOpen]);
    return loading ? (_jsx(Loader, {})) : (_jsx(MenuContext.Provider, { value: contextValue, children: _jsxs("div", { className: `${classes.appLayout} ovmAppContainer`, children: [_jsx(ImpersonificationStart, {}), _jsx(Header, {}), _jsxs("div", { className: classes.pageContainer, children: [_jsx(AssetDetailRightPanel, {}), _jsx(TaskRightPanel, {}), _jsx(AuthenticatedRoutes, {})] })] }) }));
};
export default Authenticated;
