// @flow
import type { AssetTypes } from 'app/core/types'
import { enumProxyFromArray } from 'app/libs/helpers'

export const assetsTypes: { [assetType: AssetTypes]: string } = {
  ep: 'Episode',
  fo: 'Folder',
  gp: 'Group',
  lc: 'Location',
  mo: 'Model',
  mi: 'Mission',
  pc: 'Computer',
  pj: 'Project',
  sh: 'Shot',
  sq: 'Sequence',
  us: 'User',
  of: 'Office',
  ph: 'Place-holder',
}

export const responseAssetsTypes = {
  group: 'gp',
}

/**
 * return an array of asset types
 * [
 *   'ep',
 *   'fo',
 *   ...
 * ]
 */
export const assetsTypesShortList: Array<$Keys<typeof assetsTypes>> = Object.keys(assetsTypes)

/**
 * return an object like this:
 *  {
 *    ep: 'ep',
 *    fo: 'fo'
 *    ...
 *  }
 *
 * Useful when you want to use assetType with validation
 */
export const assetTypesKey: { [key: string]: string } = enumProxyFromArray(assetsTypesShortList)
