/** @flow */
import React, { useState, useRef, useMemo, useEffect, ReactElement, FocusEvent } from 'react'
import { isNumber } from 'lodash'
import uniqid from 'app/libs/uniqid.js'
import StartRating from '@material-ui/lab/Rating'
import keyCode from 'app/libs/keyCode'
import { targetIsChild } from 'app/libs/helpers/dom'

export type TSRatingProps = {|
  classes?: {},
  disabled?: boolean,
  emptyIcon?: ReactElement<any>,
  getLabelText?: () => void,
  value: number,
  icon?: ReactElement<any>,
  IconContainerComponent?: ReactElement<any>,
  max?: number,
  name?: string,
  onChange?: (value: number) => void,
  onChangeActive?: () => void,
  onBlur?: (value: number, event: FocusEvent<HTMLElement>) => void,
  precision?: number,
  readOnly?: boolean,
  validatorRef?: () => void,
  size?: 'small' | 'medium' | 'large',
  // TO DO : check the TS equivalent for React$ElementType
  // innerRef?: RefObject<React$ElementType> | (() => void),
  innerRef?: RefObject<any> | (() => void),
  focusOnMount?: boolean,
|}

export type RatingProps = {|
  classes?: Object,
  disabled?: boolean,
  emptyIcon?: React$Element<any>,
  getLabelText?: Function,
  value: number,
  icon?: React$Element<any>,
  IconContainerComponent?: React$Component<any>,
  max?: number,
  name?: string,
  onChange?: (value: number) => void,
  onChangeActive?: Function,
  onBlur?: (value: number, event: SyntheticFocusEvent<HTMLElement>) => void,
  precision?: number,
  readOnly?: boolean,
  validatorRef?: Function,
  size?: 'small' | 'medium' | 'large',
  innerRef?: React$Ref<React$ElementType> | Function,
  focusOnMount?: boolean,
|}

export function Rating(props: RatingProps): React$Node {
  const { value, onChange, onBlur, validatorRef, readOnly, name, precision, innerRef, max, focusOnMount, ...rest } =
    props

  const _rating = useRef<number>(Number(value || 0))
  const [rating, setRating] = useState(Number(value || 0))

  const containerRef = useRef()
  const componentRef = useRef()

  function incremet() {
    if (_rating.current < (max || 5)) {
      _rating.current += precision || 1
      setRating(_rating.current)
    }
  }
  function decremet() {
    if (_rating.current > 0) {
      _rating.current -= precision || 1
      setRating(_rating.current)
    }
  }

  function onKeyDown(event) {
    if (event.keyCode === keyCode.ESCAPE) {
      event.preventDefault()
      setRating(value)
      _rating.current = value
      onBlur?.(value, event)
    }
    if (event.keyCode === keyCode.UP) {
      event.preventDefault()
      incremet()
    }
    if (event.keyCode === keyCode.DOWN) {
      event.preventDefault()
      decremet()
    }
    if (event.keyCode === keyCode.RIGHT) {
      event.preventDefault()
      incremet()
    }
    if (event.keyCode === keyCode.LEFT) {
      event.preventDefault()
      decremet()
    }
    if (event.keyCode === keyCode.ENTER) {
      event.preventDefault()
      onChange?.(_rating.current)
    }
  }

  function _onBlur(event: SyntheticFocusEvent<HTMLElement>) {
    if (onBlur && !targetIsChild(event)) onBlur(_rating.current, event)
  }

  const customName = useMemo(() => (readOnly ? 'rating' : uniqid()), [readOnly])

  useEffect(() => {
    setRating(value || 0)
    _rating.current = value
  }, [value])

  useEffect(() => {
    if (focusOnMount && containerRef.current) containerRef.current.focus()
  }, [focusOnMount, !!containerRef.current])

  if (!isNumber(Number(value))) return value

  return (
    <div
      tabIndex={readOnly ? '-1' : '0'}
      ref={(ref) => {
        if (typeof innerRef === 'function') innerRef(ref)
        else if (typeof innerRef === 'object') innerRef.current = ref
        containerRef.current = ref
      }}
      onKeyDown={readOnly ? undefined : onKeyDown}
      onBlur={readOnly ? undefined : _onBlur}
    >
      <StartRating
        value={rating}
        readOnly={readOnly}
        ref={componentRef}
        name={name || customName}
        max={max}
        precision={precision}
        onChange={(event: SyntheticInputEvent<>) => {
          if (onChange) {
            onChange(Number(event.target.value))
          } else {
            setRating(Number(event.target.value))
            _rating.current = Number(event.target.value)
          }
        }}
        style={{ position: 'relative', zIndex: 1 }}
        {...rest}
      />
    </div>
  )
}
