// @flow
import React, { useEffect, useState, useMemo } from 'react'
import map from 'lodash/map'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData } from 'app/components/Form'
import { getResources } from 'app/store/selectors'
import resources from 'app/store/resources'
import type { Department, ProjectDepartment, ResourcesList, DateDay, ProjectPlan } from 'app/core/types'
import { getMinMaxDatesFromObjects } from 'app/core/utils/dates'
import Loader from 'app/components/Loader/Loader.jsx'
import moment from 'moment'

export type ModalEditProjectDepartmentProps = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  projectDepartment?: ?ProjectDepartment,
  onSave?: ($Shape<ProjectDepartment>) => Promise<any>,
  onChange?: ($Shape<ProjectDepartment>) => any,
  projectPlan?: ProjectPlan,
  parentSubRows?: Array<{ ...any, startDate: DateDay, endDate: DateDay }>,
|}

export function ModalEditProjectDepartment(props: ModalEditProjectDepartmentProps): React$Element<any> {
  const { projectDepartment, onSave, onChange, projectPlan: defaultProjectPlan, parentSubRows, ...modalProps } = props

  const [departments, setDepartments] = useState<ResourcesList<Department>>({})
  const [department, setDepartment] = useState<Department | void>()
  const [projectPlan, setProjectPlan] = useState<ProjectPlan | void>(defaultProjectPlan)
  const [projectPlans, setProjectPlans] = useState<ResourcesList<ProjectPlan>>({})
  const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false)

  useEffect(() => {
    const config = { params: { queries: { page_size: 1000 } } }

    Promise.all([
      resources.departments.fetchAll(config).then(() => {
        const departments = getResources(undefined, 'departments')
        setDepartments(departments)
        setDepartment(departments[projectDepartment?.department])
      }),
      !defaultProjectPlan && projectDepartment?.projectPlan
        ? resources.projectPlans.fetchAll(config).then(() => {
            const projectPlans = getResources(undefined, 'projectPlans', undefined, ['projectInst'])
            setProjectPlans(projectPlans)
            setProjectPlan(projectPlans[defaultProjectPlan?.id || projectDepartment.projectPlan])
          })
        : null,
    ]).then(() => setResourcesLoaded(true))
  }, [])

  async function save(data: Object) {
    const { projectPlan, department, startDate, endDate } = data

    const newProjectDepartment = {
      id: projectDepartment?.id,
      startDate,
      endDate,
      projectPlan: projectPlan?.value,
      department: department?.value || department,
    }

    if (onSave) return onSave(newProjectDepartment)
    const res = await resources.projectDepartments[projectDepartment?.id ? 'update' : 'create'](newProjectDepartment)
    onChange?.({ ...(departments[department] || {}), ...res.resources[0] })
    return res
  }

  const { estimatedStartDate, estimatedEndDate } = useMemo(() => {
    if (!parentSubRows?.length && projectPlan) {
      return {
        estimatedStartDate: projectPlan.startDate,
        estimatedEndDate: projectPlan.endDate,
      }
    }
    let date = getMinMaxDatesFromObjects(parentSubRows)[1]
    if (date) date = moment(date).add(1, 'day').format('YYYY-MM-DD') // prettier-ignore
    const estimatedStartDate = date
    let estimatedEndDate = date

    if (projectPlan && date && date < projectPlan.endDate) {
      estimatedEndDate = projectPlan.endDate
    }

    return {
      estimatedStartDate,
      estimatedEndDate,
    }
  }, [projectPlan, parentSubRows])

  const defaultData = {
    ...projectDepartment,
    projectPlan: projectPlan ? { label: projectPlan.name, value: projectPlan.id } : undefined,
    department: department ? { label: department.name, value: department.id } : undefined,
    startDate: projectDepartment?.startDate || estimatedStartDate,
    endDate: projectDepartment?.endDate || estimatedEndDate,
  }

  return (
    <ModalConfirmForm
      title={department ? `Edit department ${department.name || ''}` : 'Create a new department'}
      draggable={true}
      {...modalProps}
    >
      {!resourcesLoaded ? (
        <div style={{ height: 300 }} className="flex center alignCenter fullWidth">
          <Loader />
        </div>
      ) : (
        <FormData
          onSave={save}
          flashNotifSuccessLabel={`Department ${department ? 'edited' : 'created'}`}
          defaultData={defaultData}
          properties={[
            {
              label: 'Project',
              key: 'projectPlan',
              type: 'muiautocomplete',
              elementProps: {
                isRequired: true,
                options: map(projectPlans, (projectPlan) => ({
                  label: projectPlan.name,
                  value: projectPlan.id,
                })),
                disabled: !!defaultProjectPlan || !!projectDepartment?.projectPlan,
                placeholder: 'Select a project',
              },
            },
            {
              label: 'Department',
              key: 'department',
              type: 'select',
              elementProps: {
                options: map(departments, (dpt) => ({ label: dpt.name, value: dpt.id })),
                isRequired: true,
                fullWidth: true,
                placeholder: 'Select a department',
              },
            },
            {
              label: 'Start date',
              key: 'startDate',
              type: 'string',
              elementProps: {
                type: 'date',
                isRequired: true,
              },
            },
            {
              label: 'End date',
              key: 'endDate',
              type: 'string',
              elementProps: {
                type: 'date',
                isRequired: true,
              },
            },
          ]}
        />
      )}
    </ModalConfirmForm>
  )
}
