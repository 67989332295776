/** @flow */
import React from 'react'
import { openModal, confirm } from 'app/components/Modal'
import { forEach, map, invert, flatten } from 'lodash'
import { permission } from 'app/containers/Permissions'
import { getServerConfig } from 'app/core/utils/getServerConfig'
import type { TableInstance, TableColumn } from 'app/components/Table/types.js'
import { error } from 'app/components/Notifications/notify.js'
import api from 'app/core/api/api.js'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'

import { PushToColumnModal } from './PushToColumnModal.jsx'
import type { MenuAction } from '../../../../core/types/MenuAction'

const pushTo = (
  columnSrc: TableColumn,
  columnDest: TableColumn,
  instance: TableInstance,
  merge: boolean,
  mergeOnly: boolean,
) => {
  const { progressionStatus, pushType: srcType, id: src } = columnSrc.extends || {}
  const { parentId, pushType: destType, id: dest } = columnDest.extends || {}
  const contentTypes = invert(getServerConfig().CONTENT_TYPES)

  const { reloadData } = instance

  const onRequestSuccess = () => {
    reloadData()
  }

  const onRequestError = () => {
    error('An error occur with the push to column.')
  }

  if (mergeOnly) {
    return confirm({
      render: `Are you sure you want to ${merge ? 'merge' : 'replace'} the contents of column ${String(
        columnSrc.Header,
      )} into column ${String(columnDest.Header)}?`,
      title: `${merge ? 'Merge' : 'Replace'} ${String(columnSrc.Header)} content into ${String(columnDest.Header)}`,
      onValidate: () =>
        api
          .pushToColumn({
            rootAsset: parentId,
            srcContentType: srcType === 'steps' ? contentTypes.step : contentTypes.dynamicapproval,
            src,
            destContentType: destType === 'steps' ? contentTypes.step : contentTypes.dynamicapproval,
            dest,
            merge,
            mergeOnly,
          })
          .then(onRequestSuccess)
          .catch(onRequestError),
    })
  }

  return openModal(
    <PushToColumnModal
      merge={merge}
      srcType={srcType}
      destType={destType}
      columnSrc={columnSrc}
      columnDest={columnDest}
      progressionStatus={progressionStatus}
      onSave={(status) => {
        return api
          .pushToColumn({
            rootAsset: parentId,
            status: status.map((rel) => [rel.src, rel.dest]),
            srcContentType: srcType === 'steps' ? contentTypes.step : contentTypes.dynamicapproval,
            src,
            destContentType: destType === 'steps' ? contentTypes.step : contentTypes.dynamicapproval,
            dest,
            merge,
            mergeOnly,
          })
          .then(onRequestSuccess)
          .catch(onRequestError)
      }}
    />,
  )
}

const renderPushActions = (
  columnId: string,
  instance: TableInstance,
  columnSrc: TableColumn,
  merge: boolean,
  mergeOnly: boolean,
) => {
  if (!columnId) return []
  const { visibleColumns } = instance

  const items = {}

  forEach((visibleColumns: Array<TableColumn>), (column) => {
    const { extends: _extends, Header, readOnly, id } = column
    const { pushType } = _extends || {}

    if (id !== columnId && pushType && !readOnly) {
      if (!items[pushType]) items[pushType] = []

      items[pushType].push({
        label: Header,
        onClick: (event: Object) => pushTo(columnSrc, column, instance, merge, mergeOnly),
        editAction: false,
      })
    }
  })

  let label = 'Push status and replace column to'
  if (merge && !mergeOnly) label = 'Push status and merge column to'
  if (mergeOnly && !merge) label = 'Replace column content to'
  if (mergeOnly && merge) label = 'Merge column content to'

  return [
    {
      label,
      editAction: false,
      items: (flatten(
        (map(items, (item, name: string) => [{ separator: true, label: name }, ...item]): Array<Object>),
      ): Array<Object>),
    },
  ]
}

export const pushToColumnActions = (
  columnId: string,
  instance: TableInstance,
  column: TableColumn,
): Array<MenuAction> => {
  if (!permission(['projet_follow-up__push/merge to'])) return []

  return [
    {
      label: 'Push',
      editAction: false,
      leftLabel: <FontIcon icon="fas-sign-out-alt" />,
      items: [
        ...renderPushActions(columnId, instance, column, false, true),
        ...renderPushActions(columnId, instance, column, true, true),
        ...renderPushActions(columnId, instance, column, false, false),
        ...renderPushActions(columnId, instance, column, true, false),
      ],
    },
  ]
}
