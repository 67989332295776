// @flow
import React from 'react'
import { map } from 'lodash'
import type { Take } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import { type Pipe } from 'app/core/utils/pipeNext.type'
import { getResources } from 'app/store/selectors/getResources'
import { MediaItem } from 'app/components/Medias/MediaItem/MediaItem.tsx'

type Props = {
  medias: Array<Object>,
}

function DisplayTakeValidationMediasComponent(props: Props): React$Node {
  const { medias } = props

  if (medias.length === 0)
    return (
      <div className="fullWidth textCenter" style={{ color: 'lightgrey' }}>
        There is no validation media.
      </div>
    )

  return (
    <div className="flex row wrap fullWidth">
      {map(medias, (media) => {
        return (
          <MediaItem
            key={media.url}
            medias={medias}
            media={media}
            style={{ height: 'fit-content', maxWidth: '100%' }}
            allowPinMedia={true}
            allowValidateMedia={true}
          />
        )
      })}
    </div>
  )
}

const pipeInst: Pipe<{ take: Take }, typeof DisplayTakeValidationMediasComponent> = pipe()
export const DisplayTakeValidationMedias: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { take } = props
    return { medias: getResources(state, 'medias', take.validationMedias) }
  })
  .render(DisplayTakeValidationMediasComponent)
