// @flow

import React from 'react'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type { DefaultCellProps, Column, Cell, CellInstance } from 'app/components/Table/types.js'

import classes from './CellSelectRow.module.scss'

export type CellSelectRowParams = {|
  ...DefaultCellProps,
  getSelectableCells?: (
    instance: CellInstance,
    event: SyntheticMouseEvent<>,
  ) => $Exact<{
    selectedCells: { [cellId: string]: Cell },
    lastSelectedCell: ?Cell,
  }>,
|}

export const CellSelectRow = ({ getSelectableCells, ...data }: CellSelectRowParams): Column => ({
  cellType: 'CellSelectRow',
  tabIndex: '-1',
  Cell: (instance) => {
    const { updateSelection } = instance

    function onClick(event: SyntheticMouseEvent<>) {
      if (!getSelectableCells) return
      const { selectedCells, lastSelectedCell } = getSelectableCells(instance, event)
      if (lastSelectedCell) lastSelectedCell.focusCell()
      updateSelection(lastSelectedCell, selectedCells)
    }

    return (
      <div
        tabIndex="-1"
        className={classes.checkbox}
        onClick={getSelectableCells ? onClick : undefined}
        onContextMenu={getSelectableCells ? onClick : undefined}
      >
        <FontIcon icon="fas-long-arrow-alt-right" />
      </div>
    )
  },
  width: 50,
  ...data,
})
