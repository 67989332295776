import api from 'app/core/api/api.js'

const findMediaId = (content) => {
  const re = /<.*?data-mediauuid="(.*?)".*?>/gi
  let match
  const ids = []
  while ((match = re.exec(content)) !== null) {
    ids.push(match[1])
  }
  return ids
}

const findMediaURLOldVersion = (content) => {
  const re = /<[a-z].*?href="(http:\/\/(dev.)?api.ovm.io\/up-medias\/uploads\/.*?)".*?>/gi
  let match
  const urls = []
  while ((match = re.exec(content)) !== null) {
    if (match[0].indexOf('data-mediauuid') === -1) {
      urls.push(match[1])
    }
  }
  return urls
}

export default (nextText, prevText) => {
  const nextIds = findMediaId(nextText)
  const prevIds = findMediaId(prevText)

  const nextURLsOldVersion = findMediaURLOldVersion(nextText)
  const prevURLsOldVersion = findMediaURLOldVersion(prevText)

  // support old version
  const mediaIdToDelete = prevIds.filter((prevId) => !nextIds.find((nextId) => nextId === prevId))
  const mediaURLToDeleteOldVersion = prevURLsOldVersion.filter(
    (prevId) => !nextURLsOldVersion.find((nextId) => nextId === prevId),
  )

  return Promise.all(mediaIdToDelete.map((id) => api.medias.delete(id)))
    .then(() => ({
      mediasId: nextIds,
      mediasIdDeleted: mediaIdToDelete,
      mediaURLToDeleteOldVersion,
    }))
    .catch((err) => ({
      mediasId: nextIds,
      mediasIdDeleted: mediaIdToDelete,
      mediaURLToDeleteOldVersion,
    }))
}
