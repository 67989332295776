/** @flow */
import React from 'react'
import type { TableInstance } from '../../types'
import { DisplayDefault } from './DisplayDefault.jsx'
import { Container } from './Container.jsx'

type ResourceType = string
type Props = {|
  instance: { ...TableInstance, stats: Object },
  resource: ResourceType,
  fields?: Array<string>,
  dontRound?: boolean,
  updateFields?: (item: any) => any,
|}

export function CellStatsDefault(props: Props): React$Node {
  const { instance, resource, fields, dontRound, updateFields } = props

  if (!instance) return null

  const { stats, tableId } = instance

  if (!stats || !stats.default || !stats.default[resource]) return null

  return (
    <Container>
      <DisplayDefault
        tableId={tableId}
        stats={stats.default[resource]}
        fields={fields}
        dontRound={dontRound}
        updateFields={updateFields}
      />
    </Container>
  )
}
