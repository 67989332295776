// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-OrganizationChart-GlobalPage-___SkeletonGlobale-module__cardContainer {
  height: calc(100vh - 35rem);
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding: 10px;
  flex-wrap: wrap;
  width: 90%;
  margin: 20px auto;
}

.app-components-OrganizationChart-GlobalPage-___SkeletonGlobale-module__cards {
  min-width: 12.5rem;
  margin: 1rem;
  min-height: 13.13rem;
  height: 13.13rem;
  transition: all 0.1s;
}

.app-components-OrganizationChart-GlobalPage-___SkeletonGlobale-module__media {
  margin: 0.63rem auto;
  border: 1px solid grey;
}

.app-components-OrganizationChart-GlobalPage-___SkeletonGlobale-module__embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  max-width: 13.75rem;
  padding-left: var(--slide-spacing);
  position: relative;
  margin: 1rem;
}
`, "",{"version":3,"sources":["webpack://./app/components/OrganizationChart/GlobalPage/SkeletonGlobale.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,YAAY;EACZ,mBAAmB;EACnB,kCAAkC;EAClC,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".cardContainer {\n  height: calc(100vh - 35rem);\n  display: flex;\n  justify-content: center;\n  overflow-y: auto;\n  padding: 10px;\n  flex-wrap: wrap;\n  width: 90%;\n  margin: 20px auto;\n}\n\n.cards {\n  min-width: 12.5rem;\n  margin: 1rem;\n  min-height: 13.13rem;\n  height: 13.13rem;\n  transition: all 0.1s;\n}\n\n.media {\n  margin: 0.63rem auto;\n  border: 1px solid grey;\n}\n\n.embla__slide {\n  flex: 0 0 var(--slide-size);\n  min-width: 0;\n  max-width: 13.75rem;\n  padding-left: var(--slide-spacing);\n  position: relative;\n  margin: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `app-components-OrganizationChart-GlobalPage-___SkeletonGlobale-module__cardContainer`,
	"cards": `app-components-OrganizationChart-GlobalPage-___SkeletonGlobale-module__cards`,
	"media": `app-components-OrganizationChart-GlobalPage-___SkeletonGlobale-module__media`,
	"embla__slide": `app-components-OrganizationChart-GlobalPage-___SkeletonGlobale-module__embla__slide`
};
export default ___CSS_LOADER_EXPORT___;
