/** @flow */
import React, { useState, useRef, forwardRef, useEffect, FocusEvent, KeyboardEvent, ReactElement } from 'react'
import moment from 'moment'
import cx from 'classnames'
import vars from 'app/styles/vars.js'
import { targetIsChild } from 'app/libs/helpers/dom'
import { Input } from 'app/components/Form'
import type { SmartDate as SmartDateType, ElementProps } from 'app/core/types'
import type { SmartDate as TSSmartDateType, ElementProps as TSElementsProps } from 'app/core/types/types'
import 'moment-business-days'

import classes from './SmartDate.module.scss'

moment.updateLocale('fr', {
  workingWeekdays: [1, 2, 3, 4, 5],
})

function getSmartDateBackgroundColor(
  currentExpectedDate: ?moment$Moment,
  initialExpectedDate: moment$Moment,
  realDate: ?moment$Moment,
  maxDate: ?moment,
  now: moment$Moment,
  isDuringWeek: boolean,
): string | void {
  if (maxDate?.isBefore(now, 'day') && !isDuringWeek) return '#7f7f7f'
  if (maxDate?.isSame(now, 'day')) return vars.colors.blue
  if (isDuringWeek) {
    return vars.colors.orange
  }

  return undefined
}

export const calculateSmartDate = (
  smartDate: SmartDateType,
): { style: Object, date: ?string, subComponent: ?string, diff: string | null } | null => {
  if (!smartDate) return null
  const initialExpectedDate = moment(smartDate.initialExpectedDate)
  const currentExpectedDate = smartDate?.currentExpectedDate ? moment(smartDate.currentExpectedDate) : null
  const realDate = smartDate?.realDate ? moment(smartDate.realDate) : null

  const diffNumber =
    currentExpectedDate && realDate
      ? currentExpectedDate.businessDiff(realDate, 'days')
      : realDate
      ? initialExpectedDate.businessDiff(realDate, 'days')
      : null

  const diff = diffNumber
    ? `${diffNumber < 0 ? '+' : ''}${Math.abs(diffNumber)}${diffNumber < 0 ? '' : ' days left'}`
    : null

  const dates = [initialExpectedDate, currentExpectedDate, realDate].filter((_) => _)

  if (!dates.length) return null

  const style = {
    fontWeight: undefined,
    color: undefined,
    backgroundColor: undefined,
  }
  let subComponent = null

  const now = moment()
  const firstDayOfWeek = moment(now).startOf('week')
  const lastDayOfWeek = moment(now).endOf('week')

  const maxDate: ?moment = moment.max(...dates.filter(Boolean))
  const maxExpectedDate = moment.max(
    ...[
      initialExpectedDate ? moment(initialExpectedDate) : null,
      currentExpectedDate ? moment(currentExpectedDate) : null,
    ].filter(Boolean),
  )

  const isDuringWeek = Boolean(
    maxDate?.isSameOrAfter(firstDayOfWeek, 'day') && maxDate?.isSameOrBefore(lastDayOfWeek, 'day'),
  )

  if (maxDate) {
    if (realDate && maxDate.isSameOrAfter(realDate, 'day')) {
      if (realDate.isSameOrBefore(maxExpectedDate, 'day')) style.color = vars.colors.green
      if (maxDate.isAfter(maxExpectedDate, 'day')) {
        style.color = vars.colors.red
        subComponent = maxExpectedDate.format('DD/MM/YYYY')
      }

      style.fontWeight = 'bold'
    }

    if (!realDate && maxDate) {
      if (isDuringWeek && !maxDate?.isSame(now, 'day')) style.color = '#ffffff'
      else if (initialExpectedDate.isSameOrBefore(moment())) style.color = vars.colors.orange
    }

    style.backgroundColor = getSmartDateBackgroundColor(
      currentExpectedDate,
      initialExpectedDate,
      realDate,
      maxDate,
      now,
      isDuringWeek,
    )
  }

  let date

  if (typeof diffNumber === 'number' && diffNumber >= 0) date = realDate?.format('DD/MM/YYYY')
  else date = maxDate?.format('DD/MM/YYYY') || null

  return {
    diff,
    style,
    date,
    subComponent,
  }
}

export type SmartDateProps = {|
  ...ElementProps,
  value: SmartDateType,
|}

export function SmartDateRead(props: SmartDateProps): React$Node {
  const { value, className, style: propsStyle, ...rest } = props

  const smartDate = calculateSmartDate(value)

  if (!smartDate) return null

  const { style, date, subComponent, diff } = smartDate

  if (!date) return null

  return (
    <div className={cx(classes.container, className)} style={{ ...style, ...propsStyle }} {...rest}>
      {diff ? <div className={classes.diff}>{diff}</div> : null}
      <div className={classes.date}>{date}</div>
      {subComponent ? <div className={classes.subComponent}>({subComponent})</div> : null}
    </div>
  )
}

export interface TSSmartDateInputProps extends TSElementsProps {
  value: TSSmartDateType;
  onChange?: (smartDate: TSSmartDateType) => void;
  onBlur?: (smartDate: TSSmartDateType, event: FocusEvent) => void;
  onEnter?: (smartDate: TSSmartDateType, event: KeyboardEvent<EventTarget>) => void;
  onCancel?: () => void;
  elementRef?: ReactElement<any>;
  autoFocus?: boolean;
}

export type SmartDateInputProps = {|
  ...ElementProps,
  value: SmartDateType,
  onChange?: (smartDate: ?SmartDateType) => void,
  onBlur?: (smartDate: ?SmartDateType, event: SyntheticFocusEvent<>) => void,
  onEnter?: (smartDate: ?SmartDateType, event: SyntheticKeyboardEvent<EventTarget>) => void,
  onCancel?: Function,
  elementRef?: React$ElementRef<any>,
  autoFocus?: boolean,
|}

function SmartDateInputComponent(props: SmartDateInputProps) {
  const {
    value,
    className,
    onBlur: propsOnBlur,
    onChange: propsOnChange,
    onCancel,
    onEnter,
    elementRef,
    autoFocus,
    ...rest
  } = props

  const [initialExpectedDate, setInitialExpectedDate] = useState(value?.initialExpectedDate || '')
  const [currentExpectedDate, setCurrentExpectedDate] = useState(value?.currentExpectedDate || '')
  const [realDate, setRealDate] = useState(value?.realDate || '')

  const [showErrors, setShowErrors] = useState(false)

  const containerRef = useRef()
  const initialExpectedDateRef = useRef()
  const currentExpectedDateRef = useRef()
  const realDateRef = useRef()

  const exitOnCancel = useRef(false)

  function getValue() {
    if (!initialExpectedDate) return null
    if (initialExpectedDate || currentExpectedDate || realDate) {
      return {
        initialExpectedDate: initialExpectedDate || null,
        currentExpectedDate: currentExpectedDate || null,
        realDate: realDate || null,
      }
    }
    return null
  }

  function onBlur(event: SyntheticFocusEvent<>) {
    if (!targetIsChild(event)) {
      // If errors are found
      if (!initialExpectedDate && !showErrors) {
        setShowErrors(true)
        return
      }

      if (exitOnCancel.current) {
        exitOnCancel.current = false
        return
      }

      const value = getValue()
      if (propsOnBlur) propsOnBlur(value, event)
      if (propsOnChange) propsOnChange(value)
    }
  }

  function onFocus(event: SyntheticFocusEvent<>) {
    // $FlowFixMe
    if (!targetIsChild(event, event.target)) initialExpectedDateRef.current?.focus()
  }

  function onKeyDown(event: SyntheticKeyboardEvent<EventTarget>) {
    switch (event.key) {
      case 'Escape':
        exitOnCancel.current = true
        if (onCancel) onCancel()
        break
      case 'Enter': {
        if (!initialExpectedDate && !showErrors) {
          setShowErrors(true)
          break
        }
        const newValue = getValue()
        if (onEnter) onEnter(newValue, event)
        if (propsOnChange) propsOnChange(newValue)
        break
      }
      default:
        break
    }
  }

  const firstFocused = useRef(false)
  useEffect(() => {
    if (autoFocus && !firstFocused.current) {
      firstFocused.current = true
      initialExpectedDateRef.current?.focus()
    }
  }, [!!initialExpectedDateRef.current, autoFocus])

  useEffect(() => {
    setInitialExpectedDate(value?.initialExpectedDate || '')
    setCurrentExpectedDate(value?.currentExpectedDate || '')
    setRealDate(value?.realDate || '')
  }, [value])

  useEffect(() => {
    if (showErrors) initialExpectedDateRef.current?.focus?.()
  }, [showErrors])

  return (
    <div
      className={cx(classes.inputContainer, className)}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      tabIndex="0"
      ref={(ref) => {
        if (elementRef) elementRef.current = ref
        containerRef.current = ref
      }}
      {...rest}
    >
      <label>Initial expected date *</label>
      <Input
        className={classes.input}
        type="date"
        value={initialExpectedDate}
        onChange={(e) => setInitialExpectedDate(e.target.value)}
        inputRef={initialExpectedDateRef}
        dataCy="initialExpectedDate"
      />
      {showErrors ? <div className={classes.error}>This field is required</div> : null}

      <label>Current expected date</label>
      <Input
        className={classes.input}
        type="date"
        value={currentExpectedDate}
        onChange={(e) => setCurrentExpectedDate(e.target.value)}
        inputRef={currentExpectedDateRef}
        dataCy="currentExpectedDate"
      />
      <label>Real date</label>
      <Input
        className={classes.input}
        type="date"
        value={realDate}
        onChange={(e) => setRealDate(e.target.value)}
        inputRef={realDateRef}
        dataCy="realDate"
      />
    </div>
  )
}

export const SmartDateInput: React$AbstractComponent<*, *> = forwardRef<*, *>((props, ref) => (
  <SmartDateInputComponent elementRef={ref} {...props} />
))
