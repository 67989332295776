// @flow
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { map, filter } from 'lodash'
import cx from 'classnames'
import resources from 'app/store/resources'
import { VirtualizedTree, type VirtualizedTreeProps } from 'app/components/Tree/VirtualizedTree.jsx'
import { type Node } from 'app/components/Tree/TreeRow.jsx'
import { userHasPermissions } from 'app/core/permissions'
import { MUIButton } from 'app/components/Form'
import { nonBreakingDash } from 'app/libs/helpers'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import { ModalEditAsset } from 'app/containers/Assets/ModalEditAsset/ModalEditAsset.jsx'
import { ModalTrigger, openModal } from 'app/components/Modal'
import { Permission, permission } from 'app/containers/Permissions'
import type { Asset, ID, AssetTypes, IconType, ResourcesList } from 'app/core/types'
import history from 'app/main/routerHistory'
import { router } from 'app/containers/Assets/AssetDetail/router'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'

import classes from './AssetsTree.module.scss'

export type Props = {|
  ...$Rest<VirtualizedTreeProps, { items: Array<any> }>,
  assetTypes?: Array<AssetTypes>,
  searchInput?: React$Element<any>,
  renderLabel?: (asset: Object) => React$Element<any>,
  renderIcon?: (asset: Object) => IconType,
  showArrow?: (asset: Object) => React$Element<any>,
  filterAssets?: (assets: ResourcesList<Object>) => Array<Object>,
  assetTypesCreationAllowed?: Array<AssetTypes>,
  projectId?: ?ID,
  rootAssetId?: ?ID,
  currentAssetId?: ?ID,
  onClickItem?: Function,
  disbaleContextMenu?: boolean,
  className?: string,
  style?: Object,
  allowCreate?: boolean,
  showRoot?: boolean,
|}

export default function (props: Props): React$Node {
  const {
    renderLabel = (asset) => asset.name,
    renderIcon = (asset) => assetIcons(asset.assetType),
    showArrow = (asset) => ['pc', 'lc', 'mo', 'sh', 'sq', 'gp', 'us', 'mi'].indexOf(asset.assetType) === -1,
    projectId: _projectId,
    disbaleContextMenu,
    currentAssetId,
    showRoot,
    rootAssetId,
    className,
    style,
    onClickItem,
    onOpenChild,
    assetTypesCreationAllowed,
    searchInput,
    filterAssets,
    assetTypes,
    allowCreate,
    expandedLevel,
    resizable,
  } = props

  const assets: ResourcesList<Asset> = useSelector((state) => state.assets.resources)
  const rootAsset: ?Asset = useSelector((state) => (showRoot ? state.assets.resources[rootAssetId] : null))
  const projectId: ID = useSelector((state) => _projectId || state.project?.id)

  const [loader, setLoader] = useState<boolean>(true)

  useEffect(() => {
    let fetchRootAssets
    let fetchAttributes

    if (!rootAssetId) {
      if (currentAssetId) fetchRootAssets = resources.assets.fetchWithFullPath(currentAssetId)
      else fetchRootAssets = resources.assets.fetchRoot()
    } else if (currentAssetId) fetchRootAssets = resources.assets.fetchWithFullPath(currentAssetId, rootAssetId)
    else fetchRootAssets = resources.assets.fetchChilds(rootAssetId)

    if (allowCreate && userHasPermissions({ 'assets-list': ['create'] }) && props.projectId) {
      fetchAttributes = resources.attributes.fetchByProject(props.projectId)
    }

    Promise.all([fetchRootAssets, fetchAttributes]).then(() => {
      if (setLoader) setLoader(false)
    })
  }, [rootAssetId, currentAssetId, allowCreate])

  function getContextMenuItems(asset: Asset) {
    if (disbaleContextMenu) return undefined

    const menuItems = []

    if (permission(['projet_assets__update'])) {
      menuItems.push({
        label: 'Edit',
        onClick: () => openModal(<ModalEditAsset projectId={asset.project} assetId={asset.id} />),
        cy: 'editAsset',
      })
    }
    if (permission(['projet_assets__create'])) {
      menuItems.push({
        label: 'New',
        onClick: () => openModal(<ModalEditAsset projectId={asset.project} parentId={asset.parent} />),
        cy: 'newAsset',
      })
    }
    if (permission(['projet_assets__update', 'projet_assets__create'], 'or')) {
      menuItems.push({ separator: true })
    }

    menuItems.push(
      {
        label: 'Show',
        onClick: () => router.goTo('index', { assetId: asset.id }, { rightPanel: true }),
        cy: 'showAsset',
      },
      { label: 'Wiki', onClick: () => history.push(`/assets-wiki/${asset.id}`), cy: 'wiki' },
    )

    return menuItems
  }

  function prepareItem(asset: Asset): $ElementType<Node, 'data'> {
    const { name, parent, id, internalStatus } = asset

    const label = renderLabel?.({ ...asset, name: nonBreakingDash(name) })
    const icon = renderIcon?.({ ...asset, name: nonBreakingDash(name) })
    const displayArrow = showArrow?.({ ...asset, name: nonBreakingDash(name) })

    return {
      label,
      icon,
      displayArrow,
      id,
      parentId: parent,
      removed: internalStatus === 2,
      metadata: asset,
      contextMenuItems: getContextMenuItems(asset),
      isRoot: showRoot ? id === rootAsset?.id : parent === rootAsset?.id,
    }
  }

  const items = useMemo(() => {
    let assetsFiltered = assets || {}

    if (filterAssets) assetsFiltered = filterAssets(assets)
    if (assetTypes) assetsFiltered = filter(assetsFiltered, (asset) => assetTypes.includes(asset.assetType))

    if (showRoot && rootAsset) {
      assetsFiltered = filter(assetsFiltered, (a) => a.parent !== rootAsset.parent)

      if (Array.isArray(assetsFiltered)) assetsFiltered.push(rootAsset)
      else assetsFiltered[rootAsset.id] = rootAsset
    }

    const items = map(assetsFiltered, prepareItem)

    return items
  }, [assets, filterAssets, assetTypes, showRoot, rootAsset])

  function onClickAsync(item) {
    return resources.assets.fetchChilds(item.id)
  }

  return (
    <div className={cx([className, classes.container])} style={style}>
      <div className="flex row alignCenter spaceBetween fullWidth marginBottom10" style={{ maxHeight: 40 }}>
        {searchInput}
        <Permission actions={['projet_assets__create']}>
          <ModalTrigger modal={<ModalEditAsset projectId={projectId} assetTypes={assetTypesCreationAllowed} />}>
            <MUIButton icon="fas-plus" color="primary" tooltip="Create asset" dataCy="create-asset" />
          </ModalTrigger>
        </Permission>
      </div>
      {loader ? (
        <div className="fullWidth fullHeight flex center alignCenter">
          <LoaderSmall />
        </div>
      ) : (
        <div style={{ height: 'calc(100% - 41px)', width: '100%' }}>
          <VirtualizedTree
            items={items}
            rootItemId={showRoot && rootAsset ? rootAsset.parent : rootAssetId}
            currentItemId={currentAssetId}
            async={onClickAsync}
            onClickItem={onClickItem}
            onOpenChild={onOpenChild}
            expandedLevel={expandedLevel}
            resizable={resizable}
          />
        </div>
      )}
    </div>
  )
}
