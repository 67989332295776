/** @flow */
import React, { useRef, useState, useMemo, useLayoutEffect, useEffect } from 'react'
import cx from 'classnames'
import moment from 'moment'
import keyCode from 'app/libs/keyCode'
import classesTooltip from './Tooltips.module.scss'

import type { CellInstance } from '../../types'

import classes from './TimelineMaxResizer.module.scss'

type Params = {|
  instance: CellInstance,
  date: ?moment,
  setIsUnderEditing?: (isUnderEditing: boolean) => void,
  onUpdate: (date: ?moment) => Promise<any>,
  onPositionChange?: (left: number) => void,
  onFocus?: (e: SyntheticFocusEvent<HTMLElement>) => void,
  onBlur?: (e: SyntheticFocusEvent<HTMLElement>) => void,
  style?: Object,
  className?: string,
  tooltip?: string,
  position?: 'min' | 'max',
  color?: ?string,
  disabled?: boolean,
|}

export function TimelineMaxResizer(params: Params): React$Node {
  const {
    instance,
    date,
    onUpdate,
    onPositionChange,
    style,
    className,
    tooltip,
    position,
    color,
    onFocus,
    onBlur,
    disabled,
  } = params
  const { dimensions, ganttProperties } = instance
  const { ganttStart } = ganttProperties || {}
  const { dayWidth = 0 } = dimensions || {}

  const rangeDimensions = useRef({ originX: 0, vector: 0 })
  const [left, setPositionChange] = useState(0)

  const initialLeft = useMemo(() => {
    const daysDiff = date?.diff(ganttStart, 'days') || 0
    let left = daysDiff * dayWidth

    if (position === 'max') {
      left += dayWidth - 3
    }
    return left
  }, [date, ganttStart, dayWidth, position])

  useLayoutEffect(() => {
    setPositionChange(0)
  }, [date])

  useEffect(() => {
    onPositionChange?.(left + initialLeft)
  }, [left, initialLeft])

  function resetRangeDimensions() {
    rangeDimensions.current = { originX: 0, vector: 0 }
  }

  function getDateFromPosition(left: number) {
    const daysLeft = Math.round(left / dayWidth)
    return moment(date).add(daysLeft, 'days')
  }

  function changeDomPosition(leftDiff) {
    setPositionChange(leftDiff)
  }

  async function onSave(left: number) {
    resetRangeDimensions()
    if (Math.abs(left) < dayWidth / 2) left = 0
    const date = getDateFromPosition(left)
    return onUpdate(date).catch(() => changeDomPosition(0))
  }

  function move(e: MouseEvent) {
    const { originX } = rangeDimensions.current
    const vector = e.clientX - originX
    rangeDimensions.current.vector = vector
    changeDomPosition(vector)
  }

  function stopMove(e: MouseEvent) {
    window.removeEventListener('mousemove', move)
    window.removeEventListener('mouseup', stopMove)
    const { vector } = rangeDimensions.current
    if (vector === 0) {
      resetRangeDimensions()
      return
    }
    onSave(vector)
  }

  function cancel(e: KeyboardEvent) {
    if (e.keyCode === keyCode.ESCAPE) {
      resetRangeDimensions()
      changeDomPosition(0)
      window.removeEventListener('mousemove', move)
      window.removeEventListener('mouseup', stopMove)
      window.removeEventListener('keydown', cancel)
    }
  }

  function onMouseDown(e: SyntheticMouseEvent<any>) {
    e.stopPropagation()

    if (e.button !== 0) return

    resetRangeDimensions()
    rangeDimensions.current.originX = e.clientX

    window.addEventListener('mousemove', move)
    window.addEventListener('mouseup', stopMove)
    window.addEventListener('keydown', cancel)
  }

  return (
    <div
      className={cx(classes.container, className, { [classes.disabled]: disabled })}
      style={{ ...style, left: initialLeft + left, backgroundColor: color }}
      onMouseDown={disabled ? undefined : onMouseDown}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex="0"
    >
      <span className={classesTooltip.tooltip} position={position === 'min' ? 'bottom' : 'top'}>{`${getDateFromPosition(
        left,
      )?.format('dd DD/MM/YYYY')}${tooltip || ''}`}</span>
      <div className={classes.button}>
        <div
          className={classes.upperTriangle}
          style={{
            borderColor:
              color &&
              (position === 'min'
                ? `${color} transparent transparent transparent`
                : `transparent ${color} transparent transparent`),
          }}
          position={position}
        />
        <div
          className={classes.downerTriangle}
          style={{
            borderColor:
              color &&
              (position === 'min'
                ? `transparent transparent transparent ${color}`
                : `transparent transparent ${color} transparent`),
          }}
          position={position}
        />
      </div>
    </div>
  )
}
