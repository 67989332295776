/** @flow */
import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import moment from 'moment'

import { MyHours } from 'app/pages/MyHours/MyHours.tsx'
import { MyAllotmentHours } from 'app/pages/MyHours/MyAllotmentHours.tsx'
import Notifications from 'app/pages/Notifications/Notifications.jsx'
import Projects from 'app/pages/Project/Projects.jsx'
import Project from 'app/pages/Project'
import Admin from 'app/pages/Admin/Admin.jsx'
import { NotFound } from 'app/components/NotFound/NotFound.jsx'
import AssetDetail from 'app/containers/Assets/AssetDetail/AssetDetailPage.tsx'
import WikiFallback from 'app/pages/AssetsWiki/WikiFallback.jsx'
import { Wiki } from 'app/pages/AssetsWiki/AssetsWiki.jsx'
import { MyTasks } from 'app/pages/MyTasks/MyTasks.jsx'
import HR from 'app/pages/HR'
import OrganizationChart from 'app/pages/OrganizationChart/OrganizationChart.tsx'
import OrganizationChartDetail from 'app/pages/OrganizationChart/OrganizationChartDetail.tsx'
import { userPrefLocalResource } from 'app/core/utils/localUserPrefs.js'
import Errors from 'app/components/Error/Error.jsx'
import TestErrors from './TestErrors.jsx'

export default function (): React$Element<any> {
  const hoursFormatView = userPrefLocalResource.getData('hoursFormatView') || 'month'

  return (
    <Errors>
      <Switch>
        <Route exact={true} path="/projects" component={Projects} />
        <Route path="/projects/:projectId" component={Project} />
        <Route path="/wiki/:articleId?" component={WikiFallback} />
        <Route path="/assets-wiki/:assetId?/:articleId?/:articleVersion?" component={Wiki} />
        <Route exact={true} path="/assets" component={AssetDetail} />
        <Route exact={true} path="/testErrors" component={TestErrors} />
        <Route
          exact={true}
          path="/my-hours"
          render={({ match }) => (
            <Redirect
              to={`${match.url}/${hoursFormatView}/${moment().format(
                hoursFormatView === 'month' ? 'MM-YYYY' : 'w-YYYY',
              )}`}
            />
          )}
        />
        <Route path="/my-hours/:formatView/:selectedWeek" component={MyHours} />
        <Route
          exact={true}
          path="/my-allotment-hours"
          render={({ match }) => (
            <Redirect
              to={`${match.url}/${hoursFormatView}/${moment().format(
                hoursFormatView === 'month' ? 'MM-YYYY' : 'w-YYYY',
              )}`}
            />
          )}
        />
        <Route path="/my-allotment-hours/:formatView/:selectedWeek" component={MyAllotmentHours} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/admin" component={Admin} />
        <Route exact={true} path="/" render={() => <Redirect to="/projects" />} />
        <Route path="/my-tasks" component={MyTasks} />
        <Route path="/hr" component={HR} />
        <Route exact={true} path="/organization-chart" component={OrganizationChart} />
        <Route path="/organization-chart/:personId" component={OrganizationChartDetail} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Errors>
  )
}
