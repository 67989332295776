import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Switch } from 'react-router-dom';
import classes from './login.module.scss';
import Login from './Login.jsx';
import Logout from './Logout';
const Layout = (props) => {
    const { match } = props;
    return (_jsx("div", { className: classes.loginLayout, children: _jsxs(Switch, { children: [_jsx(Route, { path: `${match.url}/login`, component: Login }), _jsx(Route, { path: `${match.url}/logout`, component: Logout })] }) }));
};
export default Layout;
