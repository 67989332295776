// @flow
import React, { useMemo } from 'react'
import { map, sortBy, values } from 'lodash'
import { Table } from 'app/components/Table'
import type { ColumnHeader, Cell } from 'app/components/Table'
import { CellText, CellSelect, CellRichText, CellDuration, CellFlags, CellMedias } from 'app/components/Table/Cells'
import type { ResourcesList, ProgressionStatus, ID } from 'app/core/types'
import { optionsProgressionStatus } from 'app/core/utils/optionsProgressionStatus'
import { permission } from 'app/containers/Permissions'
import { createUpdatingFlagsPromises } from 'app/components/Form/Flags/utils'
import { confirmDelete } from 'app/components/Modal'
import resources from 'app/store/resources'
import { accessorTakeMedias, saveTakeMedias } from 'app/components/Table/Cells/CellMedias/utils'
import { getResources } from 'app/store/selectors/getResources'

const tableId = 'tableTakes'

type Props = {|
  project: ?ID,
  taskId: ID,
  toggleButtons?: Object,
  setPaginatedList: any,
  paginatedList: any,
|}

const checkPerms = (cell: Cell, actions: Array<'edit' | 'copy' | 'past' | 'delete' | 'merge'>) => {
  const { original } = cell.row
  const returnedActions = []
  const edit = actions.indexOf('edit') !== -1
  const copy = actions.indexOf('copy') !== -1
  const past = actions.indexOf('past') !== -1 || actions.indexOf('merge') !== -1
  const dlt = actions.indexOf('delete') !== -1

  if (permission(['projet_tasks_takes_update']) && edit) returnedActions.push('edit')
  if (permission(['projet_tasks_takes_update']) && copy) returnedActions.push('copy')
  if (permission(['projet_tasks_takes_update']) && past) returnedActions.push('past')
  if (permission(['projet_tasks_takes_update']) && dlt) returnedActions.push('delete')
  if (permission(['projet_tasks_takes_delete'])) {
    returnedActions.push({
      label: 'Remove take',
      editAction: true,
      onClick: () =>
        confirmDelete({
          render: 'Are you sure you want to remove this take?',
          onValidate: () => resources.takes.delete(original.id),
        }),
    })
  }

  return returnedActions
}

export function TableTakes(props: Props): React$Node {
  const { project, taskId, setPaginatedList, paginatedList, ...rest } = props
  const includedResources = [
    'takeFlagsInst.flagInst',
    'takeRefMediasInst.mediaInst',
    'takeValidationMediasInst.mediaInst',
  ]

  const progressionStatus = useMemo(
    () => getResources<ResourcesList<ProgressionStatus>>(undefined, 'progressionStatus'),
    [],
  )

  const takes = getResources(undefined, `takes.paginatedLists.${paginatedList}`, undefined, includedResources)

  const colums: Array<ColumnHeader> = useMemo(
    () => [
      {
        id: 'takes',
        Header: 'Takes',
        columns: [
          CellText({
            id: 'takeNumber',
            Header: 'Take number',
            accessor: 'number',
            hiddenable: true,
            fixable: true,
            width: 40,
            readOnly: true,
            noFilterOption: true,
            inputProps: { type: 'number' },
          }),
          CellText({
            id: 'creationDate',
            Header: 'Created at',
            readOnly: true,
            inputProps: {
              type: 'datetime',
            },
            accessor: 'createdAt',
            hiddenable: true,
            fixable: true,
            noFilterOption: true,
            actions: () => [],
          }),
          CellText({
            id: 'lastUpdate',
            Header: 'Updated at',
            readOnly: true,
            inputProps: {
              type: 'datetime',
            },
            accessor: 'updatedAt',
            hiddenable: true,
            fixable: true,
            noFilterOption: true,
            actions: () => [],
          }),
          CellSelect({
            id: 'status',
            Header: 'Status',
            readOnly: false,
            accessor: 'status',
            hiddenable: true,
            fixable: true,
            options: (row) => optionsProgressionStatus(progressionStatus),
            noFilterOption: true,
            actions: (instance, cell) => checkPerms(cell, ['edit', 'copy', 'past']),
            save: {
              resource: 'takes',
              attribute: 'status',
            },
          }),
          CellRichText({
            id: 'brief',
            Header: 'Brief',
            readOnly: false,
            accessor: 'comment',
            width: undefined,
            hiddenable: true,
            fixable: true,
            minWidth: 120,
            noFilterOption: true,
            actions: (instance, cell) => checkPerms(cell, ['edit', 'copy', 'past', 'delete']),
            save: {
              resource: 'takes',
              attribute: 'comment',
            },
          }),
          CellDuration({
            id: 'duration',
            Header: 'Duration (estim)',
            readOnly: false,
            accessor: 'estimLength',
            hiddenable: true,
            fixable: true,
            noFilterOption: true,
            actions: (instance, cell) => checkPerms(cell, ['edit', 'copy', 'past', 'delete']),
            save: {
              resource: 'takes',
              attribute: 'estimLength',
            },
          }),
          CellFlags({
            id: 'flags',
            Header: 'Flags',
            readOnly: false,
            accessor: 'takeFlagsInst',
            width: undefined,
            hiddenable: true,
            fixable: true,
            minWidth: 120,
            noFilterOption: true,
            actions: (instance, cell) => checkPerms(cell, ['edit', 'copy', 'past', 'delete']),
            foreignKey: 'take',
            category: 'take',
            save: {
              resource: 'takes',
              formatData: (item, value, cell, instance, type) => {
                if (type === 'delete') value = []

                const newFlags = map(value, ({ flagInst }) => ({ take: item.id, flag: flagInst.id }))
                return createUpdatingFlagsPromises(newFlags, map(item.takeFlagsInst), 'takeFlags', type)
              },
            },
            getResourceID: (cell) => cell.row.original.id,
          }),
          CellMedias({
            id: 'takeRefMedias',
            Header: 'Refs',
            readOnly: false,
            fixable: true,
            hiddenable: true,
            aggregate: (vals) => vals[0],
            noFilterOption: true,
            width: undefined,
            minWidth: 120,
            allowPinMedia: true,
            allowValidateMedia: true,
            accessor: (item) => {
              if (!item) return null
              const { takeRefMediasInst } = item

              return accessorTakeMedias(takeRefMediasInst)
            },
            actions: () => ['edit', 'delete'],
            save: {
              resource: 'takeRefMedias',
              formatData: (item, value, cell, instance, type) =>
                saveTakeMedias(value, 'takeRefMedias', item.id, item.takeRefMediasInst, type, cell.value),
            },
          }),
          CellMedias({
            id: 'takeValidationMedias',
            Header: 'Validation medias',
            readOnly: false,
            fixable: true,
            hiddenable: true,
            aggregate: (vals) => vals[0],
            noFilterOption: true,
            allowPinMedia: true,
            allowValidateMedia: true,
            width: undefined,
            minWidth: 120,
            accessor: (item) => {
              if (!item) return null
              const { takeValidationMediasInst } = item

              return accessorTakeMedias(takeValidationMediasInst)
            },
            actions: () => ['edit', 'delete'],
            save: {
              resource: 'takeValidationMedias',
              formatData: (item, value, cell, instance, type) =>
                saveTakeMedias(value, 'takeValidationMedias', item.id, item.takeValidationMediasInst, type, cell.value),
            },
          }),
        ],
      },
    ],
    [],
  )

  const resourcesParams = useMemo(
    () => ({
      resourceType: 'takes',
      requestName: 'fetchAllByTask',
      headers: project ? { [window.OVM_PROJECT_HEADER]: project || '' } : {},
      includedResources,
      requestData: taskId,
    }),
    [],
  )

  return (
    <Table
      tableId={tableId}
      reduxData={sortBy(values(takes), 'number')}
      resourcesParams={resourcesParams}
      getPaginatedList={setPaginatedList}
      columns={colums}
      rowExpander={true}
      {...rest}
    />
  )
}
