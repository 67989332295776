var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    loading: false,
    error: null,
    data: [],
};
export const fetchCollectiveAgreementTitles = createAsyncThunk('collectiveAgreementTitles/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.collectiveAgreementTitles.fetchAll({
            page_size: 1000,
        });
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const deleteCollectiveAgreementTitle = createAsyncThunk('collectiveAgreementTitles/delete', (id_1, _a) => __awaiter(void 0, [id_1, _a], void 0, function* (id, { fulfillWithValue, rejectWithValue }) {
    try {
        yield api.collectiveAgreementTitles.delete(id);
        return fulfillWithValue(id);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const createCollectiveAgreementTitle = createAsyncThunk('collectiveAgreementTitles/create', (newCollectiveAgreementTitle_1, _a) => __awaiter(void 0, [newCollectiveAgreementTitle_1, _a], void 0, function* (newCollectiveAgreementTitle, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.collectiveAgreementTitles.create(newCollectiveAgreementTitle);
        return fulfillWithValue(response);
    }
    catch (err) { }
}));
export const updateCollectiveAgreementTitle = createAsyncThunk('collectiveAgreementTitles/update', (newCollectiveAgreementTitle_1, _a) => __awaiter(void 0, [newCollectiveAgreementTitle_1, _a], void 0, function* (newCollectiveAgreementTitle, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.collectiveAgreementTitles.update(newCollectiveAgreementTitle);
        return fulfillWithValue(response);
    }
    catch (err) { }
}));
const { reducer } = createSlice({
    name: 'collectiveAgreementTitles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // FETCH COLLECTIVE AGREEMENT TITLES
        builder.addCase(fetchCollectiveAgreementTitles.pending, (state, action) => {
            state.loading = true;
        }),
            builder.addCase(fetchCollectiveAgreementTitles.fulfilled, (state, action) => {
                state.data = action.payload.results;
                state.loading = false;
            }),
            builder.addCase(fetchCollectiveAgreementTitles.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            }),
            // DELETE COLLECTIVE AGREEMENT TITLE
            builder.addCase(deleteCollectiveAgreementTitle.pending, (state, action) => { }),
            builder.addCase(deleteCollectiveAgreementTitle.fulfilled, (state, { payload }) => {
                const newData = state.data.filter((ej) => ej.id !== payload);
                state.data = newData;
            }),
            builder.addCase(deleteCollectiveAgreementTitle.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            }),
            // CREATE COLLECTIVE AGREEMENT TITLE
            builder.addCase(createCollectiveAgreementTitle.pending, (state, action) => { }),
            builder.addCase(createCollectiveAgreementTitle.fulfilled, (state, { payload }) => {
                state.data = [...state.data, payload];
            }),
            builder.addCase(createCollectiveAgreementTitle.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            }),
            // UPDATE COLLECTIVE AGREEMENT TITLE
            builder.addCase(updateCollectiveAgreementTitle.pending, (state, action) => { }),
            builder.addCase(updateCollectiveAgreementTitle.fulfilled, (state, { payload }) => {
                let collectiveAgreementTitleIndex = state.data.findIndex((ca) => ca.id === payload.id);
                if (collectiveAgreementTitleIndex) {
                    state.data[collectiveAgreementTitleIndex] = payload;
                }
            }),
            builder.addCase(updateCollectiveAgreementTitle.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            });
    },
});
export default reducer;
