// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Project-___Project-module__projectName {
  color: #ffffff;
  text-transform: uppercase;
  border-top: solid 1px #ccc;
  font-weight: bold;
  z-index: 2;
  position: relative;
  line-height: 25px;
  font-size: 17px;
  text-align: center;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-pages-Project-___Project-module__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./app/pages/Project/Project.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,yBAAyB;EACzB,0BAA0B;EAC1B,iBAAiB;EACjB,UAAU;EACV,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,MAAM;EACN,mCAAmC;EACnC,UAAU;AACZ","sourcesContent":[".projectName {\n  color: #ffffff;\n  text-transform: uppercase;\n  border-top: solid 1px #ccc;\n  font-weight: bold;\n  z-index: 2;\n  position: relative;\n  line-height: 25px;\n  font-size: 17px;\n  text-align: center;\n  min-height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.overlay {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  background-color: rgb(0, 0, 0, 0.3);\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectName": `app-pages-Project-___Project-module__projectName`,
	"overlay": `app-pages-Project-___Project-module__overlay`
};
export default ___CSS_LOADER_EXPORT___;
