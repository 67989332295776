// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetDetail-Discussions-___Topic-module__item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  max-width: 300px;
  min-width: 150px;
}

.app-containers-Assets-AssetDetail-Discussions-___Topic-module__item:hover {
  background-color: var(--colors-mainColorLight);
}

.app-containers-Assets-AssetDetail-Discussions-___Topic-module__item:active {
  background-color: var(--colors-mainColorMedium);
}

.app-containers-Assets-AssetDetail-Discussions-___Topic-module__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 26px;
  width: 100%;
}

.app-containers-Assets-AssetDetail-Discussions-___Topic-module__subtext {
  color: lightgrey;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal !important;
}

.app-containers-Assets-AssetDetail-Discussions-___Topic-module__notifUnread {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #f06a54;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetDetail/Discussions/Topic.module.scss"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".item {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n  height: 40px;\n  max-width: 300px;\n  min-width: 150px;\n}\n\n.item:hover {\n  background-color: var(--colors-mainColorLight);\n}\n\n.item:active {\n  background-color: var(--colors-mainColorMedium);\n}\n\n.text {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  line-height: 26px;\n  width: 100%;\n}\n\n.subtext {\n  color: lightgrey;\n  font-size: 12px;\n  line-height: 14px;\n  font-weight: normal !important;\n}\n\n.notifUnread {\n  width: 6px;\n  height: 6px;\n  border-radius: 3px;\n  background-color: #f06a54;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `app-containers-Assets-AssetDetail-Discussions-___Topic-module__item`,
	"text": `app-containers-Assets-AssetDetail-Discussions-___Topic-module__text`,
	"subtext": `app-containers-Assets-AssetDetail-Discussions-___Topic-module__subtext`,
	"notifUnread": `app-containers-Assets-AssetDetail-Discussions-___Topic-module__notifUnread`
};
export default ___CSS_LOADER_EXPORT___;
