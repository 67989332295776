var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import pipe from 'app/core/utils/pipe';
import resources from 'app/store/resources';
import history from 'app/main/routerHistory';
import { AssetsTreeSearch } from 'app/containers/Assets/AssetsTree';
import Widget from 'app/components/Widget/Widget.jsx';
import { RightContentContainer } from './RightContent.jsx';
const FollowUpView = (props) => {
    const { match } = props;
    const { projectId, assetId } = match.params;
    const onClickTreeItem = (item) => {
        const { metadata } = item;
        if (!metadata)
            return;
        history.pushWithParams(match, { assetId: metadata.id }, window.location.search);
    };
    const onSearchAsset = (assetId) => {
        history.pushWithParams(match, { assetId });
    };
    return (_jsxs("div", { style: { display: 'flex', height: '100%', overflow: 'hidden' }, children: [_jsx(Widget, { enableHideColumn: true, collapseTitle: "Asset list", resizable: true, optionName: "widget-followUp", defaultWidth: 300, children: _jsx(AssetsTreeSearch, { assetTypes: ['ep', 'fo', 'mo', 'sh', 'mi'], onClickItem: onClickTreeItem, currentAssetId: assetId, rootAssetId: projectId, onSearchAsset: onSearchAsset, parentSearch: assetId, resizable: true }) }), _jsx("div", { className: "fullHeight fullWidth", children: assetId && _jsx(RightContentContainer, {}) })] }));
};
const pipeInst = pipe();
export const FollowUp = pipeInst
    .withRouter()
    .connect((state, props) => {
    const { projectId } = props.match.params;
    return {
        projectId: state.project ? state.project.id : projectId !== null && projectId !== void 0 ? projectId : '',
    };
})
    .request(({ projectId }) => {
    return Promise.all([
        resources.trackingSchemas.fetchByProject(projectId, {
            params: { queries: { isVisible: true, isExternal: false } },
        }),
        resources.assets.fetchEpisodes(projectId),
        resources.stepProjects.fetchByProject(projectId),
        resources.attributes.fetchByProject(projectId),
        resources.progressionStatus.fetchAll(),
        resources.dynamicApprovals.fetchByProject(projectId),
    ]);
})
    .render((_a) => {
    var { projectId, history, location } = _a, props = __rest(_a, ["projectId", "history", "location"]);
    return _jsx(FollowUpView, Object.assign({ match: props.match }, props));
});
