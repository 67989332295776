/** @flow */
import React, { useState } from 'react'
import { map, filter, sortBy } from 'lodash'
import Widget from 'app/components/Widget/Widget.jsx'
import pipe from 'app/core/utils/pipe'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import { Title } from 'app/components/Texts/Title/Title.jsx'
import resources from 'app/store/resources'
import { AutocompleteSingle } from 'app/components/Form'
import { getResources } from 'app/store/selectors/getResources'
import type { Asset } from 'app/core/types'
import { ProjectView } from './ProjectView.jsx'

type Props = {|
  projects: Array<Asset>,
|}

function ProjectsListComponent(props: Props): React$Node {
  const { projects } = props

  const [results, setResults] = useState(projects)

  function searchInProjects(search) {
    setResults(filter(projects, (project: Asset): boolean => project.name.includes(search)))
    return Promise.resolve()
  }

  return (
    <Widget style={{ padding: 10, width: '100%' }}>
      <div className="flex row noWrap fullWidth spaceBetween">
        <Title size="2" className="marginBottom10">
          Projects list
        </Title>
        <div>
          <AutocompleteSingle
            onSearch={searchInProjects}
            lengthForSearch={0}
            style={{ width: 200 }}
            placeholder="Search in projects"
            searchable={true}
          />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 52px)', overflowY: 'auto' }} className="fullWidth">
        {sortBy(results, ['name']).map((project: Asset, index: number) => (
          <ProjectView key={project.id} projectId={project.id} />
        ))}
      </div>
    </Widget>
  )
}

const pipeInst: Pipe<{}, typeof ProjectsListComponent> = pipe()

export const ProjectsList: React$ComponentType<any> = pipeInst
  .connect((state) => ({
    projects: map(getResources(state, 'assets', { assetType: 'pj' }, ['thumbnailInst'])),
  }))
  .request((props) => {
    return resources.assets.fetchAllProjects()
  })
  .render(ProjectsListComponent)
