// @flow
import store from 'app/store/index.ts'
import type { StoreState, AssetCustomAttributeValue } from 'app/core/types'

export const getAssetsAttributValues = (
  state?: StoreState = store.getState(),
  attribut: string,
): AssetCustomAttributeValue => {
  return state.assetsCustomAttributValues.resources[attribut]
}
