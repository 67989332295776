// @flow
import { map, filter, sortBy } from 'lodash'
import type { ResourcesList, ProgressionStatus } from 'app/core/types'

export const progressionStatusTypes = ['normal', 'new', 'validation', 'close', 'pause', 'retake']

export const optionsProgressionStatus = (
  progressionStatus: ResourcesList<ProgressionStatus> | Array<ProgressionStatus>,
): Array<Object> => {
  return map(
    progressionStatus,
    (status) =>
      status && {
        value: status.id,
        label: status.name,
        backgroundColor: status.color,
      },
  ).filter((_) => _)
}

export const progressionStatusFilter = (
  progressionStatus: ResourcesList<ProgressionStatus> | Array<ProgressionStatus>,
  include: 'with' | 'without',
  types: Array<'normal' | 'new' | 'validation' | 'close' | 'pause' | 'retake'>,
): Array<ProgressionStatus> => {
  const status = types.map((type) => progressionStatusTypes.indexOf(type))

  const orderedProgressionStatus = sortBy(progressionStatus, ['order'])

  if (include === 'with') {
    return filter(orderedProgressionStatus, (ps) => status.indexOf(ps.statusType) !== -1)
  }

  return filter(orderedProgressionStatus, (ps) => status.indexOf(ps.statusType) === -1)
}
