// @flow
import React, { useRef, useState } from 'react'
import { Editor } from 'app/components/TextEditor/Editor.jsx'
import { MUIModal, type ModalProps } from 'app/components/Modal'

export type CellRichTextModalProps = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  modalTitle: React$Node,
  allowEmpty?: boolean,
  value: string,
  onChange: Function,
  open?: boolean,
|}

export function CellRichTextModal(props: CellRichTextModalProps): React$Node {
  const { value = '', modalTitle = 'Text', onChange, allowEmpty = true, open, ...rest } = props
  const originalValue = value

  const content = useRef<string>(value)
  const setContent = (value: string) => {
    content.current = value
  }

  const [disableValidate, setDisableValidate] = useState(true)

  async function onValidate() {
    if (!allowEmpty && !content.current) return Promise.reject()
    return onChange(content.current)
  }

  function _onChange(editorValue: string, editor: Object, event: *) {
    setContent(editorValue)
    if (editorValue !== originalValue) setDisableValidate(false)
    else setDisableValidate(true)
  }

  return (
    <MUIModal
      onValidate={onValidate}
      title={modalTitle}
      resizable={true}
      draggable={true}
      disableEnforceFocus={true}
      open={open}
      disableValidate={disableValidate}
      {...rest}
    >
      <Editor
        style={{ maxHeight: '100%' }}
        className="fullHeight"
        value={value}
        onChange={_onChange}
        onInit={(editorRef) => {
          if (editorRef) editorRef.editing.view.focus()
        }}
      />
    </MUIModal>
  )
}
