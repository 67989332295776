var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    loading: false,
    error: null,
    data: [],
};
export const fetchExternalJobs = createAsyncThunk('externalJobs/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.externalJobs.fetchAll({ page_size: 500 });
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const createExternalJob = createAsyncThunk('externalJobs/create', (name_1, _a) => __awaiter(void 0, [name_1, _a], void 0, function* (name, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.externalJobs.create({ name });
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const updateExternalJob = createAsyncThunk('externalJobs/update', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ name, id }, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.externalJobs.update({ name, id });
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const deleteExternalJob = createAsyncThunk('externalJobs/delete', (id_1, _a) => __awaiter(void 0, [id_1, _a], void 0, function* (id, { fulfillWithValue, rejectWithValue }) {
    try {
        yield api.externalJobs.delete(id);
        return fulfillWithValue(id);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { actions, reducer } = createSlice({
    name: 'externalJobs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // FETCH EXTERNAL JOBS
        builder.addCase(fetchExternalJobs.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(fetchExternalJobs.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.results;
            }),
            builder.addCase(fetchExternalJobs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            });
        // CREATE EXTERNAL JOB
        builder.addCase(createExternalJob.pending, (state, action) => {
            // state.loading = true
        }),
            builder.addCase(createExternalJob.fulfilled, (state, { payload }) => {
                state.data = [...state.data, payload];
            }),
            builder.addCase(createExternalJob.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            });
        // UPDATE EXTERNAL JOB
        builder.addCase(updateExternalJob.pending, () => { }),
            builder.addCase(updateExternalJob.fulfilled, (state, { payload }) => {
                const externalJob = state.data.find((ej) => ej.id === payload.id);
                if (externalJob) {
                    externalJob.name = payload.name;
                }
            }),
            builder.addCase(updateExternalJob.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            });
        // DELETE EXTERNAL JOB
        builder.addCase(deleteExternalJob.pending, () => { }),
            builder.addCase(deleteExternalJob.fulfilled, (state, { payload }) => {
                const newData = state.data.filter((ej) => ej.id !== payload);
                state.data = newData;
            }),
            builder.addCase(deleteExternalJob.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            });
    },
});
export default reducer;
