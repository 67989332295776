// @flow
import * as React from 'react'
import pipe from 'app/core/utils/pipe'
import { type StoreState } from 'app/core/types'
import { getResources } from 'app/store/selectors/getResources'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { assetsTypes } from 'app/core/constants'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import { Permission } from 'app/containers/Permissions'

import type {
  DisplayDynamicApprovalProps,
  DisplayDynamicApprovalConnectProps,
  DisplayDynamicApprovalConnectOutput,
  DisplayDynamicApprovalPipeType,
} from './DynamicApprovalsEditor.type'
import classes from './DynamicApprovalsEditor.module.scss'

function DisplayDynamicApprovalContainer(props: DisplayDynamicApprovalProps) {
  const { dynamicApproval, Modal, steps } = props
  if (!dynamicApproval) return <div className={classes.displayDynamicApprovalContainer} />

  const targetStep = steps.find((step) => step.id === dynamicApproval.targetStep)

  return (
    <div className={classes.displayDynamicApprovalContainer}>
      <span className={classes.dynamicApprovalInfo}>
        <span>Name:</span> <span data-cy="dynapp-info-name">{dynamicApproval.name}</span>
      </span>
      <span className={`${classes.dynamicApprovalInfo} flex row alignCenter`}>
        <span>Asset type:</span>{' '}
        <span data-cy="dynapp-info-assettype" className="flex row alignCenter">
          <FontIcon icon={assetIcons(dynamicApproval.assetType)} className="marginRight5" />
          {assetsTypes[dynamicApproval.assetType]}
        </span>
      </span>
      {targetStep ? (
        <span className={`${classes.dynamicApprovalInfo} flex row alignCenter`}>
          <span>Target step:</span>{' '}
          <span data-cy="dynapp-info-assettype" className="flex row alignCenter">
            {targetStep?.name}
          </span>
        </span>
      ) : null}
      <Permission actions={['projet_follow-up_dynamic approval management_update']}>
        <Modal dynamicApproval={dynamicApproval}>
          <FontIcon
            data-cy="dynapp-info-edit-button"
            className={classes.iconButton}
            icon="fas-edit"
            style={{ padding: 4, position: 'absolute', top: 0, right: 0 }}
          />
        </Modal>
      </Permission>
    </div>
  )
}

export const DisplayDynamicApproval: DisplayDynamicApprovalPipeType = pipe()
  .connect((state: StoreState, props: DisplayDynamicApprovalConnectProps): DisplayDynamicApprovalConnectOutput => {
    const { attrID } = props
    const result = getResources(state, 'dynamicApprovals', { id: attrID })

    return { dynamicApproval: result ? result[props.attrID] : undefined }
  })
  .render(DisplayDynamicApprovalContainer)
