/** @flow */
import store from 'app/store/index.ts'

export const getUserSettings = (key?: string): any => {
  const { user } = store.getState()

  let options = JSON.parse(window.localStorage.getItem(`userSettings-${user?.asset || ''}`))
  if (!options) {
    window.localStorage.setItem(`userSettings-${user?.asset || ''}`, JSON.stringify({}))
    options = {}
  }
  if (!key) return options
  return options[key] || false
}

export const setUserSettings = (key: string, value: any) => {
  const { user } = store.getState()
  const options = getUserSettings()
  const newOptions = { ...options, [key]: value }
  window.localStorage.setItem(`userSettings-${user?.asset || ''}`, JSON.stringify(newOptions))
}
