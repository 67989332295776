// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Drawer-___Drawer-module__overlay {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.app-components-Drawer-___Drawer-module__collapse {
  background-color: #ffffff;
  z-index: 11;
  top: 0px;
  right: 0px;
  position: absolute;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./app/components/Drawer/Drawer.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,QAAQ;EACR,UAAU;EACV,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".overlay {\n  position: fixed;\n  bottom: 0px;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 10;\n}\n\n.collapse {\n  background-color: #ffffff;\n  z-index: 11;\n  top: 0px;\n  right: 0px;\n  position: absolute;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `app-components-Drawer-___Drawer-module__overlay`,
	"collapse": `app-components-Drawer-___Drawer-module__collapse`
};
export default ___CSS_LOADER_EXPORT___;
