// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellSelect-___CellSelect-module__container {
  position: relative;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
}

.app-components-Table-Cells-CellSelect-___CellSelect-module__read {
  min-height: 30px;
  height: 100%;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: break-word;
  text-align: center;
  overflow: hidden;
}

.app-components-Table-Cells-CellSelect-___CellSelect-module__edit {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 3px;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellSelect/CellSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,MAAM;EACN,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".container {\n  position: relative;\n  height: 100%;\n  width: 100%;\n  position: relative;\n  z-index: 1;\n}\n\n.read {\n  min-height: 30px;\n  height: 100%;\n  line-height: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-sizing: border-box;\n  white-space: break-word;\n  text-align: center;\n  overflow: hidden;\n}\n\n.edit {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  padding: 3px;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellSelect-___CellSelect-module__container`,
	"read": `app-components-Table-Cells-CellSelect-___CellSelect-module__read`,
	"edit": `app-components-Table-Cells-CellSelect-___CellSelect-module__edit`
};
export default ___CSS_LOADER_EXPORT___;
