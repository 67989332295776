var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { map } from 'lodash';
import classes from './MainSection.module.scss';
import RoomIcon from '@mui/icons-material/Room';
import { useEffect, useState } from 'react';
import { MUIButton } from 'app/components/Form';
import { createPersonAttributeValue, updatePersonAttributeValue } from 'app/store/reducers/organizationChart';
import { useDispatch, useSelector } from 'react-redux';
import { success } from 'app/components/Notifications/notify';
const MainSection = (props) => {
    const { studio, displayMainSection, personDatas, updateLoading } = props;
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [error, setError] = useState(false);
    const [dataIsSaved, setDataIsSaved] = useState(false);
    const { asset: userId } = useSelector((state) => state.user);
    useEffect(() => {
        if (displayMainSection) {
            setData(displayMainSection.map((section) => (Object.assign({}, section))));
            setNewData(displayMainSection.map((section) => (Object.assign({}, section))));
        }
    }, [displayMainSection]);
    useEffect(() => {
        if (error) {
            setNewData(data.map((section) => (Object.assign({}, section))));
            setError(false);
        }
        if (dataIsSaved) {
            setData(newData.map((section) => (Object.assign({}, section))));
            setDataIsSaved(false);
        }
    }, [error, dataIsSaved]);
    function handleChange(id, value) {
        const dataIndex = newData.findIndex((d) => d.id === id);
        const changedData = [...newData];
        changedData[dataIndex].value = value;
        setNewData(changedData);
    }
    function handleSubmit() {
        newData.map((section, index) => __awaiter(this, void 0, void 0, function* () {
            const selectedData = newData[index];
            const oldData = [displayMainSection.find((leftSection) => leftSection.id === section.id)];
            if (oldData) {
                const dataChanged = oldData.filter((leftSection) => (leftSection === null || leftSection === void 0 ? void 0 : leftSection.value) !== section.value);
                if (dataChanged.length > 0) {
                    try {
                        const id = selectedData === null || selectedData === void 0 ? void 0 : selectedData.attributeId;
                        const data = {
                            id: selectedData === null || selectedData === void 0 ? void 0 : selectedData.attributeId,
                            person: personDatas.id,
                            personAttribute: selectedData.id,
                            value: selectedData.value,
                        };
                        yield dispatch(id ? updatePersonAttributeValue(data) : createPersonAttributeValue(data));
                        success('Saved');
                        setDataIsSaved(true);
                    }
                    catch (error) {
                        console.error(error);
                        setError(true);
                    }
                }
            }
        }));
    }
    return (_jsx("div", { className: classes.mainSection, children: _jsxs("ul", { className: classes.mainList, children: [_jsxs("li", { className: classes.header, children: [_jsxs("div", { className: classes.studio, children: [_jsx("div", { className: classes.mapIcon, children: _jsx(RoomIcon, { className: classes.icon }) }), studio] }), userId !== (personDatas === null || personDatas === void 0 ? void 0 : personDatas.user) && (_jsx(MUIButton, { loader: updateLoading, onClick: () => {
                                if (!isEditing)
                                    setIsEditing(true);
                                if (isEditing) {
                                    handleSubmit();
                                    setIsEditing(false);
                                }
                            }, style: { width: 80 }, children: isEditing ? 'Valider' : 'Editer' }))] }), map(newData, (section) => (_jsx("div", { children: !isEditing ? (_jsx("li", { children: _jsxs("div", { className: classes.listItems, children: [_jsxs("h4", { className: classes.listItemsTitle, children: [section.title, " :"] }), _jsx("div", { dangerouslySetInnerHTML: {
                                        __html: section.value.length ? section.value : '<p>Non renseigné</p>',
                                    } })] }) })) : (_jsxs("div", { children: [_jsxs("h4", { className: classes.listItemsTitle, children: [section.title, " :"] }), section.isEditable ? (_jsx("textarea", { value: section === null || section === void 0 ? void 0 : section.value, onChange: (e) => handleChange(section.id, e.target.value), rows: 5, cols: 50, className: classes.textArea })) : (_jsx("p", { children: section.value.length > 0 ? section.value : 'Non renseigné' }))] })) }, section.id)))] }) }));
};
export default MainSection;
