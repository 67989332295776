/** @flow */
import type { ID } from 'app/core/types'

export default (): ?ID => {
  const pathNameItems = window.location.pathname.split('/')
  const isProject = pathNameItems[1] === 'projects'

  if (!isProject) return undefined

  const projectId = pathNameItems[2]
  return projectId
}
