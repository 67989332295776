// @flow
import * as React from 'react'
import cx from 'classnames'
import { Drag, Drop } from 'app/components/DragNDrop/DragNDrop.js'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'
import defaultClasses from './ModulableTree.module.scss'
import { ModulableTreeActions } from './ModulableTreeActions.jsx'

export type ModulableTreeProps = {|
  label?: string | React.Element<any>,
  title?: string | React.Element<any>,
  parentLabel?: string,
  noExpand?: boolean,
  defaultExpand?: boolean,
  centerContent?: (index: number) => React$Node,
  hideOrigin?: boolean,
  key?: string | number,
  backgroundColor?: string,
  draggableContentDir?: boolean,
  drop?: Function,
  icon?: React.Element<any>,
  noDragIcon?: boolean,
  index?: number,
  onClick?: Function,
  Header?: React.Element<any>,
  DragIcon?: React.Element<any>,
  level?: number,
  items?: Array<Object>,
  compactMenuToLength?: number,
  dragContent?: any,
  type?: string,
  noLevel?: boolean,
  scrollable?: boolean,
  readOnly?: boolean,
  classes?: Object,
  firstItemStyle?: Object,
  noItemsLabel?: React$Node,
  actions?: Array<Object>,
  treeProps?: {|
    draggableContentDir?: boolean,
    centerContent?: (index: number) => React$Node,
    noExpand?: boolean,
    defaultExpand?: boolean,
    noDragIcon?: boolean,
    dropStyle?: Object,
    classes?: Object,
    hideOrigin?: boolean,
    noLevel?: boolean,
    DragIcon?: React.Element<any>,
    compactMenuToLength?: number,
  |},
|}

export type ModulableTreeState = {|
  isExpand: boolean,
  isHover: boolean,
|}

export class ModulableTree extends React.PureComponent<ModulableTreeProps, ModulableTreeState> {
  classes: { [key: string]: string } = {
    ...defaultClasses,
    ...(this.props.treeProps ? this.props.treeProps.classes : {}),
    ...this.props.classes,
  }

  static defaultProps: $Shape<ModulableTreeProps> = {
    level: 0,
  }

  state: ModulableTreeState = {
    isExpand: Boolean(this.props.defaultExpand),
    isHover: false,
  }

  onMouseEnter: Function = (event: Event) => {
    this.setState({ isHover: true })
  }

  onMouseLeave: Function = (event: Event) => {
    this.setState({ isHover: false })
  }

  onDrop: Function = (content: any) => {
    const { drop } = this.props
    if (typeof drop === 'function') {
      drop(content)
    }
  }

  onExpand: Function = () => this.setState((state) => ({ isExpand: !state.isExpand }))

  stopPropagation: Function = (event: Event) => {
    event?.stopPropagation?.()
    return false
  }

  getTreeProps: Function = () => {
    const { draggableContentDir, noExpand, classes, treeProps, hideOrigin, DragIcon, noDragIcon, noLevel } = this.props

    const draggableContentDirTreeProps = treeProps && treeProps.draggableContentDir
    const noExpandTreeProps = treeProps && treeProps.noExpand
    const classesTreeProps = treeProps && treeProps.classes
    const hideOriginTreeProps = treeProps && treeProps.hideOrigin
    const DragIconTreeProps = treeProps && treeProps.DragIcon
    const noDragIconTreeProps = treeProps && treeProps.noDragIcon
    const noLevelTreeProps = treeProps && treeProps.noLevel

    return {
      draggableContentDir: Boolean(
        draggableContentDir === undefined ? draggableContentDirTreeProps : draggableContentDir,
      ),
      noExpand: Boolean(noExpand === undefined ? noExpandTreeProps : noExpand),
      classes: classes === undefined ? classesTreeProps : classes,
      DragIcon: DragIcon === undefined ? DragIconTreeProps : DragIcon,
      hideOrigin: Boolean(hideOrigin === undefined ? hideOriginTreeProps : hideOrigin),
      noDragIcon: Boolean(noDragIcon === undefined ? noDragIconTreeProps : noDragIcon),
      noLevel: Boolean(noLevel === undefined ? noLevelTreeProps : noLevel),
      dropStyle: treeProps && treeProps.dropStyle,
      centerContent: treeProps && treeProps.centerContent,
    }
  }

  isDragAndDropable: Function = (item: *) => {
    const { drop, dragContent, type } = this.props
    const { draggableContentDir, dropStyle, hideOrigin } = this.getTreeProps()

    let dndItem = item

    if (drop) {
      dndItem = (
        <Drop drop={this.onDrop} dropStyle={dropStyle}>
          {dndItem}
        </Drop>
      )
    }
    if (dragContent) {
      dndItem = (
        <Drag content={dragContent} type={type} hideOrigin={hideOrigin}>
          {dndItem}
          {draggableContentDir ? this.renderItems() : null}
        </Drag>
      )
    }
    return dndItem
  }

  renderItemsAsRoot: Function = () => {
    const { level = 0, items, type, treeProps, noItemsLabel, title, Header, firstItemStyle } = this.props
    const treePropsRest = this.getTreeProps()

    return (
      <div className="flex column fullHeight">
        {(title || Header) && (
          <div className={this.classes.headerContainer}>
            {title && <div className={this.classes.title}>{title}</div>}
            {Header && <div>{Header}</div>}
          </div>
        )}
        {items && items.length > 0 ? (
          <div style={{ overflow: 'auto', width: '100%', height: '100%' }}>
            <div className={this.classes.itemsContainer}>
              {items.map((item, index) => (
                <ModulableTree
                  level={level + 1}
                  type={item.type || type}
                  treeProps={treeProps}
                  index={index}
                  firstItemStyle={firstItemStyle}
                  {...treePropsRest}
                  {...item}
                  key={item.key || item.label}
                />
              ))}
            </div>
          </div>
        ) : (
          noItemsLabel && <div style={{ flex: 1, textAlign: 'center', padding: '10px' }}>{noItemsLabel}</div>
        )}
      </div>
    )
  }

  renderItems: Function = () => {
    const { noExpand, classes } = this.getTreeProps()
    const { level = 0, items, type, treeProps, label, centerContent, readOnly } = this.props
    const { isExpand } = this.state

    return (
      items &&
      (noExpand || isExpand) && (
        <div
          className={this.classes.items}
          onDragStart={this.stopPropagation}
          onDragEnter={this.stopPropagation}
          onDragLeave={this.stopPropagation}
          onDragOver={this.stopPropagation}
          onDrop={this.stopPropagation}
          onMouseEnter={this.stopPropagation}
          onMouseLeave={this.stopPropagation}
          data-cy={cyLabelFormater(`modulable-list-${level === 0 ? 'root' : items ? 'directory' : 'item'}`, label)}
        >
          {items.map((item, index) => (
            <ModulableTree
              level={level + 1}
              type={item.type || type}
              classes={classes}
              centerContent={centerContent}
              index={index}
              readOnly={readOnly}
              {...treeProps}
              treeProps={treeProps}
              {...item}
              key={item.key || item.label}
            />
          ))}
        </div>
      )
    )
  }

  render(): React$Node {
    const {
      label,
      level,
      items,
      drop,
      icon,
      actions,
      dragContent,
      Header,
      firstItemStyle,
      title,
      backgroundColor,
      centerContent,
      index,
      scrollable,
      readOnly,
    } = this.props
    const { isExpand, isHover } = this.state
    const { noExpand, draggableContentDir, DragIcon, noDragIcon, noLevel } = this.getTreeProps()

    if (!label || title) return this.renderItemsAsRoot()

    let listItems = !draggableContentDir || (draggableContentDir && !dragContent) ? this.renderItems() : null

    if (scrollable) {
      listItems = <div style={{ height: `calc(100% - ${Header ? '62' : '0'}px)`, overflowY: 'auto' }}>{listItems}</div>
    }

    return (
      <>
        {level === 0 && Header ? Header : null}
        <div
          style={{ backgroundColor }}
          className={cx(
            this.classes.container || null,
            { [this.classes.rootLevel]: level === 1 },
            level === 0 && this.classes.itemsContainer,
          )}
        >
          {this.isDragAndDropable(
            <div
              data-cy={cyLabelFormater(`modulable-${level === 0 ? 'root' : items ? 'directory' : 'item'}`, label)}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              style={firstItemStyle}
            >
              <div
                className={cx(this.classes.item, level === 0 && this.classes.firstItem)}
                style={{ paddingLeft: level && !noLevel ? level * 20 : 0, pointerEvents: drop ? 'none' : 'all' }}
              >
                <div className={this.classes.itemContentWrapper}>
                  <div className={this.classes.labelContent}>
                    {items && !noExpand && (
                      <div data-cy="modulable-tree-arrow" onClick={this.onExpand} className={this.classes.expandIcon}>
                        <FontIcon icon={isExpand ? 'fas-caret-down' : 'fas-caret-right'} />
                      </div>
                    )}
                    {icon ? <div className={this.classes.icon}>{icon}</div> : null}
                    <span className={cx(this.classes.label)}>{label}</span>
                  </div>
                  {centerContent && typeof index === 'number' ? centerContent(index) : null}
                  {!readOnly ? (
                    <div className={this.classes.actionsContent}>
                      {dragContent && !noDragIcon && isHover && (
                        <div className={this.classes.draggableIcon}>
                          {DragIcon || <FontIcon icon="fas-arrows-alt" />}
                        </div>
                      )}
                      {actions && <ModulableTreeActions {...this.props} />}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>,
          )}
          {listItems}
        </div>
      </>
    )
  }
}
