/** @flow */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { map } from 'lodash'

type Props = {|
  count: { [string]: number },
  width?: number,
  unitWidth?: number,
|}

export function LinksCountInput(props: Props): React$Node {
  const { count, width = 150, unitWidth = 30 } = props

  const sliderRef = useRef()

  const [index, setIndex] = useState(0)

  function onScroll(event: Event) {
    const { scrollLeft } = event.srcElement
    if (scrollLeft === 0) {
      setIndex(0)
    } else {
      const index = Math.ceil((scrollLeft - unitWidth / 2) / unitWidth)
      setIndex(index)
    }
  }

  const [left, right] = useMemo(() => {
    let left = 0
    let right = 0
    Object.values(count).forEach((value, valIndex) => {
      if (Number(valIndex) >= index) right += Number(value)
      else left += Number(value)
    })
    return [left, right]
  }, [index])

  useEffect(() => {
    if (sliderRef.current) sliderRef.current.addEventListener('scroll', onScroll)
    return () => {
      if (sliderRef.current) sliderRef.current.removeEventListener('scroll', onScroll)
    }
  }, [sliderRef.current])

  return (
    <div className="relative">
      <span className="marginRight5 bold">count: </span>
      <div
        style={{
          height: 'calc(100% - 40px)',
          width: 4,
          position: 'absolute',
          left: 'calc(50% - 2px)',
          backgroundColor: 'grey',
        }}
      />
      <div
        className="flex row noWrap overflowXAuto"
        style={{ border: '1px solid lightgrey', borderRadius: '3px', width, maxWidth: width, overflowX: 'auto' }}
        ref={(ref) => {
          sliderRef.current = ref
        }}
      >
        <div style={{ minWidth: width / 2, height: 10 }} />
        {map(count, (value, key) => {
          return (
            <div className="flex column textCenter" style={{ minWidth: unitWidth, maxWidth: unitWidth }}>
              <div className="bold" style={{ minHeight: '50%' }}>
                {key}
              </div>
              <div style={{ minHeight: '50%' }}>{value}</div>
            </div>
          )
        })}
        <div style={{ minWidth: width / 2, height: 10 }} />
      </div>
      <div className="flex row noWrap">
        <div className="flex center alignCenter" style={{ width: width / 2 }}>
          {left}
        </div>
        <div className="flex center alignCenter" style={{ width: width / 2 }}>
          {right}
        </div>
      </div>
    </div>
  )
}
