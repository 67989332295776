var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useState } from 'react';
import { map, find } from 'lodash';
import cx from 'classnames';
import { MUIModal } from 'app/components/Modal';
import Loader from 'app/components/Loader/Loader.jsx';
import { Checkbox, MUIAutocomplete, MUIButton } from 'app/components/Form';
import { notify } from 'app/components/Notifications/notify';
import classes from './ModalGroupsAuthorizations.module.scss';
import { useSelector } from 'react-redux';
import { groupCategoryPermissionsResourcesSelector } from 'app/store/selectors/groupCategoryPermissions';
import { assetsTypeSelector } from 'app/store/selectors/assets';
import { responseAssetsTypes } from 'app/core/constants/assetsTypes.js';
const ModalGroupsAuthorizations = (props) => {
    const { category, getGroupsDatas, onAddGroup, onRemoveGroup, onCheckboxChange } = props, modalProps = __rest(props, ["category", "getGroupsDatas", "onAddGroup", "onRemoveGroup", "onCheckboxChange"]);
    const [modalIsLoading, setModalIsLoading] = useState(false);
    const groupCategoryPermissions = useSelector(groupCategoryPermissionsResourcesSelector(category.id));
    const groups = useSelector(assetsTypeSelector(responseAssetsTypes.group));
    function save() {
        return Promise.resolve();
    }
    useEffect(() => {
        setModalIsLoading(true);
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield getGroupsDatas(category);
            setModalIsLoading(false);
        }))();
    }, []);
    function addGroup(group) {
        return __awaiter(this, void 0, void 0, function* () {
            setModalIsLoading(true);
            if (find(groupCategoryPermissions, (c) => c.group === group.id))
                notify('Group already exist');
            try {
                yield onAddGroup(group.id, category.id);
            }
            catch (error) {
                console.log(error);
            }
            setModalIsLoading(false);
        });
    }
    function removeGroup(id) {
        return __awaiter(this, void 0, void 0, function* () {
            setModalIsLoading(true);
            try {
                yield onRemoveGroup(id);
            }
            catch (error) {
                console.log(error);
            }
            setModalIsLoading(false);
        });
    }
    function checkboxClick(id, type, value) {
        return __awaiter(this, void 0, void 0, function* () {
            setModalIsLoading(true);
            try {
                yield onCheckboxChange(id, type, value);
            }
            catch (error) {
                console.log(error);
            }
            setModalIsLoading(false);
        });
    }
    function headerRenderer() {
        return [
            _jsx("div", { className: cx(classes.header, classes.cell), children: "Group" }, "headerGroupName"),
            _jsx("div", { className: cx(classes.header, classes.cell), children: "Read" }, "header-readPermission"),
            _jsx("div", { className: cx(classes.header, classes.cell), children: "Create" }, "header-createPermission"),
            _jsx("div", { className: cx(classes.header, classes.cell), children: "Update" }, "header-updatePermission"),
            _jsx("div", { className: cx(classes.header, classes.cell), children: "Delete" }, "header-deletePermission"),
            _jsx("div", { className: cx(classes.header, classes.cell), children: "Read Own" }, "header-readOwnPermission"),
            _jsx("div", { className: cx(classes.header, classes.cell), children: "Update Own" }, "header-updateOwnPermission"),
            _jsx("div", { className: cx(classes.header, classes.cell), children: "Delete Own" }, "header-deleteOwnPermission"),
            _jsx("div", { className: cx(classes.header, classes.cell), children: "Remove group" }, "header-removeGroup"),
        ];
    }
    return (_jsx(MUIModal, Object.assign({ title: `Edit groups authorizations for ${category.name}`, draggable: true, resizable: true, onValidate: save }, modalProps, { children: _jsxs("div", { className: classes.container, children: [_jsxs("div", { className: classes.toolbar, children: [_jsx(MUIAutocomplete, { onChange: (opt) => addGroup(opt.data), searchInOptions: true, options: map(groups, (group) => ({ label: group.name, value: group.id, data: group })), clearOnValidate: true, placeholder: "Add a group", style: { width: 200 } }), modalIsLoading ? (_jsx("div", { style: { height: 25, width: 25 }, className: "flex center alignCenter fullWidth", children: _jsx(Loader, {}) })) : null] }), _jsx("div", { className: classes.scroll, children: _jsxs("div", { className: classes.grid, children: [headerRenderer(), map(groupCategoryPermissions, (groupCategoryPermission) => {
                                const { group: groupId, id, readPermission, createPermission, updatePermission, deletePermission, readOwnPermission, updateOwnPermission, deleteOwnPermission, } = groupCategoryPermission;
                                const group = groups[groupId];
                                if (!group)
                                    return null;
                                return [
                                    _jsx("div", { className: classes.cell, children: group.name }, group.id),
                                    _jsx("div", { className: classes.cell, children: _jsx(Checkbox, { checked: readPermission, onChange: (value) => checkboxClick(id, 'readPermission', value) }) }, `${id}-readPermission`),
                                    _jsx("div", { className: classes.cell, children: _jsx(Checkbox, { checked: createPermission, onChange: (value) => checkboxClick(id, 'createPermission', value) }) }, `${id}-createPermission`),
                                    _jsx("div", { className: classes.cell, children: _jsx(Checkbox, { checked: updatePermission, onChange: (value) => checkboxClick(id, 'updatePermission', value) }) }, `${id}-updatePermission`),
                                    _jsx("div", { className: classes.cell, children: _jsx(Checkbox, { checked: deletePermission, onChange: (value) => checkboxClick(id, 'deletePermission', value) }) }, `${id}-deletePermission`),
                                    _jsx("div", { className: classes.cell, children: _jsx(Checkbox, { checked: readOwnPermission, onChange: (value) => checkboxClick(id, 'readOwnPermission', value) }) }, `${id}-readOwnPermission`),
                                    _jsx("div", { className: classes.cell, children: _jsx(Checkbox, { checked: updateOwnPermission, onChange: (value) => checkboxClick(id, 'updateOwnPermission', value) }) }, `${id}-updateOwnPermission`),
                                    _jsx("div", { className: classes.cell, children: _jsx(Checkbox, { checked: deleteOwnPermission, onChange: (value) => checkboxClick(id, 'deleteOwnPermission', value) }) }, `${id}-deleteOwnPermission`),
                                    _jsx("div", { className: classes.cell, children: _jsx(MUIButton, { dataCy: "trash", variant: "text", icon: "fas-trash", onClick: () => removeGroup(id) }) }, `${id}-removeGroup`),
                                ];
                            })] }) })] }) })));
};
export default ModalGroupsAuthorizations;
