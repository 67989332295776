// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Flags-___Flags-module__container {
}

.app-components-Form-Flags-___Flags-module__item {
  display: inline-block;
  color: #fff;
  background-color: var(--colors-grey);
  margin: 0 5px;
  padding: 0 10px;
  border-radius: 30px;
  font-weight: bold;
}

.app-components-Form-Flags-___Flags-module__item:hover {
    background-color: var(--colors-mainColor);
  }
`, "",{"version":3,"sources":["webpack://./app/components/Form/Flags/Flags.module.scss"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,oCAAoC;EACpC,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AAKnB;;AAHE;IACE,yCAAyC;EAC3C","sourcesContent":[".container {\n}\n\n.item {\n  display: inline-block;\n  color: #fff;\n  background-color: var(--colors-grey);\n  margin: 0 5px;\n  padding: 0 10px;\n  border-radius: 30px;\n  font-weight: bold;\n\n  &:hover {\n    background-color: var(--colors-mainColor);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Form-Flags-___Flags-module__container`,
	"item": `app-components-Form-Flags-___Flags-module__item`
};
export default ___CSS_LOADER_EXPORT___;
