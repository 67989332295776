// @flow

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import queryString from 'query-string'
import { map, find, capitalize, sortBy } from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import { MUISelect } from 'app/components/Form/Select/MUISelect.jsx'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import pipe from 'app/core/utils/pipe'
import { ResizableCol } from 'app/components/ResizableContainer'
import resources from 'app/store/resources'
import history from 'app/main/routerHistory'
import { ToggleButton, ToggleButtonGroup } from 'app/components/Form'
import { getResources } from 'app/store/selectors/getResources'
import type { ResourcesList, Group, Asset, Match, ID } from 'app/core/types'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import { MyHoursCalendar } from 'app/containers/MyHoursCalendar/MyHoursCalendar.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import { assetIcons } from 'app/components/Icons/assetsIcons.js'
import { getUserSettings, setUserSettings } from 'app/libs/helpers/userSettings'

import { useLocation } from 'react-router-dom'
import { TableTimeRecapActivities } from './TableTimeRecapActivities.jsx'
import { TableTimeRecap, tableId as timeRecapTableId } from './TableTimeRecap.jsx'
import classes from './TimeRecap.module.scss'
import type { Option } from '../../../core/types/Option'

type Props = {|
  groups: ?ResourcesList<Group>,
  selectedDay: ?number,
  selectedUser: ?ID,
  selectedRow: ?('otherProjects' | 'otherActivities' | 'total'),
  selectedUserInst: ?Asset,
  userSearchData: ?Asset,
  match: {|
    ...$Exact<Match>,
    params: {|
      month: string,
      projectId: ?ID,
      resourceId: ?ID,
    |},
  |},
  allotment?: boolean,
  resourceId: ID,
  projectId: ID,
  userId: ID,
  month: string,
|}

function TimeRecap(props: Props): React$Node {
  const {
    match,
    selectedUser,
    selectedDay: _selectedDay,
    selectedRow,
    userSearchData,
    groups,
    selectedUserInst,
    resourceId,
    projectId,
    month,
    userId,
  } = props
  const { pathname } = useLocation()
  const [reloadActivitiesKey, setReloadActivitiesKey] = useState<string | void>()
  const [checked, setChecked] = useState(false)
  const [allotment, setAllotment] = useState(false)
  const [showQuickLook, setShowQuickLook] = useState<boolean>(getUserSettings(`${timeRecapTableId}-showQuickLook`))
  const [projects, setProjects] = useState<Array<Asset>>([])

  const reloadActivities = useCallback(() => setReloadActivitiesKey(String(Date.now())), [setReloadActivitiesKey])

  const date = useMemo(() => moment(month, 'MM-YYYY'), [month])
  const selectedDay = useMemo(() => {
    if (!_selectedDay) return null
    return moment(month, 'MM-YYYY').date(_selectedDay)
  }, [_selectedDay, month])
  const tableKey = useMemo(
    () => `${month}-${projectId || ''}-${resourceId || ''}${checked ? '-hideEmpty' : ''}${String(showQuickLook)}`,
    [month, projectId, resourceId, checked, showQuickLook],
  )

  const selectedGroup = useMemo(() => find(groups, (group) => group.name === 'all')?.id, [groups])
  const tableHoursTools = useRef()

  useEffect(() => {
    setAllotment(pathname.includes('allotment-time-recap'))
  }, [pathname])

  useEffect(() => {
    resources.assets.fetchUserProjects(userId).then((res) => {
      setProjects(res.resources)
    })
  }, [])

  useEffect(() => {
    let title = capitalize(date.format('MMMM'))
    if (selectedUserInst?.name) title = `${title} - ${selectedUserInst.name}`
    // $FlowFixMe
    if (selectedDay?.format) title = `${selectedDay.format('D')} ${title}`
    documentTitle(title)
  }, [date, selectedUserInst, selectedDay])

  function hideEmpty() {
    setChecked(!checked)
  }

  function onChangeLocation(newParams: { resourceId?: ?ID, projectId?: ID, month?: string }) {
    const params = { ...match.params, ...newParams }
    const { resourceId, projectId, month } = params

    history.pushWithQuery(
      { selectedDay: undefined, selectedUser: undefined, selectedRow: undefined },
      `/hr/${!allotment ? 'time-recap' : 'allotment-time-recap'}/${month}/${
        projectId ? `${projectId}/${resourceId || ''}` : ''
      }`,
    )
  }

  const toolbar = useMemo(() => {
    const { projectId, resourceId, month } = match.params

    const selectedMonth = moment(month, 'MM-YYYY')
    const selectedGroup = find(groups, (group) => group.name === 'all')?.id

    return (
      <div className={`${classes.toolbar} fullWidth flex row noWrap alignCenter`}>
        <PopupState variant="popover" popupId="calendar">
          {(popupState) => (
            <>
              <ToggleButtonGroup className="marginRight10">
                <ToggleButton
                  onClick={() =>
                    onChangeLocation({
                      month: moment(selectedMonth).subtract(1, 'month').format('MM-YYYY'),
                    })
                  }
                >
                  <FontIcon icon="fas-chevron-left" />
                </ToggleButton>
                {/* $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow */}
                <ToggleButton icon="fas-calendar" {...bindTrigger(popupState)}>
                  <span className="capitalize">{selectedMonth.format('MMMM YYYY')}</span>
                </ToggleButton>
                <ToggleButton
                  onClick={() =>
                    onChangeLocation({
                      month: moment(selectedMonth).add(1, 'month').format('MM-YYYY'),
                    })
                  }
                >
                  <FontIcon icon="fas-chevron-right" />
                </ToggleButton>
              </ToggleButtonGroup>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MyHoursCalendar
                  display="month"
                  selectedWeek={moment(month, 'MM-YYYY')}
                  onSelectMonth={(month: moment) => onChangeLocation({ month: month.format('MM-YYYY') })}
                />
              </Popover>
            </>
          )}
        </PopupState>

        <MUISelect
          options={[{ label: 'All projects', value: 'all' }].concat(
            sortBy(
              map(projects, (project) => ({
                value: project.id,
                label: project.name,
              })),
              [(o: Option) => o.label.toLowerCase()],
            ),
          )}
          style={{ width: 180 }}
          value={projectId}
          placeholder="Project"
          onChange={(value) => {
            const selectedProjectId = value.value

            if (selectedProjectId && selectedProjectId !== 'all')
              resources.assets.fetchGroupsByProject(selectedProjectId)

            onChangeLocation({
              projectId: String(selectedProjectId),
              ...(selectedProjectId === 'all' || selectedProjectId !== projectId ? { resourceId: null } : {}),
            })
          }}
        />
        <MUISelect
          key={projectId}
          style={{ width: 150 }}
          options={map(groups, (group) => ({
            value: group.id,
            label: group.name,
          }))}
          disabled={projectId === 'all'}
          value={projectId !== 'all' ? resourceId || selectedGroup : undefined}
          placeholder="Group"
          onChange={(data) => {
            return onChangeLocation({
              resourceId: String(data.value),
              ...(data.data && data.data.project ? { projectId: data.data.project } : {}),
            })
          }}
        />
        <ToggleButtonGroup>
          <ToggleButton selected={checked} onClick={hideEmpty} icon="fas-eye-slash" tooltip="Hide empty rows" />
          <ToggleButton
            selected={showQuickLook}
            onClick={() =>
              setShowQuickLook((val) => {
                setUserSettings(`${timeRecapTableId}-showQuickLook`, !val)
                return !val
              })
            }
            icon="fas-table"
            tooltip="Show activities overview (may be slow)"
          />
        </ToggleButtonGroup>
      </div>
    )
  }, [match, groups, projects, showQuickLook])

  const ToolbarFilters = useMemo(() => {
    return (
      <div className="flex alignCenter marginLeftAuto">
        <AssetsSelect
          value={
            userSearchData
              ? { label: userSearchData.name, value: userSearchData.id, icon: assetIcons(userSearchData.assetType) }
              : null
          }
          style={{ marginRight: 5, maxWidth: 250, width: 200, marginLeft: 'auto' }}
          assetTypes={['us']}
          placeholder={userSearchData ? userSearchData.name : 'Search user...'}
          options={
            userSearchData
              ? [{ label: userSearchData.name, value: userSearchData.id, icon: assetIcons(userSearchData.assetType) }]
              : undefined
          }
          onChange={(data) => {
            history.pushWithQuery({
              userSearch: data.value,
              selectedDay: undefined,
              selectedUser: undefined,
              selectedRow: undefined,
            })
          }}
        />
        <IconButton
          onClick={(event) =>
            history.pushWithQuery({
              userSearch: undefined,
              selectedDay: undefined,
              selectedUser: undefined,
              selectedRow: undefined,
            })
          }
        >
          <FontIcon icon="fas-times-circle" style={{ fontSize: 18 }} />
        </IconButton>
      </div>
    )
  }, [userSearchData])

  return (
    <div className="fullWidth fullHeight" data-cy="page-timerecap">
      <div className="flex fullHeight">
        <ResizableCol
          defaultLeftSize={2}
          optionName="TimeRecapVertResizing"
          disabled={undefined}
          left={
            <TableTimeRecap
              key={tableKey}
              month={month}
              allotment={allotment}
              resourceId={resourceId || selectedGroup}
              reloadActivities={reloadActivities}
              hideEmpty={checked}
              ExtendsToolbar={toolbar}
              ToolbarFilters={ToolbarFilters}
              projectId={projectId}
              userSearch={userSearchData}
              showQuickLook={showQuickLook}
              getTools={(tools) => {
                tableHoursTools.current = tools
              }}
              selectedRow={selectedRow}
              selectedUser={selectedUser}
              selectedDay={_selectedDay}
            />
          }
          right={
            selectedDay && selectedUser ? (
              <div className={classes.timeRecapUserDate}>
                <TableTimeRecapActivities
                  reloadKey={`${tableKey}-${selectedDay.format('YYYY-MM-DD')}-${selectedUser}-${
                    reloadActivitiesKey || ''
                  }`}
                  type={selectedRow}
                  allotment={allotment}
                  userId={selectedUser}
                  date={selectedDay}
                  tableHoursTools={tableHoursTools.current}
                />
              </div>
            ) : null
          }
        />
      </div>
    </div>
  )
}

const Component: React$ComponentType<any> = pipe()
  .withRouter()
  .connect((state, props) => {
    const { location } = props
    const { projectId, month, resourceId } = props.match.params
    const { selectedUser, selectedDay, selectedRow, userSearch } = queryString.parse(
      (location.search || '').replace('?', ''),
    )

    return {
      selectedUser,
      selectedUserInst: getResources(state, 'assets', selectedUser),
      selectedDay: Number(selectedDay),
      selectedRow,
      userSearchData: userSearch ? getResources(state, 'assets', userSearch) : undefined,
      userId: state.user.asset,
      groups: projectId && getResources(state, 'assets', { assetType: 'gp', project: projectId }),
      projectId,
      month,
      resourceId,
    }
  })
  .request((props) => {
    const { match, userSearch } = props
    const { projectId } = match.params

    return Promise.all([
      resources.progressionStatus.fetchAll(),
      userSearch && resources.assets.fetchOne(userSearch),
      projectId && projectId !== 'all' && resources.assets.fetchGroupsByProject(projectId),
    ])
  })
  .render(TimeRecap)

export default Component
