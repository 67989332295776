/** @flow */
import React from 'react'
import { DurationRead } from 'app/components/Duration/Duration.tsx'
// TODO : when converting this component to TS, remove DurationProps below and import from app/components/Duration/Duration.tsx
import type { Activity, DurationProps } from 'app/core/types'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'

import type { DefaultCellProps, Column } from '../../types'
import { groupingFns } from './groupingFns.jsx'
import classes from './CellDuration.module.scss'
import { TextDuration } from './TextDuration.jsx'

type CellDurationParams = {|
  ...$Exact<DefaultCellProps>,
  durationProps?: DurationProps,
  disableSelection?: boolean,
  textAlignment?: 'left' | 'center' | 'right',
|}

export const CellTextDuration = ({
  durationProps,
  disableSelection,
  textAlignment,
  ...data
}: CellDurationParams): Column => ({
  cellType: 'CellTextDuration',
  Cell: (instance) => {
    const { value, cell, prefs } = instance
    const { getCellProps } = cell
    const { edition, isRowExpanded } = getCellProps()
    const { isBeingEdit } = edition
    const activity: Activity = value || { duration: undefined, text: '' }

    if (isBeingEdit) {
      return (
        <TextDuration
          prefs={prefs}
          edition={edition}
          activity={value}
          cell={cell}
          isRowExpanded={isRowExpanded}
          textAlignment={textAlignment}
          {...durationProps}
        />
      )
    }

    return (
      <div className={classes.container}>
        {activity.comment ? (
          <Tooltip title={activity.comment}>
            <div className={classes.activityCommentBadge} />
          </Tooltip>
        ) : null}
        <DurationRead value={activity.duration} {...durationProps} />
      </div>
    )
  },
  width: 100,
  groupingFns,
  ...data,
})
