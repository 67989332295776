/** @flow */
import type { Step, DynamicApproval } from 'app/core/types'
import type { CalculatedFieldsModelProps } from './calculatedFieldsTypes'

const durationParser = (str: string) => {
  const [label] = str.split('.')
  return label.substr(0, label.lastIndexOf('-'))
}

export const calculatedFieldsModels: Array<CalculatedFieldsModelProps> = [
  {
    value: 'duration',
    label: 'Duration',
    units: [
      {
        label: 'steps',
        resourceName: 'steps',
        prefix: 'step',
        attributes: [
          {
            key: 'task.estimatedLength',
            label: 'estimated length',
            value: (step: Step) =>
              `step|${step?.name || 'unknownName'}-${step?.number || 'unknownNumber'}.task.estimatedLength`,
            parser: durationParser,
          },
          {
            key: 'task.timeSpent',
            label: 'spent time',
            value: (step: Step) =>
              `step|${step?.name || 'unknownName'}-${step?.number || 'unknownNumber'}.task.timeSpent`,
            parser: durationParser,
          },
          {
            key: 'task.realEstimLength',
            label: 'supervisor estimation',
            value: (step: Step) =>
              `step|${step?.name || 'unknownName'}-${step?.number || 'unknownNumber'}.task.realEstimLength`,
            parser: durationParser,
          },
          {
            key: 'task.quoteEstimLength',
            label: 'assumptions estimation',
            value: (step: Step) =>
              `step|${step?.name || 'unknownName'}-${step?.number || 'unknownNumber'}.task.quoteEstimLength`,
            parser: durationParser,
          },
        ],
      },
    ],
    funcs: [],
    requiredFunc: false,
    operators: ['+', '-', '*', '/'],
  },
  {
    value: 'dynappsummary',
    label: 'Dynamic approval summary',
    units: {
      label: 'dynamic approval',
      resourceName: 'dynamicApprovals',
      prefix: 'dynApp',
      attributes: {
        value: (dynApp: DynamicApproval) => `dynApp|${dynApp?.name || 'unknownName'}`,
        parser: (dynApp: string) => dynApp,
        label: '',
        key: '',
      },
    },
    funcs: ['minStatus', 'maxStatus', 'maxStatusIgnoreBlank', 'minStatusIgnoreBlank'],
    requiredFunc: true,
    operators: [','],
  },
]
