import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useState, useEffect, useMemo } from 'react';
import resources from 'app/store/resources';
import { getResources } from 'app/store/selectors';
import Loader from 'app/components/Loader/Loader.jsx';
import { permission } from 'app/containers/Permissions/index.js';
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
import { JobSkillLevelInput } from 'app/components/JobSkillLevelInput/JobSkillLevelInput.jsx';
import { EditList } from '../EditList';
import { ModalEditJob } from './ModalEditJob';
export function JobsEditor() {
    const [isLoading, setIsLoading] = useState(false);
    const [jobs, setJobs] = useState();
    const [skillAttributes, setSkillAttributes] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        resources.jobs.fetchAll({ params: { queries: { page_size: 1000 } } }).then((res) => {
            setJobs(getResources(undefined, 'jobs', undefined, ['skillLevelsInst']));
            setIsLoading(false);
        });
        resources.personAttributes
            .fetchAll({ params: { queries: { page_size: 1000, attrType: 'skill' } } })
            .then((res) => setSkillAttributes(res.resources));
    }, []);
    useEffect(() => {
        documentTitle('Jobs editor', 'page');
    }, []);
    const canEdit = useMemo(() => permission(['human resources_settings_posts_edit']), []);
    return isLoading ? (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter", children: _jsx(Loader, {}) })) : (_jsx("div", { className: "padding10", style: { width: 'fit-content', maxHeight: '100%', height: '100%' }, children: _jsx(EditList, { title: "Jobs", unity: "Job", titles: ['Job title', 'Collective agreement title', 'Required skills', 'Quotation reference'], columnsSizes: [300, 400, 400, 180], readOnly: !canEdit, labelSelectors: [
                'name',
                {
                    key: 'collectiveAgreementTitlesInst',
                    parser: (job) => {
                        if (!(job === null || job === void 0 ? void 0 : job.collectiveAgreementTitlesInst))
                            return undefined;
                        return (_jsx("div", { className: "flex column", children: job.collectiveAgreementTitlesInst.map((jobCollectiveAgreementTitle) => (_jsx("div", { className: "padding5", children: jobCollectiveAgreementTitle.jobTitle }, jobCollectiveAgreementTitle.jobTitle))) }));
                    },
                },
                {
                    key: 'skillLevelsInst',
                    parser: (job) => {
                        if (!job.skillLevelsInst)
                            return undefined;
                        return _jsx(JobSkillLevelInput, { jobSkillLevels: job.skillLevelsInst, skillAttributes: skillAttributes });
                    },
                },
                'quoteReference',
            ], itemsList: jobs || {}, onRemove: (job) => {
                const newJobs = jobs ? Object.assign({}, jobs) : {};
                if (job === null || job === void 0 ? void 0 : job.id)
                    delete newJobs[job.id];
                setJobs(newJobs);
            }, EditModal: (item) => (_jsx(ModalEditJob, { job: item, onChange: (job) => {
                    const newJobs = jobs ? Object.assign({}, jobs) : {};
                    if (job === null || job === void 0 ? void 0 : job.id)
                        newJobs[job.id] = job;
                    setJobs(newJobs);
                } })), resource: "jobs" }) }));
}
