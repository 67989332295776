import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { showPanel } from 'app/components/OverPage/OverPage.jsx';
import AssetDetail from './AssetDetail.jsx';
import { router } from './router.js';
export default () => {
    const open = router.getConfig().rightPanel && router.exist();
    useEffect(() => {
        if (open)
            showPanel('assetDetail', _jsx(AssetDetail, {}), () => router.remove());
        else
            showPanel('assetDetail', null);
    }, [open]);
    return null;
};
