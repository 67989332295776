// @flow

import type { StoreResourceDef } from 'app/core/types'

export const categories: StoreResourceDef<> = {
  resourceType: 'categories',
  relations: {
    personAttributesInst: {
      type: 'hasMany',
      resourceType: 'personAttributes',
      foreignKey: 'category',
    },
  },
}
