/** @flow */
import React, { useEffect } from 'react'

export default function TestErrors(): React$Node {
  useEffect(() => {
    throw new Error('Auto generated error for testing.')
  }, [])

  return <div>This page generate an error</div>
}
