// @flow
import React, { useState } from 'react'
import cx from 'classnames'
import Validators from '../Validators/Validators.jsx'
import classes from './Textarea.module.scss'

type Props = {
  value: string,
  children?: any,
  defaultValue?: string,
  onChange: Function,
  onBlur?: Function,
  style?: Object,
  className?: string,
  validatorRef?: Object | Function,
  contentRef?: Object | Function,
  inputRef?: Object | Function,
  errors?: Array<any>,
  validOnBlur?: boolean,
  isRequired?: boolean,
  validators?: Object,
}

export function Textarea(props: Props): React$Node {
  const {
    value,
    defaultValue,
    validatorRef,
    errors,
    children,
    inputRef,
    isRequired,
    validators,
    validOnBlur,
    contentRef,
    onBlur,
    onChange,
    style,
    className,
    ...otherProps
  } = props

  const [error, setError] = useState(false)

  let localValidatorRef

  const valid = () => {
    return localValidatorRef && localValidatorRef.valid()
  }

  const localOnBlur = (event) => {
    if (validOnBlur) valid()
    if (onBlur) onBlur(event)
  }

  const _onChange = (event) => {
    onChange(event.target.value)
  }

  const classNameInput = cx(classes.input, {
    [classes.error]: error || (errors && errors.length > 0),
  })

  return (
    <div
      ref={contentRef}
      className={className}
      style={style}
      onKeyDown={(e) => {
        e.stopPropagation()
      }}
    >
      {/* $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow
       */}
      <textarea
        ref={inputRef}
        value={value}
        defaultValue={defaultValue}
        className={cx(classNameInput, className)}
        onBlur={localOnBlur}
        onChange={_onChange}
        {...otherProps}
      />

      <Validators
        ref={(ref) => {
          if (validatorRef) validatorRef(ref)
          localValidatorRef = ref
        }}
        className={classes.fieldError}
        value={value}
        errors={errors}
        onSuccess={() => setError(false)}
        onError={() => setError(true)}
        validators={{
          required: isRequired,
          ...validators,
        }}
      />
    </div>
  )
}
