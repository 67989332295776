// @flow

import React, { useMemo, useState } from 'react'
import { TableTasks } from 'app/pages/Project/Tasks/TableTasks.tsx'
import { ToggleButton, ToggleButtonGroup } from 'app/components/Form'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import { Filters } from './Filters'

export const tableId = 'MyTaskTableTask'

type Props = {||}

export function MyTasks(props: Props): React$Node {
  const [pastTasks, setPastTasks] = useState<boolean>(false)

  documentTitle(pastTasks ? 'Past tasks' : '')

  const resourcesParams = useMemo(() => {
    return {
      resourceType: 'tasks',
      requestName: pastTasks ? 'fetchUserPastTasks' : 'fetchUserTasks',
      queries: {
        status__statusType__in: pastTasks ? '3' : '0,2,4',
      },
      includedResources: [
        'assetInst',
        'assetInst.thumbnailInst',
        'stepInst',
        'assignedUserInst',
        'suggestedUserInst',
        'statusInst',
        'activitiesInst',
        'takesInst.statusInst',
        'takesInst.takeFlagsInst.flagInst',
        'takesInst.takeRefMediasInst.mediaInst',
        'takesInst.takeValidationMediasInst.mediaInst',
      ],
    }
  }, [pastTasks])

  return (
    <div className="fullWidth fullHeight" data-cy="page-mytasks">
      <TableTasks
        tableId={tableId}
        enableAddTime={true}
        swapTableType={
          <ToggleButtonGroup>
            <ToggleButton icon="far-square" onClick={() => setPastTasks(false)} selected={!pastTasks}>
              Current tasks
            </ToggleButton>
            <ToggleButton icon="fas-check-square" onClick={() => setPastTasks(true)} selected={pastTasks}>
              Past tasks
            </ToggleButton>
          </ToggleButtonGroup>
        }
        Filters={Filters}
        resources={resourcesParams}
      />
    </div>
  )
}
