/** @flow */
import PubSub from 'pubsub-js'
import type { NotificationParams } from './NotificationsPublisher.jsx'

type MessageType = Function | React$Node

export const notify = (message: MessageType, notifParams?: NotificationParams): * => {
  PubSub.publish('NOTIFY', { message, notifParams })
}

export const error = (message: MessageType, params?: NotificationParams): * => {
  PubSub.publish('NOTIFY', { message, notifParams: { variant: 'error', ...params } })
}

export const success = (message: MessageType, params?: NotificationParams): * => {
  PubSub.publish('NOTIFY', { message, notifParams: { variant: 'success', ...params } })
}

export const oldNotif = (data: { [key: 'success' | 'pending' | 'warning' | 'error' | 'message']: MessageType }) => {
  const { success, pending, warning, error, message } = data
  if (success) notify(success, { variant: 'success' })
  else if (pending) notify(pending, { variant: 'info' })
  else if (warning) notify(warning, { variant: 'error' })
  else if (error) notify(error, { variant: 'error' })
  else if (message) notify(message)
}

export const notifyExport = (message: MessageType, params?: NotificationParams): * => {
  PubSub.publish('NOTIFY', {
    message,
    notifParams: {
      persist: true,
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      onClick: undefined,
      ...params,
    },
  })
}
