// @flow

import * as React from 'react'
import { rootContextMenu } from 'app/main/rootContainers.js'
import { ContextMenu } from 'app/components/ContextMenu'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import classes from './Menu.module.scss'

export type Action = {|
  label: React$Node,
  rightLabel?: string,
  onClick: (e: SyntheticMouseEvent<HTMLElement>) => void,
  disabled?: boolean,
|}

export type Actions = Array<Action>

export type MenuProps = {|
  button?: React.Element<any>,
  actions: Actions,
  actions: Array<any>,
|}

export type MenuState = {
  isOpen: boolean,
  menuWidth: number,
}

export class Menu extends React.PureComponent<MenuProps, MenuState> {
  onClick: Function = (event: Object) => {
    event.preventDefault()
    const { actions } = this.props
    const { clientX, clientY } = event.nativeEvent

    rootContextMenu.render(<ContextMenu clientX={clientX} clientY={clientY} items={actions} />)
  }

  render(): React$Node {
    const { button, actions } = this.props

    if (!Array.isArray(actions) || actions.length < 1) return null

    return (
      <div className={classes.container}>
        {button ? (
          React.cloneElement(button, { onClick: this.onClick })
        ) : (
          <FontIcon className={classes.iconButton} icon="fas-ellipsis-v" onClick={this.onClick} />
        )}
      </div>
    )
  }
}
