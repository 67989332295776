// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Permissions-___PermissionsPanel-module__groupGrid {
  display: grid;
  grid-template-columns: repeat(2, fit-content(100%));
  grid-gap: 15px;
}

.app-containers-Permissions-___PermissionsPanel-module__groupName {
  text-transform: capitalize;
  font-weight: bold;
}

.app-containers-Permissions-___PermissionsPanel-module__activePerms {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #48c27e;
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: bold;
  color: #ffffff;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Permissions/PermissionsPanel.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mDAAmD;EACnD,cAAc;AAChB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".groupGrid {\n  display: grid;\n  grid-template-columns: repeat(2, fit-content(100%));\n  grid-gap: 15px;\n}\n\n.groupName {\n  text-transform: capitalize;\n  font-weight: bold;\n}\n\n.activePerms {\n  width: 20px;\n  height: 20px;\n  border-radius: 10px;\n  background-color: #48c27e;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  font-weight: bold;\n  color: #ffffff;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupGrid": `app-containers-Permissions-___PermissionsPanel-module__groupGrid`,
	"groupName": `app-containers-Permissions-___PermissionsPanel-module__groupName`,
	"activePerms": `app-containers-Permissions-___PermissionsPanel-module__activePerms`
};
export default ___CSS_LOADER_EXPORT___;
