// @flow

import type { StoreResourceDef } from 'app/core/types'

export const imputations: StoreResourceDef<> = {
  resourceType: 'imputations',
  relations: {
    contractInst: {
      type: 'hasOne',
      resourceType: 'contracts',
      foreignKey: 'contract',
    },
    personInst: {
      type: 'hasOne',
      resourceType: 'people',
      foreignKey: 'person',
    },
  },
}
