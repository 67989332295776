/** @flow */

import React from 'react'
import moment from 'moment'
import type { ID, Activity } from 'app/core/types'
import { FormData } from 'app/components/Form'
import { ModalConfirmForm } from 'app/components/Modal'
import resources from 'app/store/resources'

type Props = {|
  userId: ID,
  project: ?ID,
  task: ID,
  preSelectedDate?: Date,
  onChange?: (Array<Activity>) => void,
|}

export function ModalAddActivity(props: Props): React$Node {
  const { userId, preSelectedDate = new Date(), onChange, task, project, ...rest } = props

  function onAddCustomActivity(data: Object) {
    const { duration, date } = data

    return resources.activities
      .create({
        duration,
        date: moment(date).format('YYYY-MM-DD'),
        user: userId,
        project,
        task,
      })
      .then((res) => {
        if (onChange) onChange(res.resources)
      })
  }

  return (
    <ModalConfirmForm title="Add activity on the task" minWidth={500} {...rest}>
      <FormData
        onSave={onAddCustomActivity}
        defaultData={{
          duration: 0,
          date: preSelectedDate,
        }}
        properties={[
          {
            key: 'duration',
            label: 'Duration',
            type: 'duration',
            elementProps: {
              isRequired: true,
              hide: { days: true },
            },
          },
          {
            key: 'date',
            label: 'Date',
            type: 'string',
            elementProps: (data: Object, setData: Function) => ({
              type: 'date',
              isRequired: true,
              value: data.date ? moment(data.date).format('YYYY-MM-DD') : undefined,
              max: moment().format('YYYY-MM-DD'),
              min: '2000-01-01',
              onChange: (e) => setData({ date: e.target.value }),
            }),
          },
        ]}
      />
    </ModalConfirmForm>
  )
}
