// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-ColumnTimelineImputation-Range-___LengthButton-module__container {
  position: absolute;
  height: 12px;
  width: 12px;
  display: inline-block;
  z-index: 2;
}

  .app-components-Table-Cells-ColumnTimelineImputation-Range-___LengthButton-module__container[position='left'] {
    cursor: w-resize;
    left: -8px;
  }

  .app-components-Table-Cells-ColumnTimelineImputation-Range-___LengthButton-module__container[position='right'] {
    cursor: e-resize;
    right: -8px;
  }

  .app-components-Table-Cells-ColumnTimelineImputation-Range-___LengthButton-module__container .app-components-Table-Cells-ColumnTimelineImputation-Range-___LengthButton-module__button {
    height: 100%;
    width: 100%;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/ColumnTimelineImputation/Range/LengthButton.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,qBAAqB;EACrB,UAAU;AAeZ;;EAbE;IACE,gBAAgB;IAChB,UAAU;EACZ;;EACA;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,YAAY;IACZ,WAAW;EACb","sourcesContent":[".container {\n  position: absolute;\n  height: 12px;\n  width: 12px;\n  display: inline-block;\n  z-index: 2;\n\n  &[position='left'] {\n    cursor: w-resize;\n    left: -8px;\n  }\n  &[position='right'] {\n    cursor: e-resize;\n    right: -8px;\n  }\n\n  .button {\n    height: 100%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-ColumnTimelineImputation-Range-___LengthButton-module__container`,
	"button": `app-components-Table-Cells-ColumnTimelineImputation-Range-___LengthButton-module__button`
};
export default ___CSS_LOADER_EXPORT___;
