// @flow
import * as React from 'react'
import { FormData } from 'app/components/Form'
import type { DynamicApproval, AssetTypes, Step, ID } from 'app/core/types'
import { ModalConfirmForm, ModalTrigger } from 'app/components/Modal'

type Props = {|
  dynamicApproval: DynamicApproval | void,
  title: string,
  onSave: Function,
  children: React.Element<any>,
  assetType?: ?AssetTypes,
  steps: Array<Step>,
|}

export class NewDynamicApprovalModal extends React.PureComponent<Props> {
  dynamicApprovalToFormdata: (dynamicApproval: ?DynamicApproval) => ?{|
    assetType: AssetTypes,
    id: ID,
    name: string,
    targetStep: ?ID,
  |} = (dynamicApproval: ?DynamicApproval) => {
    return (
      dynamicApproval && {
        name: dynamicApproval.name,
        assetType: dynamicApproval.assetType,
        id: dynamicApproval.id,
        targetStep: dynamicApproval.targetStep,
      }
    )
  }

  formdataToDynamicApproval: (dynamicApproval: DynamicApproval) => {|
    assetType: ?AssetTypes,
    id: ID,
    name: string,
    targetStep: ?ID,
  |} = (dynamicApproval) => {
    const { assetType } = this.props
    return (
      dynamicApproval && {
        name: dynamicApproval.name,
        assetType: dynamicApproval.assetType || assetType,
        id: dynamicApproval.id,
        targetStep: dynamicApproval.targetStep,
      }
    )
  }

  render(): React$Node {
    const { children, onSave, title, dynamicApproval, steps } = this.props

    return (
      <ModalTrigger
        modal={
          <ModalConfirmForm title={title} draggable={false}>
            <FormData
              defaultData={this.dynamicApprovalToFormdata(dynamicApproval)}
              properties={[
                {
                  key: 'name',
                  label: 'Name',
                  type: 'string',
                  elementProps: {
                    isRequired: true,
                    placeholder: 'Dynamic approval name',
                  },
                },
                {
                  key: 'targetStep',
                  label: 'Target Step',
                  tooltip:
                    'Add a menu "Auto push" in the menu of the Dynamic Approval to push/merge automatically to the specified step.',
                  type: 'select',
                  elementProps: {
                    fullWidth: true,
                    placeholder: 'Target step... (optionnal)',
                    options: steps.map((step) => ({ label: step.name, value: step.id })),
                  },
                },
              ].filter((_) => _)}
              flashNotifSuccessLabel={dynamicApproval ? 'Dynamic approval updated' : 'Dynamic approval created'}
              onSave={(formValues) =>
                onSave(this.formdataToDynamicApproval({ ...formValues, id: dynamicApproval && dynamicApproval.id }))
              }
            />
          </ModalConfirmForm>
        }
      >
        {children}
      </ModalTrigger>
    )
  }
}
