// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Tabs-___Tabs-module__container {
  background-color: #fff;
}

  .app-components-Tabs-___Tabs-module__container:not(.app-components-Tabs-___Tabs-module__noBorder) {
    border: var(--mainBorder);
  }

.app-components-Tabs-___Tabs-module__nav {
  text-transform: uppercase;
  border-bottom: var(--mainBorder);
  display: flex;
  align-items: center;
  color: var(--colors-blackLight);
  font-family: 'ralewaybold';
}

.app-components-Tabs-___Tabs-module__navItem {
  line-height: 2.5;
  text-align: center;
  flex: 1;
}

.app-components-Tabs-___Tabs-module__navItem:not(:first-child) {
    border-left: var(--mainBorder);
  }

.app-components-Tabs-___Tabs-module__navItem:last-child {
    border-right: var(--mainBorder);
  }

.app-components-Tabs-___Tabs-module__navItem:hover,
  .app-components-Tabs-___Tabs-module__navItem.app-components-Tabs-___Tabs-module__active {
    color: #fff;
    background-color: var(--colors-grey);
    cursor: pointer;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Tabs/Tabs.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AAKxB;;EAHE;IACE,yBAAyB;EAC3B;;AAGF;EACE,yBAAyB;EACzB,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,+BAA+B;EAC/B,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,OAAO;AAgBT;;AAdE;IACE,8BAA8B;EAChC;;AAEA;IACE,+BAA+B;EACjC;;AAEA;;IAEE,WAAW;IACX,oCAAoC;IACpC,eAAe;EACjB","sourcesContent":[".container {\n  background-color: #fff;\n\n  &:not(.noBorder) {\n    border: var(--mainBorder);\n  }\n}\n\n.nav {\n  text-transform: uppercase;\n  border-bottom: var(--mainBorder);\n  display: flex;\n  align-items: center;\n  color: var(--colors-blackLight);\n  font-family: 'ralewaybold';\n}\n\n.navItem {\n  line-height: 2.5;\n  text-align: center;\n  flex: 1;\n\n  &:not(:first-child) {\n    border-left: var(--mainBorder);\n  }\n\n  &:last-child {\n    border-right: var(--mainBorder);\n  }\n\n  &:hover,\n  &.active {\n    color: #fff;\n    background-color: var(--colors-grey);\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Tabs-___Tabs-module__container`,
	"noBorder": `app-components-Tabs-___Tabs-module__noBorder`,
	"nav": `app-components-Tabs-___Tabs-module__nav`,
	"navItem": `app-components-Tabs-___Tabs-module__navItem`,
	"active": `app-components-Tabs-___Tabs-module__active`
};
export default ___CSS_LOADER_EXPORT___;
