// @flow

import * as React from 'react'
import cx from 'classnames'

import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type { IconType } from 'app/core/types'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { toHighlightJSX } from 'app/libs/helpers/toHighlightJSX.jsx'
import classes from './AutocompleteSingle.module.scss'
import { cyLabelFormater } from '../../../../libs/helpers/cyTools'

export type AutocompleteOption<Data = void> = {|
  value: React$Node,
  label: string,
  onMouseDown: Function,
  onClick: Function,

  labelCustom?: string,
  icon?: IconType,
  backgroundColor?: string,
  color?: string,
  disabled?: boolean,
  highlighted?: boolean,
  selected?: boolean,

  separator?: boolean,
  animeKeyPressed?: $Exact<{ shift?: boolean, ctrl?: boolean, meta?: boolean }>,

  data?: Data,
|}

type Props = AutocompleteOption<any>

type State = {|
  shift: boolean,
  ctrl: boolean,
  meta: boolean,
|}

export class Option extends React.PureComponent<Props, State> {
  static defaultProps: $Shape<Props> = {
    labelCustom: '',
    backgroundColor: undefined,
    highlighted: false,
    disabled: false,
  }

  state: State = {
    shift: false,
    ctrl: false,
    meta: false,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.setOnKeyDown, false)
    document.addEventListener('keyup', this.setOnKeyUp, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.setOnKeyDown, false)
    document.removeEventListener('keyup', this.setOnKeyUp, false)
  }

  setOnKeyDown: Function = (event: *) => {
    if (event.shiftKey) this.setState({ shift: true })
    if (event.ctrlKey) this.setState({ ctrl: true })
    if (event.metaKey) this.setState({ meta: true })
  }

  setOnKeyUp: Function = (event: *) => {
    if (!event.shiftKey) this.setState({ shift: false })
    if (!event.ctrlKey) this.setState({ ctrl: false })
    if (!event.metaKey) this.setState({ meta: false })
  }

  render(): React$Node {
    const {
      value,
      label,
      labelCustom,
      backgroundColor,
      color,
      onClick,
      onMouseDown,
      disabled,
      highlighted,
      icon,
      selected,
      separator,
      animeKeyPressed,
    } = this.props

    const { shift, ctrl, meta } = this.state

    if (separator) {
      return (
        <div className="relative" tabIndex="-1">
          <div
            style={{
              width: '100%',
              backgroundColor: 'rgba(0,0,0,0.08)',
              height: 1,
              margin: label ? '8px 0 6px 0' : undefined,
            }}
          />
          {label ? (
            <div
              className="fontSize10 absolute"
              style={{
                lineHeight: '11px',
                top: -5,
                left: 5,
                backgroundColor: '#ffffff',
                color: 'rgba(0,0,0,0.5)',
                padding: '0 3px',
              }}
            >
              {label}
            </div>
          ) : null}
        </div>
      )
    }

    return (
      <div
        data-item-value={value}
        data-cy={cyLabelFormater('option', value || labelCustom || label)}
        className={cx(classes.searchResultsItem, {
          [classes.disabled]: disabled,
          [classes.highlighted]: highlighted,
          [classes.hasBackgroundColor]: !!backgroundColor,
          [classes.shiftPressed]: animeKeyPressed?.shift && shift,
          [classes.ctrlPressed]: animeKeyPressed?.ctrl && ctrl,
          [classes.metaPressed]: animeKeyPressed?.meta && meta,
        })}
        onClick={!disabled ? onClick : null}
        onMouseDown={onMouseDown}
        style={{
          backgroundColor: disabled ? '#fff' : backgroundColor || '#ffffff',
          color: color || (backgroundColor ? getColorFromBackground(backgroundColor) : 'initial'),
          fontWeight: backgroundColor ? 'bold' : 'initial',
        }}
      >
        {selected && (
          <div style={{ width: 20 }}>
            <FontIcon icon="check" style={{ fontSize: 14 }} />
          </div>
        )}
        <div className="flex row alignCenter" style={!selected ? { paddingLeft: 10 } : undefined}>
          {icon ? <FontIcon icon={icon} className="marginRight5" /> : undefined}
          <span style={{ whiteSpace: 'pre' }}>{toHighlightJSX(labelCustom || label)}</span>
        </div>
      </div>
    )
  }
}
