/** @flow */
import React from 'react'
import { forEach, map } from 'lodash'
import type { Media } from 'app/core/types'
import { getMediaExtension, getGroupExtension } from 'app/containers/Medias/utils'
import { MediaItem } from 'app/components/Medias/MediaItem/MediaItem.tsx'
import classes from './MediasList.module.scss'

export type ModalMediasProps = {|
  medias: Array<Media>,
  onDelete?: (media: Media) => void,
  allowPinMedia: boolean,
  allowValidateMedia: boolean,
|}

export function MediasList(props: ModalMediasProps): React$Node {
  const { medias, onDelete, allowPinMedia, allowValidateMedia } = props

  const mediasByGroup = {}

  forEach(medias, (media) => {
    const mediaExtension = getMediaExtension(media) || 'other'
    const extension = getGroupExtension(mediaExtension)

    if (!mediasByGroup[extension]) mediasByGroup[extension] = []
    mediasByGroup[extension].push(media)
  })

  return (
    <div className="flex flex1 column relative">
      {map(mediasByGroup, (groupedMedias: Array<Media>, groupName: string) => {
        return (
          <div key={`${groupName}-medias`} className="flex row wrap marginBottom20">
            <div className={classes.groupBackground}>{groupName}s</div>
            {groupedMedias.map((media, index) => {
              return (
                <MediaItem
                  key={media.id || String(index)}
                  medias={medias}
                  media={media}
                  onDelete={onDelete ? (e) => onDelete(media) : undefined}
                  allowPinMedia={allowPinMedia}
                  allowValidateMedia={allowValidateMedia}
                />
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
