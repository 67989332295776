// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-AssetsWiki-___AssetsWiki-module__container {
  display: flex;
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
}

.app-pages-AssetsWiki-___AssetsWiki-module__assets {
  flex-shrink: 0;
  padding-right: 20px;
  height: 100%;
}

.app-pages-AssetsWiki-___AssetsWiki-module__articles {
  flex-shrink: 0;
  margin-right: 10px;
  height: 100%;
  overflow: scroll;
}

.app-pages-AssetsWiki-___AssetsWiki-module__articleContent {
  flex: 1;
}

.app-pages-AssetsWiki-___AssetsWiki-module__articleContentTitle {
  font-size: 16px;
  text-align: center;
  border-bottom: var(--mainBorder);
  padding-bottom: 5px;
  margin-bottom: 5px;
  height: 30px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.app-pages-AssetsWiki-___AssetsWiki-module__wikiTreeContent {
  height: calc(100% - 30px);
}

.app-pages-AssetsWiki-___AssetsWiki-module__unread {
  color: var(--colors-orange);
  font-weight: bold;
}

.app-pages-AssetsWiki-___AssetsWiki-module__countercontainer {
  display: flex;
  justify-content: 'space-between';
  min-width: 110px;
}

.app-pages-AssetsWiki-___AssetsWiki-module__countercontainer span {
    min-width: 45px;
    text-align: right;
  }

.app-pages-AssetsWiki-___AssetsWiki-module__countercontainer svg {
    margin: auto;
  }

.app-pages-AssetsWiki-___AssetsWiki-module__treeItemLabel {
  display: flex;
  justify-content: space-between;
  width: inherit;
}
`, "",{"version":3,"sources":["webpack://./app/pages/AssetsWiki/AssetsWiki.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gCAAgC;EAChC,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,gBAAgB;AAQlB;;AAPE;IACE,eAAe;IACf,iBAAiB;EACnB;;AACA;IACE,YAAY;EACd;;AAGF;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  padding: 10px;\n  height: 100%;\n  box-sizing: border-box;\n}\n\n.assets {\n  flex-shrink: 0;\n  padding-right: 20px;\n  height: 100%;\n}\n\n.articles {\n  flex-shrink: 0;\n  margin-right: 10px;\n  height: 100%;\n  overflow: scroll;\n}\n\n.articleContent {\n  flex: 1;\n}\n\n.articleContentTitle {\n  font-size: 16px;\n  text-align: center;\n  border-bottom: var(--mainBorder);\n  padding-bottom: 5px;\n  margin-bottom: 5px;\n  height: 30px;\n  font-weight: bold;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 100%;\n}\n\n.wikiTreeContent {\n  height: calc(100% - 30px);\n}\n\n.unread {\n  color: var(--colors-orange);\n  font-weight: bold;\n}\n\n.countercontainer {\n  display: flex;\n  justify-content: 'space-between';\n  min-width: 110px;\n  span {\n    min-width: 45px;\n    text-align: right;\n  }\n  svg {\n    margin: auto;\n  }\n}\n\n.treeItemLabel {\n  display: flex;\n  justify-content: space-between;\n  width: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-AssetsWiki-___AssetsWiki-module__container`,
	"assets": `app-pages-AssetsWiki-___AssetsWiki-module__assets`,
	"articles": `app-pages-AssetsWiki-___AssetsWiki-module__articles`,
	"articleContent": `app-pages-AssetsWiki-___AssetsWiki-module__articleContent`,
	"articleContentTitle": `app-pages-AssetsWiki-___AssetsWiki-module__articleContentTitle`,
	"wikiTreeContent": `app-pages-AssetsWiki-___AssetsWiki-module__wikiTreeContent`,
	"unread": `app-pages-AssetsWiki-___AssetsWiki-module__unread`,
	"countercontainer": `app-pages-AssetsWiki-___AssetsWiki-module__countercontainer`,
	"treeItemLabel": `app-pages-AssetsWiki-___AssetsWiki-module__treeItemLabel`
};
export default ___CSS_LOADER_EXPORT___;
