/** @flow */

import vars from 'app/styles/vars.js'
import { padStart } from 'lodash'

const { colors } = vars

export function getColorFromBackground(hex: ?string, bw: boolean = true): string {
  if (!hex) return colors.black

  if (hex.indexOf('#') === 0) hex = hex.slice(1)
  if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]

  if (!hex || hex.length !== 6) return colors.black

  let r = parseInt(hex.slice(0, 2), 16)
  let g = parseInt(hex.slice(2, 4), 16)
  let b = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? colors.black : '#ffffff'
  }

  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)

  return `#${padStart(r, 2, '0')}${padStart(g, 2, '0')}${padStart(b, 2, '0')}`
}

// export const getColorFromBackground = (backgroundColor: ?string): string => {
//   if (!backgroundColor) return colors.black

//   if (backgroundColor.length === 4) backgroundColor += backgroundColor.substr(1)

//   const r: number = Number(parseInt(backgroundColor.substr(1, 2), 16).toString(10)) * 0.2126
//   const g: number = Number(parseInt(backgroundColor.substr(3, 2), 16).toString(10)) * 0.7152
//   const b: number = Number(parseInt(backgroundColor.substr(5, 2), 16).toString(10)) * 0.0722

//   const sum = r + g + b
//   const perceivedLightness = sum / 255
//   const threshold = 0.6

//   function hslToRgb(h, s, l) {
//     let r = l
//     let g = l
//     let b = l

//     return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
//   }

//   return `hsl(0, 0%, calc((${perceivedLightness} - ${threshold}) * -10000000%))`
// }
