/** @flow */
import React from 'react'
import { map, sortBy } from 'lodash'
import type { CellInstance } from '../../types'
import { Container } from './Container.jsx'
import { MakeTableFromArray } from './MakeTableFromArray.jsx'
import { LinksCountInput } from './LinksCountInput.jsx'

type Props = {|
  instance: CellInstance,
  category: string,
  statsAccessKey?: string,
|}

export function CellStatsAssetLinks(props: Props): React$Node {
  const { instance, category, statsAccessKey = 'links' } = props
  const {
    stats,
    column,
    tableId,
    state: {
      columnResizing: { columnWidths },
    },
  } = instance
  if (!stats || !stats.default) return null

  const { links_category, links_counts } = stats[statsAccessKey]

  const linksCategory = links_category[category]
  const linksCount = sortBy(
    links_counts
      .filter((lc) => lc.links__attributes__category === category)
      .map(({ links__name, count }) => ({ name: links__name, count })),
    ['name'],
  )

  const width = columnWidths[column.id] || column.width

  function displayValue(value: string | number | Object, attr: string) {
    if (!value && value !== 0) {
      return (
        <span key={attr} style={{ color: 'lightgrey' }}>
          null
        </span>
      )
    }

    if (typeof value === 'object') return <LinksCountInput count={value} width={width ? width - 20 : undefined} />

    return (
      <div key={attr} className="flex row wrap alignCenter">
        <span className="marginRight5 bold">{attr}: </span>
        {value}
      </div>
    )
  }

  return (
    <Container>
      <div className="fullWidth">
        <div className="fitContent">{map(linksCategory, displayValue)}</div>{' '}
        <MakeTableFromArray tableId={tableId} style={{ height: '300px' }} values={linksCount} referenceName="name" />
      </div>
    </Container>
  )
}
