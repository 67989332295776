/** @flow */
import React, { useEffect, useRef, useState } from 'react'
import { rootContextMenu } from 'app/main/rootContainers'
import moment from 'moment'
import forEach from 'lodash/forEach'
import resources from 'app/store/resources'
import type { Imputation, Contract, ID } from 'app/core/types'
import { ContextMenu } from 'app/components/ContextMenu/ContextMenuComponent.jsx'
import { error } from 'app/components/Notifications/notify.js'
import { confirmDelete } from 'app/components/Modal/confirmDelete.jsx'

import { TooltipContract, TooltipImputation } from './Tooltips.jsx'
import type { CellInstance, Cell } from '../../../types'
import classes from './Range.module.scss'

export type Props = {|
  instance: CellInstance,
  cell: Cell,
  contract: ?Contract,
  containerIsFocused: boolean,
  containerRef: React$ElementRef<any>,
|}

export function RowMetaData(props: Props): React$Node {
  const { instance, contract, containerIsFocused, containerRef, cell } = props
  const { ganttProperties, dimensions, unsetSavingCells, setSavingCells, updateCells, state } = instance
  const { showRowImputations } = state.ganttCellsState
  const { ganttStart } = ganttProperties || {}
  const { dayWidth } = dimensions || {}
  const { row } = cell

  const [userContracts, setUserContracts] = useState<void | Array<Contract>>()
  const [userImputations, setUserImputations] = useState<void | Array<Imputation>>()
  const [sibledContract, setSibledContract] = useState<void | ID>()

  const isMount = useRef(true)

  useEffect(() => {
    return () => {
      isMount.current = false
    }
  }, [])

  function getDimensionsFromDates(startDate, endDate) {
    const daysLeft = moment(startDate)?.diff(ganttStart, 'days') || 0
    const _left = dayWidth * daysLeft
    const _width = (moment(endDate).diff(moment(startDate), 'day') + 1) * dayWidth
    return { left: _left, width: _width }
  }

  function getMetaData() {
    if (!contract?.person) return

    const selectedCells = { [cell.id]: cell }

    setSavingCells(selectedCells)

    resources.contracts.fetchAll({ params: { queries: { person__uuid: contract.person } } }).then((resContracts) => {
      const contracts = resContracts.resources
      return resources.imputations
        .fetchAll({ params: { queries: { contract__uuid__in: contracts.map((c) => c.id).toString() } } })
        .then((resImputations) => {
          if (isMount.current) {
            const imputations = resImputations.resources
            setUserContracts(contracts.filter((c) => c.id !== contract.id))
            setUserImputations(imputations.filter((c) => c.id !== row.original.id))
            unsetSavingCells(selectedCells)
            updateCells(selectedCells)
          }
        })
    })
  }

  function removeContractIfEmpty(contractToRemove: Contract) {
    return resources.imputations
      .fetchAll({ params: { queries: { contract__uuid: contractToRemove.id } } })
      .then((res) => {
        if (res.resources.length > 0) {
          return error('Contract is not empty.')
        }
        return confirmDelete({
          render: 'Are you sure to remove this contract ?',
          onValidate: () => resources.contracts.delete(contractToRemove.id),
        })
      })
  }

  function onContextMenu(contractToRemove: Contract) {
    return (event: SyntheticMouseEvent<HTMLElement>) => {
      event.preventDefault()
      const { clientX, clientY } = event.nativeEvent

      rootContextMenu.render(
        <ContextMenu
          clientX={clientX}
          clientY={clientY}
          items={[
            {
              cy: 'removeContractIfEmpty',
              label: "Remove contract if no imputation's present",
              onClick: (e) => removeContractIfEmpty(contractToRemove),
            },
          ]}
        />,
      )
    }
  }

  useEffect(() => {
    if (containerIsFocused && showRowImputations) {
      getMetaData()
    }
  }, [containerIsFocused, showRowImputations])

  useEffect(() => {
    let contracts
    if (sibledContract) {
      contracts = containerRef.current?.querySelectorAll(`div[contract-id="${sibledContract}"]`)
      if (contracts?.length > 0) {
        forEach(contracts, (c) => {
          if (c.getAttribute?.('gantt-cell-contract')) {
            c.style.backgroundColor = '#0095ff'
          } else {
            c.style.opacity = 0.4
          }
        })
      }
    }
    return () => {
      if (contracts?.length > 0) {
        forEach(contracts, (c) => {
          if (c.getAttribute?.('gantt-cell-contract')) {
            c.style.backgroundColor = '#67c0ff'
          } else {
            c.style.opacity = 0.2
          }
        })
      }
    }
  }, [sibledContract])

  if (!showRowImputations) return null

  return (
    <>
      {userContracts &&
        userContracts.map((uContract) => {
          return (
            <TooltipContract key={uContract.id} contract={uContract}>
              <div
                contract-id={uContract.id}
                onMouseEnter={() => setSibledContract(uContract.id)}
                onMouseLeave={() => setSibledContract()}
                onContextMenu={onContextMenu(uContract)}
                style={getDimensionsFromDates(uContract.startDate, uContract.endDate)}
                className={classes.userContract}
                tabIndex="0"
              />
            </TooltipContract>
          )
        })}
      {userImputations &&
        userImputations.map((uImputation) => {
          return (
            <TooltipImputation key={uImputation.id} imputation={uImputation}>
              <div
                contract-id={uImputation.contract}
                onMouseEnter={() => setSibledContract(uImputation.contract)}
                onMouseLeave={() => setSibledContract()}
                onContextMenu={(event) => event.preventDefault()}
                style={getDimensionsFromDates(uImputation.startDate, uImputation.endDate)}
                className={classes.userImputation}
                tabIndex="0"
              />
            </TooltipImputation>
          )
        })}
    </>
  )
}
