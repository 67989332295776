// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetsTree-___AssetsTree-module__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.app-containers-Assets-AssetsTree-___AssetsTree-module__tree {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetsTree/AssetsTree.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,OAAO;AACT","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: baseline;\n}\n\n.tree {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-containers-Assets-AssetsTree-___AssetsTree-module__container`,
	"tree": `app-containers-Assets-AssetsTree-___AssetsTree-module__tree`
};
export default ___CSS_LOADER_EXPORT___;
