// @flow
import { set } from 'lodash'

/**
 * convert ['a.b.c'] to { a: { b: { c: true } } }
 */
export const convertSchema = (schema: Array<string>): { ... } => {
  const obj = {}
  schema.forEach((path) => {
    set(obj, path, true)
  })
  return obj
}
