// @flow

import * as React from 'react'
import { map } from 'lodash'
import Widget from 'app/components/Widget/Widget.jsx'
import resources from 'app/store/resources'
import type { Pipe, ID, Match, Step, TrackingSchema } from 'app/core/types'
import { getResources } from 'app/store/selectors'
import pipe from 'app/core/utils/pipe'
import ExpansionPanel from 'app/components/ExpansionPanel/ExpansionPanel.jsx'
import { assetsTypes } from 'app/core/constants'
import {
  faA,
  faCheckSquare,
  faCube,
  faLink,
  faP,
  faSquareRootAlt,
  faStepForward,
  faStickyNote,
} from '@fortawesome/free-solid-svg-icons'
import { AttributesEditor } from './AttributesEditor/AttributesEditor.jsx'

import { DynamicApprovalsEditor } from './DynamicApprovalsEditor/DynamicApprovalsEditor.jsx'
import classes from './SettingsFollowUp.module.scss'
import { Steps } from './Steps.jsx'
import { TrackingSchemasToolbar } from './TrackingSchemasToolbar.jsx'
import { TrackingSchemas } from './TrackingSchemas.jsx'
import { Asset } from './Asset.jsx'
import { Notes } from './Notes.jsx'
import { AssetLinks } from './AssetLinks.jsx'
import { CalculatedFields } from './CalculatedFields/CalculatedFields.tsx'

type Props = {|
  trackingSchemaId: ?ID,
  projectId: ?ID,
  steps: Array<Step>,
  trackingSchema: ?TrackingSchema,
|}

function SettingsFollowUpContainer(props: Props): React$Node {
  const { trackingSchemaId, trackingSchema, projectId, steps } = props

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <ExpansionPanel title="Assets" data-cy="follow-up-expension-assets" icon={faCube}>
          <Asset assetType={trackingSchema?.assetType} />
        </ExpansionPanel>
        <ExpansionPanel title="Attributes" data-cy="follow-up-expension-attributes" icon={faA}>
          <AttributesEditor
            key={trackingSchemaId}
            displayInfos={true}
            style={{ padding: '0 10px' }}
            assetType={trackingSchema ? trackingSchema.assetType : null}
          />
        </ExpansionPanel>
        <ExpansionPanel title="Parent attributes" data-cy="follow-up-expension-parent-attributes" icon={faP}>
          <AttributesEditor
            key={trackingSchemaId}
            displayInfos={true}
            style={{ padding: '0 10px' }}
            assetType={trackingSchema ? trackingSchema.assetType : null}
            attributeType="parentAttributes"
          />
        </ExpansionPanel>
        {trackingSchema && ['sh', 'mo'].includes(trackingSchema.assetType) ? (
          <ExpansionPanel
            title={`${assetsTypes[trackingSchema.assetType]} production notes`}
            data-cy="follow-up-expension-notes"
            icon={faStickyNote}
          >
            <Notes key={trackingSchemaId} steps={steps} assetType={trackingSchema.assetType} />
          </ExpansionPanel>
        ) : null}
        {trackingSchema?.assetType !== 'mi' && projectId ? (
          <ExpansionPanel title="Asset links" data-cy="follow-up-expension-notes" icon={faLink}>
            <AssetLinks key={trackingSchemaId} projectId={projectId} assetType={trackingSchema?.assetType} />
          </ExpansionPanel>
        ) : null}
        {steps.length > 0 ? (
          <ExpansionPanel title="Steps" data-cy="follow-up-expension-steps" icon={faStepForward}>
            <Steps key={trackingSchemaId} steps={steps} />
          </ExpansionPanel>
        ) : null}
        <ExpansionPanel title="Dynamic Approvals" data-cy="follow-up-expension-dynApp" icon={faCheckSquare}>
          <div className={classes.AttributesBlockContainer} style={{ padding: '0 10px' }}>
            <DynamicApprovalsEditor
              key={trackingSchemaId}
              trackingSchemaId={trackingSchemaId}
              displayInfos={true}
              assetType={trackingSchema?.assetType}
              steps={steps}
            />
          </div>
        </ExpansionPanel>
        {projectId ? (
          <ExpansionPanel
            title="Calculated fields"
            data-cy="follow-up-expension-calculatedFields"
            icon={faSquareRootAlt}
          >
            <CalculatedFields key={trackingSchemaId} projectId={projectId} />
          </ExpansionPanel>
        ) : null}
      </div>
      <div className={classes.rightContainer}>
        <Widget style={{ padding: 5, height: '100%', width: '100%' }}>
          <TrackingSchemasToolbar trackingSchemaId={trackingSchemaId} />
          {trackingSchemaId ? (
            <TrackingSchemas key={trackingSchemaId} trackingSchemaId={trackingSchemaId} projectId={projectId} />
          ) : null}
        </Widget>
      </div>
    </div>
  )
}

const pipeInst: Pipe<{ match: Match }, typeof SettingsFollowUpContainer> = pipe()

export const SettingsFollowUp: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { match } = props
    const { trackingSchemaId, projectId } = match.params

    const trackingSchema = trackingSchemaId ? getResources(state, 'trackingSchemas', trackingSchemaId) : undefined
    const stepProjects = getResources(state, 'stepProjects', { project: projectId }, ['stepInst'])
    const steps = map(stepProjects, ({ stepInst }) => stepInst).filter((step) => {
      if (trackingSchema && trackingSchema.assetType) {
        return step.assetType === trackingSchema.assetType
      }
      return true
    })

    return {
      steps,
      projectId,
      trackingSchemaId,
      trackingSchema,
    }
  })
  .request((props) => {
    return Promise.all([
      props.projectId ? resources.trackingSchemas.fetchByProject(props.projectId) : undefined,
      props.projectId ? resources.stepProjects.fetchByProject(props.projectId) : undefined,
      props.projectId ? resources.dynamicApprovals.fetchByProject(props.projectId) : undefined,
    ])
  })
  .render(({ match, ...rest }) => <SettingsFollowUpContainer {...rest} />)
