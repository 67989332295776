var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect } from 'react';
import { pickBy, keys, values } from 'lodash';
import resources from 'app/store/resources';
import { MUIButton, Flags } from 'app/components/Form';
import { ModalTrigger, confirmDelete, confirm } from 'app/components/Modal';
import { ModalEditAsset } from 'app/containers/Assets/ModalEditAsset/ModalEditAsset.jsx';
import { viewableValue } from 'app/components/Form/FormData/getInput';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import { Permission } from 'app/containers/Permissions';
import classes from './Infos.module.scss';
import { assetIcons } from '../../../../components/Icons/assetsIcons';
import { useSelector } from 'react-redux';
const Infos = ({ asset }) => {
    const { id: projectId } = useSelector((state) => state.project);
    const projectAttributes = useSelector((state) => values(state.attributes.resources));
    const projectConfig = useSelector((state) => state.projectConfig.data);
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield resources.attributes.fetchByProject(projectId);
            }
            catch (err) {
                console.log(err);
            }
        }))();
    }, [projectId]);
    const onDelete = () => {
        return confirmDelete({
            render: `Are you sure you want to delete ${asset.name} ?`,
            onValidate: () => __awaiter(void 0, void 0, void 0, function* () { return yield resources.assets.deleteAsset(asset); }),
            validateMessage: 'Asset deleted',
        });
    };
    const onUndelete = () => {
        return confirm({
            render: `Are you sure you want to undelete ${asset.name} ?`,
            onValidate: () => resources.assets.undeleteAsset(asset),
            validateMessage: 'Asset undeleted',
        });
    };
    const editButton = () => {
        return (_jsx(Permission, { actions: ['projet_assets__update'], children: _jsx(ModalTrigger, { modal: _jsx(ModalEditAsset, { projectId: asset.project, assetId: asset.id }), children: _jsxs(MUIButton, { color: "primary", children: [_jsx(FontIcon, { icon: "fas-edit", className: "marginRight5" }), " Edit"] }) }) }));
    };
    const deleteButton = () => {
        const removed = asset.internalStatus === 2;
        return (_jsx(Permission, { actions: ['projet_assets__delete'], children: !removed ? (_jsxs(MUIButton, { bgColor: "#E56D7A", onClick: onDelete, children: [_jsx(FontIcon, { icon: "fas-trash", className: "marginRight5" }), " Delete"] })) : (_jsxs(MUIButton, { onClick: onUndelete, children: [_jsx(FontIcon, { icon: "fas-trash", className: "marginRight5" }), " Undelete"] })) }));
    };
    const renderAtrribut = (attributName) => {
        const attribute = projectAttributes === null || projectAttributes === void 0 ? void 0 : projectAttributes.find((attr) => attr.name === attributName);
        return (_jsxs("div", { className: classes.field, children: [_jsxs("div", { className: classes.infoLabel, children: [attributName, ":"] }), _jsx("div", { className: classes.fieldValue, style: { wordBreak: 'break-word' }, children: attribute && attribute.attrType
                        ? viewableValue(attribute.attrType, asset.attributes[attributName], attribute.editorType)
                        : String(asset.attributes[attributName]) })] }, attributName));
    };
    const renderattributes = () => {
        if (Object.keys(asset.attributes).length === 0)
            return null;
        // attributes that starts with 'ovm_' are private
        return (_jsxs("div", { className: classes.attributesContainer, children: [_jsx("span", { className: classes.attributesLabel, children: "Attributes" }), keys(pickBy(asset.attributes, (value, key) => !key.startsWith('ovm_') && ['string', 'number', 'boolean'].includes(typeof value))).map((attribut) => renderAtrribut(attribut))] }));
    };
    return (_jsx("div", { className: classes.infos, children: _jsxs("div", { style: { height: '100%', overflowY: 'auto', width: '100%' }, children: [_jsxs("div", { className: classes.leftContent, children: [_jsxs("div", { className: classes.field, children: [_jsx("div", { className: classes.infoLabel, children: "Name:" }), _jsx("div", { className: classes.fieldValue, children: asset.name })] }), _jsxs("div", { className: classes.field, children: [_jsx("div", { className: classes.infoLabel, children: "ID:" }), _jsx("div", { className: classes.fieldValue, children: asset.id })] }), _jsxs("div", { className: classes.field, children: [_jsx("div", { className: classes.infoLabel, children: "Type:" }), _jsxs("div", { className: classes.fieldValue, children: [_jsx(FontIcon, { icon: assetIcons(asset.assetType), className: "marginRight5" }), projectConfig.ASSET_TYPES[asset.assetType]] })] }), asset.flags && asset.flags.length > 0 && (_jsxs("div", { className: classes.field, children: [_jsx("div", { className: classes.infoLabel, children: "Flags:" }), _jsx("div", { className: classes.fieldValue, children: _jsx(Flags, { isBeingEdit: false, resourceType: "asset", id: asset.id }) })] })), renderattributes()] }), _jsxs("div", { className: classes.rightContent, children: [deleteButton(), _jsx("span", { className: "marginLeft10", children: editButton() })] })] }) }));
};
export default Infos;
