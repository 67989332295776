// @flow

import type { StoreResourceDef } from 'app/core/types'

export const taskViewers: StoreResourceDef<> = {
  resourceType: 'taskViewers',
  relations: {
    assetInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'asset',
    },
  },
}
