import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'app/components/Form'
import DatePicker from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import 'react-datepicker/dist/react-datepicker.css'
import './DateTimePicker.scss'

export default class DateTimePickerForm extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    inputRef: PropTypes.func,
    inputClassName: PropTypes.string,
  }

  static defaultProps = {
    showTimeSelect: true,
    timeFormat: 'HH:mm',
    timeIntervals: 15,
    dateFormat: 'MMMM d, yyyy HH:mm',
    timeCaption: 'time',
  }

  static getDerivedStateFromProps(props) {
    const incomValue = props.value
    let value = null

    if (!incomValue);
    else if (typeof incomValue === 'string') value = new Date(incomValue)
    else if (typeof incomValue === typeof new Date()) value = incomValue

    return { value }
  }

  state = {
    value: this.props.value,
  }

  onChange = (value) => {
    this.props.onChange(value.toISOString(), value)
  }

  render() {
    const { value } = this.state
    const { value: __unusedValue, inputClassName, inputRef, ...props } = this.props

    return (
      <div className="relative">
        <DatePicker
          customInput={<Input onChange={Function} ref={inputRef} dataCy="datepicker-input" />}
          popperContainer={(props) => <div style={{ zIndex: 10 }} {...props} />}
          locale={fr}
          {...props}
          selected={value}
          onChange={this.onChange}
        />
      </div>
    )
  }
}
