// @flow

import * as React from 'react'
import Checkbox from 'app/components/Form/Checkbox/Checkbox.jsx'

import classes from './CheckboxGroup.module.scss'

type Option = {
  value: string,
  label: string | Function,
  style?: Object,
  labelStyle?: Object,
}

type Props = {
  onChange: Function,
  options: Array<Option>,
  value: {
    [key: string]: boolean,
  },
  optionStyle?: Object,
}

export class CheckboxGroup extends React.PureComponent<Props> {
  onChange(key: string, checked: boolean) {
    const { onChange, value } = this.props
    onChange({
      ...value,
      [key]: !checked,
    })
  }

  render(): React$Node {
    const { options, value, onChange, ...props } = this.props

    return (
      // $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow
      <div className={classes.container} {...props}>
        {options.map((option) => {
          const { value: key, label, style, labelStyle } = option
          const checked = !!value[key]

          const onClick = () => this.onChange(key, checked)

          return (
            <div key={key} className={classes.optionContainer} style={style}>
              <Checkbox className={classes.pointer} checked={checked} onChange={onClick} />
              {typeof label === 'function' ? (
                label({ onClick })
              ) : (
                <div className={classes.pointer} onClick={onClick} style={labelStyle}>
                  {label}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}
