/** @flow */
import React from 'react'
import { reduce, sortBy, map } from 'lodash'
import type { Column } from '../../types'
import { Read } from './CellFlags'

export const groupingFns: (aggregated?: boolean) => $ElementType<Column, 'groupingFns'> = (aggregated) =>
  aggregated
    ? undefined
    : {
        flags: {
          fn: (rows, columnId, instance) => {
            const orderedFlags = {}
            const unorderedFlags = rows.reduce((prev, row, i) => {
              const value = sortBy(
                map(row.values[columnId], (rel) => rel.flagInst),
                ['id'],
              )

              const resKey = reduce(
                value,
                (acc, asset) => {
                  return `${acc}@${asset.id}`
                },
                '',
              )
              prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
              prev[resKey].push(row)
              return prev
            }, {})
            Object.keys(unorderedFlags)
              .sort((prev, current) => current.split('@').length - prev.split('@').length)
              .forEach((key) => {
                orderedFlags[key] = unorderedFlags[key]
              })

            return orderedFlags
          },
          label: 'Flags',
          headerRow: (cell, instance) => {
            if (!cell.value) return null

            return (
              <Read
                cell={cell}
                isRowExpanded={true}
                prefs={instance.prefs}
                cellWidth={instance.state.columnResizing.columnWidths[cell.column.id]}
              />
            )
          },
        },
      }
