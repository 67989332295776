var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground';
import resources from 'app/store/resources';
import { getResources } from 'app/store/selectors';
import Tooltip from '@material-ui/core/Tooltip';
import { Box } from '@material-ui/core';
import { FlagsRelations } from './FlagsRelations.jsx';
import classes from './Flags.module.scss';
export const Flags = (_a) => {
    var { id, isBeingEdit, resourceType, setDefaultFlags, flagsList, title } = _a, flagRelationProps = __rest(_a, ["id", "isBeingEdit", "resourceType", "setDefaultFlags", "flagsList", "title"]);
    const resource = `${resourceType}s`;
    const flagsSubResources = `${resourceType}FlagsInst`;
    const item = useSelector((state) => {
        return getResources(state, resource, id, [flagsSubResources, `${flagsSubResources}.flagInst`]);
    });
    const flagsRelations = flagsList ? flagsList : (item === null || item === void 0 ? void 0 : item[flagsSubResources]) || [];
    useEffect(() => {
        if (setDefaultFlags) {
            setDefaultFlags(item === null || item === void 0 ? void 0 : item[flagsSubResources]);
        }
    }, []);
    useEffect(() => {
        if (id && !item)
            resources[resource].fetchOne(id);
    }, [id, item]);
    return isBeingEdit ? (_jsxs(Box, { display: "flex", alignItems: "center", width: "100%", children: [title && (_jsxs(Box, { mr: "10px", fontSize: 16, fontWeight: "bold", children: [title, ' '] })), _jsx(FlagsRelations, Object.assign({ resourceID: id, foreignKey: resourceType, category: item.assetType || resourceType || 'take', value: flagsRelations, loading: !item }, flagRelationProps))] })) : (_jsx("div", { className: "flex row wrap fullWidth", children: map(flagsRelations, (flagRelation) => {
            const { flagInst: { id, name, color, description }, } = flagRelation;
            return (_jsx(Tooltip, { title: `Description: ${description || 'No description'}`, children: _jsx("span", { className: classes.item, style: { backgroundColor: color, color: getColorFromBackground(color) }, children: name }) }, id));
        }) }));
};
