/** @flow */
import { RetakeToNewStatus } from 'app/components/Takes/RetakeTakesToNewStatus.jsx'
import { getLastTakeFromTask } from 'app/pages/Project/ProductionManagement/FollowUp/utils'
import type { Take } from 'app/core/types'
import type { HookFunction } from './types'

const updateLastTakeStatusFromTask = (data: Array<Take>, getResourcesAPI: Function, getResources: Function) => {
  const resourceAPI = getResourcesAPI()

  return resourceAPI.tasks.update(
    data
      .map((lastTake) => {
        if (!lastTake.task || !lastTake.status) {
          console.error('Take taskId/status should be set to update task status')
          return undefined
        }

        return {
          id: lastTake.task,
          status: lastTake.status,
        }
      })
      .filter((_) => _),
    { bulk: true, localOnly: true },
  )
}

const createNewTakeIfStatusIsRetake = async (data: Array<Take>) => {
  const { getResources: getStoreResources } = await import('app/store/selectors/getResources')

  const retakeStatusRequests = []
  const updateStatusRequests = []
  let usedStatus

  data.forEach((takeData) => {
    if (!takeData.status) return

    const status = getStoreResources(undefined, 'progressionStatus', takeData.status)

    if (status.statusType === 5) {
      if (!usedStatus) usedStatus = status

      const take = getStoreResources(undefined, 'takes', takeData.id)
      const task = getStoreResources(undefined, 'tasks', take.task, ['takesInst'])
      const lastTake = getLastTakeFromTask(task)

      if (take && lastTake && lastTake.id === take.id) {
        retakeStatusRequests.push({
          name: 'take',
          task: task.id,
        })
      } else {
        updateStatusRequests.push(takeData)
      }
    }
  })

  if (retakeStatusRequests.length > 0) {
    const { promiseModal } = await import('app/components/Modal/promiseModal.jsx')
    const { getInput } = await import('app/components/Form/FormData/getInput.tsx')

    return promiseModal({
      render: RetakeToNewStatus(getStoreResources(undefined, 'progressionStatus'), getInput, usedStatus),
      onValidate: (data: Object) => retakeStatusRequests.map((req) => ({ ...req, status: data.status })),
      onCancel: () => Promise.reject(),
    })
      .then(async (statusRequests) => {
        if (statusRequests) {
          const res = await import('app/store/resources')
          const resources = res.default
          return resources.takes.create(statusRequests)
        }
        return Promise.resolve(updateStatusRequests)
      })
      .catch((err) => {
        return Promise.reject()
      })
  }
  return Promise.resolve(updateStatusRequests)
}

export const afterCreate: HookFunction<Array<Take>> = (getResourcesAPI, getResources) => (data) => {
  updateLastTakeStatusFromTask(data, getResourcesAPI, getResources)
  return Promise.resolve(data)
}

export const afterUpdate: HookFunction<Array<Take>> = (getResourcesAPI, getResources) => (data) => {
  updateLastTakeStatusFromTask(data, getResourcesAPI, getResources)
  return Promise.resolve(data)
}

export const beforeUpdate: HookFunction<Array<Take>> = (getResourcesAPI, getResources) => (data) => {
  return createNewTakeIfStatusIsRetake(data).then(() => data)
}
