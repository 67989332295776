/** @flow */
import React from 'react'
import { FormData, type FormaDataProps } from 'app/components/Form'
import { ModalConfirmForm, ModalTrigger } from 'app/components/Modal'
import type { Attribute, PersonAttribute, Category, ContractAttribute, ID } from 'app/core/types'
import { attrEditsForm, getEditorType, getAttrType } from './attributeAssets'

export type NewAttributeModalProps = {|
  attribute?: $Shape<Attribute> | $Shape<PersonAttribute> | $Shape<ContractAttribute>,
  title: string,
  onSave: (Object) => Promise<any>,
  children: React$Element<any>,
  edit: boolean,
  categories?: Array<Category>,
  noNameVerification?: boolean,
  projectId?: ID,
|}

export function NewAttributeModal(props: NewAttributeModalProps): React$Node {
  const { children, onSave, title, attribute, edit, categories, noNameVerification, projectId } = props

  const attributeToFormdata: (
    $Shape<Attribute> | $Shape<PersonAttribute> | $Shape<ContractAttribute> | void,
  ) => $ElementType<FormaDataProps, 'defaultData'> = (attribute) => {
    const {
      name,
      attrType,
      id,
      category,
      editorParams,
      required,
      displayName,
      fillingHelp,
      isInCreateForm,
      createFormRank,
      userOrdering,
    } = attribute || {}

    const categoryInst = categories?.find((c) => c.id === category)

    return {
      name: displayName || name,
      attrType: attrType ? { label: attrType, value: attrType } : undefined,
      id,
      category: categoryInst ? { label: categoryInst.name, value: categoryInst.id } : undefined,
      required,
      fillingHelp,
      isInCreateForm,
      createFormRank,
      userOrdering,
      ...editorParams,
    }
  }

  const formdataToAttribute: (Object) => { ...Attribute, ...PersonAttribute, ...ContractAttribute } = (attribute) => {
    const {
      name,
      attrType,
      editorType,
      id,
      choice,
      required,
      category: _category,
      displayName,
      fillingHelp,
      isInCreateForm,
      createFormRank,
      userOrdering,
      ..._editorParams
    } = attribute

    let category = ''
    if (typeof _category === 'string') category = _category
    else if (typeof _category === 'object') category = _category.value

    const editorParams = _editorParams || {}

    if (choice && Array.isArray(choice) && choice.length > 0) {
      editorParams.choice = choice
      editorParams.userOrdering = userOrdering
    }

    return {
      name,
      attrType: attrType && getAttrType(attrType.value),
      editorType: getEditorType(attribute),
      id: attribute.id,
      required,
      category,
      editorParams: _editorParams,
      displayName: name,
      project: projectId || undefined,
      fillingHelp,
      isInCreateForm,
      createFormRank,
    }
  }

  return (
    <ModalTrigger
      modal={
        <ModalConfirmForm title={title} width={600}>
          <FormData
            defaultData={attributeToFormdata(attribute)}
            properties={(data) => attrEditsForm(data, Boolean(edit), categories, noNameVerification)}
            onSave={(formValues) => onSave(formdataToAttribute({ ...formValues, id: attribute && attribute.id }))}
            flashNotifSuccessLabel={edit ? 'Attribute updated' : 'Attribute created'}
          />
        </ModalConfirmForm>
      }
    >
      {children}
    </ModalTrigger>
  )
}
