/** @flow */
import customEnv from './customEnv.js'

const { protocol } = window.location

type Env = 'develop' | 'preproduction' | 'production'

type ProcessEnv = {
  NODE_ENV: Env,
  OVM: {
    API_URL: string,
    SOCKET_URL?: string,
    ENV?: Env,
    PORT?: number,
    SENTRY_DSN?: string,
    SENTRY_URL?: string,
    SENTRY_AUTH_TOKEN?: string,
    SENTRY_ORG?: string,
    SENTRY_PROJECT?: string,
    CI_COMMIT_TAG?: string,
    CI_COMMIT_REF_NAME?: string,
  },
}

type Settings = {
  cookieToken: 'ovm_auth',
  git: {
    CI_COMMIT_TAG?: string,
    CI_COMMIT_REF_NAME?: string,
  },
  sentry: {
    SENTRY_DSN?: string,
    SENTRY_URL?: string,
    SENTRY_AUTH_TOKEN?: string,
    SENTRY_ORG?: string,
    SENTRY_PROJECT?: string,
  },
  env: {
    API_URL: string,
    SOCKET_URL: string | null,
    NODE_ENV: ?Env,
    PORT?: number,
  },
}

function constructUrl(url: ?string, port?: number): string {
  if (!url) return ''
  return `${protocol}//${url}${port ? `:${port}` : ''}`
}

const processEnv: Object | ProcessEnv = process.env

const { NODE_ENV, OVM } = processEnv

const {
  API_URL,
  SOCKET_URL,
  ENV,
  PORT,
  SENTRY_DSN,
  SENTRY_URL,
  SENTRY_AUTH_TOKEN,
  SENTRY_ORG,
  SENTRY_PROJECT,
  CI_COMMIT_TAG,
  CI_COMMIT_REF_NAME,
} = { ...OVM, ...customEnv }

const env: Env = ENV || NODE_ENV

if (NODE_ENV !== 'test' && !API_URL) throw new Error('API_URL should be setted up.')

const settings: Settings = Object.freeze({
  cookieToken: 'ovm_auth',
  env: {
    API_URL: constructUrl(API_URL, PORT),
    SOCKET_URL: constructUrl(SOCKET_URL),
    NODE_ENV: env,
  },
  sentry: {
    SENTRY_DSN,
    SENTRY_URL,
    SENTRY_AUTH_TOKEN,
    SENTRY_ORG,
    SENTRY_PROJECT,
  },
  git: {
    CI_COMMIT_TAG,
    CI_COMMIT_REF_NAME,
  },
})

export const isProd = (): boolean => env === 'production'

export default settings
