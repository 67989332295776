// @flow
import * as React from 'react'
import { startsWith, forEach, isNumber } from 'lodash'
import type { DropProps, DropState } from './DragNDrop.type'
import { Drag } from './Drag.jsx'

const getOriginTargetData = (event) => {
  const id = event.dataTransfer.types.find((type) => startsWith(type, 'drag-'))
  return Drag.originElData[id]
}

const unsetOriginTargetData = (event) => {
  const id = event.dataTransfer.types.find((type) => startsWith(type, 'drag-'))
  Drag.originElData[id] = undefined
}

export class Drop extends React.PureComponent<DropProps, DropState> {
  state: DropState = {
    initClientHeight: 0,
  }

  dropRef: Object | null = React.createRef()

  componentDidMount() {
    if (this.dropRef) {
      this.setState({ initClientHeight: this.dropRef.clientHeight })
    }
  }

  dropHandler: Function = (event: Object) => {
    event.stopPropagation()
    const { drop, dragLeave } = this.props

    if (this.dropRef) {
      const { initClientHeight } = this.state
      this.dropRef.style.height = `${initClientHeight}px`
      event.target.style.height = `${initClientHeight}px`
      this.dropRef.style.transitionDuration = '0s'
      event.target.style.transitionDuration = '0s'

      this.dropRef.querySelectorAll('.drop-element').forEach((el) => el.remove())
      event.target.querySelectorAll('.drop-element').forEach((el) => el.remove())

      setTimeout(() => {
        // $FlowFixMe
        this.dropRef.style.transitionDuration = '0.4s'
      }, 400)
    }

    unsetOriginTargetData(event)

    const rawContent = event.dataTransfer.getData('text/x-asset')
    let content = JSON.parse(rawContent)

    if (typeof content === typeof {}) {
      content = { ...content, timestamp: Date.now() }
    }

    typeof dragLeave === 'function' && dragLeave()
    drop(content)
  }

  dragEnterHandler: Function = (event: Object) => {
    const { dragEnter, dropStyle } = this.props
    const originTarget = getOriginTargetData(event)
    const { clientHeight } = originTarget

    if (this.dropRef) {
      let dropHeight = clientHeight
      const child = window.document.createElement('div')
      child.className = 'drop-element'
      child.style.height = `${dropHeight}px`

      dropStyle &&
        forEach(dropStyle, (value, key) => {
          if (key === 'height') dropHeight = value
          if (isNumber(value)) value = `${value}px`
          child.style[key] = value
        })

      // $FlowFixMe
      this.dropRef.style.height = `${this.dropRef.clientHeight + dropHeight}px`
      // $FlowFixMe
      event.target.style.height = `${this.dropRef.clientHeight + dropHeight}px`

      // $FlowFixMe
      this.dropRef.firstChild && this.dropRef.firstChild.appendChild(child)
      event.target.firstChild && event.target.firstChild.appendChild(child)
    }

    typeof dragEnter === 'function' && dragEnter(event, originTarget)
  }

  dragLeaveHandler: Function = (event: Object) => {
    const { dragLeave } = this.props
    const { initClientHeight } = this.state
    const originTarget = getOriginTargetData(event)

    if (this.dropRef) {
      this.dropRef.style.height = `${initClientHeight}px`
      event.target.style.height = `${initClientHeight}px`

      this.dropRef.querySelectorAll('.drop-element').forEach((el) => el.remove())
      event.target.querySelectorAll('.drop-element').forEach((el) => el.remove())
    }

    typeof dragLeave === 'function' && dragLeave(event, originTarget)
  }

  dragOverHandler: Function = (event: Object) => {
    event.preventDefault()
    const { dragOver } = this.props
    const originTarget = getOriginTargetData(event)
    const { hideOrigin, ref } = originTarget

    if (hideOrigin) {
      ref.hidden = true
    }

    typeof dragOver === 'function' && dragOver(event, originTarget)
  }

  render(): React$Node {
    const { children, drop, dragEnter, dragLeave, dragOver, dropStyle, ...props } = this.props

    if (!drop || typeof drop !== typeof Function) return children

    return (
      <div
        onDrop={this.dropHandler}
        onDragEnter={this.dragEnterHandler}
        onDragLeave={this.dragLeaveHandler}
        onDragOver={this.dragOverHandler}
        style={{
          transitionProperty: 'all',
          transitionDuration: '0.4s',
          transitionTimingFunction: 'ease-out',
        }}
        ref={(ref: Object | null) => {
          this.dropRef = ref
        }}
      >
        <div {...props}>{children}</div>
      </div>
    )
  }
}
