// @flow

import React from 'react'
import { forEach, startsWith, sortBy, map } from 'lodash'
import pipe from 'app/core/utils/pipe'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import { FormData, MUIButton, AutocompleteSingle, FlagsRelations } from 'app/components/Form'
import api from 'app/core/api/api.js'
import resources from 'app/store/resources'
import { ModalConfirmForm, confirmDelete, ModalTrigger, openModal } from 'app/components/Modal'
import { assetsTypes } from 'app/core/constants/assetsTypes'
import type { ID, Asset, Attribute, Options, Option, Flag } from 'app/core/types'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import { getResources } from 'app/store/selectors'
import { regex } from 'app/libs/helpers'
import { getInputByType } from 'app/components/Form/FormData/getInput.tsx'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL.js'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { updateAssetFlags } from 'app/core/utils/saveAssetFlags'
import { ModalUpdateEpisodicAsset } from './ModalUpdateEpisodicAsset.tsx'
import { getAssetTypesOptions } from './utils.js'

export type Props = {|
  projectId: ?ID,
  parent?: Asset,
  asset?: Asset,
  assetTypes: Array<$Keys<typeof assetsTypes>>,
  projectAttributes: Array<Attribute>,
  onSave?: ($Exact<{ asset: $Shape<Asset>, flags: Array<$Shape<Flag>>, taskGroup?: ?ID }>) => Promise<any>,
  onChange?: (asset: Asset) => void,
|}

type State = {|
  attributes: Options,
|}

function Attribut({ attribute, label, data, setData, asset, onRemoveAttribut, ...props }: *) {
  return (
    <div className="flex row noWrap fullWidth">
      <div className="flex1" style={{ maxWidth: '100%', maxHeight: 150 }}>
        {getInputByType({
          type: attribute.attrType,
          params: attribute.editorParams,
          editorType: attribute.editorType,
          key: `$attribut__${label}`,
          data,
          props,
          setData,
        })}
      </div>
      <div className="margin5">
        <MUIButton
          onClick={() =>
            confirmDelete({
              title: `Delete ${label} from asset`,
              onValidate: onRemoveAttribut(asset, label),
              render: 'Are you sure you want to delete this attribut from asset ?',
            })
          }
          style={{ minWidth: 0 }}
          variant="text"
          tooltip={`Remove ${label} attribut on this asset`}
        >
          <FontIcon icon="fas-trash" />
        </MUIButton>
      </div>
    </div>
  )
}

class ModalEditAssetBase extends React.Component<Props, State> {
  static defaultProps = {
    assetTypes: Object.keys(assetsTypes),
  }

  state = {
    attributes: this.props.asset
      ? Object.entries(this.props.asset.attributes).map(([label, value]) => ({ label, value }))
      : [],
  }

  properties = (attributes) => [
    {
      key: 'name',
      label: 'Name',
      type: 'string',
      elementProps: (data) => {
        const {
          assetType: { value: assetType },
        } = data
        return {
          isRequired: true,
          validators: {
            nameValidation: (value: string) => {
              if (
                ['mo', 'sh', 'sq', 'pj', 'ep', 'fo', 'mi'].includes(assetType) &&
                regex.is.assetName.test(value) === false
              ) {
                return 'Only lowercase alphanumeric characters and hyphens are allowed.'
              }
              if (['us', 'ph'].indexOf(assetType) !== -1 && regex.is.userName.test(value) === false) {
                return 'Only lowercase alphanumeric characters, points and hyphens are allowed.'
              }
              if (['gp'].indexOf(assetType) !== -1 && regex.is.groupName.test(value) === false) {
                return 'Only lowercase characters, underscores and hyphens are allowed.'
              }
              return false
            },
          },
        }
      },
    },
    {
      key: 'assetType',
      label: 'Type',
      type: 'select',
      elementProps: (data, setData) => {
        return {
          options: getAssetTypesOptions(this.props.assetTypes, 'include').filter(
            (opt) => ['gp', 'ph', 'pc', 'sq'].indexOf(opt.value) === -1,
          ),
          isRequired: true,
          fullWidth: true,
          placeholder: 'Choose type...',
          onChange: (val) => {
            if (val.value === 'mi' && data?.parent?.data && data.parent.data.assetType !== 'fo') {
              setData({ parent: undefined, assetType: 'mi' })
            }
            setData({ assetType: val.value })
          },
        }
      },
    },
    {
      key: 'project',
      label: 'Project',
      type: 'autocomplete',
      element: AssetsSelect,
      elementProps: (data, setData) => ({
        assetTypes: ['pj'],
        additionnalOptions: [{ label: 'No project', value: null }],
        onChange: (project: Option) => setData({ project }),
        disabled: !!getProjectIdFromURL(),
        value: { ...data.project, assetType: 'pj' },
        placeholder: 'Select a project',
      }),
    },
    {
      key: 'parent',
      label: 'Parent',
      type: 'autocomplete',
      element: AssetsSelect,
      elementProps: (state, setData) => {
        const assetTypeValue = state.assetType
        let assetTypes = ['pj', 'ep', 'fo']

        if (assetTypeValue === 'mi') assetTypes = ['fo']

        return {
          key: assetTypeValue,
          assetTypes,
          onChange: (value) => {
            const { asset } = this.props
            if (asset && asset.attributes && asset.attributes.ovm_isEpisodic) {
              return openModal(
                <ModalUpdateEpisodicAsset onValidate={(taskGroup: ID) => setData({ parent: value, taskGroup })} />,
              )
            }
            return setData({ parent: value })
          },
          placeholder: 'Search asset',
          isRequired: true,
          value: state.parent,
          inputProps: {
            disabled: !assetTypeValue,
          },
        }
      },
    },
    {
      key: 'thumbnail',
      label: 'Thumbnail',
      type: 'file',
      elementProps: {
        isRequired: false,
        accept: 'image/*',
        multiple: false,
      },
    },
    {
      key: 'flags',
      label: 'Flags',
      type: 'autocomplete',
      element: FlagsRelations,
      isShow: ({ assetType }) => assetType,
      elementProps: (data: Object, setData: Function) => {
        return {
          foreignKey: 'asset',
          resourceID: this.props.asset && this.props.asset.id,
          category: data.assetType,
          itemCategory: data.assetType,
          placeholder: 'Select a flag',
        }
      },
    },
    ...attributes
      .map(({ label, value }: *) => {
        const { projectAttributes, asset } = this.props

        const attribute = projectAttributes.find((attr) => attr.name === label)

        if (!attribute)
          return {
            key: `$hidden_attribut__${label}`,
            label,
            type: 'string',
            elementProps: {
              disabled: true,
              value,
            },
          }

        return {
          key: `$attribut__${label}`,
          label,
          type: 'custom',
          element: (data: any, setData: Function) => {
            return (
              <Attribut
                attribute={attribute}
                label={label}
                data={data}
                setData={setData}
                asset={asset}
                onRemoveAttribut={this.onRemoveAttribut}
              />
            )
          },
        }
      })
      .filter((_) => _),
  ]

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.asset?.attributes &&
      nextProps.asset?.attributes &&
      JSON.stringify(this.props.asset.attributes) !== JSON.stringify(nextProps.asset?.attributes)
    ) {
      const attributes = Object.entries(nextProps.asset.attributes).map(([label, value]) => ({ label, value }))

      this.setState({
        attributes,
      })
    }
  }

  onRemoveAttribut = (asset: Object, name: string) => (e) => {
    this.setState((state) => ({ attributes: state.attributes.filter((attr) => attr.label !== name) }))

    if (!asset) return Promise.resolve()

    return resources.assets.update({
      id: asset.id,
      attributes: {
        ...asset.attributes,
        [name]: null,
      },
    })
  }

  onSave = (...args) => {
    return this.props.asset ? this.onUpdate(...args) : this.onCreate(...args)
  }

  async onUpdate(data, formState, defaultData, handleClose) {
    const { asset, projectId, onSave, onChange } = this.props
    if (!asset) return null

    const attributes = {}

    forEach(data, (value, key) => {
      if (startsWith(key, '$attribut__')) attributes[key.replace('$attribut__', '')] = value
    })

    // avoid to defined a children of current asset as parent
    if (data.parent.value !== asset.parent) {
      const res = await api.assets.fullPath({ id: data.parent.value })

      const results = res.results || res

      const fullPathId = results.map(({ id }) => id)
      if (fullPathId.includes(asset.id)) {
        return console.error({ message: `This asset is a children of current asset`, propertyKey: 'parent' })
      }
    }

    let media

    if (!data.thumbnail[0]) {
      media = null
      if (asset.thumbnail) resources.medias.delete(asset.thumbnail)
    } else if (data.thumbnail[0] && startsWith(data.thumbnail[0].url, 'blob:')) {
      const res = await resources.medias.upload({ files: data.thumbnail })
      media = res && res.resources && res.resources[0].id
      if (asset.thumbnail) resources.medias.delete(asset.thumbnail)
    } else media = undefined

    const assetattributes = {}

    forEach(asset.attributes, (attr, name: string) => {
      if (!startsWith(name, 'ovm_')) assetattributes[name] = attr
    })

    const attributesWasUpdated = JSON.stringify(attributes) !== JSON.stringify(assetattributes)

    const assetToUpdate = {
      id: asset.id,
      name: data.name !== asset.name ? data.name : undefined,
      project: data.project && data.project.value !== asset.project ? data.project.value : undefined,
      parent: data.parent && data.parent.value !== asset.parent ? data.parent.value : undefined,
      assetType: data.assetType !== asset.assetType ? data.assetType : undefined,
      thumbnail: media === asset.thumbnail || (media && asset.thumbnail === undefined) ? undefined : media,
      attributes: attributesWasUpdated ? attributes : undefined,
    }

    if (onSave) {
      return onSave({ asset: assetToUpdate, flags: data.flags, taskGroup: formState.taskGroup })
    }

    const config = projectId ? { params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } } } : undefined
    const promises = [
      resources.assets.update(assetToUpdate, config),
      updateAssetFlags(asset, data.flags),
      formState.taskGroup && api.changeEpisodicAssetParent({ asset: asset.id, taskGroup: formState.taskGroup }),
    ]
    const res = await Promise.all(promises)
    if (onChange) onChange(getResources(undefined, 'assets', res[0].resources[0].id))
    return res
  }

  async onCreate(data, formState) {
    const { projectId, onSave, onChange } = this.props
    const attributes = {}

    forEach(data, (value, key) => {
      if (startsWith(key, '$attribut__')) attributes[key.replace('$attribut__', '')] = value
    })

    const res = data.thumbnail.length > 0 ? await resources.medias.upload({ files: data.thumbnail }) : null
    const media = res && res.resources && res.resources[0]

    const assetToCreate = {
      name: data.name,
      parent: data.parent.value,
      attributes,
      assetType: data.assetType,
      thumbnail: media && media.id,
      project: (data.project && data.project.value) || projectId || null,
    }

    if (onSave) {
      return onSave({ asset: assetToCreate, flags: data.flags })
    }

    const saveAssetsRes = await resources.assets.save({ ...assetToCreate, assetFlags: data.flags })
    if (onChange) onChange(getResources(undefined, 'assets', saveAssetsRes.resources[0].id))
    return updateAssetFlags(saveAssetsRes[0].resources[0], data.flags)
  }

  modalAddAttributButton = () => {
    const { projectAttributes = [] } = this.props

    return (
      <ModalTrigger
        modal={
          <ModalConfirmForm
            title="Add an attribute on the asset"
            validateLabel="Add"
            draggable={false}
            resizable={false}
          >
            <FormData
              defaultData={{}}
              properties={[
                {
                  key: 'attributes',
                  label: 'attributes',
                  type: 'autocomplete',
                  element: AutocompleteSingle,
                  elementProps: (data: Object, setData: Function) => {
                    const options = sortBy(projectAttributes, ['name'])
                      .map((attr) => {
                        const { attributes } = this.state
                        if (attributes.find((currentAttr) => currentAttr.label === attr.name)) return undefined

                        return {
                          label: attr.name,
                          value: attr.name,
                        }
                      })
                      .filter((_) => _)

                    return {
                      value: data.attributes,
                      options: () => options,
                      placeholder: 'Select an attribute',
                      searchable: true,
                      isRequired: true,
                      onChange: (value: Object) => setData({ attributes: value }),
                    }
                  },
                },
              ]}
              onSave={({ attributes }) =>
                new Promise((resolve) => {
                  this.setState((state) => {
                    const newattributes = [...state.attributes]
                    if (attributes.label) newattributes.push({ label: attributes.label, value: '' })
                    return { ...state, attributes: newattributes }
                  }, resolve)
                })
              }
            />
          </ModalConfirmForm>
        }
      >
        <MUIButton>
          <FontIcon icon="fas-plus" className="marginRight5" />
          Add attribut
        </MUIButton>
      </ModalTrigger>
    )
  }

  convertAssetToFormData(asset: Object) {
    const attributes = {}
    const { parent: propsParent, projectId } = this.props

    const parent = asset.parentInst || propsParent

    // $FlowFixMe[incompatible-type] $FlowFixMe Error when updating flow
    this.state.attributes.forEach(({ label, value }: *) => {
      attributes[`$attribut__${label}`] = value
    })

    let project: ?Asset

    if (asset.project) project = getResources(undefined, 'assets', asset.project)
    if (projectId) project = getResources(undefined, 'assets', projectId)

    return {
      ...asset,
      thumbnail: asset.thumbnailInst ? [asset.thumbnailInst] : [],
      assetType: asset.assetType,
      parent: parent
        ? {
            value: parent.id,
            label: parent.name,
            assetType: parent.assetType,
          }
        : null,
      project: project ? { value: project.id, label: project.name } : null,
      flags: asset ? asset.assetFlagsInst : [],
      ...attributes,
    }
  }

  render(): React$Node {
    const defaultAsset = {
      assetType: '',
      assetFlagsInst: {},
      attributes: {},
    }

    const {
      asset = defaultAsset,
      assetTypes,
      projectId,
      projectAttributes,
      parent,
      onSave,
      onChange,
      ...rest
    } = this.props
    const { attributes } = this.state

    return (
      <ModalConfirmForm
        draggable={false}
        title={`${this.props.asset ? 'Edit' : 'Create'} asset`}
        resizable={true}
        minWidth={600}
        width={800}
        height={800}
        extendsButtons={this.modalAddAttributButton()}
        validateLabel={`${this.props.asset ? 'Edit' : 'Add'} asset`}
        cancelLabel={`${this.props.asset ? 'Cancel' : 'Close'}`}
        showValidateAndContinue={!this.props.asset}
        {...rest}
      >
        {({ handleClose }) => (
          <FormData
            defaultData={this.convertAssetToFormData(asset)}
            properties={this.properties(attributes)}
            onSave={(...values) => this.onSave(...values, handleClose)}
            flashNotifSuccessLabel={this.props.asset ? 'Asset updated' : 'Asset created'}
          />
        )}
      </ModalConfirmForm>
    )
  }
}

const pipeInst: Pipe<{ assetId?: ?ID, projectId?: ?ID, parentId?: ?ID }, typeof ModalEditAssetBase> = pipe()

export const ModalEditAsset: React$ComponentType<any> = pipeInst
  .connect((state, props) => ({
    projectAttributes: map(getResources(state, 'attributes', { project: props.projectId || null })),
    asset: props.assetId
      ? getResources(state, 'assets', props.assetId, ['assetFlagsInst.flagInst', 'parentInst', 'thumbnailInst'])
      : undefined,
    parent: props.parentId ? getResources(state, 'assets', props.parentId) : undefined,
  }))
  .request((props) => {
    const { projectId, parentId } = props
    const config = (queries) => ({
      params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' }, queries: { page_size: 1000, ...queries } },
    })

    return Promise.all([
      projectId
        ? resources.attributes.fetchByProject(projectId, config())
        : resources.attributes.fetchAll(config({ project: null })),
      parentId && resources.assets.fetchOne(parentId, config()),
    ])
  })
  .renderLoader(() => null)
  .render(({ assetId, parentId, ...rest }) => <ModalEditAssetBase {...rest} />)
