var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useMemo } from 'react';
import moment from 'moment';
import { FormData } from 'app/components/Form';
import { ModalConfirmForm } from 'app/components/Modal';
import resources from 'app/store/resources';
import { getResources } from 'app/store/selectors';
import store from 'app/store';
import classes from './ModalAddAbsenceActivity.module.scss';
export function ModalAddAbsenceActivity(props) {
    const { paginatedList, userId, preSelectedDate = new Date(), comment, duration, onChange, showDuration, preSelectedCells, period, tableTime } = props, rest = __rest(props, ["paginatedList", "userId", "preSelectedDate", "comment", "duration", "onChange", "showDuration", "preSelectedCells", "period", "tableTime"]);
    const userProjects = useMemo(() => getResources(undefined, 'assets', store.getState().user.projects), []);
    function getDurationFromForm(durationText) {
        if (!durationText)
            return 60 * 60 * 8;
        return 60 * 60 * 4;
    }
    function getDates(startDate, endDate) {
        const dateArray = [];
        let currentDate = moment(new Date(startDate));
        while (currentDate <= moment(new Date(endDate))) {
            dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }
    function onAddCustomActivity(data) {
        return __awaiter(this, void 0, void 0, function* () {
            if (data.minDate && data.maxDate) {
                const allDates = getDates(data.minDate, data.maxDate);
                const newData = allDates.map((date) => {
                    return {
                        date,
                        durationText: data.durationText,
                        comment,
                    };
                });
                if (data.minDateAfternoon) {
                    newData[0].durationText = 'afternoon';
                    newData[0].comment = 'afternoon';
                }
                if (data.maxDateMorning) {
                    newData[newData.length - 1].durationText = 'morning';
                    newData[newData.length - 1].comment = 'morning';
                }
                const asyncData = newData.map((element) => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    // TO DO : check if we can retrieve user leave in the store to avoir request
                    const getRes = yield resources.activities.fetchAll({
                        params: { queries: { date: element.date, user: userId } },
                    });
                    const leaveActivity = getRes.resources.find((activity) => activity.activityType === 'lv');
                    const postRes = yield resources.activities[(leaveActivity === null || leaveActivity === void 0 ? void 0 : leaveActivity.id) ? 'update' : 'create'](Object.assign(Object.assign({}, data), { id: leaveActivity === null || leaveActivity === void 0 ? void 0 : leaveActivity.id, date: element.date, duration: getDurationFromForm(element.durationText), user: userId, activityType: 'lv', project: data.project ? data.project.value : undefined, comment: (_a = element.comment) !== null && _a !== void 0 ? _a : '' }));
                    return postRes;
                }));
                const res = yield Promise.all(asyncData);
                const results = res.map((el) => el.resources[0]);
                return onChange === null || onChange === void 0 ? void 0 : onChange(results);
            }
            // TO DO : check if we can retrieve user leave in the store to avoir request
            const getRes = yield resources.activities.fetchAll({
                params: { queries: { date: moment(data.date).format('YYYY-MM-DD'), user: userId } },
            });
            const leaveActivity = getRes.resources.find((activity) => activity.activityType === 'lv');
            const postRes = yield resources.activities[(leaveActivity === null || leaveActivity === void 0 ? void 0 : leaveActivity.id) ? 'update' : 'create'](Object.assign(Object.assign({}, data), { id: leaveActivity === null || leaveActivity === void 0 ? void 0 : leaveActivity.id, date: moment(data.date).format('YYYY-MM-DD'), duration: (showDuration ? getDurationFromForm(data.durationText) : duration) || 0, user: userId, activityType: 'lv', project: data.project ? data.project.value : undefined, comment: showDuration ? data.durationText || '' : comment }));
            onChange === null || onChange === void 0 ? void 0 : onChange(postRes.resources);
            return postRes;
        });
    }
    let preSelectedCellMinDate = new Date();
    let preSelectedCellMaxDate = new Date();
    if (preSelectedCells && !tableTime) {
        preSelectedCellMinDate = moment(Object.keys(preSelectedCells)[0], 'DD MM YYYY').toDate();
        preSelectedCellMaxDate = moment(Object.keys(preSelectedCells)[Object.keys(preSelectedCells).length - 1], 'DD MM YYYY').toDate();
    }
    if (preSelectedCells && tableTime) {
        preSelectedCellMinDate = moment(Object.keys(preSelectedCells)[0], 'YYYY MM DD').toDate();
        preSelectedCellMaxDate = moment(Object.keys(preSelectedCells)[Object.keys(preSelectedCells).length - 1], 'YYYY MM DD').toDate();
    }
    return (_jsxs(ModalConfirmForm, Object.assign({ title: "Add a leave", minWidth: 500 }, rest, { children: [_jsxs("div", { className: classes.leavesReminder, children: [_jsx("h4", { children: "Rappel" }), _jsx("p", { children: "La demande de cong\u00E9s doit se faire au minimum 15 jours avant, et 1 mois pour les cong\u00E9s d'\u00E9t\u00E9." })] }), _jsx(FormData, { onSave: onAddCustomActivity, flashNotifSuccessLabel: "Leave added", defaultData: {
                    date: preSelectedDate,
                    minDate: preSelectedCellMinDate,
                    maxDate: preSelectedCellMaxDate,
                }, properties: (preSelectedCells && Object.keys(preSelectedCells).length < 2) || (!preSelectedCells && !period)
                    ? [
                        {
                            key: 'project',
                            label: 'Project',
                            type: 'autocomplete',
                            elementProps: () => ({
                                options: () => [
                                    ...userProjects.map((project) => ({ label: project.name, value: project.id })),
                                    { label: '-', value: null },
                                ].sort((a, b) => a.label.localeCompare(b.label)),
                                style: { width: '100%' },
                            }),
                        },
                        showDuration
                            ? {
                                key: 'durationText',
                                label: 'Duration',
                                type: 'select',
                                elementProps: {
                                    options: [
                                        { value: 'morning', label: 'Morning' },
                                        { value: 'afternoon', label: 'Afternoon' },
                                        { value: '', label: 'All day' },
                                    ],
                                    style: { width: '100%' },
                                },
                            }
                            : null,
                        {
                            key: 'date',
                            label: 'Date',
                            type: 'string',
                            elementProps: (data, setData) => ({
                                type: 'date',
                                isRequired: true,
                                value: data.date ? moment(data.date).format('YYYY-MM-DD') : undefined,
                                min: '2000-01-01',
                                onChange: (e) => setData({ date: e.target.value }),
                            }),
                        },
                    ].filter(Boolean)
                    : [
                        {
                            key: 'project',
                            label: 'Project',
                            type: 'autocomplete',
                            elementProps: (data, setData) => ({
                                options: () => [{ label: '-', value: null }].concat(userProjects.map((project) => ({ label: project.name, value: project.id }))),
                                style: { width: '100%' },
                            }),
                        },
                        {
                            key: 'minDate',
                            label: 'Min Date',
                            type: 'string',
                            elementProps: (data, setData) => ({
                                type: 'date',
                                isRequired: true,
                                value: data.minDate ? moment(data.minDate).format('YYYY-MM-DD') : undefined,
                                min: '2000-01-01',
                                onChange: (e) => setData({ minDate: e.target.value }),
                            }),
                        },
                        {
                            key: 'minDateAfternoon',
                            label: 'Only afternoon',
                            type: 'checkbox',
                        },
                        {
                            key: 'maxDate',
                            label: 'Max Date',
                            type: 'string',
                            elementProps: (data, setData) => ({
                                type: 'date',
                                isRequired: true,
                                value: data.maxDate ? moment(data.maxDate).format('YYYY-MM-DD') : undefined,
                                min: '2000-01-01',
                                onChange: (e) => setData({ maxDate: e.target.value }),
                            }),
                        },
                        {
                            key: 'maxDateMorning',
                            label: 'Only morning',
                            type: 'checkbox',
                        },
                    ].filter(Boolean) })] })));
}
