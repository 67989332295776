import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Icon from '../Icon.jsx'
import classes from '../Icon.module.scss'

export default class Logo extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  }

  static defaultProps = {
    width: 40,
    height: 40,
  }

  render() {
    const className = cx(classes.logo, {
      [this.props.className]: this.props.className,
    })

    return (
      <Icon {...this.props} className={className} viewBox="0 0 40 40" data-cy="ovm-logo">
        <path
          fill={this.props.color}
          d="M35.9,10c-2.3,0-4.1,1.8-4.1,4c0,0.3,0.1,0.7,0.1,1L25,17.5c-0.8-1.5-2.1-2.7-3.8-3.2L22.7,8c0.1,0,0.3,0,0.4,0 c2.3,0,4.1-1.8,4.1-4c0-2.2-1.9-4-4.1-4C20.8,0,19,1.8,19,4c0,1.7,1.1,3.2,2.7,3.7L20.2,14c-0.3,0-0.6-0.1-1-0.1 c-2.1,0-3.9,0.9-5,2.4l-6.3-4c0.3-0.5,0.4-1.1,0.4-1.8c0-2.2-1.9-4-4.1-4c-2.3,0-4.1,1.8-4.1,4c0,2.2,1.9,4,4.1,4 c1.3,0,2.4-0.6,3.1-1.4l6.3,4c-0.5,0.9-0.8,1.9-0.8,3c0,1.5,0.5,2.8,1.4,3.8L12,25.9c-0.7-0.6-1.6-0.9-2.7-0.9c-2.3,0-4.1,1.8-4.1,4 s1.9,4,4.1,4c2.3,0,4.1-1.8,4.1-4c0-0.9-0.3-1.7-0.8-2.3l2.1-1.9c1.1,1,2.7,1.6,4.3,1.6c1.3,0,2.4-0.4,3.4-1l5.9,7.7 c-0.8,0.7-1.3,1.8-1.3,2.9c0,2.2,1.9,4,4.1,4c2.3,0,4.1-1.8,4.1-4c0-2.2-1.9-4-4.1-4c-0.7,0-1.4,0.2-2,0.5l-5.9-7.7 c1.3-1.1,2.2-2.8,2.2-4.7c0-0.6-0.1-1.2-0.3-1.8l6.8-2.5c0.7,1.3,2.1,2.1,3.6,2.1c2.3,0,4.1-1.8,4.1-4C40,11.8,38.1,10,35.9,10z M20,4c0-1.7,1.4-3,3.1-3c1.7,0,3.1,1.4,3.1,3c0,1.7-1.4,3-3.1,3S20,5.7,20,4z M4.1,13.5c-1.7,0-3.1-1.4-3.1-3s1.4-3,3.1-3 c1.7,0,3.1,1.4,3.1,3C7.3,12.2,5.9,13.5,4.1,13.5z M34.5,36c0,1.7-1.4,3-3.1,3c-1.7,0-3.1-1.4-3.1-3c0-1.7,1.4-3,3.1-3 C33.1,33,34.5,34.3,34.5,36z M9.4,32c-1.7,0-3.1-1.4-3.1-3c0-1.7,1.4-3,3.1-3v0c0.9,0,1.7,0.4,2.3,0.9c0,0,0,0,0,0 c0.5,0.5,0.8,1.2,0.8,2C12.5,30.6,11.1,32,9.4,32z M24.6,20.1c0,2.9-2.4,5.2-5.4,5.2c-3,0-5.4-2.3-5.4-5.2c0-2.9,2.4-5.2,5.4-5.2v0 c2.3,0,4.3,1.4,5,3.4c0,0,0,0,0,0C24.5,18.9,24.6,19.5,24.6,20.1C24.6,20.1,24.6,20.1,24.6,20.1z M35.9,17c-1.7,0-3.1-1.4-3.1-3 s1.4-3,3.1-3v0c1.7,0,3.1,1.4,3.1,3C39,15.6,37.6,17,35.9,17z"
        />
      </Icon>
    )
  }
}
