import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      light: '#63cfdb',
      main: '#3dc3d2',
      dark: '#2a8893',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#6cce97',
      main: '#48C27E',
      dark: '#328758',
      contrastText: '#ffffff',
    },
    error: {
      light: '#ff7468',
      main: '#d2423d',
      dark: '#9a0016',
      contrastText: '#ffffff',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['"Helvetica"', 'Arial', 'sans-serif'].join(','),
  },
  fontSize: 12,
  htmlFontSize: 10,
  overrides: {
    MuiInputBase: {
      root: {
        color: '#4a4a4a',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 3,
        boxShadow: 'none',
        fontSize: 14,
        fontWeight: 'bold',
        padding: '0 10px',
      },
      text: {
        width: 'inherit',
        color: 'grey',
        fontWeight: 'bolder',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      label: {
        width: 'inherit',
      },
    },
  },
})

export default theme
