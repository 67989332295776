var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { map } from 'lodash';
import classes from './LeftSection.module.scss';
import { useEffect, useState } from 'react';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Tooltip } from '@mui/material';
import { ThumbnailModal } from 'app/components/Table/Cells/CellThumbnail/ThumbnailModal';
import WebcamModal from './WebcamModal';
import { MUIButton } from 'app/components/Form';
import { createPersonAttributeValue, updatePersonAttributeValue } from 'app/store/reducers/organizationChart';
import { useDispatch, useSelector } from 'react-redux';
import { success } from 'app/components/Notifications/notify';
const LeftSection = (props) => {
    const { backgroundImageUrl, personDatas, displayLeftSection, media, updateLoading, setMedia, onSavePicture } = props;
    const dispatch = useDispatch();
    const [openModalAddPhoto, setOpenModalAddPhoto] = useState(false);
    const [openModalWebcam, setOpenModalWebcam] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [error, setError] = useState(false);
    const [dataIsSaved, setDataIsSaved] = useState(false);
    const { asset: userId } = useSelector((state) => state.user);
    useEffect(() => {
        if (displayLeftSection) {
            setData(displayLeftSection.map((section) => (Object.assign({}, section))));
            setNewData(displayLeftSection.map((section) => (Object.assign({}, section))));
        }
    }, [displayLeftSection]);
    useEffect(() => {
        if (error) {
            setNewData(data.map((section) => (Object.assign({}, section))));
            setError(false);
        }
        if (dataIsSaved) {
            setData(newData.map((section) => (Object.assign({}, section))));
            setDataIsSaved(false);
        }
    }, [error, dataIsSaved]);
    function handleChange(id, value) {
        const dataIndex = newData.findIndex((d) => d.id === id);
        const changedData = [...newData];
        changedData[dataIndex].value = value;
        setNewData(changedData);
    }
    function handleSubmit() {
        newData.map((section, index) => __awaiter(this, void 0, void 0, function* () {
            const selectedData = newData[index];
            const oldData = [displayLeftSection.find((leftSection) => leftSection.id === section.id)];
            if (oldData) {
                const dataChanged = oldData.filter((leftSection) => (leftSection === null || leftSection === void 0 ? void 0 : leftSection.value) !== section.value);
                if (dataChanged.length > 0) {
                    try {
                        const id = selectedData === null || selectedData === void 0 ? void 0 : selectedData.attributeId;
                        const data = {
                            id: selectedData === null || selectedData === void 0 ? void 0 : selectedData.attributeId,
                            person: personDatas.id,
                            personAttribute: selectedData.id,
                            value: selectedData.value,
                        };
                        yield dispatch(id ? updatePersonAttributeValue(data) : createPersonAttributeValue(data));
                        success('Saved');
                        setDataIsSaved(true);
                    }
                    catch (error) {
                        console.error(error);
                        setError(true);
                    }
                }
            }
        }));
    }
    return (_jsxs("div", { style: { width: '35%' }, children: [_jsxs("div", { className: classes.container, children: [_jsx("div", { style: {
                            backgroundImage: `url(${backgroundImageUrl})`,
                        }, className: classes.picture }), userId === (personDatas === null || personDatas === void 0 ? void 0 : personDatas.user) && (_jsx("div", { className: classes.overlay, children: _jsxs("div", { className: classes.icon, children: [_jsx(Tooltip, { title: "Prendre une photo avec la webcam", onClick: () => setOpenModalWebcam(true), children: _jsx(VideoCameraFrontIcon, { className: classes.faUser }) }), _jsx(Tooltip, { title: "Importer depuis l'ordinateur", onClick: () => setOpenModalAddPhoto(true), children: _jsx(AddAPhotoIcon, { className: classes.faUser }) })] }) })), openModalAddPhoto && (_jsx(ThumbnailModal, { title: "Importer une photo de profil depuis l'ordinateur", defaultMedia: media, onRequestClose: () => __awaiter(void 0, void 0, void 0, function* () {
                            setOpenModalAddPhoto(false);
                        }), onSave: (file) => onSavePicture(file), setMedia: setMedia })), openModalWebcam && (_jsx(WebcamModal, { title: "Prendre une photo avec la webcam", onRequestClose: () => __awaiter(void 0, void 0, void 0, function* () {
                            setOpenModalWebcam(false);
                        }), onSave: (file) => onSavePicture(file), defaultMedia: media, personId: personDatas.id }))] }), _jsxs("div", { className: classes.leftSection, children: [_jsxs("div", { className: classes.topLeftSection, children: [_jsxs("p", { className: classes.name, children: [personDatas === null || personDatas === void 0 ? void 0 : personDatas.firstName.toUpperCase(), " ", personDatas === null || personDatas === void 0 ? void 0 : personDatas.lastName.toUpperCase()] }), userId !== (personDatas === null || personDatas === void 0 ? void 0 : personDatas.user) && (_jsx(MUIButton, { loader: updateLoading, onClick: () => {
                                    if (!isEditing)
                                        setIsEditing(true);
                                    if (isEditing) {
                                        handleSubmit();
                                        setIsEditing(false);
                                    }
                                }, style: { width: 80 }, children: isEditing ? 'Valider' : 'Editer' }))] }), _jsx("ul", { className: classes.leftList, children: map(newData, (section) => (_jsx("div", { children: !isEditing ? (_jsx("li", { children: _jsxs("div", { className: classes.listItems, children: [_jsxs("h4", { className: classes.listItemsTitle, children: [section.title, " :"] }), _jsx("p", { children: section.value.length > 0 ? section.value : 'Non renseigné' })] }) })) : (_jsx("li", { children: _jsxs("div", { className: classes.listItems, children: [_jsxs("h4", { className: classes.listItemsTitle, children: [section.title, " :"] }), section.isEditable ? (_jsx("input", { size: 25, value: section === null || section === void 0 ? void 0 : section.value, onChange: (e) => handleChange(section.id, e.target.value) })) : (_jsx("p", { children: section.value.length > 0 ? section.value : 'Non renseigné' }))] }) })) }, section.id))) })] })] }));
};
export default LeftSection;
