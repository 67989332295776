import { uniqBy } from 'lodash'
import { actions } from 'app/store/actions/assetTalks'

export const findThread = (topics, threadId) => {
  for (const topic of topics) {
    if (!topic.threadsInst) continue

    // topics.threads = topic.threadsInst.slice();

    const thread = topic.threadsInst.find((thread) => thread.id === threadId)

    if (thread) {
      return thread
    }
  }

  return null
}

const updateTopicsUnread = (topics) => {
  const _topics = topics.slice()

  // console.log('_topics', _topics)

  topicsLoop: for (const topic of _topics) {
    topic.hasUnreadThread = false

    if (!topic.threadsInst) continue

    for (const thread of topic.threadsInst) {
      if (thread.unreadPostCount > 0) {
        topic.hasUnreadThread = true
        continue topicsLoop
      }

      // if (thread.lastReadedPost && (thread.lastReadedPost.id !== thread.lastPostInst.id)) {
      //     topic.hasUnreadThread = true;
      //     continue topics_loop;
      // }
    }
  }

  return _topics
}

const assetTalks = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_ALL_DATA: {
      const { topics } = action

      /**
       * Hack
       */
      // _cleanUnreadPost(topics);

      return {
        topics,
      }
    }

    case actions.ADD_TOPIC: {
      const topics = state.topics.slice()

      topics.push(action.topic)

      return { ...state, topics }
    }

    case actions.ADD_THREAD: {
      const topics = state.topics.slice()

      for (const topic of topics) {
        if (topic.id === action.thread.topic) {
          if (!topic.threadsInst) {
            topic.threadsInst = []
          }

          topic.threadsInst = topic.threadsInst.slice()

          topic.threadsInst.push(action.thread)
        }
      }

      return { ...state, topics }
    }

    case actions.INSERT_PREV_POSTS: {
      const topics = state.topics.slice()

      const thread = findThread(topics, action.threadId)

      if (thread) {
        if (!thread.posts) {
          thread.posts = []
        }

        // thread.posts = action.posts.concat(thread.posts).unique('id');
        thread.posts = uniqBy(action.posts.concat(thread.posts), 'id')
      }

      return { ...state, topics }
    }

    case actions.ADD_POSTS: {
      const topics = state.topics.slice()

      const thread = findThread(topics, action.threadId)

      if (thread) {
        if (!thread.posts) {
          thread.posts = []
        }

        // thread.posts = thread.posts.concat(action.posts).unique('id');
        thread.posts = uniqBy(thread.posts.concat(action.posts), 'id')
      }

      return { ...state, topics }
    }

    case actions.POST_ARRIVED: {
      // console.log('POST_ARRIVED');

      let topics = state.topics.slice()

      // TODO: check with id
      const allowNotif = action.user !== action.post.authorInst.name

      // console.log('allowNotif', allowNotif)

      for (const topic of topics) {
        if (!topic.threadsInst) continue

        topic.threadsInst = topic.threadsInst.slice()

        for (const thread of topic.threadsInst) {
          if (thread.id === action.threadId) {
            if (!thread.posts) {
              thread.posts = []
            } else {
              thread.posts = thread.posts.slice()
            }

            // console.log('thread', thread)

            thread.posts.push(action.post)
            // thread.posts = thread.posts.unique('id');
            thread.posts = uniqBy(thread.posts, 'id')
            thread.lastPostInst = action.post

            if (allowNotif) {
              thread.unreadPostCount += 1
            }
          }
        }
      }

      if (allowNotif) {
        topics = updateTopicsUnread(topics)
      }

      return { ...state, topics }
    }

    case actions.POST_UPDATED: {
      const _state = { ...state }

      const thread = findThread(_state.topics, action.threadId)

      for (const postIndex in thread.posts) {
        if (thread.posts[postIndex].id === action.post.id) {
          thread.posts[postIndex] = action.post
          break
        }
      }

      return _state
    }

    case actions.SET_LAST_READED_POST: {
      let topics = state.topics.slice()

      for (const topic of topics) {
        if (!topic.threadsInst) continue

        topic.threadsInst = topic.threadsInst.slice()

        for (const thread of topic.threadsInst) {
          if (thread.id === action.threadId) {
            thread.lastReadedPost = action.lastReadedPost

            if (thread.lastReadedPost && thread.lastReadedPost.id === thread.lastPostInst.id) {
              thread.unreadPostCount = 0
            }
          }
        }
      }

      topics = updateTopicsUnread(topics)

      return { ...state, topics }
    }

    case actions.RESET: {
      return {}
    }

    default:
      return state
  }
}

export default assetTalks
