/** @flow */
import React from 'react'
import { uniqBy, padEnd, get } from 'lodash'
import {
  CellText,
  CellDuration,
  CellRichText,
  CellSelect,
  CellRating,
  CellCheckbox,
  CellSmartDate,
} from 'app/components/Table/Cells'
import { CellStatsAttributes } from 'app/components/Table/Cells/StatsCells/index.js'
import resources from 'app/store/resources'
import type { Column } from 'app/components/Table/types'
import type { Asset, Attribute, ID, Task } from 'app/core/types'
import { viewableValue } from 'app/components/Form/FormData/getInput.tsx'
import dateToLocaleStringFull from 'app/libs/helpers/dateToLocaleStringFull.js'
import { regex } from 'app/libs/helpers/regex.js'
import moment from 'moment'
import classes from 'app/pages/Project/Tasks/TableTasks.module.scss'

type Props = {|
  attributes: Attribute[] | void,
  readOnly: boolean,
  projectsByAttributes?: { [key: string]: ID[] },
|}

type TaskItems = {|
  approval: null,
  approvalInst: null,
  comment: string,
  createdAt: Date,
  estimLength: number,
  flags: ID[],
  flagsInst: ID[],
  id: ID,
  name: string,
  number: number,
  refMedias: [],
  refMediasInst: [],
  rel_takeFlagsInst: ID[],
  rel_takeRefMediasInst: [],
  rel_takeValidationMediasInst: [],
  status: ID,
  statusInst: { [key: string]: string | number },
  statusUpdatedAt: Date,
  takeFlagsInst: [],
  takeRefMediasInst: [],
  takeValidationMediasInst: [],
  task: ID,
  taskInst: Task,
  updatedAt: Date,
  validationComment: null,
  validationMedias: [],
  validationMediasInst: [],
|}

export default function AttributesColumns(props: Props): Array<Column> | void {
  const { attributes, readOnly, projectsByAttributes } = props
  if (!attributes || !Array.isArray(attributes)) return undefined
  return uniqBy(attributes, 'name')
    .map((attribute): Column => {
      const cellContent: $Shape<Column> = {
        id: attribute.id,
        Header: attribute.name,
        hiddenable: true,
        hiddenByDefault: true,
        fixable: true,
        readOnly,
        sortingKey: `asset__attributes__${attribute.name}`,
        Stats: (instance) => {
          let formatValue: (value: any) => string
          let formatLabel: (value: any) => string | React$Element<any>
          let radialLabel: (values: Object) => string

          if (['rating', 'priority', 'text'].indexOf(attribute.editorType) !== -1) {
            formatLabel = (val) => viewableValue(attribute.attrType, val, attribute.editorType) || 'null'
            if (attribute.editorType === 'rating')
              radialLabel = (values) => padEnd('★'.repeat(Number(values.label)), 5, '☆')
          } else {
            formatValue = (val) => viewableValue(attribute.attrType, val, attribute.editorType) || 'null'
          }

          return (
            <CellStatsAttributes
              instance={instance}
              name={attribute.name}
              formatValue={formatValue}
              formatLabel={formatLabel}
              radialLabel={radialLabel}
              showModal={['smartdate'].includes(attribute.editorType)}
            />
          )
        },
        Placeholder: (instance) => {
          if (projectsByAttributes) {
            const { cell } = instance
            const { original } = cell.row
            const project: ID = original?.taskInst?.assetInst.project || ''

            if (!project) {
              return (
                <div className="flex alignCenter fullWidth fullHeight">
                  <div className={classes.placeholderLabel}>No project for this asset</div>
                </div>
              )
            }

            if (!projectsByAttributes[attribute.name].includes(project)) {
              return (
                <div className="flex alignCenter fullWidth fullHeight">
                  <div className={classes.placeholderLabel}>Attribute doesn{`'`}t exist in this project.</div>
                </div>
              )
            }
            return null
          }
          return null
        },
        accessor: projectsByAttributes
          ? (item: TaskItems) => {
              const asset: Asset = item?.taskInst?.assetInst
              return asset?.attributes?.[attribute.name]
            }
          : `attributes.${attribute.name}`,
        actions: projectsByAttributes
          ? (tableInstance, cell) => {
              const { original } = cell.row
              if (
                !original?.taskInst?.assetInst?.project ||
                !projectsByAttributes[attribute.name].includes(original.taskInst.assetInst.project)
              ) {
                return []
              }
              return ['edit', 'delete', 'copy', 'past']
            }
          : () => ['edit', 'delete', 'copy', 'past'],
        save: {
          resource: 'assets',
          formatData: (item: Asset, value, cell, instance, type) => {
            if (!projectsByAttributes) {
              if (type === 'delete') {
                return {
                  id: item.id,
                  attributes: { ...item.attributes, [attribute.name]: null },
                }
              }

              return {
                id: item.id,
                attributes: { ...item.attributes, [attribute.name]: value },
              }
            }
            return null
          },
          saveData: (item: TaskItems, value, cell, instance, type) => {
            if (projectsByAttributes) {
              const { attributes, project } = item?.taskInst?.assetInst || {}

              if (type === 'delete') {
                return resources.assets.update(
                  {
                    id: item?.taskInst?.asset,
                    attributes: { ...attributes, [attribute.name]: null },
                  },
                  {
                    params: { headers: { [window.OVM_PROJECT_HEADER]: project || '' } },
                  },
                )
              }

              return resources.assets.update(
                {
                  id: item?.taskInst?.asset,
                  attributes: { ...attributes, [attribute.name]: value },
                },
                {
                  params: { headers: { [window.OVM_PROJECT_HEADER]: project || '' } },
                },
              )
            }
            return Promise.resolve()
          },
        },
      }

      const createMinMaxValidator = ({ min, max }, errorLabel, format = (data: any) => data) => {
        const validators: Object = {}
        if (min)
          validators.min = (value: number) =>
            format(value) < format(min) ? `${errorLabel} must be grather than ${min}` : false
        if (max)
          validators.max = (value: number) =>
            format(value) > format(max) ? `${errorLabel} must be lower than ${max}` : false
        return validators
      }

      if (attribute.editorType === 'choice') {
        const options = () => {
          const label = (value) => {
            const str = viewableValue(attribute.attrType, value, attribute.editorType)
            return str ? String(str) : ''
          }
          return (
            attribute?.editorParams?.choice?.map((value: string) => ({
              value,
              label: label(value),
            })) || []
          )
        }

        return CellSelect({
          ...cellContent,
          readMask: (value) => {
            if (attribute.attrType === 'datetime') return dateToLocaleStringFull(value)
            return value
          },
          accessor: (item) => {
            if (!projectsByAttributes && !item) return ''
            if (projectsByAttributes && !item?.taskInst?.assetInst) return ''
            const { attributes } = projectsByAttributes ? item?.taskInst?.assetInst || {} : item
            if (!attributes) return ''

            if (attribute.attrType === 'integer' && attributes[attribute.name]) {
              return String(attributes[attribute.name])
            }

            if (attribute.attrType === 'datetime' && attributes[attribute.name]) {
              const datetime = new Date(attributes[attribute.name])
              return datetime.toISOString()
            }

            if (attribute.attrType === 'time' && attributes[attribute.name]) {
              return moment(attributes[attribute.name], 'HH:MM:SS').format('HH:MM')
            }
            return attributes[attribute.name]
          },
          save: {
            resource: 'assets',
            formatData: (item: Asset, value, cell, instance, type) => {
              if (!projectsByAttributes) {
                let returnedValue = value

                if (type === 'delete') return { id: item.id, attributes: { [attribute.name]: null } }
                if (attribute.attrType === 'datetime') returnedValue = new Date(value)
                return {
                  id: item.id,
                  attributes: { ...item.attributes, [attribute.name]: returnedValue },
                }
              }
              return null
            },
            saveData: (item: TaskItems, value, cell, instance, type) => {
              if (projectsByAttributes) {
                const { attributes, project } = item?.taskInst?.assetInst || {}
                if (type === 'delete') {
                  return resources.assets.update(
                    {
                      id: item?.taskInst?.asset,
                      attributes: { ...attributes, [attribute.name]: null },
                    },
                    {
                      params: { headers: { [window.OVM_PROJECT_HEADER]: project || '' } },
                    },
                  )
                }

                let returnedValue = value
                if (attribute.attrType === 'datetime') returnedValue = new Date(value)

                return resources.assets.update(
                  {
                    id: item?.taskInst?.asset,
                    attributes: { ...attributes, [attribute.name]: returnedValue },
                  },
                  {
                    params: { headers: { [window.OVM_PROJECT_HEADER]: project || '' } },
                  },
                )
              }
              return Promise.resolve()
            },
          },
          options,
        })
      }

      switch (attribute.attrType) {
        case 'bool': {
          return CellCheckbox({ ...cellContent })
        }
        case 'char': {
          const { colored } = attribute.editorParams

          return CellText({
            ...cellContent,
            colored,
            noReadStyle: colored,
            actions: () => ['edit', 'delete', 'copy', 'past'],
            width: 200,
          })
        }
        case 'date': {
          const validators: Object = {}
          const { min, max } = attribute.editorParams
          if (min) {
            validators.min = (value: Date) => {
              return new Date(value) < new Date(min)
                ? `date must be grather than ${new Date(min).toLocaleDateString()}`
                : false
            }
          }
          if (max) {
            validators.max = (value: Date) => {
              return new Date(value) > new Date(max)
                ? `date must be lower than ${new Date(max).toLocaleDateString()}`
                : false
            }
          }

          return CellText({
            ...cellContent,
            inputProps: {
              type: 'date',
              validators,
            },
          })
        }
        case 'datetime': {
          const { min, max } = attribute.editorParams

          return CellText({
            ...cellContent,
            inputProps: {
              min: min ? new Date(min) : undefined,
              max: max ? new Date(max) : undefined,
              type: 'datetime',
            },
          })
        }
        case 'smartdate': {
          return CellSmartDate({
            ...cellContent,
            width: 200,
          })
        }
        case 'duration': {
          if (!projectsByAttributes) {
            return CellDuration({
              ...cellContent,
              accessor: (item: Asset) => {
                if (!item) return undefined
                const { attributes } = item
                if (!attributes) return undefined

                return attributes[attribute.name]
              },
            })
          }
          return CellDuration({ ...cellContent })
        }
        case 'email': {
          return CellText({
            ...cellContent,
            width: 150,
            inputProps: {
              type: 'email',
              validators: {
                email: (value: string) => {
                  return !regex.is.email.test(value) && regex.exist(value) ? 'Invalide email' : false
                },
              },
            },
          })
        }
        case 'float': {
          const { min, max, step } = attribute.editorParams
          const validators = createMinMaxValidator({ min, max }, 'float', (data) => Number(data))

          return CellText({
            ...cellContent,
            inputProps: {
              type: 'number',
              min,
              max,
              step,
              validators,
            },
          })
        }
        case 'integer': {
          if (attribute.editorType === 'rating') {
            return CellRating({ ...cellContent })
          }

          const { min, max, step, colored } = attribute.editorParams
          const validators = createMinMaxValidator({ min, max }, 'integer', (data) => Number(data))

          return CellText({
            ...cellContent,
            colored,
            noReadStyle: colored,
            inputProps: {
              type: 'number',
              min,
              max,
              step,
              validators: {
                noFloat: (value: string) => {
                  const isInt = regex.is.integer.test(value) || !regex.exist(value)
                  return !isInt ? `It's not an integer` : false
                },
                ...validators,
              },
            },
          })
        }
        case 'ip': {
          return CellText({
            ...cellContent,
            inputProps: {
              type: 'ip',
              validators: {
                ip: (value: string) => {
                  return !regex.is.ip.test(value) && regex.exist(value) ? 'Invalide IP' : false
                },
              },
            },
          })
        }
        case 'text': {
          if (!projectsByAttributes) {
            return CellRichText({
              ...cellContent,
              width: 200,
            })
          }
          return CellRichText({
            ...cellContent,
            actions: (tableInstance, cell) => {
              const { original } = cell.row
              if (
                !original?.taskInst?.assetInst?.project ||
                !projectsByAttributes[attribute.name].includes(original.taskInst.assetInst.project)
              ) {
                return []
              }
              return ['edit', 'delete', 'copy', 'past']
            },
            width: 200,
            accessor: (item: Asset) => get(item, `attributes.${attribute.name}`, ''),
          })
        }
        case 'time': {
          const { min, max } = attribute.editorParams
          const validators = createMinMaxValidator({ min, max }, 'time')

          return CellText({
            ...cellContent,
            accessor: (item: Asset) => item?.attributes?.[attribute.name],
            inputProps: {
              type: 'time',
              validators,
            },
          })
        }
        case 'url': {
          return CellText({
            ...cellContent,
            width: 150,
            inputProps: {
              type: 'url',
              validators: {
                url: (value: string) => {
                  return !regex.is.url.test(value) && regex.exist(value) ? 'Invalide URL' : false
                },
              },
            },
          })
        }
        default:
          console.error(`unknow attribute type ${attribute.attrType}`)
          return CellText({ ...cellContent })
      }
    })
    .filter((_) => _)
}
