import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { colorsClassName } from 'app/styles/colors'
import CustomPropTypes from 'app/core/propTypes'
import classes from './Icon.module.scss'

export default class Icon extends React.PureComponent {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    viewBox: PropTypes.string.isRequired,
    fill: CustomPropTypes.color,
    stroke: CustomPropTypes.color,
    fillHover: CustomPropTypes.color,
    strokeHover: CustomPropTypes.color,
    onClick: PropTypes.func,
    className: PropTypes.string,

    /**
     * @deprecated
     */
    fillClassName: CustomPropTypes.deprecated(CustomPropTypes.color, 'Use fill instead'),

    /**
     * @deprecated
     */
    fillHoverClassName: CustomPropTypes.deprecated(CustomPropTypes.color, 'Use fillHover instead'),

    /**
     * @deprecated
     */
    strokeClassName: CustomPropTypes.deprecated(CustomPropTypes.color, 'Use stroke instead'),

    /**
     * @deprecated
     */
    strokeHoverClassName: CustomPropTypes.deprecated(CustomPropTypes.color, 'Use strokeHover instead'),

    /**
     * @deprecated
     */
    align: CustomPropTypes.deprecated(PropTypes.string, 'Use props style instead'),

    /**
     * @deprecated
     */
    color: CustomPropTypes.deprecated(PropTypes.string, 'Use fill or stroke instead'),

    /**
     * @deprecated
     */
    button: CustomPropTypes.deprecated(PropTypes.any, 'Use props style instead'),
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const {
      className,
      align, // deprecated
      color, // deprecated
      button, // deprecated
      children,
      fill,
      fillHover,
      stroke,
      strokeHover,
      fillClassName, // deprecated
      fillHoverClassName, // deprecated
      strokeClassName, // deprecated
      strokeHoverClassName, // deprecated
      onClick,
      ...otherProps
    } = this.props

    let fillProps = fill
    let fillHoverProps = fillHover
    let strokeProps = stroke
    let strokeHoverProps = strokeHover

    if (!fill && fillClassName) {
      fillProps = fillClassName
    }

    if (!fillHover && fillHoverClassName) {
      fillHoverProps = fillHoverClassName
    }

    if (!stroke && strokeClassName) {
      strokeProps = strokeClassName
    }

    if (!strokeHover && strokeHoverClassName) {
      strokeHoverProps = strokeHoverClassName
    }

    const _className = cx(
      [
        className,
        fillProps && colorsClassName.fill[fillProps],
        fillHoverProps && colorsClassName.fillHover[fillHoverProps],
        strokeProps && colorsClassName.stroke[strokeProps],
        strokeHoverProps && colorsClassName.strokeHover[strokeHoverProps],
      ],
      {
        [classes.btnType]: !!onClick,
      },
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        className={_className}
        onClick={onClick}
        {...otherProps}
      >
        {children}
      </svg>
    )
  }
}
