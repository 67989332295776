/*eslint-disable */

import React from 'react'
import Icon from '../Icon.jsx'

export default class Planet_5 extends React.Component {
  static defaultProps = {
    width: 14,
    height: 18,
    fill: 'grey',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 14 18">
        <path
          d="M13,15.2l-0.1-3.6c0.5-0.2,0.9-0.7,0.9-1.4c0-0.8-0.6-1.5-1.4-1.5c-0.6,0-1.1,0.4-1.3,1H7.7
                    c-0.2-0.6-0.7-1-1.3-1C6.1,8.8,5.8,8.9,5.6,9L2.8,7.6c0-0.1,0.1-0.3,0.1-0.4c0-0.5-0.2-0.9-0.6-1.2l0.9-3c0.8,0,1.4-0.7,1.4-1.5
                    C4.5,0.7,3.9,0,3.1,0C2.3,0,1.7,0.7,1.7,1.5c0,0.5,0.2,0.9,0.5,1.1L1.3,5.7C0.6,5.8,0,6.4,0,7.2C0,8,0.6,8.6,1.4,8.6
                    c0.3,0,0.6-0.1,0.8-0.3L5,9.8c0,0.1-0.1,0.3-0.1,0.4c0,0.5,0.3,1,0.7,1.3l-0.4,2.6C4.5,14.2,4,14.8,4,15.6C4,16.4,4.6,17,5.4,17
                    c0.5,0,1-0.3,1.2-0.7l4.6,0.7c0.2,0.6,0.7,1,1.4,1c0.8,0,1.4-0.7,1.4-1.5C14,15.9,13.6,15.3,13,15.2z M11.3,16l-4.4-0.7
                    c-0.1-0.4-0.3-0.8-0.7-1l0.4-2.6c0.5-0.1,1-0.5,1.1-1h3.4c0.1,0.4,0.5,0.8,0.9,0.9l0.1,3.5C11.7,15.3,11.4,15.6,11.3,16z M12.4,9.7
                    c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5S12.2,9.7,12.4,9.7z M6.4,9.7c0.3,0,0.5,0.2,0.5,0.5
                    s-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5S6.1,9.7,6.4,9.7z M3.1,1c0.3,0,0.5,0.2,0.5,0.5S3.4,2,3.1,2C2.8,2,2.6,1.7,2.6,1.5
                    S2.8,1,3.1,1z M1.4,7.6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5S1.7,7.6,1.4,7.6z M5.4,16
                    c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5S5.7,16,5.4,16z M12.6,17c-0.3,0-0.5-0.2-0.5-0.5
                    c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5C13.1,16.8,12.8,17,12.6,17z"
        />
      </Icon>
    )
  }
}

/* eslint-enable */
