// @flow
import { map } from 'lodash'
import { operators as op, presetOperators as pop } from 'app/components/Table/TableView/Elements/Filters/operators.ts'
import type { ColumnFilter } from 'app/core/types'

type Params = {|
  linkTypes: Array<Object>,
  categories: Array<Object>,
  notes: Array<Object>,
|}

export class Filters {
  constructor({ linkTypes, categories, notes }: Params) {
    this.params = {
      ...this.params,
      linkTypesOptions: linkTypes,
      categoriesOptions: categories.map((category) => ({ value: category, label: category })),
      notesOptions: notes,
    }
  }

  params: { linkTypesOptions: Array<Object>, categoriesOptions: Array<Object>, notesOptions: Array<Object> } = {}

  formatValueNoteText: (value: string, filterKey?: string) => string = (value, filterKey) => {
    if (filterKey) {
      return `${filterKey}=${JSON.stringify({
        foreignKeyName: 'step',
        field: 'text',
        foreignKeyList: value.split(','),
      })}`
        .replace('undefined', 'general')
        .replace('null', 'general')
    }

    return JSON.stringify({
      foreignKeyName: 'step',
      field: 'text',
      foreignKeyList: value.split(','),
    })
      .replace('undefined', 'general')
      .replace('null', 'general')
  }

  getFilters(): Array<ColumnFilter> {
    return [
      {
        label: 'Asset',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => ({
            ...op,
            attr: 'asset__uuid',
            multiple: true,
            inputType: 'asset',
            getSubLabel: (asset: Object) => {
              if (!asset) return undefined
              return asset.parent__name
            },
          })),
          ...map(pop.text, (op) => ({
            ...op,
            attr: 'asset__name',
            inputType: 'text',
          })),
        ],
      },
      {
        label: 'Category',
        type: 'column',
        operators: [
          ...map([op.inList, op.notInList], (op) => ({
            ...op,
            attr: 'asset__attributes__category',
            inputType: 'select',
            options: {
              label: 'Asset category',
              data: this.params.categoriesOptions,
            },
            multiple: true,
          })),
          ...map(pop.isNull, (op) => ({
            ...op,
            attr: 'asset__attributes__category',
          })),
          ...map([op.hasKey, op.hasNotKey], (op) => ({
            ...op,
            filterValue: '"category"',
            attr: 'asset__attributes',
          })),
        ],
      },
      {
        label: 'Type',
        type: 'column',
        operators: [
          ...map([op.in, op.notIn], (op) => ({
            ...op,
            attr: 'linkType',
            multiple: true,
            inputType: 'select',
            options: {
              label: 'Types',
              data: this.params.linkTypesOptions,
            },
          })),
        ],
      },
      {
        label: 'Flags',
        type: 'column',
        operators: [
          ...map(pop.list, (op) => ({
            ...op,
            attr: 'asset__flags__uuid',
            multiple: true,
            inputType: 'flags',
          })),
          ...map([op.countIsEmpty, op.countIsNotEmpty], (op) => ({
            ...op,
            attr: 'asset__flags__uuid',
          })),
        ],
      },
      {
        label: 'Refs medias',
        type: 'column',
        operators: [
          ...map([op.countIsEmpty, op.countIsNotEmpty], (op) => ({
            ...op,
            attr: 'asset__mediaGroups__medias__uuid',
          })),
        ],
      },
      {
        label: 'Notes',
        type: 'column',
        operators: [
          ...map(pop.text, (op) => ({
            ...op,
            attr: 'notes__text',
            inputType: 'text',
          })),
          {
            value: 'relationEmptyWithValue',
            label: 'Is empty',
            type: 'operator',
            attr: 'notes',
            formatMultipleValues: this.formatValueNoteText,
            serverKey: 'relationempty',
            resourceValue: 'steps',
            multiple: true,
            options: {
              label: 'Step',
              data: this.params.notesOptions,
            },
            inputType: 'select',
          },
          {
            value: 'relationNotEmptyWithValue',
            label: 'Is not empty',
            type: 'operator',
            attr: 'notes',
            formatMultipleValues: this.formatValueNoteText,
            serverKey: 'relationempty!',
            resourceValue: 'steps',
            multiple: true,
            options: {
              label: 'Step',
              data: this.params.notesOptions,
            },
            inputType: 'select',
          },
        ],
      },
    ]
  }
}
