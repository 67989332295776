// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Timecode-___Timecode-module__container {
  display: flex;
  align-content: center;
  align-items: center;
  height: 30px;
  padding: 0px 8px;
  border: 1px solid #ccc;
  border-radius: var(--mainBorderRadius);
  position: relative;
}

.app-components-Timecode-___Timecode-module__input {
  border: none;
  position: relative;
  height: 100%;
  min-height: 19px;
  outline: none;
  text-align: end;
  font-size: 1em;
  padding: 0em;
  background-color: transparent;
  z-index: 1;
  width: 100%;
}

.app-components-Timecode-___Timecode-module__input:focus {
  font-weight: bold;
}

.app-components-Timecode-___Timecode-module__unit {
  pointer-events: none;
}

.app-components-Timecode-___Timecode-module__container:first-child {
  width: '';
}

.app-components-Timecode-___Timecode-module__fieldError {
  position: absolute;
  right: 0px;
}

.app-components-Timecode-___Timecode-module__error {
  border-color: var(--colors-red);
  box-shadow: 0 0 3px var(--colors-red);
}

.app-components-Timecode-___Timecode-module__errorLabel {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: var(--colors-red);
  font-size: 13px;
}

.app-components-Timecode-___Timecode-module__disabled {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.05);
  cursor: not-allowed;
}

.app-components-Timecode-___Timecode-module__disabled input {
    color: rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Timecode/Timecode.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;EACtB,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,cAAc;EACd,YAAY;EACZ,6BAA6B;EAC7B,UAAU;EACV,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,+BAA+B;EAC/B,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,aAAa;EACb,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,qCAAqC;EACrC,mBAAmB;AAKrB;;AAJE;IACE,yBAAyB;IACzB,mBAAmB;EACrB","sourcesContent":[".container {\n  display: flex;\n  align-content: center;\n  align-items: center;\n  height: 30px;\n  padding: 0px 8px;\n  border: 1px solid #ccc;\n  border-radius: var(--mainBorderRadius);\n  position: relative;\n}\n\n.input {\n  border: none;\n  position: relative;\n  height: 100%;\n  min-height: 19px;\n  outline: none;\n  text-align: end;\n  font-size: 1em;\n  padding: 0em;\n  background-color: transparent;\n  z-index: 1;\n  width: 100%;\n}\n\n.input:focus {\n  font-weight: bold;\n}\n\n.unit {\n  pointer-events: none;\n}\n\n.container:first-child {\n  width: '';\n}\n\n.fieldError {\n  position: absolute;\n  right: 0px;\n}\n\n.error {\n  border-color: var(--colors-red);\n  box-shadow: 0 0 3px var(--colors-red);\n}\n\n.errorLabel {\n  position: absolute;\n  right: 0;\n  bottom: -20px;\n  color: var(--colors-red);\n  font-size: 13px;\n}\n\n.disabled {\n  color: rgba(0, 0, 0, 0.5);\n  background-color: rgba(0, 0, 0, 0.05);\n  cursor: not-allowed;\n  & input {\n    color: rgba(0, 0, 0, 0.5);\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Timecode-___Timecode-module__container`,
	"input": `app-components-Timecode-___Timecode-module__input`,
	"unit": `app-components-Timecode-___Timecode-module__unit`,
	"fieldError": `app-components-Timecode-___Timecode-module__fieldError`,
	"error": `app-components-Timecode-___Timecode-module__error`,
	"errorLabel": `app-components-Timecode-___Timecode-module__errorLabel`,
	"disabled": `app-components-Timecode-___Timecode-module__disabled`
};
export default ___CSS_LOADER_EXPORT___;
