// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellTask-___CellTask-module__readContainer {
  padding: 3px;
  height: 100%;
  position: relative;
}

.app-components-Table-Cells-CellTask-___CellTask-module__title {
  font-size: 13px;
  font-weight: bold;
  text-align: start;
}

.app-components-Table-Cells-CellTask-___CellTask-module__status {
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  width: 100%;
}

.app-components-Table-Cells-CellTask-___CellTask-module__date {
  text-align: start;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.app-components-Table-Cells-CellTask-___CellTask-module__date > * {
  position: relative;
  z-index: 1;
}

.app-components-Table-Cells-CellTask-___CellTask-module__comment {
  height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  position: relative;
  z-index: 1;
}

.app-components-Table-Cells-CellTask-___CellTask-module__top {
  position: relative;
}
.app-components-Table-Cells-CellTask-___CellTask-module__assignedUser {
  float: right;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  padding: 0 8px;
}

.app-components-Table-Cells-CellTask-___CellTask-module__isCollapsedTextIcon {
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: rgba(0, 0, 0, 0.3);
  width: 30px;
}

.app-components-Table-Cells-CellTask-___CellTask-module__isCollapsedTextIcon:hover {
  color: rgba(0, 0, 0, 0.7);
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellTask/CellTask.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".readContainer {\n  padding: 3px;\n  height: 100%;\n  position: relative;\n}\n\n.title {\n  font-size: 13px;\n  font-weight: bold;\n  text-align: start;\n}\n\n.status {\n  font-size: 15px;\n  font-weight: bold;\n  position: absolute;\n  width: 100%;\n}\n\n.date {\n  text-align: start;\n  font-size: 12px;\n  font-weight: normal;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  white-space: nowrap;\n}\n\n.date > * {\n  position: relative;\n  z-index: 1;\n}\n\n.comment {\n  height: calc(100% - 50px);\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding: 5px;\n  position: relative;\n  z-index: 1;\n}\n\n.top {\n  position: relative;\n}\n.assignedUser {\n  float: right;\n  font-size: 12px;\n  font-weight: bold;\n  white-space: nowrap;\n  padding: 0 8px;\n}\n\n.isCollapsedTextIcon {\n  position: absolute;\n  bottom: 5px;\n  left: 5px;\n  color: rgba(0, 0, 0, 0.3);\n  width: 30px;\n}\n\n.isCollapsedTextIcon:hover {\n  color: rgba(0, 0, 0, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readContainer": `app-components-Table-Cells-CellTask-___CellTask-module__readContainer`,
	"title": `app-components-Table-Cells-CellTask-___CellTask-module__title`,
	"status": `app-components-Table-Cells-CellTask-___CellTask-module__status`,
	"date": `app-components-Table-Cells-CellTask-___CellTask-module__date`,
	"comment": `app-components-Table-Cells-CellTask-___CellTask-module__comment`,
	"top": `app-components-Table-Cells-CellTask-___CellTask-module__top`,
	"assignedUser": `app-components-Table-Cells-CellTask-___CellTask-module__assignedUser`,
	"isCollapsedTextIcon": `app-components-Table-Cells-CellTask-___CellTask-module__isCollapsedTextIcon`
};
export default ___CSS_LOADER_EXPORT___;
