/** @flow */

import { confirm } from 'app/components/Modal'
import history from 'app/main/routerHistory'

import type { ModalProps } from '../../components/Modal/MUIModal.jsx'

const askListRequired: Array<() => boolean> = []

window.onbeforeunload = () => {
  for (let i = 0; i < askListRequired.length; i += 1) {
    if (askListRequired[i]()) return true
  }
  return null
}

type Props = {|
  askRequired: () => boolean,
  data?: ModalProps,
|}

export default ({ askRequired, data = {} }: Props): (() => void) => {
  askListRequired.push(askRequired)

  const unlistenLeave = history.block((nextLocation) => {
    if (askRequired()) {
      confirm({
        title: 'Unsaved changes',
        render: 'You will lose your unsaved changes.',
        validateLabel: 'Leave this page',
        validateLabelColor: '#E56D7A',
        cancelLabel: 'Stay on this page',
        onValidate: () => {
          unlistenLeave()
          history.push(nextLocation)
          return Promise.resolve()
        },
        ...data,
      })
      return false
    }
    unlistenLeave()
    return true
  })

  return () => {
    const findIndex = askListRequired.findIndex((_askRequired) => _askRequired === askRequired)
    askListRequired.splice(findIndex, 1)
    unlistenLeave()
  }
}
