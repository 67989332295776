// @flow
import { colors } from 'app/styles/colors'

type MessageTypes = {
  [_: string]: {
    label: string,
    backgroundColor: string,
  },
}

export const messageTypes: MessageTypes = {
  '0': {
    label: 'High',
    backgroundColor: colors.red,
  },
  '1': {
    label: 'Medium',
    backgroundColor: colors.yellowDark,
  },
  '2': {
    label: 'Low',
    backgroundColor: colors.blue,
  },
}

export const scope = {
  all: 'All users',
  authenticated: 'Authenticated',
  unauthenticated: 'Unauthenticated',
}
