/** @flow */
import type { ReportData } from 'app/containers/Reporting/reporting.jsx'

type Data = {|
  ...$Shape<ReportData>,
  attachments: Array<$Exact<{ title: string, image_url: string }>>,
|}

type ReportActions = Array<{|
  label: string,
  channel: string,
  action: (Data) => $Exact<{
    text: string,
    attachments?: Array<$Exact<{ title: string, image_url: string }>>,
  }>,
|}>

const reportingActions: ReportActions = [
  {
    label: 'Bug with the interface',
    channel: '#overmind_support',
    action: (data) => {
      const { message, attachments, url, user, type } = data

      return {
        text: `**${type}**\n${url}\n**${document.title}**\n**${user.username}**\n\n${message}\n\n.`,
        attachments,
      }
    },
  },
  {
    label: 'Bug with datas',
    channel: '#overmind_support',
    action: (data) => {
      const { message, attachments, url, user, type } = data

      return {
        text: `**${type}**\n${url}\n**${document.title}**\n**${user.username}**\n\n${message}\n\n.`,
        attachments,
      }
    },
  },
  {
    label: 'Feature suggestions',
    channel: '#overmind_features',
    action: (data) => {
      const { message, attachments, url, user, type } = data

      return {
        text: `**${type}**\n${url}\n**${document.title}**\n**${user.username}**\n\n${message}\n\n.`,
        attachments,
      }
    },
  },
]

export { reportingActions }
