// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Project-Settings-FollowUp-CalculatedFields-___FormulaItem-module__value {
  padding: 0px 5px;
  border-radius: 3px;
  background-color: rgb(0, 138, 230);
  color: #ffffff;
  white-space: nowrap;
  height: 22px;
  line-height: 22px;
}

.app-pages-Project-Settings-FollowUp-CalculatedFields-___FormulaItem-module__operator {
  padding: 5px;
  font-weight: bold;
  font-size: large;
}

.app-pages-Project-Settings-FollowUp-CalculatedFields-___FormulaItem-module__function {
  border-radius: 3px;
  padding: 5px;
  background-color: rgba(0, 138, 230, 0.1);
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 20px;
}

.app-pages-Project-Settings-FollowUp-CalculatedFields-___FormulaItem-module__formulaItemContainer {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./app/pages/Project/Settings/FollowUp/CalculatedFields/FormulaItem.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kCAAkC;EAClC,cAAc;EACd,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,wCAAwC;EACxC,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".value {\n  padding: 0px 5px;\n  border-radius: 3px;\n  background-color: rgb(0, 138, 230);\n  color: #ffffff;\n  white-space: nowrap;\n  height: 22px;\n  line-height: 22px;\n}\n\n.operator {\n  padding: 5px;\n  font-weight: bold;\n  font-size: large;\n}\n\n.function {\n  border-radius: 3px;\n  padding: 5px;\n  background-color: rgba(0, 138, 230, 0.1);\n  display: inline-flex;\n  flex-wrap: wrap;\n  align-items: center;\n  line-height: 20px;\n}\n\n.formulaItemContainer {\n  display: inline-flex;\n  flex-wrap: wrap;\n  align-items: center;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"value": `app-pages-Project-Settings-FollowUp-CalculatedFields-___FormulaItem-module__value`,
	"operator": `app-pages-Project-Settings-FollowUp-CalculatedFields-___FormulaItem-module__operator`,
	"function": `app-pages-Project-Settings-FollowUp-CalculatedFields-___FormulaItem-module__function`,
	"formulaItemContainer": `app-pages-Project-Settings-FollowUp-CalculatedFields-___FormulaItem-module__formulaItemContainer`
};
export default ___CSS_LOADER_EXPORT___;
