/** @flow */
import type { Media } from 'app/core/types'
import { type IconType } from 'app/core/types'

export const types = { IMAGE: 'IMAGE', PDF: 'PDF', OTHER: 'OTHER' }

export const findMediaType = ({ url, name }: Object): string => {
  if (!url && !name) return types.OTHER

  const splitedURL = (name || url).split('.')
  const extension = splitedURL[splitedURL.length - 1]

  if (['png', 'jpg', 'jpeg', 'gif'].includes(extension.toLowerCase())) {
    return types.IMAGE
  }
  if (extension.toLowerCase() === 'pdf') {
    return types.PDF
  }

  return types.OTHER
}

export const getExtension = (name: string): ?string => {
  if (!name) return undefined

  const splitedURL = name.split('.')
  return splitedURL[splitedURL.length - 1]
}

export const isImageType = (name: string): ?boolean => {
  if (!name) return undefined

  return ['png', 'jpg', 'jpeg', 'gif', 'svg', 'bmp'].includes(getExtension(name))
}

export const mediaExtension = (media: ?Media): string => {
  if (!media || !media.url) return ''
  const splitedURL = media.url.split('.')
  const extension = splitedURL[splitedURL.length - 1]
  return extension
}

export function getMediaExtension(media: Media): ?string {
  const { url, name, id } = media

  const nameExtended = id ? url : name

  const splitedUrl = nameExtended.split('/')
  const filename = splitedUrl[splitedUrl.length - 1]
  const splitedName = filename.split('.')
  const extension = splitedName.length > 1 ? splitedName[splitedName.length - 1] : undefined

  return extension
}

// prettier-ignore
export function getGroupExtension(ext: ?string): string {
  if (!ext) return 'other'
  if (['png', 'jpg', 'jpeg', 'gif', 'svg', 'bmp'].includes(ext)) return 'image'
  if (['zip', 'rar', 'tar', 'targz', 'bin', 'dmg', 'gz', 'iso', '7z', 'tgz'].includes(ext)) return 'archive'
  if (['mp4', 'avi', 'mkv', 'mpg', 'mpe', 'mpeg'].includes(ext)) return 'video'
  if (['bat', 'doc', 'docx', 'odt', 'odp', 'ods', 'odg', 'pdf', 'pps', 'ppt', 'xls', 'xlsx', 'odt', 'odp', 'ods', 'odg', 'txt'].includes(ext)) return 'document'
  if (['bat', 'exe', 'sh', 'h', 'py', 'json', 'c', 'js', 'ext'].includes(ext)) return 'script'
  if (['aac','mp3','wav', 'ogg'].includes(ext)) return 'audio'
  if (['fbx', 'abc', 'export', 'colanium', 'rum', 'colasset', 'rumba', 'nk', 'mamb'].includes(ext)) return 'logiciel'
  return 'other'
}

export function getGroupIcon(groupName: string): IconType {
  if (groupName === 'image') return 'fas-file-image'
  if (groupName === 'archive') return 'fas-file-archive'
  if (groupName === 'video') return 'fas-file-video'
  if (groupName === 'document') return 'fas-file-alt'
  if (groupName === 'script') return 'fas-scroll'
  if (groupName === 'audio') return 'fas-file-audio'
  if (groupName === 'logiciel') return 'fas-desktop'
  return 'fas-file'
}
