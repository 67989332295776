// @flow
import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { StepProjectsActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const stepProjects: StoreResourceDef<StepProjectsActions<>> = {
  resourceType: 'stepProjects',
  actions: (actionsCreators) => ({
    fetchByProject: (projectId, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.projects
            .steps({ id: projectId }, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then((res) => {
              const { results } = res
              const { rel_stepProjectsInst, stepsInst } = results
              return {
                ...res,
                results: {
                  rel_stepProjectsInst: rel_stepProjectsInst.map((stepProject) => ({
                    ...stepProject,
                    stepInst: stepsInst.find((s) => s.id === stepProject.step),
                  })),
                  stepsInst,
                },
              }
            })
            .then(transformResults(config)),
      }
    },
  }),
  relations: {
    stepInst: {
      type: 'hasOne',
      resourceType: 'steps',
      foreignKey: 'step',
    },
  },
}
