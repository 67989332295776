/** @flow */
import { useEffect, useMemo, useRef, useState } from 'react'
import emitter from 'tiny-emitter/instance'
import { assertNonNull, first } from './utils'
import type { DropIndication } from './DropIndication'
import type { TreeViewProps } from './TreeViewProps'
import type { Node } from './Node'
import classes from './TreeRow.module.scss'
import { NODE_DIRECTORY } from './Node'

export const DRAG_MIME = 'application/x.react-draggable-tree-drag'
export const DRAG_ID = 'application/x.react-draggable-tree-id'

export interface TreeViewItem<Data = any> {
  key: string;
  parent: ?TreeViewItem<>;
  children: TreeViewItem<>[];
  node: Node<Data>;
  isExternal?: boolean;
}

export interface ItemRow {
  item: TreeViewItem<>;
  depth: number;
  selected: boolean;
  ancestorSelected: boolean;
  select: () => void;
  deselect: () => void;
  collapsed: boolean;
  collapse: () => void;
}

export interface DropLocation {
  parent: TreeViewItem<>;
  before: TreeViewItem<> | void;
  indication: DropIndication;
}

type UseTreeViewSatesProps = {|
  treeId: string,
  rootNode: Node<>,
  indentation?: $ElementType<TreeViewProps<>, 'indentation'>,
  dropIndicatorOffset?: $ElementType<TreeViewProps<>, 'dropIndicatorOffset'>,
  canDropData?: $ElementType<TreeViewProps<>, 'canDropData'>,
  handleDrop?: $ElementType<TreeViewProps<>, 'handleDrop'>,
  nonReorderable?: $ElementType<TreeViewProps<>, 'nonReorderable'>,
  handleDragStart?: $ElementType<TreeViewProps<>, 'handleDragStart'>,
  handleDragEnd?: $ElementType<TreeViewProps<>, 'handleDragEnd'>,
  onBackgroundClick?: $ElementType<TreeViewProps<>, 'onBackgroundClick'>,
  onBackgroundDragEnter?: (e: SyntheticDragEvent<HTMLElement>) => void,
  onBackgroundDragLeave?: (e: SyntheticDragEvent<HTMLElement>) => void,
  noHiddingOnDrag?: boolean,
|}

export type UseTreeViewSatesOutput = {|
  rows: ItemRow[],
  setDropLocation: (dropLocation: DropLocation | void) => void,
  dropLocation: DropLocation | void,
  setIndentation: (indentation: number) => void,
  indentation: number,
  setDropIndicatorOffset: (dropIndicatorOffset: number) => void,
  dropIndicatorOffset: number,
  canDropData: (location: ?DropLocation, event: SyntheticDragEvent<>, draggedItem: TreeViewItem<> | void) => boolean,
  handleDrop: (location: DropLocation, event: SyntheticDragEvent<>, draggedItem: TreeViewItem<> | void) => boolean,
  setHeaderDOM: (element: ?HTMLElement) => void,
  getHeaderBottom: () => number,
  itemToDOM: WeakMap<TreeViewItem<>, HTMLElement>,
  getItemDOMTop: (item: TreeViewItem<>) => number,
  getItemDOMHeight: (item: TreeViewItem<>) => number,
  getItemDOMBottom: (item: TreeViewItem<>) => number,
  onRowDragStart: (index: number, e: SyntheticDragEvent<HTMLElement>, dragImage?: ?Element) => void,
  onRowDragEnd: (index: number, e: SyntheticDragEvent<HTMLElement>) => void,
  onRowDragOver: (index: number, e: SyntheticDragEvent<HTMLElement>) => void,
  onRowDragEnter: (index: number, e: SyntheticDragEvent<HTMLElement>) => void,
  onRowDragLeave: (e: SyntheticDragEvent<HTMLElement>) => void,
  onRowDrop: (index: number, e: SyntheticDragEvent<HTMLElement>) => void,
  onBackgroundDragEnter: (e: SyntheticDragEvent<HTMLElement>) => void,
  onBackgroundDragLeave: (e: SyntheticDragEvent<HTMLElement>) => void,
  onBackgroundDragOver: (e: SyntheticDragEvent<HTMLElement>) => void,
  onBackgroundDrop: (e: SyntheticDragEvent<HTMLElement>) => void,
  onBackgroundClick?: $ElementType<TreeViewProps<>, 'onBackgroundClick'>,
  setSelectedNodes: ((({ [key: string]: boolean }) => { [key: string]: boolean }) | { [key: string]: boolean }) => void,
  setCollapsedNodes: (
    (({ [key: string]: boolean }) => { [key: string]: boolean }) | { [key: string]: boolean },
  ) => void,
|}

function createTreeViewItem<Data>(
  node: Node<Data>,
  parent?: TreeViewItem<Data>,
  selectedNodes: { [key: string]: boolean },
  collapsedNodes: { [key: string]: boolean },
): TreeViewItem<Data> {
  const item: TreeViewItem<Data> = {
    key: node.key,
    parent,
    children: [],
    node,
  }
  if (collapsedNodes[node.key]) return item
  item.children = node.children.map((child) => createTreeViewItem(child, item, selectedNodes, collapsedNodes))
  return item
}

export function useTreeViewSates(props: UseTreeViewSatesProps): UseTreeViewSatesOutput {
  const {
    treeId,
    rootNode,
    indentation: initialIndentation = 30,
    dropIndicatorOffset: initialDropIndicatorOffset = 0,
    canDropData: initialCanDropData,
    handleDrop: initialHandleDrop,
    nonReorderable,
    handleDragStart: initialHandleDragStart,
    handleDragEnd: initialHandleDragEnd,
    onBackgroundClick,
    onBackgroundDragEnter: initialOnBackgroundDragEnter,
    onBackgroundDragLeave: initialOnBackgroundDragLeave,
    noHiddingOnDrag,
  } = props

  const headerDOM = useRef<void | HTMLElement>()
  const isDragging = useRef<{ active: boolean, isDropped: boolean, dragImage: ?HTMLElement }>({
    active: false,
    isDropped: false,
    dragImage: null,
  })
  const itemToDOM: WeakMap<TreeViewItem<>, HTMLElement> = useMemo(() => new WeakMap(), [])

  const [rows, setRows] = useState<ItemRow[]>([])
  const [selectedNodes, setSelectedNodes] = useState<{ [key: string]: boolean }>({})
  const [collapsedNodes, setCollapsedNodes] = useState<{ [key: string]: boolean }>({})
  const [indentation, setIndentation] = useState<number>(initialIndentation)
  const [dropIndicatorOffset, setDropIndicatorOffset] = useState<number>(initialDropIndicatorOffset)
  const [draggedItem, _setDraggedItem] = useState<TreeViewItem<> | void>()

  const dropLocation = useRef<DropLocation | void>()

  function getItemRows(item: TreeViewItem<>, depth: number): ItemRow[] {
    return [
      {
        item,
        depth,
        selected: selectedNodes[item.key],
        ancestorSelected: Boolean(item.parent ? selectedNodes[item.parent.key] : false),
        select: () => setSelectedNodes((sn) => ({ ...sn, [item.key]: true })),
        deselect: () => setSelectedNodes((sn) => ({})),
        collapsed: collapsedNodes[item.key],
        collapse: () => setCollapsedNodes((cn) => ({ ...cn, [item.key]: !cn[item.key] })),
      },
      ...item.children.flatMap((child) => getItemRows(child, depth + 1)),
    ]
  }

  const rootItem = useMemo(
    () => createTreeViewItem(rootNode, undefined, selectedNodes, collapsedNodes),
    [rootNode, selectedNodes, collapsedNodes],
  )

  useEffect(() => {
    setRows(rootItem.children.flatMap((item) => getItemRows(item, 0)))
  }, [rootItem, selectedNodes, collapsedNodes])

  function setDraggedItem(item: TreeViewItem<> | void, e: SyntheticDragEvent<HTMLElement>) {
    if (!item) {
      window.treeViewTransfer = undefined
      _setDraggedItem()
      return
    }

    e.dataTransfer.effectAllowed = 'copyMove'
    e.dataTransfer.setData(DRAG_MIME, 'drag')

    window.treeViewTransfer = item
    _setDraggedItem(item)
  }

  function getDraggedItem(e: SyntheticDragEvent<HTMLElement>) {
    let currentDraggedItem = e.dataTransfer.types.includes(DRAG_MIME) ? draggedItem : undefined

    if (!currentDraggedItem && window.treeViewTransfer) {
      currentDraggedItem = { ...window.treeViewTransfer, isExternal: true }
      _setDraggedItem(currentDraggedItem)
    }

    return currentDraggedItem
  }

  function setDropLocation(newDropLocation: DropLocation | void) {
    if (dropLocation.current === newDropLocation) return
    dropLocation.current = newDropLocation
    emitter.emit(`dropLocationChange-${treeId}`, '', newDropLocation)
  }

  function canDropData(
    location: ?DropLocation,
    event: SyntheticDragEvent<>,
    draggedItem: TreeViewItem<> | void,
  ): boolean {
    return location
      ? initialCanDropData?.(location?.parent, {
          event,
          draggedItem,
        }) ?? false
      : false
  }

  function handleDrop(
    location: DropLocation,
    event: SyntheticDragEvent<>,
    draggedItem: TreeViewItem<> | void,
    callback?: () => void,
  ): boolean {
    if (!canDropData(location, event, draggedItem)) return false
    initialHandleDrop?.(
      location.parent,
      {
        event,
        draggedItem,
        before: location.before,
      },
      callback,
    )
    return true
  }

  function setHeaderDOM(element: ?HTMLElement) {
    headerDOM.current = element || undefined
  }

  function getHeaderBottom(): number {
    if (!headerDOM.current) return 0
    return headerDOM.current.offsetTop + headerDOM.current.offsetHeight
  }

  function getItemDOMTop(item: TreeViewItem<>): number {
    return itemToDOM.get(item)?.offsetTop ?? 0
  }

  function getItemDOMHeight(item: TreeViewItem<>): number {
    return itemToDOM.get(item)?.offsetHeight ?? 0
  }

  function getItemDOMBottom(item: TreeViewItem<>): number {
    const dom = itemToDOM.get(item)
    if (!dom) return 0
    return dom.offsetTop + dom.offsetHeight
  }

  function findCloseRowsDirectory(index: number): { row: ?ItemRow, isLast: boolean } {
    let isLast = false
    let upRow
    let currentUpDiff = 0
    for (let i = index; i < rows.length; i += 1) {
      currentUpDiff += 1
      const row = rows[i]
      if (row.item.node.type === NODE_DIRECTORY) {
        upRow = row
        break
      }
      if (i === rows.length - 1) {
        isLast = true
      }
    }

    let downRow
    let currentDownDiff = 0
    for (let i = index; i >= 0; i -= 1) {
      currentDownDiff += 1
      const row = rows[i]
      if (row.item.node.type === NODE_DIRECTORY) {
        downRow = row
        break
      }
    }

    if (currentDownDiff <= currentUpDiff && downRow) return { row: downRow, isLast }
    return { row: upRow || downRow, isLast: downRow ? isLast : false }
  }

  function getDropDepth(e: SyntheticDragEvent<HTMLElement>): number {
    const rect = e.currentTarget.getBoundingClientRect()
    return Math.max(Math.round((e.clientX - rect.left - dropIndicatorOffset) / indentation), 0)
  }

  function getDropLocationOver(item: ?TreeViewItem<>): DropLocation | void {
    if (!item) return undefined
    return {
      parent: item,
      before: item.children[0],
      indication: {
        type: 'over',
        top: getItemDOMTop(item),
        height: getItemDOMHeight(item),
      },
    }
  }

  function getDropLocationBetween(index: number, dropDepth: number): DropLocation {
    if (rows.length === 0) {
      return {
        parent: rootItem,
        before: undefined,
        indication: {
          type: 'between',
          top: 0,
          depth: 0,
        },
      }
    }

    if (index === 0) {
      return {
        parent: assertNonNull(rows[0]?.item?.parent),
        before: rows[0]?.item,
        indication: {
          type: 'between',
          top: getItemDOMTop(rows[0]?.item),
          depth: rows[0]?.depth,
        },
      }
    }

    const rowPrev = rows[index - 1]
    const rowNext = index < rows.length ? rows[index] : undefined

    if (!rowNext || rowNext.depth < rowPrev.depth) {
      if (rowNext && dropDepth <= rowNext.depth) {
        return {
          parent: assertNonNull(rowNext.item.parent),
          before: rowNext.item,
          indication: {
            type: 'between',
            top: getItemDOMTop(rowNext.item),
            depth: rowNext.depth,
          },
        }
      }

      const depth = Math.min(dropDepth, rowPrev.depth)
      const up = rowPrev.depth - depth

      let { parent } = rowPrev.item
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < up; ++i) {
        parent = parent?.parent
      }

      return {
        parent: assertNonNull(parent),
        before: undefined,
        indication: {
          type: 'between',
          top: getItemDOMBottom(rowPrev.item),
          depth,
        },
      }
    }

    return {
      parent: assertNonNull(rowNext.item.parent),
      before: rowNext.item,
      indication: {
        type: 'between',
        top: getItemDOMTop(rowNext.item),
        depth: rowNext.depth,
      },
    }
  }

  function getDropLocationForRow(
    index: number,
    event: SyntheticDragEvent<HTMLElement>,
    draggedItem: TreeViewItem<> | void,
  ): DropLocation | void {
    const row = rows[index]
    const { item } = row

    if (!item.parent) {
      throw new Error('item must have parent')
    }

    const rect = event.currentTarget.getBoundingClientRect()
    const dropPos = (event.clientY - rect.top) / rect.height
    const dropDepth = getDropDepth(event)

    const locationBefore = getDropLocationBetween(index, dropDepth)
    const locationOver = getDropLocationOver(item)
    const locationAfter = getDropLocationBetween(index + 1, dropDepth)

    if (!locationOver) return undefined

    if (!nonReorderable) {
      if (canDropData(locationOver, event, draggedItem)) {
        if (canDropData(locationBefore, event, draggedItem) && dropPos < 1 / 4) {
          return locationBefore
        }
        if (canDropData(locationAfter, event, draggedItem) && 3 / 4 < dropPos) {
          return locationAfter
        }
        return locationOver
      }
      if (canDropData(locationBefore, event, draggedItem) && dropPos < 1 / 2) {
        return locationBefore
      }
      if (canDropData(locationAfter, event, draggedItem)) {
        return locationAfter
      }
      const { row, isLast } = findCloseRowsDirectory(index)
      if (row) {
        let root = item
        while (root.parent) root = root?.parent

        return {
          parent: isLast ? assertNonNull(root) : assertNonNull(row.item.parent),
          before: isLast ? undefined : row.item,
          indication: {
            type: 'between',
            top: isLast ? getItemDOMBottom(rows[rows.length - 1].item) : getItemDOMTop(row.item),
            depth: row.depth,
          },
        }
      }
    } else {
      if (canDropData(locationOver, event, draggedItem)) {
        return locationOver
      }
      const locationOverParent = getDropLocationOver(item.parent)
      if (canDropData(locationOverParent, event, draggedItem)) {
        return locationOverParent
      }
    }
    return undefined
  }

  function getDropLocationForBackground(e: SyntheticDragEvent<HTMLElement>): DropLocation | void {
    const rect = e.currentTarget.getBoundingClientRect()
    const top = e.clientY - rect.top
    if (top <= getHeaderBottom()) {
      return {
        parent: rootItem,
        before: first(rows)?.item,
        indication: {
          type: 'between',
          top: getHeaderBottom(),
          depth: 0,
        },
      }
    }
    const currentDraggedItem = getDraggedItem(e)
    const location = getDropLocationBetween(rows.length, getDropDepth(e))

    if (canDropData(location, e, currentDraggedItem)) return location
    return undefined
  }
  /// / Item visibility during drop

  function hideItemVisibility(item?: TreeViewItem<>) {
    if (noHiddingOnDrag || !item) return
    setTimeout(() => {
      itemToDOM.get(item)?.classList.add(classes.hideTargetDuringDragging)
      item.children.forEach((child) => itemToDOM.get(child)?.classList.add(classes.hideTargetDuringDragging))
    }, 0)
  }

  function restoreItemVisibility(item?: TreeViewItem<>) {
    if (noHiddingOnDrag || !item) return
    setTimeout(() => {
      itemToDOM.get(item)?.classList.remove(classes.hideTargetDuringDragging)
      item.children.forEach((child) => itemToDOM.get(child)?.classList.remove(classes.hideTargetDuringDragging))
    }, 0)
  }

  /// / Row drag and drop

  function onRowDragStart(index: number, e: SyntheticDragEvent<HTMLElement>, dragImage?: ?Element) {
    const { item } = rows[index]

    /**
     * Create a copy of the hidden element for keep visibility of
     * the drag image
     */
    const $elDragImage = e.currentTarget.cloneNode(true)
    $elDragImage.id = 'drag-ghost'
    $elDragImage.style.position = 'absolute'
    $elDragImage.style.top = '-1000px'
    $elDragImage.style.backgroundColor = `#ffffff`
    document.body?.appendChild($elDragImage)
    e.dataTransfer.setDragImage($elDragImage, 0, 0)

    isDragging.current.active = true
    isDragging.current.dragImage = $elDragImage

    hideItemVisibility(item)

    if (!initialHandleDragStart?.(item, { event: e })) {
      e.preventDefault()
      return
    }

    setDraggedItem(item, e)
  }

  function onRowDragEnd(index: number, e: SyntheticDragEvent<HTMLElement>) {
    const { item } = rows[index]

    if (!isDragging.current.isDropped) restoreItemVisibility(item)
    isDragging.current.dragImage?.remove()
    isDragging.current = { isDropped: false, active: false, dragImage: null }

    initialHandleDragEnd?.(item)
  }

  function onRowDragOver(index: number, e: SyntheticDragEvent<HTMLElement>) {
    const currentDraggedItem = getDraggedItem(e)

    setDropLocation(getDropLocationForRow(index, e, currentDraggedItem))

    if (dropLocation.current) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  function onRowDragEnter(index: number, e: SyntheticDragEvent<HTMLElement>) {
    const currentDraggedItem = getDraggedItem(e)

    setDropLocation(getDropLocationForRow(index, e, currentDraggedItem))
    e.preventDefault()
    e.stopPropagation()
  }

  function onRowDragLeave(e: SyntheticDragEvent<HTMLElement>) {
    setDropLocation()
    e.preventDefault()
    e.stopPropagation()
  }

  function onRowDrop(index: number, e: SyntheticDragEvent<HTMLElement>) {
    const currentDraggedItem = getDraggedItem(e)
    const dropLocation = getDropLocationForRow(index, e, currentDraggedItem)

    if (
      dropLocation &&
      handleDrop(dropLocation, e, currentDraggedItem, () => restoreItemVisibility(currentDraggedItem))
    ) {
      isDragging.current.isDropped = true
      e.preventDefault()
      e.stopPropagation()
    }
    setDropLocation()
    setDraggedItem(undefined, e)
  }

  /// / Background drop

  function onBackgroundDragEnter(e: SyntheticDragEvent<HTMLElement>) {
    setDropLocation(getDropLocationForBackground(e))
    initialOnBackgroundDragEnter?.(e)
    e.preventDefault()
    e.stopPropagation()
  }

  function onBackgroundDragLeave(e: SyntheticDragEvent<HTMLElement>) {
    setDropLocation()
    initialOnBackgroundDragLeave?.(e)
    e.preventDefault()
    e.stopPropagation()
  }

  function onBackgroundDragOver(e: SyntheticDragEvent<HTMLElement>) {
    setDropLocation(getDropLocationForBackground(e))
    if (canDropData(dropLocation.current, e, draggedItem)) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  function onBackgroundDrop(e: SyntheticDragEvent<HTMLElement>) {
    const dropLocation = getDropLocationForBackground(e)
    const currentDraggedItem = getDraggedItem(e)

    if (!dropLocation || !currentDraggedItem) {
      e.preventDefault()
      e.stopPropagation()
      setDropLocation()
      setDraggedItem(undefined, e)
      return
    }

    if (handleDrop(dropLocation, e, currentDraggedItem, () => restoreItemVisibility(currentDraggedItem))) {
      isDragging.current.isDropped = true
      e.preventDefault()
      e.stopPropagation()
    }

    setDropLocation()
    setDraggedItem(undefined, e)
  }

  return {
    rows,
    setDropLocation,
    dropLocation: dropLocation.current,
    indentation,
    dropIndicatorOffset,
    canDropData,
    handleDrop,
    setHeaderDOM,
    itemToDOM,
    getHeaderBottom,
    getItemDOMTop,
    getItemDOMHeight,
    getItemDOMBottom,
    onRowDragStart,
    onRowDragEnd,
    onRowDragOver,
    onRowDragEnter,
    onRowDragLeave,
    onRowDrop,
    onBackgroundDragEnter,
    onBackgroundDragLeave,
    onBackgroundDragOver,
    onBackgroundDrop,
    setSelectedNodes,
    setCollapsedNodes,
    setIndentation,
    setDropIndicatorOffset,
    onBackgroundClick,
  }
}
