// @flow

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Logo } from 'app/components/Icons'
import { BurgerButton } from 'app/components/Icons/BurgerButton/BurgerButton.jsx'
import { HorizontalCollapse } from 'app/components/Transitions/HorizontalCollapse.jsx'
import history from 'app/main/routerHistory'
import { MenuContext } from 'app/main/Authenticated/Authenticated.tsx'
import classes from './Header.module.scss'
import Nav from './Nav.jsx'
import User from './User.jsx'
import IconsNav from './IconsNav.jsx'
import { SearchingBar } from './SearchingBar/SearchingBar.jsx'

type Props = {||}

export function Header(props: Props): React$Node {
  const menuContextConsumer = useContext(MenuContext)

  function displayBurgerButton(): boolean {
    const location = history.location.pathname.split('/')
    if (location[1] === 'admin') return true
    if (location[1] === 'notifications') return true
    if (location[1] === 'hr') return true
    if (location[1] === 'projects') {
      if (location[2]) return true
    }
    return false
  }

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <HorizontalCollapse open={displayBurgerButton()} width={50}>
          <div className={classes.burgerButton}>
            <BurgerButton onClick={menuContextConsumer.toggleMenu} open={menuContextConsumer.isOpen} />
          </div>
        </HorizontalCollapse>
        <Link className={classes.name} to="/">
          <Logo className={classes.logo} />
          <span className={classes.nameStart}>Over</span>
          <span className={classes.nameEnd}>mind</span>
        </Link>
        <Nav />
        <SearchingBar />
        <IconsNav />
        <User />
      </header>
    </div>
  )
}
