// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Menu-___Menu-module__container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.app-components-Menu-___Menu-module__iconButton {
  margin-right: 10px;
  padding: 0 5px;
  flex: 0;
  cursor: pointer;
}

.app-components-Menu-___Menu-module__iconButton:hover {
  color: var(--colors-mainColor);
}

.app-components-Menu-___Menu-module__subNav {
  background-color: #fff;
  border: var(--mainBorder);
}

.app-components-Menu-___Menu-module__item {
  padding: 4px 8px;
  cursor: pointer;
}

.app-components-Menu-___Menu-module__item:hover {
  background-color: var(--colors-mainColor);
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./app/components/Menu/Menu.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,OAAO;EACP,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,yCAAyC;EACzC,cAAc;AAChB","sourcesContent":[".container {\n  position: relative;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n}\n\n.iconButton {\n  margin-right: 10px;\n  padding: 0 5px;\n  flex: 0;\n  cursor: pointer;\n}\n\n.iconButton:hover {\n  color: var(--colors-mainColor);\n}\n\n.subNav {\n  background-color: #fff;\n  border: var(--mainBorder);\n}\n\n.item {\n  padding: 4px 8px;\n  cursor: pointer;\n}\n\n.item:hover {\n  background-color: var(--colors-mainColor);\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Menu-___Menu-module__container`,
	"iconButton": `app-components-Menu-___Menu-module__iconButton`,
	"subNav": `app-components-Menu-___Menu-module__subNav`,
	"item": `app-components-Menu-___Menu-module__item`
};
export default ___CSS_LOADER_EXPORT___;
