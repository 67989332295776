// @flow
import React, { useMemo } from 'react'
import moment from 'moment'
import { map } from 'lodash'
import type { ModalProps } from 'app/components/Modal/MUIModal.jsx'
import type { Asset, ActivityApproval } from 'app/core/types'
import { ModalConfirmForm } from 'app/components/Modal'
import { FormData } from 'app/components/Form'
import { getResources } from 'app/store/selectors'

type Props = {|
  ...$Diff<
    ModalProps,
    { children: $ElementType<ModalProps, 'children'>, onValidate: $ElementType<ModalProps, 'onValidate'> },
  >,
  startDate: moment,
  endDate: moment,
  activityApproval: ?ActivityApproval,
  username: string,
  onChange: (res: Object) => Promise<any>,
  type: 'week' | 'month',
|}

export function ModalActivityValidation(props: Props): React$Node {
  const { startDate, endDate, type, username, onChange, activityApproval, ...modalProps } = props
  const user: Asset = useMemo(() => map(getResources(undefined, 'assets', { name: username }))?.[0], [username])

  const onSave = async (changeData: Object, data: Object, defaultData: Object) => {
    if (activityApproval) {
      return onChange({
        id: activityApproval.id,
        isApproved: changeData.approved,
        comment: changeData.comment,
      })
    }
    return onChange({
      user: user.id,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      isApproved: changeData.approved,
      comment: changeData.comment,
    })
  }

  const title = useMemo(() => {
    if (type === 'week') {
      return `${user?.name.split('.').join(' ')} - ${moment(startDate).format('dddd DD MMMM')} / ${moment(
        endDate,
      ).format('dddd DD MMMM')}`
    }
    if (type === 'month') {
      return `${user?.name.split('.').join(' ')} - ${moment(startDate).format('MMMM')}`
    }
    return 'Activity validation'
  }, [startDate, endDate, type])

  return (
    <ModalConfirmForm title={<span className="capitalize">{title}</span>} {...modalProps}>
      <FormData
        onSave={onSave}
        defaultData={{
          approved: activityApproval?.isApproved,
          comment: activityApproval?.comment,
        }}
        properties={[
          {
            key: 'approved',
            label: 'Approved',
            type: 'checkbox',
          },
          {
            label: 'Comment',
            key: 'comment',
            type: 'richtext',
          },
        ]}
      />
    </ModalConfirmForm>
  )
}
