import { uniqBy } from 'lodash'
import api from 'app/core/api/api.js'
import { symbolEnum } from 'app/libs/helpers'

const actions = symbolEnum([
  'SET_ALL_DATA',
  'ADD_TOPIC',
  'ADD_THREAD',
  'INSERT_PREV_POSTS',
  'ADD_POSTS',
  'SET_LAST_READED_POST',
  'POST_ARRIVED',
  'POST_UPDATED',
  'RESET',
])

export const PAGE_SIZE = 15

// actions

export const setAllData = (topics) => {
  return {
    type: actions.SET_ALL_DATA,
    topics,
  }
}

export const addTopic = (topic) => {
  return {
    type: actions.ADD_TOPIC,
    topic,
  }
}

export const addThread = (thread) => {
  return {
    type: actions.ADD_THREAD,
    thread,
  }
}

export const insertPrevPosts = (threadId, posts) => {
  return {
    type: actions.INSERT_PREV_POSTS,
    threadId,
    posts,
  }
}

export const addPosts = (threadId, posts) => {
  return {
    type: actions.ADD_POSTS,
    threadId,
    posts,
  }
}

export const addOneLastPost = (threadId, post, user) => {
  return {
    type: actions.POST_ARRIVED,
    threadId,
    post,
    user,
  }
}

export const updatePostLocal = (threadId, post) => {
  return {
    type: actions.POST_UPDATED,
    threadId,
    post,
  }
}

export const setLastReadedPost = (threadId, lastReadedPost) => {
  return {
    type: actions.SET_LAST_READED_POST,
    threadId,
    lastReadedPost,
  }
}

export const reset = () => {
  return {
    type: actions.RESET,
  }
}

/**
 * HTTP REQUEST
 */
export const fetchAllData = (assetId) => {
  return function (dispatch) {
    return api.assets
      .topics({
        id: assetId,
      })
      .then((res) => dispatch(setAllData(res.results)))
  }
}

export const fetchPrevPosts = (threadId, lastPostedOn) => {
  return function (dispatch) {
    return api.threads
      .posts(
        {
          id: threadId,
        },
        {
          page_size: PAGE_SIZE,
          ordering: '-postedOn',
          postedOn__lte: lastPostedOn,
        },
      )
      .then((res) => dispatch(insertPrevPosts(threadId, res.results.reverse())))
  }
}

export const fetchNextPosts = (threadId, lastPostedOn) => {
  return function (dispatch) {
    return api.threads
      .posts(
        {
          id: threadId,
        },
        {
          page_size: PAGE_SIZE,
          postedOn__gte: lastPostedOn,
        },
      )
      .then((res) => dispatch(addPosts(threadId, res.results)))
  }
}

export const fetchLastPosts = (threadId) => {
  return function (dispatch) {
    return api.threads
      .posts(
        {
          id: threadId,
        },
        {
          page_size: PAGE_SIZE,
          ordering: '-postedOn',
          postedOn__lte: new Date().toISOString(),
        },
      )
      .then((res) => {
        dispatch(addPosts(threadId, res.results.reverse()))
        dispatch(setLastReadedPost(threadId, res.lastReadedPost))
      })
  }
}

export const fetchPostBetween = (threadId, postId) => {
  return function (dispatch) {
    return api.posts
      .fetchOne(postId)
      .then((post) => {
        const getLte = api.threads.posts(
          {
            id: threadId,
          },
          {
            page_size: PAGE_SIZE,
            ordering: '-postedOn',
            postedOn__lte: post.postedOn,
          },
        )

        const getGte = api.threads.posts(
          {
            id: threadId,
          },
          {
            page_size: PAGE_SIZE,
            postedOn__gte: post.postedOn,
          },
        )

        return Promise.all([getLte, getGte]).then((res) => {
          const { lastReadedPost } = res[0]
          const lteData = res[0].results
          const gteData = res[1].results

          lteData.reverse()

          return {
            // posts: lteData.concat(gteData).unique('id'),
            posts: uniqBy(lteData.concat(gteData), 'id'),
            lastReadedPost,
          }
        })
      })
      .then((res) => {
        dispatch(addPosts(threadId, res.posts))
        dispatch(setLastReadedPost(threadId, res.lastReadedPost))
      })
  }
}

export const postTopic = (topic) => {
  return function (dispatch) {
    return api.topics.create(topic).then((res) => dispatch(addTopic(res)))
  }
}

export const postThread = (thread) => {
  return function (dispatch) {
    return api.threads.create(thread).then((res) => dispatch(addThread(res)))
  }
}

export const postPost = (post) => {
  return function (dispatch) {
    return api.posts.create(post)
  }
}

export const updatePost = (post) => {
  return function (dispatch) {
    return api.posts.update(post)
  }
}

export const updateLastReadedPost = (post) => {
  return function (dispatch) {
    return api.threads
      .lastReadedPost({
        id: post.thread,
        post: post.id,
      })
      .then((res) => {
        dispatch(setLastReadedPost(post.thread, post))
      })
  }
}

export { actions }
