import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Input, Checkbox, Textarea } from 'app/components/Form';
import AutocompleteMultiple from 'app/components/Form/Autocomplete/AutocompleteMultiple';
import { getInput } from 'app/components/Form/FormData/getInput';
import { Richtext } from 'app/components/Form/Richtext/Richtext.jsx';
import { UploadFilesPreviews } from 'app/components/Medias/UploadFilesModal.jsx';
import moment from 'moment';
import propertyTypes from './propertyTypes';
export default (property, state, setData) => {
    if (property.type === propertyTypes.string) {
        const { elementProps = {}, inputType = 'text' } = property;
        return (_jsx(Input, Object.assign({ value: state[property.key] || '', type: inputType, onChange: (e, value) => setData({ [property.key]: value }), dataCy: property.key || elementProps.placeholder || '' }, elementProps)));
    }
    if (property.type === 'textarea') {
        const { elementProps = {} } = property;
        return (_jsx(Textarea, Object.assign({ value: state[property.key] || '', onChange: (value) => setData({ [property.key]: value }), rows: 5 }, elementProps)));
    }
    if (property.type === propertyTypes.checkbox) {
        const Field = property.component || Checkbox;
        return (_jsx(Field, { checked: Boolean(state[property.key]), onChange: (event) => setData({ [property.key]: !state[property.key] }) }));
    }
    if (property.type === 'richtext') {
        const elementProps = (property === null || property === void 0 ? void 0 : property.elementProps) || {};
        return (_jsx(Richtext, Object.assign({ value: state[property.key], onChange: (value) => setData({ [property.key]: value }) }, elementProps)));
    }
    if (property.type === propertyTypes.select) {
        const { elementProps, key, type } = property;
        return getInput({ key, type, props: elementProps }, state, setData);
    }
    if (property.type === 'muiautocomplete') {
        const { elementProps = {}, key, type } = property;
        return getInput({ key, type, props: elementProps }, state, setData);
    }
    if (property.type === propertyTypes.autocomplete) {
        const Field = property.element || Autocomplete;
        const { elementProps = {} } = property;
        const props = { value: {}, selected: undefined };
        if (property.useSelected) {
            props.selected = state[property.key];
        }
        else if (typeof state[property.key] === 'string' && !!state[`${property.key}Inst`]) {
            props.value = { label: state[`${property.key}Inst`].name, value: state[`${property.key}Inst`].id };
        }
        else
            props.value = state[property.key];
        return (_jsx(Field, Object.assign({}, props, { onChange: (value) => setData({ [property.key]: property.useSelected ? value.value : value }) }, elementProps)));
    }
    if (property.type === propertyTypes.autocompleteMultiple) {
        const Field = property.element || AutocompleteMultiple;
        return _jsx(Field, { value: state[property.key], onChange: (value) => setData({ [property.key]: value }) });
    }
    if (property.type === propertyTypes.dateTime) {
        const { elementProps = {} } = property;
        const value = state[property.key] ? moment(state[property.key]).format('YYYY-MM-DDTHH:mm') : '';
        return (_jsx(Input, Object.assign({ value: value, onChange: (e, value) => setData({ [property.key]: moment(value, 'YYYY-MM-DDTHH:mm').toDate() }), dataCy: property.key || elementProps.placeholder || '' }, elementProps, { type: "datetime" })));
    }
    if (property.type === propertyTypes.duration) {
        const { elementProps, key, type } = property;
        return getInput({ key, type, props: elementProps }, state, setData);
    }
    if (property.type === propertyTypes.file) {
        const { elementProps } = property;
        return (_jsx(UploadFilesPreviews, Object.assign({ overflow: false, setFiles: (files) => {
                setData({
                    [property.key]: files,
                });
            }, files: state[property.key] }, elementProps)));
    }
    const { elementProps = {}, key, type } = property;
    return getInput({ key, type, props: elementProps }, state, setData);
};
