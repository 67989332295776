/** @flow */
import React, { Suspense } from 'react'
import type { TablePrefs } from './TableComponent.jsx'

const TableComponent = React.lazy(() => import('./TableComponent.jsx'))

function Table(props: TablePrefs): React$Node {
  return (
    <Suspense fallback="loading...">
      <TableComponent {...props} />
    </Suspense>
  )
}

export type { TablePrefs }
export { Table }
