// @flow
import React from 'react'
import { type Pipe } from 'app/core/utils/pipeNext.type'
import pipe from 'app/core/utils/pipe'
import { type ID } from 'app/core/types'
import resources from 'app/store/resources'
import GroupsComponent from 'app/containers/Groups/Groups.jsx'

type Props = {|
  projectId: ?ID,
  match: Object,
|}

function Groups(props: Props): React$Node {
  const { projectId, match } = props

  return (
    <GroupsComponent
      route={`/projects/${match.params.projectId}/settings/groups/users-by-group`}
      routing="/projects/:projectId/settings/groups/users-by-group"
      projectId={projectId}
    />
  )
}

type PipeType = Pipe<{}, typeof Groups>

const pipeInst: PipeType = pipe()

const Component: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => {
    const projectId = state.project && state.project.id

    return {
      projectId,
    }
  })
  .request(({ projectId }) => (projectId ? resources.assets.fetchGroupsByProject(projectId) : Promise.resolve()))
  .render(({ location, history, ...props }) => <Groups {...props} />)

export default Component
