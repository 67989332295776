// @flow
import { map } from 'lodash'
import { operators as op, presetOperators as pOp } from 'app/components/Table/TableView/Elements/Filters/operators.ts'
import { breakdownDatasKey } from 'app/core/constants/breakdownDatasKey'
import type { ColumnFilter } from 'app/core/types'

export class Filters {
  getFilters(): Array<ColumnFilter> {
    return [
      {
        label: 'Asset',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'uuid',
            multiple: true,
            inputType: 'asset',
            getSubLabel: (asset: Object) => {
              if (!asset) return undefined
              return asset.parent__name
            },
          })),
          ...map(pOp.text, (op) => ({
            ...op,
            attr: 'name',
            inputType: 'text',
          })),
        ],
      },
      {
        label: 'Linked assets',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'links__uuid',
            multiple: true,
            inputType: 'asset',
            getSubLabel: (asset: Object) => {
              if (!asset) return undefined
              return asset.parent__name
            },
          })),
        ],
      },
      {
        label: 'Flags',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'flags__uuid',
            multiple: true,
            inputType: 'flags',
          })),
          ...map([op.countIsEmpty, op.countIsNotEmpty], (op) => ({
            ...op,
            attr: 'flags__uuid',
          })),
        ],
      },
      {
        label: 'Length',
        type: 'column',
        operators: [
          ...map(pOp.number, (op) => ({
            ...op,
            attr: `attributes__length`,
            inputType: 'number',
          })),
        ],
      },
      {
        label: 'Sequence',
        type: 'column',
        operators: [
          ...map(pOp.exact, (op) => ({
            ...op,
            attr: 'attributes__sequence',
          })),
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'attributes__sequence',
            formatValue: (value: mixed) => String(value),
          })),
          ...map([op.isNull, op.isNotNull], (op) => ({
            ...op,
            attr: 'attributes__sequence',
          })),
        ],
      },
      {
        label: 'Notes',
        type: 'column',
        operators: [
          ...map(pOp.text, (op) => ({
            ...op,
            attr: `attributes__${breakdownDatasKey}__note`,
          })),
        ],
      },
    ]
  }
}
