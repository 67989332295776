export const binaryOperators = (type) => {
    return [
        'countIsEmpty',
        'countIsNotEmpty',
        'isEmpty',
        'isNotEmpty',
        'isNull',
        'isNotNull',
        'isTrue',
        'isFalse',
        'hasKey',
        'hasNotKey',
        'relationEmpty',
        'relationNotEmpty',
        // custom operator types
        'isAutoAssigned',
        'isNotAutoAssigned',
        'today',
        'now',
        'now+1w',
        'now+2w',
        'now+1m',
        'now+2m',
        'now-1w',
        'now-2w',
        'now-1m',
        'now-2m',
        'thisMonth',
        'thisYear',
    ].includes(type);
};
export const gtOperators = (type) => ['countGt', 'gt', 'gte', 'sumGt', 'dateGt', 'dateGte'].includes(type);
export const ltOperators = (type) => ['countLt', 'lt', 'lte', 'sumLt', 'dateLt', 'dateLte'].includes(type);
export function operatorCanHaveSeveralValues(operator, values) {
    switch (operator) {
        case 'in':
        case 'notIn':
        case 'inList':
        case 'notInList':
        case 'relationEmptyWithValue':
        case 'relationNotEmptyWithValue':
            return true;
        case 'range':
            if (values.length < 2)
                return true;
            return false;
        default:
            if (values.length < 1)
                return true;
            return false;
    }
}
export function operatorNeedMoreValues(operator, values) {
    if (binaryOperators(operator))
        return false;
    switch (operator) {
        case 'range':
            if (values.length < 2)
                return true;
            return false;
        default:
            if (values.length < 1)
                return true;
            return false;
    }
}
export const listOperators = (type) => ['in', 'notIn', 'inList', 'notInList', 'relationEmptyWithValue', 'relationNotEmptyWithValue'].includes(type);
