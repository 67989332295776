var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MUIModal } from 'app/components/Modal';
import { AssetHistory } from './AssetHistory';
export function AssetHistoryModal(props) {
    const { title, onRequestClose } = props, rest = __rest(props, ["title", "onRequestClose"]);
    return (_jsx(MUIModal, { title: title, draggable: true, resizable: true, height: 800, width: 1200, minWidth: 400, onRequestClose: onRequestClose, hideCancel: true, validateLabel: "Close", validateLabelColor: "#4a4a4a", children: _jsx(AssetHistory, Object.assign({}, rest)) }));
}
