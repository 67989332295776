var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SketchPicker } from 'react-color';
import { FormData } from 'app/components/Form';
import { ModalConfirmForm } from 'app/components/Modal';
import resources from 'app/store/resources';
import styles from 'app/styles/vars.js';
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground';
const progressionStatusTypes = ['Normal', 'New', 'Validation', 'Close', 'Pause', 'Retake'];
export function ModalEditStatus(props) {
    const { progressionStatus } = props, rest = __rest(props, ["progressionStatus"]);
    function onSave(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield resources.progressionStatus[progressionStatus ? 'update' : 'create']({
                id: progressionStatus ? progressionStatus.id : undefined,
                name: data[0].name,
                color: data[0].color,
                statusType: data[0].statusType,
            });
            return res;
        });
    }
    return (_jsx(ModalConfirmForm, Object.assign({ title: "Add a status", validateLabel: `${progressionStatus ? 'Edit' : 'Add'} status` }, rest, { children: ({ handleClose }) => (_jsx(FormData, { defaultData: progressionStatus || { statusType: 0 }, properties: [
                {
                    key: 'layout',
                    type: 'custom',
                    dontInjectProps: true,
                    element: (data, setData) => {
                        return (_jsx("div", { className: "flex", children: _jsx("div", { style: {
                                    backgroundColor: data.color,
                                    fontWeight: 'bold',
                                    padding: '3px 10px',
                                    borderRadius: 3,
                                    color: getColorFromBackground(data.color),
                                }, children: data.name }) }));
                    },
                },
                {
                    key: 'name',
                    label: 'Name',
                    type: 'string',
                    elementProps: {
                        isRequired: true,
                    },
                },
                {
                    key: 'color',
                    label: 'Color',
                    type: 'custom',
                    elementProps: {
                        isRequired: true,
                    },
                    element: (data, setData) => {
                        return (_jsx(SketchPicker, { onChangeComplete: (color) => {
                                setData(Object.assign(Object.assign({}, data), { color: color.hex }));
                            }, disableAlpha: true, presetColors: styles.randomColors, color: data.color }));
                    },
                },
                {
                    key: 'statusType',
                    label: 'Type of status',
                    type: 'select',
                    elementProps: (data, setData) => ({
                        value: String(data.statusType),
                        onChange: (val) => {
                            setData(Object.assign(Object.assign({}, data), { statusType: String(val.value) }));
                        },
                        isRequired: true,
                        fullWidth: true,
                        options: progressionStatusTypes.map((label, value) => ({ label, value: String(value) })),
                        placeholder: 'Select a status type',
                    }),
                },
            ], onSave: (...values) => onSave(values), flashNotifSuccessLabel: `Status ${progressionStatus ? 'edited' : 'created'}` })) })));
}
