// @flow

import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { PostsActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const posts: StoreResourceDef<PostsActions<>> = {
  resourceType: 'posts',
  actions: () => ({
    getByThread: (threadId, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.threads
            .posts({ id: threadId }, { ordering: '-postedOn', ...params.queries }, params.headers)
            .then(transformResults(config)),
      }
    },
    fetchPreviousPosts: ({ threadId, before }, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.threads
            .posts(
              { id: threadId },
              { ordering: '-postedOn', postedOn__lte: before, page_size: 50, ...params.queries },
              params.headers,
            )
            .then(transformResults(config)),
      }
    },
  }),
  relations: {
    authorInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'author',
    },
    mediasInst: {
      type: 'hasMany',
      resourceType: 'medias',
      foreignKey: 'medias',
      serverKey: 'rel_postMediasInst',
    },
    flagsInst: {
      type: 'hasMany',
      resourceType: 'flags',
      foreignKey: 'flags',
      serverKey: 'rel_postFlagsInst',
    },
  },
}
