/** @flow */
import React, { useState, useEffect } from 'react'
import { MUIModal } from 'app/components/Modal'
import type { ID } from 'app/core/types'
import { durationStr } from 'app/components/Duration/Duration.tsx'
import { Pie } from 'app/components/Graphs/Pie.jsx'
import { ToggleButtonGroup, ToggleButton } from 'app/components/Form'

type Props = {|
  users: Array<{
    fAssignedUser__uuid: ID,
    fAssignedUser__name: string,
    count: number,
    estimLength__total: number,
    frame__total: number,
    activities_total: number,
  }>,
  framerate: number,
  hiddenColumns?: Array<number>,
|}

export function ModalAssignedUsers(props: Props): React$Node {
  const { users, framerate, hiddenColumns, ...rest } = props

  const variants = []
  if (!hiddenColumns?.includes(1)) variants.push('Estim. Sum')
  if (!hiddenColumns?.includes(2)) variants.push('Frames')
  if (!hiddenColumns?.includes(3)) variants.push('Secondes')
  if (!hiddenColumns?.includes(4)) variants.push('sec/day estim')
  if (!hiddenColumns?.includes(5)) variants.push('% Assigned')
  if (!hiddenColumns?.includes(6)) variants.push('Assignements number')
  if (!hiddenColumns?.includes(7)) variants.push('Activities')

  const [variant, setVariant] = useState(variants[0])
  const [data, setPieData] = useState([])

  useEffect(() => {
    const totalAssign = users.reduce((count, user) => count + user.count, 0)

    const parsedData: Array<Object> = users
      .map(
        ({
          fAssignedUser__uuid,
          fAssignedUser__name,
          count = 0,
          estimLength__total = 0,
          frame__total = 0,
          activities_total = 0,
        }) => {
          const seconds = Math.round((frame__total / framerate) * 100) / 100 || 0
          const secDay = Math.round((frame__total / framerate / (estimLength__total / 60 / 60 / 7.8)) * 100) / 100 || 0
          const percAssigned = Math.round(((100 * count) / totalAssign) * 100) / 100 || 0
          let value

          if (variant === 'Estim. Sum') {
            value = Math.round(estimLength__total) || 0
          } else if (variant === 'Frames') {
            value = frame__total || 0
          } else if (variant === 'Secondes') {
            value = seconds || 0
          } else if (variant === 'sec/day estim') {
            value = secDay || 0
          } else if (variant === '% Assigned') {
            value = percAssigned || 0
          } else if (variant === 'Assignements number') {
            value = count || 0
          } else if (variant === 'Activities') {
            value = activities_total || 0
          }

          if (value === 0 || !fAssignedUser__name) return null

          return {
            id: fAssignedUser__name,
            label: fAssignedUser__name,
            value,
          }
        },
      )
      .filter((_) => _)

    setPieData(parsedData)
  }, [variant])

  const menu = variants.map((item) => {
    return (
      <ToggleButton key={item} onClick={() => setVariant(item)} selected={item === variant}>
        {item}
      </ToggleButton>
    )
  })

  let sliceLabel = (_) => _.value
  let formatValue

  if (['Estim. Sum', 'Activities'].includes(variant)) {
    formatValue = (d) => durationStr(d)
    sliceLabel = (d) => durationStr(d.value)
  }
  if (variant === '% Assigned') {
    formatValue = (d) => `${d}%`
    sliceLabel = (d) => `${d.value}%`
  }

  return (
    <MUIModal
      title="Assigned users stats"
      resizable={true}
      draggable={true}
      width={1000}
      height={600}
      hideValidate={true}
      cancelLabel="Close"
      cancelLabelColor="#4a4a4a"
      disableBackdrop={true}
      {...rest}
    >
      <div className="fullWidth" style={{ height: 'calc(100% - 5px)' }}>
        <div className="fullWidth flex row noWrap" style={{ height: '40px' }}>
          <ToggleButtonGroup>{menu}</ToggleButtonGroup>
        </div>
        <div className="fullWidth" style={{ height: 'calc(100% - 40px)' }}>
          <Pie data={data} sliceLabel={sliceLabel} formatValue={formatValue} />
        </div>
      </div>
    </MUIModal>
  )
}
