import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { find } from 'lodash'
import { MUIButton } from 'app/components/Form'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import classes from './Impersonification.module.scss'
import { idMessageNotif } from './ImpersonificationStart.jsx'
import { setImpersonificationUser, deleteImpersonificationUser } from './impersonificationUser'

class Impersonification extends React.Component {
  static propTypes = {
    message: PropTypes.object,
  }

  onChange = (value) => {
    const { email } = value.data.attributes
    this.startImpersonification(value.value, email)
  }

  startImpersonification = (id, email) => {
    const user = { email, id }
    setImpersonificationUser(user)
    window.location.href = '/'
  }

  onDeleteImpersonification = () => {
    deleteImpersonificationUser()
    window.location.reload()
  }

  render() {
    const { message } = this.props
    const { id = '', email = '' } = message ? message.data : {}

    const label = `Voir l'interface en tant que`

    return (
      <div className={classes.container}>
        <div className={classes.top}>
          <div className={classes.label}>{label}</div>
          <AssetsSelect
            assetTypes={['us']}
            placeholder="Search user"
            value={{ label: email, value: id }}
            onChange={this.onChange}
          />
        </div>
        <MUIButton bgColor="#E56D7A" onClick={this.onDeleteImpersonification}>
          Stop Impersonification
        </MUIButton>
      </div>
    )
  }
}

export default connect((state) => ({
  message: find(state.messages.resources, { id: idMessageNotif }),
}))(Impersonification)
