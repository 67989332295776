const findLevel = (items, item, parentKey, currentLevel = 0) => {
  if (!item[parentKey]) return currentLevel
  const foundItem = items.find(({ id }) => id === item[parentKey])
  return findLevel(items, foundItem, parentKey, currentLevel + 1)
}

const itemsWithDepthIndent = (items, item, parentKey, key, char = '__') => {
  const depth = findLevel(items, item, parentKey)
  let spaces = ''
  for (let i = 0; i < depth; i += 1) {
    spaces += char
  }

  return `${spaces} ${item[key]}`
}

export default itemsWithDepthIndent
