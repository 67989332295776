/** @flow */
import moment from 'moment'
import type { DateDay } from 'app/core/types'

export function getMomentDatesBetween(
  startDate: Date | DateDay | moment,
  endDate: Date | DateDay | moment,
  params?: { startOf?: string, endOf?: string, format?: string, toDate?: boolean, toISOString?: boolean },
): Array<any> | null {
  if (!startDate || !endDate) {
    console.error('getMomentDatesBetween: Start date or end date missing.')
    return null
  }

  const { startOf = 'day', endOf = 'day', format, toDate, toISOString } = params || {}
  const start = moment(startDate).startOf(startOf)
  const end = moment(endDate).endOf(endOf)

  if (start.isAfter(endDate)) {
    console.error('getMomentDatesBetween: Start date is after end date.')
    return null
  }

  const accumulator = moment(start)

  const days = []

  while (accumulator.isSameOrBefore(end)) {
    let day
    if (toDate) day = accumulator.toDate()
    else if (toISOString) day = accumulator.toISOString()
    else if (format) day = accumulator.format(format)
    else day = accumulator
    days.push(day)
    accumulator.add(1, 'day')
  }
  return days
}

export function getMinMaxDatesFromObjects(
  items?: Array<{ ...any, startDate: DateDay, endDate: DateDay }>,
): Array<DateDay | void> {
  let min: void | DateDay
  let max: void | DateDay
  items?.forEach((item) => {
    if (typeof item !== 'object') {
      return
    }
    const { startDate, endDate } = item
    if (!min || min > startDate) min = startDate
    if (!max || max < endDate) max = endDate
  })

  return [min, max]
}
