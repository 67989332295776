/** @flow */
import React from 'react'
import cx from 'classnames'
import classes from './Toggle.module.scss'

type OptionProps = {|
  option: {
    value: any,
    label: string,
    disabled?: boolean,
  },
  currentValue?: any,
  className?: string,
|}

function Option({ option: { value, label, disabled }, currentValue, className }: OptionProps) {
  return <div className={cx(className, { [classes.active]: currentValue === value })}>{label}</div>
}

type OptionType = {|
  label: string,
  value: any,
  color?: string,
  disabled?: boolean,
|}

type Props = {|
  value: any,
  option1?: OptionType,
  option2?: OptionType,
  onChange: Function,
  className?: string,
  size?: 'small' | 'large',
  style?: Object,
|}

export default class Toggle extends React.PureComponent<Props> {
  onClick: Function = (event: MouseEvent) => {
    const { value, option1, option2, onChange } = this.props

    const newValue = value !== option1?.value ? option1?.value : option2?.value

    onChange(newValue, event)
  }

  isDisabled(): boolean {
    const { value, option1, option2 } = this.props

    return Boolean((value === option1?.value && option1?.disabled) || (value === option2?.value && option2?.disabled))
  }

  render(): React$Node {
    const { value, onChange, option1, option2, size, style, className, ...props } = this.props

    const styleBtn = value === option1?.value ? {} : { width: '100%' }

    const color = value === option1?.value ? option1?.color : option2?.color

    const _style = {
      backgroundColor: color,
      borderColor: color,
      ...style,
      height: 30,
    }

    const _className = cx(classes.toggle, {
      [classes.disabled]: this.isDisabled(),
      [classes.small]: size === 'small',
      className,
    })

    return (
      <div className={_className} onClick={this.onClick} style={{ ..._style }} {...props}>
        <div className={classes.btnContainer} style={styleBtn}>
          <div className={classes.btn} />
        </div>

        <div className={classes.ghost}>
          <div className={classes.btnContainer}>
            <div className={classes.btn} />
          </div>
          {option1?.label ? <div className={classes.ghostLabel}>{option1.label}</div> : null}
        </div>

        <div className={classes.ghost}>
          <div className={classes.btnContainer}>
            <div className={classes.btn} />
          </div>
          {option2?.label ? <div className={classes.ghostLabel}>{option2.label}</div> : null}
        </div>

        {option1 ? <Option className={classes.option1} option={option1} currentValue={value} /> : null}
        {option2 ? <Option className={classes.option2} option={option2} currentValue={value} /> : null}
      </div>
    )
  }
}
