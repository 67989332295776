// @flow
import Router from 'app/components/LocalRouter'
import { linkPropsLocalStorageSetter, pathInterceptorLocalStorageGetter } from 'app/components/LocalRouter/utils'

export const createRouter = (routerName: string, routes: Object, params?: Object): * => {
  const router = new Router(routerName, routes, params, {
    linkProps: linkPropsLocalStorageSetter(routerName),
    pathInterceptor: pathInterceptorLocalStorageGetter(routerName),
  })

  return router
}
