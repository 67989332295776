// @flow

import api from 'app/core/api/api'
import type { StoreResourceDef } from 'app/core/types'
import type { AssetLinksActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const assetLinks: StoreResourceDef<AssetLinksActions<>> = {
  resourceType: 'assetLinks',
  actions: (getResources) => ({
    fetchAssetLinks: (asssetId, config) => {
      const { params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets.links({ id: asssetId }, params.queries).then(transformResults(restConfig))
        },
      }
    },
    fetchAssetUsage: (assetId, config) => {
      const { params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => api.assets.usage({ id: assetId }, params.queries).then(transformResults(restConfig)),
      }
    },
  }),
  relations: {
    to_assetInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'to_asset',
    },
    from_assetInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'from_asset',
    },
  },
}
