// @flow
import React from 'react'
import Widget, { type WidgetProps } from 'app/components/Widget/Widget.jsx'
import MessagesTable from './MessagesTable.jsx'

export default function (): React$Element<(props: WidgetProps) => React$Node> {
  return (
    <Widget style={{ height: '100%', width: '100%' }}>
      <MessagesTable />
    </Widget>
  )
}
