// @flow

import React from 'react'
import { Rating } from 'app/components/Form'
import { regex } from 'app/libs/helpers'
import type { Column, DefaultCellProps } from '../../types'

type Params = {|
  ...DefaultCellProps,
|}

export const CellRating = ({ actions, ...data }: Params): Column => {
  return {
    cellType: `CellRating`,
    Cell: (instance) => {
      const { value, cell } = instance
      const { getCellProps, row } = cell
      const { edition } = getCellProps()
      const { isBeingEdit, endEdit, save } = edition

      if (isBeingEdit) {
        return (
          <div className="fullHeight flex center alignCenter">
            <Rating
              value={value}
              onBlur={(_value, event) => {
                if (value !== _value) save(_value)
                else endEdit()
              }}
              onChange={(_value) => {
                if (value !== _value) save(_value)
                else endEdit()
              }}
              name={row.original?.id}
              focusOnMount={true}
            />
          </div>
        )
      }

      if (!regex.exist(value)) return null

      return (
        <div className="fullHeight flex center alignCenter">
          <Rating value={value} readOnly={true} name={row.original?.id} />
        </div>
      )
    },
    actions: (instance, cell) => {
      const { edition } = cell.getCellProps()
      const { save } = edition

      const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []

      return [
        ...resolvedActions,
        { separator: true },
        {
          label: 'Rating',
          editAction: true,
          items: [0, 1, 2, 3, 4, 5].map((opt) => ({
            onClick: () => {
              save(opt)
              cell.focusCell()
            },
            editAction: true,
            label: <Rating value={opt} readOnly={true} name={String(opt)} />,
            hotKeys: [String(opt)],
            rightLabel: String(opt),
          })),
        },
      ]
    },
    groupingFns: {
      rating: {
        fn: (rows, columnId, instance) =>
          rows.reduce((prev, row, i) => {
            const resKey = String(row.values[columnId])
            prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
            prev[resKey].push(row)
            return prev
          }, {}),
        label: 'Rating',
        headerRow: (cell) => {
          if (!cell.value) return null
          return <Rating value={cell.value} readOnly={true} />
        },
      },
    },
    width: 140,
    ...data,
  }
}
