// @flow

/* eslint-disable react/no-multi-comp  */

import * as React from 'react'
import { pipeRequest } from 'react-hoc-pipe'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader from 'app/components/Loader/Loader.jsx'

export type { Pipe, PipeInst } from './pipe.type'

const hocs = {
  enableDeepCompare: (options) => () => {
    options.enableDeepCompare = true
  },
  connect:
    (options) =>
    (mapStateToProps, mapDispatchToProps, mergeProps, connectOptions = {}) =>
    (App) => {
      if (options.enableDeepCompare) {
        connectOptions.areStatePropsEqual = (prevState, nextState) =>
          JSON.stringify(prevState) === JSON.stringify(nextState)
      }

      return connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(App)
    },
  withRouter: (options) => () => withRouter,
  isLoading: (pipeOptions) => (isLoading) => (App) => {
    return function (props) {
      const FinalApp = App
      const FinalLoader = pipeOptions.Loader || null
      return isLoading(props) && Loader ? <FinalLoader {...props} /> : <FinalApp {...props} />
    }
  },
}

export default (externalsHOCs?: Object): * => {
  return pipeRequest({ ...hocs, ...externalsHOCs }).renderLoader(() => <Loader />)
}
