// @flow
import React, { useEffect, useMemo, useState } from 'react'
import { filter, sortBy } from 'lodash'
import { MUIButton, Input } from 'app/components/Form'
import type { AssetTypes, ResourcesList, Attribute, PersonAttribute } from 'app/core/types'
import resources from 'app/store/resources'
import { Permission } from 'app/containers/Permissions'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'
import { confirmDelete } from 'app/components/Modal/confirmDelete.jsx'

import { useSelector } from 'react-redux'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import { NODE_DIRECTORY, Node } from 'app/components/TreeDnD/Node'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'
import { getResources } from 'app/store/selectors/getResources'
import { getAttributeNode } from './getAttributeNode.jsx'
import { DisplayAttribute } from './DisplayAttribute.jsx'
import classes from './AttributesEditor.module.scss'
import { NewAttributeModal } from './NewAttributeModal.jsx'

type Props = {|
  displayInfos: boolean,
  style?: Object,
  assetType: ?AssetTypes,
  attributeType?: string,
|}

export function AttributesEditor(props: Props): React$Node {
  const { displayInfos, style, assetType, attributeType } = props

  const [attribute, setAttribute] = useState<Attribute | void>()
  const [aggregated, setAggregated] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const projectId = useMemo(() => getProjectIdFromURL() || '', [])

  const attributes = useSelector((state) => {
    return getResources<ResourcesList<Attribute>>(state, 'attributes', { project: projectId }, undefined)
  })

  useEffect(() => {
    if (projectId) {
      resources.attributes.fetchByProject(projectId)
    }
  }, [])

  function onSelect(attribute: Attribute) {
    setAttribute(attribute)
  }

  async function onEdit(attribute: Attribute) {
    const attributeUpdateRes = await resources.attributes.update(attribute)
    const newAttribute = attributeUpdateRes.resources[0]

    const trackingSchemaUpdateRes = await resources.trackingSchemas.updateAttributeByID({
      id: attribute.id,
      projectID: projectId,
      newAttribute,
    })
    setAttribute(newAttribute)
    return [attributeUpdateRes, trackingSchemaUpdateRes]
  }

  async function onCreate(attribute: $Shape<Attribute> | $Shape<PersonAttribute>) {
    const res = await resources.attributes.create({ ...attribute, project: projectId })
    setAttribute(res.resources[0])
    return res
  }

  async function onDelete(attribute: Attribute) {
    const promises = [
      resources.trackingSchemas.deleteItemByID({ id: attribute.id, projectID: projectId }),
      resources.attributes.delete(attribute.id),
    ]
    const res = await Promise.all(promises)
    setAttribute()
    return res
  }

  function onSearchAttribute(event: SyntheticInputEvent<>) {
    setAttribute()
    setSearch(event.target.value)
  }

  function toggleAggregated(event: SyntheticMouseEvent<>) {
    setAggregated((aggregated) => !aggregated)
  }

  const filteredAttributes = useMemo(
    () =>
      sortBy(
        filter(
          attributes,
          (attr: Attribute): boolean =>
            attr.name.includes(search) && (aggregated ? !['text'].includes(attr.attrType) : true),
        ),
        ['name'],
      ),
    [attributes, search],
  )

  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: filteredAttributes.map((attribute, index) =>
        getAttributeNode({
          attribute,
          aggregated,
          onSelect,
          parentNode: rootNode,
          type: attributeType ?? 'attributes',
        }),
      ),
    })

    return rootNode
  }, [filteredAttributes.length, aggregated])

  return (
    <div className={classes.container} style={style} data-cy="attributes-editor">
      <div className={classes.toolbar}>
        <div>
          <Input
            placeholder="Search an attribute"
            value={search}
            onChange={onSearchAttribute}
            style={{ width: 300 }}
            dataCy="attributEditor-search"
          />
        </div>
        <div className="flex row noWrap">
          {['fo', 'ep'].includes(assetType) ? (
            <MUIButton
              tooltip="Aggregated attributes"
              onClick={toggleAggregated}
              color={aggregated ? 'primary' : undefined}
              dataCy="attr-aggregated-create"
            >
              Aggregated
            </MUIButton>
          ) : null}
          <Permission actions={['projet_follow-up_asset attributes_create']}>
            <NewAttributeModal
              title="Create new attribute"
              onSave={onCreate}
              edit={false}
              projectId={projectId || undefined}
            >
              <MUIButton
                icon="fas-plus"
                tooltip="Create new attribute"
                style={{ marginLeft: 5 }}
                dataCy="attr-create"
              />
            </NewAttributeModal>
          </Permission>
        </div>
      </div>
      <div className={classes.subContainer}>
        <div className={classes.scrollBlock} data-cy="attributEditor-listItems">
          {!filteredAttributes.length ? (
            <div className={classes.noAttributeItem}>No attributes</div>
          ) : (
            <TreeDnD rootNode={rootNode} />
          )}
        </div>
        <div className={classes.displayAttribute}>
          {displayInfos && attribute ? (
            <DisplayAttribute
              attribute={attribute}
              EditButtons={
                <div className="flex row noWrap">
                  <div className={classes.listItem__actionContainer}>
                    <Permission actions={['projet_follow-up_asset attributes_delete']}>
                      <FontIcon
                        className={classes.iconButton}
                        icon="trash"
                        data-cy={cyLabelFormater('attribut-item-delete', attribute.name)}
                        onClick={() => {
                          confirmDelete({
                            render: (
                              <div>
                                Are you sure you want to delete the <b>{attribute.name}</b> attribute?
                              </div>
                            ),
                            validateMessage: 'Attribute deleted',
                            onValidate: () => onDelete(attribute),
                          })
                        }}
                      />
                    </Permission>
                    <Permission actions={['projet_follow-up_asset attributes_update']}>
                      <NewAttributeModal
                        title="Edit attribute"
                        onSave={onEdit}
                        attribute={attribute}
                        edit={true}
                        projectId={projectId || undefined}
                      >
                        <FontIcon
                          data-cy={cyLabelFormater('attribut-item-edit', attribute.name)}
                          className={classes.iconButton}
                          icon="fas-edit"
                        />
                      </NewAttributeModal>
                    </Permission>
                  </div>
                </div>
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
