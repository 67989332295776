// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Pagination-___Pagination-module__pagination {
  display: flex;
}

  .app-components-Pagination-___Pagination-module__pagination ul {
    display: flex;
    margin: 0 20px;
  }

  .app-components-Pagination-___Pagination-module__pagination li {
    display: inline-block;
    padding: 3px;
    cursor: pointer;
  }

  .app-components-Pagination-___Pagination-module__pagination li:hover,
    .app-components-Pagination-___Pagination-module__pagination li.app-components-Pagination-___Pagination-module__active {
      font-weight: bold;
    }
`, "",{"version":3,"sources":["webpack://./app/components/Pagination/Pagination.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;AAiBf;;EAfE;IACE,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,eAAe;EAMjB;;EAJE;;MAEE,iBAAiB;IACnB","sourcesContent":[".pagination {\n  display: flex;\n\n  ul {\n    display: flex;\n    margin: 0 20px;\n  }\n\n  li {\n    display: inline-block;\n    padding: 3px;\n    cursor: pointer;\n\n    &:hover,\n    &.active {\n      font-weight: bold;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `app-components-Pagination-___Pagination-module__pagination`,
	"active": `app-components-Pagination-___Pagination-module__active`
};
export default ___CSS_LOADER_EXPORT___;
