// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-DragNDrop-___DragNDrop-module__dragWrapper {
  cursor: grab;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
}

.app-components-DragNDrop-___DragNDrop-module__dragWrapper:active {
  cursor: grabbing;
}
`, "",{"version":3,"sources":["webpack://./app/components/DragNDrop/DragNDrop.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,wBAAwB;EACxB,yBAAyB;EACzB,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dragWrapper {\n  cursor: grab;\n  transition-property: all;\n  transition-duration: 0.4s;\n  transition-timing-function: ease-out;\n}\n\n.dragWrapper:active {\n  cursor: grabbing;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragWrapper": `app-components-DragNDrop-___DragNDrop-module__dragWrapper`
};
export default ___CSS_LOADER_EXPORT___;
