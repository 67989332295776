// @flow
import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import type { PostBoardNotesActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const postBoardNotes: StoreResourceDef<PostBoardNotesActions<>> = {
  resourceType: 'postBoardNotes',
  actions: (actionsCreators) => ({
    history: ({ id }, config) => {
      const { params = {}, ...restConfig } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.postBoardNotes
            .history({ id }, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(transformResults(restConfig)),
      }
    },
  }),
}
