/** @flow */
import React, { useEffect, useMemo, useState } from 'react'
import { filter, map, find, head, forEach } from 'lodash'
import history from 'app/main/routerHistory'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import resources from 'app/store/resources'
import { Table } from 'app/components/Table/Table.jsx'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import { CellLink, CellThumbnail, CellText, CellRichText, CellFlags, CellMedias } from 'app/components/Table/Cells'
import { accessorAssetMediaGroups, saveAssetMediasGroups } from 'app/components/Table/Cells/CellMedias/utils.js'
import { router } from 'app/containers/Assets/AssetDetail/router'
import { getResources } from 'app/store/selectors/getResources'
import type { ColumnHeader, Column } from 'app/components/Table/types'
import type { ResourcesList, StepProject, Asset, Match, ID, Option, Attribute } from 'app/core/types'
import { permission } from 'app/containers/Permissions/index.js'
import { openModal } from 'app/components/Modal/index.js'
import { postBoardTypes } from 'app/core/constants'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import { createUpdatingFlagsPromises } from 'app/components/Form/Flags/utils'
import { AssetHistoryModal } from 'app/containers/AssetHistory/AssetHistoryModal.tsx'
import vars from 'app/styles/vars.js'
import AttributesColumns from 'app/components/AttributesColumns/AttributesColumns.jsx'
import { createRecursiveRequestIfNoPostboardLink } from './utils'
import { Filters } from './Filters'

export const tableId = 'PostBoardShots'

type Props = {|
  match?: Match,
  episodeId?: ?ID,
|}

const useResources = (episodeId: ?ID, projectId: ?ID, paginatedList: ?string) => {
  const [episode, setEpisode] = useState<Asset | void>()
  const [stepProjects, setStepProjects] = useState<Array<
    StepProject | { stepInst: { id: void, name: 'General' } },
  > | void>()
  const [notes, setNotes] = useState<Array<Option> | void>()
  const [attributes, setAttributes] = useState<Array<Attribute> | void>()

  useEffect(() => {
    function _getResources(episodeProject: ?ID) {
      const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: episodeProject || '' } } }

      return Promise.all([
        resources.assetsCustomAttributValues.fetch('category', requestsConfig),
        episodeProject ? resources.stepProjects.fetchByProject(episodeProject, requestsConfig) : undefined,
        episodeId ? resources.assets.fetchOne(episodeId) : undefined,
        resources.attributes.fetchAll(requestsConfig),
      ]).then(() => {
        if (episodeId) setEpisode(getResources<Asset>(undefined, 'assets', episodeId))

        const resourcesStepProjects = getResources<ResourcesList<StepProject>>(
          undefined,
          'stepProjects',
          { project: projectId },
          ['stepInst'],
        )
        const filteredStepsProjects = filter(
          resourcesStepProjects,
          (stepProject) => stepProject.stepInst?.assetType === 'sh' && stepProject.display?.includes(tableId),
        )
        const _stepProjects = [{ stepInst: { id: undefined, name: 'General' } }, ...filteredStepsProjects]
        setStepProjects(_stepProjects)

        setNotes([
          ..._stepProjects.map(({ stepInst }) => {
            if (stepInst.name === 'General') return { value: 'general', label: 'general' }
            return { value: stepInst.id, label: stepInst.name }
          }),
        ])

        setAttributes(
          map(
            getResources<ResourcesList<Attribute>>(
              undefined,
              'attributes',
              projectId ? { project: projectId } : undefined,
            ),
          ),
        )
      })
    }

    if (episodeId) {
      resources.assets.fetchOne(episodeId).then((res) => {
        _getResources(res?.resources?.[0]?.project || projectId)
      })
    } else _getResources(projectId)
  }, [episodeId])

  return {
    episode,
    notes,
    stepProjects,
    attributes,
  }
}

export function TablePostBoardShots(props: Props): React$Node {
  const { match } = props
  const projectId = getProjectIdFromURL()
  const episodeId = props.episodeId || match?.params.episodeId || null

  const [paginatedList, setPaginatedList] = useState<string | void | null>()

  const { episode, stepProjects, notes, attributes } = useResources(episodeId, projectId, paginatedList)

  useEffect(() => {
    if (episode) documentTitle(episode.name)
  }, [episode])

  const showHistory = (note) => () => {
    return openModal(
      <AssetHistoryModal
        tableId="assetsHistory"
        requestName="history"
        resourceType="postBoardNotes"
        projectId={projectId}
        columns={[
          {
            Header: 'Notes',
            id: 'notes',
            columns: [
              CellText({
                id: 'step',
                Header: 'Step',
                accessor: 'actionObject.stepInst.name',
                readOnly: true,
              }),
              CellRichText({
                id: 'note',
                Header: 'Note',
                actions: () => ['copy'],
                accessor: 'actionObject.text',
                readOnly: true,
                width: undefined,
              }),
            ],
          },
        ]}
        assetId={note.id}
        title="Model note history"
      />,
    )
  }

  const defaultActions = (note) => {
    if (!note) return []
    return ['copy', { label: 'Show history', editAction: false, onClick: showHistory(note) }]
  }

  const toolbar = (instance) => {
    return (
      <AssetsSelect
        assetTypes={['ep']}
        onChange={(episode: { value: string }) => {
          if (!projectId || !episode) return
          history.push(`/projects/${projectId}/postboard-shots/${episode.value}`)
        }}
        placeholder="Search asset"
        style={{ width: '100%', minWidth: 200, background: '#ffffff' }}
        showAssetType={true}
        showAssetParent={true}
        value={episode ? { label: episode.name, value: episode.id, icon: assetIcons(episode.assetType) } : null}
      />
    )
  }

  const toggleButtons = (instance) => {
    if (episode && episode.id && permission(['wiki___read'])) {
      return [
        {
          icon: 'fas-external-link-alt',
          onClick: () => router.goTo('wiki', { assetId: episode.id }, { rightPanel: true }),
          label: 'Wiki',
          key: 'wiki',
        },
      ]
    }
    return []
  }

  const readOnly = !permission(['projet_production notes_notes_update'])

  const attributesColumns = useMemo<Array<Column> | void>(
    () => AttributesColumns({ attributes, readOnly }),
    [attributes],
  )

  const columns: Array<ColumnHeader> = useMemo(() => {
    if (!stepProjects) return []
    return [
      {
        Header: 'Asset',
        id: 'Asset',
        columns: [
          CellLink({
            Header: 'Asset',
            showRemoved: true,
            fixable: false,
            fixed: 'left',
            accessor: 'name',
            id: 'name',
            readOnly,
            sortingKey: 'name',
            width: 150,
            onClick: (row) => router.goTo('index', { assetId: row.original.id }, { rightPanel: true }),
          }),
          CellText({
            Header: 'Sequence',
            id: 'sequence',
            hiddenable: true,
            fixable: true,
            sortingKey: 'attributes__sequence',
            containerStyle: { padding: 0 },
            colored: true,
            readOnly,
            actions: () => ['edit', 'copy', 'past'],
            accessor: (item: Asset) => {
              let { sequence } = item.attributes
              if ([undefined, null].includes(sequence)) {
                sequence = ''
              }
              return sequence
            },
            width: 80,
            save: {
              resource: 'assets',
              formatData: (item: Asset, value) => ({ id: item.id, attributes: { sequence: value } }),
            },
          }),
          CellThumbnail({
            Header: 'Thumbnail',
            accessor: 'thumbnailInst',
            id: 'thumbnail',
            fixable: true,
            hiddenable: true,
            width: 150,
            readOnly,
            actions: () => ['edit', 'copy', 'past', 'delete'],
            save: {
              resource: 'assets',
              formatData: (item: Asset, value, cell, instance, type) => {
                return {
                  id: item.id,
                  thumbnail: value?.id || null,
                }
              },
            },
          }),
          CellFlags({
            Header: 'Flags',
            id: 'flags',
            width: 200,
            accessor: 'assetFlagsInst',
            fixable: true,
            hiddenable: true,
            readOnly,
            actions: (row) => ['edit', 'copy', 'past', 'delete'],
            foreignKey: 'asset',
            category: (cell) => cell.row.original.assetType,
            getResourceID: (cell) => cell.row.original.id,
            save: {
              resource: 'assets',
              formatData: (item: Asset, value, cell, instance, type) => {
                if (type === 'delete') value = []

                const newFlags = map(value, ({ flagInst }) => ({ asset: item.id, flag: flagInst.id }))
                return createUpdatingFlagsPromises(newFlags, map(item.assetFlagsInst), 'assetFlags', type)
              },
            },
          }),
          CellMedias({
            Header: 'Refs',
            id: 'medias',
            fixable: true,
            hiddenable: true,
            accessor: (item) => {
              if (!item) return null
              const { assetMediaGroupsInst, id } = item
              return accessorAssetMediaGroups(assetMediaGroupsInst, [
                postBoardTypes.postBoardShots,
                `${postBoardTypes.postBoardShots}_${id}`,
              ])
            },
            readOnly,
            allowPinMedia: true,
            allowValidateMedia: true,
            actions: () => ['edit', 'delete'],
            save: {
              resource: 'medias',
              formatData: (item, value, cell, instance, type) => {
                const { assetMediaGroupsInst, id } = item
                const groupMediaName = `${postBoardTypes.postBoardShots}_${id}`
                let mediasGroup

                forEach(assetMediaGroupsInst, (assetMediaGroup) => {
                  if (assetMediaGroup.mediaGroupInst && assetMediaGroup.mediaGroupInst.name === groupMediaName) {
                    mediasGroup = assetMediaGroup.mediaGroupInst
                  }
                })

                return saveAssetMediasGroups(value, groupMediaName, item.id, mediasGroup, type, cell.value)
              },
            },
          }),
        ],
      },
      {
        id: 'notes',
        Header: 'Production Notes',
        headerColor: '#EEB413',
        columns: [
          ...stepProjects.map(({ stepInst }) => {
            return CellRichText({
              Header: stepInst.name,
              fixable: true,
              hiddenable: true,
              readOnly,
              actions: (instance, cell) => {
                const postBoardLink = map(cell.row.original.postBoardLinksInst)[0]
                const note = find(
                  postBoardLink && postBoardLink.notesInst,
                  ({ step }) => step === stepInst.id || (step === null && !stepInst.id),
                )

                return ['edit', 'delete', 'past', 'separator', ...defaultActions(note)]
              },
              accessor: (item) => {
                if (!item) return ''

                const { postBoardLinksInst } = item

                const postBoardLink = head(map(postBoardLinksInst))
                const note = find(
                  postBoardLink?.notesInst,
                  ({ step }) => step === stepInst.id || (step === null && !stepInst.id),
                )

                return note && note.text ? note.text : ''
              },
              id: stepInst.id || 'general',
              width: 300,
              save: {
                resource: 'postBoardNotes',
                formatData: (item, value, cell, instance, type) => {
                  const postBoardLink = head(map(cell.row.original.postBoardLinksInst))
                  if (!postBoardLink) {
                    return createRecursiveRequestIfNoPostboardLink(
                      item,
                      undefined,
                      value,
                      !stepInst.id ? null : stepInst,
                    )
                  }
                  const { notesInst, id } = postBoardLink

                  const note = find(notesInst, ({ step }) => step === stepInst.id || (step === null && !stepInst.id))

                  if (!note) return { link: id, text: value, step: stepInst.id }
                  return { id: note.id, text: value }
                },
              },
            })
          }),
        ],
      },
      {
        Header: 'Assets Attributes',
        id: 'assetAttributes',
        columns: attributesColumns || [],
        headerColor: vars.colors.red,
      },
    ]
  }, [stepProjects, attributesColumns])

  const resourcesParams = useMemo(() => {
    if (!episode) return undefined
    return {
      resourceType: 'assets',
      requestName: 'fetchChildrenWithPostBoardLinks',
      headers: episode.project ? { [window.OVM_PROJECT_HEADER]: episode.project || '' } : {},
      requestData: episode.id,
      includedResources: [
        'thumbnailInst',
        'assetFlagsInst.flagInst',
        'postBoardLinksInst',
        'postBoardLinksInst.notesInst',
        'assetMediaGroupsInst.mediaGroupInst.mediasInst',
      ],
    }
  }, [episode])

  const filters = useMemo(() => new Filters({ notes: notes || [] }).getFilters(), [notes])

  if (!stepProjects) return null

  return (
    <Table
      tableId={tableId}
      exportExcelFormat={episode ? { assetId: episode.id, data: {}, type: 'postBoardShots' } : undefined}
      resourcesParams={resourcesParams}
      filters={filters}
      columns={columns}
      getPaginatedList={(requestKey) => setPaginatedList(requestKey)}
      extendToolbar={toolbar}
      toggleButtons={toggleButtons}
      rowExpander={true}
      defaultPrefs={{
        minLineHeight: 70,
      }}
    />
  )
}
