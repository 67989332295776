// @flow
import type { StoreResourceDef } from 'app/core/types'

export const trackingSchemaAccesses: StoreResourceDef<> = {
  resourceType: 'trackingSchemaAccesses',
  relations: {
    assetInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'asset',
    },
    trackingSchemaInst: {
      type: 'hasOne',
      resourceType: 'trackingSchemas',
      foreignKey: 'trackingSchema',
    },
  },
}
