// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellPriority-___groupingFns-module__priority {
  border-radius: 8px;
  padding: 3px 10px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellPriority/groupingFns.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".priority {\n  border-radius: 8px;\n  padding: 3px 10px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priority": `app-components-Table-Cells-CellPriority-___groupingFns-module__priority`
};
export default ___CSS_LOADER_EXPORT___;
