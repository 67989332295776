// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Subscription-___SubscribeButton-module__buttonsContainer {
  border-radius: 3px;
  overflow: hidden;
}

.app-containers-Subscription-___SubscribeButton-module__buttonsContainer:first-child {
  margin-left: 0px !important;
}

.app-containers-Subscription-___SubscribeButton-module__buttonsContainer:last-child {
  margin-left: 0;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Subscription/SubscribeButton.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".buttonsContainer {\n  border-radius: 3px;\n  overflow: hidden;\n}\n\n.buttonsContainer:first-child {\n  margin-left: 0px !important;\n}\n\n.buttonsContainer:last-child {\n  margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": `app-containers-Subscription-___SubscribeButton-module__buttonsContainer`
};
export default ___CSS_LOADER_EXPORT___;
