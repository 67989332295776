// @flow
import React, { useEffect, useRef, useState, FocusEvent } from 'react'
import cx from 'classnames'
import type { InputProps } from 'app/core/types'
import type { InputProps as TSInputProps } from 'app/core/types/types'
import keyCode from 'app/libs/keyCode'
import classes from './Timecode.module.scss'

export interface TSTimecodeProps extends TSInputProps {
  value?: number; // timecode (nombre de secondes)
  framerate: number; // framerate (image par secondes)
  onChange?: (value: number) => void;
  inputsClassName?: string;
  readOnly?: boolean;
  onBlur?: (value: number, event: FocusEvent) => void;
  onCancel?: () => void;
  autoFocus?: boolean;
}

export type TimecodeProps = {|
  ...InputProps,
  value?: number, // timecode (nombre de secondes)
  framerate: number, // framerate (image par secondes)
  onChange?: (value: number) => void,
  inputsClassName?: string,
  readOnly?: boolean,
  onBlur?: (value: number, event: SyntheticFocusEvent<>) => void,
  onCancel?: Function,
  autoFocus?: boolean,
|}

type TimecodeType = {|
  hours: number,
  minutes: number,
  seconds: number,
  frames: number,
|}

export function Timecode(props: TimecodeProps): React$Node {
  const { value: defaultValue, framerate, onChange, inputsClassName, readOnly, onBlur, onCancel, autoFocus } = props

  const initValues: (value: number) => TimecodeType = (value: number) => {
    const totalTime = Math.floor(value / framerate)
    const moduloMinutes = totalTime % 3600
    const moduloSeconds = totalTime % 60

    return {
      hours: totalTime >= 3600 ? Math.floor(totalTime / 3600) : 0,
      minutes: moduloMinutes >= 60 ? Math.floor(moduloMinutes / 60) : 0,
      seconds: moduloSeconds,
      frames: Math.round(value % framerate),
    }
  }

  const [value, setValue] = useState({
    ...initValues(!defaultValue || Number.isNaN(defaultValue) ? 0 : defaultValue),
  })

  useEffect(() => {
    setValue({
      ...initValues(defaultValue || 0),
    })
  }, [defaultValue])

  const containerRef = useRef()
  const hoursRef = useRef()
  const minutesRef = useRef()
  const secondsRef = useRef()
  const framesRef = useRef()

  const exitOnCancel = useRef(false)

  function getSum() {
    const { hours, minutes, seconds, frames } = value
    return (hours * 3600 + minutes * 60 + seconds) * framerate + frames
  }

  const _onChange: * = (unit: string) => (event: Object) => {
    event.preventDefault()
    event.stopPropagation()
    if (Number.isNaN(Number(event.target.value))) return
    setValue({ ...value, [unit]: Number(event.target.value) })
  }

  function _onBlur(event: SyntheticFocusEvent<>) {
    event.preventDefault()
    event.stopPropagation()
    if (exitOnCancel.current) {
      exitOnCancel.current = false
      return
    }
    if (
      event.relatedTarget &&
      event.relatedTarget !== hoursRef.current &&
      event.relatedTarget !== minutesRef.current &&
      event.relatedTarget !== secondsRef.current &&
      event.relatedTarget !== framesRef.current
    ) {
      const value = getSum()
      if (onChange) onChange(value)
      if (onBlur) onBlur(value, event)
    }
  }

  const onKeyDown: * = (unit: string) => (event: SyntheticKeyboardEvent<EventTarget>) => {
    let maxNumber: number
    if (unit === 'hours') maxNumber = 24
    else maxNumber = 60

    if (event.keyCode === keyCode.ESCAPE) {
      event.preventDefault()
      event.stopPropagation()
      exitOnCancel.current = true
      if (onCancel) onCancel()
      setValue({
        ...initValues(!defaultValue || Number.isNaN(defaultValue) ? 0 : defaultValue),
      })
    }
    if (event.keyCode === keyCode.UP) {
      event.preventDefault()
      setValue((state) => ({ ...state, [unit]: Number(state[unit]) < maxNumber ? Number(state[unit]) + 1 : 0 }))
    }
    if (event.keyCode === keyCode.DOWN) {
      event.preventDefault()
      setValue((state) => ({ ...state, [unit]: Number(state[unit]) > 0 ? Number(state[unit]) - 1 : 0 }))
    }
    if (event.keyCode === keyCode.ENTER) {
      event.preventDefault()
      if (onChange) onChange(getSum())
    }
    if (event.keyCode === keyCode.TAB) {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()
      if (unit === 'hours') minutesRef.current?.select()
      if (unit === 'minutes') secondsRef.current?.select()
      if (unit === 'seconds') framesRef.current?.select()
      if (unit === 'frames') if (onChange) onChange(getSum())
    }
  }

  const firstFocused = useRef(false)
  useEffect(() => {
    if (autoFocus && !firstFocused.current) {
      firstFocused.current = true
      minutesRef.current?.focus()
      minutesRef.current?.select()
    }
  }, [!!firstFocused.current, autoFocus])

  if (!readOnly)
    return (
      <div
        className={cx(classes.container)}
        ref={(ref) => {
          containerRef.current = ref
        }}
        {...props}
      >
        <div className="flex alignCenter fullHeight flex1" style={{ minWidth: '10px', maxWidth: '20px' }}>
          <input
            value={value.hours}
            onChange={_onChange('hours')}
            onKeyDown={onKeyDown('hours')}
            ref={hoursRef}
            onBlur={_onBlur}
            className={cx(classes.input, inputsClassName)}
            style={{ textAlign: 'end' }}
          />
          <span className={classes.unit}>:</span>
        </div>
        <div className="flex alignCenter fullHeight flex1" style={{ minWidth: '10px', maxWidth: '20px' }}>
          <input
            value={value.minutes}
            onChange={_onChange('minutes')}
            onKeyDown={onKeyDown('minutes')}
            ref={minutesRef}
            onBlur={_onBlur}
            className={cx(classes.input, inputsClassName)}
            style={{ textAlign: 'end' }}
          />
          <span className={classes.unit}>:</span>
        </div>
        <div className="flex alignCenter fullHeight flex1" style={{ minWidth: '10px', maxWidth: '20px' }}>
          <input
            value={value.seconds}
            onChange={_onChange('seconds')}
            onKeyDown={onKeyDown('seconds')}
            ref={secondsRef}
            onBlur={_onBlur}
            className={cx(classes.input, inputsClassName)}
            style={{ textAlign: 'end' }}
          />
          <span className={classes.unit}>.</span>
        </div>
        <div className="flex alignCenter fullHeight flex1" style={{ minWidth: '10px', maxWidth: '20px' }}>
          <input
            value={value.frames}
            onChange={_onChange('frames')}
            onKeyDown={onKeyDown('frames')}
            ref={framesRef}
            onBlur={_onBlur}
            className={cx(classes.input, inputsClassName)}
            style={{ textAlign: 'start' }}
          />
        </div>
      </div>
    )

  if (readOnly && !defaultValue) return ''

  return (
    <div className={cx(classes.container)} {...props}>
      <span style={{ textAlign: 'center', verticalAlign: 'middle', cursor: 'default' }}>
        {value.hours.toString().length === 1 ? `0${value.hours}` : value.hours}:
        {value.minutes.toString().length === 1 ? `0${value.minutes}` : value.minutes}:
        {value.seconds.toString().length === 1 ? `0${value.seconds}` : value.seconds}.{value.frames}
      </span>
    </div>
  )
}
