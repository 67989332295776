/** @flow */
import { regex } from './regex'

export { enumProxy, enumProxyFromArray } from './enumProxy'
export { default as validateURL } from './validateURL'
export { default as validateIP } from './validateIP'
export { default as formatPhoneNumber } from './formatPhoneNumber'
export { default as getCaretPosition } from './getCaretPosition'
export { default as toLocalDateStringDash } from './toLocalDateStringDash'
export { default as flattenTree } from './flattenTree'
export { default as reverseFlattenTree } from './reverseFlattenTree'
export { default as findImgURL } from './findImgURL'
export { default as findLinkURL } from './findLinkURL'
export { default as findFileBase64 } from './findFileBase64'
export { default as escapeRegExp } from './escapeRegExp'
export { default as dateToLocaleString } from './dateToLocaleString'
export { default as dateToLocaleStringFull } from './dateToLocaleStringFull'
export { default as dateToLocaleStringTime } from './dateToLocaleStringTime'
export { stringClean, stringCleanWithDot } from './stringClean'
export { default as getDaysInMonth } from './getDaysInMonth'
export { default as dateCreateAtMidnight } from './dateCreateAtMidnight'
export { default as dateCreateAtFirstDayOfMonth } from './dateCreateAtFirstDayOfMonth'
export { default as dateGetDayInterval } from './dateGetDayInterval'
export { default as dateGetMonthInterval } from './dateGetMonthInterval'
export { Duration } from './Duration'
export { cleanObject } from './cleanObject'
export { diffObject } from './diffObject'
export { regex } from './regex'
export { findIndexesOfChar } from './findIndexesOfChar'

/**
 * Make possible to find key front string on object
 * Example:
 *    obj = {
 *       name: 'Max',
 *       address: {
 *           city: 'Paris'
 *       },
 *       friends: [
 *           {name: 'Will'}
 *           {name: 'John'}
 *       ]
 *    }
 *
 *    findKeyOnObj(obj, 'address.city')
 *
 * It work also with array:
 *
 *    findKeyOnObj(obj, 'friends.0.name')
 *
 ***************************************************************
 *
 * @param {object} obj
 * @param {string} str
 *
 * return {all}
 */
export const findKeyOnObj = (obj: Object, str: string): Object => {
  let _obj: Object = obj

  const splitedStr = str.split('.')

  for (const splitedItem of splitedStr) {
    if (_obj[splitedItem] !== undefined) {
      _obj = _obj[splitedItem]
    } else {
      console.error('findKeyOnObj not found. object ', obj, ' key: ', str)
    }
  }

  return _obj
}

export const setKeyOnObj = (obj: Object, key: string, value: any) => {
  let _obj = obj

  const splitedStr = key.split('.')
  const lastItem = splitedStr.pop()

  for (const splitedItem of splitedStr) {
    if (_obj[splitedItem] !== undefined) {
      _obj = _obj[splitedItem]
    } else {
      console.error('findKeyOnObj not found')
    }
  }

  _obj[lastItem] = value
}

export const closest = (initialElement: Element, selector: string): Element | null => {
  let elem = initialElement
  while (elem) {
    if (elem.matches(selector)) return elem
    elem = elem.parentElement
  }
  return null
}

export const mathsRandomInt = (min: number, max: number): number => {
  return Math.round(Math.random() * (max - min) + min)
}

export const validateEmail = (email: string): boolean => {
  return regex.is.email.test(email)
}

/**
 * Example:
 *
 * const ACTIONS = helpers.createReduxAction('NAMESPACE', [
 *     'RECEIVE_A',
 *     'RECEIVE_B'
 * ]);
 *
 */
export const createReduxAction = (namespace: string, actions: Array<string>): { [action: string]: string } => {
  console.warn('createReduxAction() is deprecated. Use Enum() instead')

  const output = {}

  for (const action of actions) {
    output[action] = `${namespace}_${action}`
  }

  return output
}

export const nonBreakingDash = (str: string): string => {
  if (typeof str !== 'string') return str
  return str.replace(/-/g, '‑')
}

export const getAbsolutePosition = (el: Element): $Exact<{ x: number, y: number }> => {
  const elemRect = el.getBoundingClientRect()

  return {
    x: elemRect.left,
    y: elemRect.top,
  }
}

export const composedPath = (element: Element): ?Array<Element | Document> => {
  const path = []

  let el: Element

  while (el) {
    path.push(el)

    if (el.tagName === 'HTML') {
      path.push(document)
      path.push(window)

      return path
    }

    el = el.parentElement
  }

  return null
}

export const smoothScrollTo = (element: Element, target: number, duration: number): Promise<void> => {
  target = Math.round(target)
  duration = Math.round(duration)
  if (duration < 0) {
    return Promise.reject('bad duration')
  }
  if (duration === 0) {
    element.scrollTop = target
    return Promise.resolve()
  }

  const startTime = Date.now()
  const endTime = startTime + duration

  const startTop = element.scrollTop
  const distance = target - startTop

  // based on http://en.wikipedia.org/wiki/Smoothstep
  const smoothStep = function (start, end, point) {
    if (point <= start) {
      return 0
    }
    if (point >= end) {
      return 1
    }
    const x = (point - start) / (end - start) // interpolation

    return x * x * (3 - 2 * x)
  }

  return new Promise((resolve, reject) => {
    // This is to keep track of where the element's scrollTop is
    // supposed to be, based on what we're doing
    let previousTop = element.scrollTop

    // This is like a think function from a game loop
    const scrollFrame = function () {
      if (element.scrollTop !== previousTop) {
        reject('interrupted')
        return
      }

      // set the scrollTop for this frame
      const now = Date.now()
      const point = smoothStep(startTime, endTime, now)
      const frameTop = Math.round(startTop + distance * point)

      element.scrollTop = frameTop

      // check if we're done!
      if (now >= endTime) {
        resolve()
        return
      }

      // If we were supposed to scroll but didn't, then we
      // probably hit the limit, so consider it done; not
      // interrupted.
      if (element.scrollTop === previousTop && element.scrollTop !== frameTop) {
        resolve()
        return
      }
      previousTop = element.scrollTop

      // schedule next frame for execution
      setTimeout(scrollFrame, 0)
    }

    // boostrap the animation process
    setTimeout(scrollFrame, 0)
  })
}

export const removeExtension = (string: string = ''): string => string.replace(/\.[^/.]+$/, '')

export const symbolEnum = (list: Array<string>): Object => {
  const _obj = {}

  for (const key of list) {
    _obj[key] = key
  }

  return _obj
}

export const getIn = (obj: Object, path: Array<string>): any => {
  const _path = path.slice()

  if (_path.length > 1) {
    const key = _path.shift()

    return obj[key] && getIn(obj[key], _path)
  }

  return obj[path]
}

export const exist = (value: any): boolean => {
  return value !== null && value !== undefined
}
