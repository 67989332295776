// @flow
import type { StoreResourceDef } from 'app/core/types'
import api from 'app/core/api/api.js'
import type { FilesActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const files: StoreResourceDef<FilesActions<>> = {
  resourceType: 'files',
  actions: (getResources) => ({
    fetchAllByProject: ({ assetId, parentId }, config = {}) => {
      const { params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.files
            .fetchAll(
              {
                asset: assetId,
                asset__parent__uuid: assetId ? undefined : parentId,
                ...params.queries,
              },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(restConfig)),
      }
    },
  }),
  relations: {
    assetInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'asset',
    },
    authorInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'author',
    },
  },
}
