/** @flow */
import React from 'react'
import { permission } from 'app/containers/Permissions'
import type { ID } from 'app/core/types'

type Props = {|
  localRouter: Object,
  name: string,
  component?: any,
  componentProps?: Object,
  permission?: Array<string>,
  operator?: 'and' | 'or',
  projectId?: ID,
  children?: React$Element<any>,
|}

export default class Route extends React.Component<Props> {
  render(): React$Node {
    const {
      children,
      component: App,
      componentProps,
      localRouter,
      permission: perms,
      projectId,
      operator,
      name,
    } = this.props
    if (perms && !permission(perms, operator, false, projectId)) return null

    const route = localRouter.getRoute(name)
    if (!route.isActive) return null

    if (App) {
      return <App localRouter={localRouter} {...componentProps} projectId={projectId} params={route.params} />
    }

    return children
      ? React.cloneElement(children, {
          key: route.pathWithParams,
          router: localRouter,
          params: route.params,
          projectId,
        })
      : null
  }
}
