// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellNotesFollowUp-___CellNotesRead-module__container {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.app-components-Table-Cells-CellNotesFollowUp-___CellNotesRead-module__textCell {
  width: 100%;
  height: 100%;
  min-height: 50px;
}

.app-components-Table-Cells-CellNotesFollowUp-___CellNotesRead-module__textCell:focus-within {
  background-color: #04a6ff55;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellNotesFollowUp/CellNotesRead.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".container {\n  overflow-x: hidden;\n  overflow-y: auto;\n  height: 100%;\n}\n.textCell {\n  width: 100%;\n  height: 100%;\n  min-height: 50px;\n}\n\n.textCell:focus-within {\n  background-color: #04a6ff55;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellNotesFollowUp-___CellNotesRead-module__container`,
	"textCell": `app-components-Table-Cells-CellNotesFollowUp-___CellNotesRead-module__textCell`
};
export default ___CSS_LOADER_EXPORT___;
