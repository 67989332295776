/** @flow */
import vars from 'app/styles/vars.js'

export function getTextWidth(text: string, size?: number = 14, family?: string = vars.mainFont): number {
  const font = `${size}px ${family}`
  const context = document.createElement('canvas').getContext('2d')
  context.font = font
  const { width } = context.measureText(text)

  return Math.ceil(width)
}
