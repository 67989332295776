/** @flow */
import cx from 'classnames'
import React, { useRef } from 'react'

import keyCode from 'app/libs/keyCode'

import type { CellInstance } from '../../../types'

import classes from './LengthButton.module.scss'

type Props = {|
  instance: CellInstance,
  onMouseDown?: (e: SyntheticMouseEvent<any>) => void,
  onResize?: (e: MouseEvent, { diff: number }) => void,
  onStopResize?: (e: MouseEvent, { diff: number }) => void,
  className?: string,
  style?: Object,
  position: 'left' | 'right',
  contentType?: string,
|}

export function LengthButton(props: Props): React$Node {
  const { onMouseDown, onResize, onStopResize, className, style, position, contentType, instance } = props
  const { setGanttIsUnderEditing } = instance

  function resetRangeDimensions() {
    return {
      originX: 0,
      vector: 0,
    }
  }

  const rangeDimensions = useRef(resetRangeDimensions())

  function resize(e: MouseEvent) {
    const { originX } = rangeDimensions.current
    const vector = e.clientX - originX

    if (vector === 0) return

    rangeDimensions.current.vector = vector
    onResize?.(e, { diff: vector })
  }

  function stopResize(e: MouseEvent) {
    window.removeEventListener('mousemove', resize)
    window.removeEventListener('mouseup', stopResize)

    setGanttIsUnderEditing?.(false)

    const { vector } = rangeDimensions.current

    if (vector === 0) return

    onStopResize?.(e, { diff: vector })
  }

  function cancel(e: Object) {
    if (e.keyCode === keyCode.ESCAPE) {
      setGanttIsUnderEditing?.(false)

      resetRangeDimensions()
      onResize?.(e, { diff: 0 })
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('mouseup', stopResize)
      window.removeEventListener('keydown', cancel)
    }
  }

  function _onMouseDown(e: SyntheticMouseEvent<any>) {
    e.stopPropagation()

    if (e.button !== 0) return

    setGanttIsUnderEditing?.(true)

    rangeDimensions.current = resetRangeDimensions()
    rangeDimensions.current.originX = e.clientX

    onMouseDown?.(e)

    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResize)
    window.addEventListener('keydown', cancel)
  }

  return (
    <div
      className={cx(classes.container, className)}
      style={style}
      onMouseDown={_onMouseDown}
      position={position}
      tabIndex="0"
    >
      <div className={classes.button} content-type={contentType} />
    </div>
  )
}
