/** @flow */
import React from 'react'
import AutocompleteSingle from './AutocompleteSingle/AutocompleteSingle.jsx'
import AutocompleteMultiple from './AutocompleteMultiple/AutocompleteMultiple.jsx'

export default class Autocomplete extends React.PureComponent<*> {
  render(): React$Node {
    const { multiple, innerRef, ...props } = this.props
    const AutocompleteApp = multiple ? AutocompleteMultiple : AutocompleteSingle
    return <AutocompleteApp ref={innerRef} {...props} />
  }
}
