// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Groups-___Group-module__areaGroup {
  grid-area: group;
  overflow: hidden;
}

  .app-containers-Groups-___Group-module__areaGroup .app-containers-Groups-___Group-module__usersHead {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .app-containers-Groups-___Group-module__areaGroup .app-containers-Groups-___Group-module__usersHead .app-containers-Groups-___Group-module__searchInput {
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 15px;
      border: 0.5px solid #41474d;
    }

  .app-containers-Groups-___Group-module__areaGroup .app-containers-Groups-___Group-module__usersHead .app-containers-Groups-___Group-module__searchInput:focus {
        outline: none;
      }
`, "",{"version":3,"sources":["webpack://./app/containers/Groups/Group.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAkBlB;;EAhBE;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAYlB;;EAVE;MACE,iBAAiB;MACjB,kBAAkB;MAClB,kBAAkB;MAClB,2BAA2B;IAK7B;;EAHE;QACE,aAAa;MACf","sourcesContent":[".areaGroup {\n  grid-area: group;\n  overflow: hidden;\n\n  .usersHead {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 10px;\n\n    .searchInput {\n      padding: 5px 10px;\n      border-radius: 5px;\n      margin-right: 15px;\n      border: 0.5px solid #41474d;\n\n      &:focus {\n        outline: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"areaGroup": `app-containers-Groups-___Group-module__areaGroup`,
	"usersHead": `app-containers-Groups-___Group-module__usersHead`,
	"searchInput": `app-containers-Groups-___Group-module__searchInput`
};
export default ___CSS_LOADER_EXPORT___;
