/** @flow */
import { createRoot } from 'react-dom/client'

type RootType = {
  render(children: React$Node): void,
  unmount(): void,
  _internalRoot: any | null,
}

const rootNode = document.getElementById('render-app') || document.createElement('div')
const contextMenuNode = document.getElementById('render-context-menu') || document.createElement('div')

export const rootContainer: RootType = createRoot(rootNode)
export const rootContextMenu: RootType = createRoot(contextMenuNode)
