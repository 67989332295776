/** @flow */
import type {
  ProjectDepartmentJob,
  Imputation,
  ProjectDepartment,
  ProjectPlan,
  DateDay,
  Department,
} from 'app/core/types'

// eslint-disable-next-line no-use-before-define
type SubRow = Array<RowData<any, string>> | { [key: string]: RowData<any, string> }

export type RowData<T, R> = {
  __resource: R,
  __readOnly: boolean,
  subRows?: SubRow,
  includedResources?: Array<string>,
  name: string,
  startDate: DateDay,
  endDate: DateDay,
  ...T,
}

export function formatRowProject(
  projectPlan?: $Shape<RowData<ProjectPlan, 'projectPlans'>> = {},
  subRowsContainer: SubRow = [],
  readOnly: boolean,
): $Shape<RowData<ProjectPlan, 'projectPlans'>> {
  return {
    ...projectPlan,
    __resource: 'projectPlans',
    __readOnly: readOnly,
    name: projectPlan.name,
    subRows: projectPlan.subRows || subRowsContainer,
  }
}

export function formatRowProjectDepartment(
  projectDepartment?: $Shape<
    RowData<$Exact<{ ...$Exact<Department>, ...$Exact<ProjectDepartment> }>, 'projectDepartments'>,
  > = {},
  subRowsContainer: SubRow = [],
  readOnly: boolean,
): RowData<$Exact<{ ...$Exact<Department>, ...$Exact<ProjectDepartment> }>, 'projectDepartments'> {
  return {
    ...projectDepartment,
    __resource: 'projectDepartments',
    __readOnly: readOnly,
    subRows: projectDepartment.subRows || subRowsContainer,
  }
}

export function formatRowProjectDepartmentJobs(
  projectDepartmentJob?: $Shape<RowData<ProjectDepartmentJob, 'projectDepartmentJobs'>> = {},
  subRowsContainer: SubRow = [],
  readOnly: boolean,
): $Shape<RowData<ProjectDepartmentJob, 'projectDepartmentJobs'>> {
  return {
    ...projectDepartmentJob,
    __resource: 'projectDepartmentJobs',
    __readOnly: readOnly,
    subRows: projectDepartmentJob.subRows || subRowsContainer,
  }
}

export function formatRowImputation(
  imputation?: $Shape<RowData<Imputation, 'imputations'>> = {},
  readOnly: boolean,
): RowData<Imputation, 'imputations'> {
  return {
    ...imputation,
    __resource: 'imputations',
    __readOnly: readOnly,
    includedResources: ['contractInst', 'personInst', 'contractInst.personInst', 'contractInst.secureDataInst'],
  }
}
