import React from 'react'
import { confirm } from 'app/components/Modal'

export default class CheckUpdates extends React.Component {
  currentFileContent = null

  isOpen = false

  shouldComponentUpdate() {
    return false
  }

  componentDidMount() {
    this.timer = setInterval(this.checkUpdate, 1000 * 60 * 5)
    setTimeout(this.checkUpdate, 60)
  }

  checkUpdate = () => {
    fetch(window.location.origin)
      .then((res) => res.text())
      .then((fileContent) => {
        if (this.currentFileContent && this.currentFileContent !== fileContent) {
          this.showMessage()
        }

        this.currentFileContent = fileContent
      })
  }

  showMessage = () => {
    if (this.isOpen) return
    this.isOpen = true
    confirm({
      title: 'Updates !',
      titleColor: '',
      render: <div className="marginBottom20">A new release is available. Do you want to refresh your page ?</div>,
      validateLabel: 'Yes, refresh now',
      cancelLabel: 'No, refresh later',
      cancelLabelColor: '#E56D7A',
      onValidate: () => {
        window.location.reload()
        return Promise.resolve()
      },
      onCancel: () => {
        this.isOpen = false
        clearInterval(this.timer)
        this.timer = setInterval(this.showMessage, 1000 * 60 * 10)
        return Promise.resolve()
      },
    })
  }

  render() {
    return null
  }
}
