import { forEach, find } from 'lodash'
import resources from 'app/store/resources'

export const updateAssetFlags = (asset, flags) => {
  const toCreate = []
  const toDelete = []

  forEach(flags, (flag) => {
    const request = { asset: asset.id, flag: flag.flag, id: flag.id }
    if (!flag.id) toCreate.push(request)
  })

  forEach(asset.assetFlagsInst, (assetFlags) => {
    if (!find(flags, (flag) => flag.id === assetFlags.id)) toDelete.push(assetFlags.id)
  })

  return Promise.all([resources.assetFlags.create(toCreate), resources.assetFlags.delete(toDelete)])
}
