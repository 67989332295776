/** @flow */
import React from 'react'
import cx from 'classnames'
import { map } from 'lodash'
import type { Media, ID, ArticleMedia, Pipe } from 'app/core/types'
import resources from 'app/store/resources'
import { MUIButton } from 'app/components/Form'
import { ModalTrigger, confirm } from 'app/components/Modal'
import pipe from 'app/core/utils/pipe'
import { getResources } from 'app/store/selectors'
import { Permission } from 'app/containers/Permissions'
import { UploadFilesModal } from 'app/components/Medias/UploadFilesModal.jsx'
import { MediaItem } from 'app/components/Medias/MediaItem/MediaItem.tsx'
import classes from './MediasRelation.module.scss'

type Props = {|
  articleMedias: Array<ArticleMedia>,
  className?: string,
  enableEdit?: boolean,
  articleId: ID,
  closePoper?: Function,
  canDeleteMedia?: (media: Media) => boolean,
|}

export class MediasRelationView extends React.PureComponent<Props> {
  static defaultProps: $Shape<Props> = {
    enableEdit: true,
  }

  onAddedMedia: (Array<Media>) => void = (medias) => {
    medias.forEach((media) => {
      const { articleId } = this.props
      resources.articleMedias.create({
        article: articleId,
        media: media.id,
      })
    })
  }

  onWantDelete: (ArticleMedia) => void = (articleMedia) => {
    confirm({
      title: 'Deletion',
      render: (
        <div>
          Do you want to delete <b>{articleMedia.mediaInst.name}</b>
        </div>
      ),
      validateLabel: 'Yes',
      cancelLabel: 'No',
      cancelLabelColor: '#E56D7A',
      onValidate: () => this.deleteRelation(articleMedia),
    })
  }

  deleteRelation(articleMedia: ArticleMedia): Promise<any> {
    const { canDeleteMedia } = this.props

    return resources.articleMedias.delete(articleMedia.id).then((res) => {
      let canDelete = true
      if (canDeleteMedia) canDelete = canDeleteMedia(articleMedia.mediaInst)
      if (canDelete) return resources.medias.delete(articleMedia.mediaInst.id)
      return res
    })
  }

  renderItems(): React$Element<any> {
    const { articleMedias, enableEdit } = this.props

    if (!articleMedias.length) return <div className={classes.center}>No attachments</div>

    return (
      <div className={classes.items}>
        {articleMedias.map((articleMedia) => {
          return (
            <MediaItem
              key={articleMedia.id}
              medias={articleMedias.map((articleMedia) => articleMedia.mediaInst)}
              media={articleMedia.mediaInst}
              onDelete={enableEdit ? () => this.onWantDelete(articleMedia) : undefined}
              itemStyle={{ maxWidth: 100 }}
              allowPinMedia={false}
              allowValidateMedia={false}
            />
          )
        })}
      </div>
    )
  }

  render(): React$Node {
    const { className, closePoper = Function } = this.props

    return (
      <div className={cx([classes.container, className])} onClick={closePoper}>
        <div className={classes.list}>
          <div className={classes.overflow}>{this.renderItems()}</div>
          <div style={{ marginTop: 10 }}>
            <Permission actions={['wiki__articles_update', 'uploads___upload']}>
              <ModalTrigger
                modal={
                  <UploadFilesModal
                    title="Add attachments"
                    onValidate={this.onAddedMedia}
                    uploadOnValidate={true}
                    allowPinMedia={false}
                    allowValidateMedia={false}
                  />
                }
              >
                <MUIButton icon="fas-plus">Add attachments</MUIButton>
              </ModalTrigger>
            </Permission>
          </div>
        </div>
      </div>
    )
  }
}

const pipeInst: Pipe<{ articleId: ID }, typeof MediasRelationView> = pipe()

const MediasRelationComponent: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { articleId } = props
    return {
      articleMedias: map(getResources(state, 'articleMedias', { article: articleId }, ['mediaInst'])),
    }
  })
  .render(MediasRelationView)

export default MediasRelationComponent
