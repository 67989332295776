/** @flow */
import React from 'react'
import type { ID, ElementProps } from 'app/core/types'
import { NavLink as ReactRouterLink } from 'react-router-dom'
import cx from 'classnames'
import { permission } from 'app/containers/Permissions'

export type LinkProps = {|
  localRouter: Object,
  name: string,
  activeName?: string,
  params?: Object,
  permission?: Array<string>,
  projectId?: ID,
  operator?: 'and' | 'or',
  parent?: string,
  isSubItem?: boolean,
  className?: string,
  activeClassName?: string,
  children: any,
  subItems?: React$Node,
  ...$Exact<ElementProps>,
|}
export default function (props: LinkProps): React$Node {
  const {
    localRouter,
    activeClassName,
    className,
    name,
    activeName,
    params,
    permission: perms,
    projectId,
    operator,
    subItems,
    isSubItem,
    parent,
    ...rest
  } = props

  if (perms && !permission(perms, operator, false, projectId)) return null

  const absoluteHref = localRouter.getAbsoluteLink(activeName || name, params)

  if (parent) localRouter.setParent(name, parent)

  const _className = cx(
    className,
    activeClassName && {
      [activeClassName]: localRouter.routeIsActive(name, params),
    },
  )

  return (
    <div className={`relative ${isSubItem ? '' : 'flex1'}`}>
      <ReactRouterLink to={absoluteHref} disabled={!!subItems} className={_className} {...rest}>
        {props.children}
      </ReactRouterLink>
      {subItems}
    </div>
  )
}
