// @flow

import React from 'react'
import pipe from 'app/core/utils/pipe'
import { MUIButton } from 'app/components/Form'
import { getActionObjectTypeId } from 'app/core/utils/contentTypesUtils'
import { ModalTrigger } from 'app/components/Modal'
import type { User } from 'app/core/types'
import resources from 'app/store/resources'
import { getElementSubscription } from 'app/store/selectors/getElementSubscription'
import { getResources } from 'app/store/selectors/getResources'
import Subscription from './Subscription.jsx'
import { permission } from '../Permissions/index.js'
import classes from './SubscribeButton.module.scss'

type Props = {
  target: Object,
  targetType: string,
  isSubscribed: boolean,
  isReady: boolean,
  showLabel?: boolean,
  hideRegister?: boolean,
  tooltip?: string,
  component?: *,
  subscriptions: Array<Object>,
  user: User,
}

type State = {|
  loading: boolean,
|}

class SubscribeButton extends React.PureComponent<Props, State> {
  state = {
    loading: false,
  }

  isMounted = true

  componentWillUnmount() {
    this.isMounted = false
  }

  subscribe = () => {
    const { isSubscribed, subscriptions, user, target, targetType } = this.props

    this.setState({ loading: true })

    if (isSubscribed) {
      const subscription = subscriptions.find(
        ({ targetId, user: subUser }) => targetId === target.id && subUser === user.asset,
      )
      if (!subscription) return Promise.reject()

      return resources.subscriptions
        .delete(subscription.id)
        .then(() => {
          this.isMounted && this.setState({ loading: false })
        })
        .catch(() => {
          this.isMounted && this.setState({ loading: false })
        })
    }
    return resources.subscriptions
      .create({
        targetType: getActionObjectTypeId(targetType),
        targetId: target.id,
        user: user.asset,
        actorOnly: false,
      })
      .then(() => {
        this.isMounted && this.setState({ loading: false })
      })
      .catch(() => {
        this.isMounted && this.setState({ loading: false })
      })
  }

  render(): React$Node {
    const {
      target,
      targetType,
      isReady,
      isSubscribed,
      showLabel,
      tooltip,
      component,
      user,
      subscriptions,
      hideRegister,
      ...rest
    } = this.props
    const { loading } = this.state

    if (!permission(['notifications___read'])) return null

    const Button = component || MUIButton

    return (
      <div className={`${classes.buttonsContainer} flex row noWrap`} style={{ marginLeft: 10 }}>
        {/* $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating
         * flow */}
        <Button
          icon="fas-rss"
          loader={!isReady || loading}
          onClick={this.subscribe}
          style={{ borderRadius: 0, maxHeight: 30, color: isSubscribed ? '#ffffff' : 'grey' }}
          {...rest}
          bgColor={isSubscribed ? '#48C27E' : undefined}
          tooltip={tooltip || 'Subscribe to this content'}
        >
          {showLabel && `Subscribe${isSubscribed ? 'd' : ''}`}
        </Button>
        {hideRegister ? null : (
          <ModalTrigger modal={<Subscription actionObject={target} actionTypeId={getActionObjectTypeId(targetType)} />}>
            {/* $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when
             * updating flow */}
            <Button
              disabled={!isReady}
              {...rest}
              style={{
                borderRadius: 0,
                maxHeight: 30,
                color: 'grey',
                borderLeft: `2px solid ${isSubscribed ? 'transparent' : '#ffffff'}`,
              }}
              tooltip="Invit other users to subscribe to this content."
            >
              Register
            </Button>
          </ModalTrigger>
        )}
      </div>
    )
  }
}

const Component: React$ComponentType<any> = pipe()
  .request((props) => {
    return resources.subscriptions.checkSubscription([props.target.id])
  })
  .mapRequestToProps(() => ({ isReady: true }))
  .enableDeepCompare()
  .connect((state, props) => {
    return {
      user: state.user,
      isSubscribed: !!getElementSubscription(state, props.target.id),
      subscriptions: Object.values(getResources(state, 'subscriptions')),
    }
  })
  .renderLoader((props) => <SubscribeButton {...props} />)
  .render(({ dispatch, ...rest }) => <SubscribeButton {...rest} />)

export default Component
