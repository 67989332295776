// @flow
import React from 'react'
import { Nav } from 'app/components/LeftNav'
import { NavTitle } from 'app/components/LeftNav/NavTitle.jsx'
import type { Match } from 'react-router-dom'

type Props = {|
  match: Match,
|}

export default function NavNotifications(props: Props): React$Node {
  const { match } = props

  return (
    <Nav>
      <NavTitle label="All Notifications" icon="fas-bell" to={`${match.url}/all`} />
      <NavTitle
        permissions={['notifications___read']}
        label="Manage subscriptions"
        to={`${match.url}/manage`}
        icon="fas-cog"
      />
    </Nav>
  )
}
