/** @flow */
import React from 'react'
import { calculateSmartDate } from 'app/components/Form/SmartDate/SmartDate.jsx'

import type { Column } from '../../types'
import { getDate } from '../../utils.js'

export const groupingFns: $ElementType<Column, 'groupingFns'> = {
  value: {
    fn: (rows, columnId, instance) => {
      return rows.reduce((prev, row, i) => {
        const date = calculateSmartDate(row.values[columnId])?.date
        const resKey = String(date || undefined)
        prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
        prev[resKey].push(row)
        return prev
      }, {})
    },
    label: 'Value',
    headerRow: (cell) => {
      if (!cell.value) return null
      return <div className="flex alignCenter fullHeight">{calculateSmartDate(cell.value)?.date || null}</div>
    },
  },
  date: {
    fn: (rows, columnId, instance) => {
      return rows
        .sort((prev, row) => {
          const a = calculateSmartDate(prev.values[columnId])?.date || ''
          const b = calculateSmartDate(row.values[columnId])?.date || ''
          if (a < b) return -1
          if (a > b) return 1
          return 0
        })
        .reduce((prev, row, i) => {
          const smartDate = calculateSmartDate(row.values[columnId])
          if (!smartDate) return prev
          const { date } = smartDate
          const resKey = String(date ? getDate(date) : undefined)
          prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
          prev[resKey].push(row)
          return prev
        }, {})
    },
    label: `Age`,
    headerRow: (cell) => {
      const date = calculateSmartDate(cell.value)?.date
      return cell.value ? <div className="bold">{date ? getDate(date) : null}</div> : null
    },
  },
}
