// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ril-outer {
    background-color: transparent;
  }

.app-components-PictureVisualization-___PictureVisualization-module__btn {
  font-size: 18px;
  margin: 5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./app/components/PictureVisualization/PictureVisualization.module.scss"],"names":[],"mappings":"AACE;IACE,6BAA6B;EAC/B;;AAGF;EACE,eAAe;EACf,WAAW;EACX,eAAe;AACjB","sourcesContent":[":global {\n  .ril-outer {\n    background-color: transparent;\n  }\n}\n\n.btn {\n  font-size: 18px;\n  margin: 5px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `app-components-PictureVisualization-___PictureVisualization-module__btn`
};
export default ___CSS_LOADER_EXPORT___;
