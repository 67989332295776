// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Admin-ProjectsList-___projectView-module__container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  border-radius: 3px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.app-pages-Admin-ProjectsList-___projectView-module__container:hover {
  background-color: rgba(0, 0, 0, 0.03);
  transition: 300ms;
}

.app-pages-Admin-ProjectsList-___projectView-module__imageContainer {
  height: 50px;
  width: 80px;
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./app/pages/Admin/ProjectsList/projectView.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,qCAAqC;EACrC,eAAe;AACjB;;AAEA;EACE,qCAAqC;EACrC,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".container {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  margin-bottom: 10px;\n  border-radius: 3px;\n  padding: 10px;\n  background-color: rgba(0, 0, 0, 0.02);\n  cursor: pointer;\n}\n\n.container:hover {\n  background-color: rgba(0, 0, 0, 0.03);\n  transition: 300ms;\n}\n\n.imageContainer {\n  height: 50px;\n  width: 80px;\n  border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-Admin-ProjectsList-___projectView-module__container`,
	"imageContainer": `app-pages-Admin-ProjectsList-___projectView-module__imageContainer`
};
export default ___CSS_LOADER_EXPORT___;
