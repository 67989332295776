/** @flow */
import React, { useRef } from 'react'
import map from 'lodash/map'
import type { FlagRelation } from 'app/core/types'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData, MUIButton } from 'app/components/Form'
import vars from 'app/styles/vars.js'
import { FlagsRelations, type Props as FlagRelationProps } from './FlagsRelations.jsx'

const { colors } = vars

type Props = {|
  flagsRelations: Array<FlagRelation>,
  foreignKey: $ElementType<FlagRelationProps, 'foreignKey'>,
  resourceID: $ElementType<FlagRelationProps, 'resourceID'>,
  category: $ElementType<FlagRelationProps, 'category'>,
  itemCategory: $ElementType<FlagRelationProps, 'itemCategory'>,
  onSave: (data: Object, type?: 'merge' | 'past') => Promise<any>,
  autoFocus?: boolean,
  multiple?: boolean,
  ...$Shape<$Diff<ModalProps, { children: any }>>,
|}

export function ModalFlagsRelations(props: Props): React$Node {
  const { flagsRelations, onSave, category, foreignKey, resourceID, itemCategory, autoFocus, multiple, ...rest } = props
  const formDataRef = useRef()

  return (
    <ModalConfirmForm
      draggable={true}
      title="Flags"
      minWidth={600}
      validateLabel={multiple ? 'Replace' : 'Save'}
      extendsButtons={
        multiple
          ? ({ onRequestClose }) => [
              <MUIButton
                key="merge"
                bgColor={colors.blue}
                onClick={() => {
                  return onSave(formDataRef.current?.state?.data || {}, 'merge').then(onRequestClose)
                }}
              >
                Merge
              </MUIButton>,
            ]
          : undefined
      }
      {...rest}
    >
      <FormData
        ref={formDataRef}
        defaultData={{
          flagsRelations: !multiple ? map(flagsRelations) : [],
        }}
        properties={[
          {
            key: 'flagsRelations',
            label: 'Flags',
            type: 'autocomplete',
            element: FlagsRelations,
            elementProps: {
              foreignKey,
              resourceID,
              category,
              itemCategory,
              autoFocus,
            },
          },
        ]}
        onSave={(data) => onSave(data, multiple ? 'past' : undefined)}
      />
    </ModalConfirmForm>
  )
}
