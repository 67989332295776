/** @flow */
import React from 'react'
import { Timecode } from 'app/components/Timecode/Timecode.jsx'
import type { DefaultCellProps, Column } from '../../types'

import { groupingFns } from './groupingFns.jsx'

type CellTimecodeParams = {|
  ...$Exact<DefaultCellProps>,
  framerate?: number,
|}

export const CellTimecode = ({ framerate, ...data }: CellTimecodeParams): Column => ({
  cellType: 'CellTimecode',
  Cell: (instance) => {
    const { value, cell } = instance
    const { getCellProps } = cell
    const { edition } = getCellProps()
    const { isBeingEdit, endEdit, save } = edition

    if (!framerate) return null

    if (isBeingEdit) {
      return (
        <Timecode
          value={value}
          framerate={framerate}
          onChange={(_value) => {
            if (value !== _value) save(_value)
            else endEdit()
          }}
          onBlur={(_value) => {
            if (!_value && !value) endEdit()
            if (value !== _value) save(_value)
            else endEdit()
          }}
          onCancel={() => {
            endEdit()
            setTimeout(() => cell.focusCell(), 0)
          }}
          autoFocus={true}
          className="flex center alignCenter fullWidth fullHeight"
        />
      )
    }

    return (
      <Timecode
        value={value}
        framerate={framerate}
        readOnly={true}
        className="flex center alignCenter fullWidth fullHeight"
      />
    )
  },
  width: 200,
  groupingFns,
  ...data,
})
