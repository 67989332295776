// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Notifications-___NotificationsRightPanel-module__container {
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.app-containers-Notifications-___NotificationsRightPanel-module__title {
  display: flex;
  align-items: center;
}

.app-containers-Notifications-___NotificationsRightPanel-module__titleString {
  font-size: 24px;
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.app-containers-Notifications-___NotificationsRightPanel-module__notifications {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Notifications/NotificationsRightPanel.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,OAAO;EACP,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  max-height: 100%;\n  height: 100%;\n  width: 100%;\n}\n\n.title {\n  display: flex;\n  align-items: center;\n}\n\n.titleString {\n  font-size: 24px;\n  flex: 1;\n  margin-left: 10px;\n  margin-right: 10px;\n}\n\n.notifications {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-containers-Notifications-___NotificationsRightPanel-module__container`,
	"title": `app-containers-Notifications-___NotificationsRightPanel-module__title`,
	"titleString": `app-containers-Notifications-___NotificationsRightPanel-module__titleString`,
	"notifications": `app-containers-Notifications-___NotificationsRightPanel-module__notifications`
};
export default ___CSS_LOADER_EXPORT___;
