// @flow

import devWarning from 'app/libs/devWarning'

export type Config = { [key: string]: any }

let config: Config

export const getServerConfig = (): Config => config

export const setServerConfig = (incomingConfig: Object) => {
  config = incomingConfig
}

export default (): Config => {
  devWarning(() =>
    console.warn(
      `import default getServerConfig deprecated. use import { getServerConfig } from 'app/core/utils/getServerConfig'`,
    ),
  )
  return getServerConfig()
}
