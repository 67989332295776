/** @flow */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { List } from 'app/components/Table/virtualization/ReactVirtualized/index.js'
import type { ID } from 'app/core/types'

import { userPrefLocalResource } from 'app/core/utils/localUserPrefs.js'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL.js'
import reverseFlattenTree from '../../libs/helpers/reverseFlattenTree.js'
import { TreeRow, type Node, type TreeRowProps } from './TreeRow.jsx'

export const ASYNC_TYPE_HANDLER = 8909

export type VirtualizedTreeProps = {|
  ...$Rest<
    TreeRowProps,
    {
      node?: Node,
      style?: Object,
    },
  >,
  items: Array<$ElementType<Node, 'data'>>,
  rootItemId?: ?ID,
  currentItemId?: ?ID,
  style?: Object,
  className?: string,
  async?: (item: $ElementType<Node, 'data'>) => Promise<{ plainResponse: Array<$ElementType<Node, 'data'>> }>,
  onClickItem?: (item: $ElementType<Node, 'data'>) => void,
  onOpenChild?: (item: $ElementType<Node, 'data'>) => void,
  expandAll?: boolean,
  expandedLevel?: number,
  resizable?: boolean,
|}

export function VirtualizedTree(props: VirtualizedTreeProps): React$Node {
  const {
    items,
    rootItemId,
    currentItemId: _currentItemId,
    onClickItem,
    async,
    onOpenChild,
    expandAll,
    expandedLevel,
    resizable,
    style,
    className,
  } = props

  const [currentItemId, setCurrentItemId] = useState<ID | void | null>(_currentItemId)
  const [paths, setPaths] = useState<Array<ID>>([])
  const [nodes, setNodes] = useState<Array<Node>>([])
  const [expanded, setExpanded] = useState<{ [rowId: ID]: Array<ID> | null } | void>()
  const [needFocus, setNeedFocus] = useState<boolean>(true)
  const [nodesState, setNodesState] = useState<{ [rowId: ID]: { [key: string]: any } | null }>({})
  const [deepestDepth, setDeepestDepth] = useState<number>(0)

  const canceDefaultPaths = useRef(false)
  const listRef = useRef()
  const listHeight = useRef(0)

  const projectId = useMemo(() => getProjectIdFromURL(), [])

  function getOpenedPaths() {
    if (!currentItemId) return []

    function recursive(itemId?: ID, currentPath?: Array<ID> = []) {
      const item = items?.find(({ id }) => id === itemId)
      if (!item) return currentPath
      const _currentPath = [item.id, ...currentPath]
      const parent = items?.find(({ id }) => id === item.parentId)
      if (!parent) return _currentPath
      return recursive(parent?.id, _currentPath)
    }

    return recursive(currentItemId)
  }

  function sortNodesByName(a: Node, b: Node) {
    if (!a?.data?.metadata?.name || !b?.data?.metadata?.name) return 0
    return (
      a.data.metadata.name.localeCompare(b.data.metadata.name || '', undefined, {
        numeric: true,
        sensitivity: 'base',
      }) || 0
    )
  }

  function sortNodesByAssetType(a: Node, b: Node) {
    if (!a?.data?.metadata?.assetType || !b?.data?.metadata?.assetType) return 2
    const { assetType: prevAssetType } = a.data.metadata
    const { assetType } = b.data.metadata

    const values = {
      pj: 0,
      fo: 1,
      ep: 2,
      of: 3,
      lc: 4,
      pc: 5,
      gp: 6,
      us: 7,
      ph: 8,
      mi: 9,
      sh: 10,
      mo: 11,
      sq: 12,
    }
    return values[prevAssetType] - values[assetType]
  }

  function getDefaultExpanded(nodes: Array<Node>) {
    const toExpand = {}

    const openedPaths = getOpenedPaths()

    const getOpennedPathsRecursively = (_nodes) => {
      openedPaths.forEach((path, index) => {
        const item = _nodes?.find((item) => item.id === path)

        if (item && item.children) {
          if (openedPaths[openedPaths.length - 1] === item.id) return
          toExpand[item.id] = item.children.map((i) => i.id)
          getOpennedPathsRecursively(item.children)
        }
      })
    }

    const getExpandedLevelRecursively = (_nodes, currentLevel, maxLevel) => {
      if (currentLevel === maxLevel) return
      _nodes.forEach((_node) => {
        if (_node.children) {
          getExpandedLevelRecursively(_node.children, currentLevel + 1, maxLevel)
          toExpand[_node.id] = _node.children?.map((n) => n.id) || null
        }
      })
    }

    getOpennedPathsRecursively(nodes)
    if (expandedLevel) getExpandedLevelRecursively(nodes, 0, expandedLevel)
    return toExpand
  }

  function getNodesRecursively(nodes: Array<Node>, depth: number, _setDeepestDepth: function) {
    _setDeepestDepth?.(depth)

    const sortedNodes = nodes.sort(sortNodesByName).sort(sortNodesByAssetType)

    return sortedNodes.reduce((acc, node) => {
      const isInPath = paths.includes(node.id)
      const isExpanded = !!expanded?.[node.id] || expandAll === true
      const isLastInPath = paths[paths.length - 1] === node.id

      const newNode: Node = {
        ...node,
        depth,
        isExpanded,
        selected: isInPath,
        asyncRequested: Boolean(nodesState[node.id]?.asyncRequested) || (isInPath && !isLastInPath),
        expand: () => {
          if (node.children) {
            onOpenChild?.(node.data)
            setExpanded((expanded) => {
              const resolvedExpanded = expanded ? { ...expanded } : {}
              resolvedExpanded[node.id] = node.children?.map((c) => c.id) || null
              return resolvedExpanded
            })
          }
        },
        unexpand: () => {
          if (node.children) {
            setExpanded((expanded) => {
              const resolvedExpanded = expanded ? { ...expanded } : {}
              resolvedExpanded[node.id] = null
              return resolvedExpanded
            })
          }
        },
        onClick: () => {
          canceDefaultPaths.current = true
          onClickItem?.(node.data)
        },
        getAsyncChildren: () => {
          return (async?.(node.data) || Promise.resolve()).then((res) => {
            setNodesState({ ...nodesState, [node.id]: { ...nodesState[node.id], asyncRequested: true } })

            setTimeout(() => {
              setExpanded((expanded) => {
                const resolvedExpanded = expanded ? { ...expanded } : {}
                resolvedExpanded[node.id] = node.children?.map((c) => c.id) || null
                return resolvedExpanded
              })
            }, 0)

            return res
          })
        },
      }

      if (isExpanded && node.children) {
        acc.push(newNode, ...getNodesRecursively(node.children, depth + 1, _setDeepestDepth))
      } else acc.push(newNode)
      return acc
    }, [])
  }

  useEffect(() => {
    if (currentItemId && !!projectId) userPrefLocalResource.setData('currentTreeEntry', currentItemId, true)
  }, [currentItemId])

  useEffect(() => {
    if (canceDefaultPaths.current) {
      canceDefaultPaths.current = false
    } else {
      const _nodes = reverseFlattenTree(items, 'id', 'parentId', 'children', rootItemId)
      const defaultExpanded = getDefaultExpanded(_nodes)
      setExpanded(defaultExpanded)
      setNeedFocus(true)
    }
  }, [currentItemId, expandedLevel, rootItemId])

  useEffect(() => {
    if (!_currentItemId && !!projectId) {
      const sessionCurrentItemId = userPrefLocalResource.getData('currentTreeEntry', undefined, true)

      if (sessionCurrentItemId) {
        const selectedItem = items?.find((i) => i.id === sessionCurrentItemId)

        const projectId = getProjectIdFromURL()

        if (selectedItem && selectedItem.metadata?.project === projectId) {
          setCurrentItemId(sessionCurrentItemId)
          onClickItem?.(selectedItem)
        }
      }
    } else {
      setCurrentItemId(_currentItemId)
    }
  }, [_currentItemId])

  useEffect(() => {
    const _nodes = reverseFlattenTree(items, 'id', 'parentId', 'children', rootItemId)
    setNodes(_nodes)
  }, [items, rootItemId, expandedLevel, expanded])

  // get opened paths
  useEffect(() => {
    const _openedPaths = getOpenedPaths()
    setPaths(_openedPaths)
  }, [currentItemId])

  const flattenedNodes = useMemo(() => {
    let _deepestDepth = 0
    const _nodes = getNodesRecursively(nodes, 0, (val) => {
      if (val > _deepestDepth) _deepestDepth = val
    })
    setDeepestDepth(_deepestDepth)
    return _nodes
  }, [expanded, paths, nodes, expandAll])

  useEffect(() => {
    if (needFocus && paths.length > 0 && flattenedNodes.length > 0) {
      setNeedFocus(false)
      const lastId = paths[paths.length - 1]
      const nodeIndex = flattenedNodes.findIndex((el) => el.id === lastId)

      // $FlowFixMe
      if (nodeIndex > -1 && typeof listRef.current?.scrollToRow === 'function') {
        const middleHeight = listHeight.current / 22 / 2
        const toRow = Math.min(Math.round(nodeIndex + middleHeight), flattenedNodes.length - 1)
        listRef.current?.scrollToRow(toRow)
      }
    }
  }, [flattenedNodes, paths, needFocus])

  const containerStyle = useMemo(() => {
    if (resizable) return { width: '100%', overflow: 'hidden', ...style }
    return {
      minWidth: 350 + deepestDepth * 30,
    }
  }, [deepestDepth, resizable, style])

  return (
    <div className={`fullHeight ${className || ''}`} style={containerStyle}>
      <AutoSizer>
        {({ height, width }) => {
          listHeight.current = height
          return (
            <List
              ref={(ref) => {
                listRef.current = ref
              }}
              height={height}
              width={width}
              rowCount={flattenedNodes.length}
              rowHeight={22}
              rowRenderer={({ index, style, key }) => <TreeRow style={style} node={flattenedNodes[index]} key={key} />}
            />
          )
        }}
      </AutoSizer>
    </div>
  )
}
