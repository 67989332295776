// @flow

import type { StoreResourceDef } from 'app/core/types'

export const assetMediaGroups: StoreResourceDef<> = {
  resourceType: 'assetMediaGroups',
  relations: {
    mediaGroupInst: {
      type: 'hasOne',
      resourceType: 'mediaGroups',
      foreignKey: 'mediaGroup',
    },
  },
}
