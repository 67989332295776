// @flow
import api from 'app/core/api/api'
import { find } from 'lodash'
import type { StoreResourceDef } from 'app/core/types'
import type { AssetsActions } from 'app/core/types/StoreResourcesCustomActions'
import store from 'app/store/index.ts'
import {
  postCountIsUpToDate,
  postChildCountIsUpToDate,
  articleCountIsUpToDate,
  articleChildCountIsUpToDate,
} from 'app/core/constants/assetCountMeta'
import { autocomplete } from './autocomplete.js'
import { transformResults } from './utils/transformResults'

const getLocalStorageKeyNotif = (userId) => {
  return `${userId}__assetNotif`
}

const handleAssetNotif = (assets) => {
  const userId = store.getState().user.asset
  const localStorageKey = getLocalStorageKeyNotif(userId)
  const localItemsStr = localStorage.getItem(localStorageKey)
  const localItems = localItemsStr && JSON.parse(localItemsStr)

  return assets.map((asset) => {
    const localItem = localItems && localItems.find(({ id }) => id === asset.id)
    return {
      ...asset,
      [postCountIsUpToDate]: localItem ? localItem.postCount === asset.postCount : !asset.postCount,
      [postChildCountIsUpToDate]: localItem ? localItem.postChildCount === asset.postChildCount : !asset.postChildCount,
      [articleCountIsUpToDate]: localItem ? localItem.articleCount === asset.articleCount : !asset.articleCount,
      [articleChildCountIsUpToDate]: localItem
        ? localItem.articleChildCount === asset.articleChildCount
        : !asset.articleChildCount,
    }
  })
}

export const assets: StoreResourceDef<AssetsActions<>> = {
  resourceType: 'assets',
  actions: (getResources) => ({
    deleteAsset: (asset, config = {}) => {
      const { params = {}, ...restConfig } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.assets
            .delete(asset.id, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(() => {
              return transformResults(restConfig)({ ...asset, internalStatus: 2 })
            }),
      }
    },
    undeleteAsset: (asset, config = {}) => {
      const { params = {}, ...restConfig } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.assets
            .undelete(
              { id: asset.id },
              params.queries,
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(() => {
              return transformResults(restConfig)({ ...asset, internalStatus: 0 })
            }),
      }
    },
    fetchEpisodes: (projectId, config = {}) => {
      const { params = {}, ...restConfig } = config || {}

      if (!projectId) {
        return {
          type: 'read',
          requestKey: config?.requestKey,
          request: () => Promise.resolve({}),
        }
      }

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.projects
            .episodes(
              { id: projectId },
              { ordering: 'name', ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(restConfig)),
      }
    },
    fetchUserProjects: (userId, config = {}) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.users
            .projects({ id: userId }, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(transformResults({ ...config })),
      }
    },
    fetchAllProjects: (config = {}) => {
      const { params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.projects
            .fetchAll(params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(transformResults(restConfig)),
      }
    },
    fetchOffices: (config = {}) => {
      const { params = {}, ...restConfig } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.assets
            .fetchAll(
              { assetType: 'of', ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(restConfig)),
      }
    },
    fecthGroupsByUser: (userId, config = {}) => {
      const { params = { headers: { 'x-ovm-permission-project': '' } } } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.groupUsers
            .fetchAll({ user: userId }, params.headers, params.getHttpProgress, params.requestController)
            .then((res) => {
              const queries = {
                uuid__in: res.results.map((gu) => gu.group).toString(),
                page_size: 1000,
                ...params.queries,
              }
              return api.groups.fetchAll(queries, params.headers, params.getHttpProgress, params.requestController)
            }),
      }
    },
    fetchGroupsByProject: (projectId, filter, config = {}) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.projects
            .groups(
              { id: projectId },
              { page_size: 1000, ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(config)),
      }
    },
    fetchShots: (episodeId, config = {}) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets
            .fetchAll(
              { assetType: 'sh', parent: episodeId, page_size: 1000, ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(config))
        },
      }
    },
    fetchWithFullPath: (assetId, rootAssetId, config = {}) => {
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          let fullPathPromise = Promise.resolve([])

          if (assetId) {
            fullPathPromise = api.assets.fullPath({ id: assetId }).then(({ results }) => {
              const path = results.map(({ id }) => id)
              if (rootAssetId) {
                const index = path.findIndex((id) => id === rootAssetId)
                const partialPath = path.slice(index)
                return partialPath
              }

              return path
            })
          }

          return fullPathPromise
            .then((assetsIds) => {
              return Promise.all(
                assetsIds.map((id, index) => {
                  return api.assets.children({ id }).then(({ results }) => results)
                }),
              )
            })
            .then((lists) => lists.reduce((a, b) => [...a, ...b], []))
            .then(handleAssetNotif)
            .then(transformResults(config))
        },
      }
    },
    fetchWithShortPath: (assetId, rootAssetId, config = {}) => {
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          let fullPathPromise = Promise.resolve([])

          if (assetId) {
            fullPathPromise = api.assets.shortPath({ id: assetId }).then(transformResults(config))
          }

          return fullPathPromise
        },
      }
    },
    fetchRoot: (config = {}) => {
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets
            .rootFolders()
            .then((data) => {
              if (!data) return {}
              const { results } = data
              if (!results.length) console.error('There is no root asset')

              const root = find(results, (result) => result.name === 'root')
              if (!root) console.error('There is no root folder')

              return root
            })
            .then((rootAsset) => {
              if (!rootAsset.id) throw Error('The OVM server seems to be down.')
              return api.assets.children({ id: rootAsset.id }).then(({ results }) => [...results, rootAsset])
            })
            .then(handleAssetNotif)
            .then(transformResults(config))
        },
      }
    },
    fetchChilds: (assetId, config = {}) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets
            .children({ id: assetId }, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then((res) =>
              res?.results
                ? res.results.map((asset) => ({
                    ...asset,
                    parent: assetId,
                  }))
                : [],
            )
            .then(handleAssetNotif)
            .then(transformResults(config))
        },
      }
    },
    fetchChildrenWithPostBoardLinks: (episodeId, config = {}) => {
      const { params = {} } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets
            .childrenWithPostBoardLinks(
              { id: episodeId },
              { ordering: 'timestamp', ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then((res) => {
              return {
                ...res,
                results:
                  res?.results.map((asset) => ({
                    ...asset,
                    assetMediaGroupsInst: asset.mediaGroups.map((id) => ({
                      id: `${id}${asset.id}`,
                      mediaGroup: id,
                      asset: asset.id,
                    })),
                  })) || [],
              }
            })
            .then(transformResults(config))
        },
      }
    },
    fetchDescendantWithTrackingDatas: (data, config) => {
      const { id, trackingSchemaId, columns } = data
      const { params = {} } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets
            .descendantWithTrackingDatas(
              { id, trackingSchemaId },
              {
                ordering: 'name',
                columns: columns && columns.length ? JSON.stringify(columns) : undefined,
                ...params.queries,
              },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(config))
        },
      }
    },
    fetchEpisodeUsage: (episodeId, config) => {
      const { params = {} } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets
            .episodeUsage(
              { id: episodeId },
              params.queries,
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(config))
        },
      }
    },
    fetchShotUsage: (data, config) => {
      const { assetId, episodeId } = data
      const { params = {} } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets
            .shotUsage(
              { id: assetId, episodeId },
              params.queries,
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(config))
        },
      }
    },
    childrenWithLinks: (data, config = {}) => {
      const { id } = data
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () => {
          return api.assets
            .childrenWithLinks({ id }, params.queries, params.headers, params.getHttpProgress, params.requestController)
            .then(transformResults(config))
        },
      }
    },
    search: autocomplete('asset'),
    usersWithActivities: (data, config = {}) => {
      const { params = {}, ...restConfig } = config || {}
      const { startDate, endDate, id } = data
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.groups
            .usersWithActivities(
              { id },
              { startDate, endDate, ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(restConfig)),
      }
    },
    allUsersWithActivities: (data, config = {}) => {
      const { params = {}, ...restConfig } = config || {}
      const { startDate, endDate } = data

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.groups
            .allUsersWithActivities(
              null,
              { startDate, endDate, ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(restConfig)),
      }
    },
    setTreeCountAsRead: (assetId, key) => {
      if (!['postCount', 'postChildCount', 'articleCount', 'articleChildCount'].includes(key)) {
        throw new Error(
          `setTreeCountAsRead(assetId, key). Key must be 'postCount', 'postChildCount', 'articleCount' or 'articleChildCount'`,
        )
      }

      const asset = store.getState().assets.resources[assetId]
      const userId = store.getState().user.asset
      const localStorageKey = getLocalStorageKeyNotif(userId)
      const localItemsStr = localStorage.getItem(localStorageKey)
      const localItems = (localItemsStr && JSON.parse(localItemsStr)) || []
      const defaultAsset = {
        id: asset.id,
        postCount: 0,
        postChildCount: 0,
        articleCount: 0,
        articleChildCount: 0,
      }

      const localItemsAsset = localItems.find(({ id }) => id === assetId)
      if (localItemsAsset) {
        localItemsAsset[key] = asset[key]
      } else {
        localItems.push({
          ...defaultAsset,
          [key]: asset[key],
        })
      }

      localStorage.setItem(localStorageKey, JSON.stringify(localItems))

      const nextAsset = {
        ...asset,
        [postCountIsUpToDate]: key === 'postCount' ? true : asset[postCountIsUpToDate],
        [postChildCountIsUpToDate]: key === 'postChildCount' ? true : asset[postChildCountIsUpToDate],
        [articleChildCountIsUpToDate]: key === 'articleChildCount' ? true : asset[articleChildCountIsUpToDate],
        [articleCountIsUpToDate]: key === 'articleCount' ? true : asset[articleCountIsUpToDate],
      }

      return {
        type: 'update',
        requestKey: null,
        request: {
          resources: {
            assets: [nextAsset],
          },
        },
      }
    },
  }),

  relations: {
    assetFlagsInst: {
      type: 'hasMany',
      resourceType: 'assetFlags',
      foreignKey: 'asset',
      serverKey: 'rel_assetFlagsInst',
    },
    flagsInst: {
      // hydrate only. Linked to assetFlagsInst
      type: 'hasMany',
      resourceType: 'flags',
    },
    activitieApprovalsInst: {
      type: 'hasMany',
      resourceType: 'activityApprovals',
      foreignKey: 'user',
    },
    assetLinksInst: {
      type: 'hasMany',
      resourceType: 'assetLinks',
      foreignKey: 'from_asset',
      serverKey: 'rel_assetLinksInst',
    },
    linksInst: {
      // hydrate only. Linked to assetLinksInst
      type: 'hasMany',
      resourceType: 'assets',
    },
    assetMediaGroupsInst: {
      type: 'hasMany',
      resourceType: 'assetMediaGroups',
      foreignKey: 'asset',
      serverKey: 'rel_assetMediaGroupsInst',
    },
    mediaGroupsInst: {
      // hydrate only. Linked to assetMediaGroupsInst
      type: 'hasMany',
      resourceType: 'mediaGroups',
    },
    postBoardLinksInst: {
      type: 'hasMany',
      resourceType: 'postBoardLinks',
      foreignKey: 'asset',
    },
    activitiesInst: {
      type: 'hasMany',
      resourceType: 'activities',
      foreignKey: 'user',
    },
    thumbnailInst: {
      type: 'hasOne',
      resourceType: 'medias',
      foreignKey: 'thumbnail',
    },
    parentInst: {
      type: 'hasOne',
      resourceType: 'assets',
      foreignKey: 'parent',
    },
    tasksInst: {
      type: 'hasMany',
      resourceType: 'tasks',
      foreignKey: 'asset',
    },
    dynamicApprovalValuesInst: {
      type: 'hasMany',
      resourceType: 'dynamicApprovalValues',
      foreignKey: 'asset',
    },
  },
}
