// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .container {} */

.app-containers-Task-___Task-module__item {
  display: flex;
  margin: 7px 0;
}

.app-containers-Task-___Task-module__itemValue {
  color: var(--colors-mainColor);
}

.app-containers-Task-___Task-module__topInfos {
  display: flex;
  margin-bottom: 20px;
}

.app-containers-Task-___Task-module__topInfos > div {
    flex: 1;
  }

.app-containers-Task-___Task-module__user,
.app-containers-Task-___Task-module__status {
  border-radius: 3px;
  padding: 1px 10px;
  color: #ffffff;
  background-color: #555555;
  margin: 0 5px;
  white-space: nowrap;
}

.app-containers-Task-___Task-module__thumbnail {
  height: 25px;
  width: 32px;
  border-radius: 3px;
  margin: 5px;
}

.app-containers-Task-___Task-module__assetLink {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.app-containers-Task-___Task-module__assetLink:hover {
  color: var(--colors-mainColor);
}

.app-containers-Task-___Task-module__removedLabel {
  padding: 10px 15px;
  background-color: var(--colors-red);
  color: #ffffff;
  font-weight: bold;
  font-size: 30px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Task/Task.module.scss"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AAKrB;;AAHE;IACE,OAAO;EACT;;AAGF;;EAEE,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["/* .container {} */\n\n.item {\n  display: flex;\n  margin: 7px 0;\n}\n\n.itemValue {\n  color: var(--colors-mainColor);\n}\n\n.topInfos {\n  display: flex;\n  margin-bottom: 20px;\n\n  > div {\n    flex: 1;\n  }\n}\n\n.user,\n.status {\n  border-radius: 3px;\n  padding: 1px 10px;\n  color: #ffffff;\n  background-color: #555555;\n  margin: 0 5px;\n  white-space: nowrap;\n}\n\n.thumbnail {\n  height: 25px;\n  width: 32px;\n  border-radius: 3px;\n  margin: 5px;\n}\n\n.assetLink {\n  font-size: 18px;\n  font-weight: bold;\n  cursor: pointer;\n  text-decoration: underline;\n}\n\n.assetLink:hover {\n  color: var(--colors-mainColor);\n}\n\n.removedLabel {\n  padding: 10px 15px;\n  background-color: var(--colors-red);\n  color: #ffffff;\n  font-weight: bold;\n  font-size: 30px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `app-containers-Task-___Task-module__item`,
	"itemValue": `app-containers-Task-___Task-module__itemValue`,
	"topInfos": `app-containers-Task-___Task-module__topInfos`,
	"user": `app-containers-Task-___Task-module__user`,
	"status": `app-containers-Task-___Task-module__status`,
	"thumbnail": `app-containers-Task-___Task-module__thumbnail`,
	"assetLink": `app-containers-Task-___Task-module__assetLink`,
	"removedLabel": `app-containers-Task-___Task-module__removedLabel`
};
export default ___CSS_LOADER_EXPORT___;
