// @flow
import type { StoreResourceDef } from 'app/core/types'
import type { GroupPermissionsActions } from 'app/core/types/StoreResourcesCustomActions'
import api from 'app/core/api/api'
import { transformResults } from './utils/transformResults'

export const groupPermissions: StoreResourceDef<GroupPermissionsActions<>> = {
  resourceType: 'groupPermissions',
  actions: () => ({
    fetchPermissionsByGroup: (group, config) => {
      const { params = {}, ...restConfig } = config || {}

      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.groupPermissions
            .fetchAll(
              {
                ...params.queries,
                group__uuid: group,
                page_size: 1000,
              },
              params.headers,
            )
            .then(transformResults(restConfig)),
      }
    },
  }),
}
