// @flow

import type { StoreResourceDef } from 'app/core/types'

export const jobs: StoreResourceDef<> = {
  resourceType: 'jobs',
  relations: {
    skillLevelsInst: {
      type: 'hasMany',
      resourceType: 'jobSkillLevels',
      foreignKey: 'job',
    },
  },
}
