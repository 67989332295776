/** @flow */
import React, { useState, useEffect } from 'react'
import { MUIModal } from 'app/components/Modal'
import { Pie, type PieProps } from 'app/components/Graphs/Pie.jsx'
import { ToggleButtonGroup, ToggleButton } from 'app/components/Form'
import { reduce } from 'lodash'

export type Props = {|
  ...PieProps,
  onRequestClose?: Function,
  additionnalButtons?: (
    initialData: $ElementType<PieProps, 'data'>,
    setDataFunction: ({ [key: string]: ($ElementType<PieProps, 'data'>) => $ElementType<PieProps, 'data'> }) => void,
    additionnalButtonsData: Object,
    setAdditionnalButtonsData: (Object) => void,
  ) => React$Node,
|}

export function ModalPie(props: Props): React$Node {
  const { onRequestClose, data, sliceLabel, formatValue, additionnalButtons, ...rest } = props
  const [tab, setTab] = useState(0)
  const [_data, _setData] = useState<$ElementType<PieProps, 'data'>>(data)
  const [dataFunctions, setDataFunctions] = useState<{
    [key: string]: ($ElementType<PieProps, 'data'>) => $ElementType<PieProps, 'data'>,
  }>({})
  const [additionnalButtonsData, setAdditionnalButtonsData] = useState<Object>({})

  function getPercentageValues(data) {
    const total = data.reduce((a, d) => a + d.value, 0)
    return data.map((d) => ({ ...d, value: Math.round((d.value / (total / 100)) * 100) / 100 }))
  }

  useEffect(() => {
    if (tab === 0) _setData(reduce(dataFunctions, (finalData, func) => (func ? func(finalData) : finalData), data))
    if (tab === 1)
      _setData(
        getPercentageValues(reduce(dataFunctions, (finalData, func) => (func ? func(finalData) : finalData), data)),
      )
  }, [data, tab, dataFunctions])

  return (
    <MUIModal
      title="Status stats"
      resizable={true}
      draggable={true}
      width={1000}
      height={600}
      hideValidate={true}
      cancelLabel="Close"
      cancelLabelColor="#4a4a4a"
      onRequestClose={onRequestClose}
      disableBackdrop={true}
    >
      <div className="fullWidth fullHeight">
        <div className="fullWidth flex row noWrap" style={{ height: 30 }}>
          <ToggleButtonGroup>
            <ToggleButton onClick={() => setTab(0)} selected={tab === 0}>
              Unit
            </ToggleButton>
            <ToggleButton onClick={() => setTab(1)} selected={tab === 1}>
              Percentage
            </ToggleButton>
          </ToggleButtonGroup>
          {additionnalButtons?.(
            tab === 1 ? getPercentageValues(data) : data,
            setDataFunctions,
            additionnalButtonsData,
            setAdditionnalButtonsData,
          )}
        </div>
        <div className="fullWidth" style={{ height: 'calc(100% - 35px)' }}>
          <Pie
            {...rest}
            data={_data}
            sliceLabel={tab === 1 ? (values: Object) => `${values.value}%` : sliceLabel}
            formatValue={tab === 1 ? (value: string) => `${value}%` : formatValue}
          />
        </div>
      </div>
    </MUIModal>
  )
}
