// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Choice-___Choice-module__container {
  display: flex;
  flex-direction: column;
  border: solid 1px #b3b9c7;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
}

.app-components-Form-Choice-___Choice-module__ghost {
  z-index: 1500;
}

.app-components-Form-Choice-___Choice-module__input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0 5px 0 0;
  margin-bottom: 10px;
}

.app-components-Form-Choice-___Choice-module__itemsScrollBlock {
  max-height: 200px;
  overflow-y: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: relative;
}

.app-components-Form-Choice-___Choice-module__item {
  padding: 0px 5px;
  margin-bottom: 3px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.app-components-Form-Choice-___Choice-module__item:hover {
  background-color: 'rgba(0,0,0,0.1)';
}

.app-components-Form-Choice-___Choice-module__icon {
  padding: 7px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Choice/Choice.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,8BAA8B;EAC9B,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  border: solid 1px #b3b9c7;\n  padding: 5px;\n  border-radius: 4px;\n  width: 100%;\n}\n\n.ghost {\n  z-index: 1500;\n}\n\n.input {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: center;\n  padding: 0 5px 0 0;\n  margin-bottom: 10px;\n}\n\n.itemsScrollBlock {\n  max-height: 200px;\n  overflow-y: auto;\n  user-select: none;\n  position: relative;\n}\n\n.item {\n  padding: 0px 5px;\n  margin-bottom: 3px;\n  border-radius: 5px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  align-items: center;\n  align-content: center;\n}\n\n.item:hover {\n  background-color: 'rgba(0,0,0,0.1)';\n}\n\n.icon {\n  padding: 7px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Form-Choice-___Choice-module__container`,
	"ghost": `app-components-Form-Choice-___Choice-module__ghost`,
	"input": `app-components-Form-Choice-___Choice-module__input`,
	"itemsScrollBlock": `app-components-Form-Choice-___Choice-module__itemsScrollBlock`,
	"item": `app-components-Form-Choice-___Choice-module__item`,
	"icon": `app-components-Form-Choice-___Choice-module__icon`
};
export default ___CSS_LOADER_EXPORT___;
