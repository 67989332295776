var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @flow */
import { useState } from 'react';
import { MUIModal } from 'app/components/Modal';
import AssetsSelect from '../AssetsSelect/AssetsSelect.jsx';
export function ModalUpdateEpisodicAsset(props) {
    const { onValidate } = props, rest = __rest(props
    // TO DO : check value type
    , ["onValidate"]);
    // TO DO : check value type
    const [value, setValue] = useState(undefined);
    function _onValidate() {
        if (!value)
            return Promise.resolve();
        onValidate(value.value);
        return Promise.resolve();
    }
    return (_jsx(MUIModal, Object.assign({ title: "Select episode", onValidate: _onValidate }, rest, { children: _jsxs("div", { className: "padding10", children: [_jsxs("div", { className: "flex row alignCenter paddingLeft20 paddingRight20 paddingBottom30", children: [_jsx("div", { style: { fontSize: '50px' }, className: "flex center alignCenter", children: _jsx("span", { role: "img", "aria-label": "warning", children: "\u26A0\uFE0F" }) }), _jsx("div", { className: "paddingLeft20", children: "This model is episodic and the modification of its parent requires a change of episode. For more information, contact your data manager." })] }), _jsx(AssetsSelect, { model: "tasks", taskType: "tgr", value: value, onChange: setValue })] }) })));
}
