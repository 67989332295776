// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Admin-StatusEditing-___StatusEditing-module__status {
  padding: 3px 10px;
  margin: 2px 0;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}

.app-pages-Admin-StatusEditing-___StatusEditing-module__buttonDelete {
  margin-left: 10px;
  color: lightgrey;
  cursor: pointer;
}
.app-pages-Admin-StatusEditing-___StatusEditing-module__buttonDelete:hover {
  color: grey;
}
`, "",{"version":3,"sources":["webpack://./app/pages/Admin/StatusEditing/StatusEditing.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,WAAW;AACb","sourcesContent":[".status {\n  padding: 3px 10px;\n  margin: 2px 0;\n  font-weight: bold;\n  border-radius: 3px;\n  cursor: pointer;\n}\n\n.buttonDelete {\n  margin-left: 10px;\n  color: lightgrey;\n  cursor: pointer;\n}\n.buttonDelete:hover {\n  color: grey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `app-pages-Admin-StatusEditing-___StatusEditing-module__status`,
	"buttonDelete": `app-pages-Admin-StatusEditing-___StatusEditing-module__buttonDelete`
};
export default ___CSS_LOADER_EXPORT___;
