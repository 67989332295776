// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Project-Assets-Library-___Library-module__overlay_3 {
  left: 0;
  bottom: 0;
  top: 90px;
  width: 100%;
  position: absolute;
  background-color: rgba(247, 248, 251, 0.65);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  height: calc(100% - 90px);
  z-index: 10000;
}

.app-pages-Project-Assets-Library-___Library-module__spinner {
  height: 90%;
}

.app-pages-Project-Assets-Library-___Library-module__highlightString {
  max-width: 100%;
  font-size: 12px;
  color: grey;
  white-space: pre;
  word-break: break-word;
}

.app-pages-Project-Assets-Library-___Library-module__highlightString > * {
    white-space: pre;
    word-break: break-word;
  }

.app-pages-Project-Assets-Library-___Library-module__label {
  font-size: 13px;
  font-weight: bold;
  word-break: break-all;
  margin-right: 10px;
  color: #4a4a4a;
  font-weight: bold;
}

.app-pages-Project-Assets-Library-___Library-module__noResults {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

.app-pages-Project-Assets-Library-___Library-module__noResults span {
    padding: 8px;
  }
`, "",{"version":3,"sources":["webpack://./app/pages/Project/Assets/Library/Library.module.scss"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,SAAS;EACT,SAAS;EACT,WAAW;EACX,kBAAkB;EAElB,2CAA2C;EAC3C,kCAA0B;UAA1B,0BAA0B;EAC1B,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,sBAAsB;AAKxB;;AAJE;IACE,gBAAgB;IAChB,sBAAsB;EACxB;;AAGF;EACE,eAAe;EACf,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,eAAe;AAKjB;;AAHE;IACE,YAAY;EACd","sourcesContent":[".overlay_3 {\n  left: 0;\n  bottom: 0;\n  top: 90px;\n  width: 100%;\n  position: absolute;\n  // transition: background-color 0.3s;\n  background-color: rgba(247, 248, 251, 0.65);\n  backdrop-filter: blur(3px);\n  height: calc(100% - 90px);\n  z-index: 10000;\n}\n\n.spinner {\n  height: 90%;\n}\n\n.highlightString {\n  max-width: 100%;\n  font-size: 12px;\n  color: grey;\n  white-space: pre;\n  word-break: break-word;\n  > * {\n    white-space: pre;\n    word-break: break-word;\n  }\n}\n\n.label {\n  font-size: 13px;\n  font-weight: bold;\n  word-break: break-all;\n  margin-right: 10px;\n  color: #4a4a4a;\n  font-weight: bold;\n}\n\n.noResults {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-size: 24px;\n\n  & span {\n    padding: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay_3": `app-pages-Project-Assets-Library-___Library-module__overlay_3`,
	"spinner": `app-pages-Project-Assets-Library-___Library-module__spinner`,
	"highlightString": `app-pages-Project-Assets-Library-___Library-module__highlightString`,
	"label": `app-pages-Project-Assets-Library-___Library-module__label`,
	"noResults": `app-pages-Project-Assets-Library-___Library-module__noResults`
};
export default ___CSS_LOADER_EXPORT___;
