import React from 'react'
import PropTypes from 'prop-types'
import Pagination from 'app/components/Pagination/Pagination.jsx'
import { MUIButton } from 'app/components/Form'
import { dateToLocaleStringFull } from 'app/libs/helpers'
import Loader from 'app/components/Loader/Loader.jsx'
import api from 'app/core/api/api.js'
import classes from './History.module.scss'

const PAGE_SIZE = 500

export default class History extends React.Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
  }

  static defaultProps = {}

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      versionsCount: 0,
      versions: [],
      isLoading: false,
    }

    // Promise.Cancelable()
  }

  componentDidMount() {
    this.goToPage(1)
  }

  componentWillUnmount() {
    this.isCanceled = true
  }

  renderVersions() {
    const { article } = this.props
    const { versions, isLoading } = this.state

    if (!isLoading && !versions.length) return <div className={classes.center}>No history</div>

    return (
      <div className={classes.overflow}>
        <ul className={classes.list}>
          {versions.map((version) => {
            const date = dateToLocaleStringFull(new Date(version.createdAt))

            return (
              <li key={version.id} className={classes.item}>
                <div className={classes.versionNumber}>Version {version.version}</div>
                <div className={classes.date}>{date}</div>
                <div className={classes.author}>edited by {version.authorInst.name}</div>
                <MUIButton to={`/assets-wiki/${article.asset}/${article.id}/${version.id}`}>See</MUIButton>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  goToPage = (pageNum) => {
    this.setState({ isLoading: true })

    api.articles
      .versions(
        {
          id: this.props.article.id,
        },
        {
          page: pageNum,
          ordering: '-createdAt',
          page_size: PAGE_SIZE,
        },
      )
      .then((res) => {
        if (this.isCanceled) return

        this.setState({
          versions: res.results,
          versionsCount: res.count,
          currentPage: pageNum,
          isLoading: false,
        })
      })
  }

  render() {
    const { isLoading } = this.state

    return (
      <div className={classes.history}>
        {isLoading && (
          <div className={classes.center}>
            <Loader />
          </div>
        )}
        {this.renderVersions()}
        <Pagination
          currentPage={this.state.currentPage}
          className={classes.pagination}
          totalNumberOfItems={this.state.versionsCount}
          pageSize={PAGE_SIZE}
          goToPage={this.goToPage}
        />
      </div>
    )
  }
}
