/** @flow */
import React, { useRef, useEffect, useState, Fragment } from 'react'
import { map } from 'lodash'
import moment from 'moment'
import type { Post, Thread, ID } from 'app/core/types'
import TextRead from 'app/components/TextEditor/TextRead.jsx'
import { CustomLogo } from 'app/libs/helpers/CustomLogo/CustomLogo.jsx'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import vars from 'app/styles/vars.js'
import api from 'app/core/api/api'
import resources from 'app/store/resources'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { Permission } from 'app/containers/Permissions'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import copy from 'copy-to-clipboard'
import { success } from 'app/components/Notifications/notify'

import classes from './Messages.module.scss'

const { getPalette } = vars

const palette = getPalette(['c300', 'c500', 'c700', 'c900'])

type Props = {|
  posts: { [string]: Array<Array<{ ...Post, nextIsNew?: boolean }>> },
  router: Object,
  onEdit: Function,
  thread: Thread,
  selectedPost?: ID,
  innerRef: Function,
  fetchPreviousPosts: Function,
  canLoadMore: boolean,
  userId: ID,
  projectId: ID,
|}

export function Messages(props: Props): React$Element<any> {
  const { posts, router, onEdit, thread, selectedPost, innerRef, fetchPreviousPosts, canLoadMore, userId, projectId } =
    props
  const scrollableZone: Object = useRef()
  const firstUnreadPost: Object = useRef()
  const [loader, setLoader] = useState(false)

  const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } } }

  function scrollToBottom() {
    if (scrollableZone.current) {
      scrollableZone.current.scrollTop = scrollableZone.current.scrollHeight
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (selectedPost) {
        const postToScroll = document.querySelector(`.post_${selectedPost}`)
        if (postToScroll && scrollableZone.current) {
          scrollableZone.current.scrollTop =
            postToScroll.offsetTop - (scrollableZone.current.offsetHeight - postToScroll.offsetHeight) / 2
          postToScroll.classList.add(classes.postHighlighted)
        } else {
          scrollToBottom()
        }
      } else if (firstUnreadPost.current) {
        scrollableZone.current.scrollTop = firstUnreadPost.current.offsetTop - 50
      } else {
        scrollToBottom()
      }
    }, 500)
    return () => {
      if (thread && thread.lastPostInst) {
        api.threads
          .lastReadedPost({ id: thread.id, post: thread.lastPostInst.id }, null, requestsConfig.params.headers)
          .then((res) => {
            resources.threads.update({ id: thread.id, unreadPostCount: 0 }, { localOnly: true })
          })
      }
    }
  }, [])

  let lastScrollTop

  function fetchOnScroll(el) {
    const { scrollTop, scrollHeight } = el
    const st = scrollTop

    if (st <= lastScrollTop && scrollTop < 100 && loader === false && canLoadMore) {
      setLoader(true)
      fetchPreviousPosts().then((res) => {
        setLoader(false)
        el.scrollTop = el.scrollHeight - scrollHeight - 12
      })
    }

    lastScrollTop = st
  }

  useEffect(() => {
    const { scrollHeight } = scrollableZone.current
    if (!scrollHeight) fetchOnScroll(scrollableZone.current)
  })

  const onCopyTextToClipboard = (postLink) => {
    copy(postLink)
    success('Copy to clipboard')
  }

  return (
    <div
      className="flex column overflowYAuto overflowXHidden fullWidth relative"
      style={{ maxHeight: '100%' }}
      onScroll={(e) => fetchOnScroll(e.currentTarget)}
      ref={(ref) => {
        scrollableZone.current = ref
        if (innerRef) innerRef(ref)
      }}
    >
      <div className={classes.postsContainer}>
        {loader ? (
          <div className="flex flex1 center">
            <LoaderSmall />
          </div>
        ) : null}
        {map(posts, (postsByDate, date) => {
          return (
            <div className="flex column fullWidth" key={date}>
              <div className={classes.dateSeparator}>{moment(date).calendar()}</div>
              {map(postsByDate, (postsByUser, index) => {
                let logo

                if (postsByUser[0] && postsByUser[0].authorInst && postsByUser[0].authorInst.thumbnailInst) {
                  logo = (
                    <div
                      className={classes.logoThumbnail}
                      style={{
                        background: `url(${postsByUser[0].authorInst.thumbnailInst.url}) center center / cover no-repeat`,
                      }}
                    />
                  )
                } else if (postsByUser[0] && postsByUser[0].authorInst && postsByUser[0].authorInst.name) {
                  logo = CustomLogo(palette, postsByUser[0] && postsByUser[0].authorInst.name, classes.logo, '.')
                } else {
                  logo = CustomLogo(palette, '?', classes.logo, '.')
                }

                return (
                  <div className="flex row noWrap marginTop5" key={index}>
                    <div style={{ height: 'inherit' }}>
                      <Tooltip
                        style={{ position: 'sticky', top: 0 }}
                        title={postsByUser[0] && postsByUser[0].authorInst.name}
                      >
                        <div className="flex column alignCenter">
                          {logo}
                          <div className={classes.usernameContainer}>
                            {postsByUser[0].authorInst.name.split(/[.-]/g).map((name) => (
                              <div key={name} className={classes.username}>
                                {name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                    <div className="flex column fullWidth">
                      {map(postsByUser, (post) => {
                        const postLink = router.getAbsoluteLink
                          ? window.location.origin + router.getAbsoluteLink('thread', { postId: post.id })
                          : null

                        return (
                          <Fragment key={post.postedOn}>
                            <div className={`${classes.postContainer} flex row noWrap relative post_${post.id}`}>
                              {post.postedOn &&
                              post.updatedOn &&
                              post.postedOn.substr(0, 21) !== post.updatedOn?.substr(0, 21) ? (
                                <FontIcon
                                  icon="fas-pencil-alt"
                                  className="fontSize10 absolute"
                                  style={{ color: 'grey', top: 5, left: 5 }}
                                />
                              ) : null}
                              <div className={classes.post}>
                                <TextRead
                                  text={post.text}
                                  className={classes.editorRead}
                                  qlProps={{ style: { padding: 0 } }}
                                />
                              </div>
                              <div className={`${classes.postActions} flex row alignCenter`}>
                                {userId === post.authorInst.id ? (
                                  <Permission actions={['projet_assets_discussions_edit message']}>
                                    <Tooltip title="Edit message">
                                      <div
                                        className={`${classes.postAction} flex center alignCenter`}
                                        onClick={() => onEdit(post)}
                                      >
                                        <FontIcon icon="fas-edit" />
                                      </div>
                                    </Tooltip>
                                  </Permission>
                                ) : null}
                                {postLink ? (
                                  <Tooltip onClick={() => onCopyTextToClipboard(postLink)} title="Copy link of message">
                                    <div className={`${classes.postAction} flex center alignCenter`}>
                                      <FontIcon icon="fas-link" />
                                    </div>
                                  </Tooltip>
                                ) : null}
                              </div>
                            </div>
                            {post.nextIsNew ? (
                              <div className={classes.unreadContainer} ref={firstUnreadPost}>
                                <div className={classes.unreadLabel}>Unread messages</div>
                              </div>
                            ) : null}
                          </Fragment>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}
