/** @flow */
import type { Option } from '../types'

export type PostboardLinksType = 'create' | 'deriv' | 'reut' | 'update'

const postboardLinks = {
  create: 'create',
  update: 'update',
  reut: 'reut',
  deriv: 'deriv',
}

export default postboardLinks

export const postboardLinksTypesOptions: Array<Option> = [
  { value: 'create', label: 'New asset', icon: 'fas-plus' },
  { value: 'update', label: 'Updated asset', icon: 'fas-pen' },
  { value: 'reut', label: 'Reused asset', icon: 'fas-copy' },
  { value: 'deriv', label: 'Derivated asset', icon: 'fas-share' },
]
