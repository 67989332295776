/** @flow */
import React, { useMemo, useRef } from 'react'
import cx from 'classnames'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { MUIButton } from 'app/components/Form'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'

import type { ColumnHeader, Column } from '../../types'
import classes from './ColumnGanttTree.module.scss'
import { useRowDrop } from '../../hooks/useRowDrop'

export type Params = {|
  ...$Shape<Column>,
  noColorBadge?: boolean,
|}

export const ColumnGanttTree = (params?: $Shape<Params>): ColumnHeader => {
  const { noColorBadge, ...rest } = params || {}
  const columns: Array<$Shape<Column>> = [
    {
      id: 'ganttExpander',
      cellType: 'CellGanttTree',
      Header: 'People',
      disableEvents: {
        focus: true,
      },
      canGroupBy: false,
      hiddenable: false,
      unselectable: true,
      fixable: true,
      readOnly: false,
      accessor: 'name',
      actions: (instance, cell) => {
        const { ganttActions } = instance
        return ganttActions?.(instance, cell, cell.row.depth) || []
      },
      save: (original, finalValue, cell, instance, type) => {
        if (instance.ganttSave) {
          return instance.ganttSave(original, finalValue, cell, instance, type)
        }
        return {
          resource: original.__resource,
          formatData: (original, value, cell, instance, type) => {
            const { subRows, __resource, uneditableDates, ...rest } = value
            return rest
          },
        }
      },
      width: 250,
      minWidth: 50,
      Cell: (instance) => {
        const {
          row,
          value,
          cell,
          getSelectedTimelineCells,
          selectDescendentCells,
          focusTimelineCell,
          reloadGanttTree,
          state: { expanded },
        } = instance

        const dropRef = useRef(null)
        const dragRef = useRef(null)

        const { color, __resource, contract } = row.original

        const selectedTimelineCells = getSelectedTimelineCells?.() || []
        const selected = useMemo(
          () => Boolean(selectedTimelineCells.find((c) => c.row.id === row.id)),
          [selectedTimelineCells],
        )

        function onClick(e: SyntheticMouseEvent<>) {
          const timelineCell = row.cells.find((c) => c.column.cellType === 'CellTimeline')
          if (!timelineCell) return
          if (e.shiftKey) selectDescendentCells?.(timelineCell, e.ctrlKey || e.metaKey)
          focusTimelineCell?.(timelineCell)
          reloadGanttTree()
        }

        const { onClick: expanderOnClick } = row.getToggleRowExpandedProps()

        const isDraggable = typeof row.original?.rank === 'number'
        const { isDragging, isOver, canDrop } = useRowDrop({
          row,
          dropRef,
          dragRef,
          disabled: !isDraggable,
          instance,
        })

        const depthPadding = row.depth * 20

        return (
          <div
            className={cx(classes.cell, {
              [classes.isDragging]: isOver && canDrop,
              [classes.haveSelectedCells]: selectedTimelineCells.length > 0,
              [classes.selected]: selected,
            })}
            style={{
              paddingLeft: `${depthPadding}px`,
              backgroundColor: color || undefined,
              color: color ? getColorFromBackground(color) : undefined,
            }}
            ref={(ref) => {
              dropRef.current = ref
            }}
          >
            {isDraggable ? (
              <div
                className={cx(classes.dragIcon)}
                style={{
                  cursor: isDragging ? 'grabbing' : 'grab',
                  display: isOver && canDrop ? 'flex' : undefined,
                }}
                ref={(ref) => {
                  dragRef.current = ref
                }}
              >
                <FontIcon icon="fas-grip-vertical" />
              </div>
            ) : null}
            <MUIButton
              disableRipple={true}
              disabled={row.subRows?.length === 0}
              variant="text"
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation()
                e.preventDefault()
                if (row.subRows?.length) {
                  expanderOnClick?.(e)
                  cell.update?.()
                }
              }}
              style={{ width: 30, height: '100%', color: 'inherit' }}
            >
              {row.subRows?.length ? <FontIcon icon={expanded[row.id] ? 'fas-caret-down' : 'fas-caret-right'} /> : null}
            </MUIButton>
            <div
              className={classes.cellLabel}
              onClick={onClick}
              style={{
                width: `calc(100% - 30px)`,
              }}
            >
              <div
                className={cx(classes.label, {
                  [classes.imputationCandidat]: __resource === 'imputations' && !contract,
                })}
                style={{ width: `calc(100% - 5px)` }}
              >
                {value}
              </div>
            </div>
          </div>
        )
      },
      ...rest,
    },
  ]

  return {
    id: 'ganttExpander',
    Header: 'Table',
    columns,
  }
}
