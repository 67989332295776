// @flow
import React from 'react'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { FormData } from 'app/components/Form'
import resources from 'app/store/resources'
import type { Category } from 'app/core/types'

export type ModalEditCategoryProps = {|
  ...$Rest<ModalProps, { children: React$Node }>,
  category?: Category,
  onSave?: ($Shape<Category>) => Promise<any>,
  onChange?: ($Shape<Category>) => any,
|}

export function ModalEditCategory(props: ModalEditCategoryProps): React$Element<any> {
  const { onSave, onChange, category, ...modalProps } = props

  async function save(data: Object) {
    const { name } = data

    const newCategory = {
      id: category?.id,
      name,
    }

    if (onSave) return onSave(newCategory)
    const res = await resources.contracts[category?.id ? 'update' : 'create'](newCategory)
    onChange?.(res.resources[0])
    return res
  }

  return (
    <ModalConfirmForm
      title={category ? `Edit category of ${category.name || ''}` : 'Create new category'}
      draggable={true}
      {...modalProps}
    >
      <FormData
        onSave={save}
        flashNotifSuccessLabel={`Category ${category ? 'edited' : 'created'}`}
        defaultData={category}
        properties={[
          {
            label: 'Name',
            key: 'name',
            type: 'string',
            elementProps: {
              isRequired: true,
            },
          },
        ]}
      />
    </ModalConfirmForm>
  )
}
