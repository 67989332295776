var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ModalConfirmForm } from 'app/components/Modal';
import { FormData } from 'app/components/Form';
import resources from 'app/store/resources';
import api from 'app/core/api/api.js';
import { postBoardLinkTypes } from 'app/core/constants';
import { postboardLinksTypesOptions } from 'app/core/constants/postBoardLinkTypes';
import { getFormFromLinkType } from './FormLinkType';
export function ModalAddAssetToPostboard(props) {
    var _a;
    const { project, onRequestClose, episodeId, paginatedList, onSave, linkType } = props;
    function handleSave(data) {
        return __awaiter(this, void 0, void 0, function* () {
            let promise = () => { var _a; return Promise.resolve((_a = data.asset) === null || _a === void 0 ? void 0 : _a.value); };
            if (linkType === 'create' || linkType === 'deriv') {
                promise = () => __awaiter(this, void 0, void 0, function* () {
                    var _a, _b, _c;
                    const file = ((_a = data.thumbnail) === null || _a === void 0 ? void 0 : _a[0]) || null;
                    const media = file ? yield resources.medias.upload({ files: file }) : null;
                    const assetToCreate = {
                        name: data.name,
                        parent: data.parent.value,
                        attributes: {},
                        assetType: data.assetType,
                        thumbnail: (_c = (_b = media === null || media === void 0 ? void 0 : media.resources) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id,
                        project: project.id,
                    };
                    return resources.assets.create(assetToCreate).then((res) => {
                        const assetId = res.resources[0].id;
                        if (data.flags) {
                            const flags = data.flags.map(({ flagInst }) => flagInst);
                            return resources.assetFlags
                                .create(flags.map((flag) => ({ asset: assetId, flag: flag.id })))
                                .then(() => assetId);
                        }
                        return assetId;
                    });
                });
            }
            /* create postboard link */
            const promiseSaveLinks = promise().then((assetId) => resources.postBoardLinks
                .create([
                {
                    episode: episodeId,
                    asset: assetId,
                    linkType,
                    firstShot: data.firstShot,
                    notes: [],
                },
            ], { paginatedList })
                .then((res) => {
                const link = res.resources[0];
                if (link && linkType === postBoardLinkTypes.deriv) {
                    return api.assets.fetchOne(data.assetParentId.value).then((parentAsset) => {
                        const note = {
                            link: link.id,
                            text: `<p>Derivated from ${parentAsset.name}</p>`,
                        };
                        return resources.postBoardNotes.create(note);
                    });
                }
                if (link && (linkType === postBoardLinkTypes.reut || linkType === postBoardLinkTypes.update)) {
                    if (data.flags) {
                        const newFlagsId = data.flags.map((flag) => flag.id);
                        const oldFlagsId = link.assetInst.flags;
                        const flags = newFlagsId.filter((id) => !oldFlagsId.includes(id));
                        return resources.assetFlags.create(flags.map((flag) => ({ asset: link.asset, flag: flag })));
                    }
                }
                return link;
            }));
            return promiseSaveLinks.then(onSave);
        });
    }
    return (_jsx(ModalConfirmForm, { title: `Add ${((_a = postboardLinksTypesOptions.find((o) => o.value === linkType)) === null || _a === void 0 ? void 0 : _a.label.toLowerCase()) || 'model to anotate'}`, minWidth: 600, draggable: true, validateLabel: "Add asset", cancelLabel: "Close", onRequestClose: onRequestClose, children: _jsx(FormData, { onSave: handleSave, properties: getFormFromLinkType(linkType, project), defaultData: { assetType: 'mo' } }) }));
}
