/** @flow */
import React from 'react'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import AssetSelect, { type Props as AssetSelectProps } from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import type { Option } from 'app/pages/Project/Breakdown/ModalLinks.jsx'
import classes from './AssetsSelectMultiple.module.scss'

type Props = {|
  ...AssetSelectProps,
  assets: Array<Option>,
  onChange: (Array<Option>) => *,
  renderLabel: (Option) => React$Node,
  renderDeleteIcon: ({ onDelete: () => void }) => React$Node,
  style: Object,
|}

export function AssetsSelectMultiple(props: Props): React$Node {
  const defaultRenderLabel = (asset: Option) => <span dangerouslySetInnerHTML={{ __html: asset.label }} />
  const defaultRenderDeleteIcon = ({ onDelete }: { onDelete: () => void }) => (
    <FontIcon icon="close" style={{ color: '#fff', padding: '0 0 0 4px', cursor: 'pointer' }} onClick={onDelete} />
  )

  const {
    renderLabel = defaultRenderLabel,
    renderDeleteIcon = defaultRenderDeleteIcon,
    assets = [],
    style,
    onChange,
    ...rest
  } = props

  const onAddAsset = (value) => {
    if (!value || assets.indexOf(value) >= 0) return
    onChange([...assets, value])
  }

  const onDeleteAsset = (asset: Option) => {
    const _assets: Array<Option> = assets.slice()
    if (!asset || _assets.indexOf(asset) < 0) return
    _assets.splice(_assets.indexOf(asset), 1)
    onChange(_assets)
  }

  return (
    <div style={style} className={classes.container}>
      <div className={classes.assets}>
        {assets.map((asset: Option) => (
          <div key={asset.label} className={classes.item}>
            {renderLabel(asset)}
            {renderDeleteIcon({ onDelete: () => onDeleteAsset(asset) })}
          </div>
        ))}
      </div>
      <div className={classes.assetSelect}>
        <AssetSelect {...rest} onChange={onAddAsset} style={{ maxWidth: '100%' }} blurOnSelect={false} />
      </div>
    </div>
  )
}
