/** @flow */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { filter, get, map, reduce } from 'lodash'
import styles from 'app/styles/vars.js'
import { breakdownDatasKey } from 'app/core/constants/breakdownDatasKey'
import type { AssetLinkTypes, ID } from 'app/core/types'
import history from 'app/main/routerHistory'
import { Table } from 'app/components/Table/Table.jsx'
import type { ColumnHeader, ResourcesParams, Cell } from 'app/components/Table/types'
import { openModal } from 'app/components/Modal/index.js'
import { permission } from 'app/containers/Permissions/Permission.jsx'
import { router } from 'app/containers/Assets/AssetDetail/router'
import type { TablePrefs } from 'app/components/Table/Table'
import {
  CellText,
  CellLink,
  CellFlags,
  CellRichText,
  CellAssetsList,
  CellSelectRow,
  CellThumbnail,
} from 'app/components/Table/Cells'
import { getUserPrefKey } from 'app/components/Table/utils.js'
import { userPrefLocalResource } from 'app/core/utils/localUserPrefs.js'
import { getAssetsAttributValues } from 'app/store/selectors/getAssetsAttributValues.js'
import { getResources } from 'app/store/selectors/index.js'
import resources from 'app/store/resources/index.js'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { error } from 'app/components/Notifications/notify.js'
import { MakeTableFromArray, CellStatsDefault } from 'app/components/Table/Cells/StatsCells'
import { createUpdatingFlagsPromises } from 'app/components/Form/Flags/utils.js'
import { Filters } from './Filters.js'
import { sortCategories } from './utils.js'
import { ModalLinks } from './ModalLinks.jsx'
import { ModalSearchAndReplace } from './ModalSearchAndReplace.jsx'
import { CellStatsAssetLinks } from '../../../components/Table/Cells/StatsCells/CellStatsAssetLinks.jsx'

const _tableId = 'table-breakdown'

type BreakdownTableProps = {|
  parentAssetId: ?ID,
  resourcesParams: ?ResourcesParams,
  showRedirectToBreakdownEpisode?: boolean,
  projectId: ?ID,
  enableEdit?: boolean,
  tableId?: string,
|}

export function BreakdownTable(props: BreakdownTableProps): null | React$Element<(props: TablePrefs) => React$Node> {
  const { parentAssetId, resourcesParams, showRedirectToBreakdownEpisode, projectId, enableEdit, tableId } = props

  const [categories, setCategories] = useState<Array<string>>([])
  const [assetLinkTypesBreakdown, setAssetLinkTypesBreakdown] = useState<AssetLinkTypes | void>()
  const [tableIsLoaded, setTableIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } } }
    Promise.all([
      resources.assetLinkTypes.fetchAll(requestsConfig),
      resources.assetsCustomAttributValues.fetch('category', requestsConfig),
    ]).then(() => {
      const attributCategory = getAssetsAttributValues(undefined, 'category')
      setCategories(sortCategories(attributCategory).filter((_) => _))
      setAssetLinkTypesBreakdown(map(getResources(undefined, 'assetLinkTypes', { name: 'breakdown' }))?.[0])
      setTableIsLoaded(true)
    })
  }, [])

  const palette = useMemo(() => styles.getPalette(['c500', 'c400', 'c600']), [])

  const userPrefKey = useMemo(() => getUserPrefKey(_tableId), [])
  const [hiddenCategoryLength, setHiddenCategoryLength] = useState<{ [string]: boolean }>(
    userPrefLocalResource.getData(`${userPrefKey}.hiddenCategoryLength`) || {},
  )
  useEffect(() => {
    userPrefLocalResource.setData(`${userPrefKey}.hiddenCategoryLength`, hiddenCategoryLength)
  }, [hiddenCategoryLength])

  function toggleLength(instance: *, category) {
    const {
      allColumns,
      state: { hiddenColumns },
    } = instance
    const column = allColumns.find((col) => col.id === `${category} - length`)
    const hidden = Boolean(hiddenColumns?.includes(column?.id))

    return [
      {
        label: `${hidden ? 'Show' : 'Hide'} length`,
        onClick: () => {
          setHiddenCategoryLength({ ...hiddenCategoryLength, [category]: !hidden })
          column?.toggleHidden?.()
        },
        editAction: false,
      },
    ]
  }

  const columns: Array<ColumnHeader> = useMemo(
    () => [
      {
        Header: ' ',
        id: 'expanderGroup',
        resizable: false,
        columns: [
          CellSelectRow({
            Header: ' ',
            id: 'select',
            readOnly: !enableEdit,
            fixed: 'left',
            unselectable: true,
            enableMultiplePast: true,
            width: 40,
            hiddenable: true,
            actions: () => ['copy', 'delete'],
            hidden: !permission(
              [
                'projet_breakdown_links_create',
                'projet_breakdown_links_delete',
                'projet_breakdown_links_search and replace',
              ],
              'or',
            ),
            getSelectableCells: (instance, event) => {
              const { row, getLastestInstance, getCellsBetween } = instance
              const { state } = getLastestInstance()
              const { selectedCells: prevSelectedCells, lastSelectedCell: prevLastSelectedCell } = state

              let selectedCells = row.cells.filter((c) => c.column.cellType === 'CellAssetsList')
              const lastSelectedCell = selectedCells[0]

              if (event.metaKey || event.ctrlKey) {
                selectedCells = [...map(prevSelectedCells), ...selectedCells]
              } else if (event.shiftKey && prevLastSelectedCell) {
                const cells = getCellsBetween(prevLastSelectedCell, lastSelectedCell)
                selectedCells = map(cells, (cell) =>
                  cell.row.cells.filter((c) => c.column.cellType === 'CellAssetsList'),
                ).flat()
              }

              return {
                selectedCells: selectedCells.reduce(
                  (acc: { [cellId: string]: Cell }, cell) => ({ ...acc, [cell.id]: cell }),
                  {},
                ),
                lastSelectedCell,
              }
            },
          }),
        ],
      },
      {
        Header: 'Informations',
        id: 'info',
        columns: [
          CellLink({
            id: 'name',
            unselectable: false,
            readOnly: !enableEdit,
            Header: 'Name',
            fixed: 'left',
            accessor: 'name',
            showRemoved: true,
            sortingKey: 'name',
            onClick: (row, e) => router.goTo('index', { assetId: row.original.id }, { rightPanel: true }),
          }),
          CellThumbnail({
            id: 'thumbnail',
            Header: 'Thumbnail',
            accessor: 'thumbnailInst',
            fixable: true,
            readOnly: !enableEdit,
            hiddenable: true,
            width: 150,
            actions: () => ['edit', 'copy', 'past', 'delete'],
            save: {
              resource: 'assets',
              formatData: (item, value, cell, instance, type) => {
                return {
                  id: item.id,
                  thumbnail: value?.id || null,
                }
              },
            },
          }),
          CellText({
            Header: 'Length',
            id: 'length',
            accessor: 'attributes.length',
            readOnly: !enableEdit,
            inputProps: {
              type: 'number',
            },
            sortingKey: 'attributes__length',
            fixable: true,
            hiddenable: true,
            actions: () => ['edit', 'copy', 'past'],
            containerStyle: { padding: 0 },
            RenderRead: (cell, value) => <div className="flex row center alignCenter">{value}</div>,
            Stats: (instance) => <CellStatsDefault instance={instance} resource="length" />,
            save: {
              resource: 'assets',
              formatData: (item, value) => ({
                id: item.id,
                attributes: { length: value || value === 0 ? Number(value) : null },
              }),
            },
          }),
          CellText({
            Header: 'Sequence',
            id: 'sequence',
            hiddenable: true,
            readOnly: !enableEdit,
            fixable: true,
            sortingKey: 'attributes__sequence',
            actions: () => ['edit', 'copy', 'past'],
            colored: true,
            containerStyle: { padding: 0 },
            accessor: (item) => {
              let { sequence } = item.attributes
              if ([undefined, null].includes(sequence)) {
                sequence = ''
              }
              return sequence
            },
            Stats: (footer) => {
              const { stats } = footer
              const { default: _default } = stats || {}

              if (!_default) return null

              return (
                <MakeTableFromArray
                  values={_default.sequence_counts}
                  referenceName="sequence"
                  style={{ height: '100%' }}
                  tableId={tableId || _tableId}
                />
              )
            },
            save: {
              resource: 'assets',
              formatData: (item, value) => ({ id: item.id, attributes: { sequence: value } }),
            },
          }),
          CellFlags({
            Header: 'Flags',
            id: 'flags',
            width: 200,
            readOnly: !enableEdit,
            accessor: 'assetFlagsInst',
            fixable: true,
            hiddenable: true,
            actions: () => ['edit', 'copy', 'past', 'delete'],
            foreignKey: 'asset',
            category: (cell) => cell.row.original.assetType,
            getResourceID: (cell) => cell.row.original.id,
            save: {
              resource: 'assets',
              formatData: (item, value, cell, instance, type) => {
                if (type === 'delete') value = []

                const newFlags = map(value, ({ flagInst }) => ({ asset: item.id, flag: flagInst.id }))
                return createUpdatingFlagsPromises(newFlags, map(item.assetFlagsInst), 'assetFlags', type)
              },
            },
          }),
        ],
      },

      ...categories.flatMap((category, index) => [
        {
          Header: category,
          id: `${category}Header`,
          headerColor: palette[index % palette.length],
          columns: [
            CellAssetsList({
              Header: category,
              headerColor: palette[index % palette.length],
              id: category,
              readOnly: !enableEdit,
              enableMultiplePast: true,
              fixable: true,
              hiddenable: true,
              accessor: (item) => {
                if (!item) return []
                return filter(item.assetLinksInst, (link) => link.to_assetInst.attributes.category === category)
              },
              category,
              actions: () =>
                ['edit', 'copy', 'past', ...(permission(['projet_breakdown_links_delete']) ? ['delete'] : [])].filter(
                  (_) => _,
                ),
              headerActions: (instance) => toggleLength(instance, category),
              onOpenItem: (asset) => {
                router.goTo('index', { assetId: asset.id }, { rightPanel: true })
              },
              Stats: (instance) => (
                <CellStatsAssetLinks instance={instance} category={category} statsAccessKey="default" />
              ),

              save: {
                resource: 'assetLinks',
                formatData: (item, value, cell, instance, type) => {
                  console.log(value)

                  if (type === 'merge') {
                    if (map(value).length === 0) return null

                    return map(
                      value.filter((rel) => rel.to_assetInst.attributes.category === cell.column.id),
                      (link) => ({
                        // ...link,
                        from_asset: item.id,
                        datas: undefined,
                        to_asset: link.to_assetInst.id,
                        linkType: assetLinkTypesBreakdown?.id,
                        id: undefined,
                        to_assetInst: value?.to_assetInst,
                      }),
                    )
                  }

                  if (type === 'past') {
                    if (map(value).length === 0) return null
                    return {
                      type: 'rel',
                      resource: 'assetLinks',
                      toCreate: value
                        .filter((rel) => rel.to_assetInst.attributes.category === cell.column.id)
                        .map((link) => ({
                          // ...link,
                          from_asset: item.id,
                          datas: undefined,
                          to_asset: link.to_assetInst.id,
                          linkType: assetLinkTypesBreakdown?.id,
                          id: undefined,
                          to_assetInst: value?.to_assetInst,
                        })),
                      toDelete: cell.value.map((link) => link.id),
                    }
                  }

                  if (type === 'delete') return map(cell.value, ({ id }) => id)

                  const { toCreate, toDelete } = value

                  const toCreateByColumn = toCreate.filter(
                    (rel) => rel.assetInst?.attributes?.category === cell.column.id && rel.from_asset === item.id,
                  )

                  return {
                    type: 'rel',
                    resource: 'assetLinks',
                    toCreate: toCreateByColumn,
                    toDelete,
                  }
                },
              },
            }),
            CellText({
              Header: 'Count',
              headerColor: palette[index % palette.length],
              id: `${category} - count`,
              readOnly: true,
              hiddenable: false,
              fixable: false,
              resizable: true,
              hidden: Boolean(hiddenCategoryLength[category]),
              canGroupBy: false,
              headerActions: (instance) => toggleLength(instance, category),
              accessor: (item) => {
                if (!item) return 0
                return filter(item.assetLinksInst, (link) => link.to_assetInst.attributes.category === category).length
              },
              actions: () => [],
              width: 30,
              minWidth: 30,
            }),
          ],
        },
      ]),

      {
        Header: ` `,
        id: `notes`,
        columns: [
          CellRichText({
            Header: 'Note',
            id: `note`,
            fixable: true,
            readOnly: !enableEdit,
            hiddenable: true,
            accessor: (item) => get(item, `attributes.${breakdownDatasKey}.note`, ''),
            actions: () =>
              ['edit', 'copy', 'past', ...(permission(['projet_breakdown_links_delete']) ? ['delete'] : [])].filter(
                (_) => _,
              ),
            save: {
              resource: 'assets',
              formatData: (item, value) => ({ id: item.id, attributes: { [breakdownDatasKey]: { note: value } } }),
            },
          }),
        ],
      },
      {
        id: 'fixedRight',
        Header: ' ',
        columns: [
          CellLink({
            Header: 'Redirect',
            id: 'redirect',
            readOnly: !enableEdit,
            accessor: () => <FontIcon icon="fas-external-link-alt" />,
            hidden: !showRedirectToBreakdownEpisode,
            onClick: (row, event) => {
              const shot = row.original

              if (!shot.project) {
                error(`Can't redirect to breakdown : project of the shot ${shot.name} is not set`)
                return
              }

              history.push(`/projects/${shot.project}/breakdown/${shot.parent}`)
            },
          }),
        ],
      },
    ],
    [tableIsLoaded],
  )

  const ToggleButtons = useCallback(
    (instance) => {
      return [
        {
          key: 'btn1',
          onClick: () => {
            const {
              state: { selectedCells },
              updateSelection,
            } = instance.getLastestInstance()
            const fromAssetIds = map(selectedCells, (cell) => cell.row.original.id)

            if (!selectedCells || Object.keys(selectedCells).length < 1) {
              error('Select a cell on a shot to add links')
              return
            }

            let editingCell
            const cells = reduce(
              selectedCells,
              (acc, cell) => {
                const cellAssetList = { ...acc }
                cell.row.cells.forEach((_cell) => {
                  if (_cell.column.cellType === 'CellAssetsList') {
                    cellAssetList[_cell.id] = _cell
                    if (!editingCell) editingCell = _cell
                  }
                })
                return cellAssetList
              },
              {},
            )

            updateSelection(editingCell, cells)

            openModal(
              <ModalLinks
                title="Add assets"
                fromAssetsId={fromAssetIds}
                selectedCells={cells}
                onChange={(values) => {
                  return editingCell?.edition.save(values) || Promise.resolve()
                }}
              />,
            )
          },
          icon: 'fas-plus',
          label: 'Add asset on selected shot',
        },
        ...(permission(['projet_breakdown_links_search and replace']) && parentAssetId
          ? [
              {
                key: 'btn2',
                items: [
                  {
                    label: 'Add',
                    icon: 'fas-plus',
                    key: 'add',
                    onClick: () => {
                      const { reloadData } = instance
                      openModal(
                        <ModalSearchAndReplace
                          parentAssetId={parentAssetId}
                          type="add"
                          onChange={() => reloadData()}
                        />,
                      )
                    },
                  },
                  {
                    label: 'Replace',
                    icon: 'fas-exchange-alt',
                    key: 'replace',
                    onClick: () => {
                      const { reloadData } = instance
                      openModal(
                        <ModalSearchAndReplace
                          parentAssetId={parentAssetId}
                          type="replace"
                          onChange={() => reloadData()}
                        />,
                      )
                    },
                  },
                  {
                    label: 'Delete',
                    icon: 'fas-trash',
                    key: 'delete',
                    onClick: () => {
                      const { reloadData } = instance
                      openModal(
                        <ModalSearchAndReplace
                          parentAssetId={parentAssetId}
                          type="delete"
                          onChange={() => reloadData()}
                        />,
                      )
                    },
                  },
                ],
                label: 'Search and ...',
              },
            ]
          : []),
      ]
    },
    [parentAssetId],
  )

  const filters = useMemo(() => new Filters().getFilters(), [columns])

  if (!tableIsLoaded) return null

  return (
    <Table
      tableId={tableId || _tableId}
      projectId={projectId}
      exportExcelFormat={parentAssetId ? { assetId: parentAssetId, data: {}, type: 'breakdown' } : undefined}
      resourcesParams={resourcesParams}
      filters={filters}
      enableStats={true}
      toggleButtons={ToggleButtons}
      rowExpander={true}
      columns={columns}
    />
  )
}
