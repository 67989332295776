export const getHowToVisitedPages = (key) => {
  let visitedPages = JSON.parse(window.localStorage.getItem('howToVisitedPages'))
  if (!visitedPages) {
    window.localStorage.setItem('howToVisitedPages', JSON.stringify({}))
    visitedPages = {}
  }
  if (!key) return visitedPages
  return visitedPages[key] || null
}

export const setHowToVisitedPages = (key, value) => {
  const visitedPages = getHowToVisitedPages()
  const newVisitedPages = { ...visitedPages, [key]: value }
  window.localStorage.setItem('howToVisitedPages', JSON.stringify(newVisitedPages))
}
