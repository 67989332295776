// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-Flags-___FlagsRelations-module__container {
  display: flex;
  flex-wrap: wrap;
}

.app-components-Form-Flags-___FlagsRelations-module__flag {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  padding: 1px 6px;
  margin: 0 2px 5px;
  font-weight: bold;
}

.app-components-Form-Flags-___FlagsRelations-module__flag:focus-within {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/Flags/FlagsRelations.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.flag {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 30px;\n  padding: 1px 6px;\n  margin: 0 2px 5px;\n  font-weight: bold;\n}\n\n.flag:focus-within {\n  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Form-Flags-___FlagsRelations-module__container`,
	"flag": `app-components-Form-Flags-___FlagsRelations-module__flag`
};
export default ___CSS_LOADER_EXPORT___;
