// @flow
import * as React from 'react'
import { startsWith } from 'lodash'
import { AssetHistory } from 'app/containers/AssetHistory/AssetHistory.tsx'
import type { ID, ResourcesList, ProgressionStatus, Step } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import { type Pipe } from 'app/core/utils/pipeNext.type'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { durationStr } from 'app/components/Duration/Duration.tsx'
import {
  CellSelect,
  CellText,
  CellDuration,
  CellAssetsSelect,
  CellRichText,
  CellStatus,
  CellMedias,
} from 'app/components/Table/Cells'
import { getResources } from 'app/store/selectors/getResources'
import resources from 'app/store/resources'
import { optionsSteps } from 'app/core/utils/optionsSteps'
import { getSubResources } from 'app/store/resources/utils/getSubResources'
import flagClasses from 'app/components/Table/Cells/CellFlags/CellFlags.module.scss'

type TaskHistoryProps = {|
  assetId: ID,
  progressionStatus: ResourcesList<ProgressionStatus>,
  steps: ResourcesList<Step>,
  projectId: ?ID,
  tableId: string,
|}

class TaskHistoryComponent extends React.PureComponent<TaskHistoryProps> {
  columns = () => {
    const { progressionStatus, steps } = this.props

    return [
      {
        Header: ' ',
        id: 'fixed',
        columns: [
          CellText({
            id: 'type',
            Header: 'Type',
            actions: () => ['copy'],
            readOnly: true,
            accessor: ({ actionObject }) => {
              if (actionObject.task && !actionObject.duration) return `take ${actionObject.number}`
              if (actionObject.color) return 'flag'
              if (actionObject.duration) return 'time'
              return 'task'
            },
            RenderRead: (cell, value) => {
              let backgroundColor = ''

              if (value === 'task') backgroundColor = 'rgba(0,255,0,0.2)'
              if (startsWith(value, 'take')) backgroundColor = 'rgba(125,149,226,0.5)'
              if (value === 'time') backgroundColor = 'rgba(210,125,226,0.5)'
              if (value === 'flag') backgroundColor = 'rgba(255,0,0,0.2)'

              return (
                <div className="flex center alignCenter absolute fullWidth fullHeight" style={{ backgroundColor }}>
                  {value}
                </div>
              )
            },
            width: 80,
          }),
          CellText({
            id: 'take__name',
            Header: 'Info/Name',
            readOnly: true,
            actions: () => ['copy'],
            accessor: ({ actionObject }) => {
              if (actionObject.duration) {
                return durationStr(actionObject.duration)
              }
              if (actionObject.color) {
                return (
                  <span
                    className={flagClasses.flagReadOnly}
                    style={{ backgroundColor: actionObject.color, color: getColorFromBackground(actionObject.color) }}
                  >
                    {actionObject.name}
                  </span>
                )
              }
              return actionObject.name
            },
            width: 150,
          }),
          CellStatus({
            id: 'status',
            Header: 'Status',
            readOnly: true,
            accessor: 'actionObject.status',
            actions: () => ['copy'],
            progressionStatus,
            width: 110,
          }),
        ],
      },
      {
        id: 'tasksInfo',
        Header: 'Tasks info',
        headerColor: 'rgba(0,255,0,0.2)',
        columns: [
          CellSelect({
            id: 'stepInst',
            Header: 'Pipeline step',
            actions: () => ['copy'],
            accessor: 'actionObject.step',
            readOnly: true,
            width: 100,
            options: () => optionsSteps(steps),
          }),
          CellText({
            id: 'priority',
            actions: () => ['copy'],
            Header: 'Priority',
            accessor: 'actionObject.priority',
            readOnly: true,
            width: 50,
          }),
          CellAssetsSelect({
            Header: 'Worker',
            id: 'assignedUser',
            width: 175,
            actions: () => ['copy'],
            accessor: 'actionObject.assignedUserInst',
            assetTypes: ['us'],
            readOnly: true,
            placeholder: 'Assign an user',
          }),
          CellText({
            Header: 'Start Date',
            accessor: 'actionObject.startDate',
            id: 'startDate',
            actions: () => ['copy'],
            width: 150,
            readOnly: true,
            inputProps: {
              type: 'datetime',
            },
          }),
          CellText({
            Header: 'End Date',
            accessor: 'actionObject.endDate',
            readOnly: true,
            actions: () => ['copy'],
            id: 'endDate',
            width: 150,
            inputProps: {
              type: 'datetime',
            },
          }),
        ],
      },
      {
        id: 'takeInfo',
        Header: 'Take info',
        headerColor: 'rgba(125,149,226,0.5)',
        columns: [
          CellDuration({
            Header: 'Duration (estim)',
            actions: () => [],
            accessor: 'actionObject.estimLength',
            id: 'taskEstimLength',
            readOnly: true,
            width: 110,
          }),
          CellRichText({
            Header: 'Brief',
            id: 'comment',
            actions: () => [],
            readOnly: true,
            accessor: 'actionObject.comment',
            width: undefined,
            minWidth: 100,
          }),
          // Medias
          CellMedias({
            Header: 'Medias',
            id: 'medias',
            actions: () => [],
            allowPinMedia: false,
            allowValidateMedia: false,
            readOnly: true,
            accessor: ({ actionObject, actionObjectType }) => {
              if (actionObjectType === 35) return [{ mediaInst: actionObject }]
              return undefined
            },
          }),
        ],
      },
    ]
  }

  render(): React$Node {
    const { assetId, projectId, tableId } = this.props

    return (
      <AssetHistory
        tableId={tableId}
        assetId={assetId}
        resourceType="tasks"
        requestName="history"
        columns={this.columns()}
        includedResources={['assetInst']}
        projectId={projectId}
      />
    )
  }
}

type PipeType = Pipe<{ projectId: ?ID, tableId: string }, typeof TaskHistoryComponent>

const pipeInst: PipeType = pipe()

export const TaskHistory: React$ComponentType<any> = pipeInst
  .enableDeepCompare()
  .connect((state, props) => {
    const { projectId } = props
    let steps

    if (projectId) {
      const stepProjects = getResources(state, 'stepProjects', { project: projectId }, ['stepInst'])
      steps = getSubResources(stepProjects, 'stepInst')
    } else {
      steps = getResources(state, 'steps')
    }

    return {
      steps,
      progressionStatus: getResources(state, 'progressionStatus'),
    }
  })
  .request((props) => resources.progressionStatus.fetchAll())
  .render(TaskHistoryComponent)
