// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellNotesFollowUp-___ExpansionCell-module__container {
  width: 100%;
  position: relative;
  z-index: 1;
}

.app-components-Table-Cells-CellNotesFollowUp-___ExpansionCell-module__expanderBnt {
  transition: transform 150ms ease;
  margin: 5px;
  color: grey;
}

.app-components-Table-Cells-CellNotesFollowUp-___ExpansionCell-module__expanderBntPivoted {
  transform: rotate(90deg);
}

.app-components-Table-Cells-CellNotesFollowUp-___ExpansionCell-module__header {
  border-bottom: 1px solid grey;
  background-color: rgba(0, 0, 0, 0.1);
  height: calc(100% - 25px);
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellNotesFollowUp/ExpansionCell.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gCAAgC;EAChC,WAAW;EACX,WAAW;AACb;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,6BAA6B;EAC7B,oCAAoC;EACpC,yBAAyB;AAC3B","sourcesContent":[".container {\n  width: 100%;\n  position: relative;\n  z-index: 1;\n}\n\n.expanderBnt {\n  transition: transform 150ms ease;\n  margin: 5px;\n  color: grey;\n}\n\n.expanderBntPivoted {\n  transform: rotate(90deg);\n}\n\n.header {\n  border-bottom: 1px solid grey;\n  background-color: rgba(0, 0, 0, 0.1);\n  height: calc(100% - 25px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellNotesFollowUp-___ExpansionCell-module__container`,
	"expanderBnt": `app-components-Table-Cells-CellNotesFollowUp-___ExpansionCell-module__expanderBnt`,
	"expanderBntPivoted": `app-components-Table-Cells-CellNotesFollowUp-___ExpansionCell-module__expanderBntPivoted`,
	"header": `app-components-Table-Cells-CellNotesFollowUp-___ExpansionCell-module__header`
};
export default ___CSS_LOADER_EXPORT___;
