/** @flow */
import React from 'react'
import ModalConfirmForm from 'app/components/Modal/ModalConfirmForm'
import FormData from 'app/components/Form/FormData/FormData'
import type { ModalProps } from 'app/components/Modal/MUIModal.jsx'
import type { SchemaGroup } from 'app/core/types'
import { uuid } from 'app/libs/uuid'

type ModalEditSchemaGroupProps<GS, CS> = {|
  ...$Shape<$Diff<ModalProps, { children: any }>>,
  group?: SchemaGroup<GS, CS>,
  onSave: (group: SchemaGroup<GS, CS>) => Promise<any>,
|}

export function ModalEditSchemaGroup<GS, CS>(props: ModalEditSchemaGroupProps<GS, CS>): React$Node {
  const { group, onSave, ...rest } = props
  const { settings, name = '' } = group || {}

  return (
    <ModalConfirmForm title={group ? `Edit ${group.name}` : 'Add a new group'} draggable={false} {...rest}>
      <FormData
        defaultData={{
          name,
          readOnly: Boolean(settings?.readOnly),
        }}
        properties={[
          {
            key: 'name',
            label: 'Group name',
            type: 'string',
            elementProps: {
              isRequired: true,
            },
          },
          {
            key: 'readOnly',
            label: 'Read only',
            type: 'checkbox',
            elementProps: (data, setData) => {
              return {
                onChange: (value) => setData({ readOnly: value }),
                checked: Boolean(data.readOnly),
              }
            },
          },
        ]}
        onSave={({ name, ...settings }) =>
          onSave({
            items: [],
            ...group,
            key: group?.key || uuid(),
            name,
            settings: {
              ...group?.settings,
              ...settings,
            },
          })
        }
      />
    </ModalConfirmForm>
  )
}
