/** @flow */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import map from 'lodash/map'
import { GoogleCharts } from 'app/libs/googleCharts.js'
import api from 'app/core/api/api'
import { dynamicApprovalStatus, dynamicApprovalColors } from 'app/core/constants/dynamicApprovalStatus'
import type { MenuAction, ID, DateDay } from 'app/core/types'
import type { TableColumn, TableInstance } from 'app/components/Table/types.js'
import { openModal, MUIModal, type ModalProps } from 'app/components/Modal'
import Loader from 'app/components/Loader/Loader.jsx'
import { permission } from 'app/containers/Permissions'

type Entry = $Exact<{ [dynAppKey: $Keys<typeof dynamicApprovalStatus>]: number }>
type Values = $Exact<{ [date: DateDay]: Entry }>

function StatusProgress(
  props: $Exact<{ ...$Rest<ModalProps, { children: React$Node }>, instance: TableInstance, column: TableColumn }>,
) {
  const { column, onRequestClose, instance } = props
  const { id: dynamicApproval, parentId: rootAsset }: { id: ID, parentId: ID } = column.extends || {}
  const { totalRowsCount } = instance

  const [values, setValues] = useState<Values | void>()
  const [loading, setLoading] = useState<boolean>(false)

  const chartRef = useRef()

  useEffect(() => {
    if (rootAsset && dynamicApproval) {
      setLoading(true)
      api
        .dynamicApprovalStatusEvolution({
          rootAsset,
          dynamicApproval,
        })
        .then((res) => {
          setLoading(false)
          setValues(res)
        })
        .catch((err) => {
          console.error(err)
          onRequestClose?.()
        })
    }
  }, [rootAsset, dynamicApproval])

  function drawChart() {
    const dashboard = new GoogleCharts.api.visualization.Dashboard(chartRef.current)

    const data = GoogleCharts.api.visualization.arrayToDataTable([
      ['Date', ...Object.values(dynamicApprovalStatus), 'Total (no dynamic approval)'],
      ...map(values, (value, date: string) => [
        new Date(date),
        ...Object.keys(dynamicApprovalStatus).map((dynAppKey) => value[dynAppKey]),
        totalRowsCount,
      ]),
    ])

    const rangeSlider = new GoogleCharts.api.visualization.ControlWrapper({
      controlType: 'ChartRangeFilter',
      containerId: 'filter_div',
      options: {
        filterColumnLabel: 'Date',
        ui: {
          chartType: 'AreaChart',
          chartOptions: {
            isStacked: true,
            series: {
              '9': {
                type: 'line',
                color: '#0000FF',
                areaOpacity: 0,
                visibleInLegend: false,
                enableInteractivity: false,
                lineWidth: 2,
                lineDashStyle: [4, 4],
              },
            },
          },
        },
      },
    })

    const options = {
      colors: Object.keys(dynamicApprovalStatus).map((key) => dynamicApprovalColors[key]),
      title: '',
      tooltip: {
        isHtml: true,
      },
      vAxis: {
        title: 'Dynamic approval status',
      },
      focusTarget: 'category',
      isStacked: true,
      series: {
        '9': {
          type: 'line',
          color: '#0000FF',
          areaOpacity: 0,
          visibleInLegend: false,
          enableInteractivity: false,
          lineWidth: 2,
          lineDashStyle: [4, 4],
        },
      },
    }

    // Create a pie chart, passing some options
    const chart = new GoogleCharts.api.visualization.ChartWrapper({
      chartType: 'SteppedAreaChart',
      containerId: 'chart_div',
      options,
    })

    dashboard.bind(rangeSlider, chart)
    dashboard.draw(data)
  }

  useEffect(() => {
    if (!loading) GoogleCharts.load(drawChart)
  }, [loading])

  const onResize = useCallback(() => {
    if (!loading && Object.keys(values || {}).length !== 0) GoogleCharts.load(drawChart)
  }, [])

  const { ref } = useResizeDetector({
    refreshMode: 'throttle',
    refreshRate: 200,
    targetRef: chartRef,
    onResize,
  })

  return (
    <MUIModal
      key={String(loading)}
      title="Progression"
      width={1050}
      minHeight={700}
      resizable={true}
      draggable={true}
      onRequestClose={onRequestClose}
      childreContainerStyle={{ overflow: 'hidden' }}
      hideCancel={true}
      validateLabel="Close"
      validateLabelColor="#4a4a4a"
    >
      <>
        {loading ? (
          <div className="flex fullWidth center alignCenter" style={{ height: 300 }}>
            <Loader />
          </div>
        ) : Object.keys(values || {}).length === 0 ? (
          <div className="fullWidth flex center alignCenter lightgrey" style={{ height: '250px' }}>
            No data found
          </div>
        ) : null}
        <div
          ref={(_ref) => {
            chartRef.current = _ref
            ref.current = _ref
          }}
          style={{ width: '100%', height: '100%' }}
        >
          <div id="chart_div" style={{ height: 'calc(100% - 50px)' }} />
          <div id="filter_div" style={{ height: 50 }} />
        </div>
      </>
    </MUIModal>
  )
}

export const dynamicApprovalHeaderActions = (instance: TableInstance, column: TableColumn): Array<MenuAction> => {
  if (!permission(['projet_follow-up__show dynamic approval progression'])) return []

  return [
    { separator: true },
    {
      editAction: false,
      label: 'Show progression',
      onClick: (e) => {
        openModal(<StatusProgress instance={instance} column={column} />)
      },
    },
  ]
}
