// @flow

import type { StoreResourceDef } from 'app/core/types'

export const articleMedias: StoreResourceDef<> = {
  resourceType: 'articleMedias',
  relations: {
    mediaInst: {
      type: 'hasOne',
      resourceType: 'medias',
      foreignKey: 'media',
    },
  },
}
