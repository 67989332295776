/** @flow */
import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import cx from 'classnames'
import { MUIModal } from 'app/components/Modal/MUIModal.jsx'
import { MediaItem } from 'app/components/Medias/MediaItem/MediaItem.tsx'
import resources from 'app/store/resources'
import { validExtensions } from 'app/components/Medias/MediaItem/fileExtensions.js'
import classes from './UploadFilesModal.module.scss'
import { error } from '../Notifications/notify'
import type { Media } from '../../core/types/Media'
import type { Thumbnail } from '../../core/types/Thumbnail'

type Props = {|
  onValidate: Function,
  onRequestClose?: Function,
  position?: *,
  openModal?: boolean,
  uploadOnValidate?: boolean,
  title?: string,
  allowPinMedia: boolean,
  allowValidateMedia: boolean,
|}

export type TSUploadFilesPreviewsProps = {
  files: [],
  setFiles: () => void,
  filename?: string,
  overflow?: boolean,
  noThumbs?: boolean,

  /** react-dropzone types */
  accept?: string,
  disabled?: boolean,
  getFilesFromEvent?: () => void,
  maxSize?: number,
  minSize?: number,
  multiple?: boolean,
  noClick?: boolean,
  noDrag?: boolean,
  noDragEventsBubbling?: boolean,
  noKeyboard?: boolean,
  onDragEnter?: () => void,
  onDragLeave?: () => void,
  onDragOver?: () => void,
  onDrop?: () => void,
  onDropAccepted?: () => void,
  onDropRejected?: () => void,
  onFileDialogCancel?: () => void,
  preventDropOnDocument?: boolean,
  isRequired?: boolean,
  validators?: {},
  allowPinMedia: boolean,
  allowValidateMedia: boolean,
  setMedia?: React.Dispatch<React.SetStateAction<Media | Thumbnail | undefined>>,
}

export type UploadFilesPreviewsProps = {|
  files: Array<*>,
  setFiles: Function,
  filename?: string,
  overflow?: boolean,
  noThumbs?: boolean,

  /** react-dropzone types */
  accept?: string,
  disabled?: boolean,
  getFilesFromEvent?: Function,
  maxSize?: number,
  minSize?: number,
  multiple?: boolean,
  noClick?: boolean,
  noDrag?: boolean,
  noDragEventsBubbling?: boolean,
  noKeyboard?: boolean,
  onDragEnter?: Function,
  onDragLeave?: Function,
  onDragOver?: Function,
  onDrop?: Function,
  onDropAccepted?: Function,
  onDropRejected?: Function,
  onFileDialogCancel?: Function,
  preventDropOnDocument?: boolean,
  isRequired?: boolean,
  validators?: Object,
  allowPinMedia: boolean,
  allowValidateMedia: boolean,
  setMedia?: Function,
|}

export function UploadFilesPreviews(props: UploadFilesPreviewsProps): React$Node {
  const {
    files = [],
    setFiles,
    filename,
    noThumbs,
    overflow = true,
    accept = validExtensions.join(', '),
    isRequired,
    multiple = true,
    maxSize = 50 * 1000000,
    minSize,
    validators,
    allowPinMedia,
    allowValidateMedia,
    setMedia,
    ...rest
  } = props
  const [errors, setErrors] = useState([])

  const { getRootProps, getInputProps, rejectedFiles, isDragReject } = useDropzone({
    accept,
    multiple,
    maxSize,
    minSize,
    onDrop: (acceptedFiles = []) => {
      acceptedFiles.forEach((file) => {
        file.url = URL.createObjectURL(file)
      })
      const newFiles = (multiple ? files : []).concat(acceptedFiles)
      setFiles(newFiles)
    },
    ...rest,
  })

  useEffect(() => {
    if (!multiple && rejectedFiles?.length > 1) {
      setErrors(['Please select a single file.'])
      error('Please select a single file.')
    } else if (rejectedFiles) {
      setErrors(
        (rejectedFiles || []).map((file) => {
          const message = `${file.path} - ${
            file.size > maxSize
              ? 'The file size is too large.'
              : file.size < minSize
              ? 'The file size is too small.'
              : 'Wrong format'
          }`
          error(message)
          return message
        }),
      )
    }
  }, [rejectedFiles])

  async function deleteFile(file) {
    await resources.medias.delete(file.id)
    setMedia && setMedia(null)
  }

  async function removeFile(file, index) {
    if (file?.id) await deleteFile(file)
    const newArray = [...files]
    newArray.splice(index, 1)
    setFiles(newArray)
  }

  const thumbs = files.map((file, index) => (
    <MediaItem
      key={file.url}
      media={file}
      onDelete={() => {
        return removeFile(file, index)
      }}
      allowPinMedia={allowPinMedia}
      allowValidateMedia={allowValidateMedia}
    />
  ))

  return (
    <div className={classes.container}>
      <div className={classes.dropzoneContainer}>
        <div className="flex column">
          <div {...getRootProps()} className={cx(classes.dropzone, isDragReject && classes.dropzoneError)}>
            <input {...getInputProps()} data-cy="uploadFile" />
            {multiple === false ? (
              <p className="textCenter">Click to select a file or drag n drop a file here</p>
            ) : (
              <p className="textCenter">Click to select files or drag n drop some files here</p>
            )}
          </div>
          <div>
            {errors.length > 0 ? (
              <ul>
                {errors.map((err, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index} className={classes.error}>
                    {err}
                  </li>
                ))}
              </ul>
            ) : undefined}
          </div>
        </div>
      </div>
      {!noThumbs && thumbs.length > 0 ? (
        <div className={classes.filezoneContainer}>
          <div className={classes.filezone} style={{ overflowY: overflow ? 'auto' : 'none' }}>
            <div className={classes.scrollzone}>{thumbs}</div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export function UploadFilesModal(props: Props): React$Node {
  const {
    onValidate,
    position,
    onRequestClose,
    uploadOnValidate,
    openModal: defaultOpenModal,
    title = 'Files to upload',
    allowPinMedia,
    allowValidateMedia,
    ...rest
  } = props
  const [files, setFiles] = useState([])

  const [openModal, setOpenModal] = useState(defaultOpenModal !== false)

  async function _onValidate() {
    let filesOutput = files

    setOpenModal(false)
    if (onRequestClose) onRequestClose()

    if (uploadOnValidate) {
      const res = await Promise.all(files.map((file) => resources.medias.upload({ files: file })))
      filesOutput = res.map((result) => result.resources[0])
    }

    return onValidate(filesOutput, position)
  }

  useEffect(() => {
    setOpenModal(defaultOpenModal)
  }, [defaultOpenModal])

  return (
    <MUIModal
      title={title}
      onValidate={_onValidate}
      onRequestClose={onRequestClose}
      onExited={onRequestClose}
      open={openModal}
      resizable={true}
      {...rest}
    >
      <UploadFilesPreviews
        files={files}
        setFiles={setFiles}
        allowPinMedia={allowPinMedia}
        allowValidateMedia={allowValidateMedia}
      />
    </MUIModal>
  )
}
