/** @flow */
import React, { useRef } from 'react'
import moment from 'moment'
import keyCode from 'app/libs/keyCode'

import type { CellInstance, TimelineEditionItems } from '../../types'
import { TimelineResizer } from './TimelineResizer'

type Params = {|
  instance: CellInstance,
  startDate: ?moment,
  endDate: ?moment,
  timelineEditionItems: ?TimelineEditionItems,
  onPositionsChange: (leftDiff: number, widthDiff: number) => void,
  onUpdate: ({
    startDate?: ?moment,
    endDate?: ?moment,
    earliestStartDate?: ?moment,
    latestEndDate?: ?moment,
  }) => Promise<any>,
  leftTootlip?: string,
  rightTootlip?: string,
  leftDisabled?: boolean,
  rightDisabled?: boolean,
  disabled?: boolean,
|}

export const useCellTimelineEdition = (
  params: Params,
): { onMove: Function, leftResizer: React$Node, rightResizer: React$Node } => {
  const {
    instance,
    startDate,
    endDate,
    timelineEditionItems,
    onUpdate,
    onPositionsChange,
    leftTootlip,
    rightTootlip,
    leftDisabled,
    rightDisabled,
    disabled,
  } = params

  const {
    dimensions: { dayWidth = 0 } = {},
    row,
    pagination: { pageSize },
  } = instance

  const rangeDimensions = useRef({ originX: 0, vector: 0 })

  function resetRangeDimensions() {
    rangeDimensions.current = { originX: 0, vector: 0 }
  }

  function getDatesFromPositions(left: number, width: number) {
    const daysLeft = Math.round(left / dayWidth)
    const daysWidth = Math.round(width / dayWidth)
    const difference = endDate?.diff(moment(startDate), 'days') || 0
    const newStartDate = moment(startDate).add(daysLeft, 'days')
    const newEndDate = moment(newStartDate).add(difference + daysWidth, 'days')

    return { startDate: newStartDate, endDate: newEndDate }
  }

  function changeDomPosition(leftDiff, widthDiff) {
    onPositionsChange(leftDiff, widthDiff)
  }

  async function onSave(leftDiff: number, widthDiff: number) {
    resetRangeDimensions()

    if (Math.abs(leftDiff) < dayWidth / 2) leftDiff = 0
    if (Math.abs(widthDiff) < dayWidth / 2) widthDiff = 0

    if (leftDiff === 0 && widthDiff === 0) {
      changeDomPosition(0, 0)
      return Promise.resolve()
    }

    const dates = getDatesFromPositions(leftDiff, widthDiff)

    return onUpdate(dates).catch(() => changeDomPosition(0, 0))
  }

  function move(e: MouseEvent) {
    const { originX } = rangeDimensions.current
    const vector = e.clientX - originX
    rangeDimensions.current.vector = vector
    changeDomPosition(vector, 0)
  }

  function stopMove(e: MouseEvent) {
    window.removeEventListener('mousemove', move)
    window.removeEventListener('mouseup', stopMove)

    const { vector } = rangeDimensions.current

    if (vector === 0) {
      resetRangeDimensions()
      return
    }
    onSave(vector, 0)
  }

  function cancel(e: KeyboardEvent) {
    if (e.keyCode === keyCode.ESCAPE) {
      resetRangeDimensions()
      changeDomPosition(0, 0)
      window.removeEventListener('mousemove', move)
      window.removeEventListener('mouseup', stopMove)
      window.removeEventListener('keydown', cancel)
    }
  }

  function onMouseDown(e: SyntheticMouseEvent<any>) {
    e.stopPropagation()

    if (disabled || leftDisabled || rightDisabled) return
    if (e.button !== 0) return

    resetRangeDimensions()
    rangeDimensions.current.originX = e.clientX

    window.addEventListener('mousemove', move)
    window.addEventListener('mouseup', stopMove)
    window.addEventListener('keydown', cancel)
  }

  function onLengthResize(diff: number, position: 'left' | 'right') {
    if (position === 'left') changeDomPosition(diff, -diff)
    if (position === 'right') changeDomPosition(0, diff)
  }

  function onLengthStopResize(diff: number, position: 'left' | 'right') {
    if (diff === 0) return
    if (position === 'left') onSave(diff, -diff)
    if (position === 'right') onSave(0, diff)
  }

  const leftResizer =
    timelineEditionItems?.start && startDate ? (
      <TimelineResizer
        onResize={(diff) => onLengthResize(diff, 'left')}
        onStopResize={(diff) => onLengthStopResize(diff, 'left')}
        position="left"
        rangeDimensions={rangeDimensions}
        resetRangeDimensions={resetRangeDimensions}
        tooltip={leftTootlip}
        disabled={leftDisabled || disabled || timelineEditionItems.start.readOnly}
        fixFirstRow={row.index === 0}
      />
    ) : null

  const rightResizer =
    timelineEditionItems?.end && endDate ? (
      <TimelineResizer
        onResize={(diff) => onLengthResize(diff, 'right')}
        onStopResize={(diff) => onLengthStopResize(diff, 'right')}
        position="right"
        rangeDimensions={rangeDimensions}
        resetRangeDimensions={resetRangeDimensions}
        tooltip={rightTootlip}
        disabled={rightDisabled || disabled || timelineEditionItems.end.readOnly}
        fixLastRow={row.index === pageSize - 1}
      />
    ) : null

  return {
    onMove: onMouseDown,
    leftResizer,
    rightResizer,
  }
}
