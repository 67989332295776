// @flow
import find from 'lodash/find'
import filter from 'lodash/filter'
import * as resources from 'app/store/resources/resources'

export const getUpdatedRelations = (
  relationConfig: any,
  currentRelationResources: Object,
  nextRelationResources: Object,
): { relationsToCreate: Array<any>, relationsToDelete: Array<any> } => {
  const { resourceType } = relationConfig
  const relationModelConfig = resources[resourceType]

  const relationModelConfigRelations = filter(relationModelConfig.relations, (relation) => {
    return relation.foreignKey !== relationModelConfig.foreignKey
  })

  if (relationModelConfigRelations.length > 1) {
    throw new Error('Relation resourceType with 3 foreign key not currently allowed')
  }

  const relationsToCreate: Array<*> = filter(
    nextRelationResources,
    (resource) =>
      !resource.id &&
      !find(
        currentRelationResources,
        (currentRelationResource) =>
          currentRelationResource[relationConfig.foreignKey] === resource[relationConfig.foreignKey] &&
          currentRelationResource[relationModelConfigRelations[0].foreignKey] ===
            resource[relationModelConfigRelations[0].foreignKey],
      ),
  )

  const relationsToDelete: Array<*> = filter(currentRelationResources, (currentRelationResource: { id: string }) => {
    const noLongerExist = !find(
      nextRelationResources,
      (nextRelationResource) =>
        nextRelationResource[relationConfig.foreignKey] === currentRelationResource[relationConfig.foreignKey] &&
        nextRelationResource[relationModelConfigRelations[0].foreignKey] ===
          currentRelationResource[relationModelConfigRelations[0].foreignKey],
    )

    return noLongerExist
  }).map(({ id }) => id)

  return { relationsToCreate, relationsToDelete }
}
