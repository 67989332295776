// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-main-Authenticated-___Authenticated-module__appLayout {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: 100%;
}

.app-main-Authenticated-___Authenticated-module__pageContainer {
  position: relative;
  flex: 1;
  z-index: 1;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./app/main/Authenticated/Authenticated.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;EACP,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".appLayout {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  overflow: hidden;\n  width: 100%;\n}\n\n.pageContainer {\n  position: relative;\n  flex: 1;\n  z-index: 1;\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appLayout": `app-main-Authenticated-___Authenticated-module__appLayout`,
	"pageContainer": `app-main-Authenticated-___Authenticated-module__pageContainer`
};
export default ___CSS_LOADER_EXPORT___;
