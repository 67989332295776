// @flow

import * as React from 'react'
import cx from 'classnames'
import { Duration as DurationHelper, dateCreateAtMidnight } from 'app/libs/helpers'
import type { CellDurationTotalProps } from './CellDurationTotal.type'
import classes from './CellDurationTotal.module.scss'

export class CellDurationTotal extends React.PureComponent<CellDurationTotalProps> {
  render(): React$Node {
    const { duration, dateISO } = this.props
    let finalDuration = duration

    const date = new Date(dateISO)
    const isFutureDay = date > dateCreateAtMidnight()
    const isFriday = date.getDay() === 5
    const hoursPerDay = isFriday ? 7 : 8
    const durationInst = new DurationHelper(finalDuration)
    const durationIsOverLimit = durationInst.isGratherThan({ hours: hoursPerDay })
    const durationData = durationInst.getData(['hours', 'minutes'])
    let minutes = Math.round(durationData.minutes)
    let { hours } = durationData

    if (hours > hoursPerDay) {
      hours = hoursPerDay
      minutes = 0
    }

    if (durationIsOverLimit) {
      finalDuration = new DurationHelper({ hours: hoursPerDay }).getTime()
    }

    const className = cx({
      [classes.noDuration]: !finalDuration,
      [classes.totalPerDay]: finalDuration,
      [classes.noTotalPerDay]: !finalDuration && !isFutureDay,
      [classes.totalPerDayOverLimit]: durationIsOverLimit,
    })

    return (
      <div className={className} style={{ display: 'flex', justifyContent: 'center' }}>
        {hours}h {minutes}m
      </div>
    )
  }
}
