// @flow
import React from 'react'
import { Container, LeftContent, RightContent } from 'app/components/LeftNav'
import { Switch, Route } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import Nav from './Nav.jsx'
import NotificationsList from './NotificationsList/NotificationsList.jsx'
import ManageSubscriptions from './ManageSubscriptions/ManageSubscriptions.jsx'

type Props = {|
  match: Match,
|}

export default function Notifications(props: Props): React$Node {
  const { match } = props

  return (
    <Container>
      <LeftContent>{(isOpen) => <Nav match={match} />}</LeftContent>
      <RightContent>
        <Switch>
          <Route exact={true} path={`${match.url}/all`} component={() => <NotificationsList />} />
          <Route exact={true} path={`${match.url}/manage`} component={() => <ManageSubscriptions />} />
        </Switch>
      </RightContent>
    </Container>
  )
}
