/** @flow */
export function assertNonNull<T>(value: T | null | void): T {
  if (value == null) {
    throw new Error('Unexpected null value')
  }
  return value
}

export function first<T>(array: T[]): T | void {
  return array[0]
}
