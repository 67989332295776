// @flow
import type { LogicOperator } from 'app/core/types'

export const logicOperators: { [opName: string]: LogicOperator } = {
  '|': {
    type: 'logicOperator',
    value: '|',
    label: 'or',
  },
}
