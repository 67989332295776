/* eslint-disable global-require */
/** @flow */
import { forEach } from 'lodash'
import type { TrackingSchema } from 'app/core/types'
import type { HookFunction } from './types'
import { uuid } from '../../libs/uuid'

export async function verifyTrackingSchemaGroupKey(
  data: TrackingSchema,
  getResourcesAPI: Function,
): Promise<TrackingSchema> {
  let trackingSchemaShouldBeUpdated = false

  data.schema.forEach((schemaGroup) => {
    if (!schemaGroup.key || schemaGroup.key === 'undefined') trackingSchemaShouldBeUpdated = true
  })

  if (trackingSchemaShouldBeUpdated) {
    const resourceAPI = getResourcesAPI()

    const trackingSchema = { ...data }

    trackingSchema.schema.forEach((trackingSchemaGroup, index) => {
      if (!trackingSchemaGroup.key || trackingSchemaGroup.key === 'undefined') trackingSchemaGroup.key = uuid()
    })
    return resourceAPI.trackingSchemas.update(trackingSchema)
  }

  return Promise.resolve(data)
}

export async function verifyArrayTrackingSchemaGroupKey(
  data: Array<TrackingSchema>,
  getResourcesAPI: Function,
): Promise<Array<TrackingSchema>> {
  const trackingSchemaToUpdate = []

  data.forEach((trackingSchema) => {
    let trackingSchemaShouldBeUpdated = false
    forEach(trackingSchema.schema, (schemaGroup) => {
      if (!schemaGroup.key || schemaGroup.key === 'undefined') trackingSchemaShouldBeUpdated = true
    })
    if (trackingSchemaShouldBeUpdated) {
      const newTrackingSchema = { ...trackingSchema }
      newTrackingSchema.schema.forEach((trackingSchemaGroup, index) => {
        if (!trackingSchemaGroup.key || trackingSchemaGroup.key === 'undefined') trackingSchemaGroup.key = uuid()
      })
      trackingSchemaToUpdate.push(newTrackingSchema)
    }
  })

  if (trackingSchemaToUpdate.length) {
    const resourceAPI = getResourcesAPI()
    return resourceAPI.trackingSchemas.update(trackingSchemaToUpdate).then((res) => {
      return data.map((ts) => {
        const index = res.findIndex((t) => t.id === ts.id)
        if (index > -1) return res[index]
        return ts
      })
    })
  }

  return Promise.resolve(data)
}

export const afterGet: HookFunction<TrackingSchema> = (getResourcesAPI, getResources) => (data) => {
  return verifyTrackingSchemaGroupKey(data, getResourcesAPI)
}

export const afterGetAll: HookFunction<Array<TrackingSchema>> = (getResourcesAPI, getResources) => (data) => {
  return verifyArrayTrackingSchemaGroupKey(data, getResourcesAPI)
}
