// @flow
import * as React from 'react'
import type { ElementProps } from 'app/core/types'

type Props = {|
  ...ElementProps,
  width?: number | string,
  minWidth?: number,
  open: boolean,
  noHiddenOverflow?: boolean,
  children: any,
  className?: string,
  style?: Object,
  useTransition?: boolean,
|}

export function HorizontalCollapse(props: Props): React$Node {
  const {
    children,
    width = 300,
    minWidth = 0,
    open = true,
    noHiddenOverflow = false,
    className,
    useTransition,
    ...rest
  } = props

  return (
    <div
      style={{
        height: '100%',
        overflow: noHiddenOverflow ? 'visible' : 'hidden',
        width: open ? width : minWidth,
        transition: useTransition ? 'width 100ms ease-out' : undefined,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}
