// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Wiki-WikiEditor-___History-module__history {
  min-width: 500px;
  height: 70vh;
  overflow: hidden;
}

.app-containers-Wiki-WikiEditor-___History-module__item {
  padding: 10px 0 10px 10px;
  display: flex;
  align-items: center;
}

.app-containers-Wiki-WikiEditor-___History-module__item:not(:last-child) {
    border-bottom: var(--mainBorder);
  }

.app-containers-Wiki-WikiEditor-___History-module__center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: lightgrey;
}

.app-containers-Wiki-WikiEditor-___History-module__version-number,
.app-containers-Wiki-WikiEditor-___History-module__date,
.app-containers-Wiki-WikiEditor-___History-module__author {
  margin: 0 10px;
}

.app-containers-Wiki-WikiEditor-___History-module__versionNumber {
  flex-basis: 100px;
}

.app-containers-Wiki-WikiEditor-___History-module__date {
  flex-basis: 150px;
}

.app-containers-Wiki-WikiEditor-___History-module__author {
  flex-basis: 140px;
}

.app-containers-Wiki-WikiEditor-___History-module__pagination {
  display: table;
  margin: 0 auto;
  padding-top: 20px;
}

.app-containers-Wiki-WikiEditor-___History-module__overflow {
  overflow: auto;
  max-height: calc(100% - 60px);
}
`, "",{"version":3,"sources":["webpack://./app/containers/Wiki/WikiEditor/History.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AAIrB;;AAHE;IACE,gCAAgC;EAClC;;AAGF;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;;;EAGE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,6BAA6B;AAC/B","sourcesContent":[".history {\n  min-width: 500px;\n  height: 70vh;\n  overflow: hidden;\n}\n\n.item {\n  padding: 10px 0 10px 10px;\n  display: flex;\n  align-items: center;\n  &:not(:last-child) {\n    border-bottom: var(--mainBorder);\n  }\n}\n\n.center {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  user-select: none;\n  color: lightgrey;\n}\n\n.version-number,\n.date,\n.author {\n  margin: 0 10px;\n}\n\n.versionNumber {\n  flex-basis: 100px;\n}\n\n.date {\n  flex-basis: 150px;\n}\n\n.author {\n  flex-basis: 140px;\n}\n\n.pagination {\n  display: table;\n  margin: 0 auto;\n  padding-top: 20px;\n}\n\n.overflow {\n  overflow: auto;\n  max-height: calc(100% - 60px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history": `app-containers-Wiki-WikiEditor-___History-module__history`,
	"item": `app-containers-Wiki-WikiEditor-___History-module__item`,
	"center": `app-containers-Wiki-WikiEditor-___History-module__center`,
	"version-number": `app-containers-Wiki-WikiEditor-___History-module__version-number`,
	"date": `app-containers-Wiki-WikiEditor-___History-module__date`,
	"author": `app-containers-Wiki-WikiEditor-___History-module__author`,
	"versionNumber": `app-containers-Wiki-WikiEditor-___History-module__versionNumber`,
	"pagination": `app-containers-Wiki-WikiEditor-___History-module__pagination`,
	"overflow": `app-containers-Wiki-WikiEditor-___History-module__overflow`
};
export default ___CSS_LOADER_EXPORT___;
