/** @flow */
import React, { useCallback } from 'react'
import { find } from 'lodash'
import type { History, Match, Location, Pipe, ID } from 'app/core/types'
import { Wiki as WikiComponent } from 'app/containers/Wiki/Wiki.jsx'
import pipe from 'app/core/utils/pipe'
import resources from '../../store/resources'

type Props = {|
  history: History,
  match: Match,
  location: Location,
  rootAssetId: ?ID,
|}

export function WikiView(props: Props): React$Node {
  const { history, match, location, rootAssetId } = props
  const formatRoute = useCallback(
    (projectId, assetId, artcileVersionId, search) =>
      `/assets-wiki${assetId ? `/${assetId}` : ''}${artcileVersionId ? `/${artcileVersionId}` : ''}${search || ''}`,
    [],
  )

  return (
    <WikiComponent
      formatRoute={formatRoute}
      rootAssetId={rootAssetId || ''}
      history={history}
      match={match}
      location={location}
    />
  )
}

const pipeInst: Pipe<{}, typeof WikiView> = pipe()

export const Wiki: React$AbstractComponent<any, any> = pipeInst
  .connect((state, props) => {
    const assetRoot = find(state.assets.resources, { name: 'root' })
    return {
      rootAssetId: assetRoot?.id,
    }
  })
  .request(() => resources.assets.fetchRoot())
  .render(WikiView)
