/** @flow */
import React, { useState } from 'react'
import cx from 'classnames'
import Paper from '@material-ui/core/Paper'
import { EditableInput } from 'react-color/lib/components/common'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import htmlColors from 'app/styles/htmlColors.js'

import classes from './ColorPicker.module.scss'

type Color = string | { color: string, title: string }

export type ColorPickerProps = {|
  value?: string,
  colors?: Array<Color | Array<Color>>,
  onChange: (color: string) => Promise<any>,
|}

export function ColorPicker(props: ColorPickerProps): React$Node {
  const { colors = htmlColors, value, onChange: _onChange } = props

  const [isLoading, setIsLoading] = useState(false)

  function onChange(color: string) {
    setIsLoading(true)

    return _onChange(color)?.finally(() => {
      setIsLoading(false)
    })
  }

  function colorRound(color: ?string, title?: string, showSelected?: boolean = true) {
    if (!color) return null
    return (
      <div
        key={`${color}-${title || ''}`}
        style={{ backgroundColor: color }}
        className={cx(classes.colorRound, { [classes.selected]: showSelected && color === value })}
        title={title}
        onClick={() => onChange(color)}
      />
    )
  }

  function mapColors(items: Array<Color | Array<Color>>) {
    return items.map((item) => {
      if (typeof item === 'string') return colorRound(item)
      // $FlowFixMe
      if (Array.isArray(item)) return mapColors(item)
      if (typeof item === 'object') return colorRound(item.color, item.title)
      return null
    })
  }

  return (
    <Paper style={{ padding: '8px 0px 8px 8px', width: 205 }}>
      <div className={classes.scrollingContainer}>
        <div className="fullWidth fullHeight flex row wrap">{mapColors(colors)}</div>
      </div>
      <div className="fullWidth flex alignCenter" style={{ margin: '10px 0px 0px 0px' }}>
        <EditableInput
          style={{
            input: {
              border: '1px solid rgba(0,0,0,0.1)',
              fontSize: '14px',
              padding: '3px 6px',
              outline: 'none',
              marginRight: 5,
            },
          }}
          value={value}
          onChange={(color) => {
            // $FlowFixMe
            onChange(color)
          }}
        />
        {isLoading ? <LoaderSmall color="grey" /> : colorRound(value, undefined, false)}
      </div>
    </Paper>
  )
}
