// @flow

import type { PeopleActions } from 'app/core/types/StoreResourcesCustomActions'
import type { StoreResourceDef } from 'app/core/types'
import { autocomplete } from './autocomplete.js'

export const people: StoreResourceDef<PeopleActions<>> = {
  resourceType: 'people',
  actions: (getResources) => ({
    search: autocomplete('person'),
  }),
  relations: {
    thumbnailInst: {
      type: 'hasOne',
      resourceType: 'medias',
      foreignKey: 'thumbnail',
    },
    contractsInst: {
      type: 'hasMany',
      resourceType: 'contracts',
      foreignKey: 'person',
    },
    attributesInst: {
      type: 'hasMany',
      resourceType: 'personAttributeValues',
      foreignKey: 'person',
    },
  },
}
