// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-ColorPicker-___ColorPicker-module__scrollingContainer {
  overflow-y: auto;
  max-height: 200px;
}

.app-components-Form-ColorPicker-___ColorPicker-module__colorRound {
  border-radius: 3px;
  width: 30px;
  height: 20px;
  border: 1px solid white;
}

.app-components-Form-ColorPicker-___ColorPicker-module__selected,
.app-components-Form-ColorPicker-___ColorPicker-module__colorRound:hover {
  border-width: 0px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/ColorPicker/ColorPicker.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;;AAEA;;EAEE,iBAAiB;AACnB","sourcesContent":[".scrollingContainer {\n  overflow-y: auto;\n  max-height: 200px;\n}\n\n.colorRound {\n  border-radius: 3px;\n  width: 30px;\n  height: 20px;\n  border: 1px solid white;\n}\n\n.selected,\n.colorRound:hover {\n  border-width: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollingContainer": `app-components-Form-ColorPicker-___ColorPicker-module__scrollingContainer`,
	"colorRound": `app-components-Form-ColorPicker-___ColorPicker-module__colorRound`,
	"selected": `app-components-Form-ColorPicker-___ColorPicker-module__selected`
};
export default ___CSS_LOADER_EXPORT___;
