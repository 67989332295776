/** @flow */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { map } from 'lodash'
import InputBase from '@material-ui/core/InputBase'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'
import debounce from 'lodash/debounce'
import vars from 'app/styles/vars.js'
import keyCode from 'app/libs/keyCode'
import { targetIsChild } from 'app/libs/helpers/dom'
import { getUserSettings } from 'app/libs/helpers/userSettings'
import resources from 'app/store/resources/index.js'
import type { SearchResults, ResourcesList, ProgressionStatus, ID } from 'app/core/types'
import api from 'app/core/api/api'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { getResources } from 'app/store/selectors/getResources.js'
import { permission } from 'app/containers/Permissions/index.js'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'

import { SearchingResults } from './SearchingResults.jsx'

type Props = {||}

const useStyles: (Object) => { [key: string]: string } = makeStyles(() => ({
  adornedEnd: ({ isFocus }) => ({
    color: isFocus ? '#4a4a4a' : 'grey',
    padding: '0 5px',
  }),
  input: ({ isFocus }) => ({
    pointerEvents: !isFocus ? 'none' : 'all',
    cursor: !isFocus ? 'pointer' : 'initial',
  }),
}))

export function SearchingBar(props: Props): React$Node {
  const [anchorEl, setAnchorEl] = useState<void | HTMLElement>()
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [isSelectOpened, setIsSelectOpened] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchIn, setSearchIn] = useState<Array<string>>(getUserSettings(`searchingBar-searchIn`) || undefined)
  const [selectedProjects, setSelectedProjects] = useState<Array<ID>>(getUserSettings(`searchingBar-project`) || [])
  const [value, setValue] = useState<string>('')
  const [results, setResults] = useState<SearchResults | void>()

  const containerRef = useRef()
  const inputRef = useRef<null | void | HTMLInputElement>()

  const classes = useStyles({ isFocus })

  const [progressionStatus, setProgressionStatus] = useState<ResourcesList<ProgressionStatus>>({})

  const projects = useSelector((state) => {
    return map(state.user.projects, (project) => getResources(state, 'assets', project, ['thumbnailInst'])).filter(
      (_) => _,
    )
  })

  function onChange(e: SyntheticInputEvent<HTMLInputElement>) {
    setValue(e.target.value)
  }

  function onFocus(e: SyntheticFocusEvent<HTMLInputElement>) {
    if (isFocus) return
    setIsFocus(true)
    // inputRef.current?.select()
  }

  function onBlur(e: SyntheticFocusEvent<HTMLInputElement>) {
    if (targetIsChild(e)) return
    setIsFocus(false)
  }

  function globalKeydown(e: KeyboardEvent) {
    if (e.keyCode === keyCode.F && e.shiftKey && (e.ctrlKey || e.metaKey)) {
      e.preventDefault()
      inputRef.current?.select()
    }
  }

  function apiSearch(value: string) {
    setIsLoading(true)
    api
      .search(
        {
          query: value,
          scope: searchIn?.length ? searchIn : undefined,
          projects: selectedProjects.length ? selectedProjects : undefined,
        },
        undefined,
        { [window.OVM_PROJECT_HEADER]: null },
      )
      .then((res) => {
        setIsLoading(false)
        setResults(res)
      })
  }

  const search = useCallback(debounce(apiSearch, 500), [searchIn, selectedProjects])

  useEffect(() => {
    if (value) search(value)
    if (!value) setResults()
  }, [value, searchIn, selectedProjects])

  useEffect(() => {
    if (value && (isFocus || isSelectOpened)) {
      setAnchorEl(containerRef.current || undefined)
    } else {
      setAnchorEl()
    }
  }, [value, isFocus, isSelectOpened])

  useEffect(() => {
    window.addEventListener('keydown', globalKeydown)
  }, [])

  useEffect(() => {
    if (permission(['Overmind___Search bar'])) {
      resources.progressionStatus.fetchAll().then(() => {
        setProgressionStatus(getResources(undefined, 'progressionStatus'))
      })
    }
  }, [])

  const open = Boolean(anchorEl)
  const id = open ? 'searchinBarPopper' : undefined

  if (!permission(['Overmind___Search bar'])) return null

  return (
    <div
      id={id}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={isFocus ? '-1' : '0'}
      style={{
        borderRadius: 4,
        border: `${isFocus ? 3 : value ? 1 : 3}px solid ${
          isFocus ? vars.colors.mainColor : value ? 'lightgrey' : 'transparent'
        }`,
        transition: 'border-color 150ms',
        marginRight: 10,
      }}
      ref={containerRef}
    >
      <InputBase
        value={value}
        onChange={onChange}
        classes={classes}
        inputRef={inputRef}
        endAdornment={
          <div className="fullHeight flex center alignCenter" style={{ width: 20 }}>
            {isLoading ? (
              <LoaderSmall />
            ) : (
              <FontIcon icon="fas-search" style={{ color: isFocus ? '#4a4a4a' : 'grey', transition: 'color 150ms' }} />
            )}
          </div>
        }
      />
      <Popper
        id={id}
        open={open}
        onClose={() => setAnchorEl()}
        anchorEl={anchorEl}
        disablePortal={true}
        placement="bottom"
      >
        <SearchingResults
          results={results}
          searchIn={searchIn}
          setSearchIn={setSearchIn}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          projects={projects}
          setIsSelectOpened={setIsSelectOpened}
          closePopper={() => {
            setAnchorEl()
            setValue('')
          }}
          progressionStatus={progressionStatus}
        />
      </Popper>
    </div>
  )
}
