/** @flow */
import React from 'react'
import type { Schema, SchemaColumn } from 'app/core/types'
import { TreeDnD } from '../TreeDnD/TreeDnD.jsx'
import { NODE_ITEM } from '../TreeDnD/Node'
import { schemaToTree, treeToSchema } from './utils'
import type { SchemaToTreeOptions } from './utils'
import type { NodeConstructor } from '../TreeDnD/Node'
import type { TreeViewItem } from '../TreeDnD/useTreeViewSates'

type TableConstructorProps<Item, GroupSettings, ColumnSettings> = {|
  schema: Schema<Item, GroupSettings, ColumnSettings>,
  onSchemaChange: (shema: Schema<Item, GroupSettings, ColumnSettings>) => Promise<any>,
  setNode?: $ElementType<SchemaToTreeOptions<Item, GroupSettings, ColumnSettings>, 'setNode'>,
  header?: React$Node,
  onAddExternalNode?: (externalData: Object, parentNode: ?TreeViewItem<>) => SchemaColumn<Item, ColumnSettings> | null,
  style?: Object,
|}

export default function TableConstructor<Item, GroupSettings, ColumnSettings>(
  props: TableConstructorProps<Item, GroupSettings, ColumnSettings>,
): React$Node {
  const { schema, onSchemaChange, setNode, onAddExternalNode, ...rest } = props

  function onChange(node) {
    const schema = treeToSchema<Item, GroupSettings, ColumnSettings>(node)
    return onSchemaChange(schema)
  }

  const rootNode = schemaToTree<Item, GroupSettings, ColumnSettings>(schema, { setNode })

  function onSetExternalNode(item, parent): NodeConstructor<SchemaColumn<Item, ColumnSettings>> | null {
    if (!onAddExternalNode) return null
    const schemaColumn = onAddExternalNode(item.node.getData(), parent)
    if (!schemaColumn) return null

    return {
      key: schemaColumn.key,
      name: schemaColumn.name,
      parent: undefined,
      data: schemaColumn,
      color: schemaColumn.settings?.color,
      type: NODE_ITEM,
    }
  }

  return (
    <TreeDnD
      rootNode={rootNode}
      onChange={onChange}
      onAddExternalNode={onAddExternalNode ? onSetExternalNode : undefined}
      {...rest}
    />
  )
}
