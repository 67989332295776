/** @flow */
import React, { useEffect } from 'react'
// import type { Match } from 'app/core/types'
// import { useSelector } from 'react-redux'
import resources from 'app/store/resources'

type Props = {|
  // match: Match,
|}

export function Dashboard(props: Props): React$Node {
  // const { match } = props
  // const { episodeId, type, projectId } = match.params

  useEffect(() => {
    resources.teamEpisodes.fetchAll()
    resources.teams.fetchAll()
  }, [])

  // const teamEpisodes = useSelector(state => state.teamEpisodes.resources)
  // const teams = useSelector(state => state.teams.resources)

  // console.log(teamEpisodes)
  // console.log(teams)

  return (
    <div>
      C{`'`}est trop <pre>null</pre>
    </div>
  )
}
