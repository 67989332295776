// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import IconButtonBase from '@material-ui/core/IconButton'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import type { ElementProps, IconType } from 'app/core/types'
import FontIcon from '../../FontIcon/FontIcon.jsx'

export type IconButtonProps = {|
  ...ElementProps,
  icon?: IconType,
  buttonRef?: React$Ref<any>,
  children?: React$Node,
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'default',
  disabled?: boolean,
  disableRipple?: boolean,
  onClick?: Function,
  tooltip?: string,
  to?: string,
  square?: boolean,
|}

export function IconButton({
  children,
  tooltip,
  disabled,
  to,
  square,
  className,
  style,
  buttonRef,
  icon,
  ...props
}: IconButtonProps): React$Node {
  let Button = (
    <IconButtonBase
      disabled={disabled}
      component={to ? Link : 'button'}
      className={className}
      to={to}
      ref={buttonRef}
      {...props}
      style={square ? { ...style, borderRadius: 0 } : style}
    >
      {icon ? <FontIcon icon={icon} /> : null}
      {children}
    </IconButtonBase>
  )

  if (tooltip && !disabled) {
    Button = <Tooltip title={tooltip}>{Button}</Tooltip>
  }

  return Button
}
