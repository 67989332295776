/*eslint-disable */

import React from 'react'
import Icon from '../Icon.jsx'

export default class Planet_8 extends React.Component {
  static defaultProps = {
    width: 18,
    height: 18,
    fill: 'grey',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 18 18">
        <path d="M8.6,8.9c0-0.3-0.2-0.5-0.5-0.5H2.8c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h5.3 C8.4,9.5,8.6,9.2,8.6,8.9z" />
        <path d="M12.5,10.9c0-0.3-0.2-0.5-0.5-0.5H5c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h7 C12.3,11.4,12.5,11.2,12.5,10.9z" />
        <path
          d="M15.4,2.6c-3.5-3.5-9.2-3.5-12.7,0C0.9,4.3,0,6.6,0,9c0,5,4,9,9,9c2.4,0,4.7-0.9,6.4-2.6
                    C18.9,11.8,18.9,6.2,15.4,2.6z M3.7,14.9h1.7c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H2.8c0,0-0.1,0-0.1,0
                    c-1-1.4-1.6-3.1-1.6-4.8c0-2,0.7-3.8,1.9-5.1h6.8c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H4.1C5.4,1.7,7.1,1.1,9,1.1
                    c2.1,0,4.1,0.8,5.6,2.3c2.8,2.8,3.1,7.3,0.7,10.4H8.5C8.2,13.8,8,14,8,14.3c0,0.3,0.2,0.5,0.5,0.5h5.8C11.3,17.6,6.7,17.6,3.7,14.9
                    z"
        />
      </Icon>
    )
  }
}

/* eslint-enable */
