var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ModalTrigger } from 'app/components/Modal';
import { MUIButton, FormData } from 'app/components/Form';
import resources from 'app/store/resources';
import ModalConfirmForm from '../../../../components/Modal/ModalConfirmForm';
export function ModalCreateDiscussions(props) {
    const { type, relationId, projectId, isOpen } = props, rest = __rest(props, ["type", "relationId", "projectId", "isOpen"]);
    const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } } };
    function create({ title }) {
        return resources[type].create(Object.assign({ name: title }, (type === 'topics' ? { asset: relationId } : { topic: relationId })), requestsConfig);
    }
    return (_jsx(ModalTrigger, { modal: _jsx(ModalConfirmForm, { title: `Create new ${type === 'topics' ? 'topics' : 'threads'}`, children: _jsx(FormData, { onSave: create, flashNotifSuccessLabel: `${type === 'topics' ? 'Topic' : 'Thread'} created`, properties: [
                    {
                        key: 'title',
                        type: 'string',
                        elementProps: {
                            placeholder: 'Enter a title',
                            isRequired: true,
                        },
                    },
                ] }) }), children: _jsx(MUIButton, { icon: "fas-plus", tooltip: `New ${type === 'topics' ? 'topic' : 'thread'}`, style: {
                borderRadius: 0,
                height: 40,
                overflow: 'hidden',
                color: '#4a4a4a',
                borderBottom: '1px solid rgba(0,0,0,0.1)',
                whiteSpace: 'nowrap',
                width: '100%',
            }, bgColor: "#ffffff", children: isOpen ? `New ${type === 'topics' ? 'topic' : 'thread'}` : undefined }) }));
}
