import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { postBoardLinkTypes } from 'app/core/constants';
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx';
import resources from 'app/store/resources';
import { regex } from 'app/libs/helpers/regex';
import { getAssetTypesOptions } from 'app/containers/Assets/ModalEditAsset/utils';
import { assetsTypesParentTypeList } from 'app/core/constants/assetsTypesParentTypeList';
import { FlagsRelations, Flags } from 'app/components/Form';
export function getFormFromLinkType(linkType, project) {
    switch (linkType) {
        case postBoardLinkTypes.create:
            return [
                {
                    key: 'name',
                    label: 'Name',
                    type: 'string',
                    elementProps: {
                        isRequired: true,
                        validators: {
                            nameValidation: (value) => {
                                if (regex.is.assetName.test(value) === false) {
                                    return 'Only lowercase alphanumeric characters and hyphen are allowed.';
                                }
                                return false;
                            },
                        },
                    },
                },
                {
                    key: 'assetType',
                    label: 'Type',
                    type: 'select',
                    elementProps: (data, setData) => {
                        const options = getAssetTypesOptions(['mo'], 'include').filter((opt) => ['gp', 'ph', 'pc', 'sq'].indexOf(opt.value) === -1);
                        return {
                            options,
                            isRequired: true,
                            fullWidth: true,
                            onChange: (value) => {
                                setData({
                                    assetType: value,
                                    parent: '',
                                });
                            },
                            placeholder: 'Select an asset type',
                        };
                    },
                },
                {
                    key: 'parent',
                    label: 'Parent',
                    type: 'autocomplete',
                    element: AssetsSelect,
                    elementProps: (state) => {
                        const assetTypeValue = state.assetType && state.assetType;
                        return {
                            key: assetTypeValue,
                            assetTypes: assetTypeValue ? assetsTypesParentTypeList[assetTypeValue] : [],
                            placeholder: 'Search asset',
                            isRequired: true,
                            inputProps: {
                                disabled: !assetTypeValue,
                            },
                        };
                    },
                },
                {
                    key: 'thumbnail',
                    label: 'Thumbnail',
                    type: 'file',
                    elementProps: {
                        accept: 'image/png, image/jpeg, image/gif',
                        isRequired: false,
                        multiple: false,
                    },
                },
                {
                    key: 'flags',
                    label: 'Flags',
                    type: 'autocomplete',
                    element: FlagsRelations,
                    elementProps: (data, setData) => ({
                        disabled: data.linkType === 'deriv',
                        foreignKey: 'asset',
                        resourceID: null,
                        category: data.assetType,
                        itemCategory: data.assetType,
                        placeholder: 'Select a flag',
                    }),
                },
            ];
        case postBoardLinkTypes.update:
            return [
                {
                    key: 'asset',
                    label: 'Search asset',
                    type: 'autocomplete',
                    element: AssetsSelect,
                    elementProps: (state, setData) => ({
                        isRequired: linkType !== 'create' && linkType !== 'deriv',
                        assetTypes: ['mo'],
                        assetParent: project.id,
                        showAssetCateg: true,
                        onChange: (value) => {
                            setData({ asset: value });
                            resources.assets.fetchOne(value.value);
                        },
                        placeholder: 'Select an asset',
                    }),
                },
                {
                    key: 'flags',
                    label: 'Flags',
                    type: 'custom',
                    isShow: (data) => data.asset,
                    element: (data, setData) => {
                        return (_jsx(Flags, { id: data.asset.value, isBeingEdit: true, resourceType: "asset", flagsList: data.flags, onChange: (flags) => {
                                setData(Object.assign(Object.assign({}, data), { flags }));
                            } }));
                    },
                },
            ];
        case postBoardLinkTypes.reut:
            return [
                {
                    key: 'asset',
                    label: 'Search asset',
                    type: 'autocomplete',
                    element: AssetsSelect,
                    elementProps: (state, setData) => ({
                        isRequired: linkType !== 'create' && linkType !== 'deriv',
                        assetTypes: ['mo'],
                        assetParent: project.id,
                        showAssetCateg: true,
                        onChange: (value) => {
                            setData({ asset: value });
                            resources.assets.fetchOne(value.value);
                        },
                    }),
                },
                {
                    key: 'flags',
                    label: 'Flags',
                    type: 'custom',
                    isShow: (data) => data.asset,
                    element: (data, setData) => {
                        return (_jsx(Flags, { id: data.asset.value, isBeingEdit: true, resourceType: "asset", flagsList: data.flags, onChange: (flags) => {
                                setData(Object.assign(Object.assign({}, data), { flags }));
                            } }));
                    },
                },
            ];
        case postBoardLinkTypes.deriv:
            return [
                {
                    key: 'assetParentId',
                    label: 'Original asset it derivates from',
                    type: 'autocomplete',
                    element: AssetsSelect,
                    elementProps: {
                        isRequired: true,
                        assetTypes: ['mo'],
                        placeholder: 'Search asset',
                        showAssetCateg: true,
                    },
                },
                {
                    key: 'name',
                    label: 'Name',
                    type: 'string',
                    elementProps: {
                        isRequired: true,
                        validators: {
                            nameValidation: (value) => {
                                if (regex.is.assetName.test(value) === false) {
                                    return 'Only lowercase alphanumeric characters and hyphen are allowed.';
                                }
                                return false;
                            },
                        },
                    },
                },
                {
                    key: 'assetType',
                    label: 'Type',
                    type: 'select',
                    elementProps: (data, setData) => {
                        const options = getAssetTypesOptions(['mo'], 'include').filter((opt) => ['gp', 'ph', 'pc', 'sq'].indexOf(opt.value) === -1);
                        return {
                            options,
                            isRequired: true,
                            fullWidth: true,
                            onChange: (value) => {
                                setData({
                                    assetType: value,
                                    parent: '',
                                });
                            },
                        };
                    },
                },
                {
                    key: 'parent',
                    label: 'Parent',
                    type: 'autocomplete',
                    element: AssetsSelect,
                    elementProps: (state) => {
                        const assetTypeValue = state.assetType && state.assetType;
                        return {
                            key: assetTypeValue,
                            assetTypes: assetTypeValue ? assetsTypesParentTypeList[assetTypeValue] : [],
                            placeholder: 'Search asset',
                            isRequired: true,
                            inputProps: {
                                disabled: !assetTypeValue,
                            },
                        };
                    },
                },
                {
                    key: 'thumbnail',
                    label: 'Thumbnail',
                    type: 'file',
                    elementProps: {
                        accept: 'image/png, image/jpeg, image/gif',
                        isRequired: false,
                        multiple: false,
                    },
                },
                {
                    key: 'flags',
                    label: 'Flags',
                    type: 'autocomplete',
                    element: FlagsRelations,
                    elementProps: (data, setData) => ({
                        disabled: data.linkType === 'deriv',
                        foreignKey: 'asset',
                        resourceID: null,
                        category: data.assetType,
                        itemCategory: data.assetType,
                    }),
                },
            ];
        default:
            return [];
    }
}
