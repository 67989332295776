import React from 'react'
import Icon from '../Icon.jsx'

export default class Folder extends React.PureComponent {
  static defaultProps = {
    width: 34,
    height: 28,
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 17 14">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-574.000000, -48.000000)" fill="#B2B9C7">
            <g transform="translate(573.000000, 48.000000)">
              <path
                d="M16.6455,5.1911 L15.1905,12.1561 C15.1855,12.1801 15.1845,12.2071 15.1825,12.2321 C15.1815,12.2441 15.1785,12.2561 15.1785,12.2681 C15.1785,12.6181 14.8935,12.9021 14.5435,12.9021 L4.3535,12.9021 L5.8565,5.1911 L16.6455,5.1911 Z M16.2765,12.3421 L17.9995,4.0931 L16.3085,4.0921 L16.3085,3.3791 C16.3085,2.4241 15.5305,1.6481 14.5735,1.6481 L8.1645,1.6481 L6.3425,9.99999993e-05 L2.7365,9.99999993e-05 C1.7795,9.99999993e-05 0.9995,0.7761 0.9995,1.7311 L0.9995,12.2681 C0.9995,13.2231 1.7795,14.0001 2.7365,14.0001 L13.6765,14.0001 L14.5735,14.0001 C14.5825,14.0001 14.5915,13.9971 14.6025,13.9971 C15.5075,13.9661 16.2385,13.2431 16.2765,12.3421 L16.2765,12.3421 Z"
                id="Fill-1"
              />
            </g>
          </g>
        </g>
      </Icon>
    )
  }
}
