/**
 * Use this file to overide the build environment variables.
 */

module.exports = {
  // API_URL,
  // SOCKET_URL,
  // ENV,
  // PORT,
  // SENTRY_DSN,
  // SENTRY_URL,
  // SENTRY_AUTH_TOKEN,
  // SENTRY_ORG,
  // SENTRY_PROJECT,
  // CI_COMMIT_TAG,
  // CI_COMMIT_REF_NAME,
}
