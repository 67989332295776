// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./gotham-bold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./gotham-bold-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./gotham-bold-webfont.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./gotham-bolditalic-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./gotham-bolditalic-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./gotham-bolditalic-webfont.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./gotham-light-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./gotham-light-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./gotham-light-webfont.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./gotham-lightitalic-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./gotham-lightitalic-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("./gotham-lightitalic-webfont.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'gothambold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothambolditalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2'), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothamlight';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2'), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff'),
    url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothamlightitalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format('woff'), url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./app/styles/fonts/Gotham/gotham.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB;8DACqD;EACrD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B;8DAC2D;EAC3D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B;8DACsD;EACtD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC;wHACqH;EACrH,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: 'gothambold';\n  src: url('./gotham-bold-webfont.woff2') format('woff2'), url('./gotham-bold-webfont.woff') format('woff'),\n    url('./gotham-bold-webfont.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'gothambolditalic';\n  src: url('./gotham-bolditalic-webfont.woff2') format('woff2'), url('./gotham-bolditalic-webfont.woff') format('woff'),\n    url('./gotham-bolditalic-webfont.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'gothamlight';\n  src: url('./gotham-light-webfont.woff2') format('woff2'), url('./gotham-light-webfont.woff') format('woff'),\n    url('./gotham-light-webfont.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'gothamlightitalic';\n  src: url('./gotham-lightitalic-webfont.woff2') format('woff2'),\n    url('./gotham-lightitalic-webfont.woff') format('woff'), url('./gotham-lightitalic-webfont.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
