// @flow
import React, { useEffect, useState } from 'react'
import PubSub from 'pubsub-js'
import type { MouseTooltipPublishParams } from './publishMouseTooltip'

export const mouseTooltipEventName = 'ovm-mouse-tooltip'

export function MouseTooltip(): React$Node {
  const [tooltip, setTooltip] = useState<React$Node | void>()
  const [position, setPosition] = useState<{ x: number, y: number } | void>()

  useEffect(() => {
    const token = PubSub.subscribe(mouseTooltipEventName, (msg, params: MouseTooltipPublishParams) => {
      if (!params) {
        setPosition()
        setTooltip()
        return
      }

      const { tooltip: paramsTooltip } = params
      setTooltip(paramsTooltip)
      if (params?.clientX !== position?.x && params?.clientY !== position?.y) {
        setPosition({ x: params.clientX, y: params.clientY })
      }
    })

    return () => PubSub.unsubscribe(token)
  }, [position])

  if (!tooltip || !position) return null

  const style = {
    position: 'fixed',
    left: position?.x,
    top: position?.y,
    pointerEvents: 'none',
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: '3px',
    padding: '2px 4px',
    color: '#ffffff',
    zIndex: 1000,
    whiteSpace: 'pre',
  }

  return <div style={style}>{tooltip}</div>
}
