/** @flow */
import React, { useEffect, useMemo, useState } from 'react'
import { map, sortBy, find } from 'lodash'
import type { Article, ID } from 'app/core/types'
import resources from 'app/store/resources'
import { openModal } from 'app/components/Modal'
import { VirtualizedTree, type VirtualizedTreeProps } from 'app/components/Tree/VirtualizedTree.jsx'
import { MUIButton } from 'app/components/Form'
import { permission } from 'app/containers/Permissions/Permission.jsx'
import { getArticles } from 'app/store/selectors/getArticles'
import { useSelector } from 'react-redux'
import ModalCreateArticle from './ModalCreateArticle.jsx'
import classes from './WikiTree.module.scss'
import type { Node } from '../../../components/Tree/TreeRow'
import { documentTitle } from '../../../components/DocumentTitle/DocumentTitle.jsx'

type Props = {|
  ...$Rest<$Shape<VirtualizedTreeProps>, { items: $ElementType<VirtualizedTreeProps, 'items'> }>,
  router?: Object,
  currentArticleId: ?string,
  onClickArticle?: (assetId: ID, articleId: ID) => string,
  assetId: ID,
|}

function WikiTree(props: Props): React$Node {
  const { currentArticleId, router, onClickArticle, assetId, ...rest } = props
  const [expandAll, setExpandAll] = useState(false)

  const articles: Array<$ElementType<Node, 'data'>> = useSelector((state) => {
    const getArticlesRedux = getArticles(state, assetId)
    const currentArticle = getArticlesRedux[currentArticleId]
    documentTitle(currentArticle?.title)
    const organizedArticles = map(getArticlesRedux, (article: Article, index: ID) => ({
      id: article.id,
      label: article.title,
      parentId: article.parent,
      metadata: article,
      displayArrow: find(getArticlesRedux, (a) => a.parent === article.id),
      to: onClickArticle
        ? onClickArticle(assetId, article.id)
        : !router
        ? `/assets-wiki/${assetId}/${article.id}`
        : `?${router.name}=/${assetId}/wiki/${article.id}/&${router.name}-rightPanel=true`,
      isRoot: !article.parent,
    }))
    return sortBy(organizedArticles, ['label'])
  })

  useEffect(() => {
    resources.articles.fetchTOC(assetId)
  }, [assetId])

  const canEdit = useMemo(() => permission(['wiki__articles_create', 'wiki_articles__create'], 'or'), [])

  const articlesKey = articles.map((node) => node.id).join('-')

  return (
    <div className={`${classes.container} flex center column`}>
      <div className="flex row noWrap alignCenter spaceBetween fullWidth">
        <MUIButton
          icon={expandAll ? 'fas-caret-down' : 'fas-caret-right'}
          className={classes.btn}
          style={{ color: '#000000', boxShadow: 'none' }}
          bgColor="#ffffff"
          onClick={() => setExpandAll(!expandAll)}
          tooltip="Expand all"
        />
        {canEdit ? (
          <MUIButton
            icon="fas-plus"
            className={classes.btn}
            color="primary"
            onClick={() => openModal(<ModalCreateArticle assetId={assetId} />)}
          >
            New article
          </MUIButton>
        ) : null}
      </div>
      <div className={`${classes.treeOverflow} paddingTop10`} style={{ height: `calc(100% - ${canEdit ? 50 : 0}px)` }}>
        {articles.length !== 0 ? (
          <VirtualizedTree
            key={`${assetId}-${articlesKey}`}
            items={articles}
            currentItemId={currentArticleId}
            expandAll={expandAll}
            {...rest}
          />
        ) : null}
      </div>
    </div>
  )
}

export default WikiTree
