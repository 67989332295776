/** @flow */
import { type Element } from 'react'
import { kebabCase } from 'lodash'
import innerText from 'react-innertext'
import { regex } from './regex'

export function cyLabelFormater(preString: string, label: string | Element<any> | number | void | mixed): string {
  let value = innerText(label).toLowerCase()
  if (!regex.is.uuid.test(value)) {
    value = kebabCase(value)
  }
  const output = `${preString}-${value}`
  return output
}
