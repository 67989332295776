// @flow
import React, { useCallback } from 'react'
import type { History, Match, Location } from 'app/core/types'
import { Wiki } from 'app/containers/Wiki/Wiki.jsx'

type Props = {|
  history: History,
  match: Match,
  location: Location,
|}

export function ProjectWiki(props: Props): React$Node {
  const { history, match, location } = props
  const { projectId } = match.params

  const formatRoute = useCallback(
    (projectId, assetId, artcileVersionId, search) =>
      `/projects/${projectId || ''}/wiki${assetId ? `/${assetId}` : ''}${
        artcileVersionId ? `/${artcileVersionId}` : ''
      }${search || ''}`,
    [],
  )

  return (
    <Wiki
      formatRoute={formatRoute}
      rootAssetId={projectId || ''}
      history={history}
      match={match}
      location={location}
      showRoot={true}
      expandedLevel={1}
    />
  )
}
