// @flow
import * as React from 'react'
import cx from 'classnames'
import classes from './BurgerButton.module.scss'

type Props = {|
  open: boolean,
  onClick?: Function,
  style?: Object,
|}

export function BurgerButton({ open, ...props }: Props): React$Node {
  return (
    <div className={cx(classes.hamburger, classes['hamburger--slider'], open && classes['is-active'])} {...props}>
      <span className={classes['hamburger-box']}>
        <span className={classes['hamburger-inner']} />
      </span>
    </div>
  )
}
