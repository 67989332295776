// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-ColumnTimelineImputation-___BackgroundSVG-module__container {
  position: absolute;
  left: -1px;
  top: 0;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/ColumnTimelineImputation/BackgroundSVG.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,MAAM;AACR","sourcesContent":[".container {\n  position: absolute;\n  left: -1px;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-ColumnTimelineImputation-___BackgroundSVG-module__container`
};
export default ___CSS_LOADER_EXPORT___;
