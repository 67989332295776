import React from 'react'
import classes from './Label.module.scss'

export default function ({ children, ...props }) {
  return (
    <div className={classes.label} {...props}>
      {children}
    </div>
  )
}
