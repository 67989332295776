import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useState, useEffect, useMemo } from 'react';
import resources from 'app/store/resources';
import { getResources } from 'app/store/selectors';
import Loader from 'app/components/Loader/Loader.jsx';
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
import { permission } from 'app/containers/Permissions/index.js';
import { EditList } from '../EditList';
import { ModalEditDepartment } from './ModalEditDepartment';
export function DepartmentsEditor() {
    const [isLoading, setIsLoading] = useState(false);
    const [departments, setDepartments] = useState();
    useEffect(() => {
        setIsLoading(true);
        resources.departments.fetchAll({ params: { queries: { page_size: 1000 } } }).then(() => {
            setDepartments(getResources(undefined, 'departments'));
            setIsLoading(false);
        });
    }, []);
    useEffect(() => {
        documentTitle('Departments editor', 'page');
    }, []);
    const canEdit = useMemo(() => permission(['human resources_settings_departments_edit']), []);
    return isLoading ? (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter", children: _jsx(Loader, {}) })) : (_jsx("div", { className: "padding10", style: { width: 800, height: '100%' }, children: _jsx(EditList, { title: "Departments", unity: "Department", itemsList: departments || {}, readOnly: !canEdit, titles: ['Name', 'Quotation reference', 'color'], columnsSizes: [400, 180, 60], labelSelectors: [
                'name',
                'quoteReference',
                {
                    key: 'color',
                    parser: (department) => (_jsx("div", { style: {
                            backgroundColor: department.color,
                            height: 30,
                            width: 30,
                            borderRadius: '3px',
                            marginRight: 10,
                        } })),
                },
            ], onRemove: (department) => {
                const newDepartments = departments ? Object.assign({}, departments) : {};
                if (department === null || department === void 0 ? void 0 : department.id)
                    delete newDepartments[department.id];
                setDepartments(newDepartments);
            }, EditModal: (item) => (_jsx(ModalEditDepartment, { department: item, onChange: (department) => {
                    const newDepartments = departments ? Object.assign({}, departments) : {};
                    newDepartments[department.id] = department;
                    setDepartments(newDepartments);
                } })), resource: "departments" }) }));
}
