/** @flow */
import React from 'react'
import moment from 'moment'
import cx from 'classnames'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import Loader from 'app/components/Loader/Loader.jsx'
import classes from './Calendar.module.scss'
import CalendarJS from './CalendarJS.js'

type Props = {|
  RenderDay: React$ComponentType<any>,
  selectedDay?: moment,
  onChangeMonth?: (currentDay: moment) => void,
  onChangeYear?: (currentDay: moment) => void,
  onSelectWeek?: (startDate: moment, endDate: moment) => void,
  onSelectMonth?: (month: moment) => void,
  isLoading?: boolean,
  display?: 'week' | 'month',
|}

type State = {|
  currentMonth: string,
|}

export default class Calendar extends React.PureComponent<Props, State> {
  currentDate: moment = moment()

  calendar: * = new CalendarJS({
    weekStartDay: 1,
    selectedDate: this.props.selectedDay ? this.props.selectedDay.toDate() : this.currentDate.toDate(),
    currentDate: this.props.selectedDay ? this.props.selectedDay.toDate() : this.currentDate.toDate(),
  })

  state: State = {
    currentMonth: this.props.selectedDay ? this.props.selectedDay.toISOString() : this.currentDate.toISOString(),
  }

  onSelectWeek: Function = () => {
    const { onSelectWeek } = this.props

    if (onSelectWeek) {
      const selectedWeek = this.getSelectedWeek()
      onSelectWeek(selectedWeek[0], selectedWeek[selectedWeek.length - 1])
    }
  }

  onSelectMonth: Function = (month: moment) => {
    const { onSelectMonth } = this.props

    if (onSelectMonth) {
      onSelectMonth(moment(month))
    }
  }

  onChangeDay(day: moment) {
    this.calendar.selectedDate = day.toDate()
    this.calendar.currentDate = day.toDate()
  }

  getSelectedWeek(): Function {
    const { currentDate } = this.calendar
    const selectedWeek = []

    const startWeek = moment(currentDate).startOf('week')
    const endWeek = moment(currentDate).endOf('week')

    while (startWeek.toDate() < endWeek.toDate()) {
      selectedWeek.push(moment(startWeek))
      startWeek.add(1, 'day')
    }

    return selectedWeek
  }

  onChangeMonth: Function = () => {
    const { onChangeMonth } = this.props
    const { currentDate } = this.calendar

    this.setState({ currentMonth: currentDate.toISOString() })

    if (onChangeMonth) {
      onChangeMonth(moment(currentDate))
    }
  }

  onChangeYear: Function = () => {
    const { onChangeYear } = this.props
    const { currentDate } = this.calendar

    this.setState({ currentMonth: currentDate.toISOString() })

    if (onChangeYear) {
      onChangeYear(moment(currentDate))
    }
  }

  goToNextMonth: Function = () => {
    this.calendar.goToNextMonth()
    this.onChangeMonth()
  }

  goToPrevMonth: Function = () => {
    this.calendar.goToPrevMonth()
    this.onChangeMonth()
  }

  goToPrevYear: Function = () => {
    this.calendar.goToPrevYear()
    this.onChangeYear()
  }

  goToNextYear: Function = () => {
    this.calendar.goToNextYear()
    this.onChangeYear()
  }

  renderDays(week: Array<moment>): Array<React$Element<any>> {
    const { RenderDay, isLoading } = this.props

    return week.map((dayOfWeek: moment) => {
      const { currentDate } = this

      const isInCurrentMonth = dayOfWeek.isSame(this.calendar.currentDate, 'month')
      const isCurrentDay = dayOfWeek.isSame(currentDate, 'day')
      const isSelectedDay = dayOfWeek.isSame(this.calendar.selectedDate, 'day')

      const defaultClassName = cx(classes.day, {
        [classes.notInCurrentMonth]: !isInCurrentMonth,
        [classes.isCurrentDay]: isCurrentDay,
        [classes.isSelectedDay]: isSelectedDay,
      })

      return (
        <div
          className={classes.renderDay}
          key={`${dayOfWeek.format('dddd')} ${String(isLoading)}`}
          onClick={() => this.onChangeDay(dayOfWeek)}
        >
          <RenderDay
            day={dayOfWeek}
            defaultClassName={defaultClassName}
            isInCurrentMonth={isInCurrentMonth}
            isCurrentDay={isCurrentDay}
          />
        </div>
      )
    })
  }

  render(): React$Node {
    const { isLoading, display = 'week', selectedDay } = this.props
    const { currentMonth } = this.state
    const monthTab = this.calendar.getMonthTab()

    const weeks = monthTab.weeks.map((week) => week.map((day) => moment(new Date(day))))
    const selectedWeek = this.getSelectedWeek()
    const startOfWeek = selectedWeek[0]
    const endOfWeek = selectedWeek[6]

    return display === 'week' ? (
      <div className={classes.container} key={currentMonth}>
        <div className={classes.header}>
          <div className={classes.headerDate} style={{ borderRight: '1px solid white' }}>
            <div className={classes.currentYear}>{startOfWeek.format('YYYY')}</div>
            <div className={classes.currentDay}>{startOfWeek.format('ddd DD MMM')}</div>
          </div>
          <div className={classes.headerDate}>
            <div className={classes.currentYear}>{endOfWeek.format('YYYY')}</div>
            <div className={classes.currentDay}>{endOfWeek.format('ddd DD MMM')}</div>
          </div>
        </div>
        <div className={classes.calendar}>
          <div className={classes.selectMonth}>
            <FontIcon icon="fas-angle-left" className={classes.arrow} onClick={this.goToPrevMonth} />
            <div className="flex flex1 row center alignCenter fontSize18 capitalize">
              {isLoading ? <Loader className="marginRight10" size={14} /> : null}
              <span>
                {monthTab.month} {monthTab.year}
              </span>
            </div>
            <FontIcon icon="fas-angle-right" className={classes.arrow} onClick={this.goToNextMonth} />
          </div>

          <div className={classes.daysName}>
            {['1', '2', '3', '4', '5', '6', '0'].map((d) => (
              <div className={classes.dayName} key={d}>
                {moment(d, 'd').format('ddd')}
              </div>
            ))}
          </div>
          <div className={classes.weeks}>
            {weeks.map((week) => (
              <div
                className={cx(
                  classes.week,
                  classes.renderWeek,
                  moment(this.calendar.selectedDate).isSame(week[0], 'week') && classes.selectedWeek,
                )}
                key={week}
                onClick={this.onSelectWeek}
              >
                {this.renderDays(week)}
              </div>
            ))}
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.container} key={currentMonth}>
        <div className={classes.calendar}>
          <div className={classes.header}>
            <div className={classes.headerDate}>
              <div className={`${classes.currentDay} capitalize flex center`}>
                {(selectedDay || this.currentDate).format('MMMM YYYY')}
              </div>
            </div>
          </div>
          <div className={classes.selectMonth}>
            <FontIcon icon="fas-angle-left" className={classes.arrow} onClick={this.goToPrevYear} />
            <div className="flex flex1 row center alignCenter fontSize18 capitalize">
              {isLoading ? <Loader className="marginRight10" size={14} /> : null}
              <span>{monthTab.year}</span>
            </div>
            <FontIcon icon="fas-angle-right" className={classes.arrow} onClick={this.goToNextYear} />
          </div>
        </div>
        <div className="flex wrap paddingBottom20">
          {Array(12)
            .fill(0)
            .map((fill, index) => {
              const currentMonth = moment(this.calendar.currentDate)
              const month = moment().set({ month: index, year: currentMonth.year() })

              return (
                <div key={month.month()} style={{ width: '33%', padding: '2px 3px' }} className="padding5">
                  <div
                    onClick={() => this.onSelectMonth(month)}
                    className={`${classes.months} ${
                      (selectedDay || this.currentDate).isSame(month, 'month') ? classes.selected : ''
                    } flex center alignCenter`}
                  >
                    {month.format('MMMM')}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
  }
}
