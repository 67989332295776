// @flow

import * as React from 'react'
import pipe from 'app/core/utils/pipe.jsx'
import { map, find } from 'lodash'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import { FormData } from 'app/components/Form'
import resources from 'app/store/resources'
import { ModalConfirmForm } from 'app/components/Modal'
import { getResources } from 'app/store/selectors/getResources'
import { StepsRelations } from './StepsRelations.jsx'

type Props = {
  steps: Array<Object>,
  projectId: string,
  stepProjects: Object,
}

class ModalAddSteps extends React.Component<Props> {
  onSave = ({ steps }: *) => {
    const { projectId, stepProjects } = this.props
    let maxRank = Object.keys(stepProjects).length
      ? Math.max(...map(stepProjects, (stepProject) => stepProject.rank))
      : -1

    const stepProjectsToCreate = steps
      .map(({ data: step }) => {
        const stepProject = find(stepProjects, (sp) => {
          return sp.step === step.id && sp.project === projectId
        })
        if (stepProject) return undefined
        maxRank += 1
        return {
          step: step.id,
          project: projectId,
          rank: maxRank,
        }
      })
      .filter((_) => _)

    return resources.stepProjects.create(stepProjectsToCreate)
  }

  render(): React$Node {
    const { stepProjects, steps, projectId, ...rest } = this.props

    return (
      // $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow
      <ModalConfirmForm draggable={true} {...rest} title="Add steps" minWidth={600}>
        <FormData
          key={`${map(steps, (sp) => sp.id).toString()}-${map(stepProjects, (sp) => sp.id).toString()}`}
          defaultData={{ steps: [] }}
          properties={[
            {
              key: 'steps',
              label: 'Steps',
              type: 'autocomplete',
              element: StepsRelations,
              elementProps: {
                stepProjects,
                steps,
              },
            },
          ]}
          onSave={this.onSave}
        />
      </ModalConfirmForm>
    )
  }
}

const pipeInst: Pipe<{}, typeof ModalAddSteps> = pipe()

const Component: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const stepProjects = getResources(state, 'stepProjects', { project: state.project?.id }, ['stepInst'])
    const steps = getResources(
      state,
      'steps',
      map(stepProjects, (sp) => sp.step),
    )

    return {
      projectId: (state.project && state.project.id) || '',
      stepProjects,
      steps,
    }
  })
  .render(ModalAddSteps)

export default Component
