/** @flow */
import { useState, useEffect, useCallback, useRef } from 'react'

export type Progress = {
  [key: string]: number,
}

export type UseProgressProps = ?{|
  defaultLoadingProgress: Progress | void | null,
|}

type Output = {|
  initProgresses: () => void,
  updateProgress: (key: string, value: number) => void,
  progress: Progress | void | null,
|}

export const useProgress = (props: UseProgressProps): Output => {
  const { defaultLoadingProgress } = props || {}

  const [progress, _setProgress] = useState<Progress | void | null>()

  const isMount = useRef(true)

  useEffect(
    () => () => {
      isMount.current = false
    },
    [],
  )

  const initProgresses = useCallback(() => {
    if (isMount.current) _setProgress({})
  }, [isMount.current])

  function updateProgress(key: string, value: number) {
    if (isMount.current)
      _setProgress((progress) => {
        const newProgress = progress ? { ...progress } : {}
        newProgress[key] = value
        return newProgress
      })
  }

  useEffect(() => {
    if (progress && Object.keys(progress).length === 0) {
      _setProgress()
    }
  }, [progress])

  let finalProgress = {}
  Object.assign(finalProgress, defaultLoadingProgress, progress)

  if (Object.keys(finalProgress).length === 0) finalProgress = null

  return {
    initProgresses,
    updateProgress,
    progress: finalProgress,
  }
}
