/** @flow */
import type { TableRow } from 'app/components/Table/types'
import type { ResourcesList, ProgressionStatus, MenuAction, ID } from 'app/core/types'
import { permission } from 'app/containers/Permissions/Permission.jsx'
import { tableId as TableMyTaskId } from 'app/pages/MyTasks/MyTasks.jsx'

export function checkTaskPerms(actions: Array<MenuAction>, projectId?: ID): Array<MenuAction> {
  const menuActions = []
  actions.forEach((action) => {
    if (
      ['delete', 'copy', 'past', 'edit'].includes(action) &&
      permission(['projet_tasks_tasks_update'], undefined, undefined, projectId)
    ) {
      menuActions.push(action)
    }
    if (
      typeof action === 'object' &&
      action.label === 'Create new take' &&
      permission(['projet_tasks_takes_create'], undefined, undefined, projectId)
    ) {
      menuActions.push(action)
    }
    if (typeof action === 'object' && action.label === 'Show task details') {
      menuActions.push(action)
    }
    if (typeof action === 'object' && action.label === 'Add to filters') {
      menuActions.push(action)
    }
    if (typeof action === 'object' && action.separator) {
      menuActions.push(action)
    }
  })
  return menuActions
}
export function checkTakePerms(actions: Array<MenuAction>, projectId?: ID): Array<MenuAction> {
  const menuActions = []
  actions.forEach((action) => {
    if (
      ['copy', 'past', 'edit'].includes(action) &&
      permission(['projet_tasks_takes_update'], undefined, undefined, projectId)
    ) {
      menuActions.push(action)
      return
    }
    if (action === 'delete' && permission(['projet_tasks_takes_delete'], undefined, undefined, projectId)) {
      menuActions.push(action)
      return
    }
    if (
      typeof action === 'object' &&
      action.label === 'Create new take' &&
      permission(['projet_tasks_takes_create'], undefined, undefined, projectId)
    ) {
      menuActions.push(action)
      return
    }
    if (typeof action === 'object' && action.label === 'Show task details') {
      menuActions.push(action)
      return
    }
    if (typeof action === 'object' && action.label === 'Add to filters') {
      menuActions.push(action)
      return
    }
    if (typeof action === 'object' && action.separator) {
      menuActions.push(action)
    }
  })

  return menuActions
}
export function checkMyTasksPerms(
  row: TableRow,
  actions: Array<MenuAction>,
  progressionStatus: ResourcesList<ProgressionStatus>,
  tableId: string,
  columnType: string,
  projectId?: ID,
): Array<MenuAction> {
  if (tableId === TableMyTaskId) {
    const status = progressionStatus[row.original.status]
    if (status?.statusType === 3 && !permission(['my tasks___can close a take'], undefined, undefined, projectId)) {
      return []
    }

    if (columnType === 'brief' && permission(['my tasks___edit brief'], undefined, undefined, projectId)) {
      return actions
    }

    if (
      ['status', 'notes', 'validationMedias'].includes(columnType) &&
      permission(['my tasks___edit status/notes/validationMedias'], undefined, undefined, projectId)
    ) {
      return actions
    }

    return []
  }
  return actions
}
