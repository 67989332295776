// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-OverPage-___OverPage-module__draggablePart {
  min-width: 8px;
  height: 100%;
  cursor: col-resize;
  background-color: rgba(0, 0, 0, 0.05);
  transition: all 0.1s;
  font-size: 14px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.app-components-OverPage-___OverPage-module__contentContainer {
  position: relative;
  z-index: 1900;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 10px;
}

.app-components-OverPage-___OverPage-module__buttonResizeBar {
  border-radius: 4px;
  border: 1px solid var(--colors-greyMedium);
  transition: all 0.1s;
  position: absolute;
  color: var(--colors-greyMedium);
  padding: 3px 2px;
  font-size: 15px;
  background-color: #ffffff;
}
.app-components-OverPage-___OverPage-module__draggablePart:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-components-OverPage-___OverPage-module__draggablePart:hover .app-components-OverPage-___OverPage-module__buttonResizeBar {
    border: 1px solid var(--colors-grey);
    color: var(--colors-grey);
  }
`, "",{"version":3,"sources":["webpack://./app/components/OverPage/OverPage.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,qCAAqC;EACrC,oBAAoB;EACpB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,0CAA0C;EAC1C,oBAAoB;EACpB,kBAAkB;EAClB,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,oCAAoC;AAMtC;AAJE;IACE,oCAAoC;IACpC,yBAAyB;EAC3B","sourcesContent":[".draggablePart {\n  min-width: 8px;\n  height: 100%;\n  cursor: col-resize;\n  background-color: rgba(0, 0, 0, 0.05);\n  transition: all 0.1s;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n}\n\n.contentContainer {\n  position: relative;\n  z-index: 1900;\n  width: 100%;\n  max-width: 100%;\n  max-height: 100%;\n  padding: 10px;\n}\n\n.buttonResizeBar {\n  border-radius: 4px;\n  border: 1px solid var(--colors-greyMedium);\n  transition: all 0.1s;\n  position: absolute;\n  color: var(--colors-greyMedium);\n  padding: 3px 2px;\n  font-size: 15px;\n  background-color: #ffffff;\n}\n.draggablePart:hover {\n  background-color: rgba(0, 0, 0, 0.1);\n\n  .buttonResizeBar {\n    border: 1px solid var(--colors-grey);\n    color: var(--colors-grey);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggablePart": `app-components-OverPage-___OverPage-module__draggablePart`,
	"contentContainer": `app-components-OverPage-___OverPage-module__contentContainer`,
	"buttonResizeBar": `app-components-OverPage-___OverPage-module__buttonResizeBar`
};
export default ___CSS_LOADER_EXPORT___;
