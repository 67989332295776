/** @flow */
import React, { useState } from 'react'
import { map, sortBy } from 'lodash'
import type { Pipe, ProgressionStatus, ID } from 'app/core/types'
import pipe from 'app/core/utils/pipe'
import resources from 'app/store/resources'
import { getResources } from 'app/store/selectors'
import Widget from 'app/components/Widget/Widget.jsx'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { Title } from 'app/components/Texts/Title/Title.jsx'
import { ModulableTree } from 'app/components/ModulableTree/ModulableTree.jsx'
import { permission } from 'app/containers/Permissions/index.js'
import { MUIButton, Toggle } from 'app/components/Form'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import { openModal, confirmDelete } from 'app/components/Modal'
import { ModalEditStatus } from './ModalEditStatus.tsx'
import classes from './StatusEditing.module.scss'

type Props = {|
  progressionStatus: Array<ProgressionStatus>,
|}

function StatusEditingView(props: Props) {
  const { progressionStatus } = props
  const [enableUpdateRank, setEnableUpdateRank] = useState(false)

  function statusEdition(progressionStatus) {
    return (e) => openModal(<ModalEditStatus progressionStatus={progressionStatus} />)
  }

  function deleteStatus(statusId: ID) {
    return (e: SyntheticEvent<HTMLElement>) =>
      confirmDelete({
        render: 'Are you sure you want to delete this status?',
        onValidate: () => resources.progressionStatus.delete(statusId),
      })
  }

  function onDrop(content, data, index) {
    const { index: dragIndex } = content

    if (Number.isNaN(dragIndex)) return Promise.resolve()

    if (Number(dragIndex) >= Number(index) && index !== 0) {
      index += 1
    }

    const itemToMove = data[dragIndex]
    data.splice(dragIndex, 1)
    data.splice(index > 0 ? index : 0, 0, itemToMove)

    return resources.progressionStatus.update(
      data.map(({ id }, index) => ({ id, order: index })),
      {
        batch: true,
        updateBeforeRequest: true,
      },
    )
  }

  function getItems() {
    const orderedProgressionStatus = sortBy(progressionStatus, ['order'])

    return map(orderedProgressionStatus, (ps, index: number) => {
      return {
        key: ps.name,
        dragContent: enableUpdateRank && {
          data: ps,
          index,
        },
        actions: enableUpdateRank &&
          permission([]) && [
            {
              key: 'delete',
              onClick: () => deleteStatus(ps.id),
              tooltip: 'Remove status',
            },
          ],
        drop: ({ content }) => onDrop(content, orderedProgressionStatus, index),
        label: (
          <div className="flex row" style={{ marginLeft: -10 }}>
            <div
              style={{ width: 30, height: 38, borderRight: 'solid 1px #E8E8E8', marginRight: 10 }}
              className="flex center alignCenter"
            >
              {index}
            </div>
            <div
              className="flex alignCenter marginRight10 relative"
              style={!enableUpdateRank ? { pointerEvents: 'all', zIndex: 10 } : undefined}
            >
              <Tooltip title="Edit status">
                <div
                  className={classes.status}
                  style={{
                    backgroundColor: ps.color,
                    color: getColorFromBackground(ps.color),
                  }}
                  onClick={statusEdition(ps)}
                >
                  {ps.name}
                </div>
              </Tooltip>
            </div>
          </div>
        ),
      }
    })
  }

  return (
    <div className="flex">
      <Widget style={{ padding: 10, width: 'auto' }}>
        <ModulableTree
          title={
            <Title size={3} className="marginRight50">
              Status editing
            </Title>
          }
          treeProps={{
            hideOrigin: true,
            noExpand: true,
            noLevel: true,
            noDragIcon: true,
          }}
          Header={
            <div className="flex row marginBottom10">
              <MUIButton onClick={statusEdition()} icon="fas-plus">
                Add a status
              </MUIButton>
              <div className="marginLeft10">
                <Toggle
                  value={enableUpdateRank}
                  option1={{
                    label: 'Edit',
                    value: true,
                  }}
                  option2={{
                    label: 'Locked',
                    value: false,
                    disabled: true,
                  }}
                  onChange={setEnableUpdateRank}
                />
              </div>
            </div>
          }
          draggableContentDir={false}
          items={getItems()}
        />
      </Widget>
    </div>
  )
}

const pipeInst: Pipe<{}, typeof StatusEditingView> = pipe()

export const StatusEditing: React$AbstractComponent<any, any> = pipeInst
  .connect((state, props) => {
    return {
      progressionStatus: map(getResources(state, 'progressionStatus')),
    }
  })
  .request(() => resources.progressionStatus.fetchAll())
  // $FlowFixMe[prop-missing] $FlowFixMe Error when updating flow
  .render(({ match, location, ...props }) => <StatusEditingView {...props} />)
