/** @flow */
import type { AssetCustomAttributeValue } from 'app/core/types'

export const sortCategories = (
  attributCategory: ?AssetCustomAttributeValue,
): $ElementType<AssetCustomAttributeValue, 'values'> => {
  const knownCategories = ['set', 'sets', 'light', 'lights', 'character', 'characters', 'prop', 'props', 'fx', 'pipeline', 'shaders_fx', 'compo', 'matte', 'mattes', '2d_animation', '2d_matte', 'modules', 'divers', 'ribarchives'] // prettier-ignore
  const sortedCategories = []
  let endOfArray = knownCategories.length + 1

  if (attributCategory && attributCategory.values) {
    attributCategory.values.forEach((category) => {
      if (typeof category !== 'string') {
        throw new Error('[Error] The category is not of type string.')
      }

      const index = knownCategories.indexOf(category.toLowerCase())
      if (index !== -1) sortedCategories[index] = category
      else {
        sortedCategories[endOfArray] = category
        endOfArray += 1
      }
    })
  }

  return sortedCategories.filter((_) => _)
}
