var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    loading: false,
    apiLoading: false,
    data: [],
    searchInput: '',
    showLegend: true,
    size: 3,
    error: null,
    message: 'Please enter a search',
};
export const searchAssets = createAsyncThunk('library/searchAssets', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ query, projectId, size = 200 }, { fulfillWithValue, rejectWithValue }) {
    try {
        const { assets } = yield api.search({ query, fetchObjects: true, size, projects: [projectId], scope: ['assets'] });
        return fulfillWithValue(assets);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { actions, reducer } = createSlice({
    name: 'library',
    initialState,
    reducers: {
        setSearchInput: (state, { payload }) => {
            const trimmedStr = payload.trimStart();
            state.loading = !!trimmedStr;
            state.searchInput = trimmedStr;
            if (!payload) {
                state.data = [];
                state.message = 'Please enter a search';
            }
        },
        toggleShowLegend: (state) => {
            state.showLegend = !state.showLegend;
        },
        setSize: (state, { payload }) => {
            const newSize = payload === 3 ? 6 : payload + 2;
            state.size = newSize;
        },
        setLoading: (state) => {
            state.loading = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(searchAssets.pending, (state) => {
            state.apiLoading = true;
        });
        builder.addCase(searchAssets.fulfilled, (state, { payload }) => {
            const { hits, objects } = payload;
            const updatedObjects = hits.map((hit) => {
                const relatedObj = objects.find((obj) => obj.id === hit._id);
                return Object.assign({ highlight: hit.highlight }, relatedObj);
            });
            state.data = updatedObjects;
            state.apiLoading = false;
            state.loading = false;
            state.message = 'Your search gave no results';
        });
        builder.addCase(searchAssets.rejected, (state, { payload }) => {
            state.error = payload;
            state.apiLoading = false;
            state.loading = false;
        });
    },
});
export const { setSearchInput, toggleShowLegend, setSize } = actions;
export default reducer;
