// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-PaginatedComponent-___PaginatedComponent-module__pagination {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 40px;
}

.app-components-PaginatedComponent-___PaginatedComponent-module__container {
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app-components-PaginatedComponent-___PaginatedComponent-module__contentContainer {
  max-height: calc(100% - 40px);
  height: calc(100% - 40px);
  overflow-x: auto;
}

.app-components-PaginatedComponent-___PaginatedComponent-module__navBar {
  margin-bottom: 25px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./app/components/PaginatedComponent/PaginatedComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".pagination {\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n  height: 40px;\n}\n\n.container {\n  background-color: #fff;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.contentContainer {\n  max-height: calc(100% - 40px);\n  height: calc(100% - 40px);\n  overflow-x: auto;\n}\n\n.navBar {\n  margin-bottom: 25px;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `app-components-PaginatedComponent-___PaginatedComponent-module__pagination`,
	"container": `app-components-PaginatedComponent-___PaginatedComponent-module__container`,
	"contentContainer": `app-components-PaginatedComponent-___PaginatedComponent-module__contentContainer`,
	"navBar": `app-components-PaginatedComponent-___PaginatedComponent-module__navBar`
};
export default ___CSS_LOADER_EXPORT___;
