// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Tree-___TreeRow-module__container {
  display: flex;
  align-items: center;
  flex-direction: row;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: background-color 0.2s;
  margin: 2px 0;
  padding-right: 5px;
  height: 20px;
}

  .app-components-Tree-___TreeRow-module__container.app-components-Tree-___TreeRow-module__highlighted > * {
    font-weight: bold;
    font-size: 1.03em;
  }

.app-components-Tree-___TreeRow-module__label {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  padding-left: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 3px;
}

.app-components-Tree-___TreeRow-module__label.app-components-Tree-___TreeRow-module__removed {
    text-decoration: line-through;
  }

.app-components-Tree-___TreeRow-module__label:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

.app-components-Tree-___TreeRow-module__label:hover .app-components-Tree-___TreeRow-module__icon {
      color: var(--colors-black);
    }

.app-components-Tree-___TreeRow-module__label .app-components-Tree-___TreeRow-module__icon {
    color: var(--colors-grey);
  }

.app-components-Tree-___TreeRow-module__arrowContainer {
  display: flex;
  margin-left: 2px;
  cursor: pointer;
  min-width: 21px;
  max-width: 21px;
  padding-left: 5px;
  justify-content: center;
}

.app-components-Tree-___TreeRow-module__ghostArrow {
  width: 25px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Tree/TreeRow.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,iCAAiC;EACjC,aAAa;EACb,kBAAkB;EAClB,YAAY;AAMd;;EAJE;IACE,iBAAiB;IACjB,iBAAiB;EACnB;;AAGF;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AAgBpB;;AAdE;IACE,6BAA6B;EAC/B;;AAEA;IACE,oCAAoC;EAItC;;AAHE;MACE,0BAA0B;IAC5B;;AAGF;IACE,yBAAyB;EAC3B;;AAGF;EACE,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  user-select: none;\n  transition: background-color 0.2s;\n  margin: 2px 0;\n  padding-right: 5px;\n  height: 20px;\n\n  &.highlighted > * {\n    font-weight: bold;\n    font-size: 1.03em;\n  }\n}\n\n.label {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  cursor: pointer;\n  width: 100%;\n  padding-left: 3px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  border-radius: 3px;\n\n  &.removed {\n    text-decoration: line-through;\n  }\n\n  &:hover {\n    background-color: rgba(0, 0, 0, 0.1);\n    .icon {\n      color: var(--colors-black);\n    }\n  }\n\n  .icon {\n    color: var(--colors-grey);\n  }\n}\n\n.arrowContainer {\n  display: flex;\n  margin-left: 2px;\n  cursor: pointer;\n  min-width: 21px;\n  max-width: 21px;\n  padding-left: 5px;\n  justify-content: center;\n}\n\n.ghostArrow {\n  width: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Tree-___TreeRow-module__container`,
	"highlighted": `app-components-Tree-___TreeRow-module__highlighted`,
	"label": `app-components-Tree-___TreeRow-module__label`,
	"removed": `app-components-Tree-___TreeRow-module__removed`,
	"icon": `app-components-Tree-___TreeRow-module__icon`,
	"arrowContainer": `app-components-Tree-___TreeRow-module__arrowContainer`,
	"ghostArrow": `app-components-Tree-___TreeRow-module__ghostArrow`
};
export default ___CSS_LOADER_EXPORT___;
