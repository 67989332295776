// @flow
/**
 *
 * @param {Array} items
 * @param {string} key
 *
 * Example:
 *
 *   const list = [{
 *       name: 'a',
 *       items: [
 *           {
 *               name: 'b'
 *               items: [
 *                   { name: 'c' }
 *                   { name: 'd' }
 *               ]
 *           }
 *       ]
 *   }]
 *
 *   flattendTree(list, 'items');
 */
const flattenTree = (items: Array<Object>, key: string): Array<Object> => {
  let output = []
  items.forEach(({ [key]: nestedItems, ...restItem }) => {
    output.push(restItem)
    if (nestedItems.length) {
      output = [...output, ...flattenTree(nestedItems, key)]
    }
  })
  return output
}

export default flattenTree
