// @flow

import React, { Fragment } from 'react'
import cx from 'classnames'
import { map, forEach } from 'lodash'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'
import { ModalAssetsRelations } from 'app/containers/AssetsRelations/ModalAssetsRelations.jsx'
import type { Asset, ID, AssetTypes } from 'app/core/types'
import type { DefaultCellProps, Column, Cell, PrefsProps } from 'app/components/Table/types.js'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'

import { groupingFns } from './groupingFns.jsx'
import classes from './CellAssetsSelect.module.scss'

type CellAssetsSelectProps = {|
  ...DefaultCellProps,
  multiple?: boolean,
  assetTypes?: Array<AssetTypes>,
  adornment?: (cell: Cell, isRowExpanded: boolean, prefs: PrefsProps) => ?React$Element<any>,
  placeholder: string,
  getResourceId?: (cell: Cell) => ID,
  foreignKey?: string,
  showIcon?: boolean,
|}

type ReadProps = {|
  value: { [id: ID]: Asset },
  showIcon?: boolean,
  adornment?: React$Node,
|}

export function Read({ value, showIcon, adornment }: ReadProps): React$Node {
  if (!value) return null

  return (
    <div className={classes.readContainer}>
      <div className={classes.read}>
        {adornment}
        {map(value, (asset: Asset, key: string) => {
          if (!asset) return null
          return (
            <Fragment key={key}>
              <div className={classes.item} style={adornment ? { margin: '20px 0 10px 0' } : undefined}>
                {showIcon ? <FontIcon icon={assetIcons(asset.assetType)} className="marginRight5" /> : null}{' '}
                <div className={classes.itemLabel} style={{ maxWidth: showIcon ? 'calc(100% - 16px)' : undefined }}>
                  {asset.name}
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export const getCellAssetSelectValue = (cellValue?: Object): { [id: ID]: Asset } => {
  const value: { [id: ID]: Asset } = {}

  if (!cellValue);
  else if (cellValue.name) value[cellValue.id] = cellValue
  else {
    forEach(cellValue, ({ assetInst }) => {
      if (!assetInst) return
      value[assetInst.id] = assetInst
    })
  }
  return value
}

export const CellAssetsSelect = ({
  multiple,
  assetTypes,
  placeholder,
  foreignKey,
  getResourceId,
  adornment,
  showIcon,
  ...data
}: CellAssetsSelectProps): Column => ({
  cellType: 'CellAssetsSelect',
  Cell: (instance) => {
    const { cell, prefs } = instance
    const { minLineHeight, maxLineHeight } = prefs
    const { value: assets, getCellProps } = cell
    const { isRowExpanded, edition } = getCellProps()
    const { endEdit, save, isBeingEdit } = edition

    const value = getCellAssetSelectValue(assets)

    return (
      <div
        className={cx(classes.container, { [classes.isRowExpanded]: isRowExpanded })}
        style={{ maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2 }}
      >
        {isBeingEdit && (!multiple || !foreignKey || !getResourceId) ? (
          <div className={classes.edit}>
            <AssetsSelect
              assetTypes={assetTypes}
              placeholder={placeholder}
              autoFocus={true}
              style={{ position: 'relative', zIndex: 1, border: 'none' }}
              onChange={({ data } = {}) => {
                cell.focusCell()
                save(data)
              }}
              onBlur={endEdit}
              noStyle={true}
            />
          </div>
        ) : (
          <Read value={value} showIcon={showIcon} adornment={adornment && adornment(cell, isRowExpanded, prefs)} />
        )}
      </div>
    )
  },
  EditModal:
    !multiple || !foreignKey || !getResourceId
      ? undefined
      : (instance) => {
          const { cell, cellPositionLabel } = instance
          const { value: assets, getCellProps } = cell
          const { edition } = getCellProps()
          const { endEdit, save } = edition

          return (
            <ModalAssetsRelations
              assetTypes={assetTypes}
              placeholder={placeholder}
              assets={assets}
              foreignKey={foreignKey}
              ressourceID={getResourceId && getResourceId(cell)}
              onClose={endEdit}
              onSave={save}
              exponentTitle={cellPositionLabel}
            />
          )
        },
  groupingFns,
  width: 180,
  ...data,
})
