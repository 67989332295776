/** @flow */
import deprecated from './deprecated'
import color from './color'
import { assetType, assetTypesList } from './assetType'
import customValidation from './customValidation'

export default {
  deprecated,
  color,
  assetType,
  assetTypesList,
  customValidation,
}
