// @flow
import * as React from 'react'
import Widget from 'app/components/Widget/Widget.jsx'
import { router } from 'app/containers/Assets/AssetDetail/router'
import AssetsTreeSearch from 'app/containers/Assets/AssetsTree/AssetsTreeSearch.jsx'

export default class Assets extends React.PureComponent<void> {
  onClickAsset: (any) => void = ({ metadata }: *) => {
    router.goTo('index', { assetId: metadata.id }, { rightPanel: true })
  }

  render(): React$Node {
    return (
      <Widget style={{ display: 'flex' }}>
        <AssetsTreeSearch
          assetTypesCreationAllowed={['ep', 'fo', 'mo', 'pj', 'sh', 'mi']}
          onClickItem={this.onClickAsset}
          assetTypes={['ep', 'fo', 'mo', 'sh', 'gp', 'us', 'pj', 'of', 'ph', 'lc', 'mi']}
        />
      </Widget>
    )
  }
}
