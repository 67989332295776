var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx';
import resources from 'app/store/resources';
import FormData from 'app/components/Form/FormData/FormData.jsx';
import { ModalConfirmForm } from 'app/components/Modal';
import { useHistory } from 'react-router-dom';
const ModalGroups = (props) => {
    const { projectId } = props, rest = __rest(props, ["projectId"]);
    const history = useHistory();
    const onSave = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const group = {
            name: data.name,
            assetType: 'gp',
            attributes: {},
            project: projectId,
            parent: data.parent.value,
        };
        const res = yield resources.assets.create(group);
        history.push(projectId && ((_b = (_a = res === null || res === void 0 ? void 0 : res.plainResponse) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id)
            ? `/projects/${projectId}/settings/groups/users-by-group/${res.plainResponse[0].id}`
            : '/admin/groups/');
        return res;
    });
    return (_jsx(ModalConfirmForm, Object.assign({ title: "Add a group", validateLabel: "Create group" }, rest, { children: _jsx(FormData, { properties: [
                {
                    key: 'name',
                    label: 'Name',
                    type: FormData.types.string,
                    elementProps: { isRequired: true },
                },
                {
                    key: 'parent',
                    label: 'Parent',
                    type: 'autocomplete',
                    element: AssetsSelect,
                    elementProps: {
                        assetTypes: ['fo'],
                        placeholder: 'Select a folder',
                        isRequired: true,
                        showAssetCateg: true,
                        showAssetParent: true,
                    },
                },
            ], onSave: onSave, flashNotifSuccessLabel: "Group created" }) })));
};
export default ModalGroups;
