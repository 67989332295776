/** @flow */
import React from 'react'
import cx from 'classnames'
import type { DefaultCellProps, Column } from 'app/components/Table/types.js'
import { dynamicApprovalStatus, dynamicApprovalColors } from 'app/core/constants/dynamicApprovalStatus.js'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'

import classes from './CellAggregatedDynamicApproval.module.scss'

type Params = {|
  ...DefaultCellProps,
|}

function CellAggregatedDynamicApprovalView(params) {
  const { values } = params

  if (!values) return null

  return (
    <div className="grid col2" style={{ gridRowGap: 0, gridColumnGap: 2 }}>
      {values.map((value) => {
        if (!value) return null

        const { status, count } = value
        const statusName = dynamicApprovalStatus[status]
        const color = dynamicApprovalColors[status]

        return [
          <div className="flex" key={`${status}`}>
            <div
              className={cx(classes.status, statusName ? undefined : 'bold grey')}
              style={statusName ? { backgroundColor: color, color: getColorFromBackground(color) } : undefined}
            >
              {statusName || 'null'}
            </div>
          </div>,
          <div key={`${status}_${String(count)}`} className={classes.count}>
            x{count}
          </div>,
        ]
      })}
    </div>
  )
}

export const CellAggregatedDynamicApproval = (data: Params): Column => {
  return {
    cellType: 'CellAggregatedAttribute',
    Cell: (instance) => {
      const { cell, prefs } = instance
      const { value, getCellProps } = cell
      const { isRowExpanded } = getCellProps()
      const { minLineHeight, maxLineHeight } = prefs

      const values: Array<?{ status: $Keys<typeof dynamicApprovalStatus>, count: number }> = value
      return (
        <div style={{ maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2, overflowY: 'auto' }}>
          <CellAggregatedDynamicApprovalView values={values} />
        </div>
      )
    },
    canGroupBy: false,
    width: 220,
    ...data,
  }
}
