/** @flow */
import reduce from 'lodash/reduce'
import type { Store } from 'app/store'
import type { StoreResourceName, StoreResourcesActions, GetResourcesActions } from 'app/core/types'
import { createResource } from './createResource'

export type Resource = (getResources: GetResourcesActions) => Object
type ResourcesSchema = { [resourceId: StoreResourceName]: Resource }
type CreateResources = (store: Store, resourcesSchema: ResourcesSchema) => StoreResourcesActions

export const createResources: CreateResources = (store, resourcesSchema): StoreResourcesActions => {
  let resources: StoreResourcesActions

  const getResources: GetResourcesActions = () => resources

  // $FlowFixMe
  resources = (reduce(
    resourcesSchema,
    (acc: StoreResourcesActions, resource: Resource, resourceType: StoreResourceName) => {
      // $FlowFixMe[prop-missing]
      acc[resourceType] = createResource(store, getResources, resourceType, resource)
      return acc
    },
    {},
  ): StoreResourcesActions)

  return resources
}
