/** @flow */
import React, { forwardRef } from 'react'
import MUIMenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import type { InputProps } from 'app/core/types'

export type MenuItemProps = {|
  children: React$Node<any>, //		Menu item contents.
  component?: React$ElementType, //	'li'	The component used for the root node. Either a string to use a HTML element or a component.
  dense?: boolean, //		If true, compact vertical padding designed for keyboard and mouse input will be used.
  disableGutters?: boolean, //	false	If true, the left and right padding is removed.
  ListItemClasses?: Object, //		classes prop applied to the ListItem element.
  onKeyUp?: (e: KeyboardEvent) => void,
  value: number,
  ...InputProps,
|}

const useStyles: () => { [key: string]: string } = makeStyles(() => ({
  root: {
    height: undefined,
    fontSize: '16px',
    padding: '5px 15px',
    '&:hover': {
      boxShadow: '0 0 0 1000px rgba(0,0,0,0.1) inset',
    },
    '&:focus': {
      boxShadow: '0 0 0 1000px rgba(0,0,0,0.2) inset',
    },
  },
}))

export const MenuItem: React$AbstractComponent<*, *> = forwardRef<MenuItemProps, *>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { onKeyUp, ...rest } = props

  const _onKeyUp = (event) => {
    if (onKeyUp) {
      onKeyUp(event)
      event.nativeEvent.stopImmediatePropagation()
    }
  }

  return <MUIMenuItem onKeyUp={_onKeyUp} {...rest} ref={ref} classes={useStyles()} />
})
