// @flow

import React, { useCallback, useMemo, useState } from 'react'
import { map } from 'lodash'
import resources from 'app/store/resources'
import { MUIButton, FormData } from 'app/components/Form'
import { ObjectEntries } from 'app/libs/flowPolyfills'
import { ModalTrigger, ModalConfirmForm, confirmDelete } from 'app/components/Modal'
import { Table } from 'app/components/Table'
import { CellText, CellSelect, CellRichText, CellLink } from 'app/components/Table/Cells'
import vars from 'app/styles/vars.js'

import { messageTypes, scope } from './utils'

const tableId = 'table-permissionGroup'

type Props = {||}

export default function (props: Props): React$Node {
  const [reloadData, _setReloadData] = useState()
  const setReloadData = useCallback(() => _setReloadData(Date.now()), [_setReloadData])

  const messageTypeOption = [
    { label: 'Choose type', value: '', disabled: true },
    ...ObjectEntries(messageTypes).map(([value, data]) => ({
      label: data.label,
      value,
      backgroundColor: data.backgroundColor,
    })),
  ]

  const scopeOptions = [
    { label: 'Choose scope', value: '', disabled: true },
    ...Object.entries(scope).map(([value, label]) => ({ label, value })),
  ]

  function onDelete(row: Object) {
    confirmDelete({
      title: 'Delete',
      render: (
        <p>
          Do you want to delete <b>{row.original.title}</b> ?
        </p>
      ),
      onValidate: () => {
        return resources.messages.delete(row.original.id).then(() => setReloadData())
      },
      validateMessage: 'Message deleted',
    })
  }

  const columns = useMemo(() => {
    return [
      {
        Header: ' ',
        id: 'rows',
        columns: [
          CellText({
            id: 'title',
            readOnly: false,
            Header: 'Title',
            accessor: 'title',
            width: 200,
            actions: () => ['edit', 'copy'],
            save: {
              resource: 'messages',
              formatData: (item, value, cell, instance, type) => ({ ...item, title: value }),
            },
          }),
          CellRichText({
            id: 'text',
            readOnly: false,
            Header: 'Text',
            accessor: 'text',
            actions: () => ['edit', 'copy', 'past'],
            save: {
              resource: 'messages',
              formatData: (item, value, cell, instance, type) => ({ ...item, text: value }),
            },
          }),
          CellSelect({
            id: 'messageType',
            readOnly: false,
            Header: 'Message Type',
            width: 100,
            actions: () => ['edit', 'copy'],
            accessor: 'messageType',
            options: () =>
              ObjectEntries(messageTypes).map(([value, data]) => ({
                label: data.label,
                value: parseInt(value, 10),
                backgroundColor: data.backgroundColor,
              })),
            save: {
              resource: 'messages',
              formatData: (item, value, cell, instance, type) => ({ ...item, messageType: value }),
            },
          }),
          CellSelect({
            id: 'Scope',
            readOnly: false,
            Header: 'Scope',
            actions: () => ['edit', 'copy'],
            width: 130,
            accessor: 'scope',
            options: () => {
              const options = map(scope, (label, value) => ({ value, label }))
              return options
            },
            save: {
              resource: 'messages',
              formatData: (item, value, cell, instance, type) => ({ ...item, scope: value }),
            },
          }),
          CellText({
            id: 'startDate',
            readOnly: false,
            inputProps: {
              type: 'date',
            },
            Header: 'Start Date',
            accessor: 'startDate',
            sortingKey: 'startDate',
            width: 150,
            actions: () => ['edit', 'copy'],
            save: {
              resource: 'messages',
              formatData: (item, value, cell, instance, type) => ({ ...item, startDate: value }),
            },
          }),
          CellText({
            id: 'endDate',
            readOnly: false,
            Header: 'End Date',
            inputProps: {
              type: 'date',
            },
            accessor: 'endDate',
            sortingKey: 'endDate',
            width: 150,
            actions: () => ['edit', 'copy'],
            save: {
              resource: 'messages',
              formatData: (item, value, cell, instance, type) => ({ ...item, endDate: value }),
            },
          }),
          CellLink({
            id: 'delete',
            readOnly: false,
            Header: 'Delete',
            onClick: (row) => onDelete(row),
            width: 80,
            accessor: () => <MUIButton icon="trash" bgColor={vars.colors.red} />,
          }),
        ],
      },
    ]
  }, [])

  const modal = useMemo(
    () => (
      <ModalTrigger
        modal={
          <ModalConfirmForm title="Add message">
            <FormData
              onSave={async (data) => {
                const message = {
                  title: data.title,
                  text: data.text,
                  messageType: data.messageType,
                  scope: data.scope,
                  startDate: data.startDate,
                  endDate: data.endDate,
                }

                const res = await resources.messages.create(message)
                setReloadData()
                return res
              }}
              flashNotifSuccessLabel="Message created"
              defaultData={{
                startDate: new Date(),
                endDate: '',
              }}
              properties={[
                {
                  key: 'title',
                  label: 'Title (not displayed)',
                  type: 'string',
                  elementProps: { isRequired: true },
                },
                {
                  key: 'text',
                  label: 'Text',
                  type: 'richtext',
                  elementProps: { isRequired: true },
                },
                {
                  key: 'messageType',
                  label: 'Message Type',
                  type: 'select',
                  elementProps: {
                    fullWidth: true,
                    isRequired: true,
                    options: messageTypeOption,
                    placeholder: 'Select a message type',
                  },
                },
                {
                  key: 'scope',
                  label: 'Scope',
                  type: 'select',
                  elementProps: {
                    fullWidth: true,
                    isRequired: true,
                    options: scopeOptions,
                    placeholder: 'Select a scope',
                  },
                },
                {
                  key: 'startDate',
                  label: 'Start Date',
                  type: 'dateTime',
                  elementProps: {
                    isRequired: true,
                  },
                },
                {
                  key: 'endDate',
                  label: 'end Date',
                  type: 'dateTime',
                  elementProps: {
                    isRequired: true,
                  },
                },
              ]}
            />
          </ModalConfirmForm>
        }
      >
        <MUIButton icon="fas-plus">Add Message</MUIButton>
      </ModalTrigger>
    ),
    [],
  )

  return (
    <div style={{ flex: 1, display: 'flex', width: '100%', height: '100%' }}>
      <Table
        tableId={tableId}
        resourcesParams={{
          resourceType: 'messages',
          requestName: 'fetchAll',
          key: reloadData,
        }}
        extendToolbar={modal}
        columns={columns}
      />
    </div>
  )
}
