/** @flow */
import React from 'react'
import { isNumber } from 'lodash'
import { getDurationRange } from 'app/components/Table/utils'

import type { Column } from '../../types'

export const groupingFns: $ElementType<Column, 'groupingFns'> = {
  updatedAt: {
    fn: (rows, columnId, instance) => {
      const values = rows
        .sort((prev, row) => prev.values[columnId] - row.values[columnId])
        .reduce((prev, row) => {
          const resKey = String(
            isNumber(Number(row.values[columnId])) ? getDurationRange(Number(row.values[columnId])) : undefined,
          )
          prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : []
          prev[resKey].push(row)
          return prev
        }, {})

      return values
    },
    label: 'Timecode range',
    headerRow: (cell) => (isNumber(cell.value) ? <div className="bold">{getDurationRange(cell.value)}</div> : null),
  },
}
