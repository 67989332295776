/** @flow */
import React, { useEffect } from 'react'
// import type { Match, StoreState } from 'app/core/types'
// import { useSelector } from 'react-redux'
import resources from 'app/store/resources'
// import { getResources } from 'app/store/selectors'

type Props = {|
  // match: Match,
|}

export function Teams(props: Props): React$Node {
  // const { match } = props
  // const { episodeId, type, projectId } = match.params

  useEffect(() => {
    resources.teams.fetchAll()
    resources.projectDepartments.fetchAll()
  }, [])

  // const projectDepartments = useSelector<StoreState, *>(state => getResources(state, ''))
  // const teams = useSelector<StoreState, *>(state => state.teams.resources)

  return (
    <div>
      C{`'`}est trop <pre>null</pre>
    </div>
  )
}
