import { types } from 'app/store/actions/user'
import { reducerSetProperies } from 'app/libs/reduxSetProperty'

const initialState = {
  username: null,
  email: null,
  first_name: null,
  last_name: null,
  assetInst: null,
  asset: null,
  permissions: {},
  projects: [],
  isAdmin: false,
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PROPERTY:
      return reducerSetProperies(state, initialState, action)

    case types.ADD:
      return action.user

    default:
      return state
  }
}

export default user
