/** @flow */
import { map, forEach, find, reduce } from 'lodash'
import type {
  ResourcesList,
  TrackingSchemaAccess,
  TrackingSchema,
  Option,
  ID,
  Schema,
  Step,
  Attribute,
  DynamicApproval,
} from 'app/core/types'
import resources from 'app/store/resources'
import type { SchemaColumnSettings, SchemaColumn, SchemaGroup } from 'app/core/types/Schema'
import type { TrackingSchemaColumn } from 'app/core/types/TrackingSchema'

type Item = {|
  ...$Shape<Step>,
  ...$Shape<Attribute>,
  ...$Shape<DynamicApproval>,
|}

export type TrackingSchemaGroupSettings = {|
  cellParams: Object,
  color?: string,
  type: string,
  readOnly?: boolean,
|}

export type TrackingSchemaSettings = {|
  restrictedActions: ('assetSheet' | 'taskSheet' | 'stats' | 'exports')[],
|}

export type TrackingSchemaColumnSettings = SchemaColumnSettings<{|
  cellParams: Object,
  columnType: string,
  timestamp: string, // To delete from trackingSchema and replace by key
  length?: boolean, // To delete
  type: string, // To delete
  attr?: string, // To delete
|}>

export function trackingSchemaToSchema(
  trackingSchema: TrackingSchema,
): Schema<Item, TrackingSchemaGroupSettings, TrackingSchemaColumnSettings, TrackingSchemaSettings> {
  return {
    id: trackingSchema.id,
    name: trackingSchema.name,
    settings: trackingSchema.settings,
    schema: reduce(
      trackingSchema.schema,
      (schemaGroups, group) => {
        const { key, groupName, items, ...settings } = group

        const schemaGroup: SchemaGroup<Item, TrackingSchemaGroupSettings, TrackingSchemaColumnSettings> = {
          key: String(key),
          name: groupName,
          settings,
          items: group.items.reduce((columns, column) => {
            const {
              key,
              name,
              type,
              timestamp,
              color,
              columnName,
              description,
              fixed,
              fixable,
              hiddenable,
              isVisible,
              hidden,
              readOnly,
              cellParams,
              columnType,
              length,
              contentType,
              attr,
              assetType,
              onEditEditorType,
              parentType,
              ...item
            } = column

            const schemaColumn: SchemaColumn<Item, TrackingSchemaColumnSettings> = {
              key,
              name: columnName || name,
              settings: {
                name,
                type,
                color,
                attr,
                columnName,
                timestamp,
                description,
                fixed,
                fixable,
                contentType,
                hiddenable,
                isVisible,
                hidden,
                readOnly,
                columnType,
                cellParams,
                length,
                assetType,
                onEditEditorType,
                parentType,
              },
              data: { ...item, assetType, name },
            }

            columns.push(schemaColumn)

            return columns
          }, []),
        }

        schemaGroups.push(schemaGroup)
        return schemaGroups
      },
      [],
    ),
  }
}

export function schemaToTrackingSchema(
  schema: Schema<Item, TrackingSchemaGroupSettings, TrackingSchemaColumnSettings, TrackingSchemaSettings>,
  trackingSchema: TrackingSchema,
): TrackingSchema {
  return {
    ...trackingSchema,
    schema: schema.schema.reduce((schema, group) => {
      const { key, name, items, settings } = group

      schema.push({
        key,
        groupName: name,
        type: settings.type,
        cellParams: settings.cellParams,
        color: settings.color,
        readOnly: Boolean(settings.color),
        items: items.reduce((columns, column) => {
          const { key, settings, data } = column

          const trackingSchemaItem: TrackingSchemaColumn<Item> = {
            ...data,
            ...settings,
            key,
          }

          columns.push(trackingSchemaItem)

          return columns
        }, []),
      })

      return schema
    }, []),
  }
}

export const updateTrackingSchemaAccess = (
  originalTrackingSchemaAccesses: TrackingSchemaAccess[] | ResourcesList<TrackingSchemaAccess>,
  linkedAssets: Array<Option>,
  originalTrackingSchema: TrackingSchema,
  newTrackingSchema: TrackingSchema,
): Promise<any> => {
  if (originalTrackingSchema.isExternal && !newTrackingSchema.isExternal) {
    return resources.trackingSchemaAccesses.delete(
      map(originalTrackingSchemaAccesses, (tsa) => tsa.id),
      { batch: true },
    )
  }
  if (!originalTrackingSchema.isExternal && newTrackingSchema.isExternal) {
    return resources.trackingSchemaAccesses.create(
      map(linkedAssets, (tsa) => ({ asset: tsa.value, trackingSchema: originalTrackingSchema.id })),
      { batch: true },
    )
  }

  const toDelete: Array<ID> = []
  const toCreate: Array<$Shape<TrackingSchemaAccess>> = []

  forEach(originalTrackingSchemaAccesses, (tsa) => {
    if (!linkedAssets.find((opt) => opt.value === tsa.asset)) {
      toDelete.push(tsa.id)
    }
  })
  linkedAssets.forEach((opt) => {
    if (!find(originalTrackingSchemaAccesses, (tsa) => opt.value === tsa.asset)) {
      toCreate.push({ asset: opt.value, trackingSchema: originalTrackingSchema.id })
    }
  })

  return Promise.all([
    toCreate.length > 0 ? resources.trackingSchemaAccesses.create(toCreate, { batch: true }) : null,
    toDelete.length > 0 ? resources.trackingSchemaAccesses.delete(toDelete, { batch: true }) : null,
  ])
}
