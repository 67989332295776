/** @flow */
import React, { type Element, type Ref, RefObject } from 'react'
import type { InputProps } from 'app/core/types'
import type { InputProps as TSInputProps } from 'app/core/types/types'
import Checkbox from '@material-ui/core/Checkbox'

export interface TSMUICheckboxProps extends TSInputProps {
  checked?: boolean;
  checkedIcon?: Element<any>;
  classes?: {};
  color?: 'primary' | 'secondary' | 'default';
  disabled?: boolean;
  disableRipple?: boolean;
  icon?: Element<any>;
  id?: string;
  indeterminate?: boolean;
  indeterminateIcon?: Element<any>;
  inputProps?: Object;
  inputRef?: RefObject<any>;
  onChange?: (value: boolean) => any;
  required?: boolean;
  type?: string;
  size?: 'small' | 'medium';
  value?: boolean;
  className?: string;
  readOnly?: boolean;
}

export type MUICheckboxProps = {|
  ...InputProps,
  checked?: boolean,
  checkedIcon?: Element<any>,
  classes?: Object,
  color?: 'primary' | 'secondary' | 'default',
  disabled?: boolean,
  disableRipple?: boolean,
  icon?: Element<any>,
  id?: string,
  indeterminate?: boolean,
  indeterminateIcon?: Element<any>,
  inputProps?: Object,
  inputRef?: Ref<any>,
  onChange?: (value: boolean) => mixed,
  required?: boolean,
  type?: string,
  size?: 'small' | 'medium',
  value?: boolean,
  className?: string,
  readOnly?: boolean,
|}

export function MUICheckbox(props: MUICheckboxProps): React$Node {
  const { readOnly, disabled, onChange, color, style, ...rest } = props

  function _onChange(event: *) {
    return onChange && onChange(event.target.checked)
  }

  return (
    <Checkbox
      color={color || 'primary'}
      onChange={onChange ? _onChange : undefined}
      disabled={readOnly || disabled}
      style={{ padding: 0, ...style }}
      {...rest}
    />
  )
}
