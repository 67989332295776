// @flow

import upperFirst from 'lodash/upperFirst'
import round from 'lodash/round'

type ParamAsNumber = number
type ParamAsDurationFormat = {
  seconds?: number,
  minutes?: number,
  hours?: number,
  days?: number,
  months?: number,
  years?: number,
}

type Config = {
  hoursInDay: number,
}

export class Duration {
  time: number

  config: Config

  constructor(param: ParamAsNumber | ParamAsDurationFormat, config: Config = { hoursInDay: 24 }) {
    this.config = config
    if (typeof param === 'number') {
      this.time = param
    } else {
      this.time = this.convertDurationFromatToFullSeconds(param)
    }
  }

  convertFullSecondsToDurationFormat: (
    seconds: number,
    chunks: Array<string>,
  ) => {
    days: number,
    hours: number,
    minutes: number,
    months: number,
    seconds: number,
    years: number,
  } = (seconds, chunks) => {
    const { hoursInDay } = this.config
    let secondsRemaining = seconds

    const secondsPerMinutes = 60
    const secondsPerHours = secondsPerMinutes * 60
    const secondsPerDay = secondsPerHours * hoursInDay
    const secondsPerMonth = secondsPerDay * 30
    const secondsPerYear = secondsPerDay * 365

    const secondsPerChunk = {
      secondsPerMinutes,
      secondsPerHours,
      secondsPerDay,
      secondsPerMonth,
      secondsPerYear,
    }

    const output = {}
    let lastChunk: 'years' | 'months' | 'days' | 'hours' | 'minutes' | void

    if (chunks.includes('years')) {
      output.years = Math.floor(secondsRemaining / secondsPerChunk.secondsPerYear)
      secondsRemaining %= secondsPerChunk.secondsPerYear
      lastChunk = 'years'
    }

    if (chunks.includes('months')) {
      output.months = Math.floor(secondsRemaining / secondsPerChunk.secondsPerMonth)
      secondsRemaining %= secondsPerChunk.secondsPerMonth
      lastChunk = 'months'
    }

    if (chunks.includes('days')) {
      output.days = Math.floor(secondsRemaining / secondsPerChunk.secondsPerDay)
      secondsRemaining %= secondsPerChunk.secondsPerDay
      lastChunk = 'days'
    }

    if (chunks.includes('hours')) {
      output.hours = Math.floor(secondsRemaining / secondsPerChunk.secondsPerHours)
      secondsRemaining %= secondsPerChunk.secondsPerHours
      lastChunk = 'hours'
    }

    if (chunks.includes('minutes')) {
      output.minutes = Math.floor(secondsRemaining / secondsPerChunk.secondsPerMinutes)
      secondsRemaining %= secondsPerChunk.secondsPerMinutes
      lastChunk = 'minutes'
    }

    if (chunks.includes('seconds')) {
      output.seconds = secondsRemaining
    } else if (lastChunk) {
      const secondsPerLastChunk = `secondsPer${upperFirst(lastChunk)}`
      output[lastChunk] = round(output[lastChunk] + secondsRemaining / secondsPerChunk[secondsPerLastChunk], 1)
    }

    return output
  }

  convertDurationFromatToFullSeconds(params: ParamAsDurationFormat): number {
    if (!params) return 0

    const { seconds = 0, minutes = 0, hours = 0, days = 0, months = 0, years = 0 } = params

    const { hoursInDay } = this.config

    const secondsPerMinutes = 60
    const secondsPerHours = secondsPerMinutes * 60
    const secondsPerDay = secondsPerHours * hoursInDay
    const secondsPerMonth = secondsPerDay * 30
    const secondsPerYear = secondsPerDay * 365

    let time = seconds

    // add minutes
    time += minutes * secondsPerMinutes

    // add hours
    time += hours * secondsPerHours

    // add days
    time += days * secondsPerDay

    // add month
    time += months * secondsPerMonth

    // add year
    time += years * secondsPerYear

    return time
  }

  getTime(): number {
    return this.time
  }

  getData(chunks: Array<string> = ['years', 'months', 'days', 'hours', 'minutes', 'seconds']): {
    days: number,
    hours: number,
    minutes: number,
    months: number,
    seconds: number,
    years: number,
  } {
    return this.convertFullSecondsToDurationFormat(this.time, chunks)
  }

  isGratherThan(data: ParamAsDurationFormat): boolean {
    return this.time > this.convertDurationFromatToFullSeconds(data)
  }

  isLowerThan(data: ParamAsDurationFormat): boolean {
    return this.time < this.convertDurationFromatToFullSeconds(data)
  }

  isEqualTo(data: ParamAsDurationFormat): boolean {
    return this.time === this.convertDurationFromatToFullSeconds(data)
  }

  getString(): string {
    const time = this.getData()
    const { minutes, hours, days, months, years } = time
    return `${years ? `${years}y ` : ''}${months ? `${months}mo ` : ''}${days}d ${hours}h ${minutes}m`
  }
}
