// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellDuration-___CellDuration-module__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

.app-components-Table-Cells-CellDuration-___CellDuration-module__activityCommentBadge {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background-color: rgba(15, 0, 230, 0.452);
}

.app-components-Table-Cells-CellDuration-___CellDuration-module__activityCommentBadge:hover {
    background-color: rgba(15, 0, 230, 0.4);
  }
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellDuration/CellDuration.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,yCAAyC;AAI3C;;AAHE;IACE,uCAAuC;EACzC","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  position: relative;\n  z-index: 1;\n}\n\n.activityCommentBadge {\n  position: absolute;\n  top: 3px;\n  right: 3px;\n  width: 8px;\n  height: 8px;\n  border-radius: 6px;\n  background-color: rgba(15, 0, 230, 0.452);\n  &:hover {\n    background-color: rgba(15, 0, 230, 0.4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellDuration-___CellDuration-module__container`,
	"activityCommentBadge": `app-components-Table-Cells-CellDuration-___CellDuration-module__activityCommentBadge`
};
export default ___CSS_LOADER_EXPORT___;
