// @flow
import type { ID } from 'app/core/types'
import { useSelector } from 'react-redux'
import { userIsAdmin, userPermissions } from './utils/userPermissions'

export const permission = (
  actions: ?Array<string>,
  operator?: 'or' | 'and' = 'and',
  isAdmin?: boolean,
  projectId?: ?ID,
  // storePermissions is usefull for testing. It's only use in Permission component below
  // TO DO : update all permission calls by adding storePermissions paramter (get with redux)
  storePermissions?: any,
): boolean => {
  // isAdmin boolean allows to check components renders depending on permissions
  if (isAdmin || userIsAdmin() || !actions) return true
  if (actions.length === 0) return false

  const permissions = storePermissions ?? userPermissions(projectId)
  const authorized = []

  actions.forEach((action, index) => {
    if (permissions?.indexOf(action) === -1) authorized[index] = false
    else authorized[index] = true
  })

  if (operator === 'and' && authorized.indexOf(false) !== -1) return false
  if (operator === 'or' && authorized.indexOf(true) === -1) return false

  return true
}

type Props = {|
  children: React$Node,
  actions: ?Array<string>,
  projectId?: ?ID,
  isAdmin?: boolean,
  operator?: 'and' | 'or',
|}

export function Permission(props: Props): React$Node {
  const { children, actions, operator = 'and', isAdmin, projectId } = props
  const { admin: isAdminFromStore } = useSelector((state) => state.user)
  const { data: config } = useSelector((state) => state.projectConfig)
  const { id: projectIdFromStore } = useSelector((state) => state.project)
  const project = projectId || projectIdFromStore
  const userIsAdmin = isAdmin || isAdminFromStore
  const permissions = (config.ACTIONS[project] || []).concat(config.ACTIONS.default || [])

  if (!permission(actions, operator, userIsAdmin, project, permissions)) return null

  return children
}
