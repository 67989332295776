import React from 'react'
import PropTypes from 'prop-types'
import api from 'app/core/api/api.js'
import { MUIModal } from 'app/components/Modal'
import { getActionObjectTypeIdentifier, functionsByContentType } from 'app/core/utils/contentTypesUtils'
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx'

export default class Subscription extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    actionObject: PropTypes.object.isRequired,
    actionTypeId: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      usersToSubscribe: [],
    }
  }

  onChangeUsersToSubscribe = (value) => {
    this.setState({ usersToSubscribe: value })
  }

  save = async () => {
    const { actionTypeId, actionObject } = this.props
    const { usersToSubscribe } = this.state

    const promises = []

    if (usersToSubscribe.length) {
      const subscriptionsToCreate = usersToSubscribe.map(({ value }) => {
        return {
          targetType: actionTypeId,
          targetId: actionObject.id,
          user: value,
          actorOnly: false,
        }
      })
      subscriptionsToCreate.forEach((subscription) => {
        api.subscriptions.create(subscription).catch((e) => {})
      })
    }
    return Promise.all(promises)
  }

  render() {
    const { title, actionObject, actionTypeId, ...props } = this.props

    const assetLabel = functionsByContentType[getActionObjectTypeIdentifier(actionTypeId)].labelFeed(actionObject)

    const modalTitle = title || <span>Register users to {assetLabel}</span>

    return (
      <MUIModal {...props} title={modalTitle} validateLabel="Register" onValidate={this.save}>
        <AssetsSelect
          assetTypes={['us']}
          placeholder="Select users to register"
          value={this.state.usersToSubscribe}
          onChange={this.onChangeUsersToSubscribe}
          multiple={true}
        />
      </MUIModal>
    )
  }
}
