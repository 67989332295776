// @flow
import map from 'lodash/map'
import sum from 'lodash/sum'
import type { StoreState } from 'app/core/types'
import { DurationRead } from 'app/components/Duration/Duration.tsx'
import { getResources } from './getResources.js'

export const getTaskTimeReal = (state: StoreState, taskId: string): React$Node => {
  const task = getResources(state, 'tasks', taskId, ['activitiesInst'])
  const duration = sum(map(task.activitiesInst, (activity) => activity.duration))
  return DurationRead({ value: duration })
}
