// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection {
  width: 58%;
}

  .app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection .app-components-OrganizationChart-DetailPage-___MainSection-module__mainList {
    border: 3px solid black;
    padding: 50px 20px 30px 20px;
    margin-block-start: 0;
    width: 90%;
    margin: 0 auto;
  }

  .app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection .app-components-OrganizationChart-DetailPage-___MainSection-module__mainList .app-components-OrganizationChart-DetailPage-___MainSection-module__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  .app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection .app-components-OrganizationChart-DetailPage-___MainSection-module__mainList .app-components-OrganizationChart-DetailPage-___MainSection-module__studio {
      display: flex;
      justify-content: flex-start;
      font-size: 22px;
      align-items: center;
      font-weight: bold;
    }

  .app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection .app-components-OrganizationChart-DetailPage-___MainSection-module__mainList .app-components-OrganizationChart-DetailPage-___MainSection-module__studio .app-components-OrganizationChart-DetailPage-___MainSection-module__mapIcon {
        padding-right: 10px;
      }

  .app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection .app-components-OrganizationChart-DetailPage-___MainSection-module__mainList .app-components-OrganizationChart-DetailPage-___MainSection-module__studio .app-components-OrganizationChart-DetailPage-___MainSection-module__mapIcon .app-components-OrganizationChart-DetailPage-___MainSection-module__icon {
          height: 40px;
          width: 40px;
        }

  .app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection .app-components-OrganizationChart-DetailPage-___MainSection-module__mainList .app-components-OrganizationChart-DetailPage-___MainSection-module__listItems {
      padding: 20px 20px;
    }

  .app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection .app-components-OrganizationChart-DetailPage-___MainSection-module__mainList .app-components-OrganizationChart-DetailPage-___MainSection-module__listItems .app-components-OrganizationChart-DetailPage-___MainSection-module__listItemsTitle {
        font-weight: bold;
        padding-right: 10px;
      }

  .app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection .app-components-OrganizationChart-DetailPage-___MainSection-module__mainList .app-components-OrganizationChart-DetailPage-___MainSection-module__textArea {
      padding: 10px;
      resize: none;
    }
`, "",{"version":3,"sources":["webpack://./app/components/OrganizationChart/DetailPage/MainSection.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;AA8CZ;;EA5CE;IACE,uBAAuB;IACvB,4BAA4B;IAC5B,qBAAqB;IACrB,UAAU;IACV,cAAc;EAsChB;;EApCE;MACE,aAAa;MACb,8BAA8B;MAC9B,mBAAmB;IACrB;;EAEA;MACE,aAAa;MACb,2BAA2B;MAC3B,eAAe;MACf,mBAAmB;MACnB,iBAAiB;IAUnB;;EARE;QACE,mBAAmB;MAMrB;;EAJE;UACE,YAAY;UACZ,WAAW;QACb;;EAIJ;MACE,kBAAkB;IAMpB;;EAJE;QACE,iBAAiB;QACjB,mBAAmB;MACrB;;EAGF;MACE,aAAa;MACb,YAAY;IACd","sourcesContent":[".mainSection {\n  width: 58%;\n\n  .mainList {\n    border: 3px solid black;\n    padding: 50px 20px 30px 20px;\n    margin-block-start: 0;\n    width: 90%;\n    margin: 0 auto;\n\n    .header {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n    }\n\n    .studio {\n      display: flex;\n      justify-content: flex-start;\n      font-size: 22px;\n      align-items: center;\n      font-weight: bold;\n\n      .mapIcon {\n        padding-right: 10px;\n\n        .icon {\n          height: 40px;\n          width: 40px;\n        }\n      }\n    }\n\n    .listItems {\n      padding: 20px 20px;\n\n      .listItemsTitle {\n        font-weight: bold;\n        padding-right: 10px;\n      }\n    }\n\n    .textArea {\n      padding: 10px;\n      resize: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainSection": `app-components-OrganizationChart-DetailPage-___MainSection-module__mainSection`,
	"mainList": `app-components-OrganizationChart-DetailPage-___MainSection-module__mainList`,
	"header": `app-components-OrganizationChart-DetailPage-___MainSection-module__header`,
	"studio": `app-components-OrganizationChart-DetailPage-___MainSection-module__studio`,
	"mapIcon": `app-components-OrganizationChart-DetailPage-___MainSection-module__mapIcon`,
	"icon": `app-components-OrganizationChart-DetailPage-___MainSection-module__icon`,
	"listItems": `app-components-OrganizationChart-DetailPage-___MainSection-module__listItems`,
	"listItemsTitle": `app-components-OrganizationChart-DetailPage-___MainSection-module__listItemsTitle`,
	"textArea": `app-components-OrganizationChart-DetailPage-___MainSection-module__textArea`
};
export default ___CSS_LOADER_EXPORT___;
