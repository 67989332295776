// @flow
import * as React from 'react'
import { useMemo, useState } from 'react'
import { map, filter, sortBy } from 'lodash'
import type { Step } from 'app/core/types'
import { Input } from 'app/components/Form'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'
import { NODE_DIRECTORY, NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import { uuid } from 'app/libs/uuid'
import classes from './Steps.module.scss'

type Props = {|
  steps: ?Array<Step>,
|}

const styledLabel = (label, type) => {
  return (
    <span>
      {label} <span style={{ color: 'grey', fontSize: 11 }}>({type})</span>
    </span>
  )
}

type TaskItem = {|
  label: React$Node,
  items?: TaskItem[],
  key: string,
  dragContent?: any,
  type?: string,
|}
const tasksItems = (content): TaskItem[] => [
  {
    label: 'last take',
    items: [
      {
        label: styledLabel('name', 'last take'),
        key: 'name',
        dragContent: {
          ...content,
          parentName: `${content.parentName}: last take`,
          name: 'name',
          attr: 'name',
          type: 'lastTake',
        },
        type: 'lastTake',
      },
      {
        label: styledLabel('brief', 'last take'),
        key: 'brief',
        dragContent: {
          ...content,
          parentName: `${content.parentName}: last take`,
          name: 'brief',
          attr: 'comment',
          type: 'lastTake',
        },
        type: 'lastTake',
      },
      {
        label: styledLabel('validation comment', 'last take'),
        key: 'validationComment',
        dragContent: {
          ...content,
          parentName: `${content.parentName}: last take`,
          name: 'validation comment',
          attr: 'validationComment',
          type: 'lastTake',
        },
        type: 'lastTake',
      },
      {
        label: styledLabel('status', 'last take'),
        key: 'status',
        dragContent: {
          ...content,
          parentName: `${content.parentName}: last take`,
          name: 'status',
          attr: 'status',
          type: 'lastTake',
        },
        type: 'lastTake',
      },
      {
        label: styledLabel('take estimation', 'last take'),
        key: 'estimLength',
        dragContent: {
          ...content,
          parentName: `${content.parentName}: last take`,
          name: 'take estimation',
          attr: 'estimLength',
          type: 'lastTake',
        },
        type: 'lastTake',
      },
      {
        label: styledLabel('ref medias', 'last take'),
        key: 'takeRefMediasInst',
        dragContent: {
          ...content,
          parentName: `${content.parentName}: last take`,
          name: 'ref medias',
          attr: 'takeRefMediasInst',
          type: 'lastTake',
        },
        type: 'lastTake',
      },
      {
        label: styledLabel('validation medias', 'last take'),
        key: 'takeValidationMediasInst',
        dragContent: {
          ...content,
          parentName: `${content.parentName}: last take`,
          name: 'validation medias',
          attr: 'takeValidationMediasInst',
          type: 'lastTake',
        },
        type: 'lastTake',
      },
      {
        label: styledLabel('flags', 'last take'),
        key: 'takeFlagsInst',
        dragContent: {
          ...content,
          parentName: `${content.parentName}: last take`,
          name: 'flags',
          attr: 'takeFlagsInst',
          type: 'lastTake',
        },
        type: 'lastTake',
      },
      {
        label: styledLabel('number', 'last take'),
        key: 'number',
        dragContent: {
          ...content,
          parentName: `${content.parentName}: last take`,
          name: 'number',
          attr: 'number',
          type: 'lastTake',
        },
        type: 'lastTake',
      },
    ],
    key: 'lastTake',
  },
  {
    label: styledLabel('name', 'task'),
    key: 'name',
    dragContent: { ...content, name: 'name', attr: 'name', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('priority', 'task'),
    key: 'priority',
    dragContent: { ...content, name: 'priority', attr: 'priority', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('start date', 'task'),
    key: 'startDate',
    dragContent: { ...content, name: 'start date', attr: 'startDate', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('end date', 'task'),
    key: 'endDate',
    dragContent: { ...content, name: 'end date', attr: 'endDate', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('earliest start date', 'task'),
    key: 'earliestStartDate',
    dragContent: { ...content, name: 'earliest start date', attr: 'earliestStartDate', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('latest end date', 'task'),
    key: 'latestEndDate',
    dragContent: { ...content, name: 'latest end date', attr: 'latestEndDate', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('status', 'task'),
    key: 'status',
    dragContent: { ...content, name: 'status', attr: 'status', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('assigned user', 'task'),
    key: 'assignedUser',
    dragContent: { ...content, name: 'assigned user', attr: 'assignedUser', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('all takes estimation', 'task'),
    key: 'estimLength',
    dragContent: { ...content, name: 'all takes estimation', attr: 'estimLength', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('supervisor estimation', 'task'),
    key: 'realEstimLength',
    dragContent: { ...content, name: 'supervisor estimation', attr: 'realEstimLength', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('assumption estimation', 'task'),
    key: 'quoteEstimLength',
    dragContent: { ...content, name: 'assumption estimation', attr: 'quoteEstimLength', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('spent time', 'task'),
    key: 'activitiesInst',
    dragContent: { ...content, name: 'activities', attr: 'activitiesInst', type: 'tasks' },
    type: 'tasks',
  },
  {
    label: styledLabel('difference', 'task'),
    key: 'difference',
    dragContent: { ...content, name: 'difference', attr: 'difference', type: 'tasks' },
    type: 'tasks',
  },
]

export function Steps(props: Props): React$Node {
  const { steps } = props

  const [search, setSearch] = useState<string>('')

  function onSearchAttribute(event: SyntheticInputEvent<>) {
    setSearch(event.target.value)
  }

  const filteredSteps = useMemo(
    () =>
      sortBy(
        filter(steps, (step) => step.name.includes(search)),
        ['name'],
      ),
    [search],
  )

  function createNode(item: TaskItem, parent: Node<>) {
    const { items, label, dragContent, type } = item

    const node = new Node({
      key: uuid(),
      name: label,
      parent,
      children: items ? items.map((item) => createNode(item, node)) : undefined,
      icon: !dragContent ? undefined : 'fas-grip-vertical',
      collapsed: true,
      disableDrag: !dragContent,
      type: NODE_ITEM,
      data: { type, ...dragContent },
    })

    return node
  }

  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'step-root',
      name: 'Steps',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: map(filteredSteps, (step) => {
        const node = new Node({
          key: uuid(),
          parent: rootNode,
          name: styledLabel(step.name, 'step'),
          color: step.color,
          data: { ...step, type: 'steps' },
          type: NODE_ITEM,
          icon: 'fas-grip-vertical',
          collapsed: true,
          children: tasksItems({ step: step.id, parentName: step.name }).map((item) => createNode(item, node)),
        })

        return node
      }),
    })
    return rootNode
  }, [filteredSteps])

  return (
    <div className={classes.TablesBlockContainer} style={{ padding: '0 10px', flex: 1 }}>
      <div className={classes.searchInputContainer}>
        <Input
          dataCy="step-name-search"
          placeholder="Search a step"
          value={search}
          onChange={onSearchAttribute}
          className={classes.searchInput}
        />
      </div>
      <div>
        <TreeDnD rootNode={rootNode} nonReorderable={true} />
        {!filteredSteps.length && <div className={classes.noItem}>No steps</div>}
      </div>
    </div>
  )
}
