import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import { useEffect, useMemo, useState } from 'react';
import { find, forEach } from 'lodash';
import { postBoardTypes } from 'app/core/constants';
import resources from 'app/store/resources';
import { getResources } from 'app/store/selectors/getResources';
import { CellRichText, CellMedias, CellLink } from 'app/components/Table/Cells';
import { accessorAssetMediaGroups, saveAssetMediasGroups } from 'app/components/Table/Cells/CellMedias/utils';
import { Table } from 'app/components/Table';
import { permission } from 'app/containers/Permissions';
import history from 'app/main/routerHistory';
import { tableId as MPNid } from 'app/pages/Project/PostBoardModel/TablePostBoardModels';
import { tableId as SPNid } from 'app/pages/Project/PostBoardShot/TablePostBoardShots.jsx';
import map from 'lodash/map';
export function Notes(props) {
    const { asset } = props;
    const [stepProjects, setStepProjects] = useState({});
    const [episodes, setEpisodes] = useState({});
    const groupMediaName = asset.assetType === 'mo' ? postBoardTypes.postBoardModels : postBoardTypes.postBoardShots;
    useEffect(() => {
        if (permission(['projet_production notes__read'], undefined, undefined, asset.project)) {
            const requestsConfig = {
                params: { headers: { [window.OVM_PROJECT_HEADER]: asset.project || '' }, queries: { page_size: 1000 } },
            };
            const promises = [];
            if (asset.assetType === 'sh') {
                promises.push(resources.postBoardLinks.fetchLinksOfAsset(asset.id, requestsConfig));
            }
            if (asset.project)
                promises.push(resources.assets.fetchEpisodes(asset.project, requestsConfig));
            if (asset.project)
                promises.push(resources.stepProjects.fetchByProject(asset.project));
            Promise.all(promises).then(() => {
                const { assetType, project } = asset;
                let displayType;
                if (assetType === 'mo')
                    displayType = MPNid;
                if (assetType === 'sh')
                    displayType = SPNid;
                function stepProjectFetcher(stepProject) {
                    if (stepProject.project !== project)
                        return false;
                    if (stepProject.stepInst.assetType === assetType) {
                        if (!displayType)
                            return true;
                        if (stepProject.display)
                            return stepProject.display.includes(displayType);
                        return false;
                    }
                    return false;
                }
                const stepProjects = getResources(undefined, 'stepProjects', stepProjectFetcher, [
                    'stepInst',
                ]);
                setEpisodes(getResources(undefined, 'assets', { assetType: 'ep', project }));
                setStepProjects(stepProjects);
            });
        }
    }, []);
    function onRedirectTablePostBoard(link) {
        const postBoardRoutes = groupMediaName === postBoardTypes.postBoardModels ? 'postboard-models' : 'postboard-shots';
        const episode = link.episode || asset.parent;
        if (asset.project)
            history.push(`/projects/${asset.project}/${postBoardRoutes}/${episode}`);
    }
    const columns = useMemo(() => {
        const readOnly = !permission(['projet_production notes_notes_update']);
        const stepProjectsWithGeneral = [{ stepInst: { id: undefined, name: 'General' } }, ...map(stepProjects)];
        return [
            {
                Header: ' ',
                id: 'epGroup',
                columns: [
                    CellLink({
                        id: 'episode',
                        Header: 'Episode',
                        fixed: 'left',
                        readOnly,
                        showRemoved: true,
                        accessor: (item) => { var _a; return (_a = episodes[item.episode]) === null || _a === void 0 ? void 0 : _a.name; },
                        onClick: (row) => onRedirectTablePostBoard(row.original),
                        width: 100,
                    }),
                    CellMedias({
                        id: 'medias',
                        Header: 'Refs',
                        readOnly,
                        fixable: true,
                        hiddenable: true,
                        allowPinMedia: false,
                        allowValidateMedia: false,
                        accessor: (item) => {
                            if (!item)
                                return null;
                            const { assetMediaGroupsInst, id } = item.assetInst;
                            return accessorAssetMediaGroups(assetMediaGroupsInst, [groupMediaName, `${groupMediaName}_${id}`]);
                        },
                        actions: () => ['edit', 'delete'],
                        save: {
                            resource: 'medias',
                            formatData: (item, value, cell, instance, event) => {
                                if (!item)
                                    return null;
                                const { assetInst } = item;
                                const { assetMediaGroupsInst, id } = assetInst;
                                const _groupMediaName = `${groupMediaName}_${id}`;
                                let mediasGroup;
                                forEach(assetMediaGroupsInst, (assetMediaGroup) => {
                                    if (assetMediaGroup.mediaGroupInst && assetMediaGroup.mediaGroupInst.name === groupMediaName) {
                                        mediasGroup = assetMediaGroup.mediaGroupInst;
                                    }
                                });
                                return saveAssetMediasGroups(value, _groupMediaName, item.assetInst.id, mediasGroup, event, cell.value);
                            },
                        },
                    }),
                ],
            },
            {
                Header: 'Production Notes',
                id: 'notes',
                columns: [
                    ...stepProjectsWithGeneral.map(({ stepInst }) => {
                        return CellRichText({
                            Header: stepInst.name,
                            actions: () => ['edit', 'copy', 'past'],
                            accessor: (item) => {
                                const note = find(item.notesInst, ({ step }) => step === stepInst.id || (step === null && !stepInst.id));
                                return note ? note.text : '';
                            },
                            id: stepInst.id || 'general',
                            readOnly,
                            width: 300,
                            save: {
                                resource: 'postBoardNotes',
                                formatData: (original, value, cell, instance, type) => {
                                    const { assetInst } = original;
                                    const postboardLink = find(assetInst.postBoardLinksInst, (p) => p.id === original.id);
                                    const note = find(original.notesInst, ({ step }) => step === stepInst.id || (step === null && !stepInst.id));
                                    if (!note) {
                                        return {
                                            link: postboardLink && postboardLink.id,
                                            text: value,
                                            step: stepInst.id,
                                        };
                                    }
                                    return {
                                        id: note.id,
                                        text: value,
                                    };
                                },
                            },
                        });
                    }),
                ],
                headerColor: '#EEB413',
            },
        ];
    }, [stepProjects]);
    const resourcesParams = useMemo(() => ({
        resourceType: 'postBoardLinks',
        requestName: 'fetchLinksOfAsset',
        headers: { [window.OVM_PROJECT_HEADER]: (asset === null || asset === void 0 ? void 0 : asset.project) || '' },
        requestData: asset === null || asset === void 0 ? void 0 : asset.id,
        includedResources: [
            'assetInst',
            'assetInst.postBoardLinksInst',
            'assetInst.assetMediaGroupsInst.mediaGroupInst.mediasInst',
            'notesInst',
        ],
    }), []);
    if (!asset.project) {
        return (_jsx("div", { className: "fullWidth fullHeight bold flex center alignCenter fontSize14 grey", children: `You have to be in the ${asset.name} project to be able to see the notes.` }));
    }
    if (!permission(['projet_production notes__read'], undefined, undefined, asset.project)) {
        return (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter fontSize14 lightgrey", children: `You don't have permission to see the postboard notes.` }));
    }
    return _jsx(Table, { tableId: "table-notes", columns: columns, resourcesParams: resourcesParams, rowExpander: true });
}
