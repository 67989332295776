// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Medias-___UploadFilesModal-module__container {
  display: table;
  width: 100% !important;
  height: 100% !important;
}

.app-components-Medias-___UploadFilesModal-module__dropzoneContainer {
  display: table-header-group;
}

.app-components-Medias-___UploadFilesModal-module__dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
}

.app-components-Medias-___UploadFilesModal-module__dropzone:focus-within {
  border-color: var(--colors-blue);
}

.app-components-Medias-___UploadFilesModal-module__dropzoneError {
  border-color: var(--colors-red);
}

.app-components-Medias-___UploadFilesModal-module__filezoneContainer {
  height: 100%;
  display: table-cell;
}

.app-components-Medias-___UploadFilesModal-module__filezone {
  height: calc(100% - 16px);
  margin-top: 16px;
}

.app-components-Medias-___UploadFilesModal-module__scrollzone {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-components-Medias-___UploadFilesModal-module__error {
  color: var(--colors-red);
}
`, "",{"version":3,"sources":["webpack://./app/components/Medias/UploadFilesModal.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,OAAO;EACP,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;EACpB,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".container {\n  display: table;\n  width: 100% !important;\n  height: 100% !important;\n}\n\n.dropzoneContainer {\n  display: table-header-group;\n}\n\n.dropzone {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  border-width: 2px;\n  border-radius: 2px;\n  border-color: #eeeeee;\n  border-style: dashed;\n  background-color: #fafafa;\n  color: #bdbdbd;\n  outline: none;\n  transition: border 0.24s ease-in-out;\n  cursor: pointer;\n}\n\n.dropzone:focus-within {\n  border-color: var(--colors-blue);\n}\n\n.dropzoneError {\n  border-color: var(--colors-red);\n}\n\n.filezoneContainer {\n  height: 100%;\n  display: table-cell;\n}\n\n.filezone {\n  height: calc(100% - 16px);\n  margin-top: 16px;\n}\n\n.scrollzone {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.error {\n  color: var(--colors-red);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Medias-___UploadFilesModal-module__container`,
	"dropzoneContainer": `app-components-Medias-___UploadFilesModal-module__dropzoneContainer`,
	"dropzone": `app-components-Medias-___UploadFilesModal-module__dropzone`,
	"dropzoneError": `app-components-Medias-___UploadFilesModal-module__dropzoneError`,
	"filezoneContainer": `app-components-Medias-___UploadFilesModal-module__filezoneContainer`,
	"filezone": `app-components-Medias-___UploadFilesModal-module__filezone`,
	"scrollzone": `app-components-Medias-___UploadFilesModal-module__scrollzone`,
	"error": `app-components-Medias-___UploadFilesModal-module__error`
};
export default ___CSS_LOADER_EXPORT___;
