/** @flow */
import { enumProxy } from 'app/libs/helpers'

export type PropertyTypesProps =
  | 'string'
  | 'select'
  | 'autocomplete'
  | 'autocompleteMultiple'
  | 'muiautocomplete'
  | 'dateTime'
  | 'file'
  | 'custom'
  | 'checkbox'
  | 'duration'
  | 'richtext'
  | 'textarea'
  | 'date'
  | 'time'
  | 'phone'
  | 'rating'

const types: * = enumProxy({
  string: 'string',
  select: 'select',
  autocomplete: 'autocomplete',
  autocompleteMultiple: 'autocompleteMultiple',
  muiautocomplete: 'muiautocomplete',
  dateTime: 'dateTime',
  file: 'file',
  custom: 'custom',
  checkbox: 'checkbox',
  duration: 'duration',
  richtext: 'richtext',
  textarea: 'textarea',
  date: 'date',
  time: 'time',
  phone: 'phone',
  rating: 'rating',
})

export default types
