// @flow

import type { StoreResourceDef } from 'app/core/types'
import api from 'app/core/api/api.js'
import type { TableSettingsActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const tableSettings: StoreResourceDef<TableSettingsActions<>> = {
  resourceType: 'tableSettings',
  actions: (getResources) => ({
    fetchByTableId: ({ user, tableType, project }, config) => {
      const { params = {} } = config || {}
      return {
        type: 'read',
        requestKey: config?.requestKey,
        request: () =>
          api.tableSettings
            .fetchAll(
              { user__uuid: user, project__uuid: project, tableType, ...params.queries },
              params.headers,
              params.getHttpProgress,
              params.requestController,
            )
            .then(transformResults(config)),
      }
    },
  }),
}
