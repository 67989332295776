// @flow
import { mapValues } from 'lodash'
import { createGetResources } from 'app/libs/redux-orm-selector'
import store from 'app/store/index.ts'
// eslint-disable-next-line no-unused-vars
import type { ResourcesList, StoreResourceName, ID } from 'app/core/types'
import * as resources from '../resources/resources'

const resourcesRelations = mapValues(resources, (resource) => {
  return resource.relations || {}
})

export function getResources<T = ResourcesList<any> | null>(
  state: Object | void | null = store.getState(),
  resources: StoreResourceName | string,
  getter: ID | Function | Object | void | null,
  subResources: Array<string> | void | null,
): T {
  const _getResources = createGetResources(resourcesRelations)
  return _getResources(state, resources, getter, subResources)
}
