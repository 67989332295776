// @flow
import store from 'app/store/index.ts'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import type { ID } from 'app/core/types'
import { ObjectEntries } from 'app/libs/flowPolyfills'
import { actions, type PermKeyType, type PermType, type PermValueType } from './permissions'

export const userIsAdmin = (): boolean => {
  return store.getState().user.isAdmin
}

const userHasOnePermission = (key: PermKeyType, actionName: PermValueType, projectId: ?string) => {
  if (!key) {
    throw new Error(`userHasPermissions() key is undefined`)
  }

  if (!actionName) {
    throw new Error(`userHasPermissions() actionName is undefined`)
  }

  if (!actions[actionName]) {
    throw new Error(
      `userHasPermissions() actionName '${actionName}' is invalid. Allow keys: ${Object.keys(actions).join(' ')}`,
    )
  }

  if (userIsAdmin()) return true // bypass permission if user is admin

  const { permissions } = store.getState().user

  const _actionName = `${actionName}Permission`

  if (permissions[projectId] && permissions[projectId][key] && permissions[projectId][key][_actionName]) {
    return true
  }

  return !!(permissions.default && permissions.default[key] && permissions.default[key][_actionName])
}

/**
 * example: {
 *      'assets-list': ['read'],
 *      'asset-links-list': ['read', 'create', 'update', 'delete'],
 *      'assetLinkTypes-list': ['read'],
 *  }
 */
export const userHasPermissions = (perms: PermType, projectId: ?ID = getProjectIdFromURL()): boolean => {
  if (!perms || typeof perms !== 'object') throw new Error('userHasPermissions() take one object as argument')

  try {
    ObjectEntries(perms).forEach(([permKey, actions]) => {
      actions.forEach((action) => {
        if (!userHasOnePermission(permKey, action, projectId)) {
          throw new Error('Permissions denied')
        }
      })
    })
  } catch (e) {
    return false
  }

  return true
}
