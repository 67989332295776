// @flow

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { HR } from './HR.jsx'

export default function (): React$Element<any> {
  return (
    <Switch>
      <Route path="/hr" component={HR} />
    </Switch>
  )
}
