// @flow
import * as React from 'react'
import ReactDOM from 'react-dom'

type Props = {
  children: any,
  margin: number,
  heightAuto: boolean,
  onAfterRender?: Function,
  getPortalStyle: Function,
}

type State = {
  pos?: {
    top: number,
    left: number,
  },
  isReady: boolean,
  height?: number,
}

export default class BoundingComponent extends React.PureComponent<Props, State> {
  static defaultProps: Object = {
    margin: 20,
    heightAuto: false,
    getPortalStyle: (content: Object) => content,
  }

  el: HTMLElement

  containerRef: HTMLElement

  childrenRef: HTMLElement

  constructor(props: Props) {
    super(props)
    this.el = document.createElement('div')

    this.state = {
      isReady: false,
    }
  }

  componentDidMount() {
    if (document.body) {
      document.body.appendChild(this.el)
    }

    const { left, top } = this.containerRef.getBoundingClientRect()

    this.setState({
      pos: { left, top },
      isReady: true,
    })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { margin, heightAuto, onAfterRender } = this.props
    if (!prevState.isReady && this.state.isReady) {
      const { offsetWidth, offsetHeight } = this.childrenRef
      const { pos: { top, left } = {} } = this.state
      let height
      const nextStatePos = { top, left }
      if (top + offsetHeight > window.innerHeight) {
        if (!heightAuto) {
          nextStatePos.top = window.innerHeight - offsetHeight - margin
        } else {
          height = window.innerHeight - margin - top
        }
      } else if (top < margin) {
        nextStatePos.top = margin
      }
      if (left + offsetWidth > window.innerWidth) {
        nextStatePos.left = window.innerWidth - offsetWidth - margin
      } else if (left < margin) {
        nextStatePos.left = margin
      }

      // eslint-disable-next-line
      this.setState({ pos: nextStatePos, height }, () => {
        if (onAfterRender) {
          onAfterRender()
        }
      })
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.removeChild(this.el)
    }
  }

  render(): React$Node {
    const { children, getPortalStyle } = this.props
    const { isReady, pos: { left, top } = {}, height } = this.state
    return !isReady ? (
      <div
        ref={(c) => {
          if (c) {
            this.containerRef = c
          }
        }}
      />
    ) : (
      ReactDOM.createPortal(
        <div style={getPortalStyle({ position: 'fixed', left, top, zIndex: 1500, height })}>
          {React.cloneElement(children, {
            ref: (c) => {
              if (c) {
                this.childrenRef = c
                if (children.ref) {
                  children.ref(c)
                }
              }
            },
          })}
        </div>,
        this.el,
      )
    )
  }
}
