// @flow
import type { Node } from 'app/components/Tree/TreeRow.jsx'
import type { ID } from 'app/core/types'

const reverseFlattenTree = (
  items: Array<Object>,
  idKey: ID,
  parentKey: string,
  itemsKey: string,
  parentId?: ?ID,
): Array<Object> => {
  const root: Array<Object> = parentId
    ? items.filter((item) => item[parentKey] === parentId)
    : items.filter((item) => !item[parentKey])

  return root.map((item: Object, index: number): $Shape<Node> => {
    return {
      id: item.id,
      [itemsKey]: reverseFlattenTree(items, idKey, parentKey, itemsKey, item[idKey]),
      data: item,
    }
  })
}

export default reverseFlattenTree
