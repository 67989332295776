/** @flow */
import { forEach, map, filter } from 'lodash'
import type {
  Media,
  ResourcesList,
  AssetMediaGroup,
  ID,
  MediasGroup,
  StoreResourceName,
  TakeValidationMedia,
  TakeRefMedia,
} from 'app/core/types'
import type { SaveDataTypes } from 'app/components/Table'

export const accessorAssetMediaGroups = (
  assetMediaGroups: ResourcesList<AssetMediaGroup>,
  groupIds: Array<string>,
): Array<Media> => {
  let medias = []

  forEach(assetMediaGroups, (assetMediaGroup) => {
    if (!assetMediaGroup.mediaGroupInst || !groupIds.includes(assetMediaGroup.mediaGroupInst.name)) return
    const { mediasInst } = assetMediaGroup.mediaGroupInst
    if (mediasInst) medias = medias.concat(map(mediasInst))
  })

  return medias
}

export const saveAssetMediasGroups = (
  value: { toCreate: Array<Object>, toDelete: Array<ID> },
  groupMediaName: string,
  assetId: ID,
  mediasGroup?: MediasGroup,
  type?: SaveDataTypes,
  cellValue: Array<Media>,
): * => {
  if (type === 'delete') {
    if (!cellValue) return null
    return map(cellValue, (media) => media.id)
  }

  const { toCreate, toDelete } = value

  return {
    type: 'recursive',
    requests: [
      // create mediaGroup
      !mediasGroup ? () => ({ resource: `mediaGroups`, toCreate: { name: groupMediaName, medias: [] } }) : null,
      // create asset-mediaGroup
      !mediasGroup
        ? (resAssetMediaGroup: Array<{ [key: StoreResourceName]: Object }>) => {
            if (resAssetMediaGroup[0] && resAssetMediaGroup[0].mediaGroups) {
              const { created } = resAssetMediaGroup[0].mediaGroups
              mediasGroup = created.resources.find((mg) => mg.name === groupMediaName)
            }
            if (!mediasGroup) return null

            return {
              toCreate: { asset: assetId, mediaGroup: mediasGroup.id },
              resource: `assetMediaGroups`,
            }
          }
        : null,
      // upload medias
      () => {
        return {
          resource: `medias`,
          toDelete: toDelete.length ? toDelete : undefined,
          upload: {
            files: toCreate,
            groupId: mediasGroup && mediasGroup.id,
          },
        }
      },
    ],
  }
}

export const accessorTakeMedias = (takeMedias: ResourcesList<TakeRefMedia | TakeValidationMedia>): Array<Media> => {
  const medias = []
  forEach(takeMedias, (refMedia) => {
    if (refMedia.mediaInst) medias.push(refMedia.mediaInst)
  })
  return medias
}

export const saveTakeMedias = (
  value: { toCreate: Array<Object>, toDelete: Array<ID> },
  resource: 'takeValidationMedias' | 'takeRefMedias',
  takeId: ID,
  relations: ResourcesList<TakeRefMedia | TakeValidationMedia>,
  event?: SaveDataTypes,
  cellValue: Array<Media>,
): * => {
  if (event === 'delete') {
    if (!cellValue) return null
    return {
      type: 'recursive',
      requests: [
        () => ({ resource: `takeRefMedias`, toDelete: map(relations, (rel) => rel.id) }),
        () => ({ resource: `medias`, toDelete: map(cellValue, (media) => media.id) }),
      ],
    }
  }

  const { toCreate, toDelete } = value

  const relToDelete = map(
    filter(relations, (rel) => toDelete.includes(rel.media)),
    (rel) => rel.id,
  )

  return {
    type: 'recursive',
    requests: [
      () => ({
        resource: `medias`,
        toDelete: toDelete.length ? toDelete : undefined,
        upload: toCreate.length ? { files: toCreate } : undefined,
        splited: ['upload'],
      }),
      (res, splited) => {
        if (!splited || !splited[0] || !splited[0].resources) return null
        const toCreate = map(splited[0].resources, (media) => ({ take: takeId, media: media.id }))

        return {
          resource,
          toCreate: toCreate.length ? toCreate : undefined,
          toDelete: relToDelete.length ? relToDelete : undefined,
        }
      },
    ],
  }
}
