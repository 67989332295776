import devWarning from 'app/libs/devWarning'

export const actionSetProperties = (type) => (properties) => ({
  type,
  properties,
})

export const reducerSetProperies = (state, initialState, action) => {
  const nextState = {}

  for (const [key, value] of Object.entries(action.properties)) {
    devWarning(() => {
      if (initialState[key] === undefined) {
        console.error(`Reducer: key ${key} is undefined in initialState`, initialState)
      }
    })

    nextState[key] = value
  }

  return {
    ...initialState,
    ...state,
    ...nextState,
  }
}
