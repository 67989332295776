// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-TreeDnD-___NodeActions-module__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 10px;
}

.app-components-TreeDnD-___NodeActions-module__actionsIconContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--colors-black);

  min-width: -moz-fit-content;

  min-width: fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  width: 20px;
  height: 20px;
}

.app-components-TreeDnD-___NodeActions-module__actionsIconContainer:hover {
    transform: scale(1.1);
  }
`, "",{"version":3,"sources":["webpack://./app/components/TreeDnD/NodeActions.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,0BAA0B;;EAE1B,2BAAsB;;EAAtB,sBAAsB;EACtB,4BAAuB;EAAvB,uBAAuB;EACvB,WAAW;EACX,YAAY;AAKd;;AAHE;IACE,qBAAqB;EACvB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  margin-left: 10px;\n}\n\n.actionsIconContainer {\n  display: flex;\n  align-content: center;\n  align-items: center;\n  justify-content: center;\n  padding: 3px;\n  border-radius: 4px;\n  cursor: pointer;\n  color: var(--colors-black);\n\n  min-width: fit-content;\n  min-height: fit-content;\n  width: 20px;\n  height: 20px;\n\n  &:hover {\n    transform: scale(1.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-TreeDnD-___NodeActions-module__container`,
	"actionsIconContainer": `app-components-TreeDnD-___NodeActions-module__actionsIconContainer`
};
export default ___CSS_LOADER_EXPORT___;
