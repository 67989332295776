// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-containers-Assets-AssetDetail-Discussions-___Chat-module__buttonSend {
  position: absolute !important;
  bottom: 10px;
  right: 10px;
  z-index: 30;
}
`, "",{"version":3,"sources":["webpack://./app/containers/Assets/AssetDetail/Discussions/Chat.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,WAAW;EACX,WAAW;AACb","sourcesContent":[".buttonSend {\n  position: absolute !important;\n  bottom: 10px;\n  right: 10px;\n  z-index: 30;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonSend": `app-containers-Assets-AssetDetail-Discussions-___Chat-module__buttonSend`
};
export default ___CSS_LOADER_EXPORT___;
