/** @flow */
import React from 'react'
import { AssetsTreeSearch } from 'app/containers/Assets/AssetsTree'
import AssetDetail from 'app/containers/Assets/AssetDetail/AssetDetail.jsx'
import { assetRoutes } from 'app/containers/Assets/AssetDetail/router'
import { createRouter } from 'app/components/LocalRouter/createRouter.js'
import Widget from 'app/components/Widget/Widget.jsx'
import { ArrowReturnBottom } from 'app/components/Icons'
import type { Match, Asset, ID } from 'app/core/types'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import resources from 'app/store/resources'
import { postCountIsUpToDate, postChildCountIsUpToDate } from 'app/core/constants/assetCountMeta'
import classes from './Assets.module.scss'

type Props = {|
  match: Match,
|}

const router = createRouter('assetDetails', assetRoutes, { rightPanel: false })
const { Link, Route } = router

export default class Assets extends React.Component<Props> {
  onClickAsset: (Object) => void = ({ metadata }) => {
    router.goTo('index', { assetId: metadata.id }, { rightPanel: false })
    resources.assets.setTreeCountAsRead(metadata.id, 'postCount')
  }

  onSearchAsset: (ID) => void = (assetId) => {
    router.goTo('index', { assetId }, { rightPanel: false })
  }

  onClickBreakcrumbItem: (Asset) => void = (item) => {
    router.goTo('index', { assetId: item.id }, { rightPanel: false })
  }

  render(): React$Node {
    const { match } = this.props
    const { projectId } = match.params

    return (
      <div className={classes.container}>
        <Widget
          className={classes.left}
          enableHideColumn={true}
          collapseTitle="Asset list"
          resizable={true}
          optionName="widget-assetList"
          defaultWidth={300}
        >
          <AssetsTreeSearch
            rootAssetId={projectId}
            assetTypes={['ep', 'fo', 'mo', 'sh', 'gp', 'mi']}
            currentAssetId={router.getParam('assetId')}
            onClickItem={this.onClickAsset}
            allowCreate={true}
            onSearchAsset={this.onSearchAsset}
            resizable={true}
            renderLabel={(asset) => {
              const postCount = !asset[postCountIsUpToDate] ? (
                <span className={classes.unread}>({asset.postCount})</span>
              ) : (
                <span>({asset.postCount})</span>
              )

              const postChildCount = !asset[postChildCountIsUpToDate] ? (
                <span className={classes.unread}>({asset.postChildCount})</span>
              ) : (
                <span>({asset.postChildCount})</span>
              )

              let title

              if (asset.attributes && asset.attributes.title) {
                title = <span> - {asset.attributes.title}</span>
              }

              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: 'inherit',
                  }}
                >
                  <div className={classes.label} title={asset.name}>
                    {asset.name}
                    {title}
                  </div>
                  <div className={classes.countercontainer}>
                    {postCount}
                    <ArrowReturnBottom width={10} height={15} />
                    {postChildCount}
                  </div>
                </div>
              )
            }}
            onOpenChild={(asset) => {
              resources.assets.setTreeCountAsRead(asset.id, 'postChildCount')
            }}
          />
        </Widget>

        <Widget className={classes.right}>
          {router.exist() ? (
            <AssetDetail
              localRouter={{ router, Link, Route }}
              breadcrumbProps={{
                onClick: this.onClickBreakcrumbItem.bind(this),
                startAtAssetId: projectId,
                enableClickItem: true,
              }}
              getAsset={(asset) => asset && documentTitle(asset.name)}
            />
          ) : null}
        </Widget>
      </div>
    )
  }
}
