// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellAssetsList-___CellAssetsList-module__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
}

.app-components-Table-Cells-CellAssetsList-___CellAssetsList-module__asset {
  display: inline-block;
  color: #fff;
  background-color: #747474;
  margin: 2px;
  padding: 0 10px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: bold;
  pointer-events: none;
  text-align: center;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 19px;
}
.app-components-Table-Cells-CellAssetsList-___CellAssetsList-module__asset:focus-within {
  cursor: pointer;
}

.app-components-Table-Cells-CellAssetsList-___CellAssetsList-module__assetTooltip {
  background-color: lightgray;
  color: #747474;
}

.app-components-Table-Cells-CellAssetsList-___CellAssetsList-module__restLabel {
  font-weight: bold;
  color: dimgrey;
  margin: 1px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellAssetsList/CellAssetsList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,WAAW;AACb","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  overflow-y: auto;\n}\n\n.asset {\n  display: inline-block;\n  color: #fff;\n  background-color: #747474;\n  margin: 2px;\n  padding: 0 10px;\n  border-radius: 30px;\n  font-size: 13px;\n  font-weight: bold;\n  pointer-events: none;\n  text-align: center;\n  white-space: nowrap;\n  max-width: 100%;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  height: 19px;\n}\n.asset:focus-within {\n  cursor: pointer;\n}\n\n.assetTooltip {\n  background-color: lightgray;\n  color: #747474;\n}\n\n.restLabel {\n  font-weight: bold;\n  color: dimgrey;\n  margin: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellAssetsList-___CellAssetsList-module__container`,
	"asset": `app-components-Table-Cells-CellAssetsList-___CellAssetsList-module__asset`,
	"assetTooltip": `app-components-Table-Cells-CellAssetsList-___CellAssetsList-module__assetTooltip`,
	"restLabel": `app-components-Table-Cells-CellAssetsList-___CellAssetsList-module__restLabel`
};
export default ___CSS_LOADER_EXPORT___;
