export function promiseAllProgress(promises, callback) {
  if (!callback) return Promise.all(promises)
  let d = 0
  callback(0)
  for (const p of promises) {
    // eslint-disable-next-line no-loop-func
    p.then(() => {
      d += 1
      callback((d * 100) / promises.length)
    })
  }
  return Promise.all(promises)
}

export function easingTimeout(fn, delay) {
  const ids = []

  const invoker = () => {
    fn()
    delay = Math.floor(delay * 1.08)
    if (delay) {
      const id = setTimeout(invoker, delay)
      ids.push(id)
    }
  }

  const id = setTimeout(invoker, delay)
  ids.push(id)

  return () => ids.forEach(clearTimeout)
}
