// @flow
import { createRouter } from 'app/components/LocalRouter'

export const assetRoutes: * = {
  asset: '/:assetId',
  index: '/:assetId/discussions',
  informations: '/:assetId/informations',
  discussions: '/:assetId/discussions',
  topic: '/:assetId/discussions/:topicId',
  thread: '/:assetId/discussions/:topicId/:threadId/:!postId',
  wiki: '/:assetId/wiki/:!articleId',
  notes: '/:assetId/notes',
  tasks: '/:assetId/tasks',
  breakdownShot: '/:assetId/breakdownShot',
  breakdownEpisode: '/:assetId/breakdownEpisode',
  postBoardShots: '/:assetId/postBoardShots',
  postBoardModels: '/:assetId/postBoardModels',
  medias: '/:assetId/medias',
  mozaic: '/:assetId/mozaic',
  mozaicAllMedias: '/:assetId/mozaicAllMedias',
  validationMedias: '/:assetId/validationMedias',
  usage: '/:assetId/usage',
}

export const router: * = createRouter('asset', assetRoutes, { rightPanel: false })
export const { Link, Route } = router
