// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-MyHours-CellDurationTotal-___CellDurationTotal-module__totalPerDay {
  color: var(--colors-green);
}

.app-pages-MyHours-CellDurationTotal-___CellDurationTotal-module__noTotalPerDay {
  color: var(--colors-red);
}

.app-pages-MyHours-CellDurationTotal-___CellDurationTotal-module__totalPerDayOverLimit {
  color: var(--colors-orange);
  font-weight: bold;
}

.app-pages-MyHours-CellDurationTotal-___CellDurationTotal-module__noDuration {
  color: var(--colors-greyLight);
}
`, "",{"version":3,"sources":["webpack://./app/pages/MyHours/CellDurationTotal/CellDurationTotal.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".totalPerDay {\n  color: var(--colors-green);\n}\n\n.noTotalPerDay {\n  color: var(--colors-red);\n}\n\n.totalPerDayOverLimit {\n  color: var(--colors-orange);\n  font-weight: bold;\n}\n\n.noDuration {\n  color: var(--colors-greyLight);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalPerDay": `app-pages-MyHours-CellDurationTotal-___CellDurationTotal-module__totalPerDay`,
	"noTotalPerDay": `app-pages-MyHours-CellDurationTotal-___CellDurationTotal-module__noTotalPerDay`,
	"totalPerDayOverLimit": `app-pages-MyHours-CellDurationTotal-___CellDurationTotal-module__totalPerDayOverLimit`,
	"noDuration": `app-pages-MyHours-CellDurationTotal-___CellDurationTotal-module__noDuration`
};
export default ___CSS_LOADER_EXPORT___;
