/** @flow */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import moment from 'moment'
import clip from 'text-clipper'
import keyCode from 'app/libs/keyCode'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { targetIsChild } from 'app/libs/helpers/dom'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { lightenDarkenColor } from 'app/libs/lightenDarkenColor'
import type { DateDay, StoreResourceName } from 'app/core/types'

import type { CellInstance, Cell, TableRow, Original } from '../../../types'
import { LengthButton } from './LengthButton.jsx'
import { SubRowsOverview } from './SubRowsOverview.jsx'
import { Contract } from './Contract.jsx'
import { RowMetaData } from './RowMetaData.jsx'
import { useRangeEdition } from './useRangeEdition'

import classes from './Range.module.scss'
import { resourcesColors } from './utils'

export type RangeValue = {|
  ...Original,
  startDate?: DateDay,
  endDate: DateDay,
  __resource: StoreResourceName,
  name: string,
  subRows?: Array<TableRow>,
  color?: string,
|}

type Props = {|
  instance: CellInstance,
  cell: Cell,
  value: RangeValue,
  readOnly?: boolean,
  multiple?: boolean,
|}

export function Range(props: Props): React$Node {
  const { instance, cell, readOnly: propsReadOnly, value, multiple } = props
  const {
    state: { selectedCells, savingCells },
    getSelectedTimelineCells,
    selectDescendentCells,
    dimensions,
    ganttProperties,
  } = instance
  const { getCellProps, column, showMenu } = cell
  const {
    startDate: _startDate,
    endDate: _endDate,
    __resource,
    name,
    subRows,
    color,
    uneditableDates,
    __readOnly,
    contractInst,
    note,
  } = value
  const { onSelectCell, onDoubleClickSelectCell } = column
  const { edition, isExpanded, selectCell, ganttState, showDateExponent } = getCellProps()
  const { lockedWithContract } = ganttState || {}

  const readOnly = __readOnly || propsReadOnly

  const { startEdit } = edition
  const { ganttStart } = ganttProperties || {}
  const { dayWidth } = dimensions || {}

  const rangeRef = useRef()
  const containerRef = useRef()

  const [{ startDate, endDate }, setDates] = useState<{ startDate: ?moment, endDate: ?moment }>({
    startDate: undefined,
    endDate: undefined,
  })
  const [{ leftDiff, widthDiff }, setPositionsChange] = useState<{ leftDiff: number, widthDiff: number }>({
    leftDiff: 0,
    widthDiff: 0,
  })

  const [containerIsFocused, setContainerIsFocused] = useState<boolean>(false)

  useEffect(() => {
    const startDate = _startDate ? moment(_startDate) : undefined
    const endDate = _endDate ? moment(_endDate) : undefined
    setDates({ startDate, endDate })
    setPositionsChange({ leftDiff: 0, widthDiff: 0 })
  }, [_startDate, _endDate])

  const selectedTimelineCells = useMemo(() => getSelectedTimelineCells?.() || [], [selectedCells])
  const selected = useMemo(() => Boolean(selectedCells?.[cell.id]), [selectedCells])

  // $FlowFixMe
  const daysLeft = useMemo(() => startDate?.diff(ganttStart, 'days') || 0, [startDate?._i, ganttStart._i])
  // $FlowFixMe
  const difference = useMemo(() => (endDate?.diff(moment(startDate), 'days') || 0) + 1, [startDate?._i, endDate?._i])
  const left = useMemo(() => dayWidth * daysLeft, [dayWidth, daysLeft])
  const width = useMemo(() => Math.max(1, dayWidth * difference), [dayWidth, difference])
  const isLoading = useMemo(() => Boolean(savingCells[cell.id]), [savingCells])

  const _style = useMemo(() => ({ left, width }), [left, width])
  const _classNames = useMemo(
    () =>
      cx(classes.imputation, {
        [classes.selected]: selected,
        [classes.cellIsLoading]: isLoading,
      }),
    [selectedTimelineCells, selected, isLoading],
  )

  const rangeColor = useMemo(
    () =>
      color
        ? lightenDarkenColor(selected ? 0 : 0.2, color)
        : resourcesColors(__resource, selected, Boolean(contractInst)),
    [color, __resource, selected, contractInst],
  )

  const { onLengthResize, onLengthStopResize, onMouseDown } = useRangeEdition({
    instance,
    startDate,
    endDate,
    cell,
    onPositionChange: setPositionsChange,
  })

  function onFocus(event: SyntheticFocusEvent<HTMLElement>) {
    if (onSelectCell) {
      onSelectCell(cell, instance?.getLastestInstance?.() || instance, event)
    }
    if (selectCell) selectCell()
  }

  function onDoubleClick(e: SyntheticMouseEvent<>) {
    if (onDoubleClickSelectCell) {
      onDoubleClickSelectCell(cell, instance?.getLastestInstance?.() || instance)
    }
    return startEdit?.(e)
  }

  function onContextMenu(e: SyntheticMouseEvent<>) {
    e.preventDefault()
    e.stopPropagation()
    showMenu && showMenu(e, cell)
  }

  function onClick(e: SyntheticMouseEvent<>) {
    if (e.shiftKey && !uneditableDates && !readOnly) selectDescendentCells?.(cell, e.ctrlKey || e.metaKey)
  }

  function onKeyDown(e: SyntheticKeyboardEvent<>) {
    switch (e.keyCode) {
      case keyCode.ENTER: {
        if (e.shiftKey) {
          e.preventDefault()
          e.stopPropagation()
          showMenu && showMenu(e, cell)
        }
        break
      }

      default:
        break
    }
  }

  function onFocusContainer(e: SyntheticFocusEvent<>) {
    setContainerIsFocused(true)
  }

  function onBlurContainer(e: SyntheticFocusEvent<>) {
    if (!targetIsChild(e)) setContainerIsFocused(false)
  }

  if (!startDate || !endDate) return null

  // const exponentLeftDiff = -leftDiff
  // const exponentLeft = Math.round(left + exponentLeftDiff / dayWidth)
  // const exponentWidth = exponentLeft + width + widthDiff
  // const exponentStartDate = ganttStart?.add(exponentLeft, 'day').format('dd DD/MM/YYYY')
  // const exponentEndDate = ganttStart?.add(exponentWidth, 'day').format('dd DD/MM/YYYY')

  // console.log('diffs', exponentLeft, exponentWidth)
  // console.log('diffs days', leftDiff / dayWidth, widthDiff / dayWidth)

  return (
    <div
      className={cx(classes.container, { [classes.showMeta]: selected, [classes.rowFocused]: selected })}
      onFocus={onFocusContainer}
      onBlur={onBlurContainer}
      ref={containerRef}
      tabIndex={0}
      onContextMenu={(e) => e.preventDefault()}
    >
      {contractInst ? (
        <RowMetaData
          instance={instance}
          cell={cell}
          contract={contractInst}
          containerIsFocused={containerIsFocused || selected}
          containerRef={containerRef}
        />
      ) : null}
      <div
        className={_classNames}
        gantt-cell-id={cell.id}
        initial-left={left}
        initial-width={width}
        tabIndex="0"
        style={_style}
        content-type={__resource}
        ref={(ref) => {
          rangeRef.current = ref
        }}
        onFocus={onFocus}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onContextMenu={onContextMenu}
        onKeyDown={onKeyDown}
      >
        <div
          className={cx(classes.editionContainer, {
            [classes.isEditable]: !isLoading,
            [classes.uneditableDates]: uneditableDates || readOnly,
          })}
          style={{
            backgroundColor: rangeColor,
            color: getColorFromBackground(rangeColor),
          }}
          onMouseDown={selected && !isLoading && !uneditableDates && !readOnly ? onMouseDown : undefined}
          title={`${name}${!contractInst && note ? `: ${clip(note, 50)}` : ''}`}
        >
          {!uneditableDates && !readOnly && !isLoading ? (
            <LengthButton
              instance={instance}
              onResize={onLengthResize('left')}
              onStopResize={onLengthStopResize('left')}
              className={classes.lengthButton}
              position="left"
              contentType={__resource}
            />
          ) : null}
          {showDateExponent ? (
            <div
              className={classes.dateExponent}
              style={multiple ? { color: getColorFromBackground(rangeColor) } : undefined}
              position={`left-${multiple ? 'inner' : 'outer'}`}
              tooltip-date={moment(startDate)
                .add(Math.round(leftDiff / dayWidth), 'day')
                .format('dd DD/MM/YYYY')}
            />
          ) : null}
          <div className={classes.title}>
            <div className={classes.label}>{name}</div>
          </div>
          {showDateExponent ? (
            <div
              className={classes.dateExponent}
              style={multiple ? { color: getColorFromBackground(rangeColor) } : undefined}
              position={`right-${multiple ? 'inner' : 'outer'}`}
              tooltip-date={moment(endDate)
                .add(Math.round((leftDiff + widthDiff) / dayWidth), 'day')
                .format('dd DD/MM/YYYY')}
            />
          ) : null}
          {!uneditableDates && !readOnly && !isLoading ? (
            <LengthButton
              onResize={onLengthResize('right')}
              onStopResize={onLengthStopResize('right')}
              className={classes.lengthButton}
              position="right"
              contentType={__resource}
              instance={instance}
            />
          ) : null}
          {lockedWithContract ? (
            <div className={classes.lockIcon}>
              <FontIcon icon="fas-lock" className="fontSize10" />
            </div>
          ) : null}
        </div>
        {!isExpanded && dimensions ? (
          <SubRowsOverview subRows={subRows} dimensions={dimensions} startDate={startDate} />
        ) : null}
      </div>
      {contractInst ? (
        <Contract
          lockedWithContract={lockedWithContract}
          contract={contractInst}
          instance={instance}
          cell={cell}
          haveSelectedCells={selectedTimelineCells.length > 0}
          selected={selected}
        />
      ) : null}
    </div>
  )
}
