// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellMedias-___ModalMedias-module__dropzone {
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 100%;
  padding: 5px;
  overflow: auto;
  position: relative;
}

.app-components-Table-Cells-CellMedias-___ModalMedias-module__dropzone:focus-within {
  border-color: #008ae6;
  transition: border 0.24s ease-in-out;
}

.app-components-Table-Cells-CellMedias-___ModalMedias-module__dropzoneError {
  border-color: var(--colors-red);
}

.app-components-Table-Cells-CellMedias-___ModalMedias-module__filezoneContainer {
  height: 100%;
  display: table-cell;
}

.app-components-Table-Cells-CellMedias-___ModalMedias-module__filezone {
  height: calc(100% - 16px);
  margin-top: 16px;
}

.app-components-Table-Cells-CellMedias-___ModalMedias-module__scrollzone {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-components-Table-Cells-CellMedias-___ModalMedias-module__error {
  color: #ffffff;
  padding: 2px 5px;
  border-radius: 3px;
  background-color: var(--colors-red);
  margin-top: 5px;
  opacity: 0.8;
}

.app-components-Table-Cells-CellMedias-___ModalMedias-module__errors {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellMedias/ModalMedias.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;EACpB,aAAa;EACb,oCAAoC;EACpC,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,oCAAoC;AACtC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,mCAAmC;EACnC,eAAe;EACf,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,OAAO;EACP,aAAa;AACf","sourcesContent":[".dropzone {\n  border-width: 2px;\n  border-radius: 2px;\n  border-color: #eeeeee;\n  border-style: dashed;\n  outline: none;\n  transition: border 0.24s ease-in-out;\n  height: 100%;\n  padding: 5px;\n  overflow: auto;\n  position: relative;\n}\n\n.dropzone:focus-within {\n  border-color: #008ae6;\n  transition: border 0.24s ease-in-out;\n}\n\n.dropzoneError {\n  border-color: var(--colors-red);\n}\n\n.filezoneContainer {\n  height: 100%;\n  display: table-cell;\n}\n\n.filezone {\n  height: calc(100% - 16px);\n  margin-top: 16px;\n}\n\n.scrollzone {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.error {\n  color: #ffffff;\n  padding: 2px 5px;\n  border-radius: 3px;\n  background-color: var(--colors-red);\n  margin-top: 5px;\n  opacity: 0.8;\n}\n\n.errors {\n  position: sticky;\n  bottom: 0;\n  left: 0;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": `app-components-Table-Cells-CellMedias-___ModalMedias-module__dropzone`,
	"dropzoneError": `app-components-Table-Cells-CellMedias-___ModalMedias-module__dropzoneError`,
	"filezoneContainer": `app-components-Table-Cells-CellMedias-___ModalMedias-module__filezoneContainer`,
	"filezone": `app-components-Table-Cells-CellMedias-___ModalMedias-module__filezone`,
	"scrollzone": `app-components-Table-Cells-CellMedias-___ModalMedias-module__scrollzone`,
	"error": `app-components-Table-Cells-CellMedias-___ModalMedias-module__error`,
	"errors": `app-components-Table-Cells-CellMedias-___ModalMedias-module__errors`
};
export default ___CSS_LOADER_EXPORT___;
