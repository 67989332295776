// @flow
import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { map } from 'lodash'
import uniqid from 'app/libs/uniqid.js'
import ResizeObserver from 'react-resize-detector'
import type { ResourcesList, Group as GroupType, GroupUser, ID, Asset } from 'app/core/types/index.js'
import { ModalTrigger, MUIModal, confirmDelete, openModal } from 'app/components/Modal'
import { MUIButton } from 'app/components/Form'
import Widget from 'app/components/Widget/Widget.jsx'
import { getStatusRequest } from 'app/store/resources/utils/getStatusRequest'
import { getResources } from 'app/store/selectors/getResources'
import { AdminPermissionsTable, PermissionsPanel, Permission, permission } from 'app/containers/Permissions'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import resources from 'app/store/resources'
import { Title } from 'app/components/Texts/Title/Title.jsx'
import { ModulableTree } from 'app/components/ModulableTree/ModulableTree.jsx'
import PaginatedComponent from 'app/components/PaginatedComponent/PaginatedComponent.jsx'
import { ModalAssetsRelations } from 'app/containers/AssetsRelations/ModalAssetsRelations.jsx'
import api from 'app/core/api/api.js'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classes from './Group.module.scss'
import ModalEditGroup from './ModalEditGroup.tsx'
import AssetsSelect from '../Assets/AssetsSelect/AssetsSelect.jsx'
import useDebounce from '../../hooks/useDebounce'

const paginatedList = uniqid('group-user')

type Props = {|
  match: *,
  location: *,
|}

function Group(props: Props): React$Node {
  const { match, location } = props
  const group: GroupType | Object = useSelector((state) => getResources(state, 'assets', props.match.params.groupId))
  const { paginatedLists } = useSelector((state) => state.groupUsers)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [height, setHeight] = useState<number | null>(null)
  const [search, setSearch] = useState<string>('')
  const [allUsers, setAllUsers] = useState<Asset[]>([])

  const { count = 0 } = paginatedLists[paginatedList] || {}

  useEffect(() => {
    const groupUserFetchStatus = getStatusRequest('groupUsers', 'fetchAllByGroup')
    setIsLoading(groupUserFetchStatus.pending)
    setSearch('')
  }, [props.match.params.groupId])

  const fetchAllUsers = (pageSize: number, currentPage: number) => {
    return resources.groupUsers.fetchAllByGroup(group.id, {
      params: {
        queries: {
          page_size: pageSize,
          page: currentPage,
        },
      },
      paginatedList,
    })
  }

  const debouncedSearchTerm = useDebounce(search, 300)

  useEffect(() => {
    resources.groupUsers.fetchSearchUserByGroup({ groupId: group.id }).then((res) => setAllUsers(res.resources))
  }, [group.id])

  const onDelete = (rel: Object) => {
    confirmDelete({
      title: 'Delete',
      render: (
        <div>
          Do you want to delete <b>{rel.userInst.name}</b> ?
        </div>
      ),
      validateLabelColor: '#E56D7A',
      validateLabel: 'Delete',
      onValidate: () => resources.groupUsers.delete(rel.id),
      validateMessage: 'User deleted',
    })
  }

  const replacePlaceholderByUser = (placeholderId: ID) => {
    return openModal(
      <MUIModal title="Replace placeholder by user">
        {({ handleClose }) => (
          <AssetsSelect
            assetTypes={['us']}
            noProjectHeader={true}
            onChange={(res) => {
              return api.replacePlaceHolder({ placeHolder: placeholderId, user: res.data && res.data.id }).then(() => {
                resources.assets.update({ ...res.data, id: placeholderId }, { localOnly: true })
                handleClose()
              })
            }}
          />
        )}
      </MUIModal>,
    )
  }

  const filteredUsers = useMemo(() => {
    return allUsers
      .filter((user) => user.name.includes(debouncedSearchTerm))
      .map((groupUser) => ({
        key: groupUser.id,
        label: groupUser.name.includes(search) ? groupUser.name : null,
        actions: [
          permission(['projet_groups__manage participants']) &&
            group.name !== 'all' &&
            groupUser.assetType === 'ph' && {
              key: 'Replace placeholder by user',
              label: <FontIcon icon="fas-user-check" />,
              tooltip: 'Replace placeholder by user',
              onClick: () => replacePlaceholderByUser(groupUser.userInst.id),
            },
          permission(['projet_groups__manage participants']) &&
            groupUser.assetType !== 'ph' && {
              key: 'delete',
              onClick: () => onDelete(groupUser),
            },
        ],
      }))
  }, [debouncedSearchTerm, allUsers])

  const addUsers = async (users) => {
    const promises = []
    users.map((user) =>
      promises.push(resources.groupUsers.create({ user: user.id, group: group.id }, { paginatedList })),
    )
    const res = await Promise.all(promises)
    return res
  }

  const history = useHistory()

  const onDeleteGroup = (e) => {
    confirmDelete({
      title: 'Delete',
      render: (
        <div>
          Do you want to delete <b>{group.name}</b> ?
        </div>
      ),
      validateLabelColor: '#E56D7A',
      validateLabel: 'Delete',
      onValidate: async () => {
        const res = await resources.assets.delete(group.id)
        history.push(
          match.params.projectId
            ? `/projects/${match.params.projectId}/settings/groups/users-by-group`
            : '/admin/groups/',
        )
        return res
      },
      validateMessage: 'Group deleted',
    })
  }

  const onTitleResize = () => {
    const el = document.querySelector('#ovm-title-groups')
    setHeight(el ? el.offsetHeight + 10 : null)
  }

  if (!group) return null

  return (
    <Widget className={classes.areaGroup} style={{ width: '100%' }}>
      <ResizeObserver handleWidth={true} onResize={onTitleResize}>
        <div id="ovm-title-groups" className="flex column fullWidth">
          <div className={classes.usersHead}>
            <Title size="3">Users of {group && group.name}</Title>
            <input
              className={classes.searchInput}
              type="text"
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              placeholder="Search for a user"
              value={search}
            />
          </div>

          <div>
            {group.name !== 'all' ? (
              <>
                <Permission actions={['projet_groups_group_update name']}>
                  <ModalTrigger modal={<ModalEditGroup groupId={group.id} />}>
                    <MUIButton style={{ color: 'grey', fontWeight: 'bolder' }} size="small" variant="text">
                      <FontIcon icon="fas-edit" className="fontSize14 marginRight5" /> Edit group name
                    </MUIButton>
                  </ModalTrigger>
                </Permission>
                <Permission actions={['projet_groups_group_delete']}>
                  <MUIButton
                    style={{ color: 'grey', fontWeight: 'bolder' }}
                    icon="fas-trash"
                    size="small"
                    variant="text"
                    onClick={onDeleteGroup}
                  >
                    Delete group
                  </MUIButton>
                </Permission>
              </>
            ) : null}

            <Permission actions={['projet_groups__manage participants']}>
              <ModalTrigger
                modal={
                  <ModalAssetsRelations
                    assetTypes={['us']}
                    placeholder="Select users"
                    noProjectHeader={true}
                    assets={[]}
                    flashNotifSuccessLabelKey="User"
                    onSave={({ assets }) => addUsers(assets.map((asset) => asset.assetInst))}
                  />
                }
              >
                <MUIButton style={{ color: 'grey', fontWeight: 'bolder' }} size="small" variant="text" icon="fas-plus">
                  Add user
                </MUIButton>
              </ModalTrigger>
            </Permission>

            <Permission actions={['projet_groups_permissions_update', 'projet_groups_permissions_read']} operator="or">
              <ModalTrigger
                modal={
                  <MUIModal
                    hideCancel={true}
                    title={`Permissions of ${group && group.name}`}
                    resizable={true}
                    width={1000}
                    height={600}
                  >
                    <PermissionsPanel isGlobal={!props.match.params.projectId} groupId={group.id} />
                  </MUIModal>
                }
              >
                <MUIButton
                  style={{ color: 'grey', fontWeight: 'bolder' }}
                  icon="fas-unlock"
                  size="small"
                  variant="text"
                >
                  Group permissions
                </MUIButton>
              </ModalTrigger>
            </Permission>

            <Permission actions={['']}>
              <ModalTrigger
                modal={
                  <MUIModal
                    title={`Permissions of ${group && group.name}`}
                    resizable={true}
                    width={1000}
                    height={600}
                    hideValidate={true}
                    cancelLabel="Close"
                    cancelLabelColor="#4a4a4a"
                  >
                    <AdminPermissionsTable groupId={group.id} />
                  </MUIModal>
                }
              >
                <MUIButton
                  style={{ color: 'grey', fontWeight: 'bolder' }}
                  icon="fas-unlock-alt"
                  size="small"
                  variant="text"
                >
                  Admin permissions
                </MUIButton>
              </ModalTrigger>
            </Permission>
          </div>
        </div>
      </ResizeObserver>
      <div style={{ height: `calc(100% - ${height || 60}px)`, marginTop: 10 }}>
        {search.length !== 0 ? (
          <ModulableTree scrollable={true} noItemsLabel="No users" items={filteredUsers} />
        ) : (
          <PaginatedComponent
            defaultPageSize={200}
            pageSizeOptions={[25, 50, 100, 200, 400]}
            key={location.pathname}
            count={count}
            style={{ height: '100%' }}
            renderChildren={(res) => {
              const groupUsers: ResourcesList<GroupUser> = getResources(
                undefined,
                `groupUsers.paginatedLists.${paginatedList}`,
                { group: match.params.groupId },
                ['userInst'],
              )
              return (
                <div style={{ overflowY: 'auto' }}>
                  <ModulableTree
                    noItemsLabel="No users"
                    items={map(groupUsers, (groupUser) => ({
                      key: groupUser.id,
                      label: groupUser.userInst.name,
                      actions: [
                        permission(['projet_groups__manage participants']) &&
                          group.name !== 'all' &&
                          groupUser.userInst.assetType === 'ph' && {
                            key: 'Replace placeholder by user',
                            label: <FontIcon icon="fas-user-check" />,
                            tooltip: 'Replace placeholder by user',
                            onClick: () => replacePlaceholderByUser(groupUser.userInst.id),
                          },
                        permission(['projet_groups__manage participants']) &&
                          groupUser.userInst.assetType !== 'ph' && {
                            key: 'delete',
                            onClick: () => onDelete(groupUser),
                          },
                      ].filter((_) => _),
                    }))}
                  />
                </div>
              )
            }}
            isLoading={isLoading}
            onRequestNeeded={fetchAllUsers}
          />
        )}
      </div>
    </Widget>
  )
}

export default Group
