/** @flow */
import * as React from 'react'
import cx from 'classnames'
import { MUISelect } from 'app/components/Form'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import { optionsPiority, getOptionsValue } from 'app/core/utils/optionsPriority'
import type { Column, DefaultCellProps, Cell } from '../../types'

import { groupingFns } from './groupingFns.jsx'
import classes from './CellPriority.module.scss'

export type CellPriorityParams = {|
  ...DefaultCellProps,
  readMask?: Function,
  getColor?: (cell: Cell) => string,
|}

export function PriorityRead({ value, readMask = (v) => v }: { value: number, readMask?: Function }): React$Node {
  const currentValue = getOptionsValue(value)
  return (
    <div className="flex center alignCenter fullHeight">
      <div
        style={{
          backgroundColor: currentValue && currentValue.backgroundColor,
          color: currentValue && getColorFromBackground(currentValue.backgroundColor),
        }}
        className={cx(classes.read, {
          [classes.backgroundColor]: !!currentValue && !!currentValue.backgroundColor,
        })}
      >
        {currentValue && readMask(currentValue.label)}
      </div>
    </div>
  )
}

export const CellPriority = ({ getColor, readMask, actions, readOnly, ...data }: CellPriorityParams): Column => ({
  cellType: 'CellPriority',
  Cell: (instance) => {
    const { value: option, cell } = instance
    const { getCellProps } = cell
    const { edition } = getCellProps()
    const { isBeingEdit, endEdit, save } = edition

    return (
      <div className={classes.container}>
        <PriorityRead value={option} readMask={readMask} />
        {isBeingEdit ? (
          <div style={{ height: '100%', overflow: 'hidden', position: 'absolute', top: 0, left: 0 }}>
            <MUISelect
              value={option}
              options={optionsPiority}
              onChange={({ value }) => save(value)}
              onClose={() => {
                setTimeout(endEdit, 0)
              }}
              style={{ width: '100%' }}
              open={isBeingEdit}
              hidden={true}
            />
          </div>
        ) : null}
      </div>
    )
  },
  readOnly,
  actions: (instance, cell) => {
    const { edition } = cell.getCellProps()
    const { save } = edition

    const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []

    if (readOnly) return resolvedActions

    return [
      ...resolvedActions,
      { separator: true },
      {
        label: 'Priority',
        editAction: true,
        items: optionsPiority.map((opt, index) => ({
          onClick: () => {
            save(opt.value)
            cell.focusCell()
          },
          editAction: true,
          label: opt.label,
          color: opt.backgroundColor,
          hotKeys: [String(index + 1)],
          rightLabel: String(index + 1),
        })),
      },
    ]
  },
  groupingFns,
  width: 120,
  ...data,
})
