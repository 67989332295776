import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useMemo } from 'react';
import moment from 'moment';
import { Table } from 'app/components/Table/Table.jsx';
import { CellText } from 'app/components/Table/Cells';
export function AssetHistory(props) {
    const { assetId, columns, projectId, includedResources, resourceType, requestName, params, tableId } = props;
    const resourcesParams = useMemo(() => ({
        resourceType,
        requestName,
        includedResources: includedResources || [],
        headers: projectId ? { [window.OVM_PROJECT_HEADER]: projectId || '' } : undefined,
        requestData: Object.assign({ id: assetId }, params),
    }), [resourceType, requestName, includedResources, projectId, assetId, params]);
    return (_jsx(Table, { tableId: tableId, resourcesParams: resourcesParams, rowExpander: true, columns: [
            {
                Header: 'History',
                id: 'history',
                columns: [
                    CellText({
                        Header: 'Date',
                        noFilterOption: true,
                        id: `actions.date`,
                        accessor: (item) => moment(item.timestamp).format('DD/MM/YYYY HH:mm'),
                        actions: () => ['copy'],
                        width: 150,
                        readOnly: true,
                    }),
                    CellText({
                        Header: 'User',
                        id: `actions.user`,
                        accessor: 'actor.name',
                        noFilterOption: true,
                        actions: () => ['copy'],
                        width: 100,
                        readOnly: true,
                    }),
                    CellText({
                        Header: 'Action',
                        id: `actions.actionType`,
                        actions: () => ['copy'],
                        noFilterOption: true,
                        accessor: 'verb',
                        width: 75,
                        readOnly: true,
                    }),
                ],
            },
            ...columns,
        ] }));
}
