/** @flow */
import React, { useMemo, useRef, useState } from 'react'
import moment from 'moment'
import cx from 'classnames'
import type { Imputation, Contract, ProjectDepartmentJob, DateDay } from 'app/core/types'
import { getMomentDatesBetween } from 'app/core/utils/dates'

import { useResize } from './useResize'
import classes from './ContractCalendar.module.scss'

export type ContractCalendarProps = {|
  contract?: Contract,
  contracts?: Array<Contract>,
  imputation?: $Shape<Imputation>,
  imputations?: Array<Imputation>,
  startDate: ?DateDay | moment,
  endDate: ?DateDay | moment,
  projectDepartmentJob?: ProjectDepartmentJob,
  setDates: (Array<DateDay> | ((Array<DateDay>) => Array<DateDay>)) => void,
  legendControl: {
    selectedImputation: boolean,
    isImputation: boolean,
    selectedContract: boolean,
    isContract: boolean,
    isJob: boolean,
    isJobImputation: boolean,
    errors: boolean,
  },
|}

type Day = {|
  date: moment,
  formatDate: DateDay,
  selectedContract: boolean,
  selectedImputation: boolean,
  isContract: boolean | 'first' | 'last',
  isImputation: boolean | 'first' | 'last',
  isJob: boolean,
  isJobImputation: boolean,
  errors: boolean,
|}

export function ContractCalendar(props: ContractCalendarProps): React$Element<any> {
  const {
    contract,
    contracts,
    imputation,
    imputations,
    startDate = moment(),
    endDate = moment().add(1, 'month'),
    projectDepartmentJob,
    legendControl,
    setDates,
  } = props

  const containerRef = useRef()

  const [editStartDate, setEditStartDate] = useState<void | Day>()

  function getDatesUsed(arr?: Array<Contract> | Array<Imputation> | Array<ProjectDepartmentJob>) {
    return (
      arr?.reduce((acc, c) => {
        const dates = { ...acc }
        const momentDates: Array<DateDay> =
          getMomentDatesBetween(c.startDate, c.endDate, { format: 'YYYY-MM-DD' }) || []
        momentDates.forEach((date, index) => {
          dates[date] = index === 0 ? 'first' : index === momentDates.length - 1 ? 'last' : true
        })
        return dates
      }, {}) || {}
    )
  }

  const contractsDates = useMemo(() => getDatesUsed(contracts?.filter((i) => i.id !== contract?.id)), [contracts])
  const imputationsDates = useMemo(
    () => getDatesUsed(imputations?.filter((i) => i.id !== imputation?.id)),
    [imputations],
  )

  const jobDates = useMemo(
    // $FlowFixMe
    () => (projectDepartmentJob ? getDatesUsed([projectDepartmentJob]) : null),
    [projectDepartmentJob],
  )
  const jobImputationDates = useMemo(() => {
    if (!projectDepartmentJob?.imputationsInst) return null
    return getDatesUsed(projectDepartmentJob.imputationsInst.filter((i) => i.id !== imputation?.id))
  }, [projectDepartmentJob])

  const [numberOfMonthsToDisplay, setNumberOfMonthsToDisplay] = useState(2)
  const [timeline] = useState(0)

  const monthsToDisplay = useMemo(() => {
    const _monthsToDisplay = []
    const start = moment(startDate)
    const end = moment(endDate)
    const diff = end.diff(start, 'month')
    let startAt = 0
    if (numberOfMonthsToDisplay > diff) {
      startAt = -Math.round((numberOfMonthsToDisplay - diff) / 2 - 1)
    }
    for (let i = 0; i < numberOfMonthsToDisplay; i += 1) {
      _monthsToDisplay.push(moment(start).add(i + timeline + startAt, 'month'))
    }
    return _monthsToDisplay
  }, [startDate, numberOfMonthsToDisplay, timeline])

  function onClickDay(e: SyntheticMouseEvent<any>, day: Day) {
    if (!editStartDate) {
      setEditStartDate(day)
      setDates([day.formatDate])
    } else {
      setDates([editStartDate.formatDate, day.formatDate])
      setEditStartDate()
    }
  }

  function getErrors(data) {
    const { selectedContract, isContract, selectedImputation, isImputation, isJob, isJobImputation } = data
    if (selectedContract && isContract) {
      return true
    }
    if (selectedImputation && isImputation) {
      return true
    }
    if (selectedImputation && isJobImputation) {
      return true
    }
    if (selectedImputation && !isJob) {
      return true
    }
    return false
  }

  function generateDaysOfMonth(month: moment) {
    const startWeek = moment(month).startOf('month').startOf('week') // prettier-ignore
    const endWeek = moment(month).endOf('month').endOf('week') // prettier-ignore
    const acc = moment(startWeek)

    const days: Array<Day> = []
    while (acc.isSameOrBefore(endWeek)) {
      const start = moment(acc).startOf('week')
      const end = moment(acc).endOf('week')
      const accumulator = moment(start)
      while (accumulator.isSameOrBefore(end)) {
        const accDate = accumulator.format('YYYY-MM-DD')
        days.push({
          date: moment(accumulator),
          formatDate: accDate,
          selectedContract: contract ? accDate >= contract.startDate && accDate <= contract.endDate : false,
          selectedImputation: imputation ? accDate >= imputation.startDate && accDate <= imputation.endDate : false,
          isContract: contractsDates?.[accDate],
          isImputation: imputationsDates?.[accDate],
          isJob: jobDates?.[accDate] || false,
          isJobImputation: jobImputationDates?.[accDate] || false,
          errors: getErrors({
            date: moment(accumulator),
            formatDate: accDate,
            selectedContract: contract ? accDate >= contract.startDate && accDate <= contract.endDate : false,
            selectedImputation: imputation ? accDate >= imputation.startDate && accDate <= imputation.endDate : false,
            isContract: contractsDates?.[accDate],
            isImputation: imputationsDates?.[accDate],
            isJob: jobDates?.[accDate] || false,
            isJobImputation: jobImputationDates?.[accDate] || false,
          }),
        })
        accumulator.add(1, 'day')
      }

      acc.add(1, 'week')
    }
    return days
  }

  function getWeekDays() {
    const weekDays = []
    // prettier-ignore
    for (let i = 1; i <= 7; i+=1) weekDays.push(moment().isoWeekday(i).format('dd'))
    return weekDays
  }

  function displayDay(day: Day, month: moment) {
    const {
      date,
      selectedContract,
      selectedImputation,
      formatDate,
      isContract,
      isImputation,
      isJob,
      isJobImputation,
      errors,
    } = day
    const dateLabel = date.date()
    return (
      <div
        onClick={(e) => onClickDay(e, day)}
        key={formatDate}
        className={cx(classes.day, {
          [classes.notInMonth]: !month.isSame(date, 'month'),
          [classes.selectedContract]: legendControl.selectedContract ? selectedContract : false,
          [classes.selectedImputation]: legendControl.selectedImputation ? selectedImputation : false,
          [classes.isContract]: legendControl.isContract ? isContract : false,
          [classes.isImputation]: legendControl.isImputation ? isImputation : false,
          [classes.isJob]: legendControl.isJob ? isJob : false,
          [classes.isJobImputation]: legendControl.isJobImputation ? isJobImputation : false,

          [classes.errors]: legendControl.errors ? errors : false,

          [classes.isContractFirstDay]: legendControl.isContract ? isContract === 'first' : false,
          [classes.isImputationFirstDay]: legendControl.isImputation ? isImputation === 'first' : false,
        })}
      >
        {dateLabel}
      </div>
    )
  }

  function generateMonth(month: moment) {
    const monthLabel = month.format('MMMM YYYY')
    return (
      <div className={classes.monthGrid} key={monthLabel}>
        <div className={classes.monthLabel}>{monthLabel}</div>
        <div className={classes.grid}>
          <div className={classes.daysOfWeek}>
            {getWeekDays().map((wday) => (
              <div key={wday}>{wday}</div>
            ))}
          </div>
          <div className={classes.dates}>{generateDaysOfMonth(month).map((day) => displayDay(day, month))}</div>
        </div>
      </div>
    )
  }

  useResize({ width: 172, height: 178, setter: setNumberOfMonthsToDisplay, ref: containerRef })

  return (
    <div className="flex center alignCenter fullWidth fullHeight">
      <div className={classes.container} ref={containerRef}>
        {monthsToDisplay.map(generateMonth)}
      </div>
    </div>
  )
}
