/** @flow */
const ids = {}
export default (str?: string): string => {
  const nNow = Date.now()
  function resursive(now) {
    if (ids[now]) resursive(now - 1)
    ids[now] = true
    return now.toString()
  }
  return `${str || ''}${resursive(nNow)}`
}
