// @flow
import * as React from 'react'
import cx from 'classnames'
import type { IconType, ElementProps } from 'app/core/types'
import { getClassName } from './icons'

export type FontIconProps = {
  ...ElementProps,
  icon: IconType,
  className?: string,
}

export default class FontIcon extends React.PureComponent<FontIconProps> {
  render(): React$Node {
    const { icon, className, ...props } = this.props
    return <div {...props} className={cx('flex', className, getClassName(icon))} />
  }
}
