/** @flow */

import { map, find } from 'lodash'
import type { Flag, TakeFlag, AssetFlag, ID, FlagRelation } from 'app/core/types'

export const cleanFlag = (flag: { ...Flag }): Flag => ({
  id: flag.id,
  name: flag.name,
  color: flag.color,
  category: flag.category,
  description: flag.description,
})

export const cleanFlags = (flags: Array<Flag>): Array<Object> => map(flags, cleanFlag)

export const createUpdatingFlagsPromises = (
  flags: Array<{ take: ID, flag: ID } | { asset: ID, flag: ID }>,
  oldFlags: Array<TakeFlag | AssetFlag>,
  resource: 'takeFlags' | 'assetFlags',
  type: void | 'delete' | 'merge' | 'past' | string,
): Object => {
  const toCreate = []
  const toDelete = []

  flags.forEach((f) => {
    const rel = find(oldFlags, (of) => of.flag === f.flag)
    if (!rel) toCreate.push(f)
  })

  if (type !== 'merge') {
    oldFlags.forEach((f) => {
      const rel = find(flags, (of) => of.flag === f.flag)
      if (!rel) toDelete.push(f.id)
    })
  }

  return {
    type: 'rel',
    resource,
    toCreate: toCreate.length > 0 ? toCreate : undefined,
    toDelete: toDelete.length > 0 ? toDelete : undefined,
  }
}

export function getRelationDiff(
  initial: Array<FlagRelation>,
  updated: Array<$Shape<FlagRelation>>,
): { toCreate: Array<FlagRelation>, toDelete: Array<ID> } {
  const toCreate = []
  const toDelete = []

  // toCreate
  updated.forEach((relation) => {
    if (!initial.find((rel) => relation.id === rel.id)) {
      toCreate.push(relation)
    }
  })

  // toDelete
  initial.forEach((relation) => {
    if (!updated.find((rel) => relation.id === rel.id)) {
      toDelete.push(relation.id)
    }
  })

  return { toCreate, toDelete }
}
