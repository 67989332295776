// @flow
import React from 'react'
import { find, maxBy, sortBy, map } from 'lodash'
import { ObjectValues } from 'app/libs/flowPolyfills'
import type { IconType, ID, Task, Take, AssetTypes, ResourcesList, TrackingSchema, Asset } from 'app/core/types'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import { assetsTypes } from 'app/core/constants/assetsTypes.js'

export const getLastTakeFromAsset = (asset: Asset, column: Object): ?Take => {
  if (!column || !column.step || !asset?.tasksInst) return undefined

  const task: Task | void = find(asset.tasksInst, (task) => task.step === column.step)

  if (!task) return undefined

  if (task.lastTake && task.takesInst && column.type !== 'lastTake') {
    const take = find(task.takesInst, (take) => take.id === task.lastTake)
    if (take) return take
  }

  // Result of lastTake (id) in a task is wrong from backend when bulk update
  // if (task.lastTake && task.takesInst) return task.takesInst[task.lastTake]
  return maxBy(ObjectValues(task.takesInst), 'number')
}

export const getLastTakeFromTask = (task: Task): ?Take => {
  if (!task?.takesInst) return undefined
  // Result of lastTake (id) in a task is wrong from backend when bulk update
  // if (task.lastTake && task.takesInst) return task.takesInst[task.lastTake]
  return maxBy(ObjectValues(task.takesInst), 'number')
}

export const getOrderingForAssetTypeTrackingSchema = (
  assetType: AssetTypes,
  trackingSchemaType: 'ep' | 'fo' | 'mo' | 'sh',
): number => {
  switch (assetType) {
    case 'ep': {
      const order = { ep: 3, fo: 1, mo: 2, sh: 0 }
      return order[trackingSchemaType]
    }
    case 'fo': {
      const order = { ep: 2, fo: 1, mo: 0, sh: 3 }
      return order[trackingSchemaType]
    }
    case 'mo': {
      const order = { ep: 3, fo: 2, mo: 0, sh: 1 }
      return order[trackingSchemaType]
    }
    case 'sh': {
      const order = { ep: 3, fo: 2, mo: 1, sh: 0 }
      return order[trackingSchemaType]
    }

    default: {
      const order = { ep: 0, fo: 1, mo: 2, sh: 3 }
      return order[trackingSchemaType]
    }
  }
}

type Output = Array<
  | {|
      assetType: AssetTypes,
      icon: IconType,
      label: React$Element<'div'>,
      ordering: number,
      value: ID,
    |}
  | {|
      label: string,
      separator: boolean,
      value: void,
    |},
>

export const sortTrackingSchemasOptions = (
  trackingSchemas: ResourcesList<TrackingSchema>,
  assetType: AssetTypes,
): Output => {
  const sortedOptions = sortBy(
    map(trackingSchemas, (trackingSchema) => {
      return {
        value: trackingSchema.id,
        label: (
          <div className="flex row noWrap alignCenter">
            {trackingSchema.isExternal ? (
              <FontIcon icon="fas-external-link-square-alt" className="grey marginRight5" />
            ) : null}
            {trackingSchema.name}
          </div>
        ),
        icon: assetIcons(trackingSchema.assetType),
        ordering: getOrderingForAssetTypeTrackingSchema(assetType, trackingSchema.assetType),
        assetType: trackingSchema.assetType,
      }
    }),
    ['ordering', 'label'],
  )

  const options = []
  let currentAssetType

  sortedOptions.forEach((opt, index) => {
    if (currentAssetType !== opt.assetType) {
      options.push({ value: undefined, separator: true, label: `${assetsTypes[opt.assetType]}s` })
      currentAssetType = opt.assetType
    }
    options.push(opt)
  })

  return options
}

export const getCalculatedFieldType = (column) => {
  const numberTypes = [
    'task_priority_accessor',
    'task_lastTake_number_accessor',
    'task_priority_accessor',
    'task_quoteEstimLength_accessor',
    'task_realEstimLength_accessor',
    'task_validLength_accessor',
    'task_status_order_accessor',
    'task_status_statusType_accessor',
    'task_lastTake_number_accessor',
    // 'task_lastTake_estimLength_accessor',
  ]
  const { blocklyWorkspace } = column
  const { type, inputs } = blocklyWorkspace?.blocks?.blocks[0].inputs.base.block || {}
  const inputsTypes = map(inputs, (input) => input.block.type)
  let calculatedFieldType = 'unknown'
  switch (type) {
    case 'number_block': {
      const isNumber = inputsTypes.every((inputType) => numberTypes.includes(inputType))
      calculatedFieldType = isNumber ? 'number' : 'duration'
      break
    }
    case 'list_block':
    case 'result': {
      const numberListTypes = []
      const taskSummaryTypes = [
        'task_takes_name_accessor',
        'task_takes_comment_accessor',
        'task_takes_number_accessor',
        'task_takes_estimLength_accessor',
        'task_takes_statusUpdatedAt_accessor',
      ]
      /* const taskListTypes = [
        'task_activities_duration_accessor',
        'task_activities_activityType_accessor',
        'task_activities_comment_accessor',
        'task_activities_date_accessor',
      ] */
      const { operation } = blocklyWorkspace.blocks.blocks[0].inputs.base.block.fields
      const { type: listElementType } = inputs.list.block
      const isListOfNumber = numberListTypes.includes(listElementType)
      const isListOfTask = taskSummaryTypes.includes(listElementType)
      switch (true) {
        case ['maximum', 'minimum'].includes(operation) && isListOfNumber:
        case ['count', 'median', 'mean', 'sum'].includes(operation): {
          calculatedFieldType = 'number'
          break
        }
        case ['maximum', 'minimum'].includes(operation) && isListOfTask: {
          calculatedFieldType = 'taskSummary'
          break
        }
        default:
          calculatedFieldType = 'dynappsummary'
      }
      break
    }
    case 'aggregate_block':
      calculatedFieldType = 'aggregation'
      break
    default:
      calculatedFieldType = 'duration'
  }

  return calculatedFieldType
}
