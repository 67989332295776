/*eslint-disable */

import React from 'react'
import Icon from '../Icon.jsx'

export default class Planet_1 extends React.Component {
  static defaultProps = {
    width: 20,
    height: 20,
    fill: 'grey',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="0 0 18 18">
        <path d="M9,0C4,0,0,4,0,9c0,4.4,3.1,8,7.2,8.8c0,0,0,0,0,0C7.8,17.9,8.4,18,9,18c2.4,0,4.6-0.9,6.2-2.5 c0,0,0.1-0.1,0.1-0.1C17,13.8,18,11.5,18,9C18,4,14,0,9,0z M15.1,14.2c-0.4-0.9-1.1-2.2-2.2-2.1c-1.1,0.1-1.6,0.9-2,1.6 c-0.3,0.5-0.6,1-1,1.1c-1.4,0.3-2.3,1.5-2.7,2C3.6,15.9,1,12.8,1,9c0-2.8,1.5-5.3,3.6-6.7c0.1,0.4,0.1,1.2-0.6,2.4 C4,4.9,3.9,5,3.8,5.1c-0.6,1.1-1.6,2-1.2,3C2.9,9,4.2,8.7,5.1,9.4c1,0.8,1.3,2.7,3,2.3c1.9-0.4,1-4.1,4.8-4.3 c2.2-0.1,3.8,1.4,4.1,1.8C16.9,11.1,16.2,12.8,15.1,14.2z" />
      </Icon>
    )
  }
}

/* eslint-enable */
