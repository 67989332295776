// @flow
import moment from 'moment'

type Arg = Date | string

export default (date: Arg = new Date()): Date => {
  return moment(date || new Date())
    .local()
    .startOf('day')
    .toDate()
}
