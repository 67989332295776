// @flow
import * as React from 'react'
import pipe from 'app/core/utils/pipe'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import resources from 'app/store/resources'
import { type Article } from 'app/core/types'
import { ModalConfirmForm } from 'app/components/Modal'
import { getArticles } from 'app/store/selectors/getArticles'
import { FormData } from 'app/components/Form'
import itemsWithDepthIndent from 'app/core/utils/itemsWithDepthIndent'
import { ObjectValues } from 'app/libs/flowPolyfills'
import type { ID } from 'app/core/types'

type Props = {
  assetId: string,
  articles: {
    [articleId: string]: Article,
  },
}

class ModalCreateArticle extends React.PureComponent<Props> {
  onSaveCreateArticle = (data: Object) => {
    const { assetId } = this.props

    return resources.articles.create({
      asset: assetId,
      title: data.title,
      parent: data.parent,
    })
  }

  render(): React$Node {
    const { assetId, articles, ...props } = this.props
    return (
      // $FlowFixMe[cannot-spread-inexact] $FlowFixMe Error when updating flow
      <ModalConfirmForm title="Create wiki" {...props}>
        <FormData
          flashNotifSuccessLabel="Wiki Created"
          onSave={this.onSaveCreateArticle}
          properties={[
            {
              key: 'title',
              label: 'Title',
              type: 'string',
              elementProps: {
                isRequired: true,
              },
            },
            {
              key: 'parent',
              label: 'Parent article',
              type: 'select',
              elementProps: {
                fullWidth: true,
                options: [
                  {
                    label: 'No parent',
                    value: '',
                  },
                  ...ObjectValues(articles).map((article) => ({
                    label: itemsWithDepthIndent(ObjectValues(articles), article, 'parent', 'title'),
                    value: article.id,
                  })),
                ],
              },
            },
          ]}
        />
      </ModalConfirmForm>
    )
  }
}

const pipeInst: Pipe<{ assetId: ID }, typeof ModalCreateArticle> = pipe()

const Component: React$ComponentType<any> = pipeInst
  .connect((state, props) => ({ articles: getArticles(state, props.assetId) }))
  .render(ModalCreateArticle)

export default Component
