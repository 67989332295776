// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellProgression-___CellProgression-module__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 5px;
}
.app-components-Table-Cells-CellProgression-___CellProgression-module__status {
  font-size: 12px;
}
.app-components-Table-Cells-CellProgression-___CellProgression-module__difference {
  font-size: 12px;
  color: grey;
}

.app-components-Table-Cells-CellProgression-___CellProgression-module__equal {
  background-color: #008ae6;
}

.app-components-Table-Cells-CellProgression-___CellProgression-module__lower > .app-components-Table-Cells-CellProgression-___CellProgression-module__left {
  background-color: var(--colors-green);
}
.app-components-Table-Cells-CellProgression-___CellProgression-module__lower > .app-components-Table-Cells-CellProgression-___CellProgression-module__right {
  background-color: lightgray;
}

.app-components-Table-Cells-CellProgression-___CellProgression-module__higher > .app-components-Table-Cells-CellProgression-___CellProgression-module__left {
  background-color: var(--colors-green);
}
.app-components-Table-Cells-CellProgression-___CellProgression-module__higher > .app-components-Table-Cells-CellProgression-___CellProgression-module__right {
  background-color: var(--colors-red);
}

.app-components-Table-Cells-CellProgression-___CellProgression-module__equal,
.app-components-Table-Cells-CellProgression-___CellProgression-module__lower,
.app-components-Table-Cells-CellProgression-___CellProgression-module__higher {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
}

.app-components-Table-Cells-CellProgression-___CellProgression-module__progressBar {
  height: 100%;
}

.app-components-Table-Cells-CellProgression-___CellProgression-module__minFormat {
  line-height: 14px;
  height: 14px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellProgression/CellProgression.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qCAAqC;AACvC;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,qCAAqC;AACvC;AACA;EACE,mCAAmC;AACrC;;AAEA;;;EAGE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  position: relative;\n  z-index: 1;\n  padding: 5px;\n}\n.status {\n  font-size: 12px;\n}\n.difference {\n  font-size: 12px;\n  color: grey;\n}\n\n.equal {\n  background-color: #008ae6;\n}\n\n.lower > .left {\n  background-color: var(--colors-green);\n}\n.lower > .right {\n  background-color: lightgray;\n}\n\n.higher > .left {\n  background-color: var(--colors-green);\n}\n.higher > .right {\n  background-color: var(--colors-red);\n}\n\n.equal,\n.lower,\n.higher {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  height: 10px;\n  border-radius: 5px;\n  overflow: hidden;\n  width: 100%;\n}\n\n.progressBar {\n  height: 100%;\n}\n\n.minFormat {\n  line-height: 14px;\n  height: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellProgression-___CellProgression-module__container`,
	"status": `app-components-Table-Cells-CellProgression-___CellProgression-module__status`,
	"difference": `app-components-Table-Cells-CellProgression-___CellProgression-module__difference`,
	"equal": `app-components-Table-Cells-CellProgression-___CellProgression-module__equal`,
	"lower": `app-components-Table-Cells-CellProgression-___CellProgression-module__lower`,
	"left": `app-components-Table-Cells-CellProgression-___CellProgression-module__left`,
	"right": `app-components-Table-Cells-CellProgression-___CellProgression-module__right`,
	"higher": `app-components-Table-Cells-CellProgression-___CellProgression-module__higher`,
	"progressBar": `app-components-Table-Cells-CellProgression-___CellProgression-module__progressBar`,
	"minFormat": `app-components-Table-Cells-CellProgression-___CellProgression-module__minFormat`
};
export default ___CSS_LOADER_EXPORT___;
