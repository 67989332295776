/**
 * ________________________________________________________________________________
 *
 *
 *                              DEPRECATED
 *
 *                    Use app/components/form/Autocomplete
 *________________________________________________________________________________
 */
import React from 'react'
import PropTypes from 'prop-types'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import cx from 'classnames'
import classes from './Select.module.scss'
import Validators from '../Validators/Validators.jsx'

export default class Select extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    isRequired: PropTypes.bool,
    defaultOption: PropTypes.object,
    value: PropTypes.any,
    validators: PropTypes.object,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    serverErrors: PropTypes.array,
  }

  static defaultProps = {
    onChange: (e) => {},
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.valid()
    }
  }

  valid() {
    if (this.refs.validator) {
      return this.refs.validator.valid()
    }
    return null
  }

  getValidators() {
    return {
      required: this.props.isRequired,
      ...this.props.validators,
    }
  }

  renderOptions() {
    const { options, children } = this.props

    if (options) {
      return options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          className={classes.option}
          style={{
            backgroundColor: option.backgroundColor,
            color: option.backgroundColor ? '#fff' : null,
            textShadow: option.backgroundColor ? '0px 0px 0px #fff' : null,
          }}
        >
          {option.label}
        </option>
      ))
    }

    return children
  }

  render() {
    const { value, children, onChange, className, serverErrors, isRequired, options, ...props } = this.props

    let selectStyle = {}

    if (options) {
      const currentOption = options.find((option) => option.value === value)

      if (currentOption && currentOption.backgroundColor) {
        selectStyle = {
          backgroundColor: currentOption.backgroundColor,
          color: 'transparent',
          textShadow: '0px 0px 0px #fff',
        }
      }
    }

    const _className = cx(`${classes.container} ${className}`, {
      [classes.error]: this.state.error,
    })

    return (
      <div className={_className} {...props}>
        <select
          ref="input"
          className={classes.select}
          value={value || undefined}
          onChange={onChange}
          style={selectStyle}
          disabled={this.props.disabled}
        >
          {this.renderOptions()}
        </select>

        <div className={classes.icon}>
          <FontIcon icon="fas-angle-down" />
        </div>

        <Validators
          ref="validator"
          className={classes.fieldError}
          value={value}
          errors={serverErrors}
          onSuccess={() => this.setState({ error: false })}
          onError={() => this.setState({ error: true })}
          validators={this.getValidators()}
        />
      </div>
    )
  }
}
