import React from 'react'
import PropTypes from 'prop-types'

export default class TabsItem extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
  }

  static defaultProps = {}

  render() {
    const { name, children, ...props } = this.props

    return <div {...props}>{children}</div>
  }
}
