/** @flow */
import { assetIcons } from 'app/components/Icons/assetsIcons.js'
import { assetsTypes } from 'app/core/constants/assetsTypes'
import type { AssetTypes, Option } from 'app/core/types'

export const getAssetTypesOptions = (
  types: Array<AssetTypes>,
  includeOrExclude: 'include' | 'exclude',
): Array<Option> => {
  return [
    ...Object.entries(assetsTypes)
      .filter(([value, label]) => {
        const indexOfVal = types.indexOf(value)

        return includeOrExclude === 'include' ? indexOfVal !== -1 : indexOfVal === -1
      })
      .map(([value, label]: *) => ({
        label,
        value,
        icon: assetIcons(value),
      })),
  ]
}
