// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-OrganizationChart-DetailPage-___WebcamModal-module__modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-components-OrganizationChart-DetailPage-___WebcamModal-module__pictureContainer {
  position: relative;
  height: 500px;
  width: 900px;
  margin-bottom: 20px;
}

.app-components-OrganizationChart-DetailPage-___WebcamModal-module__pictureContainer .app-components-OrganizationChart-DetailPage-___WebcamModal-module__picture {
    height: 100%;
    width: 100%;
  }

.app-components-OrganizationChart-DetailPage-___WebcamModal-module__pictureContainer .app-components-OrganizationChart-DetailPage-___WebcamModal-module__leftPictureOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 500px;
    background-color: black;
    opacity: 0.7;
  }

.app-components-OrganizationChart-DetailPage-___WebcamModal-module__pictureContainer .app-components-OrganizationChart-DetailPage-___WebcamModal-module__rightPictureOverlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 500px;
    background-color: black;
    opacity: 0.7;
  }

.app-components-OrganizationChart-DetailPage-___WebcamModal-module__buttonContainer {
  border: 2px solid gray;
  border-radius: 100%;
  background-color: gray;
  width: 50px;
  height: 50px;
  position: relative;
}

.app-components-OrganizationChart-DetailPage-___WebcamModal-module__takeButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 100%;
  background-color: white;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./app/components/OrganizationChart/DetailPage/WebcamModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,mBAAmB;AA0BrB;;AAxBE;IACE,YAAY;IACZ,WAAW;EACb;;AAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,YAAY;EACd;;AAEA;IACE,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,YAAY;EACd;;AAGF;EACE,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".modalContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.pictureContainer {\n  position: relative;\n  height: 500px;\n  width: 900px;\n  margin-bottom: 20px;\n\n  .picture {\n    height: 100%;\n    width: 100%;\n  }\n\n  .leftPictureOverlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 250px;\n    height: 500px;\n    background-color: black;\n    opacity: 0.7;\n  }\n\n  .rightPictureOverlay {\n    position: absolute;\n    top: 0;\n    right: 0;\n    width: 250px;\n    height: 500px;\n    background-color: black;\n    opacity: 0.7;\n  }\n}\n\n.buttonContainer {\n  border: 2px solid gray;\n  border-radius: 100%;\n  background-color: gray;\n  width: 50px;\n  height: 50px;\n  position: relative;\n}\n\n.takeButton {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 30px;\n  height: 30px;\n  border: 2px solid white;\n  border-radius: 100%;\n  background-color: white;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `app-components-OrganizationChart-DetailPage-___WebcamModal-module__modalContainer`,
	"pictureContainer": `app-components-OrganizationChart-DetailPage-___WebcamModal-module__pictureContainer`,
	"picture": `app-components-OrganizationChart-DetailPage-___WebcamModal-module__picture`,
	"leftPictureOverlay": `app-components-OrganizationChart-DetailPage-___WebcamModal-module__leftPictureOverlay`,
	"rightPictureOverlay": `app-components-OrganizationChart-DetailPage-___WebcamModal-module__rightPictureOverlay`,
	"buttonContainer": `app-components-OrganizationChart-DetailPage-___WebcamModal-module__buttonContainer`,
	"takeButton": `app-components-OrganizationChart-DetailPage-___WebcamModal-module__takeButton`
};
export default ___CSS_LOADER_EXPORT___;
