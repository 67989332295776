/** @flow */
import api from 'app/core/api/api'
// eslint-disable-next-line no-unused-vars
import type { AssetTypes, FlagCategory, TaskTypes, StoreRequest, ID } from 'app/core/types'
import { error } from 'app/components/Notifications/notify'
import type { ResourceConfig } from 'app/core/types/StoreResourcesActions'
import { transformResults } from './utils/transformResults'

export type Model = 'asset' | 'attribute' | 'flag' | 'task' | 'step' | 'dynamicApproval' | 'filter' | 'person'

const formatDispatch = (results) =>
  results.map((value) => ({
    ...value,
    parent__assetType: undefined,
    parent__name: undefined,
    __highlight: undefined,
  }))

export type Autocomplete<Res = StoreRequest> = (
  queries: $Exact<{
    query: string,
    assetType?: AssetTypes,
    category?: 'take' | 'article' | 'dynamicApprovalValue' | 'post' | AssetTypes,
    taskType?: ?TaskTypes,
    assetParent?: ?ID,
    groupUuid?: ?ID,
    parentUuid?: ?ID,
    projects?: ?string,
  }>,
  headers?: ?{ [header: string]: string },
  config?: ?{ ...ResourceConfig<>, formatMetaOnSearchResult: ?boolean },
) => Res

export const autocomplete: (Model) => Autocomplete<StoreRequest> =
  (model: Model) =>
  (queries, headers, config = {}) => {
    const { query, assetType, category, taskType, ...restQueries } = queries
    const { formatMetaOnSearchResult } = config || {}

    return {
      type: 'read',
      requestKey: config?.requestKey,
      request: () =>
        api
          .autocomplete(null, { model, query, assetType, category, taskType, ...restQueries }, headers)
          // Concat nears results (aprox results of request string) with close results
          .then((res) => {
            const { results, nears } = res
            return {
              ...res,
              results: results.concat(nears),
            }
          })
          .catch((err) => {
            error("We're having a problem with this search. If the problem persist, contact the support.")
          })
          .then(transformResults(formatMetaOnSearchResult ? { formatDispatch } : undefined)),
    }
  }
