/** @flow */
import React from 'react'
import { forEach } from 'lodash'
import type { Step, ResourcesList, PostBoardLink, Asset } from 'app/core/types'
import type { DefaultCellProps, Column, Cell } from 'app/components/Table/types.js'
import { CellNotesRead } from './CellNotesRead.jsx'

type CellNotesParams = {|
  ...DefaultCellProps,
  step: Step,
  episodes: ResourcesList<Asset>,
  getAsset: (cell: Cell) => Object,
  enableDelete?: boolean,
|}

export const CellNotesFollowUp = ({ enableDelete, episodes, step, getAsset, ...data }: CellNotesParams): Column => ({
  cellType: 'CellNotes',
  Cell: (instance) => {
    const { cell } = instance
    const { value: postBoardLinksInst } = cell

    const postBoardLinks: ResourcesList<PostBoardLink> = postBoardLinksInst
    const notes = {}

    forEach(postBoardLinks, (postBoardLink) => {
      if (postBoardLink) if (!postBoardLink.notesInst || Object.keys(postBoardLink.notesInst) === 0) return

      const episodeName = postBoardLink.episode__name

      if (!episodeName) return

      forEach(postBoardLink.notesInst, (note) => {
        if ((step.id === 'general' && note.step === null) || step.id === note.step) {
          if (!notes[episodeName]) notes[episodeName] = []
          notes[episodeName].push(note)
        }
      })
    })

    if (Object.keys(notes).length === 0) {
      return <div className="flex center alignCenter fullHeight fullWidth lightgrey">No note</div>
    }

    return <CellNotesRead asset={getAsset(cell)} episode={notes} cell={cell} instance={instance} />
  },
  controlledSelection: true,
  width: 300,
  ...data,
})
