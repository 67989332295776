/** @flow */
import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import type { ElementProps } from 'app/core/types'
import { openPictures } from 'app/components/PictureVisualization/PictureVisualization.tsx'
import classes from './TextRead.module.scss'
import 'app/components/TextEditor/Plugins/CodeBlock/theme/codeBlock.module.scss'

type Props = {|
  text: string,
  className?: string,
  padding?: number,
  getRef?: Function,
  containerProps?: Object,
  qlProps?: ElementProps,
  style?: Object,
  preventImageReflow?: boolean,
|}

export default function (props: Props): React$Node {
  const { text = '', className = '', padding, getRef, containerProps, qlProps, preventImageReflow, ...rest } = props
  const readRef: React$ElementRef<any> = useRef()

  useEffect(() => {
    if (readRef.current) {
      const imgArray: Array<HTMLImageElement> = Array.from(readRef.current.querySelectorAll('img'))
      const imgOptions = imgArray.map((img) => ({ url: img.src, name: img.src }))
      imgArray.forEach((img, index) => {
        img.style.cursor = 'pointer'
        img.style.position = 'relative'
        img.style.zIndex = '1'
        img.onclick = () => openPictures(imgOptions, false, false, index)
      })
      Array.from(readRef.current?.querySelectorAll('a') || []).forEach((img: HTMLElement) => {
        img.style.position = 'relative'
        img.style.zIndex = '1'
        img.style.whiteSpace = 'nowrap'
      })
    }
  }, [text])

  if (!text) return null

  return (
    <div
      ref={(c) => {
        getRef && getRef(c)
      }}
      className="ql-snow fullWidth"
      {...containerProps}
    >
      <div className="ql-editor" {...qlProps} ref={readRef}>
        <div
          className={cx(classes.TextRead, className, {
            [classes.preventImageReflow]: preventImageReflow,
          })}
          {...rest}
          dangerouslySetInnerHTML={{ __html: window.unescape(text) }}
        />
      </div>
    </div>
  )
}
