/** @flow */

import type { Option } from 'app/core/types'
import { getServerConfig } from './getServerConfig'

export const optionsTasksType = (taskTypes: Array<Object>): Array<Option> => {
  const config = getServerConfig()
  const tasksTypeOptions: Array<Option> = taskTypes.map((tt) => ({ value: tt.id, label: config.TASK_TYPE[tt.name] }))
  return tasksTypeOptions
}
