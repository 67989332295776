// @flow
import api from 'app/core/api/api.js'
import type { StoreResourceDef } from 'app/core/types'
import store from 'app/store/index.ts'
import { setNotifsCount } from 'app/store/actions/notifsCount'
import type { NotificationsActions } from 'app/core/types/StoreResourcesCustomActions'
import { transformResults } from './utils/transformResults'

export const notifications: StoreResourceDef<NotificationsActions<>> = {
  resourceType: 'notifications',
  actions: (getResources: Object) => ({
    fetchUserNotifications: ({ id }, requestKey, config) => {
      const { params = {}, ...restConfig } = config || {}
      return {
        type: 'read',
        requestKey,
        request: () =>
          api.users.notifications({ id }, params.queries).then((res) => {
            store.dispatch(setNotifsCount(res.unread))
            return transformResults(restConfig)(res)
          }),
      }
    },
  }),
}
