let devWarning
const env = process.env.NODE_ENV

if (env === 'development') {
  devWarning = (fn) => fn()
} else {
  devWarning = function () {
    return null
  }
}

export const createWarningOnce = (fn) => {
  if (env !== 'development')
    return function () {
      return null
    }

  let alreadyExcecuted = false
  return (...args) => {
    if (alreadyExcecuted) return
    alreadyExcecuted = true
    fn(...args)
  }
}

const finalDevWarning = devWarning

export default finalDevWarning
