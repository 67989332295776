// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__subContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;

  margin-top: 10px;
}

.app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__toolbar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
}

.app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__scrollBlock {
  max-height: 250px;
  overflow-y: auto;
  width: -moz-fit-content;
  width: fit-content;
}

.app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__noDynamicApprovalItem {
  width: 270px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__iconButton {
  padding: 4px;
}
`, "",{"version":3,"sources":["webpack://./app/pages/HR/HRSettings/PeopleTableEditor/ContractAttributesEditor/ContractAttributesEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;;EAEjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.subContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n\n  margin-top: 10px;\n}\n\n.toolbar {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n  flex-wrap: nowrap;\n}\n\n.scrollBlock {\n  max-height: 250px;\n  overflow-y: auto;\n  width: fit-content;\n}\n\n.noDynamicApprovalItem {\n  width: 270px;\n  height: 250px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n}\n\n.iconButton {\n  padding: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__container`,
	"subContainer": `app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__subContainer`,
	"toolbar": `app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__toolbar`,
	"scrollBlock": `app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__scrollBlock`,
	"noDynamicApprovalItem": `app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__noDynamicApprovalItem`,
	"iconButton": `app-pages-HR-HRSettings-PeopleTableEditor-ContractAttributesEditor-___ContractAttributesEditor-module__iconButton`
};
export default ___CSS_LOADER_EXPORT___;
