// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Table-Cells-CellFlags-___CellFlags-module__container {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
}

.app-components-Table-Cells-CellFlags-___CellFlags-module__flagReadOnly {
  display: inline-block;
  color: #fff;
  background-color: var(--colors-grey);
  margin: 2px;
  padding: 0 3px;
  border-radius: 7px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  display: relative;
  z-index: 1;
  white-space: nowrap;
  height: -moz-fit-content;
  height: fit-content;
}

.app-components-Table-Cells-CellFlags-___CellFlags-module__exponent {
  font-size: 12px;
  margin: 2px;
  padding: 0 5px;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: bold;
  color: dimgrey;
}
`, "",{"version":3,"sources":["webpack://./app/components/Table/Cells/CellFlags/CellFlags.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,oCAAoC;EACpC,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,mBAAmB;EACnB,wBAAmB;EAAnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,cAAc;EACd,wBAAmB;EAAnB,mBAAmB;EACnB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n  max-height: 100%;\n}\n\n.flagReadOnly {\n  display: inline-block;\n  color: #fff;\n  background-color: var(--colors-grey);\n  margin: 2px;\n  padding: 0 3px;\n  border-radius: 7px;\n  font-weight: bold;\n  font-size: 12px;\n  text-align: center;\n  display: relative;\n  z-index: 1;\n  white-space: nowrap;\n  height: fit-content;\n}\n\n.exponent {\n  font-size: 12px;\n  margin: 2px;\n  padding: 0 5px;\n  height: fit-content;\n  font-weight: bold;\n  color: dimgrey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-components-Table-Cells-CellFlags-___CellFlags-module__container`,
	"flagReadOnly": `app-components-Table-Cells-CellFlags-___CellFlags-module__flagReadOnly`,
	"exponent": `app-components-Table-Cells-CellFlags-___CellFlags-module__exponent`
};
export default ___CSS_LOADER_EXPORT___;
