/*eslint-disable */

import React from 'react'
import Icon from '../Icon.jsx'
import classes from './planet_9.module.scss'

export default class Planet_9 extends React.Component {
  static defaultProps = {
    width: 27.8,
    height: 28.4,
    fill: 'grey',
  }

  render() {
    return (
      <Icon {...this.props} viewBox="-5 -5 27.8 28.4">
        <path
          d="M9,18c-5,0-9-4-9-9c0-2.4,0.9-4.7,2.6-6.4c3.5-3.5,9.2-3.5,12.7,0s3.5,9.2,0,12.7C13.7,17.1,11.4,18,9,18z
                     M9,1.1C4.6,1.1,1.1,4.6,1.1,9c0,2.1,0.8,4.1,2.3,5.6c3.1,3.1,8.1,3.1,11.2,0s3.1-8.1,0-11.2l0,0C13.1,1.9,11.1,1.1,9,1.1z"
        />
        <g>
          <clipPath id="SVGID_2_">
            <circle id="SVGID_1_" cx="9" cy="9.4" r="8.5" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            className={classes.st1}
            d="M19,28.4c-4.9,0-8.8-3.9-8.8-8.8s3.9-8.8,8.8-8.8s8.8,3.9,8.8,8.8c0,2.3-0.9,4.6-2.6,6.2
                        C23.5,27.4,21.3,28.4,19,28.4z M19,11.4c-4.5,0-8.1,3.6-8.1,8.1s3.6,8.1,8.1,8.1c4.5,0,8.1-3.6,8.1-8.1c0-2.1-0.9-4.2-2.4-5.7
                        C23.2,12.3,21.1,11.4,19,11.4L19,11.4z"
          />
          <path
            className={classes.st1}
            d="M17.5,26.7c-4.8,0-8.6-3.9-8.6-8.6s3.9-8.6,8.6-8.6c4.8,0,8.6,3.9,8.6,8.6c0,2.3-0.9,4.5-2.5,6.1
                        C22,25.8,19.8,26.7,17.5,26.7z M17.5,9.8c-4.6,0-8.3,3.7-8.3,8.3s3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3c0-2.2-0.9-4.3-2.4-5.9
                        C21.8,10.7,19.7,9.8,17.5,9.8L17.5,9.8z"
          />
          <path
            className={classes.st1}
            d="M16,25.4c-4.9,0-8.8-3.9-8.8-8.8c0-4.9,3.9-8.8,8.8-8.8c4.9,0,8.8,3.9,8.8,8.8c0,2.3-0.9,4.6-2.6,6.2
                        C20.6,24.5,18.4,25.5,16,25.4z M16,8.5c-4.5,0-8.1,3.6-8.1,8.1s3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1c0-2.1-0.9-4.2-2.4-5.7
                        C20.3,9.4,18.2,8.5,16,8.5L16,8.5z"
          />
          <path
            className={classes.st1}
            d="M14.6,23.8c-4.8,0-8.6-3.9-8.6-8.6s3.9-8.6,8.6-8.6s8.6,3.9,8.6,8.6c0,2.3-0.9,4.5-2.5,6.1
                        C19.1,22.9,16.9,23.8,14.6,23.8z M14.6,6.9c-4.6,0-8.3,3.7-8.3,8.3s3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3c0-2.2-0.9-4.3-2.4-5.9
                        C18.9,7.8,16.8,6.9,14.6,6.9L14.6,6.9z"
          />
          <path
            className={classes.st1}
            d="M13.1,22.4c-4.8,0-8.6-3.9-8.6-8.6c0-4.8,3.9-8.6,8.6-8.6c4.8,0,8.6,3.9,8.6,8.6c0,2.3-0.9,4.5-2.5,6.1
                        C17.6,21.5,15.4,22.4,13.1,22.4z M13.1,5.4c-4.6,0-8.3,3.7-8.3,8.3c0,4.6,3.7,8.3,8.3,8.3c4.6,0,8.3-3.7,8.3-8.3
                        c0-2.2-0.9-4.3-2.4-5.9C17.4,6.3,15.3,5.4,13.1,5.4L13.1,5.4z"
          />
          <path
            className={classes.st1}
            d="M11.7,21.1c-4.9,0-8.8-3.9-8.8-8.8s3.9-8.8,8.8-8.8c4.9,0,8.8,3.9,8.8,8.8c0,2.3-0.9,4.6-2.6,6.2
                        C16.3,20.2,14,21.1,11.7,21.1z M11.7,4.2c-4.5,0-8.1,3.6-8.1,8.1c0,4.5,3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1c0-2.1-0.9-4.2-2.4-5.7
                        C15.9,5,13.8,4.2,11.7,4.2L11.7,4.2z"
          />
          <path
            className={classes.st1}
            d="M10.2,19.5c-4.8,0-8.6-3.9-8.6-8.6c0-2.3,0.9-4.5,2.5-6.1C7.5,1.3,13,1.4,16.3,4.8c3.3,3.4,3.3,8.8,0,12.2
                        C14.7,18.5,12.5,19.5,10.2,19.5z M10.2,2.5c-4.6,0-8.3,3.7-8.3,8.3c0,2.2,0.9,4.3,2.4,5.9c3.2,3.2,8.5,3.2,11.7,0
                        c3.2-3.2,3.2-8.4,0-11.7C14.5,3.4,12.4,2.5,10.2,2.5L10.2,2.5z"
          />
        </g>
      </Icon>
    )
  }
}

/* eslint-enable */
