/** @flow */

export default [
  // Noms de Couleur HTML Rouge
  [
    { title: 'IndianRed', color: '#CD5C5C', rgb: 'rgb(205, 92, 92)' },
    { title: 'LightCoral', color: '#F08080', rgb: 'rgb(240, 128, 128)' },
    { title: 'Salmon', color: '#FA8072', rgb: 'rgb(250, 128, 114)' },
    { title: 'DarkSalmon', color: '#E9967A', rgb: 'rgb(233, 150, 122)' },
    { title: 'Crimson', color: '#DC143C', rgb: 'rgb(220, 20, 60)' },
    { title: 'Red', color: '#FF0000', rgb: 'rgb(255, 0, 0)' },
    { title: 'FireBrick', color: '#B22222', rgb: 'rgb(178, 34, 34)' },
    { title: 'DarkRed', color: '#8B0000', rgb: 'rgb(139, 0, 0)' },
  ],
  // Noms de Couleur HTML Rose
  [
    { title: 'Pink', color: '#FFC0CB', rgb: 'rgb(255, 192, 203)' },
    { title: 'LightPink', color: '#FFB6C1', rgb: 'rgb(255, 182, 193)' },
    { title: 'HotPink', color: '#FF69B4', rgb: 'rgb(255, 105, 180)' },
    { title: 'DeepPink', color: '#FF1493', rgb: 'rgb(255, 20, 147)' },
    { title: 'MediumVioletRed', color: '#C71585', rgb: 'rgb(199, 21, 133)' },
    { title: 'PaleVioletRed', color: '#DB7093', rgb: 'rgb(219, 112, 147)' },
  ],
  // Noms de Couleur HTML Saumon
  [
    { title: 'LightSalmon', color: '#FFA07A', rgb: 'rgb(255, 160, 122)' },
    { title: 'Coral', color: '#FF7F50', rgb: 'rgb(255, 127, 80)' },
    { title: 'Tomato', color: '#FF6347', rgb: 'rgb(255, 99, 71)' },
    { title: 'OrangeRed', color: '#FF4500', rgb: 'rgb(255, 69, 0)' },
    { title: 'DarkOrange', color: '#FF8C00', rgb: 'rgb(255, 140, 0)' },
    { title: 'Orange', color: '#FFA500', rgb: 'rgb(255, 165, 0)' },
  ],
  // Noms de Couleur HTML Jaune
  [
    { title: 'Gold', color: '#FFD700', rgb: 'rgb(255, 215, 0)' },
    { title: 'Yellow', color: '#FFFF00', rgb: 'rgb(255, 255, 0)' },
    { title: 'LightYellow', color: '#FFFFE0', rgb: 'rgb(255, 255, 224)' },
    { title: 'LemonChiffon', color: '#FFFACD', rgb: 'rgb(255, 250, 205)' },
    { title: 'LightGoldenrodYellow', color: '#FAFAD2', rgb: 'rgb(250, 250, 210)' },
    { title: 'PapayaWhip', color: '#FFEFD5', rgb: 'rgb(255, 239, 213)' },
    { title: 'Moccasin', color: '#FFE4B5', rgb: 'rgb(255, 228, 181)' },
    { title: 'PeachPuff', color: '#FFDAB9', rgb: 'rgb(255, 218, 185)' },
    { title: 'PaleGoldenrod', color: '#EEE8AA', rgb: 'rgb(238, 232, 170)' },
    { title: 'Khaki', color: '#F0E68C', rgb: 'rgb(240, 230, 140)' },
    { title: 'DarkKhaki', color: '#BDB76B', rgb: 'rgb(189, 183, 107)' },
  ],
  // Noms de Couleur HTML Violet
  [
    { title: 'Lavender', color: '#E6E6FA', rgb: 'rgb(230, 230, 250)' },
    { title: 'Thistle', color: '#D8BFD8', rgb: 'rgb(216, 191, 216)' },
    { title: 'Plum', color: '#DDA0DD', rgb: 'rgb(221, 160, 221)' },
    { title: 'Violet', color: '#EE82EE', rgb: 'rgb(238, 130, 238)' },
    { title: 'Orchid', color: '#DA70D6', rgb: 'rgb(218, 112, 214)' },
    { title: 'Fuchsia', color: '#FF00FF', rgb: 'rgb(255, 0, 255)' },
    { title: 'Magenta', color: '#FF00FF', rgb: 'rgb(255, 0, 255)' },
    { title: 'MediumOrchid', color: '#BA55D3', rgb: 'rgb(186, 85, 211)' },
    { title: 'MediumPurple', color: '#9370DB', rgb: 'rgb(147, 112, 219)' },
    { title: 'RebeccaPurple', color: '#663399', rgb: 'rgb(102, 51, 153)' },
    { title: 'BlueViolet', color: '#8A2BE2', rgb: 'rgb(138, 43, 226)' },
    { title: 'DarkViolet', color: '#9400D3', rgb: 'rgb(148, 0, 211)' },
    { title: 'DarkOrchid', color: '#9932CC', rgb: 'rgb(153, 50, 204)' },
    { title: 'DarkMagenta', color: '#8B008B', rgb: 'rgb(139, 0, 139)' },
    { title: 'Purple', color: '#800080', rgb: 'rgb(128, 0, 128)' },
    { title: 'Indigo', color: '#4B0082', rgb: 'rgb(75, 0, 130)' },
    { title: 'SlateBlue', color: '#6A5ACD', rgb: 'rgb(106, 90, 205)' },
    { title: 'DarkSlateBlue', color: '#483D8B', rgb: 'rgb(72, 61, 139)' },
    { title: 'MediumSlateBlue', color: '#7B68EE', rgb: 'rgb(123, 104, 238)' },
  ],
  // Noms de Couleur HTML Vert
  [
    { title: 'GreenYellow', color: '#ADFF2F', rgb: 'rgb(173, 255, 47)' },
    { title: 'Chartreuse', color: '#7FFF00', rgb: 'rgb(127, 255, 0)' },
    { title: 'LawnGreen', color: '#7CFC00', rgb: 'rgb(124, 252, 0)' },
    { title: 'Lime', color: '#00FF00', rgb: 'rgb(0, 255, 0)' },
    { title: 'LimeGreen', color: '#32CD32', rgb: 'rgb(50, 205, 50)' },
    { title: 'PaleGreen', color: '#98FB98', rgb: 'rgb(152, 251, 152)' },
    { title: 'LightGreen', color: '#90EE90', rgb: 'rgb(144, 238, 144)' },
    { title: 'MediumSpringGreen', color: '#00FA9A', rgb: 'rgb(0, 250, 154)' },
    { title: 'SpringGreen', color: '#00FF7F', rgb: 'rgb(0, 255, 127)' },
    { title: 'MediumSeaGreen', color: '#3CB371', rgb: 'rgb(60, 179, 113)' },
    { title: 'SeaGreen', color: '#2E8B57', rgb: 'rgb(46, 139, 87)' },
    { title: 'ForestGreen', color: '#228B22', rgb: 'rgb(34, 139, 34)' },
    { title: 'Green', color: '#008000', rgb: 'rgb(0, 128, 0)' },
    { title: 'DarkGreen', color: '#006400', rgb: 'rgb(0, 100, 0)' },
    { title: 'YellowGreen', color: '#9ACD32', rgb: 'rgb(154, 205, 50)' },
    { title: 'OliveDrab', color: '#6B8E23', rgb: 'rgb(107, 142, 35)' },
    { title: 'Olive', color: '#808000', rgb: 'rgb(128, 128, 0)' },
    { title: 'DarkOliveGreen', color: '#556B2F', rgb: 'rgb(85, 107, 47)' },
    { title: 'MediumAquamarine', color: '#66CDAA', rgb: 'rgb(102, 205, 170)' },
    { title: 'DarkSeaGreen', color: '#8FBC8B', rgb: 'rgb(143, 188, 139)' },
    { title: 'LightSeaGreen', color: '#20B2AA', rgb: 'rgb(32, 178, 170)' },
    { title: 'DarkCyan', color: '#008B8B', rgb: 'rgb(0, 139, 139)' },
    { title: 'Teal', color: '#008080', rgb: 'rgb(0, 128, 128)' },
  ],
  // Noms de Couleur HTML Bleu
  [
    { title: 'Aqua', color: '#00FFFF', rgb: 'rgb(0, 255, 255)' },
    { title: 'Cyan', color: '#00FFFF', rgb: 'rgb(0, 255, 255)' },
    { title: 'LightCyan', color: '#E0FFFF', rgb: 'rgb(224, 255, 255)' },
    { title: 'PaleTurquoise', color: '#AFEEEE', rgb: 'rgb(175, 238, 238)' },
    { title: 'Aquamarine', color: '#7FFFD4', rgb: 'rgb(127, 255, 212)' },
    { title: 'Turquoise', color: '#40E0D0', rgb: 'rgb(64, 224, 208)' },
    { title: 'MediumTurquoise', color: '#48D1CC', rgb: 'rgb(72, 209, 204)' },
    { title: 'DarkTurquoise', color: '#00CED1', rgb: 'rgb(0, 206, 209)' },
    { title: 'CadetBlue', color: '#5F9EA0', rgb: 'rgb(95, 158, 160)' },
    { title: 'SteelBlue', color: '#4682B4', rgb: 'rgb(70, 130, 180)' },
    { title: 'LightSteelBlue', color: '#B0C4DE', rgb: 'rgb(176, 196, 222)' },
    { title: 'PowderBlue', color: '#B0E0E6', rgb: 'rgb(176, 224, 230)' },
    { title: 'LightBlue', color: '#ADD8E6', rgb: 'rgb(173, 216, 230)' },
    { title: 'SkyBlue', color: '#87CEEB', rgb: 'rgb(135, 206, 235)' },
    { title: 'LightSkyBlue', color: '#87CEFA', rgb: 'rgb(135, 206, 250)' },
    { title: 'DeepSkyBlue', color: '#00BFFF', rgb: 'rgb(0, 191, 255)' },
    { title: 'DodgerBlue', color: '#1E90FF', rgb: 'rgb(30, 144, 255)' },
    { title: 'CornflowerBlue', color: '#6495ED', rgb: 'rgb(100, 149, 237)' },
    { title: 'MediumSlateBlue', color: '#7B68EE', rgb: 'rgb(123, 104, 238)' },
    { title: 'RoyalBlue', color: '#4169E1', rgb: 'rgb(65, 105, 225)' },
    { title: 'Blue', color: '#0000FF', rgb: 'rgb(0, 0, 255)' },
    { title: 'MediumBlue', color: '#0000CD', rgb: 'rgb(0, 0, 205)' },
    { title: 'DarkBlue', color: '#00008B', rgb: 'rgb(0, 0, 139)' },
    { title: 'Navy', color: '#000080', rgb: 'rgb(0, 0, 128)' },
    { title: 'MidnightBlue', color: '#191970', rgb: 'rgb(25, 25, 112)' },
  ],
  // Noms de Couleur HTML Marron
  [
    { title: 'Cornsilk', color: '#FFF8DC', rgb: 'rgb(255, 248, 220)' },
    { title: 'BlanchedAlmond', color: '#FFEBCD', rgb: 'rgb(255, 235, 205)' },
    { title: 'Bisque', color: '#FFE4C4', rgb: 'rgb(255, 228, 196)' },
    { title: 'NavajoWhite', color: '#FFDEAD', rgb: 'rgb(255, 222, 173)' },
    { title: 'Wheat', color: '#F5DEB3', rgb: 'rgb(245, 222, 179)' },
    { title: 'BurlyWood', color: '#DEB887', rgb: 'rgb(222, 184, 135)' },
    { title: 'Tan', color: '#D2B48C', rgb: 'rgb(210, 180, 140)' },
    { title: 'RosyBrown', color: '#BC8F8F', rgb: 'rgb(188, 143, 143)' },
    { title: 'SandyBrown', color: '#F4A460', rgb: 'rgb(244, 164, 96)' },
    { title: 'Goldenrod', color: '#DAA520', rgb: 'rgb(218, 165, 32)' },
    { title: 'DarkGoldenrod', color: '#B8860B', rgb: 'rgb(184, 134, 11)' },
    { title: 'Peru', color: '#CD853F', rgb: 'rgb(205, 133, 63)' },
    { title: 'Chocolate', color: '#D2691E', rgb: 'rgb(210, 105, 30)' },
    { title: 'SaddleBrown', color: '#8B4513', rgb: 'rgb(139, 69, 19)' },
    { title: 'Sienna', color: '#A0522D', rgb: 'rgb(160, 82, 45)' },
    { title: 'Brown', color: '#A52A2A', rgb: 'rgb(165, 42, 42)' },
    { title: 'Maroon', color: '#800000', rgb: 'rgb(128, 0, 0)' },
  ],
  // Noms de Couleur HTML Blanc
  [
    { title: 'White', color: '#FFFFFF', rgb: 'rgb(255, 255, 255)' },
    { title: 'Snow', color: '#FFFAFA', rgb: 'rgb(255, 250, 250)' },
    { title: 'HoneyDew', color: '#F0FFF0', rgb: 'rgb(240, 255, 240)' },
    { title: 'MintCream', color: '#F5FFFA', rgb: 'rgb(245, 255, 250)' },
    { title: 'Azure', color: '#F0FFFF', rgb: 'rgb(240, 255, 255)' },
    { title: 'AliceBlue', color: '#F0F8FF', rgb: 'rgb(240, 248, 255)' },
    { title: 'GhostWhite', color: '#F8F8FF', rgb: 'rgb(248, 248, 255)' },
    { title: 'WhiteSmoke', color: '#F5F5F5', rgb: 'rgb(245, 245, 245)' },
    { title: 'SeaShell', color: '#FFF5EE', rgb: 'rgb(255, 245, 238)' },
    { title: 'Beige', color: '#F5F5DC', rgb: 'rgb(245, 245, 220)' },
    { title: 'OldLace', color: '#FDF5E6', rgb: 'rgb(253, 245, 230)' },
    { title: 'FloralWhite', color: '#FFFAF0', rgb: 'rgb(255, 250, 240)' },
    { title: 'Ivory', color: '#FFFFF0', rgb: 'rgb(255, 255, 240)' },
    { title: 'AntiqueWhite', color: '#FAEBD7', rgb: 'rgb(250, 235, 215)' },
    { title: 'Linen', color: '#FAF0E6', rgb: 'rgb(250, 240, 230)' },
    { title: 'LavenderBlush', color: '#FFF0F5', rgb: 'rgb(255, 240, 245)' },
    { title: 'MistyRose', color: '#FFE4E1', rgb: 'rgb(255, 228, 225)' },
  ],
  // Noms de Couleur HTML Gris
  [
    { title: 'Gainsboro', color: '#DCDCDC', rgb: 'rgb(220, 220, 220)' },
    { title: 'LightGray', color: '#D3D3D3', rgb: 'rgb(211, 211, 211)' },
    { title: 'Silver', color: '#C0C0C0', rgb: 'rgb(192, 192, 192)' },
    { title: 'DarkGray', color: '#A9A9A9', rgb: 'rgb(169, 169, 169)' },
    { title: 'Gray', color: '#808080', rgb: 'rgb(128, 128, 128)' },
    { title: 'DimGray', color: '#696969', rgb: 'rgb(105, 105, 105)' },
    { title: 'LightSlateGray', color: '#778899', rgb: 'rgb(119, 136, 153)' },
    { title: 'SlateGray', color: '#708090', rgb: 'rgb(112, 128, 144)' },
    { title: 'DarkSlateGray', color: '#2F4F4F', rgb: 'rgb(47, 79, 79)' },
    { title: 'Black', color: '#000000', rgb: 'rgb(0, 0, 0)' },
  ],
]
