// @flow
import * as React from 'react'
import { forEach, map, find, sortBy } from 'lodash'
import pipe from 'app/core/utils/pipe'
import { getResources } from 'app/store/selectors'
import resources from 'app/store/resources'
import ExpansionPanel from 'app/components/ExpansionPanel/ExpansionPanel.jsx'
import type { ID } from 'app/core/types'
import { PermissionCheckbox } from './PermissionCkeckbox.jsx'
import classes from './PermissionsPanel.module.scss'
import { permission } from './Permission.jsx'

type Props = {|
  actionPermission: Object,
  groupId: ID,
|}

function nest(obj: Object, keys: Array<string>, v: any) {
  if (keys.length === 1) {
    obj[keys[0]] ? obj[keys[0]].__actions__.push(v) : (obj[keys[0]] = { __actions__: [v] })
  } else {
    const key = keys.shift()
    obj[key] = nest(typeof obj[key] === 'undefined' ? {} : obj[key], keys, v)
  }
  return obj
}

function PermissionsView(props: Props): React$Node {
  const { actionPermission, groupId } = props
  const readOnly = !permission(['projet_groups_permissions_update'])

  function getNestedActivesPerms({ __actions__: a, ...rest }): number {
    if (Object.keys(rest).length === 0) a ? a.map((d) => (d.id ? 1 : 0)).reduce((a, b) => a + b) : 0
    const restValues = [0].concat(map(rest, (r) => getNestedActivesPerms(r))).reduce((a, b) => a + b)
    return a ? a.map((d) => (d.id ? 1 : 0)).reduce((a, b) => a + b) + restValues : restValues
  }

  function displayActions(
    actions: Array<{ isGlobal: boolean, action: String, permissions: Array<Object> }>,
    category: string,
    subCategory?: string = '',
    actionGroup?: string = '',
  ) {
    return (
      <>
        {map(sortBy(actions, ['action']), ({ action, id, permissions, actionPermissionId, isGlobal }: Object) => {
          return (
            <PermissionCheckbox
              disabled={readOnly}
              key={action}
              action={action}
              id={id}
              groupId={groupId}
              actionPermissionId={actionPermissionId}
              permissions={permissions}
              isGlobal={isGlobal}
              category={category}
              subCategory={subCategory}
              actionGroup={actionGroup}
            />
          )
        })}
      </>
    )
  }

  return (
    <div className="padding10">
      {map(actionPermission, ({ __actions__, ...subCategories }: Object, name: string) => {
        const activePerms = getNestedActivesPerms({ __actions__, ...subCategories })

        return (
          <ExpansionPanel
            disableExpansionPanel={Object.keys(actionPermission).length === 1}
            key={name}
            title={name}
            titleProps={{ style: { textTransform: 'capitalize' } }}
            rightContent={activePerms ? <div className={classes.activePerms}>{activePerms}</div> : null}
          >
            <div key={name} className="flex column fullWidth">
              {__actions__ && <div className="marginBottom15 marginTop10">{displayActions(__actions__, name)}</div>}
              <div>
                {map(subCategories, ({ __actions__, ...groups }: Object, subName: string) => {
                  let row = 0
                  const activePerms = getNestedActivesPerms({ __actions__, ...groups })

                  return (
                    <ExpansionPanel
                      key={subName}
                      title={subName}
                      titleProps={{ style: { textTransform: 'capitalize' } }}
                      rightContent={activePerms ? <div className={classes.activePerms}>{activePerms}</div> : null}
                    >
                      <div className="flex column fullWidth">
                        {__actions__ && (
                          <div className="marginBottom15 marginTop10">{displayActions(__actions__, name, subName)}</div>
                        )}
                        <div className={classes.groupGrid}>
                          {map(groups, ({ __actions__ }: Object, groupName: string) => {
                            row += 1
                            return [
                              <div key={`${groupName}_1`} className={classes.groupName} style={{ gridRow: row }}>
                                {groupName}:
                              </div>,
                              <div key={`${groupName}_2`}>{displayActions(__actions__, name, subName, groupName)}</div>,
                            ]
                          })}
                        </div>
                      </div>
                    </ExpansionPanel>
                  )
                })}
              </div>
            </div>
          </ExpansionPanel>
        )
      })}
    </div>
  )
}

export const PermissionsPanel: React$ComponentType<any> = pipe()
  .connect((state, props) => {
    const { groupId, isGlobal = false } = props
    const actionPermission = sortBy(getResources(state, 'actionPermissions'), [
      'category',
      'subCategory',
      'actionGroup',
    ])
    const actionPermissionGroups = map(getResources(state, 'actionPermissionGroups', { group: groupId }))

    const actionPermissionSorted = {}

    forEach(actionPermission, (ap) => {
      if (ap.isGlobal !== isGlobal) return

      const permissionGroup = find(actionPermissionGroups, (apg) => apg.actionPermission === ap.id)

      nest(
        actionPermissionSorted,
        [ap.category, ap.subCategory, ap.actionGroup || undefined].filter((_) => _),
        {
          actionPermissionId: ap.id,
          action: ap.action,
          permissions: ap.permissions,
          id: permissionGroup && permissionGroup.id,
          isGlobal: ap.isGlobal,
        },
      )
    })

    return {
      actionPermission: actionPermissionSorted,
    }
  })
  .request(({ groupId }) => {
    return Promise.all([
      resources.actionPermissions.fetchAll({
        params: {
          queries: {
            page_size: 1000,
          },
        },
      }),
      resources.actionPermissionGroups.getPermissionsByGroup(groupId),
    ])
  })
  .render(PermissionsView)
